import React from "react";
import { BiSolidPlaneAlt } from "react-icons/bi";
import EventTypeCard from "./EventTypeCard";

interface AddTransportationCardProps {
  addTransportation: any;
  first: boolean;
  isDisabled?: boolean;
}

const AddTransportationCard: React.FC<AddTransportationCardProps> = ({
  addTransportation,
  first,
  isDisabled = false,
}) => {
  return (
    <EventTypeCard
      name={"Transportation"}
      icon={BiSolidPlaneAlt}
      onClick={addTransportation}
      isDisabled={isDisabled}
      display={first ? "flex" : "none"}
    />
  );
};

export default AddTransportationCard;
