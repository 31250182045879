import { Box, Flex, Heading, Icon, Image, Text } from "@chakra-ui/react";
import { HOTEL_ORIGIN, IsImage, Language, prettyLocation, TranslationField } from "@lato/common";
import { default as React } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaHouse } from "react-icons/fa6";
import { MdHotel } from "react-icons/md";
import { HOTELBEDS_IMAGE_URL } from "../../../../../utils/constants";
import StarRating from "./hotel/StarRating";

export const defineImageUrl = (image: any, origin: HOTEL_ORIGIN) => {
  let oneImage = image;
  if (Array.isArray(image)) {
    if (image.length === 0) {
      return null;
    }
    oneImage = image[0].hasOwnProperty("site") ? image.filter((image: any) => IsImage(image.site))[0] : image[0];
  }

  if (oneImage?.hasOwnProperty("site")) {
    return oneImage.url;
  }
  return origin === HOTEL_ORIGIN.HOTELBEDS ? HOTELBEDS_IMAGE_URL + oneImage : oneImage?.replace("{size}", "x500");
};

export const SingleAccommodation: React.FC<any> = ({
  item,
  scale = 1,
  width = "80%",
  name = "rating",
  showLanguage = false,
}) => {
  const { mainAddress, subAddress } = item.hasOwnProperty("location")
    ? prettyLocation(item.location)
    : item.hasOwnProperty("address")
      ? prettyLocation({
          address: { ...item.address, country_code: item.address.countryiso },
          name: "",
          coordinates: item.coordinates,
        })
      : { mainAddress: "", subAddress: "" };

  const imageUrl = defineImageUrl(item.images && item.images.length > 0 ? item.images : item.image, item.origin);

  const stars = (
    <Box ml={scale === 1 ? "-8px" : "-1.2em"} mt={"1.3em"}>
      <StarRating defaultRating={item.rating} disable name={name} value={item.rating} scale={0.75 + (scale - 1) / 4} />
    </Box>
  );

  const languages = item.descriptions?.map((description: TranslationField) => description.language);

  return (
    <Box w={width === "75%" ? undefined : width} pointerEvents={"none"}>
      <Flex alignItems={scale === 1 ? "center" : "start"} gap={3} ml={scale >= 3 ? 4 : 0}>
        <Box
          w={scale === 1 ? "8em" : `${5 * scale}em`}
          h={scale === 1 ? "4.5em" : 3 * scale + "em"}
          overflow={"hidden"}
          borderRadius={"1em"}
          position={"relative"}
        >
          <Image
            width={"100%"}
            borderRadius={"1em"}
            position={"absolute"}
            left={"50%"}
            transform={imageUrl ? "translateX(-50%)" : "translateX(-50%) scale(2)"}
            src={
              imageUrl ?? "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png"
            }
          />
        </Box>
        <Flex flexDir={"column"} justifyContent={"space-between"} gap={1} width={"fit-content"} mr={3}>
          <Flex flexDir={scale === 1 ? "row" : "column"}>
            <Heading fontSize={`${16 + (scale - 1) * 2}px`} isTruncated width={"fit-content"}>
              {item.name}
            </Heading>
            {item.rating != undefined && item.rating !== 0 && stars}
          </Flex>
          {item.location?.name && item.location.name !== "" && (
            <Flex gap={1} alignItems={"center"} mt={scale >= 3 ? 1 : 0}>
              <Icon as={FaMapMarkerAlt} color={"gray.500"} boxSize={3} />
              <Text fontSize={`${12 + (scale - 1) * 2}px`}>{item.location.name}</Text>
            </Flex>
          )}
          {mainAddress && mainAddress !== "" && (item.location.name == null || item.location.name === "") && (
            <Flex gap={1} alignItems={"center"} mt={scale >= 3 ? 1 : 0}>
              <Icon as={FaMapMarkerAlt} color={"gray.500"} boxSize={3} />
              <Text fontSize={`${12 + (scale - 1) * 2}px`}>
                {mainAddress}
                {subAddress && subAddress !== "" ? ", " : ""} {subAddress}
              </Text>
            </Flex>
          )}
          {(item.accommodationType || item.origin) && (
            <Flex gap={1} alignItems={"center"} mt={scale >= 3 ? 1 : 0}>
              {item.accommodationType && (
                <>
                  <Icon as={FaHouse} color={"gray.500"} boxSize={3} />
                  <Text fontSize={`${12 + (scale - 1) * 2}px`}>{item.accommodationType}</Text>
                </>
              )}
              {item.origin && (
                <Flex ml="1em" gap={1} alignItems={"center"}>
                  <Icon as={MdHotel} color={"gray.500"} mt={"0.1em"} boxSize={3} />
                  <Text fontSize={`${12 + (scale - 1) * 2}px`}>{item.accommodationType}</Text>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
        <Flex alignSelf={scale >= 3 ? "flex-start" : "center"} gap={1} alignItems={"center"} marginLeft={"auto"}>
          {item.minRate && (
            <>
              <Text fontSize={`${16 + (scale - 1) * 2}px`}>
                {item.minRate} {item.currency}
              </Text>
            </>
          )}
          {showLanguage && languages && languages.length > 0 && (
            <Text ml={"auto"}>{languages.map((language: Language) => language?.flag).join(" ")}</Text>
          )}
        </Flex>
        {/* {item.rating != undefined && item.rating !== 0 && scale < 3 && stars} */}
      </Flex>
    </Box>
  );
};
