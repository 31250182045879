import React from "react";
import { Logo } from "../../assets/icons/MiniDrawerIcons";
import FullScreen from "../FullScreen";
import { LATO_SALES_URL } from "@lato/common";

interface SplitScreenLayoutProps {
  children: React.ReactNode;
}

const SplitScreenLayout: React.FC<SplitScreenLayoutProps> = ({ children }) => {
  return (
    <FullScreen
      // bg="rgb(14, 30, 37)"
      bg="lato.darkBlue"
    >
      <a href={LATO_SALES_URL}>
        <Logo boxSize={20} color="brand.300" mb={2} />
      </a>
      {children}
    </FullScreen>
  );
};
export default React.memo(SplitScreenLayout);
