import { Flex } from "@chakra-ui/react";
import { TRIP_TRAVELING_STATUS, Trip } from "@lato/common";
import React from "react";
import { FilterProps } from "react-table";
import { useTripsListStore } from "../../stores/trip/tripslistStore";
import MultiSelectColumnFilter from "./MultiSelectColumnFilter";

const TravelingFilter: React.FC<FilterProps<Trip>> = ({ ...props }) => {
  const { setTravelingFilter } = useTripsListStore();
  const statuses = Object.values(TRIP_TRAVELING_STATUS);

  const createTravelingOptions = React.useCallback((preFilteredRows: any, columnName: string) => {
    // If the members didn't load yet, return an empty set.
    return new Set(statuses);
  }, []);

  const displayTravelingOption = React.useCallback((value: string): React.ReactElement => {
    return (
      <Flex alignItems="center">
        <span>{value}</span>
      </Flex>
    );
  }, []);

  const handleOnChange = React.useCallback((e: any) => {
    if (e.length === statuses.length || e.length === 0) {
      setTravelingFilter("");
      props.columns.find((column) => column.id === "traveling_status")?.setFilter([]);
      // clears the sorting of start date if the filter is empty
      props.columns.find((column) => column.id === "start_date")?.clearSortBy();
    } else {
      setTravelingFilter(e);
      // determines if the sorting should be ascending or descending, should only be descending if the filter only contains past
      const desc = e.length === 1 && e[0] === TRIP_TRAVELING_STATUS.PAST;
      // sets the order of the sorting to sort by start_date
      props.setSortBy([{ id: "start_date", desc }]);
    }
  }, []);

  return (
    <MultiSelectColumnFilter
      {...props}
      createOptions={createTravelingOptions}
      displayOption={displayTravelingOption}
      onChange={handleOnChange}
    />
  );
};
export default React.memo(TravelingFilter);
