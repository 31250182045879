import { Stack, Text, useFormControlStyles } from "@chakra-ui/react";
import { MAP_TYPE, PRIMARY_COLOR, TRANSPORTATION_TYPE, getDistance, secondsToTimeFormat, Coord } from "@lato/common";
import polyline from "@mapbox/polyline";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import config from "../../config";
import { useMeContext } from "../../stores/me-context";
import {
  GMAPS_API_URL,
  MAPBOX_API,
  MAPBOX_ATTRIBUTION,
  OPENSTREETMAP_API,
  OPENSTREETMAP_ATTRIBUTION,
  TOMTOM_API,
  TOMTOM_ATTRIBUTION,
} from "../../utils/constants";
import ENV from "../../utils/env";
import ErrorCardView from "../layout/ErrorCardView";
import { CustomLeafletPinMarker } from "./CustomLeafletPinMarker";
import { GoToCoordinates } from "../../features/map/Maps";
import { useDirections } from "../../features/map/useDirections";

interface staticMapProps {
  center?: Coord;
  second?: Coord | null;
  imageHeight?: string;
  apiHeight?: number;
  provider?: MAP_TYPE;
  transportation_type?: TRANSPORTATION_TYPE;
  zoomPrep?: number;
  width?: number;
  height?: number;
  containerHeight?: string;
  containerWidth?: string;
  showDetails?: boolean;
  smallLabel?: boolean;
}

export const mapTypeApi: { [key in MAP_TYPE | string]: string } = {
  [MAP_TYPE.TOMTOM]: TOMTOM_API,
  [MAP_TYPE.OPENSTREETMAP]: OPENSTREETMAP_API,
  [MAP_TYPE.MAPBOX]: MAPBOX_API,
  [MAP_TYPE.GOOGLE]: "",
};

export const mapTypeAttribution: { [key in MAP_TYPE | string]: string } = {
  [MAP_TYPE.TOMTOM]: TOMTOM_ATTRIBUTION,
  [MAP_TYPE.OPENSTREETMAP]: OPENSTREETMAP_ATTRIBUTION,
  [MAP_TYPE.MAPBOX]: MAPBOX_ATTRIBUTION,
  [MAP_TYPE.GOOGLE]: "",
};

export const mapTypeKey: { [key in MAP_TYPE | string]: string } = {
  [MAP_TYPE.TOMTOM]: config[ENV].tomtom_api_key,
  [MAP_TYPE.OPENSTREETMAP]: "",
  [MAP_TYPE.MAPBOX]: config[ENV].mapbox_api_key,
  [MAP_TYPE.GOOGLE]: config[ENV].google_maps_api_key,
};

/*mapboxgl.accessToken =
  "pk.eyJ1IjoibGF0b3RyYXZlbGFwcCIsImEiOiJjbGxrdHdrOHYxajNoM2VtcG9lcWJzbjJiIn0.4DZGBBPnqr-Y4CkQYGYTeg";*/

const StaticMap: React.FC<staticMapProps> = ({
  center = { lng: 0, lat: 0 },
  second,
  transportation_type = TRANSPORTATION_TYPE.CAR,
  imageHeight = "9em",
  provider,
  containerHeight,
  containerWidth,
  width = 650,
  height = 160,
  zoomPrep,
  showDetails = true,
  smallLabel = false,
}) => {
  const user = useMeContext();
  const [bounds, setBounds] = React.useState<any>();
  const {
    isLoading: isLoadingDirections,
    data: directions,
    isError: isErrorDirections,
  } = useDirections({
    center,
    second,
    transportation_type,
    simplified: true,
    polyline: [MAP_TYPE.MAPBOX, MAP_TYPE.GOOGLE].includes(user.company.map_provider),
  });

  const mapType = provider ?? user.company.map_provider;

  const size = `${width}x${height}`;
  let api = "";
  const key = mapTypeKey[mapType];

  if (mapType === MAP_TYPE.GOOGLE) {
    const zoom = second ? "" : center ? `&zoom=${zoomPrep ?? 13}` : `&zoom=${zoomPrep ?? 10}`;
    const centerMarker = center ? `&markers=%7Clabel:A%7C${center.lat},${center.lng}` : "";
    const secondMarker = second ? `&markers=%7Clabel:B%7C${second.lat},${second.lng}` : "";

    const path =
      second &&
      center &&
      (isErrorDirections ||
        isLoadingDirections ||
        !directions ||
        directions.code !== "Ok" ||
        [TRANSPORTATION_TYPE.FLIGHT, TRANSPORTATION_TYPE.TRAIN].includes(transportation_type))
        ? `&path=color:0x43a8a0ff|weight:3|${center.lat},${center.lng}|${second.lat},${second.lng}`
        : "";

    const directionsPath =
      directions &&
      directions.code === "Ok" &&
      ![TRANSPORTATION_TYPE.FLIGHT, TRANSPORTATION_TYPE.TRAIN].includes(transportation_type)
        ? `&path=color:0x43a8a0ff|weight:3|enc:${encodeURIComponent(directions.routes[0].geometry as any)}`
        : "";

    api = `${GMAPS_API_URL}/staticmap?size=${size}&scale=2${zoom}${centerMarker}${secondMarker}${path}${directionsPath}&key=${key}`;
  }

  if (mapType === MAP_TYPE.MAPBOX) {
    api = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static";
    const basicPath = polyline.encode([
      [center.lat, center.lng],
      [second?.lat ?? 0, second?.lng ?? 0],
    ]);

    if (center) {
      const pathParam = second
        ? `path-4+43a8a0(${
            second &&
            center &&
            (isErrorDirections ||
              isLoadingDirections ||
              !directions ||
              directions.code !== "Ok" ||
              [TRANSPORTATION_TYPE.FLIGHT, TRANSPORTATION_TYPE.TRAIN].includes(transportation_type))
              ? encodeURIComponent(basicPath)
              : encodeURIComponent(directions?.routes[0].geometry)
          }),`
        : "";

      api += `/${pathParam}pin-s-a+f74e4e(${center.lng},${center.lat})${
        second ? `,pin-s-b+f74e4e(${second.lng},${second.lat})` : ""
      }/${second ? "auto" : `${center.lng},${center.lat},11`}/${size}?access_token=${key}&logo=false`;
    }
  }

  const interactionOptions = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false,
  };

  useEffect(() => {
    const bounds = second && [
      { lat: center.lat, lng: center.lng },
      { lat: second ? second.lat : center.lat, lng: second ? second.lng : center.lng },
    ];
    setBounds(bounds);
  }, [center, second]);

  return (
    <div>
      {directions && directions.code === "Ok" && showDetails && (
        <Text fontSize="sm" color="realGray.800">
          {getDistance(directions.routes[0].distance, 0)} | estimated duration:{" "}
          {secondsToTimeFormat(directions.routes[0].duration)}{" "}
        </Text>
      )}
      {mapType !== MAP_TYPE.OPENSTREETMAP && mapType !== MAP_TYPE.TOMTOM ? (
        <div
          style={{
            height: containerHeight ?? "100%",
            width: containerWidth ?? "100%",
            position: "relative",
          }}
        >
          <img
            alt={"maps"}
            style={{
              maxHeight: containerHeight,
              width: "100%",
              height: "auto",
              /*position: "absolute",
          left: "50%",
          transform: "translate(-50%, 0)",*/
              objectFit: "cover",
              borderRadius: "0.4em",
            }}
            src={api}
          />
        </div>
      ) : (
        <MapContainer
          center={second ? { lng: 0, lat: 0 } : center}
          style={{
            minHeight: containerHeight ?? "9em",
            maxHeight: containerHeight,
            width: containerWidth ?? "100%",
            height: containerHeight ?? "auto",
            objectFit: "cover",
            borderRadius: "0.4em",
          }}
          zoom={12}
          className={smallLabel ? "container-small-label" : undefined}
          {...interactionOptions}
        >
          <GoToCoordinates bounds={bounds} padding={[10, 10]} />
          <TileLayer attribution={mapTypeAttribution[mapType]} url={mapTypeApi[mapType]} />
          <CustomLeafletPinMarker
            coordinates={[center.lng, center.lat]}
            pinColor={"#E53E3E"}
            nestedIcon={<Text>A</Text>}
            disableHover
          ></CustomLeafletPinMarker>

          {second && (
            <>
              <CustomLeafletPinMarker
                coordinates={[second.lng, second.lat]}
                pinColor={"#E53E3E"}
                nestedIcon={<Text>B</Text>}
                disableHover
              ></CustomLeafletPinMarker>
              {isErrorDirections ||
              isLoadingDirections ||
              !directions ||
              directions.code !== "Ok" ||
              [TRANSPORTATION_TYPE.FLIGHT, TRANSPORTATION_TYPE.TRAIN].includes(transportation_type) ? (
                <Polyline pathOptions={{ color: PRIMARY_COLOR }} positions={[center, second]} />
              ) : (
                <Polyline
                  pathOptions={{ color: PRIMARY_COLOR }}
                  positions={directions?.routes[0].geometry.coordinates.map((coord: any) => [coord[1], coord[0]])}
                />
              )}
            </>
          )}
        </MapContainer>
      )}
    </div>
  );
};
export default React.memo(StaticMap);
