import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface NotificationBadgeProps extends BoxProps {
  count: number;
}

const NotificationBadge: React.FC<NotificationBadgeProps> = ({ count, ...props }) => {
  if (count < 1) return <></>;
  return (
    <Box
      fontSize="0.6rem"
      fontWeight="400"
      borderRadius="full"
      py={0}
      px={"0.3rem"}
      background={"rgb(237,116,102)"}
      pos="absolute"
      top={0}
      right={0}
      color="white"
      transform="translate(-5%, -20%)"
      {...props}
    >
      {count}
    </Box>
  );
};
export default React.memo(NotificationBadge);
