export type ToastType = {
  title: string;
  description?: string;
  status: "info" | "warning" | "success" | "error" | undefined;
  duration?: number;
  isClosable?: boolean;
};

export function addToast(toast: any, { title, status, description, duration, isClosable }: ToastType) {
  toast({
    title,
    description,
    status,
    duration: duration,
    isClosable: isClosable || true,
  });
}
