import { Flex, Text } from "@chakra-ui/react";
import { CompanyBrand } from "@lato/common";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import RHFInput from "../../components/input/RHFInput";
import Section from "../../components/layout/Section";
import { NewBadge } from "../../components/layout/FeatureBadges";
import { Badge } from "../../components/shadcn-ui/Badge";
import { BrandContent, BrandImage } from "./BrandSettings";

interface AIWritingPreferencesSettingsProps {
  id: string | undefined;
  brand: CompanyBrand;
  index: number;
  setAsDefault: (i: number, fieldname: BrandContent) => void;
}

const agentExamples = {
  "Professional and Informative":
    "You are a highly professional and knowledgeable travel agent using the advanced travel planning software, Lato. Your tone is informative, precise, and formal, providing detailed and accurate information to clients. Write in a manner that instills trust and confidence in your expertise.",
  "Friendly and Approachable":
    "You are a friendly and approachable travel agent using the user-friendly travel software, Lato. Your tone is warm, engaging, and personable, making clients feel welcomed and valued. Write in a manner that is conversational and easy-going, ensuring clients feel comfortable and excited about their travel plans.",
  "Adventurous and Enthusiastic":
    "You are an adventurous and enthusiastic travel agent using the dynamic travel planning software, Lato. Your tone is energetic, lively, and inspiring, encouraging clients to embark on exciting journeys. Write in a manner that sparks curiosity and a sense of adventure, motivating clients to explore new destinations.",
  "Luxurious and Sophisticated":
    "You are a luxurious and sophisticated travel agent using the premium travel management software, Lato. Your tone is elegant, refined, and upscale, catering to clients seeking high-end travel experiences. Write in a manner that exudes class and exclusivity, appealing to clients with discerning tastes.",
  "Fun and Playful":
    "You are an adventurous and enthusiastic travel agent using the dynamic travel planning software, Lato. Your tone is energetic, lively, and inspiring, encouraging clients to embark on exciting journeys. Write in a manner that sparks curiosity and a sense of adventure, motivating clients to explore new destinations.",
};

const AIWritingPreferencesSettings: React.FC<AIWritingPreferencesSettingsProps> = ({
  id,
  brand,
  index,
  setAsDefault,
}) => {
  const fieldName = `brands.${index}.content_ai_preference_sentence`;
  const { reset, getValues, control } = useFormContext();
  // const [exampleText, setExampleText] = useState(
  //   "Use an informative tone, keep it short and simple. Do not use the word 'interesting'. Use no punctuation.",
  // );

  const setAgentExample = (
    e: React.MouseEvent<HTMLDivElement> | React.PointerEvent<HTMLDivElement>,
    prompt: string,
  ) => {
    const values = getValues();
    values.brands = values.brands.map((brand: any, i: number) =>
      i === index ? { ...brand, content_ai_preference_sentence: prompt } : brand,
    );
    reset(values);
  };

  return (
    <Section
      title={
        <Flex>
          AI Brand Voice <NewBadge />
        </Flex>
      }
      id={id}
      headingExtra={<BrandImage name={brand ? brand.name : ""} logoUrl={brand?.logoUrl} />}
    >
      <Flex flexDir={"column"} gap={3}>
        <Text>
          {`Create a brand voice that represents your company's unique personality and style to keep your content on-brand.`}
        </Text>
        <div className="flex flex-wrap gap-2">
          {Object.keys(agentExamples).map((agentTitle: string, i: number) => (
            <Badge
              key={`agent-${i}`}
              variant="lato"
              className="cursor-pointer"
              onClick={(e) => setAgentExample(e, agentExamples[agentTitle])}
            >
              {agentTitle}
            </Badge>
          ))}
        </div>
        <RHFInput
          key={`${fieldName}-input`}
          name={fieldName}
          textarea
          label=" "
          minHeight={"20em"}
          placeholder={
            "Use an informative tone, keep it short and simple. Do not use the word 'interesting'. Use no punctuation."
          }
        />
        {/* Button to make setting default for all brands, disabled for now
        <Button
          alignSelf={"flex-start"}
          size={"sm"}
          float={"left"}
          fontSize={"md"}
          colorScheme="brand"
          outline={"ghost"}
          onClick={() => setAsDefault(index, "content_ai_preference_sentence")}
        >
          Make Default For All Brands
        </Button> */}
      </Flex>
    </Section>
  );
};
export default AIWritingPreferencesSettings;
