import { Box, Flex, useRadio } from "@chakra-ui/react";
import React from "react";
import colors from "../../../../../../theme/foundations/colors";

export const RadioCard = ({ borderShadow = "md", opacity = 1, width = "auto", ...props }: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" width={width}>
      <input {...input} />
      <Flex
        {...checkbox}
        cursor="pointer"
        boxShadow={borderShadow}
        borderWidth="1px"
        borderRadius="md"
        opacity={opacity}
        flexDirection={"column"}
        alignItems={"center"}
        _checked={{
          bg: colors.lato.primaryColor,
          color: "white",
          borderColor: "teal.600",
        }}
      >
        {props.children}
      </Flex>
    </Box>
  );
};
