import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useToast,
  Flex,
  ButtonGroup,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import * as yup from "yup";
import CompanyAPI from "../../../api/companies.api";
import { addToast } from "../../../utils/addToast";
import GeneralEmailForm from "../../form/GeneralEmailForm";
import CustomModal from "../../layout/CustomModal";

interface NewMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewMemberModal: React.FC<NewMemberModalProps> = ({ isOpen, onClose }) => {
  const successCallback = React.useCallback(async () => {
    // Close the modal
    onClose();
  }, [onClose]);

  return (
    <CustomModal title="Add new team member" isOpen={isOpen} onClose={onClose}>
      <Text mb="1rem">New team members will get an email with a link to accept the invitation.</Text>
      <GeneralEmailForm
        successCallback={successCallback}
        buttonText="Add member"
        apiCall={CompanyAPI.inviteTeamMember}
        successMessage="sent invitation"
        failMessage="sending invitation"
        customButtonLayout={(isSubmitting, isValid) => (
          <Flex mt={7} mb={2}>
            <ButtonGroup size="md" ml="auto">
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="brand" ml={3} isLoading={isSubmitting} isDisabled={!isValid}>
                Add member
              </Button>
            </ButtonGroup>
          </Flex>
        )}
      />
    </CustomModal>
  );
};
export default NewMemberModal;
