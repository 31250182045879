import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  TableBodyProps as ChakraTableBodyProps,
  Flex,
  Heading,
  Progress,
  Spinner,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  chakra,
} from "@chakra-ui/react";
import React from "react";
import { type DraggableProvided, type DraggableStateSnapshot } from "react-beautiful-dnd";
import { Row, TableInstance, TableRowProps } from "react-table";
import { horizontalSectionPadding } from "../layout/Section";
import { DraggableCustomRow, DroppableTbody } from "./DragAndDropTable";

interface CRUDTableProps<ObjectType extends object> {
  tableMethods: TableInstance<any>;
  rows: Row<any>[];
  totalCount: number;
  fetchingData: boolean;
  loadingData: boolean;
  getRowProps: ({
    row,
    toggleAllRowsExpanded,
  }: {
    row: Row;
    toggleAllRowsExpanded: (value?: boolean | undefined) => void;
  }) => any;
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  renderRowExpansion?: (row: Row<ObjectType>) => any;
  addRow: any;
  displayName: string;
  tableCaption: any;
  tbodyBackgroundImage: any;
  minTableHeight?: string;
  maxTableHeight?: string;
  lastDisabled: boolean;
  marginTop?: string;
  hideHeadersWhenEmpty?: boolean;
  isFiltering?: boolean;
  showHeaders?: boolean;
}

const CRUDTable = <T extends object>({
  displayName,
  tableMethods,
  rows,
  totalCount,
  fetchingData,
  loadingData,
  getRowProps,
  moveRow,
  addRow,
  renderRowExpansion = (_) => undefined,
  tableCaption,
  tbodyBackgroundImage,
  minTableHeight,
  maxTableHeight = "100%",
  lastDisabled,
  marginTop,
  hideHeadersWhenEmpty = false,
  isFiltering = false,
  showHeaders = true,
}: // isEditing,
CRUDTableProps<T>) => {
  const {
    getTableProps,
    getTableBodyProps,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    toggleAllRowsExpanded,
    canNextPage,
    pageOptions,
    headerGroups,
    columns,
    prepareRow,
    state: { pageIndex, pageSize },
    preGlobalFilteredRows,
  }: TableInstance<T> = tableMethods;

  const tableBodyStyleProps: any = {
    // The minimum table height
    minH: minTableHeight,
    overflow: "auto",
    maxHeight: maxTableHeight,
    // All the props below are just to insert a background picture into the table body
    position: "relative",
    zIndex: 1,
    marginTop: marginTop,
    _after: tbodyBackgroundImage
      ? {
          content: '""',
          display: "block",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: "0.2",
          zIndex: -1,
          backgroundImage: `url(${tbodyBackgroundImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "75% auto",
        }
      : undefined,
  };

  return (
    <Box {...tableBodyStyleProps} zIndex="auto" className="me-6 overflowVisible">
      <Table
        variant="unstyled"
        // colorScheme="lightGray"
        {...getTableProps()}
        size="sm"
        width="100%"
      >
        {showHeaders && (
          <Thead>
            {headerGroups.map((headerGroup) =>
              totalCount === 0 && hideHeadersWhenEmpty ? (
                <></>
              ) : (
                <Tr {...headerGroup.getHeaderGroupProps()} key={`headergroup-${headerGroup.id}`}>
                  {headerGroup.headers.map(
                    (column, i) =>
                      !column.hide && (
                        <Th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={`column-${headerGroup.id}-${column.id}`}
                          color="realGray.400"
                          // width={`${column.width}px`}
                          // minWidth={`${column.minWidth}px`}
                          maxW={column.chakraMaxWidth}
                          w={column.chakraWidth}
                          // This prevents the table from overflowing.
                          // maxW={0}
                          borderBottomWidth="2px"
                          borderBottomColor="lightGray.100"
                          isNumeric={column.isNumeric}
                          whiteSpace="nowrap"
                          // The padding on this Th results in the spacing between the headers
                          // horizontalSectionPadding is the px padding equal in the Section component
                          pl={i === 0 ? horizontalSectionPadding : 1}
                          pr={i === headerGroup.headers.length - 1 ? horizontalSectionPadding : 2}
                          pos="sticky"
                          top={0}
                          verticalAlign="bottom"
                          bg="white"
                          zIndex={2}
                        >
                          <Flex align="flex-end" justifyContent={column.isNumeric ? "end" : undefined}>
                            {column.isNumeric ? null : column.render("Header")}
                            <chakra.span pl={column.isNumeric ? undefined : 2} pr={column.isNumeric ? 2 : undefined}>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <TriangleDownIcon aria-label="sorted descending" />
                                ) : (
                                  <TriangleUpIcon aria-label="sorted ascending" />
                                )
                              ) : null}
                            </chakra.span>
                            {column.isNumeric ? column.render("Header") : null}
                          </Flex>
                        </Th>
                      ),
                  )}
                </Tr>
              ),
            )}
            {fetchingData && !loadingData && (
              // Re-fetching the async data
              <Tr>
                <Td colSpan={columns.length} p={0}>
                  <Progress size="xs" isIndeterminate />
                </Td>
              </Tr>
            )}
          </Thead>
        )}
        {
          rows.length > 0 ? (
            <TableBodyWrapper {...getTableBodyProps()} moveRow={moveRow} lastDisabled={lastDisabled}>
              {rows.map((row, index) => {
                prepareRow(row);
                // The key field is from
                const disabledRowDragging = lastDisabled && index === rows.length - 1;
                const CustomRowWrapper = moveRow && !disabledRowDragging ? DraggableCustomRow : CustomRow;
                return (
                  <CustomRowWrapper
                    {...row.getRowProps(getRowProps({ row, toggleAllRowsExpanded }))}
                    key={row.original.rhfId || row.id}
                    row={row}
                    index={index}
                    renderRowExpansion={renderRowExpansion}
                  />
                );
              })}
            </TableBodyWrapper>
          ) : // No rows to display: so either no records available or no records matching the filters or still fetching the data.
          loadingData ? (
            // Loading the async data
            <TableCaption>
              <Center w="100%" minH="inherit" flexDir="column">
                <Heading size="md" fontWeight="500" color="realGray.400">
                  Loading...
                </Heading>
                <Spinner size="md" mt={2} color="realGray.400" />
              </Center>
            </TableCaption>
          ) : (
            // Not loading the data
            // Either there are no records, or the filters resulted in no matches
            // Does not work, because if there is no match, the total count will be 0
            // totalCount > 0 ? (
            //   // No records matching the filters
            //   <TableCaption>
            //     <Heading size="md">0 matches</Heading>
            //     <Button type="button" mt={2}>
            //       Clear filters
            //     </Button>
            //   </TableCaption>
            // ) : (
            // This resource has zero saved records
            <TableCaption>
              <Center w="100%" minH="inherit">
                {tableCaption ?? (
                  <Heading size="md" fontWeight="500" color="realGray.400">
                    {isFiltering ? `0 ${displayName}` : `No ${displayName.toLowerCase()} yet`}
                  </Heading>
                )}
              </Center>
              {/* <Button type="button" mt={2} onClick={addRow}>
                Create a new record
              </Button> */}
            </TableCaption>
          )
          // )
        }
      </Table>
    </Box>
  );
};
export default CRUDTable;

export interface CustomRowProps extends TableRowProps {
  row: Row<any>;
  index: number;
  renderRowExpansion: (row: Row<any>) => any;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  // onClick: any;
}
// const DND_ITEM_TYPE = "row";

export const CustomRow = ({ row, index, renderRowExpansion, provided, snapshot, ...props }: CustomRowProps) => {
  return (
    <>
      <Tr
        {...props}
        borderLeftWidth={row.isExpanded ? 4 : 0}
        borderColor="brand.300"
        // React-beautiful-dnd
        ref={provided?.innerRef}
        // isDragging={snapshot?.isDragging}
        {...provided?.draggableProps}
        // {...provided.dragHandleProps}
        bg={snapshot?.isDragging ? "brand.50" : ""}
        zIndex={snapshot?.isDragging ? 1000 : 1}
      >
        {row.cells.map(
          (cell, i) =>
            !cell.column.hide && (
              // eslint-disable-next-line react/jsx-key
              <Td
                {...cell.getCellProps()}
                // Transform the widths to chakra-widths in px because otherwise the scale is exponential
                // width={`${cell.column.width}px`}
                // minWidth={`${cell.column.minWidth}px`}
                // Hack? This allows for full responsive flexibility
                w={cell.column.chakraWidth}
                maxW={cell.column.chakraMaxWidth}
                // Only set the max-width to 0 when the column should be truncated
                // maxW={cell.column.isTruncated ? 0 : -1}
                // To outline the columns to the right
                isNumeric={cell.column.isNumeric}
                textAlign={cell.column.isNumeric ? "end" : "start"}
                // To truncate the columns
                isTruncated={cell.column.isTruncated}
                // border="1px solid red"
                // Should not split cells on different rows
                whiteSpace="nowrap"
                overflow={cell.column.chakraMaxWidth ? "hidden" : "visible"}
                // Shows the complete value as a placeholder when hovering over the cell
                title={cell.column.noTooltip ? undefined : cell.value?.toString()}
                // horizontalSectionPadding is the px padding equal in the Section component
                pl={i === 0 ? horizontalSectionPadding : 1}
                pr={i === row.cells.length - 1 ? horizontalSectionPadding : 2}
                // verticalAlign="top"
              >
                {cell.render("Cell", {
                  dragHandleProps: provided?.dragHandleProps,
                })}
              </Td>
            ),
        )}
      </Tr>
      {/*
                  If the row is in an expanded state, render a row with a
                  column that fills the entire length of the table.
                */}
      {row.isExpanded ? (
        <Tr>
          <Td colSpan={row.cells.length} bg="gray.50" borderLeftWidth={4} borderBottomWidth={0} borderColor="brand.300">
            {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
            {renderRowExpansion(row)}
          </Td>
        </Tr>
      ) : null}
    </>
  );
};

interface TableBodyWrapperProps extends ChakraTableBodyProps {
  moveRow?: (dragIndex: number, hoverIndex: number) => void;
  lastDisabled: boolean;
}

const TableBodyWrapper: React.FC<TableBodyWrapperProps> = ({ moveRow, lastDisabled, ...props }) => {
  return moveRow ? <DroppableTbody {...props} moveRow={moveRow} lastDisabled={lastDisabled} /> : <Tbody {...props} />;
};
