import { extendTheme } from "@chakra-ui/react";

// Global style overrides (body, a, p, h1, ...)
import styles from "./styles";

// Foundational style overrides (colors, borders, ...)
import colors from "./foundations/colors";
import fonts from "./foundations/fonts";
import fontSizes from "./foundations/fontSizes";
// import sizes from "./foundations/sizes";
// Component style overrides (Button, Table, Accordion, ...)

import Alert from "./components/alert";
import Button from "./components/button";
import FormLabel from "./components/formLabel";
import Heading from "./components/heading";
import Input from "./components/input";
import Link from "./components/link";
import Menu from "./components/menu";
import Modal from "./components/modal";
import Select from "./components/select";
import Textarea from "./components/textarea";

const overrides = {
  styles,
  // borders,
  fonts,
  colors,
  fontSizes,
  // sizes,
  // Other foundational style overrides go here
  components: {
    Heading,
    Button,
    Input,
    Textarea,
    FormLabel,
    Select,
    Link,
    Modal,
    Alert,
    Menu,
    // Other components go here
  },
};

export default extendTheme(overrides);
