import { Flex } from "@chakra-ui/react";
import { Company, UserTrip } from "@lato/common";
import React from "react";
import { FilterProps, Filters } from "react-table";
import CompanyAPI from "../../api/companies.api";
import { mapFiltersToQueryParams } from "../../pages/trip-list-container";
import { useCustomQuery, useFindCompaniesWhere, useMyCompany } from "../../utils/query-helpers/reactQueryHooks";
import MultiSelectColumnFilter from "./MultiSelectColumnFilter";
import MultiSelectColumnFilterWithPagination from "./MultiSelectColumnFilterWithPagination";

interface CompanyFilterProps extends React.PropsWithChildren<FilterProps<UserTrip>> {
  filters: Filters<UserTrip> | undefined;
  singleSelect?: boolean;
  disableDeselect?: boolean;
}

export const useAllCompanies = () => useCustomQuery<Company[]>(["companies"], async () => await CompanyAPI.getAll());

const useSingleCompany = (id: string) =>
  useCustomQuery<Company>(["companies", id], async () => await CompanyAPI.getSingle(id));

const CompanyFilter: React.FC<CompanyFilterProps> = ({
  filters,
  singleSelect = false,
  disableDeselect = false,
  ...props
}) => {
  // const { following, isLoading: isLoadingFollowingCompanies } = useGetFollowingList();

  const { data: myCompany, isLoading: isLoadingMyCompany, error: errorLoadingMyCompany } = useMyCompany();

  const createCompanyOptions = (companies: Company[], preFilteredRows: any, columnName: string) => {
    // If the members didn't load yet, return an empty set.
    if (!companies) return new Set<string>();
    companies = companies.filter((c) => c);
    return new Set(companies.map((c) => c.id!));
  };

  const displayCompanyOption = (companies: Company[], companyId: string): React.ReactElement => {
    // Find a single companytrip of this company to get the company object
    const company = companies?.find((c) => c.id! === companyId);
    // If the members didn't load yet or somehow no company was found, return an empty fragment.
    if (!company) return <></>;
    return (
      <Flex alignItems="center">
        {/* <Avatar
            size="xs"
            name={company.name}
            src={company.avatar ? company.avatarUrl : ""}
            alt={"company-" + (company.name || company.id!)}
            mr={2}
          /> */}
        <span>{company.name}</span>
      </Flex>
    );
  };

  return (
    <MultiSelectColumnFilterWithPagination
      {...props}
      apiCall={CompanyAPI.findCompaniesWhere}
      queryName={"find-companies-where"}
      displayOptionCallback={displayCompanyOption}
      // TODO: remove later, but currently BUG: the preFilteredRows are sometimes undefined
      createOptionsCallback={createCompanyOptions}
      singleSelect={singleSelect}
      disableDeselect={disableDeselect}
      defaultDisplayValues={[myCompany]}
      isLoading={isLoadingMyCompany}
      isError={!!errorLoadingMyCompany}
      queryParams={{ ...mapFiltersToQueryParams(filters || []), step: "20" }}
    />
  );
};
export default React.memo(CompanyFilter);
