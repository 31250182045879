import { Button, Code, Flex, Icon, IconButton, Text, useClipboard, useDisclosure } from "@chakra-ui/react";
import { Trip, UserTrip } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { FaRegCopy } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useMeContext } from "../../../../../stores/me-context";
import { useTripLanguage } from "../../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../../stores/trip/tripFormStore";
import {
  DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY,
  SHARE_STEP_TRIP_QUERY_KEY,
  SHARE_STEP_USER_TRIP_QUERY_KEY,
} from "../../../../../utils/constants";
import {
  getRelationMap,
  updateRelationMap,
  usePatchSplitTrip,
  usePatchSplitUserTrip,
  useSplitTrip,
  useSplitUserTrip,
} from "../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { ShareSkeleton, WizardSkeleton } from "../../../../FullScreenSpinner";
import CustomModal from "../../../../layout/CustomModal";
import Wizard from "../../../../../features/editTrip/wizard/Wizard";
import TripForm from "../../../TripForm";
import TripSettings from "./TripSettings";

interface SettingsStepProps {
  stepNumber: number;
}

const SettingsStep: React.FC<SettingsStepProps> = ({ stepNumber }) => {
  const me = useMeContext();
  const { id: userTripId } = useParams<{ id: string }>();
  const { trip: tripFromStore, isFirst, isSample } = useTripFormStore();

  const { firstSelectedLanguageIndex } = useTripLanguage();

  const { data: usertrips, isLoading: isLoadingUsertrip } = useSplitUserTrip(
    userTripId ?? "",
    getRelationMap[SHARE_STEP_USER_TRIP_QUERY_KEY],
    SHARE_STEP_USER_TRIP_QUERY_KEY,
  );

  const usertrip = usertrips && usertrips[usertrips.length - 1];

  const { data: trip, isLoading: isLoadingTrip } = useSplitTrip(
    tripFromStore?.id ?? "",
    SHARE_STEP_TRIP_QUERY_KEY,
    getRelationMap[SHARE_STEP_TRIP_QUERY_KEY],
  );

  if (isLoadingTrip || isLoadingUsertrip || !trip || !usertrip || !me) {
    return (
      <>
        <WizardSkeleton />
        <ShareSkeleton />
      </>
    );
  }

  return (
    <>
      {!me.useNewEditTripLayout &&
        (trip ? (
          <Wizard
            trip={trip}
            collaboratorCanEdit={trip.collaboratorCanEdit}
            stepNumber={stepNumber}
            isFirst={isFirst}
            sample={isSample}
            type="Edit"
          />
        ) : (
          <WizardSkeleton />
        ))}
      <ShareStepForm trip={trip} usertrip={usertrip} isFirst={isFirst} />
    </>
  );
};
export default React.memo(SettingsStep);

interface ShareStepFormProps {
  usertrip: UserTrip;
  trip: Trip;
  isFirst: boolean;
}

const ShareStepForm: React.FC<ShareStepFormProps> = ({ usertrip, trip, isFirst }) => {
  const formId = `4-${SHARE_STEP_USER_TRIP_QUERY_KEY}`;
  const queryClient = useQueryClient();

  const defaultValues = { id: trip.id, userTrips: [usertrip], collaboratorCanEdit: trip.collaboratorCanEdit };
  const formMethods = useForm<Trip>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  const { mutateAsync: updateTrip, isPending: isLoadingUpdate } = usePatchSplitTrip(
    trip.id ?? "",
    updateRelationMap[SHARE_STEP_TRIP_QUERY_KEY],
    queryClient,
    SHARE_STEP_TRIP_QUERY_KEY,
  );

  const { mutateAsync: updateUserTrip, isPending: isLoadingUserTripUpdate } = usePatchSplitUserTrip(
    usertrip.id ?? "",
    updateRelationMap[SHARE_STEP_USER_TRIP_QUERY_KEY],
    queryClient,
    SHARE_STEP_USER_TRIP_QUERY_KEY,
  );

  const handleSubmit = async () => {
    const tripValues = formMethods.getValues();

    const userTripValues = tripValues.userTrips?.at(-1)!;

    await new Promise<void>((resolve) => {
      void updateTrip({ id: tripValues.id, collaboratorCanEdit: tripValues.collaboratorCanEdit });

      const newData = {
        ...usertrip,
        brand: userTripValues.brand,
        max_travellers: userTripValues.max_travellers,
        booked_travellers: userTripValues.booked_travellers,
        group_booking: userTripValues.group_booking,
        show_directions: userTripValues.show_directions,
        show_pois: userTripValues.show_pois,
        show_pdf: userTripValues.show_pdf,
        show_documents: userTripValues.show_documents,
        poi_range: userTripValues.poi_range,
        status: userTripValues.status,
        book_link: userTripValues.book_link,
        chat_enabled: userTripValues.chat_enabled,
        daysAfterTripCancelled: userTripValues.daysAfterTripCancelled,
      };
      void updateUserTrip(newData);

      if (userTripValues.show_directions != usertrip.show_directions) {
        queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY] });
      }
      formMethods.reset({
        ...defaultValues,
        userTrips: [newData],
      });
      resolve();
    });
  };

  if (isLoadingUpdate || isLoadingUserTripUpdate) {
    return <ShareSkeleton />;
  }

  return (
    <TripForm
      triggerResetData={trip}
      defaultValues={defaultValues}
      formId={formId}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      flexGrow={0.15}
    >
      <Button
        type="submit"
        hidden
        className="form-submit-button"
        isLoading={isLoadingUpdate || isLoadingUserTripUpdate}
      >
        Save
      </Button>
      <TripSettings isFirst={isFirst} sample={trip.sample} />
    </TripForm>
  );
};

interface ShareLinkProps {
  tripId: string | undefined;
  url: string;
  dark?: boolean;
  modalText?: string;
  isLoading?: boolean;
  alreadyShared?: boolean;
}
const ShareLink: React.FC<ShareLinkProps> = ({
  tripId,
  url,
  modalText,
  dark = false,
  isLoading = false,
  alreadyShared = false,
}) => {
  const { hasCopied, onCopy } = useClipboard(modalText ?? url);
  const bg = hasCopied ? "green.400" : dark ? "#0e1e25" : "lightGray.200";
  const { onClose, isOpen, onOpen } = useDisclosure();

  const renderContent = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (alreadyShared) {
      return <div>Already shared</div>;
    }

    if (tripId) {
      return (
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="sm" fontWeight="500" isTruncated>
            {hasCopied ? `${modalText ? "Text" : "Link"} copied to your clipboard` : url}
          </Text>
          {!hasCopied && (
            <IconButton
              icon={<Icon as={FaRegCopy} boxSize={4} />}
              aria-label="Copy url to clipboard"
              variant="basic"
              colorScheme={dark ? "white" : "gray"}
              size="xs"
            />
          )}
        </Flex>
      );
    }

    return <Text fontSize="sm">First save the changes before sharing!</Text>;
  };

  return (
    <>
      <Flex
        p={3}
        h="45px"
        w="100%"
        bg={bg}
        borderRadius="md"
        color={dark || hasCopied ? "white" : "gray.500"}
        mt="auto"
        flexDir="column"
        justifyContent="center"
        onClick={modalText ? onOpen : onCopy}
        _hover={{ cursor: "pointer" }}
      >
        {renderContent()}
      </Flex>
      <CustomModal
        title="Copy trip invitation"
        onClose={onClose}
        isOpen={isOpen}
        size="lg"
        footer={
          <>
            <Button
              onClick={() => {
                onCopy();
                onClose();
              }}
              colorScheme="brand"
              mr={2}
            >
              Copy invitation
            </Button>
            <Button onClick={onClose}>Close</Button>
          </>
        }
      >
        <Code whiteSpace="pre-line" p={3}>
          {modalText}
        </Code>
      </CustomModal>
    </>
  );
};
