import {
  Box,
  Flex,
  Icon,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { ImageSite, Image as ImageType, IsImage, TLibraryImage, TRIP_STATUS } from "@lato/common";
import React from "react";
import { prettyPrintDuration } from "../../../utils/date";
import { FaBriefcase, FaHashtag, FaCalendarAlt } from "react-icons/fa";
import { TiGroup } from "react-icons/ti";
import { displayBookedOptionBubble } from "./WizardTopBar";
import clsx from "clsx";
import { tripStatusColorMap } from "../../tripList/TripsList";

interface WizardTitleNewLayoutProps {
  title: string;
  client_name: string;
  refvalue?: string;
  status?: TRIP_STATUS;
  hidden?: boolean;
  first: boolean;
  nrOfNights: number;
  start_date?: string;
  end_date?: string;
  brand?: string;
  tripdayImage?: ImageType | TLibraryImage | undefined;
  tripdayBackgroundUrl?: string;
  flagImageUrl?: string;
  isLoadingTranslations?: boolean;
}
export const WizardTitleNewLayout: React.FC<WizardTitleNewLayoutProps> = ({
  title,
  client_name,
  status,
  first,
  refvalue,
  start_date,
  end_date,
  nrOfNights,
  brand,
  tripdayImage,
  tripdayBackgroundUrl,
  flagImageUrl,
  hidden = false,
  isLoadingTranslations,
}) => {
  return (
    <div>
      {/* <AspectRatio
              className="w-full rounded-lg overflow-hidden"
              h={"12em"}
              w={"12em"}
              bgColor={"white"}
              // bgImage={
              //   IsImage(tripdayImage?.site ?? ImageSite.CUSTOMPICTURE)
              //     ? `linear-gradient(to top, rgba(0, 67, 84, 0.5), rgba(0, 67, 84, ${tripdayBackgroundUrl ? 0.5 : 0.8})), url('${tripdayBackgroundUrl}')`
              //     : ""
              // }
              bgSize="cover"
              bgRepeat={"no-repeat"}
              bgPos={"center center"}
            > */}
      <>
        {tripdayImage?.site &&
          !IsImage(tripdayImage?.site) &&
          (tripdayImage?.site === ImageSite.CUSTOMVIDEO ? (
            <iframe
              title={`preview-image`}
              style={{ height: "100%", width: "240%", left: "-70%" }}
              src={tripdayBackgroundUrl}
            ></iframe>
          ) : (
            <video key={tripdayBackgroundUrl} autoPlay muted loop>
              <source src={tripdayBackgroundUrl} type="video/mp4" />
            </video>
          ))}
        <Flex className="w-full text-sm block text-black justify-center">
          <div>
            {isLoadingTranslations ? (
              <Skeleton height={"20px"} />
            ) : (
              <Popover trigger="hover" placement="bottom-end">
                <PopoverTrigger>
                  <Flex className="text-md font-bold gap-2 w-full text-center">
                    {/* {flagImageUrl && (
                      <Image
                        height="12px"
                        width="18px"
                        borderRadius={2}
                        shadow={"0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)"}
                        src={flagImageUrl}
                        className="self-center"
                      />
                    )} */}
                    {title}
                  </Flex>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent
                    bg="white"
                    borderColor="gray.200"
                    boxShadow="xl"
                    zIndex={9000}
                    borderRadius="md"
                    overflow="hidden"
                    width="16rem"
                  >
                    <PopoverArrow bg="white" />
                    <Box p={4} className="space-y-2">
                      {brand && (
                        <Flex alignItems="center" gap={4}>
                          <Icon as={FaBriefcase} w={5} h={5} p={1} color="gray.500" bgColor="gray.100" rounded="full" />
                          <Text fontSize="sm" fontWeight={500} color="gray.800" isTruncated>
                            {brand}
                          </Text>
                        </Flex>
                      )}
                      {client_name && (
                        <Flex alignItems="center" gap={4}>
                          <Icon as={TiGroup} w={5} h={5} p={1} color="gray.500" bgColor="gray.100" rounded="full" />
                          <Text fontSize="sm" fontWeight={500} color="gray.800" isTruncated>
                            {client_name}
                          </Text>
                        </Flex>
                      )}
                      {refvalue && (
                        <Flex alignItems="center" gap={4}>
                          <Icon as={FaHashtag} w={5} h={5} p={1} color="gray.500" bgColor="gray.100" rounded="full" />
                          <Text fontSize="sm" fontWeight={500} color="gray.800" isTruncated>
                            {refvalue}
                          </Text>
                        </Flex>
                      )}
                      {start_date && nrOfNights != null && (
                        <Flex alignItems="center" gap={4} fontSize="xs">
                          <Icon
                            as={FaCalendarAlt}
                            w={5}
                            h={5}
                            p={1}
                            color="gray.500"
                            bgColor="gray.100"
                            rounded="full"
                          />
                          <Text fontSize="sm" fontWeight={500} color="gray.800" isTruncated>
                            {prettyPrintDuration(new Date(start_date), new Date(end_date), "en", "short")}: {nrOfNights}{" "}
                            {nrOfNights === 1 ? "night" : "nights"}
                          </Text>
                        </Flex>
                      )}
                      {status && (
                        <Flex alignItems="center" gap={4} pt={2}>
                          <div
                            className={clsx(`bg-${tripStatusColorMap[status as TRIP_STATUS]}-300`, "p-1 rounded-full")}
                          />
                          <Text fontSize="xs" fontWeight={400} color="gray.500" isTruncated>
                            {status}
                          </Text>
                        </Flex>
                      )}
                    </Box>
                  </PopoverContent>
                </Portal>
              </Popover>
            )}
          </div>
        </Flex>
      </>
      {/* </AspectRatio> */}
    </div>
  );
};
