import { Select, TabPanel } from "@chakra-ui/react";
import { Country, Document } from "@lato/common";
import React from "react";
import TabModal from "../../components/layout/TabModal";
import AddWebsiteDocument from "./AddWebsiteDocument";
import DocumentDropzone from "./DocumentDropzone";

interface DocumentUploadModalProps {
  disclosure: any;
  disableLinks?: boolean;
  addDocument: (doc: Partial<Document>, country?: string) => void;
  countries?: Country[];
}

const DocumentUploadModal: React.FC<DocumentUploadModalProps> = ({
  addDocument,
  disclosure,
  disableLinks = false,
  countries = [],
}) => {
  const [selectedCountry, setSelectedCountry] = React.useState<string>();

  const countrySelector = (
    <Select placeholder="All trips" mb={2} onChange={(e) => setSelectedCountry(e.target.value)}>
      {countries.map((country) => (
        <option key={`doc-upload-country-${country.iso}`} value={country.iso}>
          {country.name}
        </option>
      ))}
    </Select>
  );

  return (
    <TabModal
      size="xl"
      title="Upload a document"
      tabs={disableLinks ? ["Upload New Document"] : ["Upload New Document", "Add Website URL"]}
      disclosure={disclosure}
    >
      <TabPanel h={countries.length > 0 ? "45vh" : "40vh"}>
        {countries.length > 0 && countrySelector}
        <DocumentDropzone addDocument={(e) => addDocument(e, selectedCountry)} closeModal={disclosure.onClose} />
      </TabPanel>
      {!disableLinks && (
        <TabPanel h={countries.length > 0 ? "45vh" : "40vh"}>
          {countries.length > 0 && countrySelector}
          <AddWebsiteDocument addDocument={(e) => addDocument(e, selectedCountry)} closeModal={disclosure.onClose} />
        </TabPanel>
      )}
    </TabModal>
  );
};
export default React.memo(DocumentUploadModal);
