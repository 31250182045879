import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Skeleton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CONTACTTYPE, Contact, LoyaltyProgramContactRelation, TRIP_STATUS, Task, User, UserTrip } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader } from "lucide-react";
import React from "react";
import { useForm, useFormContext } from "react-hook-form";
import { BiAt } from "react-icons/bi";
import { BsInfo, BsPersonFill, BsTicketPerforated } from "react-icons/bs";
import { FaBuilding as Building } from "react-icons/fa";
import { HiPhone as Phone } from "react-icons/hi";
import { IoLanguageOutline as Language, IoPaperPlaneSharp as PaperPlane } from "react-icons/io5";
import { MdLuggage as Luggage, MdModeEdit as ModeEdit } from "react-icons/md";
import { PiGlobeBold as Network } from "react-icons/pi";
import { TbExternalLink } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { CellProps, Column, Row } from "react-table";
import ContactAPI from "../../api/contacts.api";
import { FilesAPI } from "../../api/files.api";
import { useMeContext } from "../../stores/me-context";
import BackToOverviewButton from "../../pages/navigation/BackToOverview";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import { ME_QUERY_KEY, TASKS_ITEMS_CONTACT_KEY } from "../../utils/constants";
import {
  useContact,
  useContactNotes,
  useContactTasks,
  useContactTrips,
} from "../../utils/query-helpers/reactQueryHooks";
import { uploadToS3 } from "../../utils/s3Upload";
import { submitDocumentsArray } from "../../utils/submitDocumentsArray";
import { handleSubmission } from "../../utils/toErrorMap";
import useUnsavedChangesPrompt from "../../utils/useUnsavedChangesPrompt";
import { contactValidationSchema } from "../../validation/validationSchemas";
import { CollapsableTable } from "../../components/CRUD/CollapsableTable";
import MultiSelectColumnFilter from "../../components/CRUD/MultiSelectColumnFilter";
import StartDateFilter from "../../components/CRUD/StartDateFilter";
import { ContactSkeleton } from "../../components/FullScreenSpinner";
import Documents from "../documents/Documents";
import ImageUploader from "../../components/fileUpload/ImageUploader";
import Form from "../../components/form/Form";
import ErrorCardView from "../../components/layout/ErrorCardView";
import Section from "../../components/layout/Section";
import { NotesWrapper } from "../notes/Notes";
import { TasksTable } from "../tasks/TasksTable";
import CollapsibleSection from "../../components/CollapsableSection";
import { EditContactModal } from "./EditContact";
import {
  trip_user_cell,
  continent_column,
  country_column,
  ref_column,
  title_column,
  client_name_column,
  tripStatusColorMap,
} from "../tripList/TripsList";

interface ContactProps {}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const calculateAge = (date_of_birth: Date, reference_date = new Date()) => {
  const timeDiff = Math.abs(reference_date.getTime() - new Date(date_of_birth).getTime());
  const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  return age;
};

export const ContactInfo: React.FC<ContactProps> = () => {
  return (
    <>
      <BackToOverviewButton route={"/contacts"} overviewName={"contacts"} />
      <ContactDetail />
    </>
  );
};

const start_date_column: Column<UserTrip> = {
  accessor: (row) => row.trip!.start_date,
  Header: "Start Date",
  id: "start_date",
  chakraWidth: "60px",
  Filter: (props) => {
    return <StartDateFilter {...props} />;
  },
  disableFilters: false,
};

const nrofdays_column: Column<UserTrip> = {
  // Currently does not work because the tripdays is not joined in the usertrips.service getTrips()
  accessor: (row) => row.trip?.nrOfDays!,
  Header: "days",
  // isNumeric: true,
  minWidth: 60,
  chakraMaxWidth: "60px",
  width: 60,
  chakraWidth: "60px",
  Filter: (props) => {
    return <MultiSelectColumnFilter {...props} createOptions={createDayOptions} />;
  },
  // https://github.com/tannerlinsley/react-table/pull/2329
  // filter: filterDayOptions as any,
  // disableFilters: false,
  disableFilters: true,
  // Disabled because of the issues in the backend due to TypeORM
  disableSortBy: true,
};

export default ContactInfo;

const ContactDetail: React.FC<ContactProps> = () => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const toast = useToast();
  const me = useMeContext();

  const { data: contact, isLoading: isLoadingContact, error } = useContact(id!);
  const [loyaltyProgramsOnly, setLoyaltyProgramsOnly] = React.useState(false);

  const modalDisclosure = useDisclosure();
  const { onOpen } = modalDisclosure;

  const { mutateAsync: updateContact, isPending: isLoadingSave } = useMutation({
    mutationFn: (contactDTO: Contact) => ContactAPI.patch(contact.id!, contactDTO),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get-contact"],
      });
      // queryClient.setQueryData(["get-contact"]);
    },
  });

  const formMethods = useForm<Contact>({
    defaultValues: {
      ...contact,
    },
    resolver: yupResolver(contactValidationSchema),
  });
  React.useEffect(() => {
    formMethods.reset({ ...contact });
  }, [contact, formMethods]);

  const onSubmit = async (formValues: Contact) => {
    const contactDTO = {
      ...contact,
      notes: formValues.notes,
      documents: formValues.documents ?? [],
    };
    await handleSubmission({
      successMessage: "updated contact",
      failMessage: "updating contact",
      apiCall: updateContact(contactDTO),
      toast,
      setError: formMethods.setError,
    });
  };

  const handleLoyaltyDelete = async (id: string) => {
    const contactDTO = {
      ...contact,
      // remove the loyalty program by using the id
      loyaltyProgramRelations: contact.loyaltyProgramRelations.filter(
        (el: LoyaltyProgramContactRelation) => el.id !== id,
      ),
    };
    // update the loyaltyprograms
    await handleSubmission({
      successMessage: "updated contact",
      failMessage: "updating contact",
      apiCall: updateContact(contactDTO),
      toast,
      setError: formMethods.setError,
    });
  };

  const handleDocEdit = async (index: number, doc: any) => {
    console.log("pre", contact.documents);
    if (index !== contact.documents.length) {
      contact.documents[index].displayName = doc.displayName;
      contact.documents[index].type = doc.type;
    } else {
      contact.documents.push({ ...doc, ord: contact.documents.length });
      await submitDocumentsArray(contact.documents, `contacts/${contact.id}`);
    }
    contact.documents = contact.documents.map((doc, i) => ({
      ...doc,
      ord: i,
    }));
    console.log("post", contact.documents);
    onSubmit({ ...contact });
  };

  const handleDocRemove = (index: number) => {
    onSubmit({
      ...contact,
      documents: contact.documents.filter((_, i) => i !== index),
    });
  };

  const loyalty_program_columns: Column<LoyaltyProgramContactRelation>[] = React.useMemo(
    () => [
      {
        id: "name",
        Header: "Loyalty program",
        accessor: (row) => row.loyaltyProgram.name,
      },
      { id: "type", Header: "Type", accessor: (row) => row.loyaltyProgram.type },
      { id: "frequent_flyer_number", Header: "Loyalty program number", accessor: (row) => row.frequent_flyer_number },
      {
        id: "delete_program",
        header: () => null,
        Cell: (table) => {
          return (
            <Tooltip label="Remove" aria-label="remove-record-tooltip">
              <IconButton
                colorScheme="red"
                aria-label="remove-record"
                icon={<Icon as={DeleteIcon} boxSize={"18px"} />}
                w="fit-content"
                variant="ghost"
                onClick={() => handleLoyaltyDelete(table.cell.row.original.id)}
              />
            </Tooltip>
          );
        },
      },
    ],
    [contact?.loyaltyProgramRelations],
  );

  if (isLoadingContact) return <ContactSkeleton />;
  if (!contact || error) return <ErrorCardView title="Could not load contact" />;

  return (
    <>
      <Box mx={{ base: "0em", md: "10em" }} my={10} position="relative">
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <Flex
            flexDir={["column", "column", "column", "row", "row"]}
            mb={{ base: "0", md: "2.5em" }}
            gap={"2em"}
            mt={"4em"}
          >
            <ContactDetailCard onOpen={onOpen} contact={contact}></ContactDetailCard>
            <ContactNotes userName={me?.name} updateContact={updateContact} me={me} />
          </Flex>

          <ContactTasks contact={contact} />
          <Section noDivider py={4} mt={7} mb={7}>
            {
              <Documents
                handleEdit={handleDocEdit}
                handleRemove={handleDocRemove}
                fieldArrayName={"documents"}
                inForm={true}
                disableLinks={true}
                useBigHeading={true}
              />
            }
          </Section>
          <ContactTrips contactId={id!} />

          <CollapsableTable
            displayName={"Loyalty Programs"}
            icon={BsTicketPerforated}
            data={contact?.loyaltyProgramRelations ?? []}
            disableSorting
            disableSearch
            loadingData={isLoadingContact}
            columns={loyalty_program_columns}
            defaultClosed
            setFilterValue={() => {}}
            mt={7}
            addRow={(e) => {
              setLoyaltyProgramsOnly(true);
              modalDisclosure.onOpen();
            }}
          ></CollapsableTable>
        </Form>
      </Box>
      <EditContactModal
        contactDetail={contact}
        modalDisclosure={modalDisclosure}
        customOnClose={() => setLoyaltyProgramsOnly(false)}
        loyaltyProgramsOnly={loyaltyProgramsOnly}
      />
    </>
  );
};

interface ContactTripsProps {
  contactId: string;
}

const ContactTrips: React.FC<ContactTripsProps> = ({ contactId }) => {
  const [filterValue, setFilterValue] = React.useState<any>();
  const navigate = useNavigate();
  const { resetStore } = useTripFormStore();
  const me = useMeContext();

  const { data: trips, isLoading: isLoadingTrips, error: errorTrips } = useContactTrips(contactId);

  const trip_columns: Column<UserTrip>[] = React.useMemo(
    () => [
      {
        id: "user",
        // Use the user id as accessor such that users with exactly the same name are seen as different in the filter.
        accessor: (row: any) => row.user!.id!,
        chakraWidth: "80px",
        Cell: ({ row }: CellProps<UserTrip>) => {
          return trip_user_cell(row);
        },
      },
      continent_column,
      country_column,
      start_date_column,
      nrofdays_column,
      ref_column,
      title_column,
      client_name_column,
      {
        accessor: "status",
        id: "status",
        Header: "Status",
        chakraWidth: "70px",
        isNumeric: true,
        Cell: ({ row, value }: CellProps<UserTrip>) => (
          <Badge
            as={"button"}
            colorScheme={tripStatusColorMap[value as TRIP_STATUS]}
            _hover={{
              opacity: 0.8,
            }}
          >
            {value}
          </Badge>
        ),
      },
    ],
    [],
  );

  const filteredTrips = trips?.filter((usertrip: UserTrip) => {
    if (usertrip.user.companyId === me.companyId) {
      if (filterValue) {
        if (usertrip.trip?.titles.some((title) => title.content.toLowerCase().includes(filterValue.toLowerCase()))) {
          return true;
        }
        if (usertrip.trip?.client_name.toLowerCase().includes(filterValue.toLowerCase())) {
          return true;
        }
        return false;
      }
      return true;
    }
  });

  const onClickRow = React.useCallback(
    (e: any, tripId: string) => {
      e.stopPropagation();
      resetStore();
      // Edit the trip
      navigate(`/trips/${tripId}`);
    },
    [navigate],
  );

  const rowProps = ({ row }: { row: Row<any> }) => ({
    onClick: (e: any) => onClickRow(e, row.original.id),
    _hover: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  });

  if (isLoadingTrips) return <Skeleton h="200px" w="100%" />;
  if (errorTrips || !trips) return <ErrorCardView title="Could not load trips" />;

  return (
    <CollapsableTable
      displayName={"Trips"}
      icon={Luggage}
      data={trips ? filteredTrips : []}
      loadingData={isLoadingTrips}
      disableSorting={false}
      columns={trip_columns}
      getRowProps={rowProps}
      setFilterValue={setFilterValue}
      defaultClosed
    ></CollapsableTable>
  );
};

interface ContactTaskProps {
  contact: Contact;
}

const ContactTasks: React.FC<ContactTaskProps> = ({ contact }) => {
  const { data: tasks, isLoading: isLoadingTasks, isFetching: isFetchingTasks, refetch } = useContactTasks(contact.id!);
  const editDisclosure = useDisclosure();

  const handleSubmit = async () => {
    refetch();
  };

  if (isLoadingTasks || !tasks) return <Loader />;

  return (
    <>
      <Section noDivider py={4} mt={7} mb={7}>
        <CollapsibleSection
          title="Tasks"
          badgeCount={tasks.length}
          onAdd={(e) => {
            editDisclosure.onOpen();
          }}
        >
          <TasksTable
            totalCount={tasks?.length}
            fetchingTasks={isFetchingTasks}
            loadingTasks={isLoadingTasks}
            refetch={refetch}
            updateTask={handleSubmit}
            tasks={tasks}
            contact={contact}
            isInTrip={false}
            listName="Tasks"
            queryKey={TASKS_ITEMS_CONTACT_KEY}
            disclosure={editDisclosure}
            showOpenContactOption={false}
            isInCollapsableView
          />
        </CollapsibleSection>
      </Section>
    </>
  );
};

interface ContactNotesProps {
  userName: string | undefined;
  updateContact: any;
  me: User;
}

const ContactNotes: React.FC<ContactNotesProps> = ({ userName, updateContact, me }) => {
  const { id: contactId } = useParams<{ id: string }>();
  const { getValues, formState } = useFormContext<Contact>();

  useUnsavedChangesPrompt(formState.isDirty);

  const { data: notes, isLoading: isLoadingNotes } = useContactNotes(contactId!);

  return (
    <Box width={["100%", "100%", "100%", "50%", "50%"]}>
      <Heading mb={1}>
        {<Icon as={BsInfo} mb={"0.3em"} boxSize={7} mx={"0.3em"} />}
        Notes
      </Heading>
      {isLoadingNotes ? <Loader /> : <NotesWrapper parentObject={getValues()} topButtonPos={"-3.4em"} notes={notes} />}
      {/*<Section noDivider BsinForm position={"relative"} py={5}>
          <IconButton
            height={"2em"}
            top={"10px"}
            zIndex={10}
            position={"absolute"}
            colorScheme={"brand"}
            icon={<PersonPlusFill size={"16px"} />}
            aria-label="mark"
            onClick={mark}
          />
          <Button
            height={"2em"}
            colorScheme={"brand"}
            position="absolute"
            right={"2.2em"}
            top={"10px"}
            onClick={handleNotesChange}
            zIndex={10}
            isLoading={isLoadingSave}
          >
            Save
          </Button>
          <RHFRichTextEditor
            name={"notes"}
            showLoadText={false}
            classNameFormControl={"full-height-input"}
            mt={"1.8em"}
            inputHeight={"95%"}
            id={"richnotes"}
          />
    </Section>*/}
    </Box>
  );
};

interface ContactDetailCardProps {
  contact: Contact;
  onOpen?: any;
}

export const ContactDetailCard: React.FC<ContactDetailCardProps> = ({ contact, onOpen }) => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (s3Key: string) => ContactAPI.changeAvatar(s3Key, contact.id!),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: ["get-contact"],
      });
    },
  });
  const submitAvatar = async (cropper: Cropper) => {
    //TODO: instead of isMutating, create a loading variable wrapping the following lines such that the loading displays from the beginning
    // Get a presigned S3 url
    cropper.getCroppedCanvas().toBlob(async function (blob) {
      if (blob) {
        const fileType = "image/jpeg";
        const {
          data: { signedRequest, s3key },
        } = await FilesAPI.s3Sign({
          filename: contact.id!,
          filetype: fileType,
          folder: "contacts/",
        });
        const file = new File([blob], contact.id!, { type: fileType });
        await uploadToS3(file, fileType, signedRequest);
        await mutateAsync(s3key);
      }
    }, "image/jpeg");
  };
  return (
    <Box width={["100%", "100%", "100%", "50%", "50%"]}>
      <Heading mb={1} id="idforContactStepProduktly">
        {
          <Icon
            as={contact.contact_type === CONTACTTYPE.PERSON ? BsPersonFill : Building}
            mb={"0.3em"}
            boxSize={7}
            mx={"0.6em"}
          />
        }
        {(contact.contact_type === CONTACTTYPE.PERSON ? "Personal" : "Company") + " details"}
      </Heading>
      <Section noDivider height={"87%"}>
        <ContactBarrier contact={contact} onOpen={onOpen} submitAvatar={submitAvatar} editable />
        <Flex flexDir={"column"} padding={"1.5em"} gap={"1em"}>
          {contact.spokenLanguage && (
            <Flex alignItems={"center"} gap={"2.6em"}>
              <Icon as={Language}></Icon>
              <Text fontSize={"14px"}>{contact.spokenLanguage.name}</Text>
            </Flex>
          )}

          {contact.primaryEmail && (
            <Flex alignItems={"center"} gap={"2.6em"}>
              <Icon as={BiAt} alignSelf={"flex-start"} mt={"0.3em"}></Icon>
              <Box>
                {contact.primaryEmail.split(";").map((mail) => (
                  <Link
                    href={`mailto:${mail}`}
                    _hover={{
                      color: "rgba(0, 0, 255, 0.689)",
                      textDecoration: "underline",
                    }}
                    className={"external-link"}
                    display="flex"
                    key={`contact-mail-${mail}`}
                  >
                    <Text fontSize={"14px"}>
                      {mail}
                      <TbExternalLink
                        style={{ marginBottom: "0.1em", marginLeft: "0.3em" }}
                        className={"external-link-icon"}
                      />
                    </Text>
                  </Link>
                ))}
              </Box>
            </Flex>
          )}
          {contact.phoneNumber && (
            <Flex alignItems={"center"} gap={"2.6em"}>
              <Icon as={Phone} alignSelf={"flex-start"} mt={"0.3em"}></Icon>
              <Box>
                {contact.phoneNumber.split(";").map((phone) => (
                  <Link
                    href={`tel:${phone}`}
                    _hover={{
                      color: "rgba(0, 0, 255, 0.689)",
                      textDecoration: "underline",
                    }}
                    className={"external-link"}
                    key={`contact-phone-${phone}`}
                  >
                    <Text fontSize={"14px"}>
                      {phone}
                      <TbExternalLink
                        style={{ marginBottom: "0.1em", marginLeft: "0.3em" }}
                        className={"external-link-icon"}
                      />
                    </Text>
                  </Link>
                ))}
              </Box>
            </Flex>
          )}
          {contact.website && (
            <Flex alignItems={"center"} gap={"2.6em"}>
              <Icon as={Network}></Icon>
              <Link
                href={contact.website.startsWith("https://") ? contact.website : "https://" + contact.website}
                target={"_blank"}
                _hover={{
                  color: "rgba(0, 0, 255, 0.689)",
                  textDecoration: "underline",
                }}
                className={"external-link"}
              >
                <Text fontSize={"14px"}>
                  {contact.website}
                  <TbExternalLink
                    style={{ marginBottom: "0.1em", marginLeft: "0.3em" }}
                    className={"external-link-icon"}
                  />
                </Text>
              </Link>
            </Flex>
          )}
          {Object.values(contact.address).some((x) => x !== "" && x !== null) && (
            <Flex alignItems={"flex-start"} gap={"2.6em"}>
              <Icon as={PaperPlane} mt={"0.3em"}></Icon>
              <Link
                fontSize={"14px"}
                target={"_blank"}
                cursor={"pointer"}
                href={`http://maps.google.com/?q=${contact.address.country ? contact.address.country.name + ";" : ""}${
                  contact.address.city ? contact.address.city + ";" : ""
                }${contact.address.streetAddress ? contact.address.streetAddress + ";" : ""}`}
                _hover={{
                  color: "rgba(0, 0, 255, 0.689)",
                  textDecoration: "underline",
                }}
                className={"external-link"}
                display="flex"
              >
                <Box>
                  {contact.address.streetAddress && (
                    <Text mt={"-0.2em"}>
                      {capitalizeFirstLetter(contact.address.streetAddress)}{" "}
                      {contact.address.aptnumber && ` | ${contact.address.aptnumber}`}
                    </Text>
                  )}
                  {(contact.address.zipCode || contact.address.city || contact.address.state) && (
                    <Text mt={"-0.2em"}>
                      {contact.address.zipCode} {capitalizeFirstLetter(contact.address.city)}
                      {contact.address.state && `, ${capitalizeFirstLetter(contact.address.state)}`}
                    </Text>
                  )}
                  {contact.address.country && <Text>{contact.address.country.name}</Text>}
                </Box>
                <TbExternalLink
                  style={{
                    marginBottom: "-0.3em",
                    marginLeft: "0.3em",
                    alignSelf: "center",
                  }}
                  className={"external-link-icon"}
                />
              </Link>
            </Flex>
          )}
        </Flex>
      </Section>
    </Box>
  );
};

export const ConditionalWrapper = ({ condition, wrapper, children }: any) => (condition ? wrapper(children) : children);

interface ContactBarrierProps extends ContactDetailCardProps {
  editable?: boolean;
  submitAvatar?: any;
  avatarSize?: string;
  showMail?: boolean;
}

export const ContactBarrier: React.FC<ContactBarrierProps> = ({
  contact,
  onOpen,
  editable = false,
  submitAvatar,
  avatarSize = "lg",
  showMail = false,
}) => {
  return (
    <Flex gap={"1em"}>
      <ConditionalWrapper
        condition={editable}
        wrapper={(children: any) => (
          <ImageUploader submitPicture={submitAvatar} type="profile" dropzone={false}>
            {children}
          </ImageUploader>
        )}
      >
        <Avatar
          size={avatarSize}
          name={`${contact.last_name ? `${contact.last_name} ` : ""}${contact.first_name}`}
          title={`${contact.last_name ? `${contact.last_name} ` : ""}${contact.first_name}`}
          src={contact.avatarUrl}
          background={"#9f9f9f"}
          color={"white"}
        >
          {editable && (
            <AvatarBadge borderColor="transparent" right={1} bottom={0} borderRadius={0}>
              <Button
                size="xs"
                bg="white"
                border="1px"
                fontWeight="600"
                width={"0.5em"}
                borderColor="gray.300"
                borderRadius={"50%"}
              >
                <EditIcon boxSize="12px" />
              </Button>
            </AvatarBadge>
          )}
        </Avatar>
      </ConditionalWrapper>
      <Box alignSelf={"center"}>
        <Flex gap={"1em"}>
          <Heading size={contact.contact_type === CONTACTTYPE.PERSON ? avatarSize : "xl"}>{`${
            contact.last_name && contact.last_name
          } ${contact.first_name && contact.first_name}`}</Heading>
          {contact.nick_name && (
            <Heading size={avatarSize} color={"gray"}>
              {`(${contact.nick_name})`}
            </Heading>
          )}
        </Flex>
        {contact.contact_type === CONTACTTYPE.PERSON && !showMail && (
          <Text mt={"0.3em"} fontSize={avatarSize === "lg" ? "14px" : "10px"}>
            {contact.date_of_birth &&
              `${contact.date_of_birth && contact.date_of_birth} ${
                contact.date_of_birth && `• ${calculateAge(contact.date_of_birth)} years •`
              }`}{" "}
            {contact.gender && `${contact.gender.split(" ")[0]}`}
          </Text>
        )}

        {showMail && (
          <Text mt={"0.3em"} fontSize={avatarSize === "lg" ? "14px" : "10px"}>
            {contact.primaryEmail}
          </Text>
        )}
      </Box>
      {editable && <Icon mt={"0.5em"} ml={"auto"} as={ModeEdit} boxSize={6} cursor={"pointer"} onClick={onOpen} />}
    </Flex>
  );
};
