import { Trip, TripDay, tripdayTitleLocation } from "@lato/common";
import React from "react";
import { useTripLanguage } from "../../../../stores/trip-language-context";
import { DAY_BY_DAY_STEP_TRIPDAY_TITLE_QUERY_KEY } from "../../../../utils/constants";
import {
  createTranslationRelation,
  useSplitTripday,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";

export const buildTripdayTitle = (trip: Trip, tripday: TripDay, realLanguageIndex: number) => {
  // Tripday might be null because of refetch
  if (!tripday.titles || tripday.titles.length === 0) return " ";

  const savedTripdayTitle = tripday.titles[realLanguageIndex]?.content;

  const fallbackTripdayTitle = tripdayTitleLocation(
    trip,
    trip.tripdays.findIndex((td) => td.id === tripday.id),
  );
  return savedTripdayTitle || fallbackTripdayTitle || " ";
};

interface TripdayPreviewTitleProps {
  tripdayId: string;
  trip: Trip;
}

const TripdayPreviewTitle: React.FC<TripdayPreviewTitleProps> = ({ trip, tripdayId }) => {
  const { realLanguageIndex } = useTripLanguage();
  const relations = [createTranslationRelation("titles")];

  const { data: tripday } = useSplitTripday(tripdayId ?? "", relations, DAY_BY_DAY_STEP_TRIPDAY_TITLE_QUERY_KEY);

  if (!tripday) return null;

  const tripdayTitle: string = buildTripdayTitle(trip, tripday, realLanguageIndex);

  return tripdayTitle;
};

export default React.memo(TripdayPreviewTitle);
