import { Room } from "@lato/common";

export function filterDuplicateIds(arr: Room[]) {
  // Create an object to store unique IDs
  // Create an object to store unique IDs
  const uniqueIds: { [id: string]: boolean } = {};

  // Filter out duplicate IDs or objects without an id
  const filteredArray = arr.filter((item) => {
    if (item.id === undefined) {
      return true; // Keep objects without an id
    }
    if (!uniqueIds[item.id]) {
      uniqueIds[item.id] = true;
      return true;
    }
    return false;
  });

  return filteredArray;
}
