import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import {
  Country,
  GoogleAddress,
  Hotel,
  ImageSite,
  POIType,
  QueryType,
  TranslationField,
  User,
  prettyLocation,
} from "@lato/common";
import React from "react";
import { FaStar } from "react-icons/fa";
import { LiaFontAwesomeFlag, LiaStar } from "react-icons/lia";
import { CellProps, Column } from "react-table";
import { getEmptyAccommodation } from ".";
import CountryAPI from "../../../api/countries.api";
import HotelsAPI from "../../../api/hotels.api";
import { useMeContext } from "../../../stores/me-context";
import { useTripsListStore } from "../../../stores/trip/tripslistStore";
import formatAddress from "../../../utils/formatAddress";
import { useLanguages, useParamsQueryHelper } from "../../../utils/query-helpers/reactQueryHooks";
import { CARDSIZES, CustomCard } from "../../CRUD/CardView";
import ContinentFilter from "../../CRUD/ContinentFilter";
import CountryFilter from "../../CRUD/CountryFilter";
import LanguageFilter from "../../CRUD/LanguageFilter";
import MemberFilter from "../../CRUD/MemberFilter";
import { FILTER_TYPE } from "../../CustomDownShift/CustomComboBox";
import { FilterSelect } from "../../CustomDownShift/FilterSelect";
import { ListSkeleton } from "../../FullScreenSpinner";
import { CardProps } from "../../TripsList";
import ErrorCardView from "../../layout/ErrorCardView";
import { IconMarker } from "../../map/IconMarker";
import { POIsToIcons } from "../../map/POIMarker";
import { LibraryItemType, MarkerMapItem } from "../../trips/edit/daybyday/library-items/LibraryItemModal";
import LibraryItemsTable from "../../trips/edit/daybyday/library-items/LibraryItemsTable";
import { stars } from "../../trips/edit/daybyday/library-items/hotel/StarRating";
import { getEmptyLocation } from "../activities";
import { TableWithDrawerProps } from "./../../CRUD/Resource";

interface AccommodationsTableProps extends Partial<TableWithDrawerProps<any>> {
  additionalColumns?: Column<Hotel>[];
  initialFilters?: any[];
  additionalQueryState?: object;
  users: User[];
}

const eventType: LibraryItemType = "accommodation";
// Subtext for under the title
const subText = "These accommodations can be seamlessly imported into your travel itinerary with just one click.";

const defaultColumns: Column<Hotel>[] = [
  {
    Header: "Accommodation name",
    accessor: "name",
    // chakraWidth: "30%",
    disableSortBy: false,
    chakraWidth: "200px",
    Cell: ({ value }) => {
      return (
        <Text w="30em" isTruncated title={value}>
          {value}
        </Text>
      );
    },
  },

  // {
  //   accessor: "type",
  //   Header: "Type",
  //   chakraWidth: "20%",
  // },
];

const AccommodationsTable: React.FC<AccommodationsTableProps> = ({ users, initialFilters, ...props }) => {
  const me = useMeContext();
  const { continentFilter, setContinentFilter } = useTripsListStore();
  const [memberFilter, setMemberFilter] = React.useState<string[] | undefined>(users.map((member) => member.id!));
  const [locationFilter, setLocationFilter] = React.useState();
  const selectedContinents = continentFilter !== "" ? continentFilter?.split(",") : [];

  const [ratingFilter, setRatingFilter] = React.useState(
    initialFilters && initialFilters.find((e) => e.id === "rating").value
      ? initialFilters.find((e) => e.id === "rating").value
      : [0, 5],
  );

  // Used to get a new accommodation object with the correct language and user, also used to set default values in HotelForm in map
  const getNewAccommodation = (coordinates?: number[], address?: GoogleAddress, locationName?: string) => {
    const newAccommodation = getEmptyAccommodation(me);
    newAccommodation.name = locationName ?? "New Hotel";
    newAccommodation.location = {
      name: locationName ?? "",
      coordinates: coordinates ?? [0, 0],
      address: address ?? getEmptyLocation().address,
    };
    return newAccommodation;
  };

  const { data: allLanguages, isLoading: isLoadingLanguages, error: errorLanguages } = useLanguages();

  const resetFilters = () => {
    setMemberFilter(users.map((member) => member.id!));
    setContinentFilter("");
    setRatingFilter([0, 5]);
    setLocationFilter(undefined);
  };

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: errorCountries,
  } = useParamsQueryHelper<Country[]>({
    queryKey: "countries-hotels",
    apiCall: CountryAPI.getAll,
    queryParams: {
      users: memberFilter ?? [],
      type: QueryType.ACCOMMODATION,
    } as any,
    options: {
      enabled: !!memberFilter,
    },
  });

  const columns = React.useMemo(
    () => [
      ...defaultColumns,
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ value }) => {
          const address = formatAddress(value?.address, true);
          return <Text title={address}>{address}</Text>;
        },
        // Due to issues in the backend.
        disableSortBy: true,
        // chakraWidth: "30%",
        Filter: (props: any) => {
          React.useEffect(() => {
            locationFilter === "" &&
              props.columns
                .find((column: any) => column.id === "location" || column.id === "coordinates")
                ?.setFilter(undefined);
          }, [locationFilter]);
          return (
            <FilterSelect
              type={FILTER_TYPE.LOCATION}
              onChange={(e) => {
                props.columns
                  .find((column: any) => column.id === "location" || column.id === "coordinates")
                  ?.setFilter(e);
              }}
              defaultSelected={locationFilter}
              icon={LiaFontAwesomeFlag}
              name="location"
              width={"100%"}
              borderRadius="sm"
              dropdown
              setFilterValue={setLocationFilter}
            ></FilterSelect>
          );
        },
        disableFilters: false,
        hideFiltersOnMapView: true,
      },
      {
        accessor: "users" as any,
        chakraWidth: "0px",
        Filter: (props: any) => <MemberFilter {...props} members={users} setMemberFilter={setMemberFilter} />,
        disableFilters: false,
        hide: true,
      },
      {
        accessor: "continent" as any,
        id: "continents",
        Header: "Continent",
        chakraWidth: "70px",
        Cell: ({ row, value }: CellProps<Hotel>) => <Box textAlign={"start"}>{value?.continent}</Box>,
        Filter: (props: any) => (
          <ContinentFilter
            {...props}
            continents={countries
              ?.flatMap((country) => country.continent)
              .filter((value, index, self) => self.indexOf(value) === index)}
          />
        ),
        disableFilters: false,
        hide: true,
        hideFiltersOnMapView: true,
      },
      {
        accessor: "country" as any,
        id: "countries",
        Header: "Country",
        chakraWidth: "70px",
        isNumeric: false,
        Cell: ({ row, value }: CellProps<Hotel>) => (
          <Box display="flex" justifyContent="center" marginRight={2} title={value?.name}>
            {value?.iso && value.iso}
          </Box>
        ),
        Filter: (props: any) => (
          <CountryFilter
            {...props}
            type={QueryType.ACCOMMODATION}
            countries={countries?.filter((country) =>
              selectedContinents?.length > 0 ? selectedContinents.includes(country.continent) : [],
            )}
          />
        ),
        disableFilters: false,
        disableSortBy: true,
        hideFiltersOnMapView: true,
      },
      {
        Header: "Languages",
        accessor: "discriptions",
        chakraWidth: "120px",
        id: "languages",
        Cell: ({ value, row, column }: CellProps<Hotel>) => {
          const languages = ((value as TranslationField[]) ?? [])
            .map((l) => l.language)
            .sort((a, b) => (a.name > b.name ? 1 : -1));

          console.log("languages", languages, value);
          return (
            <Text isTruncated maxW="6em" ml={1} title={languages.map((l) => l.name).join(", ")}>
              {languages.map((language) => language.flag).join(" ")}
            </Text>
          );
        },
        Filter: (props: any) => {
          return <LanguageFilter {...props} isLoadingLanguages={isLoadingLanguages} languages={allLanguages} />;
        },
        disableFilters: false,
        disableSortBy: true,
        hideFiltersOnMapView: true,
      },
      {
        accessor: "rating",
        id: "rating",
        Header: "Stars",
        chakraWidth: "70px",
        Cell: ({ row, value }: CellProps<Hotel>) => {
          if (value === 0) return <Text></Text>;
          return (
            <Flex ml={1} gap={"0.1em"}>
              <Text fontSize={"16px"}>{value / 2}</Text>
              <Icon as={FaStar} color={"#F2C519"} mt={"-0.2em"} />
            </Flex>
          );
        },
        Filter: (props: any) => (
          <FilterSelect
            options={5}
            icon={LiaStar}
            onChange={(e) => {
              props.columns.find((column: any) => column.id === "rating")?.setFilter(e);
            }}
            defaultSelected={props.columns.find((column: any) => column.id === "rating")?.filterValue}
            name="rating"
            type={FILTER_TYPE.RANGE}
            dropdown={true}
            height={"2em"}
          ></FilterSelect>
        ),
        disableFilters: false,
      },
    ],
    [users, countries, selectedContinents, isLoadingLanguages, allLanguages],
  );

  if (isLoadingCountries || isLoadingLanguages) return <ListSkeleton />;
  if (errorCountries || !countries) return <ErrorCardView title="Could not load countries" />;
  if (errorLanguages || !allLanguages) return <ErrorCardView title="Could not load languages" />;

  return (
    <LibraryItemsTable
      {...props}
      defaultColumns={columns}
      api={HotelsAPI}
      subText={subText}
      eventType={eventType}
      resetFilters={resetFilters}
      enableGridView={true}
      enableMapView={true}
      cardViewComponent={AccommodationCard}
      markerComponent={HotelMarker}
      getEmptyItem={getNewAccommodation}
      initialFilters={[
        { id: "users", value: [me.id] },
        { id: "rating", value: [0, 5] },
      ]}
      searchBarPlaceholder="Search by title, location, description..."
      additionalQueryState={{
        users: memberFilter,
        rating: ratingFilter,
      }}
      setLocationFilter={setLocationFilter}
    />
  );
};

// Handels making Markers for Hotels used in the map view, icon layout is the same as hotels in POI
const HotelMarker = (item: MarkerMapItem, index: number, onClick: any) => {
  const hotel = item as Hotel;
  return (
    <IconMarker
      key={`hotel-marker-${index}`}
      coordinates={hotel.location!.coordinates ?? [0, 0]}
      layout={POIsToIcons[POIType.HOTEL]}
      onClick={() => onClick(hotel)}
      name={hotel.name}
    />
  );
};

const AccommodationCard: React.FC<CardProps> = ({ row, key, size }) => {
  const accommodation = row.original as Hotel;
  const image =
    accommodation?.images && accommodation.images[0]
      ? accommodation.images[0]
      : {
          url: "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png",
          site: ImageSite.CUSTOMPICTURE,
        };
  const optionsCell = row.cells.filter((cell: any) => cell.column.id === "libraryItem-options")[0];
  const { mainAddress, subAddress } = accommodation.location
    ? prettyLocation(accommodation.location)
    : { mainAddress: "", subAddress: "" };

  const flagWidth: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "22px",
    [CARDSIZES.L]: "18px",
    [CARDSIZES.MD]: "16px",
    [CARDSIZES.S]: "14px",
  };

  return (
    <CustomCard image={image} key={key} size={size} optionsCell={optionsCell} title={accommodation.name ?? ""}>
      <Flex
        flexDir={"column"}
        position={"absolute"}
        left={size === CARDSIZES.S ? "1.2em" : "1.8em"}
        bottom={size === CARDSIZES.S ? "-2em" : "-3em"}
        w={`calc(90% - (4% * ${accommodation.rating < 5 ? accommodation.rating + 2 : accommodation.rating}))`}
      >
        {accommodation.country?.flagImage && (
          <Box mb={1}>
            <Image
              height={`calc(${flagWidth[size]} - 6px)`}
              width={flagWidth[size]}
              borderRadius={2}
              shadow={"0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)"}
              src={accommodation.country.flagImage}
            />
          </Box>
        )}

        <Box fontSize={"1em"}>{mainAddress}</Box>
        {subAddress && subAddress !== "" && (
          <Box fontSize={"12px"} fontWeight={400}>
            {subAddress}
          </Box>
        )}
        <Flex position="relative" ml={"auto"}>
          {[...Array(accommodation.rating)].map((_, i) =>
            stars({ value: accommodation.rating }, accommodation.rating, i, 1),
          )}
        </Flex>
      </Flex>
    </CustomCard>
  );
};

export default React.memo(AccommodationsTable);
