import { Button, ButtonProps, Link, Badge } from "@chakra-ui/react";
import clsx from "clsx";
import React from "react";
import { Link as ReactLink, useLocation, useNavigate } from "react-router-dom";
import { BadgeCount } from "../CRUD/Resource";

export interface SettingsNavItemProps extends ButtonProps {
  tabname: string;
  icon: any;
  onClick?: () => void;
  iconSelected?: any;
  locationIndex?: number;
  badge?: JSX.Element;
  path: string;
  subNavItems?: SubSideMenuNavItemProps[];
  isInTrip?: boolean;
  count?: number;
  isDisabled?: boolean;
}

export const SideMenuNavItem: React.FC<SettingsNavItemProps> = ({
  tabname,
  icon,
  iconSelected,
  badge,
  path,
  subNavItems = [],
  locationIndex,
  onClick,
  isInTrip,
  count,
  isDisabled,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathName = location.pathname;

  const isActive = pathName === path;

  return (
    <div className="w-full h-[100wh]">
      <Button
        {...props}
        onClick={(e) => {
          console.log("steppp");
          if (onClick) {
            onClick();
          }
          !isInTrip && navigate(`${path}`);
        }}
        state={{ some: "value" }}
        w="96%"
        justifyContent="left"
        variant="ghost"
        key={`sidemenu-nav-${tabname}`}
        leftIcon={isActive && iconSelected ? iconSelected : icon}
        borderRadius={"md"}
        color="gray.600"
        letterSpacing={"wide"}
        mx={1}
        isActive={isActive}
        _active={{
          backgroundColor: "brand.50",
          color: "brand.500",
          fontWeight: "bolder",
        }}
        isDisabled={isDisabled}
      >
        {/* <Button w="100%" justifyContent="left" variant="ghost" leftIcon={icon}> */}
        <span className="relative flex gap-2">
          {tabname}
          {count && count != 0 && <BadgeCount totalCount={count} height={"1.5em"} />}
          {badge}
        </span>
        {/* </Button> */}
      </Button>
      {subNavItems.map((subNav) => (
        <SubSideMenuNavItem {...subNav} key={`sidemenu-sub-nav-${subNav.reference}`} />
      ))}
    </div>
  );
};
export default SideMenuNavItem;

export interface SubSideMenuNavItemProps {
  name: string;
  reference: any;
  marginLeft?: string;
}

export const SubSideMenuNavItem: React.FC<SubSideMenuNavItemProps> = ({ name, reference }) => {
  return (
    <Button
      w="100%"
      justifyContent="left"
      variant="ghost"
      borderRadius={0}
      color="gray.600"
      pl={6}
      onClick={(e) => {
        document!.getElementById(reference)?.scrollIntoView({ block: "center", behavior: "smooth" });
      }}
    >
      <span className="relative">{name}</span>
    </Button>
  );
};
