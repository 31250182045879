import { Button, Skeleton } from "@chakra-ui/react";
import { TranslationField, Trip, TripDay, tripdayTitleLocation } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { FaRegEdit } from "react-icons/fa";
import RHFTranslationInput from "../../../components/input/RHFTranslationInput";
import TripForm from "../../../components/trips/TripForm";
import { useTripLanguage } from "../../../stores/trip-language-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import { DAY_BY_DAY_STEP_TRIPDAY_TITLE_QUERY_KEY } from "../../../utils/constants";
import {
  createTranslationRelation,
  usePatchSplitTripday,
  useSplitTripday,
} from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";

interface TripdayTitleWrapperProps {
  tripdayId: string;
  tripdayIndex: number;
  collaboratorCanEdit: boolean;
  isFirst: boolean;
  trip: Trip;
}

const TripdayTitleWrapper: React.FC<TripdayTitleWrapperProps> = ({
  tripdayId,
  tripdayIndex,
  collaboratorCanEdit,
  isFirst,
  trip,
}) => {
  const relations = [createTranslationRelation("titles")];

  const { isLoadingTranslations: isLoadingTranslationsFromStore } = useTripFormStore();

  const {
    data: tripday,
    isLoading: isLoadingTripday,
    isFetching: isFetchingTripday,
    isFetchedAfterMount: isFetchedAfterMountTrip,
  } = useSplitTripday(tripdayId ?? "", relations, DAY_BY_DAY_STEP_TRIPDAY_TITLE_QUERY_KEY);

  const isLoadingTrans = (!isFetchedAfterMountTrip && isFetchingTripday) || isLoadingTranslationsFromStore;

  if (isLoadingTripday || isFetchingTripday || !tripday || isLoadingTrans)
    return <Skeleton className="w-full rounded-lg overflow-hidden h-[30px]" />;

  return (
    <TripdayTitle
      tripdayIndex={tripdayIndex}
      tripday={tripday}
      collaboratorCanEdit={collaboratorCanEdit}
      isFirst={isFirst}
      trip={trip}
    />
  );
};

export default TripdayTitleWrapper;

interface TripdayTitleProps {
  tripdayIndex: number;
  tripday: TripDay;
  collaboratorCanEdit: boolean;
  isFirst: boolean;
  trip: Trip;
}

const TripdayTitle: React.FC<TripdayTitleProps> = ({ tripdayIndex, tripday, collaboratorCanEdit, isFirst, trip }) => {
  const relations = [createTranslationRelation("titles")];
  const defaultValues = { titles: tripday?.titles };

  const queryClient = useQueryClient();
  const { realLanguageIndex } = useTripLanguage();
  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: updateTripday, isPending: isUpdatingTripday } = usePatchSplitTripday(
    tripday?.id ?? "",
    { relation: "tripday", subRelations: relations },
    queryClient,
    DAY_BY_DAY_STEP_TRIPDAY_TITLE_QUERY_KEY,
    trip?.id,
  );

  const formMethods = useForm<{ titles: TranslationField[] }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  const title = useWatch({ name: `titles.${realLanguageIndex}.content`, control: formMethods.control });

  useEffect(() => {
    formMethods.reset(defaultValues);
  }, [tripday, realLanguageIndex]);

  const handleUpdateTitle = async () => {
    const formValues = formMethods.getValues();

    setIsOpen(false);

    await updateTripday({
      id: tripday?.id,
      titles: formValues.titles,
    });
  };

  if (isUpdatingTripday) return <Skeleton className="w-full rounded-lg overflow-hidden h-[30px]" />;

  return (
    <TripForm
      formId={`3-${tripdayIndex}-form-titles`}
      formMethods={formMethods}
      onSubmit={handleUpdateTitle}
      triggerResetData={tripday}
      defaultValues={defaultValues}
    >
      <Button type="submit" hidden className="form-submit-button" id="titles-save-button">
        Save
      </Button>
      <div className="w-[97%] flex justify-center mb-3">
        {isOpen ? (
          <RHFTranslationInput
            collaboratorCanEdit={collaboratorCanEdit}
            first={isFirst}
            name="titles.0.content"
            placeholder={tripdayTitleLocation(trip, tripdayIndex) || "Day title..."}
            layout={"inline"}
            fontSizeInline={"1.5rem"}
            textAlign={"center"}
            fontWeight={"bold"}
            border={"1px solid inherit"}
            autoFocus
            borderWidth="1px"
            borderRadius={"lg"}
            onBlur={() => {
              setIsOpen(false);
              formMethods.formState.isDirty && handleUpdateTitle();
            }}
            isInline
          />
        ) : (
          <>
            <button
              className="relative text-center text-2xl font-bold flex justify-center"
              onClick={() => {
                setIsOpen(true);
                // formMethods.setFocus("titles.0.content", { shouldSelect: true });
                // ref.current.focus();
              }}
            >
              {title === "" ? tripdayTitleLocation(trip, tripdayIndex) || "Day title..." : title}
              <FaRegEdit className="w-4 h-4 absolute -right-7 bottom-2 text-gray-500" />
            </button>
          </>
        )}
      </div>
    </TripForm>
  );
};
