// Key to which notifications stored in localstorage
export const LOCAL_STORAGE_NOTIFICATION_KEY = "notifications";

/**
 * Delete  userTripId notification from localstorage
 * @param userTripId
 */
export const messageRead = (userTripId: string) => {
  const itemJson = localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_KEY);
  const result = itemJson !== null ? JSON.parse(itemJson) : [];
  if (result?.length) {
    const index = result.findIndex((x: any) => x.usertripId === userTripId);
    if (index > -1) {
      // only splice array when item is found
      result.splice(index, 1); // 2nd parameter means remove one item only
      localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY, JSON.stringify(result));
    }
  }
};

/**
 * Return number of count for All Usertrips from localstorage
 * @returns
 */
export const getLocalStorageNotificationCount = () => {
  const result = getLocalStorageNotificationValue();
  const count = result.reduce(function (accumulator: number, curValue: any) {
    return accumulator + curValue.count;
  }, 0);
  return count;
};

/**
 * Return Notification Key Value stored in localstorage
 * @returns
 */
export const getLocalStorageNotificationValue = () => {
  const notificationJson = localStorage.getItem(LOCAL_STORAGE_NOTIFICATION_KEY);
  return notificationJson !== null ? JSON.parse(notificationJson) : [];
};
