export const differenceInDays = (start_date, end_date) => {
    const diffTime = new Date(end_date).getTime() - new Date(start_date).getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};
export const durationInDays = (start_date, end_date) => {
    return Math.abs(differenceInDays(start_date, end_date)) + 1;
};
export const omitLocalTimezone = (date, inverse = false) => {
    return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * (inverse ? -60000 : 60000));
};
export const calculateTripdayDateByDayIndex = (dayIndex, start_date, trip) => {
    if (dayIndex === 0)
        return start_date;
    if (!start_date)
        return null;
    const date = new Date(start_date);
    for (let i = 0; i < dayIndex; i++) {
        const tripday = trip.tripdays[i];
        date.setDate(date.getDate() + tripday.nrOfNights);
    }
    return new Date(omitLocalTimezone(date, true)).toDateString();
};
export const getEndDateFromTrip = (trip) => {
    // Calculate the end date of the trip by providing an index greater than the number of tripdays
    return calculateTripdayDateByDayIndex(trip.tripdays.length, trip.start_date, trip);
};
export const calculateTripdayDate = (daynumber, start_date) => {
    if (!start_date)
        return null;
    const date = new Date(start_date);
    date.setDate(date.getDate() + daynumber);
    return date.toDateString();
};
export const getDateByDayindex = (trip, dayNumber, dayIndex, time) => {
    const date = calculateTripdayDate(dayNumber, trip.start_date) ?? new Date(trip.start_date);
    return new Date(new Date(date).setDate(new Date(date).getDate() + dayIndex)).setHours(+(time?.split(":") ?? ["0"])[0], +(time?.split(":") ?? ["0", "0"])[1]);
};
export const getTripDayIndexByDate = (date, trip) => {
    let counter = 0;
    const dateWithoutTime = new Date(omitLocalTimezone(date));
    dateWithoutTime.setHours(0, 0, 0, 0);
    const departDateWithoutTime = new Date(trip.start_date);
    departDateWithoutTime.setHours(0, 0, 0, 0);
    departDateWithoutTime.setDate(departDateWithoutTime.getDate() + (+trip.tripdays[0].nrOfNights - 1));
    const endDate = new Date(trip.end_date);
    while (counter < trip.tripdays.length - 1 &&
        departDateWithoutTime.getTime() < dateWithoutTime.getTime() &&
        departDateWithoutTime.getTime() < endDate.getTime()) {
        counter++;
        const tripday = trip.tripdays[counter];
        if (tripday.nrOfNights !== null) {
            departDateWithoutTime.setDate(departDateWithoutTime.getDate() + +tripday.nrOfNights);
        }
    }
    return counter;
};
export const areDatesEqual = (date1, date2) => {
    return (date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate());
};
export const isDateBeforeDate = (date1, date2) => {
    // Ignoring the hours, minutes and seconds
    return (date1.getFullYear() < date2.getFullYear() ||
        (date1.getFullYear() === date2.getFullYear() && date1.getMonth() < date2.getMonth()) ||
        (date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() < date2.getDate()));
};
export const monthByShort = {
    ["JAN"]: 0,
    ["FEB"]: 1,
    ["MAR"]: 2,
    ["APR"]: 3,
    ["MAY"]: 4,
    ["JUN"]: 5,
    ["JUL"]: 6,
    ["AUG"]: 7,
    ["SEP"]: 8,
    ["OCT"]: 9,
    ["NOV"]: 10,
    ["DEC"]: 11,
};
//returns numeric values of month & day by given daySelectString originating from daySelectOptions
export const getDayAndMonthFromSelectToDate = (daySelect) => {
    const splitDays = daySelect.split(" ");
    const month = splitDays[3];
    const day = splitDays[4];
    return [monthByShort[month.toUpperCase()], +day];
};
