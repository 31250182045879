import { Link, Text } from "@chakra-ui/react";
import React from "react";
import AuthAPI from "../api/auth.api";
import GeneralLoginRegister from "../features/auth/GeneralLoginRegister";
import { CardWrapper } from "../components/CardWrapper";
import SplitScreenLayout from "../components/layout/SplitScreenLayout";
import { loginValidationSchema } from "../validation/validationSchemas";
import { getButtonOrLinkProps } from "./register";

type LoginProps = {
  sameRouteToggle?: React.Dispatch<React.SetStateAction<boolean>>;
  successCallback?: () => void;
};
export const Login: React.FC<LoginProps> = ({ sameRouteToggle, successCallback }) => {
  const buttonOrLinkProps = getButtonOrLinkProps("/register", sameRouteToggle);

  return (
    <SplitScreenLayout>
      <CardWrapper title="Log in">
        <LoginForm successCallback={successCallback} />
      </CardWrapper>
      <Text mt={4} fontSize="md" color="white">
        {`Don't have an account yet? `}
        <Link {...buttonOrLinkProps} color="brand.400">
          Create an account
        </Link>
      </Text>
    </SplitScreenLayout>
  );
};

export default Login;

type LoginFormProps = {
  successCallback?: () => void;
};

const LoginFormm: React.FC<LoginFormProps> = ({ successCallback }) => {
  const handleUnauthorizedException = React.useCallback((statusCode: number, setError: any) => {
    if (statusCode === 401) {
      // Unauthorized exception
      // Hence, wrong credentials were given
      setError("password", {
        type: "manual",
        message: "Incorrect email - password combination.",
      });
    }
  }, []);
  return (
    <GeneralLoginRegister
      type="login"
      validationSchema={loginValidationSchema}
      successMessage="logged in"
      failMessage="logging in"
      apiCall={AuthAPI.login}
      handleAdditionalError={handleUnauthorizedException}
      successCallback={successCallback}
    />
  );
};
export const LoginForm = React.memo(LoginFormm);
