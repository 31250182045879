import { Icon, Tooltip, useToast } from "@chakra-ui/react";
import { PRIMARY_COLOR } from "@lato/common";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Editor } from "@tiptap/react";
import { ChevronRight, PaletteIcon, RepeatIcon } from "lucide-react";
import React, { ReactNode, useCallback, useLayoutEffect, useRef, useState } from "react";
import CheckIcon from "../../../assets/icons/CheckIcon";
import ImproveIcon from "../../../assets/icons/ImproveIcon";
import MagicIcon from "../../../assets/icons/MagicIcon";
import MicrophoneIcon from "../../../assets/icons/MicrophoneIcon";
import ShorterIcon from "../../../assets/icons/ShorterIcon";
import SummarizeIcon from "../../../assets/icons/SummarizeIcon";
import WriteIcon from "../../../assets/icons/WriteIcon";
import { addToast } from "../../../utils/addToast";
import { cn } from "../../../utils/cn";
import { useChatGPTPrompt } from "../../../utils/query-helpers/react-query-mutations";
import CoreInput from "../CoreInput";
import { getPrevText } from "./RichTextEditor2";
import { updateScrollView } from "./slash-command";
import { PLUS_COMMAND_ID } from "./plus-command";
import TextareaAutoHeight from "../TextareaAutoHeight";

export type AICommandType = {
  title: string;
  icon?: ReactNode;
  searchTerms?: string[];
  sub_items?: AICommandType[];
  openai_context?: string;
};

const popoverClasses = "z-50 w-72 rounded-md border border-stone-200 bg-white px-1 py-2 shadow-md transition-all";

export const writeMoreAboutAIPrompt = "Write about the given topic. Keep it factual and concisely.";

export const continueWritingAICommand: AICommandType = {
  title: "Continue writing",
  icon: <WriteIcon />,
  openai_context:
    "You should continue writing existing text based on context from prior text. " +
    "Give more weight/priority to the later characters than the beginning ones. " +
    "Keep in mind that the result is appended directly after the existing text. ",
};

export const aiCommands: AICommandType[] = [
  continueWritingAICommand,
  {
    title: "Summarize",
    icon: <SummarizeIcon />,
    openai_context: "You are an AI writing assistant that summarizes a text. ",
  },
  {
    title: "Fix spelling and grammar",
    icon: <CheckIcon />,
    openai_context: "You are an AI writing assistant that only fixes spelling and grammar. ",
  },
  {
    title: "Rephrase",
    icon: <RepeatIcon width={"16px"} height={"16px"} />,
    openai_context:
      "You are an AI writing assistant that outputs an alternative phrasing by significantly rephrasing the text.",
  },
  {
    title: "Change writing style",
    icon: <ImproveIcon />,
    sub_items: [
      { title: "Simplify writing", openai_context: "You are an AI writing assistant that simplifies writing." },
      { title: "Improve writing", openai_context: "You are an AI writing assistant that improves writing." },
    ],
  },
  {
    title: "Change length",
    icon: <ShorterIcon />,
    sub_items: [
      {
        title: "Make shorter",
        openai_context: "You are an AI writing assistant that makes text significantly shorter by omitting.",
      },
      {
        title: "Make longer",
        openai_context: "You are an AI writing assistant that makes text significantly longer by expanding.",
      },
    ],
  },
  {
    title: "Change tone",
    icon: <MicrophoneIcon />,
    sub_items: [
      { title: "Friendly", openai_context: "You are an AI writing assistant that makes text friendly." },
      { title: "Confident", openai_context: "You are an AI writing assistant that makes text confident." },
      { title: "Straightforward", openai_context: "You are an AI writing assistant that makes text straightforward." },
      { title: "Casual", openai_context: "You are an AI writing assistant that makes text casual." },
      { title: "Professional", openai_context: "You are an AI writing assistant that makes text professional." },
    ],
  },
  {
    title: "Change creativity",
    icon: <PaletteIcon width={"16px"} height={"16px"} />,
    sub_items: [
      { title: "Low", openai_context: "You are an AI writing assistant that makes text low in creativity." },
      { title: "Medium", openai_context: "You are an AI writing assistant that makes text medium in creativity." },
      { title: "High", openai_context: "You are an AI writing assistant that makes text high in creativity." },
    ],
  },
];

interface AIDropdownProps {
  items: AICommandType[];
  command: any;
  editor: Editor;
  range: any;
  bubbleMenu?: boolean;
  selectedLanguage?: string;
}

const AIDropdown = ({ items, command, editor, range, bubbleMenu = true, selectedLanguage }: AIDropdownProps) => {
  const [isOpen, setOpen] = React.useState(!bubbleMenu);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const toast = useToast();
  const inputRef = useRef<any>();

  const { mutateAsync: openai, isPending } = useChatGPTPrompt({
    onSuccess: (response) => {
      setOpen(false);

      const answer = response.response?.trim();
      if (range?.from && range?.to) {
        editor.commands.deleteRange({
          from: range.from,
          to: range.to,
        });
      }
      const { view } = editor;
      const { from, to } = view.state.selection;
      if (to) {
        // If there is a selection, move the cursor to the end of the selection.
        editor?.commands.setTextSelection(to);
      }
      editor?.commands.insertContent("<br/>");
      editor?.commands.insertContent("<br/>");
      // const from = range?.from ? range.from : editor.state.selection.to + 1;
      editor?.commands.insertContent(answer);
      setSelectedIndex(0);
      // highlight the generated text
      // if (range?.from) {
      // editor.commands.setTextSelection({
      //   from: from,
      //   to: from + 1,
      // });
      // }
    },
    onError: (error) => {
      addToast(toast, {
        title: "Error executing AI command",
        status: "error",
        description: `Please try again later or contact support.`,
      });
    },
  });

  const getTextContext = (): string => {
    let context: string;
    if (bubbleMenu) {
      const { view, state } = editor;
      const { from, to } = view.state.selection;
      const selectedText = state.doc.textBetween(from, to, "");
      context = selectedText;
    } else {
      context = getPrevText(editor, {
        chars: 2000,
        offset: 1,
      });
    }
    return context;
  };

  const selectItem = useCallback(
    (item: AICommandType, index: number) => {
      setSelectedIndex(index);
      if (isPending || item.sub_items?.length || !item.openai_context) return;
      const context = getTextContext();
      if (context.length < 5) alert("First type more characters to give some context.");
      else {
        openai({
          text: context,
          prompt: item.openai_context,
          languageCode: selectedLanguage,
        });
      }
    },
    [openai, isPending, editor, items, editor, bubbleMenu, selectedLanguage],
  );

  const handleGenerateText = () => {
    const context = getTextContext();
    const text = context;
    const prompt = bubbleMenu ? inputRef.current?.value : writeMoreAboutAIPrompt + ". " + inputRef.current?.value;
    openai({
      text,
      prompt,
      languageCode: selectedLanguage,
    });
  };

  // useEffect(() => {
  //   // const navigationKeys = ["ArrowUp", "ArrowDown", "Enter"];
  //   const navigationKeys = ["Enter"];
  //   const onKeyDown = (e: KeyboardEvent) => {
  //     if (navigationKeys.includes(e.key)) {
  //       e.preventDefault();
  //       // This is not working: I can't navigate with the arrows inside the textfield anymore
  //       // An option should be added to open sub_items tippy on arrow right
  //       if (e.key === "ArrowUp") {
  //         setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  //         return true;
  //       }
  //       if (e.key === "ArrowDown") {
  //         setSelectedIndex((selectedIndex + 1) % items.length);
  //         return true;
  //       }
  //       if (e.key === "Enter") {
  //         selectItem(items[selectedIndex]);
  //         return true;
  //       }
  //       return false;
  //     }
  //   };
  //   document.addEventListener("keydown", onKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", onKeyDown);
  //   };
  // }, [items, selectedIndex, setSelectedIndex, selectItem]);

  // useEffect(() => {
  //   setSelectedIndex(0);
  // }, [items]);

  const AIDropdownContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const container = AIDropdownContainer?.current;

    const item = container?.children[selectedIndex] as HTMLElement;

    if (item && container) updateScrollView(container, item);
  }, [selectedIndex]);

  if (items.length < 1) return null;
  return (
    // When this controlled open prop is set to true, the page scrolling does not work
    <DropdownMenu.Root open={isOpen} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>
        {bubbleMenu ? (
          <button style={{ color: PRIMARY_COLOR }} type="button">
            {isPending ? <LoadingCircle /> : <Icon as={MagicIcon} />}
            <p className="leading-none">
              <b>AI</b>
            </p>
          </button>
        ) : (
          <p className="leading-[0rem]" />
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        // className="DropdownMenuContent"
        sideOffset={5}
        id={PLUS_COMMAND_ID}
        ref={AIDropdownContainer}
        align="start"
        className={popoverClasses}
      >
        {/* {!bubbleMenu && ( */}
        <div className="flex items-center mb-2">
          <Tooltip
            label="Just tell the AI what you need: the more details, the better."
            placement="right"
            zIndex={10002}
          >
            <TextareaAutoHeight
              ref={inputRef}
              color="black"
              placeholder="Ask AI anything..."
              // className="padding-2"
              style={{ paddingLeft: "30px" }}
              onKeyDown={(e: any) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  handleGenerateText();
                }
              }}
              leftIcon={
                <span style={{ color: PRIMARY_COLOR }}>
                  {isPending ? <LoadingCircle /> : <MagicIcon className="h-5 w-5" />}
                </span>
              }
            />
          </Tooltip>
        </div>
        {/* )} */}
        {items.map((item: AICommandType, index: number) => (
          <AIDropdownItem
            key={`${item.title}-${index}`}
            item={item}
            isPending={isPending && selectedIndex === index}
            index={index}
            selectedIndex={selectedIndex}
            selectItem={selectItem}
            editor={editor}
            range={range}
            command={command}
            bubbleMenu={bubbleMenu}
          />
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default React.memo(AIDropdown);

function LoadingCircle({ dimensions }: { dimensions?: string }) {
  return (
    <svg
      aria-hidden="true"
      className={`${dimensions || "h-4 w-4"} animate-spin fill-stone-600 text-stone-200`}
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  );
}

const AIDropdownItem: React.FC<{
  item: AICommandType;
  isPending: boolean;
  index: number;
  selectedIndex: number;
  selectItem: (item: AICommandType, index: number) => void;
  command: any;
  editor: Editor;
  range: any;
  bubbleMenu?: boolean;
}> = React.memo(({ item, isPending, index, selectedIndex, selectItem, editor, range, command, bubbleMenu }) => {
  const [open, setOpen] = useState(false);
  // Remove all non [^0-9a-zA-Z_] characters
  const keyName = `${bubbleMenu}-${item.title}-dropdown-item-${index}`.replace(/\W/g, "");

  const sub_items = item.sub_items || [];
  // const component = new ReactRenderer(AIDropdown, {
  //   props: {
  //     editor: editor,
  //     range: range,
  //     command: command,
  //     items: sub_items,
  //   },
  //   editor: editor,
  // });

  // useTippy("#" + keyName, {
  //   content: component.element,
  //   placement: "right",
  //   interactive: true,
  //   // trigger: "",
  // });

  const listItemClasses = cn(
    `flex w-full items-center space-x-2 rounded-md px-2 py-1 text-left text-sm text-stone-900 hover:bg-stone-100 hover:cursor-pointer`,
    selectedIndex === index ? "bg-stone-100" : "",
  );

  const ListItem = (
    <button id={keyName} className={listItemClasses} type="button">
      {(isPending || item.icon) && (
        <div className="flex h-5 w-5 items-center justify-center" style={{ color: PRIMARY_COLOR }}>
          {/* {isPending ? <LoadingCircle /> : item.icon} */}
          {item.icon}
        </div>
      )}
      <div>
        <p className="font-normal">{item.title}</p>
      </div>
      {sub_items.length > 0 && (
        <div className="flex h-4 w-4 items-center justify-center text-gray-400" style={{ marginLeft: "auto" }}>
          <ChevronRight />
        </div>
      )}
    </button>
  );

  const noOutline = "focus:ring-0 focus:outline-none focus-visible:outline-none focus-visible:ring-0";

  if (sub_items.length === 0)
    return (
      <DropdownMenu.Item
        className={noOutline}
        onSelect={(e) => {
          e.stopPropagation();
          e.preventDefault();
          selectItem(item, index);
        }}
      >
        {ListItem}
      </DropdownMenu.Item>
    );
  return (
    <DropdownMenu.Sub open={open} onOpenChange={setOpen}>
      <DropdownMenu.SubTrigger className={noOutline}>{ListItem}</DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent className={popoverClasses} style={{ zIndex: 10001 }} sideOffset={2} alignOffset={-5}>
          {sub_items.map((sub_item, i) => (
            <DropdownMenu.Item
              key={keyName + i}
              className={cn(listItemClasses, noOutline)}
              onSelect={(e) => {
                e.stopPropagation();
                e.preventDefault();
                selectItem(sub_item, index);
              }}
            >
              {sub_item.title}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
  // if (sub_items.length === 0) return <MenuItem c={ListItem} label={item.title} className="w-full" />;
  // return (
  // <Tooltip placement="right">
  //   <TooltipTrigger className="w-full">{ListItem}</TooltipTrigger>
  //   <TooltipContent className="Tooltip">
  //     <AIDropdown items={sub_items} command={undefined} range={undefined} editor={editor} />
  //   </TooltipContent>
  // </Tooltip>
  // <Menu c={ListItem} label={item.title}>
  //   <AIDropdown items={sub_items} command={undefined} range={undefined} editor={editor} />
  // </Menu>
  // );
});
AIDropdownItem.displayName = "AIDropdownItem";
