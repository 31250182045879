import { AddIcon } from "@chakra-ui/icons";
import { Icon, IconButton, Spinner, Tooltip, useToast } from "@chakra-ui/react";
import { TranslationField, User } from "@lato/common";
import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { CellProps, Column } from "react-table";
import TextfieldsAPI from "../../../api/textfields.api";
import { getEmptyTranslationField } from "../../../pages/create-trip";
import { addToast } from "../../../utils/addToast";
import { LibraryItem } from "../../trips/edit/daybyday/library-items/LibraryItemModal";
import TextFieldsTable from "./TextfieldsTable";
import useMutation from "../../../utils/query-helpers/useMutation";

type ImportTextFieldProps = {
  toggleForceRerender: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  transformToTF: boolean;
  fieldObjectName: string;
  me: User;
  name: string;
};

const ImportTextField: React.FC<ImportTextFieldProps> = ({
  toggleForceRerender,
  onClose,
  transformToTF,
  fieldObjectName,
  me,
  name,
}) => {
  const toast = useToast();
  const { getValues, setValue } = useFormContext();
  const { replace } = useFieldArray({ name: fieldObjectName });

  const importTextFieldIntoField = React.useCallback(
    (libraryItem: LibraryItem) => {
      const emptyTranslationField = getEmptyTranslationField(me);
      const globalLanguages = (getValues(fieldObjectName) as TranslationField[]).filter(
        (field) => field.creator?.companyId === me.companyId,
      );
      const sortedLanguages = globalLanguages.map((l) => l.language.code);

      const libraryItemDescriptions = libraryItem.descriptions
        .map(({ id, ...hd }) => hd)
        .filter((field) => field.creator?.companyId === me.companyId);

      const existingTripTranslations = ([...libraryItem.descriptions] as TranslationField[]).filter(
        (hd) => sortedLanguages.findIndex((hs) => hs.includes(hd.language.code)) !== -1,
      );
      const othersTranslations = (getValues(fieldObjectName) as TranslationField[]).filter(
        (field) => field.creator?.companyId !== me.companyId,
      );

      if (existingTripTranslations && Array.isArray(existingTripTranslations) && !transformToTF) {
        const previousLanguageCodes = existingTripTranslations.map((ht) => ht?.language?.code);

        libraryItemDescriptions.forEach((hd) => {
          const copy = previousLanguageCodes.indexOf(hd.language.code);
          const remove = globalLanguages.findIndex(
            (hs) => hs.language.code.includes(hd.language.code) && hs.creator?.companyId === me.companyId,
          );
          if (copy !== -1) {
            existingTripTranslations[copy] = {
              ...hd,
              content: (globalLanguages[copy]?.content ?? "") + " " + hd?.content,
              language: remove !== -1 ? globalLanguages[remove].language : hd.language,
            };
          }
          if (remove !== -1) {
            globalLanguages.splice(remove, 1);
          }
        });

        const newDescriptions = [
          ...othersTranslations,
          ...existingTripTranslations.map((hd) => ({
            ...emptyTranslationField,
            content: hd.content,
            language: hd.language,
            language_code: hd.language.code,
          })),
          ...globalLanguages.map((hd) => ({
            ...emptyTranslationField,
            creator: hd.creator,
            content:
              existingTripTranslations.findIndex((ht) => ht.language.code === hd.language.code) === -1
                ? ""
                : existingTripTranslations.find((ht) => ht.language.code === hd.language.code)!.content,
            language: hd.language,
            language_code: hd.language.code,
          })),
        ].sort((a, b) =>
          a.language.name < b.language.name
            ? -1
            : a.language.name === b.language.name
              ? (a.creator?.companyId ?? "") < (b.creator?.companyId ?? "")
                ? -1
                : a.creator?.companyId === b.creator?.companyId
                  ? 0
                  : 1
              : 1,
        );
        replace(newDescriptions);
      } else {
        transformToTF && setValue(name, libraryItemDescriptions[0].content);
      }
      // This updates the key prop such that a rerender is forced to update the defaultValue.
      toggleForceRerender((r) => !r);
      onClose();
      addToast(toast, {
        title: "Successfully imported textfield.",
        status: "success",
      });
    },
    [onClose, fieldObjectName, getValues, setValue, replace, me, name, transformToTF, toggleForceRerender, toast],
  );

  const extraColumns: Column<LibraryItem>[] = React.useMemo(
    () => [
      {
        id: "insert-libraryItem",
        isNumeric: true,
        isTruncated: false,
        // minWidth: 60,
        // chakraMaxWidth: "60px",
        // width: 60,
        chakraWidth: "60px",
        Cell: (cell: CellProps<LibraryItem>) => {
          const api = TextfieldsAPI;
          const { mutate: getLibraryItem, rqMutation } = useMutation({
            apiCall: (id: string) => api.getSingle(id),
            failMessage: `loading libraryItem information`,
          });
          return (
            <Tooltip label="Add TextField" aria-label="insert-accommodation-tooltip">
              <IconButton
                colorScheme="brand"
                aria-label="insert-accommodation"
                icon={rqMutation?.isPending ? <Spinner /> : <Icon as={AddIcon} boxSize={3} />}
                onClick={async (e) => {
                  e.stopPropagation();
                  const libraryItem = await getLibraryItem(cell.row.original.id!);
                  if (libraryItem) {
                    importTextFieldIntoField(libraryItem);
                  }
                }}
                w="fit-content"
                variant="ghost"
              />
            </Tooltip>
          );
        },
      },
    ],
    [importTextFieldIntoField],
  );

  return (
    <TextFieldsTable
      additionalColumns={extraColumns as any}
      additionalQueryState={undefined}
      // initialFilters={[{ id: "languages", value: translatedLanguages }]}
      minTableHeight={"15em"}
      disableFilterSave
    />
  );
};
export default React.memo(ImportTextField);
