import { Text } from "@chakra-ui/react";
import { TRANSPORTATION_TYPE, TripDay, getDistanceFromLatLonInKm, getGroupedTransportations } from "@lato/common";
import L from "leaflet";
import React, { ReactElement } from "react";
import { useWatch } from "react-hook-form";
import { MdHotel, MdLocationOn } from "react-icons/md";
import { Marker, Tooltip } from "react-leaflet";
import { useParams } from "react-router-dom";
import { useMeContext } from "../../../../stores/me-context";
import { CustomLeafletPinMarker } from "../../../map/CustomLeafletPinMarker";
import Maps from "../../../../features/map/Maps";
import { getTransportations } from "./getTransportations";

interface OverviewMapProps {
  isDirectionsDisabled: boolean;
  isSmallMap?: boolean;
  expandMapButton?: boolean;
  changeTripday?: (day: number) => void;
  selectedTripdayIndex?: number;
}

function getNameTripday(tripday: TripDay): string {
  return (
    tripday.location?.name ??
    tripday.location?.address.street ??
    tripday.location?.address.city ??
    tripday.location?.address.state ??
    tripday.location?.address.country_code ??
    ""
  );
}

const isCloserThan = (firstCoords: number[], secondCoords: number[], distance: number) => {
  const distanceBetween = getDistanceFromLatLonInKm(
    { lng: firstCoords[0], lat: firstCoords[1] },
    { lng: secondCoords[0], lat: secondCoords[1] },
  );

  return distanceBetween < distance;
};

export function getMarkerPositionsFromTripdays(
  tripdays: (TripDay | null | undefined)[],
): { lat: number; lng: number }[] {
  const markerPositions: { lat: number; lng: number; icon?: ReactElement; name?: string; dayIndex?: number }[] = [];
  let previous: { lat: number; lng: number } = { lat: Infinity, lng: Infinity };
  tripdays.forEach((tripday, i) => {
    if (
      tripday &&
      tripday.hotels?.length > 0 &&
      tripday.hotels[0].location &&
      tripday.hotels[0].location.coordinates &&
      !isCloserThan(tripday.hotels[0].location.coordinates, [previous.lng, previous.lat], 1)
    ) {
      previous = {
        lat: tripday.hotels[0].location.coordinates[1],
        lng: tripday.hotels[0].location.coordinates[0],
      };
      return markerPositions.push({
        lat: tripday.hotels[0].location.coordinates[1],
        lng: tripday.hotels[0].location.coordinates[0],
        name: tripday.hotels[0].name,
        icon: <MdHotel className="w-4 h-4" />,
        dayIndex: i + 1,
      });
    } else if (
      tripday &&
      tripday.hotels?.length > 0 &&
      tripday.hotels[0].location &&
      tripday.hotels[0].location.coordinates &&
      isCloserThan(tripday.hotels[0].location.coordinates, [previous.lng, previous.lat], 1)
    ) {
      return null;
    }

    if (
      tripday &&
      tripday.location &&
      tripday.location.coordinates &&
      !isCloserThan(tripday.location.coordinates, [previous.lng, previous.lat], 1)
    ) {
      previous = {
        lat: tripday.location.coordinates[1],
        lng: tripday.location.coordinates[0],
      };
      return markerPositions.push({
        lat: tripday.location.coordinates[1],
        lng: tripday.location.coordinates[0],
        name: getNameTripday(tripday),
        icon: <MdLocationOn className="w-4 h-4" />,
        dayIndex: i + 1,
      });
    }
    return null;
  });
  return markerPositions;
}

const OverviewMap: React.FC<OverviewMapProps> = ({
  isDirectionsDisabled,
  isSmallMap,
  changeTripday,
  selectedTripdayIndex,
  expandMapButton,
}) => {
  const { id: userTripId } = useParams<{ id: string }>();
  const user = useMeContext();

  const tripdays = useWatch({ name: "tripdays" }).filter((tripday: any) => tripday.location);

  const markerPositions = React.useMemo(() => getMarkerPositionsFromTripdays(tripdays), [tripdays]);
  const transportations = React.useMemo(() => getTransportations(isDirectionsDisabled, tripdays), [tripdays]);
  const transportation_groups = React.useMemo(
    () => (!isDirectionsDisabled ? getGroupedTransportations(transportations) : []),
    [transportations],
  );

  const flights = React.useMemo(
    () => transportations && transportations.filter((transport: any) => transport.type === TRANSPORTATION_TYPE.FLIGHT),
    [transportations],
  );
  const trains = React.useMemo(
    () => transportations && transportations.filter((transport: any) => transport.type === TRANSPORTATION_TYPE.TRAIN),
    [transportations],
  );

  const mapType = user.company.map_provider;
  // If the userTripId is undefined, you are probably creating a new trip and hence thus no user trips are available yet.
  const selectedLocation = markerPositions[selectedTripdayIndex!];

  return (
    <Maps
      isDirectionsDisabled={isDirectionsDisabled}
      transportations={transportations}
      transportation_grouped={transportation_groups}
      flights={flights}
      trains={trains}
      markerPositions={markerPositions}
      userTripId={userTripId}
      mapType={mapType}
      isSmallMap={isSmallMap}
      expandMapButton={expandMapButton}
      centerpoint={selectedLocation ? [selectedLocation.lng, selectedLocation.lat] : undefined}
    >
      {markerPositions.map((marker, index) => (
        <OverviewMarker
          key={`marker-${index}`}
          index={index}
          marker={marker}
          isSmallMap={isSmallMap!}
          onClick={changeTripday}
          selectedTripdayIndex={selectedTripdayIndex}
        />
      ))}
    </Maps>
  );
};
export default React.memo(OverviewMap);

type OverviewMapMarker = {
  lat: number;
  lng: number;
  name?: string;
  icon?: ReactElement;
};

interface OverviewMarkerProps {
  index: number;
  marker: OverviewMapMarker;
  isSmallMap: boolean;
  onClick?: (day: number) => void;
  selectedTripdayIndex?: any;
}

const OverviewMarker: React.FC<OverviewMarkerProps> = ({
  index,
  marker,
  isSmallMap = false,
  onClick,
  selectedTripdayIndex,
}) => {
  const dayMarker = new L.DivIcon({
    className: "flex items-center justify-center",
    html: `<div class="flex items-center justify-center leaflet-tripday-icon"/>`,
  });

  const selectedDayMarker = new L.DivIcon({
    className: "flex items-center justify-center",
    html: `<div class="flex items-center justify-center leaflet-selected-icon"/>`,
  });

  return (
    <>
      {isSmallMap ? (
        <Marker
          key={"marker" + index}
          zIndexOffset={100 + index + (selectedTripdayIndex === index ? 100 : 0)}
          position={{
            lat: marker.lat,
            lng: marker.lng,
          }}
          eventHandlers={{
            click: () => {
              onClick && onClick(index);
            },
          }}
          icon={selectedTripdayIndex === index ? selectedDayMarker : dayMarker}
        >
          <Tooltip sticky={true}>
            <div className="flex gap-1">{index !== 0 && <div className="text-md">Day {index + 1}</div>}</div>
          </Tooltip>
        </Marker>
      ) : (
        <CustomLeafletPinMarker
          coordinates={[marker.lng, marker.lat]}
          nestedIcon={<Text>{index + 1}</Text>}
          key={`marker-${index}`}
          zIndexOffset={100 + index}
          pinColor={"#E53E3E"}
          nestedIconColor={"black"}
          disableHover={true}
        >
          <Tooltip sticky={true}>
            <div className="flex gap-1">
              {marker.icon}
              <div>
                <span className="font-bold text-md">{marker.name}</span>
                {index !== 0 && <div className="text-md">Arrival on day {index + 1}</div>}
              </div>
            </div>
          </Tooltip>
        </CustomLeafletPinMarker>
      )}
    </>
  );
};
