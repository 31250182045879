import { CheckIcon } from "@chakra-ui/icons";
import { Box, Circle, Image, Text } from "@chakra-ui/react";
import { ImageSite, IsImage } from "@lato/common";

interface CardProps {
  choosePicture: any;
  selected: boolean;
  nameElement: any;
  imageSite: ImageSite;
  imageUrl: string;
  imageAlt: string;
}

const Card: React.FC<CardProps> = ({ choosePicture, selected, nameElement, imageSite, imageUrl, imageAlt }) => {
  const selectedProps = selected && {
    border: "2px",
    borderColor: "brand.500",
  };
  if (imageUrl && imageSite) {
    return (
      <Box
        role="group"
        pos="relative"
        borderRadius={"md"}
        overflow="hidden"
        as="button"
        type="button"
        onClick={choosePicture}
        width={"100%"}
        {...selectedProps}
      >
        {selected && (
          <Circle
            pos="absolute"
            right={2}
            top={2}
            size="25px"
            bg={"brand.400"}
            borderColor={"brand.500"}
            color={"white"}
            borderWidth="2px"
          >
            <CheckIcon boxSize={"50%"} />
          </Circle>
        )}
        <Text
          pos="absolute"
          bottom={0}
          textAlign="left"
          fontSize="sm"
          w="100%"
          bg="blackAlpha.500"
          color="white"
          transition=".3s ease"
          opacity={0}
          whiteSpace="nowrap"
          isTruncated
          _groupHover={{
            opacity: 1,
          }}
          py={0.5}
          px={3}
        >
          {nameElement}
        </Text>
        {!IsImage(imageSite) ? (
          /*<iframe
            src={Photo.urls.small}
            width="375"
            height="211"
            allow="autoplay"
            sandbox="allow-downloads"
          ></iframe>*/
          <>
            {imageSite === ImageSite.CUSTOMVIDEO ? (
              <iframe
                title={imageAlt}
                src={imageUrl}
                style={{
                  height: "100%",
                  objectFit: "cover",
                  pointerEvents: "none",
                  marginLeft: "-20%",
                }}
              ></iframe>
            ) : (
              <video
                key={imageUrl}
                autoPlay
                muted
                width="100%"
                height="210"
                loop
                disablePictureInPicture
                controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
              >
                <source src={imageUrl} />
              </video>
            )}
          </>
        ) : (
          <Image width={"100%"} src={imageUrl} alt={imageAlt} />
        )}
      </Box>
    );
  } else {
    return <></>;
  }
};
export default Card;
