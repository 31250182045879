import { ImageSite, UNSPLASH_PHOTO_BASE_URL } from "@lato/common";
import ChatGptAPI from "./chatgpt.api";
import ImagesExternalAPI from "./images-external.api";
import { removeAllHTMLTags } from "../utils/sanitizeXSS";

const WIKIVOYAGE_API_URL = "https://en.wikivoyage.org/w/api.php";
const forbidden_classes = ["mw-editsection", "mw-addlisting", "noprint", "nomobile", "mf-mobile-only"];

export async function getWikiContentByLocation(
  location: string,
  addExtraImages?: boolean,
  aiPrompt?: string,
  language?: string,
) {
  if (!location || location === "") return;
  const page = await getFirstWikiPageByLocationName(location);
  if (page.extract == undefined) {
    return;
  }
  const sections = await getWikiSectionsByPageId(page.id);
  const understandSection = sections.filter((section: any) => section.line === "Understand");
  const understandContent = await getWikiContentBySection(
    page.id,
    understandSection.length > 0 ? understandSection[0].index : sections[0].index,
  );

  let prompt =
    "You are a travel agent writing a destination description of a single stop in an itinerary. \
    Modify the given text into 2 informative and attractive paragraphs.  \
    NEVER include information about information points.";
  // The text should have a minimum of 1000 characters. Start the text with an introductory sentence. \

  // if (text.length < 5000) {
  //   const doSection = sections.filter((section: any) => section.line === "Get around");
  //   if (doSection.length > 0) {
  //     const doContent = await getWikiContentBySection(page.id, doSection[0].index);
  //     text += doContent?.text.trim();
  //   }
  // }

  if (aiPrompt && aiPrompt !== "") {
    prompt += aiPrompt;
  }

  if (language && !language.includes("en")) {
    prompt += ` Translate the text to ${language}.`;
  }

  prompt += " Use html in your response.";
  const content = page.extract + understandContent?.text;
  // Filter out all climate specific information
  let text = removeHTMLNodesWithClass(content, ["climate"]);
  // Remove all HTML tags and duplicate whitespace
  text = removeAllHTMLTags(text).replaceAll("&nbsp;", " ").replace(/\s+/g, " ");

  // console.log("wikivoyage prompt", prompt);
  // console.log("wikivoyage text", text);
  const result = await ChatGptAPI.askQuestion({
    prompt,
    text,
    translate: true,
  });

  const images = [page.image, ...(understandContent?.images ?? [])]
    .filter((value, index, self) => index === self.findIndex((t) => t === value))
    .map((image, i) => getImageSrcUrl(image, i));

  const wikiImageCount = images.length;

  if (addExtraImages && wikiImageCount < 6) {
    const imageResponse = await ImagesExternalAPI.getPhotos(`q=${location}&api=unsplash&page=1`);
    if (imageResponse?.results && imageResponse?.results.length > 0) {
      images.push(
        ...imageResponse.results.slice(0, 6 - wikiImageCount).map((image: any, index: number) => ({
          s3: {
            key: image.urls.small.replace(UNSPLASH_PHOTO_BASE_URL, ""),
          },
          ord: index + wikiImageCount,
          site: ImageSite.UNSPLASH,
        })),
      );
    }
  }

  return {
    description: result.response?.replaceAll("```html", "").replaceAll("```", ""),
    images: images,
  };
}

function removeHTMLNodesWithClass(html: string, classes: string[]) {
  const div = document.createElement("div");
  div.innerHTML = html;
  for (const className of classes) {
    const allNodes = div.querySelectorAll("*");
    allNodes.forEach((node) => {
      if (node.className && node.className.includes(className)) {
        node.remove();
      }
    });
  }
  return div.innerHTML;
}

function getImageSrcUrl(image: string, index: number) {
  return {
    site: ImageSite.CUSTOMPICTURE,
    s3: {
      key: `https://en.wikivoyage.org/wiki/Special:FilePath/${encodeURIComponent(image)}`,
    },
    ord: index,
  };
}

async function getFirstWikiPageByLocationName(locationName: string) {
  const reponse = await fetch(
    `${WIKIVOYAGE_API_URL}?action=query&titles=${encodeURIComponent(
      locationName,
    )}&prop=pageimages|extracts&exintro=1&redirects=&format=json&origin=*`,
  )
    .then((response) => response.json())
    .catch((error) => console.error("Error fetching pageId:", error));

  const pages = reponse.query.pages;
  const firstPageId = Object.keys(pages)[0];
  const firstPage = pages[firstPageId];
  return {
    id: firstPage.pageid,
    title: firstPage.title,
    image: firstPage.pageimage,
    extract: firstPage.extract,
  };
}

async function getWikiSectionsByPageId(pageId: string) {
  try {
    const response = await fetch(
      `${WIKIVOYAGE_API_URL}?action=parse&pageid=${pageId}&prop=sections&format=json&origin=*`,
    );
    const reponseJson = await response.json();
    return reponseJson.parse.sections;
  } catch (error) {
    console.error("Error fetching sections:", error);
  }
}

async function getWikiContentBySection(pageId: string, sectionId: string) {
  try {
    const response = await fetch(
      `${WIKIVOYAGE_API_URL}?action=parse&pageid=${pageId}&section=${sectionId}&prop=text|images&format=json&origin=*`,
    );
    const responseJson = await response.json();
    return {
      text: removeElementsByClass(responseJson.parse.text["*"], forbidden_classes),
      images: responseJson.parse.images,
    };
  } catch (error) {
    console.error("Error fetching parsed text and images:", error);
  }
}

function removeElementsByClass(htmlString: string, classNames: string[]) {
  // Create a new DOMParser
  const parser = new DOMParser();

  // Parse the HTML string into a DOM document
  const doc = parser.parseFromString(htmlString, "text/html");

  // Remove elements with the specified classes
  classNames.forEach((className) => {
    const elementsToRemove = doc.querySelectorAll(`.${className}`);
    elementsToRemove.forEach((element) => element.parentNode?.removeChild(element));
  });

  // Serialize the modified DOM document back to an HTML string
  const serializer = new XMLSerializer();
  const modifiedHtmlString = serializer.serializeToString(doc);

  return modifiedHtmlString;
}
