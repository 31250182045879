import { Flex } from "@chakra-ui/react";
import { Trip, TripDay, UserTrip } from "@lato/common";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { default as React } from "react";
import { useParams } from "react-router-dom";
import { useMeContext } from "../../../stores/me-context";
import { useTripLanguage } from "../../../stores/trip-language-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import { TRIP_PLAIN_QUERY_KEY, USERTRIP_PLAIN_QUERY_KEY } from "../../../utils/constants";
import { getViewTripLink } from "../../../utils/generateURLs";
import {
  getRelationMap,
  useSplitTrip,
  useSplitUserTrip,
  useTripTranslations,
} from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { CustomSpinner, WizardSkeleton } from "../../../components/FullScreenSpinner";
import TranslateTitle from "../../../components/input/TranslateTitle";
import WizardButtonGroup from "./WizardButtonGroup";
import WizardTopBar from "./WizardTopBar";
import { WizardTitleNewLayout } from "./WizardTitleNewLayout";

const WizardStepp: React.FC<any> = ({ children }) => children;
export const WizardStep = React.memo(WizardStepp);

interface WizardProps {
  children?: any;
  type: string;
  isFirst: boolean;
  collaboratorCanEdit: boolean;
  stepNumber: number;
  sample: boolean;
  trip: Trip;
  translateTitleWidth?: string;
}

const Wizard: React.FC<WizardProps> = (props) => {
  const me = useMeContext();
  const { data: tripTranslations, isLoading: isLoadingTranslations } = useTripTranslations(props.trip?.id ?? "");

  const getValues = React.useCallback(
    (value: string) => {
      return (tripTranslations as any)?.[value];
    },
    [tripTranslations],
  );

  return (
    <>
      <WizardFormContent {...props}>
        <WizardStep name="General">{/* <CreateGeneralTrip /> */}</WizardStep>
        <WizardStep name="Overview">{/* <TripsOverViewStep /> */}</WizardStep>
        <WizardStep name="Day by day">{/* <TripPreviewStep /> */}</WizardStep>
        <WizardStep name="Price">{/* <TripPriceStep /> */}</WizardStep>
        <WizardStep name="Share">{/* <ShareStep /> */}</WizardStep>
      </WizardFormContent>
      {isLoadingTranslations ? (
        <CustomSpinner />
      ) : (
        <TranslateTitle
          isFirst={props.isFirst}
          name="priceDescriptions"
          getValues={getValues}
          width={props.translateTitleWidth}
        />
      )}
    </>
  );
};
export default React.memo(Wizard);

const WizardFormContent: React.FC<WizardProps> = ({
  children,
  stepNumber,
  isFirst,
  collaboratorCanEdit,
  type,
  sample,
}) => {
  const { id: userTripId } = useParams<{ id: string }>();
  const { isErrorForms, isInvalidForms, isSample, trip: tripFromStore } = useTripFormStore();

  const { data: usertrips, isLoading: isLoadingUserTrip } = useSplitUserTrip(
    userTripId ?? "",
    getRelationMap[USERTRIP_PLAIN_QUERY_KEY],
    USERTRIP_PLAIN_QUERY_KEY,
  );

  const { data: trip, isLoading: isLoadingTrip } = useSplitTrip(
    tripFromStore?.id ?? "",
    TRIP_PLAIN_QUERY_KEY,
    getRelationMap[TRIP_PLAIN_QUERY_KEY],
  );

  const steps: any = React.Children.toArray(children);
  const invalidForms = [...isInvalidForms.entries()].filter((value) => value[1] === true);
  const errorForms = [...isErrorForms.entries()].filter((value) => value[1] === true);

  const stepInvalid = invalidForms.length > 0 ? parseInt(invalidForms[0][0]?.split("-")[0]) : undefined;
  const stepError = errorForms.length > 0 ? parseInt(errorForms[0][0]?.split("-")[0]) : undefined;

  if (isLoadingTrip || isLoadingUserTrip || !usertrips || !trip) {
    return <WizardSkeleton />;
  }

  return (
    <>
      <ReactQueryDevtools />
      <WizardTopBar
        steps={steps}
        activeStep={stepNumber}
        invalidStep={
          typeof stepInvalid === "number"
            ? Number(stepInvalid)
            : typeof stepInvalid === "number"
              ? Number(stepError)
              : undefined
        }
        first={isFirst}
        collaboratorCanEdit={collaboratorCanEdit}
        userTripId={userTripId!}
        type={type}
        trip={trip}
        usertrips={usertrips}
      />
    </>
  );
};

interface WizardNewLayoutProps {
  children?: any;
  type: string;
  isFirst: boolean;
  collaboratorCanEdit?: boolean;
  stepNumber: number;
  userTrips?: UserTrip[];
  sample: boolean;
  trip?: Trip;
  translateTitleWidth?: string;
}

export const WizardNewLayout: React.FC<WizardNewLayoutProps> = ({ trip, ...props }) => {
  const me = useMeContext();
  const { id: userTripId } = useParams<{ id: string }>();
  const { data: tripTranslations, isLoading: isLoadingTranslations } = useTripTranslations(trip?.id ?? "");
  const { isFirst, isSample } = useTripFormStore();
  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();

  const getValues = React.useCallback(
    (value: string) => {
      return (tripTranslations as any)?.[value];
    },
    [tripTranslations],
  );

  if (!trip) {
    return null;
  }

  const title =
    trip?.titles?.[isFirst ? firstSelectedLanguageIndex : (secondSelectedLanguageIndex ?? 0)]?.content || "";
  const client_name = trip?.client_name || "";
  const tripdays: TripDay[] = trip?.tripdays || [];
  const start_date = trip?.start_date || "";
  const end_date = trip?.end_date || "";
  const nrOfNights = tripdays.reduce((partialSum, td) => partialSum + td.nrOfNights, 0);
  const flagImageUrl = trip.country?.flagImage;

  const usertrip: UserTrip | null = props.userTrips?.find((ustr: UserTrip) => ustr.id === userTripId) || null;
  const external_ref = usertrip?.ref;
  const status = usertrip?.status;
  const brand = usertrip?.brand?.name || "";

  return (
    <Flex className="ml-4 h-10 -ml-2 align-middle sticky top-[3.05rem] z-50 justify-between bg-gradient-to-b from-[rgb(247,252,252)] from-0% via-[rgb(247,252,252)] via-100% to-transparent to-100%">
      <WizardTitleNewLayout
        title={title}
        client_name={client_name}
        refvalue={external_ref}
        first={isFirst}
        nrOfNights={nrOfNights}
        status={status}
        start_date={start_date}
        end_date={end_date}
        brand={brand}
        flagImageUrl={flagImageUrl}
        isLoadingTranslations={isLoadingTranslations}
      />
      {isLoadingTranslations ? (
        <CustomSpinner />
      ) : (
        <TranslateTitle isFirst={isFirst} name="priceDescriptions" getValues={getValues} width={"100%"} />
      )}
      {trip && (
        <WizardButtonGroup
          viewTripLink={getViewTripLink(
            me?.company?.name || "",
            trip.titles ? trip.titles[0].content : "trip",
            userTripId!,
          )}
        />
      )}
    </Flex>
  );
};
