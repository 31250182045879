import { Button, ButtonGroup, Icon, useDisclosure } from "@chakra-ui/react";
import { DARK_BLUE_COLOR } from "@lato/common";
import { BubbleMenu, Editor } from "@tiptap/react";
import { Underline } from "lucide-react";
import React, { useEffect } from "react";
import { FaBold as Bold, FaItalic as Italic, FaLink as Link } from "react-icons/fa6";
import { GoListOrdered as ListOrdered, GoListUnordered as ListUnordered } from "react-icons/go";
import { LuHeading1 as H1, LuHeading2 as H2 } from "react-icons/lu";
import CustomModal from "../../layout/CustomModal";
import ColorInput from "../ColorInput";
import CoreInput from "../CoreInput";
import RHFColor from "../RHFColor";
import AIDropdown, { aiCommands } from "./AIDropdown";
import "./Popover.scss";
import { disable_rich_text_color_feature } from "./RichTextEditor2";

type PopoverProps = {
  editor: Editor | null;
  selectedLanguage?: string;
};

// function setLink(editor: Editor) {
//   const previousUrl = editor.getAttributes("link").href;
//   const url = window.prompt("URL", previousUrl);

//   // cancelled
//   if (url === null) {
//     return;
//   }

//   // empty
//   if (url === "") {
//     // @ts-ignore
//     editor.chain().focus().extendMarkRange("link").unsetLink().run();

//     return;
//   }

//   // update link
//   // @ts-ignore
//   editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
// }

function EditorBubbleMenu({ editor, selectedLanguage }: PopoverProps) {
  return (
    <BubbleMenu
      className={`Popover relative items-stretch bg-slate-700`}
      editor={editor || undefined}
      // shouldShow={({ editor, view, state, oldState, from, to }) => {
      //   const { doc, selection } = state;
      //   const { empty } = selection;

      //   // Sometime check for `empty` is not enough.
      //   // Doubleclick an empty paragraph returns a node size of 2.
      //   // So we check also for an empty text size.
      //   const isEmptyTextBlock =
      //     !doc.textBetween(from, to).length && isTextSelection(state.selection);

      //   // Show when the cursor is placed at a link
      //   if (editor.isActive("link")) {
      //     return true;
      //   }

      //   if (empty || isEmptyTextBlock) {
      //     return false;
      //   }

      //   return true;
      // }}
    >
      <BubbleOptions editor={editor} selectedLanguage={selectedLanguage} />
    </BubbleMenu>
  );
}

export const BubbleOptions: React.FC<PopoverProps> = ({ editor, selectedLanguage }) => {
  const linkModalDisclosure = useDisclosure();
  const isSelectionOverLink = editor?.getAttributes("link").href;
  const defaultColor = DARK_BLUE_COLOR;

  const [color, setColor] = React.useState(editor?.getAttributes("textStyle").color ?? defaultColor);

  const handleColorChange = (color: string) => {
    setColor(color);
    editor?.chain().focus().setColor(color).run();
  };

  if (!editor) {
    return null;
  }
  // const isSelectionOverLink = editor.isActive("link");

  return (
    <>
      <AIDropdown
        items={aiCommands}
        command={undefined}
        range={undefined}
        editor={editor}
        selectedLanguage={selectedLanguage}
      />
      {/* </button> */}
      <BubbleMenuDivider />

      <button
        type="button"
        className={editor.isActive("bold") ? "active" : ""}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <Icon as={Bold} />
      </button>
      <button
        type="button"
        className={editor.isActive("italic") ? "active" : ""}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <Icon as={Italic} />
      </button>
      <button
        type="button"
        className={editor.isActive("underline") ? "active" : ""}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <Icon as={Underline} />
      </button>
      <button
        type="button"
        className={editor.isActive("heading", { level: 1 }) ? "active" : ""}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        disabled={editor.isActive("bulletList") || editor.isActive("orderedList")}
      >
        <Icon as={H1} />
      </button>
      <button
        type="button"
        className={editor.isActive("heading", { level: 2 }) ? "active" : ""}
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        disabled={editor.isActive("bulletList") || editor.isActive("orderedList")}
      >
        <Icon as={H2} />
      </button>
      <button
        type="button"
        className={editor.isActive("bulletList") ? "active" : ""}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <Icon as={ListUnordered} />
      </button>
      <button
        type="button"
        className={editor.isActive("orderedList") ? "active" : ""}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <Icon as={ListOrdered} />
      </button>
      <button
        type="button"
        className={isSelectionOverLink ? "active" : ""}
        onClick={() => linkModalDisclosure.onOpen()}
      >
        <Icon as={Link} />
        <LinkModal
          isSelectionOverLink={isSelectionOverLink}
          disclosure={linkModalDisclosure}
          defaultValues={{
            link: editor.getAttributes("link").href,
          }}
          editor={editor}
        />
      </button>
      {!disable_rich_text_color_feature && (
        <ColorInput
          resetValue={defaultColor}
          currentValue={editor.getAttributes("textStyle").color ?? color}
          changeColor={(e: any) => handleColorChange(e.target.value)}
          previousValue={editor.getAttributes("textStyle").color}
          customOnChange={(e) => handleColorChange(defaultColor)}
          simple
        />
      )}
    </>
  );
};

const BubbleMenuDivider = () => (
  <div className="w-[1px] bg-[rgba(255,255,255,0.15)] mx-[3px] my-[5px] h-[inherit]"></div>
);

export default React.memo(EditorBubbleMenu);

type LinkModalProps = {
  disclosure: any;
  isSelectionOverLink: boolean;
  defaultValues: {
    link: string | null;
  };
  editor: Editor | null;
};

export const LINK_MODAL_ID = "link-modal";

const LinkModal: React.FC<LinkModalProps> = ({ disclosure, isSelectionOverLink, defaultValues, editor }) => {
  const [link, setLink] = React.useState(defaultValues.link);

  const saveLink = () => {
    // empty
    if (!link || link === "") {
      removeLink();
      return;
    }
    // update link
    // @ts-ignore
    editor.chain().focus().extendMarkRange("link").setLink({ href: link }).run();
    // Close the modal
    disclosure.onClose();
  };

  const removeLink = () => {
    // @ts-ignore
    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    // Close the modal
    disclosure.onClose();
  };

  useEffect(() => {
    setLink(defaultValues.link);
  }, [defaultValues.link]);

  return (
    <CustomModal
      title={isSelectionOverLink ? "Edit link" : "Add link"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      trapFocus={false}
      modalId={LINK_MODAL_ID}
    >
      <CoreInput
        my={3}
        label="Link"
        placeholder="https://google.com"
        value={link}
        onChange={(e: any) => setLink(e.target.value)}
      />
      <ButtonGroup display="block" textAlign="right">
        {isSelectionOverLink && (
          <Button colorScheme="red" onClick={removeLink}>
            Remove
          </Button>
        )}
        <Button colorScheme="brand" onClick={saveLink}>
          Save
        </Button>
      </ButtonGroup>
    </CustomModal>
  );
};
