import { Box } from "@chakra-ui/react";
import { GoogleAddress, MAP_TYPE, removeFarMarkers } from "@lato/common";
import React from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { GoToCoordinates } from "../../features/map/Maps";
import { mapTypeApi, mapTypeAttribution } from "./staticMap";

export interface MarkerMapsProps {
  children?: React.ReactNode;
  allMarkerPositions?: { lat: number; lng: number }[];
  onMapClicked?: (coordinates: number[], address?: GoogleAddress, locationName?: string) => void;
  mapType: MAP_TYPE;
  focusCoordinates?: number[];
}

// Map handles showing, creating, editing and deleting markers on the map
const MarkerMaps: React.FC<MarkerMapsProps> = ({
  children,
  allMarkerPositions = [],
  onMapClicked,
  mapType,
  focusCoordinates,
}) => {
  const [map, setMap] = React.useState<L.Map>();
  const LocationFinder = () => {
    useMapEvents({
      click(e) {
        const lng = ((360 + ((e.latlng.lng + 180) % 360)) % 360) - 180;
        const lat = e.latlng.lat;
        onMapClicked && onMapClicked([lng, lat]);
      },
    });
    return null;
  };
  const currentTileLayer =
    mapType === MAP_TYPE.GOOGLE ? (
      <ReactLeafletGoogleLayer apiKey={mapTypeApi[mapType]} />
    ) : (
      <TileLayer attribution={mapTypeAttribution[mapType]} url={mapTypeApi[mapType]} />
    );

  const onMapInit = (mapp: any) => {
    if (mapp && !map) {
      setMap(mapp);
    }
  };

  const bounds = removeFarMarkers(allMarkerPositions);

  // Get center of all markers
  const center = allMarkerPositions?.length === 1 ? allMarkerPositions[0] : undefined;
  const zoom = center ? 12 : allMarkerPositions.length > 0 ? 1 : 2;

  return (
    <Box w="100%" h="100%" pos="relative">
      <MapContainer
        center={center ?? { lat: 0, lng: 0 }}
        style={{
          height: "auto",
          width: "100%",
          minHeight: "100%",
          objectFit: "cover",
          borderRadius: "0.4em",
          zIndex: "5",
        }}
        zoom={zoom}
        ref={onMapInit}
      >
        {currentTileLayer}
        <LocationFinder />
        <MarkerClusterGroup chunkedLoading showCoverageOnHover={false}>
          {children}
        </MarkerClusterGroup>
        <GoToCoordinates centerPoint={focusCoordinates} zoom={10} bounds={bounds} />
      </MapContainer>
    </Box>
  );
};
export default MarkerMaps;
