import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  Code,
  Divider,
  Flex,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { logout } from "../../../features/menu/horizontal/HorizontalTopMenuBar";
import GeneralEmailForm from "../../form/GeneralEmailForm";
import Section from "../../layout/Section";
import { ADMIN_MAIL } from "@lato/common";

interface DeleteAccountProps {
  userEmail: string;
  userId: string;
}

const DeleteAccount: React.FC<DeleteAccountProps> = ({ userEmail, userId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required("Email confirmation is required")
          .email()
          .test({
            name: "email confirmation",
            message: "Incorrect email",
            test: function () {
              const { email } = this.parent;
              return email === userEmail;
            },
          }),
      }),
    [],
  );

  const successCallback = React.useCallback(async () => {
    await logout(navigate, queryClient);
  }, [navigate, queryClient]);

  const deleteAccount = async () => {
    // alert("TODO: Delete account!");
    // return await UsersAPI.deleteUser(userId);
  };

  return (
    <Section title="Delete Account" h="auto">
      <Text>
        Account deletion is final, there is no way to restore your account. All your data and trips will be lost.
      </Text>
      <Flex>
        <Tooltip label={"Contact " + ADMIN_MAIL} aria-label="contact-admin-tooltip">
          <Button colorScheme="red" onClick={onOpen} mt={2} isDisabled>
            Delete account
          </Button>
        </Tooltip>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        isCentered
        motionPreset="slideInBottom"
        size="lg"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Account
              <Divider mt={2} />
            </AlertDialogHeader>
            <AlertDialogBody>
              If you delete your account:
              <UnorderedList spacing={2} mt={2}>
                <ListItem>
                  All of your trips will be deleted immediately, along with all of your messages, images, documents and
                  points of interest.{" "}
                </ListItem>
                <ListItem>You will get removed from the company you belong to.</ListItem>
                <ListItem>Your team members and clients will no longer be able to see any of your data.</ListItem>
                <ListItem>
                  You will no longer have access to LATO. In order to regain access, you should create a new account
                  possibly with the same email address.
                </ListItem>
              </UnorderedList>
              <Text size="sm" my={3} color="red.500" fontWeight="500">
                {`You can't undo this action.`}
              </Text>
              <Text mb={2}>
                Enter your email address to confirm: <Code>{userEmail}</Code>
              </Text>
              <GeneralEmailForm
                successCallback={successCallback}
                buttonText="Delete"
                apiCall={deleteAccount}
                successMessage="deleted account"
                failMessage="deleting account"
                validationSchema={validationSchema}
                customButtonLayout={(isSubmitting, isValid) => (
                  <Flex mt={7} mb={2}>
                    <ButtonGroup size="md" ml="auto">
                      <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button type="submit" colorScheme="red" ml={3} isLoading={isSubmitting} isDisabled={!isValid}>
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Flex>
                )}
              />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Section>
  );
};
export default DeleteAccount;
