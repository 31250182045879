import { TranslationField } from "@lato/common";
import { default as React } from "react";
import { getFromTripStore } from "../stores/trip/tripFormStore";

export function updateRHFValues(getValues: any, reset: any) {
  // onBlur handler of the fields beneath to update the trip values
  const { trip } = getValues();
  reset(trip, {
    keepErrors: true, // errors will not be reset
    keepDirty: true, // dirtyFields will not be reset
    keepIsSubmitted: true,
    keepTouched: true,
    keepIsValid: true,
    keepSubmitCount: true,
    // Maybe add keepDefaultValues
  });
}

export function updateTranslateField(fieldName: string, value: string, setValue: any) {
  // Only insert the original field value into the translate field value when the translate field value is empty in order to not override changes.
  setValue(fieldName, value);
}

export const createNewTranslationFields = (translations: TranslationField[]): TranslationField[] => {
  const translationFields: TranslationField[] = translations.map((tf) => ({
    content: "",
    creator: tf.creator,
    language: tf.language,
    language_code: tf.language_code,
  }));
  return translationFields;
};

export function originalToTranslateOnBlur(
  e: React.FocusEvent<HTMLInputElement>,
  fieldName: string,
  reset: any,
  setValue: any,
) {
  // Update the according translate field.
  updateTranslateField(fieldName, e.target.value, setValue);
  // Update the subscribers.
  updateRHFValues(getFromTripStore, reset);
}
