import TripsOverViewStep from "../../../components/trips/edit/overviewStep/TripsOverViewStep";
import SampleStepWrapper from "../SampleStepWrapper";

export const OverviewSampleRoute: React.FC = () => {
  const stepNumber = 1;

  return (
    <SampleStepWrapper>
      <TripsOverViewStep stepNumber={stepNumber} />
    </SampleStepWrapper>
  );
};

export default OverviewSampleRoute;
