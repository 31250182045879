import { Flex, Tooltip } from "@chakra-ui/react";
import { Language, TRIP_STATUS, Trip, TripDay, User, UserTrip } from "@lato/common";
import clsx from "clsx";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMeContext } from "../../../stores/me-context";
import { useTripLanguage } from "../../../stores/trip-language-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import { useTripDayIndex } from "../../../components/trips/edit/daybyday/hooks/useTripDayIndex";
import { canSwitch, submitForms } from "../../../utils/EventHelper";
import StepperElement from "./StepperElement";
import { WizardTitle } from "./WizardTitle";
import { tripStatusColorMap } from "../../tripList/TripsList";

interface WizardTopBarProps {
  steps: any;
  activeStep: number;
  invalidStep?: number | undefined;
  first: boolean;
  collaboratorCanEdit: boolean;
  userTripId: string;
  type: string;
  trip: Trip;
  usertrips: UserTrip[];
}

export const stepNames = ["", "overview", "day-by-day", "price", "share"];
export const stepNamesNew = ["", "overview", "day-by-day", "price", "tasks", "notes", "share", "settings"];
export const stepNamesSamplesNew = ["", "overview", "day-by-day", "price", "tasks", "share", "settings"];

export const getRoute = (usertripId: string, stepNumber: number, me: User, dayIndex?: number, isSample?: boolean) => {
  console.log("tt route", usertripId, stepNumber, me, dayIndex, isSample);
  return `/${isSample ? "samples" : "trips"}/${usertripId}/${me.useNewEditTripLayout ? (isSample ? stepNamesSamplesNew[stepNumber] : stepNamesNew[stepNumber]) : stepNames[stepNumber]}${dayIndex ? `/${dayIndex}` : ""}`;
};

export const displayBookedOptionBubble = (value: string) => {
  return (
    <Tooltip label={value}>
      <div className={clsx(`bg-${tripStatusColorMap[value as TRIP_STATUS]}-300`, "p-1 rounded-full")} />
    </Tooltip>
  );
};

const WizardTopBar: React.FC<WizardTopBarProps> = ({
  steps,
  activeStep,
  invalidStep,
  first,
  collaboratorCanEdit,
  userTripId,
  trip,
  usertrips,
}) => {
  const me = useMeContext();
  const navigate = useNavigate();
  const { isLoadingTranslations, isSubmittingForms, isInvalidForms, isErrorForms, isSample } = useTripFormStore();
  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();
  const tripdayIndex = useTripDayIndex();

  const title = trip?.titles?.[first ? firstSelectedLanguageIndex : (secondSelectedLanguageIndex ?? 0)]?.content || "";
  const client_name = trip?.client_name || "";
  const tripdays: TripDay[] = trip?.tripdays || [];
  const start_date = trip?.start_date || "";
  const end_date = trip?.end_date || "";
  const nrOfNights = tripdays.reduce((partialSum, td) => partialSum + td.nrOfNights, 0);

  const usertrip: UserTrip | null = usertrips.find((ustr: UserTrip) => ustr.id === userTripId) || null;
  const external_ref = usertrip?.ref;
  const status = usertrip?.status;

  const handleStep = (step: number) => async () => {
    if (step !== activeStep && canSwitch(isErrorForms, isInvalidForms)) {
      await submitForms(isSubmittingForms);
      if (canSwitch(isErrorForms, isInvalidForms)) {
        navigate(getRoute(userTripId, step, me, step === 2 ? tripdayIndex : undefined, isSample));
      }
    }
  };

  const totalSteps = steps.length;

  const wizardTitleProps = {
    title,
    client_name,
    external_ref,
    first,
    nrOfNights,
    status,
    start_date: start_date,
    isLoadingTranslations: isLoadingTranslations,
  };

  return (
    <Flex
      flexDir={{ base: "column", lg: "row" }}
      alignItems={{ base: "left", lg: "center" }}
      justifyContent="space-between"
      mb={{ base: "0", lg: "5" }}
    >
      <WizardTitle {...wizardTitleProps} />
      <Flex
        minW={{ base: "auto", lg: "380px" }}
        w={{ base: "100%", lg: "40%" }}
        maxW="520px"
        justifyContent="space-between"
        align="start"
        mt={{ base: "5", lg: "0" }}
        ml={"auto"}
        mr={"auto"}
      >
        {steps.map((s: any, index: number) => (
          <StepperElement
            index={index}
            step={s}
            isActive={index === activeStep}
            isError={index === invalidStep}
            isDisabled={
              invalidStep !== undefined
                ? index !== invalidStep
                : false || [...isSubmittingForms.values()].some((value) => value === true)
            }
            totalSteps={totalSteps}
            handleStep={handleStep}
            key={`step-${index}`}
            first={first}
            collaboratorCanEdit={collaboratorCanEdit}
          />
        ))}
      </Flex>
      <WizardTitle
        title={title}
        client_name={client_name}
        status={status}
        refvalue={external_ref}
        first={first}
        nrOfNights={nrOfNights}
        start_date={start_date}
        end_date={end_date}
        hidden
      />
    </Flex>
  );
};
export default React.memo(WizardTopBar);

//used to store values for a new language in the state
//passing this through to DeeplModal
export type NewLanguage = {
  id: string;
  language: Language;
  copy: boolean;
  reset: any;
  index: number;
  allowDeepl?: boolean;
};
