import { Trip } from "@lato/common";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import ChatGptAPI from "../../api/chatgpt.api";
import CompanyAPI from "../../api/companies.api";
import FollowingStatusAPI from "../../api/followingstatus.api";
import TripsAPI from "../../api/trips.api";
import UserTripsAPI from "../../api/usertrips.api";
import { ME_QUERY_KEY, SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../constants";

/**
 * React query mutation to either follow or unfollow a specific company.
 * @param follow a boolean indicating whether to follow or to unfollow a company.
 * @returns
 */
export const useFollowOrUnfollowCompanyMutation = (follow: boolean) => {
  const followOrUnfollow = follow ? FollowingStatusAPI.followCompany : FollowingStatusAPI.unfollowCompany;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (selectedCompanyId: string) => followOrUnfollow(selectedCompanyId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companies"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getFollowing"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getFollowers"],
      });
    },
  });
};

/**
 * React query mutation to ask a question to ChatGPT.
 * @param
 * @returns
 */
export const useChatGPTPrompt = (options?: Omit<UseMutationOptions<any, any, any, any>, "mutationFn">) => {
  return useMutation({
    mutationFn: (object: { prompt: string; text: string; languageCode?: string; brandVoice?: string }) =>
      ChatGptAPI.askQuestion(object),
    ...options,
  });
};

export const useMapSettingsMutation = (options?: Omit<UseMutationOptions<any, any, any, any>, "mutationFn">) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: CompanyAPI.updateCompany,

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },

    ...options,
  });
};

/**
 * React query mutation to send a traveller mail.
 * @param
 * @returns
 */
export const useSendTripMail = (userTripId: string) => {
  return useMutation({ mutationFn: (model: object) => UserTripsAPI.sendTripMail(userTripId, model) });
};

/**
 * React query mutation to convert a .PDF, .DOCX or .TXT trip to a lato trip.
 * @param
 * @returns
 */
export const useAIPDFReader = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formdata: FormData) => ChatGptAPI.createTripFromPDF(formdata),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });
};

export const useDeleteUsertrip = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userTripId: string) => UserTripsAPI.deleteUserTrip(userTripId),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });
};

export const useCreateTrip = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newTrip: Trip) => await TripsAPI.createTrip(newTrip),
    onSuccess: () => {
      // Invalidate the list of trips
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });
};
