import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import EventsAPI from "../../../../../../api/events.api";
import { TripLanguageProvider } from "../../../../../../stores/trip-language-context";
import { TRIPDAY_ACTIVITY_QUERY_KEY } from "../../../../../../utils/constants";
import TranslateTitle from "../../../../../input/TranslateTitle";
import LibraryItemDownshift, { LIBRARYITEM_TYPE } from "../LibraryItemDownshift";
import { EventStaticMap } from "./EventStaticMap";
import { Descriptions } from "../hotel/Descriptions";

interface EventFormOutsideTripProps {
  fieldName: string;
  selectedLanguageIndex: number;
  unique: any;
  updateActivity: (e: any) => void;
  forceNew: boolean;
}
export const EventFormOutsideTrip: React.FC<EventFormOutsideTripProps> = ({
  fieldName,
  selectedLanguageIndex,
  unique,
  updateActivity,
  forceNew,
}) => {
  const { getValues, reset } = useFormContext<any>();
  const [id, setId] = useState(getValues("id"));
  const [forceNewState, setforceNewState] = useState(forceNew);
  const queryClient = useQueryClient();

  const translationFields = getValues(fieldName);
  return (
    <>
      <TripLanguageProvider doubleFields={false} first={true} defaultPartialFields={translationFields}>
        <div className="mt-4">
          <TranslateTitle
            isFirst={true}
            name={fieldName}
            getValues={getValues}
            elementId={id}
            forceNew={forceNew}
            addLanguageCall={async (id: string, code: string, translateFields: any) => {
              const formValues = getValues();
              let response;
              if (!id || forceNewState) {
                response = await EventsAPI.post({ ...formValues, id: forceNew ? undefined : id });
                id = response.id;
                setforceNewState(false);
              } else {
                await EventsAPI.patch(id, formValues);
              }
              setId(id);
              response = await EventsAPI.addLanguage(id, code, translateFields);
              if (!forceNew) {
                queryClient.setQueryData([TRIPDAY_ACTIVITY_QUERY_KEY, id], response.element);
                updateActivity(response.element);
              }
              reset(response.element);
            }}
            removeLanguageCall={async (i: number, code: string) => {
              const formValues = getValues();
              let id = formValues.id;
              let response;
              if (!id || forceNewState) {
                response = await EventsAPI.post({ ...formValues, id: forceNew ? undefined : id });
                id = response.id;
                setforceNewState(false);
              } else {
                await EventsAPI.patch(id, formValues);
              }
              setId(id);
              response = await EventsAPI.removeLanguage(id, code);
              if (!forceNew) {
                queryClient.setQueryData([TRIPDAY_ACTIVITY_QUERY_KEY, id], response.element);
                updateActivity(response.element);
              }
              reset(response.element);
            }}
          />
        </div>
        <LibraryItemDownshift
          inTrip={false}
          outsideTripLibraryitem={getValues()}
          libraryItemName={LIBRARYITEM_TYPE.EVENTS}
          first={true}
          getValues={getValues}
          uniqueId={unique}
          location={null}
          collaboratorCanEdit={false}
          reset={updateActivity}
          placeholder="Activity name"
        />
        <Descriptions fieldName={fieldName} elementType="event" />
      </TripLanguageProvider>
    </>
  );
};
