import { Directions, MAP_TYPE, TRANSPORTATION_TYPE, Coord } from "@lato/common";
import { useQuery } from "@tanstack/react-query";
import { mapTypeKey } from "../../components/map/staticMap";

export interface useDirectionsProps {
  center?: Coord | null;
  second?: Coord | null;
  markerPositions?: number[][];
  transportation_type: TRANSPORTATION_TYPE;
  simplified?: boolean;
  polyline?: boolean;
}

function getTransportation(transportation_type: TRANSPORTATION_TYPE) {
  return transportation_type === TRANSPORTATION_TYPE.BIKE
    ? "cycling"
    : transportation_type === TRANSPORTATION_TYPE.FOOT
      ? "walking"
      : "driving";
}

export const useDirections = ({
  center,
  second,
  markerPositions,
  transportation_type,
  simplified,
  polyline,
}: useDirectionsProps) => {
  const mapbox_key = mapTypeKey[MAP_TYPE.MAPBOX];

  const { isLoading, data, isError, refetch } = useQuery<Directions | Directions[]>({
    queryKey: [`directions${getTransportation(transportation_type)}`, center, second, markerPositions],
    queryFn: async () => {
      const co = markerPositions
        ?.map((pos) => {
          return `${pos[0]},${pos[1]}`;
        })
        .join(";");

      const res = await fetch(
        markerPositions
          ? `https://api.mapbox.com/directions/v5/mapbox/${getTransportation(
              transportation_type,
            )}/${co}?&geometries=geojson&overview=full&access_token=${mapbox_key}`
          : `https://api.mapbox.com/directions/v5/mapbox/${getTransportation(transportation_type)}/${center.lng},${
              center!.lat
            };${second!.lng},${second?.lat}?&geometries=${polyline ? "polyline" : "geojson"}&overview=${
              simplified ? "simplified" : "full"
            }&access_token=${mapbox_key}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      );
      const response: Directions = await res.json();

      if (response.code === "NoRoute" || response.code === "InvalidInput") {
        // Route was not found, split up the route and try fetching seperately
        // InvalidInput is returned if too many points are provided

        if (markerPositions) {
          const separateDirections = await Promise.all<Promise<Directions | undefined>>(
            markerPositions.map(async (markerPosition, i) => {
              if (i === markerPositions.length - 1) return;
              const co = `${markerPositions[i][0]},${markerPositions[i][1]};${markerPositions[i + 1][0]},${
                markerPositions[i + 1][1]
              }`;

              const res = await fetch(
                `https://api.mapbox.com/directions/v5/mapbox/${getTransportation(
                  transportation_type,
                )}/${co}?&geometries=geojson&overview=full&access_token=${mapbox_key}`,
                {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                },
              );
              const response: Directions = await res.json();
              return response;
            }),
          );

          return separateDirections.filter((d) => d) as Directions[];
        } else {
          return [];
        }
      }

      if (response.code !== "Ok") {
        throw new Error("Could not get directions");
      }

      return response;
    },
    enabled:
      ((!!center && !!second) || (markerPositions && markerPositions.length > 1)) &&
      ![TRANSPORTATION_TYPE.FLIGHT, TRANSPORTATION_TYPE.TRAIN].includes(transportation_type),
    staleTime: Infinity,
    retry: 1,
  });

  return { isLoading, data, isError, refetch };
};
