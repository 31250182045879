import { Image, POIType, TLibraryImage, TLocation, TranslationField } from "@lato/common";
import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "pois";

// Get the standard CRUD operations
const basicPOIsEndpoints = getApiEndpoints({
  url,
});

// Add language to poi
const addLanguage = (
  id: string,
  language_code: string,
  translateFields?: {
    targetLanguage: string;
    sourceLanguage?: string;
    translateFromUserCompanyId?: string;
  },
) => {
  return rawApiProvider.post(`${url}/${id}/addLanguage`, {
    language_code,
    translateFields,
  });
};

//remove language from poi
const removeLanguage = (id: string, language_code: string) => {
  return rawApiProvider.post(`${url}/${id}/removeLanguage`, {
    language_code,
  });
};

export type POIsAPIType = ReturnEndpoints & {
  addLanguage: (
    id: string,
    language_code: string,
    translateFields?: {
      targetLanguage: string;
      sourceLanguage?: string;
      translateFromUserCompanyId?: string;
    },
  ) => Promise<any>;
  removeLanguage: (id: string, language_code: string) => Promise<any>;
};
const POIsAPI: POIsAPIType = {
  ...basicPOIsEndpoints,
  addLanguage,
  removeLanguage,
};
export default POIsAPI;

export type POI = {
  id?: string;
  names: TranslationField[];
  descriptions: TranslationField[];
  location: TLocation;
  coordinatesDepricated: number[] | null;
  type: POIType;
  images: Image[];
  libraryImages: TLibraryImage[];
};
