import { Avatar, Badge, Box, Flex, Popover, PopoverTrigger, Text, useDisclosure } from "@chakra-ui/react";
import { Contact, PRIORITY, TASK_STATUS, Task, User, UserTrip } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { Column, Row } from "react-table";
import { USERTRIP_TASKS_QUERY_KEY } from "../../utils/constants";
import { useMyTeam } from "../../utils/query-helpers/reactQueryHooks";
import { useRemoveTask } from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { PaginationOptions } from "../../utils/query-helpers/usePaginatedQuery";
import sanitizeXSS, { removeAllHTMLTags } from "../../utils/sanitizeXSS";
import MultiSelectColumnFilter from "../../components/CRUD/MultiSelectColumnFilter";
import { TaskOptions } from "./TaskOptions";
import TaskPopover from "../calenderOverview/TaskPopover";
import ConfirmModal from "../../components/modals/ConfirmModal";
import CRUDResource from "../../components/CRUD/Resource";
import { PrioritySelect } from "./PrioritySelect";
import { StatusSelect } from "./StatusSelect";
import TaskModal from "./TaskModal";
import { UserSelect } from "./UserSelect";

export const taskStatusColorMap: { [key in TASK_STATUS]: string } = {
  [TASK_STATUS.TO_DO]: "gray",
  [TASK_STATUS.DONE]: "green",
};

export const taskPriorityColorMap: { [key in PRIORITY]: string } = {
  [PRIORITY.URGENT]: "red",
  [PRIORITY.HIGH]: "orange",
  [PRIORITY.NORMAL]: "blue",
  [PRIORITY.LOW]: "gray",
};

interface TasksTableProps {
  tasks?: Task[];
  listName: string;
  fetchingTasks: boolean;
  loadingTasks: boolean;
  initialTableState?: PaginationOptions;
  triggerFetch?: any;
  refetch?: any;
  totalCount: number;
  resetFilters?: () => void;
  updateTask: (task: Task) => Promise<void>;
  members?: User[];
  tripStartDate?: string;
  tripEndDate?: string;
  userTrip?: UserTrip;
  isInTrip?: boolean;
  contact?: Contact;
  queryKey?: string;
  isSample?: boolean;
  isInCollapsableView?: boolean;
  disclosure?: any;
  showOpenContactOption?: boolean;
  showOpenTripOption?: boolean;
  showRemoveFromTripOption?: boolean;
}

export const TasksTable: React.FC<TasksTableProps> = ({
  tasks,
  listName,
  fetchingTasks,
  loadingTasks,
  initialTableState,
  triggerFetch,
  refetch,
  totalCount,
  resetFilters,
  updateTask,
  members,
  tripStartDate,
  tripEndDate,
  userTrip,
  isInTrip,
  contact,
  queryKey = USERTRIP_TASKS_QUERY_KEY,
  isSample = false,
  isInCollapsableView = false,
  disclosure,
  showOpenContactOption = true,
  showOpenTripOption = true,
  showRemoveFromTripOption = false,
}) => {
  const queryClient = useQueryClient();
  const editDisclosure = disclosure ?? useDisclosure();
  const deleteModalDisclosure = useDisclosure();

  const [selectedTask, setSelectedTask] = useState<Task>();

  const { data: users } = useMyTeam();

  const { mutateAsync: deleteTask, isPending: isDeleting } = useRemoveTask(queryClient);

  const createUserOptions = React.useCallback(() => {
    // If the members didn't load yet, return an empty set.
    if (!members) return new Set<string>();
    return new Set(members.map((m) => m.id!));
  }, [members]);

  const displayUserOption = React.useCallback(
    (userId: string): React.ReactElement => {
      // Find a single usertrip of this user to get the user object
      const user = members?.find((u) => u.id! === userId);
      // If the members didn't load yet or somehow no user was found, return an empty fragment.
      if (!user) return <></>;
      return (
        <Flex alignItems="center">
          <Avatar size="xs" name={user.name} src={user.avatar.key ? user.avatarUrl : ""} mr={2} />
          <span>{user.name}</span>
        </Flex>
      );
    },
    [members],
  );

  const createStatusOptions = () => {
    return new Set(Object.values(TASK_STATUS));
  };

  const displayStatusOption = (value: string) => {
    return (
      <Badge colorScheme={taskStatusColorMap[value as TASK_STATUS]} title={value}>
        {value}
      </Badge>
    );
  };

  const createPriorityOptions = () => {
    return new Set(Object.values(PRIORITY));
  };

  const displayPriorityOption = (value: string) => {
    return (
      <Badge colorScheme={taskPriorityColorMap[value as PRIORITY]} title={value}>
        {value}
      </Badge>
    );
  };

  const handleChangeTaskStatus = React.useCallback(
    async (index: number, newStatus: TASK_STATUS) => {
      tasks && tasks[index] && (await updateTask({ ...tasks[index], status: newStatus }));
    },
    [tasks, updateTask],
  );

  const handleChangeTaskPriority = React.useCallback(
    async (index: number, newPriority: PRIORITY) => {
      tasks && tasks[index] && (await updateTask({ ...tasks[index], priority: newPriority ?? null }));
    },
    [tasks, updateTask],
  );

  const handleChangeTaskAssignee = React.useCallback(
    async (index: number, newAssignee: User) => {
      tasks && tasks[index] && (await updateTask({ ...tasks[index], user: newAssignee ?? null }));
    },
    [tasks, updateTask],
  );

  const allColumns: Column<Task>[] = React.useMemo(
    () => [
      {
        id: "status",
        accessor: "status",
        chakraWidth: "5%",
        Cell: ({ value, row }: { value: TASK_STATUS; row: Row<Task> }) => {
          return (
            <StatusSelect
              selectedStatus={value}
              handleChangeStatus={async (status: TASK_STATUS) => {
                await handleChangeTaskStatus(row.index, status);
              }}
            />
          );
        },
        Filter: (props) => {
          return (
            <MultiSelectColumnFilter
              {...props}
              createOptions={createStatusOptions}
              displayOption={displayStatusOption}
            />
          );
        },
        disableFilters: isInTrip,
      },
      {
        id: "priority",
        accessor: "priority",
        chakraWidth: "5%",
        Cell: ({ value, row }: { value: PRIORITY; row: Row<Task> }) => {
          return (
            <PrioritySelect
              selectedPriority={value}
              isWideLabel={false}
              handleChangePriority={(priority: PRIORITY) => handleChangeTaskPriority(row.index, priority)}
              isInModal={false}
            />
          );
        },
        Filter: (props) => {
          return (
            <MultiSelectColumnFilter
              {...props}
              createOptions={createPriorityOptions}
              displayOption={displayPriorityOption}
            />
          );
        },
        disableFilters: isInTrip,
      },
      {
        id: "users",
        accessor: "user",
        chakraWidth: "5%",
        Cell: ({ value, row }: { value: User; row: Row<Task> }) => {
          return (
            <Box className="flex justify-center">
              <UserSelect
                handleUserSelect={(user: User) => handleChangeTaskAssignee(row.index, user)}
                selectedUser={value}
                users={users}
                maxAmountUsers={1}
                isWideLabel={false}
                name={""}
                isRequired={false}
                isInModal={false}
              />
            </Box>
          );
        },
        Filter: (props) => {
          return (
            <MultiSelectColumnFilter
              {...props}
              displayOption={displayUserOption}
              createOptions={createUserOptions}
              isDisabled={false}
            />
          );
        },
        disableFilters: isInTrip,
        disableSortBy: true,
      },
      {
        id: "date",
        accessor: "due_date",
        Header: "Due Date",
        chakraWidth: "20%",
        Cell: ({ value, row }: { value: any; row: Row<Task> }) => {
          return (
            <Flex
              gap={2}
              onClick={() => {
                setSelectedTask(row.original);
                editDisclosure.onOpen();
              }}
            >
              <Text
                color={
                  row.original.status === TASK_STATUS.TO_DO && new Date(value) < new Date() ? "red.500" : "inherit"
                }
              >
                {isSample
                  ? `${row.original.due_days} days ${row.original.due_days_type.toLowerCase()}`
                  : new Date(value).toDateString()}
              </Text>
            </Flex>
          );
        },
      },
      {
        id: "title",
        accessor: "title",
        Header: "Title",
        chakraWidth: "20%",
        Cell: ({ value, row }: { value: string; row: Row<Task> }) => {
          return (
            <Box
              height="100%"
              width="100%"
              onClick={() => {
                setSelectedTask(row.original);
                editDisclosure.onOpen();
              }}
            >
              {value}
            </Box>
          );
        },
      },
      {
        id: "description",
        accessor: "description",
        Header: "Description",
        chakraWidth: "200px",
        chakraMaxWidth: "200px",
        Cell: ({ row, value }: { value: string; row: Row<Task> }) => {
          return (
            <p
              className="truncate"
              onClick={() => {
                setSelectedTask(row.original);
                editDisclosure.onOpen();
              }}
              dangerouslySetInnerHTML={{ __html: sanitizeXSS(removeAllHTMLTags(value)) }}
            />
          );
        },
      },
      {
        id: "options",
        accessor: "options",
        chakraWidth: "5%",
        Cell: ({ row }: { row: Row<Task> }) => {
          return (
            <TaskOptions
              task={row.original}
              onEditClick={() => {
                setSelectedTask(row.original);
                editDisclosure.onOpen();
              }}
              onDeleteClick={async () => {
                setSelectedTask(row.original);
                deleteModalDisclosure.onOpen();
              }}
              updateTask={updateTask}
              showOpenContactOption={showOpenContactOption}
              showOpenTripOption={showOpenTripOption}
              showRemoveFromTripOption={showRemoveFromTripOption}
            />
          );
        },
      },
    ],
    [
      isInTrip,
      handleChangeTaskStatus,
      handleChangeTaskPriority,
      users,
      handleChangeTaskAssignee,
      displayUserOption,
      createUserOptions,
      editDisclosure,
      deleteModalDisclosure,
    ],
  );

  const taskCalendarPopover = (eventInfo: any) => {
    const event = eventInfo.event;
    const task = event.extendedProps as Task;

    return (
      <Popover placement="top" closeOnBlur={true} isLazy>
        <PopoverTrigger>
          <div
            className={clsx(
              `border-${task.status === TASK_STATUS.DONE ? "green" : "gray"}-300`,
              `bg-${task.status === TASK_STATUS.DONE ? "green" : "gray"}-50`,
              "mx-1 h-4.5 w-3/4 z-10 border-l-8 rounded-sm shadow-sm border-y-0 border-r-0 text-black",
            )}
          >
            <span className="flex justify-between ml-2 pt-0.5 text-black font-medium my-auto">
              <span className="truncate w-4/5 text-xs">{event.title}</span>
            </span>
          </div>
        </PopoverTrigger>
        <TaskPopover
          task={task}
          listView={false}
          onDeleteClick={(task: Task) => {
            setSelectedTask(task);
            deleteModalDisclosure.onOpen();
          }}
          onEditClick={(task: Task) => {
            setSelectedTask(task);
            editDisclosure.onOpen();
          }}
          calendarView
        />
      </Popover>
    );
  };

  const calendarItems = useMemo(() => {
    return (
      tasks &&
      tasks?.map((task: Task) => {
        return {
          title: task.title,
          start: task.due_date,
          status: task.status,
          id: task.id,
          extendedProps: task,
          editable: false,
        };
      })
    );
  }, [tasks]);

  const rowProps = () => ({
    _hover: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  });

  return (
    <>
      <CRUDResource
        formName={listName}
        data={tasks ?? []}
        fetchingData={fetchingTasks}
        loadingData={loadingTasks}
        columns={allColumns as Column<any>[]}
        handleAdd={() => {
          editDisclosure.onOpen();
        }}
        getRowProps={rowProps}
        initialTableState={initialTableState}
        globalSearch={!isInCollapsableView && !isInTrip}
        triggerFetch={triggerFetch}
        totalCount={totalCount}
        enableCalendarView={!isInCollapsableView}
        showCalendarTypeButtons={false}
        calendarItems={calendarItems}
        popoverMonthGrid={taskCalendarPopover}
        minTableHeight={"7em"}
        showHeaders={true}
        heading={isInCollapsableView ? <></> : undefined}
        showHeading={!isInCollapsableView}
        useSpaciousLayout
        filtersOnNextLine
        filterWidth={"70%"}
        resetFilters={resetFilters}
        disableFilterSave={isInTrip}
        showPagination={!isInTrip}
        showViewSwitch={!isInTrip && !isInCollapsableView}
        inForm={isInCollapsableView}
      />
      {(selectedTask || editDisclosure.isOpen) && (
        <TaskModal
          onSubmitTask={triggerFetch}
          modalDisclosure={editDisclosure}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          start_date={tripStartDate ?? selectedTask?.trip?.trip?.start_date}
          end_date={tripEndDate ?? selectedTask?.trip?.trip?.end_date}
          userTrip={userTrip}
          contact={contact}
          isInTrip={isInTrip}
          refetch={refetch}
          queryKey={queryKey}
          isSample={isSample}
        />
      )}
      {deleteModalDisclosure.isOpen && (
        <ConfirmModal
          title={"Delete task"}
          description={`Are you sure you want to delete this task?`}
          action={async () => {
            await deleteTask(selectedTask);
            refetch();
          }}
          disclosure={deleteModalDisclosure}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};
