import { Box, BoxProps, Divider, Flex, HStack, Heading, Progress } from "@chakra-ui/react";
import React from "react";
type SectionProps = Omit<BoxProps, "title"> & {
  title?: any;
  heading?: React.ReactElement;
  // Extra prop to add elements to the right of the title
  headingExtra?: React.ReactElement;
  noDivider?: boolean;
  footer?: React.ReactElement;
  noHorizontalPadding?: boolean;
  noVerticalPadding?: boolean;
  noBorderRadius?: boolean;
  noShadow?: boolean;
  inForm?: boolean;
  deepestBoxClassname?: string;
  noBackground?: boolean;
  isLoading?: boolean;
  ref?: any;
  height?: string;
};

// If you change this: also change the pr and pl props in Th and Td in Table Component and in all the components where 'noHorizontalPadding' prop is used.
export const horizontalSectionPadding = 7;

const Section: React.FC<SectionProps> = ({
  children,
  title,
  heading,
  headingExtra,
  noDivider = false,
  noHorizontalPadding = false,
  noVerticalPadding = false,
  noBorderRadius = false,
  noShadow = false,
  inForm = false,
  noBackground = false,
  footer,
  deepestBoxClassname,
  isLoading = false,
  ref,
  height,
  ...props
}) => {
  return (
    // <Flex
    //   bg="white"
    //   flexDir="column"
    //   borderRadius="md"
    //   shadow="md"
    //   mt={5}
    //   overflow="hidden"
    //   w="100%"
    // >
    //   <Box bg="gray.100" overflow="hidden" p={3}>
    //     <Heading size="lg">{title}</Heading>
    //   </Box>
    //   <Divider />
    //   <Box h="100%" p={7} px={10} overflow="hidden">
    //     {children}
    //   </Box>
    // </Flex>
    <Flex
      flexDir="column"
      bg={noBackground ? undefined : "white"}
      h={height}
      borderRadius={noBorderRadius ? undefined : "xl"}
      // shadow={noShadow ? undefined : "0 2px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"}
      py={inForm ? 0 : 5}
      flex="0 0 auto"
      border="1px solid"
      borderColor={"lightGray.400"}
      ref={ref}
      {...props}
    >
      <HStack>
        {(title || heading) && (
          <Box w="100%" px={inForm ? 0 : horizontalSectionPadding}>
            {title ? (
              <Heading size="lg" color="realGray.800">
                {title}
              </Heading>
            ) : (
              heading
            )}
          </Box>
        )}
        {headingExtra && <Box pe={"2em"}>{headingExtra}</Box>}
      </HStack>
      {!noDivider && !isLoading && <Divider mt={2} mb={3} borderWidth="1px" borderColor="lightGray.200" />}
      {isLoading && <Progress mt={2} mb={3} height={"2px"} colorScheme="brand" isIndeterminate />}
      <Box w="100%" flex={1} minH={0} px={noHorizontalPadding ? 0 : horizontalSectionPadding}>
        <Box w="100%" h="100%" pb={noVerticalPadding ? 0 : "1px"} className={deepestBoxClassname}>
          {children}
        </Box>
      </Box>
      {footer && (
        <Box w="100%" h="100%" px={horizontalSectionPadding}>
          {footer}
        </Box>
      )}
    </Flex>
  );
};
export default Section;
// No React.memo because it contains children and it is rerendered every time the children change?
