export const calculateValue = (index: number, total: number) => {
  const maxValue = 2 ** 15;
  const halfValue = maxValue / 2;

  if (total === 1) {
    return halfValue;
  }

  if (total === 2 && index === 0) {
    return halfValue;
  }

  if (total === 2 && index === 1) {
    return halfValue + halfValue / 2;
  }

  // Calculate the position of the middle index
  const middleIndex = Math.floor(total / 2);

  if (index === middleIndex) {
    return halfValue;
  }

  // Calculate the proportion of the index relative to the middle index
  const proportion = index - middleIndex;

  // Calculate the final value based on the proportion
  let value = halfValue + (halfValue / (total - middleIndex + 1)) * proportion;

  // Ensure the value is within the bounds [0, maxValue]
  value = Math.min(Math.max(value, 0), maxValue);

  return Math.floor(value);
};

export const calculateBetweenValues = (first: number, second: number) => {
  return Math.floor(first + (second - first) / 2);
};
