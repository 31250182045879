import { useEffect } from "react";
import { unstable_usePrompt } from "react-router-dom";

const useUnsavedChangesPrompt = (isDirty: boolean, isTrip?: boolean) => {

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  // In react-router v5 we had a Prompt component but it got removed, they re-added an unstable usePrompt hook in v6
  unstable_usePrompt({
    when: ({ nextLocation }) => {
      if (!isTrip) return isDirty;

      const pattern = /\/(trips|samples)\/.*\/(general|overview|day-by-day|price|share|)/;
      return isDirty && !pattern.test(nextLocation.pathname);
    },
    message: "Are you sure you want to leave? All changes will be lost.",
  });
};

export default useUnsavedChangesPrompt;
