import React, { PropsWithChildren } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";

type FormProps<TFormValues> = Partial<UseFormReturn<TFormValues & FieldValues>> &
  Partial<UseFormProps<TFormValues & FieldValues>> & {
    formMethods: UseFormReturn<TFormValues & FieldValues>;
    onSubmit: SubmitHandler<TFormValues & FieldValues>;
    onError?: SubmitErrorHandler<TFormValues & FieldValues>;
    onKeyDown?: React.KeyboardEventHandler<HTMLFormElement> | undefined;
    autoComplete?: boolean;
    className?: string;
    // children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  };

/**
 * A slightly less performant version of the FlatForm.
 * In FlatForm the register, errors and other form state methods are added to the inputs.
 * While using this form they are added in the RHFInput component itself using useFormContext
 * Which triggers a re-render for every field in the form upon a validation change in any field.
 * This was prevented in RHFFlatForm by using React.memo and only receiving the appropriate error.
 * @param param0
 */
const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  children,
  onSubmit,
  formMethods,
  onError,
  autoComplete = true,
  ...props
}: PropsWithChildren<FormProps<TFormValues>>) => {
  return (
    <FormProvider {...formMethods}>
      <form
        {...props}
        autoComplete={autoComplete ? "on" : "off"}
        // style={{ height: "100%" }}
        onSubmit={formMethods.handleSubmit(onSubmit, onError)}
        // style={{ width: "100%", height: "100%" }}
      >
        {children}
      </form>
    </FormProvider>
  );
};
export default Form;
