import React from "react";
// @ts-ignore

import { POIType } from "@lato/common";
import {
  BiCycling,
  BiSolidCameraMovie,
  BiSolidCar,
  BiSolidCoffeeAlt,
  BiSolidDrink,
  BiSolidShoppingBag,
  BiSolidSpa,
  BiSolidTrain,
} from "react-icons/bi";
import { BsFlagFill, BsPinAngleFill, BsShop } from "react-icons/bs";
import { FaBuilding, FaGolfBall } from "react-icons/fa";
import {
  FaBinoculars,
  FaBridge,
  FaCampground,
  FaCity,
  FaFootball,
  FaMosque,
  FaPersonHiking,
  FaRoute,
  FaUmbrellaBeach,
} from "react-icons/fa6";
import { GiParkBench, GiWaterfall } from "react-icons/gi";
import { HiCamera } from "react-icons/hi";
import {
  MdDownhillSkiing,
  MdForest,
  MdHotel,
  MdLocalAirport,
  MdLocalPharmacy,
  MdOutlineMuseum,
  MdRestaurant,
} from "react-icons/md";
import BoatIcon from "../../assets/icons/BoatIcon";

type POITypeMapType = {
  [key in POIType]: { icon: any; bg: string; width?: string };
};

const nature = "#03A760";
const shopping = "purple";
const eatAndDrink = "orange";
const activity = "darkBlue";
const transport = "#8d8d8d";

export const POIsToIcons: POITypeMapType = {
  [POIType.OTHER]: { icon: BsPinAngleFill, bg: "rgb(94, 56, 110)" },
  [POIType.HOTEL]: { icon: MdHotel, bg: eatAndDrink },
  [POIType.BAR]: { icon: BiSolidDrink, bg: eatAndDrink },
  [POIType.COFFEE_HOUSE]: { icon: BiSolidCoffeeAlt, bg: eatAndDrink },
  [POIType.CITY]: { icon: FaCity, bg: activity },
  [POIType.RESTAURANT]: { icon: MdRestaurant, bg: eatAndDrink },
  [POIType.CULTURAL_SITE]: { icon: FaMosque, bg: activity },
  [POIType.ENTERTAINMENT]: { icon: BiSolidCameraMovie, bg: activity },
  [POIType.ACTIVITY]: { icon: FaFootball, bg: activity },
  [POIType.NATURE]: { icon: MdForest, bg: nature },
  [POIType.TOURIST_ATTRACTION]: { icon: HiCamera, bg: activity },
  [POIType.FERRY]: { icon: BoatIcon, bg: transport },
  [POIType.TRAIN_STATION]: { icon: BiSolidTrain, bg: transport },
  [POIType.AIRPORT]: { icon: MdLocalAirport, bg: transport },
  [POIType.BEACH]: { icon: FaUmbrellaBeach, bg: nature },
  [POIType.CAR_RENTAL]: { icon: BiSolidCar, bg: transport },
  [POIType.CYCLING]: { icon: BiCycling, bg: activity },
  [POIType.MARKET]: { icon: BsShop, bg: shopping },
  [POIType.SHOP]: { icon: BiSolidShoppingBag, bg: shopping },
  [POIType.MUSEUM]: { icon: MdOutlineMuseum, bg: activity },
  [POIType.SCENIC_ROUTE]: { icon: FaRoute, bg: nature },
  [POIType.SPA]: { icon: BiSolidSpa, bg: activity },
  [POIType.VIEWPOINT]: { icon: FaBinoculars, bg: nature },
  [POIType.WATERFALL]: { icon: GiWaterfall, bg: nature },
  [POIType.PARK]: { icon: GiParkBench, bg: nature },
  [POIType.FLAG]: { icon: BsFlagFill, bg: activity, width: "12px" },
  [POIType.SKI_AREA]: { icon: MdDownhillSkiing, bg: activity },
  [POIType.CAMPGROUND]: { icon: FaCampground, bg: nature },
  [POIType.PHARMACY]: { icon: MdLocalPharmacy, bg: shopping },
  [POIType.TRAIL]: { icon: FaPersonHiking, bg: nature },
  [POIType.BRIDGE]: { icon: FaBridge, bg: activity },
  [POIType.BUILDING]: { icon: FaBuilding, bg: activity, width: "12px" },
  [POIType.GOLF_RESORT]: { icon: FaGolfBall, bg: activity, width: "12px" },
  [POIType.NEIGHBORHOOD]: { icon: FaCity, bg: activity },
};
