import { Flex, Heading, Icon, Text, Tooltip } from "@chakra-ui/react";
import { Coord, Image, TLibraryImage, TRANSPORTATION_TYPE } from "@lato/common";
import { CalendarIcon, ClockIcon } from "lucide-react";
import React, { ReactElement } from "react";
import { BsFillFlagFill, BsSignpost2Fill } from "react-icons/bs";
import { MdHotel, MdLocationOn } from "react-icons/md";
import ExtraPicture from "../../../../_app/TripDaysSwiper/ExtraPicture";
import StaticMap from "../../../map/staticMap";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import Overlay from "../../../accessiblity/overlay";

interface ElementPreviewProps {
  title?: string | ReactElement;
  description: string;
  time?: string;
  date?: string;
  location?: string;
  firstCoords?: Coord;
  secondCoords?: Coord;
  overlay?: boolean;
  libraryImages?: TLibraryImage[];
  externalImages?: Image[];
  type?: "activity" | "hotel" | "transportation" | "description" | "destination";
  transportationType?: TRANSPORTATION_TYPE;
}

const ElementPreview: React.FC<ElementPreviewProps> = ({
  title,
  description,
  time,
  date,
  location,
  firstCoords,
  secondCoords,
  overlay = true,
  externalImages,
  libraryImages,
  type,
  transportationType,
}) => {
  const { isInvalidForms } = useTripFormStore();

  const allImages = [...(externalImages || []), ...(libraryImages || [])].sort((a: any, b: any) =>
    a.ord < b.ord ? -1 : a.ord === b.ord ? 0 : 1,
  );
  const firstImage = allImages.length > 0 && allImages[0] ? allImages[0] : undefined;

  const amountOfImages = [...(externalImages || []), ...(libraryImages || [])].length;

  return (
    <Overlay
      display={[...isInvalidForms.values()].some((value) => value === true)}
      backgroundColor="rgba(255,255,255,0.7)"
    >
      <div className="h-[6rem] cursor-pointer">
        <div className="flex gap-2">
          {type !== "transportation" && (
            <div className="rounded-sm h-[6rem] w-[6rem] object-cover overflow-hidden relative">
              {!!amountOfImages && (
                <Tooltip label={`${amountOfImages} image${amountOfImages > 1 ? "s" : ""}`}>
                  <div className=" bg-white text-xs text-bold bg-opacity-70 px-1 text-black rounded-full z-50 absolute top-2 right-2">
                    {amountOfImages}
                  </div>
                </Tooltip>
              )}
              {firstImage ? (
                <ExtraPicture image={firstImage} imageSize="thumb" i={1} removeImage={() => {}} autoplayVideo />
              ) : (
                <img
                  className="object-cover w-full h-full rounded-md"
                  alt="No-preview-image"
                  src={"https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png"}
                />
              )}
            </div>
          )}
          {firstCoords && secondCoords && (
            <StaticMap
              height={640}
              width={640}
              containerHeight="6rem"
              containerWidth="6rem"
              center={firstCoords}
              second={secondCoords}
              showDetails={false}
              transportation_type={transportationType}
              smallLabel
            />
          )}
          <div className="flex flex-col justify-between w-[60%] px-2">
            <div>
              {
                <Heading color="brand.500" mb={2} fontSize={"xl"} textAlign={"start"}>
                  {type === "hotel" && <Icon as={MdHotel} mr={2} mb={1} boxSize={4} />}
                  {type === "activity" && <Icon as={BsFillFlagFill} mr={2} mb={1} boxSize={4} />}
                  {type === "destination" && <Icon as={BsSignpost2Fill} mr={2} boxSize={4} />}
                  {title == null || title === "" ? (type ?? "")?.charAt(0).toUpperCase() + type?.slice(1) : title}
                </Heading>
              }
              {location && !!title && (
                <Flex alignItems={"center"} w={48}>
                  {/* <Icon as={MdLocationOn} /> */}
                  <Text fontSize="sm" isTruncated as="u" mb={1} alignItems={"center"}>
                    {location}
                  </Text>
                </Flex>
              )}
              {description && (
                <Text
                  w="75%"
                  fontSize="sm"
                  noOfLines={1}
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              )}
              {!description && !location && !title && <Text fontSize="sm">Click here to edit this element.</Text>}
            </div>
            <div className="flex gap-4 text-sm mb-4 text-gray-500">
              {date && (
                <Flex alignItems={"center"} gap={3}>
                  <Icon w={6} as={CalendarIcon} alignSelf={"flex-start"} mt={1} />
                  {date}
                </Flex>
              )}
              {time && (
                <Flex alignItems={"center"} gap={3}>
                  <Icon as={ClockIcon} alignSelf={"flex-start"} mt={1} /> {time}
                </Flex>
              )}
              {location && !title && (
                <Flex alignItems={"center"} gap={3}>
                  <Icon as={MdLocationOn} alignSelf={"flex-start"} mt={1} /> {location}
                </Flex>
              )}
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export default ElementPreview;
