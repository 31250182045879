import { StatisticsType } from "../pages/dashboard";
import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "statistics";

// Get statistics of this company
const getStatistics = (queryParams?: string) => {
  return rawApiProvider.get(`${url}?${queryParams}`);
};

// Get monthly revenue for chart
const getRevenuePerMember = (queryParams?: string) => {
  return rawApiProvider.get(`${url}/revenuePerMember?${queryParams}`);
};

// Get revenue last year and this year
const getDailyStats = (queryParams?: string) => {
  return rawApiProvider.get(`${url}/dailyRevenue?${queryParams}`);
};

// Get most booked countries
const getMostBookedCountries = (queryParams?: string) => {
  return rawApiProvider.get(`${url}/mostBookedCountries?${queryParams}`);
};

// Get booked per continent
const getBookedPerContinent = (queryParams?: string) => {
  return rawApiProvider.get(`${url}/bookedPerContinent?${queryParams}`);
};

// Get booked per continent
const getUpcomingTrips = (queryParams?: string) => {
  return rawApiProvider.get(`${url}/upcomingTrips?${queryParams}`);
};

type StatisticsAPIType = ReturnEndpoints & {
  getStatistics(queryParams?: string): Promise<StatisticsType>;
  getRevenuePerMember(queryParams?: string): Promise<any>;
  getDailyStats(queryParams?: string): Promise<any>;
  getMostBookedCountries(queryParams?: string): Promise<any>;
  getBookedPerContinent(queryParams?: string): Promise<any>;
  getUpcomingTrips(queryParams?: string): Promise<any>;
};
const StatisticsAPI: StatisticsAPIType = {
  getStatistics,
  getRevenuePerMember,
  getDailyStats,
  getMostBookedCountries,
  getBookedPerContinent,
  getUpcomingTrips,
};
export default StatisticsAPI;
