import { Stack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FullScreenSpinner, { GeneralSkeleton, WizardSkeleton } from "../../components/FullScreenSpinner";
import ErrorCardView from "../../components/layout/ErrorCardView";
import WizardButtonGroup from "../../features/editTrip/wizard/WizardButtonGroup";
import { useMeContext } from "../../stores/me-context";
import { TripLanguageProvider } from "../../stores/trip-language-context";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import { TRIP_PLAIN_QUERY_KEY, USERTRIP_PLAIN_QUERY_KEY } from "../../utils/constants";
import { getViewTripLink } from "../../utils/generateURLs";
import {
  getRelationMap,
  updateRelationMap,
  useSplitTrip,
  useSplitUserTrip,
} from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import useUnsavedChangesPrompt from "../../utils/useUnsavedChangesPrompt";
import { ConditionalWrapper } from "../../features/contacts/Contact";
import TripNav from "../trip/edit/TripNav";

type SampleStepWrapperProps = {
  children: any;
};

const SampleStepWrapper: React.FC<SampleStepWrapperProps> = ({ children }) => {
  const { id: userTripId } = useParams<{ id: string }>();
  const me = useMeContext();

  const { setTrip, setIsSample, resetStore, isDirtyForms, isSubmittingForms, isErrorForms } = useTripFormStore();

  const {
    data: usertrips,
    isLoading: isLoadingUserTrip,
    error: userTripError,
  } = useSplitUserTrip(userTripId ?? "", getRelationMap[USERTRIP_PLAIN_QUERY_KEY], USERTRIP_PLAIN_QUERY_KEY);

  const usertrip = usertrips && usertrips[usertrips.length - 1];

  const {
    data: trip,
    isLoading: isLoadingTrip,
    error: tripError,
  } = useSplitTrip(usertrip?.tripId ?? "", TRIP_PLAIN_QUERY_KEY, updateRelationMap[TRIP_PLAIN_QUERY_KEY]);

  useEffect(() => {
    resetStore();
  }, []);

  useEffect(() => {
    if (trip) {
      setTrip({ ...trip, userTrips: usertrips });
      setIsSample(true);
    }
  }, [trip?.id]);

  // Check if the user has unsaved changes
  useUnsavedChangesPrompt(
    [...isDirtyForms.values()].some((value) => value === true) &&
      ![...isSubmittingForms.values()].some((value) => value === true),
    true,
  );

  if (userTripError || tripError)
    return <ErrorCardView title={`Couldn't load trip`} description="The URL is invalid." />;
  if (isLoadingUserTrip || isLoadingTrip || !trip || !usertrip) {
    return (
      <Stack position="relative" mx={{ base: "0", md: "7" }} mb={10} spacing={1}>
        <WizardSkeleton />
        <GeneralSkeleton />
      </Stack>
    );
  }
  //if (t.userTrips.length !== 1) return <ErrorCardView title="Incorrect number of collaborators" />;

  const priceDescriptions = trip.priceDescriptions;
  const useNewEditTripLayout = me.useNewEditTripLayout;

  return (
    <TripLanguageProvider defaultPartialFields={priceDescriptions} first={false} doubleFields={false}>
      <ConditionalWrapper
        condition={me.useNewEditTripLayout}
        wrapper={(children: React.ReactNode) => <TripNav>{children}</TripNav>}
      >
        {!useNewEditTripLayout && (
          <WizardButtonGroup
            viewTripLink={getViewTripLink(
              me?.company?.name || "",
              trip.titles ? trip.titles[0].content : "trip",
              userTripId!,
            )}
          />
        )}
        <Stack
          ml={{ base: "0", md: useNewEditTripLayout ? "20" : "16" }}
          mr={{ base: "0", md: useNewEditTripLayout ? "20" : "16" }}
          mt={useNewEditTripLayout ? 4 : "-2.6em"}
          mb={useNewEditTripLayout ? 0 : "4"}
          // width="100%"
        >
          {children}
        </Stack>
      </ConditionalWrapper>
    </TripLanguageProvider>
  );
};
export default React.memo(SampleStepWrapper);
