import { Flex, Stack } from "@chakra-ui/react";
import { Event, TLocation, Trip } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { type DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { BsFlag } from "react-icons/bs";
import EventsAPI from "../../../../../../api/events.api";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { getNewEventIndex, submitForms } from "../../../../../../utils/EventHelper";
import { DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY } from "../../../../../../utils/constants";
import { useActivity, usePatchActivity } from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import useMutation from "../../../../../../utils/query-helpers/useMutation";
import { ElementSkeleton } from "../../../../../FullScreenSpinner";
import InputWrapper from "../../../../../input/InputWrapper";
import RHFInput from "../../../../../input/RHFInput";
import RHFSelect from "../../../../../input/RHFSelect";
import GeneralElementForm from "../../GeneralElementForm";
import EventForm from "./EventForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { eventSchemaInTrip } from "../../../../../../validation/validationSchemas";

interface EventFormInTripProps {
  isDragging: boolean;
  i: number;
  tripdayIndex: number;
  events: any[];
  event?: Event;
  trip: Trip;
  move: (dest: number) => void;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  daySelectOptions: any[];
  orderObjects: any[];
  orderIndex: number;
  uniqueId: string;
  fieldArrayMethods: any;
  updateActivity?: (data: any) => Promise<void>;
  isUpdatingActivity?: boolean;
  tripdayId: string;
  handleDragAndDropMove: (fromIndex: number, toIndex: number) => void;
  toIndex?: number;
  setToIndex?: (index: number) => void;
  setFromIndex?: (index: number) => void;
  onOpenWarning: () => void;
}

const EventFormInTripWrapper: React.FC<EventFormInTripProps> = ({ ...props }) => {
  const { isLoadingTranslations: isLoadingTranslationsFromStore, isMovingElements } = useTripFormStore();
  const {
    data: event,
    isLoading: isLoadingEvent,
    isFetching: isFetchingEvent,
    isFetchedAfterMount: isFetchedAfterMountEvent,
  } = useActivity(props.events[props.i].id);

  const queryClient = useQueryClient();

  const { mutateAsync: updateActivity, isPending: isUpdatingActivity } = usePatchActivity(
    props.events[props.i].id ?? "",
    props.tripdayId,
    queryClient,
  );

  //const isFetchingTrans = (isLoadingTranslations || !isFetchedAfterMountEvent) && isFetchingEvent;
  const isLoadingTrans = (!isFetchedAfterMountEvent && isFetchingEvent) || isLoadingTranslationsFromStore;

  if (isLoadingEvent || isUpdatingActivity || isLoadingTrans || isMovingElements) return <ElementSkeleton />;

  return (
    <EventFormInTrip {...props} event={event} updateActivity={updateActivity} isUpdatingActivity={isUpdatingActivity} />
  );
};

export const copyLocation = (location: TLocation) => {
  if (location) {
    const { id, ...locationWithoutId } = location;
    return locationWithoutId;
  }
  return location;
};

const EventFormInTrip: React.FC<EventFormInTripProps> = ({
  isDragging,
  i,
  tripdayIndex,
  events,
  event,
  trip,
  move,
  dragHandleProps,
  daySelectOptions,
  orderObjects,
  orderIndex,
  uniqueId,
  fieldArrayMethods,
  updateActivity = () => {},
  isUpdatingActivity = false,
  tripdayId,
  handleDragAndDropMove,
  setFromIndex,
  setToIndex,
  onOpenWarning,
}) => {
  const formId = `2-${tripdayIndex}-form-${i}-activity`;

  const errorHelpMessage = "Please use the 24 hour format: hh:mm";
  const {
    isFirst,
    setIsErrorForms,
    setIsInvalidForms,
    setUnCollapsedElement,
    unCollapsedElement,
    isSubmittingForms,
    isInvalidForms,
  } = useTripFormStore();

  const queryClient = useQueryClient();

  const [title, setTitle] = React.useState(errorHelpMessage);

  const handleRemoveActivity = React.useCallback(
    (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number, updateTripday: boolean = true) => {
      fieldArrayMethods.remove(i, updateTripday);
      setIsErrorForms(formId, false);
      setIsInvalidForms(formId, false);
    },
    [fieldArrayMethods.remove],
  );

  const handleChangeDayOrTime = React.useCallback(
    (_: any, newDayIndex: any, newTime: string | null) => {
      // Normally value should never be a string
      if (typeof newDayIndex === "string") return;
      // Value is a number or null
      const newDestination = getNewEventIndex(newDayIndex, newTime, orderIndex, orderObjects, "event");
      if (newDestination !== orderIndex && event?.time) {
        // Move the current event to the new destination index;
        move(newDestination);
      }
    },
    [events, move, i],
  );

  const { mutate: copyEvent } = useMutation({
    apiCall: (id: string) => EventsAPI.duplicate(id, tripdayId),
    successMessage: `duplicated activity`,
    failMessage: `duplicating activity`,
  });

  const formMethods = useForm<Event>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: event,
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(eventSchemaInTrip, { abortEarly: false }),
  });

  const handleDuplicateActivity = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const eventFormValues = formMethods.getValues();
      await updateActivity(eventFormValues);
      const copiedEvent = await copyEvent(eventFormValues.id);
      fieldArrayMethods.append(e, copiedEvent);
      queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripdayId] });
    },
    [fieldArrayMethods.append, formMethods],
  );

  const handleMoveTripdayActivity = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number, tripday: any) => {
      await updateActivity({
        ...formMethods.getValues(),
        ord: tripday.events.length + tripday.hotels.length + tripday.transportations.length + 1,
        tripdayId: tripday?.id,
      });
      await handleRemoveActivity(e, i, false);
      await queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripday?.id] });
      await queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripdayId] });
    },
    [fieldArrayMethods.moveToTripday, formMethods.getValues],
  );

  const titleRealLanguageIndex = 0;

  return (
    <GeneralElementForm
      handleDelete={(e) => handleRemoveActivity(e, i)}
      handleTripdayMove={(e, tripday) => {
        handleMoveTripdayActivity(e, i, tripday);
      }}
      handleDragAndDropMove={handleDragAndDropMove}
      dragHandleProps={dragHandleProps}
      type="activity"
      getEvent={formMethods.getValues}
      first={isFirst}
      handleDuplicate={handleDuplicateActivity}
      currentTripdayIndex={tripdayIndex}
      onMouseDown={async (e) => {
        if (event?.id && ![...isInvalidForms.values()].some((value) => value === true)) {
          e.stopPropagation();
          unCollapsedElement && unCollapsedElement !== event?.id && (await submitForms(isSubmittingForms));
          setUnCollapsedElement(event?.id);
        }
      }}
      icon={BsFlag}
      isLast={orderObjects.at(-1).id === event?.id}
      i={orderIndex}
      setToIndex={setToIndex}
      setFromIndex={setFromIndex}
      onOpenWarning={onOpenWarning}
    >
      <EventForm
        tripProps={{
          first: isFirst,
          location: trip.tripdays[tripdayIndex].location,
          libraryItemIndex: i,
          tripdayIndex: tripdayIndex,
          tripdayId: trip.tripdays[tripdayIndex].id,
          uniqueId: event?.updated_at,
          collaboratorCanEdit: trip.collaboratorCanEdit,
          formId: formId,
        }}
        formId={formId}
        formMethods={formMethods}
        updateActivity={updateActivity}
        isUpdating={isUpdatingActivity}
        selectedLanguageIndex={titleRealLanguageIndex}
        event={event!}
        daySelectOptions={daySelectOptions}
        eventDateAndTime={
          <Stack direction={"row"} flexWrap={{ base: "wrap", md: "nowrap" }} onClick={(e) => e.stopPropagation()}>
            <InputWrapper name={`dayIndex`} label="Day" minW="16ch" w={{ base: "100%", md: "16ch" }}>
              <RHFSelect
                placeholder="Select day"
                name={`dayIndex`}
                options={daySelectOptions}
                // onChange={(e, value) => handleChangeDayOrTime(e, value, event?.time ?? null)}
                isDisabled={!isFirst}
                onClick={(e) => e.stopPropagation()}
              />
            </InputWrapper>
            <Flex w="100%" gap={2}>
              <RHFInput
                name={`time`}
                type="time"
                label="Start time"
                w={"50%"}
                // defaultValue={event.time ?? undefined}
                // Used when the time type is not supported in the browser, only validates upon submit
                pattern="[0-9]{2}:[0-9]{2}"
                // Used in the error message
                title={title}
                onMouseEnter={() => setTitle("")}
                onMouseLeave={() => setTitle(errorHelpMessage)}
                isDisabled={!isFirst}
                onClick={(e) => e.stopPropagation()}
              />
              <RHFInput
                name={`endTime`}
                type="time"
                label="End time"
                w={"50%"}
                className="mx-auto"
                // defaultValue={event.time ?? undefined}
                // Used when the time type is not supported in the browser, only validates upon submit
                pattern="[0-9]{2}:[0-9]{2}"
                // Used in the error message
                title={title}
                onMouseEnter={() => setTitle("")}
                onMouseLeave={() => setTitle(errorHelpMessage)}
                isDisabled={!isFirst}
                onClick={(e) => e.stopPropagation()}
              />
            </Flex>
          </Stack>
        }
      />
    </GeneralElementForm>
  );
};
export default React.memo(EventFormInTripWrapper);
