import { RadioGroup, RadioProps, Radio, Stack, color } from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import colors from "../../theme/foundations/colors";

interface RHFRadioProps extends Omit<RadioProps, "onChange" | "defaultValue"> {
  name: string;
  options: { text: string; value: any }[];
  onChange?: (e: any) => void;
  defaultValue?: any;
}

const RHFRadio: React.FC<RHFRadioProps> = ({ name, options, onChange, defaultValue = options[0].value, ...props }) => {
  const { field } = useController({
    name,
  });

  return (
    <RadioGroup {...field} defaultValue={defaultValue} {...props}>
      <Stack direction="row">
        {options.map((option, i) => (
          <Radio key={`radio-${name}-option-${i}`} colorScheme={"brand"} value={option.value} onChange={onChange}>
            {option.text}
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
};
export default React.memo(RHFRadio);
