export const prettyLocation = (location) => {
    //If no address is available we will use the name of the location
    let mainAddress = null;
    let subAddress = null;
    if (location?.address) {
        if (Object.values(location.address).every((x) => x === null || x === "")) {
            const locationParts = location.name.split(",");
            if (locationParts.length > 0) {
                console.log(locationParts);
                mainAddress = locationParts
                    .slice(-2)
                    .map((location) => location.replace(/[0-9]/g, ""))
                    .join(" ");
                if (locationParts.length > 2) {
                    subAddress = locationParts.slice(0, -2).join(" ");
                }
            }
        }
        else {
            mainAddress = `${location.address?.country_code ?? ""} ${(location.address?.city || location.address?.state) ?? ""}`;
            subAddress = location.address.hasOwnProperty("street")
                ? location.address?.street
                : location.address?.streetAddress;
        }
    }
    return { mainAddress, subAddress };
};
export const tripdayTitleLocation = (trip, tripdayIndex) => {
    return ((tripdayIndex > 0
        ? trip.tripdays[tripdayIndex].location != undefined
            ? `${trip.tripdays[tripdayIndex - 1].location != undefined &&
                trip.tripdays[tripdayIndex - 1].location.name !== trip.tripdays[tripdayIndex].location.name
                ? `${trip.tripdays[tripdayIndex - 1].location.name} - `
                : ""}${trip.tripdays[tripdayIndex].location.name}`
            : undefined
        : trip.tripdays[tripdayIndex].location != undefined
            ? trip.tripdays[tripdayIndex].location.name
            : undefined) ?? undefined);
};
