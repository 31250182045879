import { SettingsIcon } from "@chakra-ui/icons";
import React from "react";
import { BiSolidBookAdd } from "react-icons/bi";
import { FaListCheck } from "react-icons/fa6";
import SideMenu from "../../components/settings/SideMenu";

interface BookingsProps {
  children: React.ReactNode;
}
const Bookings: React.FC<BookingsProps> = ({ children }) => {
  const mainPath = "booking";

  return (
    <SideMenu
      mainPath={mainPath}
      heading={"Booking"}
      navItems={[
        {
          tabname: "Overview",
          icon: <FaListCheck size="18px" />,
          path: "overview",
        },
        {
          tabname: "Book",
          icon: <BiSolidBookAdd size="18px" />,
          path: "book",
        },
        {
          tabname: "Configuration",
          icon: <SettingsIcon boxSize="18px" />,
          path: "configuration",
        },
      ]}
    >
      {children}
    </SideMenu>
  );
};
export default Bookings;
