import { EmailIcon } from "@chakra-ui/icons";
import { Button, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { handleSubmission } from "../../utils/toErrorMap";
import { emailValidationSchema } from "../../validation/validationSchemas";
import RHFInput from "../input/RHFInput";
import Form from "./Form";

interface GeneralEmailFormProps {
  validationSchema?: any;
  successCallback?: () => void;
  buttonText: string;
  apiCall: any;
  successMessage: string;
  failMessage: string;
  handleAdditionalError?: (statusCode: number, setError: any) => any;
  customButtonLayout?: (isSubmitting: boolean, isValid: boolean) => any;
}

const GeneralEmailForm: React.FC<GeneralEmailFormProps> = ({
  validationSchema,
  successCallback,
  buttonText,
  apiCall,
  successMessage,
  failMessage,
  handleAdditionalError,
  customButtonLayout,
}) => {
  const toast = useToast();
  const formMethods = useForm<{ email: string }>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
    },
    // If a different email validation schema is given, use that one.
    resolver: yupResolver(validationSchema ?? emailValidationSchema),
  });

  const handleAdditionalErr = (statusCode: number) => {
    if (handleAdditionalError) handleAdditionalError(statusCode, formMethods.setError);
  };

  const handleSubmit = async ({ email }: { email: string }) => {
    await handleSubmission({
      successMessage,
      failMessage,
      apiCall: apiCall(email),
      toast,
      successCallback,
      setError: formMethods.setError,
      handleAdditionalError: handleAdditionalErr,
    });
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <RHFInput
        name="email"
        size="lg"
        layout="normal"
        label="Email"
        type="email"
        placeholder="bob@mail.com"
        leftIcon={<EmailIcon color="gray.400" pointerEvents={"none"} />}
      />
      {!customButtonLayout ? (
        <Button
          w="100%"
          size="md"
          mt={4}
          type="submit"
          isLoading={formMethods.formState.isSubmitting}
          isDisabled={!formMethods.formState.isValid}
          colorScheme="brand"
        >
          {buttonText}
        </Button>
      ) : (
        customButtonLayout(formMethods.formState.isSubmitting, formMethods.formState.isValid)
      )}
    </Form>
  );
};
export default React.memo(GeneralEmailForm);
