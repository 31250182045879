import { Box, Stack, Text } from "@chakra-ui/react";
import React from "react";

interface OverlayProps {
  display: boolean;
  children: any;
  zIndex?: number;
  top?: number;
  cursor?: string;
  backgroundColor?: string;
}

const Overlay: React.FC<OverlayProps> = ({
  display,
  children,
  zIndex = 500,
  top = 0,
  cursor = "not-allowed",
  backgroundColor = "rgba(0,0,0,0.1)",
}) => {
  return (
    <>
      <Box
        position="absolute"
        top={top}
        left={0}
        right={0}
        bottom={0}
        display={display ? "block" : "none"}
        zIndex={zIndex}
        borderRadius={"5px"}
        backgroundColor={backgroundColor}
        cursor={cursor}
        pointerEvents={"auto"}
        _hover={{
          backgroundImage: `url('path/to/disabled-icon.svg')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        onClick={(e) => {
          // Prevent click-throughs
          e.preventDefault();
          e.stopPropagation();
        }}
      />
      {children}
    </>
  );
};

interface DisabledMessageProps {
  margin: number;
  collaboratorCanEdit: boolean;
}

export const DisabledMessage: React.FC<DisabledMessageProps> = ({ margin, collaboratorCanEdit }) => {
  return (
    <Box margin={margin} textAlign="center">
      Customize the {collaboratorCanEdit ? "trip" : "price"} in <b>step {collaboratorCanEdit && "3 and"} 4</b>. Go to{" "}
      <b>step 5</b> to share the trip URL with your client.
    </Box>
  );
};

interface CollaboratorEnabledPriceMessageProps {}

export const CollaboratorEnabledPriceMessage: React.FC<CollaboratorEnabledPriceMessageProps> = () => {
  return (
    <Box margin={1} textAlign="center">
      <Text>
        The <b>travel professional</b> that invited you to collaborate on this trip has decided that{" "}
        <b>you can only edit the Price.</b>
      </Text>
      <Text>Contact the travel professional if you would like to make other changes.</Text>
    </Box>
  );
};

export default Overlay;
