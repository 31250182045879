// const baseStyle = {
//   fontFamily: "heading",
//   fontWeight: "bold",
// };

const sizes = {
  "5xl": {
    fontSize: ["6xl", null, "7xl"],
    lineHeight: 1,
  },
  "4xl": {
    fontSize: ["5xl", null, "6xl"],
    lineHeight: 1,
  },
  "3xl": {
    fontSize: ["4xl", null, "5xl"],
    lineHeight: [1.2, null, 1],
  },
  "2xl": {
    fontSize: ["3xl", null, "4xl"],
    lineHeight: [1.33, null, 1.2],
  },
  xl: {
    fontSize: ["2xl", null, "3xl"],
    lineHeight: [1.33, null, 1.2],
  },
  lg: { fontSize: "xl", lineHeight: 1.2 },
  md: { fontSize: "md", lineHeight: 1.2 },
  sm: { fontSize: "sm", lineHeight: 1.2 },
  xs: { fontSize: "xs", lineHeight: 1.4 },
};

const defaultProps = {
  size: "xl",
};

export default {
  // baseStyle,
  sizes,
  defaultProps,
};
