import React from "react";
import { User } from "@lato/common";

// Zoho Sales IQ widget code
export const ZohoSalesIQ = ({ data }: { data: User }) => {
  (window as any).$zoho = (window as any).$zoho || {};
  (window as any).$zoho.salesiq = (window as any).$zoho.salesiq || {
    widgetcode: "siq798d030f1bf198ef85b2d93720c7642402101fdbc3f1a0126e6bdaa97e9dc9bb",
    values: {},
    ready: function () {},
  };
  const d = document;
  let s;
  s = d.createElement("script");
  s.type = "text/javascript";
  s.id = "zsiqscript";
  s.defer = true;
  s.src = "https://salesiq.zoho.eu/widget";
  let t;
  t = d.getElementsByTagName("script")[0];
  t?.parentNode?.insertBefore(s, t);
  (window as any).$zoho.salesiq.ready = function () {
    (window as any).$zoho.salesiq.visitor.name(data.name);
    (window as any).$zoho.salesiq.visitor.email(data.email);
    (window as any).$zoho.salesiq.floatbutton.visible("hide");
    (window as any).$zoho.salesiq.floatwindow.visible("hide");
    (window as any).$zoho.salesiq.privacy.updateCookieConsent(["analytics", "performance"]);
  };
};
