import { Box, Heading, Icon, Stack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useFormContext } from "react-hook-form";
import { BsFileTextFill } from "react-icons/bs";
import TextfieldsAPI from "../../../../../../api/textfields.api";
import { useMeContext } from "../../../../../../stores/me-context";
import { TripLanguageProvider, useTripLanguage } from "../../../../../../stores/trip-language-context";
import { TranslationField, TextFieldElement } from "@lato/common";
import RHFInput from "../../../../../input/RHFInput";
import RHFTranslationInput from "../../../../../input/RHFTranslationInput";
import TranslateTitle from "../../../../../input/TranslateTitle";
import { LibraryItem } from "../LibraryItemModal";
import { Descriptions } from "../hotel/Descriptions";
interface TextFieldFormProps {
  updateTextField: (textField: TextFieldElement) => void;
  forceNew?: boolean;
}

const TextFieldForm: React.FC<TextFieldFormProps> = ({ updateTextField, forceNew = false }) => {
  const me = useMeContext();

  const { getValues, reset } = useFormContext();

  const [id, setId] = React.useState(getValues("id"));
  const [forceNewState, setForceNewState] = React.useState(forceNew);

  const translationFields: TranslationField[] = getValues("descriptions");
  const languages = translationFields.map((tf) => tf.language);
  console.log(translationFields);
  // Get the index inside the translation fields of the default language. If the default language is not yet translated, return 0.
  const defaultSelectedLanguageIndex = Math.max(
    0,
    translationFields.map((tf) => tf.language?.code).indexOf(me.defaultTFLang?.code),
  );

  return (
    <Box className="flex flex-col space-y-2 items-stretch" marginBottom={2}>
      <Stack direction="row" alignItems="center" h={14}>
        <Icon as={BsFileTextFill} boxSize={6} />
        <Heading size="xl" fontSize={"22px"}>
          Text Field
        </Heading>
      </Stack>
      <TripLanguageProvider doubleFields={false} first={true} defaultPartialFields={translationFields}>
        <TranslateTitle
          isFirst={true}
          name={"descriptions"}
          getValues={getValues}
          elementId={id}
          forceNew={forceNew}
          addLanguageCall={async (id: string, code: string, translateFields: any) => {
            const formValues = getValues();
            let response;
            if (!id || forceNewState) {
              response = await TextfieldsAPI.post({ ...formValues, id: forceNew ? undefined : id });
              id = response.id;
              setForceNewState(false);
            } else {
              await TextfieldsAPI.patch(id, formValues);
            }
            setId(id);
            response = await TextfieldsAPI.addLanguage(id, code, translateFields);

            !forceNew && updateTextField(response.element);
            reset(response.element);
          }}
          removeLanguageCall={async (i: number, code: string) => {
            const formValues = getValues();
            let id = formValues.id;
            let response;
            if (!id || forceNewState) {
              response = await TextfieldsAPI.post({ ...formValues, id: forceNew ? undefined : id });
              id = response.id;
              setForceNewState(false);
            } else {
              await TextfieldsAPI.patch(id, formValues);
            }
            setId(id);
            response = await TextfieldsAPI.removeLanguage(id, code);
            !forceNew && updateTextField(response.element);
            reset(response.element);
          }}
        />
        <RHFInput name={`name`} label="Name" size="md" h="unset" fontSize={{ base: "18px", md: "25px" }} />
        <Descriptions fieldName={"descriptions"} elementType="text-field" />
      </TripLanguageProvider>
    </Box>
  );
};

export default React.memo(TextFieldForm);
