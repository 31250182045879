import { Box, Input, Text } from "@chakra-ui/react";
import { ImageSite, Image as ImageType } from "@lato/common";
import React from "react";

interface ImageByLinkUploaderProps {
  nrOfImages: number;
  showError: (show: boolean) => any;
  inLibrary?: boolean;
  addImagetoPreview: (images: any[]) => void;
}

const ImageByLinkUploader: React.FC<ImageByLinkUploaderProps> = ({
  nrOfImages,
  showError,
  inLibrary,
  addImagetoPreview,
}) => {
  const [incorrectUrl, setIncorrectUrl] = React.useState<boolean>(false);
  const imageExtensions = ["jpg", "jpeg", "png", "img", "svg", "gif", "heic", "avif"];
  const imageKeyWords = ["photo", "picture"];

  const defineImageLinkType = (link: string) => {
    const extension = link.split("?")[0].split(".").pop() ?? "img";
    if (imageExtensions.includes(extension) || imageKeyWords.some((element) => link.includes(element))) {
      return ImageSite.CUSTOMPICTURE;
    } else if (link.includes("vimeo") || link.includes("youtube")) {
      return ImageSite.CUSTOMVIDEO;
    }
    return null;
  };

  const parseYoutubeLink = (link: string) => {
    const regex = new RegExp(/(https:\/\/)?(www\.)?youtube\.com\/watch\?v=[0-z]*.*/g);
    if (regex.test(link)) {
      const youtubeCode = link.split("&")[0].split("=")[1];
      return "https://www.youtube.com/embed/" + youtubeCode;
    }
    return link;
  };

  const parseVimeolink = (link: string) => {
    const regex = new RegExp(/(https:\/\/)?(www\.)?vimeo\.com\/[0-9]*/g);
    if (regex.test(link)) {
      const vimeoCode = link.split("/").pop();
      return "https://player.vimeo.com/video/" + vimeoCode;
    }
    return link;
  };

  const handleImageLink = (event: any) => {
    showError(false);
    let link = event.target.value as string;
    let originUrl = link;
    if (link) {
      const imageSite = defineImageLinkType(event.target.value);
      if (imageSite === null) {
        setIncorrectUrl(true);
        return;
      }
      if (imageSite === ImageSite.CUSTOMVIDEO) {
        if (link.includes("youtube")) {
          const baseLink = parseYoutubeLink(link);
          const vidParams = "showInfo=0&controls=0&mute=1&loop=1&iv_load_policy=3&modestbranding=1&Showinfo=0";
          link = baseLink + "?" + vidParams;
          originUrl = baseLink + "?autoplay=1&" + vidParams;
        }
        if (link.includes("vimeo")) {
          const baseLink = parseVimeolink(link);
          link = baseLink + "?loop=1&muted=1&title=0?transparent=0";
          originUrl = baseLink + "?background=1&title=0?transparent=0";
        }
      }
      const image: ImageType = {
        ord: nrOfImages + 1,
        url: link,
        originUrl: originUrl,
        site: imageSite,
      };

      addImagetoPreview([
        {
          url: originUrl,
          type: imageSite,
          image: image,
        },
      ]);
      setIncorrectUrl(false);
    } else {
      setIncorrectUrl(false);
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={2}>
      <Text fontSize={"1em"}>Copy your image in here</Text>

      <Input
        width={"85%"}
        textAlign={"center"}
        placeholder={"https://www.imageshoster.be/45fe-faef64"}
        onChange={handleImageLink}
      ></Input>
      {incorrectUrl && (
        <Text fontSize={"md"} mt={"-0.5em"} color="red.500">
          Please make sure to use the actual url of the image.
        </Text>
      )}
      {/*imageByLink && (
        <>
          {imageByLink.site === ImageSite.CUSTOMPICTURE ? (
            <Box mt={2}>
              <Image w={"100%"} h={"12em"} src={imageByLink.url}></Image>
            </Box>
          ) : (
            <Box mt={2} width={"fit-content"} height={"12em"}>
              <iframe
                key={imageByLink.url}
                id="iframeUpload"
                height="100%"
                width="100%"
                src={imageByLink.originUrl}
              ></iframe>
            </Box>
          )}
          <Flex gap={3} mt={2}>
            {!inLibrary  && (
              <Button
                leftIcon={<DownloadIcon />}
                mb={3.5}
                color={colors.lato.primaryColor}
                borderColor={colors.lato.primaryColor}
                borderWidth={1}
                isLoading={isAddingToLibrary}
                onClick={() => handleAddToLibrary(imageByLink)}
              >
                Add Image to Library
              </Button>
            )}
            <Button
              leftIcon={<AddIcon />}
              mb={3.5}
              colorScheme="brand"
              onClick={() => handleChosenImage(imageByLink)}
            >
              Add Image {inLibrary ? "" : "to Trip"}
            </Button>
          </Flex>
        </>
            )*/}
    </Box>
  );
};
export default React.memo(ImageByLinkUploader);
