import { FormControl, FormLabel } from "@chakra-ui/react";
import { isSameDay, parseISO } from "date-fns";
import React from "react";
import { useController } from "react-hook-form";
import DayPicker, { DayPickerProps } from "./DayPicker";

interface RHFDayPickerProps extends DayPickerProps {
  name: string;
  label?: string;
  inputWidth?: string;
}

const RHFDayPicker: React.FC<RHFDayPickerProps> = ({ name, label, inputWidth, w, width, ...props }) => {
  const { field } = useController({ name, defaultValue: props.defaultDate });
  console.log(name);
  console.log(field.value);

  const onDayClick = (date: Date) => {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    // If it is the same date, then null should be set
    let realDate = null;
    if (!isSameDay(date, field.value)) {
      // Make sure to not use the local time of this computer
      realDate = new Date(date.getTime() - userTimezoneOffset);
    }

    // and also add the hours of the previous selected date.
    // new Date(
    //   realDate.getTime() +
    //     new Date(field.value).getUTCHours() * 60 * 60000
    // )
    field.onChange(realDate);
    if (props.customOnClickHandler) props.customOnClickHandler(realDate);
  };

  return (
    <FormControl
      // h="100%"
      pos="relative"
      isRequired={props.isRequired}
      bg={props.bg}
      position="relative"
      display={props.display}
      w={w ?? width}
      minW={w ?? width}
    >
      {label && <FormLabel htmlFor={name} mb={"2px"}>{label}</FormLabel>}
      <DayPicker
        {...props}
        defaultDate={field.value ? new Date(field.value) : undefined}
        customOnClickHandler={onDayClick}
        inputWidth={inputWidth}
      />
    </FormControl>
  );
};
export default React.memo(RHFDayPicker);
