import { Alert, AlertDescription, AlertIcon, AlertTitle, ModalCloseButton } from "@chakra-ui/react";
import React from "react";

interface PaymentProps {
  success: boolean;
}

const PaymentAlert: React.FC<PaymentProps> = ({ success }) => {
  return (
    <Alert
      status={success ? "success" : "error"}
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      pos="relative"
      py={10}
    >
      <ModalCloseButton pos="absolute" right={3} top={2} />
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        {success ? "Payment successful!" : "Payment canceled!"}
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        {success
          ? "Thank you for your purchase, you are now an official member of the Lato community."
          : "Something went wrong, please try again!."}
        {/* <br />
        <br />
        Click{" "}
        <Link as={ReactLink} to="/settings/billing">
          here
        </Link>{" "}
        to go back to your billing settings. */}
      </AlertDescription>
    </Alert>
  );
};
export default React.memo(PaymentAlert);
