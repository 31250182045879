import getApiEndpoints from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "trips";

// Get the standard CRUD operations
const basicTripEndpoints = getApiEndpoints({
  url,
});

// Get a trip with the temperatures
const getTripWithTemperature = (id: string) => {
  return rawApiProvider.get(`${url}/${id}/with-temperature`);
};

// // Clone a trip
// const cloneTrip = (id: string, model: any) => {
//   return rawApiProvider.put(`${url}/${id}/clone`, model);
// };

const updateTripShareStatus = (id: string, share: boolean) => {
  return rawApiProvider.patch(`${url}/updateShareStatus/${id}`, {
    share,
  });
};

type TripsAPIType = {
  getTrip(id: string): Promise<any>;
  createTrip(model: object): Promise<any>;
  deleteTrip(id: string): Promise<any>;
  updateTrip(id: string, model: object): Promise<any>;
  getTripWithTemperature(id: string): Promise<any>;
  // cloneTrip(id: string, model: any): Promise<any>;
  updateTripShareStatus(id: string, share: boolean): Promise<any>;
};
const TripsAPI: TripsAPIType = {
  ...basicTripEndpoints,
  getTrip: basicTripEndpoints.getSingle,
  createTrip: basicTripEndpoints.post,
  deleteTrip: basicTripEndpoints.delete,
  updateTrip: basicTripEndpoints.put,
  getTripWithTemperature: getTripWithTemperature,
  // cloneTrip: cloneTrip!,
  updateTripShareStatus,
};
export default TripsAPI;
