import React from "react";
import { Outlet } from "react-router-dom";
import useRedirectToPreviousSubroute from "../utils/useListenRouter";
import useLoadGoogleMap from "../utils/useLoadGoogleMap";

const App: React.FC = () => {
  // Listens to all route changes and redirects to the latest subroute if there is one in the history stack.
  // Should be inside a router provider so that's why we needed the App component
  // and why a root 'Route' is used instead of just 'Routes'.
  // The 'Outlet' renders the child 'Route' elements.
  const historyStack = useRedirectToPreviousSubroute();

  // Load google maps
  const mapLoadingOrError = useLoadGoogleMap();
  if (mapLoadingOrError) return mapLoadingOrError;

  return (
    <>
      <Outlet />
    </>
  );
};

export default App;
