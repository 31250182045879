import { Button } from "@chakra-ui/react";
import React from "react";
import { type DropzoneOptions } from "react-dropzone";
import useCustomDropzone from "../../../utils/useCustomDropzone";
import Dropzone from "../../Dropzone";
import { maxFileSizeMB } from "../ImageUploader";

interface ImageDropzoneProps {
  handleDroppedImages(images: File[] | File): void;
  multiple?: boolean;
}

const ImageDropzone: React.FC<ImageDropzoneProps> = ({ handleDroppedImages, multiple = true }) => {
  const [newImages, setNewImages] = React.useState<File[]>([]);

  const onDropAccepted = React.useCallback(
    (acceptedFiles: File[]) => {
      handleDroppedImages(multiple ? acceptedFiles : acceptedFiles[0]);
    },
    [handleDroppedImages, multiple],
  );

  const dropzoneOptions: DropzoneOptions = {
    multiple,
    accept: ["image/*", "image/avif", "image/heic"],
    onDropAccepted: (acceptedFiles) => {
      setNewImages(acceptedFiles);
    },
  };

  React.useEffect(() => {
    if (newImages.length) {
      onDropAccepted(newImages);
    }
  }, [newImages]);

  const dropzoneState = useCustomDropzone(dropzoneOptions);

  return (
    <Dropzone
      fileLimitMB={maxFileSizeMB}
      fileTypeDisplayName="images (.jpeg, .png, .gif, .svg, ...)"
      resourceName="image"
      dropzoneState={dropzoneState}
    />
  );
};
export default React.memo(ImageDropzone);
