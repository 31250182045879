import { AxiosResponse } from "axios";
import { toErrorMap } from "../../utils/toErrorMap";

export function handleResponse(response: AxiosResponse<any>) {
  // if (response.results) {
  //   return response.results;
  // }

  if (response.data) {
    return response.data;
  }

  return response;
}

export function handleError(error: any) {
  if (error.response) {
    if (error.response.data) {
      // Check if it is a validation error and return appropriate object
      if (error.response.data.message) {
        return _parseValidationErrors(error);
      }
    }
    // If no data object is present return the complete error response
    throw new Error(error.response);
  }
  // If no response object is present, return the complete error
  throw new Error(error);
}

function _parseValidationErrors(error: any) {
  try {
    const validationErrors = toErrorMap(error.response.data.message);
    return { errors: validationErrors };
  } catch (_e) {
    // Throw the original error.
    throw error;
    // throw new Error("Couldn't parse errors");
    // if it fails, it is not a validation error, thus
    // Do nothing and let the error be thrown in the other function
  }
}
