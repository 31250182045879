import { Box, Flex } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useContext, useEffect, useState } from "react";
import MessageAPI from "../api/messages.api";
import NotificationsAPI from "../api/notifications.api";
import UserTripsAPI from "../api/usertrips.api";
import FullScreenSpinner from "../components/FullScreenSpinner";
import { getLocalStorageNotificationCount, messageRead } from "../features/Notifications/NotificationFunctions";
import ChatInfo from "../features/chats/ChatInfo";
import ChatList from "../features/chats/ChatList";
import ChatWrapper from "../features/chats/ChatWrapper";
import ErrorCardView from "../components/layout/ErrorCardView";
import { NotificationContext } from "../stores/NotificationContext";
import { useSocketInstance, useSocketSubscribe } from "../app/providers/SocketProvider";
import { useChatStore } from "../stores/trip/chat-store";
import { UserTrip, User } from "@lato/common";
import { MESSAGES_QUERY_KEY, TRIPMESSAGES_QUERY_KEY } from "../utils/constants";
import { transformInfiniteData, useCustomInfiniteQuery } from "../utils/query-helpers/useCustomInfiniteQuery";
import { useTrip } from "./../utils/query-helpers/reactQueryHooks";

export type Message = {
  id?: string;
  message: string;
  created_at: Date;
  // trip: Omit<Trip, "chatmessages" | "userTrips">;
  usertrip: Pick<UserTrip, "id" | "external_ref" | "homeBackgroundNumber" | "trip">;
  sender: User | null;
  read: boolean;
};

interface ChatsProps {}

const Chats: React.FC<ChatsProps> = () => {
  const queryClient = useQueryClient();
  const [selectedTripId, setSelectedTripId] = React.useState<string>();
  // Assigned upon opening the chats. Such that this can be used for the pagination. Because when new messages are added, this can mess up the pagination.
  const [pageInitializationDate, setNewPageInitializationDate] = React.useState(new Date().toISOString());
  const { removeLocalTripMessages } = useChatStore();

  const [roomIds, setRoomIds] = React.useState<string[]>([]);
  // TODO: only fetch the messages instead of the complete trips? Or are the trips useful as well?
  // Or only load the trip when actually viewing the messages of the trip?
  // Default staleTime of 5mins: the messages will be refetched every 5 minutes.
  // const { isLoading, data: lastMessages, error } = useMyMessages();
  const endOfMessagesList = React.useRef();
  const {
    refetchEl,
    // paginationOptions,
    queryResult: { data: lastMessages, isLoading, error },
  } = useCustomInfiniteQuery<Message>(endOfMessagesList, {
    queryName: MESSAGES_QUERY_KEY,
    apiCall: MessageAPI.getMyMessages,
    additionalQueryState: {
      maxCreationDate: pageInitializationDate,
    },
  });
  console.log(lastMessages);
  const latestMessages = transformInfiniteData(lastMessages);

  let trip = undefined;
  // Get the trip using the tripId, only enable the hook when the trip id is defined.
  const {
    isInitialLoading: loadingTrip,
    error: errorTrip,
    data,
  } = useTrip(selectedTripId ?? "", {
    enabled: !!selectedTripId,
  });

  // Get the paginated messages of the trip
  const topOfMessagesRef = React.useRef<any>();
  const {
    paginationOptions,
    refetchEl: refetchEl2,
    queryResult: {
      data: tripMessages,
      isInitialLoading: isLoadingTripMessages,
      isFetching: isFetchingTripMessages,
      error: errorTripMessages,
    },
    triggerFetch,
  } = useCustomInfiniteQuery<Message>(topOfMessagesRef, {
    queryName: TRIPMESSAGES_QUERY_KEY,
    additionalQueryState: {
      tripId: selectedTripId,
      //maxCreationDate: pageInitializationDate,
      step: 20,
    },
    apiCall: (queryParams?: string) => UserTripsAPI.getPaginatedChatMessages(selectedTripId || "", queryParams),
    options: {
      enabled: !!selectedTripId,
      // TODO: fix RQ
      // onSuccess: () => removeLocalTripMessages(selectedTripId!),
    },
  });

  trip = data;
  const [messageList, setMessageList] = useState<Message[]>(transformInfiniteData(tripMessages));

  const socket = useSocketInstance();
  const { setNotificationCount } = useContext(NotificationContext);

  const markMessageRead = (userTripId: string) => {
    messageRead(userTripId);
    NotificationsAPI.markMessageRead(userTripId);
    const count = getLocalStorageNotificationCount();
    setNotificationCount(count);
  };

  /**
   * Update the roomIds once Agent Close the chat
   * @param roomId
   */
  const leftRoomListner = (roomId: string) => {
    setRoomIds(roomIds.filter((item) => item !== roomId));
  };

  // This event is sent whenever an agent leaves a room.
  useSocketSubscribe("leftRoom", leftRoomListner);

  useEffect(() => {
    setMessageList(transformInfiniteData(tripMessages));
  }, [tripMessages]);

  useEffect(() => {
    // if (selectedTripId && data?.userTrips) {
    //   const result = data.userTrips.find(({ id }) => id === selectedTripId);
    //   if (result?.chatmessages.length) {
    //     setMessageList(result?.chatmessages);
    //   } else {
    //     setMessageList([]);
    //   }
    // }

    if (selectedTripId) {
      // setRoomIds([...roomIds, selectedTripId]);
      setRoomIds([selectedTripId]);
    }
  }, [data]);

  // Mark Messages as Read Once A User Trip Is Selected
  useEffect(() => {
    if (selectedTripId) {
      markMessageRead(selectedTripId);
    }
  }, [selectedTripId]);

  /**
   * Join  Group/Room once new roomIds changes
   */
  useEffect(() => {
    if (roomIds.length) {
      socket?.emit("joinRoom", roomIds);
    }
  }, [roomIds]);

  const hasMore =
    tripMessages &&
    tripMessages.pages.length > 0 &&
    paginationOptions.page * paginationOptions.step < tripMessages.pages[tripMessages.pages.length - 1].count;

  // Prefetch the next page!
  // Not working because the page param creates another query but infinite queries don't work this way.
  // useEffect(() => {
  //   if (hasMore) {
  //     const { queryParamsString, sanitizedQueryParams } = buildQueryString({
  //       ...paginationOptions,
  //       tripId: selectedTripId,
  //       page: paginationOptions.page + 1,
  //     });
  //     queryClient.prefetchQuery(
  //       [TRIPMESSAGES_QUERY_KEY, sanitizedQueryParams],
  //       async () =>
  //         UserTripsAPI.getPaginatedChatMessages(
  //           selectedTripId || "",
  //           queryParamsString
  //         )
  //     );
  //   }
  // }, [paginationOptions.page, hasMore, queryClient]);

  if (error) return <ErrorCardView title="Could not load chat messages" />;
  if (isLoading) return <FullScreenSpinner />;
  if (errorTrip) return <ErrorCardView title="Could not load trip" />;
  if (errorTripMessages) return <ErrorCardView title="Could not load messages" />;

  const setUserSelectedTripId = (userTripId: string | undefined) => {
    const prevTripId = selectedTripId;
    // setNewPageInitializationDate(new Date())
    // queryClient.invalidateQueries([TRIPMESSAGES_QUERY_KEY, userTripId]);
    if (prevTripId !== userTripId) {
      socket?.emit("leaveRoom", prevTripId);
      setSelectedTripId(userTripId);
      triggerFetch({ tripId: userTripId });
    }
  };

  return (
    <div>
      <ReactQueryDevtools />
      <Flex flex="1 0 0px" flexDir={{ base: "column", xl: "row" }} minH="0px" w="100%" h={{ base: "auto", xl: "90vh" }}>
        <ChatList
          remoteMessages={latestMessages}
          setSelectedTripId={setUserSelectedTripId}
          selectedTripId={selectedTripId}
          refetchEl={refetchEl}
          trip={trip}
        />
        <ChatWrapper
          setUserTripId={setSelectedTripId}
          trip={trip}
          messageList={messageList}
          isLoading={loadingTrip || isLoadingTripMessages}
          isFetching={isFetchingTripMessages}
          refetchEl={refetchEl2}
          selectedTripId={selectedTripId}
          markMessageRead={markMessageRead}
        />
        <ChatInfo trip={trip} userTripId={selectedTripId} />
      </Flex>
    </div>
  );
};

export default Chats;
