import { GoogleAddress, parseAddressComponents } from "@lato/common";
import React from "react";
import { UseFormReturn, useController, useFormContext } from "react-hook-form";
import MapsAutocomplete, { closeAllAutocompleteBoxes } from "../../features/map/MapsAutocomplete";
import RHFInput from "./RHFInput";

/**
 * Hacky helper function in order to allow entering on a google autocomplete without selecting.
 * It creates a wrapper of the original event listener. It simulates a key down press if no option was selected,
 * and then it executes the original event handler which calls the onPlaceChanged function in the Autocomplete component.
 * https://stackoverflow.com/a/49620828/6320449
 *
 */
function enableEnterKey(input: HTMLInputElement) {
  /* Store original event listener */
  const _addEventListener = input.addEventListener;

  const addEventListenerWrapper = (type: any, listener: any) => {
    if (type === "keydown") {
      /* Store existing listener function */
      const _listener = listener;
      listener = (event: any) => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected = document.getElementsByClassName("pac-item-selected").length;
        if ((event.key === "Enter" || event.key === "Tab") && !suggestionSelected) {
          const e = new KeyboardEvent("keydown", {
            key: "ArrowDown",
            code: "ArrowDown",
            keyCode: 40,
          });
          _listener.apply(input, [e]);
        }
        _listener.apply(input, [event]);
      };
    }
    _addEventListener.apply(input, [type, listener]);
  };

  input.addEventListener = addEventListenerWrapper;
}

interface LocationInputProps {
  i: number;
  // defaultValue: any;
  disabled: boolean;
  trigger?: UseFormReturn["trigger"];
  onChange?: any;
  // hidden?: boolean;
}

const LocationInput: React.FC<LocationInputProps> = ({
  i,
  // defaultValue,
  disabled,
  trigger,
  onChange,
  // hidden = false
}) => {
  const fieldName = `tripdays.${i}.location` as "tripdays.0.location";

  const inputRef = React.useRef<HTMLInputElement>();
  // const { setValue, getValues, control, reset } = useFormContext();

  const { field } = useController({
    // control,
    name: fieldName,
  });
  const [inputValue, setInputValue] = React.useState(field.value?.name || "");

  // const [labelUP, setLabelUP] = React.useState(
  //   !!getValues()?.tripdays?.[i]?.location
  // );

  const handlePlaceChanged = (place: any) => {
    let address: GoogleAddress = {
      city: null,
      country_code: null,
      postal_code: null,
      state: null,
      street: null,
    };
    if (place.address_components) {
      const address_components = parseAddressComponents(place.address_components);
      const street = address_components.route?.long_name;
      const street_number = address_components.street_number?.long_name;
      address = {
        country_code: address_components.country?.short_name,
        state: address_components.administrative_area_level_1?.long_name,
        city: address_components.locality?.long_name,
        postal_code: address_components.postal_code?.long_name,
        street: street && street_number ? `${street_number} ${street}` : street ? street : street_number,
      };
    }
    if (place.name && place.geometry) {
      field.onChange({
        name: place.name,
        coordinates: [place.geometry.location.lng(), place.geometry.location.lat()],
        address: address,
      });
      trigger && trigger("tripdays");
      setInputValue(place.name);
      // After changing the location the field should be blurred
      field.onBlur();
      // Move the label up again
      // setLabelUP(true);
      onChange && onChange(place.name);
    }
  };

  // React.useEffect(() => {
  //   console.log(field.ref());
  //   // if (field.ref) {
  //   // The field.ref.current represents the input field
  //   // Make sure that when entering, the first option is chosen.
  //   // @ts-ignore
  //   // enableEnterKey(field.ref.current);
  //   // }
  // }, [field.ref]);

  const handleRef = (e: HTMLInputElement | null) => {
    if (e) {
      enableEnterKey(e);
    }
    field.ref(e);
  };

  return (
    <MapsAutocomplete handlePlaceChanged={handlePlaceChanged}>
      <RHFInput
        {...field}
        ref={handleRef}
        isDisabled={disabled}
        controlled
        value={inputValue}
        title={field.value?.name}
        // defaultValue={field.value?.name || ""}
        onChange={(e) => {
          setInputValue(e.target.value);
          // if (e.target.value === "") {
          //   onChange && onChange(null);
          //   //setValue(fieldName, null, { shouldDirty: false, shouldValidate: false });
          // }
        }}
        // Placeholder is set transparent in the custom css file in order to fix the google maps placeholder bug showing on top of label
        // placeholder="New York"
        // defaultValue={getValues()?.tripdays?.[selectedRecord]?.location}
        // conditionalUP={labelUP}
        onClick={(e) => e.stopPropagation()}
        onFocus={() => {
          closeAllAutocompleteBoxes();
        }}
        onBlur={() => {
          console.log("onBlur");
          // This results in a fast flash where the inputvalue is cleared and filled again
          if (field.value?.name !== inputValue) {
            if (inputValue === "") {
              // Clear the input value
              setInputValue("");
              // Clear the input
              field.onChange(null);
              trigger && trigger("tripdays");
              // Update the rhf values
              // updateRHFValues(getValues, reset);
            } else {
              // Set the input value again equal to the selected place name
              setInputValue(field.value?.name || "");
            }
          }
          // field.onBlur();
        }}
      />
    </MapsAutocomplete>
  );
};
export default React.memo(LocationInput);
