import { Flex, Icon, Image, Link, Text } from "@chakra-ui/react";
import { QueryRelation, Trip, UserTrip } from "@lato/common";
import * as React from "react";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { CardWrapper } from "../components/CardWrapper";
import FullScreenSpinner from "../components/FullScreenSpinner";
import ErrorCardView from "../components/layout/ErrorCardView";
import SplitScreenLayout from "../components/layout/SplitScreenLayout";
import { SHARE_STEP_TRIP_QUERY_KEY } from "./constants";
import { useCollaborationInvitationStatus } from "./query-helpers/reactQueryHooks";
import { getRelationMap, useSplitTripByUserTripId } from "./query-helpers/triphooks/trip-split-QueryHooks";

const useCollaborationInvite = (): {
  trip?: Trip;
  userTrips?: UserTrip[];
  return?: React.ReactNode;
  invitation?: boolean | undefined;
} => {
  const navigate = useNavigate();

  const { id: userTripId } = useParams<{ id: string }>();

  const {
    data: trip,
    isLoading: isLoadingTrip,
    error: tripError,
  } = useSplitTripByUserTripId(
    userTripId ?? "",
    SHARE_STEP_TRIP_QUERY_KEY,
    getRelationMap[SHARE_STEP_TRIP_QUERY_KEY] as QueryRelation[],
  );

  // Checkdata contains another userTripId if the user has or had access to this collaboration invitation.
  // If the invitation was already accepted and the user who accepted the invite is now again requesting the invitation.: another userTripId (the new one) is returned.
  // Else: the same userTripId is returned. (this means that a valid collaboration request should be handled)
  const { data: checkData, isLoading: loadingCheck, error: errorCheck } = useCollaborationInvitationStatus(userTripId!);
  const allUserTrips = checkData?.userTrips;

  if (errorCheck || tripError) {
    return {
      return: <ErrorCardView title={`Couldn't load trip`} description="The URL is invalid." />,
    };
  }

  if (isLoadingTrip || loadingCheck || !checkData || !allUserTrips) {
    return { return: <FullScreenSpinner /> };
  }

  // If the status is error, then the collaboration was already used by another company.
  if (checkData.status === "error") {
    return {
      return: (
        <SplitScreenLayout>
          <CardWrapper title={"Collaboration already accepted"} cardWidth="600px">
            <Text mb={10}>
              If you are a member of <b>{allUserTrips[1].user.company.name}</b>, please{" "}
              <Link onClick={() => navigate("/")} textDecoration={"underline"} _hover={{ color: "blue" }}>
                log in
              </Link>{" "}
              to view your trip.
            </Text>
            {trip && (
              <Flex justifyContent={"space-between"}>
                <Flex justifyContent={"space-between"} alignItems={"center"} flexDir={"column"} gap={2} w={"40%"}>
                  <Flex alignItems={"center"}>
                    <Image
                      alt={allUserTrips[0].brand.name}
                      className="brand-logo"
                      src={allUserTrips[0].brand.logoUrl}
                      style={{
                        maxHeight: `2em`,
                      }}
                    />
                  </Flex>
                </Flex>
                <Icon boxSize={8} alignSelf={"center"} as={FaArrowRightArrowLeft} />
                <Flex flexDir={"column"} alignItems={"center"} gap={2} w={"40%"}>
                  <Flex alignItems={"center"}>
                    <Image
                      alt={allUserTrips[1].brand.name}
                      className="brand-logo"
                      src={allUserTrips[1].brand.logoUrl}
                      style={{
                        maxHeight: `2em`,
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            )}
          </CardWrapper>
        </SplitScreenLayout>
      ),
    };
  }

  if (allUserTrips?.length !== 1 && allUserTrips?.length !== 2) {
    return {
      return: <ErrorCardView title="Incorrect number of collaborators" />,
    };
  }

  if (checkData?.status !== "allowed" && checkData?.status !== "invitation") {
    // The check returned a different userTripId, meaning that the invitaiton was already accepted by this user, thus redirect.
    navigate("/trips/" + checkData?.status);
  }

  // The check returned the same userTripId, meaning that the invitation was not yet accepted and is valid.
  return { trip, userTrips: allUserTrips, invitation: checkData?.status === "invitation" };
};
export default useCollaborationInvite;
