import React, { useContext } from "react";
import FullScreenSpinner from "../components/FullScreenSpinner";
import ErrorCardView from "../components/layout/ErrorCardView";
import { User } from "@lato/common";
import { useMe as useMeAsync } from "../utils/query-helpers/reactQueryHooks";

type MeContextType = User;

const MeContext = React.createContext<MeContextType | undefined>(undefined);
MeContext.displayName = "MeContext";

const MeProvider: React.FC<any> = (props) => {
  const { data: me, isLoading, error } = useMeAsync();

  if (isLoading) return <FullScreenSpinner />;
  if (error || !me) return <ErrorCardView title="Could not load user information" />;
  return <MeContext.Provider value={me} {...props} />;
};

function useMeContext(): MeContextType {
  const context = useContext<MeContextType | undefined>(MeContext);
  if (context === undefined) {
    throw new Error("useMe must be used within a MeProvider");
  }
  return context;
}

export { MeProvider, useMeContext };
