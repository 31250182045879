import React from "react";
import { useParams } from "react-router-dom";
import { useMeContext } from "../../../../stores/me-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { SHARE_STEP_TRIP_QUERY_KEY, SHARE_STEP_USER_TRIP_QUERY_KEY } from "../../../../utils/constants";
import { useUserTripNotes } from "../../../../utils/query-helpers/reactQueryHooks";
import {
  getRelationMap,
  useSplitTrip,
  useSplitUserTrip,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { CustomSpinner } from "../../../FullScreenSpinner";
import { NotesWrapper } from "../../../../features/notes/Notes";
import { Box, Heading, Text } from "@chakra-ui/react";

interface NotesStepProps {
  stepNumber: number;
}

export const NotesStep: React.FC<NotesStepProps> = ({ stepNumber }) => {
  const me = useMeContext();
  const { id: userTripId } = useParams<{ id: string }>();
  const { trip: tripFromStore, isFirst, isSample } = useTripFormStore();

  const { data: usertrips, isLoading: isLoadingUsertrip } = useSplitUserTrip(
    userTripId ?? "",
    getRelationMap[SHARE_STEP_USER_TRIP_QUERY_KEY],
    SHARE_STEP_USER_TRIP_QUERY_KEY,
  );

  const { data: trip, isLoading: isLoadingTrip } = useSplitTrip(
    tripFromStore?.id ?? "",
    SHARE_STEP_TRIP_QUERY_KEY,
    getRelationMap[SHARE_STEP_TRIP_QUERY_KEY],
  );

  const { data: notes, isLoading: isLoadingNotes } = useUserTripNotes(
    tripFromStore?.userTrips ? tripFromStore.userTrips[isFirst ? 0 : 1].id : userTripId,
  );

  const usertrip = usertrips && usertrips[isFirst ? 0 : 1];

  if (isLoadingTrip || isLoadingUsertrip || !trip || !usertrip || !me || isLoadingNotes)
    return <CustomSpinner m="auto" />;

  return (
    <Box mt={3} flexGrow={1}>
      <Heading size="lg">Internal notes</Heading>
      <Text
        fontSize="md"
        color="realGray.600"
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
        width={"70%"}
        mb={"0.9em"}
      >
        Some private notes not shared with your client or another professional.
      </Text>
      {isLoadingNotes ? (
        <CustomSpinner m="auto" />
      ) : (
        <NotesWrapper parentObject={usertrip} maxHeight={"none"} height={"92%"} hideSmallAdd notes={notes} />
      )}
    </Box>
  );
};
export default React.memo(NotesStep);
