import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Flex, Input, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { addDays, format, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import { Column } from "react-table";
import useRangeInput from "../input/date/CustomUseRangeInput";
import DateRangePicker from "../input/date/DateRangePicker";
interface StartDateFilterProps {
  column: { filterValue: any; setFilter: any; preFilteredRows: any } & Column<any>;
}

const StartDateFilter: React.FC<StartDateFilterProps> = ({ column: { filterValue, setFilter } }) => {
  const { dayPickerProps, fieldProps } = useRangeInput({ numberOfMonths: 2 });
  const fromDate = (dayPickerProps.selected as any)?.from;
  const toDate = (dayPickerProps.selected as any)?.to;
  const filterActivated = fromDate && toDate ? true : false;

  useEffect(() => {
    setFilter({
      from: fromDate ? format(fromDate, "yyyy-MM-dd") : undefined,
      to: toDate ? format(toDate, "yyyy-MM-dd") : undefined,
    });
  }, [dayPickerProps.selected]);

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        color="gray.500"
        as={Button}
        rightIcon={
          <Flex alignItems="center">
            <Text color="gray.800" isTruncated size="sm">
              {filterActivated ? "" : "All"}
            </Text>
            <ChevronDownIcon boxSize={5} />
          </Flex>
        }
      >
        period
      </MenuButton>
      <MenuList zIndex={300}>
        <MenuItem>
          <DayPicker {...dayPickerProps} />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
export default React.memo(StartDateFilter);
