import { Button, Flex, FlexProps, Heading, Icon } from "@chakra-ui/react";
import React from "react";

interface EventTypeCardProps extends FlexProps {
  icon: any;
  name: string;
  isDisabled?: boolean;
  isHidden?: boolean;
}

const EventTypeCard: React.FC<EventTypeCardProps> = ({ icon, name, isHidden, isDisabled, ...props }) => {
  if (isHidden) return;
  return (
    <Flex
      as={Button}
      flexDir={"column"}
      w={28}
      h={{ base: "12", lg: "16", xl: "24" }}
      mx={{ base: "1", lg: "2" }}
      borderRadius={"md"}
      colorScheme="brand"
      variant="outline"
      borderWidth="2px"
      justifyContent="center"
      isDisabled={isDisabled}
      boxShadow="md"
      {...props}
    >
      <Icon as={icon} boxSize={4} />
      <Heading fontSize={{ base: "10px", lg: "12px" }} fontWeight={600}>
        {name}
      </Heading>
    </Flex>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.isDisabled === nextProps.isDisabled;
};

export default React.memo(EventTypeCard, areEqual);
