import { Flex } from "@chakra-ui/react";
import React from "react";
import Section from "../../../layout/Section";
import Settings from "../../Settings";

// Stripe initialization
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import StripeAPI from "../../../../api/stripe.api";
import config from "../../../../config";
import { useMeContext } from "../../../../stores/me-context";
import ENV from "../../../../utils/env";
import FullScreenSpinner from "../../../FullScreenSpinner";
import { Plan } from "@lato/common";
import Plans from "../Plans";

const configEnv = config[ENV];

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(configEnv.STRIPE_PUBLIC_API_KEY);

interface CheckoutProps {}

export type Subscription = {
  plan: Plan;
  users: number;
  yearly: boolean;
};

const Checkout: React.FC<CheckoutProps> = () => {
  const [chosenPlan, choosePlan] = React.useState<Subscription | null>(null);
  const [stripeClientSecret, setStripeClientSecret] = React.useState<string | null>(null);
  const me = useMeContext();
  const { mutateAsync: switchPlan, isPending: isLoadingCheckout } = useMutation({
    mutationFn: (object: any) => StripeAPI.createSubscription(object),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      // queryClient.invalidateQueries(ME_QUERY_KEY);
    },
  });
  const currentPlan = me.company.plan;

  const handleChooseSubscription = async (subscription: Subscription) => {
    choosePlan(subscription);
    const { clientSecret } = await switchPlan({
      priceId: configEnv.STRIPE_PRO_QUARTERLY,
      nrOfUsers: 1,
    });
    setStripeClientSecret(clientSecret);
  };

  if (isLoadingCheckout) return <FullScreenSpinner />;

  return (
    <Settings>
      {!stripeClientSecret ? (
        <Plans
          currentSubscription={{
            plan: currentPlan,
            users: me.company.maxUsers,
            yearly: true,
          }}
          setSubscription={handleChooseSubscription}
          payingPlan={false}
        />
      ) : (
        <Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }}>
          <Flex>
            <Section title="Current plan" h="auto">
              <form>
                <PaymentElement />
                <button>Submit</button>
              </form>
            </Section>
            <Section title="Checkout summary" h="auto">
              Stripe checkout summary: - X users
            </Section>
          </Flex>
        </Elements>
      )}
    </Settings>
  );
};
export default React.memo(Checkout);
