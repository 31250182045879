import { Box, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { UserTrip } from "@lato/common";

interface TripsGridProps {
  usertrips: UserTrip[];
}

const TripsGrid: React.FC<TripsGridProps> = ({ usertrips }) => {
  console.log("Rerendering: TripsGrid");
  return (
    <SimpleGrid minChildWidth="200px" spacing={5}>
      {usertrips.map((usertrip, i) => {
        // const numberOfDays = differenceInNumberOfDays(
        //   new Date(usertrip.trip!.start_date),
        //   new Date(usertrip.trip!.end_date)
        // );
        const numberOfDays = usertrip.trip!.nrOfDays!;
        return (
          <Flex bg="white" borderRadius="lg" boxShadow="lg" overflow="hidden" key={`trip-grid-${i}`}>
            <Box
              w="30%"
              // bg={`url('${backgroundUrl}') no-repeat center center fixed`}
              style={{
                WebkitBackgroundSize: "auto 100vh",
                MozBackgroundSize: "auto 100vh",
                OBackgroundSize: "auto 100vh",
              }}
              bgSize="auto 100vh"
            />
            <Box p={3}>
              <Heading size="lg">{usertrip.trip?.titles && usertrip.trip?.titles[0].content}</Heading>
              <Heading size="md">{usertrip.trip?.client_name}</Heading>
              <Text fontSize="sm">
                {numberOfDays} days • {numberOfDays - 1} nights
              </Text>
              <Text fontSize="sm">
                {usertrip.trip!.start_date && new Date(usertrip.trip!.start_date).toDateString()} -{" "}
                {usertrip.trip!.end_date && new Date(usertrip.trip!.end_date).toDateString()}
              </Text>
              <Text fontSize="sm">{usertrip.external_ref}</Text>
            </Box>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};
export default React.memo(TripsGrid);
