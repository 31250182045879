/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, BoxProps, Flex, Portal } from "@chakra-ui/react";
import React from "react";
import StrapiAnnouncementAlert from "./alerts/StrapiAnnouncementAlert";

interface FullScreenProps extends BoxProps {}

const FullScreen: React.FC<FullScreenProps> = ({ children, ...props }) => {
  return (
    <Portal>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 100000,
          userSelect: "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <StrapiAnnouncementAlert />
        <Flex
          bg="rgb(247, 252, 252)"
          direction="column"
          width="full"
          overflow="auto"
          height="100vh"
          align="center"
          justifyContent="center"
          {...props}
        >
          {children}
        </Flex>
      </div>
    </Portal>
  );
};
export default FullScreen;
