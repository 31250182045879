import { Flex, FormErrorMessage, Text } from "@chakra-ui/react";
import { addDays } from "date-fns";
import React from "react";
import { type DateRange } from "react-day-picker";
import { UseRangeInputOptions } from "../../../types/UseRangeInputOptions";
// TODO: change below css import, see docs
import { useFormContext } from "react-hook-form";
import { date } from "yup";
import DateRangePicker from "./DateRangePicker";
import { omitLocalTimezone } from "@lato/common";

interface DatePickerProps {
  defaultStart: string | null;
  defaultEnd: string | null;
  onChange?: any;
}

const dateToString = (date: Date) => {
  // Substract the timezone difference
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().substring(0, 10);
};
const startFieldName = "start_date";
const endFieldName = "end_date";
const DatePicker: React.FC<DatePickerProps> = ({ defaultStart, defaultEnd, onChange = () => {} }) => {
  const {
    formState: { errors },
    trigger,
    setValue,
    reset,
    getValues,
  } = useFormContext<any>();
  const startValidationError: any = errors[startFieldName];
  const endValidationError: any = errors[endFieldName];
  console.log("debug", defaultStart);
  console.log("debug", defaultEnd);
  const [startValue, setStartValue] = React.useState(defaultStart);
  const [endValue, setEndValue] = React.useState(defaultEnd);
  const [previousStartValue, setPreviousStartValue] = React.useState(defaultStart);
  const [previousEndValue, setPreviousEndValue] = React.useState(defaultEnd);

  // const {
  //   field: { onChange: onStartChange, value: startValue, onBlur: onStartBlur },
  //   fieldState: fieldStateStart,
  // } = useController({ name: startFieldName });
  // const {
  //   field: { onChange: onEndChange, value: endValue },
  //   fieldState: fieldStateEnd,
  // } = useController({ name: endFieldName });
  const updateRHFValues = (dateRange: DateRange | undefined) => {
    // console.log(dateRange?.to);
    const newStart = dateRange?.from ? dateToString(dateRange.from) : null;
    const newEnd = dateRange?.to ? dateToString(dateRange.to) : null;
    setValue("start_date", newStart, { shouldDirty: true });
    setValue("end_date", newEnd, { shouldDirty: true });
    setStartValue(newStart);
    setEndValue(newEnd);
    trigger("tripdays" as any);
    if (onChange) onChange();
  };
  const [defaultSelected, setDefaultSelected] = React.useState<DateRange>(
    !startValue || !endValue
      ? { from: new Date(), to: addDays(new Date(), 7) }
      : { from: omitLocalTimezone(new Date(startValue)), to: omitLocalTimezone(new Date(endValue)) },
  );
  console.log(defaultSelected);

  console.log(startValue);
  console.log(endValue);
  const numberOfNights =
    endValue && startValue ? (new Date(endValue).valueOf() - new Date(startValue).valueOf()) / (1000 * 3600 * 24) : 0;

  // const onBlurDateInput = React.useCallback(
  //   (e: React.FocusEvent<HTMLInputElement>) => {
  //     // Call the on blur handler of react-hook-form.
  //     onStartBlur();
  //   },
  //   [onStartBlur]
  // );

  const today = new Date();
  const defaultStartDate = defaultStart ? new Date(defaultStart) : new Date();

  const rangePickerOptions: UseRangeInputOptions = {
    min: 1,
    // // date-fns specific date format type
    format: "PP",
    numberOfMonths: 2,
    onSelect: updateRHFValues,
    fromDate: defaultStartDate < today ? defaultStartDate : today,
    // showOutsideDays,
    // updateRHFValues,
  };

  const onBeforeClose = () => {
    if (!startValue || !endValue) {
      let newDateRange: DateRange = {
        from: omitLocalTimezone(new Date(startValue ?? new Date())),
        to: omitLocalTimezone(new Date(startValue ?? new Date())),
      };
      if (!startValue && !endValue) {
        newDateRange =
          !previousStartValue || !previousEndValue
            ? { ...newDateRange, to: addDays(new Date(), 7) }
            : {
                from: omitLocalTimezone(new Date(previousStartValue)),
                to: omitLocalTimezone(new Date(previousEndValue)),
              };
      }
      setDefaultSelected(newDateRange);
      updateRHFValues(newDateRange);
    }
  };

  return (
    <Flex align="baseline" w="100%">
      {/* <FormControl
        isInvalid={fieldStateStart.invalid || fieldStateEnd.invalid}
        pt={3}
      > */}
      <DateRangePicker {...rangePickerOptions} defaultSelected={defaultSelected} beforeClose={onBeforeClose} />
      {startValidationError?.message && (
        <FormErrorMessage mt="-0.5" mx="1px">
          {startValidationError.message}
        </FormErrorMessage>
      )}
      {endValidationError?.message && (
        <FormErrorMessage mt="-0.5" mx="1px">
          {endValidationError.message}
        </FormErrorMessage>
      )}
      {/* </FormControl> */}
      <Text
        fontSize="sm"
        mx={2}
        whiteSpace="nowrap"
        // overflow="hidden"
        color="realGray.400"
      >
        <DaysAndNights numberOfNights={numberOfNights} />
      </Text>
    </Flex>
  );
};
export default React.memo(DatePicker);

export const DaysAndNights: React.FC<{ numberOfNights: number }> = ({ numberOfNights }) => (
  <>
    {/* <b> */}
    {numberOfNights + 1} {numberOfNights === 0 ? "day" : "days"}
    {/* </b> */}
    {" • "}
    {numberOfNights} {numberOfNights === 1 ? "night" : "nights"}
  </>
);

export const DatePickerWrapper = React.memo((props: { onChange: any }) => {
  const { getValues } = useFormContext<any>();
  const defaultStart: string | null = getValues("start_date");
  const defaultEnd: string | null = getValues("end_date");
  console.log("RERENDERING");
  return <DatePicker {...props} defaultStart={defaultStart} defaultEnd={defaultEnd} />;
});
