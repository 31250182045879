import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { type DropzoneState } from "react-dropzone";

interface DropzoneProps {
  resourceName: string;
  fileTypeDisplayName: string;
  dropzoneState: DropzoneState;
  fileLimitMB: number;
}

const Dropzone: React.FC<DropzoneProps> = ({ resourceName, dropzoneState, fileTypeDisplayName, fileLimitMB }) => {
  const { acceptedFiles, getRootProps, open, getInputProps, fileRejections } = dropzoneState;

  return (
    <Flex
      {...getRootProps({
        borderWidth: "3px",
        borderColor: "gray.200",
        borderStyle: "dashed",
        borderRadius: "lg",
        p: 5,
        w: "100%",
        h: "90%",
        minH: "90%",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      {/* <p>{JSON.stringify(watch(fieldArrayName))}</p> */}
      <input {...getInputProps()} />
      <Box>
        <Flex
          margin="auto"
          gap={1}
          justifyContent={"center"}
          flexDir={{ base: "column", md: "row" }}
          align={{ base: "center", md: "none" }}
        >
          <Text align="center">
            Drag {"&"} drop {resourceName}, or click
          </Text>
          <Text fontWeight={"bold"} cursor={"pointer"} _hover={{ textDecoration: "underline" }}>
            here
          </Text>
        </Flex>

        <Text fontSize="sm" align="center">
          Only {fileTypeDisplayName} smaller than {fileLimitMB} MB will be accepted.
        </Text>
      </Box>
    </Flex>
  );
};
export default React.memo(Dropzone);
