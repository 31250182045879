import { Box, Button, ButtonGroup, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { Language, Trip } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import TripsAPI from "../../../api/trips.api";
import UserTripsAPI from "../../../api/usertrips.api";
import { useMeContext } from "../../../stores/me-context";
import { useTripLanguage } from "../../../stores/trip-language-context";
import {
  GENERAL_STEP_TRIP_QUERY_KEY,
  GENERAL_STEP_USERTRIP_QUERY_KEY,
  PRICE_STEP_TRIP_QUERY_KEY,
  SAMPLES_USERTRIPS_KEY,
  TRIPDAY_ACCOMMODATION_QUERY_KEY,
  TRIPDAY_ACTIVITY_QUERY_KEY,
  TRIPDAY_TRANSPORTATION_QUERY_KEY,
  TRIPS_USERTRIPS_KEY,
  TRIP_PLAIN_QUERY_KEY,
  TRIP_TRANSLATIONS_QUERY_KEY,
  USERTRIP_PLAIN_QUERY_KEY,
} from "../../../utils/constants";
import { useLanguages } from "../../../utils/query-helpers/reactQueryHooks";
import { handleSubmission } from "../../../utils/toErrorMap";
import Form from "../../form/Form";
import RHFSelect from "../../input/RHFSelect";
import DeeplModal from "../../../features/language/DeeplModal";
import { NewLanguage } from "../../../features/editTrip/wizard/WizardTopBar";
import { checkOwnTranslation } from "./daybyday/LanguagesInput";

interface TripCustomizationLanguageModalProps {
  trip: Trip;
}

const TripCustomizationLanguageModal: React.FC<TripCustomizationLanguageModalProps> = ({ trip }) => {
  const user = useMeContext();
  const navigate = useNavigate();

  const toast = useToast();
  const queryClient = useQueryClient();
  const { changeTripLanguageContext } = useTripLanguage();

  const formMethods = useForm<{ language_code: string }>({
    shouldUnregister: false,
    defaultValues: {
      language_code: "nl",
    },
  });

  const translatedLanguages = trip.priceDescriptions
    .filter((t) => !checkOwnTranslation(t.creator, user))
    .map((t) => t.language);

  const modalDisclosure = useDisclosure();
  const [newLanguage, setNewLanguage] = React.useState<NewLanguage>();

  const { data: allLanguages, isLoading: isLoadingLanguages, error: errorLanguages } = useLanguages();

  const { mutateAsync: acceptInvite } = useMutation({
    mutationFn: (language_code: string) =>
      UserTripsAPI.addLanguageToTrip(trip.userTrips[trip.userTrips.length - 1].id!, language_code),
    onSuccess: () => {
      // Improve this, that not both need to be invalidated
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });

  const reset = async (translatedTrip: Trip) => {
    await TripsAPI.updateTrip(translatedTrip.id!, translatedTrip);
    goBack();
  };

  const successCallback = React.useCallback((resp: any) => {
    goBack();
  }, []);

  const goBack = () => {
    // Reload such that the useCollaborationInvitationCheck runs again.
    // Because when a logged out user, logs in using a user of the same company of the usertrip he should just see the trip again.
    navigate(0);
  };

  if (isLoadingLanguages) return <div>Loading...</div>;
  if (errorLanguages || !allLanguages) return <div>Could not load languages</div>;

  const handleAcceptInvite = async (formValues: any) => {
    if (translatedLanguages.findIndex((language) => language.code === formValues.language_code) === -1) {
      setNewLanguage({
        id: trip.userTrips[trip.userTrips.length - 1].id ?? "",
        copy: false,
        index: 0,
        language: allLanguages.filter((lang) => lang.code === formValues.language_code)[0],
        reset: reset,
      });
      modalDisclosure.onOpen();
    } else {
      await handleSubmission({
        successMessage: "imported language",
        failMessage: "importing language",
        apiCall: acceptInvite(formValues.language_code),
        toast,
        successCallback,
        setError: formMethods.setError,
      });
    }
  };

  const processNewLanguage = async () => {
    changeTripLanguageContext((c) => ({
      ...c,
      secondSelectedLanguageIndex: newLanguage ? newLanguage.index : 0,
      // Add the new language to the context store
      partialFields: [...c.partialFields, { creator: null, language: newLanguage!.language }],
    }));
  };

  const shareTripTitle = `'${trip.userTrips[0].brand.name}' shared this trip with you.`;

  return (
    <>
      <Form formMethods={formMethods} onSubmit={handleAcceptInvite}>
        <Box textAlign="center">
          <Text fontSize="md" mb={3}>
            {/* Customize this trip by filling out the fields created in the right
          column in step 3 and 4. */}
            {shareTripTitle}
          </Text>
          <Text fontSize="md" mb={3}>
            Choose the language in which you want to customize this trip. After choosing the language, you can adjust
            the trip.
          </Text>
          <RHFSelect
            name="language_code"
            options={allLanguages.map((l: Language) => ({
              value: l.code,
              text: `${l.flag} ${l.nativename}`,
            }))}
          />

          <ButtonGroup colorScheme="brand" mt={5}>
            <Button as={ReactLink} to="/trips" variant="outline" colorScheme="red">
              Cancel
            </Button>
            <Button type="submit" isLoading={formMethods.formState.isSubmitting}>
              Select
            </Button>
          </ButtonGroup>
        </Box>
      </Form>
      <DeeplModal
        modalDisclosure={modalDisclosure}
        currentlySelectedIndex={0}
        translatedLanguages={translatedLanguages}
        newLanguage={newLanguage}
        processNewLanguage={processNewLanguage}
        translateFromUserCompanyId={trip.userTrips[0].user.companyId}
        addLanguageCall={UserTripsAPI.addLanguageToTrip}
        invalidateQueryKeys={[
          TRIP_TRANSLATIONS_QUERY_KEY,
          GENERAL_STEP_TRIP_QUERY_KEY,
          TRIPDAY_ACTIVITY_QUERY_KEY,
          TRIPDAY_ACCOMMODATION_QUERY_KEY,
          TRIPDAY_TRANSPORTATION_QUERY_KEY,
          PRICE_STEP_TRIP_QUERY_KEY,
          TRIP_PLAIN_QUERY_KEY,
          USERTRIP_PLAIN_QUERY_KEY,
        ]}
      />
    </>
  );
};
export default React.memo(TripCustomizationLanguageModal);
