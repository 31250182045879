import ShareStep from "../../../../components/trips/edit/share/ShareStep";
import { default as TripStepWrapper } from "../TripStepWrapper";

export const ShareTripRoute: React.FC = () => {
  const stepNumber = 6;

  return (
    <TripStepWrapper>
      <ShareStep stepNumber={stepNumber} />
    </TripStepWrapper>
  );
};

export default ShareTripRoute;
