import React, { FC, useRef } from "react";
import AirportsAPI from "../../api/airports.api";
import { AIRPORTS_QUERY_KEY } from "../../utils/constants";
import { SingleAirport } from "../../features/flights/ManualFlightCreation";
import CustomDownShiftWithPagination from "./CustomDownShiftWithPagination";

interface AirportDownShiftProps {
  onSelectedHandler: (param: any) => any;
  airport?: any;
  disableInput?: boolean;
}

const AirportDownShift: FC<AirportDownShiftProps> = (props: AirportDownShiftProps) => {
  const formatSelectedInputValue = (item: any) => {
    return item
      ? `${item?.iata == undefined || item?.iata == "---" || item?.iata == "" ? item.icao : item.iata} ${item.city} ${
          item.country
        }`
      : "";
  };

  return (
    <>
      <CustomDownShiftWithPagination
        apiCall={(queryParams?: string) => AirportsAPI.getAirports(queryParams)}
        queryName={AIRPORTS_QUERY_KEY}
        listItemConfig={{
          ListItem: SingleAirport,
          showSelected: ({ item }: { item: any }) => formatSelectedInputValue(item),
          onSelected: ({ item }: { item: any }) => props.onSelectedHandler(item),
        }}
        additionalQueryState={{
          step: 5,
          page: 1,
        }}
        disableInput={props.disableInput}
        defaultSelected={props.airport}
        initialInputValue={formatSelectedInputValue(props.airport)}
        height="200px"
      />
      <style>
        {`.overflowVisible {
            overflow: visible;
          }`}
      </style>
    </>
  );
};

export default AirportDownShift;
