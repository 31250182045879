import { Icon } from "@chakra-ui/react";
import { PRIMARY_COLOR } from "@lato/common";
import { CustomSVGIcon } from "./MiniDrawerIcons";

const MultipleTabsIllustration = (props: CustomSVGIcon) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width="653.1" height="396.5" viewBox="0 0 653.1 396.5" {...props}>
    <g data-name="Group 22" transform="translate(-288 -252.3)">
      <g data-name="Group 20" transform="rotate(-33 -10518.6 -1553.6)">
        <path
          data-name="Path 561"
          d="M-1971.7 6182h-200.4a2.9 2.9 0 0 0-2.9 2.9v135.8a2.9 2.9 0 0 0 2.9 2.8h200.4a2.9 2.9 0 0 0 2.2-1 2.7 2.7 0 0 0 .4-.7 2.8 2.8 0 0 0 .2-1.1v-135.8a2.9 2.9 0 0 0-2.8-2.9Zm2 138.7a2 2 0 0 1-.3 1.1 2.1 2.1 0 0 1-.8.7 2 2 0 0 1-1 .2H-2172a2 2 0 0 1-2-2v-135.8a2 2 0 0 1 2-2h200.4a2 2 0 0 1 2 2Z"
          fill="#3f3d56"
        />
        <path data-name="Rectangle 99" fill="#3f3d56" d="M-2174.6 6193.5h205.3v.8h-205.3z" />
        <circle data-name="Ellipse 88" cx="2.5" cy="2.5" r="2.5" transform="translate(-2170 6185.7)" fill="#3f3d56" />
        <circle data-name="Ellipse 89" cx="2.5" cy="2.5" r="2.5" transform="translate(-2163 6185.7)" fill="#3f3d56" />
        <circle data-name="Ellipse 90" cx="2.5" cy="2.5" r="2.5" transform="translate(-2155.9 6185.7)" fill="#3f3d56" />
        <path
          data-name="Path 583"
          d="M-2100.8 6234.8h-52a2.5 2.5 0 0 1 0-5h52a2.5 2.5 0 0 1 0 5Z"
          fill={PRIMARY_COLOR}
        />
        <path data-name="Path 584" d="M-2068.8 6237h-89.1a.3.3 0 1 1 0-.7h89.1a.3.3 0 0 1 0 .7Z" fill="#3f3d56" />
        <path data-name="Path 585" d="M-2100.8 6256h-52a2.5 2.5 0 0 1 0-5h52a2.5 2.5 0 0 1 0 5Z" fill={PRIMARY_COLOR} />
        <path data-name="Path 586" d="M-2068.8 6258.1h-89.1a.3.3 0 1 1 0-.6h89.1a.3.3 0 1 1 0 .6Z" fill="#3f3d56" />
        <path data-name="Path 587" d="M-2100.8 6277.1h-52a2.5 2.5 0 0 1 0-5h52a2.5 2.5 0 0 1 0 5Z" fill="#f2f2f2" />
        <path data-name="Path 588" d="M-2068.8 6279.3h-89.1a.3.3 0 1 1 0-.7h89.1a.3.3 0 0 1 0 .7Z" fill="#3f3d56" />
      </g>
      <path
        data-name="Path 552"
        d="M545.7 391.5a10 10 0 0 1 1.4.7l44.8-19 1.6-12h18l-1.1 27-59.2 15.7a10.6 10.6 0 0 1-.4 1.2 10.2 10.2 0 1 1-5.1-13.6Z"
        fill="#a0616a"
      />
      <path data-name="Path 553" d="M618 637h-12.3l-5.8-47.3h18Z" fill="#a0616a" />
      <path data-name="Path 554" d="M597 633.5h23.6v14.9h-38.5a14.9 14.9 0 0 1 14.9-14.9Z" fill="#2f2e41" />
      <path data-name="Path 555" d="m665.7 633.6-12.2 1.2-10.5-46.5 18-1.8Z" fill="#a0616a" />
      <path
        data-name="Path 556"
        d="m644.4 632.1 23.5-2.3 1.5 14.8-38.4 3.8a14.9 14.9 0 0 1 13.4-16.3Z"
        fill="#2f2e41"
      />
      <circle data-name="Ellipse 84" cx="24.6" cy="24.6" r="24.6" transform="translate(596.8 262)" fill="#a0616a" />
      <path
        data-name="Path 557"
        d="M603 627.5a4.5 4.5 0 0 1-4.4-3.7L571 470.2a1.4 1.4 0 0 1 0-.3v-8.5a1.5 1.5 0 0 1 .2-1l2.8-3.8a1.5 1.5 0 0 1 1.1-.6c15.7-.7 66.8-2.9 69.3.2s1.6 12.5 1.4 14.4v.2l23 147a4.5 4.5 0 0 1-3.7 5.1l-14.4 2.4a4.5 4.5 0 0 1-5-3.1c-4.5-14.2-19.3-62-24.5-80.4a.5.5 0 0 0-1 .1l1.1 78v1.7a4.5 4.5 0 0 1-4 4.6l-13.9 1.2h-.4Z"
        fill="#2f2e41"
      />
      <path
        data-name="Path 99"
        d="M605.8 320.8a20.5 20.5 0 0 0-8.3 12 113.7 113.7 0 0 0-4.9 27.2l-1.6 27.6-19.2 73.1c16.7 14.1 26.3 11 48.8-.6s25 3.8 25 3.8l4.5-62.2 6.4-68a30.2 30.2 0 0 0-4.9-4.7 49.7 49.7 0 0 0-42.4-9Z"
        fill={PRIMARY_COLOR}
      />
      <path
        data-name="Path 558"
        d="M599.6 406.2a10.5 10.5 0 0 1 1.5.7l44.3-22.2.7-12 18.3-1.2 1 27.4-59.3 19.6a10.5 10.5 0 1 1-6.6-12.3Z"
        fill="#a0616a"
      />
      <path
        data-name="Path 101"
        d="M656.2 334c10.9 3.8 12.8 45.5 12.8 45.5-12.8-7-28.2 4.5-28.2 4.5s-3.2-10.9-7-25a24.5 24.5 0 0 1 5-23.1s6.5-5.8 17.4-2Z"
        fill={PRIMARY_COLOR}
      />
      <path
        data-name="Path 102"
        d="M643.4 293c-3-2.4-7.2 2-7.2 2l-2.5-22s-15.3 1.9-25-.6-11.4 8.9-11.4 8.9a78.6 78.6 0 0 1-.3-13.8c.6-5.5 8.6-11 22.7-14.7s21.4 12.3 21.4 12.3c9.8 4.9 5.4 30.4 2.3 28Z"
        fill="#2f2e41"
      />
      <g data-name="Group 19" transform="translate(2910 -5674.8)">
        <path
          data-name="Path 561"
          d="M-1971.7 6182h-200.4a2.9 2.9 0 0 0-2.9 2.9v135.8a2.9 2.9 0 0 0 2.9 2.8h200.4a2.9 2.9 0 0 0 2.2-1 2.7 2.7 0 0 0 .4-.7 2.8 2.8 0 0 0 .2-1.1v-135.8a2.9 2.9 0 0 0-2.8-2.9Zm2 138.7a2 2 0 0 1-.3 1.1 2.1 2.1 0 0 1-.8.7 2 2 0 0 1-1 .2H-2172a2 2 0 0 1-2-2v-135.8a2 2 0 0 1 2-2h200.4a2 2 0 0 1 2 2Z"
          fill="#3f3d56"
        />
        <path data-name="Rectangle 99" fill="#3f3d56" d="M-2174.6 6193.5h205.3v.8h-205.3z" />
        <circle data-name="Ellipse 88" cx="2.5" cy="2.5" r="2.5" transform="translate(-2170 6185.7)" fill="#3f3d56" />
        <circle data-name="Ellipse 89" cx="2.5" cy="2.5" r="2.5" transform="translate(-2163 6185.7)" fill="#3f3d56" />
        <circle data-name="Ellipse 90" cx="2.5" cy="2.5" r="2.5" transform="translate(-2155.9 6185.7)" fill="#3f3d56" />
        <path
          data-name="Path 583"
          d="M-2100.8 6234.8h-52a2.5 2.5 0 0 1 0-5h52a2.5 2.5 0 0 1 0 5Z"
          fill={PRIMARY_COLOR}
        />
        <path data-name="Path 584" d="M-2068.8 6237h-89.1a.3.3 0 1 1 0-.7h89.1a.3.3 0 0 1 0 .7Z" fill="#3f3d56" />
        <path data-name="Path 585" d="M-2100.8 6256h-52a2.5 2.5 0 0 1 0-5h52a2.5 2.5 0 0 1 0 5Z" fill={PRIMARY_COLOR} />
        <path data-name="Path 586" d="M-2068.8 6258.1h-89.1a.3.3 0 1 1 0-.6h89.1a.3.3 0 1 1 0 .6Z" fill="#3f3d56" />
        <path data-name="Path 587" d="M-2100.8 6277.1h-52a2.5 2.5 0 0 1 0-5h52a2.5 2.5 0 0 1 0 5Z" fill="#f2f2f2" />
        <path data-name="Path 588" d="M-2068.8 6279.3h-89.1a.3.3 0 1 1 0-.7h89.1a.3.3 0 0 1 0 .7Z" fill="#3f3d56" />
      </g>
      <path
        data-name="Path 561"
        d="M531.9 371.2H291.5a3.5 3.5 0 0 0-3.5 3.5v162.9a3.5 3.5 0 0 0 3.5 3.4h240.4a3.5 3.5 0 0 0 2.6-1.2 3.2 3.2 0 0 0 .6-1 3.4 3.4 0 0 0 .2-1.2v-163a3.5 3.5 0 0 0-3.4-3.4Zm2.4 166.4a2.4 2.4 0 0 1-.4 1.3 2.6 2.6 0 0 1-.9.9 2.5 2.5 0 0 1-1.1.2H291.5a2.5 2.5 0 0 1-2.5-2.4v-163a2.5 2.5 0 0 1 2.5-2.4h240.4a2.5 2.5 0 0 1 2.4 2.5Z"
        fill="#3f3d56"
      />
      <path data-name="Rectangle 99" fill="#3f3d56" d="M288.5 385h246.3v1H288.5z" />
      <circle data-name="Ellipse 88" cx="3" cy="3" r="3" transform="translate(294 375.7)" fill="#3f3d56" />
      <circle data-name="Ellipse 89" cx="3" cy="3" r="3" transform="translate(302.4 375.7)" fill="#3f3d56" />
      <circle data-name="Ellipse 90" cx="3" cy="3" r="3" transform="translate(311 375.7)" fill="#3f3d56" />
      <path data-name="Path 589" d="M477.4 471.1h-82.2a3.7 3.7 0 1 1 0-7.5h82.2a3.7 3.7 0 0 1 0 7.5Z" fill="#f2f2f2" />
      <path data-name="Path 590" d="M423.7 455.2h-28.5a3.7 3.7 0 1 1 0-7.5h28.5a3.7 3.7 0 0 1 0 7.5Z" fill="#f2f2f2" />
      <circle
        data-name="Ellipse 91"
        cx="15.9"
        cy="15.9"
        transform="translate(342 443.9)"
        fill={PRIMARY_COLOR}
        r="15.9"
      />
      <path
        data-name="Path 591"
        d="M485.7 435.5H337.5a8 8 0 0 0-8 8V476a8 8 0 0 0 8 8h148.2a8 8 0 0 0 7.9-8v-32.7a8 8 0 0 0-8-8Zm7 40.6a7 7 0 0 1-7 7H337.5a7 7 0 0 1-7-7v-32.7a7 7 0 0 1 7-7h148.2a7 7 0 0 1 7 7Z"
        fill="#3f3d56"
      />
    </g>
  </Icon>
);
export default MultipleTabsIllustration;
