import { Flex, IconButton, Tooltip, Box, IconButtonProps, Spinner } from "@chakra-ui/react";
import React from "react";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { BsFlagFill } from "react-icons/bs";
import { MdHotel } from "react-icons/md";

interface AddElementOnHoverProps {
  i: number;
  nrOfHotels: number;
  nrOfEvents: number;
  nrOfNights: number;
  first: boolean;
  isFetchingTripday: boolean;
  isUpdatingTripday: boolean;
  addAccommodation: (e: any, accommodation?: any, ord?: number) => Promise<number>;
  addActivity: (e: any, activity?: any, ord?: number) => Promise<number>;
  addTransportation: (e: any, transportation?: any, ord?: number) => Promise<number>;
  handleDragAndDropMove: (fromOrd: number, toOrd: number) => Promise<void>;
}

const AddElementOnHover: React.FC<AddElementOnHoverProps> = ({
  i,
  nrOfEvents,
  nrOfHotels,
  nrOfNights,
  first,
  isUpdatingTripday,
  isFetchingTripday,
  addAccommodation,
  addActivity,
  addTransportation,
  handleDragAndDropMove,
}) => {
  const addElementInBetween = React.useCallback(
    async (e: any, action: (e: any, object?: any, ord?: number) => Promise<number>) => {
      const result = await action(e, undefined, i);
      //await handleDragAndDropMove(ord, i);
    },
    [handleDragAndDropMove, i],
  );

  return (
    <Box
      h={7}
      // _hover={{
      //   height: "auto",
      // }}
      // _hover={{
      //   display: "block",
      // }}
      className="group relative hover:h-8 max-h-2"
    >
      <Box className="hidden group-hover:flex justify-around items-center absolute top-[50%] translate-y-[-70%] z-50 w-full">
        <Box h="1px" w="30%" background="brand.500" />
        {isFetchingTripday ? (
          <Spinner />
        ) : (
          <Flex gap={2}>
            {nrOfHotels < 1 && nrOfNights > 0 && first && (
              // Add onClick
              <AddElementOnHoverButton
                resourcename="accommodation"
                Icon={MdHotel}
                onClick={(e) => addElementInBetween(e, addAccommodation)}
              />
            )}
            {/* Add disabled and onClick */}
            {nrOfEvents < 50 && first && (
              <AddElementOnHoverButton
                resourcename="activity"
                Icon={BsFlagFill}
                isDisabled={isUpdatingTripday}
                onClick={(e) => addElementInBetween(e, addActivity)}
              />
            )}
            {/* Add disabled and onClick */}
            <AddElementOnHoverButton
              resourcename="transportation"
              Icon={BiSolidPlaneAlt}
              isDisabled={isUpdatingTripday}
              onClick={(e) => addElementInBetween(e, addTransportation)}
            />
          </Flex>
        )}
        <Box h="1px" w="30%" background="brand.500" />
      </Box>
    </Box>
  );
};
export default React.memo(AddElementOnHover);

type AddElementOnHoverButtonType = Omit<IconButtonProps, "aria-label"> & {
  resourcename: string;
  Icon: any;
  isDisabled?: boolean;
};

const AddElementOnHoverButton: React.FC<AddElementOnHoverButtonType> = ({
  resourcename,
  Icon,
  isDisabled,
  ...props
}) => {
  return (
    <Tooltip label={`Add ${resourcename}`}>
      <IconButton
        {...props}
        bg="white"
        aria-label={`add-${resourcename}`}
        icon={<Icon />}
        colorScheme="brand"
        variant={"outline"}
        isDisabled={isDisabled}
      />
    </Tooltip>
  );
};
