import { Button, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import StripeAPI from "../../../api/stripe.api";
import { handleSubmission } from "../../../utils/toErrorMap";
import Section from "../../layout/Section";
import ConfirmModal from "../../modals/ConfirmModal";
import { ME_QUERY_KEY, MY_COMPANY_QUERY_KEY } from "../../../utils/constants";

interface CancelSubscriptionProps {
  scheduledToCancel: boolean;
  current_period_end: string;
  id: any;
}

const CancelSubscription: React.FC<CancelSubscriptionProps> = ({ scheduledToCancel, current_period_end, id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const disclosure = useDisclosure();
  const navigate = useNavigate();

  const { mutateAsync: cancelSubscription, isPending } = useMutation({
    mutationFn: () => StripeAPI.cancelSubscription(scheduledToCancel ? "cancel=false" : "cancel=true"),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [MY_COMPANY_QUERY_KEY],
      });
      navigate(0);
    },
  });

  const handleCancelSubscription = async () => {
    await handleSubmission({
      successMessage: `${scheduledToCancel ? "reactivated" : "cancelled"} subscription`,
      failMessage: `${scheduledToCancel ? "reactivating" : "cancelling"} subscription`,
      apiCall: cancelSubscription(),
      toast,
    });
  };

  const handleClickButton = () => {
    if (scheduledToCancel) {
      handleCancelSubscription();
    } else {
      disclosure.onOpen();
    }
  };

  return (
    <Section title="Cancel subscription" h="auto" id={id}>
      {/* <Text>Your subscription will be cancelled at the end of your current billing cycle (${billing_cycle_end}).</Text> */}
      {scheduledToCancel && (
        <Text>
          Your subscription will be cancelled at the end of your current billing cycle ({current_period_end}). Click the
          button beneath to undo the scheduled cancellation. If you want to update your current subscription, you will
          first need to undo your subscription cancellation.
        </Text>
      )}
      <Button colorScheme="red" onClick={handleClickButton} mt={2} variant={scheduledToCancel ? "outline" : "solid"}>
        {scheduledToCancel ? "Uncancel" : "Cancel"} subscription
      </Button>
      <ConfirmModal
        disclosure={disclosure}
        title="Cancel subscription"
        description="Are you sure you want to cancel your subscription?"
        action={handleCancelSubscription}
        isLoading={isPending}
        confirmButtonName="Delete subscription"
      />
    </Section>
  );
};
export default React.memo(CancelSubscription);
