import { Box, Button, ButtonProps, Flex, MenuButton, Spinner, Text, useToast } from "@chakra-ui/react";
import { Language, User } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { BsPencilSquare, BsTranslate } from "react-icons/bs";
import { useMeContext } from "../../stores/me-context";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import { handleSubmission } from "../../utils/toErrorMap";
import CustomModal from "../../components/layout/CustomModal";
import { NewLanguage } from "../editTrip/wizard/WizardTopBar";

interface DeeplModalProps {
  modalDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
  processNewLanguage?: (trip?: any) => any;
  currentlySelectedIndex: number;
  translatedLanguages: Language[];
  newLanguage: NewLanguage | undefined;
  translateFromUserCompanyId?: string;
  addLanguageCall: any;
  invalidateQueryKeys: string[];
  forceNew?: boolean;
}

const DeeplModal: React.FC<DeeplModalProps> = ({
  modalDisclosure: { isOpen, onClose },
  processNewLanguage = () => {},
  currentlySelectedIndex,
  translatedLanguages,
  newLanguage,
  translateFromUserCompanyId,
  addLanguageCall,
  invalidateQueryKeys,
  forceNew = false,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [originLanguageIndex, setOriginLanguageIndex] = React.useState(currentlySelectedIndex);
  const [deeplCharacters, setDeeplCharacters] = React.useState<number>(0);
  const user = useMeContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { setIsLoadingTranslations, isLoadingTranslations } = useTripFormStore();

  async function translateValuesWithDeepl(
    id: string,
    language: Language,
    originLanguage: string,
    translateFromUserCompanyId?: string,
  ) {
    //if (trip.hasOwnProperty("tripdays")) clearUnusedTranslationFields(trip);
    setIsLoading(true);
    await handleSubmission({
      successMessage: "translated trip",
      failMessage: "translating trip",
      apiCall: addLanguageCall(id, language.code, {
        targetLanguage: language.code,
        sourceLanguage: originLanguage,
        translateFromUserCompanyId: translateFromUserCompanyId,
      }),
      toast,
      successCallback,
      handleAdditionalError,
    });
  }

  /* const clearUnusedTranslationFields = (trip: Trip | Event | Hotel | POI) => {
    (trip as Trip).tripdays = (trip as Trip).tripdays.map((tripday) => ({
      ...tripday,
      transportations: tripday.transportations.map((transport) => ({
        ...transport,
        names: [],
      })),
    }));
  };

   const retrieveNewTranslationsFromTrip: any = (trip: any) => {
    const translations = convertObjectToFormat(trip, trip, undefined, "creator");
    const newTranslations: any = {};
    Object.keys(translations).forEach((translationKey) => {
      const translation = translations[translationKey] as TranslationField;
      if (
        (translation.creator === user || translation.creator === null) &&
        translation.language_code === newLanguage?.language.code
      ) {
        newTranslations[translationKey] = translation;
      }
    });
    return newTranslations;
  };
*/
  const successCallback = React.useCallback(
    async (resp: any) => {
      //let objectToTranslate = resp;
      /*if (resp.hasOwnProperty("tripdays")) {
        objectToTranslate = retrieveNewTranslationsFromTrip(resp);
      }
      resetRHFValues(newLanguage!.reset, objectToTranslate);*/
      if (!forceNew) {
        setIsLoadingTranslations(true);
        await Promise.all(invalidateQueryKeys.map((key) => queryClient.invalidateQueries({ queryKey: [key] })));
      }

      setIsLoading(false);
      onClose();
      await processNewLanguage();

      !forceNew && setIsLoadingTranslations(false);
    },
    [newLanguage],
  );

  const handleAdditionalError = React.useCallback((statusCode: number) => {
    setIsLoading(false);
    onClose();

    if (statusCode !== 401) {
      return "Something went wrong with Deepl, you don't have a valid API-Key for Deepl or you have passed your character limit";
    }
    return "You haven't set a Deepl API-Key";
  }, []);

  const processSelected = async (
    user: User,
    useDeepl: boolean,
    originLanguage?: string,
    translateFromUserCompanyId?: string,
  ) => {
    //In order to keep all depending functionality in this componenent, these functions are placed in this method.
    //let newTrip = replaceAndCopyAllTranslationArrays(user, newLanguage!.id, newLanguage!.language, newLanguage!.copy);
    if (useDeepl) {
      if (newLanguage?.language.code) {
        await translateValuesWithDeepl(
          newLanguage.id,
          newLanguage.language,
          originLanguage!,
          translateFromUserCompanyId,
        );
      }
    } else {
      !forceNew && setIsLoadingTranslations(true);
      if (newLanguage?.language.code) {
        await addLanguageCall(newLanguage.id, newLanguage.language.code);
        !forceNew &&
          (await Promise.all(invalidateQueryKeys.map((key) => queryClient.invalidateQueries({ queryKey: [key] }))));
      }
      onClose();
      await processNewLanguage();
      !forceNew && setIsLoadingTranslations(false);
    }
  };

  const currentlySelected = translatedLanguages[originLanguageIndex];
  /* useEffect(() => {
    if (newLanguage) {
      const userCompanyId = translateFromUserCompanyId ?? user.companyId;
      const translations = Array.from(
        recursivelyRetrieveObjectValues(newLanguage.trip, (k, v) => {
          if (
            v[k] &&
            typeof v[k] === "object" &&
            v[k]["language"] &&
            v[k]["language"]["code"] === (currentlySelected?.code ?? translatedLanguages[0].code)
          ) {
            if (v[k]["creator"]) {
              if (v[k]["creator"] && v[k]["creator"]["companyId"] === userCompanyId) {
                return true;
              }
            } else {
              return true;
            }
          }
          return false;
        }).map((trans) => trans.content),
        (translation) => translation || "**",
      );

      let translationCount = 0;
      translations.forEach((translation) => (translationCount += translation.length));
      console.log(translationCount);
      setDeeplCharacters(translationCount);
    }
  }, [newLanguage, user, currentlySelected]);*/

  console.log("RERENDERING DEEPLMODAL");

  return (
    <CustomModal
      title={isLoading ? "Translating for you..." : "How would you like to translate?"}
      isOpen={isOpen}
      onClose={onClose}
      closable={!isLoading}
      blockScrollOnMount
    >
      <Box display="flex" flexDirection={"row"} justifyContent={"space-around"} mb={3} gap={1}>
        {isLoading || isLoadingTranslations || !user ? (
          <Spinner color="brand.500" m="auto" size="xl" margin={3} my={"4.3em"} />
        ) : (
          <>
            <Button
              width={"5em"}
              height={"4em"}
              onClick={() => {
                processSelected(user, false);
              }}
              colorScheme={"teal"}
              fontSize="36px"
              variant={"outline"}
              boxShadow={"lg"}
            >
              <Flex flexDirection={"column"} justifyContent={"center"}>
                Manual
                <Box>
                  <BsPencilSquare size={"32px"} />
                </Box>
              </Flex>
            </Button>
            <DeeplTranslateButton
              translateFromTo={currentlySelected.code + " -> " + newLanguage?.language.code}
              deeplCharacters={deeplCharacters}
              menu={false}
              onClick={() => {
                if (user.company.deeplApiKey) {
                  processSelected(
                    user,
                    true,
                    currentlySelected?.code ?? translatedLanguages[0].code,
                    translateFromUserCompanyId,
                  );
                  setOriginLanguageIndex(0);
                } else {
                  window.open("/settings/deepl", "_blank");
                }
              }}
            />
          </>
        )}
      </Box>
    </CustomModal>
  );
};

type DeeplTooltipProps = ButtonProps & {
  deeplCharacters: number;
  menu: boolean;
  translateFromTo: string;
};

const DeeplTranslateButton: React.FC<DeeplTooltipProps> = ({ deeplCharacters, menu, translateFromTo, ...props }) => {
  const CustomButton = menu ? MenuButton : Button;

  return (
    <Flex flexDirection={"column"} alignItems={"center"}>
      <CustomButton
        width={"5em"}
        height={"4em"}
        margin={"auto"}
        iconSpacing={0}
        fontSize="36px"
        colorScheme="brand"
        variant={"outline"}
        boxShadow={"lg"}
        {...props}
      >
        <Flex flexDirection={"column"} justifyContent={"center"}>
          Deepl
          <Box marginLeft={"0.1em"}>
            <BsTranslate size={"32px"} />
          </Box>
          <Text fontSize={"16px"} marginTop={"0.5em"}>
            {translateFromTo}
          </Text>
        </Flex>
      </CustomButton>
      <Text fontSize={"14px"}>Approximated cost € {Math.round(deeplCharacters * 0.002) / 100}</Text>
      <Text textAlign={"center"} fontSize={"14px"}>
        First 500.000 characters are free!
      </Text>
    </Flex>
  );
};

export default React.memo(DeeplModal);
