import { CheckIcon } from "@chakra-ui/icons";
import { Circle, Flex, Heading, Image } from "@chakra-ui/react";
import { Company } from "@lato/common";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { useTripFormStore } from "../../../../../stores/trip/tripFormStore";

interface BrandPickerProps {
  company: Company;
  onChange?: (i: number) => void;
  selectedBrand?: number;
}

const BrandPicker: React.FC<BrandPickerProps> = ({ company, onChange, selectedBrand }) => {
  const brands = company.brands;

  const { trip } = useTripFormStore();
  const { field: brand } = useController({
    name: `userTrips.${trip?.userTrips?.length - 1}.brand`,
  });

  const handleChooseBrand = (i: number) => {
    if (onChange) {
      onChange(i);
    } else {
      const newBrand = company.brands[i];
      brand.onChange(newBrand);
    }
  };

  const selectedBrandId = selectedBrand !== undefined ? company.brands[selectedBrand]?.id : brand.value?.id;

  return (
    <Flex overflowX={"auto"}>
      {brands.map((brand, i) => (
        <Flex
          as="button"
          key={`brands-${i}`}
          w="100px"
          minW="100px"
          h="100px"
          borderColor={selectedBrandId === brand.id ? "brand.500" : "gray.300"}
          disabled={selectedBrandId === brand.id}
          borderWidth="2px"
          borderRadius={"lg"}
          alignItems="center"
          justifyContent={"center"}
          onClick={() => handleChooseBrand(i)}
          type="button"
          pos="relative"
          mr={3}
        >
          <Circle
            pos="absolute"
            right={2}
            top={2}
            size="25px"
            bg={selectedBrandId === brand.id ? "brand.400" : undefined}
            borderColor={selectedBrandId === brand.id ? "brand.500" : "gray.300"}
            color={selectedBrandId === brand.id ? "white" : "gray.300"}
            borderWidth="2px"
          >
            <CheckIcon boxSize={"50%"} />
          </Circle>
          {brand.logoUrl ? (
            <Image src={brand.logoUrl} h={`calc(1.8em * ${brand.logoScale})`} />
          ) : (
            <Heading size="lg">{brand.name}</Heading>
          )}
        </Flex>
      ))}
    </Flex>
  );
};
export default React.memo(BrandPicker);
