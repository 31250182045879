import { isAfter, isBefore, isSameDay } from "date-fns";

import { type DateRange } from "react-day-picker";

/**
 * Stolen from:
 * https://github.com/gpbl/react-day-picker/blob/master/packages/react-day-picker/src/contexts/SelectRange/utils/addToRange.ts
 * But adapted because the selection was not intuitive.
 *
 * Add a day to an existing range.
 *
 * The returned range takes in account the `undefined` values and if the added
 * day is already present in the range.
 */
export function customAddToRange(day: Date, range?: DateRange): DateRange | undefined {
  const { from, to } = range || {};
  console.log("from, to");
  console.log(from, to);
  console.log(day);

  if (!from) {
    return { from: day, to: undefined };
  }
  if (!to && isSameDay(from, day)) {
    return { from: from, to: day };
  }
  if (!to && isBefore(day, from)) {
    return { from: day, to: undefined };
  }
  if (!to) {
    return { from, to: day };
  }
  return { from: day, to: undefined };

  // if (!from || !to) {
  //   return { from: undefined, to: undefined };
  // }
  // if (isSameDay(from, to)) {
  //   // If start and end date is equal, enlarge the range.
  //   if (isBefore(day, from)) {
  //     // If clicked day is before start date
  //     return { from: day, to: from };
  //   }
  //   // Clicked day is after start date
  //   return { from, to: day };
  // }
  // return { from: day, to: day };

  // if (!from) {
  //   // If no start date, add the clicked day as the start and end date.
  //   return { from: day, to: day };
  // }
  // if (!to && isSameDay(from, day)) {
  //   // If a start date but no end date and start date is equal to clicked day, remove the start date.
  //   return { from: undefined, to: undefined };
  // }
  // if (!to && isBefore(day, from)) {
  //   // If a start date but no end date and clicked day is before start date, .
  //   return { from: day, to: from };
  // }
  // if (!to) {
  //   return { from, to: day };
  // }
  // if (isSameDay(to, day) && isSameDay(from, day)) {
  //   return { from: undefined, to: undefined };
  // }
  // if (isSameDay(to, day)) {
  //   return { from: to, to: to };
  // }
  // if (isSameDay(from, day)) {
  //   return { from: undefined, to: undefined };
  // }
  // // TO UNDO ADAPTATIONS:
  // // 1. Remove this line
  // return {from: day, to: undefined}
  // // 2. Uncomment all the following lines
  // // if (isAfter(from, day)) {
  // //   // The new clicked date is before the start date
  // //   return { from: day, to };
  // // }
  // // return { from, to: day };
}

export function addToRange(day: Date, range?: DateRange): DateRange | undefined {
  const { from, to } = range || {};
  if (!from) {
    return { from: day, to: undefined };
  }
  if (!to && isSameDay(from, day)) {
    return { from: from, to: day };
  }
  if (!to && isBefore(day, from)) {
    return { from: day, to: from };
  }
  if (!to) {
    return { from, to: day };
  }
  if (isSameDay(to, day) && isSameDay(from, day)) {
    return undefined;
  }
  if (isSameDay(to, day)) {
    return { from: to, to: undefined };
  }
  if (isSameDay(from, day)) {
    return undefined;
  }
  if (isAfter(from, day)) {
    return { from: day, to };
  }
  return { from, to: day };
}
