import * as yup from "yup";

export const generalValidationFields = {
  email: yup.string().email("Invalid email format").max(255).required(),
  password: yup.string().min(8).max(255).required(),
  name: yup.string().min(2).max(128).required(),
  companyName: yup.string().min(2).max(128).required(),
  coordinate: yup.object().shape({
    lat: yup.number().min(-90).max(90),
    lng: yup.number().min(-180).max(180),
  }),
};
