import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { UserTrip } from "@lato/common";
import React from "react";
import UserTripsAPI from "../../api/usertrips.api";
import CustomDownShiftWithPagination from "../../components/CustomDownShift/CustomDownShiftWithPagination";

type SingleCompanyItemProps = {
  item: UserTrip;
};

const SingleCompanyItem: React.FC<SingleCompanyItemProps> = ({ item }) => {
  return (
    <Box>
      <Flex>
        <Heading size="md">{item.trip!.titles[0].content + " "}</Heading>
        {item.external_ref && (
          <Text color="realGray.400" fontSize="sm">
            • {item.external_ref}
          </Text>
        )}
      </Flex>
      <Flex>
        <Text color="realGray.400" fontSize="sm">
          {item.trip!.start_date} - {item.trip!.end_date}{" "}
        </Text>
        <Text color="realGray.400" fontSize="sm">
          • {item.trip!.client_name}
        </Text>
      </Flex>
    </Box>
  );
};

interface TripListDropDownProps {
  setUserTripId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTrip?: React.Dispatch<React.SetStateAction<UserTrip | undefined>>;
  queryKey: string;
  placeholder?: string;
  initialInputValue?: string;
  isDropdown?: boolean;
  dropdownHeight?: string;
}
const TripListDropDown: React.FC<TripListDropDownProps> = ({
  setUserTripId,
  setTrip,
  queryKey,
  initialInputValue,
  placeholder = "Enter a client name, client ref. or trip title to start a new conversation",
  isDropdown = true,
  dropdownHeight = "24em",
}) => {
  return (
    <CustomDownShiftWithPagination
      apiCall={UserTripsAPI.getPaginatedTrips}
      queryName={queryKey}
      listItemConfig={{
        ListItem: SingleCompanyItem,
        showSelected: ({ item }) => (item ? item.trip!.titles[0].content : ""),
        onSelected: ({ item }) => {
          setUserTripId && setUserTripId(item.id);
          setTrip && setTrip(item);
        },
      }}
      height={dropdownHeight}
      placeholder={placeholder}
      initialInputValue={initialInputValue}
      isDropdown={isDropdown}
    />
  );
};

export default TripListDropDown;
