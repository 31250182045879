import { useMeContext } from "../../stores/me-context";
import { useLatestCannyChangelogs } from "../../utils/query-helpers/reactQueryHooks";
import { useDisclosure } from "@chakra-ui/react";
import CustomModal from "../../components/layout/CustomModal";
import { CANNY_CHANGELOG_URL } from "@lato/common";
export const ChangelogModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const user = useMeContext();
  const { data: changelog, isLoading: isLoadingChangelog, error: changelogError } = useLatestCannyChangelogs(user.id);
  if (isLoadingChangelog || changelogError || !changelog || Object.keys(changelog).length === 0) {
    return null;
  }
  console.log("changelog.markdownDetails", changelog.markdownDetails);
  const handleClickAllUpdates = () => {
    window.open(CANNY_CHANGELOG_URL, "_blank", "noopener noreferrer");
  };
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        title={changelog.title}
        onClose={onClose}
        closable={true}
        showHeader={true}
        size="2xl"
        modalContentProps={{ maxH: "84%", borderRadius: "20px", minW: "600px", maxW: "37%" }}
        headingProps={{ size: "xl" }}
      >
        <div dangerouslySetInnerHTML={{ __html: changelog.markdownDetails }} />

        <button
          onClick={handleClickAllUpdates}
          className="bg-[#ff817d] text-white px-3 py-2 ml-4 rounded-full border-none cursor-pointer mt-4 mb-2"
        >
          Previous updates
        </button>
      </CustomModal>
    </>
  );
};
