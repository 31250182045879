import { Button, Flex, FormLabel, Icon, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  TRANSPORTATION_TYPE,
  Transportation,
  Trip,
  calculateTripdayDateByDayIndex,
  getDayAndMonthFromSelectToDate,
  omitLocalTimezone,
  transportIconSvg,
} from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { type DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { HiExternalLink } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import TransportationsApi from "../../../../../../api/transportations.api";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { defineDaySelectOptions, getRemainingTripDays, submitForms } from "../../../../../../utils/EventHelper";
import { DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY } from "../../../../../../utils/constants";
import {
  usePatchTransportation,
  useTransportation,
} from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import useMutation from "../../../../../../utils/query-helpers/useMutation";
import { transportationSchemaInTrip } from "../../../../../../validation/validationSchemas";
import { ElementSkeleton } from "../../../../../FullScreenSpinner";
import InputWrapper from "../../../../../input/InputWrapper";
import RHFInput from "../../../../../input/RHFInput";
import RHFSelect from "../../../../../input/RHFSelect";
import GeneralElementForm from "../../GeneralElementForm";
import { getRoute } from "../../getRoute";
import TransportationForm from "./TransportationForm";

interface TransportationFormInTripProps {
  i: number;
  trip: Trip;
  tripdayIndex: number;
  remove: (index: number, transportation: Transportation, updateTripday?: boolean) => void;
  transportations: any[];
  transportation: any;
  move: (dest: number) => void;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  daySelectOptions: any[];
  orderObjects: any[];
  orderIndex: number;
  uniqueId: string;
  fieldArrayMethods: any;
  isFetchingTransport?: boolean;
  handleDragAndDropMove: (fromIndex: number, toIndex: number) => void;
  tripdayId?: string;
}

export const TransportationFormInTripWrapper: React.FC<TransportationFormInTripProps> = ({ ...props }) => {
  const { isLoadingTranslations: isLoadingTranslationsFromStore, isMovingElements } = useTripFormStore();

  if (props.transportation && props.transportation.realtime && props.transportation.type === TRANSPORTATION_TYPE.RENTAL)
    return <TransportationFormInTrip {...props} />;

  const {
    data: transportation,
    isLoading: isLoadingTransport,
    isFetching: isFetchingTransport,
    isFetchedAfterMount: isFetchedAfterMountTransportation,
    isError: isErrorTransportation,
  } = useTransportation(props.transportations[props.i].id);

  if (isErrorTransportation) return;

  const isLoadingTrans = isFetchingTransport || isLoadingTranslationsFromStore;

  if (!transportation || isLoadingTransport || isLoadingTrans || !props.trip || isMovingElements)
    return <ElementSkeleton />;
  return <TransportationFormInTrip {...props} transportation={transportation} isFetchingTransport={isLoadingTrans} />;
};
const TransportationFormInTrip: React.FC<TransportationFormInTripProps> = ({
  i,
  tripdayIndex,
  transportations,
  transportation,
  ...props
}) => {
  //code for collapsable elements
  /*const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } =
    useTripLanguage();*/

  return (
    <TransportationFormInTripForm
      {...props}
      i={i}
      tripdayIndex={tripdayIndex}
      transportations={transportations}
      transportation={transportation}
    />
  );
};
export default React.memo(TransportationFormInTrip);

const TransportationFormInTripForm: React.FC<TransportationFormInTripProps> = ({
  i,
  tripdayIndex,
  trip,
  transportation,
  remove,
  move,
  dragHandleProps,
  daySelectOptions,
  uniqueId,
  orderIndex,
  orderObjects,
  fieldArrayMethods,
  isFetchingTransport = false,
  handleDragAndDropMove,
  tripdayId,
}) => {
  const formId = `2-${tripdayIndex}-form-${i}-transportation`;

  const { id: userTripId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const errorHelpMessage = "Please use the 24 hour format: hh:mm";
  const {
    isFirst,
    setIsErrorForms,
    setIsInvalidForms,
    setUnCollapsedElement,
    unCollapsedElement,
    isSubmittingForms,
    isInvalidForms,
  } = useTripFormStore();

  const queryClient = useQueryClient();

  const [selectedType, setSelectedType] = React.useState<TRANSPORTATION_TYPE>(
    transportation?.type || TRANSPORTATION_TYPE.CAR,
  );

  const { mutateAsync: updateTransportation, isPending: isUpdatingTransportation } = usePatchTransportation(
    transportation.id ?? "",
    queryClient,
    trip.id,
    tripdayIndex,
    tripdayId,
  );

  const [title, setTitle] = React.useState(errorHelpMessage);

  const longDaySelectOptions = defineDaySelectOptions(trip, tripdayIndex, getRemainingTripDays(trip, tripdayIndex));

  const handleRemoveTransportation = React.useCallback(
    async (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number) => {
      await remove(i, transportation);

      setIsErrorForms(formId, false);
      setIsInvalidForms(formId, false);
    },
    [remove],
  );

  const stopPropagation = React.useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  }, []);

  const generalInputProps = {
    onClick: stopPropagation,
    // onBlur: updateRHFValuesShort,
  };

  const getTimes = (newDayIndex: any, newTime: string | null) => {
    const tripmonth = new Date(trip.start_date!).getMonth();
    let tripyear = new Date(trip.start_date!).getFullYear();
    //retrieving needed data from index of longdayselectoptions (long => extra day outside of tripdayrange)
    const daySelectDate = getDayAndMonthFromSelectToDate(
      longDaySelectOptions[newDayIndex && !isNaN(+newDayIndex) && newDayIndex !== true ? +newDayIndex : 0]?.text,
    );
    if ((daySelectDate[0] || daySelectDate[0] === 0) && daySelectDate[0] < tripmonth) {
      tripyear++;
    }

    const times = newTime && newTime.split(":");
    return { tripyear, daySelectDate, times };
  };

  const generateNewDate = (tripyear: number, daySelectDate: number[], times: "" | string[] | null) => {
    //using UTC to avoid timezone alterations
    return new Date(
      Date.UTC(
        tripyear,
        daySelectDate[0],
        daySelectDate[1],
        times ? +times[0] : 0,
        times ? +times[1] : 0,
        times ? (times[1] === "00" && times[0] === "00" ? 1 : 0) : 0,
      ),
    );
  };

  const dateAndTimeElement = React.useCallback(
    (handleChangeDate: any, destination: string, time: string, dayIndex: number, type: TRANSPORTATION_TYPE) => {
      let daySelectoptionsSelf = daySelectOptions;
      let stringPrefix = `${destination[0].toUpperCase() + destination.slice(1)}`;
      if (destination === "arrival") {
        if (type === TRANSPORTATION_TYPE.RENTAL) {
          stringPrefix = "Dropoff";
          daySelectoptionsSelf = longDaySelectOptions;
        } else {
          daySelectoptionsSelf = defineDaySelectOptions(trip, tripdayIndex, 1);
        }
      } else {
        if (type === TRANSPORTATION_TYPE.RENTAL) {
          stringPrefix = "Pickup";
        }
      }
      const fieldName = `${destination}_date_time`;
      return (
        <Stack direction="column" mb={1} gap={2} width={"50%"}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <FormLabel marginInlineEnd={0}>{`${stringPrefix} Day`}</FormLabel>
            <InputWrapper name={`${destination}DayIndex`} minW="65%" w="65%">
              <RHFSelect
                name={`${destination}DayIndex`}
                options={daySelectoptionsSelf}
                placeholder={"select day"}
                onChange={(e, value) => handleChangeDate(e, value, time, fieldName)}
                isDisabled={!isFirst}
                {...generalInputProps}
              />
            </InputWrapper>
          </Flex>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <FormLabel marginInlineEnd={0}>{`${stringPrefix} Time`}</FormLabel>
            <RHFInput
              name={`${destination}Time`}
              type="time"
              w="65%"
              // Used when the time type is not supported in the browser, only validates upon submit
              pattern="[0-9]{2}:[0-9]{2}"
              // Used in the error message
              title={title}
              onMouseEnter={() => setTitle("")}
              onMouseLeave={() => setTitle(errorHelpMessage)}
              isDisabled={!isFirst}
              {...generalInputProps}
              onBlur={(e) => {
                handleChangeDate(e, dayIndex, e.target.value, fieldName);
                // generalInputProps.onBlur();
              }}
            />
          </Flex>
        </Stack>
      );
    },
    [transportation],
  );

  const isRentalDropOff = transportation.realtime && transportation.type === TRANSPORTATION_TYPE.RENTAL;
  const isRealtimeFlight = transportation.realtime && transportation.type === TRANSPORTATION_TYPE.FLIGHT;

  const departureIsOutsideScope =
    transportation.departureDayIndex < 0 ||
    transportation.departureDayIndex > trip.tripdays[tripdayIndex].nrOfNights ||
    isRentalDropOff;
  const arrivalIsOutsideScope =
    transportation.arrivalDayIndex < 0 ||
    transportation.arrivalDayIndex > longDaySelectOptions.length ||
    isRentalDropOff;

  let departureDate: Date | undefined = undefined;
  let arrivalDate: Date | undefined = undefined;

  if (trip.start_date && departureIsOutsideScope) {
    departureDate = new Date(
      omitLocalTimezone(new Date(calculateTripdayDateByDayIndex(tripdayIndex, trip.start_date, trip) ?? ""), true),
    );

    departureDate.setDate(departureDate.getDate() + transportation.departureDayIndex);
  }

  if (trip.start_date && arrivalIsOutsideScope) {
    arrivalDate = new Date(
      omitLocalTimezone(new Date(calculateTripdayDateByDayIndex(tripdayIndex, trip.start_date, trip) ?? ""), true),
    );

    arrivalDate.setDate(arrivalDate.getDate() + transportation.arrivalDayIndex);
  }
  // const tripStartDate = trip.start_date ? omitLocalTimezone(new Date(trip.start_date)) : undefined;
  // const tripEndDate = trip.end_date ? omitLocalTimezone(new Date(trip.end_date)) : undefined;

  // tripEndDate && tripEndDate.setDate(tripEndDate.getDate() + 1);
  // const tripEndCompareDate = tripEndDate ? new Date(tripEndDate) : undefined;

  // tripEndCompareDate && tripEndCompareDate.setHours(23, 59, 59, 999);

  // const transportArrivalDate = omitLocalTimezone(new Date(transportation.arrival_date_time));
  // const transportDepartureDate = omitLocalTimezone(new Date(transportation.departure_date_time));

  // const departureIsOutsideScope =
  //   transportation.departure_date_time &&
  //   transportation.type === TRANSPORTATION_TYPE.FLIGHT &&
  //   tripStartDate &&
  //   (transportDepartureDate.getTime() < tripStartDate.getTime() ||
  //     (tripEndDate && transportDepartureDate.getTime() > tripEndDate.getTime()));
  // const arrivalIsOutsideScope =
  //   transportation.arrival_date_time &&
  //   transportation.type === TRANSPORTATION_TYPE.FLIGHT &&
  //   tripEndCompareDate &&
  //   (transportArrivalDate.getTime() > tripEndCompareDate.getTime() ||
  //     (tripStartDate && transportArrivalDate.getTime() < tripStartDate.getTime()));

  const rentalButton = () => {
    return (
      <Button
        rightIcon={<Icon as={HiExternalLink} />}
        colorScheme="brand"
        variant="outline"
        width={"fit-content"}
        onClick={() => {
          navigate(getRoute(userTripId!, transportation.originalTripdayIndex, trip.sample));
        }}
      >
        Go to {isRentalDropOff ? "Pickup" : "Dropoff"}
      </Button>
    );
  };

  const formMethods = useForm<Transportation>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: transportation,
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(transportationSchemaInTrip, { abortEarly: false }),
  });

  const handleChangeDayOrTime = React.useCallback(
    (_: any, newDayIndex: string | number | null, newTime: string | null, fieldName: string) => {
      if (typeof newDayIndex === "string") return;
      //retrieving needed data from made selection
      const arrivalDayIndex = formMethods.getValues("arrivalDayIndex");
      const { tripyear, daySelectDate, times } = getTimes(
        newDayIndex == null ? (arrivalDayIndex ?? 0) : newDayIndex,
        newTime ?? transportation.arrivalTime,
      );

      const date = generateNewDate(tripyear, daySelectDate, times);
      formMethods.setValue(fieldName, date);
      formMethods.trigger("arrivalDayIndex");
      formMethods.trigger("departureDayIndex");
      formMethods.trigger("arrivalTime");
      formMethods.trigger("departureTime");
    },

    [transportation, daySelectOptions, formMethods],
  );

  // const copyTransportation = async (transportation: Transportation) => {
  //   const { id, ...transportationWithoutId } = transportation;
  //   const copiedDocuments = await copyDocuments(transportation as any);
  //   return {
  //     ...transportationWithoutId,
  //     arrivalLocation: transportationWithoutId.arrivalLocation && copyLocation(transportationWithoutId.arrivalLocation),
  //     departureLocation:
  //       transportationWithoutId.departureLocation && copyLocation(transportationWithoutId.departureLocation),
  //     names: transportationWithoutId.names
  //       ? transportationWithoutId.names.map(({ id, ...name }) => name)
  //       : createNewTranslationFields(transportationWithoutId.descriptions),
  //     descriptions: transportationWithoutId.descriptions.map(({ id, ...descr }) => descr),
  //     documents: copiedDocuments,
  //     ord: fieldArrayMethods.fields.at(-1).ord + 1,
  //   } as Transportation;
  // };

  const { mutate: copyTransportation } = useMutation({
    apiCall: (id: string) => TransportationsApi.duplicate(id),
    successMessage: `duplicated transportation`,
    failMessage: `duplicating transportation`,
  });

  const handleDuplicateTransportation = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const transportFormValues = formMethods.getValues();
      await updateTransportation(transportFormValues);
      const copiedHotel = await copyTransportation(transportFormValues.id);
      fieldArrayMethods.append(e, copiedHotel);
    },
    [fieldArrayMethods, transportation],
  );

  const handleMoveTripdayTransportation = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    i: number,
    tripday: any,
  ) => {
    await updateTransportation({
      ...transportation,
      ord: tripday.events.length + tripday.hotels.length + tripday.transportations.length + 1,
      tripdayId: tripday?.id,
    });

    await remove(i, transportation, false);

    // Invalidate the data for step 2
    await queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripday?.id] });
    await queryClient.invalidateQueries({
      queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, trip.tripdays[tripdayIndex].id],
    });
  };

  return (
    <GeneralElementForm
      handleDelete={(e) => handleRemoveTransportation(e, i)}
      handleDuplicate={(e) => handleDuplicateTransportation(e)}
      handleTripdayMove={(e, tripdayIndex) => {
        handleMoveTripdayTransportation(e, i, tripdayIndex);
      }}
      // bg={isDragging ? "brand.50" : "white"}
      // zIndex={isDragging ? 1000 : undefined}
      dragHandleProps={dragHandleProps}
      type="transportation"
      getEvent={formMethods.getValues}
      first={isFirst}
      hideButtonGroup={isRentalDropOff}
      onMouseDown={async (e) => {
        if (transportation?.id && ![...isInvalidForms.values()].some((value) => value === true)) {
          e.stopPropagation();
          unCollapsedElement && unCollapsedElement !== transportation?.id && (await submitForms(isSubmittingForms));
          setUnCollapsedElement(transportation?.id);
        }
      }}
      currentTripdayIndex={tripdayIndex}
      realtime={transportation.realtime || transportation.type === TRANSPORTATION_TYPE.FLIGHT}
      icon={transportIconSvg[transportation?.type]}
      i={orderIndex}
      isLast={orderObjects.length - 1 === orderIndex}
      handleDragAndDropMove={handleDragAndDropMove}
    >
      <TransportationForm
        formId={formId}
        first={isFirst}
        type={selectedType}
        setType={setSelectedType}
        arrivalDate={arrivalDate}
        departureDate={departureDate}
        transportation={transportation}
        isUpdatingTransportation={isUpdatingTransportation}
        isFetchingTransportation={isFetchingTransport}
        daySelectOptions={longDaySelectOptions}
        updateTransportation={async (transport: Transportation) => {
          await updateTransportation(transport);
          if (
            transportation.hasOwnProperty("originalTripdayIndex") ||
            transport.hasOwnProperty("originalTripdayIndex")
          ) {
            await queryClient.invalidateQueries({
              queryKey: [
                DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY,
                trip.tripdays[transportation?.originalTripdayIndex ?? transport?.originalTripdayIndex ?? 0].id,
              ],
            });
          }
        }}
        formMethods={formMethods}
        departureDateAndTime={
          trip.sample ? (
            <></>
          ) : (
            dateAndTimeElement(
              handleChangeDayOrTime,
              "departure",
              transportation.departureTime,
              transportation.departureDayIndex,
              selectedType,
            )
          )
        }
        arrivalDateAndTime={
          trip.sample ? (
            <></>
          ) : (
            dateAndTimeElement(
              handleChangeDayOrTime,
              "arrival",
              transportation.arrivalTime,
              transportation.arrivalDayIndex,
              selectedType,
            )
          )
        }
        isRentalDropOff={isRentalDropOff}
        uniqueId={uniqueId}
        inTrip={true}
        collaboratorCanEdit={trip.collaboratorCanEdit}
        // extraButton={transportation.originalTripdayIndex !== undefined && rentalButton()}
        departureIsOutsideOfScope={departureIsOutsideScope}
        arrivalIsOutsideOfScope={arrivalIsOutsideScope}
      />
    </GeneralElementForm>
  );
};
