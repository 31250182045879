import { TRANSPORTATION_TYPE } from "../../types/index.js";
import { areCoordinatesEqual } from "./areCoordinatesEqual.js";
import { getDistanceFromLatLonInKm } from "./geoUtils.js";
export const MINIMUM_IMPLIED_DISTANCE = 1;
export const MAXIMUM_IMPLIED_DISTANCE = 200;
export function getTransportations(isDirectionsDisabled, tripdays) {
    const isFlight = (transport) => transport.type === TRANSPORTATION_TYPE.FLIGHT;
    const isRental = (transport) => transport.type === TRANSPORTATION_TYPE.RENTAL;
    const isStraightTransportation = (transport) => [TRANSPORTATION_TYPE.FLIGHT, TRANSPORTATION_TYPE.TRAIN].includes(transport.type);
    const isImpliedTransportLongEnough = (departureCoords, arrivalCoords) => {
        const distance = getDistanceFromLatLonInKm({ lng: departureCoords[0], lat: departureCoords[1] }, { lng: arrivalCoords[0], lat: arrivalCoords[1] });
        return distance > MINIMUM_IMPLIED_DISTANCE && distance < MAXIMUM_IMPLIED_DISTANCE;
    };
    const isCloserThan = (firstCoords, secondCoords, distance) => {
        const distanceBetween = getDistanceFromLatLonInKm({ lng: firstCoords[0], lat: firstCoords[1] }, { lng: secondCoords[0], lat: secondCoords[1] });
        return distanceBetween < distance;
    };
    function getToNameTransport(transport) {
        if (isFlight(transport)) {
            return transport.arrivalAirport.iata;
        }
        return (transport.arrivalLocation?.address.street ??
            transport.arrivalLocation?.address.city ??
            transport.arrivalLocation?.address.state ??
            transport.arrivalLocation?.address.country_code ??
            transport.arrivalAirport?.name ??
            "");
    }
    function getFromNameTransport(transport) {
        if (isFlight(transport)) {
            return transport.departureAirport.iata;
        }
        return (transport.departureLocation?.address.street ??
            transport.departureLocation?.address.city ??
            transport.departureLocation?.address.state ??
            transport.departureLocation?.address.country_code ??
            transport.departureLocation?.name ??
            "");
    }
    function getNameTripday(tripday) {
        return (tripday.location?.name ??
            tripday.location?.address.street ??
            tripday.location?.address.city ??
            tripday.location?.address.state ??
            tripday.location?.address.country_code ??
            "");
    }
    function getNameHotel(hotel) {
        return (hotel.location?.name ??
            hotel.location?.address.street ??
            hotel.location?.address.city ??
            hotel.location?.address.state ??
            hotel.location?.address.country_code ??
            "");
    }
    const basicProperties = {
        name: "",
        address: {
            country_code: null,
            state: null,
            city: null,
            postal_code: null,
            street: null,
        },
    };
    const handleImpliedFlightTransportations = (tripday, lastTransport, i) => {
        // Defining a flight can be done in 3 different ways:
        // 1. DAY 0 -> DAY 1 with arrivaldayindex of 0 on DAY 0
        // 2. DAY 0 -> DAY 1 with arrivaldayindex of 1 on DAY 1
        // 3. DAY 1 -> DAY 1 with arrivaldayindex of 0 on DAY 1
        // Thus, next tripdayindex is always current tripdayindex + arrivaldayindex
        // And case 1 needs +1 -> calculate distance of departureLocation of flight and current tripday location
        // If these are not close, then this is case 1
        const isCaseOne = !tripdays[i].location ||
            ((!lastTransport.arrivalDayIndex || lastTransport.arrivalDayIndex == 0) &&
                !isCloserThan(tripdays[i].location.coordinates, [lastTransport.arrivalAirport.lon, lastTransport.arrivalAirport.lat], 50));
        const index = (lastTransport.arrivalDayIndex ?? 0) + i + (isCaseOne ? 1 : 0);
        if (!tripdays[index] || !tripdays[index].location) {
            return;
        }
        const toHotel = tripdays[index].hotels && tripdays[index].hotels.length > 0 && tripdays[index].hotels[0].location;
        const departureCoords = [lastTransport.arrivalAirport.lon, lastTransport.arrivalAirport.lat];
        // Hotel or next day location
        const arrivalCoords = toHotel
            ? tripdays[index].hotels[0].location.coordinates
            : tripdays[index].location?.coordinates;
        if (arrivalCoords && isImpliedTransportLongEnough(departureCoords, arrivalCoords)) {
            transportations.push({
                type: TRANSPORTATION_TYPE.CAR,
                departureLocation: {
                    coordinates: [lastTransport.arrivalAirport.lon, lastTransport.arrivalAirport.lat],
                    ...basicProperties,
                },
                arrivalLocation: {
                    coordinates: arrivalCoords,
                    ...basicProperties,
                },
                fromName: getToNameTransport(lastTransport),
                toName: toHotel ? getNameHotel(tripdays[index].hotels[0]) : getNameTripday(tripdays[index]),
                tripdayIndex: i,
            });
            // If case 3, we have to add another transportation to the next tripday
            if ((lastTransport.arrivalDayIndex === 0 || !lastTransport.arrivalDayIndex) && tripday.location && !isCaseOne) {
                const toHotelNextTripday = tripdays[index + 1].hotels.length > 0 && tripdays[index + 1].hotels[0].location;
                if (!tripdays[index + 1].location) {
                    return;
                }
                transportations.push({
                    type: TRANSPORTATION_TYPE.CAR,
                    departureLocation: {
                        coordinates: toHotel
                            ? tripdays[index].hotels[0].location.coordinates
                            : tripdays[index].location.coordinates,
                        ...basicProperties,
                    },
                    arrivalLocation: {
                        coordinates: toHotelNextTripday
                            ? tripdays[index + 1].hotels[0].location.coordinates
                            : tripdays[index + 1].location.coordinates,
                        ...basicProperties,
                    },
                    fromName: toHotel ? getNameHotel(tripdays[index].hotels[0]) : getNameTripday(tripdays[index]),
                    toName: toHotelNextTripday
                        ? getNameHotel(tripdays[index + 1].hotels[0])
                        : getNameTripday(tripdays[index + 1]),
                    tripdayIndex: i,
                });
            }
        }
    };
    // Array to add all defined transportations and all implied transportations
    // An implied transportation is transportation that's not added in backoffice but should be shown
    const transportations = [];
    tripdays &&
        tripdays.forEach((tripday, i) => {
            // Looping over tripdays to add explicit transportations and find implicit transportations
            // Add all defined transportations and find toName and fromName
            tripday.transportations &&
                tripday.transportations.forEach((transport) => {
                    // Flights are not added! These are shown seperatly
                    if (isFlight(transport) && (!transport.arrivalAirport || !transport.departureAirport))
                        return;
                    if (!isFlight(transport) && (!transport.arrivalLocation || !transport.departureLocation))
                        return;
                    if (isRental(transport) &&
                        !transport.realtime &&
                        transport.departureLocation &&
                        transport.arrivalLocation &&
                        tripdays[i + 1] &&
                        tripdays[i + 1].location) {
                        // Pickup point for rentalcars/bus
                        transportations.push({
                            type: TRANSPORTATION_TYPE.CAR,
                            departureLocation: {
                                coordinates: [transport.departureLocation.coordinates[0], transport.departureLocation.coordinates[1]],
                                ...basicProperties,
                            },
                            arrivalLocation: {
                                coordinates: tripdays[i + 1].location.coordinates,
                                ...basicProperties,
                            },
                            fromName: getFromNameTransport(transport),
                            toName: getNameTripday(tripdays[i + 1]),
                            tripdayIndex: i,
                        });
                        return;
                    }
                    if (isRental(transport) &&
                        tripdays[i].location &&
                        transport.realtime &&
                        transport.departureLocation &&
                        transport.arrivalLocation) {
                        transportations.push({
                            type: TRANSPORTATION_TYPE.CAR,
                            departureLocation: {
                                coordinates: tripdays[i].location.coordinates,
                                ...basicProperties,
                            },
                            arrivalLocation: {
                                coordinates: [transport.arrivalLocation.coordinates[0], transport.arrivalLocation.coordinates[1]],
                                ...basicProperties,
                            },
                            fromName: getNameTripday(tripdays[i]),
                            toName: getToNameTransport(transport),
                            tripdayIndex: i,
                        });
                        return;
                    }
                    if (isRental(transport))
                        return;
                    const commonProperties = {
                        ...transport,
                        tripdayIndex: i,
                        toName: getToNameTransport(transport),
                        fromName: getFromNameTransport(transport),
                    };
                    transportations.push({
                        ...commonProperties,
                    });
                });
            // Search for implied transportations
            if (tripday.transportations && tripday.transportations.length > 0 && i !== tripdays.length - 1) {
                // All previous transportations should be added in order
                // The last transport is used to add implied transportations
                const lastTransport = transportations[transportations.length - 1];
                if (lastTransport &&
                    isStraightTransportation(lastTransport) &&
                    tripdays[i + 1].transportations &&
                    tripdays[i + 1].transportations.length > 0 &&
                    isStraightTransportation(tripdays[i + 1].transportations[0]))
                    // Last transport was a flight/train and next day has another flight/train, so no implied transportations should be added
                    return;
                // Add a transportation from the destination of the last transportation to the accomodation/location of the next tripday
                // Only implied transportations longer than 5km are added
                // Flight and other transportations are divided becausse of different properties
                if (lastTransport &&
                    isFlight(lastTransport) &&
                    lastTransport.arrivalAirport &&
                    lastTransport.departureAirport &&
                    tripdays[i + 1].location) {
                    handleImpliedFlightTransportations(tripday, lastTransport, i);
                }
                else if (lastTransport &&
                    lastTransport.arrivalAirport &&
                    lastTransport.departureAirport &&
                    tripdays[i + 1].location) {
                    const toHotel = tripdays[i + 1].hotels && tripdays[i + 1].hotels.length > 0 && tripdays[i + 1].hotels[0].location;
                    const departureCoords = lastTransport.arrivalLocation.coordinates;
                    const arrivalCoords = toHotel
                        ? tripdays[i + 1].hotels[0].location.coordinates
                        : tripdays[i + 1].location?.coordinates;
                    if (arrivalCoords && isImpliedTransportLongEnough(departureCoords, arrivalCoords)) {
                        transportations.push({
                            type: TRANSPORTATION_TYPE.CAR,
                            departureLocation: {
                                coordinates: [
                                    lastTransport.arrivalLocation.coordinates[0],
                                    lastTransport.arrivalLocation.coordinates[1],
                                ],
                                ...basicProperties,
                            },
                            arrivalLocation: {
                                coordinates: arrivalCoords,
                                ...basicProperties,
                            },
                            fromName: getToNameTransport(lastTransport),
                            toName: toHotel ? getNameHotel(tripdays[i + 1].hotels[0]) : getNameTripday(tripdays[i + 1]),
                            tripdayIndex: i,
                        });
                    }
                }
            }
            else if (tripday.location &&
                i !== tripdays.length - 1 &&
                tripdays[i + 1].location &&
                !areCoordinatesEqual(tripday.location.coordinates, tripdays[i + 1].location?.coordinates)) {
                // If no transportations are present on the tripday, add an implied transportation to the next accomodation/tripday location
                const lastTransport = transportations[transportations.length - 1];
                //First check if the transportation to the next tripday location is present on the next tripday
                if (tripdays[i + 1].transportations?.length > 0 &&
                    !isStraightTransportation(tripdays[i + 1].transportations[0]) &&
                    tripdays[i + 1].transportations[0].arrivalLocation &&
                    tripdays[i + 1].location &&
                    isCloserThan(tripdays[i + 1].transportations[0].arrivalLocation.coordinates, tripdays[i + 1].location.coordinates, 5)) {
                    return;
                }
                const toHotel = tripdays[i + 1].hotels && tripdays[i + 1].hotels?.length > 0 && tripdays[i + 1].hotels[0].location;
                const departureCoords = lastTransport
                    ? lastTransport.arrivalLocation?.coordinates
                    : tripday.location.coordinates;
                const arrivalCoords = toHotel
                    ? tripdays[i + 1].hotels[0].location.coordinates
                    : tripdays[i + 1].location?.coordinates;
                if (tripdays[i + 1].transportations?.length > 0 &&
                    isStraightTransportation(tripdays[i + 1].transportations[0]) &&
                    tripdays[i + 1].transportations[0].arrivalAirport &&
                    tripdays[i + 1].transportations[0].departureAirport &&
                    departureCoords) {
                    // Transportation to the airport, typically last day of trip
                    transportations.push({
                        type: TRANSPORTATION_TYPE.CAR,
                        departureLocation: {
                            coordinates: departureCoords,
                            ...basicProperties,
                        },
                        arrivalLocation: {
                            coordinates: [
                                tripdays[i + 1].transportations[0].departureAirport.lon,
                                tripdays[i + 1].transportations[0].departureAirport.lat,
                            ],
                            ...basicProperties,
                        },
                        fromName: getNameTripday(tripday),
                        toName: getNameTripday(tripdays[i + 1]),
                        tripdayIndex: i,
                    });
                    return;
                }
                if (departureCoords)
                    transportations.push({
                        type: TRANSPORTATION_TYPE.CAR,
                        departureLocation: { coordinates: departureCoords, ...basicProperties },
                        arrivalLocation: { coordinates: arrivalCoords, ...basicProperties },
                        fromName: getNameTripday(tripday),
                        toName: toHotel ? getNameHotel(tripdays[i + 1].hotels[0]) : getNameTripday(tripdays[i + 1]),
                        tripdayIndex: i,
                    });
            }
        });
    console.log("transports", transportations);
    return transportations;
}
