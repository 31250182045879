const config = {
  test: {
    domain: "http://localhost:3000",
    traveller_link_domain: "http://localhost:3001",
    api_url: "http://localhost:4000",
    google_maps_api_key: "AIzaSyAJ9It_QMOBJUCuWVpaOKnvOnHuX--lGxw",
    tomtom_api_key: "XZzy5mdI5mr2ctgKKkGL4FypOSI1YXUr",
    mapbox_api_key: "pk.eyJ1IjoibGF0b3RyYXZlbGFwcCIsImEiOiJjbGxrdHdrOHYxajNoM2VtcG9lcWJzbjJiIn0.4DZGBBPnqr-Y4CkQYGYTeg",
    // All Stripe information
    STRIPE_PUBLIC_API_KEY:
      "pk_test_51KkrpPBaCb8d5VCecZucNxlJBz4PN9VFBIU2KnyhwYE68ylnV3dAn3zFTIMzjDDR9X0jA0SWJMX9Ob7MfxBCzmr000XeLNPq8w",
    STRIPE_LITE_YEARLY: "price_1MHPrRBaCb8d5VCeozAQnZSf",
    STRIPE_LITE_QUARTERLY: "price_1MHPrRBaCb8d5VCe8LNzLTy8",
    STRIPE_PRO_YEARLY: "price_1MHPvWBaCb8d5VCeW6RpWB4R",
    STRIPE_PRO_QUARTERLY: "price_1MHPvWBaCb8d5VCeiIOlNkNT",
    socket_url: "http://localhost:8001",
  },
  development: {
    domain: "http://localhost:3000",
    traveller_link_domain: "http://localhost:3001",
    api_url: "http://localhost:4000",
    google_maps_api_key: "AIzaSyAJ9It_QMOBJUCuWVpaOKnvOnHuX--lGxw",
    tomtom_api_key: "XZzy5mdI5mr2ctgKKkGL4FypOSI1YXUr",
    mapbox_api_key: "pk.eyJ1IjoibGF0b3RyYXZlbGFwcCIsImEiOiJjbGxrdHdrOHYxajNoM2VtcG9lcWJzbjJiIn0.4DZGBBPnqr-Y4CkQYGYTeg",
    // All Stripe information
    STRIPE_PUBLIC_API_KEY:
      "pk_test_51KkrpPBaCb8d5VCecZucNxlJBz4PN9VFBIU2KnyhwYE68ylnV3dAn3zFTIMzjDDR9X0jA0SWJMX9Ob7MfxBCzmr000XeLNPq8w",
    STRIPE_LITE_YEARLY: "price_1MHPrRBaCb8d5VCeozAQnZSf",
    STRIPE_LITE_QUARTERLY: "price_1MHPrRBaCb8d5VCe8LNzLTy8",
    STRIPE_PRO_YEARLY: "price_1MHPvWBaCb8d5VCeW6RpWB4R",
    STRIPE_PRO_QUARTERLY: "price_1MHPvWBaCb8d5VCeiIOlNkNT",
    socket_url: "http://localhost:8001",
    GOOGLE_CLIENT_ID: "581007934707-7hkq2rvrt8b850bt6uj10ucj4hk382lv.apps.googleusercontent.com",
  },
  staging: {
    // TODO: change this, maybe use netlify's branch deployment?
    // - remove netlify travelappstartup.tk site
    // - remove nginx config
    // - remove api.travelappstartup.tk from ssl cert
    domain: "https://staging.portal.latotravelapp.com",
    traveller_link_domain: "https://staging.trips.latotravelapp.com",
    // CAUTION: use the exact same URL is in .env.staging in the server
    // But also as the main domain url name in the Nginx configuration (the domain which is redirected to)
    api_url: "https://staging.api.latotravelapp.com",
    google_maps_api_key: "AIzaSyAJ9It_QMOBJUCuWVpaOKnvOnHuX--lGxw",
    tomtom_api_key: "XZzy5mdI5mr2ctgKKkGL4FypOSI1YXUr",
    mapbox_api_key: "pk.eyJ1IjoibGF0b3RyYXZlbGFwcCIsImEiOiJjbGxrdHdrOHYxajNoM2VtcG9lcWJzbjJiIn0.4DZGBBPnqr-Y4CkQYGYTeg",
    // All Stripe information
    STRIPE_PUBLIC_API_KEY:
      "pk_test_51KkrpPBaCb8d5VCecZucNxlJBz4PN9VFBIU2KnyhwYE68ylnV3dAn3zFTIMzjDDR9X0jA0SWJMX9Ob7MfxBCzmr000XeLNPq8w",
    STRIPE_LITE_YEARLY: "price_1MHPrRBaCb8d5VCeozAQnZSf",
    STRIPE_LITE_QUARTERLY: "price_1MHPrRBaCb8d5VCe8LNzLTy8",
    STRIPE_PRO_YEARLY: "price_1MHPvWBaCb8d5VCeW6RpWB4R",
    STRIPE_PRO_QUARTERLY: "price_1MHPvWBaCb8d5VCeiIOlNkNT",
    socket_url: "https://staging.api.latotravelapp.com",
    GOOGLE_CLIENT_ID: "581007934707-7hkq2rvrt8b850bt6uj10ucj4hk382lv.apps.googleusercontent.com",
  },
  production: {
    domain: "https://portal.latotravelapp.com",
    traveller_link_domain: "https://trips.latotravelapp.com",
    // CAUTION: use the exact same URL is in .env.production in the server
    // But also as the main domain url name in the Nginx configuration (the domain which is redirected to)
    api_url: "https://api.latotravelapp.com",
    google_maps_api_key: "AIzaSyAJ9It_QMOBJUCuWVpaOKnvOnHuX--lGxw",
    tomtom_api_key: "XZzy5mdI5mr2ctgKKkGL4FypOSI1YXUr",
    mapbox_api_key: "pk.eyJ1IjoibGF0b3RyYXZlbGFwcCIsImEiOiJjbGxrdHdrOHYxajNoM2VtcG9lcWJzbjJiIn0.4DZGBBPnqr-Y4CkQYGYTeg",
    // All Stripe information
    STRIPE_PUBLIC_API_KEY:
      "pk_live_51KkrpPBaCb8d5VCeDkD7Vctak9E87G839Fj7CxSAkH37Ihgg4OS4gcA1MGQ8S1DGPlcd8CQ1S0Ge5gI1O94v5sqk00uj0Hud7o",
    STRIPE_LITE_YEARLY: "price_1MHp3qBaCb8d5VCe6TNtWqUk",
    STRIPE_LITE_QUARTERLY: "price_1MHp3qBaCb8d5VCeIc1iLRLw",
    STRIPE_PRO_YEARLY: "price_1MHp0lBaCb8d5VCe4bJu1xNn",
    STRIPE_PRO_QUARTERLY: "price_1MHp0bBaCb8d5VCe6fAnEd0U",
    socket_url: "https://api.latotravelapp.com",
    GOOGLE_CLIENT_ID: "581007934707-7hkq2rvrt8b850bt6uj10ucj4hk382lv.apps.googleusercontent.com",
  },
};
export default config;
