import { Flex, Link, Spinner, Text } from "@chakra-ui/react";
import { type UseInfiniteQueryResult } from "@tanstack/react-query";
import React from "react";
import Masonry from "react-masonry-css";
import { EXTERNAL_IMAGE_APP_NAME } from "../../utils/constants";
import ExternalCard from "./ExternalCard";

// Import react-masonry-css
import {
  ExternalImage,
  ExternalImagesPaginated,
  ImageSite,
  IsImage,
  PEXELS_VIDEO_BASE_URL,
  PIXABAY_BASE_URL,
  UNSPLASH_BASE_URL,
  capitalizeFirstLetter,
} from "@lato/common";
import "./masonry.css";

interface ExternalImageGridProps {
  infiniteQueryResult: UseInfiniteQueryResult<ExternalImagesPaginated, Error>;
  handleChosenPicture: (photo: ExternalImage) => void;
  selectedPictures: ExternalImage[];
  imageSite: ImageSite;
}

const breakPointsPhoto = {
  default: 3,
  600: 2,
  300: 1,
};

const breakPointsVideo = {
  default: 2,
  420: 2,
  360: 1,
};

export const imageBaseUrl: { [key in ImageSite | string]: any } = {
  [ImageSite.UNSPLASH]: UNSPLASH_BASE_URL,
  [ImageSite.PEXELS]: PEXELS_VIDEO_BASE_URL,
  [ImageSite.PEXELSVIDEO]: PEXELS_VIDEO_BASE_URL,
  [ImageSite.PIXABAY]: PIXABAY_BASE_URL,
  [ImageSite.PIXABAYVIDEO]: PIXABAY_BASE_URL,
};

// Masonry grid style like Pinterest and Unsplash (libraries such as masonry, colcade, ...)
const ExternalImageGrid: React.FC<ExternalImageGridProps> = ({
  infiniteQueryResult,
  handleChosenPicture,
  selectedPictures,
  imageSite,
}) => {
  const { data: paginatedResult, isLoading: isLoadingPhotos, error: errorPhotos } = infiniteQueryResult;

  if (isLoadingPhotos)
    return (
      <Flex w="100%" h="100%" align="center" justifyContent="center" mt={5}>
        <Spinner color="brand.500" />
      </Flex>
    );
  if (errorPhotos || !paginatedResult) return <div>Error loading photos...</div>;

  const externalPhotosPages = paginatedResult.pages;

  // Flat the array and then filter out all the falsy values (null and undefined etc.).
  const externalPhotos = externalPhotosPages
    .flatMap((photoPage: ExternalImagesPaginated) => photoPage.results)
    .filter(Boolean);

  if (externalPhotos.length === 0 || !externalPhotos[0])
    return (
      <Text textAlign="center" my={5}>
        No search results found.
      </Text>
    );

  return (
    <>
      <Text color="realGray.500" fontSize="sm" fontWeight="500" textAlign="right" my={1}>
        Powered by{" "}
        <Link
          isExternal
          href={`${imageBaseUrl[imageSite]}?utm_source=${EXTERNAL_IMAGE_APP_NAME}&utm_medium=referral`}
          onClick={(e) => e.stopPropagation()}
        >
          {capitalizeFirstLetter(
            !IsImage(imageSite)
              ? imageSite === ImageSite.PEXELSVIDEO
                ? ImageSite.PEXELS.toString()
                : ImageSite.PIXABAY.toString()
              : imageSite.toString(),
          )}
        </Link>
      </Text>
      <Masonry
        breakpointCols={!IsImage(imageSite) ? breakPointsVideo : breakPointsPhoto}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {externalPhotos[0] &&
          externalPhotos.map((photo: ExternalImage, photoIndex: number) => (
            <div key={`external-picture-${photoIndex}`}>
              {/* div needed for the react-masonry-css plugin to work. */}
              <ExternalCard
                Photo={photo}
                handleChosenPicture={handleChosenPicture}
                selected={selectedPictures.some((selectedPic) => selectedPic.id === photo.id)}
              />
            </div>
          ))}
      </Masonry>
    </>
  );
};
export default React.memo(ExternalImageGrid);
