import { Box, Tooltip, useDisclosure } from "@chakra-ui/react";
import {
  ENABLE_DESTINATIONS_IN_PROD,
  Event as EventObj,
  Hotel,
  TRANSPORTATION_TYPE,
  Transportation,
  Trip,
  TripDay,
} from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { IoWarningOutline } from "react-icons/io5";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { checkEventsSorted, defineDaySelectOptions, submitForms } from "../../../../../../utils/EventHelper";
import ENV from "../../../../../../utils/env";
import { useReorderFieldarray } from "../../../../../../utils/useReorderFieldarray";
import { calculateDayNumber } from "../../../../../../features/editTrip/overviewStep/TripsOverviewTable";
import DragAndDrop from "../../../../../layout/drag-and-drop/DragAndDrop";
import DestinationFormInTripWrapper from "../destination/destinationFormInTrip";
import { TransportationFormInTripWrapper } from "../transportations/TransportationFormInTrip";
import AddElementOnHover from "./AddElementOnHover";
import EventFormInTripWrapper from "./EventFormInTrip";
import HotelTabs from "./HotelTabs";
import { OrderWarningPopover } from "./OrderWarningPopover";

interface EventsListProps {
  tripdayIndex: number;
  eventFieldArrayMethods: any;
  transportationFieldArrayMethods: any;
  hotelFieldArrayMethods: any;
  tripday: TripDay;
  selectedAccommodationIndex: number;
  setSelectedAccommodationIndex: (index: number) => void;
  trip: Trip;
  titlePlaceHolder?: string;
  isFetchingTripday?: boolean;
  first: boolean;
  isUpdatingTripday: boolean;
  addAccommodation: (e: any, accommodation?: any) => Promise<number>;
  addActivity: (e: any, activity?: any) => Promise<number>;
  addTransportation: (e: any, transportation?: any) => Promise<number>;
  getElementFromTripdayFromCache: () => any;
}

const EventsList: React.FC<EventsListProps> = ({
  tripdayIndex,
  eventFieldArrayMethods,
  transportationFieldArrayMethods,
  hotelFieldArrayMethods,
  tripday,
  trip,
  titlePlaceHolder,
  selectedAccommodationIndex,
  setSelectedAccommodationIndex,
  isFetchingTripday = false,
  first,
  isUpdatingTripday,
  addAccommodation,
  addActivity,
  addTransportation,
  getElementFromTripdayFromCache,
}) => {
  const queryClient = useQueryClient();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { isSubmittingForms, setUnCollapsedElement } = useTripFormStore();
  const transportations = transportationFieldArrayMethods.fields;
  const events = eventFieldArrayMethods.fields;
  const hotels = hotelFieldArrayMethods.fields as Hotel[];
  const [toIndex, setToIndex] = React.useState<number>();
  const [fromIndex, setFromIndex] = React.useState<number>();

  //All the different types of elements are concatenated in one list and ordered by property ord
  const objects: any[] = [...(events ?? []), ...(transportations ?? []), ...(hotels ?? [])].sort((a: any, b: any) =>
    a.ord < b.ord ? -1 : a.ord === b.ord ? (a.id ? 0 : -1) : 1,
  );

  //FieldArrayMethods of all different types of elements are passed to an external service
  //this service will know which fieldArrayMethods to use when elements are moved, removed or added
  const { moveField: handleMove } = useReorderFieldarray(
    [eventFieldArrayMethods, transportationFieldArrayMethods, hotelFieldArrayMethods],
    //Passing all elements from the given tripdayValue, this will contain any updated values
    [...(tripday.events ?? []), ...(tripday.transportations ?? []), ...(tripday.hotels ?? [])].sort(
      (a: any, b: any) => a.ord - b.ord,
    ),
  );

  const handleMoveLeftRight = React.useCallback(
    async (e: any, from: number, to: number) => {
      await submitForms(isSubmittingForms);
      handleMove(objects.indexOf(hotels[from]), objects.indexOf(hotels[to]), true);
      setSelectedAccommodationIndex(objects.indexOf(hotels[to]));
    },
    [handleMove, isSubmittingForms, selectedAccommodationIndex],
  );

  const handleDragAndDropMove = React.useCallback(
    async (fromIndex: number, toIndex: number, forceMove: boolean = false) => {
      await submitForms(isSubmittingForms);

      const objectsClone = getElementFromTripdayFromCache();
      const alreadyChronical = checkEventsSorted(objectsClone);

      // Hypothetically move the event.
      const foo = objectsClone[fromIndex];
      objectsClone[fromIndex] = objectsClone[toIndex];
      objectsClone[toIndex] = foo;

      // Check if the array is still sorted if you move the event.
      if (!alreadyChronical || forceMove || checkEventsSorted(objectsClone)) {
        onClose();
        setToIndex(undefined);
        setFromIndex(undefined);

        const objectsSorted = objectsClone.sort((a: any, b: any) => a.ord - b.ord);
        if (objectsSorted[fromIndex]?.hasOwnProperty("board")) {
          // Hotel being moved, move all hotels
          const hotelIndices = objectsSorted
            .map((_: any, i: number) => i)
            .filter((i: number) => objectsSorted[i].hasOwnProperty("board"));
          const firstHotelIndex = hotelIndices[0];

          if (toIndex < fromIndex) {
            // Moving hotel up
            handleMove(firstHotelIndex, firstHotelIndex - 1);
          } else {
            // Moving hotel down
            handleMove(firstHotelIndex, firstHotelIndex + hotelIndices.length);
          }
        } else if (objectsSorted[toIndex]?.hasOwnProperty("board")) {
          // Moved an element in between hotels, get first or last index of hotel
          if (toIndex < fromIndex) {
            handleMove(
              fromIndex,
              objectsSorted.findIndex((e: any) => e.name === objectsSorted[toIndex].name),
            );
          } else {
            handleMove(
              fromIndex,
              objectsSorted.findLastIndex((e: any) => e.name === objectsSorted[toIndex].name),
            );
          }
        } else {
          handleMove(fromIndex, toIndex);
        }

        setUnCollapsedElement(undefined);
      } else {
        setToIndex(toIndex);
        setFromIndex(fromIndex);
        onOpen();
      }
    },
    [isSubmittingForms, getElementFromTripdayFromCache, onClose, setUnCollapsedElement, handleMove, onOpen],
  );

  //the days that can be selected in the current tripday. Used in dropdowns of events & transportations
  const daySelectOptions = defineDaySelectOptions(trip, tripdayIndex);

  const onClick = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // click was used as a part of the drag
    if (event.defaultPrevented) {
      return;
    }
    event.currentTarget.focus();
  }, []);
  let hotelsRendered = false;

  const realDayNumber = calculateDayNumber(tripdayIndex, trip.tripdays);

  const outOfOrderElement = useMemo(() => {
    if (!checkEventsSorted(getElementFromTripdayFromCache())) {
      return (
        <div className="w-full flex justify-end -mt-8 z-50 p-1">
          <Tooltip label="Elements are not sorted chronologically" placement="bottom">
            <p>
              <IoWarningOutline className="z-50 text-orange-600 bg-orange-100 rounded-full px-1 w-6 h-6" />
            </p>
          </Tooltip>
        </div>
      );
    }
  }, [objects, isSubmittingForms, checkEventsSorted, getElementFromTripdayFromCache]);

  return (
    <>
      {outOfOrderElement}
      {ENV !== "production" && !ENABLE_DESTINATIONS_IN_PROD && tripday.id && (
        <DestinationFormInTripWrapper
          destinationId={tripday.destination?.id}
          tripdayId={tripday.id}
          tripdayIndex={tripdayIndex}
          location={tripday.location}
          titlePlaceHolder={titlePlaceHolder}
          isFetchingTripday={isFetchingTripday}
        />
      )}
      <DragAndDrop moveCallback={handleDragAndDropMove}>
        {objects.map((item: any, i: number) => {
          if (hotels.includes(item)) {
            if (hotelsRendered) {
              return;
            }
            hotelsRendered = true;
          }
          return (
            <div key={item.rhfId!}>
              <AddElementOnHover
                i={i}
                isFetchingTripday={isFetchingTripday}
                isUpdatingTripday={isUpdatingTripday}
                nrOfHotels={hotels.length}
                nrOfEvents={events.length}
                first={first}
                nrOfNights={tripday.nrOfNights}
                handleDragAndDropMove={handleDragAndDropMove}
                // addAccommodation={async (e, ac) => {
                //   const newOrd = await addAccommodation(e, ac);
                //   await handleDragAndDropMove(newOrd, toOrd);
                // }}
                addAccommodation={addAccommodation}
                addActivity={addActivity}
                addTransportation={addTransportation}
              />
              <Draggable draggableId={item.rhfId!} index={i}>
                {(provided, snapshot) => (
                  <OrderWarningPopover
                    handleDragAndDropMove={handleDragAndDropMove}
                    toIndex={toIndex}
                    i={i}
                    onOpen={onOpen}
                    onClose={onClose}
                    isOpen={isOpen}
                    fromIndex={fromIndex}
                    setFromIndex={setFromIndex}
                  >
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={provided.draggableProps.style}
                      onClick={onClick}
                      position={"relative"}
                    >
                      {/* <div {...provided.dragHandleProps}>drag</div> */}
                      {events.includes(item as EventObj) && item.id && (
                        <EventFormInTripWrapper
                          i={events.indexOf(item as EventObj)}
                          tripdayIndex={tripdayIndex}
                          trip={trip}
                          events={events}
                          isDragging={snapshot.isDragging}
                          move={(dest) => handleMove(i, dest)}
                          dragHandleProps={provided.dragHandleProps}
                          key={item.rhfId}
                          daySelectOptions={daySelectOptions}
                          orderObjects={objects}
                          orderIndex={i}
                          uniqueId={item.rhfId!}
                          fieldArrayMethods={eventFieldArrayMethods}
                          tripdayId={tripday.id}
                          handleDragAndDropMove={handleDragAndDropMove}
                          toIndex={toIndex}
                          setToIndex={setToIndex}
                          onOpenWarning={onOpen}
                          objects={objects}
                        />
                      )}
                      {transportations.includes(item as Transportation) &&
                        ((item.type === TRANSPORTATION_TYPE.RENTAL && item.realtime) || item.id) && (
                          <TransportationFormInTripWrapper
                            i={transportations.indexOf(item as Transportation)}
                            tripdayIndex={tripdayIndex}
                            transportations={transportations}
                            transportation={item}
                            remove={transportationFieldArrayMethods.remove}
                            move={(dest) => handleMove(i, dest)}
                            dragHandleProps={provided.dragHandleProps}
                            key={item.rhfId}
                            daySelectOptions={daySelectOptions}
                            orderObjects={objects}
                            orderIndex={i}
                            uniqueId={item.rhfId!}
                            fieldArrayMethods={transportationFieldArrayMethods}
                            trip={trip}
                            handleDragAndDropMove={handleDragAndDropMove}
                            toIndex={toIndex}
                            setToIndex={setToIndex}
                            tripdayId={tripday.id}
                          />
                        )}
                      {hotels.includes(item as Hotel) && (
                        <HotelTabs
                          hotels={hotels}
                          selectedAccommodationIndex={selectedAccommodationIndex}
                          setSelectedAccommodationIndex={setSelectedAccommodationIndex}
                          hotelFieldArrayMethods={hotelFieldArrayMethods}
                          provided={provided}
                          handleMoveLeftRight={handleMoveLeftRight}
                          trip={trip}
                          tripdayIndex={tripdayIndex}
                          tripday={tripday}
                          realDayNumber={realDayNumber}
                          first={first}
                          handleDragAndDropMove={handleDragAndDropMove}
                          toIndex={toIndex}
                          setToIndex={setToIndex}
                          orderIndex={i}
                          isMovingDisabled={
                            isUpdatingTripday ||
                            isFetchingTripday ||
                            [...isSubmittingForms.values()].some((value) => value === true)
                          }
                          objectsLength={objects.length}
                        />
                      )}
                    </Box>
                  </OrderWarningPopover>
                )}
              </Draggable>
            </div>
          );
        })}
      </DragAndDrop>
    </>
  );
};
export default React.memo(EventsList);
