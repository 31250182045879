const size = {
  lg: {
    fontSize: "lg",
    px: 4,
    h: 10,
    borderRadius: "md",
  },

  md: {
    fontSize: "md",
    px: 4,
    h: 9,
    borderRadius: "md",
  },

  sm: {
    fontSize: "sm",
    px: 3,
    h: 8,
    borderRadius: "base",
  },

  xs: {
    fontSize: "xs",
    px: 2,
    h: 6,
    borderRadius: "sm",
  },
};

const sizes = {
  lg: {
    field: size.lg,
    addon: size.lg,
  },
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
  xs: {
    field: size.xs,
    addon: size.xs,
  },
};

const variantLato = {
  field: {
    border: "1px solid",
    borderColor: "inherit",
    bg: "rgb(250,251,252)",
    _hover: {
      borderColor: "brand.200",
    },
    _readOnly: {
      boxShadow: "none !important",
      userSelect: "all",
    },
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
    },
    _invalid: {
      borderColor: "red.500",
      boxShadow: `0 0 0 1px red.500`,
    },
    _focus: {
      zIndex: 1,
      borderColor: "brand.500",
      boxShadow: `0 0 0 1px brand.500`,
    },
  },
  addon: {
    border: "1px solid",
    borderColor: "inherit",
    bg: "gray.100",
  },
};

const variantNoBorders = {
  ...variantLato,
  field: {
    ...variantLato.field,
    resize: "none",
    borderColor: "transparent",
    backgroundColor: "transparent",
    fontSize: "30px",
    h: "unset",
    _hover: {
      backgroundColor: "#F0FCFA",
      resize: "vertical",
    },
    _focus: {
      backgroundColor: "#F0FCFA",
      resize: "vertical",
    },
  },
};

const variants = {
  lato: variantLato,
  noBorders: variantNoBorders,
};

const defaultProps = {
  size: "sm",
  variant: "lato",
};

export default {
  sizes,
  defaultProps,
  variants,
};
