import { Spinner } from "@chakra-ui/react";
import { InfiniteData } from "@tanstack/react-query";
import React from "react";
import { useParamsInfiniteQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import { PaginationReturn } from "../../utils/query-helpers/usePaginatedQuery";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import MultiSelectColumnFilter, { FilterButtonProps } from "./MultiSelectColumnFilter";
import { transformInfiniteData } from "../../utils/query-helpers/useCustomInfiniteQuery";

interface MultiSelectColumnFilterWithPaginationProps
  extends Omit<FilterButtonProps, "displayOption" | "createOptions"> {
  apiCall: () => Promise<any>;
  queryName: string;
  createOptionsCallback: (data: any[], preFilteredRows: any, columnName: string) => Set<string>;
  displayOptionCallback: (data: any[], option: string) => React.ReactNode;
  defaultDisplayValues?: any[];
  queryParams: { [key: string]: string };
}

const MultiSelectColumnFilterWithPagination: React.FC<MultiSelectColumnFilterWithPaginationProps> = ({
  apiCall,
  queryName,
  displayOptionCallback,
  createOptionsCallback,
  defaultDisplayValues = [],
  queryParams = [],
  ...props
}) => {
  const bottomOfListRef = React.useRef<HTMLDivElement>(null);

  const infiniteQueryResultLibrary = useParamsInfiniteQueryHelper<any>({
    queryKey: queryName,
    queryParams: queryParams as any,
    apiCall,
    options: {
      getNextPageParam: (lastPage, pages) => (pages.length >= lastPage.total_pages ? false : pages.length + 1),
    },
  });

  const { isFetchingNextPage, fetchNextPage, hasNextPage, data, isLoading } = infiniteQueryResultLibrary;

  useIntersectionObserver({
    target: bottomOfListRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const refetchEl = hasNextPage ? (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        height: "15px",
      }}
      ref={bottomOfListRef}
    >
      {isFetchingNextPage && <Spinner color="brand.500" boxSize="13px" />}
    </div>
  ) : undefined;

  const [dropDownItems, setDropDownItems] = React.useState<any>([
    ...defaultDisplayValues.filter((d) => d),
    ...(data ? transformInfiniteData(data) : []),
  ]);

  React.useEffect(() => {
    setDropDownItems([...defaultDisplayValues.filter((d) => d), ...(data ? transformInfiniteData(data) : [])]);
  }, [defaultDisplayValues, data]);

  const displayOption = React.useCallback(
    (option: string) => {
      return displayOptionCallback(dropDownItems, option);
    },
    [dropDownItems, displayOptionCallback],
  );

  const createOptions = React.useCallback(
    (preFilteredRows: any, columnName: string) => {
      return createOptionsCallback(dropDownItems, preFilteredRows, columnName);
    },
    [dropDownItems, createOptionsCallback],
  );

  return (
    <MultiSelectColumnFilter
      {...props}
      isLoading={isLoading}
      refetchEl={refetchEl}
      displayOption={displayOption}
      createOptions={createOptions}
    />
  );
};

export default MultiSelectColumnFilterWithPagination;
