import { getMiddleOfPolyline } from "./getMiddleOfPolyline.js";
export function getIconCoordination(departureLng = 0, departureLat = 0, arrivalLng = 0, arrivalLat = 0) {
    const { lat, lng } = getMiddleOfPolyline(departureLat, departureLng, arrivalLat, arrivalLng);
    const adjustment = arrivalLng < departureLng || arrivalLat < departureLat ? 268 : 268;
    const radius = ((Math.atan2(arrivalLng - departureLng, arrivalLat - departureLat) * 180) / Math.PI + adjustment) % 360;
    return {
        lat: lat,
        lng: lng,
        radius: radius,
    };
}
