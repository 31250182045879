import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { HOTEL_BOOKING_STATUS, HOTEL_ORIGIN, PRIMARY_COLOR, Room, Hotel, omitLocalTimezone } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { Network } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { BiAt } from "react-icons/bi";
import { BsFillPersonFill, BsPerson } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaHouse } from "react-icons/fa6";
import { LuBookPlus, LuHash } from "react-icons/lu";
import { MdHotel, MdPhone } from "react-icons/md";
import { PiBookmarkSimpleBold } from "react-icons/pi";
import { TbExternalLink, TbMoodKid } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { trpc } from "../../../../trpc";
import HotelsAPI from "../../../api/hotels.api";
import { useMeContext } from "../../../stores/me-context";
import { TRIPDAY_ACCOMMODATION_QUERY_KEY } from "../../../utils/constants";
import { prettyPrintDate } from "../../../utils/date";
import { useTrpcMutation, useTrpcQuery } from "../../../utils/query-helpers/reactQueryHooks";
import { useAccommodation } from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { useWindowWidth } from "../../../utils/useWindowWidth";
import { hotelAvailabilitySchema } from "../../../validation/validationSchemas";
import DateRangePicker from "../../input/date/DateRangePicker";
import { CustomSpinner } from "../../FullScreenSpinner";
import EmblaSwiper from "../../embla-swiper/EmblaSwiper";
import Form from "../../form/Form";
import InputWrapper from "../../input/InputWrapper";
import CustomModal from "../../layout/CustomModal";
import Section from "../../layout/Section";
import StaticMap from "../../map/staticMap";
import { defineImageUrl } from "../../trips/edit/daybyday/library-items/SingleAccommodation";
import { roomMap } from "../../trips/edit/daybyday/library-items/hotel/HotelForm";
import StarRating from "../../trips/edit/daybyday/library-items/hotel/StarRating";
import ConfirmModal from "../../modals/ConfirmModal";
import HolderPicker from "./HolderPicker";

interface AccommodationProps {}

// page for booking an accommodation
// when arriving on an accommodation where there is already a quote saved, the same availability request will be executed and it will be possible to confirm
// when arriving on a accommodation that is already booked, the booking info will be shown
const Accommodation: React.FC<AccommodationProps> = () => {
  const { id } = useParams<{ id: string; origin: string }>();
  const [hotel, setHotel] = React.useState<Hotel>();

  const me = useMeContext();

  const location = useLocation();

  const params = new URLSearchParams(location?.search);

  // searchParams passed in the url
  const detached = params.get("detached") === "true";
  const origin = params.get("origin");
  const startDate = params.get("start");
  const endDate = params.get("end");
  const firstName = params.get("firstName");
  const lastName = params.get("lastName");
  const adults = params.get("adults");
  const children = params.get("children");

  const { data, isLoading: isLoadingHotel } = useAccommodation(id ?? "");

  const { mutateAsync: getHotelbedHotel } = useTrpcMutation(trpc.hotelbeds.getHotelbedHotel);
  const { mutateAsync: getRatehawkHotel } = useTrpcMutation(trpc.ratehawk.getRatehawkHotel);
  const { mutateAsync: getYalagoHotel } = useTrpcMutation(trpc.yalago.getYalagoHotel);

  const { mutateAsync: getHotelbedBooking } = useTrpcMutation(trpc.hotelbeds.getHotelbedsBooking);

  const hotelMutations: { [key in HOTEL_ORIGIN]: any } = {
    [HOTEL_ORIGIN.LATO]: undefined,
    [HOTEL_ORIGIN.HOTELBEDS]: getHotelbedHotel,
    [HOTEL_ORIGIN.RATEHAWK]: getRatehawkHotel,
    [HOTEL_ORIGIN.YALAGO]: getYalagoHotel,
    [HOTEL_ORIGIN.MAPPED]: undefined,
  };

  React.useEffect(() => {
    const fetchHotel = async () => {
      const data = await hotelMutations[origin]({ code: id });
      setHotel(data);
    };

    const fetchBooking = async (bookingRef: string) => {
      const data = await getHotelbedBooking({
        bookingRef,
        companyId: me.companyId,
      });
      setHotel({ ...data.hotelDetail, booking: data });
    };

    // when the booking is detached the id will be a externalHotelId
    if (detached) {
      fetchHotel();
    } else {
      // when it is not detached it will be an id from Lato DB and we need to search for the booking by bookingRef
      setHotel(data);
      if (data?.bookingRef) {
        fetchBooking(data?.bookingRef);
      }
    }
  }, [origin, data]);

  if (!hotel || isLoadingHotel) return <CustomSpinner m="auto" />;

  return (
    <Box w="100%" maxW="1000px" mx="auto" my={10}>
      <AccommodationDetails
        hotel={hotel ?? data}
        detached={detached}
        startDate={startDate ? new Date(startDate) : undefined}
        endDate={endDate ? new Date(endDate) : undefined}
        firstName={firstName ?? undefined}
        lastName={lastName ?? undefined}
        adults={+(adults ?? 1)}
        children={children ? children.split(";") : []}
      />
    </Box>
  );
};

interface AccommodationDetailsProps {
  hotel: Hotel;
  detached: boolean;
  startDate?: Date;
  endDate?: Date;
  firstName?: string;
  lastName?: string;
  adults?: number;
  children?: string[];
}

const AccommodationDetails: React.FC<AccommodationDetailsProps> = ({
  hotel,
  detached,
  startDate,
  endDate,
  firstName,
  lastName,
  adults,
  children,
}) => {
  const descrDisclosure = useDisclosure();
  const confirmDisclosure = useDisclosure();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutateAsync: cancelBooking, isPending: isCancelling } = trpc.hotelbeds.cancelBooking.useMutation();

  const me = useMeContext();

  const formMethods = useForm<Hotel>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: hotel,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  const windowSize = useWindowWidth();

  const hasNoDescription = !hotel.descriptions || hotel.descriptions.length === 0 || windowSize < 975;

  return (
    <>
      <Form formMethods={formMethods} onSubmit={() => {}}>
        <Flex flexDir={"row"} mb={4} ml={4} gap={3} alignItems={"center"}>
          <Icon as={MdHotel} boxSize={6} mt={"0.4em"} />
          <Heading fontSize={`26px`} isTruncated width={"fit-content"}>
            {hotel.name}
          </Heading>
          {hotel.bookingStatus === HOTEL_BOOKING_STATUS.BOOKED && (
            // when the hotel is booked we show it and add an option to cancel
            <>
              <Box backgroundColor={PRIMARY_COLOR} borderRadius={"5px"} p={1} px={4}>
                <Text
                  w={"100%"}
                  textAlign={"center"}
                  fontWeight={"bold"}
                  fontSize={"18px"}
                  color="white"
                >{`Booked`}</Text>
              </Box>
              <Button
                p={"0.95em"}
                colorScheme="red"
                variant="outline"
                fontSize="17px"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDisclosure.onOpen();
                }}
                isLoading={isCancelling}
              >
                Cancel
              </Button>
            </>
          )}
          {hotel.rating != undefined && hotel.rating !== 0 && (
            <Box mt={0} ml={"auto"} mr={"10em"}>
              <StarRating defaultRating={hotel.rating} disable name={"rating"} scale={1.5} value={hotel.rating} />
            </Box>
          )}
        </Flex>
      </Form>
      <Flex width={"100%"} gap={4}>
        <Section noDivider w={"65%"} maxH={windowSize > 975 ? "13.9em" : "20em"} minH={"13.9em"}>
          <Flex
            justifyContent={"space-between"}
            gap={hasNoDescription ? 1 : 0}
            flexDir={hasNoDescription ? "column" : "row"}
          >
            <Flex flexDir={"column"} gap={1} width={"fit-content"} mr={3}>
              {hotel.location && hotel.location.name && hotel.location.name !== "" && (
                <Flex gap={4} alignItems={"center"} mt={1}>
                  <Icon as={FaMapMarkerAlt} color={"gray.500"} boxSize={4} ml={"-0.05em"} />
                  <Link
                    fontSize={"14px"}
                    target={"_blank"}
                    cursor={"pointer"}
                    href={`http://maps.google.com/?q=${
                      hotel.location.address.country ? hotel.location.address.country_code + ";" : ""
                    }${hotel.location.address.city ? hotel.location.address.city + ";" : ""}${
                      hotel.location.address.street ? hotel.location.address.street + ";" : ""
                    }`}
                    _hover={{
                      color: "rgba(0, 0, 255, 0.689)",
                      textDecoration: "underline",
                    }}
                    className={"external-link"}
                    display="flex"
                  >
                    <Text fontSize={`17px`}>{hotel.location.name}</Text>
                    <TbExternalLink
                      style={{ marginTop: "0.3em", marginLeft: "0.3em", fontSize: "17px" }}
                      className={"external-link-icon"}
                    />
                  </Link>
                </Flex>
              )}

              {hotel.phoneNumber && (
                <Flex alignItems={"center"} gap={4}>
                  <Icon as={MdPhone} color={"gray.500"} alignSelf={"flex-start"} mt={"0.3em"}></Icon>
                  <Box>
                    {hotel.phoneNumber.split(";").map((phone) => (
                      <Link
                        href={`tel:${phone}`}
                        _hover={{
                          color: "rgba(0, 0, 255, 0.689)",
                          textDecoration: "underline",
                        }}
                        className={"external-link"}
                        key={`hotel-phone-${phone}`}
                      >
                        <Text fontSize={"17px"} isTruncated>
                          {phone}
                          <TbExternalLink
                            style={{ marginBottom: "0.1em", marginLeft: "0.3em" }}
                            className={"external-link-icon"}
                          />
                        </Text>
                      </Link>
                    ))}
                  </Box>
                </Flex>
              )}
              {hotel.primaryEmail && (
                <Flex alignItems={"center"} gap={4} mt={"-0.2em"}>
                  <Icon as={BiAt} alignSelf={"flex-start"} mt={"0.4em"} color={"gray.500"}></Icon>
                  <Box>
                    {hotel.primaryEmail.split(";").map((mail) => (
                      <Link
                        href={`mailto:${mail}`}
                        _hover={{
                          color: "rgba(0, 0, 255, 0.689)",
                          textDecoration: "underline",
                        }}
                        className={"external-link"}
                        display="flex"
                        key={`hotel-mail-${mail}`}
                      >
                        <Text fontSize={"17px"} isTruncated>
                          {mail}
                          <TbExternalLink
                            style={{ marginBottom: "0.1em", marginLeft: "0.3em" }}
                            className={"external-link-icon"}
                          />
                        </Text>
                      </Link>
                    ))}
                  </Box>
                </Flex>
              )}
            </Flex>
            <Flex flexDir={"column"} align={hasNoDescription ? "flex-start" : "flex-end"} gap={1} w={"50%"}>
              {hotel.accommodationType && (
                <Tooltip aria-label={"accommodation-type"} label={"accommodation type"}>
                  <Flex
                    gap={4}
                    w={"fit-content"}
                    alignItems={"center"}
                    justifyContent={hasNoDescription ? "flex-start" : "flex-end"}
                  >
                    {hasNoDescription && <Icon as={FaHouse} color={"gray.500"} boxSize={4} />}
                    <Text fontSize={`17px`}>{hotel.accommodationType}</Text>
                    {!hasNoDescription && <Icon as={FaHouse} color={"gray.500"} boxSize={4} />}
                  </Flex>
                </Tooltip>
              )}

              {hotel.origin && (
                <Tooltip aria-label={"accommodation-origin"} label={"accommodation origin"}>
                  <Flex
                    gap={4}
                    alignItems={"center"}
                    w={"fit-content"}
                    justifyContent={hasNoDescription ? "flex-start" : "flex-end"}
                  >
                    {hasNoDescription && <Icon as={MdHotel} color={"gray.500"} mt={"0.1em"} boxSize={4} />}
                    <Text fontSize={`17px`}>{hotel.origin}</Text>
                    {!hasNoDescription && <Icon as={MdHotel} color={"gray.500"} mt={"0.1em"} boxSize={4} />}
                  </Flex>
                </Tooltip>
              )}
              {hotel.website && (
                <Flex
                  alignItems={"center"}
                  gap={4}
                  width={"100%"}
                  justifyContent={hasNoDescription ? "flex-start" : "flex-end"}
                >
                  {hasNoDescription && <Icon as={Network} color={"gray.500"}></Icon>}
                  <Link
                    href={hotel.website.startsWith("https://") ? hotel.website : "https://" + hotel.website}
                    target={"_blank"}
                    _hover={{
                      color: "rgba(0, 0, 255, 0.689)",
                      textDecoration: "underline",
                    }}
                    className={"external-link"}
                    width={"75%"}
                  >
                    <Text fontSize={"18px"} isTruncated>
                      {hasNoDescription && hotel.website}
                      <TbExternalLink
                        style={{ marginBottom: "0.1em", marginLeft: "0.3em", marginTop: "0.1em" }}
                        className={"external-link-icon"}
                      />
                      {!hasNoDescription && hotel.website}
                    </Text>
                  </Link>
                  {!hasNoDescription && <Icon as={Network} color={"gray.500"}></Icon>}
                </Flex>
              )}
            </Flex>
          </Flex>
          {hotel.descriptions && hotel.descriptions.length > 0 && (
            <>
              <Box minH={"4em"} mt={"0.5em"}>
                <Text noOfLines={3}>{hotel.descriptions[0].content}</Text>
              </Box>
              <Text
                fontWeight={"bold"}
                _hover={{
                  textDecoration: "underline",
                }}
                cursor={"pointer"}
                onClick={() => descrDisclosure.onOpen()}
              >
                {"Show more >"}
              </Text>
            </>
          )}
        </Section>
        {hotel?.location?.coordinates && (
          <StaticMap
            center={{ lat: hotel.location.coordinates[1], lng: hotel.location.coordinates[0] }}
            apiHeight={425}
            zoomPrep={14}
          />
        )}
      </Flex>
      <Box mt={4}>
        <EmblaSwiper
          mouseWheel
          gap={10}
          width="dynamic"
          renderDotNavigation={(index, selected) => (
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                background: selected ? PRIMARY_COLOR : "black",
                opacity: selected ? 1 : 0.2,
                margin: "0 4px",
              }}
            />
          )}
          slides={
            hotel.images &&
            hotel.images.map((image) => {
              const imageUrl = defineImageUrl(image, hotel.origin);

              return (
                <Box w={"18em"} h={"14em"} overflow={"hidden"} borderRadius={"1em"} position={"relative"}>
                  <Image
                    height={"100%"}
                    width={"100%"}
                    objectFit={"cover"}
                    borderRadius={"1em"}
                    position={"absolute"}
                    left={"50%"}
                    transform={imageUrl ? "translateX(-50%)" : "translateX(-50%) scale(2)"}
                    src={
                      imageUrl ??
                      "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png"
                    }
                  />
                </Box>
              );
            })
          }
        />
        {hotel.bookingStatus === HOTEL_BOOKING_STATUS.BOOKED ? (
          hotel.booking && (
            // when the hotel is booked we show information about the booking
            <Section noDivider mt={"1em"}>
              <Flex flexDirection={"row"} justifyContent={"space-between"}>
                <Tooltip label={"Check In - Check Out"} aria-label={"check-in-check-out"} placement={"top-start"}>
                  <Flex alignItems={"center"} gap={2}>
                    <CalendarIcon />
                    <Text>
                      {prettyPrintDate(hotel.booking.hotel.checkIn, undefined, "full")} -{" "}
                      {prettyPrintDate(hotel.booking.hotel.checkOut, undefined, "full")}
                    </Text>
                  </Flex>
                </Tooltip>
                <Tooltip label={"Booking status"} aria-label={"booking-status"} placement={"top-start"}>
                  <Flex alignItems={"center"} gap={2} ml={"-0.15em"}>
                    <Icon as={PiBookmarkSimpleBold} boxSize={5} />
                    <Text color={hotel.booking.status === "CANCELLED" ? "red" : undefined}>
                      {hotel.booking.status.charAt(0) + hotel.booking.status.slice(1).toLowerCase()}
                    </Text>
                  </Flex>
                </Tooltip>
                <Tooltip label="Booking creation date" aria-label="booking-creation-date" placement="top-start">
                  <Flex alignItems={"center"} gap={2} ml={"-0.1em"}>
                    <Icon as={LuBookPlus} boxSize={5} />
                    <Text>{prettyPrintDate(hotel.booking.creationDate, undefined, "full")}</Text>
                  </Flex>
                </Tooltip>
                <Tooltip label="Booking reference" aria-label="booking-reference" placement="top-start">
                  <Flex alignItems={"center"} gap={2} ml={"-0.1em"}>
                    <Icon as={LuHash} boxSize={5} />
                    <Text>{hotel.booking.reference}</Text>
                  </Flex>
                </Tooltip>
                <Tooltip label={"Holder"} aria-label="holder" placement="top-start">
                  <Flex gap={"0.6em"} alignItems={"center"} ml={"-0.1em"}>
                    <Icon as={BsPerson} boxSize={6} alignSelf={"start"} />
                    <Flex gap={2}>
                      <Text>
                        {hotel.booking.holder.surname.charAt(0) + hotel.booking.holder.surname.slice(1).toLowerCase()}{" "}
                        {hotel.booking.holder.name.charAt(0) + hotel.booking.holder.name.slice(1).toLowerCase()}
                      </Text>
                    </Flex>
                  </Flex>
                </Tooltip>
              </Flex>
            </Section>
          )
        ) : (
          // when it is a hotel that has not been booked we show the bookingprocess
          <AvailabilityForm
            hotel={hotel}
            detached={detached}
            startDate={startDate}
            endDate={endDate}
            adults={adults}
            children={children}
            holder={{
              first_name: firstName,
              last_name: lastName,
            }}
          />
        )}
      </Box>
      {descrDisclosure.isOpen && (
        <CustomModal title="Description" onClose={descrDisclosure.onClose} isOpen={descrDisclosure.isOpen}>
          <Text p={3}>{hotel.descriptions[0].content}</Text>
        </CustomModal>
      )}
      {confirmDisclosure.isOpen && (
        <ConfirmModal
          title={`Cancel Booking for ${hotel.booking.hotelDetail.name}`}
          description={
            <Box>
              {hotel.booking.hotel.rooms.map((rate: any) => {
                return (
                  <Box>
                    <Heading mb={2} size="lg">
                      {rate.name}
                    </Heading>
                    {rate.rates[0].cancellationPolicies.map((policy: any) => {
                      return (
                        <Flex mx={2} mb={2} fontSize={"14px"}>
                          Cancelling from {prettyPrintDate(policy.from)} will cost {policy.amount}{" "}
                          {hotel.booking.currency}
                        </Flex>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          }
          disclosure={confirmDisclosure}
          action={async () => {
            await cancelBooking({
              bookingReference: hotel.booking.reference,
              detached: !hotel.booking.userTripId,
              userId: me.id,
            });
            await queryClient.invalidateQueries({
              queryKey: ["usertrip", hotel.booking.userTripId],
            });

            await queryClient.invalidateQueries({ queryKey: [TRIPDAY_ACCOMMODATION_QUERY_KEY, hotel.id] });

            if (!hotel.booking.userTripId) {
              navigate("/accommodations/" + hotel.booking.hotel.code + "?origin=HotelBeds&detached=true", {
                replace: true,
              });
            }
          }}
          isLoading={isCancelling}
          confirmButtonName="Confirm"
        />
      )}
    </>
  );
};

interface AvailibilityFormProps {
  startDate?: Date;
  endDate?: Date;
  holder?: { first_name: string | undefined; last_name: string | undefined };
  hotel: Hotel;
  detached: boolean;
  adults?: number;
  children?: string[];
}

const AvailabilityForm: React.FC<AvailibilityFormProps> = ({
  startDate,
  endDate,
  hotel,
  detached,
  holder,
  adults,
  children,
}) => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [availableRooms, setAvailableRooms] = React.useState();

  const user = useMeContext();

  startDate = startDate ?? today;
  endDate = endDate ?? tomorrow;

  // getting information for the rooms of the hotel
  const { data: roomsData } = useTrpcQuery(roomMap[hotel.origin], { code: hotel.originCode });

  const rooms = roomsData?.data ?? [];

  // getting availability from hotelbeds of the hotel
  const { mutateAsync: getHotelbedAvailability, isPending } = useTrpcMutation(trpc.hotelbeds.hotelbedsAvailability);

  const availabilityFormMethods = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      from: startDate,
      to: endDate,
      minRate: 0,
      maxRate: 0,
      rooms: [
        {
          adults: adults ?? 0,
          children: children?.length ?? 0,
          ages: (children ?? []).map((childAge) => +childAge),
        },
      ],
      shiftDays: 0,
    },
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(hotelAvailabilitySchema),
  });

  const handleAvailabilitySubmit = async () => {
    const formValues = availabilityFormMethods.getValues();
    const fromDate = formValues.from;
    const toDate = formValues.to;
    const startDateAfter = new Date(fromDate ?? startDate);

    startDateAfter.setDate(startDateAfter.getDate() + 1);
    availabilityFormMethods.setValue(
      "to",
      toDate ? toDate : endDate!.getTime() < (fromDate ?? startDate).getTime() ? startDateAfter : endDate,
    );
    const availabilityResponse = await getHotelbedAvailability({
      userId: user.id,
      code: hotel.originCode,
      from: omitLocalTimezone(fromDate ?? startDate, true).toISOString(),
      to: omitLocalTimezone(
        toDate ? toDate : endDate!.getTime() < (fromDate ?? startDate).getTime() ? startDateAfter : endDate,
        true,
      ).toISOString(),
      minRate: +formValues.minRate,
      maxRate: +formValues.maxRate,
      rooms: formValues.rooms.map((room: any) => ({
        ...room,
        adults: +room.adults,
        children: +room.children,
        ages: (room?.ages ?? []).filter((age: any) => age).map((age: any) => +age),
      })),
      shiftDays: +formValues.shiftDays === 0 ? undefined : +formValues.shiftDays,
    });
    setAvailableRooms(
      availabilityResponse?.hotels?.length > 0 && availabilityResponse?.total !== 0 ? availabilityResponse : undefined,
    );
  };

  // on pageLoad when necessary filterParameters are given we already perform the request
  React.useEffect(() => {
    if (startDate && endDate && (!availableRooms || (availableRooms as any)?.length === 0)) {
      handleAvailabilitySubmit();
    }
  }, []);

  const queryParam =
    startDate && endDate
      ? `?start=${startDate}&end=${endDate}&firstName=${holder?.first_name}&lastName=${holder?.last_name}&adults=${adults}&children=${(
          children ?? []
        ).join(";")}`
      : "";

  return (
    <>
      <Heading size={"2xl"} mt={3} ml={2}>
        Book
      </Heading>
      <Divider mt={2} />
      <Section noDivider px={0} p={2} mt={4} noHorizontalPadding>
        <Form formMethods={availabilityFormMethods} onSubmit={handleAvailabilitySubmit}>
          <Flex gap={2}>
            <InputWrapper name="dateRange" bottomMarginLabel="0">
              <DateRangePicker
                borderRadius="5px"
                min={1}
                format={"PP"}
                numberOfMonths={2}
                defaultSelected={{
                  from: availabilityFormMethods.getValues("from") ?? startDate,
                  to: availabilityFormMethods.getValues("to") ?? endDate,
                }}
                onSelect={(e) => {
                  availabilityFormMethods.setValue("from", e && e.from);
                  availabilityFormMethods.setValue("to", e && e.to);
                }}
              />
            </InputWrapper>
            <HolderPicker beforeClose={() => {}} />
            <Button w={"10%"} type="submit" colorScheme="brand">
              Search
            </Button>
          </Flex>
        </Form>
      </Section>

      {availableRooms && (
        <Box my={4} px={4}>
          <BookingForm
            availableRooms={availableRooms}
            rooms={rooms}
            chosenRooms={availabilityFormMethods.getValues("rooms")}
            hotelId={hotel.id ?? ""}
            hotelCode={hotel.originCode ?? ""}
            hotelName={hotel.name ?? ""}
            bookingReference={hotel.bookingRef}
            clientReference={user.company.hotelbeds_client_id}
            selectedQuoteRates={hotel.rooms ?? []}
            detached={detached}
            defaultHolder={holder}
            queryParam={queryParam}
          />
        </Box>
      )}
    </>
  );
};

interface BookingFormProps {
  availableRooms: {
    checkIn: string;
    checkOut: string;
    hotels: any[];
  };
  rooms?: Room[];
  chosenRooms: any[];
  hotelId: string;
  hotelCode: string;
  hotelName: string;
  detached: boolean;
  bookingReference?: string;
  clientReference?: string;
  defaultHolder?: { first_name: string | undefined; last_name: string | undefined };
  queryParam?: string;
  selectedQuoteRates?: any[];
}

export const BookingForm: React.FC<BookingFormProps> = ({
  availableRooms,
  rooms,
  chosenRooms,
  hotelId,
  hotelCode,
  hotelName,
  bookingReference,
  clientReference,
  detached,
  defaultHolder,
  queryParam,
  selectedQuoteRates,
}) => {
  const checkIn = new Date(availableRooms.checkIn);
  const prettyCheckIn = prettyPrintDate(checkIn, undefined, "full");
  const checkOut = new Date(availableRooms.checkOut);
  const prettyCheckOut = prettyPrintDate(checkOut, undefined, "full");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const me = useMeContext();
  // holder for the booking
  const [holder, setHolder] = React.useState<{ first_name: string | undefined; last_name: string | undefined }>(
    defaultHolder ?? { first_name: undefined, last_name: undefined },
  );

  const confirmDisclosure = useDisclosure();

  const location = useLocation();

  const params = new URLSearchParams(location?.search);

  const accommodationId = params.get("accommodationId");

  const [availableRoomsFlat, setAvailableRoomsFlat] = React.useState(availableRooms.hotels[0].rooms);
  const [selectedRates, setSelectedRates] = React.useState<any[]>(
    availableRooms.hotels[0].rooms
      .map((room: any) =>
        room.rates.map((rate: any) => ({
          ...rate,
          room: {
            name: room.name,
            code: room.code,
          },
          amount: rate.rooms,
          price: Math.round(rate.sellingRate * rate.rooms * 100) / 100,
          currency: availableRooms.hotels[0].currency,
        })),
      )
      .flat()
      .filter(
        (rate: any) =>
          selectedQuoteRates?.findIndex(
            (quote) => quote.rateKey.split("~").slice(0, 5).join("~") === rate.rateKey.split("~").slice(0, 5).join("~"),
          ) !== -1,
      ),
  );

  //const { setValue } = useFormContext();
  const ref = React.useRef(null);

  React.useEffect(() => {
    setAvailableRoomsFlat(availableRooms.hotels[0].rooms);
    setSelectedRates(
      availableRooms.hotels[0].rooms
        .map((room: any) =>
          room.rates.map((rate: any) => ({
            ...rate,
            room: {
              name: room.name,
              code: room.code,
            },
            amount: rate.rooms,
            currency: availableRooms.hotels[0].currency,
            price: Math.round(rate.sellingRate * rate.rooms * 100) / 100,
          })),
        )
        .flat()
        .filter(
          (rate: any) =>
            selectedQuoteRates?.findIndex(
              (quote) =>
                quote.rateKey.split("~").slice(0, 5).join("~") === rate.rateKey.split("~").slice(0, 5).join("~"),
            ) !== -1,
        ),
    );
    ref.current && (ref.current as any).scrollIntoView({ behavior: "smooth", block: "center" });
  }, [availableRooms]);

  // booking the hotel
  const { mutateAsync: bookHotelMutation, isPendingBooking } = useTrpcMutation(trpc.hotelbeds.hotelbedsBook);
  // saving a quote for the hotel
  const { mutateAsync: saveQuotationMutation, isPendingQuote } = useTrpcMutation(trpc.hotelbeds.saveQuotation);

  const bookHotel = async (confirm: boolean) => {
    if (selectedRates && selectedRates.length > 0 && holder) {
      const bookParams = {
        userId: me.id,
        bookingReference,
        clientReference,
        checkIn: availableRooms.checkIn,
        checkOut: availableRooms.checkOut,
        latoId: accommodationId ?? hotelId,
        hotelCode,
        rooms: selectedRates.map((rate, index) => {
          const paxes = (chosenRooms[index]?.ages ?? []).map((age: any) => ({
            type: "CH",
            age: age,
          }));
          return {
            code: rate.room.code,
            characteristic: rate.room.characteristic ?? "",
            maxAdults: +(rate.room.maxAdults ?? 0),
            maxChildren: +(rate.room.maxChildren ?? 0),
            description: rate.room.description ?? "",
            rate: {
              rateKey: rate.rateKey,
              type: rate.rateType,
              paxes: [...paxes, { type: "AD", name: holder.last_name, surname: holder.first_name }],
            },
            comment: rate.comment,
          };
        }),
        board: selectedRates[0].boardName.charAt(0) + selectedRates[0].boardName.slice(1).toLowerCase(),
        holder: { first_name: (holder.first_name ?? "").trim(), last_name: (holder.last_name ?? "").trim() },
        detached,
      } as any;

      // when working with an accommodation attached to a trip we first save a quote
      const res = confirm ? await bookHotelMutation(bookParams) : await saveQuotationMutation(bookParams);

      if (res) {
        await queryClient.invalidateQueries({
          queryKey: [TRIPDAY_ACCOMMODATION_QUERY_KEY, accommodationId ?? hotelId],
        });
        //Setting an item in localstorage to invalidate the query in a different tab
        //react-query doesn't work across different tabs
        localStorage.setItem(`${TRIPDAY_ACCOMMODATION_QUERY_KEY}-${accommodationId}`, accommodationId ?? hotelId);

        // when the booking is not attached to a trip we navigate to the newly created hotel
        if (detached) {
          navigate("/accommodations/" + res.hotel.id + queryParam, { replace: true });
        }
      }

      //const board = selectedRates[0].boardName;
      //   setValue("board", board.charAt(0).toUpperCase() + board.slice(1).toLowerCase());
      //rooms && setRooms(selectedRates.map((rate) => rooms.find((room: any) => room.code === rate.room.code)));
      //   setValue("bookingStatus", HOTEL_BOOKING_STATUS.BOOKED);
      //   setValue("bookingRef", res.booking.reference);
    }
  };

  const dateDiff = Math.abs(checkOut.getTime() - checkIn.getTime());
  const dateDiffDays = Math.ceil(dateDiff / (1000 * 3600 * 24));

  const disableBooking =
    holder == undefined ||
    holder.first_name == undefined ||
    holder.last_name == undefined ||
    holder.first_name === "" ||
    holder.last_name === "";

  return (
    <>
      <Heading size={"lg"} mb={1} ref={ref}>
        Available Rooms for {prettyCheckIn} - {prettyCheckOut}
      </Heading>
      <Divider mb={3} />
      {availableRoomsFlat.map((room: any) => (
        <RoomBookingForm
          room={room}
          rooms={rooms}
          checkIn={checkIn}
          setSelectedRates={setSelectedRates}
          selectedRates={selectedRates}
          dateDiffDays={dateDiffDays}
        />
      ))}

      {selectedRates.filter((rate: any) => rate.amount !== "0").length > 0 && (
        <Box mt={4} mx={4} mb={"5em"}>
          <SelectedRate dateDiffDays={dateDiffDays} selectedRates={selectedRates} />

          {(hotelId || !accommodationId) && (
            <>
              <Heading size={"lg"} mt={3}>
                Holder
              </Heading>
              <Flex>
                <Flex gap={3}>
                  <Flex flexDir={"column"} w={"14em"}>
                    <FormLabel>Last name</FormLabel>
                    <Input
                      defaultValue={holder.last_name}
                      onChange={(e) => setHolder({ first_name: holder?.first_name, last_name: e.target.value })}
                    />
                  </Flex>
                  <Flex flexDir={"column"} w={"14em"}>
                    <FormLabel>First name</FormLabel>
                    <Input
                      defaultValue={holder?.first_name}
                      onChange={(e) => setHolder({ last_name: holder?.last_name, first_name: e.target.value })}
                    />
                  </Flex>
                </Flex>

                <Tooltip
                  label="Fill in a holder before booking"
                  aria-label="confirm-booking-tooltip"
                  isDisabled={!disableBooking}
                >
                  <Button
                    ml={"auto"}
                    my={4}
                    float={"right"}
                    fontSize={"14px"}
                    colorScheme="brand"
                    onClick={confirmDisclosure.onOpen}
                    isLoading={isPendingBooking}
                    isDisabled={disableBooking}
                  >
                    Confirm Booking
                  </Button>
                </Tooltip>
              </Flex>
            </>
          )}

          {confirmDisclosure.isOpen && (
            <ConfirmModal
              title={`Confirm Booking for ${hotelName}`}
              description={
                <>
                  {selectedRates.filter((rate: any) => rate.amount !== "0").length > 0 && (
                    <Box mt={4} mx={4} mb={"5em"}>
                      <SelectedRate dateDiffDays={dateDiffDays} selectedRates={selectedRates} />
                    </Box>
                  )}
                </>
              }
              disclosure={confirmDisclosure}
              action={async () => bookHotel(true)}
              confirmButtonName="Confirm"
            />
          )}

          {accommodationId && (
            <Flex>
              <Button
                ml={"auto"}
                my={4}
                float={"right"}
                fontSize={"14px"}
                colorScheme="brand"
                onClick={() => bookHotel(false)}
                isLoading={isPendingQuote}
                isDisabled={disableBooking}
              >
                Save to Trip
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </>
  );
};

interface RoomBookingFormProps {
  room: any;
  rooms?: Room[];
  checkIn: Date;
  setSelectedRates: (rates: any) => void;
  selectedRates: any[];
  dateDiffDays: number;
}

const RoomBookingForm: React.FC<RoomBookingFormProps> = ({
  room,
  rooms,
  checkIn,
  setSelectedRates,
  selectedRates,
  dateDiffDays,
}) => {
  room = {
    ...room,
    ...(rooms ? rooms.find((roomObj: any) => roomObj.code === room.code) : room),
  };

  const rates = room.rates
    .sort((a: any, b: any) => (a.net !== b.net ? a.net - b.net : b.adults + b.children - (a.adults + a.children)))
    .filter(
      (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.boardCode === value.boardCode),
    );
  return (
    <Box mb={6}>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Box ml={2} mb={2}>
          <Text fontSize={"18px"} fontWeight={"bold"}>
            {room.name.charAt(0).toUpperCase() + room.name.slice(1).toLowerCase()}
          </Text>
          {/* {room.description && (
            <Text fontSize={"sm"} color="gray.500">
              {room.description.charAt(0).toUpperCase() + room.description.slice(1).toLowerCase()}
            </Text>
          )} */}
        </Box>
      </Flex>
      <Section noDivider noVerticalPadding pt={0}>
        {rates
          .filter((rate: any) => rate.allotment !== 0)
          .map((rate: any) => (
            <RateBookingForm
              rate={rate}
              room={room}
              checkIn={checkIn}
              setSelectedRates={setSelectedRates}
              selectedRates={selectedRates}
              dateDiffDays={dateDiffDays}
            />
          ))}
      </Section>
    </Box>
  );
};

interface RateBookingFormProps {
  rate: any;
  room: any;
  checkIn: Date;
  setSelectedRates: (rates: any) => void;
  selectedRates: any[];
  dateDiffDays: number;
}

const RateBookingForm: React.FC<RateBookingFormProps> = ({
  rate,
  room,
  checkIn,
  setSelectedRates,
  selectedRates,
  dateDiffDays,
}) => {
  const price = rate.sellingRate;
  const currency = (rate.taxes?.taxes ?? [])[0]?.currency ?? "EUR";

  return (
    <Flex flexDir="column" gap={4}>
      <Flex p={2} mt={2} alignItems={"center"} justifyContent={"space-between"}>
        <Text w={"30%"}>{rate.boardName.charAt(0).toUpperCase() + rate.boardName.slice(1).toLowerCase()}</Text>
        <Box w={"15%"}>
          <Flex>
            {Array.from(new Array(rate.adults)).map((_, i) => (
              <Icon as={BsFillPersonFill}></Icon>
            ))}
          </Flex>
          <Flex>
            {Array.from(new Array(rate.children)).map((_, i) => (
              <Icon as={TbMoodKid}></Icon>
            ))}
          </Flex>
        </Box>

        <Box w={"30%"}>
          {rate.cancellationPolicies.length > 0 && (
            <Popover trigger="hover">
              <PopoverTrigger>
                <Text fontWeight={"bold"}>Cancellation Policy</Text>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />

                <PopoverBody>
                  <Box>
                    {(rate.cancellationPolicies ?? []).map((policy: any) => (
                      <Flex mx={2} mb={2} fontSize={"14px"} fontWeight={"bold"}>
                        Cancelling from {prettyPrintDate(policy.from)} will cost {policy.amount} {currency}
                      </Flex>
                    ))}
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          )}
        </Box>

        <Text w={"20%"}>
          {price} {currency}
        </Text>

        <Select
          w={"20%"}
          key={`rate-select-${rate.rateKey}-${rate.adults}-${rate.children}`}
          value={selectedRates.find((selectedRate: any) => selectedRate.rateKey === rate.rateKey)?.amount ?? 0}
          onChange={(e: any) => {
            setSelectedRates([
              ...selectedRates.filter((selectedRate: any) => selectedRate.rateKey !== rate.rateKey),
              {
                ...rate,
                amount: e.target.value,
                room: room,
                price: Math.round(price * e.target.value * 100) / 100,
                currency,
              },
            ]);
          }}
        >
          {Array.from(new Array(Math.min(rate.allotment, 10) + 1)).map((_, i) => (
            <option value={i}>{`${i}   ${i !== 0 ? `(${Math.round(price * i * 100) / 100} ${currency})` : ""}`}</option>
          ))}
        </Select>
      </Flex>
      {rate.comment && (
        <Flex mx={2} fontSize={"14px"} fontWeight={"bold"}>
          <Text>{rate.comment}</Text>
        </Flex>
      )}
    </Flex>
  );
};

interface SelectedRateProps {
  dateDiffDays?: number;
  selectedRates: any[];
}

const SelectedRate: React.FC<SelectedRateProps> = ({ dateDiffDays, selectedRates }) => {
  return (
    <>
      {dateDiffDays && (
        <Heading size={"lg"}>
          Selected Rates for {dateDiffDays} {dateDiffDays > 1 ? "nights" : "night"}
        </Heading>
      )}
      <Divider mb={3} mt={1} />
      {selectedRates
        .filter((rate: any) => rate.amount !== "0")
        .map((rate: any) => (
          <Flex flexDir={"column"} gap={3}>
            <Flex justifyContent={"space-between"}>
              <Text>
                {rate.amount} x {rate.room.name.charAt(0).toUpperCase() + rate.room.name.slice(1).toLowerCase()} -{" "}
                {rate.boardName.charAt(0).toUpperCase() + rate.boardName.slice(1).toLowerCase()}
              </Text>
              <Text>
                {rate.price} {rate.currency}
              </Text>
            </Flex>
            <Box>
              {(rate.cancellationPolicies ?? []).map((policy: any) => (
                <Flex mx={2} mb={2} fontSize={"14px"} fontWeight={"bold"}>
                  Cancelling from {prettyPrintDate(policy.from)} will cost {policy.amount} {rate.currency}
                </Flex>
              ))}
            </Box>
          </Flex>
        ))}
      <Flex justifyContent={"space-between"} mt={3}>
        <Box>
          <Text fontWeight={"bold"}>Total</Text>
          <Text fontSize={"10px"}>{"( tax included )"}</Text>
        </Box>
        <Text fontWeight={"bold"}>
          {Math.round(selectedRates.map((rate: any) => rate.price).reduce((a, b) => +a + +b, 0) * 100) / 100}{" "}
          {selectedRates[0].currency}
        </Text>
      </Flex>
    </>
  );
};

export default Accommodation;
