import { Icon, MenuDivider, Portal, UseDisclosureReturn, useDisclosure } from "@chakra-ui/react";
import { Task } from "@lato/common";
import { EditIcon } from "lucide-react";
import { BiWorld } from "react-icons/bi";
import OptionsDropdown, { OptionsDropdownItem, DeleteOptionsDropdownItem } from "../../components/CRUD/OptionsDropdown";
import React from "react";
import { IoMdPerson } from "react-icons/io";

interface TaskOptionsProps {
  task: Task;
  onEditClick?: (task: Task) => void;
  onDeleteClick?: (task: Task) => void;
  showOpenContactOption?: boolean;
  showOpenTripOption?: boolean;
  showRemoveFromTripOption?: boolean;
  updateTask?: (task: Task) => void;
}

export const TaskOptions = ({
  onEditClick,
  task,
  onDeleteClick,
  showOpenContactOption,
  showOpenTripOption,
  showRemoveFromTripOption,
  updateTask,
}: TaskOptionsProps) => {
  const optionsDisclosure = useDisclosure();

  const removeTaskFromTrip = async (e: any) => {
    e.stopPropagation();
    updateTask && updateTask({ ...task, trip: null });
    optionsDisclosure.onClose();
  };

  return (
    <OptionsDropdown {...optionsDisclosure} menuListInPortal={true}>
      {task.trip && showOpenTripOption && (
        <>
          <OptionsDropdownItem
            icon={<Icon as={BiWorld} size={"15px"} />}
            onClick={(e) => {
              window.open(`/trips/${task.trip?.id}`);
            }}
            name={"Open trip"}
          />
          <MenuDivider />
        </>
      )}
      {task.contact && showOpenContactOption && (
        <>
          <OptionsDropdownItem
            icon={<Icon as={IoMdPerson} size={"15px"} />}
            onClick={(e) => {
              window.open(`/contacts/${task.contact?.id}`);
            }}
            name={"Open contact"}
          />
          <MenuDivider />
        </>
      )}
      {showRemoveFromTripOption && (
        <>
          <OptionsDropdownItem
            icon={<Icon as={IoMdPerson} size={"15px"} />}
            onClick={removeTaskFromTrip}
            name={"Remove task from this trip"}
          />
          <MenuDivider />
        </>
      )}
      <OptionsDropdownItem
        icon={<EditIcon size={"15px"} />}
        onClick={() => onEditClick && onEditClick(task)}
        name={`Edit task`}
      />
      <DeleteOptionsDropdownItem resourceName="task" onClick={() => onEditClick && onDeleteClick(task)} />
    </OptionsDropdown>
  );
};
