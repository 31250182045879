import { BoxProps, Select, SelectProps } from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext } from "react-hook-form";

interface RHFSelectProps extends Omit<SelectProps, "defaultValue" | "onChange"> {
  name: string;
  options?: SelectOption[];
  parse?: boolean;
  optionsGrouped?: OptionsGrouped;
  onBeforeChange?: (value: string | number | null) => boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>, value: any) => void;
  inputWidth?: BoxProps["width"];
  defaultValue?: any;
}

export type SelectOption = {
  value: any;
  text?: string | number | any;
};

export interface OptionsGrouped {
  [key: string]: SelectOption[];
}

const RHFSelect: React.FC<RHFSelectProps> = ({
  name,
  options,
  optionsGrouped,
  onBeforeChange,
  inputWidth = "100%",
  parse = true,
  ...props
}) => {
  // const { getValues } = useFormContext();
  // const defaultV = getValues(name);
  const { field } = useController({
    name,
    // On first render, the default value of the column should be used,
    // subsequent renders should use the more up to date getValues defaultValue.
    // defaultValue: defaultV || defaultValue,
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    let result: any = value;
    if (parse) {
      // Try parsing the text value
      const parsed = parseInt(value, 10);
      // If parsing was not successful (NaN) then the string value should be used.
      const merged = isNaN(parsed) ? value : parsed;
      // If the string is empty then null should be used.
      result = merged === "" ? null : merged;
    }
    // Execute the custom onBeforeChange,
    // if the onBeforeChange returns false, meaning that something went wrong, don't execute the onChange functions.
    if (onBeforeChange && !onBeforeChange(result)) return;
    // Update the RHF value
    field.onChange(result);
    // Execute the custom onChange
    if (props.onChange) props.onChange(e, result);
  };

  return (
    <Select
      {...props}
      {...field}
      // Select values can't handle null values, hence when the value is null, use undefined.
      value={field.value ?? undefined}
      onChange={handleChange}
      width={inputWidth}
    >
      {options &&
        options.map(({ value, text }, i: number) => {
          // The select html tag does not have a defaultValue prop but a selected prop on the option tag
          return (
            <option
              key={`selectOption-${name}-${i}`}
              value={value}
              // selected={defaultV === option}
            >
              {text ?? value}
            </option>
          );
        })}
      {optionsGrouped &&
        Object.entries(optionsGrouped).map(([key, options]: [string, SelectOption[]]) => (
          <optgroup key={`selectOptionGroup-${key}`} label={key} className="bg-gray-400">
            {options.map(({ value, text = value }: SelectOption, i: number) => (
              <option key={`selectOption-${name}-${i}`} value={value}>
                {text}
              </option>
            ))}
          </optgroup>
        ))}
    </Select>
  );
};

export default React.memo(RHFSelect);
