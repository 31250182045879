import axios from "axios";
import { handleError, handleResponse } from "./utilities/response";
import { STRAPI_API_HOST } from "@lato/common";

const url = STRAPI_API_HOST;

// Get all the blogs of the Strapi CMS
const getMostRecentBlog = async () => {
  try {
    const onlyMostRecentBlogQuery = `&sort=publishedAt:desc&pagination[start]=0&pagination[limit]=1`;
    const response = await axios.get(`${url}/blogs?populate=*&locale=en${onlyMostRecentBlogQuery}`);
    return handleResponse(response).data[0].attributes;
  } catch (error) {
    return handleError(error);
  }
};

const getMostRecentAnnouncement = async () => {
  try {
    const onlyMostRecentAnnouncement = `&sort=publishedAt:desc&pagination[start]=0&pagination[limit]=1`;
    const response = await axios.get(`${url}/announcements?populate=*${onlyMostRecentAnnouncement}`);
    return handleResponse(response).data[0].attributes;
  } catch (error) {
    return handleError(error);
  }
};

type StrapiAPIType = {
  getMostRecentBlog(): Promise<any>;
  getMostRecentAnnouncement(): Promise<any>;
};
const StrapiAPI: StrapiAPIType = {
  getMostRecentBlog,
  getMostRecentAnnouncement,
};
export default StrapiAPI;
