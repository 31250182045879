import { Button, Flex, Heading, Image, Text, Tooltip, VStack, useDisclosure, useToast } from "@chakra-ui/react";
import { Company, CompanyBrand } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import CompanyAPI from "../../api/companies.api";
import Form from "../../components/form/Form";
import CustomModal from "../../components/layout/CustomModal";
import Settings from "../../components/settings/Settings";
import Branding, { newEmptyBrand } from "../../components/settings/company/Branding";
import TripTerms from "../../components/trips/edit/daybyday/settings/TripTerms";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { useMeContext } from "../../stores/me-context";
import { addToast } from "../../utils/addToast";
import { usePaginatedTrips } from "../../utils/query-helpers/reactQueryHooks";
import { submitDocumentsArray } from "../../utils/submitDocumentsArray";
import { handleSubmission } from "../../utils/toErrorMap";
import useUnsavedChangesPrompt from "../../utils/useUnsavedChangesPrompt";
import AIWritingPreferencesSettings from "./AIWritingPreferencesSettings";
import BrandingInformation from "./BrandingInformation";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyValidationSchema } from "../../validation/validationSchemas";
import { BRAND_TRIPS, ME_QUERY_KEY } from "../../utils/constants";

interface BrandSettingsProps {}

// Used to set the content of the brand as default for all brands.
export type BrandContent = "terms" | "content_ai_preference_sentence";

const BrandSettings: React.FC<BrandSettingsProps> = () => {
  const user = useMeContext();
  const toast = useToast();
  const deleteDisclosure = useDisclosure();
  const confirmDisclosure = useDisclosure();
  const queryClient = useQueryClient();

  const defaultBrandIndex = user.company.brands.findIndex((brand) => brand.default_brand);
  // The selected brand is the default brand, should there be an error with the default brand, the first brand is selected.
  // const [selectedBrand, setSelectedBrand] = React.useState<CompanyBrand>(defaultBrand ?? user.company.brands[0]);
  const [brandToDelete, setBrandToDelete] = React.useState<CompanyBrand | undefined>(undefined);
  const [selectedBrandIndex, setSelectedBrandIndex] = React.useState<number>(
    defaultBrandIndex !== -1 ? defaultBrandIndex : 0,
  );

  const brandRef = "brand-information";
  const brandingInfoRef = "branding-info";
  const termsRef = "terms-&-conditions";
  const AIPrefRef = "ai-pref";

  const subNavItems = [
    {
      name: "Brands",
      reference: brandRef,
    },
    {
      name: "Branding Information",
      reference: brandingInfoRef,
    },
    {
      name: "Terms and Conditions",
      reference: termsRef,
    },
    {
      name: "AI Preferences",
      reference: AIPrefRef,
    },
  ];

  const formMethods = useForm<Company>({
    mode: "onSubmit",
    // resolver: yupResolver(validationSchema),
    defaultValues: user.company,
    resolver: yupResolver(companyValidationSchema),
  });

  useUnsavedChangesPrompt(formMethods.formState.isDirty);

  // Set the company in the query cache when the component is mounted. This is used to reset the company when the user navigates away from the page.
  React.useEffect(() => {
    return () => {
      queryClient.setQueryData([ME_QUERY_KEY], { ...user, company: formMethods.getValues() });
    };
  }, []);

  // Mutation to update the company
  const { mutateAsync: mutateCompany, isPending: isLoadingSave } = useMutation({
    mutationFn: CompanyAPI.updateCompany,
    onSuccess: (data: Company) => {
      formMethods.reset(data);
    },
  });

  // Query to get the trips of the brand
  const brandTripsQueryState = usePaginatedTrips(
    {
      brands: brandToDelete?.id ?? "",
      step: 1,
    },
    {
      // The query will not execute automatically
      enabled: false,
      queryKey: [BRAND_TRIPS],
    },
  );

  // Use effect to attach event listeners to detect when the users refreshes the page or clicks the back button.
  React.useEffect(() => {
    if (formMethods.formState.isDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [formMethods.formState.isDirty]);
  useUnsavedChangesPrompt(formMethods.formState.isDirty);

  const handleSubmit = async () => {
    const values = formMethods.getValues();
    // Save the logos to S3.
    await submitDocumentsArray(values.brands, "logos/");
    await handleSubmission({
      successMessage: "updated Branding",
      failMessage: "update Branding",
      apiCall: mutateCompany(values),
      toast,
      setError: formMethods.setError,
    });
  };

  // Save the brand, used when setting the brand logo, rescaling the logo and removing the logo.
  const saveBrand = async (brand: CompanyBrand, index: number) => {
    formMethods.setValue(`brands.${index}`, brand);
    await handleSubmit();
  };

  // Handles the adding of a new brand
  const addNewBrand = async () => {
    const brandsList = formMethods.getValues("brands");
    brandsList.push(newEmptyBrand);
    formMethods.setValue("brands", [...brandsList]);
    await handleSubmit();
    // Sets the selected brand to the new brand that was added.
    setSelectedBrandIndex(brandsList.length - 1);
  };

  const onClickDelete = (index: number) => {
    setBrandToDelete(formMethods.getValues("brands")[index]);
    confirmDisclosure.onOpen();
  };

  const deleteBrand = async (index: number) => {
    deleteDisclosure.onClose();
    const brands = formMethods.getValues("brands");
    const deletedBrand = brands[index];
    const selectedBrand = brands[selectedBrandIndex];
    brands.splice(index, 1);
    // if the brand is the selected brand, select the first brand in the list. Since the brand is removed from the array, the deleted brand can't be selected.
    if (selectedBrand.id === deletedBrand.id) setSelectedBrandIndex(0);
    // if the selected brand index is higher than the deleted brand index, decrease the selected brand index by 1.
    else if (selectedBrandIndex > index) setSelectedBrandIndex(selectedBrandIndex - 1);
    // if the brand is the default brand, set the first brand in the list as default. Since the brand is removed from the array, the deleted brand can't be set as default.
    if (deletedBrand.default_brand) brands.filter((brand) => brand.id !== deletedBrand.id)[0].default_brand = true;

    formMethods.setValue("brands", brands, { shouldDirty: true });
    await handleSubmit();
  };

  const handleDeleteCheck = async (brand: CompanyBrand) => {
    // Manually start loading the number of trips of this brand.
    const result = await brandTripsQueryState.refetch();

    if (result.error || !result.data) {
      // The trips could not be loaded. Stop the deleting process and show an error message to the user
      addToast(toast, { title: "Failed deleting brand", status: "error" });
      return;
    }
    const containsTrips = result.data.count > 0;
    if (containsTrips) {
      // The brand is still used in some trips.
      // Open the warning modal.
      deleteDisclosure.onOpen();
    } else {
      const index = formMethods.getValues("brands").findIndex((b) => b.id === brand.id);
      await deleteBrand(index);
    }
  };

  // Set the brand as default, this does not save the brand to the DB.
  const setBrandAsDefault = (i: number) => {
    const brands = formMethods.getValues("brands");
    // Set the current default brand to false
    const indexDefaultBrand = brands.findIndex((brand) => brand.default_brand);
    brands[indexDefaultBrand].default_brand = false;
    formMethods.setValue(`brands.${indexDefaultBrand}.default_brand`, false, { shouldDirty: true });
    // Set the updated brand as default
    brands[i].default_brand = true;
    // Update the form values and the stat
    formMethods.setValue(`brands.${i}.default_brand`, true, { shouldDirty: true });
  };

  const setContentAsDefault = async (i: number, fieldname: BrandContent) => {
    const brands = formMethods.getValues("brands");
    const content = brands[i][fieldname];
    for (let j = 0; j < brands.length; j++) {
      if (j === i) continue;
      formMethods.setValue(`brands.${j}.${fieldname}`, content, { shouldDirty: true });
    }
    await handleSubmit();
  };

  const selectedBrand = formMethods.getValues("brands")[selectedBrandIndex];

  return (
    <Settings tabName="Branding" subNavItems={subNavItems}>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <Header isLoading={isLoadingSave} />
        <Branding
          id={brandRef}
          selectedBrandIndex={selectedBrandIndex}
          brands={formMethods.getValues("brands")}
          addNewBrand={addNewBrand}
          deleteBrand={onClickDelete}
          selectBrand={setSelectedBrandIndex}
          setBrandAsDefault={setBrandAsDefault}
        />
        {selectedBrand && (
          <Flex gap={"2em"} flexDir={"column"} mt={"1em"}>
            <BrandingInformation
              id={brandingInfoRef}
              brand={selectedBrand}
              index={selectedBrandIndex}
              setBrandAsDefault={setBrandAsDefault}
              saveBrand={saveBrand}
            />
            <TripTerms
              id={termsRef}
              brand={selectedBrand}
              index={selectedBrandIndex}
              setAsDefault={setContentAsDefault}
            />
            <AIWritingPreferencesSettings
              id={AIPrefRef}
              brand={selectedBrand}
              index={selectedBrandIndex}
              setAsDefault={setContentAsDefault}
            />
          </Flex>
        )}
        <CustomModal title={"Error deleting brand"} isOpen={deleteDisclosure.isOpen} onClose={deleteDisclosure.onClose}>
          <Text>
            {`The brand ${brandToDelete?.name} is still used in trips. Please remove the brand from the trips before deleting the brand.`}
          </Text>
          {brandToDelete && (
            <Button colorScheme="brand" onClick={deleteDisclosure.onClose} mt={"1em"}>
              Back
            </Button>
          )}
        </CustomModal>
        <ConfirmModal
          title={"Delete Brand"}
          description={`Are you sure you want to delete the brand ${brandToDelete?.name}?`}
          action={() => handleDeleteCheck(brandToDelete!)}
          disclosure={confirmDisclosure}
          isLoading={brandTripsQueryState.isLoading}
        />
      </Form>
    </Settings>
  );
};

export default BrandSettings;

type BrandImageProps = {
  name: string;
  logoUrl?: string;
};

export const BrandImage: React.FC<BrandImageProps> = ({ name, logoUrl }) => {
  return (
    <>
      {logoUrl ? (
        <Flex
          p={2}
          borderRadius="md"
          borderColor="gray.200"
          borderWidth="2px"
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip label={name}>
            <Image maxH={`3em`} src={logoUrl} alt={`company-logo`} fallbackSrc="https://via.placeholder.com/200" />
          </Tooltip>
        </Flex>
      ) : (
        <Text fontSize="lg" width={"max-content"}>
          {name}
        </Text>
      )}
    </>
  );
};

type HeaderProps = {
  isLoading?: boolean;
};

const Header: React.FC<HeaderProps> = ({ isLoading }) => {
  return (
    <Flex flexDir={"row"} justifyContent={"space-between"} mb={"1em"}>
      <VStack gap={"0.5em"} alignItems={"flex-start"}>
        <Heading>Default Brand Settings</Heading>
        <Text fontSize={{ base: "sm", md: "lg" }}>
          Please add all your brands you use to sell your trips below. When selling a trip with a specific brand, the
          logo of that brand is shown on the travel app.
        </Text>
      </VStack>
      <Flex>
        <Button colorScheme="brand" variant="solid" type="submit" isLoading={isLoading}>
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
