import { useNavigate } from "react-router-dom";
import { getInitialTrip } from "../pages/create-trip";
import { Trip } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import TripsAPI from "../api/trips.api";
import { useMeContext } from "../stores/me-context";

const useCreateNewTrip = (samples: boolean) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const me = useMeContext();
  const emptyFormInitialValues = getInitialTrip(me);

  const resourceName = samples ? "sample" : "trip";
  const route = resourceName + "s";

  const createTripMutation = useMutation({
    mutationFn: async (newTrip: Trip) => await TripsAPI.createTrip(newTrip),
    onSuccess: () => {
      // Invalidate the list of trips
      queryClient.invalidateQueries({
        queryKey: ["usertrips"],
      });
    },
  });

  const createTrip = async (initialTrip: Trip, sample: boolean): Promise<string> => {
    const sampleOverwrites = sample
      ? {
          sample,
          start_date: null,
          end_date: null,
        }
      : {};
    // Create a new trip
    const trip: Trip = await createTripMutation.mutateAsync({
      ...initialTrip,
      ...sampleOverwrites,
    });
    return trip.userTrips ? trip.userTrips[trip.userTrips.length - 1].id! : "";
  };

  const handleCreateTrip = async () => {
    const userTripId = await createTrip(emptyFormInitialValues, samples ? true : false);
    // Go to the edit trip or sample page
    navigate(`/${route}/${userTripId}`);
  };

  return { handleCreateTrip };
};

export default useCreateNewTrip;
