import { PrintLanguageType } from "../components/trips/print-trip/PrintLanguageModal";
import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider, { axios } from "./utilities/rawProvider";

const url = "pdf";

// Get the standard CRUD operations
const basicTripEndpoints = getApiEndpoints({
  url,
});

// Download a .pdf file of the given usertrip
const downloadUsertripPDF = (data: PrintLanguageType) => {
  return axios.post(`${url}/trip/pdf`, data, {
    responseType: "blob",
  });
};

const downloadVoucherPDF = (ref: string) => {
  return axios.post(`${url}/voucher/pdf/${ref}`, {}, { responseType: "blob" });
};

const downloadTripAccommodationVoucherPDF = (id: string, usertripId: string) => {
  return axios.post(`${url}/trip-accommodation-voucher/pdf/${id}/${usertripId}`, {}, { responseType: "blob" });
};

const downloadUsertripPDFStaging = (data: object) => {
  return axios({
    url: `https://staging.api.latotravelapp.com/api/v1/pdf/pdf`,
    method: "POST",
    responseType: "blob",
    data,
    // headers: {
    //   "Access-Control-Allow-Credentials": true,
    // },
  });
};

// Download a .pdf file of the given usertrip
const getDocumentData = (data: PrintLanguageType) => {
  return axios.post(`${url}/get-document-data`, data);
};

const downloadUsertripDocxStaging = (data: object) => {
  return axios({
    url: `https://staging.api.latotravelapp.com/api/v1/pdf/docx`,
    method: "POST",
    responseType: "blob",
    data,
    // headers: {
    //   "Access-Control-Allow-Credentials": true,
    // },
  });
};

// Download a .docx file of the given usertrip
const downloadUsertripDocx = (data: object) => {
  return axios.post(`${url}/trip/docx`, data, {
    responseType: "blob",
  });
};

type PdfAPIType = ReturnEndpoints & {
  downloadVoucherPDF(ref: string): Promise<any>;
  downloadTripAccommodationVoucherPDF(id: string, usertripId: string): Promise<any>;
  downloadUsertripPDF(data: PrintLanguageType): Promise<any>;
  downloadUsertripDocx(data: PrintLanguageType): Promise<any>;
  downloadUsertripPDFStaging(data: PrintLanguageType): Promise<any>;
  downloadUsertripDocxStaging(data: PrintLanguageType): Promise<any>;
  getDocumentData(data: PrintLanguageType): Promise<any>;
};
const PdfAPI: PdfAPIType = {
  ...basicTripEndpoints,
  downloadVoucherPDF,
  downloadTripAccommodationVoucherPDF,
  downloadUsertripPDF,
  downloadUsertripDocx,
  downloadUsertripPDFStaging,
  downloadUsertripDocxStaging,
  getDocumentData,
};
export default PdfAPI;
