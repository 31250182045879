import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  Select,
  Text,
  Tooltip,
  useModalContext,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Contact, HOTEL_BOOKING_STATUS, Hotel, Room, omitLocalTimezone } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { UseFormReturn, useForm, useFormContext, useWatch } from "react-hook-form";
import { BsFillPersonFill } from "react-icons/bs";
import { TbMoodKid } from "react-icons/tb";
import { trpc } from "../../../../../../../trpc";
import { useMeContext } from "../../../../../../stores/me-context";
import { prettyPrintDate } from "../../../../../../utils/date";
import { useTrpcMutation, useTrpcQuery } from "../../../../../../utils/query-helpers/reactQueryHooks";
import { hotelAvailabilitySchema } from "../../../../../../validation/validationSchemas";
import DateRangePicker from "../../../../../input/date/DateRangePicker";
import RHFDayPicker from "../../../../../input/date/RHFDayPicker";
import { CustomSpinner } from "../../../../../FullScreenSpinner";
import Form from "../../../../../form/Form";
import InputWrapper from "../../../../../input/InputWrapper";
import RHFInput from "../../../../../input/RHFInput";
import RHFSelect from "../../../../../input/RHFSelect";
import CustomModal from "../../../../../layout/CustomModal";
import ErrorCardView from "../../../../../layout/ErrorCardView";
import Section from "../../../../../layout/Section";
import RHFDecimalInput from "../../../../../input/RHFDecimalInput";
import { SingleAccommodation } from "../SingleAccommodation";

interface BookModalProps {
  hotel: Hotel;
  isOpen: boolean;
  onClose: () => void;
  startDate: Date;
  endDate: Date;
  roomCall?: any;
  holder?: any;
  adults: number;
  children: { age: number }[];
  setRooms: (rooms: any[]) => void;
  edit?: boolean;
  bookingReference?: string;
  clientReference?: string;
  userTripId?: string;
  detached?: boolean;
}

const BookModal: React.FC<BookModalProps> = ({
  hotel,
  isOpen,
  onClose,
  startDate,
  endDate,
  roomCall,
  holder,
  adults,
  children,
  setRooms,
  edit = false,
  bookingReference,
  clientReference,
  userTripId,
  detached = false,
}) => {
  const [availableRooms, setAvailableRooms] = React.useState();

  const { data: roomsData } = roomCall ? useTrpcQuery(roomCall, { code: hotel.originCode }) : { data: [] };

  const user = useMeContext();

  const rooms = roomsData?.data ?? [];

  const availabilityFormMethods = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      from: startDate,
      to: endDate,
      minRate: 0,
      maxRate: 0,
      rooms: [
        {
          adults: Math.max(1, adults),
          children: children.length,
          ages: children.map((child: any) => child.age),
        },
      ],
      shiftDays: 0,
      holder,
    },
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(hotelAvailabilitySchema),
  });

  const accommodationHeader = SingleAccommodation({ item: hotel, scale: 3, width: "75%" });

  return (
    <CustomModal title={`Book ${hotel.name}`} isOpen={isOpen} onClose={onClose} blockScrollOnMount size="3xl">
      <Section noShadow noDivider w={"100%"} borderRadius={"10px"}>
        {accommodationHeader}
        {!rooms || rooms?.length === 0 ? (
          <CustomSpinner m="auto" />
        ) : (
          <AvailabilityForm
            availabilityFormMethods={availabilityFormMethods}
            hotel={hotel}
            startDate={startDate}
            endDate={endDate}
            setAvailableRooms={setAvailableRooms}
            detached={detached}
          />
        )}

        {availableRooms && (
          <Box mt={4}>
            <BookingForm
              availableRooms={availableRooms}
              rooms={rooms}
              holder={availabilityFormMethods.getValues("holder")}
              chosenRooms={availabilityFormMethods.getValues("rooms")}
              setRooms={setRooms}
              onClose={onClose}
              hotelId={hotel.id ?? ""}
              hotelCode={hotel.originCode ?? ""}
              edit={edit}
              bookingReference={bookingReference}
              clientReference={clientReference}
              userTripId={userTripId}
              detached={detached}
            />
          </Box>
        )}
      </Section>
    </CustomModal>
  );
};

interface RoomFormProps {
  index: number;
  removeRoom: (index: number) => void;
}

interface AvailabilityFormProps {
  availabilityFormMethods: UseFormReturn<any, any, undefined>;
  hotel: Hotel;
  startDate: Date;
  endDate: Date;
  setAvailableRooms: any;
  detached: boolean;
}

const AvailabilityForm: React.FC<AvailabilityFormProps> = ({
  availabilityFormMethods,
  hotel,
  startDate,
  endDate,
  setAvailableRooms,
  detached,
}) => {
  const defaultSelected = {
    from: startDate,
    to: endDate,
  };

  const [roomsToBook, setRoomsToBook] = React.useState(1);
  const [noRoomsFound, setNoRoomsFound] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<{ from: Date | undefined; to: Date | undefined }>(defaultSelected);

  const modalContext = useModalContext();
  const user = useMeContext();

  const removeRoom = (index: number) => {
    const rooms = availabilityFormMethods.getValues("rooms");
    rooms.splice(index, 1);
    availabilityFormMethods.setValue("rooms", rooms);
    setRoomsToBook(rooms.length);
  };

  const today = new Date();

  const { mutateAsync: getHotelbedAvailability, isPending } = useTrpcMutation(trpc.hotelbeds.hotelbedsAvailability);

  const handleAvailabilitySubmit = async () => {
    const formValues = availabilityFormMethods.getValues();
    const fromDate = dateRange.from;
    const toDate = dateRange.to;
    const startDateAfter = new Date(fromDate ?? startDate);
    startDateAfter.setDate(startDateAfter.getDate() + 1);
    const availabilityResponse = await getHotelbedAvailability({
      userId: user.id,
      code: hotel.originCode,
      from: omitLocalTimezone(fromDate ?? startDate, true).toISOString(),
      to: omitLocalTimezone(
        toDate ? toDate : endDate.getTime() < (fromDate ?? startDate).getTime() ? startDateAfter : endDate,
        true,
      ).toISOString(),
      minRate: +formValues.minRate,
      maxRate: +formValues.maxRate,
      rooms: formValues.rooms.map((room: any) => ({
        ...room,
        adults: +room.adults,
        children: +room.children,
        ages: (room?.ages ?? []).filter((age: any) => age).map((age: any) => +age),
      })),
      shiftDays: +formValues.shiftDays === 0 ? undefined : +formValues.shiftDays,
    });
    setNoRoomsFound(availabilityResponse?.total === 0);
    setAvailableRooms(
      availabilityResponse?.hotels?.length > 0 && availabilityResponse?.total !== 0 ? availabilityResponse : undefined,
    );
  };

  return (
    <Form formMethods={availabilityFormMethods} onSubmit={handleAvailabilitySubmit}>
      <Heading size={"lg"} mt={1}>
        Date
      </Heading>
      <Divider mb={3} />
      <Flex flexDir="column" gap={2} mt={1}>
        <Flex gap={3} alignItems={"center"} mb={2}>
          <InputWrapper label="Date" name="dateRange" bottomMarginLabel="0">
            <DateRangePicker
              min={1}
              format={"PP"}
              numberOfMonths={2}
              fromDate={startDate < today ? startDate : today}
              defaultSelected={dateRange}
              onSelect={(e) => setDateRange({ from: e?.from, to: e?.to })}
              modalContext={modalContext}
            />
          </InputWrapper>

          {detached && (
            <InputWrapper label="Shift" name="shiftDays" bottomMarginLabel="0">
              <RHFSelect
                options={[
                  { value: 0, text: "0 days" },
                  { value: 1, text: "1 day" },
                  { value: 3, text: "3 days" },
                  { value: 7, text: "1 week" },
                ]}
                name="shiftDays"
                w="20%"
              />
            </InputWrapper>
          )}
        </Flex>
        <Box>
          <Flex gap={2}>
            <Heading size={"lg"}>Price</Heading>
            <Text alignSelf={"end"} fontSize={"10px"}>
              ( tax excluded )
            </Text>
          </Flex>
          <Divider mb={1} />
        </Box>
        <Flex gap={3}>
          <RHFDecimalInput name={`minRate`} formatCode="en-US" placeholder="0.00" label={"Minimum Price"} />
          <RHFDecimalInput name={`maxRate`} formatCode="en-US" placeholder="0.00" label={"Maximum Price"} />
        </Flex>
        <Box>
          <Heading size={"lg"}>Holder</Heading>
          <Divider mb={1} />
        </Box>
        <Flex gap={3}>
          <RHFInput name="holder.last_name" label={"Last name"} />
          <RHFInput name="holder.first_name" label={"First name"} />
        </Flex>
        <Box>
          <Heading size={"lg"}>Rooms</Heading>
          <Divider mb={1} />
        </Box>
        {Array.from(new Array(roomsToBook)).map((_, i) => (
          <RoomForm index={i} removeRoom={removeRoom} />
        ))}
        <Link ml={1} fontSize={"10px"} w={"fit-content"} onClick={() => setRoomsToBook(roomsToBook + 1)}>
          add room +
        </Link>

        <Button colorScheme="brand" type="submit" mr="auto" mt={2} isLoading={isPending}>
          Check Availability
        </Button>
        {noRoomsFound && (
          <Text textAlign={"center"} fontSize={"16px"}>
            No rooms found
          </Text>
        )}
      </Flex>
    </Form>
  );
};

const RoomForm: React.FC<RoomFormProps> = ({ index, removeRoom }) => {
  const fieldArrayName = `rooms.${index}`;

  const { setValue, getValues } = useFormContext();

  const children = useWatch({
    name: `${fieldArrayName}.children`,
  });

  return (
    <Flex gap={3} alignItems={"start"}>
      <RHFInput
        w={"30%"}
        label="Adults"
        type="number"
        name={`${fieldArrayName}.adults`}
        bottomMarginLabel="0"
        min={1}
        defaultValue={1}
      />
      <RHFInput
        w={"30%"}
        label="Children"
        type="number"
        name={`${fieldArrayName}.children`}
        bottomMarginLabel="0"
        min={0}
        defaultValue={0}
        onChange={(e: any) => {
          setValue(
            `${fieldArrayName}.ages`,
            Array.from(new Array(+e.target.value)).map(() => 0),
          );
          setValue(`${fieldArrayName}.children`, +e.target.value);
        }}
      />
      {children !== 0 && children != undefined && (
        <Flex w={"30%"} flexDir={"column"} gap={1}>
          {Array.from(new Array(children)).map((_, i) => (
            <InputWrapper
              label={`${i + 1}${numberToOrder(i + 1)} Child's Age`}
              name={`${fieldArrayName}.ages.${i}`}
              bottomMarginLabel="0"
            >
              <RHFSelect
                name={`${fieldArrayName}.ages.${i}`}
                options={Array.from(new Array(18)).map((_, i) => ({ value: i, text: `${i} years` }))}
              />
            </InputWrapper>
          ))}
        </Flex>
      )}
      <Tooltip label={"Remove room"}>
        <Button alignSelf={"start"} mt={"1.5em"} cursor={"pointer"} variant={"ghost"} onClick={() => removeRoom(index)}>
          &times;
        </Button>
      </Tooltip>
    </Flex>
  );
};

export const numberToOrder = (num: number) => {
  return num === 1 ? "st" : num === 2 ? "nd" : num === 3 ? "rd" : "th";
};

interface BookingFormProps {
  availableRooms: {
    checkIn: string;
    checkOut: string;
    hotels: any[];
  };
  rooms?: Room[];
  chosenRooms: any[];
  holder?: Contact;
  setRooms: (rooms: any[]) => void;
  onClose: () => void;
  hotelId: string;
  hotelCode: string;
  detached: boolean;
  edit?: boolean;
  bookingReference?: string;
  clientReference?: string;
  userTripId?: string;
}

export const BookingForm: React.FC<BookingFormProps> = ({
  availableRooms,
  rooms,
  chosenRooms,
  holder,
  setRooms,
  onClose,
  hotelId,
  hotelCode,
  edit = false,
  bookingReference,
  clientReference,
  userTripId,
  detached,
}) => {
  const checkIn = new Date(availableRooms.checkIn);
  const prettyCheckIn = prettyPrintDate(checkIn);
  const checkOut = new Date(availableRooms.checkOut);
  const prettyCheckOut = prettyPrintDate(checkOut);
  const queryClient = useQueryClient();
  const me = useMeContext();

  const [availableRoomsFlat, setAvailableRoomsFlat] = React.useState(availableRooms.hotels[0].rooms);
  const [selectedRates, setSelectedRates] = React.useState<any[]>([]);

  const { setValue } = useFormContext();

  useEffect(() => {
    setAvailableRoomsFlat(availableRooms.hotels[0].rooms);
    setSelectedRates([]);
  }, [availableRooms]);

  const { mutateAsync: bookHotelMutation, isPending } = useTrpcMutation(
    edit ? trpc.hotelbeds.updateBooking : trpc.hotelbeds.hotelbedsBook,
  );

  const bookHotel = async () => {
    if (selectedRates && selectedRates.length > 0 && holder) {
      const bookParams = {
        userId: me.id,
        bookingReference,
        clientReference,
        checkIn: availableRooms.checkIn,
        checkOut: availableRooms.checkOut,
        latoId: hotelId,
        hotelCode,
        rooms: selectedRates.map((rate, index) => {
          const paxes = (chosenRooms[index]?.ages ?? []).map((age: any) => ({
            type: "CH",
            age: age,
          }));
          return {
            code: rate.room.code,
            characteristic: rate.room.characteristic,
            maxAdults: +rate.room.maxAdults ?? 0,
            maxChildren: +rate.room.maxChildren ?? 0,
            description: rate.room.description,
            rate: {
              rateKey: rate.rateKey,
              type: rate.rateType,
              paxes: [...paxes, { type: "AD", name: holder.last_name, surname: holder.first_name }],
            },
            comment: rate.comment,
          };
        }),
        board: selectedRates[0].boardName.charAt(0) + selectedRates[0].boardName.slice(1).toLowerCase(),
        holder: { first_name: holder.first_name.trim(), last_name: (holder.last_name ?? "").trim() },
        detached,
      } as any;

      const res = await bookHotelMutation(bookParams);

      if (edit) {
        queryClient.invalidateQueries({
          queryKey: ["usertrip", userTripId],
        });
      } else {
        const board = selectedRates[0].boardName;
        setValue("board", board.charAt(0).toUpperCase() + board.slice(1).toLowerCase());
        rooms && setRooms(selectedRates.map((rate) => rooms.find((room: any) => room.code === rate.room.code)));
        setValue("bookingStatus", HOTEL_BOOKING_STATUS.BOOKED);
        setValue("bookingRef", res.booking.reference);
      }

      onClose();
    }
  };

  const dateDiff = Math.abs(checkOut.getTime() - checkIn.getTime());
  const dateDiffDays = Math.ceil(dateDiff / (1000 * 3600 * 24));
  return (
    <>
      <Heading size={"lg"}>
        Available Rooms for {prettyCheckIn} - {prettyCheckOut}
      </Heading>
      <Divider mb={3} />
      {availableRoomsFlat.map((room: any) => (
        <RoomBookingForm
          room={room}
          rooms={rooms}
          checkIn={checkIn}
          setSelectedRates={setSelectedRates}
          selectedRates={selectedRates}
          dateDiffDays={dateDiffDays}
        />
      ))}

      {selectedRates.filter((rate: any) => rate.amount !== "0").length > 0 && (
        <Box mt={4}>
          <Heading size={"lg"}>
            Selected Rates for {dateDiffDays} {dateDiffDays > 1 ? "nights" : "night"}
          </Heading>
          <Divider mb={3} />
          {selectedRates
            .filter((rate: any) => rate.amount !== "0")
            .map((rate: any) => (
              <Flex justifyContent={"space-between"}>
                <Text>
                  {rate.amount} x {rate.room.name.charAt(0).toUpperCase() + rate.room.name.slice(1).toLowerCase()} -{" "}
                  {rate.boardName.charAt(0).toUpperCase() + rate.boardName.slice(1).toLowerCase()}
                </Text>
                <Text>
                  {rate.price} {rate.currency}
                </Text>
              </Flex>
            ))}
          <Flex justifyContent={"space-between"} mt={3}>
            <Box>
              <Text fontWeight={"bold"}>Total</Text>
              <Text fontSize={"10px"}>{"( tax included )"}</Text>
            </Box>
            <Text fontWeight={"bold"}>
              {Math.round(selectedRates.map((rate: any) => rate.price).reduce((a, b) => +a + +b, 0) * 100) / 100}{" "}
              {(selectedRates[0] as any).currency}
            </Text>
          </Flex>
          <Button
            my={4}
            float={"right"}
            fontSize={"14px"}
            colorScheme="brand"
            onClick={bookHotel}
            isLoading={isPending}
          >
            {(edit ? "Update" : "Confirm") + " Booking"}
          </Button>
        </Box>
      )}
    </>
  );
};

interface RoomBookingFormProps {
  room: any;
  rooms?: Room[];
  checkIn: Date;
  setSelectedRates: (rates: any) => void;
  selectedRates: any[];
  dateDiffDays: number;
}

const RoomBookingForm: React.FC<RoomBookingFormProps> = ({
  room,
  rooms,
  checkIn,
  setSelectedRates,
  selectedRates,
  dateDiffDays,
}) => {
  room = {
    ...room,
    ...(rooms ? rooms.find((roomObj: any) => roomObj.code === room.code) : room),
  };

  let rates = room.rates
    .sort((a: any, b: any) => (a.net !== b.net ? a.net - b.net : b.adults + b.children - (a.adults + a.children)))
    .filter(
      (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.boardCode === value.boardCode),
    );
  return (
    <Box>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          <Text size={"md"}>{room.name.charAt(0).toUpperCase() + room.name.slice(1).toLowerCase()}</Text>
          {room.description && (
            <Text fontSize={"sm"} color="gray.500">
              {room.description.charAt(0).toUpperCase() + room.description.slice(1).toLowerCase()}
            </Text>
          )}
        </Box>
      </Flex>
      <Box shadow={"md"} borderRadius={"5px"} p={2}>
        {rates
          .filter((rate: any) => rate.allotment !== 0)
          .map((rate: any) => (
            <RateBookingForm
              rate={rate}
              room={room}
              checkIn={checkIn}
              setSelectedRates={setSelectedRates}
              selectedRates={selectedRates}
              dateDiffDays={dateDiffDays}
            />
          ))}
      </Box>
    </Box>
  );
};

interface RateBookingFormProps {
  rate: any;
  room: any;
  checkIn: Date;
  setSelectedRates: (rates: any) => void;
  selectedRates: any[];
  dateDiffDays: number;
}

const RateBookingForm: React.FC<RateBookingFormProps> = ({
  rate,
  room,
  checkIn,
  setSelectedRates,
  selectedRates,
  dateDiffDays,
}) => {
  const price = rate.sellingRate;
  const currency = (rate.taxes?.taxes ?? [])[0]?.currency ?? "EUR";

  return (
    <Flex flexDir="column">
      <Flex p={2} mt={2} alignItems={"center"} justifyContent={"space-between"}>
        <Text w={"30%"}>{rate.boardName.charAt(0).toUpperCase() + rate.boardName.slice(1).toLowerCase()}</Text>
        <Box w={"15%"}>
          <Flex>
            {Array.from(new Array(rate.adults)).map((_, i) => (
              <Icon as={BsFillPersonFill}></Icon>
            ))}
          </Flex>
          <Flex>
            {Array.from(new Array(rate.children)).map((_, i) => (
              <Icon as={TbMoodKid}></Icon>
            ))}
          </Flex>
        </Box>
        <Text w={"20%"}>
          {price} {currency}
        </Text>

        <Select
          w={"20%"}
          key={`rate-select-${rate.rateKey}-${rate.adults}-${rate.children}`}
          onChange={(e: any) => {
            setSelectedRates([
              ...selectedRates.filter((selectedRate: any) => selectedRate.rateKey !== rate.rateKey),
              {
                ...rate,
                amount: e.target.value,
                room: room,
                price: Math.round(price * e.target.value * 100) / 100,
                currency,
              },
            ]);
          }}
        >
          {Array.from(new Array(Math.min(rate.allotment, 10) + 1)).map((_, i) => (
            <option value={i}>{`${i}   ${i !== 0 ? `(${Math.round(price * i * 100) / 100} ${currency})` : ""}`}</option>
          ))}
        </Select>
      </Flex>
      {rate.comment && (
        <Flex mx={2} fontSize={"14px"} fontWeight={"bold"}>
          <Text>{rate.comment}</Text>
        </Flex>
      )}
      {(rate.cancellationPolicies ?? []).map((policy: any) => (
        <Flex mx={2} mb={2} fontSize={"14px"} fontWeight={"bold"}>
          Cancelling from {prettyPrintDate(policy.from)} will cost {policy.amount} {currency}
        </Flex>
      ))}
    </Flex>
  );
};

export default BookModal;
