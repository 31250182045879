import {
  Box,
  BoxProps,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import React from "react";
import { DeepMap, FieldError, useFormContext } from "react-hook-form";
import { getFieldErrors } from "./RHFInput";

type InputWrapperProps = Omit<FormControlProps, "label"> & {
  name: string;
  label?: React.ReactNode;
  horizontal?: boolean;
  helperText?: string;
  inputMarginLeft?: string;
  labelWidth?: BoxProps["width"];
  minWidth?: string;
  inputWidth?: BoxProps["width"];
  headerButton?: JSX.Element;
  bottomMarginLabel?: string;
  isInline?: boolean;
};

const InputWrapper: React.FC<InputWrapperProps> = ({
  name,
  label,
  horizontal = false,
  helperText,
  children,
  inputMarginLeft = "auto",
  labelWidth = "40%",
  minWidth = "60%",
  inputWidth = minWidth,
  headerButton,
  bottomMarginLabel = "0.2em",
  isInline = false,
  ...props
}) => {
  // TODO: pass the register to the children somehow.
  const {
    formState: { errors },
  } = useFormContext();
  const error = getFieldErrors(name, errors);
  return (
    <FormControl isInvalid={!!error} {...props}>
      {horizontal ? (
        <HorizontalInputWrapper
          name={name}
          label={label}
          helperText={helperText}
          error={error}
          inputMarginLeft={inputMarginLeft}
          labelWidth={labelWidth as string}
          minWidth={minWidth}
          inputWidth={inputWidth as string}
        >
          {children}
        </HorizontalInputWrapper>
      ) : (
        <>
          {!isInline && (
            <Flex alignItems="center" justifyContent={"space-between"}>
              {label && <FormLabel mb={bottomMarginLabel}>{label}</FormLabel>}
              {headerButton && headerButton}
            </Flex>
          )}
          {children}
          {helperText && (
            <FormHelperText mt="-0.5" mx="1px" color="realGray.400">
              {helperText}
            </FormHelperText>
          )}
          {error && (
            <FormErrorMessage mt="-0.5" mx="1px">
              {error.message}
            </FormErrorMessage>
          )}
        </>
      )}
    </FormControl>
  );
};
export default React.memo(InputWrapper);

type HorizontalInputWrapperProps = {
  name: string;
  label?: React.ReactNode;
  horizontal?: boolean;
  helperText?: string;
  error: DeepMap<Record<string, any>, FieldError>;
  inputMarginLeft: string;
  labelWidth: string;
  minWidth?: string;
  children: React.ReactNode;
  inputWidth: string;
  headerButton?: JSX.Element;
};

const HorizontalInputWrapper: React.FC<HorizontalInputWrapperProps> = ({
  name,
  label,
  horizontal,
  helperText,
  error,
  children,
  inputMarginLeft,
  labelWidth,
  minWidth,
  inputWidth,
  headerButton,
}) => {
  return (
    <Flex
      alignItems={{ base: "left", sm: "center" }}
      display={{ base: "flex", sm: "static" }}
      flexDir={{ base: "column", sm: "row" }}
      mt={{ base: "2", sm: "0" }}
      mb={{ base: "2", sm: "0" }}
    >
      {(label || helperText) && (
        <Box minW={labelWidth}>
          <Flex alignItems="center" justifyContent={"space-between"}>
            {label && (
              <FormLabel minW={labelWidth} m={0} htmlFor={name} whiteSpace="nowrap" variant="horizontal">
                {label}
              </FormLabel>
            )}
            {headerButton && headerButton}
          </Flex>

          {helperText && (
            <FormHelperText color="realGray.400" mt="-0.1rem">
              {helperText}
            </FormHelperText>
          )}
        </Box>
      )}
      <Box ml={inputMarginLeft} minW={{ base: "100%", sm: minWidth }} w={inputWidth} mt={{ base: "2", sm: "0" }}>
        {children}
        {error && <FormErrorMessage mt="-0.09rem">{error.message}</FormErrorMessage>}
      </Box>
    </Flex>
  );
};
