import { Box, Heading, Icon, Stack } from "@chakra-ui/react";
import { TLocation, Destination, TranslationField } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useFormContext } from "react-hook-form";
import { TbAirBalloon } from "react-icons/tb";
import DestinationsAPI from "../../../../../../api/destinations.api";
import { TripLanguageProvider, useTripLanguage } from "../../../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY } from "../../../../../../utils/constants";
import {
  updateRelationMap,
  usePatchSplitTripday,
} from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import OptionsDropdown, { DeleteOptionsDropdownItem } from "../../../../../CRUD/OptionsDropdown";
import RHFTranslationInput from "../../../../../input/RHFTranslationInput";
import TranslateTitle from "../../../../../input/TranslateTitle";
import ElementPreview from "../../ElementPreview";
import PicturesUploader from "../../PicturesUploader";
import LibraryItemDownshift, { LIBRARYITEM_TYPE } from "../LibraryItemDownshift";
import { afterChooseElementPictures } from "../activities/afterChooseElementPictures";
import SaveToLibraryOption from "../hotel/SaveToLibraryOption";

interface DestinationFormProps {
  inTrip?: boolean;
  destination?: Destination;
  updateDestination?: (data: Destination) => void;
  first?: boolean;
  collaboratorCanEdit?: boolean;
  location?: TLocation | null;
  titlePlaceHolder?: string;
  handleDelete?: (id: string) => void;
  formId?: string;
  forceNew?: boolean;
  tripdayId?: string;
  tripdayIndex?: number;
}

const DestinationForm: React.FC<DestinationFormProps> = ({
  inTrip = false,
  destination,
  updateDestination,
  first = true,
  collaboratorCanEdit = true,
  location,
  titlePlaceHolder = "",
  handleDelete,
  formId,
  forceNew = false,
  tripdayId,
  tripdayIndex,
}) => {
  const { getValues, reset } = useFormContext();
  const { firstSelectedLanguageIndex } = useTripLanguage();

  const { trip, setInTripStore, unCollapsedElement } = useTripFormStore();
  const [collapsed, setCollapsed] = React.useState(unCollapsedElement !== destination?.id && !!inTrip);

  React.useEffect(() => {
    setCollapsed(unCollapsedElement !== destination?.id && !!inTrip);
  }, [unCollapsedElement]);

  const tripdayLibraryImage = trip && trip.tripdays ? trip.tripdays[tripdayIndex ?? 0]?.libraryImage : true;
  const tripdayImage = trip && trip.tripdays ? trip.tripdays[tripdayIndex ?? 0]?.image : true;

  const afterChooseDestinationPictures = React.useCallback(
    async (images: any[], library = false) => {
      if (!tripdayImage && !tripdayLibraryImage)
        await afterChooseElementPictures(
          images,
          tripdayId,
          updateTripday,
          library,
          tripdayIndex ?? 0,
          setInTripStore,
          trip?.tripdays ? trip.tripdays[tripdayIndex ?? 0] : undefined,
        );
    },
    [trip, tripdayIndex, tripdayId],
  );

  const [id, setId] = React.useState(getValues("id"));
  const [forceNewState, setForceNewState] = React.useState(forceNew);

  const queryClient = useQueryClient();

  const { mutateAsync: updateTripday, isPending: isUpdatingTripday } = usePatchSplitTripday(
    tripdayId ?? "",
    updateRelationMap[DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY],
    queryClient,
    DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  );

  const descriptions = (
    <RHFTranslationInput
      name={`descriptions.0.content`}
      // placeholder="Located in the hearth of the city centre, this hotel offers free WiFi, wellness facilities and live music."
      // variant="noBorders"
      size="md"
      textarea
      first={first}
      collaboratorCanEdit={collaboratorCanEdit}
      isInline
      layout={"inline"}
    />
  );

  const titles = (
    <RHFTranslationInput
      name={`titles.0.content`}
      // placeholder="Located in the hearth of the city centre, this hotel offers free WiFi, wellness facilities and live music."
      // variant="noBorders"
      size="md"
      first={true}
      collaboratorCanEdit={true}
      isInline
      layout={"inline"}
    />
  );

  const translationFields: TranslationField[] = getValues("descriptions");

  const preview = React.useCallback(() => {
    const element = getValues();
    return (
      <ElementPreview
        description={
          element.descriptions[firstSelectedLanguageIndex]
            ? element.descriptions[firstSelectedLanguageIndex].content
            : ""
        }
        title={
          element.titles[firstSelectedLanguageIndex] &&
          element.titles[firstSelectedLanguageIndex].content != undefined &&
          element.titles[firstSelectedLanguageIndex].content !== ""
            ? element.titles[firstSelectedLanguageIndex].content
            : titlePlaceHolder
        }
        externalImages={element.images}
        libraryImages={element.libraryImages}
        overlay={!!unCollapsedElement}
        type="destination"
      />
    );
  }, [getValues, firstSelectedLanguageIndex]);

  return (
    <Box className="flex flex-col space-y-2 items-stretch group text-left" marginBottom={2}>
      {!inTrip && (
        <Stack direction="row" alignItems="center" h={14}>
          <Icon as={TbAirBalloon} boxSize={6} />
          <Heading size="xl" fontSize={"22px"}>
            Destination
          </Heading>
        </Stack>
      )}

      {!inTrip ? (
        <TripLanguageProvider doubleFields={false} first={true} defaultPartialFields={translationFields}>
          <TranslateTitle
            isFirst={true}
            name={"descriptions"}
            getValues={getValues}
            elementId={id}
            forceNew={forceNew}
            addLanguageCall={async (id: string, code: string, translateFields: any) => {
              const formValues = getValues();
              let response;
              if (!id || forceNewState) {
                response = await DestinationsAPI.post({ ...formValues, id: forceNew ? undefined : id });
                id = response.id;
                setForceNewState(false);
              } else {
                await DestinationsAPI.patch(id, formValues);
              }
              setId(id);
              response = await DestinationsAPI.addLanguage(id, code, translateFields);
              !forceNew && updateDestination && updateDestination(response.element);
              reset(response.element);
            }}
            removeLanguageCall={async (i: number, code: string) => {
              const formValues = getValues();
              let id = formValues.id;
              let response;
              if (!id || forceNewState) {
                response = await DestinationsAPI.post({ ...formValues, id: forceNew ? undefined : id });
                id = response.id;
                setForceNewState(false);
              } else {
                await DestinationsAPI.patch(id, formValues);
              }
              setId(id);
              response = await DestinationsAPI.removeLanguage(id, code);
              !forceNew && updateDestination && updateDestination(response.element);
              reset(response.element);
            }}
          />
          {titles}
        </TripLanguageProvider>
      ) : (
        <>
          {first && (
            <Box
              position="absolute"
              right={7}
              zIndex={1000}
              className="opacity-0 transition-all group-hover:opacity-100 "
            >
              <OptionsDropdown>
                <SaveToLibraryOption type={"destination"} getValues={getValues} />
                <DeleteOptionsDropdownItem
                  onClick={() => handleDelete && handleDelete(id)}
                  resourceName={"destination"}
                />
              </OptionsDropdown>
            </Box>
          )}
          {!collapsed && (
            <LibraryItemDownshift
              formId={formId}
              location={location!}
              collaboratorCanEdit={collaboratorCanEdit}
              first={first}
              inTrip
              libraryItemName={LIBRARYITEM_TYPE.DESTINATION}
              libraryItem={destination}
              getValues={getValues}
              reset={(destination: Destination) => {
                updateDestination && updateDestination(destination);
                afterChooseDestinationPictures(destination.images);
              }}
              placeholder={titlePlaceHolder}
            />
          )}
        </>
      )}
      {collapsed ? (
        preview()
      ) : (
        <>
          {descriptions}
          <PicturesUploader
            name={`images`}
            defaultSearchValue={getValues(`titles.0.content`)}
            defaultSearchValueName={`titles.0.content`}
            awsFilename={`accommodation`}
            location={getValues(`location`)}
            first={first}
            afterChoosePictures={(images) => afterChooseDestinationPictures(images)}
            afterChooseLibraryPictures={(images) => afterChooseDestinationPictures(images, true)}
          />
        </>
      )}
    </Box>
  );
};

export default React.memo(DestinationForm);
