import { Button, Link } from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import ColorInput from "./ColorInput";
import CoreInput from "./CoreInput";
import { RHFInputProps } from "./RHFInput";
import InputWrapper from "./InputWrapper";

interface RHFColorProps extends RHFInputProps {
  resetValue?: string;
}

const RHFColor: React.FC<RHFColorProps> = ({ name, resetValue, ...props }) => {
  // const { getValues } = useFormContext();
  // const defaultV = getValues(name);
  const { getValues } = useFormContext();
  const [previousValue, setPreviousValue] = React.useState(getValues(name));
  const { field } = useController({
    name,
    // On first render, the default value of the column should be used,
    // subsequent renders should use the more up to date getValues defaultValue.
    // defaultValue: defaultV || defaultValue,
  });

  const isValidHexColor = (hex: string) => {
    return /^#(?:[0-9a-fA-F]{3}){2}$/.test(hex);
  };

  const handleOnBlur = (e: any) => {
    if (e?.target?.value && isValidHexColor(e.target.value)) {
      setPreviousValue(e.target.value);
    } else {
      field.onChange(previousValue);
    }
  };

  return (
    <InputWrapper name={name}>
      <ColorInput
        {...props}
        field={field}
        currentValue={field.value}
        previousValue={previousValue}
        changeColor={handleOnBlur}
        resetValue={resetValue}
      />
    </InputWrapper>
  );
};

export default React.memo(RHFColor);
