import { Checkbox, Heading, Text, useDisclosure } from "@chakra-ui/react";
import {
  ExternalImage,
  ImageSite,
  Image as ImageType,
  TLibraryImage,
  Trip,
  TripDay,
  calculateTripdayDate,
  getKeyFromImageUrl,
} from "@lato/common";
import React from "react";
import { useController } from "react-hook-form";
import { calculateDayNumber } from "../../features/editTrip/overviewStep/TripsOverviewTable";
import TripdayPreviewTitle from "../../features/editTrip/previewStep/tripday-preview/TripdayPreviewTitle";
import ImageUploadModal from "../../features/external-images/ImageUploadModal";
import { useMeContext } from "../../stores/me-context";
import { useTripLanguage } from "../../stores/trip-language-context";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import transformExternalImage from "../../utils/transformExternalImage";

interface GeneralTripdayBackgroundUploaderProps {
  trip: Trip;
  tripdays: TripDay[];
  tripdayIndex: number;
  className?: string;
  first?: boolean;
  changeTripday: (i: number) => void;
  tripStartDate: string | null;
  updateTripday: any;
  disabledDaySwitch: boolean;
  tripdayImage: ImageType | TLibraryImage | undefined;
  homeBackgroundNumber: number;
  updateHomeBackgroundNumber: any;
  isUpdatingBackground: boolean;
}

const GeneralTripdayBackgroundUploader: React.FC<GeneralTripdayBackgroundUploaderProps> = ({
  trip,
  tripdays,
  tripdayIndex,
  tripStartDate,
  changeTripday,
  first,
  updateTripday,
  disabledDaySwitch,
  tripdayImage,
  homeBackgroundNumber,
  updateHomeBackgroundNumber,
  isUpdatingBackground,
  ...props
}) => {
  const me = useMeContext();
  const { setInTripStore } = useTripFormStore();
  const tripday = tripdays && tripdays[tripdayIndex];

  const disclosure = useDisclosure();

  const { field: externalImage } = useController({
    name: `image`,
  });
  const { field: libraryImage } = useController({
    name: `libraryImage`,
  });

  const { realLanguageIndex } = useTripLanguage();

  const handlePicture = (image: ImageType) => {
    externalImage.onChange(image);
    if (libraryImage.value) {
      libraryImage.onChange(null);
    }
    trip?.tripdays && trip.tripdays[tripdayIndex]
      ? setInTripStore(`tripdays.${tripdayIndex}.image`, image)
      : setInTripStore(`tripdays.${tripdayIndex}`, { image });
    updateTripday();
  };

  const showTooManyImageAlert = () => {
    alert("You can only add 1 image as a tripdayBackground, please try again");
  };

  const handleChosenLinkPictures = (images: ImageType[]) => {
    if (images.length > 1) {
      showTooManyImageAlert();
      return;
    }
    const image = images[0];
    handlePicture({
      ...image,
      s3: { key: image.originUrl },
      ord: 0,
    });
  };

  const handleChosenLibraryPicture = (image: TLibraryImage | TLibraryImage[]) => {
    if (Array.isArray(image)) {
      showTooManyImageAlert();
      return;
    }
    if (image) {
      libraryImage.onChange(image);
      if (externalImage.value) {
        externalImage.onChange(null);
      }
      trip?.tripdays && trip.tripdays[tripdayIndex]
        ? setInTripStore(`tripdays.${tripdayIndex}.libraryImage`, image)
        : setInTripStore(`tripdays.${tripdayIndex}`, { libraryImage: image });
    }
    updateTripday();
  };

  const handleChosenExternalPicture = (photo: ExternalImage | ExternalImage[]) => {
    // photo will never be an array because the multiple prop is set to false
    if (Array.isArray(photo)) {
      showTooManyImageAlert();
      return;
    }
    handlePicture({
      site: photo.imageSite,
      s3: { key: getKeyFromImageUrl(photo.urls.raw, photo.imageSite) },
      url: transformExternalImage(photo),
      ord: 0,
    });
  };

  const handleChosenLocalPicture = (blob: Blob | Blob[]) => {
    // blob will never be an array because the multiple prop is set to false
    if (Array.isArray(blob)) {
      if (blob.length > 1) {
        showTooManyImageAlert();
        return;
      }
      blob = blob[0];
    }
    // Check if there was already a temporary image
    if (tripday.image?.file != undefined) {
      // Revoke the url of the previous image
      URL.revokeObjectURL(tripday.image.url);
    }
    // create a new file with as filename the location name of the day.
    const f = new File([blob], tripday.location?.name || "", {
      type: "image/jpeg",
    });
    handlePicture({
      site: ImageSite.S3,
      file: f,
      url: URL.createObjectURL(f),
      ord: 0,
    });
  };
  const handleAddImage = () => {
    if (first) {
      disclosure.onOpen();
    }
  };

  const realDayNumber =
    tripdays && tripdayIndex < tripdays.length && tripdays.every((td) => !!td)
      ? calculateDayNumber(tripdayIndex, tripdays)
      : null;
  const tripday_start_date = realDayNumber != undefined ? calculateTripdayDate(realDayNumber, tripStartDate) : null;
  const tripday_date_pretty = tripday_start_date
    ? new Date(tripday_start_date)
        .toDateString()
        .split(" ")
        .map((el, i) => (i === 2 ? el + "," : el))
        .join(" ")
    : null;

  const handleChangeTripday = (e: any, i: number) => {
    e.stopPropagation();
    !disabledDaySwitch && changeTripday(i);
  };
  const locationName = tripday?.location?.name || "";

  return (
    <>
      <div {...props} style={{ cursor: first ? "pointer" : "default", alignItems: "start", marginTop: "1rem" }}>
        <button
          onClick={handleAddImage}
          type="button"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "linear-gradient(to top, rgba(0, 67, 84, 0.5), rgba(255, 255, 255, 0))",
          }}
        />
        {tripdayImage?.url && me.useNewEditTripLayout && (
          <Checkbox
            mx="auto"
            color="white"
            fontSize="sm"
            pos="absolute"
            bottom="1rem"
            // left="1rem"
            fontWeight={600}
            size={me.useNewEditTripLayout ? "sm" : "md"}
            backgroundColor={"brand"}
            py={2}
            borderRadius={"10px"}
            colorScheme="brand"
            opacity={1}
            isChecked={homeBackgroundNumber === tripdayIndex}
            isDisabled={homeBackgroundNumber === tripdayIndex || !first}
            // The 0 will never occur normally, as the checkbox is disabled when it is true.
            onChange={(e) => updateHomeBackgroundNumber(e.target.checked ? tripdayIndex : 0)}
          >
            Set as home background
          </Checkbox>
        )}
        <div className="text-center z-10">
          {tripday.id && (
            <Heading px={2} height={10}>
              <TripdayPreviewTitle trip={trip} tripdayId={tripday.id} />
            </Heading>
          )}
          <Text fontSize="sm">{tripday_date_pretty}</Text>
          {first && (
            <Text
              fontSize={me.useNewEditTripLayout ? "xs" : "sm"}
              fontWeight={600}
              w={"12em"}
              color="white"
              pos="absolute"
              bottom="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              backgroundColor={"rgba(0, 0, 0, 0.2)"}
              px={"0.4em"}
              py={1}
              borderRadius={"10px"}
              pointerEvents={"none"}
            >
              {isUpdatingBackground ? "Loading image..." : "Click to upload image"}
            </Text>
          )}
          {/* <div className="w-full justify-center flex items-center space-x-2 my-3">
            <Circle
              as="button"
              type="button"
              bg="brand.500"
              size="35px"
              disabled={tripdayIndex === 0 || disabledDaySwitch}
              visibility={tripdayIndex === 0 ? "hidden" : undefined}
              onClick={(e) => handleChangeTripday(e, tripdayIndex - 1)}
            >
              <ArrowBackIcon />
            </Circle>
            <Circle bg="brand.500" size="50px">
              {realDayNumber !== null && (
                <Text fontWeight={700} fontSize="sm">
                  DAY {realDayNumber + 1}
                </Text>
              )}
            </Circle>
            <Circle
              as="button"
              type="button"
              bg="brand.500"
              size="35px"
              disabled={(tripdays && tripdayIndex === tripdays.length - 1) || disabledDaySwitch}
              visibility={tripdays && tripdayIndex === tripdays.length - 1 ? "hidden" : undefined}
              onClick={(e) => handleChangeTripday(e, tripdayIndex + 1)}
            >
              <ArrowForwardIcon />
            </Circle>
          </div> */}
        </div>
      </div>
      {disclosure.isOpen && (
        <ImageUploadModal
          aspectRatio={9 / 16}
          disclosure={disclosure}
          handleChosenLocalPictures={handleChosenLocalPicture}
          handleChosenExternalPictures={handleChosenExternalPicture}
          handleChosenLinkPictures={handleChosenLinkPictures}
          handleChosenLibraryPictures={handleChosenLibraryPicture}
          multiple={false}
          defaultSearchValue={locationName}
        />
      )}
    </>
  );
};
export default GeneralTripdayBackgroundUploader;
