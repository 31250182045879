import { Flex, Grid, GridItem, Icon, Link, Text, Tooltip, useOutsideClick } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IconType } from "react-icons/lib";
import { Link as ReactLink } from "react-router-dom";
import CustomModal from "../../components/layout/CustomModal";
import { default as ResourceCenter, default as PopoverComponent } from "./ResourceCenter";

interface ResourceCenterButtons {
  ButtonData: {
    icon: IconType;
    text: string;
    route?: string;
    productVideoUrl: string | undefined;
    action?: () => void;
  }[];

  closeAllPopovers: () => void;
}

const ResourceCenterButton: React.FC<ResourceCenterButtons> = ({ ButtonData, closeAllPopovers }) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [ProductVideoOpen, setProductVideoOpen] = useState(false);
  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };
  const toggleShowProductVideo = (productVideoUrl: string | undefined) => {
    setProductVideoOpen(!ProductVideoOpen);
    setProductVideoUrl(productVideoUrl); // assuming setProductVideoUrl is a state updater function
  };

  const [productVideoUrl, setProductVideoUrl] = useState<string | undefined>(undefined);
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => setPopoverOpen(false),
  });

  const handleOptionClick = (option) => {
    setPopoverOpen(false);
  };
  console.log(productVideoUrl);
  return (
    <>
      {isPopoverOpen && (
        // <div ref={ref}>
        //   {/* <ResourceCenter
        //     isOpen={isPopoverOpen}
        //     onClose={() => setPopoverOpen(false)}
        //     closeAllPopovers={closeAllPopovers}
        //     handleOptionClick={handleOptionClick}
        //     isProductTour={true}
        //   /> */}
        // </div>
        <div ref={ref}>
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-20">
            <CustomModal
              closable={true}
              size="sm"
              title={"First step of creating your trip"}
              isOpen={true}
              modalContentProps={{ minH: "72vh", minW: "72vw" }}
              onClose={() => togglePopover()}
            >
              <div className="relative" style={{ paddingBottom: "40%", maxHeight: "50vh" }}>
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src={productVideoUrl}
                  title="General step trip"
                  frameBorder="0"
                  referrerPolicy="unsafe-url"
                  allowFullScreen={true}
                  allow="clipboard-write"
                  sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                ></iframe>
              </div>
            </CustomModal>
          </div>
        </div>
      )}

      <Grid templateColumns="repeat(3, 1fr)" justifyContent="center" alignItems="center">
        {ButtonData.map(({ icon, text, route, productVideoUrl, action }, index) => (
          <GridItem key={index}>
            <Link
              as={ReactLink}
              to={route ? route : undefined}
              onClick={() => {
                if (text === "Chat" && action) {
                  action();
                }
                closeAllPopovers();
              }}
              variant="unstyled"
              w="100%"
            >
              <a
                {...(route && route.includes("canny") ? { "data-canny-link": true } : {})}
                href={route ? route : "#"}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", width: "100%" }}
              >
                <Flex
                  as="button"
                  type="button"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  _hover={{ "& > span > svg": { transform: "scale(1.1)" }, "& > p": { color: "lato.primaryColor" } }}
                  color={"lato.primaryColor"}
                  bg={isPopoverOpen && text === "Product Videos" ? "realGray.100" : "white"}
                  w="80%"
                  minW="80%"
                  maxW="80%"
                  py={1}
                  my={1}
                  mx="auto"
                  borderRadius="md"
                >
                  <span style={{ position: "relative" }} className="w-full">
                    <Icon as={icon} width="50%" height="auto" style={{ transition: "transform 0.1s ease-in-out" }} />
                  </span>
                  <Text textAlign="center" fontSize={["xs", "xs", "xs", "xs", "xs", "sm"]} color={"realGray.700"}>
                    {text}
                  </Text>
                </Flex>
              </a>
            </Link>
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
export default ResourceCenterButton;
