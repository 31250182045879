export class Queue<T> {
  private items: T[];
  private readonly maxSize: number;

  constructor(initialItems?: T[], maxSize: number = 20) {
    this.items = [];
    this.maxSize = maxSize;
    if (initialItems) {
      initialItems.forEach((item) => this.push(item));
    }
  }

  // Adds an element to the top of the stack
  // If the element is already in the stack, it is removed first
  // If the stack is at max capacity, the last element is removed before adding the new element
  push(element: T): void {
    this.remove(element);
    if (this.items.length >= this.maxSize) {
      this.items.shift(); // Remove the bottom (first) element
    }
    this.items.push(element);
  }

  // Removes and returns the top element from the stack
  pop(): T | undefined {
    if (this.isEmpty()) {
      return undefined;
    }
    return this.items.pop();
  }

  // Returns the top element without removing it
  peek(): T | undefined {
    if (this.isEmpty()) {
      return undefined;
    }
    return this.items[this.items.length - 1];
  }

  // Checks if the stack is empty
  isEmpty(): boolean {
    return this.items.length === 0;
  }

  // Returns the number of elements in the stack
  size(): number {
    return this.items.length;
  }

  // Clears all elements from the stack
  clear(): void {
    this.items = [];
  }

  // Checks if the element exists in the stack
  contains(element: T): boolean {
    return this.items.includes(element);
  }

  // Removes an element from the stack if it exists
  remove(element: T): void {
    const index = this.items.indexOf(element);

    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }

  // Returns a string representation of the stack
  toString(): string {
    return this.items.join(",");
  }
}
