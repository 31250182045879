import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "languages";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

const getAllTranslatable = () => {
  return rawApiProvider.get(url + `/translatable`);
};

type LanguagesAPIType = ReturnEndpoints & {
  getAllTranslatable(): Promise<any>;
};
const LanguagesAPI: LanguagesAPIType = {
  getAllTranslatable,
  ...apiEndpoints,
};
export default LanguagesAPI;
