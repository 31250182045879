import { Box, Center, Flex, GridItem, Heading, IconButton, Input, Spacer, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { Trip } from "@lato/common";

interface ChatTitleLayoutProps {
  trip: Trip | undefined;
}

const ChatTitleLayout: React.FC<ChatTitleLayoutProps> = ({ trip }) => {
  return (
    <Center p={2}>
      {trip ? (
        <Box>
          {/* <Heading size="md" textAlign="center">
            {trip.titles[0].content}
          </Heading> */}
          {/* <Heading size="sm" textAlign="center">
            {trip.client_name}
          </Heading> */}
          <Flex></Flex>
        </Box>
      ) : (
        <Heading textAlign="center">New Message</Heading>
      )}
    </Center>
  );
};
export default React.memo(ChatTitleLayout);
