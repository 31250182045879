import React from "react";
// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/a11y/a11y.scss";
// import "swiper/components/controller/controller.scss";
// Custom swiper styles
import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, Image, Skeleton } from "@chakra-ui/react";
import {
  ImageSite,
  IsImage,
  PEXELS_PHOTO_BASE_URL,
  PEXELS_VIDEO_BASE_URL,
  PIXABAY_PHOTO_BASE_URL,
  PIXABAY_VIDEO_BASE_URL,
  UNSPLASH_PHOTO_BASE_URL,
  transformImage,
} from "@lato/common";
import { FaPause as Pause, FaPlay as Play } from "react-icons/fa";
import { imageNoPreviewError } from "../../features/external-images/ImageUploadModal";
import { S3_BASE_URL } from "../../utils/constants";

type ExtraPictureProps = {
  image: any;
  removeImage: undefined | ((i: number) => void);
  i: number;
  imageSize?: "thumb" | "small" | "regular";
  first?: boolean;
  autoplayVideo?: boolean;
  showGradient?: boolean;
};
const ExtraPicture: React.FC<ExtraPictureProps> = ({
  image,
  removeImage,
  i,
  first,
  imageSize = "small",
  autoplayVideo = false,
  showGradient = false,
}) => {
  const im = image;
  const imageURL = im
    ? transformImage(im, { size: imageSize })
    : "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png";
  const [retries, setRetries] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState(true);
  // if (im.file == undefined && image.site !== ImageSite.UNSPLASH) {
  //   imageURL = constructImageUrl(im);
  // }

  const [paused, setPaused] = React.useState<boolean>(true);
  const ref = React.useRef<HTMLVideoElement>(null);

  const handleRemoveImage = () => {
    // Remove the object URL
    if (im?.file != undefined) {
      // Revoke the url of the image
      URL.revokeObjectURL(im.url!);
    }
    removeImage!(i);
  };

  const imageLoadingError = (e: any) => {
    if (!im.s3 || !im.s3.key || im.s3.key === "") {
      e.currentTarget.parentElement!.innerHTML = imageNoPreviewError;
    } else {
      e.currentTarget.src = im.url && im.url !== "" && retries < 5 ? im.url : "/fallBackImage.png";
    }
    setRetries(retries + 1);
  };

  const autoplayProps = autoplayVideo ? { autoPlay: true, loop: true } : {};

  return (
    <Box
      pos="relative"
      w="100%"
      h="100%"
      bgColor={"gray.200"}
      fontSize={"sm"}
      borderRadius={"lg"}
      overflow={"hidden"}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
    >
      {im && !IsImage(im.site) ? (
        <>
          {im.site !== ImageSite.CUSTOMVIDEO && !autoplayVideo && (
            <Icon
              boxSize={6}
              color="white"
              as={paused ? Play : Pause}
              position="absolute"
              cursor="pointer !important"
              zIndex={10}
              onClick={(e) => {
                if (paused) {
                  (ref.current as any).play();
                  setPaused(false);
                } else {
                  (ref.current as any).pause();
                  setPaused(true);
                }
              }}
            />
          )}
          {im.site === ImageSite.CUSTOMVIDEO ? (
            <iframe title={`image-${i}`} src={imageURL} style={{ height: "100%", objectFit: "cover" }}></iframe>
          ) : (
            <video
              muted
              // Doesn't work on Safari
              // preload="metadata"
              disablePictureInPicture
              {...autoplayProps}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              ref={ref}
            >
              <source src={imageURL} />
            </video>
          )}
        </>
      ) : (
        <>
          {isLoading && <Skeleton m="auto" h="100%" w="100%" />}
          <Image
            display={isLoading ? "none" : "block"}
            objectFit="cover"
            h="100%"
            w="100%"
            borderRadius="lg"
            src={imageURL}
            onError={imageLoadingError}
            onLoad={() => setIsLoading(false)}
            className="scale-100 group-hover:scale-110 transition-all duration-300 ease-in-out"
            // Add overlay gradient
          ></Image>
          {showGradient && (
            <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-[#004354] to-transparent rounded-lg pointer-events-none" />
          )}
        </>
      )}
      {/* The close button to remove the image */}
      {removeImage && (
        <Flex
          display={first ? "flex" : "none"}
          pos="absolute"
          right={1}
          top={1}
          as="button"
          type="button"
          borderRadius="full"
          w="20px"
          h="20px"
          bg="gray.200"
          alignItems="center"
          justifyContent="center"
          transition="ease 0.3s"
          _hover={{
            bg: "gray.300",
          }}
          onClick={handleRemoveImage}
        >
          <CloseIcon fontSize="9px" />
        </Flex>
      )}
    </Box>
  );
};

interface VideoComponentProps {
  url: string;
  ref: any;
}

const VideoComponent: React.FC<VideoComponentProps> = ({ url, ref }) => {
  // only renders if url have changed!
  return (
    <video
      muted
      height="100%"
      disablePictureInPicture
      preload="metadata"
      style={{ maxWidth: "none", height: "100%" }}
      ref={ref}
    >
      <source src={url} />
    </video>
  );
};

export const imageBaseUrl: { [key in ImageSite | string]: any } = {
  [ImageSite.UNSPLASH]: UNSPLASH_PHOTO_BASE_URL,
  [ImageSite.S3]: S3_BASE_URL,
  [ImageSite.PEXELS]: PEXELS_PHOTO_BASE_URL,
  [ImageSite.PEXELSVIDEO]: PEXELS_VIDEO_BASE_URL,
  [ImageSite.PIXABAY]: PIXABAY_PHOTO_BASE_URL,
  [ImageSite.PIXABAYVIDEO]: PIXABAY_VIDEO_BASE_URL,
};

export const constructImageUrl = (image: ImageType) => {
  if (image.site === ImageSite.CUSTOMVIDEO || image.site === ImageSite.CUSTOMPICTURE) {
    return image.s3?.key;
  }
  return imageBaseUrl[image.site] + image.s3?.key;
};

export default React.memo(ExtraPicture);
