import getApiEndpoints from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "images/external";

// Get the standard CRUD operations
const basicUnsplashEndpoints = getApiEndpoints({
  url,
});

const getPhotos = (queryString: string) => {
  return rawApiProvider.get(url + `?${queryString}`);
};

// Function used to abide by the Unsplash guidelines.
const trackDownload = (track_download_url: string) => {
  return rawApiProvider.post(url + "/track-download", {
    url: track_download_url,
  });
};

type ImagesExternalAPIType = {
  getPhotos(queryString: string): Promise<any>;
  trackDownload(track_download_url: string): Promise<any>;
};
const ImagesExternalAPI: ImagesExternalAPIType = {
  getPhotos,
  trackDownload,
};
export default ImagesExternalAPI;
