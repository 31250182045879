function recursivelyReplaceObjectValues<T>(
  object: any,
  // Should replace is a callback which takes in a key and value.
  // It should return either a new value or the same value in case it should not be updated.
  shouldReplace: (prop: any, obj: any) => any,
): T {
  return Array.isArray(object)
    ? object.map((i) => recursivelyReplaceObjectValues(i, shouldReplace))
    : object &&
      typeof object === "object" &&
      !(object instanceof Date) &&
      // File and Blob types not available on server but also not necessary.
      !(object instanceof Blob)
    ? Object.fromEntries(
        Object.entries(object).map(([k, v]) => {
          // Get the value returned from the callback
          const nv = shouldReplace(k, v);
          return [
            k,
            // If the returned value is the same as the given value than nothing is replaced and hence further recursively search on this value.
            v !== nv ? nv : recursivelyReplaceObjectValues(v, shouldReplace),
          ];
        }),
      )
    : object;
}
// function recursivelyRemoveObjectValuesHelper(
//   obj: any,
//   shouldDelete: (prop: any, obj: any) => boolean,
// ) {
//   for (const prop in obj) {
//     if (shouldDelete(prop, obj)) delete obj[prop];
//     else if (typeof obj[prop] === 'object')
//       recursivelyRemoveObjectValuesHelper(obj[prop], shouldDelete);
//   }
// }
function recursivelyRemoveObjectValues<T>(obj: any, shouldDelete: (prop: any, obj: any) => boolean): T {
  // const clonedObj = { ...obj };
  // recursivelyRemoveObjectValuesHelper(clonedObj, shouldDelete);
  // return clonedObj;
  if (Array.isArray(obj)) {
    const a: any = [];
    obj.forEach((_, i) => {
      if (!shouldDelete(i, obj)) {
        if (
          obj[i] &&
          typeof obj[i] === "object" &&
          !(obj[i] instanceof Date) &&
          // File and Blob types not available on server but also not necessary.
          !(obj[i] instanceof Blob)
        ) {
          a.push(recursivelyRemoveObjectValues(obj[i], shouldDelete));
        } else {
          a.push(obj[i]);
        }
      }
    });
    return a;
  }
  const newObj: any = {};
  for (const prop in obj) {
    if (!shouldDelete(prop, obj)) {
      if (
        obj[prop] &&
        typeof obj[prop] === "object" &&
        !(obj[prop] instanceof Date) &&
        // File and Blob types not available on server but also not necessary.
        !(obj[prop] instanceof Blob)
      ) {
        newObj[prop] = recursivelyRemoveObjectValues(obj[prop], shouldDelete);
      } else {
        newObj[prop] = obj[prop];
      }
    }
  }
  return newObj;
}
export { recursivelyReplaceObjectValues, recursivelyRemoveObjectValues };
