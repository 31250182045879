import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Trash2 as DeleteIcon } from "lucide-react";
import React from "react";
import { BiSolidImageAdd as ImageAdd } from "react-icons/bi";
import { IoResizeOutline as Resize } from "react-icons/io5";
import { CompanyBrand } from "@lato/common";
import CustomModal from "../../layout/CustomModal";
import ImageUploader from "../ImageUploader";

interface logoUploaderProps {
  brand: CompanyBrand;
  handleChooseImage: (blob: Blob) => void;
  handleChangeLogoScale: (newValue: number) => void;
  handleCancel: () => void;
  deleteLogo: () => void;
}

const LogoUploader: React.FC<logoUploaderProps> = ({ brand, handleChooseImage, handleChangeLogoScale, deleteLogo, handleCancel }) => {
  const modalDisclosure = useDisclosure();
  const submitLogo = async (cropper: Cropper) => {
    cropper.getCroppedCanvas().toBlob(function (blob) {
      if (blob) {
        // Save the image
        handleChooseImage(blob);
        // Open the modal to scale the logo
        modalDisclosure.onOpen();
      }
    }, "image/png");
  };

  const handleResize = (e: any) => {
    e.stopPropagation();
    modalDisclosure.onOpen();
  };

  const handleDeleteLogo = (e: any) => {
    e.stopPropagation();
    // await mutateAsync(null);
    deleteLogo();
  };

  return (
    <ImageUploader
      submitPicture={submitLogo}
      type="image"
      dropzone={false}
      modalExplanation="Please only select the most significant part of your logo. Crop the image such that it only contains the logo itself and as little whitespace around the logo as possible."
      zoomable={false}
    >
      <Flex
        w="100%"
        h="150px"
        p={2}
        borderRadius="md"
        // overflow="hidden"
        // bg="gray.200"
        borderColor="gray.200"
        borderWidth="2px"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        pos="relative"
      >
        {brand.logoUrl ? (
          <Box>
            <HStack pos="absolute" bottom={2} spacing={3} left={"11%"}>
              <Tooltip label="Resize logo">
                <IconButton
                  size="sm"
                  borderRadius="full"
                  aria-label="Resize company logo"
                  icon={<Icon as={Resize} />}
                  onClick={handleResize}
                />
              </Tooltip>
              <Tooltip label="Edit logo">
                <IconButton
                  size="sm"
                  borderRadius="full"
                  aria-label="Edit company logo"
                  icon={<EditIcon />}
                />
              </Tooltip>
              <Tooltip label="Delete logo">
                <IconButton
                  size="sm"
                  borderRadius="full"
                  aria-label="Delete company logo"
                  icon={<Icon as={DeleteIcon} color="red.500" />}
                  onClick={handleDeleteLogo}
                />
              </Tooltip>
            </HStack>
            <Image
              maxH={`calc(${brand.logoScale}*1.8em)`}
              src={brand.logoUrl}
              alt={`company-logo`}
              fallbackSrc="https://via.placeholder.com/200"
            ></Image>
          </Box>
        ) : (
          <>
            <Icon boxSize="50%" as={ImageAdd} />
            <Text fontSize="sm" textAlign="center">
              Click here to add your brand logo
            </Text>
          </>
        )}
      </Flex>
      <LogoScalingModal
        disclosure={modalDisclosure}
        imageSrc={brand.logoUrl}
        defaultScale={brand.logoScale}
        handleChangeLogoScale={handleChangeLogoScale}
        handleCancel={handleCancel}
      />
    </ImageUploader>
  );
};
export default React.memo(LogoUploader);

const LogoScalingModal: React.FC<any> = ({ disclosure, imageSrc, isLoading, defaultScale, handleChangeLogoScale, handleCancel }) => {
  const maxScale = 2.5;
  const minScale = 0.75;
  const defaultLogoScale = ((defaultScale - minScale) / (maxScale - minScale)) * 100;
  const [logoScale, setLogoScale] = React.useState(defaultLogoScale);
  // logoScale varies from 0 to 100.
  const { isOpen, onClose } = disclosure;
  const defaultLogoHeight = "25px";

  const maxHeight = `calc(${defaultLogoHeight}*${maxScale})`;
  const realValue = minScale + (logoScale / 100) * (maxScale - minScale);

  const onSaveScaling = () => {
    const rounded = Math.round((realValue + Number.EPSILON) * 100) / 100;
    handleChangeLogoScale(rounded);
    onClose();
  };

  const onCancel = () => {
    handleCancel();
    onClose();
  };

  React.useEffect(() => {
    setLogoScale(((defaultScale - minScale) / (maxScale - minScale)) * 100);
  }, [defaultScale, setLogoScale]);

  return (
    <CustomModal title="Scale logo" isOpen={isOpen} onClose={onClose}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Flex flexDir="column" alignItems="center">
          <Text>
            Use the slider to scale your logo up or down. Please make sure the uploaded image only contains the logo
            itself and as little whitespace around the logo as possible.{" "}
          </Text>
          <Flex alignItems={"center"} justifyContent="center" w="100%" h={maxHeight} my={3}>
            <Image src={imageSrc} h={`calc(${defaultLogoHeight}*${realValue})`} border="1px solid darkgrey" />
          </Flex>
          <Slider
            colorScheme="brand"
            aria-label="slider-company-logo"
            w="60%"
            value={logoScale}
            onChange={setLogoScale}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb
              _focus={{
                borderColor: "gray.100",
                borderWidth: "1px",
              }}
            />
          </Slider>
          <ButtonGroup ml="auto" mt={5}>
            <Button colorScheme="red" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button colorScheme="brand" onClick={onSaveScaling}>
              Confirm
            </Button>
          </ButtonGroup>
        </Flex>
      )}
    </CustomModal>
  );
};
