import { Box, Flex, Tooltip } from "@chakra-ui/react";
import {
  ENABLE_DESTINATIONS_IN_OVERVIEW,
  TranslationField,
  TripDay,
  UserTrip,
  calculateTripdayDate,
  getDistance,
} from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { CellProps, Column } from "react-table";
import DestinationsAPI from "../../../api/destinations.api";
import TripdaysAPI from "../../../api/tripdays.api";
import { useTripLanguage } from "../../../stores/trip-language-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import {
  DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY,
  DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY,
  GENERAL_STEP_USERTRIP_QUERY_KEY,
} from "../../../utils/constants";
import { calculateEndDate, prettyPrintDate } from "../../../utils/date";
import { differenceInNumberOfDays, totalNumberOfDays } from "../../../utils/differenceInNumberOfDays";
import {
  updateRelationMap,
  useAddTripdays,
  usePatchSplitUserTrip,
  useRemoveTripday,
  useTripTranslations,
} from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import CRUDResource from "../../../components/CRUD/Resource";
import RHFDayPicker from "../../../components/input/date/RHFDayPicker";
import { CustomSpinner } from "../../../components/FullScreenSpinner";
import LocationInput from "../../../components/input/LocationInput";
import RHFSelect from "../../../components/input/RHFSelect";
import { afterChooseElementPictures } from "../../../components/trips/edit/daybyday/library-items/activities/afterChooseElementPictures";
import { getWikiContentByLocation } from "../../../api/wikivoyage.api";

interface TripsOverviewTableProps {
  extended: boolean;
  selectedRow: string | undefined;
  setSelectedRow: React.Dispatch<React.SetStateAction<string | undefined>>;
  sample: boolean;
  isFirst: boolean;
  isUpdatingTrip: boolean;
  userTrip: UserTrip;
  handleSubmit: () => Promise<void>;
}

export const calculateDayNumber = (index: number, tripdays: { nrOfNights: number }[]) => {
  return [...Array(index).keys()].map((i) => tripdays[i]?.nrOfNights ?? 0).reduce((a, b) => +a + +b, 0);
};

const TripsOverviewTable: React.FC<TripsOverviewTableProps> = ({
  sample,
  isFirst,
  isUpdatingTrip,
  userTrip,
  handleSubmit,
}) => {
  const { trip, setInTripStore } = useTripFormStore();
  const queryClient = useQueryClient();
  const { realLanguageIndex } = useTripLanguage();

  const { data: tripTranslations } = useTripTranslations(trip.id);

  const { mutateAsync: updateUserTrip } = usePatchSplitUserTrip(
    userTrip.id!,
    updateRelationMap[DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY],
    queryClient,
    DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY,
  );

  const { mutateAsync: addTripdays } = useAddTripdays(trip.id, queryClient);
  const { mutateAsync: removeTripday } = useRemoveTripday(trip.id, queryClient);

  const {
    control,
    setValue,
    getValues,
    reset,
    //trigger,
    formState: { errors },
  } = useFormContext<any>();
  const {
    fields: tripdays,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: "tripdays" as const,
    keyName: "rhfId", // default to "id", you can change the key name
  });
  const tripdaysWatch: TripDay[] = useWatch({ name: "tripdays" });

  // Used to dynamically set the dayNumber
  const appendTripday = async (n: number) => {
    // Make sure the previous last tripday now gets an increased nrOfNights.
    // if (tripdaysWatch.length > 0) {
    //   setValue(
    //     `tripdays.${tripdaysWatch.length - 1}.nrOfNights`,
    //     tripdaysWatch[tripdaysWatch.length - 1].nrOfNights + 1,
    //   );
    // }
    // Build the list of the new tripdays where the last tripday should have 0 number of nights.

    await handleSubmit();
    await addTripdays(n);

    // Append the new tripdays with 0 number of nights.
    //append(newTripdays);
    // TODO FIX: This triggers all 'tripdays' validation meaning both the tripdays array length but also individual tripdays.
    //trigger("tripdays");
  };

  const start_date: string | null = useWatch({
    name: "start_date",
  });
  const nr_of_nights = tripdaysWatch?.map((td) => td.nrOfNights).reduce((partialSum, a) => partialSum + a, 0);
  const nr_of_days = nr_of_nights + 1;
  const end_date = start_date && nr_of_days ? calculateEndDate(start_date, Math.max(0, nr_of_days - 1)) : null;
  const realNrOfNights =
    !start_date || !end_date
      ? null
      : Math.max(0, differenceInNumberOfDays(new Date(start_date), new Date(end_date)) - 1);
  const nrOfNights = Math.max(0, totalNumberOfDays(tripdaysWatch) - 1);

  const columns: Column<TripDay>[] = React.useMemo(
    () => [
      {
        id: "nrOfNights",
        accessor: "nrOfNights",
        Header: `${nrOfNights} nights`,
        Cell: ({ value, row, column }) => {
          return value === 0 ? (
            <div className="w-full">&nbsp;&nbsp;&nbsp;&nbsp;0</div>
          ) : (
            <RHFSelect
              name={`tripdays.${row.index}.${column.id}`}
              options={[...Array(50).keys()].map((i) => ({ value: i + 1 }))}
              // onChange={(e) => {
              //   setInTripStore(`tripdays.${row.index}.${column.id}`, +e.target.value);
              //   //trigger("tripdays");
              // }}
              w="max-content"
              isDisabled={!isFirst || isUpdatingTrip}
            />
          );
        },
      },
      {
        accessor: "location",
        Header: "City",
        chakraWidth: "100%",
        Cell: ({ value, row }) => {
          return (
            <Box w="100%">
              <LocationInput
                i={row.index}
                //trigger={trigger}
                // defaultValue={value}
                disabled={!isFirst || isUpdatingTrip}
                onChange={async (locationName: any) => {
                  const distances = getValues(`distances`);
                  distances && distances[row.index] && delete distances[row.index];
                  if (row.index - 1 >= 0 && distances && distances[row.index - 1]) {
                    delete distances[row.index - 1];
                  }

                  const updateTripday = async (tripday: TripDay) => {
                    if (row?.original?.id) {
                      await TripdaysAPI.patch(row.original.id, tripday, {
                        relation: "tripday",
                        subRelations: [
                          {
                            relation: "image",
                          },
                          { relation: "libraryImage" },
                        ],
                      });
                      await queryClient.invalidateQueries({
                        queryKey: [DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY, row.original.id],
                      });
                    }
                  };

                  setValue(`distances`, distances);

                  if (ENABLE_DESTINATIONS_IN_OVERVIEW) {
                    const tripdayId = getValues(`tripdays.${row.index}.id`);
                    if (tripdayId) {
                      const { destination } = await DestinationsAPI.getDestinationByTripdayId(tripdayId);
                      if (destination?.id) {
                        const locationContent = await getWikiContentByLocation(
                          locationName,
                          true,
                          trip.userTrips[trip.userTrips.length - 1].brand.content_ai_preference_sentence,
                          tripTranslations?.priceDescriptions[realLanguageIndex].language_code,
                        );
                        if (locationContent || !locationName) {
                          const newDescriptions = (destination.descriptions ?? []).map(
                            (description: TranslationField, i: number) => ({
                              ...description,
                              content:
                                i === realLanguageIndex &&
                                (description.content === "" ||
                                  description.content === "<p></p>" ||
                                  description.content.includes('<meta property="mw:PageProp/toc">'))
                                  ? !locationName
                                    ? ""
                                    : (locationContent?.description ?? "")
                                  : description.content,
                            }),
                          );

                          const newDestination = {
                            ...destination,
                            descriptions: newDescriptions,
                            images: locationContent?.images ?? [],
                          };

                          locationContent &&
                            !row.original.image &&
                            !row.original.libraryImage &&
                            afterChooseElementPictures(
                              locationContent?.images as any,
                              row?.id,
                              updateTripday,
                              false,
                              row.index,
                              setInTripStore,
                            );

                          const destinationResponse = await DestinationsAPI.patch(destination.id, newDestination);

                          await queryClient.setQueryData(
                            [DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId],
                            destinationResponse,
                          );
                        }
                      }
                    }
                  }
                }}
              />
            </Box>
          );
        },
      },
      {
        accessor: (row) =>
          row.hotels?.length > 0
            ? row.hotels[0].name + (row.hotels.length === 1 ? "" : ` + ${row.hotels.length - 1}`)
            : "",
        Header: "Accommodation",
        Cell: ({ value }: any) => <>{value}</>,
        chakraWidth: "35%",
        // isDisabled: !first,
        // skipLast: true,
        inlineEdit: false,
        // placeholder: "Marriott Hotel",
      },
    ],
    [isFirst, nrOfNights, appendTripday /*, trigger*/, reset],
  );

  const distances = useWatch({ name: `distances` });

  const distanceColumn = useMemo(() => {
    return {
      Header: "Distance",
      chakraWidth: "100%",
      Cell: ({ row, value }: any) => {
        const distances = getValues(`distances`);
        if (!distances || row.index >= distances.length || distances.length === 0 || !distances[row.index]) return "";
        const distance = distances[row.index].reduce((a: number, b: number) => a + b, 0);
        return <Box w="100%">{getDistance(distance, 0)}</Box>;
      },
    };
  }, [isFirst, nrOfNights, appendTripday, distances, reset]);

  const updateHomeBackgroundNumber = async (number: number) => {
    await updateUserTrip({
      id: userTrip?.id,
      homeBackgroundNumber: number,
    });

    await queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_USERTRIP_QUERY_KEY, userTrip?.id] });
  };

  const handleBackgroundNumber = React.useCallback(
    async (fromIndex: number, toIndex: number) => {
      let newIndex = userTrip.homeBackgroundNumber;
      if (fromIndex === userTrip.homeBackgroundNumber) {
        newIndex = toIndex;
      } else if (fromIndex < userTrip.homeBackgroundNumber && toIndex >= userTrip.homeBackgroundNumber) {
        newIndex--;
      } else if (fromIndex > userTrip.homeBackgroundNumber && toIndex <= userTrip.homeBackgroundNumber) {
        newIndex++;
      }
      await updateHomeBackgroundNumber(newIndex);
    },
    [updateHomeBackgroundNumber, userTrip.homeBackgroundNumber],
  );

  const onDelete = async (index: number) => {
    remove(index);
    setInTripStore(`tripdays.${index}.image`, null);
    setInTripStore(`tripdays.${index}.libraryImage`, null);
    setValue(`distances`, []);
    if (userTrip && index < userTrip.homeBackgroundNumber) {
      const newIndex = userTrip.homeBackgroundNumber - 1;
      updateHomeBackgroundNumber(newIndex);
    }
    await handleSubmit();
    await removeTripday(tripdays[index].id);
    //trigger("tripdays");
  };

  const onMoveTripday = React.useCallback(
    async (fromIndex: number, toIndex: number) => {
      // Move the main tripdays
      move(fromIndex, toIndex);
      setValue(`distances`, []);
      if (userTrip) {
        handleBackgroundNumber(fromIndex, toIndex);
      }
      await handleSubmit();
    },
    [move, reset, getValues, userTrip.homeBackgroundNumber],
  );

  const calculateTripdayDateByIndex = (index: number) => {
    const realDayNumber = calculateDayNumber(index, tripdaysWatch) ?? 0;
    let result = `${realDayNumber + 1}`;
    if (start_date) {
      const d = calculateTripdayDate(realDayNumber, start_date);
      if (d) {
        result += " • " + format(new Date(d), "eee d MMM");
      }
    }
    return result;
  };

  const dateColumn: Column<TripDay> = React.useMemo(
    () => ({
      id: "day",
      Header: `${nrOfNights + 1} ${nrOfNights > 0 ? "days" : "day"}`,
      accessor: (_, index) => {
        // Calculate the actual day number of this tripday record
        return calculateTripdayDateByIndex(index);
      },
      inlineEdit: false,
      isTruncated: false,
      // Cell: (props) => <EditableCell {...props} noInputBorder={false} />,
    }),
    [start_date, nrOfNights, realNrOfNights, tripdaysWatch],
  );
  // Insert the date column at index 1, after the day number
  // columns.splice(1, 0, dateColumn);
  const basicColumns = React.useMemo(() => {
    // columns.splice(1, 0, dateColumn);
    return [dateColumn, ...columns];
  }, [columns, dateColumn]);

  const disableLastRowDeleteAndDnd = ({ row }: CellProps<TripDay>) => {
    return row.original.nrOfNights === 0;
  };

  if (!tripdays) return <CustomSpinner m="auto" />;

  const addDaysButtonClassName =
    "whitespace-nowrap inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10";
  return (
    <CRUDResource
      disableSorting
      formName="tripdays"
      displayName="days"
      data={tripdays}
      columns={[...basicColumns, distanceColumn] as Column<any>[]}
      handleDelete={isFirst ? onDelete : undefined}
      disableIf={disableLastRowDeleteAndDnd}
      lastDisabled={true}
      moveRow={isFirst ? onMoveTripday : undefined}
      inlineEdit={true}
      //@ts-ignore
      error={errors.tripdays?.message}
      heading={
        <Flex w="100%" mb={2}>
          {
            <Flex w="100%" alignItems={"center"} justifyContent={"space-between"}>
              <Flex alignItems={"center"} gap={2}>
                {!sample && (
                  <RHFDayPicker name={`start_date`} width={"fit-content"} header={<>Select a start date</>} />
                )}

                <button onClick={() => appendTripday(1)} disabled={isUpdatingTrip} className={addDaysButtonClassName}>
                  + 1
                </button>
                <button onClick={() => appendTripday(3)} disabled={isUpdatingTrip} className={addDaysButtonClassName}>
                  + 3
                </button>
                <button onClick={() => appendTripday(7)} disabled={isUpdatingTrip} className={addDaysButtonClassName}>
                  + 7
                </button>
                <button onClick={() => appendTripday(14)} disabled={isUpdatingTrip} className={addDaysButtonClassName}>
                  + 14
                </button>
                {end_date && (
                  <Tooltip label="End date">
                    <p className="whitespace-nowrap hover:cursor-not-allowed inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      {prettyPrintDate(new Date(end_date), "en", "medium")}
                    </p>
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          }
        </Flex>
      }
    />
  );
};
export default TripsOverviewTable;
