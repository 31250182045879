import { QueryRelation } from "@lato/common";
import rawApiProvider from "./utilities/rawProvider";

const url = "trips-split";

// Get a trip with the temperatures
const getSplitTrip = (tripId: string, relations: string) => {
  return rawApiProvider.get(`${url}/${tripId}?relation=` + relations);
};

const getSplitTripByUserTripId = (userTripId: string, relations: string, prisma: boolean = false) => {
  return rawApiProvider.get(`${url}/userTrip/${userTripId}?relation=` + relations + `&prisma=${prisma}`);
};

const patchSplitTrip = (id: string, trip: any, relations: QueryRelation) => {
  return rawApiProvider.patch(`${url}/${id}`, { updateTripDTO: trip, relation: relations });
};
type TripsAPIType = {
  getSplitTrip(id: string, relations: string): Promise<any>;
  getSplitTripByUserTripId(id: string, relations: string, prisma?: boolean): Promise<any>;
  patchSplitTrip(id: string, trip: any, relations: QueryRelation): Promise<any>;
};
const TripsSplitAPI: TripsAPIType = {
  getSplitTrip,
  getSplitTripByUserTripId,
  patchSplitTrip,
};
export default TripsSplitAPI;
