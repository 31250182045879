import { BoxProps } from "@chakra-ui/react";
import { AI_TRIP_CONVERSIONS_PRICE_PER_UNIT, User } from "@lato/common";
import { subDays } from "date-fns";
import React from "react";
import { prettyPrintDate } from "../../../utils/date";
import { useMyTeam } from "../../../utils/query-helpers/reactQueryHooks";
import FullScreenSpinner from "../../FullScreenSpinner";
import ErrorCardView from "../../layout/ErrorCardView";
import Section from "../../layout/Section";

interface AdditionalUsageProps extends BoxProps {}

const AdditionalUsage: React.FC<AdditionalUsageProps> = (props) => {
  const { data, isLoading: isLoadingMembers, error: errorMembers } = useMyTeam();
  const members: User[] = data;

  if (isLoadingMembers) return <FullScreenSpinner />;
  if (errorMembers || !members) return <ErrorCardView title="Could not load data" />;

  const hasAdditionalUsage = members.some((user) => user.ai_trip_conversions.paid_used > 0);

  if (!hasAdditionalUsage) return null;

  const total = members.reduce(
    (acc, user) => acc + user.ai_trip_conversions.paid_used * AI_TRIP_CONVERSIONS_PRICE_PER_UNIT,
    0,
  );

  const today = new Date();
  const quarter = Math.floor(today.getMonth() / 3);
  const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
  const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);

  return (
    <Section title="Additional usage" h="auto" {...props}>
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 ">AI PDF import usage</h1>
            <p className="mt-2 text-sm text-gray-700">
              from {prettyPrintDate(startFullQuarter, "en", "long")} to {prettyPrintDate(endFullQuarter, "en", "long")}
            </p>
          </div>
          {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Print
            </button>
          </div> */}
        </div>
        <div className="-mx-4 mt-4 flow-root sm:mx-0">
          <table className="min-w-full">
            <colgroup>
              <col className="w-full sm:w-1/2" />
              <col className="sm:w-1/6" />
              <col className="sm:w-1/6" />
              <col className="sm:w-1/6" />
            </colgroup>
            <thead className="border-b border-gray-300 ">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold  sm:pl-0">
                  Name
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold  sm:table-cell">
                  # imports
                </th>
                <th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold  sm:table-cell">
                  Unit price
                </th>
                <th scope="col" className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold  sm:pr-0">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {members
                .filter((user) => user.ai_trip_conversions.paid_used > 0)
                .map((user) => (
                  <tr key={`pdf-reader-user-${user.id}`} className="border-b border-gray-200">
                    <td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                      <div className="font-medium ">{user.name}</div>
                      {/* <div className="mt-1 truncate text-gray-500">{project.description}</div> */}
                    </td>
                    <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                      {user.ai_trip_conversions.paid_used}
                    </td>
                    <td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
                      € {AI_TRIP_CONVERSIONS_PRICE_PER_UNIT}
                    </td>
                    <td className="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                      € {AI_TRIP_CONVERSIONS_PRICE_PER_UNIT * user.ai_trip_conversions.paid_used}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              {/* <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                >
                  Subtotal
                </th>
                <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                  Subtotal
                </th>
                <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-0">$8,800.00</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                >
                  Tax
                </th>
                <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                  Tax
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-0">$1,760.00</td>
              </tr> */}
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold  sm:table-cell sm:pl-0"
                >
                  Total (excl. TAX)
                </th>
                <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold  sm:hidden">
                  Total
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold  sm:pr-0">€ {total}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Section>
  );
};

export default React.memo(AdditionalUsage);
