import { Badge, Box, Flex, HStack, Heading, Text, useRadioGroup } from "@chakra-ui/react";
import { Plan, SHOW_OPTIONS, SHOW_OPTIONS_TextMap, TRIP_STATUS } from "@lato/common";
import React from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useMeContext } from "../../../../../stores/me-context";
import ENV from "../../../../../utils/env";
import { useLanguages } from "../../../../../utils/query-helpers/reactQueryHooks";
import FullScreenSpinner from "../../../../FullScreenSpinner";
import InputWrapper from "../../../../input/InputWrapper";
import RHFInput from "../../../../input/RHFInput";
import RHFRadio from "../../../../input/RHFRadio";
import RHFSelect from "../../../../input/RHFSelect";
import RHFSlider from "../../../../input/RHFSlider";
import RHFSwitch from "../../../../input/RHFSwitch";
import ErrorCardView from "../../../../layout/ErrorCardView";
import Section from "../../../../layout/Section";
import { RadioCard } from "../library-items/transportations/RadioCard";
import BrandPicker from "./BrandPicker";
import { tripStatusColorMap } from "../../../../../features/tripList/TripsList";
import { NewBadge } from "../../../../layout/FeatureBadges";

interface TripSettingsProps {
  isFirst: boolean;
  sample: boolean;
}

// General trip settings
// 1. 'Default content language' (see defaultTFLang and 'LanguageMenuItem' in 'LanguageSwitcher')
// 2. 'Default chat enabled'

// Specific trip settings
// 1. Language/localization settings: (also don't use flags for languages!)
//   1.1 Setting a default language (used when automatic detection is off or when no language is found)
//   1.2 Toggle automatic detection of language (if no language is detected or language not available -> use default language)
//   1.3 Choosing which languages the traveler may see (for example using a dropdown and displaying all the languages in the textfield as badges (see react-select))
// 2. Confirm trip setting
//   2.1 In the future allow 3 options per trip:
//        1. The traveller may not be able book his trip using Lato.
//        2. The traveller may book his trip using Lato by using a simple confirmation.
//        3. The traveller may book his trip using Lato by using a prepayment.
//   2.2 Group vs private trip: How many times a trip may be booked. Once this number is reached, the trip is toggled to booked.
//        - empty = infinite number of bookings allowed -> travel professional will need to set trip to booked manually
//        - x = trip can be booked 'x' times. Once 'x' has been reached, the trip is set to booked automatically.
// 3. Chat options: Only show the chat options for regular trips and not for samples!!!
//   3.1 May client chat?
//   3.2 Client may chat with whom?
// 4. Change trip status
// 5. Setting to decide whether the company and user information should be displayed. (Add help text that says that you can remove phone number from the settings if you don't want to show that only)
// 5. Which traveller layout
// 6. POI settings
//   6.1 Show POIs or not? (This option will be automatically become true once the trip is set to booked?)
//   6.1 Range to view POIs
//   6.2 Also show POI's of previous professional (default: true)
// 7. Setting to decided whether the traveller is able to pay or not
// 8. Setting to decided whether the next travel professional is able to edit all fields or only the price field. (When only able to edit the price field, the travel agent can not use other languages as the TO and the travel agent should be restricted to only editting the price field of those languages the TO used).

const TripSettings: React.FC<TripSettingsProps> = ({ isFirst, sample }) => {
  const me = useMeContext();
  const showSettings = {
    showBrands: me.company.brands.length >= 2,
    showchat_enabled: !sample,
    show_pois: !sample,
    show_pdf: !sample,
    show_documents: !sample,
    show_price: !sample,
    showTripStatus: !sample,
    showpoi_range: !sample,
    showCollaboratorCanEdit: !sample && isFirst && me.company.plan === Plan.PRO && ENV !== "staging",
    show_confirm_option: !sample,
    showgroup_booking: !sample,
    showChatMail: false,
    showbook_link: !sample,
    showmax_travellers: !sample,
    showbooked_travellers: !sample,
    show_defaults_show_price: false,
    show_default_show_directions: !sample,
    show_new_edittrip_layout: false,
    showPaymentSettings: !sample,
    showDaysAfterCancelled: !sample,
  };

  const chat_enable_form_name = `userTrips.${isFirst ? 0 : 1}.chat_enabled`;
  const show_pois_form_name = `userTrips.${isFirst ? 0 : 1}.show_pois`;
  const show_pdf_form_name = `userTrips.${isFirst ? 0 : 1}.show_pdf`;
  const show_documents_form_name = `userTrips.${isFirst ? 0 : 1}.show_documents`;
  const show_price_form_name = `userTrips.${isFirst ? 0 : 1}.show_price`;
  const trip_status_form_name = `userTrips.${isFirst ? 0 : 1}.status`;
  const poi_range_form_name = `userTrips.${isFirst ? 0 : 1}.poi_range`;
  const collaborator_form_name = `collaboratorCanEdit`;
  const show_confirm_option_form_name = `userTrips.${isFirst ? 0 : 1}.show_confirm_option`;
  const group_booking_name = `userTrips.${isFirst ? 0 : 1}.group_booking`;
  const book_link_name = `userTrips.${isFirst ? 0 : 1}.book_link`;
  const max_travellers_name = `userTrips.${isFirst ? 0 : 1}.max_travellers`;
  const booked_travellers_name = `userTrips.${isFirst ? 0 : 1}.booked_travellers`;
  const show_directions_form_name = `userTrips.${isFirst ? 0 : 1}.show_directions`;
  const daysAfterCancelledFieldName = `userTrips.${isFirst ? 0 : 1}.daysAfterTripCancelled`;

  // If there is no item in the showSettings object true, then don't show the settings.
  if (!Object.values(showSettings).some(Boolean)) return <></>;

  return (
    <Box mt={3} mb={[3, 5, 10]} height="100%">
      <Heading size="lg">Trip settings</Heading>
      <Text fontSize="md" color="realGray.600">
        Some settings for this specific trip.
      </Text>
      <Box m={0} w="100%" mt={3} height={"100%"}>
        <TripSettingsFields
          {...showSettings}
          chatEnableFormName={chat_enable_form_name}
          show_poisFormName={show_pois_form_name}
          show_pdfFormName={show_pdf_form_name}
          show_documentsFormName={show_documents_form_name}
          show_price_formName={show_price_form_name}
          show_directionsName={show_directions_form_name}
          tripStatusFormName={trip_status_form_name}
          poi_rangeFormName={poi_range_form_name}
          collaboratorCanEditName={collaborator_form_name}
          show_confirm_optionFormName={show_confirm_option_form_name}
          group_bookingName={group_booking_name}
          book_linkName={book_link_name}
          max_travellersName={max_travellers_name}
          booked_travellersName={booked_travellers_name}
          daysAfterCancelledFieldName={daysAfterCancelledFieldName}
          inTrip={true}
          defaultInputWidth={"8em"}
          sample={sample}
        />
      </Box>
    </Box>
  );
};
export default React.memo(TripSettings);

export const TripSettingHeading: React.FC<any> = ({ children, mt = 2, subText, inTrip, width = "auto" }) => (
  <Flex flexDir={"column"} w={{ base: "80%", sm: "auto" }}>
    {/* <Tooltip label={subText} isDisabled={false}> */}
    <Heading size="md" mb={1} ml={7} w={width} mt={mt}>
      {children}
    </Heading>
    {/* </Tooltip> */}
    {
      //subText && !inTrip && (
      <Text fontSize={"12px"} width={"18em"} mt="-0.3em" ml={7} color="realGray.400">
        {subText}
      </Text>
      /*)*/
    }
  </Flex>
);

interface TripSettingsFieldsProps {
  chatEnableFormName: string;
  show_poisFormName: string;
  show_documentsFormName: string;
  tripStatusFormName: string;
  poi_rangeFormName: string;
  collaboratorCanEditName: string;
  show_confirm_optionFormName: string;
  show_price_formName: string;
  show_directionsName: string;
  group_bookingName: string;
  chatMailName?: string;
  book_linkName?: string;
  max_travellersName?: string;
  booked_travellersName?: string;
  defaultlangName?: string;
  default_show_price_name?: string;
  daysAfterCancelledFieldName: string;
  showBrands: boolean;
  showchat_enabled: boolean;
  show_pois: boolean;
  show_pdf: boolean;
  show_documents: boolean;
  show_price: boolean;
  showTripStatus: boolean;
  showpoi_range: boolean;
  showCollaboratorCanEdit: boolean;
  show_confirm_option: boolean;
  showgroup_booking: boolean;
  showChatMail: boolean;
  showbook_link: boolean;
  showmax_travellers: boolean;
  showbooked_travellers: boolean;
  inTrip: boolean;
  defaultInputWidth: string;
  generalId?: string;
  automationsId?: string;
  chatId?: string;
  poiId?: string;
  pdfId?: string;
  documentsId?: string;
  showdefaultlang?: boolean;
  show_defaults_show_price: boolean;
  show_pdfFormName: string;
  show_default_show_directions: boolean;
  show_new_edittrip_layout: boolean;
  new_edittripName: string;
  sample?: boolean;
  showPaymentSettings: boolean;
  showDaysAfterCancelled: boolean;
  paymentsId?: string;
}

export const TripSettingsFields: React.FC<TripSettingsFieldsProps> = ({
  chatEnableFormName,
  show_poisFormName,
  show_pdfFormName,
  show_documentsFormName,
  tripStatusFormName,
  poi_rangeFormName,
  collaboratorCanEditName,
  show_confirm_optionFormName,
  show_price_formName,
  group_bookingName,
  chatMailName,
  book_linkName,
  max_travellersName,
  booked_travellersName,
  default_show_price_name,
  daysAfterCancelledFieldName,
  show_default_show_directions,
  show_new_edittrip_layout,
  defaultlangName = "",
  showBrands,
  showchat_enabled,
  show_pois,
  show_pdf,
  show_documents,
  show_price,
  showTripStatus,
  showpoi_range,
  showCollaboratorCanEdit,
  show_confirm_option,
  showgroup_booking,
  showChatMail,
  showbook_link,
  showmax_travellers,
  showbooked_travellers,
  showdefaultlang,
  show_defaults_show_price,
  show_directionsName,
  new_edittripName,
  inTrip,
  defaultInputWidth,
  generalId,
  automationsId,
  chatId,
  poiId,
  pdfId,
  documentsId,
  sample = false,
  showPaymentSettings,
  showDaysAfterCancelled,
  paymentsId,
}) => {
  const me = useMeContext();
  const { field: tripStatus } = useController({
    name: tripStatusFormName,
  });

  const { setValue, getValues } = useFormContext();

  const { getRadioProps } = useRadioGroup({
    name: "tripStatus",
    defaultValue: tripStatus.value as TRIP_STATUS,
    onChange: (value) => {
      tripStatus.onChange(value);
    },
  });
  const { data: allLanguages, isLoading: isLoadingLanguages, error: errorLanguages } = useLanguages();

  const show_poisValue = useWatch({ name: show_poisFormName });
  const groupBookingValue = useWatch({ name: group_bookingName });
  const max_travellersValue = useWatch({ name: max_travellersName ?? "" });
  const booked_travellersValue = useWatch({ name: booked_travellersName ?? "" });
  const cancel_afterdaysValue = useWatch({ name: daysAfterCancelledFieldName ?? "" });

  if (isLoadingLanguages) return <FullScreenSpinner />;
  if (!allLanguages || errorLanguages) return <ErrorCardView title="Could not load languages" />;

  return (
    <Flex flexDir="column" gap={3}>
      <Section
        title={"General"}
        deepestBoxClassname={"trip-settings-wrapper trip-settings-wrapper-padding"}
        noHorizontalPadding
        id={generalId}
      >
        {showgroup_booking && (
          <Flex justifyContent="space-between" alignItems={"center"} overflow={"hidden"} pb={{ base: "2", md: "0" }}>
            <TripSettingHeading
              width={inTrip && showCollaboratorCanEdit ? "22em" : defaultInputWidth}
              subText={"By enabling this your clients will be able to book multiple times"}
              inTrip={inTrip}
            >
              Group Booking
            </TripSettingHeading>

            <InputWrapper
              name={group_bookingName}
              horizontal
              inputMarginLeft={"0"}
              marginRight={{ base: "0", md: "7" }}
              width="100%"
              display="flex"
              justifyContent="end"
            >
              <RHFSwitch colorScheme="brand" name={group_bookingName} />
            </InputWrapper>
          </Flex>
        )}
        {showgroup_booking && showmax_travellers && groupBookingValue && max_travellersName ? (
          <>
            <Flex
              justifyContent="space-between"
              alignItems={"center"}
              h={"5em"}
              overflow={"hidden"}
              mb={{ base: "2", md: "0" }}
            >
              <Box>
                <TripSettingHeading
                  width={inTrip && showCollaboratorCanEdit ? "22em" : defaultInputWidth}
                  subText={"The maximum amount of travellers that can book the trip"}
                  inTrip={inTrip}
                >
                  Max amount of travellers
                </TripSettingHeading>
              </Box>

              <InputWrapper
                name={max_travellersName}
                horizontal
                inputMarginLeft={"0"}
                marginRight={{ base: "0", md: "7" }}
                width="100%"
                display="flex"
                justifyContent="end"
              >
                <RHFInput type={"number"} name={max_travellersName} min={booked_travellersValue}></RHFInput>
              </InputWrapper>
            </Flex>
          </>
        ) : (
          <></>
        )}
        {showgroup_booking &&
          showbooked_travellers &&
          groupBookingValue &&
          booked_travellersName &&
          max_travellersValue != undefined && (
            <>
              <Flex alignItems={"center"} h={"5em"} overflow={"hidden"} pb={{ base: "2", md: "0" }}>
                <Box>
                  <TripSettingHeading
                    width={inTrip && showCollaboratorCanEdit ? "22em" : defaultInputWidth}
                    subText={"The amount of travellers that have booked the trip"}
                    inTrip={inTrip}
                  >
                    Amount of booked travellers
                  </TripSettingHeading>
                </Box>

                <InputWrapper
                  name={booked_travellersName}
                  horizontal
                  inputMarginLeft={"0"}
                  marginRight={{ base: "0", md: "7" }}
                  width="100%"
                  display="flex"
                  justifyContent="end"
                >
                  <RHFInput
                    type={"number"}
                    name={booked_travellersName}
                    max={max_travellersValue}
                    isRequired
                  ></RHFInput>
                </InputWrapper>
              </Flex>
            </>
          )}
        {showCollaboratorCanEdit && (
          <Flex justifyContent="space-between" alignItems={"center"} overflow={"hidden"} pb={{ base: "2", md: "0" }}>
            <TripSettingHeading
              subText={"Allow invited collaborators to edit fields ( the price will always be editable )"}
              width={inTrip ? "22em" : defaultInputWidth}
              inTrip={inTrip}
            >
              Allow collaborators to edit fields
            </TripSettingHeading>

            <InputWrapper
              name={collaboratorCanEditName}
              horizontal
              inputMarginLeft={"0"}
              marginRight={{ base: "0", md: "7" }}
              width="100%"
              display="flex"
              justifyContent="end"
            >
              <RHFSwitch colorScheme="brand" name={collaboratorCanEditName} />
            </InputWrapper>
          </Flex>
        )}
        {showTripStatus && (
          <Flex
            alignItems={{ base: "left", md: "center" }}
            flexDir={{ base: "column", md: "row" }}
            overflow={"hidden"}
            pb={{ base: "2", md: "0" }}
          >
            <TripSettingHeading
              subText={"The status that will be used when you create a new trip"}
              inTrip={inTrip}
              width={defaultInputWidth}
            >
              Trip Status
            </TripSettingHeading>
            <InputWrapper
              name={""}
              horizontal
              marginRight={{ base: "0", md: "7" }}
              width="100%"
              minWidth={"100%"}
              display="flex"
              justifyContent="end"
            >
              <HStack overflowX="auto" margin="-10px" padding="10px" mt={[0, "0 !important"]} mb={"0.15em"}>
                {Object.values(TRIP_STATUS).map((status) => {
                  const radio = getRadioProps({
                    value: status,
                  });
                  return (
                    <RadioCard
                      key={status}
                      borderShadow={tripStatus.value === status ? "md" : "0"}
                      opacity={tripStatus.value === status ? 1 : 0.3}
                      {...radio}
                    >
                      <Badge colorScheme={tripStatusColorMap[status as TRIP_STATUS]} p={"0.6em"} margin={"-1px"}>
                        {status}
                      </Badge>
                    </RadioCard>
                  );
                })}
              </HStack>
            </InputWrapper>
          </Flex>
        )}
        {showdefaultlang && defaultlangName && (
          <Flex
            alignItems={{ base: "left", md: "center" }}
            overflow={"hidden"}
            flexDir={{ base: "column", md: "row" }}
            pb={{ base: "2", md: "0" }}
          >
            <TripSettingHeading
              subText={"The language that will be used when you create a new trip"}
              width={inTrip ? "22em" : defaultInputWidth}
              inTrip={inTrip}
            >
              Default language
            </TripSettingHeading>

            <InputWrapper
              name={defaultlangName}
              horizontal
              inputMarginLeft={"0"}
              mt={{ base: "2", md: "0" }}
              mb={{ base: "2", md: "0" }}
              width="100%"
              display="flex"
              justifyContent="end"
            >
              <RHFSelect
                colorScheme="brand"
                name={`${defaultlangName}.code`}
                options={allLanguages.map((lang) => ({ value: lang.code, text: lang.name }))}
              />
            </InputWrapper>
          </Flex>
        )}
        {showBrands && (
          <Flex
            alignItems={"start"}
            h={{ base: "auto", md: "8em" }}
            overflow={"hidden"}
            pb={{ base: "2", md: "0" }}
            flexDir={{ base: "column", md: "row" }}
          >
            <TripSettingHeading
              inTrip={inTrip}
              mt={4}
              width={defaultInputWidth}
              subText={"The brand logo seen by your clients"}
              minWidth={"100%"}
            >
              Brand
            </TripSettingHeading>
            <InputWrapper
              name={""}
              horizontal
              alignSelf={"center"}
              overflow={"auto"}
              minWidth={"100%"}
              width="100%"
              display="flex"
              justifyContent="end"
              marginRight={{ base: "0", md: "7" }}
            >
              <BrandPicker company={me.company} />
            </InputWrapper>
          </Flex>
        )}
        {showbook_link && (
          <Flex
            alignItems={{ base: "left", md: "center" }}
            overflow={"hidden"}
            flexDir={{ base: "column", md: "row" }}
            pb={{ base: "2", md: "0" }}
          >
            <TripSettingHeading
              inTrip={inTrip}
              mt={4}
              width={defaultInputWidth}
              subText={"Filling in this field will redirect your clients to your external bookingform"}
            >
              Booking link
            </TripSettingHeading>
            <InputWrapper
              name={""}
              horizontal
              inputMarginLeft={"0"}
              mx={{ base: "7", md: "0" }}
              marginRight={{ base: "0", md: "7" }}
              minWidth="16rem"
              w="100%"
              display="flex"
              justifyContent="end"
            >
              <RHFInput name={book_linkName!} placeholder={"https://www.exampleredirect.com"}></RHFInput>
            </InputWrapper>
          </Flex>
        )}
        {(show_defaults_show_price || show_price) && (
          <Flex
            alignItems={{ base: "left", md: "center" }}
            overflow={"hidden"}
            flexDir={{ base: "column", md: "row" }}
            pb={{ base: "2", md: "0" }}
          >
            <TripSettingHeading
              width={inTrip && showCollaboratorCanEdit ? "22em" : defaultInputWidth}
              subText={"When will your clients be able to see the price of their trip"}
              inTrip={inTrip}
            >
              Show price
            </TripSettingHeading>

            <InputWrapper
              name={default_show_price_name ?? show_price_formName}
              horizontal
              inputMarginLeft={"0"}
              minWidth={"100%"}
              marginRight={{ base: "0", md: "7" }}
              w="100%"
              display="flex"
              justifyContent="end"
            >
              <RHFRadio
                name={default_show_price_name ?? show_price_formName}
                options={Object.values(SHOW_OPTIONS)
                  .filter((option) => option !== SHOW_OPTIONS.IFBOOKED)
                  .map((option) => {
                    return { text: SHOW_OPTIONS_TextMap[option], value: option };
                  })}
              ></RHFRadio>
            </InputWrapper>
          </Flex>
        )}
        {show_new_edittrip_layout && ENV !== "production" && (
          <Flex justifyContent="space-between" alignItems={"center"} overflow={"hidden"} pb={{ base: "2", md: "0" }}>
            <TripSettingHeading
              width={inTrip && showCollaboratorCanEdit ? "22em" : defaultInputWidth}
              subText={"By enabling this you will see the new edit trip layout."}
              inTrip={inTrip}
            >
              New edit trip layout
            </TripSettingHeading>

            <InputWrapper
              name={new_edittripName}
              horizontal
              inputMarginLeft={"0"}
              marginRight={{ base: "0", md: "7" }}
              minWidth={"100%"}
              width={"100%"}
              display="flex"
              justifyContent="end"
            >
              <RHFSwitch colorScheme="brand" name={new_edittripName} />
            </InputWrapper>
          </Flex>
        )}
      </Section>
      <Section
        title={"Automations"}
        noHorizontalPadding
        deepestBoxClassname={"trip-settings-wrapper trip-settings-wrapper-padding"}
        id={automationsId}
      >
        {showDaysAfterCancelled && (
          <>
            <Flex justifyContent="space-between" alignItems={"center"} overflow={"hidden"} pb={{ base: "2", md: "0" }}>
              <TripSettingHeading
                width={inTrip ? "22em" : defaultInputWidth}
                subText={
                  "Automate the process of automatically marking a trip quotation as cancelled after a specified number of days."
                }
                inTrip={false}
              >
                Cancel quote automatically <NewBadge className="ms-0" />
              </TripSettingHeading>

              <div className="align-middle flex gap-4 justify-center md:mr-7">
                <RHFSwitch
                  colorScheme="brand"
                  name="check"
                  className="my-auto"
                  isChecked={cancel_afterdaysValue}
                  onChange={() => {
                    setValue(daysAfterCancelledFieldName, cancel_afterdaysValue ? null : 60, { shouldDirty: true });
                  }}
                />

                {(cancel_afterdaysValue || cancel_afterdaysValue == "") && (
                  <InputWrapper
                    name={daysAfterCancelledFieldName}
                    horizontal
                    width="6rem"
                    minWidth={"100%"}
                    display="flex"
                    justifyContent="end"
                  >
                    <RHFInput
                      label="number of days"
                      type="number"
                      colorScheme="brand"
                      placeholder="60"
                      name={daysAfterCancelledFieldName}
                      onChange={(e) => {
                        setValue(daysAfterCancelledFieldName, e.target.value, { shouldDirty: true });
                      }}
                      onBlur={() => {
                        if (["0", ""].includes(cancel_afterdaysValue)) {
                          setValue(daysAfterCancelledFieldName, null);
                        }
                      }}
                    />
                  </InputWrapper>
                )}
              </div>
            </Flex>
          </>
        )}
      </Section>
      {(showChatMail || showchat_enabled) && (
        <Section
          title={"Chat"}
          noHorizontalPadding
          deepestBoxClassname={"trip-settings-wrapper trip-settings-wrapper-padding"}
          id={chatId}
        >
          {showchat_enabled && (
            <Flex alignItems={"center"} overflow={"hidden"} pb={{ base: "2", md: "0" }}>
              <TripSettingHeading
                width={inTrip && showCollaboratorCanEdit ? "22em" : defaultInputWidth}
                subText={"Enable the chat with your clients"}
                inTrip={inTrip}
              >
                Enable chat
              </TripSettingHeading>

              <InputWrapper
                name={chatEnableFormName}
                horizontal
                inputMarginLeft={"0"}
                marginRight={{ base: "0", md: "7" }}
                minWidth={"100%"}
                width={"100%"}
                display="flex"
                justifyContent="end"
              >
                <RHFSwitch colorScheme="brand" name={chatEnableFormName} />
              </InputWrapper>
            </Flex>
          )}
          {showChatMail && chatMailName && (
            <Flex alignItems={"center"} overflow={"hidden"} pb={{ base: "2", md: "0" }}>
              <TripSettingHeading
                width={inTrip && showCollaboratorCanEdit ? "22em" : defaultInputWidth}
                subText={"Receive a mail when you get a chatmessage from a client"}
                inTrip={inTrip}
              >
                Receive mail on chatmessage
              </TripSettingHeading>

              <InputWrapper
                name={chatMailName}
                horizontal
                inputMarginLeft={"0"}
                marginRight={{ base: "0", md: "7" }}
                display="flex"
                justifyContent="end"
              >
                <RHFSwitch colorScheme="brand" name={chatMailName} />
              </InputWrapper>
            </Flex>
          )}
        </Section>
      )}

      {/*show_confirm_option && (
        <Flex alignItems={"center"} h={"3em"} overflow={"hidden"}>
          <TripSettingHeading
            width={
              inTrip && showCollaboratorCanEdit ? "22em" : defaultInputWidth
            }
            subText={"Show the confirm option in the travelapp"}
            inTrip={inTrip}
          >
            Show Confirm Option
          </TripSettingHeading>

          <InputWrapper
            name={show_confirm_optionFormName}
            horizontal
            inputMarginLeft={"0"}
          >
            <RHFSwitch colorScheme="brand" name={show_confirm_optionFormName} />
          </InputWrapper>
        </Flex>
          )*/}
      {!sample && show_default_show_directions && (
        <Section
          title={"Map"}
          noHorizontalPadding
          deepestBoxClassname={"trip-settings-wrapper trip-settings-wrapper-padding"}
          id={poiId}
        >
          {!sample && show_pois && (
            <Flex
              alignItems={{ base: "left", md: "center" }}
              overflow={"hidden"}
              flexDir={{ base: "column", md: "row" }}
              pb={{ base: "2", md: "0" }}
            >
              <TripSettingHeading
                subText={"When will your clients see the POIs"}
                inTrip={inTrip}
                width={defaultInputWidth}
              >
                Show POIs
              </TripSettingHeading>

              <InputWrapper
                name={show_poisFormName}
                horizontal
                inputMarginLeft={"0"}
                width="100%"
                minWidth={"100%"}
                marginRight={{ base: "0", md: "7" }}
                display="flex"
                justifyContent="end"
              >
                <RHFRadio
                  name={show_poisFormName}
                  options={Object.values(SHOW_OPTIONS)
                    .filter((option) => option !== SHOW_OPTIONS.IFQUOTE)
                    .map((option) => {
                      return { text: SHOW_OPTIONS_TextMap[option], value: option };
                    })}
                ></RHFRadio>
              </InputWrapper>
            </Flex>
          )}
          {!sample && showpoi_range && (show_poisValue !== SHOW_OPTIONS.NEVER || !inTrip) && (
            <Flex
              alignItems={{ base: "left", md: "center" }}
              h={{ base: "auto", md: "4.5rem" }}
              overflow={"hidden"}
              flexDir={{ base: "column", md: "row" }}
              pb={{ base: "2", md: "0" }}
              w={"100%"}
            >
              <TripSettingHeading
                inTrip={inTrip}
                width={defaultInputWidth}
                subText={"The range for which POIs will be shown around the trip"}
              >
                POI Range
              </TripSettingHeading>
              <InputWrapper
                name={poi_rangeFormName}
                horizontal
                inputMarginLeft={"0.3em"}
                mb={{ base: "2", md: "0" }}
                minWidth={"100%"}
                width="100%"
                marginRight={{ base: "0", md: "7" }}
                display="flex"
                justifyContent="end"
              >
                <RHFSlider
                  w={"25rem"}
                  min={0}
                  max={4}
                  name={poi_rangeFormName}
                  unit={"km"}
                  step={1}
                  sliderValues={[25, 50, 100, 250, 500]}
                ></RHFSlider>
              </InputWrapper>
            </Flex>
          )}
          {show_default_show_directions && (
            <Flex justifyContent="space-between" alignItems={"center"} overflow={"hidden"} pb={{ base: "2", md: "0" }}>
              <TripSettingHeading
                subText={"Display of directions between locations on the map"}
                width={inTrip ? "22em" : defaultInputWidth}
                inTrip={inTrip}
              >
                Show directions
              </TripSettingHeading>

              <InputWrapper
                name={show_directionsName}
                horizontal
                inputMarginLeft={"0"}
                marginRight={{ base: "0", md: "7" }}
                w="100%"
                display="flex"
                justifyContent="end"
              >
                <RHFSwitch colorScheme="brand" name={show_directionsName} />
              </InputWrapper>
            </Flex>
          )}
        </Section>
      )}

      {!sample && (
        <Section
          title={"PDF Export"}
          noHorizontalPadding
          deepestBoxClassname={"trip-settings-wrapper trip-settings-wrapper-padding"}
          id={pdfId}
        >
          {show_pdf && (
            <Flex
              alignItems={{ base: "left", md: "center" }}
              overflow={"hidden"}
              flexDir={{ base: "column", md: "row" }}
              pb={{ base: "2", md: "0" }}
            >
              <TripSettingHeading
                subText={"When will your clients be able to download a trip pdf"}
                inTrip={inTrip}
                width={inTrip ? "12em" : defaultInputWidth}
              >
                Enable .PDF export
              </TripSettingHeading>

              <InputWrapper
                name={show_pdfFormName}
                horizontal
                inputMarginLeft={"0"}
                minWidth={"100%"}
                marginRight={{ base: "0", md: "7" }}
                w="100%"
                display="flex"
                justifyContent="end"
              >
                <RHFRadio
                  name={show_pdfFormName}
                  options={Object.values(SHOW_OPTIONS)
                    .filter((option) => option !== SHOW_OPTIONS.IFQUOTE)
                    .map((option) => {
                      return { text: SHOW_OPTIONS_TextMap[option], value: option };
                    })}
                ></RHFRadio>
              </InputWrapper>
            </Flex>
          )}
        </Section>
      )}

      {!sample && (
        <Section
          title={"Documents"}
          noHorizontalPadding
          deepestBoxClassname={"trip-settings-wrapper trip-settings-wrapper-padding"}
          id={documentsId}
        >
          {show_documents && (
            <Flex
              alignItems={{ base: "left", md: "center" }}
              overflow={"hidden"}
              flexDir={{ base: "column", md: "row" }}
              pb={{ base: "2", md: "0" }}
            >
              <TripSettingHeading
                subText={"When will your clients be able to download a trip document "}
                inTrip={inTrip}
                width={inTrip ? "12em" : defaultInputWidth}
              >
                Show documents
              </TripSettingHeading>

              <InputWrapper
                name={show_documentsFormName}
                horizontal
                inputMarginLeft={"0"}
                minWidth={"100%"}
                marginRight={{ base: "0", md: "7" }}
                w="100%"
                display="flex"
                justifyContent="end"
              >
                <RHFRadio
                  name={show_documentsFormName}
                  options={Object.values(SHOW_OPTIONS)
                    .filter((option) => option !== SHOW_OPTIONS.IFQUOTE)
                    .map((option) => {
                      return { text: SHOW_OPTIONS_TextMap[option], value: option };
                    })}
                ></RHFRadio>
              </InputWrapper>
            </Flex>
          )}
        </Section>
      )}
    </Flex>
  );
};
