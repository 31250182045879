import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Badge,
  Checkbox,
  Heading,
  Popover,
  PopoverTrigger,
  Skeleton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { TASK_STATUS, Task, UserTrip } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { PlusCircleIcon } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { GoTasklist } from "react-icons/go";
import { HiOutlineChevronRight } from "react-icons/hi";
import { HiOutlineChevronLeft } from "react-icons/hi2";
import { IoEarthOutline } from "react-icons/io5";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import LoadingIcon from "../../assets/icons/loadingIcon";
import { useMeContext } from "../../stores/me-context";
import { CALENDAR_ITEMS_QUERY_KEY, GOOGLE_CALENDAR_QUERY_KEY } from "../../utils/constants";
import { useCalendar } from "../../utils/query-helpers/reactQueryHooks";
import {
  useGoogleCalendarItems,
  useGoogleRefresh,
  useMoveTask,
  useRemoveTask,
} from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { PaginationOptions, defaultPaginationOptions } from "../../utils/query-helpers/usePaginatedQuery";
import { handleSubmission } from "../../utils/toErrorMap";
import { useWindowDimensions } from "../../utils/useWindowDimensions";
import TaskModal from "../tasks/TaskModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { BasicEventPopover } from "../../components/calendar/BasicEventPopover";
import { TaskPopover } from "./TaskPopover";
import { TripPopover } from "./TripPopover";
import ENV from "../../utils/env";
import { tripStatusColorMap } from "../tripList/TripsList";

enum CalendarItemType {
  TaskDone = "taskDone",
  TaskNotDone = "taskNotDone",
  UserTrip = "userTrip",
  GoogleEvent = "GoogleEvent",
}

export const taskColor = "yellow";
export const tripColor = "cyan";
export const basicColor = "orange";

export const calendarOptions = {
  [CalendarItemType.TaskDone]: {
    color: `border-${taskColor}-400`,
    bgColor: `bg-${taskColor}-50`,
    icon: <MdCheckBox className="h-5 mr-2" />,
  },
  [CalendarItemType.TaskNotDone]: {
    color: `border-${taskColor}-400`,
    bgColor: `bg-${taskColor}-50`,
    icon: <MdCheckBoxOutlineBlank className="h-5 mr-2" />,
  },
  [CalendarItemType.UserTrip]: {
    color: `border-${tripColor}-600`,
    bgColor: `bg-${tripColor}-50`,
    icon: <IoEarthOutline className="h-5 mr-2" />,
  },
  [CalendarItemType.GoogleEvent]: {
    color: `border-${basicColor}-600`,
    bgColor: `bg-${basicColor}-50`,
    icon: <IoEarthOutline className="h-5 mr-2" />,
  },
};

interface FullCalendarProps {}
const CalendarOverview: React.FC<FullCalendarProps> = () => {
  const me = useMeContext();
  const calendar = useRef(null);
  const editDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();

  const queryClient = useQueryClient();
  const toast = useToast();

  const [selectedTask, setSelectedTask] = useState();
  const [filterGoogleCalendarItems, setFilterGoogleCalendarItems] = useState(false);

  const tokens = JSON.parse(localStorage.getItem(GOOGLE_CALENDAR_QUERY_KEY));

  const today = new Date();
  const firstDayCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [calenderStart, setCalendarStart] = React.useState<string>(firstDayCurrentMonth.toISOString());
  const [calenderEnd, setCalendarEnd] = React.useState<string>(lastDayCurrentMonth.toISOString());
  const [accessToken, setAccessToken] = React.useState<string>(tokens?.access_token);
  const { height } = useWindowDimensions();

  const { mutateAsync: refreshToken, isPending: isRefreshingToken } = useGoogleRefresh(tokens?.refresh_token, {
    enabled: !!tokens?.refresh_token,
  });

  const refresh = async () => {
    const response = await refreshToken();

    if (response?.access_token) {
      setAccessToken(response?.access_token);
      localStorage.setItem(GOOGLE_CALENDAR_QUERY_KEY, JSON.stringify(response));
    }
  };

  const {
    data: googleCalendarEvents,
    isLoading,
    isError: isErrorGoogleCalendar,
    refetch: refetchGoogleCalendar,
  } = useGoogleCalendarItems(me.id!, accessToken, calenderStart, calenderEnd, {
    enabled: !!accessToken,
  });

  useEffect(() => {
    if (isErrorGoogleCalendar && !!accessToken) {
      refresh();
    }
  }, [isErrorGoogleCalendar]);

  const defaultFilters: PaginationOptions = {
    ...defaultPaginationOptions,
    filters: [
      { id: "search", value: "" },
      { id: "user", value: [me?.id] },
      { id: "company", value: [me?.company.id] },
      { id: "startDateRange", value: calenderStart },
      { id: "endDateRange", value: calenderEnd },
      {
        id: "calendarItemType",
        value: [CalendarItemType.UserTrip, CalendarItemType.TaskNotDone, CalendarItemType.TaskDone],
      },
    ],
  };

  useEffect(() => {
    if (isErrorGoogleCalendar) {
      refresh();
    }
  }, [isErrorGoogleCalendar]);

  useEffect(() => {
    refetchGoogleCalendar();
  }, [calenderStart, calenderEnd]);

  const [filters, setFilters] = React.useState<PaginationOptions>(defaultFilters);

  const { mutateAsync: updateTask, isPending: isLoadingUpdate } = useMoveTask(queryClient);
  const { mutateAsync: deleteTask, isPending: isDeleting } = useRemoveTask(queryClient);
  const {
    data: calendarItems,
    isLoading: isLoadingCalendarItems,
    isFetching: isFetchingCalendarItems,
    refetch,
  } = useCalendar({
    ...filters,
    ...filters.filters!.reduce(
      (result, filtValue) => {
        const { id, value } = filtValue;
        result[id] = value && value.length > 0 ? value : undefined;
        return result;
      },
      {} as { [key: string]: string },
    ),
  });

  const triggerFetch = async (filters: any) => {
    setFilters({
      ...filters.filters!.reduce(
        (result: any, filtValue: any) => {
          const { id, value } = filtValue;
          result[id] = value && value.length > 0 ? value : undefined;
          return result;
        },
        {} as { [key: string]: string },
      ),
      ...filters,
    });
    await refetch();
  };

  const triggerMoveTask = async (event: any) => {
    const date = new Date(event.start);
    date.setDate(date.getDate() + 1);
    await handleSubmission({
      successMessage: "moved task",
      failMessage: "to move task",
      apiCall: updateTask({ ...event.extendedProps, due_date: date } as Task),
      toast,
      setError: () => {},
    }).then(() => {
      event.setStart(date);
    });
    queryClient.invalidateQueries({ queryKey: [CALENDAR_ITEMS_QUERY_KEY] });
  };

  const events = useMemo(() => {
    if (!calendarItems) return [];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const tasks = calendarItems?.tasks.map((item: Task) => {
      return {
        itemType: item.status === TASK_STATUS.DONE ? CalendarItemType.TaskDone : CalendarItemType.TaskNotDone,
        title: item.title,
        start: item.due_date,
        id: item.id,
        status: item.status,
        extendedProps: item,
        editable: true,
      };
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const trips = calendarItems?.trips.map((item: UserTrip) => {
      return {
        itemType: CalendarItemType.UserTrip,
        title: item.trip?.titles.at(-1)?.content,
        start: item.trip?.start_date,
        id: item.id,
        extendedProps: item,
        editable: false,
      };
    });

    const googleEvents =
      filterGoogleCalendarItems || isLoading || !googleCalendarEvents
        ? []
        : googleCalendarEvents?.map((item: any) => {
            return {
              itemType: CalendarItemType.GoogleEvent,
              title: item.summary,
              start: item.start ? (item.start.date ? new Date(item.start.date) : new Date(item.start.dateTime)) : null,
              id: item.id,
              extendedProps: item,
              editable: false,
            };
          });

    return [...tasks, ...trips, ...googleEvents];
  }, [calendarItems, filterGoogleCalendarItems, googleCalendarEvents, isLoading]);

  const popoverSelection = (event: any, listView?: boolean) => {
    switch (event.extendedProps.itemType) {
      case CalendarItemType.UserTrip:
        return <TripPopover trip={event.extendedProps} listView={listView} calendarView />;
      case CalendarItemType.TaskDone:
        return (
          <TaskPopover
            task={event.extendedProps}
            listView={listView}
            onDeleteClick={(task: Task) => {
              setSelectedTask(task);
              deleteModalDisclosure.onOpen();
            }}
            onEditClick={(task: Task) => {
              setSelectedTask(task);
              editDisclosure.onOpen();
            }}
            calendarView
          />
        );
      case CalendarItemType.TaskNotDone:
        return (
          <TaskPopover
            task={event.extendedProps}
            listView={listView}
            onDeleteClick={(task: Task) => {
              setSelectedTask(task);
              deleteModalDisclosure.onOpen();
            }}
            onEditClick={(task: Task) => {
              setSelectedTask(task);
              editDisclosure.onOpen();
            }}
            calendarView
          />
        );
      case CalendarItemType.GoogleEvent:
        return <BasicEventPopover event={event} listView={listView} calendarView />;
      default:
        return null;
    }
  };

  const renderEventContentMonthGrid = (eventInfo: any) => {
    const event = eventInfo.event;
    const options = calendarOptions[event.extendedProps.itemType];
    return (
      <Popover placement="top" closeOnBlur={true} isLazy>
        <PopoverTrigger>
          <div
            className={clsx(
              options?.color,
              options?.bgColor,
              "mx-1 h-4.5 w-3/4 z-10 border-l-4 rounded-sm shadow-sm border-y-0 border-r-0 text-black",
            )}
          >
            <span className="flex justify-between ml-2 pt-0.5 text-black font-medium my-auto">
              <span className="truncate w-4/5 text-xs">{event.title}</span>
            </span>
          </div>
        </PopoverTrigger>
        {popoverSelection(event, false)}
      </Popover>
    );
  };

  const renderEventContentWeekGrid = (eventInfo: any) => {
    const event = eventInfo.event;
    const options = calendarOptions[event.extendedProps.itemType];
    const trip: UserTrip = event.extendedProps;

    const tripEvent = () => {
      return (
        <div className="flex flex-col justify-between h-full">
          <div className="space-y-2">
            <p className="truncate pt-1.5 text-black font-medium">{event.title}</p>
            {trip.trip?.client_name && (
              <p className="flex gap-2">
                {/* <IoPersonCircleOutline className="w-5 h-5" /> */}
                <span className="truncate">{trip.trip?.client_name}</span>
              </p>
            )}
            {trip.tasks && trip.tasks.length !== 0 && (
              <p className="flex gap-2">
                <GoTasklist className="w-5 h-5" />
                {trip.tasks.length} {trip.tasks.length === 1 ? "task" : "tasks"}
              </p>
            )}
          </div>
          <Badge
            pb={2}
            alignSelf={"end"}
            w={14}
            height={"1.5rem"}
            as={"button"}
            colorScheme={tripStatusColorMap[trip.status]}
          >
            {trip.status}
          </Badge>
        </div>
      );
    };

    const taskEvent = () => {
      return <p className="truncate pt-1.5 text-black font-medium">{event.title}</p>;
    };

    const basicEvent = () => {
      return <p className="truncate pt-1.5 text-black font-medium w-full">{event.title}</p>;
    };

    return (
      <Popover placement="top" closeOnBlur={true} isLazy>
        <PopoverTrigger>
          <div
            className={clsx(
              options?.color,
              options?.bgColor,
              "mx-1 w-[90%] h-32 z-10 border-l-1 rounded-md shadow-md border-y-0 border-r-0",
            )}
          >
            <div className="h-full ml-2 text-sm text-gray-700 mt-0.5 space-y-2">
              {event.extendedProps.itemType === CalendarItemType.TaskDone ||
                (event.extendedProps.itemType === CalendarItemType.TaskNotDone && taskEvent())}
              {event.extendedProps.itemType === CalendarItemType.UserTrip && tripEvent()}
              {event.extendedProps.itemType === CalendarItemType.GoogleEvent && basicEvent()}
            </div>
          </div>
        </PopoverTrigger>
        {popoverSelection(event, false)}
      </Popover>
    );
  };

  const renderEventContentList = (eventInfo: any) => {
    return popoverSelection(eventInfo.event, true);
  };

  const onFilterChangeTasks = (e: any) => {
    let newTypeFilters = filters.filters?.find((f) => f.id === "calendarItemType")?.value;

    if (e.target.checked) {
      if (!filters.filters?.find((f) => f.id === "calendarItemType")?.value.includes(CalendarItemType.TaskDone)) {
        newTypeFilters.push(CalendarItemType.TaskDone);
      }
      if (!filters.filters?.find((f) => f.id === "calendarItemType")?.value.includes(CalendarItemType.TaskNotDone)) {
        newTypeFilters.push(CalendarItemType.TaskNotDone);
      }
    } else {
      newTypeFilters = newTypeFilters.filter(
        (t: any) => ![CalendarItemType.TaskDone, CalendarItemType.TaskNotDone].includes(t),
      );
    }

    void triggerFetch({
      ...filters,
      filters: filters.filters?.map((f) => (f.id === "calendarItemType" ? { ...f, value: newTypeFilters } : f)),
    });
  };

  const onFilterChangeTrips = (e: any) => {
    let newTypeFilters = filters.filters?.find((f) => f.id === "calendarItemType")?.value;

    if (e.target.checked) {
      if (!newTypeFilters.includes(CalendarItemType.UserTrip)) {
        newTypeFilters.push(CalendarItemType.UserTrip);
      }
    } else {
      newTypeFilters = newTypeFilters.filter((t: any) => t !== CalendarItemType.UserTrip);
    }

    void triggerFetch({
      ...filters,
      filters: filters.filters?.map((f) => (f.id === "calendarItemType" ? { ...f, value: newTypeFilters } : f)),
    });
  };

  return (
    <div className="flex flex-col">
      <div className="md:flex w-full items-start gap-x-4 py-4 ">
        <aside className="ml-4 w-56 mb-8 md:mt-[3.25rem] block">
          <Accordion defaultIndex={[0]} allowMultiple className="bg-white rounded-md border border-gray-200 shadow-sm">
            <Heading textAlign={"center"} size="md" p={4} isTruncated>
              Filters
            </Heading>
            <AccordionItem className="border-none">
              <AccordionButton className="font-bold">
                <div className="flex justify-between w-full">
                  <div className="flex">
                    <Checkbox
                      colorScheme={taskColor}
                      isChecked={filters.filters
                        ?.find((f) => f.id === "calendarItemType")
                        ?.value.includes(CalendarItemType.TaskDone)}
                      onChange={onFilterChangeTasks}
                    >
                      Tasks
                    </Checkbox>
                  </div>
                  <div className="flex mt-1">
                    <PlusCircleIcon className="h-4 w-4 mr-1 mt-1" onClick={() => editDisclosure.onOpen()} />
                  </div>
                </div>
              </AccordionButton>
            </AccordionItem>

            <AccordionItem className="border-none">
              <AccordionButton className="flex justify-between font-bold">
                <div className="flex gap-2">
                  <Checkbox
                    colorScheme={tripColor}
                    isChecked={filters.filters
                      ?.find((f) => f.id === "calendarItemType")
                      ?.value.includes(CalendarItemType.UserTrip)}
                    onChange={onFilterChangeTrips}
                  >
                    Trips
                  </Checkbox>
                </div>
              </AccordionButton>
            </AccordionItem>
            {googleCalendarEvents && (
              <AccordionItem className="border-none">
                <AccordionButton className="flex justify-between font-bold">
                  <div className="flex gap-2">
                    <Checkbox
                      colorScheme={basicColor}
                      isChecked={!filterGoogleCalendarItems}
                      onChange={(e: any) => {
                        setFilterGoogleCalendarItems(!e.target.checked);
                      }}
                    />
                    Google calendar
                  </div>
                </AccordionButton>
              </AccordionItem>
            )}
          </Accordion>
          {!tokens && (
            <p className="m-4 text-xs">
              <a href="/settings/integrations">Add your Google calender events</a>
            </p>
          )}
        </aside>

        <main className="flex-1 lg:mr-6 mr-12">
          <header className="flex items-center justify-between pb-4 lg:flex-none">
            <div className="w-44 flex gap-4">
              <h1 className="leading-6 space-x-2 text-gray-900">
                <time className="font-bold text-2xl" dateTime="2022-01">
                  {calendar.current?.getApi().view.title.split(" ")[0]}
                </time>
                <span className="text-lg font-medium">{calendar.current?.getApi().view.title.split(" ")[1]}</span>
              </h1>
              <p className="mt-2">
                {(isFetchingCalendarItems || isLoadingUpdate) && (
                  <div className="flex gap-2">
                    <LoadingIcon />
                    Loading
                  </div>
                )}
              </p>
            </div>
            <div className="ml-4 flex items-center">
              <button
                onClick={() => calendar.current?.getApi().changeView("dayGridMonth")}
                className={clsx(
                  calendar.current?.getApi().view.type == "dayGridMonth"
                    ? "text-gray-500 font-bold bg-gray-100"
                    : "bg-white",
                  "p-1 pointer text-gray-500 hover:bg-teal-50 rounded-l-lg w-20 px-2 border border-gray-200",
                )}
              >
                Month
                {/* <MdOutlineCalendarViewMonth className="h-5 w-5" /> */}
              </button>
              <button
                onClick={() => calendar.current?.getApi().changeView("dayGridWeek")}
                className={clsx(
                  calendar.current?.getApi().view.type == "dayGridWeek"
                    ? "text-gray-500 font-bold bg-gray-100"
                    : "bg-white",
                  "p-1 pointer text-gray-500 hover:bg-teal-50 w-20 px-2 border-y border-gray-200",
                )}
              >
                Week
                {/* <MdOutlineCalendarViewWeek className="h-5 w-5 " /> */}
              </button>

              <button
                onClick={() => calendar.current?.getApi().changeView("listWeek")}
                className={clsx(
                  calendar.current?.getApi().view.type == "listWeek"
                    ? "text-gray-500 font-bold bg-gray-100"
                    : "bg-white",
                  "p-1 pointer text-gray-500 hover:bg-teal-50 rounded-r-lg w-20 px-2 border border-gray-200",
                )}
              >
                List
                {/* <MdOutlineViewList className="h-5 w-5" /> */}
              </button>
            </div>
            <div className="flex bg-white relative rounded-md shadow-sm items-stretch">
              <button
                onClick={() => calendar.current?.getApi().prev()}
                type="button"
                className="flex py-1.5 w-12 items-center justify-center rounded-l-md border border-gray-200 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-teal-50"
              >
                <span className="sr-only">Previous month</span>
                <HiOutlineChevronLeft className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => calendar.current?.getApi().gotoDate(new Date())}
                type="button"
                className="hidden px-3.5 text-sm border-y border-gray-200 font-semibold text-gray-500 md:hover:bg-teal-50 focus:relative md:block "
              >
                Today
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                onClick={() => calendar.current?.getApi().next()}
                type="button"
                className="flex w-12 items-center justify-center rounded-r-md  border border-gray-200 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-teal-50"
              >
                <span className="sr-only">Next month</span>
                <HiOutlineChevronRight className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </header>
          <div className="bg-white rounded-lg shadow-lg relative">
            {isLoadingCalendarItems ? (
              <Skeleton height={400} />
            ) : (
              <FullCalendar
                // datesRender={(arg) => {
                //   console.log("change");
                //   setCalendarStart(arg.view.activeStart); //starting visible date
                //   setCalendarEnd(arg.view.activeEnd); //ending visible date
                // }}
                ref={calendar}
                height={height - 138}
                datesSet={async (event) => {
                  setCalendarStart(event.start.toISOString());
                  setCalendarEnd(event.end.toISOString());
                  calendar.current?.getApi().render();
                  await triggerFetch({
                    ...filters,
                    filters: filters.filters?.map((f) =>
                      f.id === "startDateRange"
                        ? { ...f, value: event.start.toDateString() }
                        : f.id === "endDateRange"
                          ? { ...f, value: event.end.toDateString() }
                          : f,
                    ),
                  });
                  calendar.current?.getApi().render();
                }}
                headerToolbar={false}
                editable={true}
                eventDragMinDistance={1}
                dragScroll={true}
                selectable={true}
                dayMaxEvents={true}
                showNonCurrentDates={true}
                allDaySlot={false}
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                eventBackgroundColor="transparant"
                views={{
                  dayGridMonth: { eventContent: renderEventContentMonthGrid },
                  dayGridWeek: { eventContent: renderEventContentWeekGrid },
                  listWeek: { eventContent: renderEventContentList },
                }}
                dayCellContent={(info: any) => {
                  return <p className="h-4 text-end">{info.dayNumberText}</p>;
                }}
                eventDrop={(info: any) => {
                  void triggerMoveTask(info.event);
                }}
                events={calendarItems ? events : []}
              />
            )}
          </div>
        </main>
      </div>
      {(selectedTask || editDisclosure.isOpen) && (
        <TaskModal
          onSubmitTask={(task: Task) => {
            const d = new Date(task.due_date);
            d.setDate(d.getDate() - 1);
            !selectedTask &&
              calendar.current?.getApi().addEvent({
                itemType: task.status === TASK_STATUS.DONE ? CalendarItemType.TaskDone : CalendarItemType.TaskNotDone,
                title: task.title,
                start: d,
                id: task.id,
                status: task.status,
                extendedProps: task,
                editable: true,
              });
            triggerFetch(filters);
          }}
          modalDisclosure={editDisclosure}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
        />
      )}
      {deleteModalDisclosure.isOpen && (
        <ConfirmModal
          title={"Delete task"}
          description={`Are you sure you want to delete this task?`}
          action={() => deleteTask(selectedTask)}
          disclosure={deleteModalDisclosure}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};
export default CalendarOverview;
