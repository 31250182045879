import { Box, Text } from "@chakra-ui/react";
import {
  MAP_TYPE,
  TRANSPORTATION_TYPE,
  TTransportation,
  Transportation,
  getDistance,
  getDistanceFromLatLonInKm,
  getIconCoordination,
  removeFarMarkers,
  secondsToTimeFormat,
  timeDifferenceInSeconds,
  transportIconSvg,
} from "@lato/common";
import L from "leaflet";
import React, { ReactElement, useState } from "react";
import { useWatch } from "react-hook-form";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { MdCarRental, MdDirectionsBike, MdFullscreen } from "react-icons/md";
import { TbBus, TbCar, TbPlane, TbRoad, TbSailboat, TbTrain, TbWalk } from "react-icons/tb";
import { MapContainer, Polyline, TileLayer, Tooltip, useMap, useMapEvents } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { POI } from "../../api/pois.api";
import CustomModal from "../../components/layout/CustomModal";
import { MarkerMapItem } from "../../components/trips/edit/daybyday/library-items/LibraryItemModal";
import OverviewMap from "../../components/trips/edit/overviewStep/OverviewMap";
import CustomLeafletMarker, { CustomLeafletMarkerProps } from "../../components/map/CustomLeafletMarker";
import { CustomLeafletPinMarker } from "../../components/map/CustomLeafletPinMarker";
import MapsPath, { PathPart } from "../../components/map/MapsPath";
import { mapTypeApi, mapTypeAttribution } from "../../components/map/staticMap";

export interface MapsProps {
  isDirectionsDisabled?: boolean;
  isSmallMap?: boolean;
  transportations?: Partial<TTransportation>[];
  transportation_grouped: Partial<TTransportation>[][];
  flights: Partial<TTransportation>[];
  trains: Partial<TTransportation>[];
  markerPositions: { lat: number; lng: number; name?: string; icon?: ReactElement; dayIndex?: number }[];
  travelerMode?: boolean;
  itemList?: MarkerMapItem[];
  userTripId?: string;
  markerClickHandler?: (poi: POI, ownCompany: boolean) => void;
  changeNewPOI?: (newPoi: POI, panTo?: boolean, zoom?: boolean) => void;
  onMapClicked?: (coordinates: number[]) => void;
  show_pois?: boolean;
  newItem?: MarkerMapItem | null;
  setNewItem?: React.Dispatch<React.SetStateAction<MarkerMapItem | null>>;
  ownCompany?: boolean;
  mapRef?: google.maps.Map | null;
  setMapRef?: React.Dispatch<React.SetStateAction<google.maps.Map | null>>;
  children?: React.ReactNode;
  centerpoint?: number[];
  expandMapButton?: boolean;
}

export interface CustomMapsProps extends MapsProps {
  mapType: MAP_TYPE;
}

export const transportIcon: { [key in TRANSPORTATION_TYPE | string]: any } = {
  [TRANSPORTATION_TYPE.BIKE]: <MdDirectionsBike size={"24px"} />,
  [TRANSPORTATION_TYPE.CAR]: <TbCar size={"24px"} />,
  [TRANSPORTATION_TYPE.RENTAL]: <MdCarRental size={"28px"} />,
  [TRANSPORTATION_TYPE.FLIGHT]: <TbPlane size={"24px"} />,
  [TRANSPORTATION_TYPE.BOAT]: <TbSailboat size={"24px"} />,
  [TRANSPORTATION_TYPE.BUS]: <TbBus size={"24px"} />,
  [TRANSPORTATION_TYPE.FOOT]: <TbWalk size={"24px"} />,
  [TRANSPORTATION_TYPE.TRAIN]: <TbTrain size={"24px"} />,
  [TRANSPORTATION_TYPE.CUSTOM]: <FaArrowRightLong size={"20px"} />,
};

const Maps: React.FC<CustomMapsProps> = ({
  isDirectionsDisabled = false,
  isSmallMap = false,
  transportations,
  transportation_grouped,
  flights,
  trains,
  markerPositions,
  mapType,
  children,
  centerpoint,
  expandMapButton,
}) => {
  const [map, setMap] = React.useState<L.Map>();
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);

  // POI's are not nested in an array anymore, so simple flatmap is enough
  const allMarkerPositions = removeFarMarkers(markerPositions.flat()) || [];

  const currentTileLayer =
    mapType === MAP_TYPE.GOOGLE ? (
      <ReactLeafletGoogleLayer apiKey={mapTypeApi[mapType]} />
    ) : (
      <TileLayer attribution={mapTypeAttribution[mapType]} url={mapTypeApi[mapType]} />
    );

  const onMapInit = (mapp: any) => {
    if (mapp && !map) {
      setMap(mapp);
    }
  };

  const center = centerpoint
    ? { lat: centerpoint[0], lng: centerpoint[1] }
    : allMarkerPositions?.length === 1
      ? allMarkerPositions[0]
      : undefined;
  const flightPaths = () => {
    const printedFlightCoordinates: any[] = [];
    return flights.map((flight, i) => {
      const distance =
        getDistanceFromLatLonInKm(
          {
            lng: flight.departureAirport ? +flight.departureAirport.lon : undefined,
            lat: flight.departureAirport ? +flight.departureAirport.lat : undefined,
          },
          {
            lng: flight.arrivalAirport ? +flight.arrivalAirport.lon : undefined,
            lat: flight.arrivalAirport ? +flight.arrivalAirport.lat : undefined,
          },
        ) * 1000;

      if (
        flight.departureAirport?.lon &&
        flight.departureAirport?.lat &&
        flight.arrivalAirport?.lon &&
        flight.departureAirport?.lat &&
        flight.departureAirport?.tz !== null &&
        flight.arrivalAirport?.tz != null
      ) {
        const { lat, lng, radius } = getIconCoordination(
          +flight.departureAirport?.lon,
          +flight.departureAirport?.lat,
          +flight.arrivalAirport?.lon,
          +flight.arrivalAirport?.lat,
        );
        if (
          !printedFlightCoordinates.some(
            (print: any) =>
              print.departureAirportIata === flight.arrivalAirport?.iata &&
              print.arrivalAirportIata === flight.departureAirport?.iata,
          )
        ) {
          printedFlightCoordinates.push({
            departureAirportIata: flight.departureAirport.iata,
            arrivalAirportIata: flight.arrivalAirport.iata,
          });

          return (
            <div key={(i + 1) * 100}>
              <TransportMarker
                key={`flight-marker-${i}`}
                coordinates={[lng, lat]}
                radius={radius}
                transportType={flight.type}
                transportLength={distance}
              />
              <Polyline
                pathOptions={{
                  weight: 20,
                  opacity: 0,
                }}
                positions={[
                  {
                    lat: flight.departureAirport?.lat ?? 0,
                    lng: flight.departureAirport?.lon ?? 0,
                  },
                  {
                    lat: flight.arrivalAirport?.lat ?? 0,
                    lng: flight.arrivalAirport?.lon ?? 0,
                  },
                ]}
              >
                <Tooltip sticky={true}>
                  <div className="text-center">
                    <div className="flex gap-4">
                      <span className="font-bold">{flight.fromName}</span>
                      {transportIcon[TRANSPORTATION_TYPE.FLIGHT]}
                      <span className="font-bold">{flight.toName}</span>
                    </div>
                    <br />
                    {distance && getDistance(distance, 2)}
                    {distance && flight.arrivalTime && flight.departureTime && " | "}
                    {flight.arrivalTime &&
                      flight.departureTime &&
                      secondsToTimeFormat(
                        Math.abs(
                          timeDifferenceInSeconds(flight.departureTime, flight.arrivalTime, flight.arrivalDayIndex!),
                        ),
                      )}
                  </div>
                </Tooltip>
              </Polyline>
              <Polyline
                pathOptions={{
                  color: "#43a8a0",
                  weight: 3,
                  dashArray: "10, 10",
                  opacity: 1,
                }}
                positions={[
                  {
                    lat: flight.departureAirport?.lat ?? 0,
                    lng: flight.departureAirport?.lon ?? 0,
                  },
                  {
                    lat: flight.arrivalAirport?.lat ?? 0,
                    lng: flight.arrivalAirport?.lon ?? 0,
                  },
                ]}
              />
            </div>
          );
        }
      }
    });
  };

  const trainPaths = () => {
    return trains.map((train, i) => {
      const { lat, lng, radius } = getIconCoordination(
        +train.departureLocation!.coordinates![0],
        +train.departureLocation!.coordinates![1],
        +train.arrivalLocation!.coordinates![0],
        +train.arrivalLocation!.coordinates![1],
      );
      const distance =
        getDistanceFromLatLonInKm(
          {
            lat: train.departureLocation ? +train.departureLocation.coordinates![0] : undefined,
            lng: train.departureLocation ? +train.departureLocation.coordinates![1] : undefined,
          },
          {
            lat: train.arrivalLocation ? +train.arrivalLocation.coordinates![0] : undefined,
            lng: train.arrivalLocation ? +train.arrivalLocation.coordinates![1] : undefined,
          },
        ) * 1000;
      return (
        <div key={(i + 1) * 100}>
          <TransportMarker
            key={`train-marker-${i}`}
            coordinates={[lng, lat]}
            radius={radius}
            transportType={train.type}
            transportLength={distance}
          />
          <Polyline
            pathOptions={{
              weight: 20,
              opacity: 0,
            }}
            positions={[
              {
                lat: train.departureLocation?.coordinates![1] ?? 0,
                lng: train.departureLocation?.coordinates![0] ?? 0,
              },
              {
                lat: train.arrivalLocation?.coordinates![1] ?? 0,
                lng: train.arrivalLocation?.coordinates![0] ?? 0,
              },
            ]}
          >
            <Tooltip sticky={true}>
              <div className="text-center">
                <div className="flex gap-4">
                  <span className="font-bold">{train.fromName}</span>
                  {transportIcon[TRANSPORTATION_TYPE.TRAIN]}
                  <span className="font-bold">{train.toName}</span>
                </div>
                <br />
                {distance && getDistance(distance, 2)}
                {distance &&
                  train.arrivalTime &&
                  train.departureTime &&
                  timeDifferenceInSeconds(train.departureTime, train.arrivalTime, train.arrivalDayIndex!) !== 0 &&
                  " | "}
                {train.arrivalTime &&
                  train.departureTime &&
                  timeDifferenceInSeconds(train.departureTime, train.arrivalTime, train.arrivalDayIndex!) !== 0 &&
                  secondsToTimeFormat(
                    Math.abs(timeDifferenceInSeconds(train.departureTime, train.arrivalTime, train.arrivalDayIndex!)),
                  )}
              </div>
            </Tooltip>
          </Polyline>
          <Polyline
            pathOptions={{
              color: "#43a8a0",
              weight: 3,
              dashArray: "10, 10",
              opacity: 1,
            }}
            positions={[
              {
                lat: train.departureLocation?.coordinates![1] ?? 0,
                lng: train.departureLocation?.coordinates![0] ?? 0,
              },
              {
                lat: train.arrivalLocation?.coordinates![1] ?? 0,
                lng: train.arrivalLocation?.coordinates![0] ?? 0,
              },
            ]}
          />
        </div>
      );
    });
  };

  const routePaths = React.useCallback(() => {
    if (isDirectionsDisabled) {
      return transportations!.map((transportation, index) => (
        <PathPart
          key={`mapPath-${index}`}
          map={map!}
          coordinates={[transportation.departureLocation?.coordinates!, transportation.arrivalLocation?.coordinates!]}
          index={0}
          type={TRANSPORTATION_TYPE.CAR}
          transportation_grouped={[transportation]}
          disabled={isDirectionsDisabled}
        />
      ));
    }

    return transportation_grouped
      .filter((f) => f.length > 0)
      .map((transportation_group: Partial<TTransportation>[], index: number) => {
        const co: number[][] = [];
        transportation_group.forEach((transport: Partial<Transportation>, i: number) => {
          // End and begin coordinates can be the same for subsequent transportations, filter these duplicated coordinates out
          const departureLocation = transport.departureLocation?.coordinates;
          const arrivalLocation = transport.arrivalLocation?.coordinates;

          if (i > 0) {
            co.push(arrivalLocation!);
          } else {
            co.push(departureLocation!, arrivalLocation!);
          }
        });
        return (
          <MapsPath
            key={`mapPath-${index}`}
            map={map!}
            type={transportation_group[0]!.type!}
            transportation_grouped={transportation_group}
            markerPositions={co}
          />
        );
      });
  }, [transportation_grouped, map]);

  const distances = useWatch({ name: "distances" });
  const durations = useWatch({ name: "durations" });

  const areDistancesCalculated = distances && distances.flat().reduce((a: number, b: number) => a + b, 0) >= 1;

  return (
    <Box w="100%" h="100%" pos="relative">
      {areDistancesCalculated && !isSmallMap ? (
        <div className="map-route-info flex gap-2">
          {areDistancesCalculated && (
            <span>
              {getDistance(
                distances.flat().reduce((a: number, b: number) => a + b, 0),
                0,
              )}
            </span>
          )}
          {areDistancesCalculated && durations && " | "}
          {durations && durations >= 1 && <span>{secondsToTimeFormat(durations)}</span>}
        </div>
      ) : null}
      {expandMapButton && <ExpandMapButton isMapFullscreen={isMapFullscreen} setIsMapFullscreen={setIsMapFullscreen} />}
      <MapContainer
        center={center ?? { lat: 0, lng: 0 }}
        style={{
          height: "auto",
          width: "100%",
          minHeight: "100%",
          objectFit: "cover",
          borderRadius: "0.4em",
          zIndex: "5",
        }}
        zoom={center ? 12 : allMarkerPositions.length > 0 ? undefined : 2}
        ref={onMapInit}
      >
        {currentTileLayer}
        {/* {markerPositions.map((position, index) => (
          <CustomLeafletPinMarker
            coordinates={[position.lng, position.lat]}
            nestedIcon={<Text>{index + 1}</Text>}
            key={`marker-${index}`}
            zIndexOffset={100 + markerPositions.length * 2 - 2 * index}
            pinColor={"#E53E3E"}
            nestedIconColor={"black"}
            disableHover={true}
          >
            <Tooltip sticky={true}>
              <div className="flex gap-1">
                {position.icon}
                <div>
                  <span className="font-bold text-md">{position.name}</span>
                  {position.dayIndex !== 1 && <div className="text-md">Arrival on day {position.dayIndex}</div>}
                </div>
              </div>
            </Tooltip>
          </CustomLeafletPinMarker>
        ))} */}
        {children}
        {trainPaths()}
        {flightPaths()}
        {routePaths()}
        <GoToCoordinates
          bounds={allMarkerPositions && allMarkerPositions.length > 0 ? allMarkerPositions : undefined}
          centerPoint={centerpoint}
          zoom={centerpoint ? 4 : undefined}
        />
      </MapContainer>
      <MapModal isMapFullscreen={isMapFullscreen} setIsMapFullscreen={setIsMapFullscreen} />
    </Box>
  );
};

export default React.memo(Maps);

export function GoToCoordinates(props: any) {
  const { centerPoint, bounds, padding = [50, 50], zoom = 14 } = props;
  const map = useMap();

  React.useEffect(() => {
    if (bounds && bounds.length > 0) {
      map.fitBounds(bounds, { padding });
    }
  }, [bounds]);

  React.useEffect(() => {
    if (!centerPoint || centerPoint.length === 0) {
      return;
    }

    const latitude = centerPoint[1];
    const longitude = centerPoint[0];

    map.setView([latitude, longitude], Math.max(zoom, map.getZoom()));
  }, [centerPoint]);

  return null;
}

interface TransportMarkerProps extends Omit<CustomLeafletMarkerProps, "icon"> {
  radius: number;
  transportType: any;
  coordinates: number[];
  transportLength: number;
}

export const TransportMarker: React.FC<TransportMarkerProps> = ({
  radius,
  transportType,
  transportLength,
  coordinates,
  ...props
}) => {
  const [zoomLevel, setZoomLevel] = useState<number>(5);

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });

  //@ts-ignore
  L.transportIcon = L.Icon.extend({
    options: {
      type: "",
      radius: "",
      shadowUrl: null,
      iconAnchor: [8, 12],
      className: "leaflet-marker-icon drop-shadow-md rounded-lg",
    },
    createIcon: function () {
      const type = this.options["type"];
      const radius = this.options["radius"];
      const div = document.createElement("div");
      div.setAttribute("style", "pointer-events:none");
      const img = this._createImg(transportIconSvg[type ?? TRANSPORTATION_TYPE.CAR]);
      let style = `width:20px;transform:rotate(${radius}deg)`;
      if (radius > 90 && radius < 270) {
        style += "scaleY(-1)";
      }
      img.setAttribute("style", style);
      div.appendChild(img);
      this._setIconStyles(div, "transport-icon");
      return div;
    },
  });

  return (
    <CustomLeafletMarker
      icon={
        //@ts-ignore
        new L.transportIcon({
          type: transportType,
          radius: radius,
        })
      }
      opacity={(transportLength / 1000) * zoomLevel ** 3 > 20 ? 1 : 0}
      coordinates={coordinates}
      {...props}
    />
  );
};

const MapModal = ({ isMapFullscreen, setIsMapFullscreen, tripdaySwiperPosition }: any) => {
  return (
    <CustomModal
      size={"3xl"}
      showHeader={false}
      isOpen={isMapFullscreen}
      onClose={() => setIsMapFullscreen(false)}
      title={""}
      modalBodyProps={{ padding: 0, overflow: "hidden" }}
    >
      <button className="expand-map" onClick={() => setIsMapFullscreen(false)}>
        <IoCloseOutline className="w-6 h-6" />
      </button>
      <div className="w-[80vw] h-[70vh]">
        <OverviewMap
          isDirectionsDisabled={false}
          isSmallMap
          changeTripday={() => {}}
          selectedTripdayIndex={tripdaySwiperPosition}
          expandMapButton
        />
      </div>
    </CustomModal>
  );
};

const ExpandMapButton = ({
  isMapFullscreen,
  setIsMapFullscreen,
}: {
  isMapFullscreen: boolean;
  setIsMapFullscreen: any;
}) => {
  return (
    <button className="expand-map" onClick={() => setIsMapFullscreen(!isMapFullscreen)}>
      <MdFullscreen className="w-8 h-8 bg-white rounded-full border-2 border-gray-200" />
    </button>
  );
};
