import { Icon, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { HiOutlineLibrary } from "react-icons/hi";
import { IoEarthOutline } from "react-icons/io5";
import { UserTrip } from "@lato/common";
import { OptionsDropdownItem } from "../../../CRUD/OptionsDropdown";
import CopyTripModal, { CopyTripModalProps } from "../../../../features/tripList/CopyTripModal";

interface CreateTripFromSampleProps extends Omit<CopyTripModalProps, "modalDisclosure"> {
  isDisabled?: boolean;
}

const CreateTripFromSample: React.FC<CreateTripFromSampleProps> = ({ isDisabled = false, ...props }) => {
  const modalDisclosure = useDisclosure();
  const toTrip = props.convertType === "sampleToTrip";
  return (
    <>
      <OptionsDropdownItem
        icon={<Icon as={toTrip ? IoEarthOutline : HiOutlineLibrary} />}
        onClick={(e) => modalDisclosure.onOpen()}
        name={`Create ${toTrip ? "trip" : "sample"} from ${toTrip ? "sample" : "trip"}`}
        isDisabled={isDisabled}
      />
      <CopyTripModal {...props} modalDisclosure={modalDisclosure} />
    </>
  );
};
export default React.memo(CreateTripFromSample);
