import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { GoogleAddress, parseAddressComponents } from "@lato/common";
import { SearchIcon } from "lucide-react";
import React from "react";
import MapsAutocomplete from "./MapsAutocomplete";

interface MarkerMapsAutocompleteProps {
  changeItem(coordinates: number[], address?: GoogleAddress, locationName?: string): void;
  formname?: string;
}

const MarkerMapsAutocomplete: React.FC<MarkerMapsAutocompleteProps> = ({ changeItem, formname = "Item" }) => {
  // handles the place changed event from the autocomplete
  const handlePlaceChanged = (place: any) => {
    if (place.geometry) {
      const coordinates: number[] = [place.geometry.location.lng(), place.geometry.location.lat()];
      let address: GoogleAddress = {
        city: null,
        country_code: null,
        postal_code: null,
        state: null,
        street: null,
      };
      if (place.address_components) {
        const address_components = parseAddressComponents(place.address_components);
        const street = address_components.route?.long_name;
        const street_number = address_components.street_number?.long_name;
        address = {
          country_code: address_components.country?.short_name,
          state: address_components.administrative_area_level_1?.long_name,
          city: address_components.locality?.long_name,
          postal_code: address_components.postal_code?.long_name,
          street: street && street_number ? `${street_number} ${street}` : street ? street : street_number,
        };
      }
      // Returns the coordinates and address to the Table to be handled as a new item to then be handed to the modal as location data
      changeItem(coordinates, address, place.name);
    }
  };
  return (
    <MapsAutocomplete handlePlaceChanged={handlePlaceChanged}>
      <InputGroup w="50%" left="25%" zIndex={20} mt={3} pos="absolute" id="ProduktlyTourSearchBar">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type="search"
          autoComplete="nope"
          bg="rgb(250,251,252)"
          borderRadius="md"
          borderWidth="1px"
          focusBorderColor="brand.500"
          _hover={{ borderColor: "brand.200" }}
          placeholder={`Search to add ${formname}`}
          boxShadow="xl"
        />
      </InputGroup>
    </MapsAutocomplete>
  );
};
export default React.memo(MarkerMapsAutocomplete);
