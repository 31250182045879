import { Box, Flex, Spacer, Stack } from "@chakra-ui/react";
import { BsChatSquare, BsListCheck } from "react-icons/bs";
import { HiOutlineLibrary } from "react-icons/hi";
import { IoEarth, IoEarthOutline, IoExtensionPuzzleOutline, IoExtensionPuzzleSharp } from "react-icons/io5";
import { LuBookOpenCheck } from "react-icons/lu";
import { MdDashboard, MdPermContactCalendar } from "react-icons/md";
import { Link } from "react-router-dom";
import { Logo } from "../../../assets/icons/MiniDrawerIcons";
import NotificationDisplay from "../../Notifications/NotificationDisplay";
import { disableBedBanks } from "../../../components/trips/edit/daybyday/library-items/hotel/HotelForm";
import VerticalNavBarItem from "./VerticalNavBarItem";

export const VerticalNavBar = ({ navBarWidth }: any) => {
  const topIcons = [
    { icon: MdDashboard, selectedIcon: MdDashboard, text: "Home", route: "/" },
    { icon: IoEarthOutline, selectedIcon: IoEarth, text: "Trips", route: "/trips" },
    { icon: HiOutlineLibrary, selectedIcon: HiOutlineLibrary, text: "Samples", route: "/samples" },
    {
      icon: IoExtensionPuzzleOutline,
      selectedIcon: IoExtensionPuzzleSharp,
      text: "Elements",
      route: "/library/accommodations",
    },
    { icon: BsChatSquare, selectedIcon: BsChatSquare, text: "Chats", route: "/chats", badge: <NotificationDisplay /> },
    {
      icon: BsListCheck,
      selectedIcon: BsListCheck,
      text: "Tasks",
      route: "/tasks",
    },
    { icon: MdPermContactCalendar, selectedIcon: MdPermContactCalendar, text: "Contacts", route: "/contacts" },
  ];

  if (!disableBedBanks) {
    topIcons.push({
      icon: LuBookOpenCheck,
      selectedIcon: LuBookOpenCheck,
      text: "Booking",
      route: "/booking/overview",
    });
  }

  return (
    <Flex>
      <Flex
        as="nav"
        zIndex={2000}
        w={["100vw", navBarWidth]}
        maxW={["100vw", navBarWidth]}
        minW={["100vw", navBarWidth]}
        h={[navBarWidth, "100vh"]}
        minH={[navBarWidth, "100vh"]}
        maxH={[navBarWidth, "100vh"]}
        pos="sticky"
        top={0}
        bottom={0}
        overflowY={["auto", "auto"]}
        overflowX={["auto", "hidden"]}
        borderColor="gray.200"
        shadow="lg"
        flexDirection={{ base: "row", sm: "column" }}
        alignItems="center"
        color="lightGray.500"
        gap={[0, 3]}
        py={[0, 3]}
        bg="lato.darkBlue"
      >
        <Box as={Link} to="/" textAlign="center">
          <Logo width="75%" height="auto" color="brand.300" display={{ base: "none", sm: "inline" }} />
        </Box>
        <Stack
          alignItems="center"
          flexDirection={{ base: "row", sm: "column" }}
          spacing={0}
          mt={{ base: "0", sm: "16" }}
          w="full"
        >
          {topIcons.map((iconObj) => (
            <VerticalNavBarItem
              key={iconObj.text}
              {...iconObj}
              active={location.pathname.split("/").at(1) === iconObj.route?.split("/").at(1)}
              isExternal={iconObj.isExternal}
              tooltip={iconObj.tooltip}
            />
          ))}
        </Stack>
        <Spacer />
      </Flex>
    </Flex>
  );
};

export default VerticalNavBar;
