import { Box, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import React from "react";

interface CardWrapperProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
  topIllustration?: any;
  cardWidth?: string;
}

export const CardWrapper: React.FC<CardWrapperProps> = ({
  title,
  description,
  topIllustration,
  children,
  cardWidth = "400px",
}) => {
  return (
    <Box
      p={10}
      px={{ base: "5", lg: "10" }}
      maxWidth={{ base: "90%", lg: cardWidth }}
      minWidth={{ base: "90%", lg: cardWidth }}
      borderRadius="xl"
      boxShadow="md"
      bg="white"
      textAlign="center"
    >
      {topIllustration && <Icon as={topIllustration} boxSize={28} mx="auto" />}
      <Heading mb={1} size="2xl" fontWeight="extrabold">
        {title}
      </Heading>
      {description && (
        <Text mb={4} color="gray.500" fontWeight="500">
          {description}
        </Text>
      )}
      {children}
    </Box>
  );
};
