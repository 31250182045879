import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTripMailPreview } from "../../../../utils/query-helpers/reactQueryHooks";
import FullScreenSpinner, { MailPreviewSkeleton } from "../../../FullScreenSpinner";

interface SendTripMailPreviewProps {
  mailData: any;
}

const SendTripMailPreview: React.FC<SendTripMailPreviewProps> = ({ mailData }) => {
  const { id: userTripId } = useParams<{ id: string }>();

  const { data: mailPreview, isLoading: isLoadingPreview } = useTripMailPreview(userTripId!, mailData);

  if (isLoadingPreview) {
    return <MailPreviewSkeleton />;
  }

  return (
    <div className="h-full">
      <iframe className="h-[70vh] w-full" src={"data:text/html," + encodeURIComponent(mailPreview)} />
    </div>
  );
};
export default React.memo(SendTripMailPreview);
