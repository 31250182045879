import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Flex, Heading, Tooltip } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import UserTripsAPI from "../../api/usertrips.api";
import { useTripLanguage } from "../../stores/trip-language-context";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import {
  DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY,
  DAY_BY_DAY_STEP_TRIPDAY_TITLE_QUERY_KEY,
  DAY_BY_DAY_STEP_TRIP_QUERY_KEY,
  GENERAL_STEP_TRIP_QUERY_KEY,
  PRICE_STEP_TRIP_QUERY_KEY,
  TRIPDAY_ACCOMMODATION_QUERY_KEY,
  TRIPDAY_ACTIVITY_QUERY_KEY,
  TRIPDAY_TRANSPORTATION_QUERY_KEY,
  TRIP_PLAIN_QUERY_KEY,
  TRIP_TRANSLATIONS_QUERY_KEY,
  USERTRIP_PLAIN_QUERY_KEY,
} from "../../utils/constants";
import { LanguageSwitcherAbstract } from "../../features/language/LanguageSwitcherAbstract";

type TranslateTitleProps = {
  isFirst: boolean;
  name: string;
  getValues: any;
  elementId?: string;
  addLanguageCall?: any;
  removeLanguageCall?: any;
  invalidateQueries?: string[];
  width?: string;
  forceNew?: boolean;
};
const TranslateTitle: React.FC<TranslateTitleProps> = ({
  isFirst,
  name,
  getValues,
  elementId,
  addLanguageCall,
  removeLanguageCall,
  invalidateQueries,
  width = "100%",
  forceNew = false,
}) => {
  const { id: userTripId } = useParams<{ id: string }>();

  const { trip } = useTripFormStore();

  elementId = elementId ?? userTripId;
  addLanguageCall = addLanguageCall ?? UserTripsAPI.addLanguageToTrip;
  removeLanguageCall = removeLanguageCall ?? UserTripsAPI.removeLanguageFromTrip;
  invalidateQueries = invalidateQueries ?? [
    TRIP_TRANSLATIONS_QUERY_KEY,
    GENERAL_STEP_TRIP_QUERY_KEY,
    TRIPDAY_ACTIVITY_QUERY_KEY,
    TRIPDAY_ACCOMMODATION_QUERY_KEY,
    TRIPDAY_TRANSPORTATION_QUERY_KEY,
    PRICE_STEP_TRIP_QUERY_KEY,
    TRIP_PLAIN_QUERY_KEY,
    USERTRIP_PLAIN_QUERY_KEY,
    DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY,
    DAY_BY_DAY_STEP_TRIPDAY_TITLE_QUERY_KEY,
    DAY_BY_DAY_STEP_TRIP_QUERY_KEY,
  ];

  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();
  const doubleFields = secondSelectedLanguageIndex !== null;

  return secondSelectedLanguageIndex === null ? (
    <Flex justifyContent={"center"}>
      <LanguageSwitcherAbstract
        selectedLanguageIndex={firstSelectedLanguageIndex}
        firstInput={true}
        doubleFields={doubleFields}
        getValues={getValues}
        isFirst={isFirst}
        name={name}
        elementId={elementId}
        addLanguageCall={addLanguageCall}
        removeLanguageCall={removeLanguageCall}
        invalidateQueries={invalidateQueries}
        forceNew={forceNew}
      />
    </Flex>
  ) : (
    <Flex mb={3} justifyContent="space-around" w={width} mx="auto">
      <div className="flex">
        <Tooltip
          label={`You can't edit the fields below as these are filled in by '${trip?.userTrips[0].brand?.name}'.`}
          placement="top"
          bg="gray.100"
          color="black"
          fontWeight={400}
        >
          <InfoOutlineIcon boxSize={3} />
        </Tooltip>
        <Heading pl={1} size="lg" w="100%" textAlign={"center"}>
          {trip?.userTrips[0].brand.name}
          {`'s text`} &nbsp;
          <LanguageSwitcherAbstract
            selectedLanguageIndex={firstSelectedLanguageIndex}
            firstInput={true}
            doubleFields={doubleFields}
            getValues={getValues}
            isFirst={isFirst}
            name={name}
            elementId={elementId}
            addLanguageCall={addLanguageCall}
            removeLanguageCall={removeLanguageCall}
            invalidateQueries={invalidateQueries}
            forceNew={forceNew}
          />
        </Heading>
      </div>
      {!isFirst && (
        <div className="flex">
          <Tooltip
            label="Customize this trip by filling out the fields below. When the fields on the left are in the same language as the fields on the right, then the fields on the right take priority over the fields on the left."
            placement="top"
            bg="gray.100"
            color="black"
            fontWeight={400}
          >
            <InfoOutlineIcon boxSize={3} />
          </Tooltip>
          <Heading size="lg" pl={1} w="100%" textAlign={"center"}>
            Your text &nbsp;
            <LanguageSwitcherAbstract
              selectedLanguageIndex={secondSelectedLanguageIndex}
              firstInput={false}
              doubleFields={doubleFields}
              getValues={getValues}
              isFirst={isFirst}
              name={name}
              trip={trip}
              elementId={elementId}
              addLanguageCall={addLanguageCall}
              removeLanguageCall={removeLanguageCall}
              invalidateQueries={invalidateQueries}
              forceNew={forceNew}
            />
          </Heading>
        </div>
      )}
    </Flex>
  );
};

export default React.memo(TranslateTitle);
