import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Icon, IconButton, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { Trash2 as DeleteIcon } from "lucide-react";
import React from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { MdDone, MdModeEdit } from "react-icons/md";
import NotesAPI from "../../api/notes.api";
import { useMeContext } from "../../stores/me-context";
import colors from "../../theme/foundations/colors";
import { TNote } from "@lato/common";
import { CONTACT_NOTES_QUERY_KEY, USERTRIP_NOTES_QUERY_KEY } from "../../utils/constants";
import { prettyPrintDate } from "../../utils/date";
import sanitizeXSS from "../../utils/sanitizeXSS";
import ConfirmPopover from "../../components/CRUD/ConfirmPopover";
import { SmallAddResourceButton } from "../../components/CRUD/Resource";
import { CustomSpinner } from "../../components/FullScreenSpinner";
import Form from "../../components/form/Form";
import RHFRichTextEditor2 from "../../components/input/wysiwyg/RHFRichTextEditor2";
import Section from "../../components/layout/Section";
import { TripLanguageProvider } from "../../stores/trip-language-context";

interface NotesProps {
  parentObject: any;
  topButtonPos?: string;
  maxHeight?: string;
  height?: string;
  hideSmallAdd?: boolean;
  notes?: TNote[];
}

export const NotesWrapper: React.FC<NotesProps> = ({ notes, ...props }) => {
  const formMethods = useForm<{ notes: TNote[] }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: { notes },
    shouldUnregister: false,
    criteriaMode: "all",
  });

  return (
    <Form formMethods={formMethods} onSubmit={() => {}}>
      <Notes {...props} />
    </Form>
  );
};

const Notes: React.FC<NotesProps> = ({
  parentObject,
  topButtonPos = "-4em",
  maxHeight = "20em",
  height = "88.5%",
  hideSmallAdd = false,
}) => {
  const me = useMeContext();
  const [isEditing, setIsEditing] = React.useState<number | undefined>();
  const {
    fields: notes,
    remove,
    append,
  } = useFieldArray({
    // control,
    name: "notes",
    // @ts-ignore
    keyName: "rhfId", // default to "id", you can change the key name
  });

  const queryClient = useQueryClient();
  const emptyNoteBase = {
    updated_at: new Date(),
    created_at: new Date(),
    created_by: me,
    updated_by: me,
    text: "",
  };

  const emptyNote = parentObject.hasOwnProperty("contact_type")
    ? {
        contact: parentObject,
        ...emptyNoteBase,
      }
    : { trip: parentObject, ...emptyNoteBase };

  const { mutateAsync: createNote, isPending: isCreatingNote } = useMutation({
    mutationFn: () => NotesAPI.post({ ...emptyNote }),
    onSuccess: (response) => {
      append(response);
      queryClient.invalidateQueries({
        queryKey: [CONTACT_NOTES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [USERTRIP_NOTES_QUERY_KEY],
      });
    },
  });

  return (
    <Box position="relative" h={height}>
      {!hideSmallAdd && (
        <SmallAddResourceButton
          singularDisplayName={"note"}
          onAdd={async () => {
            await createNote();
            setIsEditing(notes.length);
          }}
          position={"absolute"}
          left={"9.5em"}
          top={`calc(${topButtonPos} + 0.1em)`}
          isDisabled={!!isEditing}
        />
      )}
      <Button
        position="absolute"
        leftIcon={<AddIcon />}
        onClick={async () => {
          await createNote();
          setIsEditing(notes.length);
        }}
        right={0}
        top={topButtonPos}
        colorScheme={"brand"}
        isDisabled={!!isEditing}
      >
        Add note
      </Button>
      <Flex
        h={"100%"}
        flexDirection={"column"}
        gap={3}
        maxHeight={maxHeight}
        overflow={notes.length > 0 ? "scroll" : "hidden"}
        pb={1}
      >
        {notes.length > 0 ? (
          notes.map((note, i) => (
            <Note
              rhfId={note.rhfId}
              index={i}
              removeField={(i: number) => {
                remove(i);
              }}
              queryClient={queryClient}
              emptyNote={emptyNote}
              key={`note-${note.rhfId}`}
              fieldName={"notes"}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          ))
        ) : (
          <Section noDivider>
            <Text textAlign={"center"} mt={"3em"}>
              No notes yet...
            </Text>
          </Section>
        )}
      </Flex>
    </Box>
  );
};

export default Notes;

interface NoteElementProps {
  rhfId: string;
  removeField: (i: number) => void;
  index: number;
  emptyNote: any;
  queryClient: QueryClient;
  fieldName: string;
  isEditing?: number;
  setIsEditing: any;
}

const Note: React.FC<NoteElementProps> = ({
  rhfId,
  removeField,
  index,
  emptyNote,
  queryClient,
  fieldName,
  isEditing,
  setIsEditing,
}) => {
  const { getValues, setValue } = useFormContext();

  const prefix = `${fieldName}.${index}`;
  const note = getValues(prefix);

  const [editing, setEditing] = React.useState<boolean>(isEditing === index);

  const { mutateAsync: updateNote } = useMutation({
    mutationFn: (text: string) =>
      note.id ? NotesAPI.patch(note.id, { ...emptyNote, text }) : NotesAPI.post({ ...emptyNote, text }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONTACT_NOTES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [USERTRIP_NOTES_QUERY_KEY],
      });
    },
  });

  const { mutateAsync: removeNote } = useMutation({
    mutationFn: (id: string) => NotesAPI.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONTACT_NOTES_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [USERTRIP_NOTES_QUERY_KEY],
      });
    },
  });

  const confirmDiscl = useDisclosure();

  const saveAndClose = () => {
    const notesElement = document!.getElementById(`note-input-${rhfId}`);
    const val = notesElement!.innerHTML;
    if (!val.includes(`data-placeholder="`)) {
      updateNote(val);
    }
    setValue(`${prefix}.updated_at`, new Date());
    setEditing(false);
    setIsEditing(false);
  };

  return (
    <Section py={2} noDivider height={"fit-content"} id={`note-${rhfId}`}>
      <TripLanguageProvider first={true} doubleFields={false} defaultPartialFields={[]} enabled={false}>
        <RHFRichTextEditor2
          showLoadText={false}
          id={`note-input-${rhfId}`}
          name={`${prefix}.text`}
          // value={text}
          display={editing ? "block" : "none"}
          fontSize={"12px"}
          // placeholder={"new note..."}
        />
      </TripLanguageProvider>
      {!editing && (
        <Text
          p={1}
          dangerouslySetInnerHTML={{
            __html: sanitizeXSS(note.text ?? ""),
          }}
        ></Text>
      )}
      <Flex gap={1} justifyContent={"space-between"} alignItems={"center"} p={1}>
        <Text fontSize={"12px"} fontWeight={400}>
          <em>{note.updated_by?.name ?? note.created_by?.name} </em>
        </Text>
        <Text fontSize={"12px"} mt={"0.1em"} fontWeight={400}>
          {prettyPrintDate(note.updated_at ?? note.created_at ?? new Date(), "default", "full", true)}
        </Text>
        <Flex>
          {editing ? (
            <Tooltip label="Save" aria-label="edit-record-tooltip">
              <IconButton
                ml={"auto"}
                icon={<Icon as={MdDone} boxSize={6} color={colors.lato.primaryColor} />}
                aria-label={"edit-note"}
                variant={"ghost"}
                onClick={() => {
                  setIsEditing(false);
                  saveAndClose();
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip label="Edit" aria-label="edit-record-tooltip">
              <IconButton
                ml={"auto"}
                icon={<Icon as={MdModeEdit} boxSize={4} color={"gray.600"} />}
                aria-label={"edit-note"}
                variant={"ghost"}
                onClick={() => {
                  setEditing(true);
                  setIsEditing(index);
                }}
              />
            </Tooltip>
          )}
          <ConfirmPopover
            disclosure={confirmDiscl}
            action={(e) => {
              removeField(index);
              if (note.id) {
                removeNote(note.id);
              }
              if (editing) {
                setIsEditing(false);
              }
            }}
          >
            <Tooltip label="Remove" aria-label="remove-record-tooltip">
              <IconButton
                ml={"auto"}
                colorScheme="red"
                icon={<Icon as={DeleteIcon} boxSize={4} />}
                aria-label={"delete-note"}
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDiscl.onOpen();
                }}
                variant={"ghost"}
              />
            </Tooltip>
          </ConfirmPopover>
        </Flex>
      </Flex>
    </Section>
  );
};
