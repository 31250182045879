import { Company, User } from "@lato/common";
import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "companies";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

// Get the specific invite team member REST endpoint
const inviteTeamMember = (email: string) => {
  return rawApiProvider.post(url + "/invite", { email });
};

const getInviteInformation = (token: string) => {
  return rawApiProvider.get(url + "/invite/" + token);
};

const acceptInvitation = (token: string) => {
  return rawApiProvider.post(url + "/accept-invite", { token });
};

// Get my company REST endpoint
const getMyCompany = () => {
  return rawApiProvider.get("my-company");
};

// Get my company tram REST endpoint
const getMyCompanyTeam = () => {
  return rawApiProvider.get("my-company/team");
};

const changeLogo = (companyId: string, s3key: string) => {
  return rawApiProvider.patch(url + "/" + companyId + "/logo", { logo: { key: s3key } });
};

const updateCompany = (company: Partial<Company>) => {
  return rawApiProvider.patch(url, company);
};

const saveDeeplApiKey = (apiKey: string) => {
  return rawApiProvider.post(url + "/deepl-api-key", { deeplApiKey: apiKey });
};

const getPaginatedCompanies = (queryParams?: string) => {
  return rawApiProvider.get(`paginated-companies${queryParams ? `?${queryParams}` : ""}`);
};

const findCompaniesWhere = (queryParams?: string) => {
  return rawApiProvider.get(`find-companies-where${queryParams ? `?${queryParams}` : ""}`);
};

const getAllCompanies = () => {
  return rawApiProvider.get(url);
};

type CompanyAPIType = ReturnEndpoints & {
  inviteTeamMember(email: string): Promise<any>;
  acceptInvitation(token: string): Promise<any>;
  getMyCompany(): Promise<any>;
  getAllCompanies(): Promise<any>;
  getMyCompanyTeam(): Promise<any>;
  changeLogo(companyId: string, s3key: string | null): Promise<any>;
  updateCompany(company: Partial<Company>): Promise<any>;
  saveDeeplApiKey(apiKey: string): Promise<any>;
  getInviteInformation(token: string): Promise<{
    invitingUser: User;
    newUserEmail: string;
    newUser: User | undefined;
  }>;
  getPaginatedCompanies(queryParams?: string): Promise<any>;
  findCompaniesWhere(queryParams?: string): Promise<any>;
};
const CompanyAPI: CompanyAPIType = {
  ...apiEndpoints,
  inviteTeamMember,
  getAllCompanies,
  getMyCompany,
  getMyCompanyTeam,
  acceptInvitation,
  changeLogo,
  updateCompany,
  saveDeeplApiKey,
  getInviteInformation,
  getPaginatedCompanies,
  findCompaniesWhere,
};
export default CompanyAPI;
