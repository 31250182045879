import { encodeToLettersAndNumbersOnly } from "@lato/common";
import { FilesAPI } from "../api/files.api";
import { getMimeType, uploadToS3 } from "./s3Upload";

export async function submitDocumentsArray(docs: any, folder: string) {
  if (!docs) return;
  // First retrieve all necessary presigned s3 urls
  const signRequests: {
    filename: string;
    filetype: string;
    folder: string;
  }[] = [];
  for (let d = 0; d < docs.length; d++) {
    const doc = docs[d];
    // if the file property exists in the document object,
    // meaning that the document is just uploaded using react-dropzone,
    // only then upload the file to S3
    if ("file" in doc && doc.file != undefined) {
      const fileType = doc.file.type || getMimeType(doc.file.name) || "";
      signRequests.push({
        filename: encodeToLettersAndNumbersOnly(doc.file.name),
        filetype: fileType,
        folder: folder,
      });
    } else {
      signRequests.push({
        filename: "ignore",
        filetype: "ignore",
        folder: "ignore",
      });
    }
  }
  if (signRequests.length > 0) {
    const response = await FilesAPI.s3SignBulk({
      signRequests: signRequests.filter(
        (s: any) => s.filename !== "ignore" && s.filetype !== "ignore" && s.folder !== "ignore",
      ),
    });

    let r = 0;

    const responseData = signRequests.map((req) => {
      let returnVal = null;
      if (req.filename === "ignore" && req.filetype === "ignore" && req.folder === "ignore") {
        returnVal = null;
      } else {
        returnVal = response.data[r];
        r++;
      }
      return returnVal;
    });

    // Then upload all the documents to s3 using the presigned s3 urls
    // for (let s = 0; s < docs.length; s++) {
    //   const doc = docs[s];
    await Promise.all(
      docs.map(async (doc: any, s: number) => {
        if ("file" in doc && doc.file != undefined) {
          await uploadToS3(doc.file, signRequests[s].filetype, responseData[s]?.signedRequest ?? "");
          // if the document has a valid file object, then it is probably just uploaded using react-cropper/react-dropzone, so revoke the temporary URL
          if (doc.s3url) {
            URL.revokeObjectURL(doc.s3url);
            // Set the received s3key such that it can be saved in the database
            docs[s].s3 = { key: responseData[s]?.s3key };
          }
          if (doc.url) {
            URL.revokeObjectURL(doc.url);
            // Set the received s3key such that it can be saved in the database
            docs[s].s3 = { key: responseData[s]?.s3key };
          }
          if (doc.logoUrl) {
            URL.revokeObjectURL(doc.logoUrl);
            // Set the received s3key such that it can be saved in the database
            docs[s].logo = { key: responseData[s]?.s3key };
          }
          // Remove the file key from the object such that it is not uploaded to the ec2 server as well.
          delete docs[s].file;
          // We don't have to remove the s3url or the imageUrl as this is small and this will be filtered out on the server by the class-validator
        }
      }),
    );
  }
}
