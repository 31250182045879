import { Box, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";

import { CONTACTTYPE, Contact } from "@lato/common";
import ContactAPI from "../../api/contacts.api";
import { addToast } from "../../utils/addToast";
import { DSAccessorProps, DSListItemConfig } from "../CustomDownShift/CustomComboBox";
import CustomDownShiftWithPagination from "../CustomDownShift/CustomDownShiftWithPagination";
import { SingleContact } from "../../features/contactGroups/AddContactRelations";
import CreateContact from "../../features/contacts/CreateContact";

interface SearchContactsProps {
  assignContactsToTrip: (doc: Partial<Contact>) => void;
  fields: any;
  disclosure: any;
  placeholder?: string;
  isDropdown?: boolean;
  isCreateContactDisabled?: boolean;
  initialInputValue?: string;
}

const SearchContacts: React.FC<SearchContactsProps> = ({
  assignContactsToTrip,
  fields,
  disclosure,
  placeholder = "Add contact...",
  isDropdown = true,
  isCreateContactDisabled = false,
  initialInputValue,
}) => {
  const [defaultInput, setDefaultInput] = React.useState<string[]>();
  const toast = useToast();

  const handleOnClickCreateContact = async ({ inputValue }: DSAccessorProps<any>) => {
    const names = inputValue.split(" ");
    const firstNameVal = names[names.length - 1];
    const firstName = firstNameVal.charAt(0).toUpperCase() + firstNameVal.slice(1);
    const lastName = names
      .slice(0, names.length - 1)
      .map((n: string) => n.charAt(0).toUpperCase() + n.slice(1))
      .join(" ");
    setDefaultInput([firstName, lastName]);
    disclosure.onOpen();
  };

  console.log("initialInputValue", initialInputValue);
  const customListItems: DSListItemConfig<any>[] = React.useMemo(() => {
    return isCreateContactDisabled
      ? []
      : [
          // {
          //   ListItem: ({ inputValue }) => `Use '${inputValue}'`,
          //   showSelected: ({ inputValue }) => inputValue,
          //   isHidden: ({ inputValue }) => inputValue.length === 0,
          //   onSelected: handleOnClickManual,
          // },
          {
            ListItem: ({ inputValue }) => `Create & Add person '${inputValue}'`,
            showSelected: ({ inputValue }) => inputValue,
            isHidden: ({ inputValue }) => inputValue.length <= 1,
            onSelected: handleOnClickCreateContact,
          },
        ];
  }, [handleOnClickCreateContact]);
  return (
    <>
      <Box width={"100%"}>
        <CustomDownShiftWithPagination
          apiCall={(queryParams?: string) => ContactAPI.getAll(queryParams)}
          queryName="SEARCH_CONTACTS"
          customListItemConfigs={customListItems}
          listItemConfig={{
            ListItem: SingleContact,
            onSelected: ({ item }) => {
              if (fields.length) {
                if (fields.find((field: Contact) => field.id === item.id)) {
                  addToast(toast, {
                    title: "Contact already linked with this trip",
                    status: "error",
                  });
                  return;
                }
              }
              assignContactsToTrip(item);
            },
          }}
          additionalQueryState={{
            step: 5,
            page: 1,
            contact_type: CONTACTTYPE.PERSON,
          }}
          placeholder={placeholder}
          isDropdown={isDropdown}
          height={"200px"}
          initialInputValue={initialInputValue}
        />
      </Box>
      {disclosure.isOpen && (
        <CreateContact
          isOpen={disclosure.isOpen}
          defaultfirst_name={defaultInput && defaultInput[0]}
          defaultlast_name={defaultInput && defaultInput[1]}
          disableCompany
          onClose={(e) => {
            disclosure.onClose();
            if (e && e.hasOwnProperty("contact_type")) {
              assignContactsToTrip(e);
            }
          }}
          buttonText={"Create & Add person"}
        />
      )}
    </>
  );
};
export default React.memo(SearchContacts);
