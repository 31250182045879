import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { PRIMARY_COLOR, SECONDARY_COLOR, CompanyBrand } from "@lato/common";
import React from "react";
import { FaStar } from "react-icons/fa";
import LogoUploader from "../../components/fileUpload/dropzone/logoUploader";
import RHFColor from "../../components/input/RHFColor";
import RHFInput from "../../components/input/RHFInput";
import Section from "../../components/layout/Section";

interface BrandingInfoProps {
  id: string | undefined;
  brand: CompanyBrand;
  index: number;
  setBrandAsDefault: (i: number) => void;
  saveBrand: (brand: CompanyBrand, i: number) => void;
}

const BrandingInformation: React.FC<BrandingInfoProps> = ({ id, brand, index, setBrandAsDefault, saveBrand }) => {
  const originalLogo = brand.logoUrl;
  const originalLogoScale = brand.logoScale;
  const orginalFile = brand.file;

  const handleChooseImage = (blob: Blob) => {
    if (brand.file != undefined) {
      // Revoke the url of the previous image
      URL.revokeObjectURL(brand.logoUrl!);
    }
    // create a new file with as filename the location name of the day.
    const f = new File([blob], brand.id!, { type: "image/png" });
    brand.logoScale = 1;
    brand.logoUrl = URL.createObjectURL(f);
    brand.file = f;
  };

  const handleCancel = () => {
    brand.logoUrl = originalLogo;
    brand.logoScale = originalLogoScale;
    brand.file = orginalFile;
  };

  const handleChangeLogoScale = (newValue: number) => {
    brand.logoScale = newValue;
    saveBrand(brand, index);
  };

  const handleDeleteLogo = () => {
    brand.logoUrl = undefined;
    brand.file = undefined;
    brand.logo!.key = "";
    saveBrand(brand, index);
  };

  // used to display if brand is default
  const extraInfo = brand.default_brand ? (
    <Flex alignItems={"center"} gap={2}>
      <Text textAlign={"center"} textColor={"brand.600"} size="md">
        Default
      </Text>
      <FaStar size={"1.5em"} color="#F2C519" />
    </Flex>
  ) : (
    <></>
  );
  const fieldName = `brands.${index}`;

  return (
    <Section title={"Branding Information"} id={id} headingExtra={extraInfo}>
      <Stack direction={{ base: "column", md: "row" }} spacing={4} align={"start"} mb={"1em"}>
        <Box w={"10em"}>
          <LogoUploader
            brand={brand}
            handleChooseImage={handleChooseImage}
            handleChangeLogoScale={handleChangeLogoScale}
            deleteLogo={handleDeleteLogo}
            handleCancel={handleCancel}
          />
        </Box>
        <Flex flexDir={"column"} gap={3}>
          <Stack key={fieldName}>
            <RHFInput name={`${fieldName}.default_brand`} hidden />
            <RHFInput name={`${fieldName}.name`} defaultValue={brand.name} />
            <RHFColor name={`${fieldName}.theme.primary_color`} resetValue={PRIMARY_COLOR} />
            <RHFColor name={`${fieldName}.theme.secondary_color`} resetValue={SECONDARY_COLOR} />
          </Stack>
        </Flex>
      </Stack>
      {!brand.default_brand && (
        <Button
          alignSelf={"flex-start"}
          size={"sm"}
          float={"left"}
          fontSize={"md"}
          colorScheme="brand"
          outline={"ghost"}
          onClick={() => setBrandAsDefault(index)}
        >
          Make Default Brand
        </Button>
      )}
    </Section>
  );
};

export default BrandingInformation;
