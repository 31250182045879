import { ImageSite, transformImage } from "@lato/common";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "@chakra-ui/react";
import { CustomSpinner } from "../../../components/FullScreenSpinner";
import TranslateTitle from "../../../components/input/TranslateTitle";
import { useMeContext } from "../../../stores/me-context";
import { useTripLanguage } from "../../../stores/trip-language-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import { GENERAL_STEP_USERTRIP_QUERY_KEY, GENERAL_STEP_TRIP_QUERY_KEY } from "../../../utils/constants";
import { getViewTripLink } from "../../../utils/generateURLs";
import { checkUserTranslated } from "../../../utils/getLanguages";
import {
  useSplitUserTrip,
  getRelationMap,
  useSplitTrip,
  useTripTranslations,
} from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import WizardButtonGroup from "./WizardButtonGroup";
import { WizardTitleNewLayout } from "./WizardTitleNewLayout";

export const TripInfoWrapper = () => {
  const { id: userTripId } = useParams();
  const me = useMeContext();

  const { isFirst, trip: tripFromStore } = useTripFormStore();
  const { data: usertrips } = useSplitUserTrip(
    userTripId!,
    getRelationMap[GENERAL_STEP_USERTRIP_QUERY_KEY],
    GENERAL_STEP_USERTRIP_QUERY_KEY,
  );
  const { data: trip } = useSplitTrip(
    tripFromStore?.id,
    GENERAL_STEP_TRIP_QUERY_KEY,
    getRelationMap[GENERAL_STEP_TRIP_QUERY_KEY],
  );
  const { data: tripTranslations, isLoading: isLoadingTranslations } = useTripTranslations(trip?.id!);

  if (!trip || !usertrips || !tripTranslations)
    return (
      <div className="space-y-2">
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} h="20px" w="100%" />
        ))}
      </div>
    );

  const hasThisUserTranslated = checkUserTranslated(tripTranslations, me);
  const viewTripLink = getViewTripLink(me?.company?.name || "", trip?.titles?.[0]?.content || "trip", userTripId!);
  const doubleFields = !isFirst && hasThisUserTranslated;

  return (
    <>
      <TripInfo
        trip={trip}
        usertrips={usertrips}
        tripTranslations={tripTranslations}
        isLoadingTranslations={isLoadingTranslations}
      />
      {trip && <WizardButtonGroup viewTripLink={viewTripLink} />}
    </>
  );
};

export const TripInfo = ({ trip, usertrips, isLoadingTranslations, tripTranslations }: any) => {
  const { id: userTripId } = useParams();
  const me = useMeContext();
  const { isFirst, trip: tripFromStore } = useTripFormStore();
  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();

  if (!tripTranslations || !tripFromStore) return null;

  const hasThisUserTranslated = checkUserTranslated(tripTranslations, me);
  const doubleFields = !isFirst && hasThisUserTranslated;

  const title =
    trip?.titles?.[isFirst ? firstSelectedLanguageIndex : (secondSelectedLanguageIndex ?? 0)]?.content || "";
  const client_name = trip?.client_name || "";
  const tripdays = trip?.tripdays || [];
  const start_date = trip?.start_date || "";
  const end_date = trip?.end_date || "";
  const numberOfNights = tripdays.reduce((partialSum: number, td: any) => partialSum + td.nrOfNights, 0);
  const flagImageUrl = trip?.country?.flagImage || "";
  const usertrip = usertrips.find((ustr: any) => ustr.id === userTripId) || null;
  const tripday = trip?.tripdays?.[usertrip?.homeBackgroundNumber || 0] || {};
  const brand = usertrip?.brand?.name || "";
  const external_ref = usertrip?.ref || "";
  const status = usertrip?.status || "";

  const tripdayImage = tripday?.image || tripday?.libraryImage || undefined;
  let tripdayBackgroundUrl = "";

  if (tripdayImage?.site === ImageSite.CUSTOMVIDEO) {
    tripdayBackgroundUrl = tripdayImage.originUrl;
  } else {
    tripdayBackgroundUrl = transformImage(tripdayImage, { size: "small" });
  }

  const getValues = useCallback((value: string) => (tripTranslations || {})[value], [tripTranslations]);

  return (
    <>
      {isLoadingTranslations ? (
        <CustomSpinner />
      ) : (
        <TranslateTitle isFirst={isFirst} name="priceDescriptions" getValues={getValues} width="100%" />
      )}
      {isLoadingTranslations ? (
        <CustomSpinner />
      ) : (
        <WizardTitleNewLayout
          title={title}
          client_name={client_name}
          refvalue={external_ref}
          first={isFirst}
          nrOfNights={numberOfNights}
          status={status}
          start_date={start_date}
          end_date={end_date}
          brand={brand}
          tripdayImage={tripdayImage}
          tripdayBackgroundUrl={tripdayBackgroundUrl}
          flagImageUrl={flagImageUrl}
          isLoadingTranslations={isLoadingTranslations}
        />
      )}
    </>
  );
};

export default TripInfo;
