import { useToast } from "@chakra-ui/react";
import { addToast } from "../../addToast";

const ERROR_TOAST_ID = "toast_error";
const SUCCESS_TOAST_ID = "toast_success";
const LOADING_TOAST_ID = "toast_loading";

export function showLoadingToast(toast: any) {
  if (!toast.isActive(LOADING_TOAST_ID)) {
    addToast(toast, {
      title: `Saving trip`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  }
}

export function showErrorToast(toast: any) {
  if (!toast.isActive(ERROR_TOAST_ID)) {
    addToast(toast, {
      title: `Failed saving trip`,
      description: "Please try again",
      status: "error",
    });
  }
}

export function showSuccessToast(toast: any) {
  if (!toast.isActive(SUCCESS_TOAST_ID)) {
    // Other patches could still be saving... So check the isSubmittingForms array to see if any are true
    addToast(toast, {
      title: `Successfully saved trip`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  }
}

export const useStateToasts = () => {
  return {
    errorToast: useToast({
      id: ERROR_TOAST_ID,
      position: "bottom-right",
    }),
    successToast: useToast({
      id: SUCCESS_TOAST_ID,
      position: "bottom-right",
    }),
    loadingToast: useToast({
      id: LOADING_TOAST_ID,
      position: "bottom-right",
    }),
  };
};
