import { Button, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react";
import {
  ExternalImage,
  ImageSite,
  Image as ImageType,
  TLibraryImage,
  TLocation,
  getKeyFromImageUrl,
} from "@lato/common";
import React from "react";
import { useFormContext } from "react-hook-form";
import { IoCamera as Camera } from "react-icons/io5";
import ImageUploadModal from "../../features/external-images/ImageUploadModal";
import transformExternalImage from "../../utils/transformExternalImage";

interface TripdayExtraPictureUploaderProps {
  aspectRatio: number;
  choosePictures: (images: ImageType[]) => void;
  chooseLibraryPictures: (images: TLibraryImage[]) => void;
  nrOfImages: number;
  defaultSearchValue: string;
  defaultSearchValueName?: string;
  awsFilename: string;
  location?: TLocation;
}

const TripdayExtraPictureUploader: React.FC<TripdayExtraPictureUploaderProps> = ({
  awsFilename,
  nrOfImages,
  aspectRatio,
  choosePictures,
  chooseLibraryPictures,
  defaultSearchValue,
  defaultSearchValueName,
  location,
}) => {
  const disclosure = useDisclosure();
  const [defaultSearchValueState, setDefaultSearchValueState] = React.useState(defaultSearchValue);
  const { getValues } = useFormContext();

  const handleChosenLocalPictures = (blob: Blob | Blob[]) => {
    // blob will always be an array as the multiple prop is set to true
    if (!Array.isArray(blob)) return;
    const newImages: ImageType[] = [];
    for (let i = 0; i < blob.length; i++) {
      // create a new file with as filename the location name of the day.
      const f = new File([blob[i]], awsFilename, {
        type: "image/jpeg",
      });
      const ord = nrOfImages + i;
      newImages.push({
        site: ImageSite.S3,
        file: f,
        url: URL.createObjectURL(f),
        s3: { key: "" },
        ord,
      });
    }

    choosePictures(newImages);
  };

  const handleChosenExternalPictures = (photos: ExternalImage | ExternalImage[]) => {
    // photos will always be an array as the multiple prop is set to true
    if (!Array.isArray(photos)) return;
    const newImages: ImageType[] = [];
    for (let i = 0; i < photos.length; i++) {
      const ord = nrOfImages + i;
      const photo = photos[i];
      newImages.push({
        site: photo.imageSite,
        s3: { key: getKeyFromImageUrl(photo.urls.raw, photo.imageSite) },
        url: transformExternalImage(photo),
        ord,
      });
    }
    choosePictures(newImages);
  };

  const handleChosenLibraryPicture = (photos: TLibraryImage | TLibraryImage[]) => {
    if (!Array.isArray(photos)) return;
    const newImages: TLibraryImage[] = [];
    for (let i = 0; i < photos.length; i++) {
      const ord = nrOfImages + i;
      const photo = photos[i];
      newImages.push({
        ...photo,
        ord,
      });
    }
    chooseLibraryPictures(newImages);
  };

  const handleChosenLinkPictures = (images: ImageType[]) => {
    choosePictures(images.map((image) => ({ ...image, s3: { key: image.url } })));
  };

  const handleAddImage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if ((!defaultSearchValue || defaultSearchValue === "") && defaultSearchValueName) {
      setDefaultSearchValueState(getValues(defaultSearchValueName));
    }

    disclosure.onOpen();
  };

  return (
    // <Button
    //   w="100%"
    //   borderRadius="lg"
    //   bg="gray.200"
    //   _hover={{ bg: "gray.300" }}
    //   transition="ease 0.3s"
    //   onClick={handleAddImage}
    //   type="button"
    //   leftIcon={<Icon as={Camera} boxSize={4} />}
    // >
    //   <Text>Add image</Text>
    //   <ImageUploadModal
    //     aspectRatio={aspectRatio}
    //     disclosure={disclosure}
    //     i={i}
    //     handleChosenLocalPicture={handleChosenLocalPicture}
    //     handleChosenUnsplashPicture={handleChosenUnsplashPicture}
    //   />
    // </Button>
    <Button
      onClick={handleAddImage}
      h="auto"
      color="brand.500"
      background="gray.100"
      // leftIcon={<Icon as={Camera} boxSize={4} />}
    >
      <Flex flexDir="column" justifyContent="center" alignItems="center" my={1}>
        <Icon as={Camera} boxSize={6} />
        <Text fontSize="sm" fontWeight="300">
          Image
        </Text>
      </Flex>
      {disclosure.isOpen && (
        <ImageUploadModal
          aspectRatio={aspectRatio}
          disclosure={disclosure}
          defaultSearchValue={defaultSearchValueState}
          handleChosenLocalPictures={handleChosenLocalPictures}
          handleChosenExternalPictures={handleChosenExternalPictures}
          handleChosenLinkPictures={handleChosenLinkPictures}
          handleChosenLibraryPictures={handleChosenLibraryPicture}
          multiple={true}
          nrOfImages={nrOfImages}
          location={location}
        />
      )}
    </Button>
  );
};
export default React.memo(TripdayExtraPictureUploader);
