import { useJsApiLoader } from "@react-google-maps/api";
import FullScreenSpinner from "../components/FullScreenSpinner";
import config from "../config";
import ENV from "./env";

const libraries: any = ["places"];

const useLoadGoogleMap = () => {
  // const tripLanguageContext = useTripLanguageWithoutError();
  // const language_code = tripLanguageContext
  //   ? tripLanguageContext.partialFields[tripLanguageContext.realLanguageIndex]
  //       .language.code
  //   : undefined;

  const { isLoaded, loadError } = useJsApiLoader({
    language: "en", // without this the default browser language is used.
    googleMapsApiKey: config[ENV].google_maps_api_key,
    libraries,
  });
  /* if (loadError) {
    return <ErrorCardView title="Could not load map." />;
  }*/
  if (!isLoaded) {
    return <FullScreenSpinner />;
  }
};
export default useLoadGoogleMap;
