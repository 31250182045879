import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { DUE_DAYS_TYPE, calculateDueDate, omitLocalTimezone } from "@lato/common";
import React, { useCallback } from "react";
import { DayPicker as ReactDayPicker, useInput, type ActiveModifiers, type UseInputOptions } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useFormContext } from "react-hook-form";
import RHFInput from "./RHFInput";
import RHFSelect from "./RHFSelect";

export interface DayPickerProps {
  fromDate?: Date;
  toDate?: Date;
  customOnClickHandler?: any;
  defaultDate?: Date | null;
  isReadOnly?: boolean;
  inputWidth?: string;
  header?: JSX.Element;
  label?: string;
  dateMode: boolean;
  setDateMode: (mode: boolean) => void;
  start_date?: Date;
  end_date?: Date;
  hideDateMode?: boolean;
}

const DueDayPicker: React.FC<DayPickerProps> = ({
  required = false,
  fromDate,
  toDate,
  customOnClickHandler,
  defaultDate,
  isReadOnly = false,
  inputWidth = "120px",
  header,
  label,
  dateMode,
  setDateMode,
  start_date,
  end_date,
  hideDateMode = false,
  ...props
}) => {
  const formContext = useFormContext();
  const defaultDateWithoutTimezone = defaultDate ? omitLocalTimezone(defaultDate) : undefined;
  const dateRef = React.useRef<any>();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const options: UseInputOptions = {
    defaultSelected: defaultDateWithoutTimezone ?? (required ? new Date() : undefined),
    fromDate,
    toDate,
    toYear: 2022,
    format: "dd.MM.yyyy",
    required,
  };

  const input = useInput(options);
  const handleOnDayClick = (day: Date, modifiers: ActiveModifiers, e: React.MouseEvent) => {
    day.setDate(day.getDate() + 1);
    if (input.dayPickerProps.onDayClick) input.dayPickerProps.onDayClick(day, modifiers, e);
    if (customOnClickHandler) {
      customOnClickHandler(day);
    }
    setDateMode(true);
  };

  React.useEffect(() => {
    const defaultDateWithoutTimezone = defaultDate ? omitLocalTimezone(defaultDate) : undefined;
    input.setSelected(defaultDateWithoutTimezone);
  }, [defaultDate]);

  const openDatePicker = () => {
    if (!isReadOnly) {
      onOpen();
    }
  };

  const dueDate = useCallback(() => {
    return calculateDueDate(
      start_date!,
      end_date!,
      formContext.watch().due_days ?? 7,
      formContext.watch().due_days_type ?? DUE_DAYS_TYPE.BEFORE_START,
    ).toDateString();
  }, [end_date, formContext, start_date]);

  return (
    <Popover isOpen={isOpen} onClose={onClose} closeOnBlur>
      <PopoverAnchor>
        <InputGroup display="block" w={inputWidth} display="flex" onClick={openDatePicker}>
          {label && (
            <Box w="100%">
              <FormLabel mt={2} mr={0} w="11em">
                {label}
              </FormLabel>
            </Box>
          )}
          {dateMode ? (
            <>
              <Input
                {...props}
                {...input.inputProps}
                name="due_date"
                autoComplete="nope"
                ref={dateRef}
                placeholder="dd.mm.yyyy"
                isReadOnly={isReadOnly}
                mt="0.09rem"
              />
            </>
          ) : (
            <Input
              name="due_days"
              autoComplete="nope"
              placeholder="test"
              isReadOnly={isReadOnly}
              mt="0.09rem"
              value={`${formContext.watch()?.due_days ?? 0} days ${formContext.watch().due_days_type?.toLowerCase()}`}
            />
          )}
          {!props.isDisabled && (
            <InputRightElement cursor="pointer">
              <IconButton
                icon={<CalendarIcon />}
                variant="basic"
                aria-label="daterange-calendar-icon"
                isDisabled={isReadOnly}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </PopoverAnchor>
      {isOpen && (
        <Portal>
          <Box zIndex={5000} position={"relative"}>
            <PopoverContent h={hideDateMode ? "200px" : "400px"} w={hideDateMode ? "300px" : "750px"}>
              <PopoverArrow />
              {/* {header && <PopoverHeader p={0}>{header}</PopoverHeader>} */}
              <PopoverBody>
                <Flex>
                  <Box p={2}>
                    <Text className="text-sm text-gray-400 mb-4">
                      An automatic date will change when the start date of the trip changes.
                    </Text>
                    <Flex className="gap-4">
                      <RHFInput
                        w={24}
                        isRequired
                        name="due_days"
                        label="Days"
                        onChange={() => {
                          setDateMode(false);
                          formContext.setValue(
                            "due_date",
                            calculateDueDate(
                              start_date,
                              end_date,
                              formContext.watch("due_days") ?? 7,
                              formContext.watch("due_days_type") ?? DUE_DAYS_TYPE.BEFORE_START,
                            ),
                          );
                        }}
                      />
                      <RHFSelect
                        mt={5}
                        name="due_days_type"
                        options={Object.values(DUE_DAYS_TYPE).map((g) => ({ text: g + " of trip", value: g }))}
                        onChange={() => {
                          setDateMode(false);
                          formContext.setValue(
                            "due_date",
                            calculateDueDate(
                              start_date,
                              end_date,
                              formContext.watch("due_days") ?? 7,
                              formContext.watch("due_days_type") ?? DUE_DAYS_TYPE.BEFORE_START,
                            ),
                          );
                        }}
                      />
                    </Flex>
                    {!hideDateMode && (
                      <Text className="text-sm mt-4 text-gray-400 mb-4">{`This task is due ${dueDate()}`}</Text>
                    )}
                  </Box>
                  {!hideDateMode && (
                    <>
                      <Box width={12}>
                        <Divider h="45%" ml={5} orientation="vertical" />{" "}
                        <Box className="bg-gray-500 text-white rounded-full p-2">OR</Box>{" "}
                        <Divider h="50%" orientation="vertical" ml={5} />
                      </Box>
                      <Box p={2}>
                        <Text className="text-sm text-gray-400 mb-4">
                          A fixed date doesn't change when the start date of the trip changes.
                        </Text>
                        <ReactDayPicker {...input.dayPickerProps} showOutsideDays onDayClick={handleOnDayClick} />
                      </Box>
                    </>
                  )}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Box>
        </Portal>
      )}
    </Popover>
  );
};
export default React.memo(DueDayPicker);
