import { EmailIcon } from "@chakra-ui/icons";
import { Alert, AlertDescription, Box, Icon, Link, useToast } from "@chakra-ui/react";
import React from "react";
import AuthAPI from "../../api/auth.api";
import { User } from "@lato/common";
import { handleSubmission } from "../../utils/toErrorMap";

interface EmailConfirmationAlertProps {
  user: User;
}

const EmailConfirmationAlert: React.FC<EmailConfirmationAlertProps> = ({ user }) => {
  const toast = useToast();

  const sendNewConfirmEmail = async () => {
    await handleSubmission({
      successMessage: `sent email to ${user.email}`,
      failMessage: `sending email to ${user.email}`,
      apiCall: AuthAPI.sendNewEmailConfirm(user.email),
      toast,
    });
  };

  return user.verified ? null : (
    <Alert status="warning" color="orange.800" bg="rgb(255, 246, 230)" borderWidth="1px" borderColor="orange.200">
      {/* <AlertIcon /> */}
      <Icon as={EmailIcon} />
      <Box ml={3}>
        {/* <AlertTitle>Email address not yet confirmed!</AlertTitle> */}
        <AlertDescription display="block" fontSize="md" lineHeight={1.1}>
          Please confirm your email address to unlock all features. An email was sent to <b>{user.email}</b> (please
          also check your spam/junk folder).{" "}
          <Link onClick={sendNewConfirmEmail} textDecoration="underline" _hover={{ textDecoration: "none" }}>
            Click here
          </Link>{" "}
          {`if you didn't receive an email or if the email confirmation link has
          expired.`}
        </AlertDescription>
      </Box>
    </Alert>
  );
};
export default React.memo(EmailConfirmationAlert);
