import React from "react";
import { useNavigate } from "react-router-dom";

const POIs: React.FC = () => {
  const navigate = useNavigate();

  // Redirect to the POIs page on load
  const redirect = React.useEffect(() => {
    navigate("/library/pois");
  }, [navigate]);

  return (
    <>
      {redirect}
    </>
  );
};
export default POIs;