import CreateGeneralTrip from "../../../components/trips/edit/general/CreateGeneralTrip";
import SampleStepWrapper from "../SampleStepWrapper";

export const GeneralSampleRoute: React.FC = () => {
  const stepNumber = 0;

  return (
    <SampleStepWrapper>
      <CreateGeneralTrip stepNumber={stepNumber} />
    </SampleStepWrapper>
  );
};

export default GeneralSampleRoute;
