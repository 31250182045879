import { DownloadIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Trash2 as DeleteIcon } from "lucide-react";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  ExternalImage,
  ExternalImagesPaginated,
  ImageSite,
  IsImage,
  TLibraryImage,
  TLocation,
  getKeyFromImageUrl,
} from "@lato/common";
import React from "react";
import { useForm } from "react-hook-form";
import { useAsyncDebounce } from "react-table";
import ExternalImagesApi from "../../api/images-external.api";
import LibraryImagesAPI from "../../api/library-images.api";
import { useMeContext } from "../../stores/me-context";
import { MAX_PICTURES } from "../../utils/constants";
import ENV from "../../utils/env";
import { useParamsInfiniteQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import useMutation from "../../utils/query-helpers/useMutation";
import transformExternalImage from "../../utils/transformExternalImage";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import { libraryImageSchema } from "../../validation/validationSchemas";
import { getEmptyLibraryImage } from "../../components/elements/activities";
import { LibraryImageLocationModal } from "../../components/elements/libraryImages/libraryimage-modal";
import ErrorCardView from "../../components/layout/ErrorCardView";
import ExternalImageGrid from "./ExternalImageGrid";

interface ExternalImagesProps {
  defaultSearchValue?: string | null;
  orientation: "portrait" | "landscape" | "squarish" | undefined;
  handleChosenPictures: (photo: ExternalImage | ExternalImage[], searchInput?: string) => void;
  multiple: boolean;
  videos?: boolean;
  nrOfImages: number;
  inLibrary?: boolean;
  location?: TLocation;
}

const ExternalImages: React.FC<ExternalImagesProps> = ({
  defaultSearchValue,
  orientation,
  handleChosenPictures,
  multiple,
  videos = false,
  nrOfImages,
  inLibrary = false,
  location,
}) => {
  const user = useMeContext();
  const locationDisclosure = useDisclosure();
  const [isAddingToLibrary, setIsAddingToLibrary] = React.useState<boolean>();
  const [selectedImages, setSelectedImages] = React.useState<ExternalImage[]>([]);
  const [imagesCount, setImagesCount] = React.useState<number>(nrOfImages);
  // const formMethods = useForm<UnsplashSearchQuery>({
  //   defaultValues: {
  //     unsplashQuery: "test",
  //   },
  // });

  const [search, setSearch] = React.useState(defaultSearchValue || "");
  const [imageApi, setImageApiState] = React.useState<ImageSite>(ImageSite.UNSPLASH);
  const [imageApiType, setImageApiTypeState] = React.useState<string>("photos");

  const emptyImageItem = getEmptyLibraryImage(user);

  const formMethods = useForm<TLibraryImage>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: emptyImageItem,
    shouldUnregister: false,
    resolver: yupResolver(libraryImageSchema),
  });

  const setImageApi = (imageSite: ImageSite) => {
    console.log(imageSite, imageApiType);
    if (imageApiType === "videos") {
      switch (imageSite) {
        case ImageSite.PIXABAY:
          setImageApiState(ImageSite.PIXABAYVIDEO);
          return;
        default:
          setImageApiState(ImageSite.PEXELSVIDEO);
          return;
      }
    } else {
      setImageApiState(imageSite);
    }
  };

  const createImage = useMutation({
    apiCall: (images: TLibraryImage[]) => LibraryImagesAPI.post({ libraryImages: images }),
    successMessage: `Added images to library`,
    failMessage: `Adding images to library`,
  });

  const handleAddToLibrary = async () => {
    if (location) {
      await addToLibrary(location);
    } else {
      locationDisclosure.onOpen();
    }
  };

  const addToLibrary = async (location: TLocation) => {
    let name = search;
    if (!search || search === "") {
      name = "image";
    }
    setIsAddingToLibrary(true);
    await createImage.mutate(
      selectedImages.map((image, i) => ({
        site: image.imageSite,
        s3: { key: getKeyFromImageUrl(image.urls.raw, image.imageSite) },
        url: transformExternalImage(image),
        user: user,
        ord: 0,
        name: i === 0 ? name : `${name}-${i}`,
        location,
      })),
    );
    setIsAddingToLibrary(false);
    locationDisclosure.onClose();
  };

  const setImageApiType = (type: string) => {
    console.log(imageApi, type);
    setImageApiTypeState(type);
    if (type === "videos") {
      switch (imageApi) {
        case ImageSite.PIXABAY:
        case ImageSite.PIXABAYVIDEO:
          setImageApiState(ImageSite.PIXABAYVIDEO);
          return;
        default:
          setImageApiState(ImageSite.PEXELSVIDEO);
          return;
      }
    } else {
      switch (imageApi) {
        case ImageSite.PIXABAY:
        case ImageSite.PIXABAYVIDEO:
          setImageApiState(ImageSite.PIXABAY);
          return;
        default:
          setImageApiState(ImageSite.PEXELS);
          return;
      }
    }
  };

  // const queryResult = useUnsplashPhotos(search);
  // const infiniteQueryResult = useInfiniteQuery<UnsplashPaginated, Error>(
  //   [["unsplash_photos", { orientation: orientation }], search],
  //   async ({ pageParam = 1 }) => {
  //     const object: any = orientation
  //       ? {
  //           q: search,
  //           page: pageParam,
  //           orientation,
  //         }
  //       : { q: search, page: pageParam };
  //     const queryString = new URLSearchParams(object).toString();
  //     return await UnsplashAPI.getPhotos(queryString);
  //   },
  //   {
  //     getNextPageParam: (lastPage, pages) =>
  //       pages.length >= lastPage.total_pages ? undefined : pages.length + 1,
  //   }
  // );
  const infiniteQueryResultUnsplash = useParamsInfiniteQueryHelper<ExternalImagesPaginated>({
    queryKey: "unsplash_photos",
    queryParams: {
      orientation: orientation,
      q: search,
      api: imageApi,
    } as any,
    apiCall: ExternalImagesApi.getPhotos,
    options: {
      getNextPageParam: (lastPage: any, pages: any) =>
        pages.length >= lastPage.total_pages ? false : pages.length + 1,
    },
  });

  const { status, data, error, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    infiniteQueryResultUnsplash;

  const searchExternal = useAsyncDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
    // Debounce search
    // Trigger new unsplash api call by changing the search state
    setSearch(e.target.value);
  }, 700);
  const pageEnd = React.useRef<any>();
  useIntersectionObserver({
    target: pageEnd,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const handleChosenPicture = (img: ExternalImage) => {
    if (multiple) {
      if (selectedImages.some((selectedImg) => selectedImg.id === img.id)) {
        // If the image which the user selects is already inside the array, remove it again.
        const removedImg = selectedImages.filter((selectedImg) => selectedImg.id !== img.id);
        setSelectedImages((prevImgs) => [...removedImg]);
        setImagesCount(imagesCount - 1);
      } else {
        setSelectedImages((prevImgs) => [...prevImgs, img]);
        setImagesCount(imagesCount + 1);
      }
    } else {
      handleChosenPictures(img, search);
    }
  };

  return (
    <>
      <Flex flexWrap={{ base: "wrap-reverse", md: "nowrap" }} mx="auto" w="100%" gap={3} alignItems={"center"}>
        <Select
          width={{ base: "47%", md: "20%" }}
          onChange={(event) => setImageApiType(event.target.value)}
          borderRadius={"10em"}
          height={"3em"}
          value={imageApiType}
        >
          <option>photos</option>
          <option>videos</option>
        </Select>
        <Select
          width={{ base: "47%", md: "20%" }}
          onChange={(event) => setImageApi(event.target.value as ImageSite)}
          borderRadius={"10em"}
          value={
            IsImage(imageApi) ? imageApi : imageApi === ImageSite.PEXELSVIDEO ? ImageSite.PEXELS : ImageSite.PIXABAY
          }
          height={"3em"}
        >
          {Object.values(ImageSite)
            .filter((imageSite) =>
              !IsImage(imageApi)
                ? imageSite === ImageSite.PEXELS /* ||
                  imageSite === ImageSite.PIXABAY*/
                : // removing pixabayOption
                  imageSite !== ImageSite.S3 &&
                  imageSite !== ImageSite.PEXELSVIDEO &&
                  imageSite !== ImageSite.PIXABAYVIDEO &&
                  imageSite !== ImageSite.PIXABAY && //removing pixabayOption
                  imageSite !== ImageSite.CUSTOMPICTURE &&
                  imageSite !== ImageSite.CUSTOMVIDEO,
            )
            .map((imageSite, i) => (
              <option key={`option-${i}`}>{imageSite}</option>
            ))}
        </Select>

        <InputGroup size="md">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            defaultValue={search}
            type="search"
            placeholder="Search photos..."
            autoComplete="off"
            borderRadius="full"
            focusBorderColor="brand.500"
            _hover={{ borderColor: "brand.200" }}
            onChange={searchExternal}
          />
        </InputGroup>
      </Flex>
      <ExternalImageGrid
        infiniteQueryResult={infiniteQueryResultUnsplash}
        handleChosenPicture={handleChosenPicture}
        selectedPictures={selectedImages}
        imageSite={imageApi}
      />
      {multiple && selectedImages.length > 0 && (
        <Box pos="sticky" bottom={0}>
          {!inLibrary && (
            <Button
              pos="absolute"
              fontSize="sm"
              leftIcon={<DownloadIcon />}
              borderRadius="full"
              overflow="hidden"
              boxShadow={"lg"}
              colorScheme="brand"
              flexShrink={0}
              px={5}
              color="white"
              marginStart={"-1em"}
              onClick={handleAddToLibrary}
              isLoading={isAddingToLibrary}
            >
              Add to Library
            </Button>
          )}
          <Flex
            right={0}
            bg="gray.100"
            borderRadius="full"
            overflow="hidden"
            boxShadow={"lg"}
            marginStart="auto"
            marginEnd={inLibrary ? "auto" : "-1em"}
            w={inLibrary ? "80%" : "75%"}
          >
            <Button
              fontSize="sm"
              leftIcon={<Icon as={DeleteIcon} boxSize={4} />}
              borderRadius="full"
              flexShrink={0}
              px={5}
              color="red.400"
              onClick={() => setSelectedImages([])}
            >
              Clear selection
            </Button>
            <Tooltip
              hasArrow
              label="You can only have 20 images per element"
              bg="red.600"
              isDisabled={imagesCount <= MAX_PICTURES}
              placement="top"
            >
              <Box w="100%">
                <Button
                  fontWeight={600}
                  fontSize="md"
                  color="white"
                  colorScheme="brand"
                  w="100%"
                  borderRadius="full"
                  disabled={imagesCount > MAX_PICTURES}
                  onClick={() => handleChosenPictures(selectedImages, search)}
                >
                  Add {selectedImages.length} {selectedImages.length === 1 ? "image" : "images"}
                </Button>
              </Box>
            </Tooltip>
          </Flex>
        </Box>
      )}
      <Flex justify="center">
        <Button
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          colorScheme="brand"
          ref={pageEnd}
        >
          {isFetchingNextPage ? "Loading more..." : hasNextPage ? "Load More" : "Nothing more to load"}
        </Button>
      </Flex>
      <LibraryImageLocationModal
        locationDisclosure={locationDisclosure}
        formMethods={formMethods}
        handleSave={async (lib) => {
          await addToLibrary((lib as TLibraryImage).location!);
        }}
        isSubmitting={isAddingToLibrary ?? false}
      />
    </>
  );
};
export default React.memo(ExternalImages);
