import { Box, Button, Link, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import CompanyAPI from "../../api/companies.api";
import TranslateAPI from "../../api/translation.api";
import Form from "../../components/form/Form";
import RHFInputPassword from "../../components/input/RHFInputPassword";
import Section, { horizontalSectionPadding } from "../../components/layout/Section";
import Settings from "../../components/settings/Settings";
import { useMeContext } from "../../stores/me-context";
import { ME_QUERY_KEY, SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../utils/constants";
import { handleSubmission } from "../../utils/toErrorMap";
import useUnsavedChangesPrompt from "../../utils/useUnsavedChangesPrompt";

interface DeeplSettingsProps {}

export const DeeplSettings: React.FC<DeeplSettingsProps> = () => {
  const user = useMeContext();
  const [usage, setUsage] = React.useState<{ usage: number; limit: number }>({
    usage: 0,
    limit: 0,
  });

  const queryClient = useQueryClient();
  const toast = useToast();
  const company = user?.company;
  const { mutateAsync: saveDeeplApiKey } = useMutation({
    mutationFn: CompanyAPI.saveDeeplApiKey,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
      // Invalidate the Deepl api key! (company and or current user)
    },
  });

  console.log("rerendering deeplSettings");

  useEffect(() => {
    console.log("useEffect");
    const fetchUsage = async () => await TranslateAPI.getCharacterUsage();
    fetchUsage().then((data) => setUsage(data));
  }, []);

  const formMethods = useForm<{ deeplApiKey: string }>({
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        deeplApiKey: yup.string().required("You have not configured a deeplApiKey yet").nullable(),
      }),
    ),
    defaultValues: company,
  });

  useUnsavedChangesPrompt(formMethods.formState.isDirty);

  useEffect(() => {
    formMethods.trigger();
  }, [formMethods]);

  const updateDeeplApiKey = async ({ deeplApiKey }: { deeplApiKey: string }) => {
    await handleSubmission({
      successMessage: "updated Deepl API key",
      failMessage: "updating Deepl API key",
      apiCall: saveDeeplApiKey(deeplApiKey),
      toast,
      setError: formMethods.setError,
    });
  };

  return (
    <Settings>
      <Section title="Deepl Configuration" h="auto" noHorizontalPadding>
        <Box px={horizontalSectionPadding} display="flex" flexDirection={"column"} gap={5}>
          <p>
            In order for your company to be able to use <b>Deepl</b> you have to supply us with your <b>API-Key</b>.
          </p>
          <p>
            In order to get an <b>API-Key</b> you first have to{" "}
            <Link
              href="https://www.deepl.com/pro-api"
              textColor={"blue"}
              target={"_blank"}
              textDecoration={"underline"}
            >
              create a Deepl account here.
            </Link>
          </p>
          <p>
            The first <b>500.000</b> characters are free!
          </p>
        </Box>
      </Section>
      <Section title={"Your company's Deepl API-key"} h="100%">
        <Form formMethods={formMethods} onSubmit={updateDeeplApiKey}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"start"} justifyContent={"space-between"}>
            <RHFInputPassword name="deeplApiKey" placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"></RHFInputPassword>
            <Button mx={3} size={"md"} float={"right"} type="submit" fontSize={"md"} colorScheme="brand">
              Save key
            </Button>
          </Box>
        </Form>
      </Section>
      {usage.limit !== 0 && usage.usage !== 0 && (
        <Section title={"Deepl Usage"} h="auto">
          <Box px={horizontalSectionPadding} display="flex" flexDirection={"column"} gap={5}>
            <p>
              Your company has used <b>{usage.usage}</b> characters.
            </p>
          </Box>
        </Section>
      )}
    </Settings>
  );
};
export default DeeplSettings;
