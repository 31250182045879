import {
  Box,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useRadioGroup,
} from "@chakra-ui/react";
import { TLocation, TRANSPORTATION_TYPE, TranslationField, Transportation } from "@lato/common";
import React from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useTripLanguage } from "../../../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { prettyPrintDate } from "../../../../../../utils/date";
import AirportDownShift from "../../../../../CustomDownShift/AirportDownShift";
import TrainstationDownShift from "../../../../../CustomDownShift/TrainstationDownshift";
import { CustomSpinner } from "../../../../../FullScreenSpinner";
import Documents from "../../../../../../features/documents/Documents";
import RHFInput from "../../../../../input/RHFInput";
import RHFTranslationInput from "../../../../../input/RHFTranslationInput";
import AutocompleteStaticMap from "../../../../../../features/map/AutocompleteStaticMap";
import StaticMap from "../../../../../map/staticMap";
import ElementPreview from "../../ElementPreview";
import DayPicker from "../../../../../input/date/DayPicker";
import InputWrapper from "../../../../../input/InputWrapper";
import { AmountLabel } from "../hotel/AmountLabel";
import { RadioCard } from "./RadioCard";
import { TransportationFormProps, transportIcon } from "./TransportationForm";

export const ActualTransportationForm: React.FC<TransportationFormProps> = ({
  first = true,
  departureDateAndTime,
  arrivalDateAndTime,
  type,
  setType,
  transportation,
  uniqueId,
  inTrip = false,
  isRentalDropOff,
  extraButton,
  collaboratorCanEdit,
  departureIsOutsideOfScope,
  arrivalIsOutsideOfScope,
  isFetchingTransportation,
  daySelectOptions,
  departureDate,
  arrivalDate,
}) => {
  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();
  const { unCollapsedElement } = useTripFormStore();

  const [collapsed, setCollapsed] = React.useState(unCollapsedElement !== transportation?.id);

  React.useEffect(() => {
    setCollapsed(unCollapsedElement !== transportation?.id);
  }, [unCollapsedElement]);

  const ref = React.useRef(null);

  React.useEffect(() => {
    const now = new Date();
    const transportationCreatedData = transportation?.created_at && new Date(transportation.created_at);
    if (
      transportationCreatedData &&
      transportationCreatedData.getDate() === now.getDate() &&
      transportationCreatedData.getHours() === now.getHours() &&
      transportationCreatedData.getMinutes() === now.getMinutes()
    )
      ref && ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, []);

  const { setValue, getValues, watch } = useFormContext<Transportation>();

  const [arrivalAirportCoords, setArrivalAirportCoords] = React.useState<any>(
    transportation?.arrivalTrainstation
      ? [transportation.arrivalTrainstation.lon, transportation.arrivalTrainstation.lat]
      : transportation?.arrivalAirport
        ? [transportation.arrivalAirport.lon, transportation.arrivalAirport.lat]
        : undefined,
  );
  const [departureAirportCoords, setDepartureAirportCoords] = React.useState<any>(
    transportation?.departureTrainstation
      ? [transportation.departureTrainstation.lon, transportation.departureTrainstation.lat]
      : transportation?.departureAirport
        ? [transportation.departureAirport.lon, transportation.departureAirport.lat]
        : undefined,
  );

  const translationFields: TranslationField[] = getValues(`descriptions`);

  const description =
    translationFields?.length > 0
      ? translationFields[first ? firstSelectedLanguageIndex : (secondSelectedLanguageIndex ?? 0)]?.content
      : "";

  const [showDescription, setShowDescription] = React.useState<boolean>(
    description !== "" && description !== "<p></p>",
  );

  const { field: typeField } = useController({
    name: `type`,
  });

  const handleChooseType = (type: string) => {
    setType(type as TRANSPORTATION_TYPE);
    typeField.onChange(type);
  };

  const { field: departureLocationField } = useController({
    name: `departureLocation`,
  });

  const { field: arrivalLocationField } = useController({
    name: `arrivalLocation`,
  });

  const handleSetArrivalLocation = (coordinates: any, name: string, address: any) => {
    if (coordinates === null) {
      arrivalLocationField.onChange(null);
    } else {
      arrivalLocationField.onChange({
        coordinates: coordinates,
        name: name,
        address: address,
      });
    }
  };

  const handleSetDepartureLocation = (coordinates: any, name: string, address: any) => {
    if (coordinates === null) {
      departureLocationField.onChange(null);
    } else {
      departureLocationField.onChange({
        coordinates: coordinates,
        name: name,
        address: address,
      });
    }
  };

  const { field: arrivalAirportField } = useController({
    name: `arrivalAirport`,
  });

  const { field: departureAirportField } = useController({
    name: `departureAirport`,
  });

  const { field: arrivalTrainstationField } = useController({
    name: `arrivalTrainstation`,
  });

  const { field: departureTrainstationField } = useController({
    name: `departureTrainstation`,
  });

  const documents = useWatch({
    name: "documents",
  });

  const handleSetArrivalAirport = (airport: any, field = "arrivalAirportIata") => {
    setArrivalAirportCoords([airport.lon, airport.lat]);
    field === "arrivalAirportIata" ? arrivalAirportField.onChange(airport) : arrivalTrainstationField.onChange(airport);
    setValue(field as any, airport?.iata, { shouldDirty: true });
  };

  const handleSetDepartureAirport = (airport: any, field = "departureAirportIata") => {
    setDepartureAirportCoords([airport.lon, airport.lat]);
    field === "departureAirportIata"
      ? departureAirportField.onChange(airport)
      : departureTrainstationField.onChange(airport);
    setValue(field as any, airport?.iata, { shouldDirty: true });
  };

  //radioGroup properties from react-hook-form
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "transportationType",
    defaultValue: type,
    onChange: (value) => {
      handleChooseType(value);
    },
  });

  const showDropdowns =
    type === TRANSPORTATION_TYPE.FLIGHT || (type === TRANSPORTATION_TYPE.TRAIN && transportation.realtime);

  const group = getRootProps();

  const preview = React.useCallback(() => {
    const element = getValues();
    return (
      <ElementPreview
        title={
          <div>
            <div className="flex gap-2">
              <Icon as={transportIcon[type]} className="w-4 mt-0.5" />
              <Heading size="lg">{isRentalDropOff ? "Transportation Dropoff" : "Transportation"}</Heading>
            </div>
            <span className="text-sm text-gray-500">{transportation.airline?.name}</span>
          </div>
        }
        description={element.descriptions[firstSelectedLanguageIndex]?.content}
        location={
          (element.departureLocation ? "From " + element.departureLocation?.name : "") +
          (element.arrivalLocation ? " to " + element.arrivalLocation?.name : "")
        }
        time={
          (element.departureTime ? element.departureTime : "") +
          (element.arrivalTime ? ` - ${element.arrivalTime}` : "")
        }
        date={
          (departureDate
            ? prettyPrintDate(departureDate, "en", "medium")
            : element.departureDayIndex != null &&
                daySelectOptions &&
                daySelectOptions.length > element.departureDayIndex
              ? daySelectOptions[element.departureDayIndex]?.text
              : "") +
          (arrivalDate
            ? arrivalDate.getDate() === departureDate?.getDate() && arrivalDate.getMonth() === departureDate?.getMonth()
              ? ""
              : " - " + prettyPrintDate(arrivalDate, "en", "medium")
            : element.arrivalDayIndex != null &&
                daySelectOptions &&
                daySelectOptions.length > element.arrivalDayIndex &&
                element.arrivalDayIndex !== element.departureDayIndex
              ? " - " + daySelectOptions[element.arrivalDayIndex]?.text
              : "")
        }
        firstCoords={
          element.departureLocation?.coordinates
            ? { lat: element.departureLocation?.coordinates[1], lng: element.departureLocation?.coordinates[0] }
            : element.departureAirport
              ? { lat: element.departureAirport?.lat, lng: element.departureAirport?.lon }
              : undefined
        }
        secondCoords={
          element.arrivalLocation?.coordinates
            ? { lat: element.arrivalLocation?.coordinates[1], lng: element.arrivalLocation?.coordinates[0] }
            : element.arrivalAirport
              ? { lat: element.arrivalAirport?.lat, lng: element.arrivalAirport?.lon }
              : undefined
        }
        overlay={!!unCollapsedElement}
        type="transportation"
        transportationType={type}
      />
    );
  }, [getValues, firstSelectedLanguageIndex]);

  const [fromLocation, setFromLocation] = React.useState<TLocation | null>(transportation.departureLocation);
  const [toLocation, setToLocation] = React.useState<TLocation | null>(transportation.arrivalLocation);

  if (collapsed) {
    return (
      <div ref={ref} id={transportation.id}>
        {preview()}
      </div>
    );
  }

  return (
    <Box className="flex flex-col space-y-4 items-stretch" ref={ref} id={transportation.id}>
      <Stack direction="row" alignItems="flex-end">
        <Icon as={transportIcon[type]} boxSize={6} />
        <Heading size="xl" fontSize={"22px"}>
          {isRentalDropOff ? "Transportation Dropoff" : "Transportation"}
        </Heading>
        <Text color="gray.500" fontSize="14px">
          {transportation.airline?.name}
        </Text>
      </Stack>
      <HStack
        {...group}
        overflowX="auto"
        margin="-10px"
        py="10px"
        mt={[0, "0 !important"]}
        w="full"
        justifyContent={"space-evenly"}
      >
        {!isRentalDropOff &&
          first &&
          Object.values(TRANSPORTATION_TYPE).map((type) => {
            const radio = getRadioProps({
              value: type,
            });
            return (
              <RadioCard key={type} {...radio}>
                <Flex mx={5} my={3} flexDirection={"column"} alignItems="center">
                  <Icon as={transportIcon[type]} boxSize={5} />
                  <Text fontSize={12}>{type}</Text>
                </Flex>
              </RadioCard>
            );
          })}
      </HStack>
      {!isRentalDropOff && (
        <div className="pr-4">
          <RHFTranslationInput
            width="100%"
            first={first}
            collaboratorCanEdit={collaboratorCanEdit}
            name={`descriptions.0.content`}
            label={"Description"}
            size="md"
            textarea
            isInline
          ></RHFTranslationInput>
        </div>
      )}
      <Tabs variant="soft-rounded" colorScheme="brand">
        <TabList flexFlow={"wrap"}>
          <Tab>General</Tab>
          <Tab>Supplier</Tab>
          <Tab>
            Documents
            <AmountLabel amount={documents?.length} />
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box className="space-y-4">
              <Flex gap={5} width={"100%"}>
                {type !== TRANSPORTATION_TYPE.FLIGHT ? (
                  <Box width="50%">
                    <>
                      <AutocompleteStaticMap
                        fromFieldName={`departureLocation`}
                        toFieldName={`arrivalLocation`}
                        setGeometryFromValue={handleSetDepartureLocation}
                        setGeometryToValue={handleSetArrivalLocation}
                        first={first}
                        uniqueId={uniqueId}
                        inTrip={inTrip}
                        fromLocationValue={transportation.departureLocation}
                        toLocationValue={transportation.arrivalLocation}
                        disableFields={isRentalDropOff}
                        fromPreLabel={type === TRANSPORTATION_TYPE.RENTAL ? "Pickup" : "Departure"}
                        toPreLabel={type === TRANSPORTATION_TYPE.RENTAL ? "Dropoff" : "Arrival"}
                        transportation_type={type}
                        fromLocation={fromLocation}
                        setFromLocation={setFromLocation}
                        toLocation={toLocation}
                        setToLocation={setToLocation}
                      ></AutocompleteStaticMap>
                    </>
                  </Box>
                ) : (
                  showDropdowns && (
                    <Flex width={"50%"} flexDirection={"column"} justifyContent={"flex-start"}>
                      <Box visibility="visible">
                        <FormLabel mb={"0.2em"}>
                          Departure{" "}
                          {departureTrainstationField.value && type === TRANSPORTATION_TYPE.TRAIN
                            ? "Trainstation"
                            : "Airport"}
                        </FormLabel>
                        {isFetchingTransportation ? (
                          <CustomSpinner skeleton m="auto" h={10} />
                        ) : departureTrainstationField.value && type === TRANSPORTATION_TYPE.TRAIN ? (
                          <TrainstationDownShift
                            onSelectedHandler={(item) => handleSetDepartureAirport(item, "departureTrainstationiata")}
                            disableInput={!first}
                            trainstation={departureTrainstationField.value}
                          />
                        ) : (
                          <AirportDownShift
                            onSelectedHandler={handleSetDepartureAirport}
                            disableInput={!first || isRentalDropOff}
                            airport={departureAirportField.value}
                          />
                        )}
                      </Box>

                      <Box>
                        <FormLabel mb={"0.2em"}>
                          Arrival{" "}
                          {transportation.arrivalTrainstationIata && type === TRANSPORTATION_TYPE.TRAIN
                            ? "Trainstation"
                            : "Airport"}
                        </FormLabel>
                        {isFetchingTransportation ? (
                          <CustomSpinner skeleton m="auto" h={10} />
                        ) : arrivalTrainstationField.value && type === TRANSPORTATION_TYPE.TRAIN ? (
                          <TrainstationDownShift
                            onSelectedHandler={(item) => handleSetArrivalAirport(item, "arrivalTrainstationIata")}
                            disableInput={!first}
                            trainstation={arrivalTrainstationField.value}
                          />
                        ) : (
                          <AirportDownShift
                            onSelectedHandler={handleSetArrivalAirport}
                            disableInput={!first || isRentalDropOff}
                            airport={arrivalAirportField.value}
                          />
                        )}
                      </Box>
                    </Flex>
                  )
                )}

                <Box width="50%">
                  {type !== TRANSPORTATION_TYPE.FLIGHT
                    ? fromLocation?.coordinates &&
                      toLocation?.coordinates && (
                        <StaticMap
                          containerHeight="11.5rem"
                          containerWidth="100%"
                          height={640}
                          width={1000}
                          transportation_type={type}
                          center={
                            fromLocation?.coordinates
                              ? {
                                  lng: fromLocation.coordinates[0],
                                  lat: fromLocation.coordinates[1],
                                }
                              : undefined
                          }
                          second={
                            toLocation?.coordinates
                              ? {
                                  lng: toLocation.coordinates[0],
                                  lat: toLocation.coordinates[1],
                                }
                              : undefined
                          }
                        />
                      )
                    : departureAirportCoords &&
                      arrivalAirportCoords && (
                        <div className="mt-5">
                          <StaticMap
                            transportation_type={type}
                            containerHeight="11.5rem"
                            containerWidth="100%"
                            height={640}
                            width={1000}
                            center={
                              departureAirportCoords
                                ? {
                                    lng: departureAirportCoords[0],
                                    lat: departureAirportCoords[1],
                                  }
                                : arrivalAirportCoords && {
                                    lng: arrivalAirportCoords[0],
                                    lat: arrivalAirportCoords[1],
                                  }
                            }
                            second={
                              arrivalAirportCoords && {
                                lng: arrivalAirportCoords[0],
                                lat: arrivalAirportCoords[1],
                              }
                            }
                          />
                        </div>
                      )}
                </Box>
              </Flex>

              <Flex flexDirection={"column"} gap={4} width={"100%"}>
                <Flex flexDirection={"row"} gap={5} justifyContent={"flex-start"}>
                  {isRentalDropOff || departureIsOutsideOfScope ? (
                    <Flex width={"50%"} justifyContent={"flex-start"}>
                      <InputWrapper
                        name={"departureDayIndex"}
                        label={isRentalDropOff ? "Pickup Date" : "Departure Date"}
                      >
                        <DayPicker width={"50%"} inputWidth={"70%"} isDisabled={true} defaultDate={departureDate} />
                      </InputWrapper>
                      <RHFInput
                        name={`departureTime`}
                        label={isRentalDropOff ? "Pickup Time" : "Departure Time"}
                        width={"50%"}
                        inputWidth={"70%"}
                        bottomMarginLabel={"0"}
                        isDisabled={true}
                      />
                    </Flex>
                  ) : (
                    departureDateAndTime
                  )}
                  {isRentalDropOff || arrivalIsOutsideOfScope ? (
                    <Flex width={"50%"} justifyContent={"flex-start"}>
                      <InputWrapper name={"arrivalDayIndex"} label={isRentalDropOff ? "Dropoff Date" : "Arrival Date"}>
                        <DayPicker width={"50%"} inputWidth={"70%"} isDisabled={true} defaultDate={arrivalDate} />
                      </InputWrapper>
                      <RHFInput
                        name={`arrivalTime`}
                        label={isRentalDropOff ? "Dropoff Time" : "Arrival Time"}
                        width={"50%"}
                        inputWidth={"70%"}
                        bottomMarginLabel={"0"}
                        isDisabled={true}
                      />
                    </Flex>
                  ) : (
                    arrivalDateAndTime
                  )}
                </Flex>
              </Flex>
            </Box>
          </TabPanel>
          <TabPanel>
            <Flex flexDirection={"row"} width={"100%"} justifyContent={"space-between"}>
              <RHFInput
                name={`transportNumber`}
                label="Transport Number"
                placeholder="IB532"
                width={"49%"}
                isDisabled={!first || isRentalDropOff}
              />
              <RHFInput
                name={`carrier`}
                label="Carrier"
                placeholder="SIXT"
                width={"49%"}
                isDisabled={!first || isRentalDropOff}
              />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex justifyContent={"space-between"}>
              <Box width={extraButton ? "80%" : "100%"}>
                {!isRentalDropOff && (
                  <Documents fieldArrayName={`documents`} inForm={false} disabled={!first} showHeading={false} />
                )}
              </Box>

              {extraButton}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
