const styles = {
  global: {
    // p: {
    //   margin: "0.4rem 0 0.4rem 0",
    // },
    // "li > p": {
    //   margin: 0,
    // },
    body: {
      color: "lato.darkBlue",
    },

    h1: {
      fontSize: "1.5em",
      fontWeight: 700,
    },
    h2: {
      fontSize: "1.2em",
      fontWeight: 500,
    },

    /* BEGIN Rich Text Editor */
    /* Make sure that when an empty p tag <p></p> is used, an enter is inserted. Used for the rich-text-editor */
    "p:empty::after": {
      content: '"\\00A0"',
    },
    /* END Rich Text Editor */
  },
};
export default styles;
