const MicrophoneIcon = () => (
  <svg
    role="graphics-symbol"
    viewBox="0 0 16 16"
    className="aiChangeTone"
    style={{ width: "16px", height: "16px", display: "block", fill: "currentColor", flexShrink: 0 }}
  >
    <path d="M10.0371 1.67969L14.0293 5.67188C15.2529 4.46875 15.3486 2.82812 14.1113 1.59766C12.8809 0.374023 11.2471 0.456055 10.0371 1.67969ZM8.16406 14.9482C8.58105 14.9482 8.90918 14.627 8.90918 14.21L8.92285 9.26758L10.9189 7.41504C11.7256 7.49023 12.5527 7.15527 13.25 6.44434L9.26465 2.45215C8.55371 3.15625 8.23242 3.96973 8.31445 4.7832L1.97754 11.5918C1.69043 11.8994 1.66992 12.2822 2.00488 12.6719L1.08203 13.8408C0.958984 13.998 0.972656 14.2236 1.12988 14.3809L1.32812 14.5859C1.48535 14.7432 1.7041 14.7568 1.88184 14.6201L3.04395 13.6973C3.42676 14.0391 3.81641 14.0117 4.11719 13.7314L7.42578 10.6621V14.21C7.42578 14.627 7.75391 14.9482 8.16406 14.9482ZM2.90723 12.1934L8.7793 6.03418C8.88867 6.21191 9.01855 6.37598 9.16895 6.5332C9.32617 6.69727 9.49023 6.83398 9.6543 6.94336L3.51562 12.8086L2.90723 12.1934Z"></path>
  </svg>
);
export default MicrophoneIcon;
