import { Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { HOTEL_ORIGIN, TranslationField, User, Event, Hotel, TextFieldElement, Destination } from "@lato/common";
import React from "react";
import { useForm } from "react-hook-form";
import { EventsAPIType } from "../../../../../api/events.api";
import { HotelsAPIType } from "../../../../../api/hotels.api";
import { POI, POIsAPIType } from "../../../../../api/pois.api";
import { useMeContext } from "../../../../../stores/me-context";
import { TripLanguageProvider } from "../../../../../stores/trip-language-context";
import { MutationReturn } from "../../../../../utils/query-helpers/useMutation";
import { recursivelyRemoveObjectValues, recursivelyReplaceObjectValues } from "../../../../../utils/recursiveHelpers";
import { submitDocumentsArray } from "../../../../../utils/submitDocumentsArray";
import {
  eventSchema,
  hotelSchema,
  poiValidationSchema as poiSchema,
  textfieldSchema,
  destinationSchema,
} from "../../../../../validation/validationSchemas";
import { ELEMENT_VIEW } from "../../../../CRUD/Resource";
import { CustomSpinner } from "../../../../FullScreenSpinner";
import Form from "../../../../form/Form";
import CustomModal from "../../../../layout/CustomModal";
import { POIForm } from "../../../../../features/pois/POIForm";
import { getLanguagesFromCompany } from "../LanguagesOptions";
import { titleMap } from "./LibraryItemsTable";
import { ActualEventForm } from "./activities/ActualEventForm";
import { ActualHotelForm } from "./hotel/ActualHotelForm";
import TextFieldForm from "./textfields/TextFieldForm";
import DestinationForm from "./destination/DestinationForm";

export type LibraryItemType = "accommodation" | "activity" | "textfield" | "destination" | "poi";
export type LibraryItem = Hotel | Event | TextFieldElement | Destination | POI;
// Marker items with TLocation property
export type MarkerMapItem = Hotel | Event | POI;
export type MarkerMapItemType = "accommodation" | "activity" | "poi";
export type MarkerMapsAPI = HotelsAPIType | EventsAPIType | POIsAPIType;

interface LibraryItemModalProps {
  disclosure: any;
  mut: MutationReturn;
  onDelete?: (id: string) => void;
  libraryItem?: LibraryItem;
  updateLibraryItem?: (item: any) => void;
  type: "save-to-library" | "Create" | "Edit";
  eventType: LibraryItemType;
  forceNew?: boolean;
}

export function deleteAlreadyTranslatedFields<T>(object: T, user: User): T {
  // Find all translated languages from this user.
  const nextLanguageCodes = getLanguagesFromCompany<T>(object, user.companyId!);
  // Delete all translation fields of all the users except the last user where the language is already translated by the last user.
  return recursivelyRemoveObjectValues<T>(
    object,
    (k, v) =>
      v[k] &&
      typeof v[k] === "object" &&
      v[k]["creator"] &&
      v[k]["creator"]["companyId"] !== user.companyId! &&
      v[k]["creator"]["companyId"] !== null &&
      v[k]["language"] &&
      nextLanguageCodes.includes(v[k]["language"]["code"]),
  );
}

const LibraryItemModal: React.FC<LibraryItemModalProps> = ({
  disclosure,
  mut,
  libraryItem,
  type,
  eventType,
  updateLibraryItem,
  forceNew = false,
  onDelete,
}) => {
  const { onClose, isOpen } = disclosure;

  const user = useMeContext();

  return (
    <CustomModal
      title={type === "save-to-library" ? `Add ${eventType} to library` : `${type} ${eventType}`}
      onClose={() => {
        onClose();
        updateLibraryItem && updateLibraryItem(undefined);
      }}
      isOpen={isOpen}
      size="3xl"
      showHeader={false}
      modalContentProps={{
        minH: "80vh",
      }}
    >
      {!libraryItem ? (
        <CustomSpinner m="auto" />
      ) : (
        <LibraryItemFormInModal
          libraryItem={libraryItem}
          mut={mut}
          type={type}
          onClose={() => {
            onClose();
            updateLibraryItem && updateLibraryItem(undefined);
          }}
          eventType={eventType}
          updateLibraryItem={updateLibraryItem}
          onDelete={onDelete}
          forceNew={forceNew}
        />
      )}
    </CustomModal>
  );
};
export default React.memo(LibraryItemModal);

interface LibraryItemFormInModalProps extends Omit<LibraryItemModalProps, "disclosure"> {
  onClose: () => void;
  updateLibraryItem?: (item: any) => void;
}

export const LibraryItemFormInModal = ({
  libraryItem,
  mut,
  type,
  onClose,
  eventType,
  updateLibraryItem = () => {},
  onDelete,
  forceNew = false,
}: LibraryItemFormInModalProps) => {
  const user = useMeContext();
  const [isSubmitting, setSubmitting] = React.useState(false);

  const eventSchemaMap: { [key in string]: any } = {
    ["accommodation"]: hotelSchema,
    ["activity"]: eventSchema,
    ["textfield"]: textfieldSchema,
    ["destination"]: destinationSchema,
    ["poi"]: poiSchema,
  };

  // console.log("Hotel name: ", hotel.name);
  const formMethods = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: libraryItem,
    resolver: yupResolver(eventSchemaMap[eventType]),
    shouldUnregister: false,
  });

  const handleDelete = async () => {
    if (libraryItem && libraryItem.id && onDelete) {
      onDelete(libraryItem.id);
    }
  };

  const view = () => {
    const layout_key = `layout-${titleMap[eventType]}`;
    return localStorage.getItem(layout_key) as ELEMENT_VIEW;
  };

  const FormMap: { [key in string]: any } = {
    ["accommodation"]: (
      <ActualHotelForm
        tripProps={null}
        forceNew={forceNew}
        isUpdatingAccommodation={isSubmitting}
        formMethods={formMethods}
        updateAccommodation={updateLibraryItem}
        onDelete={handleDelete}
        isCreate={type === "Create"}
        view={view()}
      />
    ),
    ["activity"]: (
      <ActualEventForm
        tripProps={null}
        forceNew={forceNew}
        updateActivity={updateLibraryItem}
        onDelete={handleDelete}
        isCreate={type === "Create"}
        view={view()}
      />
    ),
    ["textfield"]: <TextFieldForm forceNew={forceNew} updateTextField={updateLibraryItem} />,
    ["destination"]: <DestinationForm updateDestination={updateLibraryItem} forceNew={forceNew} />,
    ["poi"]: (
      <POIForm
        tripProps={null}
        updatePOI={updateLibraryItem}
        onDelete={handleDelete}
        isCreate={type === "Create"}
        view={view()}
      />
    ),
  };

  // const { mutateAsync: asyncSave, isLoading: isSaving } = useMutation(
  //   (hotel: Hotel) => onSave(hotel),
  //   {
  //     onSuccess: () => {
  //       // Better handle this, can't you just add the current message to the cache?
  //       queryClient.invalidateQueries(ACCOMMODATIONS_QUERY_KEY);
  //       // modalDisclosure.onClose();
  //       // window.open(editTripUrl + `/print/${langCode}`, "_blank")?.focus();
  //     },
  //   }
  // );

  const handleSave = async (libraryIt: typeof libraryItem) => {
    setSubmitting(true);
    // const hotel = formMethods.getValues();
    // Save the images to S3.
    if (libraryIt && libraryIt.hasOwnProperty("images")) {
      await submitDocumentsArray((libraryIt as any).images, `${eventType}/`);
    }
    if (libraryIt && libraryIt.hasOwnProperty("documents")) {
      await submitDocumentsArray((libraryIt as any).documents, `${eventType}/`);
    }
    if (type === "Create" && eventType === "accommodation") {
      libraryIt = { ...libraryIt, origin: HOTEL_ORIGIN.LATO, rooms: [] } as LibraryItem;
    }
    await mut.mutate(libraryIt);
    setSubmitting(false);
    // Close the modal
    onClose();
  };

  React.useEffect(() => {
    // Remove the translations of previous companies.
    let newHotel = libraryItem;
    newHotel = deleteAlreadyTranslatedFields(libraryItem, user);
    // Make sure that the creator of the new translation-fields is set!
    newHotel = recursivelyReplaceObjectValues<Hotel>(newHotel, (k, v) => (k === "creator" || k === "user" ? user : v));
    console.log(newHotel);
    formMethods.reset(newHotel);
  }, [libraryItem, formMethods.reset, user]);

  const translationFields: TranslationField[] = formMethods.getValues(`descriptions`);

  return (
    <TripLanguageProvider doubleFields={false} first={true} defaultPartialFields={translationFields}>
      <Form formMethods={formMethods} onSubmit={handleSave}>
        {FormMap[eventType]}
        <Flex position={"absolute"} bottom={4} right={4}>
          <ButtonGroup ml="auto">
            <Button onClick={onClose} colorScheme="red" variant="outline">
              Cancel
            </Button>
            <Button
              // Don't add type="submit" as this will also trigger the submit button of the trip.
              // type="submit"
              // Hence, manually trigger the form submission here.
              onClick={formMethods.handleSubmit(handleSave)}
              isLoading={isSubmitting}
              colorScheme="brand"
            >
              {type === "save-to-library" ? `Save to library` : `Save`}
            </Button>
          </ButtonGroup>
        </Flex>
      </Form>
    </TripLanguageProvider>
  );
};
