import { Flex, Avatar, Box, Text, Spacer, Badge } from "@chakra-ui/react";
import { formatRelative } from "date-fns";
import React from "react";
import { Message } from "../../pages/chats";
import { getLocalStorageNotificationValue } from "../Notifications/NotificationFunctions";

type ChatListItemProps = {
  lastMessage: Message;
  active: boolean;
  selectedTripId: string | undefined;
  setSelectedTripId: (tripId: string | undefined) => void;
};

const ChatListItem: React.FC<ChatListItemProps> = ({ lastMessage, active, setSelectedTripId }) => {
  const localValue = getLocalStorageNotificationValue();
  const chatObj = localValue.find((e: any) => e.usertripId === lastMessage.usertrip.id);
  return (
    <Flex
      as="button"
      type="button"
      w="100%"
      p={2}
      _hover={{ bg: active ? "teal.50" : "gray.100" }}
      bg={active ? "teal.50" : ""}
      onClick={() => {
        setSelectedTripId(lastMessage.usertrip.id);
      }}
    >
      <Avatar
        name=""
        src={lastMessage.usertrip.trip!.tripdays[lastMessage.usertrip.homeBackgroundNumber]?.image?.url}
      />
      <Box ml="3" overflow="hidden" w="100%">
        <Flex alignItems="center">
          <Text fontSize="md" fontWeight="bold" isTruncated textAlign="left">
            {lastMessage.usertrip.trip!.titles[0].content}
          </Text>
          <Spacer />
          <Badge variant="solid" colorScheme="brand">
            {chatObj?.count}
          </Badge>
        </Flex>
        <Text fontSize="sm" fontWeight="bold" isTruncated textAlign="left" mt={-1} color="gray.500">
          {lastMessage.usertrip.trip!.client_name}
        </Text>
        {/* <Text fontSize="xs" isTruncated textAlign="left">
          {new Date(lastMessage.trip.start_date) < new Date()
            ? "started "
            : "starts "}
          {formatDistanceToNow(new Date(lastMessage.trip.start_date), {
            addSuffix: true,
          })}
        </Text> */}
        <Flex alignItems="center">
          <Text fontSize="xs" isTruncated overflow="hidden" whiteSpace="nowrap" textAlign="left" color="gray.500">
            {lastMessage.message}
          </Text>
          <Spacer />
          <Text fontSize="xs" color="gray.500">
            {formatRelative(new Date(lastMessage.created_at), new Date())}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
export default ChatListItem;
