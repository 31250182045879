import { Box } from "@chakra-ui/react";
import { HOTEL_BOOKING_STATUS, TranslationField } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useFormContext } from "react-hook-form";
import HotelsAPI from "../../../../../../api/hotels.api";
import { TripLanguageProvider } from "../../../../../../stores/trip-language-context";
import { TRIPDAY_ACCOMMODATION_QUERY_KEY } from "../../../../../../utils/constants";
import TranslateTitle from "../../../../../input/TranslateTitle";
import RHFRichTextEditor2 from "../../../../../input/wysiwyg/RHFRichTextEditor2";
import LibraryItemDownshift, { LIBRARYITEM_TYPE } from "../LibraryItemDownshift";
import { LibraryItem } from "../LibraryItemModal";
import StarRating from "./StarRating";
import RHFTranslationInput from "../../../../../input/RHFTranslationInput";
import { Descriptions } from "./Descriptions";

interface HotelFormOutsideTripProps {
  updateAccommodation: () => void;
  forceNew: boolean;
}
const HotelFormOutsideTrip: React.FC<HotelFormOutsideTripProps> = ({ updateAccommodation, forceNew }) => {
  const queryClient = useQueryClient();

  const { getValues, reset } = useFormContext();
  const { setValue, getValues: getLibraryItemValues } = useFormContext<LibraryItem>();

  const [id, setId] = React.useState(getValues("id"));
  const [forceNewState, setForceNewState] = React.useState(forceNew);

  const translationFields: TranslationField[] = getValues(`descriptions`);

  // Get the index inside the translation fields of the default language. If the default language is not yet translated, return 0.
  return (
    <>
      <TripLanguageProvider doubleFields={false} first={true} defaultPartialFields={translationFields}>
        <TranslateTitle
          isFirst={true}
          name={"descriptions"}
          getValues={getValues}
          elementId={id}
          forceNew={forceNew}
          addLanguageCall={async (id: string, code: string, translateFields: any) => {
            const formValues = getValues();
            let response;
            if (!id || forceNewState) {
              response = await HotelsAPI.post({
                ...formValues,
                id: forceNew ? undefined : id,
                bookedStatus: HOTEL_BOOKING_STATUS.QUOTE,
                bookingRef: undefined,
              });
              id = response.id;
              setForceNewState(false);
            } else {
              await HotelsAPI.patch(id, formValues);
            }
            setId(id);
            response = await HotelsAPI.addLanguage(id, code, translateFields);
            if (!forceNew) {
              queryClient.setQueryData([TRIPDAY_ACCOMMODATION_QUERY_KEY, id], response.element);
              updateAccommodation(response.element);
            }
            reset(response.element);
          }}
          removeLanguageCall={async (i: number, code: string) => {
            const formValues = getValues();
            let id = formValues.id;
            let response;
            if (!id || forceNewState) {
              response = await HotelsAPI.post({ ...formValues, id: forceNew ? undefined : id });
              id = response.id;
              setForceNewState(false);
            } else {
              await HotelsAPI.patch(id, formValues);
            }
            setId(id);
            response = await HotelsAPI.removeLanguage(id, code);
            if (!forceNew) {
              queryClient.setQueryData([TRIPDAY_ACCOMMODATION_QUERY_KEY, id], response.element);
              updateAccommodation(response.element);
            }
            reset(response.element);
          }}
        />

        <Box
          mt={"1.3em !important"}
          ml={{ base: "-8em", sm: "-8em", md: "-1em", lg: "-8em", xl: "-1em", "2xl": "-1em" }}
        >
          <StarRating name={"rating"} scale={1} />
        </Box>
        <LibraryItemDownshift
          inTrip={false}
          outsideTripLibraryitem={getValues()}
          libraryItemName={LIBRARYITEM_TYPE.HOTELS}
          first={true}
          getValues={getValues}
          location={null}
          collaboratorCanEdit={false}
          reset={updateAccommodation}
          placeholder="Hotel name"
        />

        <Descriptions fieldName={"descriptions"} elementType="hotel" />
      </TripLanguageProvider>
    </>
  );
};
export default React.memo(HotelFormOutsideTrip);
