import { Box, LinkBox, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import UsersAPI from "../../api/users.api";
import ImageCropperModal from "./ImageCropperModal";
import ImageUploaderButton from "./ImageUploaderButton";
import ImageDropzone from "./dropzone/ImageDropzone";

interface ImageUploaderProps {
  submitPicture: (cropper: Cropper) => Promise<void>;
  dropzone: boolean;
  type: "profile" | "image";
  aspectRatio?: number;
  modalExplanation?: string;
  zoomable?: boolean;
  children: React.ReactNode;
}

export const maxFileSizeMB = 10;

const ImageUploader: React.FC<ImageUploaderProps> = ({
  children,
  submitPicture,
  type,
  aspectRatio,
  dropzone,
  modalExplanation,
  zoomable = true,
}) => {
  const modalDisclosure = useDisclosure();
  const [img, setImg] = React.useState<File>();

  const handleChosenImage = (image: File) => {
    // Set the image to the chosen file
    setImg(image);
    // Open the modal
    modalDisclosure.onOpen();
  };
  return (
    <>
      {dropzone ? (
        <ImageDropzone handleDroppedImages={handleChosenImage} multiple={false} />
      ) : (
        <ImageUploaderButton handleChosenImage={handleChosenImage}>{children}</ImageUploaderButton>
      )}
      <ImageCropperModal
        type={type}
        modalDisclosure={modalDisclosure}
        img={img}
        setImg={setImg}
        submitPicture={submitPicture}
        aspectRatio={aspectRatio}
        modalExplanation={modalExplanation}
        zoomable={zoomable}
      />
    </>
  );
};
export default ImageUploader;
