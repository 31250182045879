import React from "react";
import { MdHotel } from "react-icons/md";
import EventTypeCard from "./EventTypeCard";

interface AddHotelCardProps {
  tripdayIndex: number;
  nrOfNights: number;
  addHotel: any;
  first: boolean;
  nrOfHotels: number;
}

const AddHotelCard: React.FC<AddHotelCardProps> = ({ nrOfNights, addHotel, first, nrOfHotels }) => {
  if (nrOfHotels >= 5 || nrOfNights === 0 || !first) {
    return null;
  }

  return <EventTypeCard name={"Accommodation"} icon={MdHotel} onClick={addHotel} />;
};

export default React.memo(AddHotelCard);
