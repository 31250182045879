export function getDistanceFromLatLonInKm(point1, point2) {
    var { lng: lng1, lat: lat1 } = point1;
    var { lng: lng2, lat: lat2 } = point2;
    if (!lng1 || !lng2 || !lat1 || !lat2)
        return 0;
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lng2 - lng1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}
function deg2rad(deg) {
    return deg * (Math.PI / 180);
}
function calculateAngle(centerLocation, coord) {
    // Convert latitude and longitude from degrees to radians
    const lat1 = (Math.PI / 180) * centerLocation.lat;
    const lon1 = (Math.PI / 180) * centerLocation.lng;
    const lat2 = (Math.PI / 180) * coord.lat;
    const lon2 = (Math.PI / 180) * coord.lng;
    // Calculate the differences in radians
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    // Calculate the angle in radians
    let angle = Math.atan2(dLon, -dLat);
    // Convert the angle from radians to degrees
    angle = (angle * 180) / Math.PI;
    // Normalize the angle to be in the range [0, 360)
    angle = (angle + 360) % 360;
    return angle;
}
function calculateDistanceInKilometers(centerLocation, coord) {
    const earthRadius = 6371; // Radius of the Earth in kilometers
    // Convert latitude and longitude from degrees to radians
    const lat1 = (Math.PI / 180) * centerLocation.lat;
    const lon1 = (Math.PI / 180) * centerLocation.lng;
    const lat2 = (Math.PI / 180) * coord.lat;
    const lon2 = (Math.PI / 180) * coord.lng;
    // Calculate the differences in radians
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    // Haversine formula to calculate distance in kilometers
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // Calculate the distance in kilometers
    const distance = earthRadius * c;
    return distance;
}
export function GetCenterPointFromCoords(markerPositions) {
    const numCoords = markerPositions.length;
    // Calculate the average latitude and longitude
    const sumLat = markerPositions.reduce((sum, coord) => sum + coord.lat, 0);
    const sumLng = markerPositions.reduce((sum, coord) => sum + coord.lng, 0);
    const centerLocation = {
        lat: sumLat / numCoords,
        lng: sumLng / numCoords,
    };
    // Calculate distances and angles from the center point
    const distances = markerPositions.map((coord) => calculateDistanceInKilometers(centerLocation, coord));
    const angles = markerPositions.map((coord) => calculateAngle(centerLocation, coord));
    return { centerLocation, distances, angles };
}
