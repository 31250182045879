import { PaymentInvitation, QueryRelation } from "@lato/common";
import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { SHARE_STEP_PAYMENT_INVITATIONS_QUERY_KEY } from "../utils/constants";
import PaymentInvitationAPI from "./paymentInvitations.api";
import rawApiProvider from "./utilities/rawProvider";

const url = "usertrips-split";

// Get a trip with the temperatures
const getSplitUserTrip = (userTripId: string, relations: string) => {
  return rawApiProvider.get(`${url}/${userTripId}?relation=` + relations);
};

const patchSplitUserTrip = (id: string, usertrip: Partial<UserTrip>, relations: QueryRelation) => {
  return rawApiProvider.patch(`${url}/${id}`, { userTripDTO: usertrip, relation: relations });
};

const patchPaymentInvitations = (userTripId: string, paymentInvitations: PaymentInvitation[]) => {
  return rawApiProvider.patch(`${url}/${userTripId}/payment-invitations`, { paymentInvitations });
};

type UserTripsSplitAPIType = {
  getSplitUserTrip(id: string, relations: string): Promise<any>;
  patchSplitUserTrip(id: string, usertrip: Partial<UserTrip>, relations: QueryRelation): Promise<any>;
  patchPaymentInvitations(userTripId: string, paymentInvitations: PaymentInvitation[]): Promise<any>;
};

const UserTripsSplitAPI: UserTripsSplitAPIType = {
  getSplitUserTrip,
  patchSplitUserTrip,
  patchPaymentInvitations,
};

export const usePatchPaymentInvitations = (
  userTripId: string,
  //relations: QueryRelation,
  queryClient: any,
  queryKey: string = SHARE_STEP_PAYMENT_INVITATIONS_QUERY_KEY,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  return useMutation({
    mutationFn: async (paymentInvitations: PaymentInvitation[]) => {
      return await UserTripsSplitAPI.patchPaymentInvitations(userTripId, paymentInvitations);
    },
    onSuccess: (response) => {
      queryClient.setQueryData([queryKey, userTripId], response);
    },
  });
};

export const usePatchPaymentInvitation = (
  queryClient: any,
  id?: string,
  queryKey: string = SHARE_STEP_PAYMENT_INVITATIONS_QUERY_KEY,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  return useMutation({
    mutationFn: async (paymentInvitation: PaymentInvitation) => {
      return await PaymentInvitationAPI.patchPaymentInvitation(paymentInvitation);
    },
    onMutate: async (paymentInvitation) => {
      if (id) {
        const previousPaymentInvitations = queryClient.getQueryData([queryKey, id]);

        const index = previousPaymentInvitations.findIndex((pt: PaymentInvitation) => pt.id === paymentInvitation.id);
        previousPaymentInvitations[index] = paymentInvitation;
        queryClient.setQueryData([queryKey, id], previousPaymentInvitations);
      }
    },
    onSuccess: (tasks) => {
      if (id) {
        queryClient.invalidateQueries({ queryKey: [queryKey, id] });
      }

      queryClient.invalidateQueries({
        queryKey: [SHARE_STEP_PAYMENT_INVITATIONS_QUERY_KEY],
      });
    },
  });
};

export const useAddPaymentInvitation = (queryClient: any, id?: string, queryKey?: string) => {
  return useMutation({
    mutationFn: async (paymentInvitation: PaymentInvitation) => {
      return await PaymentInvitationAPI.addPaymentInvitation(paymentInvitation);
    },
    onMutate: async (newPaymentInvitation) => {},
    onError: (err, newPaymentInvitation, context) => {},
    // Always refetch after error or success:
    onSettled: (newPaymentInvitation) => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });

      // const isToday = new Date(newPaymentInvitation.due_date).toDateString() === new Date().toDateString();

      // if (isToday) {
      //   queryClient.invalidateQueries({ queryKey: [queryKey] });
      // }

      // if (id) {
      //   queryClient.invalidateQueries({ queryKey: [queryKey, id] });
      // }
    },
  });
};

export default UserTripsSplitAPI;
