import { Box, BoxProps, Flex, Link, Text, Tooltip, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AuthAPI from "../../../api/auth.api";
import { User } from "@lato/common";
import { ToastType, addToast } from "../../../utils/addToast";
import { DevTool } from "../../../utils/hookform-devtools";
import { handleSubmission } from "../../../utils/toErrorMap";
import useUnsavedChangesPrompt from "../../../utils/useUnsavedChangesPrompt";
import Form from "../../form/Form";
import SubmitButton from "../../form/SubmitButton";
import RHFInput from "../../input/RHFInput";
import Section, { horizontalSectionPadding } from "../../layout/Section";

interface PasswordSettingsProps {
  user: User;
}

type PasswordFormValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const PasswordSettings: React.FC<PasswordSettingsProps> = ({ user }) => {
  const toast = useToast();

  const { email, verified } = user;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        oldPassword: yup.string().required("Old password is required"),
        newPassword: yup.string().required("New password is required"),
        newPasswordConfirm: yup
          .string()
          .required("Confirm new password is required")
          .test({
            name: "password-confirmation",
            message: "Passwords do not match",
            test: function () {
              const { newPassword, newPasswordConfirm } = this.parent;
              if (newPassword && newPasswordConfirm !== newPassword) {
                return false;
              }
              return true;
            },
          }),
      }),
    [],
  );

  // OnTouched results in a small bug for the submit button disable functionality,
  // when filling it in correctly on the first try, the button is still disabled,
  // the user must first leave (blur) the input before the button becomes enabled
  const formMethods = useForm<PasswordFormValues>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
  });

  useUnsavedChangesPrompt(formMethods.formState.isDirty);

  const handleAdditionalError = (statusCode: number) => {
    if (statusCode === 401) {
      // Unauthorized exception
      // Hence, wrong credentials were given
      formMethods.setError("oldPassword", {
        type: "manual",
        message: "Wrong password provided",
      });
    }
  };

  const changePassword = async (values: PasswordFormValues) => {
    await handleSubmission({
      successMessage: "updated password",
      failMessage: "updating password",
      apiCall: AuthAPI.changePassword(values),
      toast,
      setError: formMethods.setError,
      handleAdditionalError,
    });
    // TODO: after changing password let the user logged in but send a mail to the user just like Github does, include a link to undo this change in the mail
  };

  const forgotPassword = async () => {
    // If the user is not yet verified, don't let him send a pasword reset email.
    if (!verified) return;
    let toastOptions: ToastType;
    try {
      console.log("forgot password clicked");
      await AuthAPI.forgotPassword(email);
      console.log("forgot password token generated");
      toastOptions = {
        title: `Email sent to ${email}.`,
        description: `To reset your password, please check your mailbox.`,
        status: "success",
      };
      // TODO: show spinner, and maybe add popup?
    } catch (error) {
      console.log(error);
      toastOptions = {
        title: `Failed sending a mail to ${email}`,
        status: "error",
      };
    }
    addToast(toast, toastOptions);
  };

  return (
    <Section title="Change password" h="auto" noHorizontalPadding>
      <DevTool control={formMethods.control} />
      {/* <Box w="65%" minW="500px"> */}
      <Form formMethods={formMethods} onSubmit={changePassword}>
        <SettingsInputWrapper>
          <RHFInput
            name="oldPassword"
            inputWidth={{ base: "100%", md: "63%" }}
            labelWidth={{ base: "100%", md: "250px" }}
            label="Old password"
            placeholder="•••••"
            type="password"
            layout="horizontal"
          />
        </SettingsInputWrapper>
        <SettingsInputWrapper hasBg>
          <RHFInput
            name="newPassword"
            label="New password"
            placeholder="••••••••"
            type="password"
            layout="horizontal"
            inputWidth={{ base: "100%", md: "63%" }}
            labelWidth={{ base: "100%", md: "250px" }}
          />
        </SettingsInputWrapper>
        <SettingsInputWrapper>
          <RHFInput
            name="newPasswordConfirm"
            label="Confirm new password"
            placeholder="••••••••"
            type="password"
            layout="horizontal"
            inputWidth={{ base: "100%", md: "63%" }}
            labelWidth={{ base: "100%", md: "250px" }}
          />
        </SettingsInputWrapper>
        <Flex alignItems="center" mt={2} px={horizontalSectionPadding}>
          <SubmitButton>Save</SubmitButton>
          <Tooltip
            label={!verified ? "First confirm email address" : "Send a reset password email"}
            bg={!verified ? "red.100" : undefined}
            color={!verified ? "red.800" : undefined}
          >
            <Link
              as="button"
              type="button"
              ml={3}
              onClick={forgotPassword}
              color="brand.700"
              _hover={{
                cursor: !verified ? "not-allowed" : "pointer",
              }}
            >
              <Text fontSize="md">Forgot your password?</Text>
            </Link>
          </Tooltip>
        </Flex>
      </Form>
      {/* </Box> */}
    </Section>
  );
};
export default PasswordSettings;

type SettingsInputWrapperProps = BoxProps & {
  hasBg?: boolean;
  internalWidth?: any;
  internalMinWidth?: any;
};
export const SettingsInputWrapper: React.FC<SettingsInputWrapperProps> = ({
  hasBg = false,
  internalWidth,
  internalMinWidth,
  children,
  ...props
}) => {
  return (
    <Box bg={hasBg ? "gray.50" : undefined} px={horizontalSectionPadding} py={1} {...props}>
      <Box w={internalWidth} minW={internalMinWidth}>
        {children}
      </Box>
    </Box>
  );
};
