import { Image as ImageType } from "@lato/common";

export const afterChooseElementPictures = async (
  images: ImageType[],
  tripdayId: string | undefined,
  updateTripday: (tripday: any) => any,
  firstImagelibrary: boolean,
  tripdayIndex: number,
  setInTripStore: any,
  tripdayInStore?: any,
) => {
  if (images.length === 0 || tripdayId == undefined) {
    return;
  }
  let tripday = {
    id: tripdayId,
  };

  if (firstImagelibrary) {
    tripday = {
      ...tripday,
      libraryImage: images[0],
    } as any;
    tripdayInStore
      ? setInTripStore(`tripdays.${tripdayIndex}.libraryImage`, tripday?.libraryImage)
      : setInTripStore(`tripdays.${tripdayIndex}`, { libraryImage: tripday?.libraryImage });
    await updateTripday({ libraryImage: tripday?.libraryImage });
  } else {
    tripday = {
      ...tripday,
      image: images[0],
    } as any;
    tripdayInStore
      ? setInTripStore(`tripdays.${tripdayIndex}.image`, tripday?.image)
      : setInTripStore(`tripdays.${tripdayIndex}`, { image: tripday?.image });
    await updateTripday({ image: tripday?.image });
  }
  // Check if there's already an image
};
