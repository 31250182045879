import React from "react";
import Settings from "../../components/settings/Settings";
import Integrations from "../../components/settings/integrations/Integrations";

interface integrationsSettingsProps {}

export const IntegrationsSettings: React.FC<integrationsSettingsProps> = () => {
  return (
    <Settings tabName={"Integrations"}>
      <Integrations />
    </Settings>
  );
};
export default IntegrationsSettings;
