import { CalendarIcon } from "@chakra-ui/icons";
import { Box, Button, Portal as ChakraPortal, Flex, Heading, Link, Stack, useDisclosure } from "@chakra-ui/react";
import { HOTEL_ORIGIN } from "@lato/common";
import React from "react";
import { useForm } from "react-hook-form";
import { BiMoney } from "react-icons/bi";
import { LiaFontAwesomeFlag, LiaStar } from "react-icons/lia";
import { useLocation, useParams } from "react-router-dom";
import { useAsyncDebounce } from "react-table";
import { trpc } from "../../../trpc";
import { useMeContext } from "../../stores/me-context";
import { usePaginatedQuery } from "../../utils/query-helpers/usePaginatedQuery";
import Pagination from "../../components/CRUD/Pagination";
import { GlobalFilter } from "../../components/CRUD/Resource";
import { FILTER_TYPE } from "../../components/CustomDownShift/CustomComboBox";
import { FilterSelect } from "../../components/CustomDownShift/FilterSelect";
import { CustomSpinner } from "../../components/FullScreenSpinner";
import HolderPicker from "../../components/elements/accommodations/HolderPicker";
import Form from "../../components/form/Form";
import Section from "../../components/layout/Section";
import { SingleAccommodation } from "../../components/trips/edit/daybyday/library-items/SingleAccommodation";
import BookModal from "../../components/trips/edit/daybyday/library-items/hotel/BookModal";
import Bookings from "./Bookings";

export const BookDetachedWrapper: React.FC = () => {
  return (
    <Bookings>
      <BookDetached />
    </Bookings>
  );
};

interface BookDetachedProps {}

// component to search for hotels to make a booking
// currently only works for hotelbeds
const BookDetached: React.FC<BookDetachedProps> = () => {
  const location = useLocation();

  // when the booking is made for a trip the accommodationId of the LatoHotel in DB is passed in the url
  const { accommodationId } = useParams<{ accommodationId: string }>();

  const params = new URLSearchParams(location?.search);

  // filterParams passed in the url
  const originParam = params.get("origin");
  const startDate = params.get("start");
  const endDate = params.get("end");
  const firstName = params.get("firstName");
  const lastName = params.get("lastName");
  const adults = params.get("adults");
  const children = params.get("children");
  const coordinatesParam = params.get("location");
  const locationName = params.get("locationName");

  // searchInput
  const [globalFilter, setGlobalFilter] = React.useState("");
  //ratingFilter
  const [rating, setRating] = React.useState([0, 5]);
  // origin state, currently only hotelbeds
  const [origin, setOrigin] = React.useState([originParam ?? HOTEL_ORIGIN.HOTELBEDS]);
  // locationFilter
  const [coordinates, setCoordinates] = React.useState(
    coordinatesParam ? coordinatesParam.split(";").map((coord) => +coord) : undefined,
  );

  // pagination page
  const [cursor, setCursor] = React.useState(1);
  // pagination step
  const [limit, setLimit] = React.useState(10);
  // priceRange filter
  const [priceRange, setPriceRange] = React.useState([0, 2000]);
  // occupance Filter
  const [rooms, setRooms] = React.useState([
    {
      adults: +(adults ?? 1),
      children: children ? children.split(";").length : 0,
      ages: children ? children.split(";").map((age) => +age) : [],
    },
  ]);

  const me = useMeContext();
  const today = startDate ? new Date(startDate) : new Date();
  const tomorrow = endDate ? new Date(endDate) : new Date();
  if (!endDate) {
    tomorrow.setDate(tomorrow.getDate() + 1);
  }

  // dateRange Filter, when this is filled an availability request will be performed to hotelbeds
  // if not filled hotels will be searched in lato DB
  const [availabilityDateRange, setAvailabilityDateRange] = React.useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: today,
    to: tomorrow,
  });

  const inputHasEmptyValue = (!globalFilter || globalFilter === "") && coordinates;

  const formMethods = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      rooms,
    },
    shouldUnregister: false,
    criteriaMode: "all",
  });

  const {
    paginationOptions,
    queryResult: { data, isLoading },
    triggerFetch,
  } = usePaginatedQuery<any>(
    {
      queryName: "hotelbed-hotels",
      apiCall: inputHasEmptyValue ? trpc.hotelbeds.hotelbedsListAvailability : trpc.hotelbeds.getHotelbedHotels,
      additionalQueryState: {
        userId: me.id,
        location: coordinates,
        rating,
        q: globalFilter ?? "",
        cursor,
        limit,
        descriptions: false,
        from: availabilityDateRange.from,
        to: availabilityDateRange.to,
        minRate: priceRange[0],
        maxRate: priceRange[1],
        rooms,
      },
    },
    true,
  );

  const hotels = React.useMemo(() => data?.data ?? [], [data]);

  // pagination options
  const tableMethods = {
    state: {
      pageIndex: cursor - 1,
      pageSize: limit,
    },
    page: hotels,
    pageOptions: paginationOptions,
    nextPage: () => {
      setCursor(cursor + 1);
    },
    previousPage: () => {
      setCursor(cursor - 1);
    },
    gotoPage: (page: number) => {
      setCursor(page + 1);
    },
    setPageSize: (size: number) => {
      setLimit(size);
    },
    canNextPage: !!(data as any)?.nextCursor,
    canPreviousPage: cursor > 1,
    pages: Math.ceil((data?.count ?? 0) / limit),
  };

  // query Parameters that will be passed to the bookingsPage
  const queryParam = accommodationId
    ? `&start=${today}&end=${tomorrow}&firstName=${firstName}&lastName=${lastName}&adults=${adults}&children=${children}&accommodationId=${accommodationId}`
    : "";

  return (
    <Stack w="100%" maxW="1000px" mx="auto" mb={10} gap={4} mt={accommodationId ? 10 : undefined}>
      <Form formMethods={formMethods} onSubmit={() => {}}>
        <Flex gap={0} flexDir={"column"}>
          <Flex gap={4}>
            <Heading mb={5}>Book</Heading>
            {/* <FilterSelect
              icon={LiaStar}
              onChange={setOrigin}
              defaultSelected={origin}
              name="origin"
              type={FILTER_TYPE.MULTISELECT}
              options={[{ text: "Hotelbeds", value: HOTEL_ORIGIN.HOTELBEDS }]}
            /> */}
            <Box w="30%">
              <GlobalFilter
                globalFilter={globalFilter}
                setGlobalFilter={useAsyncDebounce((e) => {
                  setGlobalFilter(e);
                  tableMethods.gotoPage(0);
                }, 400)}
                placeholder={"Search for a hotel"}
                borderRadius="xl"
              />
            </Box>
            <FilterSelect
              onChange={(e) => {
                setCoordinates(e);
                tableMethods.gotoPage(0);
              }}
              icon={LiaFontAwesomeFlag}
              defaultSelected={coordinates && coordinates[0] === 0 && coordinates[1] === 0 ? undefined : coordinates}
              name={locationName ?? ""}
              type={FILTER_TYPE.LOCATION}
            />
            <FilterSelect
              options={5}
              onChange={(e) => {
                setRating(e);
                tableMethods.gotoPage(0);
              }}
              icon={LiaStar}
              defaultSelected={rating}
              name="rating"
              type={FILTER_TYPE.RANGE}
              height={"2em"}
            />
          </Flex>
          <Flex gap={4} ml={"4.8em"}>
            <FilterSelect
              onChange={(e) => {
                setAvailabilityDateRange(e);
                tableMethods.gotoPage(0);
              }}
              icon={CalendarIcon}
              defaultSelected={availabilityDateRange}
              name={"availabilityDateRange"}
              type={FILTER_TYPE.DATERANGE}
              height={"2em"}
              hidden={!inputHasEmptyValue}
            ></FilterSelect>
            <FilterSelect
              options={2000}
              onChange={useAsyncDebounce((e: any) => {
                setPriceRange(e);
                tableMethods.gotoPage(0);
              }, 200)}
              icon={BiMoney}
              defaultSelected={priceRange}
              name="priceRange"
              type={FILTER_TYPE.RANGE}
              height={"2em"}
              hidden={!inputHasEmptyValue}
            />
            <Box width={"30%"} hidden={!inputHasEmptyValue}>
              <HolderPicker
                borderRadius="10em"
                beforeClose={() => {
                  setRooms(
                    formMethods
                      .getValues("rooms")
                      .map((room: any) => ({ ...room, adults: +room.adults, children: +room.children })),
                  );
                  tableMethods.gotoPage(0);
                }}
              ></HolderPicker>
            </Box>
          </Flex>
        </Flex>
      </Form>

      {isLoading ? (
        <CustomSpinner />
      ) : (
        <>
          {data && (
            // pagination buttons
            <Pagination onlyButtons tableMethods={tableMethods} pageSize={limit} totalCount={data.count}></Pagination>
          )}
          {(hotels ?? []).map((hotel: any) => (
            <DetachedAccommodation hotel={hotel} queryParam={queryParam} />
          ))}
          {data && (
            // pagination buttons at bottom of page
            <Pagination tableMethods={tableMethods} pageSize={limit} totalCount={data.count}></Pagination>
          )}
        </>
      )}
    </Stack>
  );
};

interface DetachedAccommodationProps {
  hotel: any;
  queryParam: string;
}

const tripBookingsWrapper: React.FC = () => {
  return <BookDetached />;
};

const DetachedAccommodation: React.FC<DetachedAccommodationProps> = ({ hotel, queryParam }) => {
  const bookDisclosure = useDisclosure();
  const user = useMeContext();

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const formMethods = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: hotel,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  // the shown hotel is just a link to a bookingpage
  return (
    <>
      <Link
        ml="auto"
        mr={"2em"}
        href={`/accommodations/${hotel?.originCode}?origin=${hotel?.origin}&detached=true${queryParam}`}
        target={"_blank"}
        w={"100%"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Section
          noDivider
          noHorizontalPadding
          noVerticalPadding
          //onClick={bookDisclosure.onOpen}
          cursor={"pointer"}
          _hover={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)" }}
        >
          <Form formMethods={formMethods} onSubmit={() => {}}>
            <Flex gap={4}>
              <SingleAccommodation item={hotel} scale={3} width={"100%"} />
              <Button ml="auto" mr={"2em"} colorScheme="brand" fontSize="18px" width={"4em"} py={"0.8em"}>
                Book
              </Button>
            </Flex>
            {bookDisclosure.isOpen && (
              // alternate way to bookingFlow
              // currently not used
              <ChakraPortal>
                <BookModal
                  onClose={bookDisclosure.onClose}
                  isOpen={bookDisclosure.isOpen}
                  hotel={hotel}
                  roomCall={trpc.hotelbeds.getHotelbedRooms}
                  startDate={today}
                  endDate={tomorrow}
                  adults={1}
                  children={[]}
                  setRooms={() => {}}
                  detached
                  clientReference={user.company.hotelbeds_client_id}
                />
              </ChakraPortal>
            )}
          </Form>
        </Section>
      </Link>
    </>
  );
};

export default tripBookingsWrapper;
