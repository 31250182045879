import axios, { AxiosRequestConfig } from "axios";
import config from "../../config";
import ENV from "../../utils/env";
import { handleError, handleResponse } from "./response";

// Define your api url from any source.
const api_version = "/api/v1";
const BASE_URL = config[ENV].api_url + api_version;
axios.defaults.baseURL = BASE_URL; // the prefix of the URL
axios.defaults.headers.get["Accept"] = "application/json"; // default header for all get request
axios.defaults.headers.post["Accept"] = "application/json"; // default header for all POST request
axios.defaults.headers.common["Content-Type"] = "application/json"; // default header for all requests
axios.defaults.withCredentials = true; // Include the cookie credentials by default

/** @param {string} resource */
const get = async (resource: string, config?: AxiosRequestConfig | undefined) => {
  try {
    const response = await axios.get(`/${resource}`, config);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource: string, model: object) => {
  return axios.post(`/${resource}`, model).then(handleResponse).catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource: string, model: object) => {
  return axios.put(`/${resource}`, model).then(handleResponse).catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const patch = (resource: string, model: object) => {
  return axios.patch(`/${resource}`, model).then(handleResponse).catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource: string) => {
  return axios.delete(`/${resource}`).then(handleResponse).catch(handleError);
};

const rawApiProvider = {
  get,
  post,
  put,
  patch,
  remove,
};
export default rawApiProvider;
export { axios };
