import { create } from "zustand";

interface TripsListState {
  continentFilter: string;
  countryFilter: string;
  travelingFilter: string;
  setContinentFilter: (by: string) => void;
  setCountryFilter: (by: string) => void;
  setTravelingFilter: (by: string) => void;
  resetStore: () => void;
}

const initialState = {
  continentFilter: "",
  countryFilter: "",
  travelingFilter: "",
};

export const useTripsListStore = create<TripsListState>((set) => ({
  ...initialState,
  setContinentFilter: (filterValues: string) => set(() => ({ continentFilter: filterValues })),
  setCountryFilter: (filterValues: string) => set(() => ({ countryFilter: filterValues })),
  setTravelingFilter: (filterValues: string) => set(() => ({ travelingFilter: filterValues })),
  resetStore: () => {
    set(initialState);
  },
}));
