import { Box, Button, HStack, Icon, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trip, TripDay, UserTrip } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { BsGridFill } from "react-icons/bs";
import { TiThList } from "react-icons/ti";
import { useParams } from "react-router-dom";
import { POI } from "../../../../api/pois.api";
import { useMeContext } from "../../../../stores/me-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { DAY_BY_DAY_STEP_TRIP_QUERY_KEY, DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY } from "../../../../utils/constants";
import { calculateEndDate } from "../../../../utils/date";
import { getViewTripLink } from "../../../../utils/generateURLs";
import {
  getRelationMap,
  updateRelationMap,
  usePatchSplitTrip,
  useSplitTrip,
  useSplitUserTrip,
  useTripTranslations,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { tripValidationSchemas } from "../../../../validation/validationSchemas";
import { OverviewSkeleton, WizardSkeleton } from "../../../FullScreenSpinner";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "../../../Resizable";
import TripsOverviewTable from "../../../../features/editTrip/overviewStep/TripsOverviewTable";
import Overlay, { DisabledMessage } from "../../../accessiblity/overlay";
import Wizard from "../../../../features/editTrip/wizard/Wizard";
import TripForm from "../../TripForm";
import OverviewMap from "./OverviewMap";
import { useWindowDimensions } from "../../../../utils/useWindowDimensions";

interface TripsOverViewStepProps {
  stepNumber: number;
}

export const TripsOverViewStep: React.FC<TripsOverViewStepProps> = ({ stepNumber }) => {
  const { id: userTripId } = useParams<{ id: string }>();
  const { isFirst, isSample, trip: tripFromStore } = useTripFormStore();
  const me = useMeContext();

  const {
    data: trip,
    isLoading: isLoadingTrip,
    isFetching: isFetchingTrip,
    isFetchedAfterMount: isFetchedAfterMountTrip,
  } = useSplitTrip(tripFromStore?.id, DAY_BY_DAY_STEP_TRIP_QUERY_KEY, getRelationMap[DAY_BY_DAY_STEP_TRIP_QUERY_KEY]);

  const { data: usertrips, isLoading: isLoadingUserTrip } = useSplitUserTrip(
    userTripId ?? "",
    getRelationMap[DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY],
    DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY,
  );
  const { data: tripTranslations, isLoading: isLoadingTranslations } = useTripTranslations(tripFromStore?.id ?? "");

  const renderDisabledMessage = () => {
    if (!isFirst && !isLoadingTrip && trip) {
      return <DisabledMessage margin={3} collaboratorCanEdit={trip?.collaboratorCanEdit} />;
    }
    return null;
  };

  const getValues = React.useCallback(
    (value: string) => {
      return (tripTranslations as any)?.[value];
    },
    [tripTranslations],
  );

  return (
    <>
      {!me.useNewEditTripLayout &&
        (trip ? (
          <Wizard
            trip={trip}
            collaboratorCanEdit={trip.collaboratorCanEdit}
            stepNumber={stepNumber}
            isFirst={isFirst}
            sample={isSample}
            type="Edit"
          />
        ) : (
          <WizardSkeleton />
        ))}
      {renderDisabledMessage()}
      {isLoadingTrip ||
      isFetchingTrip ||
      isLoadingUserTrip ||
      !usertrips ||
      !trip ||
      (!isFetchedAfterMountTrip && isFetchingTrip) ? (
        <OverviewSkeleton />
      ) : (
        <TripsOverviewForm
          trip={trip}
          userTrip={(usertrips ?? []).at(-1)!}
          userTripId={userTripId!}
          isSample={isSample}
          isFirst={isFirst}
        />
      )}
    </>
  );
};

export default React.memo(TripsOverViewStep);

interface TripsOverviewFormProps {
  trip: Trip;
  userTrip: UserTrip;
  userTripId: string;
  isSample: boolean;
  isFirst: boolean;
}

const TripsOverviewForm: React.FC<TripsOverviewFormProps> = ({ trip, userTrip, userTripId, isSample, isFirst }) => {
  const formId = `1-${DAY_BY_DAY_STEP_TRIP_QUERY_KEY}`;
  const [horizontal, setHorizontal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<string | undefined>();
  const queryClient = useQueryClient();
  const me = useMeContext();

  const { width } = useWindowDimensions();

  const { mutateAsync: updateTrip, isPending: isLoadingUpdate } = usePatchSplitTrip(
    trip.id ?? "",
    updateRelationMap[DAY_BY_DAY_STEP_TRIP_QUERY_KEY],
    queryClient,
    DAY_BY_DAY_STEP_TRIP_QUERY_KEY,
  );

  const formMethods = useForm<Trip>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: trip,
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(tripValidationSchemas[1], { abortEarly: false }),
  });

  const handleSubmit = React.useCallback(async () => {
    const tripValues = formMethods.getValues();

    const tripdays = tripValues.tripdays.map((tripday, index) => ({
      ...tripday,
      destination: tripday.destination && {
        ...tripday.destination,
        id: tripday.destination.id ?? trip.tripdays.find((td) => td.id === tripday?.id)?.destination?.id,
      },
      dayNumber: index + 1,
    }));

    const nr_of_nights = tripdays.map((td) => td.nrOfNights).reduce((partialSum, a) => partialSum + a, 0);
    const nr_of_days = nr_of_nights + 1;

    const end_date = tripValues.start_date
      ? calculateEndDate(tripValues.start_date, Math.max(0, nr_of_days - 1))
      : null;

    const result = await updateTrip({
      id: tripValues.id,
      start_date: tripValues.start_date,
      end_date: end_date,
      tripdays: tripdays.map((tripday: TripDay) => ({
        id: tripday.id,
        dayNumber: tripday.dayNumber,
        nrOfNights: tripday.nrOfNights,
        location: tripday.location,
        destination: tripday.destination,
      })),
    });

    formMethods.reset(result);
  }, [formMethods, queryClient, trip]);

  return (
    <TripForm
      formId={formId}
      triggerResetData={trip}
      defaultValues={{ ...trip, distances: formMethods.getValues().distances ?? [] }}
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <Button type="submit" hidden isLoading={isLoadingUpdate} className="form-submit-button">
        Save
      </Button>
      {width > 1440 && !me.useNewEditTripLayout && (
        <HStack spacing={1} mb={2}>
          <Box as="button" type="button" onClick={() => setHorizontal(false)}>
            <Icon as={BsGridFill} boxSize="18px" p={0} m={0} color={horizontal ? "gray.300" : "teal.500"} />
          </Box>
          <Box as="button" type="button" onClick={() => setHorizontal(true)}>
            <Icon as={TiThList} boxSize="18px" p={0} m={0} color={horizontal ? "teal.500" : "gray.300"} />
          </Box>
        </HStack>
      )}
      {horizontal || width <= 1440 ? (
        <Stack h="90vh" w="100%" position={"relative"}>
          <ResizablePanelGroup direction="vertical">
            <ResizablePanel defaultSize={40}>
              <OverviewMap isDirectionsDisabled={!userTrip.show_directions} />
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel defaultSize={60}>
              <Box position={"relative"} h="100%">
                <Overlay display={!isFirst}>
                  <TripsOverviewTable
                    extended={horizontal}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    isFirst={isFirst}
                    sample={isSample}
                    isUpdatingTrip={isLoadingUpdate}
                    userTrip={userTrip}
                    handleSubmit={handleSubmit}
                  />
                </Overlay>
              </Box>
            </ResizablePanel>
          </ResizablePanelGroup>
        </Stack>
      ) : (
        <HStack h="90vh" w="100%" position={"relative"}>
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel defaultSize={60} className="relative">
              <Overlay display={!isFirst}>
                <TripsOverviewTable
                  extended={horizontal}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  isFirst={isFirst}
                  sample={isSample}
                  isUpdatingTrip={isLoadingUpdate}
                  userTrip={userTrip}
                  handleSubmit={handleSubmit}
                />
              </Overlay>
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel defaultSize={40}>
              <OverviewMap isDirectionsDisabled={!userTrip.show_directions} />
            </ResizablePanel>
          </ResizablePanelGroup>
        </HStack>
      )}
    </TripForm>
  );
};
function useWindowSize(): { width: any } {
  throw new Error("Function not implemented.");
}
