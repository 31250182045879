import { ButtonGroup, IconButton } from "@chakra-ui/button";
import { ChevronDownIcon, ChevronUpIcon, DragHandleIcon } from "@chakra-ui/icons";
import { BoxProps } from "@chakra-ui/layout";
import React from "react";
import { type DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { UseFormGetValues } from "react-hook-form";
import EventOptions from "./library-items/hotel/EventOptions";

interface EventFormProps extends BoxProps {
  handleDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleDuplicate: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleTripdayMove: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tripdayIndex: any) => void;
  handleMoveLeft?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleMoveRight?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleDragAndDropMove: (fromIndex: number, toIndex: number, forceMove?: boolean) => boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  type: "accommodation" | "activity" | "transportation";
  getEvent: UseFormGetValues<any>;
  first: boolean;
  icon: any;
  hideButtonGroup?: boolean;
  currentTripdayIndex: number;
  realtime?: boolean;
  canMove?: boolean;
  canDuplicate?: boolean;
  showLeftMove?: boolean;
  showRightMove?: boolean;
  hideLastTripday?: boolean;
  i: number;
  isLast: boolean;
  setToIndex: (index: number) => void;
  setFromIndex: (index: number) => void;
  onOpenWarning: () => void;
}

const GeneralElementForm: React.FC<EventFormProps> = ({
  handleDelete,
  handleDuplicate,
  handleTripdayMove,
  handleMoveLeft,
  handleMoveRight,
  handleDragAndDropMove,
  dragHandleProps,
  type,
  getEvent,
  children,
  first,
  icon,
  hideButtonGroup = false,
  currentTripdayIndex,
  realtime = false,
  canMove = true,
  canDuplicate = true,
  showLeftMove = false,
  showRightMove = false,
  hideLastTripday = false,
  i,
  isLast,
  setToIndex,
  setFromIndex,
  onOpenWarning,
  ...props
}) => {
  return (
    <div className="group relative mb-3 p-3 rounded-md border border-solid border-gray-200 bg-white" {...props}>
      {!hideButtonGroup && (
        <ButtonGroup
          pos="absolute"
          right={2}
          top={2}
          spacing={0}
          display={first ? "flex" : "none"}
          gap={1}
          bgColor={"transparent"}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {canMove && (
            <>
              {i !== 0 && (
                <IconButton
                  className="opacity-0 transition-all group-hover:opacity-100 z-50"
                  bgColor="transparent"
                  aria-label="Move element up"
                  icon={<ChevronUpIcon boxSize={4} />}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    if (!handleDragAndDropMove(i, i - 1)) {
                      setToIndex(i - 1);
                      setFromIndex(i);
                      onOpenWarning();
                    }
                  }}
                />
              )}
              <IconButton
                className="opacity-0 transition-all group-hover:opacity-100 z-50"
                bgColor="transparent"
                aria-label="Drag element"
                icon={<DragHandleIcon boxSize={2.5} />}
                onMouseDown={(e) => e.stopPropagation()}
                {...dragHandleProps}
              />
              {!isLast && (
                <IconButton
                  className="opacity-0 transition-all group-hover:opacity-100 z-50"
                  bgColor="transparent"
                  aria-label="Move element down"
                  icon={<ChevronDownIcon boxSize={4} />}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    if (!handleDragAndDropMove(i, i + 1)) {
                      setToIndex(i + 1);
                      setFromIndex(i);
                      onOpenWarning();
                    }
                  }}
                />
              )}
            </>
          )}
          <EventOptions
            className="opacity-0 transition-all group-hover:opacity-100 z-50"
            handleDelete={handleDelete}
            handleDuplicate={handleDuplicate}
            handleMove={handleTripdayMove}
            handleMoveLeft={handleMoveLeft}
            handleMoveRight={handleMoveRight}
            hideMoveTripday={realtime}
            type={type}
            getEvent={getEvent}
            currentTripdayIndex={currentTripdayIndex}
            showLeftMove={showLeftMove}
            showRightMove={showRightMove}
            showDuplicate={canDuplicate}
            hideLastTripday={hideLastTripday}
          />
        </ButtonGroup>
      )}

      {children}
    </div>
  );
};
export default GeneralElementForm;
