const baseStyle = {
  transition: `all 0.15s ease-out`,
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "inherit",
  _focus: {
    boxShadow: "outline",
  },
};

const variants = {
  normal: {},
  unstyled: {
    _hover: {
      textDecoration: "none",
    },
  },
};

const defaultProps = {
  variant: "normal",
};

export default {
  baseStyle,
  variants,
  defaultProps,
};
// export default {
//   baseStyle,
//   // variants,
//   // defaultProps,
// };
