import { CalendarIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { DayPicker } from "react-day-picker";
import useRangeInput from "./CustomUseRangeInput";
import { type UseRangeInputOptions } from "../../../types/UseRangeInputOptions";
// Import react-day-picker styles
import "react-day-picker/dist/style.css";
// Custom react-day-picker styles
import * as classNames from "../../../styles/daypicker.module.css";

interface DateRangePickerProps extends UseRangeInputOptions {
  beforeClose?: () => void;
  borderRadius?: string;
  modalContext?: any;
}
const DateRangePicker: React.FC<DateRangePickerProps> = ({
  beforeClose,
  borderRadius = "none",
  modalContext,
  ...options
}) => {
  const startDateRef = React.useRef<any>();

  const { onOpen, onClose, isOpen } = useDisclosure();
  // React.useEffect(() => {
  //   // Used for the react-query caching, such that the daterange is updated once the new invalidated trip values were fetched.
  //   setselected(defaultSelected);
  //   setInputValue(dateRangeToInputValue(defaultSelected, format, locale));
  // }, [defaultSelected]);

  const handleOnClose = () => {
    // Call a function before closing the popover.
    if (beforeClose) beforeClose();
    // The real onClose function of the popover disclosure.
    onClose();
  };

  const { dayPickerProps, fieldProps } = useRangeInput(options);

  return (
    <Popover
      // TODO: uncomment the line beneath such that you can manually change the dates.
      // BUGS:
      // 1. You can't close the popover anymore by clicking outside of it because the focus is gone.
      // 2. Updating the dates don't take into account the 'min' and 'max' values of the useRangeInput.
      // 3. Updating the end date such that it is smaller than the start date switches them and is weird.
      //    -> instead it should become the previous value again. Thus the end date must become null.
      // initialFocusRef={startDateRef}
      placement="bottom-start"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleOnClose}
    >
      <PopoverAnchor>
        <InputGroup onClick={() => onOpen()}>
          <Input
            {...fieldProps}
            borderRadius={borderRadius}
            // autoComplete="nope"
            ref={startDateRef}
            // onBlur={onBlurDateInput}
            // placeholder={format(new Date(), dateOptions.format!)}
          />
          <InputRightElement cursor="pointer">
            <IconButton
              icon={<CalendarIcon />}
              // Custom button variant
              variant="basic"
              aria-label="daterange-calendar-icon"
            />
          </InputRightElement>
        </InputGroup>
      </PopoverAnchor>
      <Portal containerRef={modalContext?.dialogRef}>
        <PopoverContent w="100%">
          {/* <PopoverHeader fontWeight="semibold">Start Date</PopoverHeader> */}
          <PopoverArrow />
          {/* <PopoverCloseButton /> */}
          <PopoverBody h="350px">
            <DayPicker
              {...dayPickerProps}
              // @ts-ignore
              classNames={classNames}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
export default React.memo(DateRangePicker);
