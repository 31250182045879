import { Box, Flex, Text } from "@chakra-ui/react";
import Section from "../../components/layout/Section";
import React from "react";
import useCreateNewTrip from "../../utils/useCreateNewTrip";

interface CreateFirstTripCardProps {}

export const CreateFirstTripCard: React.FC<CreateFirstTripCardProps> = () => {
  const image = {
    url: "https://video.pexels.com/video-files/4183292/4183292-hd_1920_1080_30fps.mp4",
  };

  const { handleCreateTrip } = useCreateNewTrip(false);

  return (
    <Box height={"16.4em"} width={"29.2em"} className="group hover:cursor-pointer" onClick={handleCreateTrip}>
      <Section
        h={"100%"}
        w={"100%"}
        noDivider
        noHorizontalPadding
        noVerticalPadding
        paddingTop={0}
        paddingBottom={0}
        overflow={"hidden"}
        borderRadius={"10px"}
        shadow="md"
        border="none"
      >
        <Box position="relative" height={"100%"} width={"100%"} overflow={"hidden"}>
          <Box overflow="hidden" height={"100%"}>
            <video key={image.url} autoPlay muted loop height={"100%"}>
              <source src={image.url} type="video/mp4" />
            </video>
          </Box>
          <Box color={"white"} width={"100%"} position={"absolute"} bottom={0} left={0} p={6} fontSize={"lg"}>
            <Flex display={"lg"}>
              <Text my={1} textAlign={"start"} fontWeight={700} fontSize={"lg"} ml={2} isTruncated>
                Create your first trip!
              </Text>
            </Flex>

            <Text ml={2} fontSize={"sm"} color={"white"} zIndex={10} width="95%" fontWeight={600} isTruncated>
              Click here to get started.
            </Text>
          </Box>
        </Box>
      </Section>
    </Box>
  );
};
