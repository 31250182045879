import { POIType } from "@lato/common";
import React from "react";
import { FilterProps } from "react-table";
import MultiSelectColumnFilter from "./MultiSelectColumnFilter";

interface TypeFilterProps extends React.PropsWithChildren<FilterProps<any>> {
  types: POIType[];
  setTypeFilter: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

// Allows POIs to be filtered by poi type
const TypeFilter: React.FC<TypeFilterProps> = ({ types, setTypeFilter, ...props }) => {
const displayTypeOption = React.useCallback(
    (type: string): React.ReactElement => {
      return <span>{type}</span>;
    },
    [],
  );

  const createTypeOptions = React.useCallback(
    (preFilteredRows: any, columnName: string) => {
      if (!types) return new Set<string>();
      return new Set(types.map((t) => t));
    },
    [types],
  );

  return (
    <>
      {types && types.length > 0 && (
        <MultiSelectColumnFilter
          {...props}
          displayName={"type"}
          createOptions={createTypeOptions}
          displayOption={displayTypeOption}
          useSelectedItems={true}
          onChange={(e) => {
            if (e.length === types.length || e.length === 0) {
              setTypeFilter([]);
              props.columns.find((column) => column.id === "poiTypes" || column.id === "type")?.setFilter([]);
            } else {
              setTypeFilter(e);
            }
          }}
        />
      )}
    </>
  );
};
export default React.memo(TypeFilter);