import React from "react";
import { Marker, Popup, useMap, type MarkerProps } from "react-leaflet";
import { useAsyncDebounce } from "react-table";

export interface CustomLeafletMarkerProps extends Omit<MarkerProps, "position"> {
  coordinates: number[] | null;
  icon: any;
  children?: React.ReactNode;
  onClick?: () => void;
  openPopup?: boolean;
}

const CustomLeafletMarker: React.FC<CustomLeafletMarkerProps> = ({
  coordinates,
  icon,
  children,
  onClick,
  openPopup,
  ...props
}) => {
  const markerRef = React.useRef(null);
  const openPopupDeb = useAsyncDebounce(() => {
    if (markerRef && markerRef.current) (markerRef as any).current?.openPopup();
  }, 100);
  React.useEffect(() => {
    if (openPopup && markerRef.current) {
      openPopupDeb();
    }
  }, [openPopup, markerRef, coordinates]);
  return (
    <Marker
      {...props}
      position={{
        lng: (coordinates && coordinates[0]) ?? 0,
        lat: (coordinates && coordinates[1]) ?? 0,
      }}
      icon={icon}
      eventHandlers={{
        click: async () => onClick && await (onClick as any)(),
      }}
      ref={markerRef}
    >
      {children && openPopup ? (
        <Popup className="leaflet-popup" keepInView>
          {children}
        </Popup>
      ) : children ? (
        children
      ) : null}
    </Marker>
  );
};

export default React.memo(CustomLeafletMarker);
