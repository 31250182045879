import React from "react";
import { useMeContext } from "../../stores/me-context";
import { Box, Heading, Text, Flex } from "@chakra-ui/react";

interface GreetingProps {}

export const Greeting: React.FC<GreetingProps> = () => {
  const user = useMeContext();
  const [hour, setHour] = React.useState<number>();

  const getHour = () => {
    const date = new Date();
    setHour(date.getHours());
  };

  const username = user?.name ? ", " + user.name : "";

  React.useEffect(() => {
    getHour();
  }, []);

  const defaultBrand = user?.company.brands.find((br) => br.default_brand);

  return (
    <Box alignItems="center" justifyContent="start">
      <Heading size="lg" color="#004354">
        {hour! < 12 && hour! > 4
          ? `Good morning${username}`
          : hour! < 18
            ? `Good afternoon${username}`
            : `Good evening${username}`}
      </Heading>
      {/* {defaultBrand?.logoUrl && <Image src={defaultBrand.logoUrl} height={`calc(1.8em*${defaultBrand.logoScale})`} />} */}
    </Box>
  );
};
