import { Button, useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthAPI from "../../api/auth.api";
import { CardWrapper } from "../../components/CardWrapper";
import { handleSubmission } from "../../utils/toErrorMap";
import SplitScreenLayout from "../../components/layout/SplitScreenLayout";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { passwordValidationSchema } from "../../validation/validationSchemas";
import Form from "../../components/form/Form";
import RHFInputPassword from "../../components/input/RHFInputPassword";
import { useChangePasswordTokenStatus } from "../../utils/query-helpers/reactQueryHooks";
import ChangePasswordIllustration from "../../assets/icons/ChangePasswordIllustration";
import WarningIllustration from "../../assets/icons/Warning.Illustration";
import FullScreenSpinner from "../../components/FullScreenSpinner";

export const ChangePassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const toast = useToast();
  const formMethods = useForm<{ password: string }>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      password: "",
    },
    resolver: yupResolver(passwordValidationSchema),
  });

  const successCallback = React.useCallback(() => {
    navigate("/login", { replace: true });
  }, [navigate]);

  const handleSubmit = async ({ password }: { password: string }) => {
    await handleSubmission({
      successMessage: "changed password",
      failMessage: "changing password",
      apiCall: AuthAPI.changePasswordToken({
        password,
        token,
      }),
      toast,
      successCallback,
      setError: formMethods.setError,
    });
  };

  const { isLoading: isLoadingTokenStatus, error: tokenError } = useChangePasswordTokenStatus(token!);

  if (isLoadingTokenStatus) return <FullScreenSpinner />;

  return (
    <SplitScreenLayout>
      {tokenError ? (
        <CardWrapper
          title="Invalid reset link"
          description="Could not load password reset link. The link may be expired or
    already used."
          topIllustration={WarningIllustration}
        ></CardWrapper>
      ) : (
        <CardWrapper title="Reset your password" topIllustration={ChangePasswordIllustration}>
          <Form formMethods={formMethods} onSubmit={handleSubmit}>
            <RHFInputPassword name="password" label="New password" />
            <Button
              w="100%"
              size="md"
              mt={4}
              type="submit"
              isLoading={formMethods.formState.isSubmitting}
              isDisabled={!formMethods.formState.isValid}
              colorScheme="brand"
            >
              Change password
            </Button>
          </Form>
        </CardWrapper>
      )}
    </SplitScreenLayout>
  );
};

export default ChangePassword;
