import useEmblaCarousel, { type EmblaOptionsType } from "embla-carousel-react";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import React from "react";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import { canSwitch, submitForms } from "../../utils/EventHelper";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import "./embla.css";

interface EmblaSwiperProps {
  slides?: React.ReactNode[];
  slidesBefore?: React.ReactNode[];
  slidesAfter?: React.ReactNode[];
  slideBetween?: React.ReactNode;
  selectedAccommodationIndex?: number;
  setSelectedAccommodationIndex?: (index: number) => void;
  options?: EmblaOptionsType;
  gap?: number;
  width?: "dynamic" | "full";
  mouseWheel?: boolean;
  renderDotNavigation?: (index: number, selected: boolean) => React.ReactNode;
}

const EmblaSwiper: React.FC<EmblaSwiperProps> = ({
  slides,
  slidesBefore,
  slidesAfter,
  slideBetween,
  options,
  gap = 20,
  renderDotNavigation,
  width = "dynamic",
  mouseWheel = false,
  selectedAccommodationIndex,
  setSelectedAccommodationIndex,
}) => {
  // @ts-ignore
  const { isSubmittingForms, isInvalidForms, isErrorForms, isDirtyForms } = useTripFormStore();
  const [emblaRef, emblaApi] = useEmblaCarousel(options, mouseWheel ? [WheelGesturesPlugin()] : []);
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  React.useEffect(() => {
    emblaApi && selectedAccommodationIndex && emblaApi.scrollTo(selectedAccommodationIndex);
    emblaApi && !selectedAccommodationIndex && emblaApi.scrollTo(options?.startIndex === 0 ? options.startIndex : 0);
  }, [slides?.length, scrollSnaps]);

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className={`embla__container`} style={{ marginLeft: `-${gap}px` }}>
          {slides &&
            slides.map((slide, i) => (
              <div
                key={`slide-${i}`}
                className={`embla__slide`}
                style={{ paddingLeft: `${gap}px`, flex: `0 0 ${width === "dynamic" ? "auto" : "100%"}` }}
              >
                {slide}
              </div>
            ))}
          {!slides && slidesBefore && slidesAfter && slideBetween && (
            <>
              {slidesBefore.map((slide, i) => (
                <div
                  key={`slide-${i}`}
                  className={`embla__slide`}
                  style={{ paddingLeft: `${gap}px`, flex: `0 0 ${width === "dynamic" ? "auto" : "100%"}` }}
                >
                  {slide}
                </div>
              ))}
              {slideBetween}
              {slidesAfter.map((slide, i) => (
                <div
                  key={`slide-${i}`}
                  className={`embla__slide`}
                  style={{ paddingLeft: `${gap}px`, flex: `0 0 ${width === "dynamic" ? "auto" : "100%"}` }}
                >
                  {slide}
                </div>
              ))}
            </>
          )}
        </div>
        {renderDotNavigation && (
          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={async () => {
                  if (setSelectedAccommodationIndex) {
                    setSelectedAccommodationIndex(index);
                    if (selectedIndex !== index && canSwitch(isErrorForms, isInvalidForms)) {
                      if ([...isDirtyForms.values()].some((value) => value === true)) {
                        await submitForms(isSubmittingForms);
                      }
                      if (canSwitch(isErrorForms, isInvalidForms)) {
                        onDotButtonClick(index);
                      }
                    }
                  } else {
                    onDotButtonClick(index);
                  }
                }}
                // className={"embla__dot".concat(index === selectedIndex ? " embla__dot--selected" : "")}
              >
                {renderDotNavigation(index, index === selectedIndex)}
              </DotButton>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(EmblaSwiper);
