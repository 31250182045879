import { Flex, Spinner, Text } from "@chakra-ui/react";
import { TLibraryImage } from "@lato/common";
import { type UseInfiniteQueryResult } from "@tanstack/react-query";
import React from "react";
import Masonry from "react-masonry-css";
import LibraryCard from "./LibraryCard";
import "./masonry.css";

interface ExternalImageGridProps {
  infiniteQueryResult: UseInfiniteQueryResult<TLibraryImage, Error>;
  handleChosenPicture: (photo: TLibraryImage) => void;
  selectedPictures: TLibraryImage[];
}

const breakPoints = {
  default: 3,
  600: 2,
  300: 1,
};

// Masonry grid style like Pinterest and Unsplash (libraries such as masonry, colcade, ...)
const LibraryImageGrid: React.FC<ExternalImageGridProps> = ({
  infiniteQueryResult,
  handleChosenPicture,
  selectedPictures,
}) => {
  const { data: paginatedResult, isLoading: isLoadingPhotos, error: errorPhotos } = infiniteQueryResult;

  if (isLoadingPhotos)
    return (
      <Flex w="100%" h="100%" align="center" justifyContent="center" mt={5}>
        <Spinner color="brand.500" />
      </Flex>
    );
  if (errorPhotos || !paginatedResult) return <div>Error loading photos...</div>;

  let libraryPhotos;
  if (paginatedResult) {
    libraryPhotos = paginatedResult.pages.flatMap((page) => page.data);
  }

  if (!libraryPhotos || libraryPhotos.length === 0 || !libraryPhotos[0])
    return (
      <Text textAlign="center" my={5}>
        No search results found.
      </Text>
    );

  return (
    <>
      <Masonry breakpointCols={breakPoints} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
        {libraryPhotos[0] &&
          libraryPhotos.map((photo: TLibraryImage, photoIndex: number) => (
            <div key={`photo-${photoIndex}`}>
              {/* div needed for the react-masonry-css plugin to work. */}
              <LibraryCard
                key={`library-picture-${photoIndex}`}
                Photo={photo}
                handleChosenPicture={handleChosenPicture}
                selected={selectedPictures.some((selectedPic) => selectedPic.id === photo.id)}
              />
            </div>
          ))}
      </Masonry>
    </>
  );
};
export default React.memo(LibraryImageGrid);
