import { Box, Flex, Heading, Stepper, Text } from "@chakra-ui/react";
import { calculateTripdayDate, padZero } from "@lato/common";
import React, { useMemo } from "react";
import MoonIcon from "../../../../assets/icons/MoonIcon";
import { calculateDayNumber } from "../../../../features/editTrip/overviewStep/TripsOverviewTable";
import { useTripLanguage } from "../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { useWindowWidth } from "../../../../utils/useWindowWidth";
import { DayScrollerVerticalCardProps } from "./DayScrollerVertical";
import { DayScrollerStep } from "./DaySrollerStep";
import TripdayPreviewTitle, {
  buildTripdayTitle,
} from "../../../../features/editTrip/previewStep/tripday-preview/TripdayPreviewTitle";

export const DayScrollerVerticalCard: React.FC<DayScrollerVerticalCardProps> = ({
  i,
  tripdays,
  tripday,
  trip,
  isActive,
  isInvalid,
  setActiveDay,
  // executeIfClicked,
  start_date,
  disabled,
}) => {
  const { unCollapsedElement, setUnCollapsedElement } = useTripFormStore();
  const realDayNumber = calculateDayNumber(i, tripdays);
  const nextRealDayNumber = calculateDayNumber(i + 1, tripdays);
  const td_start_date = start_date && calculateTripdayDate(realDayNumber, start_date);
  const tripday_start_date = td_start_date && td_start_date.substring(0, 11);
  const { realLanguageIndex } = useTripLanguage();
  // const tripday_end_date = calculateTripdayDate(
  //   nextRealDayNumber - 1,
  //   start_date
  // ).substring(0, 11);
  const handleChooseDay = React.useCallback(
    (_: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setActiveDay(i);
    },
    [setActiveDay, i],
  );

  const cardWidth = "full";
  const windowSize = useWindowWidth();

  const elements = [...(tripday.events ?? []), ...(tripday.transportations ?? []), ...(tripday.hotels ?? [])].sort(
    (a: any, b: any) => a.ord - b.ord,
  );

  const selectedIndex = elements.findIndex((e) => e.id === unCollapsedElement);
  const hotelSelected =
    selectedIndex != -1 && !elements[selectedIndex]?.type && !elements[selectedIndex]?.hasOwnProperty("endTime");
  const amountOfHotels = tripday.hotels?.length ?? 0;

  const onElementClick = (elementId: string) => {
    // Scroll to the selected element and collapse
    const element = document.getElementById(elementId);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });

    setUnCollapsedElement(elementId);
  };

  const tripdayTitle = buildTripdayTitle(trip, tripday, realLanguageIndex);

  const previousLocationName = i > 0 ? tripdays[i - 1].location?.name : undefined;
  const currentLocationName = tripdays[i].location?.name;
  const titleEqualsMergedLocations =
    !!previousLocationName && !!currentLocationName
      ? tripdayTitle === previousLocationName + " - " + currentLocationName
      : false;

  return (
    // Disables the auto closing / collapsing of the elements when clicking on a day button or child element button
    <Box onMouseDown={(e) => e.stopPropagation()}>
      <Box
        borderRadius="base"
        overflow={"hidden"}
        //shadow="md"
        w="full"
        minH={"40px"}
        minW={"12rem"}
        maxW={cardWidth}
        color={isActive ? (isInvalid ? "red.500" : "brand.500") : "realGray.600"}
        borderColor={isActive ? (isInvalid ? "red.500" : "brand.500") : "brand.50"}
        bg={"white"}
        borderWidth={isActive ? "1px" : "1px"}
        style={{
          scrollSnapAlign: "center",
          opacity: disabled && !isActive ? 0.5 : 1,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        _hover={{
          cursor: "pointer",
        }}
        transition="all 0.2s ease-in-out"
        onClick={(e) => {
          if (!disabled) handleChooseDay(e);
        }}
      >
        <Flex>
          <Box
            flexBasis={"30px"}
            flexGrow={0}
            flexShrink={0}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            background={isInvalid ? "red.50" : "brand.50"}
            color={isInvalid ? "red.500" : "brand.500"}
          >
            <Heading size="md" whiteSpace="nowrap">
              {padZero(realDayNumber + 1)}
              {/* {nextRealDayNumber > realDayNumber + 1 && ` - ${padZero(nextRealDayNumber)}`} */}
            </Heading>
            {/* <Text fontSize={"xs"}>DAY</Text> */}
          </Box>

          <Box
            justifyContent="space-between"
            gap="1rem"
            alignItems="center"
            py={1}
            px={2}
            w="100%"
            flex={1}
            overflow={"hidden"}
          >
            {titleEqualsMergedLocations ? (
              <div className="flex items-center">
                <Heading size="sm" isTruncated className="max-w-[50%]">
                  {previousLocationName}
                </Heading>
                <Heading size="sm" mx={1}>
                  {"→"}
                </Heading>
                <Heading size="sm" isTruncated>
                  {currentLocationName}
                </Heading>
              </div>
            ) : (
              <Heading size="sm" isTruncated height={4}>
                <TripdayPreviewTitle tripdayId={tripday.id!} trip={trip} />
              </Heading>
            )}

            <Flex justifyContent={"space-between"} w="100%" alignItems={"center"}>
              <Text fontSize="xs" whiteSpace={"nowrap"}>
                {tripday_start_date}
                {/* {nextRealDayNumber > realDayNumber + 1 &&
    ` - ${tripday_end_date}`}{" "} */}
              </Text>
              <Flex alignItems={"center"}>
                <MoonIcon className="h-[10px] w-[10px] mr-[1px]" />
                <Text fontSize="xs" whiteSpace={"nowrap"}>
                  {tripday.nrOfNights}
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
      {isActive && elements.length > 0 && windowSize > 992 && (
        <Stepper index={selectedIndex} orientation="vertical" gap={1} mx={2} mt={2} cursor={"pointer"}>
          {elements.map((element, index) => (
            <DayScrollerStep
              key={element.id}
              index={index}
              selectedIndex={selectedIndex}
              elements={elements}
              element={element}
              onElementClick={onElementClick}
              realLanguageIndex={realLanguageIndex}
              hotelSelected={hotelSelected}
              amountOfHotels={amountOfHotels}
            />
          ))}
        </Stepper>
      )}
    </Box>
  );
};
