import { Box, Button, Flex, FormControl, FormLabel, Heading } from "@chakra-ui/react";
import { TRANSPORTATION_TYPE, Transportation, durationInDays } from "@lato/common";
import React from "react";
import { useForm } from "react-hook-form";
import AirportsAPI from "../../api/airports.api";
import { useMeContext } from "../../stores/me-context";
import { AIRPORTS_QUERY_KEY } from "../../utils/constants";
import CustomDownShiftWithPagination from "../../components/CustomDownShift/CustomDownShiftWithPagination";
import RHFDayPicker from "../../components/input/date/RHFDayPicker";
import { getEmptyTransportation } from "../../components/elements/activities";
import Form from "../../components/form/Form";
import RHFInput from "../../components/input/RHFInput";
import RHFTimeMaskInput from "../../components/input/RHFTimeMaskInput";

interface ManualFlightCreationProps {
  modalDisclosure: any;
  addFlight: (flight: Transportation) => void;
  startDate?: Date;
  endDate?: Date;
}

type SingleAirportItemProps = {
  item: any;
};

export const SingleAirport: React.FC<SingleAirportItemProps> = ({ item }) => {
  return (
    <Box>
      <Flex>
        <Heading fontSize={"10px"}>
          {item.iata == undefined || item.iata == "---" || item.iata == "" ? item.icao : item.iata} {item.city}{" "}
          {item.country}
        </Heading>
      </Flex>
    </Box>
  );
};

const ManualFlightCreation: React.FC<ManualFlightCreationProps> = ({
  modalDisclosure,
  addFlight,
  startDate,
  endDate,
}) => {
  const [fromIata, setFromIata] = React.useState<string>("");
  const [toIata, setToIata] = React.useState<string>("");

  const me = useMeContext();

  const newFlight: Transportation = {
    ...getEmptyTransportation(me),
    type: TRANSPORTATION_TYPE.FLIGHT,
  };

  const handleManualSubmit = (flight: Transportation) => {
    console.log(flight);
    if (manualFormMethods.formState.errors) {
      addFlight({
        ...newFlight,
        realtime: false,
        transportNumber: flight.transportNumber,
        departureDayIndex: 0,
        arrivalDayIndex:
          (flight.departureDate &&
            flight.arrivalDate &&
            durationInDays(flight.departureDate.toString(), flight.arrivalDate?.toString())) ??
          null,
        departureAirportIata: fromIata,
        arrivalAirportIata: toIata,
      });
      modalDisclosure.onClose();
    }
  };

  const manualFormMethods = useForm<Transportation>();
  console.log(startDate);
  console.log(endDate);

  return (
    <Box mx={10}>
      <Form formMethods={manualFormMethods} onSubmit={handleManualSubmit}>
        <Flex flexDirection={"column"} mt={3}>
          <Box ml={2}>
            <RHFInput
              name="transportNumber"
              label="FLIGHT NO"
              placeholder="IB532"
              width={"50%"}
              inputWidth={"60%"}
              gap={1}
            />
          </Box>
          <Flex flexDirection={"row"} justifyContent={"space-around"}>
            <FormControl width="45%">
              <FormLabel>FROM</FormLabel>
              <Box>
                <CustomDownShiftWithPagination
                  apiCall={(queryParams?: string) => AirportsAPI.getAirports(queryParams)}
                  queryName={AIRPORTS_QUERY_KEY}
                  listItemConfig={{
                    ListItem: SingleAirport,
                    showSelected: ({ item }) => (item ? `${item.iata} ${item.city}` : ""),
                    onSelected: ({ item }) => {
                      setFromIata(item.iata);
                    },
                  }}
                  additionalQueryState={{
                    step: 5,
                    page: 1,
                  }}
                />
              </Box>
            </FormControl>
            <FormControl width="45%">
              <FormLabel>TO</FormLabel>
              <Box>
                <CustomDownShiftWithPagination
                  apiCall={(queryParams?: string) => AirportsAPI.getAirports(queryParams)}
                  queryName={AIRPORTS_QUERY_KEY}
                  listItemConfig={{
                    ListItem: SingleAirport,
                    showSelected: ({ item }) => (item ? `${item.iata} ${item.city}` : ""),
                    onSelected: ({ item }) => {
                      setToIata(item.iata);
                    },
                  }}
                  additionalQueryState={{
                    step: 5,
                    page: 1,
                  }}
                />
              </Box>
            </FormControl>
          </Flex>
          <Flex flexDirection={"row"} gap={2} justifyContent={"space-around"}>
            <RHFDayPicker
              name="departure_date_time"
              label="DEPARTURE DATE"
              w={"45%"}
              inputWidth={"95%"}
              defaultDate={startDate}
              fromDate={startDate}
              toDate={endDate}
            />
            <RHFTimeMaskInput
              name="departure_date_time"
              label="TAKEOFF"
              w={"45%"}
              inputWidth={"50%"}
              defaultDate={startDate}
            ></RHFTimeMaskInput>
          </Flex>
          <Flex flexDirection={"row"} gap={2} justifyContent={"space-around"}>
            <RHFDayPicker
              name="arrival_date_time"
              label="ARRIVAL DATE"
              w={"45%"}
              inputWidth={"95%"}
              fromDate={startDate}
              toDate={endDate}
              defaultDate={startDate}
            />
            <RHFTimeMaskInput
              name="arrival_date_time"
              label="LANDING"
              w={"45%"}
              inputWidth={"50%"}
              defaultDate={startDate}
            ></RHFTimeMaskInput>
          </Flex>
        </Flex>

        <Flex mt={3} justifyContent={"space-around"}>
          <Button
            w="30%"
            mt={4}
            onClick={() => handleManualSubmit(manualFormMethods.getValues())}
            isLoading={manualFormMethods.formState.isSubmitting}
            isDisabled={!manualFormMethods.formState.isValid}
            colorScheme="brand"
          >
            Add Flight
          </Button>
        </Flex>
      </Form>
    </Box>
  );
};

export default ManualFlightCreation;
