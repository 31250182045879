import TripPreviewStep from "../../../components/trips/edit/daybyday/TripPreviewStep";
import SampleStepWrapper from "../SampleStepWrapper";

export const DayByDaySampleRoute: React.FC = () => {
  const stepNumber = 2;

  return (
    <SampleStepWrapper>
      <TripPreviewStep stepNumber={stepNumber} />
    </SampleStepWrapper>
  );
};

export default DayByDaySampleRoute;
