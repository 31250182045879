import { GoogleAddress } from "@lato/common";
import { UseQueryOptions } from "@tanstack/react-query";
import React from "react";
import { BsPinAngleFill } from "react-icons/bs";
import { useMeContext } from "../../stores/me-context";
import { useParamsQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import { PaginationReturn } from "../../utils/query-helpers/usePaginatedQuery";
import FullScreenSpinner from "../FullScreenSpinner";
import ErrorCardView from "../layout/ErrorCardView";
import { MarkerMapItem, MarkerMapItemType, MarkerMapsAPI } from "../trips/edit/daybyday/library-items/LibraryItemModal";
import { IconMarker } from "./IconMarker";
import MarkerMaps from "./MarkerMaps";
import MarkerMapsAutocomplete from "../../features/map/MarkerMapsAutoComplete";

interface LibraryItemMapsProps {
  showAllMarkers?: boolean;
  markerComponent?: (item: MarkerMapItem, index: number, onClick: any) => React.ReactNode;
  getEmptyItem: (coordinates?: number[], address?: GoogleAddress, locationName?: string) => MarkerMapItem;
  type: MarkerMapItemType;
  query_key: string;
  filterOptions?: any;
  api: MarkerMapsAPI;
  handleAdd: any;
  handleEdit: any;
  focusCoordinates?: number[];
  setTotalCount?: any;
}

export function useAPI<T extends MarkerMapItem>(
  queryParams: any,
  query_key: string,
  api: MarkerMapsAPI,
  options?: UseQueryOptions<any, any, any>,
) {
  return useParamsQueryHelper<PaginationReturn<T>>({
    queryKey: query_key,
    apiCall: api.getAll,
    queryParams,
    options,
  });
}

const zoomLevel = 12;

const LibraryItemMaps: React.FC<LibraryItemMapsProps> = ({
  showAllMarkers,
  markerComponent,
  getEmptyItem,
  type,
  query_key,
  filterOptions = {},
  api,
  handleAdd,
  handleEdit,
  focusCoordinates,
  setTotalCount,
}) => {
  const me = useMeContext();

  const [mapRef] = React.useState<google.maps.Map | null>(null);
  const { data, isLoading, error: errorItem } = useAPI(filterOptions, query_key, api);
  const items = data?.data.filter((item: MarkerMapItem) => item.location !== null);
  setTotalCount(items?.length);
  const coordinates = items?.map((item: MarkerMapItem) => {
    return {
      lat: item.location!.coordinates![1],
      lng: item.location!.coordinates![0],
    };
  });

  if (isLoading) return <FullScreenSpinner />;
  if (errorItem || !data) return <ErrorCardView title={`Could not load ${type}'s`} />;

  const handleMarkerClick = (libraryItem: MarkerMapItem) => {
    //const itemDetail: MarkerMapItem = await api.getSingle(libraryItem.id!);
    handleEdit(libraryItem.id!);
  };

  const changeNewItem = (coordinates: number[], panTo = true, zoom = true) => {
    //setNewItem(newItem);
    if (panTo && coordinates) {
      mapRef?.panTo({
        lat: coordinates[1],
        lng: coordinates[0],
      });
    }
    if (zoom) {
      mapRef?.setZoom(zoomLevel);
    }
  };

  const handleSearch = (coordinates: number[], address?: GoogleAddress, locationName?: string) => {
    changeNewItem(coordinates);
    const item = getEmptyItem(coordinates, address, locationName);
    handleAdd(item);
  };

  const onMapClicked = (coordinates: number[]) => {
    changeNewItem(coordinates);
    const item = getEmptyItem(coordinates);
    handleAdd(item);
  };

  return (
    <>
      {showAllMarkers && <MarkerMapsAutocomplete changeItem={handleSearch} formname={type} />}
      <MarkerMaps
        allMarkerPositions={coordinates}
        onMapClicked={onMapClicked}
        mapType={me.company.map_provider}
        focusCoordinates={focusCoordinates}
      >
        {items?.map((item, i) =>
          markerComponent ? (
            markerComponent(item, i, handleMarkerClick)
          ) : (
            <IconMarker
              key={`poi-marker-${i}`}
              coordinates={item.location?.coordinates ?? [0, 0]}
              layout={{
                icon: BsPinAngleFill,
                bg: "rgb(94, 56, 110)",
              }}
              onClick={() => handleMarkerClick(item)}
            />
          ),
        )}
      </MarkerMaps>
    </>
  );
};
export default LibraryItemMaps;
