export function isDifferentDay(date1: Date, date2: Date) {
  return (
    date1.getDate() !== date2.getDate() ||
    date1.getMonth() !== date2.getMonth() ||
    date1.getFullYear() !== date2.getFullYear()
  );
}

export const prettyPrintDate = (
  date: Date,
  language_code?: string,
  dateMode?: "short" | "medium" | "long" | "full",
  time = false,
  options?: Intl.DateTimeFormatOptions,
  showYear: boolean = true,
): string => {
  // Use the selected language. 'default' uses the browser language but this will never be used as the selected language is always defined
  const langCode = language_code || "default";
  date = new Date(date);
  let dateOptions: null | Intl.DateTimeFormatOptions = null;
  let timeOptions: null | Intl.DateTimeFormatOptions = null;
  // Assign the time options if we need the time
  timeOptions = time
    ? {
        // Don't use timeStyle yet because it had errors: Can't set option weekday when timeStyle is used
        // timeStyle: "short",
        // OR
        hour: "numeric",
        minute: "numeric",
      }
    : null;
  // Assign the correct date options if we need the date
  if (dateMode === "short") {
    dateOptions = {
      day: "numeric",
      month: "short",
    };
  } else if (dateMode === "medium") {
    dateOptions = {
      weekday: "short",
      day: "numeric",
      month: "short",
    };
  } else if (dateMode === "long") {
    dateOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
  } else if (dateMode === "full") {
    dateOptions = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: showYear ? "numeric" : undefined,
    };
  }
  if (dateOptions || timeOptions) {
    // If either a general date mode or time mode is given overwrite the given options argument.
    options = {
      ...dateOptions,
      ...timeOptions,
    };
  }
  // Fallback -> no mode given, use the given options
  return new Intl.DateTimeFormat(langCode, options).format(date);
};
export const prettyPrintDuration = (
  start: Date,
  end: Date,
  language_code: string,
  dateMode?: "short" | "medium" | "long" | "full",
): string => {
  return (
    prettyPrintDate(start, language_code, dateMode) +
    (start.getTime() !== end.getTime() ? " — " + prettyPrintDate(end, language_code, dateMode) : "")
  );
};

export function convertToUTCTimezone(date: Date | string): Date {
  const localDate = new Date(date);
  // ATTENTION: I am using the date (localDate) to calculate the timezone. This is due to winter hour and summer hour: https://stackoverflow.com/a/45908136
  const timeOffset = localDate.getTimezoneOffset() * 60 * 1000;
  const utcDate = new Date(localDate.getTime() + timeOffset);
  return utcDate;
}

export function calculateEndDate(startDate: string, numNights: number) {
  // Parse the start date and time
  const startDateObj = new Date(startDate);
  // Get the start timezone
  // const startTzObj = new Intl.DateTimeFormat(undefined, { timeZoneName: 'short' });
  // const startTz = startTzObj.formatToParts(startDateObj).find(part => part.type === 'timeZoneName')?.value;

  // Convert the start date to UTC
  startDateObj.setMinutes(startDateObj.getMinutes() + startDateObj.getTimezoneOffset());
  // Add number of nights
  startDateObj.setDate(startDateObj.getDate() + numNights);
  // Get the end timezone
  // const endTzObj = new Intl.DateTimeFormat(undefined, { timeZoneName: 'short' });
  // const endTz = endTzObj.formatToParts(startDateObj).find(part => part.type === 'timeZoneName')?.value;

  // Convert back to the end timezone
  startDateObj.setMinutes(startDateObj.getMinutes() - startDateObj.getTimezoneOffset());
  // Return the calculated end date and end timezone
  console.log("test", startDateObj);
  return startDateObj.toISOString().substring(0, 10);
}
