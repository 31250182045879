import React from "react";
import { FieldName, useController } from "react-hook-form";
import formatPrice from "../../utils/formatPrice";
import RHFInput from "./RHFInput";

interface RHFDecimalInputProps {
  name: FieldName<any>;
  formatCode: string;
  placeholder?: string;
  label?: string;
  onChange?: (e: string) => void;
  isDisabled?: boolean;
}

const RHFDecimalInput: React.FC<RHFDecimalInputProps> = ({
  name,
  placeholder,
  formatCode,
  label,
  isDisabled = false,
  onChange,
  ...props
}) => {
  const handleDeletion = (event: React.ChangeEvent<HTMLInputElement>) => {
    // cursor position
    const cursor_pos = event.target.selectionStart!;
    const text = event.target.value;

    if (event.key === "Backspace") {
      if (cursor_pos === 0 && event.target.selectionEnd === text.length) {
        event.preventDefault();
        event.target.value = null;
        event.target.setSelectionRange(0, 0);
        return;
      }
      if (cursor_pos > text.length - 3) {
        // Deleting decimals
        if (text.charAt(cursor_pos - 1) == "0") {
          // Trying to delete a zero after the decimal point, move cursor
          event.preventDefault();
          event.target.setSelectionRange(cursor_pos - 1, cursor_pos - 1);
        } else if (text.charAt(cursor_pos - 1) == ".") {
          // Cursor is after the decimal point, delete the number before the decimal point
          event.preventDefault();
          event.target.value = text.slice(0, cursor_pos - 2) + text.slice(cursor_pos - 1);
          event.target.setSelectionRange(cursor_pos - 2, cursor_pos - 2);

          if (event.target.value.match(/^[0 ]*.00/)) {
            // Empty, put 0.0
            event.target.value = null;
            event.target.setSelectionRange(0, 0);
          }
        } else {
          // Deleting a number behind the decimal point, replace it with a zero
          event.preventDefault();
          event.target.value = text.slice(0, cursor_pos - 1) + "0" + text.slice(cursor_pos);
          event.target.setSelectionRange(cursor_pos - 1, cursor_pos - 1);
        }
      } else {
        // Deleting numbers in front of decimal point
        if (text.charAt(cursor_pos) == "." && text.split(".")[0].length == 1) {
          // Deleting the only number before the decimal point, add zero and move cursor to front
          event.target.value = "0" + text.slice(cursor_pos);
          event.target.setSelectionRange(0, 0);
        } else if (text.charAt(cursor_pos - 1) == " " && cursor_pos > 1) {
          // Deleting a number in front of a space
          event.preventDefault();
          event.target.value = text.slice(0, cursor_pos - 2) + text.slice(cursor_pos - 1);
          event.target.setSelectionRange(cursor_pos - 2, cursor_pos - 2);
        }

        if (cursor_pos === 1 && event.target.value.match(/^[1-9][0 ]*/)) {
          event.preventDefault();
          event.target.value = event.target.value.replace(/^[0-9][0 ]*/g, "");
          event.target.setSelectionRange(0, 0);
        }
      }

      if (event.target.value == "0.00") {
        event.target.value = null;
      }
    } else if ((event.key === "." || event.key === ",") && text.charAt(cursor_pos) === ".") {
      // Typing a decimal in front of the decimal point moves the cursor behind the decimal point
      event.preventDefault();
      event.target.setSelectionRange(cursor_pos + 1, cursor_pos + 1);
    } else if (text.match(/^0\.[0-9]{2}$/) && isFinite(event.key) && cursor_pos === 0) {
      // Case where a digit is typed with the cursor before the zero in 0.12 for example
      // Input should replace the zero
      event.preventDefault();
      event.target.value = event.key + text.slice(cursor_pos + 1);
      event.target.setSelectionRange(cursor_pos + 1, cursor_pos + 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, blur: boolean) => {
    //onChange && onChange(event.target.value);

    const text = event.target.value;
    const original_length = text.length;

    // cursor position
    let cursor_pos = event.target.selectionStart!;

    if (text == "" || text == null) return;

    const new_text = formatPrice({ text, addDecimals: event.target.value.indexOf(".") === -1 || blur });

    // set value
    event.target.value = new_text;

    if (blur) return;

    if (text.length == 1) {
      event.target.setSelectionRange(1, 1);
    } else {
      // calculate position for cursor
      const new_length = new_text.length;
      cursor_pos =
        cursor_pos > text.length - 3
          ? new_length - original_length + cursor_pos + 1
          : new_length - original_length + cursor_pos;
      event.target.setSelectionRange(cursor_pos, cursor_pos);
    }
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.focus();
    setTimeout(function () {
      event.target.setSelectionRange(0, event.target.value.length);
    }, 0);
  };

  return (
    <RHFInput
      id="decimalInput"
      type="decimal"
      inputMode="numeric"
      isDisabled={isDisabled}
      onKeyDown={(e) => {
        handleDeletion;
        onChange && onChange(e.target?.value);
      }}
      onFocus={handleFocus}
      placeholder={placeholder?.toString()}
      onChange={(e) => {
        handleChange(e, false);
        onChange && onChange(e.target?.value);
      }}
      onBlur={(e) => handleChange(e, true)}
      label={label}
      name={name}
      {...props}
    ></RHFInput>
  );
};

export default React.memo(RHFDecimalInput);
