import { Tooltip } from "@chakra-ui/react";
import React from "react";

interface FeatureBadgeProps {
  className?: string;
}

interface BetaBadgeProps extends FeatureBadgeProps {}

export const BetaBadge: React.FC<BetaBadgeProps> = React.memo(({ className }) => {
  return (
    <Tooltip label="This feature is in beta 🚀 Try it out and let us know what you think!">
      <span
        className={
          className + " inline items-center rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-800 ms-2"
        }
      >
        Beta
      </span>
    </Tooltip>
  );
});
BetaBadge.displayName = "BetaBadge";

interface NewBadgeProps extends FeatureBadgeProps {}

export const NewBadge: React.FC<NewBadgeProps> = React.memo(({ className }) => {
  return (
    <Tooltip label="Introducing our latest feature 🌟 try it now and elevate your experience!">
      <span
        className={
          className + " inline items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-800 ms-2"
        }
      >
        New
      </span>
    </Tooltip>
  );
});
NewBadge.displayName = "NewBadge";
