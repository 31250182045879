import { type DropzoneOptions, type DropzoneState, useDropzone } from "react-dropzone";
import { maxFileSizeMB } from "../components/fileUpload/ImageUploader";

const useCustomDropzone = (dropzoneOptions: DropzoneOptions): DropzoneState =>
  useDropzone({
    // 10MB
    maxSize: maxFileSizeMB * 1000000,
    onDropRejected: (fileRejections) => {
      const errors: any = {};
      fileRejections.forEach((file) => {
        const e: string[] = [];
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            e.push(
              `Image exceeds file size limit of ${
                dropzoneOptions.maxSize ? dropzoneOptions.maxSize / 1000000 : maxFileSizeMB
              } MB.`,
            );
          }
          if (err.code === "file-invalid-type") {
            e.push(`Invalid file type, only ${dropzoneOptions.accept} files are allowed.`);
          }
          if (err.code === "too-many-files") {
            e.push("Too many files uploaded, only 1 file allowed.");
          }
        });
        errors[file.file.name] = e;
      });
      let alertMessage = "The following files could not be uploaded:\n";
      Object.entries(errors).map(([fileName, errorMessages]: [fileName: string, errorMessages: any]) => {
        alertMessage += fileName + ":";
        errorMessages.map((errorMessage: any, i: number) => {
          alertMessage += `${i + 1}. ` + errorMessage;
        });
        alertMessage += "\n";
      });
      alert(alertMessage);
    },
    // Spread out the custom dropzone options to override the generic dropzone options.
    ...dropzoneOptions,
  });
export default useCustomDropzone;
