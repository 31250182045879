import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Text } from "@chakra-ui/react";
import { EDocument, Document } from "@lato/common";
import React from "react";

interface AddWebsiteDocumentProps {
  addDocument: (doc: Partial<Document>) => void;
  closeModal: any;
}

const domainMatch = /[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+/;
const regMatch =
  // eslint-disable-next-line no-useless-escape
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const AddWebsiteDocument: React.FC<AddWebsiteDocumentProps> = ({ addDocument, closeModal }) => {
  const [url, changeUrl] = React.useState("");
  const [error, setError] = React.useState(false);
  const handleSubmit = async () => {
    console.log("Checking validity...");
    // Check the validity of the website URL
    if (regMatch.test(url)) {
      console.log("VALID!");
      // Valid URL
      const matches = url.match(domainMatch)!;
      // Add the document
      addDocument({
        s3: { key: url },
        s3url: url,
        displayName: matches[0],
        type: EDocument.OTHERLINK,
      });
      // Close the modal
      closeModal();
    } else {
      console.log("INVALID");
      // Invalid URL
      setError(true);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeUrl(e.target.value);
    setError(false);
  };

  return (
    <Stack spacing={3} display="flex" flexDir="column" justifyContent="center">
      <Text fontSize="md">Enter a website URL to create a link to another website.</Text>
      <FormControl isInvalid={error}>
        <FormLabel>Website URL</FormLabel>
        <Input placeholder="Enter website URL" value={url} onChange={handleInputChange} />
        {error && (
          <FormErrorMessage mt="-0.5" mx="1px">
            <ul>
              <li>Make sure to start with http(s)</li>
              <li>Include a valid top-level domain (e.g. .com, .net, ...)</li>
            </ul>
          </FormErrorMessage>
        )}
      </FormControl>
      <Button size="md" mt={4} isDisabled={error} type="button" colorScheme="brand" onClick={handleSubmit}>
        Add link
      </Button>
    </Stack>
  );
};
export default React.memo(AddWebsiteDocument);
