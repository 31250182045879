import { InfoIcon } from "@chakra-ui/icons";
import { Alert, AlertDescription, Box, Button, Icon, Link, Tooltip, useToast } from "@chakra-ui/react";
import { Company, Plan, User } from "@lato/common";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Link as ReactLink } from "react-router-dom";
import AuthAPI from "../../api/auth.api";
import { useMeContext } from "../../stores/me-context";
import { prettyPrintDate } from "../../utils/date";
import { differenceInNumberOfDays } from "../../utils/differenceInNumberOfDays";
import { handleSubmission } from "../../utils/toErrorMap";
import { ME_QUERY_KEY } from "../../utils/constants";

interface FreeTrialAlertProps {
  user: User;
}

const hasStripeAccount = (company: Company) => !!company.stripeCustomerId;

const useTrialInfo = (company: Company): { numberOfDaysLeft: number | null; pretty_date: string | null } | null => {
  const trial_end = company.trial_end_date ? new Date(company.trial_end_date) : null;
  const now_date = new Date();
  if (company.plan === Plan.FREE)
    return {
      numberOfDaysLeft: null,
      pretty_date: null,
    };
  if (!trial_end || trial_end <= now_date) return null;
  const numberOfDaysLeft = differenceInNumberOfDays(new Date(), new Date(trial_end));
  const pretty_date = prettyPrintDate(trial_end, "en", "long");
  return {
    numberOfDaysLeft,
    pretty_date,
  };
};

const upgrade_link = "/settings/billing";

const handleStartFreeTrial = async (queryClient: QueryClient, toast: any) => {
  await handleSubmission({
    successMessage: "activated free trial, you can now create trips",
    failMessage: "activating free trial",
    apiCall: AuthAPI.createStripeAccount(),
    successCallback: async () => {
      await queryClient.invalidateQueries({ queryKey: [ME_QUERY_KEY] });
    },
    toast,
  });
};

const UpgradeNowButtonn: React.FC<FreeTrialAlertProps> = ({ user }) => {
  const trialInfo = useTrialInfo(user.company);
  const toast = useToast();
  const queryClient = useQueryClient();
  if (!trialInfo) return null;
  const { numberOfDaysLeft } = trialInfo;

  return (
    <>
      <Tooltip
        label={
          numberOfDaysLeft !== null
            ? `Trial ends in ${numberOfDaysLeft} ${numberOfDaysLeft === 1 ? "day" : "days"}`
            : ""
        }
      >
        {hasStripeAccount(user.company) ? (
          <Button as={ReactLink} to={upgrade_link} colorScheme="brand" size={"sm"}>
            Upgrade now
            {/* Choose plan */}
          </Button>
        ) : (
          <Button onClick={() => handleStartFreeTrial(queryClient, toast)} colorScheme="brand" size={"sm"}>
            Activitate free trial
          </Button>
        )}
      </Tooltip>
    </>
  );
};
export const UpgradeNowButton = React.memo(UpgradeNowButtonn);

const FreeTrialAlert: React.FC = () => {
  const user = useMeContext();
  const trialInfo = useTrialInfo(user.company);
  const queryClient = useQueryClient();
  const toast = useToast();
  if (!trialInfo) return null;
  const { numberOfDaysLeft, pretty_date } = trialInfo;

  if (numberOfDaysLeft && numberOfDaysLeft > 100) return null;

  const UpgradeNowLink = (
    <Link as={ReactLink} to={upgrade_link} textDecoration="underline" _hover={{ textDecoration: "none" }}>
      Upgrade now
    </Link>
  );

  const StartFreeTrialLink = (
    <Link
      onClick={() => handleStartFreeTrial(queryClient, toast)}
      _hover={{ textDecoration: "none" }}
      textDecoration="underline"
    >
      activate your free trial
    </Link>
  );

  return (
    <>
      <Alert
        status="info"
        color="blue.700"
        bg="rgb(226, 239, 254)"
        borderWidth="1px"
        // borderColor="blue.400"
        borderRadius={"lg"}
        boxShadow={"md"}
        mb={5}
      >
        {/* <AlertIcon /> */}
        <Icon as={InfoIcon} />
        <Box ml={3}>
          {/* <AlertTitle>Email address not yet confirmed!</AlertTitle> */}
          <AlertDescription display="block" fontSize="md" lineHeight={1.1}>
            {numberOfDaysLeft !== null ? (
              <>
                Your trial ends in{" "}
                <b>
                  {numberOfDaysLeft} {numberOfDaysLeft === 1 ? "day" : "days"}
                </b>{" "}
                on {pretty_date}. {UpgradeNowLink} before losing access.
              </>
            ) : hasStripeAccount(user.company) ? (
              <>Your trial has expired. {UpgradeNowLink} to regain access to all features.</>
            ) : (
              <>To experience all features of Lato. You can {StartFreeTrialLink}.</>
            )}
          </AlertDescription>
        </Box>
      </Alert>
    </>
  );
};

export default React.memo(FreeTrialAlert);
