import { Box, Flex, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useController } from "react-hook-form";
import { FaRegStarHalf, FaStarHalf } from "react-icons/fa";

interface StarRatingProps {
  defaultRating?: number;
  name: string;
  scale?: number;
  disable?: boolean;
  value?: number;
}

export const stars = (rating: { value: number }, highlight: number, i: number, scale: number) => (
  <React.Fragment key={i}>
    <Icon
      position={"absolute"}
      width={`${scale}em`}
      height={`${scale}em`}
      left={`${Math.round((i + 1) / 2) * scale}em`}
      transform={i % 2 === 0 ? "rotateY(0)" : " rotateY(180deg)"}
      as={i + 1 <= rating.value || i + 1 <= highlight ? FaStarHalf : FaRegStarHalf}
      pointerEvents={"none"}
      color={"#F2C519"}
      opacity={i + 1 <= rating.value ? (i + 1 <= highlight ? 1 : 0.7) : 0.7}
      top={"-1em"}
    ></Icon>
  </React.Fragment>
);

const StarRating: React.FC<StarRatingProps> = ({ defaultRating = 0, name, scale = 1.5, disable, value = 0 }) => {
  const { field: rating } = useController({
    name,
  });

  const [highlight, setHighlight] = React.useState(defaultRating);

  if (disable) {
    const rating = {
      value,
    };
    return (
      <Flex position="relative">{[...Array(rating.value)].map((_, i) => stars(rating, defaultRating, i, scale))}</Flex>
    );
  }

  return (
    <Flex position="relative">
      {[...Array(10)].map((_, i) => (
        <React.Fragment key={i}>
          {stars(rating, highlight, i, scale)}
          {!disable && (
            <Box
              width={`${scale * 0.5}em`}
              height={`${scale}em`}
              position="absolute"
              left={`${Math.round((i + 1) / 2) * scale + (i % 2 === 0 ? 0 : scale * 0.5)}em`}
              zIndex={10}
              onClick={() => {
                rating.onChange(i + 1);
                setHighlight(i + 1);
              }}
              cursor={"pointer"}
              top={"-1em"}
              onMouseOver={() => setHighlight(i + 1)}
              onMouseLeave={() => setHighlight(rating.value)}
            ></Box>
          )}
        </React.Fragment>
      ))}
      {!disable && rating.value !== 0 && (
        <Tooltip label={"clear"} aria-label="clear-star-rating">
          <Box
            cursor={"pointer"}
            fontSize={"16px"}
            position="absolute"
            top={"-1.5em"}
            left={`${scale * 6.2}em}`}
            onClick={() => {
              rating.onChange(0);
              setHighlight(0);
            }}
          >
            &times;
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
};

export default StarRating;
