import axios from "axios";
import config from "../config";
import ENV from "../utils/env";

const base_url = config[ENV].api_url;
const api_version = "/api/v1";
const base_files_url = base_url + api_version + "/documents";

export class FilesAPI {
  static async s3Sign(s3SignRequestObject: {
    filename: string;
    filetype: string;
    folder: string;
  }): Promise<{ data: { signedRequest: string; s3key: string } }> {
    return await axios.post(base_files_url + "/sign", s3SignRequestObject, {
      headers: {
        "Content-Type": "application/json",
      },
      // In order to be able to set the cookie in the browser
      withCredentials: true,
    });
  }

  static async s3SignBulk(s3SignRequestObjects: any): Promise<{ data: { signedRequest: string; s3key: string }[] }> {
    return await axios.post(base_files_url + "/signBulk", s3SignRequestObjects, {
      headers: {
        "Content-Type": "application/json",
      },
      // In order to be able to set the cookie in the browser
      withCredentials: true,
    });
  }
}
