import React, { useEffect, useRef } from "react";
import Section from "../../components/layout/Section";

const LeadForm: React.FC<any> = () => {
  // prevents it from rendering more then once
  const hasRendered = useRef(false);
  const leadFormDivId = "zf_div_IsXl0Rl42Qvz_XtN3WJT_vmCm33pYHO8yJ45OpbBcRk";
  // this is embedded from zoho, all changed expect from layout changes are to be made in https://forms.zoho.eu/latoportal/home#myforms
  useEffect(() => {
    if (!hasRendered.current) {
      try {
        const f = document.createElement("iframe");
        f.src =
          "https://forms.zohopublic.eu/latoportal/form/LeadForm/formperma/IsXl0Rl42Qvz_XtN3WJT_vmCm33pYHO8yJ45OpbBcRk?zf_rszfm=1";
        f.style.border = "none";
        f.style.height = "1210px";
        f.style.width = "90%";
        f.style.marginLeft = "50px";
        f.style.transition = "all 0.5s ease";
        f.setAttribute("aria-label", "LeadForm");
        const d = document.getElementById(leadFormDivId);
        if (d) {
          d.appendChild(f);
          window.addEventListener(
            "message",
            function (event) {
              const evntData = event.data;
              if (evntData && evntData.constructor == String) {
                const zf_ifrm_data = evntData.split("|");
                if (zf_ifrm_data.length == 2) {
                  const zf_perma = zf_ifrm_data[0];
                  const zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + "px";
                  const iframe = d.getElementsByTagName("iframe")[0];
                  if (iframe && iframe.src.indexOf("formperma") > 0 && iframe.src.indexOf(zf_perma) > 0) {
                    const prevIframeHeight = iframe.style.height;
                    if (prevIframeHeight !== zf_ifrm_ht_nw) {
                      iframe.style.height = zf_ifrm_ht_nw;
                    }
                  }
                }
              }
            },
            false,
          );
        }
        hasRendered.current = true;
      } catch (e) {}
    }
  }, []);

  return (
    <Section>
      <div>
        <div id={leadFormDivId}></div>
      </div>
    </Section>
  );
};

export default React.memo(LeadForm);
