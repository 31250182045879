import { useState } from "react";

import { NotificationContext } from "../../stores/NotificationContext";

interface NotificationProviderProps {
  children: React.ReactNode;
}

/**
 * Notification Provider with Initial Value binded
 * @param React Children Component
 * @returns NotificationProvider Binded Component provide common propertied realated
 * to notification / notification context
 */
const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);

  return (
    <NotificationContext.Provider value={{ notificationCount, setNotificationCount }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
