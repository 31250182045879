import React from "react";
import { DragDropContext, Droppable, type DroppableProps, type DropResult } from "react-beautiful-dnd";

interface DragAndDropProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  moveCallback: (fromIndex: number, toIndex: number) => void;
  direction?: DroppableProps["direction"];
}

// Based on: https://codesandbox.io/s/j2mro6zmmw?file=/index.js:3513-3671
const DragAndDrop: React.FC<DragAndDropProps> = ({ children, moveCallback, direction, ...props }) => {
  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // no movement
    if (destination.index === source.index) {
      return;
    }

    // Call the move callback which is different for each DragAndDrop use.
    moveCallback(source.index, destination.index);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction={direction}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps}>
            <div ref={provided.innerRef} {...props}>
              {React.Children.map(children, (child, index) => {
                // Checking isValidElement is the safe way and avoids a typescript error too.
                if (React.isValidElement(child)) {
                  return child;
                }
                return child;
              })}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default DragAndDrop;
