import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "notes";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

const getNotesFromContact = (contactId: string) => {
  return rawApiProvider.get(`${url}/contact/${contactId}`);
};

const getNotesFromUserTrip = (userTripId: string) => {
  return rawApiProvider.get(`${url}/trip/${userTripId}`);
};

type NotesAPIType = ReturnEndpoints & {
  getNotesFromContact: (contactId: string) => Promise<any>;
  getNotesFromUserTrip: (userTripId: string) => Promise<any>;
};
const NotesAPI: NotesAPIType = {
  ...apiEndpoints,
  getNotesFromContact,
  getNotesFromUserTrip,
};
export default NotesAPI;
