import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { DayPicker as ReactDayPicker, useInput, type ActiveModifiers, type UseInputOptions } from "react-day-picker";
// Import react-day-picker styles
import "react-day-picker/dist/style.css";
// Custom react-day-picker styles
import * as classNames from "../../../styles/daypicker.module.css";
import { omitLocalTimezone } from "@lato/common";

export interface DayPickerProps extends InputProps {
  required?: boolean;
  fromDate?: Date;
  toDate?: Date;
  customOnClickHandler?: any;
  defaultDate?: Date | null;
  isReadOnly?: boolean;
  inputWidth?: string;
  header?: JSX.Element;
  label?: string;
}

const toDefaultDate = (defaultDate: Date | undefined | null) =>
  // The new Date() checks are just to check if it is not an 'Invalid Date'
  defaultDate && new Date(defaultDate) && !isNaN(new Date(defaultDate).getTime())
    ? // Get rid of the local timezone of the user.
      omitLocalTimezone(defaultDate)
    : undefined;

const DayPicker: React.FC<DayPickerProps> = ({
  required = false,
  fromDate,
  toDate,
  customOnClickHandler,
  defaultDate,
  isReadOnly = false,
  inputWidth = "100%",
  header,
  label,
  ...props
}) => {
  const defaultD = toDefaultDate(defaultDate);
  const dateRef = React.useRef<any>();
  const popoverRef = React.useRef<any>();
  const { onOpen, onClose, isOpen } = useDisclosure();
  // Props to pass to `useInput`
  const options: UseInputOptions = {
    // Select today as default
    defaultSelected: defaultD ?? (required ? new Date() : undefined),
    // Limit the valid dates
    fromDate,
    toDate,
    toYear: 2022,
    format: "dd.MM.yyyy",
    // Make the selection mandatory.
    required,
  };

  const input = useInput(options);
  const handleOnDayClick = (day: Date, modifiers: ActiveModifiers, e: React.MouseEvent) => {
    // Trigger the react-day-picker onClick function
    if (input.dayPickerProps.onDayClick) input.dayPickerProps.onDayClick(day, modifiers, e);
    // Trigger the custom onClickHandler
    if (customOnClickHandler) {
      customOnClickHandler(day);
    }
  };

  const handleOnCloseOutsideClick = React.useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      onClose();
    },
    [onClose],
  );

  // useOnClickOutside(popoverRef, isOpen, handleOnCloseOutsideClick);

  React.useEffect(() => {
    // if (defaultDate) {
    const defaultD = toDefaultDate(defaultDate);
    // @ts-ignore
    input.setSelected(defaultD);
    // }
    // input.fieldProps.
  }, [defaultDate]);

  const open = () => {
    // Only open the datepicker if the input is not disabled.
    if (!isReadOnly) {
      onOpen();
    }
  };

  const dateRegex = new RegExp(/[0-9][0-9].[0-9][0-9].[0-9][0-9][0-9][0-9]/gm);

  return (
    <>
      {/* {isOpen && <div className="fixed top-0 left-0 w-full h-full" />} */}
      <Popover
        // initialFocusRef={dateRef}
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={dateRef}
        closeOnBlur={true}
      >
        {/* <PopoverTrigger>
        
      </PopoverTrigger> */}
        <PopoverAnchor>
          <InputGroup display="flex" onClick={open}>
            {label && (
              <FormLabel mt={2} mr={0} width="11em">
                {label}
              </FormLabel>
            )}
            <Input
              {...props}
              {...input.inputProps}
              w={inputWidth}
              autoComplete="nope"
              ref={dateRef}
              placeholder="dd.mm.yyyy"
              isReadOnly={isReadOnly}
              onChange={(e) => {
                props.onChange && props.onChange(e);
                input.inputProps.onChange && input.inputProps.onChange(e);
                const datestring = e.target.value;
                if (dateRegex.test(datestring)) {
                  const dateStringSplit = datestring.split(".");
                  let date = new Date(+dateStringSplit[2], +dateStringSplit[1] - 1, +dateStringSplit[0]);
                  if (fromDate && date.getTime() < fromDate.getTime()) {
                    date = fromDate;
                  }
                  if (toDate && date.getTime() > toDate.getTime()) {
                    date = toDate;
                  }
                  handleOnDayClick(date, {}, e as any);
                }
              }}
              onBlur={(e) => {
                if (!popoverRef?.current?.contains(e.relatedTarget)) onClose();
              }}
            />
            {!props.isDisabled && (
              <InputRightElement cursor="pointer">
                <IconButton
                  icon={<CalendarIcon />}
                  // Custom button variant
                  variant="basic"
                  aria-label="daterange-calendar-icon"
                  isDisabled={isReadOnly}
                />
              </InputRightElement>
            )}
          </InputGroup>
        </PopoverAnchor>
        {isOpen && (
          <Portal>
            <Box zIndex={5000} position={"relative"}>
              <PopoverContent ref={popoverRef}>
                <PopoverArrow />
                {header && <PopoverHeader>{header}</PopoverHeader>}
                <PopoverBody h="350px">
                  <ReactDayPicker
                    {...input.dayPickerProps}
                    // @ts-ignore
                    classNames={classNames}
                    showOutsideDays
                    onDayClick={handleOnDayClick}
                  />
                </PopoverBody>
              </PopoverContent>
            </Box>
          </Portal>
        )}
      </Popover>
    </>
  );
};
export default React.memo(DayPicker);
