import { Button, Flex, Stack, Text, useToast } from "@chakra-ui/react";
import { UserTrip } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import UserTripsAPI from "../../api/usertrips.api";
import Form from "../../components/form/Form";
import RHFInput from "../../components/input/RHFInput";
import RHFDayPicker from "../../components/input/date/RHFDayPicker";
import CustomModal from "../../components/layout/CustomModal";
import { getInitialTrip } from "../../pages/create-trip";
import { useMeContext } from "../../stores/me-context";
import { SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../utils/constants";
import { handleSubmission } from "../../utils/toErrorMap";

export type CopyTripType = {
  newclient_name: string;
  newstart_date: Date;
  passengers: boolean;
  flights: boolean;
  tripdocs: boolean;
  tripdays: boolean;
  priceDetails: boolean;
  userTrips: { brand: boolean };
};

export interface CopyTripModalProps {
  sample: boolean;
  convertType?: "tripToSample" | "sampleToTrip";
  userTrip: UserTrip;
  modalDisclosure: any;
}

const CopyTripModal: React.FC<CopyTripModalProps> = ({ sample, convertType, userTrip, modalDisclosure }) => {
  const me = useMeContext();
  const toast = useToast();
  const trip = userTrip.trip!;
  const navigate = useNavigate();
  const isNewResourceSample = !convertType ? sample : convertType === "sampleToTrip" ? false : true;
  const defaultValues: CopyTripType = {
    newclient_name: !convertType ? trip.client_name : "",
    passengers: false,
    tripdocs: true,
    flights: false,
    tripdays: true,
    priceDetails: true,
    userTrips: { brand: true },
  };
  const newResourceName = isNewResourceSample ? "sample" : "trip";
  const newRouteName = newResourceName + "s";

  const formMethods = useForm<CopyTripType>({
    shouldUnregister: false,
    defaultValues,
  });

  const { isOpen, onClose } = modalDisclosure;
  const queryClient = useQueryClient();

  const { mutateAsync: copyUserTrip, isPending: isCopying } = useMutation({
    mutationFn: (copyUserTripDto: object) => UserTripsAPI.copyUserTrip(userTrip.id!, copyUserTripDto),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });

  const emptyFormInitialValues = getInitialTrip(me);

  const onCopy = React.useCallback(
    async (formValues: CopyTripType) => {
      const copyDTO = {
        ...formValues,
        newSampleValue: isNewResourceSample,
        newStartDate:
          convertType === "tripToSample" ? null : (formValues.newstart_date ?? emptyFormInitialValues.start_date),
      };
      // Create the new trip
      const newUt = await handleSubmission({
        successMessage: "duplicated trip",
        failMessage: "duplicating trip",
        apiCall: copyUserTrip(copyDTO),
        toast,
        setError: formMethods.setError,
      });
      // Route the the corresponding page
      if (newUt) navigate(`/${newRouteName}/${newUt.id!}`);
    },
    [copyUserTrip],
  );

  const action = convertType ? "Create" : "Duplicate";

  const showAllProps = !sample && !convertType;
  const showDayPicker = showAllProps || convertType === "sampleToTrip";
  return (
    <CustomModal
      title={`${action} ${newResourceName} '${
        userTrip.trip?.titles?.length !== 0 ? userTrip.trip?.titles[0].content : ""
      }'`}
      onClose={onClose}
      isOpen={isOpen}
      size="sm"
      colorScheme="brand"
    >
      <Form formMethods={formMethods} onSubmit={onCopy} className="space-y-3">
        <Flex gap={4}>
          {showAllProps && <RHFInput name="newclient_name" label="New client name" w="200px" inputWidth={"12em"} />}
          {showDayPicker && (
            <RHFDayPicker
              required
              name={`newstart_date`}
              defaultDate={new Date()}
              label="New start date"
              width={"10em"}
              header={<>Select a start date</>}
            />
          )}
        </Flex>
        <Text fontSize="sm" color="realGray.600">
          Deselect any items you {`don't`} want to copy over to the new {newResourceName}
        </Text>
        <Stack spacing={1}>
          {showAllProps && (
            <>
              <RHFInput type="checkbox" name="passengers">
                All passengers
              </RHFInput>
              <RHFInput type="checkbox" name="flights">
                All flights
              </RHFInput>
            </>
          )}
          <RHFInput type="checkbox" name="tripdocs">
            All documents
          </RHFInput>
          <RHFInput type="checkbox" name="tripdays">
            All tripdays
          </RHFInput>
          <RHFInput type="checkbox" name="priceDetails">
            All price details
          </RHFInput>
          <RHFInput type="checkbox" name="tasks">
            All tasks
          </RHFInput>
        </Stack>
        <Flex alignItems="center" justifyContent="right" mt={3}>
          <Button onClick={onClose} colorScheme="red" variant="outline" mr={2}>
            Cancel
          </Button>
          <Button type="submit" colorScheme="brand" isLoading={isCopying}>
            {action} {newResourceName}
          </Button>
        </Flex>
      </Form>
    </CustomModal>
  );
};
export default React.memo(CopyTripModal);
