import { AddIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ImageSite, IsImage, TLibraryImage, prettyLocation } from "@lato/common";
import React from "react";
import { LiaFontAwesomeFlag } from "react-icons/lia";
import Masonry from "react-masonry-css";
import { useAsyncDebounce } from "react-table";
import LibraryImagesAPI from "../../../api/library-images.api";
import { useMeContext } from "../../../stores/me-context";
import { LIBRARY_IMAGES_QUERY_KEY } from "../../../utils/constants";
import { prettyPrintDate } from "../../../utils/date";
import { useParamsInfiniteQueryHelper } from "../../../utils/query-helpers/reactQueryHooks";
import useMutation from "../../../utils/query-helpers/useMutation";
import useIntersectionObserver from "../../../utils/useIntersectionObserver";
import { ResourceTitle, SmallAddResourceButton } from "../../CRUD/Resource";
import { FILTER_TYPE } from "../../CustomDownShift/CustomComboBox";
import { FilterSelect } from "../../CustomDownShift/FilterSelect";
import Section from "../../layout/Section";
import LibraryItems from "../../trips/edit/daybyday/library-items/LibraryItems";
import LibraryImageModal from "./libraryimage-modal";
import LearningSection from "../../learn/LearningSection";
import articleData from "../../../assets/data/learnsection/learnArticles.json";

interface LibraryImagesProps {}

// Subtext for under the title
const subText = "These images can be seamlessly added to accommodations, activities or as tripday image.";

const LibraryImages: React.FC<LibraryImagesProps> = () => {
  const user = useMeContext();
  const [edit, setEdit] = React.useState<boolean>(false);
  const [item, setItem] = React.useState<TLibraryImage | undefined>(undefined);
  const [locationFilter, setLocationFilter] = React.useState();
  const [locationFilterCoordinates, setLocationFilterCoordinates] = React.useState();
  const [globalFilter, setGlobalFilter] = React.useState<string>("");

  const disclosure = useDisclosure();
  const locationDisclosure = useDisclosure();

  const infiniteQueryResultUnsplash = useParamsInfiniteQueryHelper<TLibraryImage>({
    queryKey: LIBRARY_IMAGES_QUERY_KEY,
    queryParams: {
      q: globalFilter,
      location: locationFilterCoordinates,
    } as any,
    apiCall: LibraryImagesAPI.getAll,
    options: {
      getNextPageParam: (lastPage, pages) => (pages.length >= lastPage.total_pages ? false : pages.length + 1),
    },
  });

  const { status, data, error, isFetching, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    infiniteQueryResultUnsplash;

  const pageEnd = React.useRef<any>(null);

  const isMoreDataAvailable =
    hasNextPage && data.pages.flatMap((page) => (page as any).data).length < data.pages[0]?.count;

  useIntersectionObserver({
    target: pageEnd,
    onIntersect: fetchNextPage,
    enabled: isMoreDataAvailable,
  });

  const deleteImage = useMutation(
    {
      apiCall: (image: TLibraryImage) => LibraryImagesAPI.delete(image.id!),
      successMessage: `deleted image`,
      failMessage: `deleting image`,
    },
    [LIBRARY_IMAGES_QUERY_KEY],
  );

  const onEdit = React.useCallback(
    async (itemObj?: TLibraryImage) => {
      if (itemObj) {
        setEdit(true);
        setItem(itemObj);
        disclosure.onOpen();
      }
    },
    [setItem, disclosure],
  );

  const onFilterChange = useAsyncDebounce((value: string) => {
    setGlobalFilter(value);
  }, 300);

  const breakPoints = {
    default: 4,
    600: 3,
    300: 2,
  };

  console.log("data", data);
  let images;
  if (data) {
    images = data.pages.flatMap((page) => (page as any).data);
  }
  return (
    <LibraryItems>
      <Box mb={"-1em"}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Flex h={5} alignItems={"center"}>
            <ResourceTitle displayName={"images"} totalCount={data?.pages ? (data?.pages[0] as any).count : 0} />
            <SmallAddResourceButton
              display={{ base: "none", md: "inline" }}
              singularDisplayName={""}
              onAdd={() => {
                setItem(undefined);
                setEdit(false);
                disclosure.onOpen();
              }}
            />
          </Flex>
          <Button
            leftIcon={window.innerWidth < 768 ? "" : <AddIcon />}
            colorScheme="brand"
            onClick={() => {
              setItem(undefined);
              setEdit(false);
              disclosure.onOpen();
            }}
          >
            Add Image
          </Button>
        </Flex>
        {subText && (
          <Text fontSize="small" color="GrayText" mb={4}>
            {subText}
          </Text>
        )}
      </Box>
      <Section
        noHorizontalPadding
        noDivider
        mb={10}
        heading={
          <Box mb={2}>
            <Flex alignItems="center" justifyContent={"space-between"}>
              <Flex alignItems="center" width={"100%"}>
                <>
                  <HStack w="60%" spacing={3}>
                    <InputGroup size="sm">
                      <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                      </InputLeftElement>
                      <Input
                        size="sm"
                        borderRadius="md"
                        type="search"
                        //value={value || ""}
                        onChange={(e) => {
                          onFilterChange(e.target.value);
                        }}
                        placeholder={`Search by title, client name or ref.`}
                        // style={{
                        //   fontSize: "1.1rem",
                        // }}
                      />
                    </InputGroup>
                    <Box maxW={48}>
                      <FilterSelect
                        type={FILTER_TYPE.LOCATION}
                        onChange={setLocationFilterCoordinates}
                        defaultSelected={locationFilter}
                        icon={LiaFontAwesomeFlag}
                        name="location"
                        width={"100%"}
                        setFilterValue={setLocationFilter}
                        borderRadius="sm"
                        dropdown
                      ></FilterSelect>
                    </Box>
                    {/*tableMethods.headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column) =>
                      column.canFilter ? (
                        <div key={`filter-column-name`}>
                          {column.render("Filter")}
                        </div>
                      ) : null
                    )
                      )*/}
                  </HStack>
                </>
              </Flex>
            </Flex>
          </Box>
        }
      >
        <Box m={8}>
          {images ? (
            <>
              <Masonry
                breakpointCols={breakPoints}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {images[0] &&
                  images.map((image: TLibraryImage, photoIndex: number) => {
                    const { mainAddress } = image.location ? prettyLocation(image.location) : { mainAddress: "" };

                    return (
                      <Box key={`external-picture-${photoIndex}`}>
                        <Box
                          role="group"
                          pos="relative"
                          borderRadius={"md"}
                          overflow="hidden"
                          as="button"
                          type="button"
                          width={"100%"}
                          onClick={() => onEdit(image)}
                        >
                          <Flex
                            display={"flex"}
                            right={1}
                            top={1}
                            pos={"absolute"}
                            as="button"
                            type="button"
                            borderRadius="full"
                            w="20px"
                            h="20px"
                            bg="gray.200"
                            alignItems="center"
                            justifyContent="center"
                            transition="ease 0.3s"
                            opacity={0}
                            _hover={{
                              bg: "gray.300",
                            }}
                            _groupHover={{
                              opacity: 1,
                            }}
                            zIndex={1}
                            onClick={(ev) => {
                              ev.stopPropagation();
                              deleteImage.mutate(image);
                            }}
                          >
                            <CloseIcon fontSize="9px" />
                          </Flex>
                          <Flex
                            pos="absolute"
                            bottom={0}
                            w="100%"
                            bg="blackAlpha.500"
                            _groupHover={{
                              opacity: 1,
                            }}
                            py={0.5}
                            px={3}
                            transition=".3s ease"
                            opacity={0}
                          >
                            {image.country?.flagImage && (
                              <Box my={"auto"} mr={1}>
                                <Image height={`10px`} width={"12px"} src={image.country.flagImage} />
                              </Box>
                            )}
                            <Text textAlign="left" fontSize="sm" color="white" whiteSpace="nowrap" isTruncated>
                              {mainAddress && mainAddress !== "" ? mainAddress : image.name}
                            </Text>
                            <Text alignSelf={"flex-end"} ml={"auto"} fontSize="sm" color="white" whiteSpace="nowrap">
                              {prettyPrintDate(image.created_at)}
                            </Text>
                          </Flex>
                          {!IsImage(image.site) ? (
                            <>
                              {image.site === ImageSite.CUSTOMVIDEO ? (
                                <iframe
                                  title={`image-${photoIndex}`}
                                  src={image.url}
                                  style={{
                                    width: "100%",
                                    objectFit: "cover",
                                    pointerEvents: "none",
                                  }}
                                ></iframe>
                              ) : (
                                <video
                                  key={image.url}
                                  autoPlay
                                  muted
                                  width="100%"
                                  height="210"
                                  loop
                                  disablePictureInPicture
                                  controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
                                >
                                  <source src={image.url} />
                                </video>
                              )}
                            </>
                          ) : (
                            <Image width={"100%"} src={image.url} />
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              </Masonry>
              {(isFetching || isLoading) && (
                <Flex w="100%" h="100%" align="center" justifyContent="center" mt={5}>
                  <Spinner color="brand.500" />
                </Flex>
              )}

              <Flex justify="center">
                <Button
                  onClick={() => fetchNextPage()}
                  disabled={!isMoreDataAvailable}
                  colorScheme="brand"
                  ref={pageEnd}
                >
                  {isFetchingNextPage ? "Loading more..." : isMoreDataAvailable ? "Load More" : "Nothing more to load"}
                </Button>
              </Flex>
            </>
          ) : (
            <Text textAlign="center" my={5}>
              No search results found.
            </Text>
          )}
        </Box>
      </Section>
      <Box marginRight={"10%"}>
        <LearningSection learnData={articleData.imageArticles} />
      </Box>
      {(disclosure.isOpen || locationDisclosure.isOpen) && (
        <LibraryImageModal
          inModal={true}
          disclosure={disclosure}
          edit={edit}
          image={item}
          locationDisclosure={locationDisclosure}
        />
      )}
    </LibraryItems>
  );
};
export default React.memo(LibraryImages);
