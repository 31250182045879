import { DUE_DAYS_TYPE } from "../../types/index.js";
export function calculateDueDate(startDate, endDate, dueDays, dueDaysType) {
    let calculatedDate = new Date();
    switch (dueDaysType) {
        case DUE_DAYS_TYPE.BEFORE_START:
            calculatedDate = new Date(startDate.getTime());
            calculatedDate.setDate(calculatedDate.getDate() - dueDays);
            break;
        case DUE_DAYS_TYPE.AFTER_START:
            calculatedDate = new Date(startDate.getTime());
            calculatedDate.setDate(calculatedDate.getDate() + dueDays);
            break;
        case DUE_DAYS_TYPE.BEFORE_END:
            calculatedDate = new Date(endDate.getTime());
            calculatedDate.setDate(calculatedDate.getDate() - dueDays);
            break;
        case DUE_DAYS_TYPE.AFTER_END:
            calculatedDate = new Date(endDate.getTime());
            calculatedDate.setDate(calculatedDate.getDate() + dueDays);
            break;
        default:
            throw new Error("Invalid due days type");
    }
    return calculatedDate;
}
