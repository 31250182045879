import { Box, Flex, Heading } from "@chakra-ui/react";
import { Trainstation } from "@lato/common";
import React, { FC, useRef } from "react";
import TrainstationsAPI from "../../api/trainstations.api";
import { TRAINSTATIONS_QUERY_KEY } from "../../utils/constants";
import CustomDownShiftWithPagination from "./CustomDownShiftWithPagination";

interface AirportDownShiftProps {
  onSelectedHandler: (param: any) => any;
  trainstation?: any;
  disableInput?: boolean;
}

interface SingleTrainstationItemProps {
  item: Trainstation;
}

export const SingleTrainstation: React.FC<SingleTrainstationItemProps> = ({ item }) => {
  return (
    <Box>
      <Flex>
        <Heading fontSize={"10px"}>
          {item.iata == undefined || item.iata == "---" || item.iata == "" ? "" : item.iata} {item.name}
        </Heading>
      </Flex>
    </Box>
  );
};

const TrainstationDownShift: FC<AirportDownShiftProps> = (props: AirportDownShiftProps) => {
  const formatSelectedInputValue = (item: any) => {
    return item
      ? `${item?.iata == undefined || item?.iata == "---" || item?.iata == "" ? "" : item.iata} ${item.name}`
      : "";
  };

  return (
    <>
      <CustomDownShiftWithPagination
        apiCall={(queryParams?: string) => TrainstationsAPI.getTrainstations(queryParams)}
        queryName={TRAINSTATIONS_QUERY_KEY}
        listItemConfig={{
          ListItem: SingleTrainstation,
          showSelected: ({ item }: { item: any }) => formatSelectedInputValue(item),
          onSelected: ({ item }: { item: any }) => props.onSelectedHandler(item),
        }}
        additionalQueryState={{
          step: 5,
          page: 1,
        }}
        disableInput={props.disableInput}
        defaultSelected={props.trainstation}
        initialInputValue={formatSelectedInputValue(props.trainstation)}
        height="200px"
      />
      <style>
        {`.overflowVisible {
            overflow: visible;
          }`}
      </style>
    </>
  );
};

export default TrainstationDownShift;
