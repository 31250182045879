import { Button, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import { IoWarningOutline } from "react-icons/io5";

interface OrderWarningPopoverProps {
  children: React.ReactNode;
  handleDragAndDropMove: (i: number, toIndex: number, moveAnyway: boolean) => void;
  toIndex?: number;
  i: number;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  fromIndex?: number;
  setFromIndex: (index?: number) => void;
}

export const OrderWarningPopover: React.FC<OrderWarningPopoverProps> = ({
  children,
  handleDragAndDropMove,
  toIndex,
  i,
  onOpen,
  onClose,
  isOpen,
  fromIndex,
  setFromIndex,
}) => {
  return (
    <Popover isOpen={isOpen && fromIndex === i} onOpen={onOpen} onClose={onClose} placement="top" closeOnBlur={false}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <div className="flex">
          <div className="w-32 px-4 flex justify-center items-center">
            <IoWarningOutline className="text-orange-600 bg-orange-100 rounded-full px-1 w-8 h-8" />
          </div>
          <div className="space-y-4 py-4 px-2">
            <p className="font-bold">Order warning</p>
            <p className="text-sm">Moving this element will break the chronological order of the elements.</p>
            <div className="flex gap-4 justify-end">
              <Button
                variant={"ghost"}
                onClick={() => {
                  onClose();
                  setFromIndex(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                colorScheme="orange"
                onClick={() => {
                  (toIndex || toIndex === 0) && handleDragAndDropMove(i, toIndex, true);
                  onClose();
                }}
              >
                Move anyway
              </Button>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
