import { Button } from "@chakra-ui/react";
import { IMPERSONALIZATION_COOKIE_KEY, User, Plan } from "@lato/common";
import React from "react";
import MultipleTabsIllustration from "../../assets/icons/multiple-tabs.illustration";
import { useSocketInstance, useSocketSubscribe } from "../../app/providers/SocketProvider";
import { useMeContext } from "../../stores/me-context";
import { BROWSER_TOKEN_KEY } from "../../utils/constants";
import getCookie from "../../utils/get-cookie";
import { CardWrapper } from "../CardWrapper";
import SplitScreenLayout from "../layout/SplitScreenLayout";

// This window should only block if the user has a paid account and the user is not impersonalizing.
function isBlockable(user: User | undefined): boolean {
  const isPaidAccount = user?.company?.plan !== Plan.FREE;
  const isImpersonalization = getCookie(IMPERSONALIZATION_COOKIE_KEY) !== null;
  return !!user && isPaidAccount && !isImpersonalization;
}

/**
 * SimultaneousSessionGuard is a React functional component that displays a card with a message and a button when the user is logged in from another tab.
 *
 * @return {ReactElement} The rendered component.
 */
const SimultaneousSessionGuard: React.FC = () => {
  const [blocked, setBlocked] = React.useState(false);
  const user = useMeContext();
  const blockable = isBlockable(user);

  useSocketSubscribe("keep-alive", async (browser_token: string) => {
    // This message is sent when the user is logged in from another tab.
    // The browser_token is the token present in the browser of the user who is just now accessing the system.
    const current_browser_token = localStorage.getItem(BROWSER_TOKEN_KEY);
    // This window should only block if the user has a paid account and the user is not impersonalizing.
    if (blockable) {
      // Block this screen if the newly logged in browser_token is different from the current browser token.
      setBlocked(browser_token !== current_browser_token);
    }
  });

  if (!blocked) {
    // Session should connect
    return <HappyFlow user={user} />;
  }

  const reactivateSession = () => {
    setBlocked(false);
  };

  // Session should not connect
  return (
    <SplitScreenLayout>
      <CardWrapper
        title="Another dashboard detected"
        description="You have logged in from another device or browser. Please only login from one device and browser at a time."
        topIllustration={MultipleTabsIllustration}
      >
        <Button w="100%" size="md" mt={4} colorScheme="brand" fontSize="2xl" onClick={reactivateSession}>
          Reactivate
        </Button>
      </CardWrapper>
    </SplitScreenLayout>
  );
};
export default React.memo(SimultaneousSessionGuard);

const HappyFlow: React.FC<{ user: User | undefined }> = ({ user }) => {
  const socket = useSocketInstance();

  // Listen to the connect event and send this to the server
  React.useEffect(() => {
    // Normally we would use socket.on("connect") but we only open 1 socket instance in the SocketProvider for performance.
    // So we use the existing socket and check when this component mounts instead of checking if the socket is connected.
    const blockable = isBlockable(user);
    if (socket && user && blockable) {
      let browser_token = localStorage.getItem(BROWSER_TOKEN_KEY);
      if (!browser_token) {
        browser_token = new Date().toISOString();
        localStorage.setItem(BROWSER_TOKEN_KEY, browser_token);
      }
      socket.emit("connect-lato", { userId: user.id, browser_token });
    }
  }, [socket, user]);

  return <></>;
};
