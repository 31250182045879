import React from "react";
import CreateGeneralTrip from "../../../../components/trips/edit/general/CreateGeneralTrip";
import TripStepWrapper from "../TripStepWrapper";

export const GeneralTripRoute: React.FC = () => {
  const stepNumber = 0;

  return (
    <TripStepWrapper>
      <CreateGeneralTrip stepNumber={stepNumber} />
    </TripStepWrapper>
  );
};

export default GeneralTripRoute;
