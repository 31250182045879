import { Button } from "@chakra-ui/react";

import { HOTEL_BOOKING_STATUS, HOTEL_ORIGIN, Hotel } from "@lato/common";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { trpc } from "../../../../../../../trpc";
import { useMeContext } from "../../../../../../stores/me-context";
import { useTripLanguage } from "../../../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import ENV from "../../../../../../utils/env";
import { filterDuplicateIds } from "../../../../../../utils/filterRooms";
import { useTripTranslations } from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { ELEMENT_VIEW } from "../../../../../CRUD/Resource";
import TripForm from "../../../../TripForm";
import { LibraryItemInTripProps } from "../LibraryItemDownshift";
import { resolveIncorrectTranslationFields } from "../activities/resolveIncorrectTranslationFields";
import { ActualHotelForm } from "./ActualHotelForm";
import { closeElement } from "../../../../../../utils/EventHelper";

export const disableBedBanks = ENV === "production";

export interface HotelFormProps {
  tripProps: LibraryItemInTripProps | null;
  setHotel?: any;
  hotel?: Hotel;
  updateAccommodation: any;
  onDelete?: any;
  isCreate?: boolean;
  view?: ELEMENT_VIEW;
  formId?: string;
  isUpdatingAccommodation: boolean;
  formMethods: UseFormReturn<Hotel, any, undefined>;
  hotelAmount?: number;
  forceNew?: boolean;
}

const HotelForm: React.FC<HotelFormProps> = ({
  tripProps,
  hotel,
  updateAccommodation,
  onDelete,
  isCreate,
  view,
  isUpdatingAccommodation,
  formMethods,
  formId,
  hotelAmount,
}) => {
  const { trip, setUnCollapsedElement, isInvalidForms, isErrorForms } = useTripFormStore();
  const { data: tripTranslations } = useTripTranslations(trip.id);
  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();
  const me = useMeContext();

  const handleSubmit = async () => {
    closeElement(setUnCollapsedElement, isInvalidForms, isErrorForms);
    const formValues = formMethods.getValues();
    await updateAccommodation({
      ...formValues,
      rooms: filterDuplicateIds(formValues.rooms).map((room) =>
        room.id === room.description
          ? { ...room, id: undefined, maxAdults: +(room.maxAdults ?? 0), maxChildren: +(room.maxChildren ?? 0) }
          : { ...room, maxAdults: +(room.maxAdults ?? 0), maxChildren: +(room.maxChildren ?? 0) },
      ),
      descriptions: resolveIncorrectTranslationFields(
        formValues.descriptions,
        tripProps?.first ?? false,
        firstSelectedLanguageIndex,
        secondSelectedLanguageIndex ?? 0,
        tripTranslations,
        me,
      ),
    });
  };

  return (
    <TripForm
      formId={formId!}
      triggerResetData={hotel}
      defaultValues={hotel}
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <Button type="submit" hidden isLoading={isUpdatingAccommodation} className="form-submit-button">
        Save
      </Button>
      <ActualHotelForm
        formId={formId}
        hotelAmount={hotelAmount}
        tripProps={tripProps}
        updateAccommodation={updateAccommodation}
        onDelete={onDelete}
        isCreate={isCreate}
        view={view}
        hotel={hotel}
        isUpdatingAccommodation={isUpdatingAccommodation}
        formMethods={formMethods}
      />
    </TripForm>
  );
};
export default React.memo(HotelForm);

export const hotelStatusColorMap: { [key in HOTEL_BOOKING_STATUS]: string } = {
  [HOTEL_BOOKING_STATUS.QUOTE]: "gray",
  [HOTEL_BOOKING_STATUS.ONREQUEST]: "blue",
  [HOTEL_BOOKING_STATUS.BOOKED]: "green",
  [HOTEL_BOOKING_STATUS.CANCELLED]: "red",
};

export const boardMap: { [key in HOTEL_ORIGIN]: any } = {
  [HOTEL_ORIGIN.HOTELBEDS]: trpc.hotelbeds.getHotelbedBoards,
  [HOTEL_ORIGIN.RATEHAWK]: undefined,
  [HOTEL_ORIGIN.LATO]: undefined,
  [HOTEL_ORIGIN.YALAGO]: undefined,
  [HOTEL_ORIGIN.MAPPED]: undefined,
};

export const roomMap: { [key in HOTEL_ORIGIN]: any } = {
  [HOTEL_ORIGIN.HOTELBEDS]: trpc.hotelbeds.getHotelbedRooms,
  [HOTEL_ORIGIN.RATEHAWK]: trpc.ratehawk.getRatehawkRooms,
  [HOTEL_ORIGIN.LATO]: undefined,
  [HOTEL_ORIGIN.YALAGO]: trpc.yalago.getYalagoRooms,
  [HOTEL_ORIGIN.MAPPED]: undefined,
};
