import { useToast } from "@chakra-ui/react";
import {
  useQueryClient,
  useMutation as useRQMutation,
  type QueryKey,
  type UseMutationResult,
} from "@tanstack/react-query";
import { HandleSubmissionType, handleSubmission } from "../toErrorMap";

export type MutationReturn = {
  mutate: any;
  rqMutation: UseMutationResult<unknown, unknown, void, unknown>;
};

// Added additionalQueryKey to the useMutation function to enable invalidation other queries (invalidating the table query when data changes in the maps)
const useMutation = (mutationOptions: HandleSubmissionType<any>, queryKey?: QueryKey, additionalQueryKey?: QueryKey): MutationReturn => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const mutationResult = useRQMutation({
    mutationFn: async (...args) => mutationOptions.apiCall(...args),
    onSuccess: () => {
      if (queryKey) queryClient.invalidateQueries({ queryKey: queryKey });
      if (additionalQueryKey) queryClient.invalidateQueries({ queryKey: additionalQueryKey });
    },
  });
  
  

  const handleMutation = async (...args: any) => {
    return await handleSubmission({
      ...mutationOptions,
      toast,
      apiCall: mutationResult.mutateAsync(...args),
    });
  };

  return { mutate: handleMutation, rqMutation: mutationResult };
};
export default useMutation;
