import { PRIMARY_COLOR } from "@lato/common";

const baseStyleDialog = {
  borderRadius: "xl",
  overflow: "hidden",
};
const baseStyleHeader = {
  // bg: PRIMARY_COLOR,
  // color: "white",
};
const baseStyle = {
  dialog: baseStyleDialog,
  header: baseStyleHeader,
};

export default {
  baseStyle,
};
