import { Icon } from "@chakra-ui/icons";
import { Box, Tooltip, Text, Link, Heading } from "@chakra-ui/react";
import { useState } from "react";
import { IconType } from "react-icons/lib";

export type SeperatedHeadingProps = {
  title: React.ReactElement | string;
  description?: string;
  morelink?: string;
  morelinkText?: string;
  size?: number;
  style?: any;
  icon?: IconType;
  hoverIcon?: IconType;
  tooltipText?: string;
  onClick?: () => void;
};

export const SeperatedHeading: React.FC<SeperatedHeadingProps> = ({
  title,
  description,
  morelink,
  morelinkText,
  size,
  style,
  icon,
  hoverIcon,
  tooltipText,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      mt={6}
      mb={3}
      right={{ base: "0", md: "auto" }}
      width={{ base: "auto", md: "100%" }}
      textAlign={"left"}
      style={style}
      color={"gray.500"}
    >
      {morelink ? (
        <Link
          href={morelink}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          _hover={{ textDecoration: "none" }}
        >
          <Heading size="lg" fontSize={size}>
            {title}
          </Heading>
          <p>{morelinkText}</p>
        </Link>
      ) : (
        <Link onClick={onClick}>
          <Tooltip label={tooltipText} placement="right">
            <Heading
              size="md"
              fontWeight={500}
              fontSize={size}
              className={"hover:underline cursor-pointer"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              width={"fit-content"}
            >
              {icon && <Icon as={isHovered && onClick ? hoverIcon : icon} mr={2} mb={0.5} />}
              {title}
            </Heading>
          </Tooltip>
        </Link>
      )}
      {description && (
        <Text fontSize="md" color="realGray.600">
          {description}
        </Text>
      )}
    </Box>
  );
};
