import { Avatar, Flex, Icon, IconButton, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { Contact, GENDER, Passport, Trip } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { CheckIcon } from "lucide-react";
import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { MdModeEdit } from "react-icons/md";
import { TbExternalLink } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { CellProps, Column } from "react-table";
import { GENERAL_STEP_CONTACTS_QUERY_KEY } from "../../utils/constants";
import {
  useAddPassenger,
  useRemovePassenger,
  useTripContacts,
} from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import FieldArrayResource from "../../components/CRUD/FieldArrayResource";
import { SmallAddResourceButton } from "../../components/CRUD/Resource";
import { CustomSpinner } from "../../components/FullScreenSpinner";
import SearchContacts from "../../components/TripContacts/SearchContacts";
import { calculateAge } from "../contacts/Contact";
import { EditContactModal } from "../contacts/EditContact";
import Form from "../../components/form/Form";

export type Passenger = {
  first_name: string;
  last_name: string;
  primaryEmail: string;
  date_of_birth: string | null;
  gender: GENDER;
  passport: Passport;
  id?: string;
};

const short_gender_names: Record<GENDER, string> = {
  [GENDER.M]: "M",
  [GENDER.F]: "F",
  [GENDER.X]: "X",
};

const fieldName = "travellers";

interface PassengersProps {
  trip_start_date: string | null;
  trip: Trip;
  tripId: string;
  lead_booker?: Contact;
  tripContacts?: Contact[];
}

const newPassenger: Passenger = {
  last_name: "",
  first_name: "",
  gender: GENDER.M,
  date_of_birth: null,
  passport: {
    number: "",
    expiration_date: null,
  },
  primaryEmail: "",
};

export const Passengers: React.FC<PassengersProps> = ({ trip, tripId, trip_start_date }) => {
  const { data: passengers, isLoading: isLoadingContacts } = useTripContacts(tripId ?? "");

  if (isLoadingContacts || !passengers || !tripId) return <CustomSpinner card skeleton />;
  return <PassengersForm trip_start_date={trip_start_date} tripContacts={passengers} trip={trip} tripId={tripId} />;
};

const PassengersForm: React.FC<PassengersProps> = ({ trip_start_date, tripContacts, trip, tripId }) => {
  const formId = `0-${GENERAL_STEP_CONTACTS_QUERY_KEY}`;
  const queryClient = useQueryClient();
  const contactDisclosure = useDisclosure();

  const { mutateAsync: addPassenger, isPending: isAddingPassenger } = useAddPassenger(tripId ?? "", queryClient);
  const { mutateAsync: removePassenger, isPending: isRemovingPassenger } = useRemovePassenger(
    tripId ?? "",
    queryClient,
  );

  const defaultValues = { travellers: tripContacts };

  const formMethods = useForm<{ travellers: Contact[] }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: false,
    criteriaMode: "all",
    //resolver: yupResolver(tripValidationSchemas[0], { abortEarly: false }),
  });

  // const handleSubmit = async () => {
  //   const { travellers } = formMethods.getValues();
  //   //await updatePassengers(travellers);
  // };

  const fieldArrayPassenger = useFieldArray({
    control: formMethods.control,
    name: fieldName,
    keyName: "rhfId",
  });

  const handleChooseContact = async (e: Partial<Contact>) => {
    fieldArrayPassenger.append(e);
    await addPassenger(e);
  };

  const handleRemoveContact = async (e: any, obj: Partial<Contact>) => {
    await removePassenger(obj.id!);
  };

  // const leadBooker = useWatch({ name: "lead_booker", defaultValue: trip.lead_booker });
  // const contacts = useWatch({ name: "contacts", defaultValue: tripContacts });

  // useEffect(() => {
  //   if (!leadBooker && contacts && contacts.length > 0) {
  //     formMethods.setValue("lead_booker", contacts[0]);
  //     formMethods.setValue("leadBookerId", contacts[0].id);
  //   }
  // }, [contacts]);

  const columns: Column<Passenger>[] = React.useMemo(
    () => [
      // {
      //   id: "lead_booker",
      //   chakraWidth: "60px",
      //   Header: "Lead booker",
      //   center: true,
      //   Cell: ({ row }: CellProps<any>) => {
      //     return (
      //       <CheckIcon
      //         //style={{ color: `${row.original.id === leadBooker?.id ? "green" : "lightGray"}` }}
      //         className="cursor-pointer mx-auto"
      //         onClick={() => {
      //           // if (!row.original.primaryEmail) {
      //           //   setError("The lead booker needs an e-mail.");
      //           // } else {
      //           //   setValue("lead_booker", row.original);
      //           //   setValue("leadBookerId", row.original.id);
      //           //   setError(undefined);
      //           // }
      //         }}
      //       />
      //     );
      //   },
      // },
      {
        id: "passenger-avatar",
        chakraWidth: "60px",
        Cell: ({ row }: CellProps<Passenger>) => {
          return (
            <Avatar
              key={`avatar-badge-${row.original.id}`}
              size="sm"
              name={`${row.original.last_name} ${row.original.first_name}`}
              src={(row.original as any).avatarUrl}
              background={"#9f9f9f"}
              color={"white"}
            />
          );
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        Cell: ({ value, row, column }) => {
          // if (!row.original.hasOwnProperty("contact_type")) {
          //   return (
          //     <RHFInput
          //       name={`$passengers.${(row.original as any).index}.${column.id}`}
          //       onClick={(e: any) => e.stopPropagation()}
          //       defaultValue={value}
          //     />
          //   );
          // }
          return <Text>{value}</Text>;
        },
      },
      {
        accessor: "first_name",
        Header: "First Name",
        // Cell: (props) => <EditableCell {...props} noInputBorder={false} />,
        Cell: ({ value, row, column }) => {
          // if (!row.original.hasOwnProperty("contact_type")) {
          //   return (
          //     <RHFInput
          //       name={`${fieldName}.${(row.original as any).index}.${column.id}`}
          //       onClick={(e: any) => e.stopPropagation()}
          //       defaultValue={value}
          //     />
          //   );
          // }
          return <Text>{value}</Text>;
        },
      },
      {
        accessor: "gender",
        Header: Object.values(short_gender_names).join(""),
        chakraWidth: "6ch",
        Cell: ({ value, row, column }) => {
          // if (!row.original.hasOwnProperty("contact_type")) {
          //   return (
          //     <RHFSelect
          //       name={`${fieldName}.${(row.original as any).index}.${column.id}`}
          //       options={Object.values(GENDER).map((g) => ({ value: g }))}
          //       // defaultValue={row.original.gender}
          //     />
          //   );
          // }
          return <Text>{short_gender_names[value]}</Text>;
        },
      },
      {
        accessor: "date_of_birth",
        id: "age",
        Header: "Age",
        chakraWidth: "6ch",
        Cell: ({ row, value }: CellProps<any>) => {
          if (!value) return <></>;
          const ageNow = calculateAge(value);
          let ageAtTimeOfDeparture = trip_start_date ? calculateAge(value, new Date(trip_start_date)) : null;
          ageAtTimeOfDeparture = ageNow === ageAtTimeOfDeparture ? null : ageAtTimeOfDeparture;
          if (!ageNow) return <></>;
          return (
            <Tooltip label={ageAtTimeOfDeparture ? "Age at time of departure" : null} aria-label="Age notice">
              <Text ml={1}>
                {ageNow}
                {ageAtTimeOfDeparture ? `*` : ""}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        accessor: "date_of_birth",
        Header: "Date of Birth",
        chakraWidth: "12ch",
        Cell: ({ row, value, column }: CellProps<any>) => {
          // if (!row.original.hasOwnProperty("contact_type")) {
          //   return (
          //     <MaskedDateInput
          //       name={`${fieldName}.${row.original.index}.${column.id}`}
          //       // defaultValue={value}
          //     />
          //   );
          // }
          if (value) {
            const date = new Date(value);
            return (
              <Text>{`${+date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}-${
                +date.getMonth() + 1 < 10 ? `0${+date.getMonth() + 1}` : +date.getMonth() + 1
              }-${date.getFullYear()}`}</Text>
            );
          }
          return <Text></Text>;
        },
      },
      {
        accessor: "primaryEmail",
        id: "primaryEmail",
        Header: "Email",
        chakraWidth: "80px",
        Cell: ({ row, value }: CellProps<any>) => {
          return <Text>{value}</Text>;
        },
      },
      {
        id: "passport.number",
        accessor: (row) => row.passport.number,
        Header: "Passport No.",
        Cell: ({ row, value, column }: CellProps<Passenger>) => {
          // if (!row.original.hasOwnProperty("contact_type")) {
          //   return (
          //     <RHFInput
          //       name={`$passengers.${(row.original as any).index}.${column.id}`}
          //       onClick={(e: any) => e.stopPropagation()}
          //       defaultValue={value}
          //     />
          //   );
          // }
          return <Text>{value}</Text>;
        },
      },
      {
        id: "passport.expiration_date",
        accessor: (row) => row.passport.expiration_date,
        Header: "Exp. Date",
        Cell: ({ row, value, column }: CellProps<Passenger>) => {
          // if (!row.original.hasOwnProperty("contact_type")) {
          //   return (
          //     <MaskedDateInput
          //       name={`${fieldName}.${(row.original as any).index}.${column.id}`}
          //       // defaultValue={value}
          //     />
          //   );
          // }
          if (value) {
            const date = new Date(value);
            return (
              <Text>{`${+date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}-${
                +date.getMonth() + 1 < 10 ? `0${+date.getMonth() + 1}` : +date.getMonth() + 1
              }-${date.getFullYear()}`}</Text>
            );
          }
          return <Text></Text>;
        },
      },
      {
        id: "edit",
        chakraWidth: "40px",
        Cell: ({ row }: CellProps<Passenger>) => {
          const modalDisclosure = useDisclosure();
          const { onOpen } = modalDisclosure;
          const navigate = useNavigate();

          if (row.original.hasOwnProperty("contact_type")) {
            const contact = {
              ...(row.original as Contact),
              date_of_birth: row.original.date_of_birth ? new Date(row.original.date_of_birth) : null,
              passport: {
                ...row.original.passport,
                expiration_date:
                  row.original.passport.expiration_date && new Date(row.original.passport.expiration_date),
              },
            };

            return (
              <>
                <Tooltip label="Edit" aria-label="edit-contact-tooltip">
                  <IconButton
                    icon={<Icon as={MdModeEdit} boxSize={5} />}
                    aria-label={"edit-contact"}
                    variant="ghost"
                    onClick={(e) => {
                      e.stopPropagation();
                      onOpen();
                    }}
                  />
                </Tooltip>
                <Tooltip label="Go to" aria-label="view-contact-tooltip">
                  <IconButton
                    icon={<Icon as={TbExternalLink} boxSize={5} />}
                    aria-label="view-contact"
                    variant="ghost"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      // Edit the trip
                      navigate(`/contacts/${contact.id}`);
                    }}
                  />
                </Tooltip>
                <EditContactModal
                  contactDetail={contact}
                  modalDisclosure={modalDisclosure}
                  reset={(contact) => {
                    const contacts = formMethods.getValues(fieldName);
                    const index = contacts.findIndex((c) => c.id === contact.id);
                    contacts[index] = contact;
                    formMethods.reset({
                      travellers: contacts,
                    });
                  }}
                />
              </>
            );
          }
          return <></>;
        },
      },
    ],
    [trip_start_date],
  );

  /*const onClickRow = React.useCallback(
    (e: any, contactId: string) => {
      e.stopPropagation();
      // Edit the trip
      navigate(`/contacts/${contactId}`);
    },
    [navigate],
  );

  const rowProps = ({ row }: { row: Row<any> }) => ({
    onClick: (e: any) => (row.original.hasOwnProperty("contact_type") ? onClickRow(e, row.original.id) : {}),
    _hover: {
      textDecoration: row.original.hasOwnProperty("contact_type") ? "underline" : "none",
      cursor: row.original.hasOwnProperty("contact_type") ? "pointer" : "default",
    },
  });*/

  return (
    <Form onSubmit={() => {}} formMethods={formMethods} id={"travellers"}>
      <FieldArrayResource
        newEmptyRecord={newPassenger}
        formName={fieldName}
        columns={columns}
        showAddButton={false}
        displayName={"Travellers"}
        fieldArray={fieldArrayPassenger}
        inlineEdit={false}
        onDelete={handleRemoveContact}
        disableFilterSave={true}
        headerButton={
          <Flex alignItems={"center"} w={"25em"} gap={3}>
            <SearchContacts
              assignContactsToTrip={handleChooseContact}
              fields={formMethods.getValues(fieldName)}
              disclosure={contactDisclosure}
            />
            <SmallAddResourceButton singularDisplayName={"Contact"} onAdd={contactDisclosure.onOpen} />
          </Flex>
        }
      />
    </Form>
  );
};
