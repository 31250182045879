import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";

const url = "library-images";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

type LibraryImagesAPIType = ReturnEndpoints;
const LibraryImagesAPI: LibraryImagesAPIType = {
  ...apiEndpoints,
};
export default LibraryImagesAPI;
