import { Box } from "@chakra-ui/react";
import { TRIP_STATUS, Trip, UserTrip } from "@lato/common";
import React, { useEffect, useMemo, useState } from "react";
import { Filters } from "react-table";
import { ListSkeleton } from "../components/FullScreenSpinner";
import FreeTrialAlert from "../components/alerts/FreeTrialAlert";
import ErrorCardView from "../components/layout/ErrorCardView";
import TripsGrid from "../components/trips/TripsGrid";
import { useMeContext } from "../stores/me-context";
import { useTripsListStore } from "../stores/trip/tripslistStore";
import { STORAGE_KEY, validateLocalStorage } from "../utils/checkOldStorage";
import { RECENTLY_EDITED_LIST_QUERY_KEY, SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../utils/constants";
import ENV from "../utils/env";
import { grantPayingPlansPermission } from "../utils/grantPermission";
import { useMultipleTrips, useMyTeam, usePaginatedTrips } from "../utils/query-helpers/reactQueryHooks";
import { PaginationOptions, defaultPaginationOptions } from "../utils/query-helpers/usePaginatedQuery";
import { ELEMENT_VIEW } from "../components/CRUD/Resource";
import { useNavigate, useSearchParams } from "react-router-dom";
import LearningSection from "../components/learn/LearningSection";
import articleData from "../assets/data/learnsection/learnArticles.json";
import TripsList from "../features/tripList/TripsList";

interface dashboardProps {
  samples?: boolean;
}

export const mapFiltersToQueryParams = (filters: Filters<any>) =>
  filters?.reduce(
    (result, filtValue) => {
      const { id, value } = filtValue;
      result[id] = value && value.length > 0 ? value : undefined;
      return result;
    },
    {} as { [key: string]: string },
  );

export const TripListContainerRoute: React.FC<dashboardProps> = ({ samples = false }) => {
  return (
    <Box w="100%" maxW="1000px" mx="auto" my={10}>
      <TripListContainer samples={samples} />
      <LearningSection learnData={articleData[samples ? "sampleArticles" : "tripArticles"]} randomiseArticles={true} />
    </Box>
  );
};

export default TripListContainerRoute;

export const TripListContainer: React.FC<dashboardProps> = ({ samples = false }) => {
  const navigate = useNavigate();
  const me = useMeContext();

  const [searchParams] = useSearchParams();
  const recentlyOpenedParam = searchParams.get("recentlyOpened");
  const [isRecentFilter, setIsRecentFilter] = useState(!!recentlyOpenedParam);
  const [trips, setTrips] = useState<UserTrip[]>([]);
  const [isLoadingSwitch, setIsLoadingSwitch] = useState(false);

  const recentlyEditedListKey = `${RECENTLY_EDITED_LIST_QUERY_KEY}-${me.id}-${ENV}`;
  const recentlyOpenedTrips = localStorage.getItem(recentlyEditedListKey) ?? "";

  const listName = samples ? "samples" : "trips";

  const { data: members, isLoading: isLoadingMembers, error: errorMembers } = useMyTeam();

  const [isList] = React.useState(true);

  const LS_FILTER_KEY = STORAGE_KEY(listName, me.id, ENV);
  const LS_LAYOUT_KEY = `layout-${listName}`;

  validateLocalStorage(LS_FILTER_KEY);
  const localFilters = localStorage.getItem(LS_FILTER_KEY) && JSON.parse(localStorage.getItem(LS_FILTER_KEY)!);

  const userLayoutPref = localStorage.getItem(LS_LAYOUT_KEY) as ELEMENT_VIEW;

  const defaultFilters = [
    { id: "search", value: "" },
    { id: "days", value: "" },
    { id: "statuses", value: [TRIP_STATUS.QUOTE, TRIP_STATUS.APPROVED, TRIP_STATUS.BOOKED, TRIP_STATUS.CANCELED] },
    { id: "users", value: [me?.id] },
    { id: "companies", value: [me?.company.id] },
    { id: "start_date", value: "" },
    { id: "end_date", value: "" },
    { id: "country", value: [] },
    { id: "continent", value: [] },
    { id: "languages", value: [] },
    { id: "traveling_status", value: [] },
    { id: "brands", value: [] },
  ];

  const defaultTableState: PaginationOptions = {
    ...defaultPaginationOptions,
    filters: defaultFilters,
    step: userLayoutPref == ELEMENT_VIEW.CALENDAR ? 100 : 10,
  };

  const [tableState, setTableState] = React.useState<PaginationOptions>(
    localFilters
      ? {
          ...localFilters,
          page: localFilters.page,
          updated: undefined,
          step: userLayoutPref == ELEMENT_VIEW.CALENDAR ? 100 : 10,
        }
      : defaultTableState,
  );

  const { resetStore } = useTripsListStore();

  useEffect(() => {
    resetStore();
  }, []);

  const { data, isFetching, isLoading, error } = usePaginatedTrips(
    {
      ...tableState,
      ...mapFiltersToQueryParams(tableState.filters!),
      sample: samples ? true : false,
      tasks: true,
    },
    {
      enabled: tableState.hasOwnProperty("order") && !isRecentFilter,
      queryKey: [samples ? SAMPLES_USERTRIPS_KEY : TRIPS_USERTRIPS_KEY, tableState],
    },
  );
  const userTripIds = recentlyOpenedTrips
    ? recentlyOpenedTrips.split(",").filter((el) => el !== "" && el && el !== "undefined")
    : [];

  const {
    data: recentlyOpenedTripsData,
    isFetching: isRecentlyOpenedFetching,
    isLoading: isRecentlyOpenedLoading,
    error: isRecentlyOpenedError,
    refetch,
  } = useMultipleTrips(userTripIds, {
    enabled: isRecentFilter && !samples && userTripIds.length > 0,
    queryKey: [RECENTLY_EDITED_LIST_QUERY_KEY, tableState],
  });

  useEffect(() => {
    if (isRecentFilter) {
      setTrips(recentlyOpenedTripsData ? recentlyOpenedTripsData.data : []);
    } else {
      setTrips(data ? data.data : []);
    }
  }, [isRecentFilter, data, recentlyOpenedTripsData]);

  const triggerFetch = React.useCallback(
    ({
      order,
      orderBy,
      page,
      step,
      q,
      filters,
    }: {
      page: number;
      step: number;
      q: string;
      order: "DESC" | "ASC" | undefined;
      orderBy: string | undefined;
      filters: Filters<Trip>;
    }) => {
      // This will get called when the table needs new data
      // set the state to the new query params, such that the useQuery will automatically trigger an update
      // If no sorting is enabled, then sort on descending order because of the creation date descending order.

      const days = filters
        .filter((filter) => filter.id === "days")
        .map((filter) => filter.value)
        .join(",");

      const statuses =
        filters.find((filter) => filter.id === "status")?.value?.join(",") ||
        [TRIP_STATUS.QUOTE, TRIP_STATUS.APPROVED, TRIP_STATUS.BOOKED, TRIP_STATUS.CANCELED].join(",");
      const travelingStatuses = filters.find((filter) => filter.id === "traveling_status")?.value?.join(",") || "";
      const users = filters.find((filter) => filter.id === "users")?.value?.join(",") || me?.id;
      const companies = filters.find((filter) => filter.id === "companies")?.value?.join(",") || me?.company.id;
      const startDateRange = filters.find((filter) => filter.id === "start_date")?.value?.from;
      const endDateRange = filters.find((filter) => filter.id === "start_date")?.value?.to;
      const countries = filters.find((filter) => filter.id === "country")?.value?.join(",") || "";
      const continents = filters.find((filter) => filter.id === "continent")?.value?.join(",") || "";
      const languages = filters.find((filter) => filter.id === "languages")?.value?.join(",") || "";
      const brands = filters.find((filter) => filter.id === "brands")?.value?.join(",") || "";

      const filterInstance: PaginationOptions = {
        page,
        step: localStorage.getItem(LS_LAYOUT_KEY) === ELEMENT_VIEW.CALENDAR ? 100 : step,
        orderBy,
        order,
        q,
        filters: [
          { id: "search", value: q },
          { id: "days", value: days },
          { id: "statuses", value: statuses },
          { id: "travelingStatuses", value: travelingStatuses },
          { id: "users", value: users },
          { id: "companies", value: companies },
          { id: "startDateRange", value: startDateRange },
          { id: "endDateRange", value: endDateRange },
          { id: "countries", value: countries },
          { id: "continents", value: continents },
          { id: "languages", value: languages },
          { id: "brands", value: brands },
        ],
      };

      setTableState(filterInstance);
    },
    [me?.id, me?.company.id, userLayoutPref],
  );

  const tripsList = useMemo(() => {
    const permissionToCreateTrips = grantPayingPlansPermission(me.company.plan);

    return (
      <>
        {!permissionToCreateTrips && <FreeTrialAlert />}
        {isList ? (
          <TripsList
            samples={samples}
            userTrips={trips ?? []}
            loadingTrips={isLoading || isRecentlyOpenedLoading || isLoadingSwitch}
            fetchingTrips={isFetching || isRecentlyOpenedFetching || isLoadingSwitch}
            triggerFetch={triggerFetch}
            totalCount={data?.count ? data.count : 0}
            resetFilters={async () => {
              setIsLoadingSwitch(true);
              setTableState(defaultTableState);
              setIsRecentFilter(false);
              !!recentlyOpenedParam && navigate("/trips");
            }}
            initialTableState={defaultTableState}
            permissionToCreateTrips={permissionToCreateTrips}
            members={members}
            filters={tableState}
            listName={listName}
            isRecentFilter={isRecentFilter}
            setIsRecentFilter={async (value: boolean) => {
              setIsLoadingSwitch(true);
              if (value && !recentlyOpenedTripsData) {
                await refetch();
              }
              setIsRecentFilter(value);
            }}
          />
        ) : (
          <TripsGrid usertrips={data?.data ? data.data : []} />
        )}
      </>
    );
  }, [
    trips,
    isLoading,
    data,
    isLoadingSwitch,
    tableState,
    listName,
    isRecentFilter,
    recentlyOpenedParam,
    defaultTableState,
  ]);

  if (isLoadingMembers) return <ListSkeleton />;
  if (errorMembers || !members) return <ErrorCardView title="Could not load company members" />;
  if (error || isRecentlyOpenedError) return <ErrorCardView title="Could not load trips" />;

  return tripsList;
};
