import { Button, Flex, Heading, Stack, useToast } from "@chakra-ui/react";
import { Company } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useForm } from "react-hook-form";
import CompanyAPI from "../../api/companies.api";
import { useMeContext } from "../../stores/me-context";
import { handleSubmission } from "../../utils/toErrorMap";
import Form from "../../components/form/Form";
import RHFInputPassword from "../../components/input/RHFInputPassword";
import Section from "../../components/layout/Section";
import { SettingsInputWrapper } from "../../components/settings/account/PasswordSettings";
import Bookings from "./Bookings";
import { ME_QUERY_KEY } from "../../utils/constants";

// settings page for booking configurations
// currently only used to set a hotelbedsApiKey
const BookingConfiguration: React.FC = () => {
  const user = useMeContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  const formMethods = useForm<Company>({
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
    defaultValues: user.company,
  });

  const { mutateAsync: mutateCompany } = useMutation({
    mutationFn: CompanyAPI.updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
    },
  });

  const updateCompanyInformation = async (company: Company) => {
    await handleSubmission({
      successMessage: "updated company information",
      failMessage: "updating company information",
      apiCall: mutateCompany(company),
      toast,
      setError: formMethods.setError,
    });
  };

  return (
    <Bookings>
      <ReactQueryDevtools />
      <Stack w="100%" maxW="1000px" mx="auto" mb={10} gap={4}>
        <Heading>Configuration</Heading>

        <Form formMethods={formMethods} onSubmit={updateCompanyInformation}>
          <Section
            heading={
              <Flex justifyContent={"space-between"} mr={7}>
                <Heading>Hotelbeds</Heading>
                <Button type="submit" colorScheme="brand">
                  Save
                </Button>
              </Flex>
            }
          >
            <SettingsInputWrapper>
              <RHFInputPassword
                name="hotelbeds_client_id"
                label="Client Reference"
                placeholder="Lato"
                // defaultValue={company.name}
                layout="horizontal"
              />
            </SettingsInputWrapper>
          </Section>
        </Form>
      </Stack>
    </Bookings>
  );
};

export default BookingConfiguration;
