const SummarizeIcon = () => (
  <svg
    role="graphics-symbol"
    viewBox="0 0 16 16"
    className="assistSummarize"
    style={{ width: "16px", height: "16px", display: "block", fill: "currentColor", flexShrink: 0 }}
  >
    <path d="M2.64746 7.24414C3.60449 7.24414 4.37695 6.56738 4.37695 5.59668C4.37695 4.66699 3.74121 4.03125 2.90039 4.03125C2.50391 4.03125 2.16211 4.1543 1.91602 4.44824C2.12793 3.62109 2.81836 3.06055 3.77539 2.99902C4.05566 2.97852 4.27441 2.75293 4.27441 2.46582C4.27441 2.1377 3.9873 1.91895 3.61133 1.91895C2.05957 1.91895 0.678711 3.21094 0.678711 5.01562C0.678711 6.36914 1.49219 7.24414 2.64746 7.24414ZM7.07031 7.24414C8.03418 7.24414 8.80664 6.56738 8.80664 5.59668C8.80664 4.66699 8.1709 4.03125 7.33008 4.03125C6.93359 4.03125 6.58496 4.1543 6.33887 4.44824C6.55762 3.62109 7.24121 3.06055 8.19824 2.99902C8.48535 2.97852 8.7041 2.75293 8.7041 2.46582C8.7041 2.1377 8.41699 1.91895 8.03418 1.91895C6.48242 1.91895 5.10156 3.21094 5.10156 5.01562C5.10156 6.36914 5.92188 7.24414 7.07031 7.24414ZM10.7822 3.42285H14.5762C14.9932 3.42285 15.3281 3.08789 15.3281 2.6709C15.3281 2.25391 14.9932 1.91895 14.5762 1.91895H10.7822C10.3584 1.91895 10.0303 2.25391 10.0303 2.6709C10.0303 3.08789 10.3584 3.42285 10.7822 3.42285ZM10.7822 7.00488H14.5762C14.9932 7.00488 15.3281 6.66992 15.3281 6.25293C15.3281 5.83594 14.9932 5.50098 14.5762 5.50098H10.7822C10.3584 5.50098 10.0303 5.83594 10.0303 6.25293C10.0303 6.66992 10.3584 7.00488 10.7822 7.00488ZM1.41699 10.5801H14.5762C14.9932 10.5801 15.3281 10.252 15.3281 9.82812C15.3281 9.41113 14.9932 9.07617 14.5762 9.07617H1.41699C1 9.07617 0.665039 9.41113 0.665039 9.82812C0.665039 10.252 1 10.5801 1.41699 10.5801ZM1.41699 14.1621H14.5762C14.9932 14.1621 15.3281 13.8271 15.3281 13.4102C15.3281 12.9932 14.9932 12.6582 14.5762 12.6582H1.41699C1 12.6582 0.665039 12.9932 0.665039 13.4102C0.665039 13.8271 1 14.1621 1.41699 14.1621Z"></path>
  </svg>
);
export default SummarizeIcon;
