import { Box, Flex, Image, Text } from "@chakra-ui/react";
import {
  GoogleAddress,
  ImageSite,
  POIType,
  TextFieldElement,
  TranslationField,
  User,
  prettyLocation,
} from "@lato/common";
import React from "react";
import { LiaFontAwesomeFlag } from "react-icons/lia";
import { CellProps, Column } from "react-table";
import POIsAPI, { POI } from "../../api/pois.api";
import { useMeContext } from "../../stores/me-context";
import formatAddress from "../../utils/formatAddress";
import { useLanguages, useMyTeam } from "../../utils/query-helpers/reactQueryHooks";
import sanitizeXSS, { removeAllHTMLTags } from "../../utils/sanitizeXSS";
import { CARDSIZES, CustomCard } from "../../components/CRUD/CardView";
import LanguageFilter from "../../components/CRUD/LanguageFilter";
import MemberFilter from "../../components/CRUD/MemberFilter";
import { ELEMENT_VIEW, TableWithDrawerProps } from "../../components/CRUD/Resource";
import TypeFilter from "../../components/CRUD/TypeFilter";
import { FILTER_TYPE } from "../../components/CustomDownShift/CustomComboBox";
import { FilterSelect } from "../../components/CustomDownShift/FilterSelect";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import { CardProps } from "../../components/TripsList";
import { getEmptyLocation, getEmptyPoi } from "../../components/elements/activities";
import ErrorCardView from "../../components/layout/ErrorCardView";
import { IconMarker } from "../../components/map/IconMarker";
import { POIsToIcons } from "../../components/map/POIMarker";
import { LibraryItemType, MarkerMapItem } from "../../components/trips/edit/daybyday/library-items/LibraryItemModal";
import LibraryItemsTable from "../../components/trips/edit/daybyday/library-items/LibraryItemsTable";

interface POIsTableProps extends Partial<TableWithDrawerProps<any>> {
  additionalColumns?: Column<TextFieldElement>[];
  additionalQueryState?: object;
  initialFilters?: Array<any>;
  minTableHeight?: string;
}

const eventType: LibraryItemType = "poi";
// Subtext for under the title
const subText =
  "Points of Interest are automatically added to each trip based on the location of the POI and the locations in the trip.";

const defaultColumns: Column<POI>[] = [
  {
    Header: "Title",
    accessor: "names",
    Cell: ({ value, row, column }) => {
      const content = value && (value as any).length ? (value as any)[0].content : "";
      return (
        <Text
          title={"title"}
          maxW={"40em"}
          maxH={"4em"}
          whiteSpace={"normal"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          dangerouslySetInnerHTML={{
            __html: sanitizeXSS(content),
          }}
        ></Text>
      );
    },
  },
  {
    Header: "Description",
    accessor: "descriptions",
    Cell: ({ value, row, column }) => {
      const content = value && (value as any).length ? (value as any)[0].content : "";
      return (
        <Text
          title={"description"}
          maxW={"40em"}
          maxH={"4em"}
          whiteSpace={"normal"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          dangerouslySetInnerHTML={{
            __html: sanitizeXSS(removeAllHTMLTags(content)),
          }}
        ></Text>
      );
    },
  },
  {
    Header: "Type",
    accessor: "type",
    id: "poiType",
    Cell: ({ value, row, column }) => {
      const content = (value as any).length ? value : "";
      return (
        <Text
          title={"type"}
          maxW={"40em"}
          maxH={"4em"}
          whiteSpace={"normal"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          dangerouslySetInnerHTML={{
            __html: sanitizeXSS(content),
          }}
        ></Text>
      );
    },
  },
];

const POIsTable: React.FC<POIsTableProps> = ({ initialFilters, minTableHeight, ...props }) => {
  const api = POIsAPI;
  const { data: userdata, isLoading: isLoadingMembers, error: errorMembers } = useMyTeam();
  const { data: allLanguages, isLoading: isLoadingLanguages, error: errorLanguages } = useLanguages();
  const types = Object.values(POIType);
  const me = useMeContext();
  const mapState = localStorage.getItem("layout-POIs");

  const users: User[] = userdata;
  const [memberFilter, setMemberFilter] = React.useState<string[] | undefined>(
    users?.map((member: User) => member.id!),
  );
  const [locationFilter, setLocationFilter] = React.useState();
  const [languageFilter, setLanguageFilter] = React.useState<string[] | undefined>(
    initialFilters ? initialFilters.find((lang) => lang.id === "languages").value : undefined,
  );
  const [typeFilter, setTypeFilter] = React.useState<string[] | undefined>(
    initialFilters ? initialFilters.find((lang) => lang.id === "types").value : undefined,
  );

  const resetFilters = () => {
    setMemberFilter(users?.map((member: User) => member.id!));
  };

  // Used to get a new POI object with the correct language and user, also used to set default values in POIForm in map
  const getNewPOI = (coordinates?: number[], address?: GoogleAddress, locationName?: string) => {
    const newPOI = getEmptyPoi(me);
    newPOI.names[0].content = locationName ?? "New POI";
    newPOI.location = {
      name: locationName ?? "",
      coordinates: coordinates ?? [0, 0],
      address: address ?? getEmptyLocation().address,
    };
    return newPOI;
  };

  const columns = React.useMemo(
    () => [
      ...defaultColumns.map((c) => ({
        ...c,
        disableFilters:
          (c.accessor === "descriptions" || c.accessor === "names") && mapState === ELEMENT_VIEW.MAP ? false : true,
      })),
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ value }) => {
          const address = formatAddress(value?.address, true);
          return <Text title={address}>{address}</Text>;
        },
        // Due to issues in the backend.
        disableSortBy: true,
        // chakraWidth: "30%",
        Filter: (props: any) => {
          React.useEffect(() => {
            locationFilter === "" &&
              props.columns
                .find((column: any) => column.id === "location" || column.id === "coordinates")
                ?.setFilter(undefined);
          }, [locationFilter]);
          return (
            <FilterSelect
              type={FILTER_TYPE.LOCATION}
              onChange={(e) => {
                props.columns
                  .find((column: any) => column.id === "location" || column.id === "coordinates")
                  ?.setFilter(e);
              }}
              defaultSelected={locationFilter}
              icon={LiaFontAwesomeFlag}
              name="location"
              width={"100%"}
              borderRadius="sm"
              dropdown
              setFilterValue={setLocationFilter}
            ></FilterSelect>
          );
        },
        disableFilters: false,
        hideFiltersOnMapView: true,
      },
      {
        accessor: "users" as any,
        chakraWidth: "0px",
        Filter: (props: any) => <MemberFilter {...props} members={users} setMemberFilter={setMemberFilter} />,
        disableFilters: false,
        disableSortBy: true,
        hide: true,
      },
      {
        accessor: "type",
        id: "poiTypes",
        chakraWidth: "0px",
        Filter: (props: any) => <TypeFilter {...props} types={types} setTypeFilter={setTypeFilter} />,
        disableSortBy: true,
        disableFilters: false,
        hide: true,
      },
      {
        Header: "Languages",
        accessor: "descriptions",
        chakraWidth: "60px",
        id: "languages",
        Cell: ({ value, row, column }: CellProps<TextFieldElement>) => {
          const languages = ((value as TranslationField[]) ?? [])
            .map((l) => l.language)
            .sort((a, b) => (a.name > b.name ? 1 : -1));
          return (
            <Text isTruncated maxW="6em" ml={1} title={languages.map((l) => l.name).join(", ")}>
              {languages.map((language) => language.flag).join(" ")}
            </Text>
          );
        },
        Filter: (props: any) => {
          return (
            <LanguageFilter
              {...props}
              languages={allLanguages}
              column={{
                ...props.column,
                filterValue: languageFilter,
              }}
            />
          );
        },
        disableFilters: false,
        hideFiltersOnMapView: true,
      },
    ],
    [memberFilter, initialFilters, typeFilter, locationFilter],
  );

  if (isLoadingLanguages || isLoadingMembers) return <FullScreenSpinner />;
  if (errorLanguages || !allLanguages || errorMembers || !users)
    return <ErrorCardView title="Could not load languages" />;

  return (
    <>
      <LibraryItemsTable
        {...props}
        displayName="POIs"
        subText={subText}
        minTableHeight={minTableHeight}
        defaultColumns={columns}
        enableGridView
        enableMapView
        defaultView={ELEMENT_VIEW.MAP}
        cardViewComponent={POICard}
        markerComponent={POIMarker}
        getEmptyItem={getNewPOI}
        api={api}
        eventType={eventType}
        resetFilters={resetFilters}
        searchBarPlaceholder="Search by title, description, location, ..."
        useSpaciousLayout
        initialFilters={[{ id: "users", value: memberFilter }, ...(initialFilters ?? [])]}
        additionalQueryState={{
          users: memberFilter,
          languages: languageFilter,
          types: typeFilter,
        }}
        setLocationFilter={setLocationFilter}
      />
    </>
  );
};

const POICard: React.FC<CardProps> = ({ row, key, size }) => {
  const poi = row.original as POI;
  const image =
    poi?.images && poi.images[0]
      ? poi.images[0]
      : {
          url: "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png",
          site: ImageSite.CUSTOMPICTURE,
        };
  const optionsCell = row.cells.filter((cell: any) => cell.column.id === "libraryItem-options")[0];
  const { mainAddress, subAddress } = poi.location ? prettyLocation(poi.location) : { mainAddress: "", subAddress: "" };

  const flagWidth: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "22px",
    [CARDSIZES.L]: "18px",
    [CARDSIZES.MD]: "16px",
    [CARDSIZES.S]: "14px",
  };

  return (
    <CustomCard key={key} size={size} title={poi.names && poi.names[0].content} image={image} optionsCell={optionsCell}>
      <Flex
        flexDir={"column"}
        gap={"0.2em"}
        position={"absolute"}
        left={size === CARDSIZES.S ? "1.2em" : "1.8em"}
        bottom={size === CARDSIZES.S ? "-2em" : "-3em"}
      >
        {poi.location?.address?.country?.flagImage && (
          <Box mb={1}>
            <Image
              height={`calc(${flagWidth[size]} - 6px)`}
              width={flagWidth[size]}
              borderRadius={2}
              shadow={"0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)"}
              src={poi.location.address.country.flagImage}
            />
          </Box>
        )}
        <Box fontSize={"1em"}>{mainAddress}</Box>
        {subAddress && subAddress !== "" && (
          <Box fontSize={"12px"} fontWeight={400}>
            {subAddress}
          </Box>
        )}
      </Flex>
    </CustomCard>
  );
};

// Handels making Markers for POI used in the map view
const POIMarker = (item: MarkerMapItem, index: number, onClick: any) => {
  const poi = item as POI;
  return (
    <IconMarker
      key={`poi-marker-${index}`}
      coordinates={poi.location?.coordinates ?? [0, 0]}
      layout={POIsToIcons[poi.type]}
      onClick={() => onClick(poi)}
      name={poi.location.name}
    />
  );
};
export default React.memo(POIsTable);
