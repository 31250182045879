import { EditIcon } from "@chakra-ui/icons";
import { Box, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { FaCopy } from "react-icons/fa";
import { CellProps, Column, Row } from "react-table";
import { useMyTeam } from "../../../../../utils/query-helpers/reactQueryHooks";
import useMutation from "../../../../../utils/query-helpers/useMutation";
import OptionsDropdown, { DeleteOptionsDropdownItem, OptionsDropdownItem } from "../../../../CRUD/OptionsDropdown";
import FullScreenSpinner from "../../../../FullScreenSpinner";
import AccommodationsTable from "../../../../elements/accommodations/AccommodationsTable";
import TextFieldsTable from "../../../../elements/textfields/TextfieldsTable";
import DestinationsTable from "../../../../elements/destinations/DestinationsTable";
import ErrorCardView from "../../../../layout/ErrorCardView";
import POIsTable from "../../../../../features/pois/POIsTable";
import LibraryItemModal, { LibraryItem, LibraryItemType, MarkerMapItemType } from "./LibraryItemModal";
import { queryKeyMarkerMap } from "./LibraryItemsTable";
import ActivitiesTable from "./activities/ActivitiesTable";
import { useQueryClient } from "@tanstack/react-query";
import LearningSection from "../../../../learn/LearningSection";
import articleData from "../../../../../assets/data/learnsection/learnArticles.json";

interface LibraryItemsListProps<T extends LibraryItem> {
  emptyResource: T;
  query_key: string;
  api: any;
  eventType: LibraryItemType;
  initialFilters?: Array<any>;
}

function LibraryItemsList<T extends LibraryItem>({
  eventType,
  emptyResource,
  api,
  query_key,
  ...props
}: LibraryItemsListProps<T>) {
  const disclosure = useDisclosure();
  const [h, setH] = React.useState<{
    libraryItem: T;
    type: "Edit" | "Create";
  }>();
  const [focusCoordinates, setFocusCoordinates] = React.useState<number[]>([]);
  const { data: members, isLoading: isLoadingMembers, error: errorMembers } = useMyTeam();

  //Query key for maps
  const map_query_key = queryKeyMarkerMap[eventType as MarkerMapItemType];
  const queryClient = useQueryClient();

  // const { mutateAsync: deletelibraryItem, isLoading: isDeleting } = useMutation(
  //   (id: string) => api.delete(id),
  //   {
  //     onSuccess: () => {
  //       // Better handle this, can't you just add the current message to the cache?
  //       queryClient.invalidateQueries(${eventType}S_QUERY_KEY);
  //       // Make sure the popover is closed once the POI is removed.
  //       // closePopover();
  //     },
  //   }
  // );

  const { mutate: deleteEntity } = useMutation(
    {
      apiCall: (id: string) => api.delete(id),
      successMessage: `deleted ${eventType}`,
      failMessage: `deleting ${eventType}`,
    },
    [query_key],
    [map_query_key],
  );

  const { mutate: duplicateEntity } = useMutation(
    {
      apiCall: (id: string) => api.duplicate(id),
      successMessage: `duplicated ${eventType}`,
      failMessage: `duplicating ${eventType}`,
    },
    [query_key],
    [map_query_key],
  );

  const { mutate: getlibraryItem, rqMutation } = useMutation({
    apiCall: (id: string) => api.getSingle(id),
    failMessage: `loading ${eventType} information`,
  });

  const editMut = useMutation(
    {
      apiCall: (libraryItem: T) => api.patch(libraryItem.id!, libraryItem),
      successMessage: `updated ${eventType}`,
      failMessage: `updating ${eventType}`,
    },
    [query_key],
    [map_query_key],
  );

  const createMut = useMutation(
    {
      apiCall: (libraryItem: T) => api.post(libraryItem),
      successMessage: `created ${eventType}`,
      failMessage: `creating ${eventType}`,
      successCallback: (data: any) => {
        setFocusCoordinates(data.location?.coordinates ?? []);
        queryClient.invalidateQueries({ queryKey: [query_key] });
      },
    },
    [query_key],
    [map_query_key],
  );

  // const handleDeletelibraryItem = async (id: string) => {
  //   await handleSubmission({
  //     successMessage: `deleted ${eventType}`,
  //     failMessage: `deleting ${eventType}`,
  //     apiCall: deletelibraryItem(id),
  //     toast,
  //   });
  // };

  const onEdit = React.useCallback(
    async (libraryItemId: string) => {
      disclosure.onOpen();
      const libraryItem = await getlibraryItem(libraryItemId);
      if (libraryItem) {
        setH({ libraryItem, type: "Edit" });
        setFocusCoordinates(libraryItem.location?.coordinates ?? []);
      }
    },
    [getlibraryItem, setH, disclosure],
  );

  const onDelete = React.useCallback(
    async (libraryItemId: string) => {
      await deleteEntity(libraryItemId);
      disclosure.onClose();
    },
    [deleteEntity, disclosure],
  );

  const extraColumns: Column<T>[] = React.useMemo(
    () => [
      {
        id: "libraryItem-options",
        isNumeric: true,
        isTruncated: false,
        // minWidth: 60,
        // chakraMaxWidth: "60px",
        // width: 60,
        chakraWidth: "60px",
        Cell: (cell: CellProps<T>) => {
          return (
            <OptionsDropdown>
              {/* TODO: load all libraryItem data */}
              <OptionsDropdownItem
                icon={<EditIcon boxSize={3} />}
                onClick={async () => onEdit(cell.row.original.id!)}
                name={`Edit ${eventType}...`}
              />
              <OptionsDropdownItem
                icon={<FaCopy boxSize={3} />}
                onClick={async () => await duplicateEntity(cell.row.original.id!)}
                name={`Duplicate ${eventType}...`}
              />
              <DeleteOptionsDropdownItem
                onClick={async () => await deleteEntity(cell.row.original.id!)}
                resourceName={eventType}
              />
            </OptionsDropdown>
          );
        },
      },
    ],
    [deleteEntity, onEdit],
  );

  const addlibraryItem = (item?: T) => {
    // Allow for a default item to be passed in (used in maps to pass a location with a new object)
    const data = item ?? emptyResource;
    setH({ libraryItem: data, type: "Create" });
    setFocusCoordinates(data.location?.coordinates ?? []);
    disclosure.onOpen();
  };

  const rowProps = ({ row }: { row: Row<any> }) => ({
    onClick: () => onEdit(row.original.id!),
    _hover: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  });

  const TableTypeMap: { [key in string]: any } = {
    ["accommodation"]: AccommodationsTable,
    ["activity"]: ActivitiesTable,
    ["textfield"]: TextFieldsTable,
    ["destination"]: DestinationsTable,
    ["poi"]: POIsTable,
  };

  const TableType = TableTypeMap[eventType];

  if (isLoadingMembers) return <FullScreenSpinner />;
  if (errorMembers || !members) return <ErrorCardView title="Could not load company members" />;

  return (
    <>
      <Box w="100%" maxW="1000px" mx="auto" mb={6}>
        <TableType
          {...props}
          additionalColumns={extraColumns as any}
          handleAdd={addlibraryItem}
          handleEdit={onEdit}
          getRowProps={rowProps}
          users={members}
          focusCoordinates={focusCoordinates}
        />
        <LearningSection learnData={articleData[eventType + "Articles"]} randomiseArticles={false} />
      </Box>
      {disclosure.isOpen && (
        <LibraryItemModal
          disclosure={disclosure}
          mut={h && h?.type === "Edit" ? editMut : createMut}
          libraryItem={h?.libraryItem}
          updateLibraryItem={(item: any) => setH({ ...h, libraryItem: item })}
          eventType={eventType}
          type={h?.type ?? "Create"}
          onDelete={onDelete}
        />
      )}
    </>
  );
}
export default React.memo(LibraryItemsList);
