import { Box, Step, StepIndicator, StepSeparator, StepTitle, Stepper } from "@chakra-ui/react";
import { TripDay, Trip, capitalizeFirstLetter } from "@lato/common";

export interface DayScrollerVerticalCardProps {
  index: number;
  selectedIndex: number;
  elements: TripDay["events"] | TripDay["transportations"] | TripDay["hotels"];
  element: Event | Transportation | Hotel;
  onElementClick: (elementId: string) => void;
  realLanguageIndex: number;
  hotelSelected: boolean;
  amountOfHotels: number;
}

export const DayScrollerStep: React.FC<DayScrollerVerticalCardProps> = ({
  index,
  selectedIndex,
  elements,
  element,
  onElementClick,
  realLanguageIndex,
  hotelSelected,
  amountOfHotels,
}) => {
  const isHotel = !element.type && !("endTime" in element);
  const isBeforeCurrentIndex = elements.findIndex((e) => !e.type && !("endTime" in e)) < index;

  if (isHotel && isBeforeCurrentIndex) {
    return null;
  }

  const isSelected = selectedIndex === index || (hotelSelected && isHotel);

  const getTitle = (): string => {
    if (element.type) {
      return capitalizeFirstLetter(element.type);
    }
    if ("endTime" in element) {
      return element.titles?.[realLanguageIndex]?.content || "Activity";
    }
    return amountOfHotels === 1 ? element.name || "Accommodation" : `Accommodations (${amountOfHotels})`;
  };

  return (
    <Step onClick={() => onElementClick(element.id)} key={element.id}>
      <StepIndicator
        h={2}
        w={2}
        mb={-3}
        ml={0.5}
        background={isSelected ? "brand.300 !important" : "lightGray !important"}
        borderColor={isSelected ? "brand.300 !important" : "lightGray !important"}
        color={isSelected ? "brand.500 !important" : "lightGray !important"}
      />
      <Box flexShrink={0} mt="-0.4em" h="35px">
        <StepTitle
          className="w-[150px] truncate"
          color={isSelected ? "brand.500" : "gray.400"}
          fontWeight={isSelected ? 600 : 500}
        >
          {getTitle()}
        </StepTitle>
      </Box>
      <StepSeparator ml={-2.5} mt={-6} minH={3.5} rounded="full" background="lightGray.300 !important" />
    </Step>
  );
};
