import { Language, User } from "@lato/common";
import { checkOwnTranslation } from "../components/trips/edit/daybyday/LanguagesInput";
import { PartialTranslationField } from "../stores/trip-language-context";

export const getDefaultSelectedIndex = (
  doubleFields: boolean,
  first: boolean,
  tfs: PartialTranslationField[],
  me: User,
) => {
  const showType = getShowType(doubleFields, first);
  const translatedLanguages = getAlreadyTranslatedLanguages(showType, me, tfs);
  const defaultSelectedLanguageIndex = getDefaultSelectedIndexHelper(translatedLanguages, me);
  return defaultSelectedLanguageIndex;
};

// Get the index inside the translation fields of the default language. If the default language is not yet translated, return 0.
const getDefaultSelectedIndexHelper = (langs: Language[], user: User) =>
  Math.max(0, langs?.map((lang) => lang?.code).indexOf(user.defaultTFLang.code));

type ShowType = "showAll" | "showOthers" | "showOwn";
export const getShowType = (doubleFields: boolean, firstInput: boolean): ShowType => {
  const showType = !doubleFields ? (firstInput ? "showOwn" : "showAll") : firstInput ? "showOthers" : "showOwn";
  // const showType = !doubleFields
  //   ? "showAll"
  //   : firstInput
  //   ? "showOthers"
  //   : "showOwn";
  return showType;
};

export const getAlreadyTranslatedLanguages = (
  showType: ShowType,
  user: User,
  translationFields: PartialTranslationField[],
) => {
  // Show own when:
  // 1. 1 usertrip -> not first
  // 2. 2 usertrips and the second does not have translations -> first and
  const translatedLanguages = translationFields
    ?.filter((t) =>
      showType === "showOwn"
        ? checkOwnTranslation(t.creator, user)
        : showType === "showOthers"
          ? !checkOwnTranslation(t.creator, user)
          : true,
    )
    .map((t) => t.language);
  return translatedLanguages;
};
