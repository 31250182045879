import { RefObject, useCallback, useEffect } from "react";

interface UseOutsideClickProps<T extends HTMLElement = HTMLElement> {
  /**
   * Whether the hook is enabled
   */
  enabled?: boolean;
  /**
   * Execute the handler function when a click is triggered outside the 'refs' elements.
   */
  refs?: RefObject<T>[];
  /**
   * Execute the handler function when a click is triggered outside the 'selectors' elements.
   */
  selectors?: string[];
  /**
   * Function invoked when a click is triggered outside the referenced element.
   * This function can be either an external function or wrapped in `useCallback`
   * to avoid unnecessary re-renders and re-attaching of event listeners.
   */
  handler: (e: Event) => void;
}

function useOnClickOutside<T extends HTMLElement = HTMLElement>({
  refs,
  selectors,
  enabled = true,
  handler,
}: UseOutsideClickProps<T>): void {
  const listener = useCallback(
    (event: Event) => {
      // 'true' if the click happened inside one of the 'selectors' elements, else 'false'
      const selecterElements =
        !!selectors &&
        selectors.length > 0 &&
        selectors.flatMap((selector) => Array.from(document.querySelectorAll(selector)));
      const insideAtLeastOneSelector =
        selecterElements &&
        selecterElements.length > 0 &&
        selecterElements.some((element) => element.contains(event.target as Node));
      // 'true' if the click happened inside one of the 'refs' elements, else 'false'
      const insideAtLeastOneRef =
        !!refs && refs.length > 0 && refs.some((ref) => ref.current && ref.current.contains(event.target as Node));
      // Do nothing if the click happened in one of the ref elements
      if (!insideAtLeastOneSelector && !insideAtLeastOneRef) {
        // Don't trigger the handler if the click happened in one of the 'selectors' elements or 'refs' elements.
        // So, only if both booleans are false, we can execute the handler.
        handler(event);
      }
    },
    [refs, handler],
  );

  useEffect(
    () => {
      if (!enabled) return;
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [refs, enabled, listener],
  );
}
export default useOnClickOutside;
