import { LoyaltyProgram } from "@lato/common";
import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "loyalty-programs";

// Get the standard CRUD operations

const getAll = () => {
  return rawApiProvider.get(url);
};

type LoyaltyProgramsAPIType = {
  getAll(): Promise<LoyaltyProgram[]>;
};
const LoyaltyProgramsAPI: LoyaltyProgramsAPIType = {
  getAll,
};
export default LoyaltyProgramsAPI;
