import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "openai";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

// Create a trip from a PDF file
const createTripFromPDF = (model: object) => {
  return rawApiProvider.post(url + `/parse`, model);
};

type ChatGptAPIType = ReturnEndpoints & {
  askQuestion(model: object): Promise<any>;
  createTripFromPDF(model: object): Promise<any>;
};
const ChatGptAPI: ChatGptAPIType = {
  ...apiEndpoints,
  askQuestion: apiEndpoints.post,
  createTripFromPDF,
};
export default ChatGptAPI;
