import { BoxProps, Icon, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import { BsCursorText } from "react-icons/bs";
import OptionsDropdown, { OptionsDropdownItem } from "../../CRUD/OptionsDropdown";
import CustomModal from "../../layout/CustomModal";
import SaveToLibraryOption from "../../trips/edit/daybyday/library-items/hotel/SaveToLibraryOption";
import InputWrapper from "../InputWrapper";
import RichTextEditor2 from "./RichTextEditor2";
import ImportTextfield from "../../elements/textfields/ImportTextfield";
import { useTripLanguage } from "../../../stores/trip-language-context";
import { useMeContext } from "../../../stores/me-context";

type RHFRichTextEditor2Props = BoxProps & {
  name: string;
  label?: React.ReactNode;
  showLoadText?: boolean;
  id?: string;
  getTranslationsHook?: any;
  transformToTF?: boolean;
  isInline?: boolean;
};

const RHFRichTextEditor2: React.FC<RHFRichTextEditor2Props> = ({
  name,
  label,
  showLoadText = true,
  getTranslationsHook,
  transformToTF = false,
  isInline,
  ...props
}) => {
  const { getValues } = useFormContext();
  const { realLanguageIndex, enabled: hasLanguageEnabled } = useTripLanguage();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const [forceRerender, toggleForceRerender] = React.useState(false);

  const me = useMeContext();

  const fieldObjectName = name.split(".").slice(0, -2).join(".");

  const { field: controllerField } = useController({ name });

  const translatedLanguages = (getValues(fieldObjectName) ?? []).map((t: any) => t?.language_code);

  const defaultValue = getValues(name);

  const getTextValue = React.useCallback(() => {
    if (transformToTF)
      return [
        {
          content: getValues(name),
          language: me.defaultTFLang,
          language_code: me.defaultTFLang.code,
          creator: me,
        },
      ];
    return getValues(name.split(".").slice(0, -2).join("."));
  }, [name]);

  return (
    <InputWrapper
      key={`inputwrapper-${forceRerender}`}
      name={name}
      label={label}
      isInline={isInline}
      headerButton={
        showLoadText ? (
          <OptionsDropdown>
            <OptionsDropdownItem icon={<Icon as={BsCursorText} />} onClick={onOpen} name="Load Text" />
            <SaveToLibraryOption type={"textfield"} getValues={getTextValue} />
          </OptionsDropdown>
        ) : undefined
      }
    >
      {/* <DevTool control={control} /> */}
      <RichTextEditor2
        onChange={controllerField.onChange}
        defaultValue={defaultValue}
        selectedLanguage={hasLanguageEnabled ? (translatedLanguages[realLanguageIndex] ?? me.defaultTFLang.code) : null}
        isInline={isInline}
        {...props}
      />
      <CustomModal title="Import textfield from library" onClose={onClose} isOpen={isOpen} size="3xl">
        <ImportTextfield
          fieldObjectName={fieldObjectName}
          toggleForceRerender={toggleForceRerender}
          me={me}
          transformToTF={transformToTF}
          onClose={onClose}
          name={name}
        />
      </CustomModal>
    </InputWrapper>
  );
};

export default React.memo(RHFRichTextEditor2);
