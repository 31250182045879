import { Box, Flex, Skeleton, SkeletonCircle, Spinner, SpinnerProps, Stack } from "@chakra-ui/react";
import React from "react";
import FullScreen from "./FullScreen";
import Section from "./layout/Section";
import Quotes from "./Quotes";
import "../styles/quote.css";

interface FullScreenSpinnerProps extends SpinnerProps {
  skeleton?: boolean;
  card?: boolean;
  h?: number;
  amount?: number;
}

export const CustomSpinner: React.FC<FullScreenSpinnerProps> = ({ skeleton, card, h, ...props }) => {
  if (skeleton) {
    if (card) {
      return (
        <Section pos="relative" className="group" height={"100%"} noDivider>
          <Stack
            w={"100%"}
            h={`${h}`}
            minH={(h ?? 100) < 100 ? "0" : "10em"}
            //padding={(h ?? 100) < 100 ? 1 : 6}
            spacing={1}
          >
            <Skeleton height={(h ?? 100) <= 50 ? "20px" : "40px"} width="30%" />
            <Box height="20px" />
            {(h ?? 100) >= 10 && <Skeleton height="30px" />}
            {(h ?? 100) >= 50 && <Skeleton height="30px" />}
            {(h ?? 100) >= 70 && <Skeleton height="30px" />}
          </Stack>
        </Section>
      );
    }
    return (
      <Stack
        w={"100%"}
        h={`${h}`}
        minH={(h ?? 100) < 100 ? "0" : "10em"}
        padding={(h ?? 100) < 100 ? 1 : 6}
        spacing={1}
      >
        <Skeleton height={(h ?? 100) <= 50 ? "20px" : "40px"} width="50%" />
        <Box height="20px" />
        {(h ?? 100) >= 10 && <Skeleton height="20px" />}
        {(h ?? 100) >= 50 && <Skeleton height="20px" />}
        {(h ?? 100) >= 70 && <Skeleton height="20px" />}
      </Stack>
    );
  }

  return (
    <Flex w={"100%"} h={"100%"} minH={"10em"} justifyContent={"center"} alignItems={"center"}>
      <Spinner color="brand.500" size="xl" {...props} />
    </Flex>
  );
};

export const OverviewSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Section pos="relative" className="group" height={"100%"} mb={3} mt={2} noDivider>
      <Flex>
        <Stack w={"50%"} minH={"10em"} padding={6} spacing={1}>
          <Skeleton height="30px" mb={6} width="50%" />
          <Stack gap={5}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Skeleton key={index} height="30px" />
            ))}
          </Stack>
        </Stack>
        <Skeleton w={"50%"} />
      </Flex>
    </Section>
  );
};

export const TripdayButtonsSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Flex gap={4} justifyContent={"center"} alignItems={"center"}>
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton key={index} height="120px" w="120px" borderRadius={"10px"} />
      ))}
    </Flex>
  );
};

export const GeneralSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Section pos="relative" className="group" height={"100%"} noDivider w="75%" mt={10}>
      <Skeleton h={"40px"} mb={2} />
      <Flex>
        <Stack w={"50%"} spacing={1} mr={2}>
          <Stack gap={3}>
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} height="50px" />
            ))}
          </Stack>
        </Stack>
        <Skeleton w={"50%"} />
      </Flex>
    </Section>
  );
};

export const ShareSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Stack m={12}>
      <Section pos="relative" className="group" height={"100%"} mb={3} mt={0} noDivider>
        <Skeleton height="20px" m={3} w="200px" />
        <Skeleton height="50px" m={3} />
      </Section>
      <Flex gap={6}>
        <Section pos="relative" className="group" height={"100%"} w="50%" mb={3} mt={0} noDivider>
          <Stack minH={"10em"} spacing={1}>
            <Skeleton m={3} height="30px" width="50%" />
            <Stack gap={2}>
              {Array.from({ length: 6 }).map((_, index) => (
                <Skeleton ml={3} key={index} height="50px" />
              ))}
            </Stack>
          </Stack>
        </Section>
        <Section pos="relative" className="group" height={"100%"} w="50%" mb={3} mt={0} noDivider>
          <Stack minH={"10em"} spacing={1}>
            <Skeleton m={3} height="30px" width="50%" />
            <Stack gap={2}>
              {Array.from({ length: 6 }).map((_, index) => (
                <Skeleton ml={3} key={index} height="50px" />
              ))}
            </Stack>
          </Stack>
        </Section>
      </Flex>
    </Stack>
  );
};

export const ElementSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <div className="h-[8rem] my-2 p-4 bg-white border border-gray-100 rounded-md">
      <Flex w={"100%"} gap={2}>
        <Skeleton height={"6rem"} mb={2} width={"6rem"} />
        <Stack gap={2}>
          <Skeleton height="30px" width="200px" />
          <Skeleton height="20px" width="300px" />
          <Skeleton height="20px" width="300px" />
        </Stack>
      </Flex>
    </div>
  );
};

export const WizardSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Flex mt={10} h={"90px"} alignItems={"start"}>
      <Stack w={"33%"} gap={2}>
        <Stack gap={3}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} h="15px" w="50%" />
          ))}
        </Stack>
      </Stack>
      <Flex w={"33%"} gap={"3em"} mt={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Stack key={index} justify={"center"}>
            <SkeletonCircle mx="auto" h={"30px"} w={"30px"} />
            <Skeleton h="10px" w="40px" />
          </Stack>
        ))}
      </Flex>
      <Flex w="33%" gap={4} justify={"end"}></Flex>
    </Flex>
  );
};

export const PreviewSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return <Skeleton h="350px" w="64"></Skeleton>;
};

export const MailPreviewSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Flex mt={4} h={"350px"}>
      <Skeleton h="350px" w="100%"></Skeleton>
    </Flex>
  );
};

export const ListSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Stack>
      <Flex justifyContent={"space-between"}>
        <Skeleton h="40px" w="200px" />
        <Skeleton h="40px" w="100px" />
      </Flex>
      <Section noDivider>
        <Stack>
          <Skeleton h="40px" w="100%" />
          <Flex gap={2} mb={4}>
            {Array.from({ length: 6 }).map((_, index) => (
              <Skeleton key={index} h="40px" w="80px" />
            ))}
          </Flex>
          {Array.from({ length: 6 }).map((_, index) => (
            <Skeleton key={index} h="40px" w="100%" />
          ))}
        </Stack>
      </Section>
    </Stack>
  );
};

export const StatsSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Stack gap={4} w={"100%"} h="24em">
      <Skeleton w="100%" h="30%" />
      <Skeleton w="100%" h="30%" />
      <Skeleton w="100%" h="30%" />
    </Stack>
  );
};

export const ContactSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Stack mt={12} maxW={"5xl"} mx="auto">
      <Flex gap={4}>
        <Skeleton h="200px" w="50%" />
        <Skeleton h="200px" w="50%" />
      </Flex>
      <Skeleton h="100px" w="100%" />
      <Skeleton h="200px" w="100%" />
    </Stack>
  );
};

export const SettingsSkeleton: React.FC<FullScreenSpinnerProps> = ({ amount }) => {
  return (
    <Stack m={6}>
      {Array.from({ length: amount ?? 6 }).map((_, index) => (
        <Skeleton key={index} h="40px" w="100%" />
      ))}
    </Stack>
  );
};

export const FeaturedTripsSkeleton: React.FC<FullScreenSpinnerProps> = ({ amount }) => {
  return (
    <Stack mt={16}>
      <Skeleton h="30px" w="200px" />
      <Flex gap={4}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton key={index} h="215px" w="215px" />
        ))}
      </Flex>
    </Stack>
  );
};

const FullScreenSpinner: React.FC<FullScreenSpinnerProps> = (props) => {
  const quote = (window as any).startupQuote;
  return (
    // <FullScreen>
    //   <p id="quote" style={{ fontSize: "1.3em", fontWeight: 600, color: "#243543" }}>
    //     {quote.quote}
    //   </p>
    //   <CustomSpinner m="auto" {...props} />
    // </FullScreen>
    <div className="quote">
      <p>{quote}</p>
    </div>
  );
};
export default React.memo(FullScreenSpinner);

export const NewWizardSkeleton: React.FC<FullScreenSpinnerProps> = ({ ...props }) => {
  return (
    <Flex flexDir={"column"} justifyContent={"space-between"} h="92vh" w="20%" pr={4} pt={10}>
      <Skeleton w="100%" h="100px" />
      <Skeleton w="100%" h="200px" />
    </Flex>
  );
};
