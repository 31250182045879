import { Box, Button, Flex, Heading, SimpleGrid, Text, useDisclosure, useNumberInput } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import StripeAPI from "../../../api/stripe.api";
import { grantPermission } from "../../../utils/grantPermission";
import { useCustomQuery } from "../../../utils/query-helpers/reactQueryHooks";
import Section from "../../layout/Section";
import PlanCard, { calculateMonthlyPrice, plans } from "./PlanCard";
import { Subscription } from "./checkout/Checkout";
import { Role, capitalizeFirstLetter, Plan } from "@lato/common";
import { useMeContext } from "../../../stores/me-context";
import UpdatePreviewModal from "./UpdatePreviewModal";
import "./billing.css";
interface PlansProps {
  currentSubscription: Subscription;
  setSubscription: (newSubscription: Subscription) => Promise<void>;
  payingPlan: boolean;
}

const useStripePlans = () => useCustomQuery<any[]>(["plans"], async () => await StripeAPI.getAllPlans());

const Plans: React.FC<PlansProps> = ({ currentSubscription, setSubscription, payingPlan }) => {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const [newSubscription, setNewSubscription] = React.useState<Subscription>(currentSubscription);
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    defaultValue: currentSubscription.users,
    min: 1,
    onChange: (_valueAsString, valueAsNumber) => setNewSubscription((old) => ({ ...old, users: valueAsNumber })),
  });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  // const {
  //   data: plans,
  //   isLoading: isLoadingPlans,
  //   error: errorLoadingPlans,
  // } = useStripePlans();

  const newPrice = calculateMonthlyPrice(newSubscription);
  const currentPrice = calculateMonthlyPrice(currentSubscription);
  const isUpgrading = newPrice > currentPrice;
  const changeInterval = newSubscription.yearly !== currentSubscription.yearly;
  const newPriceId = newSubscription.yearly
    ? plans[newSubscription.plan].yearly.stripeId
    : plans[newSubscription.plan].quarterly.stripeId;

  const { mutateAsync: switchPlan, isPending: isLoadingCheckout } = useMutation({
    mutationFn: (priceId: string) =>
      // SubscriptionsAPI.switchPlan({
      //   plan,
      // }),
      StripeAPI.createCheckoutSession({
        priceId,
        nrOfUsers: newSubscription.users,
      }),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      // queryClient.invalidateQueries(ME_QUERY_KEY);
    },
  });

  const user = useMeContext();

  const permissionToSwitchPlan = grantPermission(user.role, [Role.ADMIN]);

  const handleSwitchTo = async () => {
    if (!permissionToSwitchPlan) {
      alertNotAdmin();
      return;
    }
    if (payingPlan) {
      // Open the preview proration modal
      onOpen();
      return;
    }
    // onCheckout(
    //   yearly ? plans[plan].yearly.stripeId : plans[plan].quarterly.stripeId
    // );
    // If payment succeeded Switch plan
    const res = await switchPlan(newPriceId);
    window.location.href = res.url;
  };
  const changeSelectedPlan = (plan: Plan) => {
    setNewSubscription((old) => ({ ...old, plan }));
  };

  return (
    <Section title="Choose plan" h="auto">
      <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={3} py={2}>
        {/* <PlanCard
          plan={Plan.FREE}
          currentPlan={currentPlan}
          yearly={yearly}
          extraUsers={extraUsers}
        /> */}
        <PlanCard
          plan={Plan.LITE}
          selectedSubscription={newSubscription}
          changeSelectedPlan={changeSelectedPlan}
          chooseSubscription={setSubscription}
        />
        <PlanCard
          plan={Plan.PRO}
          selectedSubscription={newSubscription}
          chooseSubscription={setSubscription}
          changeSelectedPlan={changeSelectedPlan}
        />
        {/* {plans.map((_, i) => (
          <PlanCard
            key={`plan-${i}`}
            plan={Plan.LITE}
            currentPlan={currentPlan}
          />
        ))} */}
      </SimpleGrid>
      <Flex justifyContent={"center"} alignItems="center" flexDir={{ base: "column", md: "row" }}>
        <Flex alignItems={"center"} justifyContent="center" flexDir="column" mr={{ base: "0", lg: "5" }}>
          <div className="pricing-mode-container">
            <label className="pricing-mode-switch">
              <input
                onChange={() => {
                  setNewSubscription((old) => ({
                    ...old,
                    yearly: !old.yearly,
                  }));
                }}
                className="pricing-mode-checkbox"
                type="checkbox"
                checked={newSubscription.yearly}
              />
              <span className="pricing-mode-slider"></span>
              <div className="pricing-mode">
                <p className="pricing-monthly">Quarterly</p>
                <p className="pricing-yearly">Yearly (-15%)</p>
              </div>
            </label>
          </div>
          <div className="pricing-user-container">
            <p>users</p>
            <div className="pricing-user">
              <button {...dec} className="decrease-user">
                -
              </button>
              <input
                // ref={userValueRef}
                className="number-of-user"
                type="number"
                min="1"
                // value={userValue}
                {...input}
                placeholder="Users"
                // onChange={inputUser}
              />
              <button {...inc} className="increase-user">
                +
              </button>
            </div>
          </div>
          {/* <FormControl
            display="flex"
            alignItems="center"
            w="fit-content"
            mb={3}
          >
            <FormLabel htmlFor="yearly">Quarterly</FormLabel>
            <Switch
              id="yearly"
              colorScheme="brand"
              isChecked={newSubscription.yearly}
              onChange={() =>
                setNewSubscription((old) => ({ ...old, yearly: !old.yearly }))
              }
            />
            <FormLabel htmlFor="yearly" mb="0" ml={2} whiteSpace="nowrap">
              Yearly (-15%)
            </FormLabel>
          </FormControl> */}
          {/* <FormControl display="flex" alignItems="center" w="fit-content">
            <FormLabel htmlFor="extra-users" mb="0">
              Users
            </FormLabel>
            <HStack maxW="120px">
              <Button {...dec} colorScheme={"brand"} size="sm">
                -
              </Button>
              <Input {...input} />
              <Button {...inc} colorScheme={"brand"} size="sm">
                +
              </Button>
            </HStack>
          </FormControl> */}
        </Flex>
        <Box ml={5} mt={{ base: "5", sm: "0" }} textAlign={{ base: "center", sm: "left" }}>
          <Heading>Summary</Heading>
          <Text fontWeight={600}>{capitalizeFirstLetter(newSubscription.plan)} plan</Text>
          <Text>
            {newSubscription.users} user{newSubscription.users === 1 ? "" : "s"}
          </Text>
          <Text>
            Total: €{newSubscription.yearly ? newPrice * 12 : newPrice * 3}/
            {newSubscription.yearly ? "year" : "quarter"} excl. TAX
          </Text>
          <Button
            mt={3}
            colorScheme={"brand"}
            size="md"
            isDisabled={
              newSubscription.plan === Plan.FREE ||
              (payingPlan && JSON.stringify(currentSubscription) === JSON.stringify(newSubscription))
            }
            isLoading={isLoadingCheckout}
            onClick={handleSwitchTo}
            // onClick={() =>
            //   chooseSubscription({ plan: plan, users: extraUsers, yearly })
            // }
          >
            Review purchase
          </Button>
        </Box>
      </Flex>
      {payingPlan && (
        <UpdatePreviewModal
          isOpen={isOpen}
          onClose={onClose}
          subscription={newSubscription}
          proration={isUpgrading && !changeInterval}
          newPriceId={newPriceId}
          newPrice={newPrice}
          oldSubscription={currentSubscription}
        />
      )}
    </Section>
  );
};
export default React.memo(Plans);

export const alertNotAdmin = () => {
  alert("You don't have enough permissions to update the subscription plan, please ask your admin.");
};
