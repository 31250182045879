import { Button, Flex, Icon, IconButton, Select, Text } from "@chakra-ui/react";
import {
  HiMiniChevronDoubleLeft,
  HiMiniChevronDoubleRight,
  HiMiniChevronLeft,
  HiMiniChevronRight,
} from "react-icons/hi2";

import { TableInstance } from "react-table";

interface PaginationProps {
  tableMethods: TableInstance<any> | any;
  pageSize?: number;
  totalCount?: number;
  onlyButtons?: boolean;
}

const Pagination = ({ tableMethods, pageSize, totalCount, onlyButtons = false }: PaginationProps) => {
  const { state } = tableMethods;

  return (
    <>
      {/* Only display the pagination section when a pageSize is given and when there are records to show */}
      {pageSize && totalCount && totalCount > 0 ? (
        <Flex
          color="realGray.500"
          alignItems="center"
          justifyContent="space-between"
          // Equal to the padding in the Section component
          px={7}
        >
          {!onlyButtons && (
            <Flex alignItems="center">
              <Text fontSize="sm">Per page&nbsp;</Text>
              <Select
                color="black"
                w="fit-content"
                size="sm"
                variant="outline"
                cursor={"pointer"}
                value={pageSize}
                onChange={(e) => {
                  tableMethods.setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
          {!onlyButtons && (
            <Text fontSize="sm">
              {state.pageIndex * state.pageSize + 1} - {state.pageIndex * state.pageSize + tableMethods.page.length} of{" "}
              {totalCount} items
            </Text>
          )}
          <Flex alignItems="center" gap={1} ml={onlyButtons ? "auto" : undefined}>
            <IconButton
              aria-label={"first-page-button"}
              variant="outline"
              colorScheme="gray"
              onClick={() => tableMethods.gotoPage(0)}
              isDisabled={!tableMethods.canPreviousPage}
              icon={<Icon as={HiMiniChevronDoubleLeft} boxSize={4} />}
            />

            <IconButton
              aria-label={"previous-page-button"}
              variant="outline"
              colorScheme="gray"
              onClick={() => tableMethods.previousPage()}
              isDisabled={!tableMethods.canPreviousPage}
              icon={<Icon as={HiMiniChevronLeft} boxSize={4} />}
            />

            <Flex alignItems="center">
              <Text fontSize="sm">
                Page {state.pageIndex + 1} of {tableMethods.pageOptions?.length ?? tableMethods.pages}
              </Text>
              {/* <Input
            maxW="35px"
            size="sm"
            type="number"
            defaultValue={pageIndex + 1}
            onBlur={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              tableMethods.gotoPage(page);
            }}
            style={{ width: "100px" }}
          /> */}
            </Flex>
            <IconButton
              onClick={() => tableMethods.nextPage()}
              isDisabled={!tableMethods.canNextPage}
              variant="outline"
              colorScheme="gray"
              aria-label={"next-page-button"}
              icon={<Icon as={HiMiniChevronRight} boxSize={4} />}
            />
            <IconButton
              aria-label={"last-page-button"}
              variant="outline"
              colorScheme="gray"
              onClick={() => tableMethods.gotoPage(Math.ceil(totalCount / pageSize) - 1)}
              isDisabled={!tableMethods.canNextPage}
              icon={<Icon as={HiMiniChevronDoubleRight} boxSize={4} />}
            />
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default Pagination;
