import Input from "./input";
import { mergeWith as merge } from "@chakra-ui/utils";

const sizes = merge({}, Input.sizes, {
  xs: {
    icon: {
      insetEnd: "0.25rem",
    },
  },
});

export default {
  sizes,
  defaultProps: Input.defaultProps,
  variants: Input.variants,
};
