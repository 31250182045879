import React from "react";
import { BsPinAngleFill } from "react-icons/bs";
import { Tooltip } from "react-leaflet";
import { CustomLeafletPinMarker } from "./CustomLeafletPinMarker";

export interface MarkerProps {
  name?: string | null;
  coordinates: number[] | null;
  children?: React.ReactNode;
  openPopup?: boolean;
  layout?: MarkerLayout;
  onClick?: () => void;
}

export type MarkerLayout = {
  icon: React.ElementType;
  bg: string;
  width?: string;
};

// Generic marker component to easier handle custom markers for maps
export const IconMarker: React.FC<MarkerProps> = ({
  coordinates,
  children,
  openPopup,
  onClick,
  layout = { icon: BsPinAngleFill, bg: "rgb(94, 56, 110)" },
  name,
}) => {
  return (
    <CustomLeafletPinMarker
      coordinates={coordinates}
      pinColor={layout.bg}
      openPopup={openPopup}
      onClick={onClick}
      nestedIcon={
        <layout.icon style={{ width: layout.width ?? "100%", height: "100%" }} width={layout ?? "100%"} height="100%" />
      }
    >
      {name && (
        <Tooltip key={`poi-tooltip`} direction="top" offset={[0, -35]}>
          <span className="font-bold">{name}</span>
        </Tooltip>
      )}
      {children}
    </CustomLeafletPinMarker>
  );
};
