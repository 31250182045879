const LOCAL_STORAGE_DELETE_BEFORE_DATE = new Date("2024-04-02T08:00:53.745Z");

export const STORAGE_KEY = (listName: any, id: any, ENV: any) => {
  return `filter-${listName}-${id}-${ENV}`;
};

export const validateLocalStorage = (key: string) => {
  const localStorageData = localStorage.getItem(key);
  if (localStorageData) {
    try {
      const parsedData = JSON.parse(localStorageData);
      // Check if parsedData matches the expected structure
      if (parsedData && typeof parsedData === "object" && parsedData.updated) {
        // Check if updated date is older than a specified date, if yes, delete the local storage
        const specifiedDate = LOCAL_STORAGE_DELETE_BEFORE_DATE;
        const lastUpdated = new Date(parsedData.updated);
        if (lastUpdated < specifiedDate) {
          localStorage.removeItem(key);
          console.log("Local storage deleted due to outdated data.");
        }
      } else {
        localStorage.removeItem(key);
        console.log("Local storage deleted due to invalid structure.");
      }
    } catch (error) {
      localStorage.removeItem(key);
      console.error("Error parsing local storage data:", error);
    }
  }
};
