import { Alert, AlertDescription } from "@chakra-ui/react";
import React from "react";
import ENV from "../../utils/env";
import { useLatestStrapiAnnouncement } from "../../utils/query-helpers/reactQueryHooks";

interface StrapiAnnouncementAlertProps {}

export type TAnnouncement = {
  title: string;
};

const StrapiAnnouncementAlert: React.FC<StrapiAnnouncementAlertProps> = () => {
  const { data } = useLatestStrapiAnnouncement();
  const [announcement, setAnnouncement] = React.useState<TAnnouncement>();

  React.useEffect(() => {
    if (!data) return;
    setAnnouncement(data);
  }, [data]);

  if (ENV === "staging" || !announcement) return null;
  // Don't show the announcement if the end date is in the past
  // if (announcement.end_datetime < new Date()) return null;

  return (
    <Alert
      status="info"
      variant={"solid"}
      // color="orange.800"
      // bg="rgb(255, 246, 230)"
      // borderWidth="1px"
      // borderColor="orange.200"
      justifyContent={"center"}
    >
      {/* <AlertTitle>{announcement.title}</AlertTitle> */}
      <AlertDescription fontSize="md" lineHeight={1.1}>
        {announcement.title}
      </AlertDescription>
    </Alert>
  );
};
export default React.memo(StrapiAnnouncementAlert);
