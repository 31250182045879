import { Button, Flex, Text } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { CustomSpinner } from "../../components/FullScreenSpinner";
import RHFSelect from "../../components/input/RHFSelect";
import CustomModal from "../../components/layout/CustomModal";
import { TRIPS_USERTRIPS_KEY } from "../../utils/constants";
import { useMyTeam } from "../../utils/query-helpers/reactQueryHooks";
import { usePatchSplitUserTrip } from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import Form from "../../components/form/Form";

interface AssignTripUserModalProps {
  modalDisclosure: any;
  userTripId: string;
  currentUserId: string;
}

const AssignTripUserModal: React.FC<AssignTripUserModalProps> = ({ ...props }) => {
  const { isOpen, onClose } = props.modalDisclosure;

  return (
    <CustomModal
      title={`Assign the trip to another member`}
      onClose={onClose}
      isOpen={isOpen}
      size="sm"
      colorScheme="brand"
    >
      {isOpen && <AssignTripUserModalContent {...props} />}
    </CustomModal>
  );
};
export default React.memo(AssignTripUserModal);

const AssignTripUserModalContent: React.FC<AssignTripUserModalProps> = (props) => {
  const { data: members, isLoading: isLoadingMyTeam } = useMyTeam();

  if (isLoadingMyTeam || !members) return <CustomSpinner m="auto" />;

  return <AssignTripUserForm {...props} members={members} />;
};

const AssignTripUserForm: React.FC<any> = ({ members, userTripId, currentUserId, modalDisclosure }) => {
  const formMethods = useForm<any>({
    shouldUnregister: false,
    defaultValues: {
      userId: currentUserId,
      userTripId,
    },
  });

  const queryClient = useQueryClient();

  const { mutateAsync: updateUserTrip, isPending: isLoadingUserTripUpdate } = usePatchSplitUserTrip(
    userTripId ?? "",
    { relation: "usertrip" },
    queryClient,
    TRIPS_USERTRIPS_KEY,
  );

  const handleSubmit = async () => {
    const { userId } = formMethods.getValues();
    const user = members.find((u: any) => u.id === userId);

    await updateUserTrip({ user });

    modalDisclosure.onClose();
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <Text fontSize="sm" color="realGray.600" mt={2}>
        Choose the user to assign the trip.
      </Text>
      <Flex gap={"2em"} flexDir={"column"} alignItems={"center"}>
        <RHFSelect
          name="userId"
          options={members.map((l: any) => ({
            value: `${l.id}`,
            text: l.name,
          }))}
          parse={false}
        />

        <Button colorScheme="brand" type="submit" w="40%" isLoading={isLoadingUserTripUpdate}>
          Assign to trip
        </Button>
      </Flex>
    </Form>
  );
};
