export const capitalizeFirstLetter = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};
export const convertHTMLToPlainText = (htmlString) => {
    const entities = {
        "&amp;": "&",
        "&lt;": "<",
        "&gt;": ">",
        "&quot;": '"',
        "&apos;": "'",
    };
    const decodedString = htmlString.replace(/&amp;|&lt;|&gt;|&quot;|&apos;/g, (match) => entities[match]);
    const strippedString = decodedString.replace(/<[^>]+>/g, "");
    return strippedString;
};
export const encodeToLettersAndNumbersOnly = (text) => text.toLowerCase().replace(/[^a-z0-9\.]+/g, "-");
