import { Text } from "@chakra-ui/react";
import { TextFieldElement, TranslationField, User } from "@lato/common";
import React from "react";
import { CellProps, Column } from "react-table";
import TextfieldsAPI from "../../../api/textfields.api";
import { useLanguages, useMyTeam } from "../../../utils/query-helpers/reactQueryHooks";
import sanitizeXSS, { removeAllHTMLTags } from "../../../utils/sanitizeXSS";
import LanguageFilter from "../../CRUD/LanguageFilter";
import MemberFilter from "../../CRUD/MemberFilter";
import { ListSkeleton } from "../../FullScreenSpinner";
import ErrorCardView from "../../layout/ErrorCardView";
import { LibraryItemType } from "../../trips/edit/daybyday/library-items/LibraryItemModal";
import LibraryItemsTable from "../../trips/edit/daybyday/library-items/LibraryItemsTable";
import { TableWithDrawerProps } from "./../../CRUD/Resource";

interface TextFieldsTableProps extends Partial<TableWithDrawerProps<any>> {
  additionalColumns?: Column<TextFieldElement>[];
  additionalQueryState?: object;
  initialFilters?: Array<any>;
  minTableHeight?: string;
}
const eventType: LibraryItemType = "textfield";
// Subtext for under the title
const subText = "These text snippets can be seamlessly imported into any text field of your travel itinerary.";

const defaultColumns: Column<TextFieldElement>[] = [
  {
    Header: "Textfield name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "descriptions",
    Cell: ({ value, row, column }) => {
      const content = (value as any).length ? (value as any)[0].content : "";
      return (
        <Text
          title={"description"}
          maxW={"40em"}
          maxH={"4em"}
          whiteSpace={"normal"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          dangerouslySetInnerHTML={{
            __html: sanitizeXSS(removeAllHTMLTags(content)),
          }}
        ></Text>
      );
    },
  },

  // {
  //   accessor: "type",
  //   Header: "Type",
  //   chakraWidth: "20%",
  // },
];

const TextFieldsTable: React.FC<TextFieldsTableProps> = ({ initialFilters, minTableHeight, ...props }) => {
  const { data, isLoading: isLoadingMembers, error: errorMembers } = useMyTeam();
  const { data: allLanguages, isLoading: isLoadingLanguages, error: errorLanguages } = useLanguages();

  const users: User[] = data;
  const [memberFilter, setMemberFilter] = React.useState<string[] | undefined>(
    users?.map((member: User) => member.id!),
  );
  const [languageFilter, setLanguageFilter] = React.useState<string[] | undefined>(
    initialFilters ? initialFilters.find((lang) => lang.id === "languages")?.value : undefined,
  );

  const resetFilters = () => {
    setMemberFilter(users?.map((member: User) => member.id!));
  };

  const columns = React.useMemo(
    () => [
      ...defaultColumns,
      {
        accessor: "users" as any,
        chakraWidth: "0px",
        Filter: (props: any) => <MemberFilter {...props} members={users} setMemberFilter={setMemberFilter} />,
        disableFilters: false,
        hide: true,
      },
      {
        Header: "Languages",
        accessor: "descriptions",
        chakraWidth: "60px",
        id: "languages",
        Cell: ({ value, row, column }: CellProps<TextFieldElement>) => {
          const languages = ((value as TranslationField[]) ?? [])
            .map((l) => l.language)
            .sort((a, b) => (a.name > b.name ? 1 : -1));
          return (
            <Text isTruncated maxW="6em" ml={1} title={languages.map((l) => l.name).join(", ")}>
              {languages.map((language) => language.flag).join(" ")}
            </Text>
          );
        },
        Filter: (props: any) => {
          return (
            <LanguageFilter
              {...props}
              isLoadingLanguages={isLoadingLanguages}
              languages={allLanguages}
              column={{
                ...props.column,
                filterValue: languageFilter,
              }}
            />
          );
        },
        disableFilters: false,
        disableSortBy: true,
      },
    ],
    [memberFilter, initialFilters, allLanguages, isLoadingLanguages],
  );
  if (isLoadingLanguages || isLoadingMembers) return <ListSkeleton />;
  if (errorLanguages || !allLanguages || errorMembers || !users)
    return <ErrorCardView title="Could not load languages" />;

  return (
    <>
      <LibraryItemsTable
        {...props}
        minTableHeight={minTableHeight}
        defaultColumns={columns}
        api={TextfieldsAPI}
        subText={subText}
        eventType={eventType}
        resetFilters={resetFilters}
        useSpaciousLayout
        initialFilters={[{ id: "users", value: memberFilter }, ...(initialFilters ?? [])]}
        additionalQueryState={{
          users: memberFilter,
          languages: languageFilter,
        }}
      />
    </>
  );
};
export default React.memo(TextFieldsTable);
