import config from "../config";
import ENV from "./env";

export const getViewTripLink = (companyName: string, tripTitle: string, tripId: string) => {
  const encodedCompanyName = companyName.toLowerCase().replace(/[^a-z0-9]/g, "-");
  const encodedTripTitle = tripTitle ? tripTitle.toLowerCase().replace(/[^a-z0-9]/g, "-") : "app";
  const baseURL = config[ENV].traveller_link_domain;
  const url = `/${encodedCompanyName}/${encodedTripTitle}/${tripId}`;
  return baseURL + url;
};

export const getEditTripLink = (tripId: string) => config[ENV].domain + "/trips/" + tripId;
export const getAcceptCollaborationLink = (tripId: string) =>
  config[ENV].domain + "/trips/collaborate/" + tripId + "/accept";

export const getCollaborateTripLink = (tripId: string) => config[ENV].domain + "/trips/collaborate/" + tripId;
