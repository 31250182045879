import { Box, Image, Stack } from "@chakra-ui/react";
import {
  ENABLE_DESTINATIONS_IN_PROD,
  Event,
  Hotel,
  TranslationField,
  Transportation,
  Trip,
  TripDay,
  tripdayTitleLocation,
} from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { default as React, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { BsFlagFill } from "react-icons/bs";
import { useMeContext } from "../../../stores/me-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import { closeElement, getLinkedLocations, submitForms } from "../../../utils/EventHelper";
import {
  DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY,
  TRIPDAY_ACCOMMODATION_QUERY_KEY,
  TRIPDAY_ACTIVITY_QUERY_KEY,
  TRIPDAY_TRANSPORTATION_QUERY_KEY,
} from "../../../utils/constants";
import ENV from "../../../utils/env";
import {
  createTranslationRelation,
  usePatchSplitTripday,
  useSplitTripday,
  useTripTranslations,
} from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { submitDocumentsArray } from "../../../utils/submitDocumentsArray";
import { CustomSpinner } from "../../../components/FullScreenSpinner";
import { getEmptyActivity, getEmptyHotel, getEmptyTransportation } from "../../../components/elements/activities";
import AddDestinationCard from "../../../components/trips/edit/daybyday/AddDestinationCard";
import AddHotelCard from "../../../components/trips/edit/daybyday/AddHotelCard";
import AddTransportationCard from "../../../components/trips/edit/daybyday/AddTransportationCard";
import EventTypeCard from "../../../components/trips/edit/daybyday/EventTypeCard";
import EventsList from "../../../components/trips/edit/daybyday/library-items/activities/EventsList";
import { calculateBetweenValues } from "../../../utils/calculateValue";
import { createNewTranslationFields } from "../../../utils/createNewTranslationFields";
import { useAsyncDebounce } from "react-table";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import TripdayTitleWrapper from "./TripdayTitleWrapper";

interface TripSwiperSlideProps {
  i: number;
  trip: Trip;
  selectedAccommodationIndex: number;
  setSelectedAccommodationIndex: any;
  titlePlaceHolder?: string;
  tripdayId: string;
}

const TripSwiperSlide: React.FC<TripSwiperSlideProps> = ({
  i,
  trip,
  titlePlaceHolder,
  selectedAccommodationIndex,
  setSelectedAccommodationIndex,
  tripdayId,
}) => {
  const queryClient = useQueryClient();

  const relations = [
    createTranslationRelation("titles"),
    { relation: "events" },
    { relation: "hotels" },
    { relation: "destination" },
    { relation: "location" },
    {
      relation: "transportations",
      subRelations: [
        { relation: "arrivalLocation" },
        { relation: "departureLocation" },
        { relation: "arrivalAirport" },
        { relation: "departureAirport" },
      ],
    },
  ];

  const {
    data: tripday,
    isLoading: isLoadingTripday,
    isFetching: isFetchingTripday,
  } = useSplitTripday(tripdayId, relations);
  const { data: tripTranslations, isLoading: isLoadingTrip } = useTripTranslations(trip?.id ?? "");

  const { mutateAsync: updateTripday, isPending: isUpdatingTripday } = usePatchSplitTripday(
    tripdayId,
    { relation: "tripday", subRelations: relations },
    queryClient,
    DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY,
    trip?.id,
  );

  if (!tripday || isLoadingTripday) return <CustomSpinner m="auto" />;

  return (
    <Box w="100%" mx="auto">
      <Stack spacing={0} flexDir={{ base: "column-reverse", md: "column" }} gap={{ base: "4", md: "0" }}>
        <TripSwiperSlideForm
          trip={trip}
          tripday={tripday}
          tripdayIndex={i}
          tripdayId={tripday.id}
          updateTripday={updateTripday}
          isUpdatingTripday={isUpdatingTripday || isLoadingTripday}
          translations={tripTranslations?.priceDescriptions ?? []}
          titlePlaceHolder={titlePlaceHolder}
          selectedAccommodationIndex={selectedAccommodationIndex}
          setSelectedAccommodationIndex={setSelectedAccommodationIndex}
          isFetchingTripday={isFetchingTripday}
        />
      </Stack>
    </Box>
  );
};

export default React.memo(TripSwiperSlide);

interface TripSwiperSlideFormProps {
  tripday: TripDay;
  tripdayIndex: number;
  updateTripday: any;
  isUpdatingTripday: boolean;
  translations: TranslationField[];
  trip: Trip;
  selectedAccommodationIndex: number;
  setSelectedAccommodationIndex: any;
  titlePlaceHolder?: string;
  isFetchingTripday: boolean;
  tripdayId?: string;
}
const TripSwiperSlideForm: React.FC<TripSwiperSlideFormProps> = ({
  tripday,
  tripdayIndex,
  updateTripday,
  isUpdatingTripday,
  translations,
  trip,
  titlePlaceHolder,
  selectedAccommodationIndex,
  setSelectedAccommodationIndex,
  isFetchingTripday,
  tripdayId,
}) => {
  const me = useMeContext();
  const queryClient = useQueryClient();

  const ref = React.useRef<any>();
  const {
    isFirst,
    isSubmittingForms,
    setIsMovingElements,
    setUnCollapsedElement,
    collaboratorCanEdit,
    isInvalidForms,
    isErrorForms,
    unCollapsedElement,
  } = useTripFormStore();

  const handlerCallback = React.useCallback(() => {
    if (unCollapsedElement) {
      submitForms(isSubmittingForms);
      closeElement(setUnCollapsedElement, isInvalidForms, isErrorForms);
    }
  }, [unCollapsedElement, setUnCollapsedElement, isInvalidForms, isErrorForms, isSubmittingForms]);

  useOnClickOutside({
    refs: [ref],
    selectors: [".pac-container"],
    handler: handlerCallback,
  });

  const emptyActivity = getEmptyActivity(me);
  const emptyTransportation = getEmptyTransportation(me);
  const emptyHotel = getEmptyHotel(me);

  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: tripday,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  const events = useFieldArray({
    control: formMethods.control,
    name: "events",
    keyName: "rhfId",
  });
  const hotels = useFieldArray({
    control: formMethods.control,
    name: "hotels",
    keyName: "rhfId",
  });
  const transportations = useFieldArray({
    control: formMethods.control,
    name: "transportations",
    keyName: "rhfId",
  });

  const handleUpdateTripday = useAsyncDebounce(async () => {
    await updateTripday({
      id: tripdayId,
      hotels: hotels.fields.map((hotel) => (!hotel?.id ? hotel : { id: hotel.id, ord: hotel.ord })),
      events: events.fields.map((event) => (!event?.id ? event : { id: event.id, ord: event.ord })),
      transportations: transportations.fields.map((transport) =>
        !transport?.id ? transport : { id: transport.id, ord: transport.ord },
      ),
    });
  }, 100);

  React.useEffect(() => {
    if (
      !tripday?.hotels ||
      selectedAccommodationIndex === undefined ||
      selectedAccommodationIndex > tripday.hotels.length - 1
    ) {
      setSelectedAccommodationIndex(0);
    }
  }, [tripday.hotels, selectedAccommodationIndex, tripdayId]);

  React.useEffect(() => {
    const currentEvents = events.fields;

    const newEvents = tripday.events
      .sort(
        (a, b) =>
          currentEvents.findIndex((e: any) => e.id === b.id) - currentEvents.findIndex((e: any) => e.id === a.id),
      )
      .filter((ev) => !currentEvents.some((e: any) => e.id === ev.id));

    let newIndex = 0;

    events.replace(
      tripday.events.map((e: any) => {
        const currentEvent = currentEvents.find((event: any) => event.id === e.id);
        if (currentEvent) {
          return { ...currentEvent, ord: e.ord ?? 0, documents: currentEvent.documents ?? [] };
        } else {
          newIndex++;
          setUnCollapsedElement(e.id);
          return newEvents[newIndex - 1];
        }
      }),
    );
  }, [tripday.events, tripdayId]);

  React.useEffect(() => {
    const currentHotels = hotels.fields;

    const newHotels = tripday.hotels
      .sort(
        (a, b) =>
          currentHotels.findIndex((e: any) => e.id === b.id) - currentHotels.findIndex((e: any) => e.id === a.id),
      )
      .filter((hot) => !currentHotels.some((e: any) => e.id === hot.id));

    // newHotels.forEach((hotel) => {
    //   hotels.update(hotel.index, hotel.hotel);
    //   setSelectedAccommodationIndex(hotel.index);
    //   setUnCollapsedElement(hotel.hotel.id);
    // });

    let newIndex = 0;

    hotels.replace(
      tripday.hotels.map((e: any) => {
        const currentHotel = currentHotels.find((event: any) => event.id === e.id);
        if (currentHotel) {
          return { ...currentHotel, ord: e.ord ?? 0, documents: currentHotel.documents ?? [] };
        } else {
          newIndex++;
          setUnCollapsedElement(e.id);
          return newHotels[newIndex - 1];
        }
      }),
    );
  }, [tripday.hotels, tripdayId]);

  React.useEffect(() => {
    const currentTransportations = transportations.fields;

    const newTransports = tripday.transportations
      .sort(
        (a, b) =>
          currentTransportations.findIndex((e: any) => e.id === b.id) -
          currentTransportations.findIndex((e: any) => e.id === a.id),
      )
      .filter((trans) => !currentTransportations.some((e: any) => e.id === trans.id));

    let newIndex = 0;

    transportations.replace(
      tripday.transportations.map((e: any) => {
        const currentTransport = currentTransportations.find((transport: any) => transport.id === e.id);
        if (currentTransport) {
          return { ...currentTransport, ord: e.ord ?? 0, documents: currentTransport.documents ?? [] };
        } else {
          newIndex++;
          setUnCollapsedElement(e.id);
          return newTransports[newIndex - 1];
        }
      }),
    );
  }, [tripday.transportations, tripdayId]);

  const addEvent = React.useCallback(
    async (event: any, eventObj?: any, ord?: number) => {
      setUnCollapsedElement(undefined);
      const sorted = [...events.fields, ...transportations.fields, ...hotels.fields].sort((a: any, b: any) =>
        a.ord < b.ord ? -1 : a.ord === b.ord ? (a.id ? 0 : -1) : 1,
      );

      const newOrd =
        sorted.length === 0
          ? 2 ** 15 / 2
          : calculateBetweenValues(
              ord === 0 ? 0 : sorted[(ord ?? sorted.length) - 1].ord,
              !ord && ord !== 0 ? 2 ** 15 : sorted[ord].ord,
            );

      if (!eventObj && !isUpdatingTripday) {
        eventObj = {
          ...emptyActivity,
          titles: createNewTranslationFields(translations),
          descriptions: createNewTranslationFields(translations),
          ord: newOrd,
          location: getLinkedLocations(
            [...events.fields, ...transportations.fields].sort((a: any, b: any) => a.ord - b.ord),
            true,
          ),
        };
      }
      if (eventObj.images && eventObj.images.length > 0) await submitDocumentsArray(eventObj.images, "activity");
      events.append(eventObj);
      await submitForms(isSubmittingForms);
      await handleUpdateTripday();
      return newOrd;
    },
    [
      setUnCollapsedElement,
      events,
      transportations.fields,
      hotels.fields,
      isUpdatingTripday,
      isSubmittingForms,
      handleUpdateTripday,
      emptyActivity,
      translations,
    ],
  );

  const objects: any[] = [...(events.fields ?? []), ...(transportations.fields ?? []), ...(hotels.fields ?? [])].sort(
    (a: any, b: any) => (a.ord < b.ord ? -1 : a.ord === b.ord ? (a.id ? 0 : -1) : 1),
  );

  const getElementFromTripdayFromCache = useCallback(() => {
    const hotelsRaw = queryClient.getQueriesData({ queryKey: [TRIPDAY_ACCOMMODATION_QUERY_KEY], type: "active" });
    const activitiesRaw = queryClient.getQueriesData({ queryKey: [TRIPDAY_ACTIVITY_QUERY_KEY], type: "active" });
    const transportationsRaw = queryClient.getQueriesData({
      queryKey: [TRIPDAY_TRANSPORTATION_QUERY_KEY],
      type: "active",
    });

    const mapToOrderedItems = (items: any[]) =>
      items
        .map((item) => item[1])
        .filter((item) => item)
        .filter((item) => objects.find((o) => o.id === item.id))
        .map((item) => ({ ...item, ord: objects.find((o) => o.id === item.id)?.ord }));

    const hotels = mapToOrderedItems(hotelsRaw);
    const activities = mapToOrderedItems(activitiesRaw);
    const transportations = mapToOrderedItems(transportationsRaw);

    return [...hotels, ...activities, ...transportations].filter(Boolean).sort((a, b) => {
      if (a.ord !== b.ord) return a.ord - b.ord;
      return a.id ? 0 : -1;
    });
  }, [isSubmittingForms, objects, tripdayId]);

  const addHotel = React.useCallback(
    async (_: any, hotel?: any, ord?: number) => {
      setUnCollapsedElement(undefined);
      const sorted = [...events.fields, ...transportations.fields, ...hotels.fields].sort((a: any, b: any) =>
        a.ord < b.ord ? -1 : a.ord === b.ord ? (a.id ? 0 : -1) : 1,
      );
      const lastHotelIndex = sorted.findLastIndex((s: any) => s.hasOwnProperty("board"));
      const newOrd =
        sorted.length === 0
          ? 2 ** 15 / 2
          : lastHotelIndex === -1
            ? calculateBetweenValues(
                ord === 0 ? 0 : sorted[(ord ?? sorted.length) - 1].ord,
                !ord && ord !== 0 ? 2 ** 15 : sorted[ord].ord,
              )
            : sorted.findLast((h) => h.hasOwnProperty("board"))?.ord + 1;

      if (!hotel) {
        hotel = {
          ...emptyHotel,
          ord: newOrd,
          descriptions: createNewTranslationFields(translations),
          collapsed: false,
        };
      }
      if (hotel.images && hotel.images.length > 0) await submitDocumentsArray(hotel.images, "accommodation");
      hotels.append(hotel);
      await submitForms(isSubmittingForms);
      await handleUpdateTripday();
      setSelectedAccommodationIndex(hotels.fields.length);
      return newOrd;
    },
    [
      setUnCollapsedElement,
      events.fields,
      transportations.fields,
      hotels,
      isSubmittingForms,
      handleUpdateTripday,
      setSelectedAccommodationIndex,
      emptyHotel,
      translations,
    ],
  );

  const addTransportation = React.useCallback(
    async (event: any, transport?: any, ord?: number) => {
      setUnCollapsedElement(undefined);
      const sorted = [...events.fields, ...transportations.fields, ...hotels.fields].sort((a: any, b: any) =>
        a.ord < b.ord ? -1 : a.ord === b.ord ? (a.id ? 0 : -1) : 1,
      );

      const newOrd =
        sorted.length === 0
          ? 2 ** 15 / 2
          : calculateBetweenValues(
              ord === 0 ? 0 : sorted[(ord ?? sorted.length) - 1].ord,
              !ord && ord !== 0 ? 2 ** 15 : sorted[ord].ord,
            );

      if (!transport && !isUpdatingTripday) {
        transport = {
          ...emptyTransportation,
          ord: newOrd,
          descriptions: createNewTranslationFields(translations),
          departureLocation: getLinkedLocations(
            [...events.fields, ...transportations.fields].sort((a: any, b: any) => a.ord - b.ord),
            true,
          ),
        };
      }
      //tripday.transportations.push(transport);
      transportations.append(transport);
      await submitForms(isSubmittingForms);
      await handleUpdateTripday();
      // Return the ord of the new item
      return newOrd;
    },
    [
      setUnCollapsedElement,
      events.fields,
      transportations,
      hotels.fields,
      isUpdatingTripday,
      isSubmittingForms,
      handleUpdateTripday,
      emptyTransportation,
      translations,
    ],
  );

  const eventsFieldArrayMethods = {
    remove: async (indexToRemove: number, updateTripday: boolean = true) => {
      //tripday.events = tripday.events.filter((_: any, index: number) => index !== indexToRemove);
      events.remove(indexToRemove);
      updateTripday && (await handleUpdateTripday());
      setUnCollapsedElement(undefined);
    },
    append: addEvent,
    replace: async (eventsArr: Event[]) => {
      //tripday.events = events;
      setIsMovingElements(true);
      events.replace(eventsArr);
      await submitForms(isSubmittingForms);
      await handleUpdateTripday();
      setIsMovingElements(false);
    },
  };

  const hotelsFieldArrayMethods = {
    remove: async (idToRemove: string, updateTripday: boolean = true) => {
      // hotels.remove removes the wrong hotel sometimes, so using replace instead
      hotels.replace(hotels.fields.filter((hotel) => hotel.id !== idToRemove));
      setUnCollapsedElement(undefined);
      setSelectedAccommodationIndex(!selectedAccommodationIndex ? 0 : selectedAccommodationIndex - 1);

      await handleUpdateTripday();
    },
    append: addHotel,
    replace: async (hotelsArr: Hotel[]) => {
      setIsMovingElements(true);
      hotels.replace(hotelsArr);
      await submitForms(isSubmittingForms);
      await handleUpdateTripday();
      setIsMovingElements(false);
    },
  };
  const transportationsFieldArrayMethods = {
    remove: async (indexToRemove: number, transport: Transportation, updateTripday: boolean = true) => {
      //tripday.transportations = tripday.transportations.filter((_: any, index: number) => index !== indexToRemove);
      transportations.remove(indexToRemove);
      updateTripday && (await handleUpdateTripday());
      if (transport.hasOwnProperty("originalTripdayIndex")) {
        await queryClient.invalidateQueries({
          queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, trip.tripdays[transport?.originalTripdayIndex ?? 0].id],
        });
      }
      setUnCollapsedElement(undefined);
    },
    append: addTransportation,
    replace: async (transportationsArr: Transportation[]) => {
      setIsMovingElements(true);
      //tripday.transportations = transportations;
      transportations.replace(transportationsArr);
      await handleUpdateTripday();
      setIsMovingElements(false);
    },
  };

  if (!trip) return <CustomSpinner m="auto" />;

  const dayIsEmpty = hotels.fields.length + events.fields.length + transportations.fields.length === 0;

  return (
    <>
      <TripdayTitleWrapper
        tripdayIndex={tripdayIndex}
        collaboratorCanEdit={collaboratorCanEdit}
        isFirst={isFirst}
        tripdayTitleLocation={tripdayTitleLocation}
        trip={trip}
        tripdayId={tripday.id}
      />
      <div ref={ref}>
        <EventsList
          first={isFirst}
          tripdayIndex={tripdayIndex}
          eventFieldArrayMethods={{ ...eventsFieldArrayMethods, fields: events.fields }}
          transportationFieldArrayMethods={{ ...transportationsFieldArrayMethods, fields: transportations.fields }}
          hotelFieldArrayMethods={{ ...hotelsFieldArrayMethods, fields: hotels.fields }}
          tripday={tripday}
          trip={trip}
          titlePlaceHolder={titlePlaceHolder}
          selectedAccommodationIndex={selectedAccommodationIndex}
          setSelectedAccommodationIndex={setSelectedAccommodationIndex}
          isFetchingTripday={isFetchingTripday}
          isUpdatingTripday={isUpdatingTripday}
          addAccommodation={addHotel}
          addActivity={addEvent}
          addTransportation={addTransportation}
          getElementFromTripdayFromCache={getElementFromTripdayFromCache}
        />
      </div>

      <div className={"mx-auto h-full"}>
        {dayIsEmpty && (
          <Image
            src={"https://res.cloudinary.com/lato/image/upload/v1725544843/undraw_add_notes_re_ln36_l3pvc8.svg"}
            alt="empty-day"
            className="w-36 mx-auto my-8 object-cover rounded-md"
          />
        )}
        <div className="flex my-auto h-full">
          {ENV !== "production" && !ENABLE_DESTINATIONS_IN_PROD && tripday.id && (
            <AddDestinationCard
              setUnCollapsedElement={setUnCollapsedElement}
              translations={translations}
              first={isFirst}
              control={formMethods.control}
              tripdayId={tripday.id}
            />
          )}
          <AddHotelCard
            tripdayIndex={tripdayIndex}
            addHotel={addHotel}
            nrOfNights={tripday.nrOfNights}
            nrOfHotels={hotels.fields.length}
            first={isFirst}
          />
          <EventTypeCard
            name={"Activity"}
            icon={BsFlagFill}
            isHidden={tripday.events?.length > 50}
            isDisabled={isUpdatingTripday}
            onClick={addEvent}
            display={isFirst ? "flex" : "none"}
          />
          <AddTransportationCard addTransportation={addTransportation} isDisabled={isUpdatingTripday} first={isFirst} />
        </div>
      </div>
    </>
  );
};
