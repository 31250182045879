import { MenuItem } from "@chakra-ui/react";
import {
  Language,
  recursivelyReplaceObjectValues,
  recursivelyRetrieveObjectValues,
  Trip,
  User,
  TranslationField,
} from "@lato/common";
import React from "react";
import { POI } from "../../../../api/pois.api";
import { useMeContext } from "../../../../stores/me-context";
import { getEmptyTranslationField } from "../../../../pages/create-trip";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { submitForms } from "../../../../utils/EventHelper";
import { useLanguages } from "../../../../utils/query-helpers/reactQueryHooks";
import { LanguageMenuItem } from "./LanguageSwitcher";

interface LanguagesOptionsProps {
  // append: (translation: TranslationField) => void;
  translatedLanguages: Language[];
  handleNewLanguage: (i: number, id: string, language: Language, copy: boolean, reset: any) => void;
  isDisabled: boolean;
  isFirst: boolean;
  doubleFields: boolean;
  getValues: any;
  reset: any;
  trip?: Trip;
  elementId: string;
}

const appendTranslation = (newTranslation: TranslationField, array: TranslationField[]): TranslationField[] => {
  return array.concat([newTranslation]);
};

export function replaceAllTranslationArrays<T>(trip: T, f: (v: TranslationField[]) => TranslationField[]) {
  return recursivelyReplaceObjectValues<T>(trip, (_, v) => {
    if (v && Array.isArray(v) && v.length && v.every((item) => item && typeof item === "object" && "creator" in item)) {
      return f(v);
    }
    return v;
  });
}

export const replaceAndCopyAllTranslationArrays = (me: User, trip: Trip | POI, language: Language, copy: boolean) =>
  replaceAllTranslationArrays(trip, (v) => {
    if (copy) {
      console.log(v);
      const content = v.find(({ language: l }) => l.code === language.code)?.content || "";
      return [...v, { ...getEmptyTranslationField(me), language, content, language_code: language.code }];
    } else {
      return [...v, { ...getEmptyTranslationField(me), language, language_code: language.code }];
    }
  });

const LanguagesOptions: React.FC<LanguagesOptionsProps> = ({
  translatedLanguages,
  handleNewLanguage,
  isDisabled,
  isFirst,
  doubleFields,
  getValues,
  reset,
  trip,
  elementId,
}) => {
  const user = useMeContext();
  const { isSubmittingForms } = useTripFormStore();
  const { data: allLanguages, isLoading: isLoadingLanguages, error: errorLanguages } = useLanguages();

  if (isLoadingLanguages) return <MenuItem>Loading...</MenuItem>;
  if (errorLanguages || !allLanguages) return <MenuItem>Could not load languages</MenuItem>;

  const untranslatedLanguages = allLanguages.filter((l) => !translatedLanguages.some((l1) => l1?.code === l?.code));

  const collaborationButNothingTranslated = !isFirst && !doubleFields;
  const languageOptions = collaborationButNothingTranslated ? allLanguages : untranslatedLanguages;

  trip = trip || getValues();

  const newLanguage = async (i: number) => {
    // Get new language
    const language = languageOptions[i];
    // Append a new description
    // append(newTranslation);
    // const newTrip = changeTranslations(trip, (arr: TranslationField[]) =>
    //   appendTranslation(newTranslation, arr)
    // );
    // Find all translated languages of the previous user.
    const previousLanguageCodes = getLanguagesFromPreviousCompanies(trip, user.companyId!);

    const copy = previousLanguageCodes.includes(language.code);
    //Saving previous translations
    await submitForms(isSubmittingForms);

    // Handle clicking a description
    const names = [...translatedLanguages.map((l) => l.name), language.name];

    names.sort((a, b) => (a > b ? 1 : -1));

    handleNewLanguage(
      names.findIndex((l) => l === language.name),
      elementId,
      language,
      copy,
      reset,
    );
  };

  return (
    <>
      {languageOptions.map((l, i) => (
        <LanguageMenuItem
          key={`newlanguage-${i}`}
          language={l}
          defaultTFLang={user.defaultTFLang}
          onClick={() => newLanguage(i)}
          isDisabled={isDisabled}
        >
          {l.nativename}
        </LanguageMenuItem>
      ))}
    </>
  );
};
export default React.memo(LanguagesOptions);

export function getLanguagesFromCompany<T>(trip: T, companyId: string) {
  return recursivelyRetrieveObjectValues(
    trip,
    (k, v) => v[k] && typeof v[k] === "object" && v[k]["creator"] && v[k]["creator"]["companyId"] === companyId,
  ).map((tf) => tf.language.code);
}

export function getLanguagesFromPreviousCompanies<T>(trip: T, companyId: string) {
  return recursivelyRetrieveObjectValues(
    trip,
    (k, v) =>
      v[k] &&
      typeof v[k] === "object" &&
      v[k]["creator"] &&
      v[k]["creator"]["companyId"] !== companyId &&
      v[k]["creator"]["companyId"] !== null,
  ).map((tf) => tf.language.code);
}
