import { Box, Flex, Heading, Icon, IconButton, Tooltip } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { IoChevronDownSharp as ChevronDown, IoChevronForwardSharp as ChevronForward } from "react-icons/io5";
import {
  Row,
  SortingRule,
  TableInstance,
  TableOptions,
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Section from "../layout/Section";
import { BadgeCount, GlobalFilter } from "./Resource";
import CRUDTable from "./Table";
import { AddIcon } from "@chakra-ui/icons";

interface CollapsableTableProps<ObjectType extends object> {
  columns: any;
  data: ObjectType[];
  disableSorting: boolean;
  initialSortBy?: Array<SortingRule<ObjectType>>;
  pageSize?: number;
  initialRowExpansionState?: Record<string, boolean>;
  totalCount?: number;
  disableSearch?: boolean;
  fetchingData?: boolean;
  loadingData?: boolean;
  getRowProps?: ({
    row,
    toggleAllRowsExpanded,
  }: {
    row: Row;
    toggleAllRowsExpanded: (value?: boolean | undefined) => void;
  }) => any;
  displayName: string;
  minTableHeight?: string;
  setFilterValue: (value: any) => void;
  icon: any;
  defaultClosed?: boolean;
  mt?: string;
  addRow?: (e) => void;
  badge?: React.ReactElement;
}

export const CollapsableTable = <T extends object>({
  columns,
  data,
  disableSorting,
  initialSortBy = [],
  pageSize,
  initialRowExpansionState = {},
  totalCount = data.length,
  disableSearch = false,
  fetchingData = false,
  loadingData = false,
  getRowProps = () => ({}),
  displayName,
  badge,
  minTableHeight,
  setFilterValue,
  icon,
  defaultClosed = false,
  mt,
  addRow,
}: CollapsableTableProps<T>) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(!defaultClosed);

  const initialTableState = useMemo(
    () => ({
      // The initial column on which to sort
      sortBy: initialSortBy,
      pageSize,
      // The initial rows which are expanded
      expanded: initialRowExpansionState,
      // hiddenColumns: ["status"],
      // hiddenColumns: hiddenCol,
    }),
    [initialRowExpansionState, pageSize],
  );
  const generalTableOptions: TableOptions<T> = {
    columns,
    data,
    disableSortBy: disableSorting,
    // Initial sort state
    initialState: initialTableState,
    // To not reset the expanded state when the data changes
    // autoResetExpanded: false,
    // Custom comparator functions
    // sortTypes: { alphanumeric: caseInsensitiveAlphaNumericSort },
    // To allow for server side pagination, only when a pageSize prop is set
    manualPagination: !!pageSize,
    // To allow for server side sorting, only when a pageSize is given. (Hence, only when server side functionality do also manual sorting)
    manualSortBy: pageSize ? true : false,
    // To allow for server-side searching
    manualGlobalFilter: true,
    // To allow for server-side filtering
    manualFilters: false,
    // To allow for both pagination and sorting
    autoResetSortBy: false,
    autoResetPage: false,
    // The number of pages needed, only when a pageSize prop is set
    pageCount: pageSize ? Math.ceil(totalCount / pageSize) : undefined,
    // Not from the react-table API but everything added below can be used inside the Cell renderers etc
  };

  const tableMethods = useTable<T>(
    {
      ...generalTableOptions,
    },
    useGlobalFilter,
    // // Explanation about the different settings for the column specific filters (this in combination with disableFilters in defaultColumn will disable the column filters by default)
    // // https://github.com/tannerlinsley/react-table/issues/2506#issuecomment-701197958
    useSortBy,
    usePagination,
  );

  const { state }: TableInstance<T> = tableMethods;

  React.useEffect(() => {
    setFilterValue(state.globalFilter);
  }, [state]);
  return (
    <>
      <Section noDivider mt={mt}>
        <Box>
          <Flex
            width={"100%"}
            gap={5}
            alignItems={"center"}
            onClick={() => setCollapsed(!collapsed)}
            cursor={"pointer"}
          >
            <Icon
              aria-label={collapsed ? "expand" : "collapse"}
              as={collapsed ? ChevronForward : ChevronDown}
              boxSize={6}
              color={"grey"}
            ></Icon>
            <Heading size={"lg"} my={"0.3em"}>
              {/*<Icon as={icon} mb={"0.3em"} boxSize={7} mr={"0.6em"} />*/}
              {displayName}
              {<BadgeCount totalCount={data.length} height={"1.5em"} />}
              {badge}
            </Heading>
            {!collapsed && (
              <Box
                onClick={(e: any) => {
                  e.stopPropagation();
                }}
                width={"40%"}
              >
                {!disableSearch && (
                  <GlobalFilter
                    preGlobalFilteredRows={tableMethods.preGlobalFilteredRows}
                    globalFilter={tableMethods.state.globalFilter}
                    setGlobalFilter={tableMethods.setGlobalFilter}
                  />
                )}
              </Box>
            )}
            {addRow && (
              <Tooltip label={"Add new " + displayName}>
                <IconButton
                  ml={"auto"}
                  aria-label="add"
                  icon={<AddIcon />}
                  colorScheme="brand"
                  variant={"ghost"}
                  onClick={(e) => {
                    e.stopPropagation();
                    addRow(e);
                  }}
                />
              </Tooltip>
            )}
          </Flex>
          {!collapsed && (
            <CRUDTable
              isFiltering={
                (tableMethods?.state?.filters
                  ? (tableMethods.state.filters as any[]).some((filter: any) => filter.value.length > 0)
                  : false) ||
                (tableMethods.state.globalFilter && tableMethods.state.globalFilter !== "")
              }
              tableMethods={tableMethods}
              rows={pageSize ? tableMethods.page : tableMethods.rows}
              totalCount={totalCount}
              fetchingData={fetchingData}
              loadingData={loadingData}
              getRowProps={getRowProps}
              displayName={displayName}
              minTableHeight={minTableHeight}
              maxTableHeight={"20em"}
              addRow={undefined}
              tableCaption={undefined}
              tbodyBackgroundImage={undefined}
              lastDisabled={false}
              marginTop={"0.8em"}
              hideHeadersWhenEmpty={true}
            />
          )}
        </Box>
      </Section>
    </>
  );
};
