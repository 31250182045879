import React from "react";
import { useFieldArray } from "react-hook-form";
import { useMeContext } from "../../../../stores/me-context";
import { PartialTranslationField } from "../../../../stores/trip-language-context";
import { User, TranslationField } from "@lato/common";
import RHFInput, { RHFInputProps } from "../../../input/RHFInput";
import RHFRichTextEditor2 from "../../../input/wysiwyg/RHFRichTextEditor2";

interface LanguagesInputProps extends RHFInputProps {
  name: string;
  first: boolean;
  firstInput: boolean;
  selectedLanguageIndex: number;
  doubleFields: boolean;
  isInline?: boolean;
}

export const getTranslationsFromLoggedInUser = (tfs: TranslationField[], user: User) => {
  return tfs.filter((tf) => tf.creator?.companyId! === null || tf.creator?.companyId === user.companyId);
};

export const getTranslationsNOTFromLoggedInUser = (tfs: TranslationField[], user: User) => {
  const translationsFromLoggedInUser = getTranslationsFromLoggedInUser(tfs, user);
  return tfs.filter((tf) => !translationsFromLoggedInUser.map((tf) => tf.id).includes(tf.id));
};

export const checkOwnTranslation = (creator: User | null, user: User) => {
  return !creator || creator.companyId! === user.companyId!;
};

export const calculateRealLanguageIndex = (
  translations: PartialTranslationField[],
  showType: "showAll" | "showOthers" | "showOwn",
  selectedLanguageIndex: number,
  user: User,
) => {
  if (showType === "showAll") return selectedLanguageIndex;
  let i = 0;
  while (i < translations.length) {
    if (showType === "showOwn") {
      if (checkOwnTranslation(translations[i].creator, user)) {
        selectedLanguageIndex--;
      }
    }
    if (showType === "showOthers") {
      if (!checkOwnTranslation(translations[i].creator, user)) {
        selectedLanguageIndex--;
      }
    }
    if (selectedLanguageIndex < 0) break;
    i++;
  }
  return i;
};

const getNewIndexAfterRemoving = (previouslySelectedIndex: number, removedIndex: number) => {
  if (previouslySelectedIndex === removedIndex) return 0;
  else if (previouslySelectedIndex < removedIndex) return previouslySelectedIndex;
  else return previouslySelectedIndex - 1;
};

const LanguagesInput = React.forwardRef<HTMLInputElement, LanguagesInputProps>(
  ({ name, first, firstInput, selectedLanguageIndex, doubleFields, isInline, ...props }, ref) => {
    const user = useMeContext();

    // Remove the 'hasTranslations prop from all the props.
    // Get the index where the last array is indexed.
    const fieldName = name;
    // When initialzing the input, a 0 is used which is replaced beneath.
    const endFieldArray = fieldName.lastIndexOf("0") - 1;
    const fieldArrayName = fieldName.substring(0, endFieldArray);
    const propertyName = fieldName.substring(endFieldArray, fieldName.length);

    const { fields, append, remove } = useFieldArray({
      name: fieldArrayName as any,
    });
    const translations = fields as TranslationField[];

    const showType = !doubleFields ? (first ? "showOwn" : "showAll") : firstInput ? "showOthers" : "showOwn";

    const realLanguageIndex = calculateRealLanguageIndex(translations, showType, selectedLanguageIndex, user);

    const actualFieldName = fieldName.replace(/0([^0]*)$/, `${realLanguageIndex}` + "$1") as any;

    const InputLabel = (
      <div className="flex justify-between w-full">
        <p>{props.label}</p>
      </div>
    );

    const commonInputProps = {
      name: actualFieldName,
      label: isInline ? undefined : InputLabel,
    };

    const InputWrapper = props.textarea ? RHFRichTextEditor2 : RHFInput;

    return (
      <div
        // VERY IMPORTANT: this key will force a new rerender when the name changes, because RHF7 has issues with dynamic name rerendering: https://github.com/react-hook-form/react-hook-form/issues/4794
        key={actualFieldName}
        style={{ width: "100%" }}
      >
        <InputWrapper ref={ref} isInline={isInline} {...props} {...commonInputProps} />
      </div>
    );
  },
);
LanguagesInput.displayName = "LanguagesInput";
export default React.memo(LanguagesInput);
