export function recursivelyRetrieveObjectValues(obj, shouldReturn) {
    let array = [];
    for (const prop in obj) {
        // Push a fixed value from the object to the array
        if (shouldReturn(prop, obj))
            array.push(obj[prop]);
        else if (typeof obj[prop] === "object")
            array = array.concat(recursivelyRetrieveObjectValues(obj[prop], shouldReturn));
    }
    return array;
}
/**
 * Recursively builds an array by iterating through the properties of an object and applying a callback function to each property.
 *
 * @param {any} obj - The object to iterate through.
 * @param {(prop: any, obj: any) => unknown | null} shouldReturn - The callback function to apply to each property. It takes the property and the object as parameters and should return a value or null. Null if the property should not be included in the array.
 * @return {unknown[]} The resulting array built by applying the callback function to each property.
 */
export function recursivelyBuildArray(obj, shouldReturn) {
    let array = [];
    for (const prop in obj) {
        const value = shouldReturn(prop, obj);
        // Push the value to the array returned by the callback -> variable value
        if (value)
            array.push(value);
        else if (typeof obj[prop] === "object")
            array = array.concat(recursivelyBuildArray(obj[prop], shouldReturn));
    }
    return array;
}
export function recursivelyReplaceObjectValues(object, 
// Should replace is a callback which takes in a key and value.
// It should return either a new value or the same value in case it should not be updated.
shouldReplace) {
    return !Array.isArray(object)
        ? object && typeof object === "object" && !(object instanceof Date)
            ? Object.fromEntries(Object.entries(object).map(([k, v]) => {
                // Get the value returned from the callback
                const nv = shouldReplace(k, v);
                return [
                    k,
                    // If the returned value is the same as the given value than nothing is replaced and hence further recursively search on this value.
                    v !== nv ? nv : recursivelyReplaceObjectValues(v, shouldReplace),
                ];
            }))
            : object
        : object.map((i) => recursivelyReplaceObjectValues(i, shouldReplace));
}
// function recursivelyRemoveObjectValuesHelper(
//   obj: any,
//   shouldDelete: (prop: any, obj: any) => boolean,
// ) {
//   for (const prop in obj) {
//     if (shouldDelete(prop, obj)) delete obj[prop];
//     else if (typeof obj[prop] === 'object')
//       recursivelyRemoveObjectValuesHelper(obj[prop], shouldDelete);
//   }
// }
export function recursivelyRemoveObjectValues(obj, shouldDelete) {
    // const clonedObj = { ...obj };
    // recursivelyRemoveObjectValuesHelper(clonedObj, shouldDelete);
    // return clonedObj;
    if (Array.isArray(obj)) {
        const a = [];
        obj.forEach((_, i) => {
            if (!shouldDelete(i, obj)) {
                if (obj[i] && typeof obj[i] === "object" && !(obj[i] instanceof Date)) {
                    a.push(recursivelyRemoveObjectValues(obj[i], shouldDelete));
                }
                else {
                    a.push(obj[i]);
                }
            }
        });
        return a;
    }
    const newObj = {};
    for (const prop in obj) {
        if (!shouldDelete(prop, obj)) {
            if (obj[prop] && typeof obj[prop] === "object" && !(obj[prop] instanceof Date)) {
                newObj[prop] = recursivelyRemoveObjectValues(obj[prop], shouldDelete);
            }
            else {
                newObj[prop] = obj[prop];
            }
        }
    }
    return newObj;
}
