const CheckIcon = () => (
  <svg
    role="graphics-symbol"
    viewBox="0 0 16 16"
    className="aiFixSpellingGrammar"
    style={{ width: "16px", height: "16px", display: "block", fill: "currentColor", flexShrink: 0 }}
  >
    <path d="M6.6123 14.2646C7.07715 14.2646 7.43945 14.0869 7.68555 13.7109L14.0566 3.96973C14.2344 3.69629 14.3096 3.44336 14.3096 3.2041C14.3096 2.56152 13.8311 2.09668 13.1748 2.09668C12.7236 2.09668 12.4434 2.26074 12.1699 2.69141L6.57812 11.5098L3.74121 7.98926C3.48828 7.68848 3.21484 7.55176 2.83203 7.55176C2.16895 7.55176 1.69043 8.02344 1.69043 8.66602C1.69043 8.95312 1.7793 9.20605 2.02539 9.48633L5.55273 13.7588C5.84668 14.1074 6.1748 14.2646 6.6123 14.2646Z"></path>
  </svg>
);
export default CheckIcon;
