import { ChevronDownIcon } from "@chakra-ui/icons";
import { Trash2 as DeleteIcon } from "lucide-react";

import {
  Button,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemProps,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Language, TranslationField } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useFormContext } from "react-hook-form";
import UsersAPI from "../../../../api/users.api";
import { useMeContext } from "../../../../stores/me-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { ME_QUERY_KEY, SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../../../utils/constants";
import { resetRHFValues } from "../../../fileUpload/TripdayImageDropzone";
import ConfirmModal from "../../../modals/ConfirmModal";
import LanguagesOptions, { replaceAllTranslationArrays } from "./LanguagesOptions";

interface LanguageSwitcherProps {
  currentlySelectedIndex: number;
  handleNewLanguage: (i: number, object: any, language: Language, copy: boolean, reset: any) => void;
  handleChangeLanguage: (i: number) => void;
  handleRemoveLanguage: (i: number, code: string) => void;
  translatedLanguages: Language[];
  isFirst: boolean;
  firstInput: boolean;
  doubleFields: boolean;
  showType: "showAll" | "showOthers" | "showOwn";
  calculateRealIndex: (i: number) => number;
  elementId: string;
  getValues?: any;
  reset?: any;
  disableLanguageOptions?: boolean;
  hideDeleteIcon?: boolean;
}

const removeTranslation = (i: number, array: TranslationField[]): TranslationField[] => {
  const arrayCopy = [...array];
  arrayCopy.splice(i, 1);
  return arrayCopy;
};

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  currentlySelectedIndex,
  translatedLanguages,
  isFirst,
  firstInput,
  handleChangeLanguage,
  handleRemoveLanguage,
  doubleFields,
  showType,
  calculateRealIndex,
  getValues = () => {},
  reset = () => {},
  disableLanguageOptions = false,
  hideDeleteIcon = false,
  ...props
}) => {
  const user = useMeContext();
  translatedLanguages = translatedLanguages.filter((l: TranslationField) => l);
  const { isErrorForms, isInvalidForms, trip } = useTripFormStore();
  const [toBeRemovedIndex, setToBeRemovedIndex] = React.useState<number>();
  const [isRemoving, setIsRemoving] = React.useState(false);

  const disclosure = useDisclosure();

  const handleClickDeleteIcon = React.useCallback(
    (e: React.MouseEvent<SVGElement, MouseEvent>, i: number, onClose: () => void) => {
      onClose();
      e.stopPropagation();
      setToBeRemovedIndex(i);
      disclosure.onOpen();
    },
    [setToBeRemovedIndex, disclosure.onOpen],
  );

  const handleRemoveTranslation = React.useCallback(
    async (e: React.MouseEvent<SVGElement, MouseEvent>, i: number, code: string) => {
      e.stopPropagation();
      setIsRemoving(true);
      setToBeRemovedIndex(undefined);
      // remove(i);
      const realLanguageIndex = calculateRealIndex(i);
      const newTrip = replaceAllTranslationArrays(trip, (arr: TranslationField[]) =>
        removeTranslation(realLanguageIndex, arr),
      );
      await handleRemoveLanguage(i, code);
      resetRHFValues(reset, newTrip);
      setIsRemoving(false);
    },
    [getValues, trip, handleRemoveLanguage, user, reset, calculateRealIndex, setToBeRemovedIndex],
  );

  const defaultTFLang = user.defaultTFLang;

  const currentlySelected = translatedLanguages[currentlySelectedIndex];

  const ownTranslations = !doubleFields ? (isFirst ? true : false) : firstInput ? false : true;
  const notAllowed = doubleFields && firstInput;

  const previousCompanyName = !ownTranslations && trip?.userTrips ? trip?.userTrips[0].brand.name : null;

  const collaborationButNothingTranslated = !isFirst && !doubleFields;

  // If it should be possible that 1 language is used multiple times -> show all languages instead of 'untranslated' languages.
  // When a duplicate language is selected, show both in 'translated' section and indicate which one is which (e.g. by adding 'yours' or company/user name at then end)
  // BUT then: limit 1 same language per user or company.

  return (
    <Menu autoSelect={false} isLazy>
      {({ onClose }) => (
        <>
          <MenuButton
            isDisabled={
              [...isErrorForms.values()].some((value) => value === true) ||
              [...isInvalidForms.values()].some((value) => value === true)
            }
            as={Button}
            iconSpacing={0}
            rightIcon={<ChevronDownIcon />}
            variant="link"
            _hover={{ textDecoration: "none" }}
            fontSize="lg"
          >
            {currentlySelected && currentlySelected.flag}
          </MenuButton>
          <Portal>
            <MenuList
              maxW="350px"
              zIndex={9999}
              overflow={{ base: "scroll", md: "auto" }}
              maxH={{ base: "40vh", md: "auto" }}
              mr={{ base: "5rem", md: "0" }}
            >
              <MenuGroup title={ownTranslations ? "Your languages" : `${previousCompanyName}'s languages`}>
                {translatedLanguages.map((language, i) => (
                  <LanguageMenuItem
                    key={`description-${i}`}
                    onClick={() => handleChangeLanguage(i)}
                    bg={currentlySelectedIndex === i ? "gray.100" : undefined}
                    language={language}
                    defaultTFLang={defaultTFLang}
                    // Add a remove button to the right if there are atleast 2 translations.
                    // @ts-ignore
                    command={
                      translatedLanguages.length > 1 && !isRemoving && ownTranslations && !hideDeleteIcon ? (
                        <Icon
                          as={DeleteIcon}
                          ml={2}
                          className="hover:text-red-700"
                          onClick={(e) => handleClickDeleteIcon(e, i, onClose)}
                        />
                      ) : undefined
                    }
                  >
                    {language?.nativename}
                  </LanguageMenuItem>
                ))}
              </MenuGroup>
              {!notAllowed && !disableLanguageOptions && (
                <>
                  <MenuDivider />
                  <MenuGroup title={collaborationButNothingTranslated ? "Add your own language" : "Add a new language"}>
                    <LanguagesOptions
                      // append={append}
                      isDisabled={notAllowed}
                      translatedLanguages={translatedLanguages}
                      isFirst={isFirst}
                      doubleFields={doubleFields}
                      getValues={getValues}
                      reset={reset}
                      trip={trip}
                      {...props}
                    />
                  </MenuGroup>
                </>
              )}
            </MenuList>
          </Portal>
          {toBeRemovedIndex != null && (
            <ConfirmModal
              title={`Deleting language (${translatedLanguages[toBeRemovedIndex].code})...`}
              description={
                translatedLanguages.length === 1
                  ? `You cannot delete the only language.`
                  : `Are you sure you want to delete ${translatedLanguages[toBeRemovedIndex].name}? This will delete all content associated with this language.`
              }
              confirmButtonName={translatedLanguages.length === 1 ? "Ok" : "Delete"}
              action={async (e) =>
                translatedLanguages.length === 1
                  ? () => {}
                  : await handleRemoveTranslation(e, toBeRemovedIndex, translatedLanguages[toBeRemovedIndex].code)
              }
              disclosure={disclosure}
              isLoading={isRemoving}
            />
          )}
        </>
      )}
    </Menu>
  );
};
export default React.memo(LanguageSwitcher);

type LanguageMenuItemProps = MenuItemProps & {
  language: Language;
  defaultTFLang: Language;
};
export const LanguageMenuItem: React.FC<LanguageMenuItemProps> = ({ language, defaultTFLang, ...props }) => {
  const queryClient = useQueryClient();

  const { mutateAsync: mutateUser } = useMutation({
    mutationFn: UsersAPI.patchUser,
    onSuccess: async (response: any) => {
      await queryClient.setQueryData([ME_QUERY_KEY], response);
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });

  const makeDefaultLang = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    await mutateUser({ defaultTFLang: language });
  };
  return (
    <MenuItem
      className="group"
      icon={<>{language.flag}</>}
      my={1}
      _hover={{ bg: "gray.100" }}
      {...props}
      // Add a 'make default' button.
      // @ts-ignore
      command={
        <div className="ms-3 invisible group-hover:visible flex items-center justify-end">
          <Text w={"12ch"} textAlign="end" fontSize="sm">
            {language.code === defaultTFLang.code ? (
              "default"
            ) : (
              <Link as="button" onClick={makeDefaultLang}>
                make default
              </Link>
            )}
          </Text>
          {props.command}
        </div>
      }
    >
      {language.name.split(",")[0]}
    </MenuItem>
  );
};
