import { Button } from "@chakra-ui/react";
import { Task, UserTrip, calculateDueDate } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { USERTRIP_TASKS_QUERY_KEY } from "../../../../../utils/constants";
import { usePatchTask } from "../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { TasksTable } from "../../../../../features/tasks/TasksTable";
import TripForm from "../../../TripForm";

interface TripTasksProps {
  userTrip: UserTrip;
  tasks: Task[];
  start_date: string;
  end_date: string;
  refetch: () => void;
  isFetchingTasks: boolean;
  isLoadingTasks: boolean;
  isSample?: boolean;
}

export const TasksWrapper: React.FC<TripTasksProps> = ({
  userTrip,
  start_date,
  end_date,
  tasks,
  refetch,
  isLoadingTasks,
  isFetchingTasks,
  ...props
}) => {
  const formId = `4-${USERTRIP_TASKS_QUERY_KEY}`;
  const queryClient = useQueryClient();

  const defaultValues = {
    tasks: tasks?.map((task: Task) => ({
      ...task,
      date:
        task.due_date ||
        calculateDueDate(new Date(start_date), new Date(end_date), task.due_days!, task.due_days_type!),
    })),
  };

  const formMethods = useForm<{ tasks: Task[] }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  const { mutateAsync: updateTask, isPending: isLoadingUpdate } = usePatchTask(queryClient);

  const handleSubmit = async (newTask: Task) => {
    await updateTask(newTask);
    refetch();
  };

  return (
    <div className="my-4">
      <TripForm
        formId={formId}
        formMethods={formMethods}
        onSubmit={handleSubmit}
        triggerResetData={tasks}
        defaultValues={defaultValues}
      >
        <Button type="submit" hidden className="form-submit-button">
          Save
        </Button>
        <TasksTable
          totalCount={tasks?.length}
          fetchingTasks={isFetchingTasks}
          loadingTasks={isLoadingTasks}
          refetch={refetch}
          updateTask={handleSubmit}
          tripStartDate={start_date}
          tripEndDate={end_date}
          userTrip={userTrip}
          tasks={tasks}
          isInTrip={true}
          listName="Trip tasks"
          showOpenTripOption={false}
          showRemoveFromTripOption={true}
          {...props}
        />
      </TripForm>
    </div>
  );
};

export default React.memo(TasksWrapper);
