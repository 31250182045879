import { LockIcon } from "@chakra-ui/icons";
import React from "react";
import { BiSolidMapPin, BiWorld } from "react-icons/bi";
import { BsTranslate } from "react-icons/bs";
import { FaCogs, FaStore } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa6";
import { IoPersonCircleSharp } from "react-icons/io5";
import SideMenu from "./SideMenu";
import { SubSideMenuNavItemProps } from "./SideMenuNavItem";
import ENV from "../../utils/env";

interface settingsProps {
  children: React.ReactNode;
  subNavItems?: SubSideMenuNavItemProps[];
  tabName?: string;
}
const Settings: React.FC<settingsProps> = ({ children, subNavItems, tabName }) => {
  const mainPath = "settings";

  const navItems = [
    {
      tabname: "Account",
      icon: <IoPersonCircleSharp size="20px" />,
      path: "account",
      marginLeft: "-1px",
    },
    {
      tabname: "Security",
      icon: <LockIcon boxSize="18px" />,
      path: "security",
    },
    {
      tabname: "Billing",
      icon: <FaDollarSign size="18px" />,
      path: "billing",
    },
    // {
    //   tabname: "Followers",
    //   icon: <FaSlideshare size="18px" />,
    //   badge: <FollowRequestBadge transform={"translate(90%, -20%)"} />,
    //   path,
    // },
    {
      tabname: "Deepl",
      icon: <BsTranslate size="18px" />,
      path: "deepl",
    },
    {
      tabname: "Trip",
      icon: <BiWorld size="18px" />,
      path: "trip",
    },
    {
      tabname: "Branding",
      icon: <FaStore size="18px" />,
      path: "branding",
    },
    {
      tabname: "Map",
      icon: <BiSolidMapPin size="18px" />,
      path: "map",
    },
    {
      tabname: "Integrations",
      icon: <FaCogs size="18px" />,
      path: "integrations",
    },
  ];

  return (
    <SideMenu mainPath={mainPath} heading={"Settings"} tabName={tabName} subNavItems={subNavItems} navItems={navItems}>
      {children}
    </SideMenu>
  );
};
export default Settings;
