import { Box, Button, Divider, Flex, Heading, Icon, Image, Link, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { BiText } from "react-icons/bi";
import { IoCalendarNumber } from "react-icons/io5";
import { MdFamilyRestroom } from "react-icons/md";
import { useParams } from "react-router-dom";
import EditTripIllustration from "../../../assets/icons/edit-trip";
import TravelAppIllustration from "../../../assets/icons/travel-app.illustration";
import { CardWrapper } from "../../../components/CardWrapper";
import SplitScreenLayout from "../../../components/layout/SplitScreenLayout";
import colors from "../../../theme/foundations/colors";
import { LATO_SLOGAN } from "../../../utils/constants";
import { getAcceptCollaborationLink, getEditTripLink, getViewTripLink } from "../../../utils/generateURLs";
import { useMe } from "../../../utils/query-helpers/reactQueryHooks";
import useCollaborationInvite from "../../../utils/useCollaborationInvite";

const TripCollaborateRoute: React.FC<any> = () => {
  const { data: me } = useMe();

  const { id: userTripId } = useParams<{ id: string }>();
  const { trip, userTrips, return: ret } = useCollaborationInvite();

  if (ret || !trip) return ret;

  const usertrip = userTrips![userTrips!.length - 1];

  const viewTripURL = getViewTripLink(
    usertrip.user.company.name,
    trip.titles.length > 0 ? trip.titles[0].content : "trip",
    usertrip.id!,
  );
  const acceptCollaborationLink = getAcceptCollaborationLink(userTripId!);
  const editTripLink = getEditTripLink(userTripId!);

  if (usertrip.user.company.id === me?.company?.id) {
    window.location.href = editTripLink;
  }

  // The check returned the same userTripId, meaning that the invitation was not yet accepted and is valid.
  return (
    <SplitScreenLayout>
      {/* <Flex gap={"4em"} mt={"4em"} flexDirection={{ base: "column", md: "row" }}> */}
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        w="44.5%"
        mb={5}
        backgroundColor={"white"}
        p={"4em"}
        borderRadius={"10px"}
      >
        <Box>
          <Box
            position="relative"
            //backgroundColor={"white"}
            m="auto"
            w="fit-content"
            h="fit-content"
            p="1em"
            borderRadius={"10px"}
            mb={"2em"}
          >
            {usertrip.brand.logo && usertrip.brand.logoUrl ? (
              <Image
                left="1.9em"
                m={"auto"}
                top={0}
                alt={usertrip.brand.name}
                className="brand-logo"
                src={usertrip.brand.logoUrl}
                style={{
                  maxHeight: `calc(${usertrip.brand.logoScale}*1.6em)`,
                }}
              />
            ) : (
              <>
                <Heading className="brand-name max-w-[270px] truncate">{usertrip.brand.name}</Heading>
              </>
            )}
          </Box>
          <Text textAlign="center" fontSize={"18px"} maxW={"600px"} m="auto">
            {usertrip.user.name} from {usertrip.brand.name} has invited you to collaborate on a trip:
          </Text>
          <Flex flexDir="column" gap={2} justifyContent={"flex-start"} alignItems={"center"} m="auto">
            <Flex
              mt={2}
              fontWeight={"bold"}
              gap={2}
              fontSize={"22px"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Text textAlign={"center"}>{trip.titles[0].content}</Text>
            </Flex>
            <Flex gap={2} fontSize={"18px"} alignItems={"center"} justifyContent={"flex-start"}>
              <Icon as={MdFamilyRestroom} />
              <Text>{trip.client_name}</Text>
            </Flex>
            <Flex gap={2} fontSize={"18px"} alignItems={"center"} justifyContent={"flex-start"}>
              <Icon as={IoCalendarNumber} />
              <Text>{trip.start_date}</Text>
            </Flex>
          </Flex>
          <Box m={"auto"} w={"fit-content"} mt={4}></Box>
        </Box>
        {/* <Text textAlign="center" maxW={"600px"} m="auto" mt={4}>
          Look at a{" "}
          <Link
            textDecoration={"underline"}
            color={colors.lato.primaryColor}
            _hover={{ color: "blue.400" }}
            href={viewTripURL}
            target={"_blank"}
          >
            preview
          </Link>{" "}
          of the trip in the travel app.
        </Text> */}
        <Text mb={7} textAlign={"center"}>
          Log in to Lato to view or adjust the trip. Once ready, share the trip with the traveler.
        </Text>
        <Button
          fontSize={"18px"}
          p={"1em"}
          colorScheme="brand"
          m="auto"
          onClick={() => (window.location.href = acceptCollaborationLink)}
        >
          Go to the trip in Lato
        </Button>
      </Flex>
    </SplitScreenLayout>
  );
};
export default TripCollaborateRoute;
