import { createContext } from "react";

/**
 *  Interface for  Notification Context
 * @notificationCount is number
 * @setNotificationCount is React State Dispatch function which recieve a number
 * */
interface INotificationContextState {
  notificationCount: number;
  setNotificationCount: (count: number) => void;
}

/**
 * Default/Initial Notification Context State
 */
const defaultContextState: INotificationContextState = {
  notificationCount: 0,
  setNotificationCount: () => {},
};

//Notification Context Created
export const NotificationContext = createContext(defaultContextState);
