import { AspectRatio } from "@chakra-ui/react";
import { Image as ImageType, TLibraryImage, TLocation } from "@lato/common";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import PicturesSwiper, { extraPictureAspectRatio } from "../../../../_app/TripDaysSwiper/PicturesSwiper";
import TripdayExtraPictureUploader from "../../../../_app/TripDaysSwiper/TripdayExtraPictureUploader";
import { resetRHFValues } from "../../../../components/fileUpload/TripdayImageDropzone";

interface PicturesUploaderProps {
  name: string;
  defaultSearchValue: string;
  defaultSearchValueName?: string;
  awsFilename: string;
  first?: boolean;
  location?: TLocation;
  afterChoosePictures?: (images: ImageType[]) => void;
  afterChooseLibraryPictures?: (images: ImageType[]) => void;
  addOffset?: boolean;
}

export const extraPictureWidth = "100px";

const PicturesUploader: React.FC<PicturesUploaderProps> = ({
  name,
  defaultSearchValue,
  defaultSearchValueName,
  awsFilename,
  first = true,
  location,
  afterChoosePictures,
  afterChooseLibraryPictures,
  addOffset = false,
}) => {
  // const { getValues, setValue, reset, control } =
  //   useFormContext<Trip>();
  const {
    fields: externalImages,
    append: externalAppend,
    replace: externalReplace,
    move,
  } = useFieldArray({
    // control,
    name: name as any,
    // @ts-ignore
    keyName: "rhfId", // default to "id", you can change the key name
    // defaultValues: getValues().tripdays[tripdayIndex].events[eventIndex].images,
  });

  const {
    fields: libraryImages,
    append: libraryAppend,
    replace: libraryReplace,
  } = useFieldArray({
    name: name.replace("images", "libraryImages") as any,
    keyName: "rhfId",
  });

  // const handlePictures = (images: ImageType[]) => {
  //   const newTripday: TripDay = {
  //     ...tripday,
  //     otherImages: [...tripday.otherImages, ...images],
  //   };
  //   const newTripdays = trip.tripdays;
  //   newTripdays[i] = newTripday;
  //   resetRHFValues(reset, { ...trip, tripdays: newTripdays });
  // };

  const images = [...externalImages, ...libraryImages].sort((a: any, b: any) =>
    a.ord < b.ord ? -1 : a.ord === b.ord ? 0 : 1,
  );

  const choosePictures = React.useCallback(
    (images: ImageType[]) => {
      externalAppend(images);
      if (afterChoosePictures) afterChoosePictures(images);
    },
    [externalAppend, afterChoosePictures],
  );

  const chooseLibraryPictures = React.useCallback(
    (images: TLibraryImage[]) => {
      libraryAppend(images);
      if (afterChooseLibraryPictures) afterChooseLibraryPictures(images);
    },
    [libraryAppend, afterChooseLibraryPictures],
  );

  const removePicture = React.useCallback(
    (index: number) => {
      if (first) {
        const imagesClone = [...images] as any[];
        imagesClone.splice(index, 1);
        const renamedOrdProps = imagesClone.map((image, i) => ({
          ...image,
          ord: i,
        }));
        externalReplace(renamedOrdProps.filter((image) => !image.hasOwnProperty("name")));
        libraryReplace(renamedOrdProps.filter((image) => image.hasOwnProperty("name")));
      }
      // const trip = getValues();
      // remove(index);
      // // Set the values of the 'ord' property.
      // trip.tripdays[tripdayIndex].events[eventIndex].images = renamedOrdProps;
      // resetRHFValues(reset, trip);
    },
    [externalReplace, libraryReplace, externalImages, libraryImages, images],
  );

  const movePicture = React.useCallback(
    (fromIndex: number, toIndex: number) => {
      // TODO: update the indices of the images such as in EventsList
      const imagesClone = [...images] as any[];
      imagesClone.splice(toIndex, 0, imagesClone.splice(fromIndex, 1)[0]);
      // console.log(eventsClone);
      const renamedOrdProps = imagesClone.map((event, i) => ({
        ...event,
        ord: i,
      }));

      externalReplace(renamedOrdProps.filter((image) => !image.hasOwnProperty("name")));
      libraryReplace(renamedOrdProps.filter((image) => image.hasOwnProperty("name")));
      // const trip = getValues();
      // move(fromIndex, toIndex);
      // // Set the values of the 'ord' property.
      // trip.tripdays[tripdayIndex].events[eventIndex].images = renamedOrdProps;
      // resetRHFValues(reset, trip);
    },
    [externalReplace, libraryReplace, images],
  );

  return (
    <div className="flex w-full justify-start">
      {/* <TripdayExtraPictureUploader
        eventIndex={eventIndex}
        i={tripdayIndex}
        choosePictures={choosePictures}
        aspectRatio={extraPictureAspectRatio}
        tripday={tripdays[tripdayIndex]}
      /> */}
      <AspectRatio
        ratio={extraPictureAspectRatio}
        w={extraPictureWidth}
        minW={extraPictureWidth}
        maxW={extraPictureWidth}
        display={first ? "flex" : "none"}
      >
        <TripdayExtraPictureUploader
          defaultSearchValue={defaultSearchValue}
          defaultSearchValueName={defaultSearchValueName}
          choosePictures={choosePictures}
          chooseLibraryPictures={chooseLibraryPictures}
          aspectRatio={extraPictureAspectRatio}
          nrOfImages={images.length}
          awsFilename={awsFilename}
          location={location}
        />
      </AspectRatio>
      <PicturesSwiper
        name={name}
        removePicture={removePicture}
        movePicture={movePicture}
        images={images}
        first={first}
        addOffset={addOffset}
      />
    </div>
  );
};
export default React.memo(PicturesUploader);
