import { useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";

import { CONTACTTYPE, Contact, omitLocalTimezone } from "@lato/common";
import ContactAPI from "../../api/contacts.api";
import { handleSubmission } from "../../utils/toErrorMap";
import { contactValidationSchema } from "../../validation/validationSchemas";
import CustomModal from "../../components/layout/CustomModal";
import ContactForm from "./ContactForm";

interface CreateContactProps {
  isOpen: boolean;
  onClose: (contact?: Contact) => void;
  defaultfirst_name?: string;
  defaultlast_name?: string;
  disableCompany?: boolean;
  buttonText?: string;
}

const CreateContact: React.FC<CreateContactProps> = ({
  isOpen,
  onClose,
  defaultfirst_name = "",
  defaultlast_name,
  disableCompany = false,
  buttonText = "Save",
}) => {
  const toast = useToast();

  const queryClient = useQueryClient();
  // Call API on Submit
  const { mutateAsync: createContact, isPending } = useMutation({
    mutationFn: (contactDTO: Contact) => ContactAPI.post(contactDTO),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get-contacts"],
      });
    },
  });
  const defaultValues = {
    first_name: defaultfirst_name,
    last_name: defaultlast_name,
    nick_name: undefined,
    vat: undefined,
    primaryEmail: "",
    phoneNumber: "",
    contact_type: CONTACTTYPE.PERSON,
    gender: undefined,
    date_of_birth: null,
    passport: {
      number: "",
      expiration_date: null,
    },
    notes: [],
    address: {
      country: null,
      city: "",
      zipCode: "",
      streetAddress: "",
    },
    documents: [],
  };

  const formMethods = useForm<Contact>({
    defaultValues,
    resolver: yupResolver(contactValidationSchema),
  });

  const onCloseFn = (contact?: Contact) => {
    onClose(contact);
    formMethods.reset(defaultValues);
  };

  const onSubmit = async (formValues: Contact) => {
    const contactDTO = {
      ...formValues,
      date_of_birth: formValues.date_of_birth && omitLocalTimezone(formValues.date_of_birth, true),
    };
    // Create the new group
    const res = await handleSubmission({
      successMessage: "Contact Created",
      failMessage: "Please try again",
      apiCall: createContact(contactDTO),
      toast,
      setError: formMethods.setError,
    });
    if (res && !res.errors) onCloseFn({ ...res });
  };

  return (
    <CustomModal title={`Create Contact`} onClose={onCloseFn} isOpen={isOpen} size="2xl" colorScheme="brand">
      <ContactForm
        onSubmit={onSubmit}
        formMethods={formMethods}
        onClose={onCloseFn}
        isLoadingSave={isPending}
        disableCompany={disableCompany}
        create
        buttonText={buttonText}
      />
    </CustomModal>
  );
};

export default React.memo(CreateContact);
