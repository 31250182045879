// This file extends the textarea theme
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/textarea.ts

import fontSizes from "../foundations/fontSizes";
import Input from "./input";

const noBorders: any = { ...Input.variants.noBorders.field };
delete noBorders.fontSize;

const baseStyle = {
  minHeight: "120px",
};

const variants = {
  lato: Input.variants.lato.field,
  noBorders: { ...noBorders },
  // Default chakra-ui implemented variants are kept.
};

// We have to copy paste this 'sizes' object because otherwise the default Chakra-UI Input sizes are used.
const sizes = {
  // xs: Input.sizes.xs.field,
  // sm: Input.sizes.sm.field,
  // md: Input.sizes.md.field,
  // lg: Input.sizes.lg.field,
  xs: Input.sizes.md.field,
  sm: Input.sizes.md.field,
  md: Input.sizes.md.field,
  lg: Input.sizes.md.field,
};

export default {
  defaultProps: Input.defaultProps,
  variants,
  sizes,
  baseStyle,
};
