import { Icon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import { ChevronDown, ChevronRight } from "lucide-react";
import React from "react";
import { ResourceTitle } from "../../../../../CRUD/Resource";
import RHFInput from "../../../../../input/RHFInput";

interface SupplierInformationProps {
  placeholderChain?: string;
  placeholderRef?: string;
  first?: boolean;
}

export const CollapsableSupplierInformation: React.FC<SupplierInformationProps> = ({}) => {
  const [collapsed, setCollapsed] = React.useState(true);

  return (
    <>
      <Flex
        width={"100%"}
        gap={2}
        alignItems={"center"}
        onClick={() => setCollapsed(!collapsed)}
        cursor={"pointer"}
        py={2}
      >
        <Icon
          aria-label={collapsed ? "expand" : "collapse"}
          as={collapsed ? ChevronRight : ChevronDown}
          color={"black"}
          boxSize={4}
        ></Icon>
        <ResourceTitle displayName={"Supplier information"} inForm>
          {/*<Icon as={icon} mb={"0.3em"} boxSize={7} mr={"0.6em"} />*/}
        </ResourceTitle>
      </Flex>
      {!collapsed && <SupplierInformation />}
    </>
  );
};

export const SupplierInformation: React.FC<SupplierInformationProps> = ({
  placeholderChain,
  placeholderRef,
  first = true,
}) => {
  return (
    <>
      <Flex w={"100%"} gap={3}>
        <RHFInput name="chain" label="Supplier" placeholder={placeholderChain} isDisabled={!first ?? undefined} />
        <RHFInput
          name="chainRef"
          label="Supplier Reference"
          placeholder={placeholderRef}
          isDisabled={!first ?? undefined}
        />
      </Flex>
    </>
  );
};
