import {
  Box,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { BsPersonFill } from "react-icons/bs";
import CounterInput from "../../input/CounterInput";
import { default as InputWrapper } from "../../input/InputWrapper";
import RHFSelect from "../../input/RHFSelect";
import { numberToOrder } from "../../trips/edit/daybyday/library-items/hotel/BookModal";

interface HolderPickerProps {
  beforeClose?: () => void;
  borderRadius?: string;
}

const HolderPicker: React.FC<HolderPickerProps> = ({ beforeClose, borderRadius = "5px" }) => {
  const fieldArrayName = "rooms.0";

  const { onOpen, onClose, isOpen } = useDisclosure();

  const { setValue } = useFormContext();

  const { field: children } = useController({
    name: `${fieldArrayName}.children`,
  });

  const adults = useWatch({
    name: `${fieldArrayName}.adults`,
  });

  const handleOnClose = () => {
    // Call a function before closing the popover.
    if (beforeClose) beforeClose();
    // The real onClose function of the popover disclosure.
    onClose();
  };

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onOpen={onOpen} onClose={handleOnClose}>
      <PopoverAnchor>
        <InputGroup onClick={() => onOpen()}>
          <Input
            borderRadius={borderRadius}
            value={`${adults} ${`adult` + (adults > 1 ? "s" : "")} ${children.value > 0 ? ` - ${children.value} ${`child` + (children.value > 1 ? "ren" : "")}` : ""} `}
          />
          <InputRightElement cursor="pointer">
            <IconButton
              icon={<Icon as={BsPersonFill} />}
              // Custom button variant
              variant="basic"
              aria-label="daterange-calendar-icon"
            />
          </InputRightElement>
        </InputGroup>
      </PopoverAnchor>
      <Portal>
        <PopoverContent w="100%">
          <PopoverArrow />
          <PopoverBody>
            <Flex flexDir={"column"} gap={3}>
              <InputWrapper horizontal label={"adults"} name={"adults"}>
                <CounterInput onChange={(e) => setValue(`${fieldArrayName}.adults`, e)} min={1} defaultValue={adults} />
              </InputWrapper>
              <InputWrapper horizontal label={"children"} name={"children"}>
                <CounterInput onChange={children.onChange} min={0} defaultValue={children.value} />
              </InputWrapper>
              <Flex w={"50%"} flexDir={"column"} gap={1}>
                {Array.from(new Array(+children.value)).map((_, i) => (
                  <InputWrapper
                    label={`${i + 1}${numberToOrder(i + 1)} Child's Age`}
                    name={`${fieldArrayName}.ages.${i}`}
                    bottomMarginLabel="0"
                  >
                    <RHFSelect
                      name={`${fieldArrayName}.ages.${i}`}
                      options={Array.from(new Array(18)).map((_, i) => ({ value: i, text: `${i} years` }))}
                    />
                  </InputWrapper>
                ))}
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default HolderPicker;
