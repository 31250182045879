import { Box, Input, Select, Switch, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { Country, DefaultDocument, Document, EDocument, QueryType, isLink } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { CellProps, Column, Filters, useAsyncDebounce } from "react-table";
import CountryAPI from "../../../api/countries.api";
import DefaultDocumentsAPI from "../../../api/default-documents.api";
import { useMeContext } from "../../../stores/me-context";
import { useTripsListStore } from "../../../stores/trip/tripslistStore";
import { addToast } from "../../../utils/addToast";
import { useParamsQueryHelper } from "../../../utils/query-helpers/reactQueryHooks";
import { submitDocumentsArray } from "../../../utils/submitDocumentsArray";
import CountryFilter from "../../CRUD/CountryFilter";
import CRUDResource from "../../CRUD/Resource";
import FullScreenSpinner from "../../FullScreenSpinner";
import DocumentUploadModal from "../../../features/documents/DocumentUploadModal";
import { documentDisplay, view_document_cell } from "../../../features/documents/Documents";
import ErrorCardView from "../../layout/ErrorCardView";

interface DefaultDocumentsProps {
  id: string;
}

const DefaultDocuments: React.FC<DefaultDocumentsProps> = ({ id }) => {
  const me = useMeContext();

  const [orderBy, setOrderBy] = React.useState<string | undefined>();
  const [order, setOrder] = React.useState<"DESC" | "ASC">("DESC");
  const [search, setSearch] = React.useState("");

  const toast = useToast();
  const { countryFilter } = useTripsListStore();

  const triggerFetch = React.useCallback(
    ({
      order,
      orderBy,
      q,
      filters,
    }: {
      q: string;
      order: "DESC" | "ASC" | undefined;
      orderBy: string | undefined;
      filters: Filters<DefaultDocument>;
    }) => {
      setOrderBy(orderBy);
      setOrder(order || "DESC");
      setSearch(q);
    },
    [
      setOrderBy,
      setOrder,
      setSearch,
      //setGenderFilter,
    ],
  );

  const { data, isLoading, error } = useParamsQueryHelper<DefaultDocument[]>({
    queryKey: "default-documents",
    apiCall: DefaultDocumentsAPI.getAll,
    queryParams: {
      orderBy,
      order,
      q: search,
      countries: countryFilter,
    } as any,
    options: {},
  });

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: countriesError,
  } = useParamsQueryHelper<Country[]>({
    queryKey: "get-countries",
    apiCall: CountryAPI.getAll,
    queryParams: {} as any,
    options: {},
  });

  const queryClient = useQueryClient();

  const { mutateAsync: createDocument } = useMutation({
    mutationFn: (doc: Partial<ExtendedDefaultDocument>) => DefaultDocumentsAPI.post(doc),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: ["default-documents"],
      });
      addToast(toast, {
        title: "Succesfully added document",
        status: "success",
      });
    },
    onError: (error) => {
      addToast(toast, {
        title: "Unable to add document",
        status: "error",
      });
    },
  });

  const { mutateAsync: updateDocument } = useMutation({
    mutationFn: (doc: ExtendedDefaultDocument) =>
      DefaultDocumentsAPI.patch(doc.id!, { ...doc, displayName: doc?.document as any }),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: ["default-documents"],
      });
      addToast(toast, {
        title: "Successfully updated document",
        status: "success",
      });
    },
  });

  const { mutateAsync: deleteDocument } = useMutation({
    mutationFn: (id: string) => DefaultDocumentsAPI.delete(id),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: ["default-documents"],
      });
      addToast(toast, {
        title: "Successfully removed document",
        status: "success",
      });
    },
  });

  const disclosure = useDisclosure();

  type ExtendedDefaultDocument = DefaultDocument & { document?: object };

  const columns: Column<ExtendedDefaultDocument>[] = React.useMemo(
    () => [
      {
        accessor: "id",
        id: "name",
        Header: "Name*",
        Cell: ({ value, row, column }: CellProps<ExtendedDefaultDocument>) => {
          return (
            <Input
              key={`default-document-name-${row.original?.id}`}
              onClick={(e) => e.stopPropagation()}
              onChange={useAsyncDebounce(
                (e) =>
                  updateDocument({
                    ...row.original,
                    document: {
                      ...row.original.document,
                      displayName: e.target.value,
                    },
                  }),
                400,
              )}
              defaultValue={(row.original?.document as any)?.displayName}
              isDisabled={column.isDisabled}
              placeholder={column.placeholder}
            />
          );
        },
      },
      {
        accessor: "document",
        id: "type",
        Header: "Type*",
        Cell: ({ value, row, column }: CellProps<ExtendedDefaultDocument>) => {
          return (
            <>
              <Select
                // @ts-ignore
                // isDisabled={value === EDocument.LINK}
                defaultValue={value?.type}
                key={`default-document-type-${row.original?.id}`}
                onChange={(e) =>
                  updateDocument({
                    ...row.original,
                    document: {
                      ...row.original.document,
                      type: e.target.value as EDocument,
                    },
                  })
                }
              >
                {/* Never iterate over these! For some reason this will introduce some bugs */}
                {isLink(value?.type)
                  ? Object.values(EDocument)
                      .filter((doc) => isLink(doc))
                      .map((doc) => (
                        <option key={`doc-type-${doc}`} value={doc}>
                          {documentDisplay[doc]}
                        </option>
                      ))
                  : Object.values(EDocument)
                      .filter((doc) => !isLink(doc))
                      .map((doc) => (
                        <option key={`doc-type-${doc}`} value={doc}>
                          {documentDisplay[doc]}
                        </option>
                      ))}
              </Select>
            </>
          );
        },
      },
      {
        accessor: "country",
        Header: "country",
        chakraWidth: "60px",
        Cell: ({ row, value, column }) => {
          return (
            <Text>{row.original?.country?.name}</Text>
            // <Select
            //   placeholder="All trips"
            //   disabled
            //   defaultValue={row.original?.country?.iso}
            //   onChange={(e) =>
            //     updateDocument({
            //       ...row.original,
            //       country: countries!.find((country) => country.iso === e.target.value) ?? undefined,
            //     })
            //   }
            //   key={`default-document-country-${row.original?.id}`}
            // >
            //   {(countries ?? []).map((country) => (
            //     <option key={`doc-country-${country.iso}`} value={country.iso}>
            //       {country.name}
            //     </option>
            //   ))}
            // </Select>
          );
        },
        Filter: (props: any) => {
          return <CountryFilter {...props} type={QueryType.DEFAULTDOCUMENT} />;
        },
        disableFilters: false,
      },
      {
        id: "view-document",
        isNumeric: true,
        chakraWidth: "60px",
        Cell: ({ row }: CellProps<ExtendedDefaultDocument>) => view_document_cell(row.original.document as Document),
      },
      {
        Header: "enabled",
        accessor: "enabled",
        id: "enable-document",
        isNumeric: true,
        chakraWidth: "60px",
        Cell: ({ row, value }: CellProps<DefaultDocument>) => {
          return (
            <Switch
              ml={3}
              colorScheme="brand"
              onChange={(e) => updateDocument({ ...row.original, enabled: !value })}
              defaultChecked={value}
              key={`default-document-enabled-${row.original?.id}`}
            ></Switch>
          );
        },
      },
    ],
    [countries],
  );

  if (isLoadingCountries) return <FullScreenSpinner />;
  if (countriesError || !countries) return <ErrorCardView title="Failed fetching countries" />;

  const addDocument = async (doc: Partial<Document>, selectedCountry?: string | undefined) => {
    await submitDocumentsArray([doc], `users/${me.id}/`);
    await createDocument({
      enabled: true,
      document: {
        ...doc,
        ord: 0,
        displayName: doc?.displayName,
      },
      country: countries.find((country) => country.iso === selectedCountry) ?? undefined,
    });
    return;
  };

  return (
    <Box id={id}>
      <CRUDResource
        formName={""}
        displayName="Default documents"
        columns={columns as any}
        data={data ?? []}
        handleAdd={disclosure.onOpen}
        handleDelete={(i, e) => deleteDocument(e.id)}
        inlineEdit={true}
        inForm={false}
        globalSearch={true}
        triggerFetch={triggerFetch}
        maxTableHeight={"20em"}
        searchBarPlaceholder={"Search by name, type or country"}
      />
      {disclosure.isOpen && (
        <DocumentUploadModal disclosure={disclosure} addDocument={addDocument} countries={countries} />
      )}
    </Box>
  );
};

export default DefaultDocuments;
