import { Box, Heading, Icon, IconButton, Stack, Tooltip, useDisclosure } from "@chakra-ui/react";
import { POIType } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { Trash2 as DeleteIcon } from "lucide-react";
import React, { useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { BsPin } from "react-icons/bs";
import POIsAPI, { POI } from "../../api/pois.api";
import { TripLanguageProvider } from "../../stores/trip-language-context";
import { ELEMENT_VIEW } from "../../components/CRUD/Resource";
import InputWrapper from "../../components/input/InputWrapper";
import RHFSelect from "../../components/input/RHFSelect";
import TranslateTitle from "../../components/input/TranslateTitle";
import AutocompleteStaticMap from "../map/AutocompleteStaticMap";
import StaticMap from "../../components/map/staticMap";
import PicturesUploader from "../../components/trips/edit/daybyday/PicturesUploader";
import LibraryItemDownshift, {
  LIBRARYITEM_TYPE,
  LibraryItemInTripProps,
} from "../../components/trips/edit/daybyday/library-items/LibraryItemDownshift";
import { Descriptions } from "../../components/trips/edit/daybyday/library-items/hotel/Descriptions";
import ConfirmModal from "../../components/modals/ConfirmModal";

interface POIFormProps {
  tripProps: LibraryItemInTripProps | null;
  selectedLanguageIndex?: number;
  updatePOI: any;
  onDelete?: any;
  isCreate?: boolean;
  view?: ELEMENT_VIEW;
}

export const POIForm: React.FC<POIFormProps> = ({
  tripProps,
  selectedLanguageIndex = 0,
  updatePOI,
  onDelete,
  isCreate,
  view,
}) => {
  const ref = React.useRef(null);
  const fieldName = `descriptions`;
  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext<any>();
  const confirmDisclosure = useDisclosure();
  const first = tripProps ? tripProps.first : true;
  const unique = tripProps?.uniqueId;
  // const locationName = poi ? poi.names?.length > 0 ? poi.names[0].content : "" : "";
  const poi = getValues() as POI;
  const locationName = "";
  const hasDelete = onDelete !== undefined;

  return (
    <Box className="flex flex-col space-y-2 items-stretch" ref={ref}>
      <Stack direction="row" alignItems="center">
        <Icon as={BsPin} boxSize={5} />
        <Heading size="xl" fontSize={"22px"}>
          Point of Interest
        </Heading>
        {/* Only show the delete icon when the form for updating and there is a delete method (handeld in LibraryItemModal.tsx) */}
        {!isCreate && hasDelete && view === ELEMENT_VIEW.MAP && (
          <Tooltip label="Delete POI" aria-label="Delete POI" hasArrow>
            <IconButton
              aria-label="Delete POI"
              size="sm"
              onClick={() => confirmDisclosure.onOpen()}
              icon={<DeleteIcon />}
              colorScheme="red"
              variant="outline"
              border="none"
              marginLeft="auto"
            />
          </Tooltip>
        )}
      </Stack>
      <ConfirmModal
        title="Delete POI"
        description="Are you sure you want to delete this POI? It will be deleted from all trips."
        disclosure={confirmDisclosure}
        action={onDelete}
      />
      <POIOutsideTrip
        fieldName={fieldName}
        selectedLanguageIndex={selectedLanguageIndex}
        poi={poi}
        updatePOI={updatePOI}
        locationName={locationName}
        unique={unique}
      />
      <PicturesUploader
        name={`images`}
        defaultSearchValue={locationName}
        defaultSearchValueName={getValues(`location.name`) ? "location.name" : "names.0.content"}
        awsFilename={`accommodation`}
        first={first}
        location={getValues(`location`)}
      />
    </Box>
  );
};

const POIStaticMap = (selectedLanguageIndex: any) => {
  const { field: location } = useController({
    name: `location`,
  });

  return (
    <>
      <AutocompleteStaticMap
        fillFieldName={`names.${selectedLanguageIndex}.content`}
        fromFieldName={`location`}
        fromLocationValue={location.value}
        setGeometryFromValue={(coordinates: any, name: string, address: any) => {
          if (coordinates === null) {
            location.onChange(null);
          } else {
            location.onChange({
              name: name,
              address: address,
              coordinates: coordinates,
            });
          }
        }}
      />
      {location?.value?.coordinates && (
        <StaticMap
          containerHeight="11.2rem"
          containerWidth="100%"
          height={640}
          width={1000}
          center={{
            lng: location.value.coordinates[0],
            lat: location.value.coordinates[1],
          }}
        />
      )}
    </>
  );
};

interface POIOutsideTripProps {
  fieldName: string;
  selectedLanguageIndex: number;
  poi: POI;
  updatePOI: any;
  locationName: string;
  unique: any;
  updateActivity?: () => void;
}

const POIOutsideTrip: React.FC<POIOutsideTripProps> = ({
  fieldName,
  selectedLanguageIndex,
  poi,
  locationName,
  updatePOI,
  unique,
}) => {
  const { getValues, reset } = useFormContext<any>();
  const [id, setId] = useState(getValues("id"));

  const queryClient = useQueryClient();

  const translationFields = getValues(fieldName);

  const addLanguageCall = async (id: string, code: string, translateFields: any) => {
    const formValues = getValues();
    let response;
    if (!id) {
      response = await POIsAPI.post({ ...formValues, id });
      id = response.id;
    } else {
      await POIsAPI.patch(id, formValues);
    }
    setId(id);
    response = await POIsAPI.addLanguage(id, code, translateFields);
    reset(response.poi);
    updatePOI(response.poi);
  };

  const removeLanguageCall = async (id: string, code: string) => {
    const formValues = getValues();
    let response;
    if (!id) {
      response = await POIsAPI.post({ ...formValues, id });
      id = response.id;
    } else {
      await POIsAPI.patch(id, formValues);
    }
    setId(id);
    response = await POIsAPI.removeLanguage(id, code);
    reset(response.poi);
    updatePOI(response.poi);
  };

  return (
    <>
      <TripLanguageProvider doubleFields={false} first={true} defaultPartialFields={translationFields}>
        <TranslateTitle
          isFirst={true}
          name={fieldName}
          getValues={getValues}
          elementId={id}
          addLanguageCall={addLanguageCall}
          removeLanguageCall={removeLanguageCall}
        />

        <LibraryItemDownshift
          inTrip={false}
          outsideTripLibraryitem={getValues()}
          libraryItemName={LIBRARYITEM_TYPE.POIS}
          POILocationName={locationName}
          first={true}
          isPoi={true}
          getValues={getValues}
          location={poi.location ?? null}
          collaboratorCanEdit={false}
          reset={updatePOI}
        />

        <POIStaticMap selectedLanuageIndex={selectedLanguageIndex} />
        <InputWrapper name="type" label="Category">
          <RHFSelect
            name={`type`}
            options={Object.values(POIType).map((g) => ({ value: g }))}
            defaultValue={poi ? poi.type : POIType.OTHER}
          />
        </InputWrapper>
        <Descriptions fieldName={fieldName} elementType="Poi" />
      </TripLanguageProvider>
    </>
  );
};
