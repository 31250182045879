import { Autocomplete as GoogleAutocomplete } from "@react-google-maps/api";
import React, { ReactChild } from "react";

interface MapsAutocompleteProps {
  handlePlaceChanged(place: any): void;
  children: ReactChild;
}

const placesFields = [
  "name",
  "geometry.location",
  //
  "address_components",
  // A link to the location on Google Maps.
  "url",
  //
  "utc_offset_minutes",
  //
  "formatted_address",
  "adr_address",
];

export const closeAllAutocompleteBoxes = () => {
  // Hide all autocomplete boxes upon clicking an option.
  // This is needed because there was a bug in Safari and Firefox where multiple autocomplete boxes stayed open.
  const allAutocompletes = document.querySelectorAll<HTMLElement>(".pac-container");
  allAutocompletes.forEach((autocomplete) => {
    autocomplete.style.display = "none";
  });
};

const MapsAutocomplete: React.FC<MapsAutocompleteProps> = ({ handlePlaceChanged, children }) => {
  const [autocompleteRef, setAutocompleteRef] = React.useState<any>(null);
  const [googleMapsRef, setGoogleMapsRef] = React.useState<google.maps.Map | null>();
  // const placeMarker = (position: google.maps.LatLng, name: string) => {
  //   new window.google.maps.Marker({
  //     position: position,
  //     map,
  //     label: (selectedRecord + 1).toString(),
  //     title: name,
  //   });
  // };

  const onLoad = (ref: any) => {
    setAutocompleteRef(ref);
  };

  const onPlaceChanged = () => {
    console.log("onPlaceChanged...");
    closeAllAutocompleteBoxes();
    if (autocompleteRef) {
      // The language of this get place query is determined by the language set in the useLoadGoogleMap
      const place = autocompleteRef.getPlace();
      if (!place.geometry) {
        // handleEnterWithoutSelection();
        // window.alert(
        //   "Please make sure to select a valid option before pressing 'Enter'."
        // );
        // return;
      }
      handlePlaceChanged(place);
    } else {
      console.log("Autocomplete box not loaded yet!");
    }
  };

  return (
    // Autocomplete returns only 1 result while SearchBox can return more (for example when you search 'winkel' it will show 20 winkels).
    // We could use SearchBox for the Points of Interest, however, we can't restrict the response fields so we have to pay for all returned fields.
    // If pricing is not too high maybe switch to the SearchBox component for points of interest -> https://stackoverflow.com/questions/65399542/react-google-maps-api-how-to-search-current-location-for-a-search-result
    <GoogleAutocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad} fields={placesFields}>
      {children}
    </GoogleAutocomplete>
  );
};
export default MapsAutocomplete;
