import { TRANSPORTATION_TYPE } from "../../types/index.js";
import { getDistanceFromLatLonInKm } from "./geoUtils.js";
import { MINIMUM_IMPLIED_DISTANCE } from "./getTransportations.js";
export function getGroupedTransportations(transportations) {
    // We group the transportations per transportation type
    // This way car, bikes and foot routes can be retreived with one Directions API call
    // Flights and trains are filtered out and act as group dividers, these will be shown separately
    const transportation_grouped_by_type = [];
    let transportations_copy = [...transportations];
    const filtered_types = [
        TRANSPORTATION_TYPE.FLIGHT,
        TRANSPORTATION_TYPE.TRAIN,
        TRANSPORTATION_TYPE.BIKE,
        TRANSPORTATION_TYPE.FOOT,
        TRANSPORTATION_TYPE.BOAT,
        TRANSPORTATION_TYPE.CUSTOM,
    ];
    while (transportations_copy.some((t) => filtered_types.includes(t.type))) {
        const i = transportations_copy.findIndex((t) => filtered_types.includes(t.type));
        transportation_grouped_by_type.push(transportations_copy.slice(0, i));
        if ([
            TRANSPORTATION_TYPE.BIKE,
            TRANSPORTATION_TYPE.FOOT,
            TRANSPORTATION_TYPE.BOAT,
            TRANSPORTATION_TYPE.CUSTOM,
        ].includes(transportations_copy[i].type)) {
            transportation_grouped_by_type.push([transportations_copy[i]]);
        }
        transportations_copy = transportations_copy.slice(i + 1);
    }
    transportation_grouped_by_type.push(transportations_copy);
    // Now we have groups of transportations with the same type (car, bike, foot)
    // Because implied trasportations are already added, transportations that don't arrive close to the next transportation should be grouped separately
    const transportation_groups = [];
    transportation_grouped_by_type.forEach((transportation_group) => {
        let foo = transportation_group;
        for (let j = 0; j < transportation_group.length - 1; j++) {
            if (getDistanceFromLatLonInKm({
                lat: transportation_group[j].arrivalLocation?.coordinates[0],
                lng: transportation_group[j].arrivalLocation?.coordinates[1],
            }, {
                lat: transportation_group[j + 1].departureLocation?.coordinates[0],
                lng: transportation_group[j + 1].departureLocation?.coordinates[1],
            }) > MINIMUM_IMPLIED_DISTANCE) {
                transportation_groups.push(foo.slice(0, j + 1));
                foo = foo.slice(j + 1);
            }
        }
        transportation_groups.push(foo);
    });
    console.log("transports_grouped", transportation_groups);
    return transportation_groups;
}
