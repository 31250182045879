import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "destinations";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

// Add language to event
const addLanguage = (
  id: string,
  language_code: string,
  translateFields?: {
    targetLanguage: string;
    sourceLanguage?: string;
    translateFromUserCompanyId?: string;
  },
) => {
  return rawApiProvider.post(`${url}/${id}/addLanguage`, {
    language_code,
    translateFields,
  });
};

//remove language from event
const removeLanguage = (id: string, language_code: string) => {
  return rawApiProvider.post(`${url}/${id}/removeLanguage`, {
    language_code,
  });
};

const removeDestination = (id: string) => {
  return rawApiProvider.remove(`${url}/${id}`);
};

const getDestinationByTripdayId = (tripdayId: string) => {
  return rawApiProvider.get(`${url}/tripday/${tripdayId}`);
};

const duplicate = (id: string) => {
  return rawApiProvider.post(url + "/duplicate", {
    id,
  });
};

type DestinationsAPIType = ReturnEndpoints & {
  getDestinationByTripdayId: (tripdayId: string) => Promise<any>;
  addLanguage: (
    id: string,
    language_code: string,
    translateFields?: {
      targetLanguage: string;
      sourceLanguage?: string;
      translateFromUserCompanyId?: string;
    },
  ) => Promise<any>;
  removeLanguage: (id: string, language_code: string) => Promise<any>;
  removeDestination: (id: string) => Promise<any>;
  duplicate: (id: string) => Promise<any>;
};
const DestinationsAPI: DestinationsAPIType = {
  ...apiEndpoints,
  getDestinationByTripdayId,
  addLanguage,
  removeLanguage,
  removeDestination,
  duplicate,
};
export default DestinationsAPI;
