import { Flex, Heading, Stack, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMeContext } from "../../../../stores/me-context";
import Preview from "../../../../features/editTrip/previewStep/tripday-preview/TripdayPreview";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { canSwitch, submitForms } from "../../../../utils/EventHelper";
import { DAY_BY_DAY_STEP_TRIP_QUERY_KEY } from "../../../../utils/constants";
import { getRelationMap, useSplitTrip } from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { ElementSkeleton, PreviewSkeleton, WizardSkeleton } from "../../../FullScreenSpinner";
import TripSwiperSlide from "../../../../features/editTrip/previewStep/TripSwiperSlide";
import { DisabledMessage } from "../../../accessiblity/overlay";
import Wizard from "../../../../features/editTrip/wizard/Wizard";
import DayScrollerVertical from "./DayScrollerVertical";
import { getRoute } from "./getRoute";
import { limitNumberWithinRange } from "./limitNumberWithinRange";

interface TripPreviewStepProps {
  stepNumber: number;
}

const TripPreviewStep: React.FC<TripPreviewStepProps> = ({ stepNumber }) => {
  const navigate = useNavigate();
  const me = useMeContext();
  const { id: userTripId, dayIndex } = useParams<{ id: string; dayIndex: string }>();
  const [isSmallScreen] = useMediaQuery("(max-width: 960px)");

  const [selectedAccommodationIndex, setSelectedAccommodationIndex] = React.useState<number>(0);
  const {
    isFirst,
    isSample,
    trip: tripFromStore,
    isSubmittingForms,
    isInvalidForms,
    isErrorForms,
    setUnCollapsedElement,
  } = useTripFormStore();

  const {
    data: trip,
    isLoading: isLoadingTrip,
    isFetching: isFetchingTrip,
  } = useSplitTrip(
    tripFromStore?.id ?? "",
    DAY_BY_DAY_STEP_TRIP_QUERY_KEY,
    getRelationMap[DAY_BY_DAY_STEP_TRIP_QUERY_KEY],
  );

  const handleDayChange = async (value: number) => {
    if (canSwitch(isInvalidForms, isErrorForms)) {
      await submitForms(isSubmittingForms);
      if (canSwitch(isInvalidForms, isErrorForms)) {
        navigate(getRoute(userTripId!, value, isSample));
      }
    }
  };

  useEffect(() => {
    if (trip) {
      const tripdaysLength = trip.tripdays?.length;
      const limitedDayIndex = dayIndex ? limitNumberWithinRange(Number(dayIndex), tripdaysLength - 1) : 0;

      if (Number(dayIndex) >= tripdaysLength) {
        navigate(getRoute(userTripId!, limitedDayIndex, isSample));
      }

      if (!dayIndex) {
        navigate(getRoute(userTripId!, 0, isSample));
      }
    }
  }, [dayIndex, isSample, navigate, trip, trip?.tripdays?.length, userTripId]);

  const start_date = trip?.start_date;
  const tripdays = trip?.tripdays;

  if (tripdays?.length === 0) {
    return (
      <Flex w="100%" h="75vh" alignItems="center" justifyContent="center">
        <Heading>No days added yet, please first add a day in the previous step.</Heading>
      </Flex>
    );
  }

  const disabledDaySwitch =
    [...isSubmittingForms.values()].some((value) => value === true) ||
    [...isErrorForms.values()].some((value) => value === true) ||
    [...isInvalidForms.values()].some((value) => value === true);

  const i = dayIndex ? limitNumberWithinRange(Number(dayIndex), (trip?.tripdays?.length || 1) - 1) : 0;
  const tripdayId = useMemo(() => trip?.tripdays[i]?.id, [i, trip?.tripdays]);

  return (
    <>
      {!me.useNewEditTripLayout &&
        (trip ? (
          <Wizard
            trip={trip}
            collaboratorCanEdit={trip.collaboratorCanEdit}
            stepNumber={stepNumber}
            isFirst={isFirst}
            sample={isSample}
            type="Edit"
            translateTitleWidth="65%"
          />
        ) : (
          <WizardSkeleton />
        ))}
      {!isFirst && !trip?.collaboratorCanEdit && (
        <DisabledMessage margin={2} collaboratorCanEdit={trip?.collaboratorCanEdit} />
      )}
      <Flex
        flexDir={!me.useNewEditTripLayout ? ["column", "column", "column", "row", "row"] : "row"}
        w="100%"
        position={"relative"}
      >
        {!me.useNewEditTripLayout && (
          <div className="relative min-[992px]:sticky top-[12.2%] max-[991px]:w-full">
            {trip && tripdays && !isFetchingTrip && (
              <DayScrollerVertical
                i={i}
                trip={trip}
                tripdays={tripdays}
                // activeDay={tripdaySwiperPosition}
                setActiveDay={handleDayChange}
                start_date={start_date || null}
                disabledDaySwitch={disabledDaySwitch}
              />
            )}
          </div>
        )}
        <Stack
          gap={4}
          className={
            me.useNewEditTripLayout
              ? "relative flex-1 w-[40vw] overflow-hidden"
              : "relative flex-1 min-w-0 max-[991px]:w-full"
          }
          mx={{ base: 4, xl: 12 }}
          mr={{ base: 4, xl: 20 }}
        >
          {isLoadingTrip || isFetchingTrip || !trip?.tripdays?.length ? (
            <ElementSkeleton />
          ) : (
            <TripSwiperSlide
              tripdayId={tripdayId!}
              trip={trip}
              i={i}
              selectedAccommodationIndex={selectedAccommodationIndex}
              setSelectedAccommodationIndex={setSelectedAccommodationIndex}
            />
          )}
        </Stack>
        {me.useNewEditTripLayout && (
          <div className="space-y-4 h-[calc(100vh-4.5rem)] sticky top-16 w-1/4 xl:w-1/5">
            {!tripdays || isFetchingTrip ? (
              <PreviewSkeleton />
            ) : (
              <Preview
                // tripdayIndex={tripdaySwiperPosition}
                trip={trip}
                changeTripday={handleDayChange}
                tripdayId={tripdayId!}
                disabledDaySwitch={disabledDaySwitch}
                i={i}
              />
            )}
            {!tripdays && !isFetchingTrip ? (
              <PreviewSkeleton />
            ) : (
              trip &&
              tripdays && (
                <DayScrollerVertical
                  i={i}
                  trip={trip}
                  tripdays={tripdays}
                  setActiveDay={handleDayChange}
                  start_date={start_date || null}
                  disabledDaySwitch={disabledDaySwitch}
                />
              )
            )}
          </div>
        )}
        {!isSmallScreen && !me.useNewEditTripLayout && (
          <div className="relative min-[992px]:sticky top-[16%] max-[991px]:w-full max-[991px]:hidden">
            {!trip?.tripdays.length || isFetchingTrip || !tripdayId ? (
              <PreviewSkeleton />
            ) : (
              <Preview
                // tripdayIndex={tripdaySwiperPosition}
                trip={trip}
                changeTripday={handleDayChange}
                tripdayId={tripdayId}
                disabledDaySwitch={disabledDaySwitch}
                i={i}
              />
            )}
          </div>
        )}
      </Flex>
    </>
  );
};
export default React.memo(TripPreviewStep);
