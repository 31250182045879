import { EditIcon } from "@chakra-ui/icons";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import ContactAPI from "../../api/contacts.api";
import { Contact, omitLocalTimezone } from "@lato/common";
import { GENERAL_STEP_CONTACTS_QUERY_KEY } from "../../utils/constants";
import { handleSubmission } from "../../utils/toErrorMap";
import { contactValidationSchema } from "../../validation/validationSchemas";
import { OptionsDropdownItem } from "../../components/CRUD/OptionsDropdown";
import CustomModal from "../../components/layout/CustomModal";
import ContactForm from "./ContactForm";

interface EditContactProps {
  contactDetail: Contact;
}

const EditContact: React.FC<EditContactProps> = ({ contactDetail }) => {
  const modalDisclosure = useDisclosure();
  const { onOpen } = modalDisclosure;

  return (
    <>
      <EditContactModal contactDetail={contactDetail} modalDisclosure={modalDisclosure} />
      <OptionsDropdownItem onClick={(e) => onOpen()} icon={<EditIcon boxSize={3} />} name="Edit Contact" />
    </>
  );
};
export default React.memo(EditContact);

interface EditContactModal {
  contactDetail: Contact;
  modalDisclosure: any;
  reset?: (contact: Contact) => void;
  customOnClose?: () => void;
  loyaltyProgramsOnly?: boolean;
}

export const EditContactModal: React.FC<EditContactModal> = ({
  contactDetail,
  modalDisclosure,
  reset,
  customOnClose,
  loyaltyProgramsOnly = false,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  // Call API on Submit
  const { mutateAsync: updateContact, isPending } = useMutation({
    mutationFn: (contactDTO: Contact) => ContactAPI.patch(contactDetail.id!, contactDTO),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get-contacts"],
      });
      queryClient.invalidateQueries({
        queryKey: ["get-contact"],
      });
      queryClient.invalidateQueries({
        queryKey: ["usertrip"],
      });
      queryClient.invalidateQueries({
        queryKey: [GENERAL_STEP_CONTACTS_QUERY_KEY],
      });
    },
  });

  const formMethods = useForm<Contact>({
    defaultValues: {
      ...contactDetail,
    },
    resolver: yupResolver(contactValidationSchema),
  });

  useEffect(() => {
    formMethods.reset({ ...contactDetail });
  }, [contactDetail]);

  const onClose = () => {
    customOnClose && customOnClose();
    modalDisclosure.onClose();
    formMethods.reset({ ...contactDetail });
  };
  const onSubmit = async (formValues: Contact) => {
    const contactDTO = {
      ...formValues,
      date_of_birth: formValues.date_of_birth && omitLocalTimezone(formValues.date_of_birth, true),
    };
    reset && reset(contactDTO);
    const res = await handleSubmission({
      successMessage: "updated contact",
      failMessage: "updating contact",
      apiCall: updateContact(contactDTO),
      toast,
      setError: formMethods.setError,
    });
    if (!res?.errors) onClose();
  };
  return (
    <CustomModal
      title={`Edit Contact`}
      onClose={onClose}
      isOpen={modalDisclosure.isOpen}
      size="2xl"
      colorScheme="brand"
    >
      <ContactForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        onClose={onClose}
        isLoadingSave={isPending}
        loyaltyProgramsOnly={loyaltyProgramsOnly}
      />
    </CustomModal>
  );
};
