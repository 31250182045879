import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "stripe";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

const getAllPlans = () => {
  return rawApiProvider.get(url + "/plans");
};

const createCheckoutSession = (object: any) => {
  return rawApiProvider.post(url + "/create-checkout-session", object);
};

const createSubscription = (object: any) => {
  return rawApiProvider.post(url + "/subscription", object);
};

const changeSubscriptionPreview = (queryParams?: string) => {
  return rawApiProvider.get(url + `/change-subscription-preview?${queryParams || ""}`);
};

const changeSubscription = (object: any) => {
  return rawApiProvider.put(url + "/change-subscription", object);
};

const cancelSubscription = (queryParams?: string) => {
  return rawApiProvider.remove(url + `/cancel-subscription?${queryParams || ""}`);
};

const getExtraSubscriptionInformation = () => {
  return rawApiProvider.get(`${url}/extra-subscription-information`);
};

const removeSubscriptionSchedule = () => {
  return rawApiProvider.remove(`${url}/subscription-schedule`);
};

type StripeAPIType = ReturnEndpoints & {
  getAllPlans: () => Promise<void>;
  cancelSubscription: (queryParams?: string) => Promise<void>;
  createCheckoutSession: (object: any) => Promise<any>;
  createSubscription: (object: any) => Promise<any>;
  changeSubscriptionPreview: (queryParams?: string) => Promise<any>;
  changeSubscription: (object: any) => Promise<any>;
  getExtraSubscriptionInformation(): Promise<any>;
  removeSubscriptionSchedule(): Promise<any>;
};
const StripeAPI: StripeAPIType = {
  ...apiEndpoints,
  getAllPlans,
  cancelSubscription,
  createCheckoutSession,
  createSubscription,
  changeSubscriptionPreview,
  changeSubscription,
  getExtraSubscriptionInformation,
  removeSubscriptionSchedule,
};
export default StripeAPI;
