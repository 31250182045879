import getApiEndpoints from "./utilities/core";

const url = "trainstations";

// Get the standard CRUD operations
const basicTrainstationsEndpoints = getApiEndpoints({
  url,
});

type TrainstationsAPIType = {
  getTrainstations(queryParams?: string): Promise<any>;
};
const TrainstationsAPI: TrainstationsAPIType = {
  getTrainstations: basicTrainstationsEndpoints.getAll,
};
export default TrainstationsAPI;
