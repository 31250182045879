import { Button } from "@chakra-ui/react";
import { Event } from "@lato/common";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useMeContext } from "../../../../../../stores/me-context";
import { useTripLanguage } from "../../../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { useTripTranslations } from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import FullScreenSpinner from "../../../../../FullScreenSpinner";
import TripForm from "../../../../TripForm";
import { LibraryItemInTripProps } from "../LibraryItemDownshift";
import { ActualEventForm } from "./ActualEventForm";
import { resolveIncorrectTranslationFields } from "./resolveIncorrectTranslationFields";
import { closeElement } from "../../../../../../utils/EventHelper";

interface EventFormProps {
  tripProps: LibraryItemInTripProps | null;
  eventDateAndTime?: JSX.Element;
  selectedLanguageIndex?: any;
  updateActivity: any;
  isUpdating: boolean;
  formMethods: UseFormReturn<Event, any, undefined>;
  formId?: string;
  event: Event;
  daySelectOptions?: any[];
}

const EventForm: React.FC<EventFormProps> = ({
  eventDateAndTime,
  selectedLanguageIndex = 0,
  tripProps,
  updateActivity,
  isUpdating,
  formMethods,
  formId,
  event,
  daySelectOptions,
}) => {
  const { trip, setUnCollapsedElement, isInvalidForms, isErrorForms } = useTripFormStore();
  const me = useMeContext();
  const { data: tripTranslations } = useTripTranslations(trip.id);
  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();

  if (!trip?.userTrips?.length) return <FullScreenSpinner />;

  const handleSubmit = async (e: any) => {
    closeElement(setUnCollapsedElement, isInvalidForms, isErrorForms);
    const { ord, ...formValues } = formMethods.getValues();
    await updateActivity({
      ...formValues,
      descriptions: resolveIncorrectTranslationFields(
        formValues.descriptions,
        tripProps?.first ?? false,
        firstSelectedLanguageIndex,
        secondSelectedLanguageIndex ?? 0,
        tripTranslations,
        me,
      ),
      titles: resolveIncorrectTranslationFields(
        formValues.titles,
        tripProps?.first ?? false,
        firstSelectedLanguageIndex,
        secondSelectedLanguageIndex ?? 0,
        tripTranslations,
        me,
      ),
    });
  };

  return (
    <TripForm
      formId={formId!}
      triggerResetData={event}
      defaultValues={event}
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <Button type="submit" hidden isLoading={isUpdating} className="form-submit-button">
        Save
      </Button>
      <ActualEventForm
        tripProps={tripProps}
        selectedLanguageIndex={selectedLanguageIndex}
        eventDateAndTime={eventDateAndTime}
        updateActivity={updateActivity}
        event={event}
        daySelectOptions={daySelectOptions}
      />
    </TripForm>
  );
};
export default EventForm;
