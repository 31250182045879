import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, HStack, Icon, Link, Text, useRadioGroup } from "@chakra-ui/react";
import {
  Address,
  Contact,
  CONTACTTYPE,
  Country,
  CountryGroups,
  GENDER,
  Language,
  LoyaltyProgram,
  LoyaltyProgramContactRelation,
} from "@lato/common";
import React, { ReactNode } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { BsTicketPerforated, BsPersonFill as PersonFill } from "react-icons/bs";
import { FaBuilding as Building, FaPassport as Passport } from "react-icons/fa";
import { TbExternalLink, TbWorld } from "react-icons/tb";
import CountryAPI from "../../api/countries.api";
import LoyaltyProgramsAPI from "../../api/loyalty-program.api";
import { useParamsQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import { RHFEmailInput } from "../auth/GeneralLoginRegister";
import MaskedDateInput from "../../components/input/date/MaskedDateInput";
import Form from "../../components/form/Form";
import { CustomSpinner } from "../../components/FullScreenSpinner";
import InputWrapper from "../../components/input/InputWrapper";
import PhoneInput from "../../components/input/PhoneInput";
import RHFInput, { RHFInputProps } from "../../components/input/RHFInput";
import RHFRadio from "../../components/input/RHFRadio";
import RHFSelect, { OptionsGrouped, SelectOption } from "../../components/input/RHFSelect";
import ErrorCardView from "../../components/layout/ErrorCardView";
import Section from "../../components/layout/Section";
import { RadioCard } from "../../components/trips/edit/daybyday/library-items/transportations/RadioCard";

export const genderText: { [key in GENDER | string]: string } = {
  [GENDER.M]: "M",
  [GENDER.F]: "F",
  [GENDER.X]: "X",
};

export const contact_typeIcon: { [key in CONTACTTYPE | string]: any } = {
  [CONTACTTYPE.PERSON]: { as: PersonFill, boxSize: 6 },
  [CONTACTTYPE.COMPANY]: { as: Building, boxSize: 5 },
};

interface ContactFormProps {
  formMethods: UseFormReturn<Contact, any, undefined>;
  onSubmit: (e: Contact) => void;
  onClose: (e: any) => void;
  isLoadingSave: boolean;
  create?: boolean;
  disableCompany?: boolean;
  buttonText?: string;
  loyaltyProgramsOnly?: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({
  formMethods,
  onSubmit,
  onClose,
  isLoadingSave,
  create = false,
  disableCompany = false,
  buttonText = "Save",
  loyaltyProgramsOnly = false,
}) => {
  const [contact_type, setContactType] = React.useState<CONTACTTYPE>(
    formMethods.getValues().contact_type ?? CONTACTTYPE.PERSON,
  );

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: countryError,
  } = useParamsQueryHelper<CountryGroups>({
    queryKey: "countries-form",
    apiCall: CountryAPI.getAllCountriesForContactForm,
    queryParams: {} as any,
    options: {},
  });

  const {
    data: loyaltyPrograms,
    isLoading: isLoadingLoyaltyPrgrams,
    error: loyaltyProgramError,
  } = useParamsQueryHelper<LoyaltyProgram[]>({
    queryKey: "loyalty-program-form",
    apiCall: LoyaltyProgramsAPI.getAll,
    queryParams: {} as any,
    options: {},
  });

  if (isLoadingCountries || isLoadingLoyaltyPrgrams) return <CustomSpinner m={"auto"} />;
  if (countryError || !countries) return <ErrorCardView title="Could not load countries" />;
  if (loyaltyProgramError || !loyaltyPrograms) return <ErrorCardView title="Could not load loyalty programs" />;

  const languages = (countries.all ?? [])
    .filter((country) => country.languages && country.languages.length > 0)
    .flatMap((country) => country.languages)
    .filter((value, index, self) => index === self.findIndex((t) => t?.code === value?.code))
    .map((language) => {
      const country = (countries.most_used ?? []).filter(
        (country) =>
          country.languages &&
          country.languages.filter((countryLanguage) => countryLanguage.code === language.code).length > 0,
      );
      return {
        mostCommon: country.length > 0,
        iso: country.flatMap((country) => country.iso),
        ...language,
      };
    })
    .sort((a, b) => ((a?.name ?? "") < (b?.name ?? "") ? -1 : 1));

  const formProps: InnerContactFormProps = {
    contact_type,
    most_used_countries: countries.most_used,
    countries: countries.all,
    loyaltyPrograms: loyaltyPrograms.sort((a, b) => ((a.name ?? "") < (b.name ?? "") ? -1 : 1)),
    languages: languages as Language[],
    formMethods,
    onClose,
    onSubmit,
    isLoadingSave,
    setContactType,
    create,
    disableCompany,
    buttonText,
    loyaltyProgramsOnly,
  };

  return (
    <Flex flexDir={"column"} justifyContent={"center"}>
      {contact_type === CONTACTTYPE.PERSON ? (
        <PersonContactForm {...formProps} />
      ) : (
        <CompanyContactForm {...formProps} />
      )}
    </Flex>
  );
};
export default ContactForm;

interface InnerContactFormProps extends ContactFormProps {
  contact_type: CONTACTTYPE;
  most_used_countries: Country[];
  countries: Country[];
  loyaltyPrograms: LoyaltyProgram[];
  languages: Language[];
  isLoadingSave: boolean;
  setContactType: (value: CONTACTTYPE) => void;
  create: boolean;
  buttonText?: string;
  loyaltyProgramsOnly: boolean;
}

const PersonContactForm: React.FC<InnerContactFormProps> = ({
  formMethods,
  onSubmit,
  onClose,
  contact_type,
  most_used_countries,
  countries,
  loyaltyPrograms,
  languages,
  isLoadingSave,
  setContactType,
  create,
  disableCompany,
  buttonText = "Save",
  loyaltyProgramsOnly,
}) => {
  const GenderInput = () => {
    return (
      <InputWrapper name="gender" label="Gender">
        <RHFRadio
          mt={"0.5em"}
          name={"gender"}
          defaultValue={null}
          options={Object.values(GENDER).map((option) => {
            return { text: genderText[option], value: option };
          })}
        ></RHFRadio>
      </InputWrapper>
    );
  };
  const formValues = formMethods.getValues();

  const [hideExtraInputs, setHideExtraInputs] = React.useState<boolean>(create);
  const [selectedCountry, setSelectedCountry] = React.useState<Country | undefined>(
    formValues?.address?.country ?? undefined,
  );

  return (
    <Form formMethods={formMethods} onSubmit={(formValues) => onSubmit({ ...formValues, contact_type })}>
      <Flex gap={1} flexDir={"column"} mt={3}>
        {/*create && !disableCompany && <ContactTypeRadio setContactType={setContactType} contact_type={contact_type} />*/}
        {!loyaltyProgramsOnly && (
          <>
            <Section noDivider>
              <Flex flexDir={"column"} gap={1}>
                <Flex flexDir={{ base: "column", md: "row" }} gap={"1em"}>
                  <RHFInput name="last_name" label="Last name" isRequired={true} />
                  <RHFInput name="first_name" label={"First name"} isRequired={true} />
                  <RHFInput name="nick_name" label="Nick name" />
                </Flex>
                <Flex flexDir={{ base: "column", md: "row" }} gap={"1em"}>
                  <CountryContactForm
                    countries={countries}
                    most_used_countries={most_used_countries}
                    setSelectedCountry={setSelectedCountry}
                  />
                  <MaskedDateInput name="date_of_birth" label="Date of birth (dd.mm.yyyy)" />
                  <GenderInput />
                </Flex>
                <Flex flexDir={{ base: "column", md: "row" }} gap={"1em"}>
                  <LanguageContactForm
                    languages={languages}
                    selectedCountry={selectedCountry}
                    width={{ base: "100%", md: "33%" }}
                  />

                  <RHFEmailInput label={"Email"} name={"primaryEmail"} width={{ base: "100%", md: "33%" }} />
                  <PhoneInput
                    selectedCountry={selectedCountry}
                    hidePrefixSelector
                    name={"phoneNumber"}
                    label={"Phone number"}
                    width={{ base: "100%", md: "33%" }}
                    defaultValue={
                      formValues.phoneNumber && formValues.phoneNumber !== ""
                        ? formValues.phoneNumber
                        : (formValues.address?.country?.phonePrefix ?? "")
                    }
                    /* customInput={<PhoneInput hidePrefixSelector name={""} label={""}} 
                 value={
                  formValues.phoneNumber && formValues.phoneNumber !== ""
                    ? formValues.phoneNumber
                    : formValues.address?.country?.phonePrefix ?? ""
                }
                
                maxInputs={1} */
                  />
                </Flex>
              </Flex>

              {!hideExtraInputs && (
                <AddressContactForm
                  most_used_countries={most_used_countries}
                  countries={countries}
                  formMethods={formMethods}
                  setSelectedCountry={setSelectedCountry}
                />
              )}
            </Section>
            {hideExtraInputs && (
              <Flex justifyContent={"center"} width={"100%"}>
                <Text
                  p={2}
                  cursor={"pointer"}
                  _hover={{ textDecoration: "underline" }}
                  fontSize={"12px"}
                  color={"gray"}
                  width={"fit-content"}
                  onClick={() => setHideExtraInputs(false)}
                >
                  show more <Icon as={ChevronDownIcon} boxSize={5} />
                </Text>
              </Flex>
            )}
            {!hideExtraInputs && (
              <>
                <Flex
                  flexDir={{ base: "column", md: "row" }}
                  gap={"0.5em"}
                  ml={"0.5em"}
                  mt={"1em"}
                  alignContent={"center"}
                >
                  <Icon as={Passport} boxSize={6} />
                  <Heading size={"xl"} lineHeight={1}>
                    Passport info
                  </Heading>
                </Flex>
                <Section noDivider borderWidth="1px">
                  <Flex flexDir={"column"} gap={1}>
                    <Flex flexDir={{ base: "column", md: "row" }} gap={"1em"}>
                      <RHFInput name="passport.number" label="Passport number" />

                      <InputWrapper name="passport.expiration_date" label="Passport expiry date">
                        <MaskedDateInput name="passport.expiration_date" />
                      </InputWrapper>
                    </Flex>
                  </Flex>
                </Section>
              </>
            )}
          </>
        )}

        {!hideExtraInputs && <LoyaltyProgramForm loyaltyPrograms={loyaltyPrograms} />}
      </Flex>
      <Flex alignItems="center" justifyContent="right" mt={3}>
        <Button onClick={onClose} colorScheme="red" variant="outline" mr={2}>
          Cancel
        </Button>
        <Button type="submit" colorScheme="brand" isLoading={isLoadingSave}>
          {buttonText}
        </Button>
      </Flex>
    </Form>
  );
};

interface LoyaltyProgramFormProps {
  loyaltyPrograms: LoyaltyProgram[];
}

const LoyaltyProgramForm: React.FC<LoyaltyProgramFormProps> = ({ loyaltyPrograms }) => {
  const { getValues, setValue } = useFormContext();

  const existingPrograms = getValues("loyaltyProgramRelations") || [];

  const groupedPrograms: OptionsGrouped = loyaltyPrograms.reduce(
    (acc, obj) => {
      const typeString = obj.type.toString();
      if (!acc[typeString]) {
        acc[typeString] = [];
      }
      acc[typeString].push({ value: obj.id, text: obj.name });
      return acc;
    },
    {} as { [key: string]: { value: number; text: string | undefined }[] },
  );

  const sortedGroupedPrograms = Object.keys(groupedPrograms)
    .sort()
    .reduce((acc, key) => {
      (acc as any)[key] = groupedPrograms[key];
      return acc;
    }, {});

  const [programCount, setProgramCount] = React.useState(existingPrograms.length || 0);

  const deleteAdditionalInput = (indexToRemove: number) => {
    const programs = getValues("loyaltyProgramRelations") as LoyaltyProgramContactRelation[];
    const tempInputs = programs.filter((_, i) => i !== indexToRemove);
    setProgramCount(programCount - 1);
    setValue("loyaltyProgramRelations", tempInputs);
  };
  return (
    <>
      <Flex flexDir={{ base: "column", md: "row" }} gap={"0.5em"} ml={"0.5em"} mt={"1em"} alignContent={"center"}>
        <Icon as={BsTicketPerforated} boxSize={6} />
        <Heading size={"xl"} lineHeight={1}>
          Loyalty Programs
        </Heading>
      </Flex>
      <Section noDivider borderWidth="1px">
        <Flex flexDir={"column"} gap={1}>
          {Array.from(new Array(programCount)).map((_, index) => (
            <Flex flexDir={"row"} gap={"1em"} alignItems={"center"}>
              <InputWrapper name={`loyaltyProgramRelations.${index}.loyaltyProgram`} label="Program">
                <RHFSelect
                  optionsGrouped={sortedGroupedPrograms}
                  name={`loyaltyProgramRelations.${index}.loyaltyProgram.id`}
                  placeholder="Select Program"
                ></RHFSelect>
              </InputWrapper>
              <RHFInput
                name={`loyaltyProgramRelations.${index}.frequent_flyer_number`}
                label="Loyalty program number"
              />
              <CloseIcon
                fontSize="9px"
                alignSelf={"center"}
                ml={"0.5em"}
                cursor={"pointer"}
                mt={"2em"}
                onClick={(e) => deleteAdditionalInput(index)}
              />
            </Flex>
          ))}
          <Text
            onClick={() => setProgramCount(programCount + 1)}
            color={"blue"}
            cursor={"pointer"}
            alignSelf={"flex-start"}
            fontSize={"12px"}
          >
            {`+ Add Program`}
          </Text>
        </Flex>
      </Section>
    </>
  );
};

const CompanyContactForm: React.FC<InnerContactFormProps> = ({
  formMethods,
  onSubmit,
  onClose,
  contact_type,
  most_used_countries,
  countries,
  isLoadingSave,
  setContactType,
  create,
  buttonText = "Save",
  languages,
}) => {
  const formValues = formMethods.getValues();
  const [hideExtraInputs, setHideExtraInputs] = React.useState<boolean>(create);
  const [selectedCountry, setSelectedCountry] = React.useState<Country | undefined>(
    formValues?.address?.country ?? undefined,
  );

  return (
    <Form formMethods={formMethods} onSubmit={(e: any) => {}}>
      <Flex gap={1} flexDir={"column"} mt={3}>
        {create && <ContactTypeRadio setContactType={setContactType} contact_type={contact_type} />}
        <Section noDivider>
          <Flex flexDir={"column"} gap={1}>
            <Flex gap={"1em"}>
              <RHFInput name="first_name" label={"Company name"} isRequired={true} width={"31%"} />

              <RHFInput name="vat" label="Tax ID" width={"35%"} />
              <InputAdditional
                label={"Website"}
                name={"website"}
                width={"31%"}
                inputIcon={externalLink}
                value={formValues.website}
                maxInputs={1}
              />
            </Flex>

            <Flex gap={"1em"}>
              <CountryContactForm
                countries={countries}
                most_used_countries={most_used_countries}
                width={"50%"}
                setSelectedCountry={setSelectedCountry}
              />
              <RHFEmailInput label={"Email"} name={"primaryEmail"} width={"33%"} />
            </Flex>
            <Flex gap={"1em"}>
              <LanguageContactForm languages={languages} width={"33%"} selectedCountry={selectedCountry} />
              <PhoneInput
                hidePrefixSelector
                selectedCountry={selectedCountry}
                name={"phoneNumber"}
                label={"phone number"}
                width={"33%"}
                defaultValue={
                  formValues.phoneNumber && formValues.phoneNumber !== ""
                    ? formValues.phoneNumber
                    : (formValues.address?.country?.phonePrefix ?? "")
                }
                /*customInput={<PhoneInput hidePrefixSelector name={""} label={""} />}
                value={formValues.phoneNumber}
                width={"50%"}
                //maxInputs={1}*/
              />
            </Flex>
          </Flex>
          {!hideExtraInputs && (
            <AddressContactForm
              most_used_countries={most_used_countries}
              countries={countries}
              formMethods={formMethods}
            />
          )}
        </Section>
        {hideExtraInputs && (
          <Flex justifyContent={"center"} width={"100%"}>
            <Text
              p={2}
              cursor={"pointer"}
              _hover={{ textDecoration: "underline" }}
              fontSize={"12px"}
              color={"gray"}
              width={"fit-content"}
              onClick={() => setHideExtraInputs(false)}
            >
              show more <Icon as={ChevronDownIcon} boxSize={5} />
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="right" mt={3}>
        <Button onClick={onClose} colorScheme="red" variant="outline" mr={2}>
          Cancel
        </Button>
        <Button
          colorScheme="brand"
          isLoading={isLoadingSave}
          onClick={() => {
            onSubmit({ ...formMethods.getValues(), contact_type });
          }}
        >
          {buttonText}
        </Button>
      </Flex>
    </Form>
  );
};

const ContactTypeRadio: React.FC<any> = ({ setContactType, contact_type }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "contact_type",
    defaultValue: contact_type,
    onChange: (value) => {
      setContactType(value);
    },
  });
  const group = getRootProps();

  return (
    <HStack {...group} overflowX="auto" margin="auto" padding="10px" mt={[0, "0 !important"]}>
      {Object.values(CONTACTTYPE).map((type: any) => {
        const radio = getRadioProps({
          value: type,
        });
        return (
          <RadioCard key={type} {...radio}>
            <Flex
              mx={5}
              my={3}
              flexDirection={"column"}
              alignItems="center"
              justifyContent={"space-between"}
              height={"3em"}
              width={"2em"}
            >
              <Box h={"2em"}>
                <Icon {...contact_typeIcon[type]} />
              </Box>
              <Text fontSize={12}>{type}</Text>
            </Flex>
          </RadioCard>
        );
      })}
    </HStack>
  );
};

interface LanguageContactFormProps {
  languages: Language[];
  width?: string | object;
  selectedCountry?: Country;
}

const LanguageContactForm: React.FC<LanguageContactFormProps> = ({ languages, width = "100%", selectedCountry }) => {
  const { setValue } = useFormContext<any>();
  let languages_grouped: OptionsGrouped = {
    Recent: (languages
      .filter((l) => l.mostCommon)
      .map((language: Language) => ({
        value: language.code,
        text: language.name,
      })) ?? []) as SelectOption[],
    All: (languages
      .filter((l) => !l.mostCommon)
      .map((language: Language) => ({
        value: language.code,
        text: language.name,
      })) ?? []) as SelectOption[],
  };

  if (selectedCountry) {
    const countryLanguages = languages.filter((language) => language.iso.includes(selectedCountry.iso));

    if (countryLanguages.length > 0) {
      languages_grouped = {
        "Languages of selected country": (countryLanguages.map((language: Language) => ({
          value: language.code,
          text: language.name,
        })) ?? []) as SelectOption[],
        ...languages_grouped,
      };
    }
  }

  const handleSelectLanguage = (e: any, language_code: any) => {
    const language = languages.filter((lang) => lang.code === language_code)[0];

    if (language) {
      setValue("spokenLanguage", language);
    }
  };

  return (
    <InputWrapper name="spokenLanguage" label="Language" width={width}>
      <RHFSelect
        name={`spokenLanguage.code`}
        optionsGrouped={languages_grouped}
        placeholder="Select language"
        onChange={handleSelectLanguage}
      ></RHFSelect>
    </InputWrapper>
  );
};

interface CountryContactFormProps {
  most_used_countries: Country[];
  countries: Country[];
  setGoogleAddress?: any;
  googleAddress?: Address;
  width?: string;
  setSelectedCountry?: (value: Country) => void;
}

const CountryContactForm: React.FC<CountryContactFormProps> = ({
  countries,
  most_used_countries,
  setGoogleAddress,
  googleAddress,
  width = "100%",
  setSelectedCountry,
}) => {
  const { getValues, setValue } = useFormContext<any>();

  const handleSelectCountry = (e: any, countryIso: any) => {
    const country = countries.filter((countr) => countr.iso === countryIso)[0];

    if (country) {
      setValue("address.country", country);
      setGoogleAddress && setGoogleAddress({ ...googleAddress, country });
    }

    setSelectedCountry && setSelectedCountry(country);
  };

  const countries_grouped: OptionsGrouped = {
    Recent: (most_used_countries?.map((country: Country) => ({
      value: country.iso,
      text: country.name,
    })) ?? []) as SelectOption[],
    All: (countries?.map((country: Country) => ({
      value: country.iso,
      text: country.name,
    })) ?? []) as SelectOption[],
  };

  return (
    <InputWrapper name="address.country" label="Country" width={width}>
      <RHFSelect
        name={`address.country.iso`}
        optionsGrouped={countries_grouped}
        placeholder="Select country"
        onChange={handleSelectCountry}
      ></RHFSelect>
    </InputWrapper>
  );
};

interface AddressContactFormProps {
  most_used_countries: Country[];
  countries: Country[];
  formMethods: any;
  setSelectedCountry?: (value: Country) => void;
}

const AddressContactForm: React.FC<AddressContactFormProps> = ({
  most_used_countries,
  countries,
  setSelectedCountry,
  formMethods,
}) => {
  const { getValues, setValue } = useFormContext<any>();

  const [googleAddress, setGoogleAddress] = React.useState<Address>({
    country: undefined,
    city: "",
    streetAddress: "",
    zipCode: "",
    aptnumber: "",
    state: "",
  });

  return (
    <>
      <Heading size={"lg"} mt={"1em"}>
        Address
      </Heading>
      <Flex flexDir={{ base: "column", md: "row" }} gap={"1em"}>
        <Box width={{ base: "100%", md: "330%" }} position={"relative"}>
          <RHFInput
            name="address.streetAddress"
            label="Street address"
            placeholder="Hoogpoort 31"
            onChange={(e) =>
              setGoogleAddress({
                ...googleAddress,
                streetAddress: e.target.value,
              })
            }
          />
          {/*!Object.values(googleAddress).every((x) => x === null || x === "") && (
            <Link
              position={"absolute"}
              top={"1.4em"}
              right={"0.3em"}
              target={"_blank"}
              href={`http://maps.google.com/?q=${googleAddress.country ? googleAddress.country.name + ";" : ""}${
                googleAddress.city ? googleAddress.city + ";" : ""
              }${googleAddress.streetAddress ? googleAddress.streetAddress + ";" : ""}`}
            >
              <Icon as={TbExternalLink} />
            </Link>
            )*/}
        </Box>
        <RHFInput name="address.aptnumber" label="Apt, suite, etc." placeholder="7B" />
      </Flex>
      <Flex flexDir={{ base: "column", md: "row" }} gap={"1em"}>
        <RHFInput name="address.zipCode" label="Postal code" placeholder="9000" />
        <RHFInput
          name="address.city"
          label="City"
          placeholder="Ghent"
          onChange={(e) => setGoogleAddress({ ...googleAddress, city: e.target.value })}
        />
        <RHFInput name="address.state" label="State" placeholder="Oost-Vlaanderen" />
        <CountryContactForm
          most_used_countries={most_used_countries}
          countries={countries}
          setSelectedCountry={setSelectedCountry}
          setGoogleAddress={setGoogleAddress}
          googleAddress={googleAddress}
        />
      </Flex>
    </>
  );
};

interface InputAdditionalProps {
  label: string;
  name: string;
  type?: string;
  width?: string;
  inputIcon?: (e: any) => ReactNode;
  value?: string;
  inputType?: string;
  customInput?: any;
  maxInputs?: number;
}

const externalLink = (link: string) => {
  if (!link.startsWith("https://")) {
    link = "https://" + link;
  }
  return (
    <Link href={link} target={"_blank"}>
      <Icon as={TbExternalLink} />
    </Link>
  );
};

const InputAdditional: React.FC<InputAdditionalProps> = ({
  label,
  name,
  type,
  inputIcon = () => <></>,
  width = "auto",
  value = "",
  inputType = "",
  customInput,
  maxInputs = 3,
}) => {
  const { setValue } = useFormContext<any>();

  const [additionalInputs, setAdditionalInputs] = React.useState<string[]>(value?.split(";") ?? [""]);
  const addAdditionalInput = (e: any, i: number) => {
    if (e.target.value) {
      const tempInputs = additionalInputs;
      tempInputs[i] = e.target.value;
      setAdditionalInputs(tempInputs);
    }
    setValue(name, additionalInputs.filter((input) => input && input !== "").join(";"));
  };
  /*const deleteAdditionalInput = (indexToRemove: number) => {
    const tempInputs = additionalInputs.filter((_, i) => i !== indexToRemove);
    setAdditionalInputs(tempInputs);
    setValue(
      name,
      additionalInputs.filter((input) => input && input !== "").join(";")
    );
  };*/

  return (
    <Flex flexDirection={"column"} w={width} gap={1}>
      <InputWrapper
        label={customInput ? "" : label.charAt(0).toUpperCase() + label.slice(1)}
        position={"relative"}
        name={`${name}-0`}
      >
        <>
          {customInput ? (
            React.cloneElement(customInput, {
              name: `${name}-0`,
              label: label,
              onChange: (e) => addAdditionalInput(e, 0),
              defaultValue: additionalInputs[0],
            })
          ) : (
            <RHFInput
              onChange={(e) => addAdditionalInput(e, 0)}
              alignSelf={"flex-end"}
              name={`${name}-0`}
              defaultValue={additionalInputs[0]}
              type={inputType}
            />
          )}
          <Box position="absolute" right={"0.4em"} top={"1.4em"}>
            {inputIcon(additionalInputs[0])}
          </Box>
        </>
      </InputWrapper>
      {additionalInputs.length > 1 &&
        additionalInputs.slice(1).map((_, i) => (
          <Flex
            alignSelf={"flex-end"}
            gap={1}
            ml={"0.5em"}
            cursor={"pointer"}
            w={"100%"}
            position={"relative"}
            key={`additional-input-${i}`}
          >
            <>
              {/*<CloseIcon
                fontSize="9px"
                alignSelf={"center"}
                ml={"0.5em"}
                onClick={(e) => deleteAdditionalInput(i + 1)}
        />*/}
              {customInput ? (
                React.cloneElement(customInput, {
                  name: `${name}-${i + 1}`,
                  label: "",
                  onChange: (e) => addAdditionalInput(e, i + 1),
                  defaultValue: additionalInputs[i + 1],
                })
              ) : (
                <RHFInput
                  onChange={(e) => addAdditionalInput(e, i + 1)}
                  alignSelf={"flex-end"}
                  type={type}
                  name={`${name}-${i + 1}`}
                  defaultValue={additionalInputs[i + 1]}
                />
              )}
              <Box position="absolute" right={"0.4em"} top={"0"}>
                {inputIcon(additionalInputs[i + 1])}
              </Box>
            </>
          </Flex>
        ))}
      {additionalInputs.length < maxInputs && (
        <Text
          onClick={() => setAdditionalInputs([...additionalInputs, ""])}
          color={"blue"}
          cursor={"pointer"}
          alignSelf={"flex-start"}
          fontSize={"12px"}
        >
          {`+ Add ${label}`}
        </Text>
      )}
    </Flex>
  );
};

interface RHFWebInputProps extends RHFInputProps {
  name: string;
  label: string;
  isReadOnly?: boolean;
  width?: string;
}

export const RHFWebInput: React.FC<RHFWebInputProps> = ({ name, label, isReadOnly = false, ...props }) => {
  return (
    <RHFInput
      name={name}
      label={label}
      placeholder="www.example.com"
      leftIcon={<Icon as={TbWorld} color="gray.400" pointerEvents={"none"} boxSize={4} />}
      isReadOnly={isReadOnly}
      {...props}
    />
  );
};
