import {
  Avatar,
  Badge,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { TASK_STATUS, Task, areDatesEqual, isDateBeforeDate } from "@lato/common";
import clsx from "clsx";
import { IoEarthOutline } from "react-icons/io5";
import { LuText } from "react-icons/lu";
import { prettyPrintDate } from "../../utils/date";
import { PriorityLabel } from "../tasks/PriorityLabel";
import { taskStatusColorMap } from "../tasks/TasksTable";
import { taskColor } from "./CalendarOverview";
import { TaskOptions } from "../tasks/TaskOptions";
import { removeAllHTMLTags } from "../../utils/sanitizeXSS";
import { React } from "react";

interface TaskPopoverProps {
  task: Task;
  listView?: boolean;
  calendarView?: boolean;
  dashboardView?: boolean;
  onEditClick?: (task: Task) => void;
  onDeleteClick?: (task: Task) => void;
  onTaskTitleClick?: (task: Task) => void;
}

export const TaskPopover = ({
  task,
  listView,
  dashboardView = false,
  calendarView = false,
  onEditClick,
  onDeleteClick,
  onTaskTitleClick,
}: TaskPopoverProps) => {
  const optionsDisclosure = useDisclosure();

  console.log("dates", new Date(task.due_date).toDateString(), new Date().toDateString());
  const header = (
    <div key={task.id} className="flex text-black">
      <div
        className={clsx(
          `bg-${calendarView || dashboardView ? taskColor : taskStatusColorMap[task.status]}-400`,
          "w-1 h-10 mt-1.5 rounded-md",
        )}
      />
      <div className="flex justify-between w-full">
        <div className="w-[90%]">
          <button
            className={clsx(
              task.status !== TASK_STATUS.DONE && isDateBeforeDate(new Date(task.due_date), new Date())
                ? "text-red-500"
                : "text-black",
              "ml-4 z-0 flex font-bold w-[90%] truncate hover:underline cursor-pointer",
            )}
            onClick={() => {
              if (onTaskTitleClick) {
                onTaskTitleClick(task);
              } else {
                task.trip && window.open(`/trips/${task.trip?.id}`);
              }
            }}
          >
            {task.title}
            <PriorityLabel priority={task.priority} marginTop="mt-1 mr-2" />
          </button>
          {listView && !dashboardView ? (
            <Badge height={"1.5rem"} as={"button"} ml={5} colorScheme={taskStatusColorMap[task.status]}>
              {task.status}
            </Badge>
          ) : (
            <span className="text-gray-500 text-sm ml-4">
              {areDatesEqual(new Date(task.due_date), new Date())
                ? "Today"
                : prettyPrintDate(task.due_date, undefined, "full")}
            </span>
          )}
        </div>
        {!listView && (
          <TaskOptions
            optionsDisclosure={optionsDisclosure}
            onEditClick={onEditClick}
            task={task}
            onDeleteClick={onDeleteClick}
          />
        )}
        {/* {task.trip && (
          <IconButton
            backgroundColor={"white"}
            icon={<IoOpenOutline className="text-black w-4 h-4" />}
            className="p-2 bg-white rounded-md"
            aria-label={""}
            onClick={() => {
              window.open(`/trips/${task.trip?.id}`);
            }}
          />
        )} */}
      </div>

      {/* <Checkbox
        colorScheme="teal"
        isChecked={task.isDone}
        onChange={async (e) => {
          await updateTask({ ...task, isDone: e.target.checked });
          refetch();
        }}
      /> */}
    </div>
  );

  const body = (
    <div className="flex justify-between gap-2">
      <div className="space-y-4 text-sm text-gray-700 mt-0.5">
        {task.trip && (
          <a target="_blank" className="flex gap-2 hover:underline" href={`/trips/${task.trip?.id}`} rel="noreferrer">
            <IoEarthOutline className="w-4 h-4 mt-0.5" />
            {task.trip?.trip?.titles.at(-1)?.content}
          </a>
        )}
        <p className="flex gap-2">
          <LuText className="w-4 h-4 mt-0.5" />
          {task.description === "" ? "Empty" : removeAllHTMLTags(task.description)}
        </p>
        {task.user && (
          <p>
            <Avatar
              justifySelf="center"
              size="xs"
              name={task.user.name}
              src={task.user.avatar ? task.user.avatarUrl : undefined}
              title={task.user.name}
            />
          </p>
        )}
      </div>
      <Badge height={"1.5rem"} as={"button"} colorScheme={taskStatusColorMap[task.status]}>
        {task.status}
      </Badge>
    </div>
  );

  if (listView) {
    return header;
  }

  return (
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>{header}</PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
      </PopoverContent>
    </Portal>
  );
};

export default TaskPopover;
