import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FREE_AI_TRIP_CONVERSIONS_LIMIT, Trip, User } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { DropzoneOptions } from "react-dropzone";
import { Link as ReactLink } from "react-router-dom";
import UsersAPI from "../api/users.api";
import ConfirmPopover from "../components/CRUD/ConfirmPopover";
import Dropzone from "../components/Dropzone";
import CustomModal from "../components/layout/CustomModal";
import { getViewTripLink } from "../utils/generateURLs";
import { useAIPDFReader, useDeleteUsertrip } from "../utils/query-helpers/react-query-mutations";
import { handleSubmission } from "../utils/toErrorMap";
import useCustomDropzone from "../utils/useCustomDropzone";
import { hasPayingPlan } from "./billingSettings";
import { ME_QUERY_KEY } from "../utils/constants";

interface AIPDFReaderButtonProps {
  user: User;
}

const AIPDFReaderButton: React.FC<AIPDFReaderButtonProps> = ({ user }) => {
  const disclosure = useDisclosure();
  const confirmNotSatisfiedDisclosure = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const hasPayingAccount = hasPayingPlan(user.company);

  const freeConversionsUsed = user.ai_trip_conversions.free_used;

  const [firstAttempt, setFirstAttempt] = React.useState(true);
  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const [generatedTrip, setGeneratedTrip] = React.useState<Trip | null>(null);

  const { mutate: deleteUserTrip } = useDeleteUsertrip();

  const { mutateAsync, isPending } = useAIPDFReader();

  const { mutateAsync: updateAIConversionCounter } = useMutation({
    mutationFn: (type: "increment" | "decrement") => UsersAPI.updateAIConversionCounter(type),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
    },
  });

  const generateTravelAppLink = React.useCallback((trip: Trip) => {
    const ut = trip.userTrips[trip.userTrips.length - 1];
    return getViewTripLink(ut.brand.name || "", trip.titles.length !== 0 ? trip.titles[0].content : "", ut.id!);
  }, []);

  const handleSuccess = React.useCallback((newTrip: Trip) => {
    // const travelAppLink = generateTravelAppLink(newTrip);
    // Open the travel app
    // window.open(travelAppLink);
    // Check if the trip import has an error

    setGeneratedTrip(newTrip);
  }, []);

  const handleFileSubmission = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    await handleSubmission({
      successMessage: "imported trip",
      failMessage: "importing trip",
      apiCall: mutateAsync(formData),
      toast,
      successCallback: (trip: Trip) => handleSuccess(trip),
      handleAdditionalError: (statusCode: number, setError: any, errors?: any[]) => {
        if (statusCode === 400) {
          const errorMessage = errors && errors[0];
          setErrorMessage(errorMessage);
          return errorMessage;
        }
      },
      // setError: formMethods.setError,
    });
  };

  const dropzoneOptions: DropzoneOptions = {
    // accept: [".pdf", ".doc", ".docx", ".csv", ".txt"],
    accept: [".doc", ".docx", ".txt", ".pdf"],
    onDropAccepted: async (acceptedFiles) => {
      // Remove error message
      setErrorMessage(undefined);
      // Proceed with the uploaded files
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        setUploadedFile(file);
        await handleFileSubmission(file);
      }
      // acceptedFiles.forEach(async (file) => {
      //   const formData = new FormData();
      //   formData.append("file", file);
      //   uploadCsv(formData);
      // });
      // disclosure.onClose();
    },
  };

  const dropzoneState = useCustomDropzone(dropzoneOptions);

  const handleClose = () => {
    // Reset the state
    setErrorMessage(undefined);
    // Close the popover
    disclosure.onClose();
    // Reset the state
    // Reset the travel-app link
    setGeneratedTrip(null);
    // Reset the file to null
    setUploadedFile(null);
    // Reset the first attempt
    setFirstAttempt(true);
  };

  const rejectConversion = React.useCallback(() => {
    if (generatedTrip) deleteUserTrip(generatedTrip.userTrips[generatedTrip.userTrips.length - 1].id!);
    // When the user indicates he is not happy with the result of the trip conversion, revoke the AI conversion.
    updateAIConversionCounter("decrement");
  }, [generatedTrip]);

  const rejectAndClose = React.useCallback(() => {
    rejectConversion();
    // Close the modal
    handleClose();
  }, [rejectConversion]);

  const acceptConversion = () => {
    // Close the modal
    handleClose();
  };

  const retryConversion = async () => {
    if (!uploadedFile) return;
    // Reject the conversion
    rejectConversion();
    // Reset the state
    // Reset the travel-app link
    setGeneratedTrip(null);
    // Reset the file to null
    setUploadedFile(null);
    // Set the first attempt to false
    setFirstAttempt(false);
    // Retry the conversion
    await handleFileSubmission(uploadedFile);
  };

  const travelAppLink = generatedTrip ? generateTravelAppLink(generatedTrip) : null;

  const nextImportIsFree = freeConversionsUsed < FREE_AI_TRIP_CONVERSIONS_LIMIT;

  return (
    <>
      <div className="relative">
        <Tooltip label="Import an existing trip">
          <Button type="button" colorScheme="brand" mx={3} onClick={disclosure.onOpen} variant={"outline"}>
            Import pdf
          </Button>
        </Tooltip>
      </div>
      <CustomModal
        size="lg"
        title="Import an existing trip"
        onClose={handleClose}
        isOpen={disclosure.isOpen}
        closable={!isPending && !travelAppLink}
      >
        <Flex flexDir={"column"} gap={8} my={3}>
          {!travelAppLink ? (
            isPending ? (
              <Box textAlign={"center"} py={6}>
                <Flex justifyContent={"center"}>
                  <Spinner colorScheme="brand" />
                </Flex>
                <Text fontSize={"md"} mt={2} fontWeight={"bold"}>
                  This might take a minute... Please hold on while your trip is being parsed.
                </Text>
              </Box>
            ) : (
              <>
                <Text>
                  Import a trip or quote from an existing <em>.pdf, .doc, or .txt</em> file and let it be automatically
                  converted into a Lato trip.
                </Text>
                {/* <Flex gap={4} justifyContent={"center"}>
                  <Flex
                    onClick={() => toggleGPT4o(false)}
                    as={Button}
                    variant={gpt4o ? "outline" : "solid"}
                    colorScheme="brand"
                    flexDir={"column"}
                    h="fit-content"
                    p={2}
                    py={4}
                    w={"100px"}
                  >
                    <Heading>Basic</Heading>
                    <Text>30-60s</Text>
                  </Flex>
                  <Flex
                    onClick={() => toggleGPT4o(true)}
                    as={Button}
                    variant={gpt4o ? "solid" : "outline"}
                    flexDir={"column"}
                    colorScheme="brand"
                    h="fit-content"
                    p={2}
                    py={4}
                    w={"100px"}
                  >
                    <Heading>Expert</Heading>
                    <Text>60-120s</Text>
                  </Flex>
                </Flex> */}
                {errorMessage && <Text color="red">{errorMessage}</Text>}
                {hasPayingAccount || nextImportIsFree ? (
                  <Dropzone
                    fileLimitMB={10}
                    fileTypeDisplayName="files"
                    resourceName="trip file"
                    dropzoneState={dropzoneState}
                  />
                ) : (
                  <Text color="red">You must have a paying account to import trips.</Text>
                )}
                <Text fontSize={"md"} color="gray">
                  {nextImportIsFree
                    ? `You've used ${freeConversionsUsed} out of ${FREE_AI_TRIP_CONVERSIONS_LIMIT} free imports for this user. After that, €0.85 per import.`
                    : // "A cost of €0.85 will be incurred per import."
                      `A charge of €0.85 will be applied at the end of this quarter.`}{" "}
                  <Link as={ReactLink} to="/settings/billing" isExternal>
                    View additional usage.
                  </Link>
                </Text>
              </>
            )
          ) : (
            <>
              <Alert
                status="success"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                height="170px"
                background={"transparent"}
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  Successfully imported trip!
                </AlertTitle>
                <AlertDescription maxWidth="sm" fontSize={"md"} color={"gray"}>
                  {/* Carefully review the generated trip before accepting. If a new tab did not open automatically, please
                  click{" "}
                  <Link href={travelAppLink} target="_blank" rel="noreferrer" textDecoration={"underline"} color="blue">
                    here
                  </Link>{" "}
                  to view the result. */}
                  Review the imported trip{" "}
                  <Link href={travelAppLink} target="_blank" rel="noreferrer" textDecoration={"underline"} color="blue">
                    here
                  </Link>{" "}
                  before accepting.
                </AlertDescription>
              </Alert>

              {/* <Text fontSize={"sm"} color="gray" mt={1}>
                {`Carefully review the imported trip before proceeding. If, for any reason, you are not satisfied, please indicate it here. This will void the import cost, and the associated imported trip will be immediately deleted.`}
              </Text> */}
              <Box>
                <Flex justifyContent={"end"}>
                  <ConfirmPopover
                    disclosure={confirmNotSatisfiedDisclosure}
                    action={firstAttempt ? retryConversion : rejectAndClose}
                    message={
                      firstAttempt
                        ? "Don't worry, retry the import for free for the possibility of a better result."
                        : `Don't worry, you can undo the import, this will void the import cost.`
                    }
                    confirmButtonText={firstAttempt ? "Retry for free" : "Undo import"}
                    confirmButtonColorScheme={firstAttempt ? "brand" : "red"}
                    titleText={firstAttempt ? "" : ""}
                    popoverContentClassname="!w-[430px]"
                    popoverBodyClassname="!py-8 text-center"
                  >
                    <Button variant={"link"} colorScheme="realGray">
                      {firstAttempt ? "Not satisfied?" : "Still not satisfied?"}
                    </Button>
                  </ConfirmPopover>
                  <Button onClick={acceptConversion} colorScheme="brand" ml={3}>
                    Accept import
                  </Button>
                </Flex>
              </Box>
            </>
          )}
        </Flex>
      </CustomModal>
    </>
  );
};

export default React.memo(AIPDFReaderButton);
