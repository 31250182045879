import { Box, Text } from "@chakra-ui/react";
import React from "react";

interface SingleBoardItemProps {
  item: any;
}

export const SingleItem: React.FC<SingleBoardItemProps> = ({ item }) => {
  return (
    <Box>
      {item && <Text fontSize="sm">{item.description?.charAt(0) + item.description?.slice(1).toLowerCase()}</Text>}
    </Box>
  );
};
