import { Trip, TripDay } from "@lato/common";
import clsx from "clsx";
import React from "react";
import { useMeContext } from "../../../../stores/me-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { DayScrollerVerticalCard } from "./DayScrollerVerticalCard";

interface DayScrollerVerticalProps {
  trip: Trip;
  tripdays: TripDay[];
  i: number;
  setActiveDay: (value: number) => void;
  start_date: string | null;
  disabledDaySwitch: boolean;
}

const DayScrollerVertical: React.FC<DayScrollerVerticalProps> = ({
  tripdays,
  trip,
  i,
  setActiveDay,
  start_date,
  disabledDaySwitch,
}) => {
  const me = useMeContext();
  const { isInvalidForms, isErrorForms } = useTripFormStore();

  const invalidForms = [...isInvalidForms.entries()].filter((value) => value[1] === true);
  const errorForms = [...isErrorForms.entries()].filter((value) => value[1] === true);

  const dayInvalid = invalidForms.length > 0 ? parseInt(invalidForms[0][0]?.split("-")[1]) : undefined;
  const dayError = errorForms.length > 0 ? parseInt(errorForms[0][0]?.split("-")[1]) : undefined;

  return (
    // <Box
    //   h={{ base: "70%", xl: "75vh" }}
    //   overflow={{ base: "none", xl: "auto" }}
    //   style={{
    //     msOverflowStyle: "none",
    //     scrollbarWidth: "none",
    //   }}
    //   css={{
    //     "&::-webkit-scrollbar": {
    //       display: "none",
    //     },
    //   }}
    // >
    <div className={clsx("flex gap-2 flex-col", me.useNewEditTripLayout && "h-[70%] overflow-y-scroll")}>
      {tripdays?.map((tripday, j) => (
        <DayScrollerVerticalCard
          key={`DayScrollerVertical-item-${j}`}
          trip={trip}
          i={j}
          setActiveDay={setActiveDay}
          tripdays={tripdays}
          tripday={tripday}
          isActive={i === j}
          isInvalid={j === dayInvalid || j === dayError}
          start_date={start_date}
          disabled={disabledDaySwitch}
        />
      ))}
    </div>
    // </Box>
  );
};

export default React.memo(DayScrollerVertical);

export interface DayScrollerVerticalCardProps {
  i: number;
  tripdays: TripDay[];
  tripday: TripDay;
  trip: Trip;
  setActiveDay: (value: number) => void;
  isActive: boolean;
  isInvalid: boolean;
  // executeIfClicked: (clickHandler: () => void) => void;
  start_date: string | null;
  disabled: boolean;
}
