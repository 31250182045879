import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "countries";

// Get the standard CRUD operations
const { getAll } = getApiEndpoints({
  url,
});

const getAllContinents = () => {
  return rawApiProvider.get(url + "/continents");
};

const getAllCountriesForContactForm = () => {
  return rawApiProvider.get(url + "/contactForm");
};

type CountryAPIType = {
  getAll(queryParams?: string): Promise<any>;
  getAllContinents(): Promise<any>;
  getAllCountriesForContactForm(): Promise<any>;
};
const CountryAPI: CountryAPIType = {
  getAll,
  getAllContinents,
  getAllCountriesForContactForm,
};
export default CountryAPI;
