import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { addToast } from "./addToast";

export function showNoConnectionToast(toast: any) {
  addToast(toast, {
    title: "Offline",
    description: "No internet connection. Saving is currently not possible.",
    status: "info",
    duration: undefined,
    isClosable: true,
  });
}

// export function showConnectionBackToast(toast: any) {
//   addToast(toast, {
//     title: "Back online!",
//     status: "success",
//     duration: 3000,
//     isClosable: true,
//   });
// }

const useInternetConnection = () => {
  const [isOnline, setIsOnline] = useState(true);

  const toast_lost = useToast({
    id: "toast_connection_lost",
  });
  // const toast_back = useToast({
  //   id: "toast_connection_back",
  // });

  useEffect(() => {
    toast_lost.closeAll();
    // toast_back.closeAll();
    // if (isOnline) {
    //   showConnectionBackToast(toast_back);
    // }
    if (!isOnline) {
      showNoConnectionToast(toast_lost);
    }

    return () => {
      toast_lost.closeAll();
      // toast_back.closeAll();
    };
  }, [isOnline, toast_lost]);

  useEffect(() => {
    const goOnline = () => setIsOnline(navigator.onLine);
    const goOffline = () => setIsOnline(navigator.onLine);

    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []); // Empty dependency array to ensure this runs once on mount
};

export default useInternetConnection;
