import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from "@chakra-ui/react";
import { PRIORITY } from "@lato/common";
import clsx from "clsx";
import React from "react";
import { IoBanOutline, IoFlagOutline } from "react-icons/io5";
import { PriorityLabel } from "./PriorityLabel";
import { ConditionalWrapper } from "../contacts/Contact";
import { GrFlag } from "react-icons/gr";

interface PrioritySelectProps {
  selectedPriority?: PRIORITY;
  isDisabled: boolean;
  isHorizontal?: boolean;
  isWideLabel?: boolean;
  handleChangePriority?: (priority?: PRIORITY) => void;
  isInModal?: boolean;
}

export const PrioritySelect: React.FC<PrioritySelectProps> = ({
  selectedPriority,
  isDisabled,
  isHorizontal = false,
  isWideLabel = true,
  handleChangePriority,
  isInModal = true,
}) => {
  const displayPriorityOption = (priority: PRIORITY) => (
    <Box>
      <PriorityLabel priority={priority} showLabel={true} />
    </Box>
  );

  const handlePriorityChange = (priority?: string | string[]) => {
    if (handleChangePriority) handleChangePriority(priority ? (priority as PRIORITY) : undefined);
  };

  const menu = (
    <MenuOptionGroup defaultValue={selectedPriority}>
      {Object.values(PRIORITY).map((priority) => (
        <MenuItemOption
          key={`priority-option-${priority}`}
          value={priority}
          onClick={() => {
            priority === selectedPriority ? handlePriorityChange(undefined) : handlePriorityChange(priority);
          }}
        >
          {displayPriorityOption(priority)}
        </MenuItemOption>
      ))}
      <Divider />
    </MenuOptionGroup>
  );

  const listOfPriorities = Object.values(PRIORITY);

  if (isHorizontal)
    return (
      <Flex className={clsx("relative group rounded-md h-8  gap-4", isWideLabel ? "w-48" : "w-8")}>
        {listOfPriorities.map((priority) => (
          <PriorityLabel
            key={priority}
            onClick={() =>
              priority === selectedPriority ? handleChangePriority(undefined) : handleChangePriority(priority)
            }
            isSelected={selectedPriority === priority}
            priority={priority}
            showLabel={false}
            marginTop="mt-2"
          />
        ))}
      </Flex>
    );

  return (
    <Flex className={clsx("relative group rounded-md h-8 justify-between", isWideLabel ? "w-48" : "w-8")}>
      <Box className="absolute z-10 ml-1">
        {selectedPriority ? (
          <Flex className="-mt-1">
            <PriorityLabel priority={selectedPriority} showLabel={false} marginTop="mt-3" />
          </Flex>
        ) : (
          <GrFlag className="mt-2 ml-2 text-gray-300" />
        )}
      </Box>

      <Menu closeOnSelect={true}>
        <MenuButton
          color="gray.500"
          as={Button}
          isDisabled={isDisabled}
          p={-1}
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          backgroundColor={"transparent"}
          className={clsx("text-black h-8 cursor-pointer z-50", isWideLabel ? "-mt-1 w-48" : "w-8")}
          rightIcon={isWideLabel ? <ChevronDownIcon boxSize={5} /> : undefined}
        ></MenuButton>
        <ConditionalWrapper condition={!isInModal} wrapper={(children: any) => <Portal>{children}</Portal>}>
          <MenuList zIndex={50} position="absolute" minWidth="200px" maxH="25em" overflowY="scroll">
            {menu}
          </MenuList>
        </ConditionalWrapper>
      </Menu>
    </Flex>
  );
};

export default PrioritySelect;
