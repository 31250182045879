import { Editor, Extension, ReactRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import { PluginKey } from "prosemirror-state";
import tippy from "tippy.js";

import AIDropdown, { AICommandType, aiCommands } from "./AIDropdown";

export const PLUS_COMMAND_ID = "plus-command";

const Command = Extension.create({
  name: PLUS_COMMAND_ID,
  addOptions() {
    return {
      suggestion: {
        char: "+",
        command: ({ editor, range, props }: { editor: Editor; range: Range; props: any }) => {
          props.command({ editor, range });
        },
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey(PLUS_COMMAND_ID),
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

const renderItems = (selectedLanguage?: string) => {
  let component: ReactRenderer | null = null;
  let popup: any | null = null;

  return {
    onStart: (props: { editor: Editor; clientRect: DOMRect }) => {
      component = new ReactRenderer(AIDropdown, {
        props: { ...props, bubbleMenu: false, selectedLanguage },
        editor: props.editor,
      });

      popup = tippy("body", {
        getReferenceClientRect: props.clientRect as any,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: "manual",
        placement: "bottom-start",
        offset: [0, 0],
      });
    },
    onUpdate: (props: { editor: Editor; clientRect: DOMRect }) => {
      component?.updateProps(props);

      popup &&
        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
    },
    onKeyDown: (props: { event: KeyboardEvent }) => {
      if (props.event.key === "Escape") {
        popup?.[0].hide();

        return true;
      }

      // @ts-ignore
      return component?.ref?.onKeyDown(props);
    },
    onExit: () => {
      popup?.[0].destroy();
      component?.destroy();
    },
  };
};

export const getSuggestionItems = ({ query }: { query: string }): AICommandType[] => {
  return aiCommands.filter((item) => {
    if (typeof query === "string" && query.length > 0) {
      const search = query.toLowerCase();
      return (
        item.title.toLowerCase().includes(search) ||
        (item.searchTerms && item.searchTerms.some((term: string) => term.includes(search)))
      );
    }
    return true;
  });
};

export const PlusCommand = (selectedLanguage: string) =>
  configureCommandWithLanguage(selectedLanguage, Command, renderItems, getSuggestionItems);

export const configureCommandWithLanguage = (
  selectedLanguage: string,
  Command: any,
  renderItems: any,
  getSuggestionItems: any,
) =>
  Command.configure({
    suggestion: {
      items: getSuggestionItems,
      render: () => renderItems(selectedLanguage),
    },
  });
