import {
  Directions,
  Leg,
  PRIMARY_COLOR,
  TRANSPORTATION_TYPE,
  TTransportation,
  getDistance,
  getDistanceFromLatLonInKm,
  getIconRadius,
  secondsToTimeFormat,
} from "@lato/common";
import L, { LatLngExpression } from "leaflet";
import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { useFormContext } from "react-hook-form";
import { Polyline } from "react-leaflet";
import { TransportMarker, transportIcon } from "../../features/map/Maps";
import { useDirections } from "../../features/map/useDirections";

interface MapsPath {
  map: L.Map;
  type: TRANSPORTATION_TYPE;
  markerPositions: number[][];
  transportation_grouped: Partial<TTransportation>[];
}
const isCloserThan = (firstCoords: number[], secondCoords: number[], distance: number) => {
  const distanceBetween = getDistanceFromLatLonInKm(
    { lng: firstCoords[0], lat: firstCoords[1] },
    { lng: secondCoords[0], lat: secondCoords[1] },
  );

  return distanceBetween < distance;
};

export function getRouteMiddlePoint(type: TRANSPORTATION_TYPE, distance: number, coordinates: number[][]) {
  if ([TRANSPORTATION_TYPE.BOAT].includes(type)) {
    const firstCoord = coordinates[0];
    const lastCoord = coordinates[coordinates.length - 1];

    const midPoint = [
      firstCoord[0] + (lastCoord[0] - firstCoord[0]) * 0.5,
      firstCoord[1] + (lastCoord[1] - firstCoord[1]) * 0.5,
    ];

    // let current_distance = 0;
    // let i = 0;
    // while (current_distance < distance / 2) {
    //   // Calculate distance between points of route untill middle point is found
    //   current_distance += getDistanceFromLatLonInKm(
    //     { lat: coordinates[i][0], lng: coordinates[i][1] },
    //     { lat: coordinates[i + 1][0], lng: coordinates[i + 1][1] },
    //   );
    //   i++;
    // }
    return midPoint;
  } else {
    return [coordinates[Math.floor(coordinates.length / 2)][0], coordinates[Math.floor(coordinates.length / 2)][1]];
  }
}

const MapsPath: React.FC<MapsPath> = ({ map, type, markerPositions, transportation_grouped }) => {
  const [coordinates_splitup, setCoordinates_splitup] = React.useState<number[][][]>();
  const { setValue, getValues } = useFormContext();
  const MINIMUM_LEG_DISTANCE = 1000;

  const {
    data: directions,
    isLoading: isLoadingDirections,
    isError: isErrorDirections,
  } = useDirections({
    markerPositions: markerPositions,
    transportation_type: type,
    simplified: false,
    polyline: false,
  });

  const splitDirections = (directions: Directions) => {
    // We've gotten the route, but this route consists of multiple transportations to limit the api calls
    // So, split up the directions into transportations
    const coordinates_splitup: number[][][] = [];
    const { routes, waypoints } = directions;
    let coordinates = directions.routes[0].geometry.coordinates;

    routes[0].legs
      .filter((l) => l.distance > MINIMUM_LEG_DISTANCE / 1000)
      .forEach((leg: Leg, index: number) => {
        // Little routes are ignored
        if (leg.distance > MINIMUM_LEG_DISTANCE) {
          // Using string of coordinates array to efficiently search a coordinate
          const searchJson = JSON.stringify(waypoints[index + 1].location);
          const arrJson = coordinates.map((co: any) => JSON.stringify(co));
          coordinates_splitup.push(coordinates.slice(0, arrJson.indexOf(searchJson)));
          coordinates = coordinates.slice(arrJson.indexOf(searchJson), coordinates.length - 1);
        }
      });

    setCoordinates_splitup(coordinates_splitup);
  };

  const handleMultipleDirections = (directions: Directions[]) => {
    // If a call didn't succeed, show a straight line
    const coordinates_splitup = directions.map((d, i) => {
      if (d.code !== "Ok") {
        return [markerPositions[i], markerPositions[i + 1]];
      }
      return d.routes[0].geometry.coordinates;
    });

    setCoordinates_splitup(coordinates_splitup);
  };

  useEffect(() => {
    if (!coordinates_splitup || !directions) return;

    // Get distances between legs
    const legs_filtered = Array.isArray(directions)
      ? directions.map((d) => {
          if (d.code !== "Ok") {
            return {
              // TODO: distance
              distance: 0,
              duration: 0,
            };
          }
          return d.routes[0];
        })
      : directions.routes[0].legs.filter((l) => l.distance > MINIMUM_LEG_DISTANCE);

    coordinates_splitup.map((_, index: number) => {
      if (!transportation_grouped[index]) return;
      const previousDistance = getValues().distances ?? [];

      if (
        previousDistance[transportation_grouped[index].tripdayIndex!] &&
        !previousDistance[transportation_grouped[index].tripdayIndex!].includes(
          legs_filtered[index] ? legs_filtered[index]?.distance : 0,
        )
      ) {
        previousDistance[transportation_grouped[index].tripdayIndex!].push(
          legs_filtered[index] ? legs_filtered[index]?.distance : 0,
        );
      } else {
        previousDistance[transportation_grouped[index].tripdayIndex!] = [
          legs_filtered[index] ? legs_filtered[index]?.distance : 0,
        ];
      }
      console.log("distances", previousDistance);
      setValue(`distances`, previousDistance);

      const duration = getValues(`durations`) ?? 0;
      setValue(`durations`, duration + (legs_filtered[index] ? legs_filtered[index]?.duration : 0));
    });
  }, [coordinates_splitup]);

  useEffect(() => {
    if (!directions) return;

    if (!Array.isArray(directions)) {
      // Route was in one call, splitting is needed
      splitDirections(directions);
    } else {
      // Route wasn't fetched in one call, so we have to split it up
      handleMultipleDirections(directions);
    }
  }, [directions]);

  if (isLoadingDirections || !directions || isErrorDirections) {
    // Directions are being fetched, show straight lines
    // Or an error occured
    return (
      <Polyline
        pathOptions={{ color: PRIMARY_COLOR }}
        positions={markerPositions.map((coord: number[]) => [coord[1], coord[0]]) as LatLngExpression[]}
      />
    );
  }

  if (coordinates_splitup) {
    const legs_filtered = Array.isArray(directions)
      ? directions.map((d) => {
          if (d.code !== "Ok") {
            return {
              // TODO: distance
              distance: 0,
            };
          }
          return d.routes[0];
        })
      : directions.routes[0].legs.filter((l) => l.distance > MINIMUM_LEG_DISTANCE);

    return coordinates_splitup.map((coordinates: number[][], index: number) => {
      console.log("mapsPath", transportation_grouped, index, legs_filtered);

      return (
        <PathPart
          key={index}
          legs_filtered={legs_filtered}
          map={map}
          coordinates={coordinates}
          transportation_grouped={transportation_grouped}
          index={index}
          type={type}
          directions={directions}
        />
      );
    });
  }
};

export default MapsPath;

interface PathPartProps {
  legs_filtered?: any[];
  map: L.Map;
  coordinates: number[][];
  transportation_grouped: Partial<TTransportation>[];
  index: number;
  type: TRANSPORTATION_TYPE;
  directions?: any;
  disabled?: boolean;
}

export const PathPart: React.FC<PathPartProps> = ({
  legs_filtered,
  map,
  coordinates,
  transportation_grouped,
  index,
  type,
  directions,
  disabled,
}) => {
  React.useEffect(() => {
    if (!map) return;
    const cos = coordinates.map((c) => [c[1], c[0]]) as LatLngExpression[];
    const transportTypeIcon = type && transportIcon[type];

    const shouldShowRoute =
      (legs_filtered && legs_filtered[index]?.distance > 2000000) ||
      [TRANSPORTATION_TYPE.BOAT, TRANSPORTATION_TYPE.CUSTOM].includes(type);
    const firstpolyline = new L.Polyline(shouldShowRoute ? [cos[0], cos[cos.length - 1]] : cos, {
      opacity: 0,
      weight: 10,
    });
    const secondPolyline = new L.Polyline(shouldShowRoute ? [cos[0], cos[cos.length - 1]] : cos, {
      color: "#43a8a0",
      opacity: 1,
      weight: 3,
    });

    const tooltip = new L.Tooltip({ sticky: true });

    tooltip.setContent(`
    <div style="width: 250px">
      <div style="font-weight: 600;  display: flex; justify-content: space-between; align-items: center;">        
        <div style="width: 100px; display: flex; items-align: center;"><div style="display:flex; align-items: center; margin-right: 10px">${ReactDOMServer.renderToString(
          transportation_grouped[index]?.fromIcon,
        )}</div><span style="text-wrap: wrap; items-align: center; display: flex;">${
          transportation_grouped[index] ? transportation_grouped[index]?.fromName : ""
        }</span></div>
        <div style="margin-left: 10px; margin-right: 10px;">${
          type && ReactDOMServer.renderToString(transportTypeIcon)
        }</div>
        <div style="width: 100px; display: flex; items-align: center; justify-content: flex-end;"><span style="text-wrap: wrap; text-align: end; display: flex;">${
          transportation_grouped[index] ? transportation_grouped[index]?.toName : ""
        }</span><div style="display:flex; align-items: center; margin-left: 10px">${ReactDOMServer.renderToString(
          transportation_grouped[index]?.toIcon,
        )}</div></div>
      </div>
      <br />
      <div style="width: 250px; text-align: center; font-size: 14px;">
      ${
        legs_filtered && legs_filtered[index]?.distance !== 0 && type !== TRANSPORTATION_TYPE.CUSTOM
          ? `
          ${legs_filtered[index]?.distance && getDistance(legs_filtered[index]?.distance, 2)}
          ${legs_filtered[index]?.distance && legs_filtered[index]?.duration && " | "}
          ${legs_filtered[index]?.duration && secondsToTimeFormat(legs_filtered[index]?.duration)}
        `
          : disabled
            ? "Directions are disabled"
            : cos.length === 2
              ? "No route found"
              : ""
      }
      </div>
    </div>`);

    const isArrivalUnreachable =
      coordinates.at(-1) &&
      transportation_grouped[index]?.arrivalLocation?.coordinates &&
      !isCloserThan(coordinates.at(-1)!, transportation_grouped[index]?.arrivalLocation?.coordinates!, 0.5);
    const isDepartureUnreachable =
      coordinates.at(0) &&
      transportation_grouped[index]?.departureLocation?.coordinates &&
      !isCloserThan(coordinates.at(0)!, transportation_grouped[index]?.departureLocation?.coordinates!, 0.5);

    const firstCordArrival = isArrivalUnreachable ? [coordinates.at(-1)![1], coordinates.at(-1)![0]] : undefined;
    const secondCordArrival = isArrivalUnreachable
      ? [
          transportation_grouped[index]?.arrivalLocation?.coordinates![1],
          transportation_grouped[index]?.arrivalLocation?.coordinates![0],
        ]
      : undefined;

    const firstCordDeparture = isDepartureUnreachable
      ? [
          transportation_grouped[index]?.departureLocation?.coordinates![1],
          transportation_grouped[index]?.departureLocation?.coordinates![0],
        ]
      : undefined;
    const secondCordDeparture = isDepartureUnreachable ? [coordinates.at(0)![1], coordinates.at(0)![0]] : undefined;

    const arrivalFirstUnreachableLine =
      isArrivalUnreachable &&
      new L.Polyline([firstCordArrival, secondCordArrival] as LatLngExpression[], {
        opacity: 0,
        weight: 20,
        dashArray: "5, 10",
      });
    const arrivalSecondUnreachableLine =
      isArrivalUnreachable &&
      new L.Polyline([firstCordArrival, secondCordArrival] as LatLngExpression[], {
        color: "#43a8a0",
        opacity: 1,
        weight: 3,
        dashArray: "5, 10",
      });

    const departureFirstUnreachableLine =
      isDepartureUnreachable &&
      new L.Polyline([firstCordDeparture, secondCordDeparture] as LatLngExpression[], {
        opacity: 0,
        weight: 20,
        dashArray: "5, 10",
      });
    const departureSecondUnreachableLine =
      isDepartureUnreachable &&
      new L.Polyline([firstCordDeparture, secondCordDeparture] as LatLngExpression[], {
        color: "#43a8a0",
        opacity: 1,
        weight: 3,
        dashArray: "5, 10",
      });

    if (isArrivalUnreachable && arrivalFirstUnreachableLine && arrivalSecondUnreachableLine) {
      arrivalFirstUnreachableLine.bindTooltip(tooltip);
      arrivalSecondUnreachableLine.bindTooltip(tooltip);
      arrivalFirstUnreachableLine.on("mouseover", function () {
        arrivalSecondUnreachableLine.bringToFront();
        secondPolyline.bringToFront();
        secondPolyline.setStyle({ color: "#ff817d" });
        arrivalSecondUnreachableLine.setStyle({ color: "#ff817d" });
      });

      arrivalFirstUnreachableLine.on("mouseout", function () {
        arrivalFirstUnreachableLine.bringToFront();
        firstpolyline.bringToFront();
        secondPolyline.setStyle({ color: "#43a8a0" });
        arrivalSecondUnreachableLine.setStyle({ color: "#43a8a0" });
      });
      arrivalFirstUnreachableLine.addTo(map);
      arrivalSecondUnreachableLine.addTo(map);
    }

    if (isDepartureUnreachable && departureFirstUnreachableLine && departureSecondUnreachableLine) {
      departureFirstUnreachableLine.bindTooltip(tooltip);
      departureSecondUnreachableLine.bindTooltip(tooltip);
      departureFirstUnreachableLine.on("mouseover", function () {
        departureSecondUnreachableLine.bringToFront();
        secondPolyline.bringToFront();
        secondPolyline.setStyle({ color: "#ff817d" });
        departureSecondUnreachableLine.setStyle({ color: "#ff817d" });
      });

      departureFirstUnreachableLine.on("mouseout", function () {
        departureFirstUnreachableLine.bringToFront();
        firstpolyline.bringToFront();
        secondPolyline.setStyle({ color: "#43a8a0" });
        departureSecondUnreachableLine.setStyle({ color: "#43a8a0" });
      });
      departureFirstUnreachableLine.addTo(map);
      departureSecondUnreachableLine.addTo(map);
    }

    firstpolyline.bindTooltip(tooltip);
    secondPolyline.bindTooltip(tooltip);

    firstpolyline.on("mouseover", function () {
      secondPolyline.bringToFront();
      secondPolyline.setStyle({ color: "#ff817d" });
      arrivalSecondUnreachableLine && arrivalSecondUnreachableLine.setStyle({ color: "#ff817d" });
      arrivalSecondUnreachableLine && arrivalSecondUnreachableLine.bringToFront();
      departureSecondUnreachableLine && departureSecondUnreachableLine.setStyle({ color: "#ff817d" });
      departureSecondUnreachableLine && departureSecondUnreachableLine.bringToFront();
    });

    firstpolyline.on("mouseout", function () {
      firstpolyline.bringToFront();
      secondPolyline.setStyle({ color: "#43a8a0" });
      arrivalFirstUnreachableLine && arrivalFirstUnreachableLine.bringToFront();
      arrivalSecondUnreachableLine && arrivalSecondUnreachableLine.setStyle({ color: "#43a8a0" });
      departureFirstUnreachableLine && departureFirstUnreachableLine.bringToFront();
      departureSecondUnreachableLine && departureSecondUnreachableLine.setStyle({ color: "#43a8a0" });
    });

    firstpolyline.addTo(map);
    secondPolyline.addTo(map);
    return () => {
      firstpolyline.removeFrom(map);
      secondPolyline.removeFrom(map);
      arrivalFirstUnreachableLine && arrivalFirstUnreachableLine.removeFrom(map);
      arrivalSecondUnreachableLine && arrivalSecondUnreachableLine.removeFrom(map);
      departureFirstUnreachableLine && departureFirstUnreachableLine.removeFrom(map);
      departureSecondUnreachableLine && departureSecondUnreachableLine.removeFrom(map);
    };
  }, [coordinates, index, legs_filtered, map, transportation_grouped]);

  if (
    transportation_grouped[index] &&
    ![TRANSPORTATION_TYPE.CAR, TRANSPORTATION_TYPE.RENTAL, TRANSPORTATION_TYPE.CUSTOM].includes(
      transportation_grouped[index]?.type!,
    ) &&
    !Array.isArray(directions) &&
    coordinates.length > 2
  ) {
    return (
      <TransportMarker
        key={`transport-marker-${index}`}
        coordinates={getRouteMiddlePoint(
          transportation_grouped[index]?.type!,
          directions.routes[0]?.distance / 1000,
          coordinates,
        )}
        radius={
          getIconRadius(
            coordinates[0][0],
            coordinates[0][1],
            coordinates[coordinates.length - 1][0],
            coordinates[coordinates.length - 1][1],
          ).radius
        }
        transportType={transportation_grouped[index]?.type!}
        transportLength={getDistanceFromLatLonInKm(
          { lng: coordinates[0][0], lat: coordinates[0][1] },
          { lng: coordinates[coordinates.length - 1][0], lat: coordinates[coordinates.length - 1][1] },
        )}
      />
    );
  }
};
