import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Contact, DUE_DAYS_TYPE, PRIORITY, TASK_STATUS, Task, User, UserTrip, calculateDueDate } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import React from "react";
import { useForm } from "react-hook-form";
import { FaArrowUpRightFromSquare, FaCaretDown } from "react-icons/fa6";
import { IoMdPerson } from "react-icons/io";
import { IoEarthSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useMeContext } from "../../stores/me-context";
import { TRIPS_USERTRIPS_KEY } from "../../utils/constants";
import { useMyTeam } from "../../utils/query-helpers/reactQueryHooks";
import { useAddTask, usePatchTask, useRemoveTask } from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { submitDocumentsArray } from "../../utils/submitDocumentsArray";
import { HandleSubmissionType, handleSubmission } from "../../utils/toErrorMap";
import DayPicker from "../../components/input/date/DayPicker";
import SearchContacts from "../../components/TripContacts/SearchContacts";
import TripListDropDown from "../tripList/TripListDropDown";
import Documents from "../documents/Documents";
import Form from "../../components/form/Form";
import DueDatePicker from "../../components/input/DueDatePicker";
import RHFInput from "../../components/input/RHFInput";
import RichTextEditor2 from "../../components/input/wysiwyg/RichTextEditor2";
import CustomModal from "../../components/layout/CustomModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { PrioritySelect } from "./PrioritySelect";
import { StatusSelect } from "./StatusSelect";
import { UserSelect } from "./UserSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { taskValidationSchema } from "../../validation/validationSchemas";

interface TaskModalProps {
  modalDisclosure: any;
  selectedTask?: Task;
  setSelectedTask?: (task?: Task) => void;
  isInTrip?: boolean;
  start_date?: string;
  end_date?: string;
  onSubmitTask?: (task: Task) => void;
  refetch?: () => void;
  contact?: Contact;
  userTrip?: UserTrip;
  queryKey?: string;
  isSample?: boolean;
}

const TaskModal: React.FC<TaskModalProps> = ({
  modalDisclosure,
  selectedTask,
  setSelectedTask,
  start_date,
  end_date,
  isInTrip = false,
  onSubmitTask,
  refetch,
  contact,
  userTrip,
  queryKey,
  isSample = false,
}) => {
  const { isOpen, onClose } = modalDisclosure;
  const deleteDisclosure = useDisclosure();
  const contactDisclosure = useDisclosure();
  const relationDisclosure = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { id: userTripId } = useParams<{ id: string }>();

  const me = useMeContext();
  const { data: users, isLoading: isLoadingTeam } = useMyTeam();

  const [dateMode, setDateMode] = React.useState<boolean>(
    selectedTask ? !selectedTask?.due_days_type || !selectedTask?.due_days : !isInTrip,
  );
  const [trip, setTrip] = React.useState<UserTrip | null>(userTrip);

  const onCloseModal = () => {
    setSelectedTask && setSelectedTask(undefined);
    onClose();
  };

  const formMethods = useForm<Task>({
    mode: "onSubmit",
    resolver: yupResolver(taskValidationSchema),
    reValidateMode: "onChange",
    defaultValues: selectedTask ?? {
      title: "",
      description: "",
      status: TASK_STATUS.TO_DO,
      user: me,
      due_date: start_date ? new Date(start_date) : new Date(),
      due_days: 7,
      due_days_type: DUE_DAYS_TYPE.BEFORE_START,
      priority: undefined,
      docs: [],
      trip: userTrip,
      contact: contact,
    },
  });

  const { setValue, getValues, watch, formState } = formMethods;

  const { mutateAsync: updateTask } = usePatchTask(queryClient, userTripId ?? contact?.id, queryKey);
  const { mutateAsync: addTask, isPending: isAddingTask } = useAddTask(
    queryClient,
    userTripId ?? contact?.id,
    queryKey,
  );
  const { mutateAsync: deleteTask, isPending: isDeleting } = useRemoveTask(queryClient);

  const successCallback = React.useCallback((taskDTO: Task) => {
    refetch && refetch();
    onCloseModal();
    onSubmitTask && onSubmitTask(taskDTO);
  }, []);

  const onSubmit = async () => {
    const formValues = getValues();

    let date;

    if (formValues.due_date && dateMode) {
      date = new Date(formValues.due_date);
      date.setDate(date.getDate());
    }

    if (formValues.docs) {
      await submitDocumentsArray(formValues.docs, "tasks/");
    }

    const taskDTO: Task = {
      ...formValues,
      due_date:
        date ??
        calculateDueDate(
          new Date(start_date ?? ""),
          new Date(end_date ?? ""),
          formValues.due_days!,
          formValues.due_days_type,
        ),
      due_days: !dateMode ? parseInt(formValues.due_days) : null,
      due_days_type: !dateMode ? formValues.due_days_type : null,
      priority: formValues.priority ?? null,
      user: formValues.user ?? null,
      contact: formValues.contact ?? null,
      trip: formValues.trip ?? null,
    };

    const submissionValues: Pick<HandleSubmissionType<any>, "successMessage" | "failMessage" | "apiCall"> = selectedTask
      ? { successMessage: "Task is updated", failMessage: "to update task", apiCall: updateTask(taskDTO) }
      : { successMessage: "Task is added", failMessage: "to add task", apiCall: addTask(taskDTO) };

    await handleSubmission({
      ...submissionValues,
      toast,
      setError: formMethods.setError,
      successCallback: () => successCallback(taskDTO),
    });
  };

  return (
    <>
      <CustomModal
        title={selectedTask ? "Updating task" : "Creating task"}
        onClose={onCloseModal}
        isOpen={isOpen}
        size="2xl"
        colorScheme="brand"
        modalContentProps={{
          minH: "80vh",
        }}
        modalBodyProps={{
          padding: 0,
        }}
        className="p-0 m-0"
        showHeader={false}
      >
        <Form formMethods={formMethods} onSubmit={onSubmit} className="h-full">
          <Stack spacing={3} m={8} h="full">
            <RHFInput
              name="title"
              label="Title"
              isRequired
              layout="inline"
              fontSizeInline="1.5rem"
              className="text-xl font-bold"
              placeholder="Task title"
            />
            <Flex my={4} gap={6} justifyContent={"space-between"}>
              <VStack spacing={2} alignItems={"flex-start"} w={"45%"}>
                <Flex>
                  <Text width="11em" fontSize="sm" fontWeight={"medium"} mb={0.5} color="realGray.400">
                    Status
                  </Text>
                  <StatusSelect
                    selectedStatus={watch().status}
                    handleChangeStatus={(status: TASK_STATUS) => {
                      setValue("status", status);
                    }}
                  />
                </Flex>
                <Box mt={4}>
                  {isInTrip || !!watch().trip ? (
                    <DueDatePicker
                      name="due_date"
                      defaultDate={watch().due_date}
                      required
                      fromDate={new Date()}
                      label={dateMode ? "Fixed due date" : "Automatic due date"}
                      dateMode={dateMode}
                      setDateMode={setDateMode}
                      start_date={start_date ? new Date(start_date) : new Date(trip?.trip?.start_date)}
                      end_date={end_date ? new Date(end_date) : new Date(trip?.trip?.end_date)}
                      inputWidth="17em"
                      customOnClickHandler={(value: any) => setValue("due_date", value)}
                      hideDateMode={isSample}
                      header={
                        <div className="w-full h-16 isolate inline-flex rounded-md self-end">
                          <button
                            type="button"
                            onClick={() => setDateMode(false)}
                            className={clsx(
                              "w-1/2 px-3 py-1 text-sm justify-center font-bold",
                              !dateMode && "text-[#43a8a0]",
                            )}
                          >
                            Automatic
                          </button>
                          <Divider orientation="vertical" />
                          <button
                            type="button"
                            onClick={() => setDateMode(true)}
                            className={clsx(
                              "w-1/2 px-3 py-1 text-sm justify-center font-bold",
                              dateMode && "text-[#43a8a0]",
                            )}
                          >
                            Fixed
                          </button>
                        </div>
                      }
                    />
                  ) : (
                    <DayPicker
                      name="due_date"
                      defaultDate={selectedTask?.trip ? start_date : watch().due_date}
                      label="Due date"
                      customOnClickHandler={(value: any) => {
                        const date = new Date(value);
                        date.setDate(date.getDate() + 1);
                        setValue("due_date", date);
                      }}
                      inputWidth="12em"
                    />
                  )}
                </Box>
                {!isInTrip && (
                  <Flex mt={2}>
                    <Text mt={2} minW="11em" fontSize="sm" fontWeight={"medium"} mb={0.5} color="realGray.400">
                      Relations
                    </Text>
                    <Popover
                      isOpen={relationDisclosure.isOpen}
                      onOpen={relationDisclosure.onOpen}
                      onClose={relationDisclosure.onClose}
                      placement="bottom"
                      trigger="click"
                    >
                      <PopoverTrigger>
                        <Button w={"12em"} className="flex justify-between">
                          {watch().trip && (
                            <>
                              <IoEarthSharp className="mr-2" />
                              <span className="w-3/4 truncate">{watch().trip?.trip?.titles[0]?.content}</span>
                            </>
                          )}
                          {watch().contact && (
                            <>
                              <IoMdPerson className="mr-2" />
                              <span className="w-3/4 truncate">
                                {watch().contact.first_name} {watch().contact.last_name}
                              </span>
                            </>
                          )}
                          {!watch().trip && !watch().contact && "Select relation"}
                          <FaCaretDown className="ml-2" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="bg-transparent border-0">
                        <PopoverArrow />
                        <PopoverBody p={0}>
                          <Tabs isFitted size="md" colorScheme="brand" defaultIndex={0}>
                            <TabList>
                              <Tab>Trip</Tab>
                              <Tab>Contact</Tab>
                            </TabList>

                            <TabPanels>
                              <TabPanel>
                                <TripListDropDown
                                  isDropdown={false}
                                  dropdownHeight="12em"
                                  setTrip={(trip: any) => {
                                    setValue("contact", null);
                                    setValue("trip", trip);
                                    setTrip(trip);
                                  }}
                                  queryKey={TRIPS_USERTRIPS_KEY}
                                  placeholder="Search for trip"
                                />
                              </TabPanel>
                              <TabPanel>
                                <SearchContacts
                                  assignContactsToTrip={(contact: Partial<Contact>) => {
                                    setValue("trip", null);
                                    setValue("contact", contact as Contact);
                                  }}
                                  fields={[]}
                                  disclosure={contactDisclosure}
                                  placeholder="Search for contact"
                                  isDropdown={false}
                                  isCreateContactDisabled={true}
                                />
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                          <Button
                            className="mx-auto w-full"
                            variant="none"
                            onClick={() => {
                              setValue("trip", null);
                              setValue("contact", null);
                              relationDisclosure.onClose();
                            }}
                          >
                            Or clear relation
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                    {(watch().trip || watch().contact) && (
                      <IconButton
                        bgColor="transparent"
                        color="gray"
                        _hover={{ bgColor: "transparant" }}
                        icon={<FaArrowUpRightFromSquare />}
                        onClick={() => {
                          watch().trip && window.open(`/trips/${watch().trip!.id}`);
                          watch().contact && window.open(`/contacts/${watch().contact!.id}`);
                        }}
                        aria-label={"Go to relation button"}
                      />
                    )}
                  </Flex>
                )}
              </VStack>

              <VStack spacing={4} alignItems={"flex-start"} w={"45%"}>
                <Flex>
                  <Text fontSize="sm" fontWeight={"medium"} mx="1px" color="realGray.400" width="8em">
                    Priority
                  </Text>
                  <Box className="-mt-1">
                    <PrioritySelect
                      handleChangePriority={(priority: PRIORITY) => setValue("priority", priority)}
                      selectedPriority={watch().priority}
                      isDisabled={false}
                      isWideLabel={true}
                      isHorizontal={true}
                    />
                  </Box>
                </Flex>
                {!isLoadingTeam && (
                  <Flex>
                    <Text fontSize="sm" fontWeight={"medium"} mt={2} mx="1px" color="realGray.400" width="8em">
                      Assigned to
                    </Text>
                    {users && (
                      <UserSelect
                        handleUserSelect={(user?: User) => setValue("user", user)}
                        selectedUser={watch().user}
                        isDisabled={isLoadingTeam}
                        users={users as User[]}
                      />
                    )}
                  </Flex>
                )}
              </VStack>
            </Flex>
            <RichTextEditor2
              defaultValue={watch().description}
              onChange={(value: string) => formMethods.setValue("description", value)}
            />
            <Documents fieldArrayName="docs" inForm={true} showDocumentType={false} />
            <Flex placeSelf={"end"} justifyContent="end" w="full" px={8} mb={4}>
              {selectedTask && (
                <Button onClick={() => deleteDisclosure.onOpen()} colorScheme="red" variant="outline" mr={2}>
                  Delete
                </Button>
              )}
              <Button type="submit" colorScheme="brand" isLoading={isAddingTask}>
                {selectedTask ? "Update task" : "Create task"}
              </Button>
            </Flex>
          </Stack>
        </Form>
      </CustomModal>

      {deleteDisclosure.isOpen && (
        <ConfirmModal
          title={"Delete task"}
          description={`Are you sure you want to delete this task?`}
          action={() => {
            deleteTask(selectedTask);
            onCloseModal();
            refetch && refetch();
          }}
          disclosure={deleteDisclosure}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};

export default TaskModal;
