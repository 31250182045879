import { Box } from "@chakra-ui/react";
import React from "react";
import { BiBookContent, BiMailSend } from "react-icons/bi";
import {
  BsCalculator,
  BsCheckLg,
  BsCursorText,
  BsFileEarmarkWord,
  BsFillFileTextFill,
  BsLightbulb,
  BsPeople,
  BsTranslate,
} from "react-icons/bs";
import { FaMapMarkedAlt, FaTasks } from "react-icons/fa";
import { FaLink, FaRegBell, FaSlideshare, FaSuitcase } from "react-icons/fa6";
import { GrRobot } from "react-icons/gr";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { IoIosSpeedometer } from "react-icons/io";
import { IoColorPaletteOutline } from "react-icons/io5";
import { MdOndemandVideo, MdOutlineDesignServices, MdPayment, MdRecordVoiceOver } from "react-icons/md";
import { RiExternalLinkLine, RiFilePdf2Line, RiHistoryFill, RiRoadMapLine } from "react-icons/ri";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import colors from "../../theme/foundations/colors";
import { SeperatedHeading } from "../../components/SeperateHeading";
interface featuresProps {}

export type Feature = {
  title: string;
  description: string;
  icon: any;
  newest_feature?: boolean;
  year?: number;
  scroll?: boolean;
};

const new_features: Feature[] = [
  // {
  //   title: "Accommodation options",
  //   description: "Propose multiple accommodations to your clients.",
  //   icon: <BsChatDots />,
  //   year: 2022,
  // },
  // {
  //   title: "Fullscreen images",
  //   description: "Your clients can now view the images on full screen.",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "Order travel documents",
  //   description: "Order the travel documents to your liking",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "Multiple company brands",
  //   description: "Add multiple company brands/logos to your account",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "Resize company logo",
  //   description: "Resize your logo to make sure it looks great on the travel app",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "Omit trip day location",
  //   description: "Omit a location for when your client wants to plan some days themselves.",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "Nights instead of days",
  //   description: "Now you can use nights instead of days to plan your trips.",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "PDF improvement",
  //   description: "Generating a PDF is now faster and easier.",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "Download PDF from travel app",
  //   description: "Your clients can now download the PDF directly from the travel app.",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "POI improvement",
  //   description: "Add images to your POI's and format the description of your POI's.",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "Upload any document extension",
  //   description: "Upload documents in any extension (.pdf, .jpg, .png, .doc, .txt, ...).",
  //   icon: <BsChatDots />,
  // },
  // {
  //   title: "Lato library",
  //   description: "Save activities and accommodations and reuse them.",
  //   icon: <IoExtensionPuzzleOutline />,
  // },
  // {
  //   title: "Chat",
  //   description: "Chat available between travel agent and traveler",
  //   icon: <BsChatDots />,
  // },
  {
    title: "Sample sharing",
    description: "Follow other travel professionals and exchange ideas",
    icon: <FaSlideshare />,
    year: 2023,
  },
  {
    title: "Invite link",
    description: "Invite new travel professionals and gain free user months",
    icon: <BiMailSend />,
  },
  {
    title: "Deepl Translation",
    description: "Use <b>Deepl</b> for translating every text using tripbuilding",
    icon: <BsTranslate />,
  },
  {
    title: "ChatGPT integration",
    description: "Generate content in a matter of seconds using <b>ChatGPT</b>",
    icon: <BsTranslate />,
  },
  {
    title: "Flights & Transport",
    description: "Add flights, trains, car rides and all other means of <b>transportation</b> to trips",
    icon: <IoColorPaletteOutline />,
  },
  {
    title: "Video integration",
    description: "You can now use <b>photos and videos</b> to make your travel proposals more attractive",
    icon: <MdOndemandVideo />,
  },
  {
    title: "Saving text fields",
    description:
      "Tired of copying and pasting the same <b>textfields</b> from other trips?\n Now you can <b>save</b> them and easily <b>add</b> them to other trips",
    icon: <BsCursorText />,
  },
  {
    title: "Image by link",
    description: "It is now possible to add <b>images</b> by just <b>copying the link</b> in Lato",
    icon: <RiExternalLinkLine />,
  },
  {
    title: "Generating DocxFiles",
    description: "Next to generating a PDF File it is now also possible to <b>generate</b> a <b>DOCX File</b>",
    icon: <BsFileEarmarkWord />,
  },
  {
    title: "Custom travel app styling",
    description: "Apply your own custom styling to the travel app",
    icon: <BsCursorText />,
  },
  {
    title: "Copy and paste images",
    description: "Copy and paste images in the trip editor to build beautiful itineraries even faster",
    icon: <BsFileEarmarkWord />,
  },
  {
    title: `Translate POIs`,
    description: "Easily translate POIs manually or with DeepL",
    icon: <RiExternalLinkLine />,
  },
  {
    title: "Contact database",
    description: "Manage all your contacts from within Lato",
    icon: <BsPeople />,
  },
  {
    title: "Default documents",
    description: "Add default documents that will be automatically added to your trips",
    icon: <HiOutlineDocumentCheck />,
  },
  {
    title: "LATO AI",
    description: "Quickly edit or generate content using Lato's AI tools",
    icon: <HiOutlineDocumentCheck />,
  },
  {
    title: "Detailed travel routes, durations & distances",
    description: "Gives travelers detailed travel routes, durations and distances of the trip",
    icon: <HiOutlineDocumentCheck />,
  },
  {
    title: "Trip import using AI",
    description:
      "Import existing travel proposal documents (PDF, txt, Word) & let AI read them and automatically create a trip in Lato.",
    icon: <HiOutlineDocumentCheck />,
    year: 2024,
  },
  {
    title: "Major performance upgrade + partial autosave",
    description:
      "The trip editing page is now significantly faster and has a partial autosave feature to avoid losing data",
    icon: <IoIosSpeedometer />,
  },
  {
    title: "Map view of activities & accommodations",
    description: "View all your activities/accommodations inside one map view",
    icon: <FaMapMarkedAlt />,
    newest_feature: true,
  },
  {
    title: "Improvement on importing a trip using AI",
    description: "Improved styling of exported PDFs when importing a trip using AI",
    icon: <RiFilePdf2Line />,
    newest_feature: true,
    scroll: true,
  },
];
export const newest_features = new_features.filter((f) => f.newest_feature);

const scheduled_2024: Feature[] = [
  {
    title: "Destination content",
    description: "Easily customize your trip with individual titles and descriptions for each day",
    icon: <BiBookContent />,
    newest_feature: true,
  },
  {
    title: "AI in your brand voice",
    description: "configure AI preferences per brand to more accurately generate content",
    icon: <GrRobot />,
  },
  {
    title: "TTS (text-to-speech)",
    description: "Let your travelers enjoy a voice-over of your trips in Lato",
    icon: <MdRecordVoiceOver />,
  },

  {
    title: "Task management",
    description: "Create and manage your tasks in Lato",
    icon: <FaTasks />,
  },
  {
    title: "Payment Invites",
    description: "Sends a payment invite via mail",
    icon: <BiMailSend />,
  },
  {
    title: "Integration bed banks",
    description: "List of accommodations integrated from Hotelbeds, Ratehawk and Yalago ",
    icon: <FaRegBell />,
  },
  {
    title: "Offline app & push notifications",
    description: "Use the app in offline mode & receive notifications from messages etc",
    icon: <FaRegBell />,
  },

  {
    title: "Calculation tool",
    description: "Keep track of your bookings and calculate the total cost of a trip in Lato",
    icon: <BsCalculator />,
  },
  {
    title: "Payment via travel app",
    description: "Allow travelers to pay immediately via the app when confirming a trip",
    icon: <MdPayment />,
  },
  {
    title: "API Connection",
    description: "Connect your other systems to Lato",
    icon: <FaLink />,
  },
  {
    title: "Integrate your own look & feel",
    description: "Add your own branding to proposals in the Lato travel app",
    icon: <MdOutlineDesignServices />,
  },
  {
    title: "Traveler input",
    description:
      "Allow travelers to add their own personal details and have easy access to all the information you need to book their trip",
    icon: <FaSuitcase />,
  },
  {
    title: "History & revision",
    description: "Go back to a previous version of your proposal in just a few clicks",
    icon: <RiHistoryFill />,
  },
  // {
  //   title: "External hotel databases",
  //   description:
  //     "Use our connected external hotel databases to quickly add hotel information to your trip",
  //   //icon: <BsCalculator />,
  // },
  {
    title: "Daily Google Maps itineraries",
    description: "Work out daily itineraries for a trip and add them to the travel app",
    icon: <RiRoadMapLine />,
  },
  {
    title: "Website CMS + CRM",
    description:
      "Connect Lato to your website CMS to share your trips directly on your website and/or to your CRM system to reduce your administrative work",
    icon: <BsFillFileTextFill />,
  },
];
export const upcoming_features = scheduled_2024.filter((f) => f.newest_feature);

const scheduled_2025: Feature[] = [
  {
    title: "Automated travel proposal creation",
    description: "Have travelers share their preferences to let Lato propose a trip automatically",
    icon: <BsLightbulb />,
  },
];

export const FeaturesOverview: React.FC<featuresProps> = () => {
  let index = 0;
  const getTimelinePosition = (index: number): "left" | "right" => {
    return index % 2 === 0 ? "left" : "right";
  };
  const scrollRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <Box w="100%" maxW="1000px" mx="auto" display="flex" flexDirection="row" position="relative">
      <SeperatedHeading title="Lato's Roadmap" size={36} style={{ position: "absolute" }} />
      {/* @ts-ignore */}
      <VerticalTimeline animate={true} lineColor={"black"}>
        {new_features.map((feature) => {
          const newest_feature = feature.newest_feature;
          return (
            <>
              {feature.year && <YearBox text={`${feature.year}`} />}
              {/* @ts-ignore */}
              <VerticalTimelineElement
                key={feature.title}
                contentStyle={{
                  background: newest_feature ? colors.lato.secondaryColor : colors.lato.primaryColor,
                  color: "white",
                  borderRadius: "20px",
                  boxShadow: "1px 2px lightgray",
                }}
                contentArrowStyle={{
                  display: "none",
                }}
                iconStyle={{
                  background: colors.lato.primaryColorDark,
                  color: "#fff",
                  border: "0px",
                }}
                icon={feature.icon}
                position={getTimelinePosition(index++)}
              >
                {newest_feature ? (
                  <Box
                    ref={feature.scroll ? scrollRef : null}
                    backgroundColor={colors.lato.primaryColor}
                    borderRadius="15px"
                    width={{ base: "20%", md: "12%" }}
                    py={{ base: "2px", md: "0px" }}
                    textAlign={"center"}
                    position="absolute"
                    right={"-5px"}
                    top={"-6px"}
                    zIndex={1000}
                    fontWeight={"bold"}
                    color={"white"}
                    fontSize={"14px"}
                  >
                    NEW
                  </Box>
                ) : (
                  <BsCheckLg
                    size="42px"
                    color={"white"}
                    style={{
                      position: "absolute",
                      right: "-3px",
                      top: "-4px",
                    }}
                  />
                )}
                <h3 className="vertical-timeline-element-title" style={{ fontWeight: "bold" }}>
                  {feature.title}
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  {feature.description.replaceAll("<b>", "").replaceAll("</b>", "")}
                </h4>
              </VerticalTimelineElement>
            </>
          );
        })}
        {scheduled_2024.map((feature, i) => {
          const inProgress = i <= 5;
          return (
            // @ts-ignore
            <VerticalTimelineElement
              key={feature.title}
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "white",
                color: colors.lato.primaryColor,
                borderRadius: "20px",
                boxShadow: "1px 2px lightgray",
              }}
              contentArrowStyle={{
                display: "none",
              }}
              iconStyle={{
                background: colors.lato.primaryColorDark,
                color: "#fff",
                border: "0px",
              }}
              icon={feature.icon}
              position={getTimelinePosition(index++)}
            >
              {inProgress && (
                <Box
                  backgroundColor={colors.lato.primaryColor}
                  borderRadius="15px"
                  width={{ base: "30%", md: "28%" }}
                  px={{ base: "2px", md: "0px" }}
                  textAlign={"center"}
                  position="absolute"
                  right={"-5px"}
                  top={"-6px"}
                  zIndex={1000}
                  fontWeight={"bold"}
                  color={"white"}
                  fontSize={{ base: "12px", md: "14px" }}
                >
                  IN PROGRESS
                </Box>
              )}
              <h3 className="vertical-timeline-element-title" style={{ fontWeight: "bold", width: "70%" }}>
                {feature.title}
              </h3>
              <h4 className="vertical-timeline-element-subtitle">{feature.description}</h4>
            </VerticalTimelineElement>
          );
        })}
        <YearBox text="2025" />
        {scheduled_2025.map((feature) => (
          // @ts-ignore
          <VerticalTimelineElement
            key={feature.title}
            className="vertical-timeline-element--work"
            contentStyle={{
              background: "white",
              color: colors.lato.primaryColor,
              borderRadius: "20px",
              boxShadow: "1px 2px lightgray",
            }}
            contentArrowStyle={{
              display: "none",
            }}
            iconStyle={{
              background: colors.lato.primaryColorDark,
              color: "#fff",
              border: "0px",
            }}
            icon={feature.icon}
            position={getTimelinePosition(index++)}
          >
            <h3 className="vertical-timeline-element-title" style={{ fontWeight: "bold" }}>
              {feature.title}
            </h3>
            <h4 className="vertical-timeline-element-subtitle">{feature.description}</h4>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </Box>
  );
};

export default FeaturesOverview;

interface YearBoxProps {
  text: string;
}

export const YearBox: React.FC<YearBoxProps> = ({ text }) => {
  return (
    <Box
      margin={{ base: "0", lg: "auto" }}
      backgroundColor={colors.lato.primaryColor}
      borderRadius="30px"
      width={{ base: "14%", md: "10%" }}
      textAlign={"center"}
      position="relative"
      zIndex={1000}
      fontWeight={"bold"}
      color={"white"}
      fontSize={{ base: "17px", sm: "20px", lg: "24px" }}
    >
      {text}
    </Box>
  );
};
