import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "authentication";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

const register = (registerInput: any) => {
  return rawApiProvider.post(url + "/register", registerInput);
};

const login = (loginInput: any) => {
  return rawApiProvider.post(url + "/login", loginInput);
};

const me = () => {
  return rawApiProvider.get(url + "/me");
};

const logout = () => {
  return rawApiProvider.get(url + "/logout");
};

const createStripeAccount = () => {
  return rawApiProvider.get(url + "/create-stripe-account");
};

const changePassword = (changePasswordInput: {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}) => {
  return rawApiProvider.post(url + `/change-password/`, changePasswordInput);
};

const changePasswordToken = (registerInput: { password: string; token: string }) => {
  return rawApiProvider.put(url + `/change-password-token/`, registerInput);
};

const getChangePasswordTokenStatus = (token: string) => {
  return rawApiProvider.get(`${url}/change-password-token-status/${token}`);
};

const forgotPassword = (email: string) => {
  return rawApiProvider.post(url + "/forgot-password", { email });
};

const sendNewEmailConfirm = (email: string) => {
  return rawApiProvider.post(url + `/new-confirm-email/`, { email });
};

const confirmEmail = (token: string) => {
  return rawApiProvider.get(url + "/confirm-email/" + token);
};

const googleTokens = (code: string) => {
  return rawApiProvider.post(url + "/google", { code });
}

const googleRefreshToken = (refreshToken: string) => {
  return rawApiProvider.post(url + "/refresh-token", { refreshToken });
}

type AuthAPIType = ReturnEndpoints & {
  login(loginInput: any): Promise<any>;
  register(registerInput: any): Promise<any>;
  me(): Promise<any>;
  logout(): Promise<any>;
  createStripeAccount(): Promise<any>;
  changePassword(changePasswordInput: object): Promise<any>;
  changePasswordToken(changePasswordInput: object): Promise<any>;
  forgotPassword(email: string): Promise<any>;
  sendNewEmailConfirm(email: string): Promise<any>;
  confirmEmail(token: string): Promise<any>;
  getChangePasswordTokenStatus(token: string): Promise<boolean>;
  googleTokens(code: string): Promise<any>;
  googleRefreshToken(refreshToken: string): Promise<any>;
};
const AuthAPI: AuthAPIType = {
  ...apiEndpoints,
  login,
  register,
  me,
  logout,
  createStripeAccount,
  changePassword,
  changePasswordToken,
  forgotPassword,
  sendNewEmailConfirm,
  confirmEmail,
  getChangePasswordTokenStatus,
  googleTokens,
  googleRefreshToken,
};
export default AuthAPI;

export type LoginType = {
  email: string;
  password: string;
};

export type RegisterType = LoginType & {
  // name: string;
  // companyName: string;
};
