import { create } from "zustand";
import { Message } from "../../pages/chats";

interface ChatStoreState {
  newLocalMessagesPerTrip: {
    [key: string]: Message[];
  };
  addNewLocalTripMessage: (message: Message) => void;
  removeLocalTripMessage: (userTripId: string, id: string) => void;
  removeLocalTripMessages: (userTripId: string) => void;
  resetStore: () => void;
}

const initialState = {
  newLocalMessagesPerTrip: {},
};

export const useChatStore = create<ChatStoreState>((set, get) => ({
  ...initialState,
  addNewLocalTripMessage: (newMessage) =>
    set((prevState) => ({
      newLocalMessagesPerTrip: {
        ...prevState.newLocalMessagesPerTrip,
        // Append the new message at the end of the list
        [newMessage.usertrip.id!]:
          newMessage.usertrip.id! in prevState.newLocalMessagesPerTrip
            ? [...prevState.newLocalMessagesPerTrip[newMessage.usertrip.id!], newMessage]
            : [newMessage],
      },
    })),
  removeLocalTripMessage: (userTripId, id) => {
    set(() => {
      const prevNewLocalMessagesPerTrip = get().newLocalMessagesPerTrip;
      return {
        newLocalMessagesPerTrip:
          userTripId in prevNewLocalMessagesPerTrip
            ? {
                ...prevNewLocalMessagesPerTrip,
                [userTripId]: prevNewLocalMessagesPerTrip[userTripId].filter((mess) => mess.id! !== id),
              }
            : { ...prevNewLocalMessagesPerTrip },
      };
    });
  },
  removeLocalTripMessages: (userTripId) =>
    set((prevState) => {
      const prevNewLocalMessagesPerTrip = {
        ...prevState.newLocalMessagesPerTrip,
      };
      delete prevNewLocalMessagesPerTrip[userTripId];
      return {
        newLocalMessagesPerTrip: prevNewLocalMessagesPerTrip,
      };
    }),
  resetStore: () => {
    set(initialState);
  },
}));
