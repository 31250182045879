import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import { Box, Button, Circle, Flex, Icon, IconButton, Image, Text, Tooltip } from "@chakra-ui/react";
import { CompanyBrand, PRIMARY_COLOR, SECONDARY_COLOR } from "@lato/common";
import { Trash2 as DeleteIcon } from "lucide-react";
import React from "react";
import { FaRegStar, FaStar } from "react-icons/fa";

interface BrandingProps {
  id: string | undefined;
  selectedBrandIndex: number;
  brands: CompanyBrand[];
  addNewBrand: () => void;
  selectBrand: (i: number) => void;
  setBrandAsDefault: (i: number) => void;
  deleteBrand: (i: number) => void;
}

export const newEmptyBrand: CompanyBrand = {
  name: "New brand",
  logoScale: 1,
  default_brand: false,
  theme: {
    primary_color: PRIMARY_COLOR,
    secondary_color: SECONDARY_COLOR,
  },
  content_ai_preference_sentence: "",
};

// Size of the branding flex
const BrandingSize = "180px";

const Branding: React.FC<BrandingProps> = ({
  id,
  selectedBrandIndex,
  brands,
  addNewBrand,
  setBrandAsDefault,
  selectBrand,
  deleteBrand,
}) => {
  const maxBrands = 5;

  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      alignItems={{ base: "center", md: "stretch" }}
      justifyContent={"start"}
      mt={2}
      maxW={{ base: "100%", md: "72vw" }}
      overflowX={"scroll"}
      gap={2}
    >
      {brands.map((brand, i) => (
        <Brand
          key={brand.id ?? `brand-${i}`}
          id={id}
          i={i}
          isSelected={selectedBrandIndex === i}
          canDelete={brands.length > 1}
          deleteBrand={deleteBrand}
          selectBrand={selectBrand}
          setBrandAsDefault={setBrandAsDefault}
          brand={brand}
        />
      ))}
      {brands.length < maxBrands && (
        <Flex
          as={Button}
          w={BrandingSize}
          alignItems={"center"}
          justifyContent="center"
          flexDir={"column"}
          onClick={addNewBrand}
          colorScheme="brand"
          h={{ base: "180px", md: "unset" }}
          variant="outline"
          bg={"white"}
        >
          <Icon as={AddIcon} />
          Add new brand
        </Flex>
      )}
    </Flex>
  );
};
export default React.memo(Branding);

interface BrandProps {
  id: string | undefined;
  i: number;
  brand: CompanyBrand;
  canDelete: boolean;
  deleteBrand: (i: number) => void;
  selectBrand: (i: number) => void;
  setBrandAsDefault: (i: number) => void;
  isSelected: boolean;
}
const Brand: React.FC<BrandProps> = ({
  id,
  i,
  brand,
  canDelete,
  deleteBrand,
  selectBrand,
  setBrandAsDefault,
  isSelected,
}) => {
  const iconSize = "2em";
  // If brand is the default brand, show a filled star, else show an empty star
  const defaultIcon = brand.default_brand ? <FaStar size={iconSize} /> : <FaRegStar size={iconSize} />;

  return (
    <Flex flexDir={"column"} id={id}>
      <Flex
        as={Button}
        onClick={() => selectBrand(i)}
        colorScheme="brand"
        variant="outline"
        w={BrandingSize}
        h={BrandingSize}
        p={2}
        borderRadius="md"
        borderColor="brand.600"
        borderWidth={isSelected ? "2px" : "1px"}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        pos="relative"
        bg={"white"}
      >
        {brand.logoUrl ? (
          <Box>
            <Image
              maxH={`calc(${brand.logoScale}*3em)`}
              src={brand.logoUrl}
              alt={`company-logo`}
              fallbackSrc="https://via.placeholder.com/200"
            ></Image>
          </Box>
        ) : (
          <Text fontSize="lg">{brand.name}</Text>
        )}
        {canDelete && (
          // Delete Icon
          <Tooltip label="Delete brand">
            <IconButton
              aria-label="Delete POI"
              size="sm"
              onClick={(e) => {
                // To prevent the brand from being selected when clicking the delete icon
                e.stopPropagation();
                deleteBrand(i);
              }}
              icon={<DeleteIcon size={iconSize} />}
              colorScheme="red"
              variant="outline"
              border="none"
              pos={"absolute"}
              z-index={10}
              insetInlineEnd={"4px"}
              insetBlockStart={"4px"}
            />
          </Tooltip>
        )}
        {/* Default Brand Icon */}
        <Tooltip label={brand.default_brand ? "Default brand" : "Set as default brand"}>
          <IconButton
            aria-label="Delete POI"
            size="sm"
            onClick={(e) => {
              // To prevent the brand from being selected when clicking the default icon
              e.stopPropagation();
              setBrandAsDefault(i);
            }}
            icon={defaultIcon}
            color={"#F2C519"}
            variant="outline"
            border="none"
            pos={"absolute"}
            z-index={10}
            insetInlineEnd={"4px"}
            insetBlockEnd={"4px"}
          />
        </Tooltip>
        {/* Icon showing if brand is currently selected */}
        {isSelected && (
          <Circle
            pos="absolute"
            insetBlock={"8px"}
            insetInline={"8px"}
            size="25px"
            bg={isSelected ? "brand.400" : undefined}
            borderColor={isSelected ? "brand.500" : "gray.300"}
            color={isSelected ? "white" : "gray.300"}
            borderWidth="2px"
          >
            <CheckIcon boxSize={"50%"} />
          </Circle>
        )}
      </Flex>
    </Flex>
  );
};
