import { Box, Button, Flex, Heading, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Contact } from "@lato/common";
import React from "react";
import { BsPersonFill } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import ContactAPI from "../../api/contacts.api";
import { handleSubmission } from "../../utils/toErrorMap";
import { OptionsDropdownItem } from "../../components/CRUD/OptionsDropdown";
import CustomDownShiftWithPagination from "../../components/CustomDownShift/CustomDownShiftWithPagination";
import CustomModal from "../../components/layout/CustomModal";
import { ContactBarrier } from "../contacts/Contact";

interface AddContactRelationsProps {
  contactDetail: Contact;
}

type SingleContactItemProps = {
  item: any;
};

export const SingleContact: React.FC<SingleContactItemProps> = ({ item }) => {
  return <ContactBarrier contact={item} avatarSize={"sm"} />;
};

export const SingleContactMail: React.FC<SingleContactItemProps> = ({ item }) => {
  return <ContactBarrier contact={item} avatarSize={"sm"} showMail />;
};

export const SingleMail: React.FC<SingleContactItemProps> = ({ item }) => {
  return (
    <Text m={"2px"} fontWeight={400} fontSize={"12px"}>
      {item.primaryEmail}
    </Text>
  );
};

const AddContactRelations: React.FC<AddContactRelationsProps> = ({ contactDetail }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const [directRelations, setDirectRelations] = React.useState<Contact[]>([]);
  let contact;
  /*React.useEffect(() => {
    if (isOpen) {
      contact = ContactAPI.getSingle(contactDetail.id!);
    }
  }, [contactDetail, isOpen]);*/
  const toast = useToast();

  const { mutateAsync: updateContact, isPending: isLoadingUpdate } = useMutation({
    mutationFn: (contactDTO: Contact) => ContactAPI.patch(contactDetail.id!, contactDTO),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get-contacts"],
      });
    },
  });

  const saveRelations = async () => {
    const res = await handleSubmission({
      successMessage: "Updated Relations",
      failMessage: "Updating Relations",
      apiCall: updateContact({
        ...contactDetail,
        directRelations: directRelations,
      }),
      toast,
    });
    if (!res.errors) onClose();
  };

  const handlePickedContact = (contact: Contact) => {
    setDirectRelations([...directRelations, contact]);
  };

  return (
    <>
      <CustomModal title={`Relations`} onClose={onClose} isOpen={isOpen} size="sm" colorScheme="brand">
        <Flex flexDir={"column"} gap={"0.5em"} minH={"10em"}>
          <Heading size={"md"}>Direct relations</Heading>
          <Box>
            {directRelations.map((relation) => (
              <Flex
                alignItems={"center"}
                border={"solid 1px lightgray"}
                borderRadius={"5px"}
                gap={"0.6em"}
                padding={"0.1em"}
                paddingLeft={"0.5em"}
                key={`contact-relation-${relation.id}`}
              >
                <Icon as={BsPersonFill} />
                <Text>
                  {relation.first_name} {relation.last_name}
                </Text>
              </Flex>
            ))}
          </Box>
          <Heading size={"sm"}>Add a new relation</Heading>
          <CustomDownShiftWithPagination
            apiCall={(queryParams?: string) => ContactAPI.getAll(queryParams)}
            queryName={"get-contacts"}
            excludeIds={contactDetail ? [contactDetail.id!] : []}
            listItemConfig={{
              ListItem: SingleContact,

              onSelected: ({ item }) => {
                handlePickedContact(item);
              },
            }}
            additionalQueryState={{
              step: 5,
              page: 1,
            }}
            height={"200px"}
          />
          <Button colorScheme="brand" isLoading={isLoadingUpdate} onClick={saveRelations} alignSelf={"flex-start"}>
            Save
          </Button>
        </Flex>
      </CustomModal>
      <OptionsDropdownItem onClick={(e) => onOpen()} name="Edit Relations" icon={<FaUserFriends size={"16px"} />} />
    </>
  );
};
export default AddContactRelations;
