import { Avatar, Box, Center, Flex, Icon, Spacer, Text } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatRelative } from "date-fns";
import React from "react";
import { HiEye } from "react-icons/hi";
import { toTwoDigits } from "../../_app/TimeCountDown";
import MessageAPI from "../../api/messages.api";
import { DeleteIcon } from "../../assets/icons/MiniDrawerIcons";
import { useMeContext } from "../../stores/me-context";
import { Message } from "../../pages/chats";
import { useChatStore } from "../../stores/trip/chat-store";
import { TRIPMESSAGES_QUERY_KEY } from "../../utils/constants";
import { isDifferentDay } from "../../utils/date";
import OptionsDropdown, { OptionsDropdownItem } from "../../components/CRUD/OptionsDropdown";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import Typing from "./Typing";

interface ChatBoxLayoutProps {
  messages: Message[];
  isFetching: boolean;
  chat_enabled: boolean;
  refetchEl: JSX.Element | undefined;
  selectedTripId: string;
}

const ChatBoxLayout: React.FC<ChatBoxLayoutProps> = ({
  messages,
  isFetching,
  chat_enabled,
  refetchEl,
  selectedTripId,
}) => {
  // Initialise previous date with a date which will never be equal to the date of a message sent.
  let previousDate: Date = new Date("1970-01-01Z00:00:00:000");
  const me = useMeContext();
  const { removeLocalTripMessage } = useChatStore();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteMessage } = useMutation({
    mutationFn: (messageId: string) => MessageAPI.deleteMessage(messageId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TRIPMESSAGES_QUERY_KEY, { tripId: selectedTripId }],
      });
    },
  });

  let previousSender: string | undefined;

  return (
    <Flex
      flexDir="column-reverse"
      overflow="auto"
      // flexGrow={1}
      maxH="100%"
      h="100%"
      borderTop={{ base: "solid 1px", xl: "none" }}
      borderColor={{ base: "gray.300", xl: "none" }}
    >
      {!chat_enabled ? (
        <Text px={{ base: "0", xl: "7" }} pb={5} color="" textAlign="center">
          The chat for this trip is disabled, send a message to automatically enable it.
        </Text>
      ) : (
        <Box px={{ base: "0", xl: "7" }}>
          {/* Remove this box to reverse the order */}
          {refetchEl}
          {messages.map((message, i) => {
            const currentDate = new Date(message.created_at);
            // If the current message was sent on a different day than the previous message, add a daystamp divider.
            const showDayStamp = isDifferentDay(currentDate, previousDate);
            // Update the previous date variable.
            previousDate = currentDate;
            const senderId = message.sender?.id;
            const ownMessage = senderId === me?.id;
            const previousIsSameSender = senderId === previousSender;
            previousSender = senderId;
            const nextMessage: Message | undefined = messages[i + 1];
            const nextIsSameSender = nextMessage?.sender?.id === senderId;

            async function handleChatMessageDeletion(e: any, id: string, index: number): Promise<void> {
              e.stopPropagation();
              removeLocalTripMessage(selectedTripId, id);
              await deleteMessage(id);
            }

            return (
              <Box key={`message-${i}`}>
                {showDayStamp && (
                  <Center my={2}>
                    <Text fontSize="xs" color="gray.500">
                      {formatRelative(currentDate, new Date()).split(" at")[0]}
                    </Text>
                  </Center>
                )}
                <Flex w="100%" mb={nextIsSameSender ? "2px" : 3} pos="relative">
                  {ownMessage ? (
                    <>
                      <Spacer />
                      <Box width={"30px"}>
                        <OptionsDropdown>
                          <OptionsDropdownItem
                            icon={<DeleteIcon h={3} />}
                            name="Delete message"
                            onClick={(onClickEvent) => handleChatMessageDeletion(onClickEvent, message.id!, i)}
                          />
                        </OptionsDropdown>
                      </Box>
                    </>
                  ) : (
                    !nextIsSameSender && (
                      <Avatar
                        pos="absolute"
                        left={-5}
                        bottom={0}
                        size="xs"
                        name={message.sender?.name}
                        src={message.sender?.avatar && message.sender.avatarUrl ? message.sender?.avatarUrl : ""}
                      />
                    )
                  )}
                  <Box
                    ml={ownMessage ? 0 : 3}
                    mr={3}
                    maxWidth="75%"
                    px={2}
                    py="0.2rem"
                    bg={ownMessage ? "teal.400" : "gray.200"}
                    color={ownMessage ? "white" : "gray.700"}
                    borderRadius="2xl"
                    borderTopLeftRadius={previousIsSameSender && !ownMessage ? "base" : undefined}
                    borderBottomLeftRadius={nextMessage && nextIsSameSender && !ownMessage ? "base" : undefined}
                    borderTopRightRadius={previousIsSameSender && ownMessage ? "base" : undefined}
                    borderBottomRightRadius={nextMessage && nextIsSameSender && ownMessage ? "base" : undefined}
                  >
                    {message.message}
                    <Flex justify="flex-end" alignItems="flex-end">
                      {/* TODO: Adjust size */}

                      <Spacer />
                      <Text fontSize="xs" textAlign={ownMessage ? "end" : "start"}>
                        {toTwoDigits(currentDate.getHours()) + ":" + toTwoDigits(currentDate.getMinutes())}
                      </Text>
                      {/*
                    If User's sent message, read by Travel Agent than display eye icon
                  */}
                      {ownMessage && message.read && <Icon mx={1} height="0.7em" as={HiEye} marginLeft="2px" />}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            );
          })}

          <Typing />
        </Box>
      )}
    </Flex>
  );
};
export default React.memo(ChatBoxLayout);
