import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import UserTripsAPI from "../../../api/usertrips.api";
import FullScreenSpinner from "../../../components/FullScreenSpinner";
import { ToastType, addToast } from "../../../utils/addToast";
import { SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../../utils/constants";
import { useMe } from "../../../utils/query-helpers/reactQueryHooks";
import useCollaborationInvite from "../../../utils/useCollaborationInvite";
import Login from "../../login";
import Register from "../../register";

const AcceptCollaborateRoute: React.FC<any> = () => {
  const { userTrips, return: ret, invitation } = useCollaborationInvite();
  const { data: me, isLoading: isLoadingMe } = useMe();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const reloadScreen = () => navigate(0);

  const { mutateAsync: acceptInvite } = useMutation({
    mutationFn: () => UserTripsAPI.acceptTripCollaboration(userTrips![userTrips!.length - 1].id!),
    onSuccess: () => {
      // Improve this, that not both need to be invalidated
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });

  React.useEffect(() => {
    async function asyncAcceptInvite() {
      const errorToast: ToastType = {
        title: "Could not accept collaboration invitation.",
        status: "error",
        description: "This invitation link has expired or someone already accepted the invitation.",
      };
      const successToast: ToastType = {
        title: "Successfully accepted collaboration invitation.",
        status: "success",
      };
      let actualToast = successToast;
      try {
        const newUserTrip = await acceptInvite();

        if (newUserTrip.error) {
          // The invitation was not accepted.
          actualToast = errorToast;
        }
      } catch (e) {
        console.error(e);
        actualToast = errorToast;
      }
      addToast(toast, actualToast);
      navigate("/trips");
    }

    if (me && invitation) asyncAcceptInvite();
  }, [invitation, me]);

  if (ret) return ret;
  if (isLoadingMe) return <FullScreenSpinner />;
  if (invitation && !me) {
    return <LoginOrRegister successCallback={reloadScreen} />;
  }

  return <FullScreenSpinner />;
};
export default AcceptCollaborateRoute;

type LoginOrRegisterProps = {
  successCallback?: () => void;
};
export const LoginOrRegister: React.FC<LoginOrRegisterProps> = ({ successCallback }) => {
  const [isLogin, toggleLogin] = React.useState(false);

  return isLogin ? (
    <Login sameRouteToggle={toggleLogin} successCallback={successCallback} />
  ) : (
    <Register sameRouteToggle={toggleLogin} successCallback={successCallback} />
  );
};
