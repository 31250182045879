import { Button, InputProps, Link } from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import CoreInput from "./CoreInput";
import { RHFInputProps } from "./RHFInput";

interface ColorInputProps extends InputProps {
  resetValue?: string;
  changeColor?: (value: string) => void;
  customOnChange?: (e: any) => void;
  previousValue: string;
  currentValue: string;
  field?: any;
  simple?: boolean;
}

const ColorInput: React.FC<ColorInputProps> = ({
  resetValue,
  changeColor,
  customOnChange,
  previousValue,
  currentValue,
  onChange,
  field,
  simple = false,
  ...props
}) => {
  const isValidHexColor = (hex: string) => {
    return /^#(?:[0-9a-fA-F]{3}){2}$/.test(hex);
  };

  const handleOnBlur = (e: any) => {
    if (e?.target?.value && isValidHexColor(e.target.value)) {
      changeColor && changeColor(e.target.value);
    } else {
      changeColor && changeColor(previousValue);
    }
  };

  return (
    <>
      <CoreInput
        {...props}
        value={simple ? "" : currentValue}
        {...field}
        // This breaks the input field
        // onBlur={handleOnBlur}
        width={simple ? "0.5em" : undefined}
        backgroundColor={simple ? "rgb(51 65 85)" : undefined}
        borderColor={simple ? "rgb(51 65 85)" : undefined}
        _hover={{
          borderColor: simple ? "rgb(51 65 85)" : undefined,
        }}
        paddingStart={simple ? "1.5em" : "3em"}
        mx={simple ? "-0.2em" : undefined}
        // onChange={customOnChange ? (e: any) => customOnChange(e, e.target.value) : props.onChange}
        leftIcon={
          <div
            style={{
              backgroundColor: simple ? "rgb(51 65 85)" : currentValue,
              width: "18px",
              height: simple ? "16px" : "18px",
              borderRadius: simple ? "10%" : "100%",
              overflow: "hidden",
            }}
          >
            {simple && (
              <div style={{ position: "absolute", top: "0.24em" }}>
                <p style={{ fontSize: "17px", marginLeft: "20%", color: "white" }}>A</p>
                <div
                  style={{
                    width: "18px",
                    height: "2px",
                    overflow: "hidden",
                    backgroundColor: currentValue,
                    marginTop: "auto",
                    pointerEvents: "none",
                  }}
                />
              </div>
            )}
            <input
              value={currentValue}
              onChange={(e: any) => {
                changeColor && changeColor(e);
              }}
              {...field}
              type="color"
              style={{
                // Hide this input field because it is ugly
                opacity: 0,
                width: simple ? "2em" : undefined,
                paddingInlineStart: simple ? "2em !important" : undefined,
                paddingWebkitStart: simple ? "2em !important" : undefined,
                height: simple ? "1.5em" : undefined,
                zIndex: 100,
                cursor: simple ? "pointer" : "default",
              }}
            />
          </div>
        }
        rightIcon={
          resetValue && !simple ? (
            <Button
              variant="link"
              mr={4}
              onClick={(e) => {
                field && field.onChange(resetValue);
                customOnChange && customOnChange(e);
              }}
            >
              reset
            </Button>
          ) : undefined
        }
      />
    </>
  );
};

export default React.memo(ColorInput);
