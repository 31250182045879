import { Badge, IconButton, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, Portal } from "@chakra-ui/react";
import { TRIP_STATUS, UserTrip } from "@lato/common";
import React, { useEffect } from "react";
import { GoTasklist } from "react-icons/go";
import { IoOpenOutline, IoPersonCircleOutline } from "react-icons/io5";
import { prettyPrintDate } from "../../utils/date";
import { DaysAndNights } from "../../components/input/date/DatePicker";
import { tripColor } from "./CalendarOverview";
import { tripStatusColorMap } from "../tripList/TripsList";

interface TaskPopoverProps {
  trip: UserTrip;
  calendarView?: boolean;
  listView?: boolean;
  ref?: any;
}

export const TripPopover = ({ trip, listView, ref, calendarView = false }: TaskPopoverProps) => {
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  const header = (
    <div className="flex gap-4">
      <div
        className={`w-1 bg-${calendarView ? tripColor : tripStatusColorMap[trip.status]}-${calendarView ? "600" : "400"} rounded-md`}
      />
      <div className="flex justify-between w-full">
        <div>
          <div className="z-0 flex font-bold text-black">{trip.trip?.titles.at(-1)?.content}</div>
          {!listView && (
            <p className="text-gray-500 text-sm">{prettyPrintDate(new Date(trip.trip?.start_date!), "en", "full")}</p>
          )}
          <p className="text-gray-500 text-xs">
            <DaysAndNights numberOfNights={trip.trip?.nrOfDays! - 1} />
          </p>
        </div>
        <IconButton
          backgroundColor={"white"}
          icon={<IoOpenOutline className="text-black w-4 h-4" />}
          className="p-2 bg-white rounded-md"
          aria-label={""}
          onClick={() => {
            window.open(`/trips/${trip?.id}`);
          }}
        />
      </div>
    </div>
  );

  const body = (
    <>
      <div className="flex justify-between gap-2">
        <div className="space-y-4 text-sm text-gray-700 mt-0.5">
          {trip.trip!.client_name && (
            <p className="flex gap-2">
              <IoPersonCircleOutline className="w-5 h-5" />
              {trip.trip!.client_name}
            </p>
          )}
          {trip.tasks && trip.tasks?.length !== 0 && (
            <p className="flex gap-2">
              <GoTasklist className="w-5 h-5" />
              {trip.tasks?.length} {trip.tasks?.length === 1 ? "task" : "tasks"}
            </p>
          )}
        </div>
        <Badge height={"1.5rem"} as={"button"} colorScheme={tripStatusColorMap[trip.status]}>
          {trip.status}
        </Badge>
      </div>

      {trip?.bookedDate && (
        <div className="flex justify-end">
          <p className="flex gap-2 text-gray-400">booked {new Date(trip.bookedDate).toLocaleDateString()}</p>
        </div>
      )}
    </>
  );

  if (listView) {
    return (
      <>
        {header}
        {/* {body} */}
      </>
    );
  }
  return (
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>{header}</PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
      </PopoverContent>
    </Portal>
  );
};
