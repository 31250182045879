import config from "../config";
import ENV from "./env";

export const EXTERNAL_IMAGE_APP_NAME = "lato_travel_app";
const AWS_PUBLIC_BUCKET_NAME = "lato-assets";
const AWS_REGION = "eu-west-1";
// Unsplash api url for retrieving the images/image urls in json format.
export const S3_BASE_URL = `https://${AWS_PUBLIC_BUCKET_NAME}.s3-${AWS_REGION}.amazonaws.com/`;
// Basic query keys
export const ME_QUERY_KEY = "me";
export const MY_COMPANY_QUERY_KEY = "my-company";
//Elements
export const ACCOMMODATIONS_QUERY_KEY = "ourHotelsPagination";
export const ACCOMMODATIONS_ALL_QUERY_KEY = "ourHotelsAll";
export const LIBRARY_IMAGES_QUERY_KEY = "ourImages";
export const TEXTFIELDS_QUERY_KEY = "ourTextFieldsPagination";
export const DESTINATIONS_QUERY_KEY = "ourDestinationsPagination";
export const ACCOMMODATIONS_DOWNSHIFT_QUERY_KEY = "ourDownshiftHotels";
export const ACTIVITIES_QUERY_KEY = "ourActivitiesPagination";
export const ACTIVITIES_ALL_QUERY_KEY = "ourActivitiesAll";
export const ACTIVITIES_DOWNSHIFT_QUERY_KEY = "ourDownshiftActivities";
export const POI_QUERY_KEY = "ourPOIsPagination";
export const POI_ALL_QUERY_KEY = "ourPOIsAll";
//Chat
export const MESSAGES_QUERY_KEY = "messages";
export const TRIPMESSAGES_QUERY_KEY = "trip-messages";
//Airports
export const AIRPORTS_QUERY_KEY = "airports";
//Trainstations
export const TRAINSTATIONS_QUERY_KEY = "trainstations";
//Plain trip
export const TRIP_PLAIN_QUERY_KEY = "get-plain-trip";
export const TRIP_TRANSLATIONS_QUERY_KEY = "get-trip-translations";
export const TRIP_COUNTS_QUERY_KEY = "get-trip-counts";
//Plain Usertrip
export const USERTRIP_PLAIN_QUERY_KEY = "get-plain-user-trip";
//Step 1 General
export const GENERAL_STEP_TRIP_QUERY_KEY = "get-trip-split-general";
export const GENERAL_STEP_USERTRIP_QUERY_KEY = "get-usertrip-split-general";
export const GENERAL_STEP_CONTACTS_QUERY_KEY = "trip-split-contacts";
export const GENERAL_STEP_DOCUMENTS_QUERY_KEY = "trip-split-documents";
export const GENERAL_STEP_FLIGHTS_QUERY_KEY = "trip-split-flights";
//Step 2 Overview
export const DAY_BY_DAY_STEP_TRIP_QUERY_KEY = "get-trip-split-overview";
export const DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY = "get-usertrip-split-overview";
//Step 3 DayByday
export const DAY_BY_DAY_STEP_DESTINATION_QUERY_KEY = "get-destination";
export const DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY = "get-tripday-destination";
export const DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY = "get-trip-split-tripdays-image";
export const DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY = "trip-split-tripdays-daybyday";
export const DAY_BY_DAY_STEP_TRIPDAY_TITLE_QUERY_KEY = "trip-split-tripday-title";
//Step 3 Elements
export const TRIPDAY_ACTIVITY_QUERY_KEY = "trip-split-tripday-activity";
export const TRIPDAY_ACCOMMODATION_QUERY_KEY = "trip-split-tripday-accommodation";
export const TRIPDAY_TRANSPORTATION_QUERY_KEY = "trip-split-tripday-transportation";
//Step 4 Price
export const PRICE_STEP_USERTRIP_QUERY_KEY = "trip-split-usertrip-price";
export const PRICE_STEP_TRIP_QUERY_KEY = "trip-split-trip-price";
//Step 5 Share
export const SHARE_STEP_TRIP_QUERY_KEY = "trip-split-share";
export const SHARE_STEP_USER_TRIP_QUERY_KEY = "usertrip-split-share";
export const SHARE_STEP_PAYMENT_INVITATIONS_QUERY_KEY = "trip-split-payment-invitations";

// Recently edited trip on dashboard
export const RECENTLY_EDITED_TRIP_QUERY_KEY = "recently-edited-trip";
export const RECENTLY_EDITED_USERTRIP_QUERY_KEY = "recently-edited-usertrip";
export const LAST_EDITED_USERTRIP_QUERY_KEY = "last-edited-usertrip";

// Recently edited list
export const RECENTLY_EDITED_LIST_QUERY_KEY = "recently-edited-list";

//Notes
export const CONTACT_NOTES_QUERY_KEY = "contact-notes";
export const USERTRIP_NOTES_QUERY_KEY = "usertrip-notes";
//Usertrips
export const SAMPLES_USERTRIPS_KEY = "samples-usertrips";
export const TRIPS_USERTRIPS_KEY = "trips-usertrips";
export const USERTRIP_TASKS_QUERY_KEY = "usertrip-tasks";

//Calendar
export const CALENDAR_ITEMS_QUERY_KEY = "calendar-items";

//Tasks
export const TASKS_ITEMS_QUERY_KEY = "tasks-items";
export const TASKS_ITEMS_CONTACT_KEY = "tasks-items-contact";

// Google calendar
export const GOOGLE_CALENDAR_QUERY_KEY = "google-calendar";

// Dashboard
export const PAST_TRIPS = "past-trips";
export const UPCOMING_TRIPS = "upcoming-trips";
export const TRAVELING_TRIPS = "traveling-trips";
export const INSPIRED_TRIPS = "inspired-trips";

// Settings
export const BRAND_TRIPS = "brand-trips";

export const GMAPS_API_URL = `https://maps.googleapis.com/maps/api`;
export const LATO_SLOGAN = "Create, sell & experience travel in one tool.";
export const MAX_PICTURES = 20;
export const BROWSER_TOKEN_KEY = "btk";
export const TOMTOM_API = `https://api.tomtom.com//map/1/tile/basic/main/{z}/{x}/{y}.png?key=${config[ENV].tomtom_api_key}&language=en-GB`;
export const TOMTOM_ATTRIBUTION = "&copy; Tomtom";
export const OPENSTREETMAP_API = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
export const OPENSTREETMAP_ATTRIBUTION =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
export const MAPBOX_API = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${config[ENV].mapbox_api_key}`;
export const MAPBOX_ATTRIBUTION =
  '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
export const HOTELBEDS_IMAGE_URL = "http://photos.hotelbeds.com/giata/original/";

export const MAX_DOC_NAME_LENGTH = 64;

export const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
