import { Box, Flex, Image, Skeleton } from "@chakra-ui/react";
import { Country } from "@lato/common";
import React from "react";
import { FilterProps } from "react-table";
import { useTripsListStore } from "../../stores/trip/tripslistStore";
import MultiSelectColumnFilter from "./MultiSelectColumnFilter";

interface CountryFilterProps extends React.PropsWithChildren<FilterProps<any>> {
  countries: Country[];
  isLoadingCountries: boolean;
  isErrorCountries: boolean;
  singleSelect?: boolean;
}

const CountryFilter: React.FC<CountryFilterProps> = ({
  countries,
  isLoadingCountries,
  isErrorCountries,
  singleSelect = false,
  ...props
}) => {
  const { setCountryFilter } = useTripsListStore();

  const createCountryOptions = React.useCallback(
    (preFilteredRows: any, columnName: string) => {
      // If the members didn't load yet, return an empty set.
      if (!countries) return new Set<string>();

      return new Set(countries.map((c) => c.iso));
    },
    [countries],
  );

  const createSearchValueOption = React.useCallback(
    (countryIso: string) => {
      if (!countries) return "";
      return countries?.find((c) => c.iso === countryIso)?.name ?? "";
    },
    [countries],
  );

  const displayCountryOption = React.useCallback(
    (countryIso: string): React.ReactElement => {
      // Find a single countrytrip of this country to get the country object
      const country = countries?.find((c) => c.iso === countryIso);
      // If the members didn't load yet or somehow no country was found, return an empty fragment.
      if (!country) return <></>;

      return (
        <Flex alignItems="center">
          {/* <Avatar
            size="xs"
            name={country.name}
            src={country.avatar ? country.avatarUrl : ""}
            alt={"country-" + (country.name || country.id!)}
            mr={2}
          /> */}
          <Box display="flex" gap={2} alignItems={"center"}>
            <Image
              height={"10px"}
              width={"16px"}
              borderRadius={2}
              shadow={"0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)"}
              src={country.flagImage}
              alt={country.name}
            />{" "}
            {country.name}
          </Box>
        </Flex>
      );
    },
    [countries],
  );

  if (isLoadingCountries) return <Skeleton h={"30px"} w={"120px"} />;
  if (isErrorCountries) return <></>;

  return (
    <>
      {countries && countries.length > 0 && (
        <MultiSelectColumnFilter
          {...props}
          displayName={"country"}
          displayOption={displayCountryOption}
          searchValueOption={createSearchValueOption}
          // TODO: remove later, but currently BUG: the preFilteredRows are sometimes undefined
          createOptions={createCountryOptions}
          useSelectedItems={false}
          singleSelect={singleSelect}
          onChange={(e) => {
            if (e.length === countries.length) {
              setCountryFilter("");
              props.columns.find((column) => column.id === "country" || column.id === "countries")?.setFilter([]);
            } else {
              setCountryFilter(e.join(","));
              props.columns.find((column) => column.id === "country" || column.id === "countries")?.setFilter(e);
            }
          }}
        />
      )}
    </>
  );
};
export default React.memo(CountryFilter);
