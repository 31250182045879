import { createContext } from "react";
import { Socket } from "socket.io-client";

/**
 * Interface which intially will be undefined but later once connection established to type Socket
 */
interface ISocketContextState {
  socket: Socket | null;
}

/**
 *  Default Socket Context State
 */
const defaultSocketContextState: ISocketContextState = {
  socket: null,
};

// Socket Context Created
export const SocketContext = createContext(defaultSocketContextState);
