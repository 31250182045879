import React from "react";
import Settings from "../../components/settings/Settings";
import DeleteAccount from "../../components/settings/account/DeleteAccount";
import PasswordSettings from "../../components/settings/account/PasswordSettings";
import { useMeContext } from "../../stores/me-context";

interface SecuritySettingsProps {}

const SecuritySettings: React.FC<SecuritySettingsProps> = () => {
  const user = useMeContext();

  return (
    <Settings>
      <PasswordSettings user={user} />
      <DeleteAccount userEmail={user.email} userId={user.id!} />
    </Settings>
  );
};
export default React.memo(SecuritySettings);
