import { Button, Flex, Link, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useMeContext } from "../../../stores/me-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import { submitForms } from "../../../utils/EventHelper";
import { addToast } from "../../../utils/addToast";

interface WizardButtonGroupProps {
  viewTripLink: string;
}

export function showSuccessToast(toast: any, name: string) {
  addToast(toast, {
    title: `Successfully saved ${name}`,
    status: "success",
    duration: 3000,
    isClosable: true,
  });
}

const WizardButtonGroup: React.FC<WizardButtonGroupProps> = ({ viewTripLink }) => {
  const me = useMeContext();
  const toast = useToast({
    id: "toast_success",
    position: "bottom-right",
  });
  const { isSubmittingForms, isDirtyForms } = useTripFormStore();

  const handleSubmit = (e: any) => {
    if (![...isDirtyForms.values()].some((value) => value === true)) {
      if (!toast.isActive("toast_success")) {
        showSuccessToast(toast, "trip");
      }
    } else {
      submitForms(isSubmittingForms);
    }
  };

  // const disableSave =
  //   ![...isDirtyForms.values()].some((value) => value === true) ||
  //   [...isInvalidForms.values()].some((value) => value === true);

  const oldLayoutFlexOptions = !me.useNewEditTripLayout
    ? {
        position: "sticky",
        top: "4.4em",
        mt: "1.9em",
        ml: "auto",
        right: "2.6rem",
        w: "fit-content",
      }
    : {};

  return (
    <Flex borderRadius={"10px"} justifyContent={"center"} zIndex={1003} mt={1} {...oldLayoutFlexOptions}>
      <Button
        as={Link}
        href={viewTripLink}
        type="button"
        colorScheme="brand"
        variant="outline"
        isExternal
        bg="white"
        mr={2}
      >
        Preview
      </Button>
      <Button
        colorScheme="brand"
        variant="solid"
        // isDisabled={disableSave}
        onClick={handleSubmit}
        isLoading={[...isSubmittingForms.values()].some((value) => value === true)}
      >
        Save
      </Button>
    </Flex>
  );
};
export default React.memo(WizardButtonGroup);
