const ShorterIcon = () => (
  <svg
    role="graphics-symbol"
    viewBox="0 0 16 16"
    className="aiMakeShorter"
    style={{ width: "16px", height: "16px", display: "block", fill: "currentColor", flexShrink: 0 }}
  >
    <path d="M1.4502 7.01221H14.541C14.9785 7.01221 15.3271 6.63623 15.3271 6.20557C15.3271 5.76123 14.9854 5.40576 14.541 5.40576H1.4502C1.00586 5.40576 0.664062 5.7749 0.664062 6.20557C0.664062 6.62939 1.0127 7.01221 1.4502 7.01221ZM1.4502 10.5942H8.44336C8.88086 10.5942 9.22949 10.2183 9.22949 9.7876C9.22949 9.3501 8.8877 8.98779 8.44336 8.98779H1.4502C1.00586 8.98779 0.664062 9.35693 0.664062 9.7876C0.664062 10.2114 1.0127 10.5942 1.4502 10.5942Z"></path>
  </svg>
);
export default ShorterIcon;
