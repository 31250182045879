import { Button, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Airport,
  TRANSPORTATION_TYPE,
  Transportation,
  Trip,
  calculateTripdayDateByDayIndex,
  getDateByDayindex,
  getTripDayIndexByDate,
  omitLocalTimezone,
} from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { addDays, isSameDay } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useController, useFieldArray, useForm, useWatch } from "react-hook-form";
import { CellProps, Column } from "react-table";
import { toTwoDigits } from "../../_app/TimeCountDown";
import { useMeContext } from "../../stores/me-context";
import { getDayIndexFromDate } from "../../utils/EventHelper";
import { GENERAL_STEP_FLIGHTS_QUERY_KEY, TRIP_PLAIN_QUERY_KEY } from "../../utils/constants";
import {
  getRelationMap,
  usePatchTripFlights,
  useRemoveFlight,
  useSplitTrip,
  useTripFlights,
  useTripTranslations,
} from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { generalFlights } from "../../validation/validationSchemas";
import CRUDResource from "../../components/CRUD/Resource";
import AirportDownShift from "../../components/CustomDownShift/AirportDownShift";
import TrainstationDownShift from "../../components/CustomDownShift/TrainstationDownshift";
import RHFDayPicker from "../../components/input/date/RHFDayPicker";
import { CustomSpinner } from "../../components/FullScreenSpinner";
import { createNewTranslationFields } from "../../utils/createNewTranslationFields";
import { getEmptyTransportation } from "../../components/elements/activities";
import AmadeusFlightSearch from "./AmadeusFlightSearch";
import PnrParser from "./PnrParser";
import RHFInput from "../../components/input/RHFInput";
import TimeMaskInput from "../../components/input/TimeMaskInput";
import CustomModal from "../../components/layout/CustomModal";
import TripForm from "../../components/trips/TripForm";
import DayPicker from "../../components/input/date/DayPicker";

interface FlightsProps {
  trip: Trip;
  tripId: string;
  flights?: Transportation[];
  tripFromGeneral?: Trip;
}

export const Flights: React.FC<FlightsProps> = ({ trip, tripId }) => {
  const { data: splitTrip, isLoading: isLoadingTrip } = useTripTranslations(tripId ?? "");

  const { data: flights, isLoading: isLoadingFlights, isFetching: isFetchingFlights } = useTripFlights(tripId ?? "");

  const { data: generalTrip, isLoading: isLoadingGeneralTrip } = useSplitTrip(
    tripId ?? "",
    TRIP_PLAIN_QUERY_KEY,
    getRelationMap[TRIP_PLAIN_QUERY_KEY],
  );

  if (
    isLoadingTrip ||
    isLoadingGeneralTrip ||
    isLoadingFlights ||
    isFetchingFlights ||
    !splitTrip ||
    !generalTrip ||
    !tripId ||
    !trip
  )
    return <CustomSpinner card skeleton m="auto" />;

  return (
    <FlightsForm
      trip={{ ...trip, ...generalTrip, priceDescriptions: splitTrip.priceDescriptions }}
      tripFromGeneral={trip}
      tripId={tripId}
      flights={flights}
    />
  );
};

const FlightsForm: React.FC<FlightsProps> = ({ trip, tripFromGeneral, flights, tripId }) => {
  const formId = `0-${GENERAL_STEP_FLIGHTS_QUERY_KEY}`;
  const tripStartDate = trip.start_date ? new Date(trip.start_date) : undefined;

  const [error, setError] = useState<string | undefined>(undefined);

  const formMethods = useForm<{ flights: Transportation[] }>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      flights,
    },
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(generalFlights, { abortEarly: false }),
  });

  const { fields, remove, append } = useFieldArray({
    control: formMethods.control,
    name: "flights",
    // @ts-ignore
    keyName: "rhfId", // default to "id", you can change the key name
  });

  const getDepOrArrDate = (tripdayId: string) =>
    new Date(
      omitLocalTimezone(
        new Date(
          calculateTripdayDateByDayIndex(
            trip.tripdays?.findIndex((td) => td.id === tripdayId) ?? 0,
            trip.start_date,
            trip,
          ) ?? "",
        ),
        true,
      ),
    );

  const flightFieldsWithDates = fields
    .map((flight) => {
      const departureDate = getDepOrArrDate(flight.tripdayId || "");
      departureDate.setDate(departureDate.getDate() + (flight.departureDayIndex ?? 0));
      const arrivalDate = getDepOrArrDate(flight.tripdayId || "");
      arrivalDate.setDate(arrivalDate.getDate() + (flight.arrivalDayIndex ?? 0));

      return { ...flight, departureDate, arrivalDate };
    })
    .sort((a, b) => a.departureDate.getTime() - b.departureDate.getTime());

  const earliestFlight = flightFieldsWithDates[0];

  const [amadeusStartDate, setAmadeusStartDate] = useState(
    earliestFlight
      ? new Date(
          getDateByDayindex(
            trip,
            earliestFlight?.tripday?.dayNumber ?? 0,
            earliestFlight?.departureDayIndex ?? 0,
            earliestFlight?.departureTime ?? undefined,
          ),
        )
      : (tripStartDate ?? new Date()),
  );

  const queryClient = useQueryClient();

  const { mutateAsync: updateFlights, isPending: isLoadingUpdate } = usePatchTripFlights(tripId ?? "", queryClient);

  const { mutateAsync: removeFlights } = useRemoveFlight(tripId ?? "", queryClient, flightFieldsWithDates);

  const handleSubmit = async () => {
    const formValues = formMethods.getValues();

    await updateFlights(formValues.flights);
  };

  useEffect(() => {
    if (
      !formMethods.formState.isValid &&
      formMethods.formState.errors?.flights &&
      formMethods.formState.errors.flights.length! > 0
    ) {
      setError("The arrival time can not be earlier then the departure time");
    } else {
      setError(undefined);
    }
  }, [formMethods.formState.errors, formMethods.formState.isValid]);

  const columns: Column<Transportation>[] = React.useMemo(
    () => [
      /*{
        accessor: "realtime",
        noTooltip: true,
        Cell: ({ value }: CellProps<Transportation>) => (
          <Tooltip label={value ? "Real-time" : "Custom"} aria-label="amadeus-or-not-tooltip">
            <Circle bg={value ? "green.500" : "orange.500"} size="7px" />
          </Tooltip>
        ),
        chakraWidth: "10px",
      },*/
      {
        accessor: "transportNumber",
        Header: "Flight No",
        chakraWidth: "11ch",
        Cell: ({ row, value, column }: CellProps<Transportation>) => {
          return (
            <RHFInput
              name={`flights.${row.index}.transportNumber`}
              onClick={(e: any) => e.stopPropagation()}
              defaultValue={value}
              isDisabled={column.isDisabled}
              //isReadOnly={row.original.realtime}
              placeholder={column.placeholder}
            />
          );
        },
      },
      {
        id: "departureDayIndex",
        accessor: "departureDayIndex",
        width: "10ch",
        Cell: ({ row, value, column }: CellProps<Transportation>) => {
          const tripStartDate = new Date(omitLocalTimezone(new Date(trip.start_date ?? "")));
          const tripdayId = formMethods.getValues(`flights.${row.index}.tripdayId`);
          const departureDayIndex = formMethods.getValues(`flights.${row.index}.departureDayIndex`) ?? 0;
          const { field: arrivalField } = useController({
            name: `flights.${row.index}.arrivalDayIndex`,
          });
          const arrivalDayIndex = arrivalField.value ?? 0;

          const updateDayIndexes = (value: Date) => {
            const dateValue = new Date(omitLocalTimezone(value, true));
            let departureIndex = 0;
            let tripdayIndex = 0;
            if (value) {
              tripdayIndex = getTripDayIndexByDate(dateValue, trip);
              const departureDate = new Date(
                calculateTripdayDateByDayIndex(tripdayIndex ?? 0, trip.start_date, trip) ?? "",
              );
              departureIndex = Math.round((dateValue.getTime() - departureDate.getTime()) / (1000 * 60 * 60 * 24));
            }

            formMethods.setValue(`flights.${row.index}.departureDayIndex`, departureIndex, { shouldDirty: true });
            formMethods.setValue(`flights.${row.index}.tripdayId`, trip.tripdays[tripdayIndex].id, {
              shouldDirty: true,
            });
            // When the departure date changes we need to update the arrival date
            arrivalField.onChange(departureIndex);
            // formMethods.setValue(
            //   `flights.${row.index}.arrivalDayIndex`,
            //   arrivalDayIndex + (departureIndex - departureDayIndex),
            //   { shouldDirty: true },
            // );
          };

          const dateClickHandler = (clicked: any) => {
            const clickedDate = new Date(omitLocalTimezone(clicked, true));
            updateDayIndexes(clickedDate);
            const earlierThanAmadeus = clickedDate?.getTime() < amadeusStartDate?.getTime();
            const amadeusEalierThanTrip =
              tripStartDate && omitLocalTimezone(amadeusStartDate).getTime() < tripStartDate.getTime();
            const amadeausClickedAreSame = amadeusStartDate && isSameDay(new Date(value), amadeusStartDate.getTime());
            if (earlierThanAmadeus || amadeusEalierThanTrip || amadeausClickedAreSame) {
              setAmadeusStartDate(clickedDate);
            }
          };

          const departureDate = new Date(
            omitLocalTimezone(
              new Date(
                calculateTripdayDateByDayIndex(
                  trip.tripdays?.findIndex((td) => td.id === tripdayId) ?? 0,
                  trip.start_date,
                  trip,
                ) ?? "",
              ),
              true,
            ),
          );

          departureDate.setDate(departureDate.getDate() + departureDayIndex);

          return (
            <DayPicker
              name={`flights.${row.index}.departureDayIndex`}
              defaultDate={departureDayIndex != null ? departureDate : tripStartDate}
              fromDate={tripStartDate}
              customOnClickHandler={dateClickHandler}
            />
          );
        },
        Header: "Departure date",
      },
      {
        accessor: "departureAirportIata",
        Header: "From",
        chakraWidth: "25ch",
        Cell: ({ row, value, column }: CellProps<Transportation>) => {
          const depFN = `flights.${row.index}`;

          const { field } = useController<Airport>({
            name: depFN as any,
          });

          const onSelectedHandler = (item: any, field = "departureAirport") => {
            return formMethods.setValue(`${depFN}.${field}` as any, item, { shouldDirty: true });
          };

          const transportation = field.value as any;

          return transportation.departureTrainstation ? (
            <TrainstationDownShift
              trainstation={transportation.departureTrainstation}
              onSelectedHandler={(item) => onSelectedHandler(item, "departureTrainstation")}
            />
          ) : (
            <AirportDownShift airport={transportation.departureAirport} onSelectedHandler={onSelectedHandler} />
          );
        },
      },
      {
        accessor: "arrivalAirportIata",
        Header: "To",
        chakraWidth: "25ch",
        Cell: ({ row, value, column }: CellProps<Transportation>) => {
          const depFN = `flights.${row.index}`;

          const { field } = useController({
            name: depFN,
          });

          const onSelectedHandler = (item: any, field = "arrivalAirport") => {
            return formMethods.setValue(`${depFN}.${field}` as any, item, { shouldDirty: true });
          };

          const transportation = field.value;

          return transportation.arrivalTrainstation ? (
            <TrainstationDownShift
              trainstation={transportation.arrivalTrainstation}
              onSelectedHandler={(item) => onSelectedHandler(item, "arrivalTrainstation")}
            />
          ) : (
            <AirportDownShift airport={transportation.arrivalAirport} onSelectedHandler={onSelectedHandler} />
          );
        },
      },
      {
        id: "departureTime",
        accessor: "departureTime",
        chakraWidth: "10ch",
        Cell: ({ row, value, column }: CellProps<Transportation>) => {
          const updateTimeField = (value: string) => {
            const regex = new RegExp("[0-9]{2}:[0-9]{2}");
            if (regex.test(value)) {
              formMethods.setValue(`flights.${row.index}.departureTime`, value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }
          };

          return (
            <TimeMaskInput
              // Don't use column.id here because we used a custom ID for this column.
              name={`flights.${row.index}.departureTime`}
              //defaultDate={value ? new Date(value) : null}
              customOnChangeHandler={updateTimeField}
            />
          );
        },
        Header: "Takeoff",
      },
      {
        id: "arrivalTime",
        accessor: "arrivalTime",
        chakraWidth: "10ch",
        Cell: ({ row, value, column }: CellProps<Transportation>) => {
          const updateTimeField = (value: string) => {
            const regex = new RegExp("[0-9]{2}:[0-9]{2}");
            if (regex.test(value)) {
              formMethods.setValue(`flights.${row.index}.arrivalTime`, value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }
          };

          return (
            <TimeMaskInput
              // Don't use column.id here because we used a custom ID for this column.
              name={`flights.${row.index}.arrivalTime`}
              //defaultDate={value ? new Date(value) : null}
              customOnChangeHandler={updateTimeField}
              showError={false}
            />
          );
        },
        Header: "Landing",
      },
      {
        accessor: "arrivalDayIndex",
        width: "10ch",
        Cell: ({ row, value, column }: CellProps<Transportation>) => {
          const { field: departureField } = useController({
            name: `flights.${row.index}.departureDayIndex`,
          });
          const departureDayIndex = departureField.value;
          const arrivalDayIndex = formMethods.getValues(`flights.${row.index}.arrivalDayIndex`);
          const tripdayId = formMethods.getValues(`flights.${row.index}.tripdayId`);

          const departure = new Date(
            calculateTripdayDateByDayIndex(
              trip.tripdays?.findIndex((td) => td.id === tripdayId) ?? 0,
              trip.start_date,
              trip,
            ) ?? "",
          );

          departure.setDate(departure.getDate() + (departureDayIndex ?? 0));

          const arrival = new Date(
            calculateTripdayDateByDayIndex(
              trip.tripdays?.findIndex((td) => td.id === tripdayId) ?? 0,
              trip.start_date,
              trip,
            ) ?? "",
          );

          arrival.setDate(arrival.getDate() + (arrivalDayIndex ?? 0));

          const arrivalDate = new Date(omitLocalTimezone(arrival, true));
          const departureDate = departure && new Date(omitLocalTimezone(departure, true));
          const tripStartDate = new Date(omitLocalTimezone(new Date(trip.start_date ?? "")));
          const tripEndDate = new Date(omitLocalTimezone(new Date(trip.end_date ?? "")));

          const isSameDate = departure && arrival && isSameDay(arrivalDate, departureDate);

          const updateArrivalDayIndex = (value: Date) => {
            const dateValue = new Date(omitLocalTimezone(value, true));
            let arrivalIndex = 0;
            if (value) {
              const tripdayIndex = trip.tripdays?.findIndex((td) => td.id === tripdayId);
              const departureDate = new Date(
                calculateTripdayDateByDayIndex(tripdayIndex ?? 0, trip.start_date, trip) ?? "",
              );
              arrivalIndex = Math.round((dateValue.getTime() - departureDate.getTime()) / (1000 * 60 * 60 * 24));
            }

            formMethods.setValue(`flights.${row.index}.arrivalDayIndex`, arrivalIndex, { shouldDirty: true });
          };

          return (
            <DayPicker
              defaultDate={arrivalDayIndex != null ? arrivalDate : tripStartDate}
              //isReadOnly={row.original.realtime}
              fromDate={departureDayIndex != null ? departureDate : tripStartDate}
              // The arrival date can not be more than 2 days away from the departure date
              toDate={departureDayIndex != null ? addDays(departureDate, 2) : undefined}
              // toDate={departureDate ? addDays(departureDate, 2) : tripEndDate}
              // Doesn't get updated on next render, probably better to use useWatch?
              borderColor={isSameDate || !departure || !arrival ? "inherit" : "yellow.500"}
              customOnClickHandler={(value: any) => {
                updateArrivalDayIndex(value);
              }}
            />
          );
        },
        Header: "Arrival date",
      },
    ],
    [
      flights,
      trip,
      formMethods.formState.errors,
      formMethods.formState.isValid,
      flightFieldsWithDates,
      amadeusStartDate,
    ],
  );
  const me = useMeContext();

  const newFlight = {
    ...getEmptyTransportation(me),
    // departure_date_time: flightFieldsWithDates.length > 0 ? new Date(flightFieldsWithDates.at(-1)?.departure_date_time!) : tripStartDate,
    // arrival_date_time: flightFieldsWithDates.length > 0 ? new Date(flightFieldsWithDates.at(-1)?.arrival_date_time!) : tripStartDate,
    type: TRANSPORTATION_TYPE.FLIGHT,
    departureDayIndex: 0,
    arrivalDayIndex: 0,
  };

  function appendFlight(flight: any) {
    const flightArrivalDayIndex = flight.arrivalDayIndex;
    let flightDepartureDayIndex = flight.departureDayIndex;
    const flightDepartureDate = omitLocalTimezone(new Date(flight.departureDate));
    let tripdayIndex = 0;
    if (flight.departureDate) {
      tripdayIndex = getTripDayIndexByDate(flight.departureDate, trip);
      const departureDate = new Date(calculateTripdayDateByDayIndex(tripdayIndex, trip.start_date, trip) ?? "");
      flightDepartureDayIndex = Math.round(
        (new Date(
          flightDepartureDate.getFullYear(),
          flightDepartureDate.getMonth(),
          flightDepartureDate.getDate(),
        ).getTime() -
          new Date(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate()).getTime()) /
          (1000 * 60 * 60 * 24),
      );
    }

    flight = {
      ...flight,
      departureDayIndex: flightDepartureDayIndex,
      departureTime: flight.departureTime ?? "00:00",
      arrivalDayIndex: flightArrivalDayIndex + flightDepartureDayIndex,
      arrivalTime: flight.arrivalTime ?? "00:00",
      carrier: "",
      descriptions: createNewTranslationFields(trip.priceDescriptions),
      updated_at: new Date(),
      tripdayId: trip.tripdays[tripdayIndex].id,
    };
    append(flight);
    formMethods.trigger();
  }

  const appendNewFlight = () => {
    appendFlight(newFlight);
  };

  const removeFlight = async (i: number) => {
    if (flights) {
      const id = flightFieldsWithDates[i]?.id;
      remove(i);
      if (id) {
        await removeFlights(id);
      }
      if (flightFieldsWithDates.length === 1 && tripStartDate) {
        setAmadeusStartDate(tripStartDate);
      }
    }
  };

  const disclosure = useDisclosure();

  if (isLoadingUpdate || !trip.start_date) return <CustomSpinner card skeleton m="auto" />;

  return (
    <TripForm formId={formId} formMethods={formMethods} onSubmit={handleSubmit} id="flights">
      <Button type="submit" hidden className="form-submit-button">
        Save
      </Button>
      <CRUDResource
        handleAdd={appendNewFlight}
        handleDelete={removeFlight}
        formName={"flights"}
        columns={columns as any}
        data={flightFieldsWithDates ?? []}
        disableSorting
        inlineEdit={true}
        heading2={
          <AmadeusFlightSearch
            addFlight={appendFlight}
            startDate={amadeusStartDate}
            setStartDate={setAmadeusStartDate}
          />
        }
        /* Test PNR - ATTENTION: the flights in this PNR depart on 29 feb., so if it is not a leap year, the flight will be added on 1 mar.
2  SQ2037 N 29FEB 4 BRUFRA HK1  0930 1030  29FEB  E  SQ/OHXM5E
3  SQ 025 N 29FEB 4*FRASIN HK1  1135 0655  01MAR  E  SQ/OHXM5E
4  SQ 207 N 01MAR 5*SINMEL HK1  0745 1810  01MAR  E  SQ/OHXM5E
5  ARNK
6  SQ 224 W 21MAR 4 PERSIN HK1  0640 1155  21MAR  E  SQ/OHXM5E
7  SQ2008 W 21MAR 4 SINFRA HK1  2355 0610  22MAR  E  SQ/OHXM5E
8  SQ2038 W 22MAR 5 FRABRU TK1  0940 1040  22MAR  E  SQ/OHXM5E
11 SVC SQ HK1 PENF BRU              PAST
        */
        headerButton={
          <Button type="button" colorScheme="brand" variant="solid" mx={3} onClick={disclosure.onOpen}>
            PNR
          </Button>
        }
        error={error}
      />
      <CustomModal size="xl" title="PNR" isOpen={disclosure.isOpen} onClose={disclosure.onClose} trapFocus={false}>
        <PnrParser modalDisclosure={disclosure} addFlight={appendFlight}></PnrParser>
      </CustomModal>
    </TripForm>
  );
};
