import { Heading, Box, Flex, Text } from "@chakra-ui/react";

interface SingleCardBoxLayoutProps {
  row: any;
}

const SingleCardBoxLayout: React.FC<SingleCardBoxLayoutProps> = ({ row }) => {
  return (
    <Box p={2}>
      {row.cells.map((cell: any, i: any) => {
        return (
          <Flex key={i} {...cell.getCellProps()} justify="space-between">
            <Heading>
              <Text fontSize="sm" casing="uppercase">
                {cell.render("Header")}
              </Text>
            </Heading>
            <Box fontSize="sm">{cell.render("Cell")}</Box>
          </Flex>
        );
      })}
    </Box>
  );
};

export default SingleCardBoxLayout;
