import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import {
  Company,
  Country,
  HELLO_MAIL,
  LATO_FACEBOOK_URL,
  LATO_INSTAGRAM_URL,
  LATO_SALES_URL,
  TravelCompanyType,
} from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CompanyAPI from "../../../api/companies.api";
import CountryAPI from "../../../api/countries.api";
import { ME_QUERY_KEY, SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../../utils/constants";
import { useParamsQueryHelper } from "../../../utils/query-helpers/reactQueryHooks";
import { handleSubmission } from "../../../utils/toErrorMap";
import FullScreenSpinner from "../../FullScreenSpinner";
import Form from "../../form/Form";
import SubmitButton from "../../form/SubmitButton";
import InputWrapper from "../../input/InputWrapper";
import RHFInput from "../../input/RHFInput";
import RHFSelect from "../../input/RHFSelect";
import ErrorCardView from "../../layout/ErrorCardView";
import Section, { horizontalSectionPadding } from "../../layout/Section";
import { SettingsInputWrapper } from "../account/PasswordSettings";

interface CompanySettingsProps {
  company: Company;
  id: any;
}

// const TravelCompanyTypeMapping: Record<TravelCompanyType, string> = {
//   [TravelCompanyType.LA]: "Local Agent (LA), Destination Management Company (DMC)",
//   [TravelCompanyType.TO]: "Tour Operator (TO), Travel Organizer (TO), Travel Designer",
//   [TravelCompanyType.TA]: "Travel Agent, Travel Reseller",
//   [TravelCompanyType.OTHER]: "Other",
// }

export type Address = {
  country?: Country | null;
  city: string;
  zipCode: string;
  aptnumber: string;
  streetAddress: string;
  state: string;
};

export const CompanySettings: React.FC<CompanySettingsProps> = ({ company, id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const {
    data: countries,
    isLoading,
    error,
  } = useParamsQueryHelper<Country[]>({
    queryKey: "countries-company",
    apiCall: CountryAPI.getAll,
    queryParams: {} as any,
    options: {},
  });

  const formMethods = useForm<Company>({
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
    defaultValues: company,
  });

  const { mutateAsync: mutateCompany } = useMutation({
    mutationFn: CompanyAPI.updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });

  const updateCompanyInformation = async (company: Company) => {
    await handleSubmission({
      successMessage: "updated company information",
      failMessage: "updating company information",
      apiCall: mutateCompany(company),
      toast,
      setError: formMethods.setError,
    });
  };

  if (isLoading) return <FullScreenSpinner />;
  if (error || !countries) return <ErrorCardView title="Could not load countries" />;

  return (
    <Section title="Company information" h="auto" noHorizontalPadding id={id}>
      <Text px={horizontalSectionPadding}>This is public information and can be seen by others.</Text>
      <Box minW="65%">
        <Form formMethods={formMethods} onSubmit={updateCompanyInformation}>
          <SettingsInputWrapper>
            <RHFInput
              name="name"
              label="Company name"
              placeholder="Lato"
              // defaultValue={company.name}
              layout="horizontal"
              isRequired
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          {/* <SettingsInputWrapper hasBg>
                  <RHFInput
                    name="brand"
                    label="Company Brand"
                    placeholder="Apple"
                    layout="horizontal"
                    isDisabled
                  />
                </SettingsInputWrapper> */}
          <SettingsInputWrapper hasBg>
            <RHFInput
              name="description"
              label="Company slogan"
              placeholder="Take your travel business to the next level"
              defaultValue={company.description}
              layout="horizontal"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper>
            <InputWrapper
              horizontal
              name="type"
              label="Travel company type"
              labelWidth={{ base: "40%", md: "250px" }}
              inputMarginLeft={"0"}
            >
              <RHFSelect
                name="companyType"
                // defaultValue="Other"
                options={Object.values(TravelCompanyType).map((g) => ({
                  value: g,
                }))}
                inputWidth={{ base: "100%", md: "63%" }}
              />
            </InputWrapper>
          </SettingsInputWrapper>
          {/* No company email? -> send all mails to all users and admin email */}
          <SettingsInputWrapper hasBg>
            <RHFInput
              name="billingEmail"
              label="Billing email"
              type="email"
              placeholder={HELLO_MAIL}
              layout="horizontal"
              isRequired
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          {/* <SettingsInputWrapper hasBg>
                  <RHFInput
                    name="company-phone-number"
                    label="Company Phone Number"
                    placeholder="+141 51 23 12 34"
                    layout="horizontal"
                    isDisabled
                  />
                </SettingsInputWrapper> */}
          <SettingsInputWrapper>
            <RHFInput
              name="site"
              label="Website"
              placeholder={LATO_SALES_URL}
              layout="horizontal"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper hasBg>
            <RHFInput
              name="facebookUrl"
              label="Facebook"
              placeholder={LATO_FACEBOOK_URL}
              layout="horizontal"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper>
            <RHFInput
              name="instagramUrl"
              label="Instagram"
              placeholder={LATO_INSTAGRAM_URL}
              layout="horizontal"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper hasBg>
            <InputWrapper
              name="address.country"
              label="Country"
              horizontal
              labelWidth={{ base: "40%", md: "250px" }}
              inputMarginLeft={"0"}
            >
              <RHFSelect
                name={`address.country.iso`}
                options={countries.map((country) => ({
                  value: country.iso,
                  text: country.name,
                }))}
                inputWidth={{ base: "100%", md: "63%" }}
                placeholder="Select country"
              ></RHFSelect>
            </InputWrapper>
          </SettingsInputWrapper>
          <SettingsInputWrapper>
            <RHFInput
              name="address.city"
              label="City"
              placeholder="Ghent"
              layout="horizontal"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper hasBg>
            <RHFInput
              name="address.zipCode"
              label="Zip code"
              placeholder="9000"
              layout="horizontal"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper>
            <RHFInput
              name="address.streetAddress"
              label="Street address"
              placeholder="Hoogpoort 31"
              layout="horizontal"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <Flex alignItems="center" mt={2}>
            <SubmitButton mt={3} mx={horizontalSectionPadding}>
              Save
            </SubmitButton>
          </Flex>
        </Form>
      </Box>

      <Flex w="100%" flexDir="column" alignItems="center">
        {/* <SingleImageDropzone /> */}
        {/* <ImageUploader
                      submitPicture={async (cropper: Cropper) => {
                        console.log("Submit logo");
                      }}
                      type="image"
                    >
                      <Center
                        w="200px"
                        h="200px"
                        borderRadius="md"
                        // overflow="hidden"
                        bg="gray.200"
                      >
                        {company.logo ? (
                          <Box pos="relative">
                            <IconButton
                              right={-2}
                              top={-2}
                              size="sm"
                              pos="absolute"
                              borderRadius="full"
                              aria-label="Remove tripday image"
                              icon={<CloseIcon />}
                              onClick={(e) => {
                                // e.stopPropagation();
                                // console.log("Remove image");
                                // // To not upload the file and s3key to the server
                                // if (file != undefined) {
                                //   // If the image was just uploaded, revoke the temporary URL
                                //   URL.revokeObjectURL(imageUrl!);
                                // }
                                // setS3key("");
                                // setFile(null);
                                // // To not show the file
                                // setImageUrl("");
                              }}
                            />
                            <Image
                              borderRadius="md"
                              boxSize="100%"
                              src={company.logo}
                              alt={`company-logo`}
                              fallbackSrc="https://via.placeholder.com/200"
                            ></Image>
                          </Box>
                        ) : (
                          <Flex
                            justifyContent="center"
                            alignItems="center"
                            flexDir="column"
                          >
                            <Icon boxSize="50%" as={ImageAdd} />
                            <Text>Click to add company logo</Text>
                          </Flex>
                        )}
                      </Center>
                    </ImageUploader> */}
      </Flex>
    </Section>
  );
};
export default CompanySettings;
