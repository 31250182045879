import { Stack, useDisclosure } from "@chakra-ui/react";
import { TRIP_STATUS, Trip, UserTrip } from "@lato/common";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GeneralSkeleton, NewWizardSkeleton, WizardSkeleton } from "../../../components/FullScreenSpinner";
import CustomModal from "../../../components/layout/CustomModal";
import ErrorCardView from "../../../components/layout/ErrorCardView";
import WizardButtonGroup from "../../../features/editTrip/wizard/WizardButtonGroup";
import TripCustomizationLanguageModal from "../../../components/trips/edit/TripCustomizationLanguageModal";
import { useMeContext } from "../../../stores/me-context";
import { TripLanguageProvider } from "../../../stores/trip-language-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import {
  RECENTLY_EDITED_LIST_QUERY_KEY,
  TRIP_PLAIN_QUERY_KEY,
  USERTRIP_PLAIN_QUERY_KEY,
} from "../../../utils/constants";
import ENV from "../../../utils/env";
import { getViewTripLink } from "../../../utils/generateURLs";
import { checkUserTranslated } from "../../../utils/getLanguages";
import {
  getRelationMap,
  useSplitTripByUserTripId,
  useSplitUserTrip,
  useTripTranslations,
} from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import useUnsavedChangesPrompt from "../../../utils/useUnsavedChangesPrompt";
import { Queue } from "../../../utils/queue";
import TripNav from "./TripNav";
import { ConditionalWrapper } from "../../../features/contacts/Contact";
import { useCollaborationInvitationStatus } from "../../../utils/query-helpers/reactQueryHooks";
import { is } from "date-fns/locale";

type EditRouteProps = {
  children: any;
};

const TripStepWrapper: React.FC<EditRouteProps> = ({ children }) => {
  const me = useMeContext();
  const {
    trip: tripFromStore,
    isDirtyForms,
    isSubmittingForms,
    isErrorForms,
    resetStore,
    setTrip,
    setIsFirst,
    setIsSample,
    setCollaboratorCanEdit,
  } = useTripFormStore();

  const { id: userTripId } = useParams<{ id: string }>();

  const { data: checkData } = useCollaborationInvitationStatus(userTripId!);

  const pushToRecentlyEditedList = (userTripCreatorId: string) => {
    if (me.id !== userTripCreatorId) return;

    const recentlyEditedListKey = `${RECENTLY_EDITED_LIST_QUERY_KEY}-${me.id}-${ENV}`;
    // Put trip in localstorage for recent trips and recent trip on dashboard
    const recentlyOpenedTrips = localStorage.getItem(recentlyEditedListKey) as string;
    const stack = new Queue<string>(recentlyOpenedTrips ? recentlyOpenedTrips.split(",") : [], 20);
    stack.push(userTripId!);
    localStorage.setItem(recentlyEditedListKey, stack.toString());
  };

  const {
    data: userTrips,
    isLoading: isLoadingUserTrip,
    error: userTripError,
  } = useSplitUserTrip(userTripId ?? "", getRelationMap[USERTRIP_PLAIN_QUERY_KEY], USERTRIP_PLAIN_QUERY_KEY, {
    enabled: !tripFromStore,
  });

  const {
    data: trip,
    isLoading: isLoadingTrip,
    error: tripError,
  } = useSplitTripByUserTripId(
    userTripId ?? "",
    TRIP_PLAIN_QUERY_KEY,
    getRelationMap[TRIP_PLAIN_QUERY_KEY],
    {
      enabled: !tripFromStore,
    },
    true,
  );

  const { data: tripTranslations, isLoading: isLoadingTranslations } = useTripTranslations(trip?.id ?? "");

  useEffect(() => {
    resetStore();
  }, []);

  const isFirst = !!userTrips && userTrips[0].user.companyId === me.companyId;
  const isSample = trip?.sample ?? false;

  useEffect(() => {
    if (trip && userTrips) {
      setIsFirst(isFirst);
      setIsSample(isSample);
      setCollaboratorCanEdit(trip.collaboratorCanEdit);
      setTrip({ ...trip, userTrips: userTrips });
    }
  }, [isFirst, isSample, setCollaboratorCanEdit, setIsFirst, setIsSample, setTrip, trip, userTrips]);

  // Check whether this user is not the first user and if this user does not have any translation-fields yet.
  const shouldCreateNewLanguage = !isFirst && tripTranslations && !checkUserTranslated(tripTranslations, me);

  // Check if the user has unsaved changes
  useUnsavedChangesPrompt(
    [...isDirtyForms.values()].some((value) => value === true) &&
      ![...isSubmittingForms.values()].some((value) => value === true),
    true,
  );

  if (userTripError || tripError)
    return <ErrorCardView title={`Couldn't load trip`} description="The URL is invalid." />;

  if (!trip || !userTrips || !tripTranslations) {
    return (
      <Stack
        position="relative"
        mx={{ base: "0", md: "7" }}
        mb={10}
        spacing={1}
        flexDir={me.useNewEditTripLayout ? "row" : "column"}
        w="100%"
      >
        {!me.useNewEditTripLayout && <WizardSkeleton />}
        <div className="w-full">
          <GeneralSkeleton />
        </div>
      </Stack>
    );
  }

  if (checkData && checkData.status !== "allowed") {
    return <ErrorCardView title="You don't have permission to edit this trip" />;
  }

  const isCancelled = !isFirst && userTrips[0].status === TRIP_STATUS.CANCELED;
  // Check if this trip has multiple usertrips and whether the first user has cancelled this trip.
  if (isCancelled) return <ErrorCardView title="This trip has been cancelled by a previous travel agency." />;

  const hasThisUserTranslated = checkUserTranslated(tripTranslations, me);

  const priceDescriptions = tripTranslations.priceDescriptions;
  const doubleFields = !isFirst && hasThisUserTranslated;

  const useNewEditTripLayout = me.useNewEditTripLayout;

  pushToRecentlyEditedList(userTrips[isFirst ? 0 : 1].user.id!);

  return (
    <TripLanguageProvider defaultPartialFields={priceDescriptions} first={isFirst} doubleFields={doubleFields}>
      <ConditionalWrapper
        condition={me.useNewEditTripLayout}
        wrapper={(children: React.ReactNode) => <TripNav>{children}</TripNav>}
      >
        {shouldCreateNewLanguage && <TripOverlay trip={{ ...trip, userTrips: userTrips }} />}
        {!useNewEditTripLayout && (
          <WizardButtonGroup
            viewTripLink={getViewTripLink(
              me?.company?.name || "",
              trip.titles ? trip.titles[0].content : "trip",
              userTripId!,
            )}
          />
        )}
        <Stack
          ml={{ base: "0", xl: useNewEditTripLayout ? "20" : "16" }}
          mr={{ base: "0", xl: useNewEditTripLayout ? "20" : "16" }}
          mt={useNewEditTripLayout ? 4 : "-2.6em"}
          mb={useNewEditTripLayout ? 0 : "4"}
          // width="100%"
        >
          {children}
        </Stack>
      </ConditionalWrapper>
    </TripLanguageProvider>
  );
};

type TripOverlayProps = {
  trip: Trip;
};
export const TripOverlay: React.FC<TripOverlayProps> = ({ trip }) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <CustomModal title={"Choose language"} isOpen={isOpen} onClose={onClose} closable={false} size="sm">
      <TripCustomizationLanguageModal trip={trip} />
    </CustomModal>
  );
};

TripStepWrapper.displayName = "EditRoute";
export default TripStepWrapper;
