import { Button } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthAPI from "../../api/auth.api";
import WarningIllustration from "../../assets/icons/Warning.Illustration";
import { CardWrapper } from "../../components/CardWrapper";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import { logout } from "../../features/menu/horizontal/HorizontalTopMenuBar";
import GeneralLoginRegister from "../../features/auth/GeneralLoginRegister";
import SplitScreenLayout from "../../components/layout/SplitScreenLayout";
import { useMeContext } from "../../stores/me-context";
import { useCompanyInviteInfo, useMe } from "../../utils/query-helpers/reactQueryHooks";
import { registerValidationSchema } from "../../validation/validationSchemas";

const AcceptInvitation: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const queryClient = useQueryClient();
  const { data: inviteInfo, isLoading: isLoadingInfo, error: errorInfo } = useCompanyInviteInfo(token!);
  const { data: loggedInUser, isLoading: isLoadingMe, error: errorMe } = useMe();
  const navigate = useNavigate();

  const handleUnauthorizedException = React.useCallback((statusCode: number, setError: any) => {
    if (statusCode === 401) {
      // Unauthorized exception
      // Hence, wrong credentials were given
      setError("password", {
        type: "manual",
        message: "Incorrect email - password combination.",
      });
    }
  }, []);

  if (isLoadingMe) return <FullScreenSpinner />;
  if (isLoadingInfo) return <FullScreenSpinner />;

  const joinCompanyRegister = async (values: any) => {
    return await AuthAPI.register({ companyInviteToken: token, ...values });
  };

  const joinCompanyLogin = async (values: any) => {
    return await AuthAPI.login({ companyInviteToken: token, ...values });
  };

  return (
    <SplitScreenLayout>
      {errorInfo || !inviteInfo ? (
        <CardWrapper
          title="Invalid invitation link"
          description="Could not load company invitation link. The link may be expired or
        already used."
          topIllustration={WarningIllustration}
        ></CardWrapper>
      ) : (
        <>
          {loggedInUser && loggedInUser.email !== inviteInfo.newUserEmail ? (
            <CardWrapper
              title="Wrong logged in user"
              description={`You are invited with '${inviteInfo.newUserEmail}' but you
            are currently logged in as '${loggedInUser.email}'.`}
              topIllustration={WarningIllustration}
            >
              <Button w="100%" size="md" colorScheme="brand" onClick={async () => await logout(navigate, queryClient)}>
                Log out and join with the other account
              </Button>
            </CardWrapper>
          ) : (
            // Never used, as the company of a user is always defined, you can't invite a user who has already a company defined.
            // When the user already exist he also has a company already.
            // inviteInfo.newUser ?
            // (
            //   <CardWrapper
            //     title={`You have been invited by '${inviteInfo.invitingUser.name}' to
            //   join '${inviteInfo.invitingUser.company.name}' in Lato`}
            //     description={`Log in using ${inviteInfo.newUserEmail} to accept the invitation.`}
            //   >
            //     <GeneralLoginRegister
            //       type="login"
            //       validationSchema={loginValidationSchema}
            //       successMessage="logged in"
            //       failMessage="logging in"
            //       apiCall={joinCompanyLogin}
            //       defaultEmail={inviteInfo.newUserEmail}
            //       handleAdditionalError={handleUnauthorizedException}
            //     />
            //   </CardWrapper>
            // ) :
            <CardWrapper
              title={`You have been invited by '${inviteInfo.invitingUser.name}' to
            join '${inviteInfo.invitingUser.company.name}' in Lato`}
              description={`Create an account below using '${inviteInfo.newUserEmail}' to accept the invitation.`}
            >
              <GeneralLoginRegister
                type="register"
                validationSchema={registerValidationSchema}
                successMessage="registered account"
                failMessage="registering account"
                apiCall={joinCompanyRegister}
                defaultEmail={inviteInfo.newUserEmail}
              />
            </CardWrapper>
          )}
        </>
      )}
    </SplitScreenLayout>
  );
};

export default AcceptInvitation;
