import { ArrowBackIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, Heading, IconButton, SkeletonText } from "@chakra-ui/react";
import React from "react";
import { Message } from "../../pages/chats";
import { useChatStore } from "../../stores/trip/chat-store";
import { Trip } from "@lato/common";
import ChatListItem from "./ChatListItem";

interface ChatListProps {
  remoteMessages: Message[];
  setSelectedTripId: (tripId: string | undefined) => void;
  selectedTripId: string | undefined;
  // bottomRef: RefObject<any>;
  refetchEl: JSX.Element | undefined;
  trip: Trip | undefined;
}

const ChatList: React.FC<ChatListProps> = ({ trip, remoteMessages, setSelectedTripId, selectedTripId, refetchEl }) => {
  const { newLocalMessagesPerTrip } = useChatStore();
  const latestMessages = Object.values(newLocalMessagesPerTrip)
    .filter((v) => v && v.length > 0)
    .map((v) => v[v.length - 1])
    .sort((a, b) => (a.created_at > b.created_at ? -1 : a.created_at === b.created_at ? 0 : 1));

  // Remove the trips of the new messages in the remote list
  const remoteMssgs = remoteMessages.filter((rm) => !(rm.usertrip.id! in newLocalMessagesPerTrip));

  const all_messages = [...latestMessages, ...remoteMssgs];

  return (
    <Box
      w={{ base: "100%", xl: "25%" }}
      borderRight={{ base: "none", xl: "1px" }}
      borderColor="gray.300 !important"
      whiteSpace="nowrap"
      h={{ base: "auto", xl: "100%" }}
    >
      <Flex flexDir="column" h="100%">
        <Center
          p={5}
          pb={{ base: "0", xl: "5" }}
          px={{ base: "0", xl: "5" }}
          position="sticky"
          zIndex={1}
          top={0}
          flexDirection="column"
        >
          <Flex alignItems={{ base: "none", xl: "center" }} justifyContent="space-between" w="100%" mb={4}>
            {selectedTripId ? (
              <ArrowBackIcon onClick={() => setSelectedTripId(undefined)} display={{ base: "inline", xl: "none" }} />
            ) : (
              <Box />
            )}
            <Heading size="lg">Messaging</Heading>
            {selectedTripId ? (
              <IconButton
                justifyContent={{ base: "right", xl: "center" }}
                icon={<EditIcon boxSize={5} onClick={() => setSelectedTripId(undefined)} />}
                variant="ghost"
                aria-label="New Message Button"
              />
            ) : (
              <Box />
            )}
          </Flex>
          {/* <Input
            placeholder="Search messages..."
            borderRadius="md"
            focusBorderColor="brand.500"
            size="md"
          /> */}
        </Center>

        {!trip || (trip && window.innerWidth > 992) ? (
          <Box h="100%" overflow="auto" borderBottom={{ base: "1px", xl: "none" }} borderColor="gray.300 !important">
            {all_messages ? (
              all_messages.map((lastMessage, i) => {
                return (
                  <ChatListItem
                    key={"lastMessage-" + i}
                    lastMessage={lastMessage}
                    active={selectedTripId === lastMessage.usertrip.id!}
                    selectedTripId={selectedTripId}
                    setSelectedTripId={setSelectedTripId}
                  />
                );
              })
            ) : (
              // Skeleton
              // <Flex padding="6" boxShadow="lg" bg="white">
              //<SkeletonCircle size="10" />
              <SkeletonText mt="4" noOfLines={20} spacing="4" />
              // </Flex>
            )}
            {refetchEl}
          </Box>
        ) : (
          <></>
        )}
      </Flex>
    </Box>
  );
};
export default ChatList;
