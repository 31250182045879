import {
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "../../components/shadcn-ui/Command";
import React from "react";
import { Button } from "../../components/shadcn-ui/Button";
import { isOSX } from "../../utils/getPlatform";
import { Search, ChevronRight } from "lucide-react";
import { getRoutes } from "../../utils/routes";
import { useNavigate } from "react-router-dom";

export function CommandMenu() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const isMac = isOSX();

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        setOpen((open) => !open);
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const onItemClick = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  return (
    <>
      <div className="w-full flex-1 md:w-auto md:flex-none">
        <Button
          variant="outline"
          onClick={() => setOpen(true)}
          placeholder="Search..."
          className="shadow-sm h-8 justify-start focus-visible:ring-1 relative w-full text-muted-foreground sm:pr-12 md:w-40 lg:w-64"
        >
          <Search className="me-2 h-4 w-4 shrink-0 opacity-50" />
          <span>Search...</span>
          <kbd className="pointer-events-none absolute right-1.5 top-1.25 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
            {isMac ? <span className="text-xs">⌘</span> : "Ctrl"} K
          </kbd>
        </Button>
      </div>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Go to">
            {getRoutes({ flatten: false, onlyNavs: true }).map((route: any, i) => (
              <React.Fragment key={`route-${i}`}>
                <CommandItem onSelect={() => onItemClick(route.path)}>
                  <route.nav.icon className="me-2 h-4 w-4" />
                  <span>{route.nav!.name}</span>
                </CommandItem>
                {route.subroutes?.map((subroute: any, j: number) => (
                  <CommandItem key={`command-item-${i}-${j}`} onSelect={() => onItemClick(subroute.path)}>
                    <route.nav.icon className="me-2 h-4 w-4" />
                    <span className="inline-flex">
                      {route.nav!.name}
                      &nbsp;
                      <ChevronRight className="h-4 w-4" />
                      &nbsp;
                      {subroute.nav!.name}
                    </span>
                  </CommandItem>
                ))}
              </React.Fragment>
            ))}
          </CommandGroup>
          {/* <CommandGroup heading="Suggestions">
            <CommandItem>
              <Calendar className="mr-2 h-4 w-4" />
              <span>Calendar</span>
            </CommandItem>
            <CommandItem>
              <Smile className="mr-2 h-4 w-4" />
              <span>Search Emoji</span>
            </CommandItem>
            <CommandItem>
              <Calculator className="mr-2 h-4 w-4" />
              <span>Calculator</span>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Settings">
            <CommandItem>
              <User className="mr-2 h-4 w-4" />
              <span>Profile</span>
              <CommandShortcut>⌘P</CommandShortcut>
            </CommandItem>
            <CommandItem>
              <CreditCard className="mr-2 h-4 w-4" />
              <span>Billing</span>
              <CommandShortcut>⌘B</CommandShortcut>
            </CommandItem>
            <CommandItem>
              <Settings className="mr-2 h-4 w-4" />
              <span>Settings</span>
              <CommandShortcut>⌘S</CommandShortcut>
            </CommandItem>
          </CommandGroup> */}
        </CommandList>
      </CommandDialog>
    </>
  );
}
