import { Language, TranslationField, User } from "@lato/common";
import React, { useContext } from "react";
import { calculateRealLanguageIndex } from "../components/trips/edit/daybyday/LanguagesInput";
import { useTripFormStore } from "./trip/tripFormStore";
import { getDefaultSelectedIndex, getShowType } from "../utils/user-translation-utils";
import { useMeContext } from "./me-context";

export type PartialTranslationField = {
  language: Language;
  creator: User | null;
  [key: string]: any;
};
export type TripLanguageType = {
  firstSelectedLanguageIndex: number;
  secondSelectedLanguageIndex: number | null;
  partialFields: PartialTranslationField[];
  // languages: Language[];
  // selectedLanguage: Language;
};

type TripLanguageContextType = TripLanguageType & {
  changeTripLanguageContext: React.Dispatch<React.SetStateAction<TripLanguageType>>;
  realLanguageIndex: number;
  calculateRealIndex: (index: number) => number;
  enabled: boolean;
};

const TripLanguageContext = React.createContext<TripLanguageContextType | undefined>(undefined);
TripLanguageContext.displayName = "TripLanguageContext";

type TripLanguageProviderProps = {
  // partial fields because when adding a new language the new TF doesn't exist yet.
  defaultPartialFields: PartialTranslationField[];
  first: boolean;
  doubleFields: boolean;
  enabled?: boolean;
  children: React.ReactNode;
};

const TripLanguageProvider: React.FC<TripLanguageProviderProps> = ({
  // firstSelectedLanguageIndex: defaultFirstSelectedLanguageIndex,
  // secondSelectedLanguageIndex: defaultSecondSelectedLanguageIndex,
  // languages: defaultLanguages,
  // selectedLanguage: defaultSelectedLanguage,
  defaultPartialFields,
  first,
  doubleFields,
  enabled = true,
  ...props
}) => {
  const me = useMeContext();
  defaultPartialFields = defaultPartialFields.filter((l: TranslationField) => l.language_code);
  const { selectedFirstLanguage, selectedSecondLanguage, setSelectedFirstLanguage, setSelectedSecondLanguage } =
    useTripFormStore();

  const defaultFirstSelectedLanguageIndex =
    selectedFirstLanguage ?? getDefaultSelectedIndex(doubleFields, true, defaultPartialFields, me);
  const defaultSecondSelectedLanguageIndex =
    selectedSecondLanguage ?? getDefaultSelectedIndex(doubleFields, false, defaultPartialFields, me);
  const secondSelectedLanguageIndex = doubleFields ? defaultSecondSelectedLanguageIndex : null;

  const [tripLanguageContext, changeTripLanguageContext] = React.useState<TripLanguageType>({
    firstSelectedLanguageIndex: defaultFirstSelectedLanguageIndex,
    secondSelectedLanguageIndex: secondSelectedLanguageIndex,
    partialFields: defaultPartialFields,
    // languages: defaultLanguages,
    // selectedLanguage: defaultSelectedLanguage,
  });

  React.useEffect(() => {
    changeTripLanguageContext({
      firstSelectedLanguageIndex: defaultFirstSelectedLanguageIndex,
      secondSelectedLanguageIndex: secondSelectedLanguageIndex,
      partialFields: defaultPartialFields,
    });
  }, [secondSelectedLanguageIndex]);

  React.useEffect(() => {
    setSelectedFirstLanguage(tripLanguageContext.firstSelectedLanguageIndex);
    setSelectedSecondLanguage(tripLanguageContext.secondSelectedLanguageIndex ?? undefined);
  }, [tripLanguageContext.firstSelectedLanguageIndex, tripLanguageContext.secondSelectedLanguageIndex]);

  // If the secondSelectedLanguageIndex is null, the first selected language index should be used, otherwise the second should be used.
  const languageIndex =
    tripLanguageContext.secondSelectedLanguageIndex !== null
      ? tripLanguageContext.secondSelectedLanguageIndex
      : tripLanguageContext.firstSelectedLanguageIndex;

  const calculateRealIndex = React.useCallback(
    (index: number) => {
      const showType = getShowType(tripLanguageContext.secondSelectedLanguageIndex !== null, first);
      // Calculate the real selected language index
      const realLanguageIndex = calculateRealLanguageIndex(tripLanguageContext.partialFields, showType, index, me);
      if (realLanguageIndex >= defaultPartialFields.length) return 0;
      return realLanguageIndex;
    },
    [tripLanguageContext],
  );

  const realLanguageIndex = calculateRealIndex(languageIndex);

  const value = React.useMemo(
    () => ({
      ...tripLanguageContext,
      firstSelectedLanguageIndex:
        tripLanguageContext.firstSelectedLanguageIndex >= tripLanguageContext.partialFields.length
          ? 0
          : tripLanguageContext.firstSelectedLanguageIndex,
      secondSelectedLanguageIndex:
        (tripLanguageContext.secondSelectedLanguageIndex ?? 0) >= tripLanguageContext.partialFields.length
          ? 0
          : tripLanguageContext.secondSelectedLanguageIndex,
      changeTripLanguageContext,
      realLanguageIndex,
      calculateRealIndex,
      enabled,
    }),
    [tripLanguageContext, changeTripLanguageContext, enabled],
  );

  return <TripLanguageContext.Provider value={value} {...props} />;
};

function useTripLanguage(): TripLanguageContextType {
  const context = useTripLanguageWithoutError();
  if (context === undefined) {
    throw new Error("useTripLanguage must be used within a TripLanguageProvider");
  }
  return context;
}

function useTripLanguageWithoutError(): TripLanguageContextType | undefined {
  return useContext<TripLanguageContextType | undefined>(TripLanguageContext);
}

export { TripLanguageProvider, useTripLanguage, useTripLanguageWithoutError };
