import TripPriceStep from "../../../../components/trips/edit/price/TripPriceStep";
import TripStepWrapper from "../TripStepWrapper";

export const PriceTripRoute: React.FC = () => {
  const stepNumber = 3;

  return (
    <TripStepWrapper>
      <TripPriceStep stepNumber={stepNumber} />
    </TripStepWrapper>
  );
};

export default PriceTripRoute;
