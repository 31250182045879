import { Flex } from "@chakra-ui/react";
import React from "react";
import { VerticalNavBar } from "../../features/menu/vertical/VerticalNavBar";
import MainFrame from "./MainFrame";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import { closeElement, submitForms } from "../../utils/EventHelper";

interface LatoPortalProps {
  fullHeight?: boolean;
  children: React.ReactNode;
}

const navBarWidth = "8vh";

const LatoPortal: React.FC<LatoPortalProps> = ({ children, fullHeight = false }) => {
  return (
    <Flex flexDirection={["column-reverse", "row"]} h="100vh">
      <VerticalNavBar navBarWidth={navBarWidth} />
      <MainFrame fullHeight={fullHeight} navBarWidth={navBarWidth}>
        {children}
      </MainFrame>
    </Flex>
  );
};
export default LatoPortal;
