import { Button, ButtonGroup, Flex, Text, useToast } from "@chakra-ui/react";
import { Role, User } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import UsersAPI from "../../../api/users.api";
import { DevTool } from "../../../utils/hookform-devtools";
import { handleSubmission } from "../../../utils/toErrorMap";
import Form from "../../form/Form";
import InputWrapper from "../../input/InputWrapper";
import RHFSelect from "../../input/RHFSelect";
import CustomModal from "../../layout/CustomModal";
import { ME_QUERY_KEY } from "../../../utils/constants";

interface EditRoleModalProps {
  user: User;
  disclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
}

const EditRoleModal: React.FC<EditRoleModalProps> = ({ user, disclosure }) => {
  console.log(user);
  const { onClose, isOpen } = disclosure;

  const queryClient = useQueryClient();

  const { mutateAsync: editRole } = useMutation({
    mutationFn: (role: Role) =>
      UsersAPI.editRole(user.id!, {
        role,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["my-team"],
      });
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
    },
  });

  const toast = useToast();
  const formMethods = useForm<{ role: Role }>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      role: user.role,
    },
  });

  const successCallback = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = async ({ role }: { role: Role }) => {
    await handleSubmission({
      successMessage: "updated role",
      failMessage: "updating role",
      apiCall: editRole(role),
      toast,
      successCallback,
      setError: formMethods.setError,
    });
  };

  React.useEffect(() => {
    formMethods.reset({ role: user.role });
  }, [user.role, formMethods.reset]);

  return (
    <CustomModal title="Edit user role" onClose={onClose} isOpen={isOpen} size="sm">
      <Text mb={3}>
        Please select a new role for user: <em>{user.email}</em>
      </Text>
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <DevTool control={formMethods.control} />
        <InputWrapper name="role" label="Role">
          <RHFSelect name="role" options={Object.values(Role).map((g) => ({ value: g }))} />
        </InputWrapper>
        <Flex mt={7} mb={2}>
          <ButtonGroup size="md" ml="auto">
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="brand"
              ml={3}
              isLoading={formMethods.formState.isSubmitting}
              isDisabled={!formMethods.formState.isValid}
            >
              Save
            </Button>
          </ButtonGroup>
        </Flex>
      </Form>
    </CustomModal>
  );
};
export default React.memo(EditRoleModal);
