import { Box, Flex } from "@chakra-ui/react";
import { GoogleAddress, TLocation, TRANSPORTATION_TYPE, parseAddressComponents } from "@lato/common";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import formatAddress from "../../utils/formatAddress";
import RHFInput from "../../components/input/RHFInput";
import MapsAutocomplete from "./MapsAutocomplete";
import StaticMap from "../../components/map/staticMap";
import { SearchIcon } from "lucide-react";
import { IoIosSearch } from "react-icons/io";
import { MdSearch } from "react-icons/md";

interface AutocompleteStaticMapProps {
  fillFieldName?: string;
  fromFieldName: string;
  toFieldName?: string;
  setGeometryFromValue: any;
  setGeometryToValue?: any;
  fromLocationValue?: any;
  toLocationValue?: any;
  mapMargin?: string;
  first?: boolean;
  showMap?: boolean;
  uniqueId?: string;
  inTrip?: boolean;
  raiseMap?: boolean;
  fromPreLabel?: string;
  toPreLabel?: string;
  disableFields?: boolean;
  hideLabel?: boolean;
  inputWidth?: string;
  transportation_type?: TRANSPORTATION_TYPE;
  toLocation?: TLocation;
  fromLocation?: TLocation;
  setToLocation?: any;
  setFromLocation?: any;
}

const AutocompleteStaticMap: React.FC<AutocompleteStaticMapProps> = ({
  fillFieldName,
  fromFieldName,
  toFieldName,
  setGeometryFromValue,
  setGeometryToValue,
  fromLocationValue = undefined,
  toLocationValue = undefined,
  mapMargin = "",
  first = true,
  showMap = true,
  uniqueId = "unique",
  inTrip = false,
  raiseMap = false,
  fromPreLabel = "",
  toPreLabel = "",
  disableFields = false,
  hideLabel = false,
  inputWidth = "100%",
  transportation_type,
  toLocation,
  fromLocation,
  setToLocation,
  setFromLocation,
}) => {
  const handlePlaceChanged = (place: any, to: boolean = false) => {
    if (place !== null) {
      if (place.geometry) {
        // Change the chosen location.
        const coordinates = [place.geometry.location.lng(), place.geometry.location.lat()];
        let address: GoogleAddress = {
          city: null,
          country_code: null,
          postal_code: null,
          state: null,
          street: null,
        };
        if (place.address_components) {
          const address_components = parseAddressComponents(place.address_components);
          const street = address_components.route?.long_name;
          const street_number = address_components.street_number?.long_name;
          address = {
            country_code: address_components.country?.short_name,
            state: address_components.administrative_area_level_1?.long_name,
            city: address_components.locality?.long_name,
            postal_code: address_components.postal_code?.long_name,
            street: street && street_number ? `${street_number} ${street}` : street ? street : street_number,
          };
        }
        if (!to) {
          if (setFromLocation) {
            setFromLocation({
              name: place.formatted_address ?? place.name,
              coordinates: coordinates,
              address: address,
            });
          }
          setGeometryFromValue(coordinates, place.formatted_address ?? place.name, address);
        } else {
          if (setToLocation) {
            setToLocation({
              name: place.formatted_address ?? place.name,
              coordinates: coordinates,
              address: address,
            });
          }
          setGeometryToValue(coordinates, place.formatted_address ?? place.name, address);
        }
        // Clear the text field
        // setValue("gmaps_autocomplete" as any, "");
      }
    } else {
      if (!to) {
        console.log("removeFrom");
        setFromLocation(null);
        setGeometryFromValue(null, "", "");
      } else {
        console.log("removeTo");
        setToLocation(null);
        setGeometryToValue(null, "", "");
      }
    }
  };

  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"flex-start"}
      width={"100%"}
      height={raiseMap ? { lg: "3.5em", "2xl": "auto" } : "auto"}
      position={"relative"}
      gap={inTrip ? 0 : 3}
    >
      <Flex flexDirection={"column"} gap={2} justifyContent={"flex-start"} width={inputWidth} marginRight={"auto"}>
        <Box width={"100%"}>
          <MapsAutocomplete handlePlaceChanged={handlePlaceChanged}>
            <AutoCompleteStaticMapInput
              fieldName={fromFieldName}
              fillFieldName={fillFieldName}
              first={first}
              uniqueId={`${uniqueId}From`}
              preLabel={fromPreLabel}
              isDisabled={disableFields}
              handlePlaceChanged={handlePlaceChanged}
              hideLabel={hideLabel}
            />
          </MapsAutocomplete>
        </Box>
        {toFieldName && (
          <Box width={"100%"}>
            <MapsAutocomplete handlePlaceChanged={(plan) => handlePlaceChanged(plan, true)}>
              <AutoCompleteStaticMapInput
                fieldName={toFieldName}
                first={first}
                uniqueId={`${uniqueId}To`}
                preLabel={toPreLabel}
                isDisabled={disableFields}
                handlePlaceChanged={handlePlaceChanged}
                to
              />
            </MapsAutocomplete>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

interface AutoCompleteStaticMapInputProps {
  fieldName: string;
  fillFieldName?: string;
  first: boolean;
  uniqueId: string;
  preLabel?: string;
  isDisabled?: boolean;
  handlePlaceChanged: (place: any, to?: boolean) => void;
  to?: boolean;
  hideLabel?: boolean;
}

const AutoCompleteStaticMapInput: React.FC<AutoCompleteStaticMapInputProps> = ({
  fieldName,
  fillFieldName,
  first,
  uniqueId,
  preLabel = "",
  isDisabled = false,
  handlePlaceChanged,
  to = false,
  hideLabel = false,
}) => {
  const { getValues, setValue, setFocus } = useFormContext();
  const location = getValues(fieldName);
  const getformattedAddres = () => {
    const address = getValues("address");
    if (address) return formatAddress(address);
  };

  const [locationInputValue, setLocationInputValue] = React.useState<string | undefined>(
    location ? location.name : fillFieldName ? getformattedAddres() : "",
  );

  const fillOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value && fillFieldName) {
      const accommodationName = getValues(fillFieldName as any);
      setValue(`gmaps_autocomplete.${uniqueId}` as any, accommodationName);
      setLocationInputValue(accommodationName);
      e.target.select();
      // Trigger the focus event again because google maps tries to open the dropdown before the value was set and thus it doesn't open because the value is empty.
      google.maps.event.trigger(e.target, "focus", {});
    }
  };

  return (
    <>
      <RHFInput
        name={`gmaps_autocomplete.${uniqueId}`}
        value={locationInputValue !== undefined && location?.name === undefined ? locationInputValue : location?.name}
        onChange={(e) => {
          setLocationInputValue(e.target.value);
          if (e.target.value === "") {
            console.log("remove", fillFieldName);
            handlePlaceChanged(null, to);
          }
          if (location) {
            location.name = undefined;
          }
        }}
        onClick={(e) => e.stopPropagation()}
        onFocus={fillOnFocus}
        label={hideLabel ? "" : `${preLabel} Location / Address`}
        isDisabled={isDisabled || !first}
        key={uniqueId}
        w="100%"
        py={4}
        bgColor={"white"}
        inputWidth="100%"
      />
      {/* Used to add the error message for when the location object is empty */}
      <RHFInput name={fieldName} type="hidden" />
    </>
  );
};

export default AutocompleteStaticMap;
