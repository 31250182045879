import {
  Checkbox,
  ComponentWithAs,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Textarea,
} from "@chakra-ui/react";
import clsx from "clsx";
import React from "react";
import InputMask from "react-input-mask";

export const CoreInput: React.FC<any> = React.forwardRef(
  ({ textarea, selectValues, radioOptions, name, register = () => ({}), ...props }, ref) => {
    const InputType = textarea ? Textarea : radioOptions ? RadioGroup : Input;

    if (props.type === "checkbox") {
      return <Checkbox {...register(name)} {...props} />;
    }

    const inputProps = {
      InputType,
      name,
      register,
      ...props,
      ref,
    };

    if (selectValues) {
      return (
        <InputWrapper {...inputProps}>
          {selectValues.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </InputWrapper>
      );
    }

    if (radioOptions) {
      return (
        <InputWrapper {...inputProps}>
          {radioOptions.map((option) => (
            <Radio key={option} value={option}>
              {option}
            </Radio>
          ))}
        </InputWrapper>
      );
    }

    return <InputWrapper {...inputProps} />;
  },
);
CoreInput.displayName = "CoreInput";

export default CoreInput;

const InputWrapper: React.FC<any> = React.forwardRef(
  ({ InputType, name, register, children, leftIcon, rightIcon, ...props }, ref) => {
    const registerProps = register(name);

    const { size, ...restProps } = props;

    return (
      <InputGroup size={size} onWheel={(e) => (e.target as any).blur()}>
        {leftIcon && <InputLeftElement>{leftIcon}</InputLeftElement>}
        <InputType
          as={props.mask ? InputMask : ""}
          {...registerProps}
          {...restProps}
          ref={registerProps?.ref || ref}
          className={clsx("swiper-no-swiping", restProps.className)}
          autoComplete="nope"
        >
          {children}
        </InputType>
        {rightIcon && <InputRightElement>{rightIcon}</InputRightElement>}
      </InputGroup>
    );
  },
);
InputWrapper.displayName = "InputWrapper";
