import { Flex, Icon, Link, Text, Tooltip } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { Link as ReactLink } from "react-router-dom";
import { IconObject } from "./VerticalNavBar";

interface VerticalNavBarItemProps extends IconObject {
  active: boolean;
  isHorizontal?: boolean;
}

/**
 * FC for an individual list item in the menu. It consists of an icon and a text.
 * Active state is handled here as well as the hover state
 *
 * @param param0
 */
const VerticalNavBarItem: React.FC<VerticalNavBarItemProps> = ({
  icon,
  selectedIcon,
  text,
  route,
  action,
  active,
  isExternal,
  tooltip,
  disabled,
  badge,
  isHorizontal = false,
}) => {
  const handleAction = useCallback(async () => {
    if (action && !disabled) await action();
  }, [action]);
  return (
    <Tooltip placement="right" label={tooltip}>
      <Link
        as={ReactLink}
        onClick={handleAction}
        to={!disabled ? route : undefined}
        isExternal={isExternal}
        variant="unstyled"
        w="100%"
      >
        <Flex
          as="button"
          type="button"
          alignItems="center"
          flexDirection={isHorizontal ? "row" : "column"}
          justifyContent={isHorizontal ? "space-between" : "center"}
          _hover={{ bg: "whiteAlpha.200" }}
          color={active ? "brand.300" : ""}
          bg={active ? "whiteAlpha.200" : ""}
          w="75%"
          minW="75%"
          maxW="75%"
          px={isHorizontal ? 2 : 0}
          py={isHorizontal ? 2 : 1}
          my={isHorizontal ? 2 : 1}
          mx="auto"
          borderRadius="md"
          disabled={disabled}
        >
          <span style={{ position: "relative" }} className={isHorizontal ? "hidden" : "w-full"}>
            {badge}
            <Icon
              // If active then use the filled icon
              as={active ? selectedIcon : icon}
              width={6}
              height={6}
            />
          </span>
          <Text
            color={isHorizontal ? "black" : "white"}
            textAlign="center"
            fontSize={["xs", "xs", "xs", "xs", "xs", "sm"]}
          >
            {text}
          </Text>
          {/* <Heading textAlign="center" size="xs" fontSize="1em">
          {text}
        </Heading> */}
        </Flex>
      </Link>
    </Tooltip>
  );
};
export default VerticalNavBarItem;
