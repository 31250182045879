import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Trash2 as DeleteIcon } from "lucide-react";
import { OptionsDropdownItem } from "../../components/CRUD/OptionsDropdown";

import ContactAPI from "../../api/contacts.api";
import { Contact } from "@lato/common";
import CustomModal from "../../components/layout/CustomModal";

interface DeleteContactProps {
  contactDetail: Contact;
}

const DeleteContact: React.FC<DeleteContactProps> = ({ contactDetail }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteContact, isPending } = useMutation({
    mutationFn: (contactId: string) => ContactAPI.delete(contactId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get-contacts"],
      });
      onClose();
    },
  });
  return (
    <>
      <CustomModal title={`Delete Group`} onClose={onClose} isOpen={isOpen} size="sm" colorScheme="brand">
        <Text fontSize="md">Are you sure you want to delete contact {contactDetail.first_name} ?</Text>
        <Flex alignItems="center" justifyContent="right" mt={3}>
          <Button onClick={onClose} colorScheme="red" variant="outline" mr={2}>
            Cancel
          </Button>
          <Button colorScheme="brand" onClick={() => deleteContact(contactDetail.id!)} isLoading={isPending}>
            Delete
          </Button>
        </Flex>
      </CustomModal>
      <OptionsDropdownItem
        onClick={(e) => onOpen()}
        name="Delete Contact"
        color="red.500"
        icon={<DeleteIcon size={"16px"} />}
      />
    </>
  );
};
export default DeleteContact;
