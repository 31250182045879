import { Avatar, Flex, Icon, Image, Text, Tooltip, useClipboard, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { CellProps, Column, FilterProps, Filters, Row } from "react-table";

import { CONTACTTYPE, Country, GENDER, QueryType, Contact } from "@lato/common";
import { HiOutlineCheck as Check, HiPhone as Phone } from "react-icons/hi";
import { MdMail as Mail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CountryAPI from "../../api/countries.api";
import { useTripsListStore } from "../../stores/trip/tripslistStore";
import { usePaginatedContacts, useParamsQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import CountryFilter from "../../components/CRUD/CountryFilter";
import MultiSelectColumnFilter from "../../components/CRUD/MultiSelectColumnFilter";
import OptionsDropdown from "../../components/CRUD/OptionsDropdown";
import CRUDResource from "../../components/CRUD/Resource";
import { ListSkeleton } from "../../components/FullScreenSpinner";
import ErrorCardView from "../../components/layout/ErrorCardView";
import { calculateAge } from "./Contact";
import { genderText } from "./ContactForm";
import CreateContact from "./CreateContact";
import DeleteContact from "./DeleteContact";
import EditContact from "./EditContact";
import UploadCsv from "../../components/UploadCsv";

const ContactList: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [page, setPage] = useState(1);
  const initialStep = 10;
  const [step, setStep] = useState(initialStep);
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const [order, setOrder] = useState<"DESC" | "ASC">("DESC");
  const [search, setSearch] = useState("");

  const defaultContactTypes = [CONTACTTYPE.PERSON, CONTACTTYPE.COMPANY];
  const defaultContactTypesString = defaultContactTypes.join(",");
  const [contact_typeFilter, setContactTypeFilterFilter] = React.useState<string | undefined>(
    defaultContactTypesString,
  );
  const defaultGenders = Object.values(GENDER);
  const defaultGenderString = defaultGenders.join(",");
  const [genderFilter, setGenderFilter] = React.useState<string | undefined>(defaultGenderString);

  const { countryFilter, continentFilter, setCountryFilter, setContinentFilter } = useTripsListStore();

  const resetFilters = () => {
    setGenderFilter(defaultGenderString);
    setCountryFilter("");
    setContinentFilter("");
  };

  const triggerFetch = React.useCallback(
    ({
      order,
      orderBy,
      page,
      step,
      q,
      filters,
    }: {
      page: number;
      step: number;
      q: string;
      order: "DESC" | "ASC" | undefined;
      orderBy: string | undefined;
      filters: Filters<Contact>;
    }) => {
      setOrderBy(orderBy);
      setOrder(order || "DESC");
      setPage(page);
      setStep(step);
      setSearch(q);

      const contact_typeFilter =
        filters.find((filter) => filter.id === "contact_type")?.value.join(",") || defaultContactTypesString;
      setContactTypeFilterFilter(contact_typeFilter);
      const genderFilter = filters.find((filter) => filter.id === "gender")?.value.join(",") || [];
      setGenderFilter(genderFilter);
    },
    [setOrderBy, setOrder, setPage, setStep, setSearch, setContactTypeFilterFilter, setGenderFilter],
  );

  const { isLoading, data, isFetching } = usePaginatedContacts({
    page,
    orderBy,
    order,
    step,
    q: search,
    contact_type: contact_typeFilter,
    countries: countryFilter,
    gender: genderFilter,
  });

  const contacts = data?.data ?? [];

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: errorCountries,
  } = useParamsQueryHelper<Country[]>({
    queryKey: "countries-contacts",
    apiCall: CountryAPI.getAll,
    queryParams: {
      type: QueryType.CONTACT,
    } as any,
    options: {},
  });

  const createContactTypeOptions = () => {
    return new Set(Object.values(CONTACTTYPE));
  };

  const createGenderOptions = () => {
    return new Set(Object.values(GENDER));
  };

  const selectedContinents = React.useCallback(
    () => (continentFilter !== "" ? continentFilter?.split(",") : []),
    [continentFilter],
  );

  const columns: Column<Contact>[] = React.useMemo(
    () => [
      /*{
        accessor: "contact_type",
        Header: "Type",
        chakraWidth: "80px",
        Filter: (props) => {
          return <MultiSelectColumnFilter {...props} createOptions={createContactTypeOptions} />;
        },
        Cell: ({ row, value }: CellProps<Contact>) => {
          return <Icon as={value === CONTACTTYPE.PERSON ? PersonFill : Building} boxSize={5} ml={4} />;
        },
        disableFilters: false,
      },*/
      {
        id: "passenger-avatar",
        chakraWidth: "60px",
        Cell: ({ row }: CellProps<Contact>) => {
          return (
            <Avatar
              size="sm"
              name={`${row.original.last_name ? `${row.original.last_name} ` : ""}${row.original.first_name}`}
              title={`${row.original.last_name ? `${row.original.last_name} ` : ""}${row.original.first_name}`}
              src={(row.original as any).avatarUrl}
              background={"#9f9f9f"}
              color={"white"}
            />
          );
        },
      },
      {
        Header: "Name",
        accessor: "first_name",
        chakraWidth: "200px",
        Cell: ({ row, value }: CellProps<Contact>) => {
          return (
            <Text>
              {row.original.last_name} {value}
              {row.original.nick_name && ` (${row.original.nick_name})`}
            </Text>
          );
        },
      },
      {
        accessor: "gender",
        Header: "Gender",
        chakraWidth: "80px",
        Cell: ({ row, value }: CellProps<Contact>) => {
          if (row.original.contact_type === CONTACTTYPE.PERSON) {
            return <Text marginLeft={4}>{genderText[value]}</Text>;
          }
          return <></>;
        },
        Filter: (props) => {
          return <MultiSelectColumnFilter {...props} createOptions={createGenderOptions} />;
        },
        disableFilters: false,
      },
      {
        accessor: "date_of_birth",
        id: "date_of_birth",
        Header: "Date of birth",
        chakraWidth: "80px",
        Cell: ({ row, value }: CellProps<Contact>) => {
          if (row.original.contact_type === CONTACTTYPE.PERSON) {
            return <Text>{value}</Text>;
          }
          return <></>;
        },
      },
      {
        accessor: "date_of_birth",
        id: "age",
        Header: "Age",
        chakraWidth: "80px",
        Cell: ({ row, value }: CellProps<Contact>) => {
          if (row.original.contact_type === CONTACTTYPE.PERSON && value) {
            return <Text ml={1}>{calculateAge(value)}</Text>;
          }
          return <></>;
        },
      },
      {
        accessor: "spokenLanguage",
        Header: "Language",
        chakraWidth: "100px",
        Cell: ({ row, value }: CellProps<Contact>) => {
          return <Text>{value?.name}</Text>;
        },
      },
    ],
    [genderFilter],
  );

  const navigate = useNavigate();

  const onClickRow = React.useCallback(
    (e: any, contactId: string) => {
      e.stopPropagation();
      // Edit the trip
      navigate(`/contacts/${contactId}`);
    },
    [navigate],
  );

  const rowProps = ({ row }: { row: Row<any> }) => ({
    onClick: (e: any) => onClickRow(e, row.original.id),
    _hover: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  });

  if (isLoadingCountries) return <ListSkeleton />;
  if (errorCountries || !countries) return <ErrorCardView title="Could not load countries" />;

  const realcolumns = [
    ...columns,
    {
      Header: "Country",
      accessor: "address",
      id: "country",
      chakraWidth: "80px",
      Cell: ({ row, value }: CellProps<Contact>) => {
        if (value.country) {
          return (
            <Flex marginLeft={4}>
              <Image
                height={"10px"}
                width={"16px"}
                borderRadius={2}
                shadow={"0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)"}
                src={value?.country?.flagImage}
                title={value?.country?.name}
                alt={value?.country?.name}
              />
            </Flex>
          );
        }
        return <></>;
      },
      Filter: (props: FilterProps<Contact>) => (
        <CountryFilter
          {...props}
          countries={countries?.filter((country) =>
            selectedContinents()?.length > 0 ? selectedContinents().includes(country.continent) : [],
          )}
        />
      ),
      disableFilters: false,
    },
    {
      id: "groupOptions",
      isNumeric: true,
      chakraWidth: "80px",
      Cell: ({ row }: CellProps<any>) => {
        const { onCopy: onCopyPhone, hasCopied: hasCopiedPhone } = useClipboard(row.original.phoneNumber);
        const { onCopy: onCopyMail, hasCopied: hasCopiedMail } = useClipboard(row.original.primaryEmail);

        return (
          <Flex justifyContent={"flex-end"} alignItems={"center"} gap={1}>
            {row.original.primaryEmail && (
              <Tooltip
                label={(hasCopiedMail ? "copied " : "copy ") + row.original.primaryEmail}
                aria-label="copy-mail"
                hasArrow
              >
                <span>
                  <Icon
                    as={hasCopiedMail ? Check : Mail}
                    boxSize={4}
                    color={hasCopiedMail ? "green" : "gray"}
                    _hover={{ color: hasCopiedMail ? "green" : "black" }}
                    zIndex={10}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCopyMail();
                    }}
                  />
                </span>
              </Tooltip>
            )}
            {row.original.phoneNumber && (
              <Tooltip
                label={(hasCopiedPhone ? "copied " : "copy ") + row.original.phoneNumber}
                aria-label="copy-phone"
                hasArrow
              >
                <span>
                  <Icon
                    as={hasCopiedPhone ? Check : Phone}
                    boxSize={4}
                    ml={1}
                    color={hasCopiedPhone ? "green" : "gray"}
                    _hover={{ color: hasCopiedPhone ? "green" : "black" }}
                    zIndex={10}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCopyPhone();
                    }}
                  />
                </span>
              </Tooltip>
            )}
            <OptionsDropdown>
              <EditContact contactDetail={row.original} />
              <DeleteContact contactDetail={row.original} />
            </OptionsDropdown>
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <CRUDResource
        formName={"contacts"}
        data={contacts || []}
        fetchingData={isFetching}
        loadingData={isLoading}
        totalCount={data?.count ?? 0}
        getRowProps={rowProps}
        columns={realcolumns as Column<any>[]}
        globalSearch={true}
        resetFilters={resetFilters}
        handleAdd={onOpen}
        triggerFetch={triggerFetch}
        filterWidth={"65%"}
        initialTableState={{
          q: search,
          step,
          page,
          order,
          orderBy,
          filters: [
            {
              id: "contact_type",
              value: defaultContactTypes,
            },
            { id: "countries", value: [] },
            { id: "gender", value: defaultGenders },
          ],
        }}
        headerButton={<UploadCsv />}
        useSpaciousLayout
      />
      <CreateContact
        isOpen={isOpen}
        onClose={(e: Contact | undefined) => {
          onClose();
          if (e?.hasOwnProperty("contact_type")) {
            navigate(`/contacts/${e?.id}`);
          }
        }}
      />
    </>
  );
};
export default ContactList;
