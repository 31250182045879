import { Box, Button, Flex, Heading, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { HiArrowRight as ArrowRight } from "react-icons/hi";
import TransportationsAPI from "../../api/transportations.api";
import { Transportation, monthByNum } from "@lato/common";
import { handleSubmission } from "../../utils/toErrorMap";
import Form from "../../components/form/Form";
import RHFInput from "../../components/input/RHFInput";

interface PnrParserProps {
  addFlight: (flight: Transportation) => void;
  modalDisclosure: any;
}

type pnrForm = {
  pnr: string;
};

export const prettyDateTime = (date: string) => {
  const splitDate = date.split("-");
  const dayTime = splitDate[2].split("T");
  return `${dayTime[0].slice(0, 2)} ${monthByNum[+splitDate[1]]} ${dayTime[1].slice(0, 5)}`;
};

const PnrParser: React.FC<PnrParserProps> = ({ addFlight, modalDisclosure }) => {
  const pnrFormMethods = useForm<pnrForm>();
  const toast = useToast();
  const [flights, setFlights] = useState<Transportation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const successCallback = (resp: Transportation[]) => {
    resp.forEach((flight) => {
      const index = flights.findIndex(
        (x) =>
          x.transportNumber === flight.transportNumber &&
          x.arrivalAirportIata === flight.arrivalAirportIata &&
          x.departureAirportIata == flight.departureAirportIata,
      );
      return index === -1
        ? flights.push({
            ...flight,
            realtime: false,
          })
        : null;
    });
    setFlights(flights);
    setIsLoading(false);
  };

  const removeFlight = (number: string, flightArrivalIata: string, flightDepartureIata: string) => {
    setFlights(
      flights.filter(
        (flight) =>
          flight.transportNumber !== number ||
          flight.arrivalAirportIata !== flightArrivalIata ||
          flight.departureAirportIata !== flightDepartureIata,
      ),
    );
  };

  const handleFlightAdds = () => {
    flights.forEach((flight) => {
      const departDate = new Date(flight.departureDate ?? "");
      const arriveDate = new Date(flight.arrivalDate ?? "");

      addFlight(flight);
    });
    modalDisclosure.onClose();
  };

  const handlePnrSubmit = async (pnrValues: pnrForm) => {
    setIsLoading(true);
    await handleSubmission({
      successMessage: "parsed your PNR",
      failMessage: "parsing your PNR",
      apiCall: TransportationsAPI.parsePnr(pnrValues.pnr),
      toast,
      successCallback,
      setError: pnrFormMethods.setError,
    });
  };

  return (
    <Box mx={8}>
      <Heading mb={3} mt={3} size={"lg"}>
        Paste the flight details from your PNR in here
      </Heading>

      <Form formMethods={pnrFormMethods} onSubmit={handlePnrSubmit}>
        <RHFInput
          borderRadius="md"
          name="pnr"
          textarea
          focusBorderColor="brand.500"
          size="md"
          placeholder={`1.SMITH/JOHN MR\n2 SQ2037 N 29FEB 4 BRUFRA HK1 0930 1030 29FEB E SQ/OHXM5E\n3 SQ 025 N 29FEB 4*FRASIN HK1 1135 0655 01MAR E SQ/OHXM5E\n4 SQ 207 N 01MAR 5*SINMEL HK1 0745 1810 01MAR E SQ/OHXM5E\n5 ARNK\n6 SQ 224 W 21MAR 4 PERSIN HK1 0640 1155 21MAR E SQ/OHXM5E\n7 SQ2008 W 21MAR 4 SINFRA HK1 2355 0610 22MAR E SQ/OHXM5E\n8 SQ2038 W 22MAR 5 FRABRU TK1 0940 1040 22MAR E SQ/OHXM5E\n11 SVC SQ HK1 PENF BRU             PAST`}
        ></RHFInput>
        <Flex mt={3} justifyContent={"space-around"}>
          <Button
            w="30%"
            mt={4}
            onClick={() => handlePnrSubmit(pnrFormMethods.getValues())}
            colorScheme="brand"
            isLoading={isLoading}
          >
            Parse PNR
          </Button>
        </Flex>
      </Form>
      {flights[0] && (
        <>
          <Box my={4} px={2} maxHeight={"10em"} overflow={"scroll"}>
            {flights.map((flight, i) => (
              <Box
                key={`PnrFlight-${i}`}
                py={1}
                px={3}
                my={2}
                borderRadius={"5px"}
                border={"rgba(0, 0, 0, 0.16) solid 1px"}
                backgroundColor={"lightGray.100"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Box fontWeight={"bold"} width={"4.5em"}>
                  {flight.transportNumber}
                </Box>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1} width={"5em"}>
                  {flight.departureAirportIata} <ArrowRight size="15px" />
                  {flight.arrivalAirportIata}
                </Box>
                {flight.departureDate && <Box width={"8em"}>{prettyDateTime(flight.departureDate.toString())}</Box>}
                <Box
                  color={"red.600"}
                  cursor={"pointer"}
                  position={"relative"}
                  bottom={"2px"}
                  onClick={() =>
                    removeFlight(
                      flight.transportNumber,
                      flight.arrivalAirportIata ?? "",
                      flight.departureAirportIata ?? "",
                    )
                  }
                >
                  x
                </Box>
              </Box>
            ))}
          </Box>
          <Flex mt={3} justifyContent={"space-around"}>
            <Button w="30%" mt={4} onClick={handleFlightAdds} colorScheme="brand">
              Add Flights to Trip
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default PnrParser;
