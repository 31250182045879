import { EDocument, Document } from "@lato/common";
import React from "react";
import { type DropzoneOptions } from "react-dropzone";
import { UseFieldArrayReturn } from "react-hook-form";
import { MAX_DOC_NAME_LENGTH } from "../../utils/constants";
import useCustomDropzone from "../../utils/useCustomDropzone";
import { maxFileSizeMB } from "../../components/fileUpload/ImageUploader";
import Dropzone from "../../components/Dropzone";

interface DocumentDropzoneProps {
  addDocument: (doc: Partial<Document>) => void;
  closeModal: any;
}

const DocumentDropzone: React.FC<DocumentDropzoneProps> = ({ addDocument, closeModal }) => {
  const dropzoneOptions: DropzoneOptions = {
    // accept: [".pdf", ".doc", ".docx"],
    onDropAccepted: (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        // TODO: if it becomes possible to edit the document of a record, also revoke the object url!! (lik in TripSwiperSlide.tsx)
        const doc = {
          file,
          displayName: file.name.substring(0, file.name.lastIndexOf(".")).substring(0, MAX_DOC_NAME_LENGTH),
          type: EDocument.OTHERDOC,
          // Create a temporary local URL to preview the document
          s3url: URL.createObjectURL(file),
        };
        addDocument(doc);
      });
      // Close the modal
      closeModal();
    },
  };

  const dropzoneState = useCustomDropzone(dropzoneOptions);

  return (
    <Dropzone
      fileLimitMB={maxFileSizeMB}
      fileTypeDisplayName="files"
      resourceName="travel documents"
      dropzoneState={dropzoneState}
    />
  );
};
export default React.memo(DocumentDropzone);
