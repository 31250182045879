import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import { TokenResponse, googleLogout, hasGrantedAllScopesGoogle, useGoogleLogin } from "@react-oauth/google";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { FaRegCopy, FaRegTrashAlt } from "react-icons/fa";
import { FaAnglesRight } from "react-icons/fa6";
import { LiaSyncSolid } from "react-icons/lia";
import { v4 as uuid } from "uuid";
import UsersAPI from "../../../api/users.api";
import { Logo } from "../../../assets/icons/MiniDrawerIcons";
import { useMeContext } from "../../../stores/me-context";
import { GOOGLE_CALENDAR_QUERY_KEY, ME_QUERY_KEY } from "../../../utils/constants";
import { useGoogleToken } from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import Section from "../../layout/Section";
import { BetaBadge } from "../../layout/FeatureBadges";
import ConfirmModal from "../../modals/ConfirmModal";
import ENV from "../../../utils/env";

interface IntegrationsProps {}

const Integrations: React.FC<IntegrationsProps> = () => {
  const me = useMeContext();
  const queryClient = useQueryClient();
  const apiKeyDisclosure = useDisclosure();
  const confirmDisclosure = useDisclosure();

  const tokenResponse = localStorage.getItem(GOOGLE_CALENDAR_QUERY_KEY);

  const { mutateAsync: getGoogleToken, isPending: isLoadingUpdate } = useGoogleToken();

  const [zapierApiKey, setZapierApiKey] = useState<string>();
  const [loggedIn, setLoggedIn] = React.useState(!!tokenResponse);
  const [calendarIntegrationError, setCalendarIntegrationError] = React.useState<string | null>(null);

  const { mutateAsync: updateZapierApiKey } = useMutation({
    mutationFn: UsersAPI.updateZapierApiKey,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
    },
  });

  const generatedNewZapierApiKey = async () => {
    const apiKey = uuid();
    setZapierApiKey(`${me.id}.${apiKey}`);

    await updateZapierApiKey(apiKey);
  };

  const deleteApiKey = async () => {
    setZapierApiKey(undefined);
    await updateZapierApiKey(undefined);
  };

  const login = useGoogleLogin({
    flow: "auth-code",
    overrideScope: true,
    scope: "https://www.googleapis.com/auth/calendar.events.readonly",
    onSuccess: async (tokenResponse: TokenResponse) => {
      const hasAccess = hasGrantedAllScopesGoogle(
        tokenResponse,
        "https://www.googleapis.com/auth/calendar.events.readonly",
      );

      if (!hasAccess) {
        setCalendarIntegrationError("You need to grant access to your Google Calendar to continue.");
      }

      const googletoken = await getGoogleToken(tokenResponse.code);
      localStorage.setItem(GOOGLE_CALENDAR_QUERY_KEY, JSON.stringify(googletoken));

      setLoggedIn(true);
    },
  });

  return (
    <>
      <script src="https://apis.google.com/js/api.js"></script>
      <Section title="Integrations" h="auto" noHorizontalPadding position="relative">
        <Flex
          alignItems={{ base: "left", md: "center" }}
          overflow={"hidden"}
          flexDir={{ base: "column", md: "row" }}
          pb={{ base: "2", md: "0" }}
          justifyContent={"space-between"}
          mr={4}
          mb={8}
        >
          <Flex flexDir={"column"} w={{ base: "80%", sm: "auto" }}>
            <Heading size="md" mb={1} ml={7} display={"flex"}>
              <span className="mt-1">Google Calendar</span>
            </Heading>
            <Flex className="ml-7 my-2">
              <img
                className="w-[20px] ml-4 mt-0.5"
                src={"https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg"}
              />
              <FaAnglesRight className="mx-3 mt-1 text-gray-500" />
              <Logo mt={"3px"} width="30px" height="auto" color="brand.300" />
            </Flex>
            <Text fontSize={"12px"} width={"48em"} mt="1em" ml={7} color="realGray.400">
              Sync your Google Calendar to your Lato calendar. The Google Calendar integration is private. No one else
              in your company can access your Google Calendar through this integration.
              <br />
              You may need to occasionally re-authenticate your Google account. The Google Calendar integration uses
              cookies to maintain the sync between Google and ClickUp. Some web browsers, add-ons, or extensions may
              delete cookies required for the integration to work.
              <br />
              <br />
              Lato's use and transfer to any other app of information received from Google APIs will adhere to{" "}
              <a
                className="underline"
                target="_blank"
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                rel="noreferrer"
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </Text>
          </Flex>
          {loggedIn ? (
            <div className="grid space-y-1">
              <Button colorScheme="brand" variant={"outline"} _hover={{ bg: "transparent" }}>
                Connected
              </Button>

              <Button
                colorScheme="brand"
                variant={"outline"}
                bg="transparent"
                color="red.400"
                border="none"
                _hover={{ bg: "red.50" }}
                leftIcon={<FaRegTrashAlt />}
                onClick={confirmDisclosure.onOpen}
              >
                Remove
              </Button>
            </div>
          ) : (
            <div className="flex-col justify-end">
              {calendarIntegrationError && (
                <Text fontSize="sm" color="red">
                  {calendarIntegrationError}
                </Text>
              )}
              <Button colorScheme="brand" onClick={() => login()}>
                Sync your Google calendar
              </Button>
            </div>
          )}
        </Flex>
        {(ENV !== "production" ||
          ["c28cc494-e625-4d1b-8ab6-783c0aa19fe9", "5763a9ed-a60a-45e3-b8e8-b0f4c7d00715"].includes(me.id!)) && (
          <Flex
            alignItems={{ base: "left", md: "center" }}
            overflow={"hidden"}
            flexDir={{ base: "column", md: "row" }}
            pb={{ base: "2", md: "0" }}
            justifyContent={"space-between"}
            mr={4}
          >
            <Flex flexDir={"column"} w={{ base: "80%", sm: "auto" }} gap={2}>
              <Heading size="md" mb={1} ml={7} display={"flex"}>
                <span className="mt-1">Zapier</span>
                <BetaBadge className="w-11" />
              </Heading>
              <Flex className="ml-7 my-1">
                <img
                  className="w-[40px] mt-0.5"
                  src={"https://upload.wikimedia.org/wikipedia/commons/f/fd/Zapier_logo.svg"}
                />
                <LiaSyncSolid className="mx-3 mt-1 w-5 h-5 text-gray-500" />
                <Logo mt={"3px"} width="30px" height="auto" color="brand.300" />
              </Flex>
              <Text fontSize={"12px"} width={"18em"} mt="1em" ml={7} color="realGray.400">
                Do not share your API key with others. In order to protect the security of your account.
              </Text>
            </Flex>
            {zapierApiKey && <ApiKeyField apiKey={zapierApiKey} />}
            {me.zapierApiKey ? (
              <div className="grid space-y-1">
                <Tooltip
                  label="Because of security reasons, your api key can only be shown once. If you lost your key, generate a new one. Your old key will be deleted."
                  placement={"left"}
                >
                  <Button colorScheme="brand" variant={"outline"} _hover={{ bg: "transparent" }}>
                    Already generated
                  </Button>
                </Tooltip>
                <Button
                  colorScheme="brand"
                  variant={"outline"}
                  bg="transparent"
                  color="red.400"
                  border="none"
                  _hover={{ bg: "red.50" }}
                  leftIcon={<FaRegTrashAlt />}
                  onClick={apiKeyDisclosure.onOpen}
                >
                  Delete API key
                </Button>
              </div>
            ) : (
              <Button onClick={generatedNewZapierApiKey} colorScheme="brand">
                Generate API key
              </Button>
            )}
          </Flex>
        )}
      </Section>
      <ConfirmModal
        title="Are you sure you want to delete API key?"
        description="Your Zapier integration will be disabled."
        disclosure={apiKeyDisclosure}
        action={deleteApiKey}
      />{" "}
      <ConfirmModal
        title="Remove Google Calendar Integration"
        description="Are you sure you want to stop syncing your events to the Lato calendar?"
        disclosure={confirmDisclosure}
        action={() => {
          googleLogout();
          setLoggedIn(false);
          localStorage.removeItem(GOOGLE_CALENDAR_QUERY_KEY);
        }}
      />
    </>
  );
};
export default Integrations;

interface IntegrationsProps {}

interface ApiKeyFieldProps {
  apiKey: string;
}

const ApiKeyField: React.FC<ApiKeyFieldProps> = ({ apiKey }) => {
  const { hasCopied, onCopy } = useClipboard(apiKey);
  const bg = hasCopied ? "green.400" : "lightGray.200";

  const renderContent = () => {
    return (
      <Flex alignItems="center" justifyContent="space-between" alignSelf={"center"} width={"100%"}>
        <Text fontSize="sm" fontWeight="500" isTruncated>
          {hasCopied ? `Api Key copied to your clipboard` : apiKey}
        </Text>
        {!hasCopied && (
          <IconButton
            icon={<Icon as={FaRegCopy} boxSize={4} />}
            aria-label="Copy url to clipboard"
            variant="basic"
            colorScheme="gray"
            size="xs"
          />
        )}
      </Flex>
    );
  };

  return (
    <Box justifyContent="center" alignItems="center" w="45%" px={8}>
      <Tooltip label={"Your API key won't be shown again. Store it in a save place."}>
        <Flex
          p={3}
          h="45px"
          bg={bg}
          borderRadius="md"
          color={hasCopied ? "white" : "gray.500"}
          my="auto"
          justifyContent="center"
          alignContent={"center"}
          onClick={onCopy}
          _hover={{ cursor: "pointer" }}
        >
          {renderContent()}
        </Flex>
      </Tooltip>
    </Box>
  );
};
