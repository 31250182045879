import TasksStep from "../../../../components/trips/edit/tasks/TasksStep";
import { default as TripStepWrapper } from "../TripStepWrapper";

export const TasksTripRoute: React.FC = () => {
  const stepNumber = 4;

  return (
    <TripStepWrapper>
      <TasksStep stepNumber={stepNumber} />
    </TripStepWrapper>
  );
};

export default TasksTripRoute;
