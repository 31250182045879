import * as yup from "yup";
import { dateRangeToInputValue } from "../components/input/date/CustomUseRangeInput";
import { MAX_DOC_NAME_LENGTH } from "../utils/constants";
import { correctNumberOfDays } from "../utils/differenceInNumberOfDays";
import { generalValidationFields } from "./general-fields";
import { yupResolver } from "@hookform/resolvers/yup";
import { urlRegex } from "./regex";

export const loginValidationSchema = yup.object().shape({
  email: generalValidationFields.email,
  // Don't display a message about the length of the password when logging in!
  password: yup.string().required(),
});

export const emailValidationSchema = yup.object().shape({
  email: generalValidationFields.email,
});

export const passwordValidationSchema = yup.object().shape({
  password: generalValidationFields.password,
});

export const hotelAvailabilitySchema = yup.object().shape({
  from: yup.date().required(),
  to: yup.date(),
  // holder: yup.object().shape({
  //   last_name: yup.string().required("Last name is required"),
  //   first_name: yup.string().required("First name is required"),
  // }),
});

export const registerValidationSchema = yup.object().shape({
  email: generalValidationFields.email,
  password: generalValidationFields.password,
  // passwordConfirm: generalValidationFields.password.test({
  //   name: "password-confirmation",
  //   message: "Passwords do not match",
  //   test: function () {
  //     const { password, passwordConfirm } = this.parent;
  //     if (password && passwordConfirm !== password) {
  //       return false;
  //     }
  //     return true;
  //   },
  // }),
});

export const accountSetupValidationSchema = yup.object().shape({
  name: generalValidationFields.name,
  companyName: generalValidationFields.companyName,
});

export const brandValidationSchema = yup.object().shape({
  name: yup.string().min(1, "Name is required").max(128, "Max 128 chars allowed"),
  theme: yup.object().shape({
    primary_color: yup.string().required("Primary color is required"),
    secondary_color: yup.string().required("Secondary color is required"),
    // primaryColor: yup.string().matches(/^#(?:[0-9a-fA-F]{3}){2}$/),
  }),
});

export const companyValidationSchema = yup.object().shape({
  brands: yup.array().of(brandValidationSchema),
});

/******************* BEGIN TRIP VALIDATION SCHEMAS ************************/
const documentSchema = yup.object().shape({
  displayName: yup
    .string()
    .required("Document name is required.")
    .max(MAX_DOC_NAME_LENGTH, `Max. ${MAX_DOC_NAME_LENGTH} char. allowed`),
  type: yup.string().required("Document type is required"),
});
// const flightSchema = yup.object().shape({
//   departure_date_time: yup.string().nullable(),
//   arrival_date_time: yup.string().nullable(),
//   departureAirportIata: yup
//     .string()
//     .required("Required")
//     .length(3, "Should be 3 char."),
//   arrivalAirportIata: yup
//     .string()
//     .required("Required")
//     .length(3, "Should be 3 char."),
//   // .test({
//   //   name: "check_arrival_after_departure",
//   //   test: function (arrival_date_time) {
//   //     // If 1 of the dates is not defined, it is valid.
//   //     if (!arrival_date_time || !this.parent.departure_date_time) return true;
//   //     // If the arrival is before the departure, should return false!
//   //     return !isBefore(arrival_date_time, this.parent.departure_date_time);
//   //   },
//   //   message: "Arrival should be after departure.",
//   // }),
// });
const locationSchema = yup
  .object()
  .nullable()
  .shape({
    coordinates: yup.array().required("Latitude and latitude is required").length(2),
  });

const locationSchema2 = yup
  .object()
  .shape({
    // type: yup.string().matches(/Point/),
    coordinates: yup.array().length(2),
  })
  .nullable()
  .required("Please select a location")
  .typeError("Please select a location");

const translationFieldSchema = yup.object().shape({
  content: yup.string().max(5000, "Max 5000 char. allowed"),
});

const translationFieldSchemaNames = yup.object().shape({
  content: yup.string().required("Adding a title is required").max(5000, "Max 5000 char. allowed"),
});

export const poiValidationSchema = yup.object().shape({
  names: yup.array().of(translationFieldSchemaNames),
  type: yup.string().required("POI type is required"),
  location: yup.object().test("location", "Not a valid location.", (value: any) => {
    return value && value.coordinates && value.coordinates.length === 2;
  }),
});

const translationFieldRequiredSchema = yup.object().shape({
  content: yup.string().required("Title is required").max(5000, "Max 5000 char. allowed"),
});

const tripDescriptionTranslationFieldSchema = yup.object().shape({
  content: yup.string().max(2000, "Max 2000 char. allowed"),
});

export const hotelSchema = yup.object().shape({
  name: yup.string().min(1, "Name is required").max(128, "Max 128 chars allowed"),
  location: locationSchema2,
  descriptions: yup.array().of(translationFieldSchema),
  primaryEmail: yup.string().email("Invalid email format").max(255),
  phoneNumber: yup
    .string()
    .trim()
    .nullable()
    .matches(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g, {
      message: "Invalid phone number",
      excludeEmptyString: true,
    }),
  website: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(urlRegex, "Invalid website URL"),
});

export const libraryImageSchema = yup.object().shape({
  location: locationSchema2,
});

export const hotelSchemaInTrip = yup.object().shape({
  name: yup
    .string()
    // .min(1, "Name is required")
    .max(128, "Max 128 chars allowed"),
  descriptions: yup.array().of(translationFieldSchema),
  location: locationSchema,
  documents: yup.array().of(documentSchema),
  primaryEmail: yup.string().email("Invalid email format").max(255),
  phoneNumber: yup
    .string()
    .trim()
    .nullable()
    .matches(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g, {
      message: "Invalid phone number",
      excludeEmptyString: true,
    }),
  website: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(urlRegex, "Invalid website URL"),
});
const tripdaySchema = yup.object().shape({
  nrOfDays: yup.number().min(1),
  location: locationSchema,
  documents: yup.array().of(documentSchema),
  // ATTENTION: if you enable this, the validation in step 2 may be broken?
  // events: eventSchema,
});
export const eventSchema = yup.object().shape({
  descriptions: yup.array().of(translationFieldSchema),
  titles: yup.array().of(translationFieldSchema),
  location: locationSchema2,
  primaryEmail: yup.string().email("Invalid email format").max(255),
  phoneNumber: yup
    .string()
    .trim()
    .nullable()
    .matches(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g, {
      message: "Invalid phone number",
      excludeEmptyString: true,
    }),
  website: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(urlRegex, "Invalid website URL"),
  // dayIndex: yup.number().min(0),
});
export const textfieldSchema = yup.object().shape({
  descriptions: yup.array().of(translationFieldSchema),
  name: yup.string().max(64, "Max 64 char. allowed"),
  // dayIndex: yup.number().min(0),
});

export const destinationSchema = yup.object().shape({
  descriptions: yup.array().of(translationFieldSchema),
  titles: yup.array().of(translationFieldSchema),
});
export const eventSchemaInTrip = yup.object().shape({
  descriptions: yup.array().of(translationFieldSchema),
  titles: yup.array().of(translationFieldSchema),
  location: locationSchema,
  documents: yup.array().of(documentSchema),
  primaryEmail: yup.string().email("Invalid email format").max(255),
  phoneNumber: yup
    .string()
    .trim()
    .nullable()
    .matches(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g, {
      message: "Invalid phone number",
      excludeEmptyString: true,
    }),
  website: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(urlRegex, "Invalid website URL"),
  endTime: yup
    .string()
    .nullable()
    .test("endTime", "The end time can not be earlier then the start time", function (value) {
      if (value != undefined && this.parent.time != undefined) {
        const arrivalSplit = value.split(":");
        const departureSplit = this.parent.time.split(":");
        if (+arrivalSplit[0] < +departureSplit[0]) {
          return false;
        }
        if (+arrivalSplit[0] === +departureSplit[0] && +arrivalSplit[1] < +departureSplit[1]) {
          return false;
        }
      }
      return true;
    }),

  // dayIndex: yup.number().min(0),
});
export const transportationSchemaInTrip = yup.object().shape({
  type: yup.string().required(),
  descriptions: yup.array().of(translationFieldSchema),
  transportNumber: yup.string().max(50, "Max 50 char. allowed"),
  carrier: yup.string().max(50, "Max 50 char. allowed"),
  documents: yup.array().of(documentSchema),
  arrivalDayIndex: yup
    .string()
    .nullable()
    .test("arrivalDayIndex", "The arrival day can not be earlier then the departure day", function (value) {
      if (value != undefined && this.parent.departureDayIndex != undefined && this.parent.departureDayIndex > value) {
        return false;
      }
      return true;
    }),
  departureDayIndex: yup.string().nullable(),
  arrivalTime: yup
    .string()
    .nullable()
    .test("arrivalTime", "The arrival time can not be earlier then the departure time", function (value) {
      if (this.parent.arrivalDayIndex == undefined && this.parent.departureDayIndex == undefined) {
        return true;
      }
      const arrivalDayIndex = this.parent.arrivalDayIndex ?? 0;
      const departureDayIndex = this.parent.departureDayIndex ?? 0;
      if (departureDayIndex < arrivalDayIndex) return true;

      const departureTime = this.parent.departureTime;
      const arrivalTime = this.parent.arrivalTime;

      if (departureTime == undefined || arrivalTime == undefined) return true;

      const departureTimeSplit = departureTime.split(":");
      const arrivalTimeSplit = arrivalTime.split(":");

      if (+arrivalTimeSplit[0] < +departureTimeSplit[0]) {
        return false;
      } else {
        if (+arrivalTimeSplit[0] === +departureTimeSplit[0] && +arrivalTimeSplit[1] < +departureTimeSplit[1]) {
          return false;
        }
      }

      return true;
    }),
  //names: yup.array().of(translationFieldSchema),
});

export const transportationFlightSchemaInTrip = yup.object().shape({
  type: yup.string().required(),
  descriptions: yup.array().of(translationFieldSchema),
  transportNumber: yup.string().max(50, "Max 50 char. allowed"),
  carrier: yup.string().max(50, "Max 50 char. allowed"),
  documents: yup.array().of(documentSchema),
  arrival_date_time: yup
    .date()
    .nullable()
    .test("arrival_date_time", "The arrival time can not be earlier then the departure time", function (value) {
      if (this.parent.arrival_date_time == undefined || this.parent.departure_date_time == undefined) {
        return true;
      }
      const arrivalDate = new Date(this.parent.arrival_date_time);
      const departureDate = new Date(this.parent.departure_date_time);

      if (departureDate.getTime() <= arrivalDate.getTime()) return true;

      const departureTime = this.parent.departureTime;
      const arrivalTime = this.parent.arrivalTime;

      if (departureTime == undefined || arrivalTime == undefined) return true;

      const departureTimeSplit = departureTime.split(":");
      const arrivalTimeSplit = arrivalTime.split(":");

      if (+arrivalTimeSplit[0] < +departureTimeSplit[0]) {
        return false;
      } else {
        if (+arrivalTimeSplit[0] === +departureTimeSplit[0] && +arrivalTimeSplit[1] < +departureTimeSplit[1]) {
          return false;
        }
      }
    }),
  arrivalTime: yup
    .string()
    .nullable()
    .test("arrivalTime", "The arrival time can not be earlier then the departure time", function (value) {
      if (this.parent.arrival_date_time == undefined || this.parent.departure_date_time == undefined) {
        return true;
      }
      const arrivalDate = new Date(this.parent.arrival_date_time);
      const departureDate = new Date(this.parent.departure_date_time);

      if (departureDate.getTime() <= arrivalDate.getTime()) return true;

      const departureTime = this.parent.departureTime;
      const arrivalTime = this.parent.arrivalTime;

      if (departureTime == undefined || arrivalTime == undefined) return true;

      const departureTimeSplit = departureTime.split(":");
      const arrivalTimeSplit = arrivalTime.split(":");

      if (+arrivalTimeSplit[0] < +departureTimeSplit[0]) {
        return false;
      } else {
        if (+arrivalTimeSplit[0] === +departureTimeSplit[0] && +arrivalTimeSplit[1] < +departureTimeSplit[1]) {
          return false;
        }
      }

      return true;
    }),
  //names: yup.array().of(translationFieldSchema),
});

export const generalFlights = yup.object().shape({
  flights: yup.array().of(transportationFlightSchemaInTrip),
});

const privateTripdaySchema = yup.object().shape({
  events: yup.array().of(eventSchemaInTrip),
  hotels: yup.array().of(hotelSchemaInTrip),
  transportations: yup.array().of(transportationSchemaInTrip),
});
const passportSchema = yup.object().shape({
  number: yup.string().max(16, "Max 16 char. allowed"),
  expiration_date: yup
    .date()
    .transform((value, originalValue) => {
      const date = originalValue ? new Date(originalValue) : null;
      return date;
    })
    .nullable()
    .min(new Date(1900, 1, 1), "Min. is 01/01/1900")
    .max(new Date(2100, 1, 1), "Max. is 01/01/2100"),
});

const generalUserTrip = yup.object().shape({
  notes: yup.array(),
  external_ref: yup.string().max(64, "Max. 64 char. allowed"),
});

const priceSchema = yup.object().shape({
  price: yup.string().max(14, "Price is too large").nullable(),
  currencyIso: yup.string(),
  currency: yup.object().shape({ iso: yup.string(), name: yup.string(), symbol: yup.string() }),
});
export const priceUserTrip = yup.object().shape({ userTrips: yup.array().of(priceSchema) });
export const priceTrip = yup.object().shape({
  priceDescriptions: yup.array().of(translationFieldSchema),
  includeds: yup.array().of(translationFieldSchema),
  notIncludeds: yup.array().of(translationFieldSchema),
});

// const countrySchema = yup.object().nullable();
//.required("Country is required.");
const dateSchema = yup.string().nullable();
// An array of validation schemas for the different steps
export const tripValidationSchemas = [
  // Validation schema for step 1
  yup.object().shape({
    titles: yup.array().of(translationFieldRequiredSchema),
    descriptions: yup.array().of(tripDescriptionTranslationFieldSchema),
    client_name: yup.string().max(64),
    // country: countrySchema,
    tripdocs: yup.array().of(documentSchema),
    //flights: yup.array().of(flightSchema),
    userTrips: yup.array().of(generalUserTrip),
  }),
  // Validation schema for step 2
  yup.object().shape({
    start_date: dateSchema,
    end_date: dateSchema,
    tripdays: yup.array().of(tripdaySchema),
    // .test({
    //   name: "check_tripdays_length",
    //   test: function (tripdays: any) {
    //     const { start_date, end_date, sample } = this.parent;
    //     const errorMessage = correctNumberOfDays(tripdays, start_date, end_date, sample);
    //     return !errorMessage
    //       ? true
    //       : this.createError({
    //           message: errorMessage,
    //           // path: 'fieldName', // Fieldname
    //         });
    //   },
    // }),
  }), // Validation schema for step 3
  yup.object().shape({
    tripdays: yup.array().of(privateTripdaySchema),
  }), // Validation schema for step 4
  yup.object().shape({
    price: priceUserTrip,
    priceDescriptions: yup.array().of(translationFieldSchema),
    includeds: yup.array().of(translationFieldSchema),
    notIncludeds: yup.array().of(translationFieldSchema),
  }),
];

/******************* END TRIP VALIDATION SCHEMAS ************************/

/** START CONTACT VALIDATION SCHEMA */
const addressValidationSchema = yup.object().shape({
  country: yup.string().max(64, "Max 64 char. allowed"),
  city: yup.string().max(16, "Max 16 char. allowed"),
  zipCode: yup.string().max(16, "Max 16 char. allowed"),
  streetAddress: yup.string().max(256, "Max 256 char. allowed"),
});
export const personalInfoValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .max(64, "Max 64 char. allowed")
    .min(2, "Name has to be 2 char. or longer"),

  phoneNumber: yup
    .string()
    .trim()
    .nullable()
    .matches(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g, {
      message: "Invalid phone number",
      excludeEmptyString: true,
    }),
});
export const termsValidationSchema = yup.object().shape({
  brands: yup.array().of(yup.object().shape({ terms: yup.string().max(64000, "Max 64000 characters allowed") })),
});
export const contactValidationSchema = yup.object().shape({
  last_name: yup
    .string()
    .required("Last name is required")
    .max(64, "Max 64 char. allowed")
    .min(2, "Last name has to be 2 char. or longer"),
  first_name: yup
    .string()
    .required("First name is required")
    .max(64, "Max 64 char. allowed")
    .min(2, "First name has to be 2 char. or longer"),
  primaryEmail: yup.string().email("Invalid email format").max(255),
  loyaltyProgramRelations: yup.array().of(
    yup.object().shape({
      loyaltyProgram: yup.object({
        id: yup.number().required("Loyalty program is required"),
      }),
      frequent_flyer_number: yup.string().required("Loyalty program number is required"),
    }),
  ),
  phoneNumber: yup
    .string()
    .trim()
    .nullable()
    .matches(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g, {
      message: "Invalid phone number",
      excludeEmptyString: true,
    }),
  gender: yup.string().nullable(),
  contact_type: yup.string(),
  passport: passportSchema,
  date_of_birth: yup
    .date()
    .nullable()
    .min(new Date(1900, 1, 1), "Invalid date")
    .max(new Date(), "Invalid date"),
  // address: addressValidationSchema,
});

/** END CONTACT VALIDATION SCHEMA */

export const contactGroupValidationSchema = yup.object().shape({
  name: generalValidationFields.name,
});

export const sendTripMailValidationSchema = yup.object().shape({
  mailDescriptions: yup.array().of(translationFieldSchema),
});

/** START TASKS VALIDATION SCHEMA */

export const taskValidationSchema = yup.object().shape({
  title: yup.string().required("Title is required").max(64, "Max 64 char. allowed").min(2, "Min 2. char expected"),
});

/** END TASKS VALIDATION SCHEMA */
