import { DARK_BLUE_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_LIGHT, SECONDARY_COLOR, TERNARY_COLOR } from "@lato/common";

const colors = {
  brand: {
    50: "#e3fbf6",
    100: "#c4ece2",
    200: "#a1ddcd",
    300: "#7ed0bf",
    400: "#5bc1b2",
    500: PRIMARY_COLOR,
    600: "#328382",
    700: "#276668",
    800: "#1c494f",
    900: "#112c35",
  },
  lightGray: {
    50: "#ffffff",
    100: "#f9f9f9",
    200: "#f2f2f2",
    300: "#ececec",
    400: "#e6e6e6",
    500: "#dfdfdf",
    600: "#d9d9d9",
    700: "#d3d3d3",
    800: "#cccccc",
    900: "#c6c6c6",
  },
  realGray: {
    50: "#f0f1f3",
    100: "#e8e8e8",
    200: "#d1d1d1",
    300: "#bbbbbb",
    400: "#a4a4a4",
    500: "#8d8d8d",
    600: "#767676",
    700: "#606060",
    800: "#494949",
    900: "#323232",
  },
  lato: {
    primaryColor: PRIMARY_COLOR,
    primaryColorDark: TERNARY_COLOR,
    primaryColorLight: PRIMARY_COLOR_LIGHT,
    secondaryColor: SECONDARY_COLOR,
    darkBlue: DARK_BLUE_COLOR,
    bg: "rgb(247,252,252)",
  },
};

export default colors;
