import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { UseFormGetValues } from "react-hook-form";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { TripDay, calculateTripdayDate } from "@lato/common";
import { DAY_BY_DAY_STEP_TRIP_QUERY_KEY } from "../../../../../../utils/constants";
import { getRelationMap, useSplitTrip } from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import OptionsDropdown, {
  DeleteOptionsDropdownItem,
  DuplicateOptionsDropdownItem,
  MoveLeftOptionsDropdownItem,
  MoveLeftRightDropdownItem,
  MoveTripdayOptionsDropdownItem,
} from "../../../../../CRUD/OptionsDropdown";
import { calculateDayNumber } from "../../../../../../features/editTrip/overviewStep/TripsOverviewTable";
import SaveToLibraryOption from "./SaveToLibraryOption";

interface EventOptionsProps {
  handleDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleDuplicate: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleMove: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, newIndex: string) => void;
  handleMoveLeft?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleMoveRight?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type: "accommodation" | "activity" | "transportation";
  getEvent: UseFormGetValues<any>;
  currentTripdayIndex: number;
  hideMoveTripday: boolean;
  showLeftMove: boolean;
  showRightMove: boolean;
  showDuplicate: boolean;
  hideLastTripday: boolean;
  className: string;
}

const EventOptions: React.FC<EventOptionsProps> = ({
  handleDelete,
  handleDuplicate,
  handleMove,
  handleMoveLeft,
  handleMoveRight,
  type,
  getEvent,
  currentTripdayIndex,
  hideMoveTripday,
  showLeftMove,
  showRightMove,
  showDuplicate,
  hideLastTripday,
  className,
}) => {
  const [moveMenuIsOpen, setMoveMenuIsOpen] = React.useState(false);
  const { trip: tripFromStore } = useTripFormStore();

  const { data: trip, isLoading: isLoadingTrip } = useSplitTrip(
    tripFromStore?.id ?? "",
    DAY_BY_DAY_STEP_TRIP_QUERY_KEY,
    getRelationMap[DAY_BY_DAY_STEP_TRIP_QUERY_KEY],
  );

  const getTripdayValues = React.useCallback(() => {
    let lastTripDayIndex = 1;
    return trip?.tripdays
      .map((tripday: TripDay, i: number) => {
        if (i === trip?.tripdays.length - 1 && hideLastTripday) {
          return;
        }
        let dayValue = "";
        const realDayNumber = calculateDayNumber(i, trip.tripdays);
        const td_start_date = trip.start_date && calculateTripdayDate(realDayNumber, trip.start_date);
        let tripday_start_date = td_start_date && td_start_date.substring(0, 11);

        if (tripday.nrOfNights > 1) {
          const coherentTripday = lastTripDayIndex + (tripday.nrOfNights - 1);
          const tr_start_dateDate = td_start_date && new Date(td_start_date);
          const coherentTripdayDate =
            tr_start_dateDate &&
            new Date(tr_start_dateDate.setDate(tr_start_dateDate.getDate() + (tripday.nrOfNights - 1)));

          dayValue = `Move to day ${lastTripDayIndex} - ${coherentTripday}`;
          if (coherentTripdayDate) {
            tripday_start_date += ` - ${coherentTripdayDate.toDateString().substring(0, 11)}`;
          }
        } else {
          dayValue = `Move to day ${lastTripDayIndex}`;
        }
        lastTripDayIndex += tripday.nrOfNights;

        const isDisabled = type === "accommodation" && (tripday.hotels?.length ?? 0) >= 3;
        return {
          value: tripday,
          isDisabled,
          text: (
            <Tooltip label={isDisabled && "This day already has 3 hotels"}>
              <Flex justifyContent={"space-between"} flexDir="column" alignItems={"start"} w={"100%"}>
                <Text fontWeight={600}>{dayValue} </Text>
                <Text color={"gray.500"} fontSize="xs">
                  {tripday_start_date}
                </Text>
              </Flex>
            </Tooltip>
          ),
        };
      })
      .filter((tripday: any, i: number) => i !== currentTripdayIndex && tripday);
  }, [trip]);

  return (
    <div className={className}>
      <OptionsDropdown>
        {/* <OptionsDropdownItem
                icon={<EditIcon boxSize={3} />}
                onClick={(e) => onEdit(e, row.index)}
                name={`Edit ${resourceName}`}
                isDisabled={!canEdit(row)}
              />
              <OptionsDropdownItem
                icon={<Icon as={Share} />}
                onClick={(e) => {
                  onCopyViewUrl();
                  addToast(toast, {
                    title: "Copied link to your clipboard.",
                    status: "success",
                    description: `You can now share this ${resourceName} with your clients/travelers.`,
                  });
                }}
                name="Get traveler link"
              /> */}
        {type !== "transportation" && <SaveToLibraryOption type={type} getValues={getEvent} />}
        {showDuplicate && <DuplicateOptionsDropdownItem onClick={handleDuplicate} resourceName={type} />}
        {!hideMoveTripday && (
          <MoveTripdayOptionsDropdownItem
            currentTripdayIndex={currentTripdayIndex}
            resourceName={type}
            onClick={() => setMoveMenuIsOpen(true)}
          />
        )}
        {showLeftMove && <MoveLeftOptionsDropdownItem resourceName={type} onClick={handleMoveLeft} />}
        {showRightMove && <MoveLeftRightDropdownItem resourceName={type} onClick={handleMoveRight} />}

        <DeleteOptionsDropdownItem onClick={handleDelete} resourceName={type} />
      </OptionsDropdown>
      <Menu
        isOpen={moveMenuIsOpen}
        closeOnBlur={true}
        onClose={() => {
          setMoveMenuIsOpen(false);
        }}
      >
        <MenuButton
          as={IconButton}
          variant="basic"
          colorScheme="gray"
          aria-label="table-options"
          position="absolute"
          pointerEvents="none"
          // display="none"
        />
        <MenuList overflowY={"auto"} maxH={"16em"} className={"menu-list-scroll-colors"} key={"tripday-menu-list"}>
          {getTripdayValues()?.map((tripday: any, i: number) => (
            <MenuItem
              key={tripday.value + i}
              value={tripday.value}
              isDisabled={tripday.isDisabled}
              onClick={(e) => {
                handleMove(e, tripday.value);
              }}
            >
              {tripday.text}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};
export default React.memo(EventOptions);
