import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Text,
} from "@chakra-ui/react";
import {
  AiOutlineBook,
  AiOutlineCi,
  AiOutlineDesktop,
  AiOutlineFieldTime,
  AiOutlineFileText,
  AiOutlineGlobal,
  AiOutlineMessage,
  AiOutlinePlayCircle,
  AiOutlineSetting,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import { FaRegFilePdf, FaRegLightbulb } from "react-icons/fa";
import { MdOutlineUpdate, MdOutlineRateReview } from "react-icons/md";
import { FaRegRectangleList } from "react-icons/fa6";
import { GoProjectRoadmap, GoVideo } from "react-icons/go";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoIosContact } from "react-icons/io";
import { IoExtensionPuzzleOutline, IoTodayOutline } from "react-icons/io5";
import ENV from "../../utils/env";
import ResourceCenterButton from "./ResourceCenterButtons";
import React from "react";
import {
  CANNY_CHANGELOG_URL,
  CANNY_URL,
  DEMO_BOOKING_LINK,
  HELLO_MAIL,
  HELP_CENTER_LINK,
  VIDEOS_URL,
  WEBINAR_URL,
} from "@lato/common";

const ResourceCenter = ({ isOpen, onClose, handleOptionClick, isProductTour, closeAllPopovers, user }) => {
  //logic to login user to canny when clicking link via resource center
  React.useEffect(() => {
    // Download Canny SDK
    (function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          const f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
          e.type = "text/javascript";
          e.async = true;
          e.src = "https://canny.io/sdk.js";
          f.parentNode.insertBefore(e, f);
        }
      }
      if (typeof w.Canny !== "function") {
        const c = function () {
          c.q.push(arguments);
        };
        c.q = [];
        w.Canny = c;
        if (d.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })(window, document, "canny-jssdk", "script");

    // Use the Canny SDK to identify the current user of your website
    window.Canny("identify", {
      appID: "667a7ea6e2807f6e1a4a4b06",
      user: {
        email: user.email,
        name: user.name,
        id: user.id,
      },
    });
  }, [user]);
  //open chat of simplebase
  const openChatWidget = () => {
    // The Simplebase script widget is added in the body tag of the index.html file.
    // It exposes a simplebase object in the window object.
    const simplebase = (window as any).simplebase;
    if (simplebase) {
      if (typeof simplebase.updateUserAttributes === "function") {
        // Update user attributes
        simplebase.updateUserAttributes({
          email: user.email,
          name: user.name,
        });
      }
      const simplebaseChatLauncherIframeSelector = ".sb-chat-launcher-iframe";
      const simplebaseChatLauncherIframe = document.querySelector<HTMLElement>(simplebaseChatLauncherIframeSelector);
      if (simplebaseChatLauncherIframe) {
        // Make the launcher visible again. It is set invisible by default in the root index.css file.
        simplebaseChatLauncherIframe.style.display = "block";
      }
      const simplebaseChatWidgetIframeSelector = ".sb-chat-widget-iframe";
      const simplebaseChatWidgetIframe = document.querySelector<HTMLElement>(simplebaseChatWidgetIframeSelector);
      if (simplebaseChatWidgetIframe) {
        // In the root index.css file, the z-index of the widget is set to 1000.
        const isOpen = simplebaseChatWidgetIframe?.classList.contains("is-visible");
        if (isOpen) {
          // Close the widget
          if (typeof simplebase.widget.hide === "function") {
            // Open the widget
            simplebase.widget.hide();
          }
        } else {
          if (typeof simplebase.widget.show === "function") {
            // Open the widget
            simplebase.widget.show();
          }
        }
      }
    }
  };
  //all the buttons for the main ResourceCenter
  const GeneralData = [
    {
      icon: AiOutlineBook,
      text: "Help Center",
      route: HELP_CENTER_LINK,
      category: "Resource Center",
      action: () => window.open(HELP_CENTER_LINK, "_blank"),
    },
    {
      icon: AiOutlineMessage,
      text: "Chat",
      category: "Support",
      action: openChatWidget,
    },

    {
      icon: MdOutlineUpdate,
      text: "Updates",
      route: CANNY_CHANGELOG_URL,
      category: "Resource Center",
      action: () => window.open(CANNY_CHANGELOG_URL, "_blank"),
    },
    // {
    //   icon: GoVideo,
    //   text: "Guides",
    //   route: HELP_CENTER_LINK + "/category/col-itinerary-builder",
    //   category: "Resource Center",
    //   action: () => window.open(HELP_CENTER_LINK + "/category/col-itinerary-builder", "_blank"),
    // },
    {
      icon: AiOutlineFieldTime,
      text: "Webinars",
      route: WEBINAR_URL,
      category: "Resource Center",
      action: () => window.open(WEBINAR_URL, "_blank"),
    },
    // { icon: GoVideo, text: "Product Tour", category: "Resource Center" },

    // {
    //   icon: AiOutlineVideoCamera,
    //   text: "Video's",
    //   route: VIDEOS_URL,
    //   category: "Resource Center",
    //   action: () => window.open(VIDEOS_URL, "_blank"),
    // },
    {
      icon: AiOutlineDesktop,
      text: "Demo",
      route: DEMO_BOOKING_LINK,
      category: "Support",
      action: () => window.open(DEMO_BOOKING_LINK, "_blank"),
    },
    {
      icon: FaRegLightbulb,
      text: "Request a Feature",
      route: "mailto:" + HELLO_MAIL,
      category: "Feedback",
      action: () => window.open("mailto:" + HELLO_MAIL, "_blank"),
    },
    // {
    //   icon: GoProjectRoadmap,
    //   text: "Roadmap",
    //   route: CANNY_URL,
    //   category: "Resource Center",
    //   action: () => window.open(CANNY_URL, "_blank"),
    // },

    {
      icon: MdOutlineRateReview,
      text: "Write a Review",
      category: "Feedback",
      route: "https://g.page/r/CUyohQ879f6IEBE/review",
      action: () => window.open("https://g.page/r/CUyohQ879f6IEBE/review", "_blank"),
    },
  ];
  //all the buttons for the ResourceCenter for Tour guides
  const TourGuideData = [
    {
      icon: AiOutlinePlayCircle,
      productVideoUrl: "https://embed.app.guidde.com/playbooks/dFUx67VVfH3gp2z1cWUBoP",
      text: "General Step Trip",
    },
  ];
  const categorizeData = (data) => {
    const categories = {};
    data.forEach((item) => {
      const category = item.category || "Uncategorized";
      if (!categories[category]) {
        categories[category] = [];
      }
      categories[category].push(item);
    });
    return categories;
  };

  const GeneralDataWithCategories = categorizeData(GeneralData);

  const ButtonData = isProductTour ? TourGuideData : GeneralDataWithCategories;

  return (
    <>
      <Flex bg="#004354" position="relative" right={isProductTour ? 335 : 200} top={isProductTour ? -55 : 5}>
        <div className="popover-style">
          <Popover isOpen={isOpen} onClose={onClose} autoFocus={false} closeOnBlur={true} placement="right">
            <PopoverContent bg="white" borderRadius="10px">
              <PopoverHeader
                bg="lato.primaryColor"
                borderRadius=" 10px 10px 0px 0px"
                pt={5}
                pb={10}
                px={9}
                position="relative"
                zIndex={2}
              >
                <PopoverCloseButton color="white" position="absolute" top="15px" right="15px" fontSize="10px" />
                <Box mb={6}>
                  <Text fontSize="2xl" fontWeight="bold" color="white">
                    Hi {user.name.split(" ")[0]}!
                  </Text>
                  <Text fontSize="md" color="white">
                    How may we help you today?
                  </Text>
                </Box>
              </PopoverHeader>
              <Box position="relative" zIndex={1000} borderTop="none" marginTop="-13px">
                <PopoverBody
                  m={4}
                  px={0}
                  border="1px solid #e2e8f0"
                  bg="white"
                  borderRadius="10px"
                  boxShadow={"0 2px 4px rgba(0, 0, 0, 0.1)"}
                  z-index={20}
                  mt="-35px"
                  mb="25px"
                >
                  {isProductTour ? (
                    <Box textAlign="center" mb={4}>
                      <Text fontSize="lg" fontWeight="bold" mb={2} ml={1} color="lato.darkBlue">
                        Product Tours
                      </Text>
                      <ResourceCenterButton ButtonData={ButtonData} />
                    </Box>
                  ) : (
                    Object.entries(ButtonData).map(([category, buttons]) => (
                      <Box key={category} mt={2} ml={3} mr={3}>
                        <Text fontSize="lg" fontWeight="bold" mb={2} ml={2} color="lato.darkBlue">
                          {category}
                        </Text>
                        <ResourceCenterButton ButtonData={buttons} closeAllPopovers={closeAllPopovers} />
                      </Box>
                    ))
                  )}
                </PopoverBody>
              </Box>
            </PopoverContent>
          </Popover>
        </div>
      </Flex>
    </>
  );
};

export default ResourceCenter;
