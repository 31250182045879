import { Box, Button, ButtonGroup, Divider, Flex, Text, useToast } from "@chakra-ui/react";
import { capitalizeFirstLetter } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StripeAPI from "../../../api/stripe.api";
import { prettyPrintDate } from "../../../utils/date";
import { usePlanUpdatePreview } from "../../../utils/query-helpers/reactQueryHooks";
import { handleSubmission } from "../../../utils/toErrorMap";
import CustomModal from "../../layout/CustomModal";
import { Subscription } from "./checkout/Checkout";
import { ME_QUERY_KEY } from "../../../utils/constants";

interface UpdatePreviewModalProps {
  isOpen: any;
  onClose: any;
  subscription: Subscription;
  proration: boolean;
  newPriceId: string;
  newPrice: number;
  oldSubscription: Subscription;
}

const UpdatePreviewModal: React.FC<UpdatePreviewModalProps> = ({
  isOpen,
  onClose,
  subscription,
  proration,
  newPriceId,
  newPrice,
  oldSubscription,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: preview,
    isLoading: isLoadingPreview,
    isFetching: isFetchingPreview,
    error: errorLoadingPreview,
  } = usePlanUpdatePreview(
    { priceId: newPriceId, nrOfUsers: subscription.users, proration },
    {
      enabled: isOpen,
    },
  );

  const { mutateAsync: updateSubscription, isPending: isUpdatingSubscription } = useMutation({
    mutationFn: (priceId: string) =>
      StripeAPI.changeSubscription({
        priceId,
        nrOfUsers: subscription.users,
        proration,
      }),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
      navigate(0);
    },
  });

  const successCallback = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleUpdateSubscription = async () => {
    await handleSubmission({
      successMessage: `updated subscription`,
      failMessage: `updating subscription`,
      apiCall: updateSubscription(newPriceId),
      successCallback,
      toast,
    });
  };
  const period_start = preview && prettyPrintDate(new Date(preview.period_start), undefined, "long");
  const period_end = preview && prettyPrintDate(new Date(preview.period_end), undefined, "long");
  const nrOfMonths = subscription.yearly ? 12 : 3;
  const totalPlanPrice = newPrice * nrOfMonths;
  const creditLines =
    preview &&
    preview.prices
      .filter((pr: any) => pr.description.includes("Unused") || pr.description.includes("Remaining"))
      .map((pr: any) => ({
        ...pr,
        amount: pr.amount > 0 ? pr.amount - totalPlanPrice : pr.amount,
        description: pr.description.includes("Unused")
          ? `${oldSubscription.users} x ${capitalizeFirstLetter(oldSubscription.plan)} plan: now - ${period_end}`
          : `${subscription.users} x ${capitalizeFirstLetter(subscription.plan)} plan: ${period_start} - now`,
      }));
  const totalCredits = creditLines && creditLines.reduce((sum: number, pr: any) => sum + pr.amount, 0);

  return (
    <CustomModal title="Review plan changes" isOpen={isOpen} onClose={onClose} size="xl">
      {isFetchingPreview ? (
        <div>Loading...</div>
      ) : errorLoadingPreview || !preview ? (
        <div>Error: could not fetch subscription summary.</div>
      ) : (
        <>
          <Box mt={3} mb={7}>
            {proration
              ? `The changes will take effect immediately and are prorated accordingly. You will receive the following extra one-time charge for your current billing cycle (${period_start} - ${period_end}).`
              : `The changes will take effect on the first day of your next billing cycle (${period_start} - ${period_end}). At that moment you will receive the following charge.`}
          </Box>

          <InvoiceLineItem
            lineItem={{
              prefixAmount: `€ ${newPrice} x ${`${nrOfMonths} months`} = `,
              amount: totalPlanPrice,
              description: `${subscription.yearly ? "Yearly" : "Quarterly"} subscription cost`,
            }}
          />
          {/* TODO: add tax to the total? */}
          <Divider borderWidth="1px" />
          <InvoiceLineItem
            lineItem={{
              amount: totalCredits,
              description: "Total credits",
            }}
          />
          {totalCredits < 0 &&
            creditLines.map((pr: any, i: number) => (
              <InvoiceLineItem key={`invoice-line-${i}`} lineItem={{ ...pr }} ml={4} mr={16} color="gray.400" />
            ))}
          <Divider borderWidth={2} />
          <InvoiceLineItem
            lineItem={{
              amount: preview.newPrice,
              description: "Total excluding TAX",
            }}
            fontWeight={500}
          />
          <InvoiceLineItem
            lineItem={{
              amount: preview.newPrice + (preview.tax || 0),
              description: "Total",
            }}
            fontWeight={500}
          />

          <ButtonGroup display="flex" justifyContent={"end"} mt={7}>
            <Button onClick={onClose} colorScheme="red" variant="outline">
              Cancel
            </Button>
            <Button onClick={handleUpdateSubscription} isLoading={isUpdatingSubscription} colorScheme="brand">
              Purchase
            </Button>
          </ButtonGroup>
        </>
      )}
    </CustomModal>
  );
};
export default React.memo(UpdatePreviewModal);

const InvoiceLineItem = ({ lineItem, ...props }: any) => {
  const { amount, description, prefixAmount, postfixAmount } = lineItem;
  const roundedAmount = amount.toFixed(2);
  return (
    <Flex justifyContent={"space-between"} my={2} {...props}>
      <Text fontSize="md">{description}</Text>
      <Text fontSize="md">
        {prefixAmount}
        {roundedAmount <= 0 ? `- € ${Math.abs(roundedAmount)}` : `€ ${roundedAmount}`}
        {postfixAmount}
      </Text>
    </Flex>
  );
};
