import { Button, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import React from "react";
import { type DropzoneOptions } from "react-dropzone";
import ContactAPI from "../api/contacts.api";
import { addToast } from "../utils/addToast";
import useCustomDropzone from "../utils/useCustomDropzone";
import FullScreenSpinner from "./FullScreenSpinner";
import Dropzone from "./Dropzone";
import CustomModal from "./layout/CustomModal";

const UploadCsv: React.FC = () => {
  const disclosure = useDisclosure();
  const downloadLinkRef = React.useRef<HTMLAnchorElement>(null);

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: uploadCsv } = useMutation({
    mutationFn: (file: any) => ContactAPI.uploadCsvFile(file),
    onSuccess: () => {
      addToast(toast, {
        title: "Contacts imported successfully",
        status: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["get-contacts"],
      });
    },
    onError: () => {
      addToast(toast, {
        title: "Unable to import contacts, try again later",
        status: "error",
      });
    },
  });

  const dropzoneOptions: DropzoneOptions = {
    accept: [".csv", ".txt"],
    onDropAccepted: (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        uploadCsv(formData);
      });
      disclosure.onClose();
    },
  };

  const handleDownload = () => {
    const codeContent = `first_name(required);last_name(required);Gender;mail;phone;date_of_birth;passNum;passport.expiration_date;country;city;zipcode;streetAddress;CRM_ID
    John;Doe;M;john.doe@gmail.com;324789658;11/05/1998;1233-45865;11/05/2030;BE;Ghent;9000;OneStreet 42;
    Mary;Jane Lou;F;Mary.JaneLou@gmail.com;324749165;17/08/1976;1233-45865;17/08/2030;BE;Ghent;9000;TwoStreet 32;
    Jan;Deeg;;FancyCompany@gmail.com;324749165;;;;BE;Ghent;9000;ThreeStreet 32;`;

    // Create a Blob
    const blob = new Blob([codeContent], { type: "text/plain;charset=utf-8" });

    if (downloadLinkRef.current) {
      // Set the href and download attributes on the hidden anchor element
      downloadLinkRef.current.href = URL.createObjectURL(blob);
      downloadLinkRef.current.download = "contacts_upload_template.csv";

      // Trigger the download
      downloadLinkRef.current.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(downloadLinkRef.current.href);
    }
  };

  const dropzoneState = useCustomDropzone(dropzoneOptions);

  return (
    <>
      <Button type="button" colorScheme="brand" mx={3} onClick={disclosure.onOpen} variant={"outline"}>
        Import csv
      </Button>

      <CustomModal
        title={`Upload Contacts`}
        onClose={disclosure.onClose}
        isOpen={disclosure.isOpen}
        size="lg"
        colorScheme="brand"
      >
        <Dropzone
          fileLimitMB={10}
          fileTypeDisplayName="files"
          resourceName="contacts csv file"
          dropzoneState={dropzoneState}
        />
        <Flex justifyContent={"center"} my={2}>
          <Button type="button" colorScheme="brand" mx={3} id="template-button" onClick={handleDownload}>
            Download Template
          </Button>
          <a ref={downloadLinkRef} style={{ display: "none" }} href={"blank"}>
            content
          </a>
        </Flex>
      </CustomModal>
    </>
  );
};

export default UploadCsv;
