import TasksStep from "../../../components/trips/edit/tasks/TasksStep";
import SampleStepWrapper from "../SampleStepWrapper";

export const TasksSampleRoute: React.FC = () => {
  const stepNumber = 4;

  return (
    <SampleStepWrapper>
      <TasksStep stepNumber={stepNumber} />
    </SampleStepWrapper>
  );
};

export default TasksSampleRoute;
