function getPlatform() {
  // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
  if (
    typeof (navigator as any)["userAgentData"] !== "undefined" &&
    (navigator as any)["userAgentData"] != null &&
    (navigator as any)["userAgentData"].platform != null
  ) {
    return (navigator as any)["userAgentData"].platform.toLowerCase();
  }
  // Deprecated but still works for most of the browser
  if (typeof navigator.platform !== "undefined") {
    if (typeof navigator.userAgent !== "undefined" && /android/.test(navigator.userAgent.toLowerCase())) {
      // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
      return "android";
    }
    return navigator.platform.toLowerCase();
  }
  return "unknown";
}
export default getPlatform;

export const isOSX = () => /mac/.test(getPlatform()); // Mac desktop
export const isIOS = () => ["iphone", "ipad", "ipod"].indexOf(getPlatform()); // Mac iOs
export const isApple = () => isOSX() || isIOS(); // Apple device (desktop or iOS)
export const isWindows = () => /win/.test(getPlatform()); // Windows
export const isAndroid = () => /android/.test(getPlatform()); // Android
export const isLinux = () => /linux/.test(getPlatform()); // Linux
