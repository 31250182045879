import { Box } from "@chakra-ui/react";
import React from "react";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import ErrorCardView from "../../components/layout/ErrorCardView";
import { useMeContext } from "../../stores/me-context";
import { useTripsStatistics } from "../../utils/query-helpers/reactQueryHooks";
import { StatType, StatisticsSection } from "../../features/dashboard/Stats";

interface statisticProps {}

export const StatisticOverviewProduction: React.FC<statisticProps> = () => {
  const user = useMeContext();

  const { data: statData, isLoading: isLoadingStats, error: statsError } = useTripsStatistics({});

  if (isLoadingStats) return <FullScreenSpinner />;
  if (statsError) return <ErrorCardView title="Could not load trips" />;

  const generalStats: StatType[] = [
    { label: "Trips", value: statData?.total.count },
    { label: "Booked trips", value: statData?.total.booked },
    {
      label: "Booking rate",
      value: statData?.total.bookingRate + "%",
    },
  ];
  const monthlyStats: StatType[] = [
    {
      label: "Trips",
      value: statData?.thisMonth.count,
      ratioDiff: statData ? Math.abs(statData.ratioDiff.count) : undefined,
      helpText:
        statData && statData.ratioDiff.count >= 0
          ? "More trips were created this month"
          : "Less trips were created this month",
      diffIncreased: statData ? statData.ratioDiff.count >= 0 : undefined,
    },
    {
      label: "Booked trips",
      value: statData?.thisMonth.booked,
      ratioDiff: statData ? Math.abs(statData.ratioDiff.booked) : undefined,
      helpText:
        statData && statData.ratioDiff.booked >= 0
          ? "More trips were sold this month"
          : "Less trips were sold this month",
      diffIncreased: statData ? statData.ratioDiff.booked >= 0 : undefined,
    },
    {
      label: "Booking rate",
      value: statData?.thisMonth.bookingRate + "%",
      ratioDiff: statData ? Math.abs(statData.ratioDiff.bookingRate) + "%" : undefined,
      helpText:
        statData && statData.ratioDiff.bookingRate >= 0
          ? "More trips were converted this month"
          : "Less trips were converted this month",
      diffIncreased: statData ? statData.ratioDiff.bookingRate >= 0 : undefined,
    },
  ];

  return (
    <Box w="100%" maxW="1000px" mx="auto" py={10}>
      <StatisticsSection
        isLoading={isLoadingStats || !statData}
        statData={generalStats}
        title="All-time statistics"
        size={28}
        onstatisticOverview={false}
      />
      <StatisticsSection
        isLoading={isLoadingStats || !statData}
        statData={monthlyStats}
        title="Past 30 days statistics"
        size={28}
        onstatisticOverview={false}
      />
    </Box>
  );
};

export default StatisticOverviewProduction;
