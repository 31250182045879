import { Stack } from "@chakra-ui/react";
import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CalendarView from "../../features/calenderOverview/CalendarOverview";

const CLIENT_ID = "581007934707-7hkq2rvrt8b850bt6uj10ucj4hk382lv.apps.googleusercontent.com";

const Calendar: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <Stack h="90vh">
        <CalendarView />
      </Stack>
    </GoogleOAuthProvider>
  );
};
export default Calendar;
