import {
  Contact,
  Event,
  Hotel,
  QueryRelation,
  TRANSPORTATION_TYPE,
  Destination,
  Transportation,
  Trip,
  TripDay,
  UserTrip,
  Task,
} from "@lato/common";
import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import ContactAPI from "../../../api/contacts.api";
import DefaultDocumentsAPI from "../../../api/default-documents.api";
import DocumentsAPI from "../../../api/documents.api";
import EventsAPI from "../../../api/events.api";
import HotelsAPI from "../../../api/hotels.api";
import TasksAPI from "../../../api/tasks.api";
import TransportationsApi from "../../../api/transportations.api";
import DestinationsAPI from "../../../api/destinations.api";
import TripdaysAPI from "../../../api/tripdays.api";
import TripsSplitAPI from "../../../api/trips-split.api";
import UserTripsSplitAPI from "../../../api/usertrips-split.api";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import {
  CALENDAR_ITEMS_QUERY_KEY,
  DAY_BY_DAY_STEP_DESTINATION_QUERY_KEY,
  DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY,
  DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY,
  DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  DAY_BY_DAY_STEP_TRIP_QUERY_KEY,
  DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY,
  GENERAL_STEP_CONTACTS_QUERY_KEY,
  GENERAL_STEP_DOCUMENTS_QUERY_KEY,
  GENERAL_STEP_FLIGHTS_QUERY_KEY,
  GENERAL_STEP_TRIP_QUERY_KEY,
  GENERAL_STEP_USERTRIP_QUERY_KEY,
  GOOGLE_CALENDAR_QUERY_KEY,
  LAST_EDITED_USERTRIP_QUERY_KEY,
  PRICE_STEP_TRIP_QUERY_KEY,
  PRICE_STEP_USERTRIP_QUERY_KEY,
  RECENTLY_EDITED_TRIP_QUERY_KEY,
  RECENTLY_EDITED_USERTRIP_QUERY_KEY,
  SAMPLES_USERTRIPS_KEY,
  SHARE_STEP_TRIP_QUERY_KEY,
  SHARE_STEP_USER_TRIP_QUERY_KEY,
  TASKS_ITEMS_QUERY_KEY,
  TRIPDAY_ACCOMMODATION_QUERY_KEY,
  TRIPDAY_ACTIVITY_QUERY_KEY,
  TRIPDAY_TRANSPORTATION_QUERY_KEY,
  TRIPS_USERTRIPS_KEY,
  TRIP_COUNTS_QUERY_KEY,
  TRIP_PLAIN_QUERY_KEY,
  TRIP_TRANSLATIONS_QUERY_KEY,
  USERTRIP_PLAIN_QUERY_KEY,
  USERTRIP_TASKS_QUERY_KEY,
} from "../../constants";
import { submitDocumentsArray } from "../../submitDocumentsArray";
import { showErrorToast, showLoadingToast, showSuccessToast, useStateToasts } from "./formStateToasts";
import AuthAPI from "../../../api/auth.api";
import UserTripsAPI from "../../../api/usertrips.api";

const defaultRQOptions = {
  retry: 0,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
};

export const createTranslationRelation = (name: string) => ({
  relation: name,
  subRelations: [
    {
      relation: "language",
    },
    { relation: "creator" },
  ],
});

export const getRelationMap: { [key in string]: any[] } = {
  [DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY]: [
    {
      relation: "image",
    },
    {
      relation: "libraryImage",
    },
  ],
  [DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY]: [
    { relation: "user", subRelations: [{ relation: "company" }] },
    { relation: "brand" },
  ],
  [TRIP_PLAIN_QUERY_KEY]: [
    createTranslationRelation("priceDescriptions"),
    createTranslationRelation("titles"),
    {
      relation: "tripdays",
      subRelations: [{ relation: "location" }, { relation: "image" }, { relation: "libraryImage" }],
    },
  ],
  [PRICE_STEP_USERTRIP_QUERY_KEY]: [{ relation: "currency" }],
  [PRICE_STEP_TRIP_QUERY_KEY]: [
    createTranslationRelation("includeds"),
    createTranslationRelation("notIncludeds"),
    createTranslationRelation("priceDescriptions"),
  ],
  [SHARE_STEP_TRIP_QUERY_KEY]: [createTranslationRelation("titles")],
  [SHARE_STEP_USER_TRIP_QUERY_KEY]: [
    { relation: "notes" },
    { relation: "user", subRelations: [{ relation: "company", subRelations: [{ relation: "brands" }] }] },
    { relation: "brand" },
  ],
  [GENERAL_STEP_TRIP_QUERY_KEY]: [
    createTranslationRelation("titles"),
    createTranslationRelation("descriptions"),
    {
      relation: "country",
    },
    { relation: "tripdays", subRelations: [{ relation: "location" }] },
  ],
  [GENERAL_STEP_USERTRIP_QUERY_KEY]: [
    { relation: "user", subRelations: [{ relation: "company" }] },
    { relation: "brand" },
  ],
  [USERTRIP_PLAIN_QUERY_KEY]: [{ relation: "user", subRelations: [{ relation: "company" }] }, { relation: "brand" }],
  [GENERAL_STEP_CONTACTS_QUERY_KEY]: [
    { relation: "contacts", subRelations: [{ relation: "company" }, { relation: "trips" }] },
  ],
  [GENERAL_STEP_DOCUMENTS_QUERY_KEY]: [
    { relation: "tripdocs" },
    {
      relation: "tripdefaultdocs",
      subRelations: [{ relation: "defaultDocument", subRelations: [{ relation: "document" }] }],
    },
  ],
  [DAY_BY_DAY_STEP_TRIP_QUERY_KEY]: [
    createTranslationRelation("titles"),
    {
      relation: "country",
    },
    {
      relation: "tripdays",
      subRelations: [
        createTranslationRelation("titles"),
        { relation: "image" },
        { relation: "libraryImage" },
        { relation: "destination" },
        { relation: "location" },
        { relation: "events", subRelations: [{ relation: "titles" }] },
        { relation: "hotels", subRelations: [{ relation: "location" }] },
        {
          relation: "transportations",
          subRelations: [
            { relation: "arrivalLocation" },
            { relation: "departureLocation" },
            { relation: "arrivalAirport" },
            { relation: "departureAirport" },
          ],
        },
      ],
    },
  ],
  [RECENTLY_EDITED_TRIP_QUERY_KEY]: [
    createTranslationRelation("titles"),
    {
      relation: "country",
    },
    {
      relation: "tripdays",
      subRelations: [{ relation: "location" }, { relation: "image" }, { relation: "libraryImage" }],
    },
  ],
  [RECENTLY_EDITED_USERTRIP_QUERY_KEY]: [{ relation: "user" }, { relation: "tasks" }],
  [LAST_EDITED_USERTRIP_QUERY_KEY]: [{ relation: "user" }, { relation: "tasks" }],
};

export const updateRelationMap: { [key in string]: any } = {
  [DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY]: {
    relation: "tripday",
    subRelations: getRelationMap[DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY],
  },
  [TRIP_PLAIN_QUERY_KEY]: getRelationMap[TRIP_PLAIN_QUERY_KEY],
  [PRICE_STEP_USERTRIP_QUERY_KEY]: {
    relation: "usertrip",
    subRelations: getRelationMap[PRICE_STEP_USERTRIP_QUERY_KEY],
  },
  [PRICE_STEP_TRIP_QUERY_KEY]: {
    relation: "trip",
    subRelations: getRelationMap[PRICE_STEP_TRIP_QUERY_KEY],
  },
  [SHARE_STEP_TRIP_QUERY_KEY]: {
    relation: "trip",
    subRelations: getRelationMap[SHARE_STEP_TRIP_QUERY_KEY],
  },
  [SHARE_STEP_USER_TRIP_QUERY_KEY]: {
    relation: "usertrip",
    subRelations: getRelationMap[SHARE_STEP_USER_TRIP_QUERY_KEY],
  },
  [GENERAL_STEP_TRIP_QUERY_KEY]: {
    relation: "trip",
    subRelations: getRelationMap[GENERAL_STEP_TRIP_QUERY_KEY],
  },
  [GENERAL_STEP_USERTRIP_QUERY_KEY]: {
    relation: "usertrip",
    subRelations: getRelationMap[GENERAL_STEP_USERTRIP_QUERY_KEY],
  },
  [USERTRIP_PLAIN_QUERY_KEY]: {
    relation: "usertrip",
    subRelations: getRelationMap[GENERAL_STEP_USERTRIP_QUERY_KEY],
  },
  [GENERAL_STEP_CONTACTS_QUERY_KEY]: {
    relation: "trip",
    subRelations: getRelationMap[GENERAL_STEP_CONTACTS_QUERY_KEY],
  },
  [GENERAL_STEP_DOCUMENTS_QUERY_KEY]: {
    relation: "trip",
    subRelations: getRelationMap[GENERAL_STEP_DOCUMENTS_QUERY_KEY],
  },
  [DAY_BY_DAY_STEP_TRIP_QUERY_KEY]: {
    relation: "trip",
    subRelations: getRelationMap[DAY_BY_DAY_STEP_TRIP_QUERY_KEY],
  },
  [DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY]: {
    relation: "usertrip",
    subRelations: getRelationMap[DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY],
  },
};

export const useTripTranslations = (tripId: string) => {
  return useSplitTrip(tripId, TRIP_TRANSLATIONS_QUERY_KEY, [createTranslationRelation("priceDescriptions")]);
};

export const useSplitTrip = (
  tripId: string,
  queryKey: string,
  relations: QueryRelation[] = [],
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) =>
  useQuery<Trip>({
    queryKey: [queryKey, tripId],
    queryFn: async () => {
      return (
        tripId &&
        (await TripsSplitAPI.getSplitTrip(tripId, JSON.stringify({ relation: "trip", subRelations: relations })))
      );
    },
    ...defaultRQOptions,
    ...options,
  });

export const useSplitTripByUserTripId = (
  userTripId: string,
  queryKey: string,
  relations: QueryRelation[] = [],
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
  prisma: boolean = false,
) =>
  useQuery<Trip>({
    queryKey: [queryKey, userTripId],
    queryFn: async () => {
      return await TripsSplitAPI.getSplitTripByUserTripId(
        userTripId,
        JSON.stringify({ relation: "trip", subRelations: relations }),
        prisma,
      );
    },
    ...defaultRQOptions,
    ...options,
  });

export const useTripContacts = (tripId: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) => {
  return useQuery({
    queryKey: [GENERAL_STEP_CONTACTS_QUERY_KEY, tripId],
    queryFn: async () => {
      return tripId && (await ContactAPI.getTripContacts(tripId));
    },
    ...defaultRQOptions,
    ...options,
  });
};

const loadAllTripDocuments = async (tripId: string) => {
  const tripdocs = await DocumentsAPI.getTripDocuments(tripId);
  const tripdefaultdocs = await DefaultDocumentsAPI.getTripDefaultDocuments(tripId);

  return { id: tripId, tripdocs, tripdefaultdocs };
};

export const useTripDocuments = (tripid: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) => {
  return useQuery({
    queryKey: [GENERAL_STEP_DOCUMENTS_QUERY_KEY, tripid],
    queryFn: async () => {
      return tripid && (await loadAllTripDocuments(tripid));
    },
    ...defaultRQOptions,
    ...options,
  });
};

export const useTripFlights = (tripId: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useQuery({
    queryKey: [GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId],
    queryFn: async () => {
      return tripId && (await TransportationsApi.getFlightsByTrip(tripId));
    },
    ...defaultRQOptions,
    ...options,
  });

export const usePatchTripFlights = (
  tripId: string,
  queryClient: any,
  queryKey: string = GENERAL_STEP_FLIGHTS_QUERY_KEY,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();

  return useMutation({
    mutationFn: async (flights: Transportation[]) => {
      return await TransportationsApi.patchMultiple(flights, tripId);
    },
    onMutate: async (flights: Transportation[]) => {
      showLoadingToast(loadingToast);

      await queryClient.cancelQueries({ queryKey: [queryKey, tripId] });

      const previousFlights = queryClient.getQueryData([queryKey, tripId]);

      queryClient.setQueryData([queryKey, tripId], flights);

      // Set the flights for the day-by-day step
      for (let i = 0; i < previousFlights.length; i++) {
        const flight = previousFlights[i];
        // There shouldn't be any outgoing queries, bcs we're on a different step, so no cancellation is needed
        // await queryClient.cancelQueries({ queryKey: [TRIPDAY_TRANSPORTATION_QUERY_KEY, flight.id] });

        // Check if the transportation is already fetched and in cache
        // If not, we're not setting the flight in the cache, the flight will be fetched when day-by-day is loaded
        const previousFlight = queryClient.getQueryData([TRIPDAY_TRANSPORTATION_QUERY_KEY, flight.id]);
        previousFlight && queryClient.setQueryData([TRIPDAY_TRANSPORTATION_QUERY_KEY, flight.id], flight);
      }

      return { previousFlights, newFlights: flights };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);

      queryClient.setQueryData([queryKey, tripId], context?.previousFlights);

      // Set the flights back to the old flights
      for (let i = 0; i < context?.previousFlights.length; i++) {
        const flight = context?.previousFlights[i];
        queryClient.setQueryData([TRIPDAY_TRANSPORTATION_QUERY_KEY, flight.id], flight);
      }
    },
    onSuccess: async (flights, trip, context) => {
      // setQueryData is not enough. Id's aren't set, so next patch will think these are new flights
      // queryClient.setQueryData([queryKey, tripId], flights);
      await queryClient.invalidateQueries({
        queryKey: [GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId],
      });

      await queryClient.invalidateQueries({
        queryKey: [TRIP_PLAIN_QUERY_KEY, tripId],
      });

      // Need to do this to add the flights in overview step on the map
      queryClient.invalidateQueries({
        queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY],
      });

      // Set the transportations for day-by-day
      for (let i = 0; i < flights.length; i++) {
        const flight = flights[i];
        const oldFlight = queryClient.getQueryData([TRIPDAY_TRANSPORTATION_QUERY_KEY, flight.id]);

        // Only invalidate and refetch if the data is different
        if (oldFlight && !_.isEqual(_.omit(oldFlight, ["updated_at"]), _.omit(flight, ["updated_at"]))) {
          await queryClient.invalidateQueries({
            queryKey: [TRIPDAY_TRANSPORTATION_QUERY_KEY, flight.id],
            refetchType: "all",
            exact: true,
          });

          // Invalidate the tripdays of the flights that have been changed
          await queryClient.invalidateQueries({
            queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, flight.tripdayId],
            refetchType: "all",
            exact: true,
          });

          if (oldFlight.tripdayId !== flight.tripdayId) {
            // Flight changed tripday. Invalidate the tripday of the old flight
            await queryClient.invalidateQueries({
              queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, oldFlight.tripdayId],
              refetchType: "all",
              exact: true,
            });
          }
        } else if (!oldFlight) {
          await queryClient.invalidateQueries({
            queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, flight.tripdayId],
            refetchType: "all",
            exact: true,
          });
        }
      }

      showSuccessToast(successToast);
    },
  });
};

export const usePatchTask = (
  queryClient: any,
  id?: string,
  queryKey: string = USERTRIP_TASKS_QUERY_KEY,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  return useMutation({
    mutationFn: async (task: Task) => {
      return await TasksAPI.patchTask(task);
    },
    onMutate: async (task) => {
      if (id) {
        const previousTasks = queryClient.getQueryData([queryKey, id]);

        const index = previousTasks.findIndex((pt: Task) => pt.id === task.id);
        previousTasks[index] = task;
        queryClient.setQueryData([queryKey, id], previousTasks);
      }
    },
    onSuccess: (tasks) => {
      if (id) {
        queryClient.invalidateQueries({ queryKey: [queryKey, id] });
      }

      queryClient.invalidateQueries({
        queryKey: [TASKS_ITEMS_QUERY_KEY],
      });
    },
  });
};

export const useRemoveFlight = (tripId: string, queryClient: any, fligths: any) => {
  const { errorToast } = useStateToasts();

  return useMutation({
    mutationFn: async (flightId: string) => {
      return await TransportationsApi.remove(flightId);
    },
    onMutate: async (flightId: string) => {
      // Getting the flights from the cache don't include the NEW flights!! That's why we're passing them as parameter
      // const flights = await queryClient.getQueryData([GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId]);

      await queryClient.cancelQueries({ queryKey: [GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId] });
      queryClient.setQueryData(
        [GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId],
        fligths.filter((f: any) => !f.id || f.id !== flightId),
      );

      return flightId;
    },
    onError: (err, flightId, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: async (flightId) => {
      await queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId] });
      // Don't invalidate the flights query! New flights that are added and not saved will be removed
      // Retrieve flights for general and day-by-day and overview flights
      await queryClient.invalidateQueries({
        queryKey: [TRIPDAY_TRANSPORTATION_QUERY_KEY, flightId[0]],
        refetchType: "all",
        exact: true,
      });
    },
  });
};

export const useMoveTask = (queryClient: any) => {
  return useMutation({
    mutationFn: async (task: Task) => {
      return await TasksAPI.patchTask(task);
    },
    onMutate: async (task) => {
      // console.log("move", task);
      // const previousTasks = queryClient.getQueryData([CALENDAR_ITEMS_QUERY_KEY, queryParams]);
      // console.log("move", previousTasks);
      // const index = previousTasks.findIndex((pt: Task) => pt.id === task.id);
      // previousTasks[index] = task;
      // queryClient.setQueryData([CALENDAR_ITEMS_QUERY_KEY, queryParams], previousTasks);
    },
    onSuccess: (tasks) => {
      queryClient.invalidateQueries({ queryKey: [CALENDAR_ITEMS_QUERY_KEY] });
    },
  });
};

export const useRemoveTask = (queryClient: any) => {
  return useMutation({
    mutationFn: async (task: Task) => {
      return await TasksAPI.delete(task.id);
    },
    onMutate: async (task) => {
      const previousTasks = queryClient.getQueryData([CALENDAR_ITEMS_QUERY_KEY]);

      queryClient.setQueryData(
        [CALENDAR_ITEMS_QUERY_KEY],
        previousTasks?.filter((pt: Task) => pt.id !== task.id),
      );
    },
    onSuccess: (tasks) => {
      queryClient.invalidateQueries({ queryKey: [CALENDAR_ITEMS_QUERY_KEY] });
    },
  });
};

export const useAddTaskToTrip = (tripId: string, queryClient: any) => {
  return useMutation({
    mutationFn: async (task: Task) => {
      return await TasksAPI.addTaskToTrip(task, tripId);
    },
    onMutate: async (newTask) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [USERTRIP_TASKS_QUERY_KEY, tripId] });

      // Snapshot the previous value
      const previousTasks = queryClient.getQueryData([USERTRIP_TASKS_QUERY_KEY, tripId]);

      // Optimistically update to the new value
      queryClient.setQueryData([USERTRIP_TASKS_QUERY_KEY, tripId], (oldTasks: Task[]) => [...oldTasks, newTask]);

      // Return a context object with the snapshotted value
      return { previousTasks };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData([USERTRIP_TASKS_QUERY_KEY, tripId], context?.previousTasks);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [USERTRIP_TASKS_QUERY_KEY, tripId] });
    },
  });
};

export const useAddTask = (queryClient: any, id?: string, queryKey?: string) => {
  return useMutation({
    mutationFn: async (task: Task) => {
      return await TasksAPI.addTask(task);
    },
    onMutate: async (newTask) => {},
    onError: (err, newTask, context) => {},
    // Always refetch after error or success:
    onSettled: (newTask) => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });

      const isToday = new Date(newTask.due_date).toDateString() === new Date().toDateString();

      if (isToday) {
        queryClient.invalidateQueries({ queryKey: [queryKey] });
      }

      if (id) {
        queryClient.invalidateQueries({ queryKey: [queryKey, id] });
      }
    },
  });
};

export const useAddTripdays = (tripId: string, queryClient: any, queryKey: string = DAY_BY_DAY_STEP_TRIP_QUERY_KEY) => {
  const { errorToast } = useStateToasts();

  return useMutation({
    mutationFn: async (number: number) => {
      return await TripdaysAPI.addTripdays(tripId, number);
    },
    onMutate: async (data: any) => {
      await queryClient.cancelQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId] });

      const previousData = queryClient.getQueryData([DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId]);

      queryClient.setQueryData(queryKey, previousData);

      return { previousData };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);
      queryClient.setQueryData([DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId], context?.previousData);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId] });
      queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [TRIP_PLAIN_QUERY_KEY, tripId] });
    },
  });
};

export const useRemoveTripday = (
  tripId: string,
  queryClient: any,
  queryKey: string = DAY_BY_DAY_STEP_TRIP_QUERY_KEY,
) => {
  const { errorToast } = useStateToasts();

  return useMutation({
    mutationFn: async (tripdayId: string) => {
      return await TripdaysAPI.removeTripday(tripdayId);
    },
    onMutate: async (data: any) => {
      await queryClient.cancelQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId] });

      const previousData = queryClient.getQueryData([DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId]);

      queryClient.setQueryData(queryKey, previousData);

      return { previousData };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);
      queryClient.setQueryData([DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId], context?.previousData);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId] });
      queryClient.invalidateQueries({ queryKey: [TRIP_PLAIN_QUERY_KEY, tripId] });
    },
  });
};

export const useAddPassenger = (
  tripId: string,
  queryClient: any,
  queryKey: string = GENERAL_STEP_CONTACTS_QUERY_KEY,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast } = useStateToasts();

  return useMutation({
    mutationFn: async (contact: Partial<Contact>) => {
      return await ContactAPI.addPassengerToTrip(tripId, contact.id!);
    },
    onMutate: async (contact: Partial<Contact>) => {
      await queryClient.cancelQueries({ queryKey: [GENERAL_STEP_CONTACTS_QUERY_KEY, tripId] });

      const previousPassengers = queryClient.getQueryData([GENERAL_STEP_CONTACTS_QUERY_KEY, tripId]);

      queryClient.setQueryData(queryKey, [...(previousPassengers || []), contact]);

      return { previousPassengers };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);
      queryClient.setQueryData([GENERAL_STEP_CONTACTS_QUERY_KEY, tripId], context?.previousPassengers);
    },
    onSuccess: (contacts) => {
      queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_CONTACTS_QUERY_KEY, tripId] });
    },
  });
};

export const useRemovePassenger = (
  tripId: string,
  queryClient: any,
  queryKey: string = GENERAL_STEP_CONTACTS_QUERY_KEY,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast } = useStateToasts();

  return useMutation({
    mutationFn: async (contactId: string) => {
      return await ContactAPI.removePassengerToTrip(tripId, contactId);
    },
    onMutate: async (contactId: string) => {
      await queryClient.cancelQueries({ queryKey: [GENERAL_STEP_CONTACTS_QUERY_KEY, tripId] });

      const previousPassengers = queryClient.getQueryData([GENERAL_STEP_CONTACTS_QUERY_KEY, tripId]);

      queryClient.setQueryData(
        [GENERAL_STEP_CONTACTS_QUERY_KEY, tripId],
        previousPassengers?.filter((c: Contact) => c.id !== contactId),
      );

      return { previousPassengers };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);
      queryClient.setQueryData([GENERAL_STEP_CONTACTS_QUERY_KEY, tripId], context?.previousPassengers);
    },
    onSuccess: (contacts) => {
      queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_CONTACTS_QUERY_KEY, tripId] });
    },
  });
};

export const useSplitUserTrip = (
  userTripId: string,
  relations: QueryRelation[] = [],
  queryKey: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) =>
  useQuery<UserTrip[]>({
    queryKey: [queryKey, userTripId],
    queryFn: async () => {
      return await UserTripsSplitAPI.getSplitUserTrip(
        userTripId,
        JSON.stringify({ relation: "usertrip", subRelations: relations }),
      );
    },
    ...defaultRQOptions,
    ...options,
  });

export const useActivity = (activityId: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useQuery({
    queryKey: [TRIPDAY_ACTIVITY_QUERY_KEY, activityId],
    queryFn: async () => {
      return await EventsAPI.getSingle(activityId);
    },
    ...defaultRQOptions,
    ...options,
  });

export const usePatchActivity = (
  activityId: string,
  tripdayId: string,
  queryClient: any,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();

  return useMutation({
    mutationFn: async (activity: Event) => {
      await submitDocumentsArray(activity.documents, "tripdays/");
      await submitDocumentsArray(activity.images, "tripdays/");
      return await EventsAPI.patch(activity.id ?? activityId, activity);
    },
    onMutate: async (activity: Event) => {
      showLoadingToast(loadingToast);
      await queryClient.cancelQueries({ queryKey: [TRIPDAY_ACTIVITY_QUERY_KEY, activity.id ?? activityId] });
      const previousActivity = queryClient.getQueryData([TRIPDAY_ACTIVITY_QUERY_KEY, activity.id ?? activityId]);

      queryClient.setQueryData([TRIPDAY_ACTIVITY_QUERY_KEY, activity.id ?? activityId], activity);

      return { previousActivity, newActivity: activity };
    },
    onError: (err, activity, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: async (activity) => {
      queryClient.setQueryData([TRIPDAY_ACTIVITY_QUERY_KEY, activity.id ?? activityId], activity);

      const tripday = await queryClient.getQueryData([DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripdayId]);
      const index = tripday?.events.findIndex((event: any) => event.id === activity.id);
      tripday.events[index] = activity;
      await queryClient.setQueryData([DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripdayId], tripday);

      showSuccessToast(successToast);
    },
  });
};

export const useAccommodation = (
  accommodationId: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) =>
  useQuery({
    queryKey: [TRIPDAY_ACCOMMODATION_QUERY_KEY, accommodationId],
    queryFn: async () => {
      return await HotelsAPI.getSingle(accommodationId);
    },
    ...defaultRQOptions,
    ...options,
  });

export const useGoogleCalendarItems = (
  userId: string,
  accessToken: string,
  calendarStart: string,
  calendarEnd: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) =>
  useQuery({
    queryKey: [GOOGLE_CALENDAR_QUERY_KEY, userId],
    queryFn: async () => {
      const events = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${calendarStart}&timeMax=${calendarEnd}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
          method: "GET",
        },
      );
      const e = await events.json();
      return e.items;
    },
    ...defaultRQOptions,
    ...options,
  });

export const useGoogleToken = (options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) => {
  return useMutation({
    mutationFn: async (code: string) => {
      return await AuthAPI.googleTokens(code);
    },
  });
};

export const useGoogleRefresh = (
  refreshToken: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  return useMutation({
    mutationFn: async () => {
      return await AuthAPI.googleRefreshToken(refreshToken);
    },
  });
};

export const usePatchAccommodation = (
  accommodationId: string,
  queryClient: any,
  tripId: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();

  return useMutation({
    mutationFn: async (accommodation: Hotel) => {
      await submitDocumentsArray(accommodation.documents, "tripdays/");
      await submitDocumentsArray(accommodation.images, "tripdays/");
      return await HotelsAPI.patch(accommodation.id ?? accommodationId, accommodation);
    },
    onMutate: async (accommodation: Hotel) => {
      showLoadingToast(loadingToast);
      await queryClient.cancelQueries({
        queryKey: [TRIPDAY_ACCOMMODATION_QUERY_KEY, accommodation.id ?? accommodationId],
      });
      const previousAccommodation = queryClient.getQueryData([
        TRIPDAY_ACCOMMODATION_QUERY_KEY,
        accommodation.id ?? accommodationId,
      ]);

      queryClient.setQueryData([TRIPDAY_ACCOMMODATION_QUERY_KEY, accommodation.id ?? accommodationId], accommodation);

      return { previousAccommodation, newAccommodation: accommodation };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: (accommodation, error) => {
      queryClient.setQueryData([TRIPDAY_ACCOMMODATION_QUERY_KEY, accommodation.id ?? accommodationId], accommodation);
      queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId] });

      showSuccessToast(successToast);
    },
  });
};

export const useTransportation = (
  transportationid: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) =>
  useQuery({
    queryKey: [TRIPDAY_TRANSPORTATION_QUERY_KEY, transportationid],
    queryFn: async () => {
      return await TransportationsApi.getSingle(transportationid);
    },
    ...defaultRQOptions,
    ...options,
  });

export const usePatchTransportation = (
  transportationId: string,
  queryClient: any,
  tripId?: string,
  tripdayIndex?: number,
  tripdayId?: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();

  return useMutation({
    mutationFn: async (transport: Transportation) => {
      await submitDocumentsArray(transport.documents, "tripdays/");
      return await TransportationsApi.patch(transport.id ?? transportationId, transport);
    },
    onMutate: async (transport: Transportation) => {
      showLoadingToast(loadingToast);

      await queryClient.cancelQueries({
        queryKey: [TRIPDAY_TRANSPORTATION_QUERY_KEY, transport?.id ?? transportationId],
      });
      const previousTransport = queryClient.getQueryData([
        TRIPDAY_TRANSPORTATION_QUERY_KEY,
        transport?.id ?? transportationId,
      ]);

      queryClient.setQueryData([TRIPDAY_TRANSPORTATION_QUERY_KEY, transport?.id ?? transportationId], transport);
      if (transport.type === TRANSPORTATION_TYPE.FLIGHT && tripId) {
        await queryClient.cancelQueries({ queryKey: [GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId] });
        const tripFlights = queryClient.getQueryData([GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId]);
        if (tripFlights) {
          const flightIndex = tripFlights.findIndex((flight: Transportation) => flight.id === transport.id);
          if (flightIndex !== -1) {
            tripFlights[flightIndex] = transport;
            queryClient.setQueryData([GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId], tripFlights);
          }
          if (flightIndex === -1 && transport.id) {
            queryClient.setQueryData([GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId], [...tripFlights, transport]);
          }
        }
      }
      return { previousTransport, newTransport: transport };
    },
    onError: (err, transport, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: async (transport, data, context) => {
      // Refetch the successfully updates transportation
      await queryClient.invalidateQueries({
        queryKey: [TRIPDAY_TRANSPORTATION_QUERY_KEY, transport?.id ?? transportationId],
      });

      if (tripId) {
        if (
          transport.type === TRANSPORTATION_TYPE.FLIGHT ||
          (context.previousTransport.type === TRANSPORTATION_TYPE.FLIGHT &&
            transport.type !== TRANSPORTATION_TYPE.FLIGHT)
        ) {
          // Refetch flights for general step
          await queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_FLIGHTS_QUERY_KEY, tripId] });
        }
        const tripdays = await queryClient.getQueryData([DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId]);

        // Refetch tripday of rental dropoff and of new dropofftripday if dropoff has changed
        if (
          transport.type === TRANSPORTATION_TYPE.RENTAL &&
          context.previousTransport.type === TRANSPORTATION_TYPE.RENTAL &&
          transport.arrivalDayIndex !== context.previousTransport.arrivalDayIndex
        ) {
          const tripdays = queryClient.getQueryData([DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId]).tripdays;
          const newDropoffTripday = tripdays[tripdayIndex + transport.arrivalDayIndex];
          const oldDropoffTripday = tripdays[tripdayIndex + context.previousTransport.arrivalDayIndex];
          queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, oldDropoffTripday.id] });
          queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, newDropoffTripday.id] });
        }

        // Refetch day by day step
        queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY, tripId] });
      }

      const tripday = await queryClient.getQueryData([DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripdayId]);
      const index = tripday?.transportations.findIndex((event: any) => event.id === transport.id);
      tripday.transportations[index] = transport;
      console.log("test tripday", tripday);
      await queryClient.setQueryData([DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripdayId], tripday);

      showSuccessToast(successToast);
    },
  });
};

export const useSplitTripday = (
  tripdayId: string,
  relations: QueryRelation[] = [],
  queryKey: string = DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) =>
  useQuery<TripDay>({
    queryKey: [queryKey, tripdayId],
    queryFn: async () => {
      return await TripdaysAPI.getTripdaySplit(
        tripdayId,
        JSON.stringify({ relation: "tripday", subRelations: relations }),
      );
    },
    ...defaultRQOptions,
    ...options,
  });

export const useDestination = (id: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useQuery<{ destination: Destination | null }>({
    queryKey: [DAY_BY_DAY_STEP_DESTINATION_QUERY_KEY, id],
    queryFn: async () => {
      return await DestinationsAPI.getSingle(id);
    },
    ...defaultRQOptions,
    ...options,
  });

export const useUsertripsCounts = (id: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useQuery<{ tasks: number; notes: number }>({
    queryKey: [TRIP_COUNTS_QUERY_KEY, id],
    queryFn: async () => {
      return await UserTripsAPI.getCounts(id);
    },
    ...defaultRQOptions,
    ...options,
  });

export const useTripdayDestination = (
  tripdayId: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  return useQuery<{ destination: Destination | null }>({
    queryKey: [DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId],
    queryFn: async () => {
      return await DestinationsAPI.getDestinationByTripdayId(tripdayId);
    },
    ...defaultRQOptions,
    ...options,
  });
};

export const useCreateDestination = (
  queryClient: any,
  tripdayId: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();

  return useMutation({
    mutationFn: async (destination: Destination) => {
      if (destination.images) await submitDocumentsArray(destination.images, "tripdays/");
      return await DestinationsAPI.post(destination);
    },
    onMutate: async (destination: Destination) => {
      showLoadingToast(loadingToast);

      await queryClient.cancelQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId] });
      const previousDestination = await queryClient.getQueryData([
        DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY,
        tripdayId,
      ]);
      queryClient.setQueryData([DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId], destination);

      return { previousDestination, newDestination: destination };
    },
    onError: (err, newDestination, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: async (destination) => {
      await queryClient.setQueryData([DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId], destination);
      queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId] });

      showSuccessToast(successToast);
    },
  });
};

export const usePatchDestination = (
  destinationId: string,
  queryClient: any,
  tripdayId: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();

  return useMutation({
    mutationFn: async (destination: Destination) => {
      if (destination.images) await submitDocumentsArray(destination.images, "tripdays/");
      return await DestinationsAPI.patch(destination.id ?? destinationId, destination);
    },
    onMutate: async (destination: Destination) => {
      showLoadingToast(loadingToast);

      await queryClient.cancelQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId] });
      const previousDestination = await queryClient.getQueryData([
        DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY,
        tripdayId,
      ]);
      queryClient.setQueryData([DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId], destination);

      return { previousDestination, newDestination: destination };
    },
    onError: (err, destination, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: async (destination) => {
      await queryClient.setQueryData([DAY_BY_DAY_STEP_TRIPDAY_DESTINATION_QUERY_KEY, tripdayId], {
        destination,
      });

      showSuccessToast(successToast);
    },
  });
};

export const usePatchSplitTripday = (
  tripdayId: string,
  relations: QueryRelation,
  queryClient: any,
  queryKey: string = DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY,
  tripId?: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();

  return useMutation({
    mutationFn: async (tripday: Partial<TripDay>) => {
      if (tripday.image) await submitDocumentsArray([tripday.image], "tripdays/");
      return await TripdaysAPI.patch(tripdayId, tripday, relations);
    },
    onMutate: async (tripday) => {
      showLoadingToast(loadingToast);

      await queryClient.cancelQueries({ queryKey: [queryKey, tripday.id] });

      const previousTripday = await queryClient.getQueryData([queryKey, tripdayId]);
      queryClient.setQueryData([queryKey, tripdayId], tripday);

      return { previousTripday, newTripday: tripday };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: async (tripday, trip, context) => {
      await queryClient.invalidateQueries({ queryKey: [queryKey, tripdayId] });

      tripday.events &&
        tripday.events.forEach(async (event: any) => {
          const eventFromCache = queryClient.getQueryData([TRIPDAY_ACTIVITY_QUERY_KEY, event.id]);
          if (eventFromCache) {
            await queryClient.setQueryData([TRIPDAY_ACTIVITY_QUERY_KEY, event.id], {
              ...eventFromCache,
              ord: event.ord,
            });
          }
        });

      // Check if a hotel was deleted to invalidate hotel names in overviewtable
      // if (tripday.hotels && !_.isEqual(tripday.hotels, context.previousTripday.hotels)) {
      //   queryClient.invalidateQueries({
      //     queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY],
      //   });
      // }

      // Should refetch the flights for general step
      queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_FLIGHTS_QUERY_KEY] });
      // Should invalidate the trip plain query for the trip in the store
      queryClient.invalidateQueries({ queryKey: [TRIP_PLAIN_QUERY_KEY] });

      // queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY] });

      showSuccessToast(successToast);
    },
  });
};

export const usePatchSplitTrip = (
  tripId: string,
  relations: QueryRelation,
  queryClient: any,
  queryKey: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();
  const { isSubmittingForms } = useTripFormStore();

  return useMutation({
    mutationFn: async (trip: Partial<Omit<Trip, "tripdays"> & { tripdays: Partial<TripDay>[] }>) => {
      return await TripsSplitAPI.patchSplitTrip(trip.id ?? tripId, trip, relations);
    },
    onMutate: async (trip) => {
      showLoadingToast(loadingToast);

      await queryClient.cancelQueries({ queryKey: [queryKey, trip.id ?? tripId] });

      const previousTrip = await queryClient.getQueryData([queryKey, trip.id ?? tripId]);
      queryClient.setQueryData([queryKey, trip.id ?? tripId], trip);

      return { previousTrip, newTrip: trip };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: (newTrip, trip, context) => {
      // Always refetch after error or success:
      queryClient.invalidateQueries({ queryKey: [queryKey, newTrip.id ?? tripId] });

      queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_TRIP_QUERY_KEY, newTrip.id ?? tripId] });

      if (context.previousTrip && !_.isEqual(context.previousTrip.titles, newTrip.titles)) {
        // Should refetch the usertrips for the usertrips list page (so titles are updated)
        queryClient.invalidateQueries({
          queryKey: [SAMPLES_USERTRIPS_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [TRIPS_USERTRIPS_KEY],
        });
        queryClient.invalidateQueries({ queryKey: [TRIP_PLAIN_QUERY_KEY, newTrip.id ?? tripId] });
      }

      if (newTrip.tripdays) {
        for (let i = 0; i < newTrip.tripdays.length; i++) {
          const oldTripday = queryClient.getQueryData([DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, newTrip.tripdays[i].id]);

          // Invalidate the tripdays that are changed
          if (oldTripday && !_.isEqual(oldTripday.location, newTrip.tripdays[i].location)) {
            queryClient.invalidateQueries({
              queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, newTrip.tripdays[i].id],
            });
          }
        }

        const isNrOfNightsChangesOfaTripDay = newTrip.tripdays.some(
          (tripday: TripDay, index: number) =>
            tripday.nrOfNights !==
            (context.previousTrip?.tripdays[index] ? context.previousTrip?.tripdays[index].nrOfNights : null),
        );
        const isTripDaysLengthChanged = newTrip.tripdays.length !== context.previousTrip?.tripdays.length;
        const isTripStartDateChanged = newTrip.start_date !== context.previousTrip?.start_date;

        if (isTripDaysLengthChanged || isNrOfNightsChangesOfaTripDay || isTripStartDateChanged) {
          // Updates the amount of days/nights in the wizard
          queryClient.invalidateQueries({
            queryKey: [TRIP_PLAIN_QUERY_KEY],
            refetchType: "all",
          });

          // Should add the new tripdays in day-by-day view
          queryClient.invalidateQueries({
            queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY],
            refetchType: "all",
          });

          queryClient.invalidateQueries({
            queryKey: [TRIPDAY_TRANSPORTATION_QUERY_KEY],
            refetchType: "all",
          });

          queryClient.invalidateQueries({
            queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY],
            refetchType: "all",
          });
        }
      }

      if (newTrip.tripdocs) {
        // Documents are set optimistically
        queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_DOCUMENTS_QUERY_KEY, newTrip.id ?? tripId] });
      }

      showSuccessToast(successToast);
    },
  });
};

export const usePatchSplitUserTrip = (
  usertripId: string,
  relations: QueryRelation,
  queryClient: any,
  queryKey: string,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) => {
  const { errorToast, loadingToast, successToast } = useStateToasts();

  return useMutation({
    mutationFn: async (userTrip: Partial<UserTrip>) => {
      return await UserTripsSplitAPI.patchSplitUserTrip(userTrip.id ?? usertripId, userTrip, relations);
    },
    onMutate: async (userTrips) => {
      showLoadingToast(loadingToast);

      await queryClient.cancelQueries({ queryKey: [queryKey, usertripId] });

      const previousUserTrips = await queryClient.getQueryData([queryKey, usertripId]);
      queryClient.setQueryData([queryKey, usertripId], Array.isArray(userTrips) ? userTrips : [userTrips]);

      return { previousUserTrips, newUserTrips: userTrips };
    },
    onError: (err, trip, context) => {
      showErrorToast(errorToast);
    },
    onSuccess: async (newUsertrips, usertrip, context) => {
      await queryClient.invalidateQueries({ queryKey: [queryKey, usertripId ?? newUsertrips.at(-1).id] });
      queryClient.invalidateQueries({
        queryKey: [DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY, usertripId ?? newUsertrips.at(-1).id],
      });

      // Should refetch the usertrips for the usertrips list page (so titles are updated)
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });

      queryClient.invalidateQueries({ queryKey: [GENERAL_STEP_TRIP_QUERY_KEY, newUsertrips[0].tripId] });

      if (
        (Array.isArray(context.previousUserTrips) ? context.previousUserTrips[0] : context.previousUserTrips)
          ?.show_directions !== newUsertrips[0].show_directions ||
        context.previousUserTrips.show_directions !== newUsertrips.show_directions
      ) {
        queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY, newUsertrips[0].tripId] });
      }

      showSuccessToast(successToast);
    },
  });
};
