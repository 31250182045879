import React from "react";
import { useFormContext } from "react-hook-form";
import { useTripLanguage } from "../../../../../../stores/trip-language-context";
import RHFTranslationInput from "../../../../../input/RHFTranslationInput";

export const Descriptions: React.FC<{ fieldName: string; elementType: string }> = ({ fieldName, elementType }) => {
  const { getValues, setValue, reset, control } = useFormContext<any>();

  const { firstSelectedLanguageIndex } = useTripLanguage();
  const value: string = getValues(`${fieldName}.${firstSelectedLanguageIndex}.content`);
  const valueGeneratedKey = value
    ? value.replace(/[^A-Z0-9]/gi, "_")
    : `${elementType}-description-${firstSelectedLanguageIndex}`;

  return (
    <RHFTranslationInput
      first={true}
      collaboratorCanEdit={false}
      name={`descriptions.0.content`}
      key={valueGeneratedKey}
      label={"Description"}
      // placeholder="Located in the hearth of the city centre, this hotel offers free WiFi, wellness facilities and live music."
      // variant="noBorders"
      size="md"
      textarea
    />
  );
};
