import { Box, Center, Flex, Heading, Image, Progress, Select, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Row, TableInstance } from "react-table";
import { ImageSite, IsImage, Image as ImageType } from "@lato/common";
import Section from "../layout/Section";
import SingleCardBoxLayout from "./SingleCardBoxLayout";
import SortingDropDown from "./SortingDropDown";
import ExtraPicture from "../../_app/TripDaysSwiper/ExtraPicture";

interface CardViewProps<ObjectType extends object> {
  tableMethods: TableInstance<any>;
  rows: Row<any>[];
  fetchingData: boolean;
  loadingData: boolean;
  displayName: string;
  tableCaption: any;
  tbodyBackgroundImage: any;
  minTableHeight: string | undefined;
  handleEdit?: (index: number, newRow: ObjectType) => void;
  cardViewComponent:
    | React.ComponentType<{
        row: any;
        key: number;
        size: CARDSIZES;
        handleEdit?: (index: number, newRow: ObjectType) => void;
      }>
    | undefined;
  getRowProps: ({
    row,
    toggleAllRowsExpanded,
  }: {
    row: Row;
    toggleAllRowsExpanded: (value?: boolean | undefined) => void;
  }) => any;
  noBorder?: boolean;
  isFiltering?: boolean;
  useSpaciousLayout?: boolean;
}

export enum CARDSIZES {
  XL = "XL",
  L = "L",
  MD = "M",
  S = "S",
}

const cardSizeWidth: { [key in CARDSIZES]: string } = {
  [CARDSIZES.XL]: "48%",
  [CARDSIZES.L]: "31%",
  [CARDSIZES.MD]: "22.8%",
  [CARDSIZES.S]: "17.6%",
};

const CardView = <T extends object>(props: CardViewProps<T>) => {
  const {
    displayName,
    tableMethods,
    rows,
    getRowProps,
    fetchingData,
    loadingData,
    tableCaption,
    tbodyBackgroundImage,
    minTableHeight,
    cardViewComponent: Component,
    handleEdit,
    noBorder,
    isFiltering = false,
    useSpaciousLayout = false,
  } = props;
  const { prepareRow, toggleAllRowsExpanded }: TableInstance<T> = tableMethods;

  const cardBodyStyleProps: any = {
    // The minimum table height
    minH: minTableHeight,
    // All the props below are just to insert a background picture into the table body
    position: "relative",
    zIndex: 1,
    _after: tbodyBackgroundImage
      ? {
          content: '""',
          display: "block",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: "0.2",
          zIndex: -1,
          backgroundImage: `url(${tbodyBackgroundImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "75% auto",
        }
      : undefined,
  };

  // Card Size const for localstorage
  const LS_CARD_SIZE = "cardsize";
  // Get the string value of the card size from localstorage
  const defaultCardSizeString = localStorage.getItem(LS_CARD_SIZE);
  // Intialize card size as per user last prefrence if present or default to L
  const defaultCardSize =
    defaultCardSizeString && defaultCardSizeString in CARDSIZES ? (defaultCardSizeString as CARDSIZES) : CARDSIZES.MD;
  const [cardSize, setCardSize] = useState<CARDSIZES>(defaultCardSize);

  /**
   * Set Card Size to localstorage whenever user change card size
   */
  useEffect(() => {
    localStorage.setItem(LS_CARD_SIZE, cardSize);
  }, [cardSize]);

  /**
   * Method manages cardsize state a
   */
  const manageCardSize = () => {
    // setCardSize(CARDSIZES.XL);
    switch (cardSize) {
      case CARDSIZES.XL:
        setCardSize(CARDSIZES.L);
        break;
      case CARDSIZES.L:
        setCardSize(CARDSIZES.MD);
        break;
      case CARDSIZES.MD:
        setCardSize(CARDSIZES.S);
        break;
      case CARDSIZES.S:
        setCardSize(CARDSIZES.XL);
        break;
      default:
        break;
    }
  };

  return (
    <Box {...cardBodyStyleProps} position="relative">
      <Flex
        justify="flex-end"
        gap="2"
        position="absolute"
        flexWrap={"wrap"}
        right={useSpaciousLayout ? "2em" : "6.2em"}
        top={rows.length === 0 ? "-2.5em" : "-3.5em"}
      >
        <SortingDropDown tableMethods={tableMethods} />
        <Select
          borderRadius={"10em"}
          onChange={(event) => {
            setCardSize(event.target.value as CARDSIZES);
          }}
          value={cardSize}
          width={"4.3em"}
        >
          {Object.values(CARDSIZES)
            .filter((val) => val !== CARDSIZES.XL)
            .map((value, i) => (
              <option key={`option-${i}`} value={value}>
                {value}
              </option>
            ))}
        </Select>
      </Flex>
      {fetchingData && !loadingData && <Progress mt={1} size="xs" isIndeterminate />}
      {rows.length > 0 ? (
        <Flex m={4} ml={6} gap="6" flexWrap="wrap" flexDir={{ base: "column", md: "row" }}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              // eslint-disable-next-line react/jsx-key
              <Box
                w={{ base: "100%", md: cardSizeWidth[cardSize] }}
                h={`${60 * (parseFloat(cardSizeWidth[cardSize]) / 100)}em`}
                shadow={noBorder ? "none" : "lg"}
                borderWidth={noBorder ? "0px" : "1px"}
                borderRadius="10px"
                {...row.getRowProps(getRowProps({ row, toggleAllRowsExpanded }))}
              >
                {Component ? (
                  <Component row={row} key={index} size={cardSize} handleEdit={handleEdit} />
                ) : (
                  <SingleCardBoxLayout row={row} key={index} />
                )}
              </Box>
            );
          })}
        </Flex>
      ) : loadingData ? (
        // Loading the async data
        <Center w="100%" minH="inherit" flexDir="column">
          <Heading size="md" fontWeight="500" color="realGray.400">
            Loading...
          </Heading>
          <Spinner size="md" mt={2} color="realGray.400" />
        </Center>
      ) : (
        <Center w="100%" minH="inherit">
          {tableCaption ?? (
            <Heading size="md" fontWeight="500" color="realGray.400">
              {isFiltering ? `0 ${displayName}` : `No ${displayName.toLowerCase()} yet`}
            </Heading>
          )}
        </Center>
      )}
    </Box>
  );
};
export default CardView;

interface CustomCardProps {
  image: ImageType | { url: string; site: ImageSite };
  key: number;
  size: string;
  optionsCell: any;
  title: string;
  children?: React.ReactNode;
}

export const CustomCard: React.FC<CustomCardProps> = ({ image, key, size, optionsCell, title, children }) => {
  const fontSizeDetails: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "24px",
    [CARDSIZES.L]: "16px",
    [CARDSIZES.MD]: "12px",
    [CARDSIZES.S]: "10px",
  };

  const topOptions: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "1em",
    [CARDSIZES.L]: "1em",
    [CARDSIZES.MD]: "0.5em",
    [CARDSIZES.S]: "0.2em",
  };

  const fontSizeTitle: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "26px",
    [CARDSIZES.L]: "20px",
    [CARDSIZES.MD]: "14px",
    [CARDSIZES.S]: "10px",
  };
  const bottomDetails: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "5.4em",
    [CARDSIZES.L]: "4em",
    [CARDSIZES.MD]: "3.6em",
    [CARDSIZES.S]: "2.8em",
  };

  return (
    <Section
      h={"100%"}
      w={"100%"}
      noDivider
      noHorizontalPadding
      noVerticalPadding
      paddingTop={0}
      paddingBottom={0}
      overflow={"hidden"}
      borderRadius={"10px"}
      key={`tripcard-${key}`}
    >
      <Box position="relative" height={"100%"} width={"100%"} overflow={"hidden"}>
        {optionsCell && (
          <Box position="absolute" top={topOptions[size]} right={1} zIndex={10} className={"trip-card-view-options"}>
            {optionsCell.render("Cell")}
          </Box>
        )}

        <Box
          position="absolute"
          backgroundColor={"rgba(0,0,0,0.3)"}
          borderRadius={"3px"}
          top="0"
          bottom="0"
          left="0"
          right="0"
        />
        <Box overflow="hidden" height={"100%"}>
          {!IsImage(image?.site) ? (
            image?.site === ImageSite.CUSTOMVIDEO ? (
              <iframe title="image" style={{ height: "100%", width: "240%", left: "-70%" }} src={image.url}></iframe>
            ) : (
              <video key={image.url} autoPlay muted loop height={"100%"}>
                <source src={image.url} type="video/mp4" />
              </video>
            )
          ) : (
            <ExtraPicture image={image} imageSize={"small"} removeImage={undefined} i={key}></ExtraPicture>
          )}
        </Box>
        <Flex
          flexDirection={"column"}
          justifyContent={"flex-start"}
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
        >
          <Text
            my={"0.5em"}
            ml={"1em"}
            mr={"2em"}
            w={"70%"}
            fontSize={fontSizeTitle[size]}
            color={"white"}
            zIndex={10}
            fontWeight={600}
            noOfLines={3}
          >
            {title}
          </Text>
        </Flex>
        <Box
          position={"absolute"}
          bottom={bottomDetails[size]}
          color={"white"}
          width={"100%"}
          fontSize={fontSizeDetails[size]}
        >
          {children && children}
        </Box>
      </Box>
    </Section>
  );
};
