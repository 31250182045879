export const getTasksProgressionColor = (percentageDone: number) => {
  if (percentageDone < 25) {
    return "red";
  } else if (percentageDone < 50) {
    return "orange";
  } else if (percentageDone < 100) {
    return "yellow";
  } else {
    return "green";
  }
};
