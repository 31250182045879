import {
  Box,
  Flex,
  IconButton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Event, TLocation } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { Trash2 as DeleteIcon } from "lucide-react";
import React from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import { useTripLanguage } from "../../../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY } from "../../../../../../utils/constants";
import {
  updateRelationMap,
  usePatchSplitTripday,
} from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { ELEMENT_VIEW } from "../../../../../CRUD/Resource";
import Documents from "../../../../../../features/documents/Documents";
import RHFTranslationInput from "../../../../../input/RHFTranslationInput";
import AutocompleteStaticMap from "../../../../../../features/map/AutocompleteStaticMap";
import ConfirmModal from "../../../../../modals/ConfirmModal";
import ElementPreview from "../../ElementPreview";
import PicturesUploader from "../../PicturesUploader";
import LibraryItemDownshift, { LIBRARYITEM_TYPE, LibraryItemInTripProps } from "../LibraryItemDownshift";
import { ContactInformation } from "../hotel/ContactInformation";
import { SupplierInformation } from "../hotel/SupplierInformation";
import StaticMap from "../../../../../map/staticMap";
import { AmountLabel } from "../hotel/AmountLabel";
import { EventFormOutsideTrip } from "./EventFormOutsideTrip";
import { afterChooseElementPictures } from "./afterChooseElementPictures";

interface ActualEventFormProps {
  tripProps: LibraryItemInTripProps | null;
  eventDateAndTime?: JSX.Element;
  selectedLanguageIndex?: number;
  updateActivity: any;
  onDelete?: any;
  view?: ELEMENT_VIEW;
  isCreate?: boolean;
  event?: Event;
  forceNew?: boolean;
  daySelectOptions?: any[];
}

export const ActualEventForm: React.FC<ActualEventFormProps> = ({
  tripProps,
  eventDateAndTime,
  selectedLanguageIndex = 0,
  updateActivity,
  onDelete,
  isCreate,
  view,
  event,
  forceNew = false,
  daySelectOptions,
}) => {
  const { trip, setInTripStore, unCollapsedElement } = useTripFormStore();
  const { realLanguageIndex } = useTripLanguage();
  const [collapsed, setCollapsed] = React.useState(unCollapsedElement !== event?.id && !!tripProps);

  React.useEffect(() => {
    setCollapsed(unCollapsedElement !== event?.id && !!tripProps);
  }, [unCollapsedElement]);

  const ref = React.useRef(null);

  React.useEffect(() => {
    const now = new Date();
    const eventCreatedData = event?.created_at && new Date(event.created_at);
    if (
      eventCreatedData &&
      eventCreatedData.getDate() === now.getDate() &&
      eventCreatedData.getHours() === now.getHours() &&
      eventCreatedData.getMinutes() === now.getMinutes()
    )
      ref && ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, []);

  const queryClient = useQueryClient();

  const { mutateAsync: updateTripday, isPending: isUpdatingTripday } = usePatchSplitTripday(
    tripProps?.tripdayId ?? "",
    updateRelationMap[DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY],
    queryClient,
    DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  );

  const tripdayLibraryImage =
    tripProps && trip.tripdays ? trip.tripdays[tripProps?.tripdayIndex ?? 0]?.libraryImage : true;
  const tripdayImage = tripProps && trip.tripdays ? trip.tripdays[tripProps?.tripdayIndex ?? 0]?.image : true;
  const fieldName = `descriptions`;

  const { getValues, watch } = useFormContext<any>();

  const { field: location } = useController({
    name: `location`,
  });

  const images = useWatch({
    name: "images",
  });

  const libraryImages = useWatch({
    name: "libraryImages",
  });

  const amountOfImages = [...(images ?? []), ...(libraryImages ?? [])].length;

  const documents = useWatch({
    name: "documents",
  });

  const hasDelete = onDelete !== undefined;
  const confirmDisclosure = useDisclosure();

  const afterChooseActivityPictures = React.useCallback(
    async (images: any[], library = false) => {
      if (!tripdayImage && !tripdayLibraryImage)
        await afterChooseElementPictures(
          images,
          tripProps?.tripdayId,
          updateTripday,
          library,
          tripProps?.tripdayIndex ?? 0,
          setInTripStore,
          trip?.tripdays && tripProps ? trip.tripdays[tripProps?.tripdayIndex ?? 0] : undefined,
        );
    },
    [trip, tripProps],
  );

  const [fromLocation, setFromLocation] = React.useState<TLocation | null>(location.value);

  const first = tripProps ? tripProps.first : true;
  const collaboratorCanEdit = tripProps ? tripProps.collaboratorCanEdit : true;
  const unique = tripProps?.uniqueId;

  const preview = React.useCallback(() => {
    const element = getValues();
    return (
      <ElementPreview
        title={element.titles[realLanguageIndex].content}
        description={element.descriptions[realLanguageIndex].content}
        time={(element.time ? element.time : "") + (element.endTime ? ` - ${element.endTime}` : "")}
        date={
          element.dayIndex !== null && daySelectOptions && daySelectOptions.length > element.dayIndex
            ? daySelectOptions[element.dayIndex].text
            : ""
        }
        location={element.location?.name}
        firstCoords={element.location?.coordinates}
        overlay={!!unCollapsedElement}
        externalImages={element.images}
        libraryImages={element.libraryImages}
        type="activity"
      />
    );
  }, [getValues, realLanguageIndex]);

  if (collapsed) {
    return (
      <div ref={ref} id={event?.id}>
        {preview()}
      </div>
    );
  }

  return (
    <>
      <div className="space-y-4 mt-0" ref={ref} id={event?.id ?? getValues("id")}>
        {!isCreate && hasDelete && view === ELEMENT_VIEW.MAP && (
          <Stack direction="row" alignItems="center" h={14}>
            <Box marginLeft="auto">
              {/* {tripProps && (
                    <RHFInput name={"showVoucher"} type={"checkbox"} mr={4}>
                      Show voucher
                    </RHFInput>
                  )} */}
              {/* Only show the delete icon when the form for updating and there is a delete method (handeld in LibraryItemModal.tsx) */}
              <Tooltip label="Delete Event" aria-label="Delete Event" hasArrow>
                <IconButton
                  aria-label="Delete Event"
                  size="sm"
                  onClick={() => confirmDisclosure.onOpen()}
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  variant="outline"
                  border="none"
                />
              </Tooltip>
            </Box>
          </Stack>
        )}
        <>
          {tripProps ? (
            <>
              <div className="ml-2">
                <LibraryItemDownshift
                  {...tripProps}
                  inTrip
                  libraryItemName={LIBRARYITEM_TYPE.EVENTS}
                  libraryItem={event}
                  getValues={getValues}
                  reset={updateActivity}
                  placeholder="Untitled Activity"
                />
              </div>
              <RHFTranslationInput
                first={first}
                collaboratorCanEdit={collaboratorCanEdit}
                name={`descriptions.0.content`}
                // label={"Description"}
                size="md"
                textarea
                isInline
              />
            </>
          ) : (
            <EventFormOutsideTrip
              updateActivity={updateActivity}
              fieldName={fieldName}
              selectedLanguageIndex={selectedLanguageIndex}
              unique={unique}
              forceNew={forceNew}
            />
          )}
          <Tabs variant="soft-rounded" colorScheme="brand">
            <TabList flexFlow={"wrap"}>
              <Tab>General</Tab>
              <Tab>Supplier</Tab>
              <Tab>Contact</Tab>
              <Tab>
                Images
                <AmountLabel amount={amountOfImages} />
              </Tab>
              <Tab>
                Documents
                <AmountLabel amount={documents.length} />
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex gap={4}>
                  <Stack width="50%" gap={4}>
                    <AutocompleteStaticMap
                      fillFieldName={`titles.${selectedLanguageIndex}.content`}
                      fromFieldName={`location`}
                      setGeometryFromValue={(coordinates: any, name: string, address: any) => {
                        if (coordinates === null) {
                          location.onChange(null);
                        } else {
                          location.onChange({
                            name: name,
                            coordinates,
                            address: address,
                          });
                        }
                      }}
                      mapMargin={"0.3em"}
                      first={first}
                      uniqueId={tripProps?.uniqueId}
                      inTrip={true}
                      fromLocationValue={location.value}
                      raiseMap={true}
                      setFromLocation={setFromLocation}
                    ></AutocompleteStaticMap>
                    {eventDateAndTime}
                  </Stack>
                  {fromLocation?.coordinates && (
                    <Box mt={4} mr={4} width="50%">
                      <StaticMap
                        containerHeight="11.2rem"
                        containerWidth="100%"
                        height={640}
                        width={1000}
                        center={
                          fromLocation?.coordinates
                            ? {
                                lng: fromLocation.coordinates[0],
                                lat: fromLocation.coordinates[1],
                              }
                            : undefined
                        }
                      />
                    </Box>
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <SupplierInformation placeholderChain="Viator" placeholderRef="AZE123" />
              </TabPanel>
              <TabPanel>
                <ContactInformation first={first} />
              </TabPanel>
              <TabPanel>
                <PicturesUploader
                  name={`images`}
                  defaultSearchValue={tripProps?.location?.name || ""}
                  defaultSearchValueName={getValues(`location.name`) ? "location.name" : "titles.0.content"}
                  awsFilename={`accommodation`}
                  first={first}
                  location={getValues(`location`)}
                  afterChoosePictures={(images) => afterChooseActivityPictures(images)}
                  afterChooseLibraryPictures={(images) => afterChooseActivityPictures(images, true)}
                />
              </TabPanel>
              <TabPanel>
                <Documents
                  fieldArrayName={`documents`}
                  inForm={false}
                  trip={trip}
                  disabled={(tripProps && !tripProps.first) ?? undefined}
                  showHeading={false}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      </div>
      <ConfirmModal
        title="Delete Event"
        description="Are you sure you want to delete this Event?"
        disclosure={confirmDisclosure}
        action={onDelete}
      />
    </>
  );
};
