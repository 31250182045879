import SettingsStep from "../../../../components/trips/edit/daybyday/settings/SettingsStep";
import { default as TripStepWrapper } from "../TripStepWrapper";

export const SettingsTripRoute: React.FC = () => {
  const stepNumber = 7;

  return (
    <TripStepWrapper>
      <SettingsStep stepNumber={stepNumber} />
    </TripStepWrapper>
  );
};

export default SettingsTripRoute;
