import { Button } from "@chakra-ui/react";
import { TRANSPORTATION_TYPE, Transportation } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { MdCarRental, MdDirectionsBike } from "react-icons/md";
import { TbBus, TbCar, TbPlane, TbSailboat, TbTrain, TbWalk } from "react-icons/tb";
import { useMeContext } from "../../../../../../stores/me-context";
import { useTripLanguage } from "../../../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, DAY_BY_DAY_STEP_TRIP_QUERY_KEY } from "../../../../../../utils/constants";
import { useTripTranslations } from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import TripForm from "../../../../TripForm";
import { resolveIncorrectTranslationFields } from "../activities/resolveIncorrectTranslationFields";
import { ActualTransportationForm } from "./ActualTransportationForm";

export interface TransportationFormProps {
  first?: boolean;
  departureDateAndTime?: JSX.Element;
  arrivalDateAndTime?: JSX.Element;
  type: TRANSPORTATION_TYPE;
  setType: any;
  transportation: any;
  uniqueId: string;
  inTrip: boolean;
  isRentalDropOff: boolean;
  extraButton?: any;
  collaboratorCanEdit: boolean;
  departureIsOutsideOfScope?: boolean;
  arrivalIsOutsideOfScope?: boolean;
  updateTransportation: any;
  isUpdatingTransportation: boolean;
  isFetchingTransportation: boolean;
  formMethods: UseFormReturn<Transportation, any, undefined>;
  formId: string;
  tripday: any;
  daySelectOptions?: any[];
  departureDate?: Date;
  arrivalDate?: Date;
}

export const transportIcon: { [key in TRANSPORTATION_TYPE | string]: any } = {
  [TRANSPORTATION_TYPE.BIKE]: MdDirectionsBike,
  [TRANSPORTATION_TYPE.CAR]: TbCar,
  [TRANSPORTATION_TYPE.RENTAL]: MdCarRental,
  [TRANSPORTATION_TYPE.FLIGHT]: TbPlane,
  [TRANSPORTATION_TYPE.BOAT]: TbSailboat,
  [TRANSPORTATION_TYPE.BUS]: TbBus,
  [TRANSPORTATION_TYPE.FOOT]: TbWalk,
  [TRANSPORTATION_TYPE.TRAIN]: TbTrain,
};

const TransportationForm: React.FC<TransportationFormProps> = ({ ...props }) => {
  const queryClient = useQueryClient();
  const me = useMeContext();
  const { trip, setUnCollapsedElement } = useTripFormStore();
  const { data: tripTranslations } = useTripTranslations(trip.id);
  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();

  const handleSubmit = async (e: any) => {
    setUnCollapsedElement(undefined);
    const { ord, ...formValues } = props.formMethods.getValues();
    if (formValues.type === TRANSPORTATION_TYPE.RENTAL && formValues.realtime) return;

    await props.updateTransportation({
      ...formValues,
      descriptions: resolveIncorrectTranslationFields(
        formValues.descriptions,
        props?.first ?? false,
        firstSelectedLanguageIndex,
        secondSelectedLanguageIndex ?? 0,
        tripTranslations,
        me,
      ),
    });
    if (formValues.type === TRANSPORTATION_TYPE.RENTAL) {
      queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIP_QUERY_KEY] });
    }
    queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, trip.id] });
  };

  return (
    <TripForm
      formId={props.formId}
      triggerResetData={props.transportation}
      defaultValues={props.transportation}
      formMethods={props.formMethods}
      onSubmit={handleSubmit}
    >
      <Button type="submit" hidden isLoading={props.isUpdatingTransportation} className="form-submit-button">
        Save
      </Button>
      <ActualTransportationForm {...props} />
    </TripForm>
  );
};

export default TransportationForm;
