import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { ImageSite, IsImage, TASK_STATUS, TRIP_TRAVELING_STATUS, Task, UserTrip } from "@lato/common";
import { FaCheck } from "react-icons/fa";
import { MdCheckCircle, MdOutlineCircle } from "react-icons/md";
import ExtraPicture from "../../_app/TripDaysSwiper/ExtraPicture";
import { getTasksProgressionColor } from "../../utils/getProgressCircleColor";
import { CARDSIZES } from "../../components/CRUD/CardView";
import Section from "../../components/layout/Section";
import { convertToUTCTimezone } from "../../utils/date";
import prettyPrintDatePlusDuration from "../../utils/prettyPrintDatePlusDuration";
import React from "react";

export const tripTravelingStatusColorMap: { [key in TRIP_TRAVELING_STATUS | string]: string } = {
  [TRIP_TRAVELING_STATUS.PAST]: "gray",
  [TRIP_TRAVELING_STATUS.TRAVELING]: "green",
  [TRIP_TRAVELING_STATUS.UPCOMING]: "blue",
};

interface TripCardProps {
  userTrip: UserTrip;
  key: string;
  cardWidth: string;
  cardHeight: string;
  padding: number;
  travelingStatus?: TRIP_TRAVELING_STATUS;
  sample?: boolean;
  cardSize: "sm" | "md" | "lg";
  onClick: () => void;
}

export const TripCard: React.FC<TripCardProps> = ({
  userTrip,
  key,
  cardWidth,
  cardHeight,
  padding,
  travelingStatus,
  cardSize,
  sample = false,
  onClick,
}) => {
  const trip = userTrip.trip;

  if (!trip) return <></>;

  const tripTitle = trip.titles?.find((title) => title.content !== "")?.content ?? "";
  const image =
    trip.tripdays[userTrip.homeBackgroundNumber]?.image ??
    trip.tripdays[userTrip.homeBackgroundNumber]?.libraryImage ??
    trip.tripdays.find((tripday) => tripday.image !== undefined)?.image;
  const tasks = userTrip.tasks || [];
  const amountTasksUnfinished = tasks.filter((task: Task) => task.status !== TASK_STATUS.DONE).length;
  const percentageTasksDone = ((tasks.length - amountTasksUnfinished) / tasks.length) * 100;
  const size = CARDSIZES.MD;

  const fontSizeDetails: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "24px",
    [CARDSIZES.L]: "16px",
    [CARDSIZES.MD]: "12px",
    [CARDSIZES.S]: "10px",
  };

  const fontSizeTitle: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "26px",
    [CARDSIZES.L]: "20px",
    [CARDSIZES.MD]: "14px",
    [CARDSIZES.S]: "10px",
  };

  return (
    <Box
      key={`slide-${key}`}
      height={cardHeight}
      width={cardWidth}
      className="group hover:cursor-pointer"
      onClick={onClick}
    >
      <Section
        h={"100%"}
        w={"100%"}
        noDivider
        noHorizontalPadding
        noVerticalPadding
        paddingTop={0}
        paddingBottom={0}
        overflow={"hidden"}
        borderRadius={"10px"}
        key={`tripcard-${key}`}
        shadow="md"
        border="none"
      >
        <Box position="relative" height={"100%"} width={"100%"} overflow={"hidden"}>
          <Box overflow="hidden" height={"100%"}>
            <div className="w-full h-full">
              {image && !IsImage(image?.site) ? (
                image?.site === ImageSite.CUSTOMVIDEO ? (
                  <iframe
                    title="image"
                    style={{ height: "100%", width: "240%", left: "-70%" }}
                    src={image.url}
                  ></iframe>
                ) : (
                  <video
                    key={image.url}
                    autoPlay
                    muted
                    loop
                    style={{ height: "100%", width: "100%", objectFit: "cover" }}
                  >
                    <source src={image.url} type="video/mp4" />
                  </video>
                )
              ) : (
                <ExtraPicture
                  image={image}
                  removeImage={undefined}
                  imageSize={cardSize == "lg" ? "regular" : "small"}
                  showGradient
                />
              )}
            </div>
          </Box>
          {tasks.length !== 0 && (
            <div className="w-6 h-6 absolute top-2 right-2 z-10">
              <Popover placement="top" closeOnBlur={true} trigger="hover">
                <PopoverTrigger>
                  <CircularProgress
                    size="20px"
                    value={percentageTasksDone === 0 ? 5 : percentageTasksDone}
                    color={`${getTasksProgressionColor(percentageTasksDone)}.400`}
                    trackColor="gray.300"
                    thickness={percentageTasksDone === 0 ? "12px" : "16px"}
                    capIsRound={true}
                  >
                    <CircularProgressLabel fontSize="12px" fontWeight={700}>
                      <Text color={"white"}>
                        {amountTasksUnfinished === 0 ? (
                          <FaCheck color="green.400" className="ml-[5px]" />
                        ) : (
                          amountTasksUnfinished
                        )}
                      </Text>
                    </CircularProgressLabel>
                  </CircularProgress>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverHeader fontWeight={600} fontSize="md">
                      {amountTasksUnfinished === 0
                        ? tasks.length === 0
                          ? "No tasks added"
                          : "All done!"
                        : `${amountTasksUnfinished} unfinished task${amountTasksUnfinished > 1 ? "s" : ""}`}
                    </PopoverHeader>
                    {tasks.length !== 0 && (
                      <PopoverBody m={2}>
                        <List>
                          {tasks
                            .sort(
                              (a, b) =>
                                (a.status === TASK_STATUS.DONE ? 1 : 0) - (b.status === TASK_STATUS.DONE ? 1 : 0),
                            )
                            .map((task) => (
                              <ListItem key={task.id}>
                                <ListIcon
                                  mb={0.5}
                                  as={task.status === TASK_STATUS.DONE ? MdCheckCircle : MdOutlineCircle}
                                  color={task.status === TASK_STATUS.DONE ? "green.500" : "black"}
                                />
                                {task.title}
                              </ListItem>
                            ))}
                        </List>
                      </PopoverBody>
                    )}
                  </PopoverContent>
                </Portal>
              </Popover>
            </div>
          )}
          {travelingStatus && (
            <Box position="absolute" borderRadius={"3px"} top="1" bottom="0" left="2" right="0">
              <Badge colorScheme={tripTravelingStatusColorMap[travelingStatus]}>{travelingStatus.toString()}</Badge>
            </Box>
          )}
          <Box
            color={"white"}
            width={"100%"}
            position={"absolute"}
            bottom={0}
            left={0}
            p={padding}
            fontSize={fontSizeDetails[size]}
          >
            {!sample && (
              <Tooltip label={tripTitle} placement="top-start">
                <Flex display={cardSize == "lg" ? "flex" : "none"} _groupHover={{ display: "flex" }}>
                  {trip.country?.flagImage && (
                    <Image mt={2} height={`16px`} width={"22px"} borderRadius={2} src={trip.country.flagImage} />
                  )}

                  <Text my={1} textAlign={"start"} fontWeight={700} fontSize={"lg"} nrOfLines={1} ml={2} isTruncated>
                    {tripTitle}
                  </Text>
                </Flex>
              </Tooltip>
            )}

            {!sample ? (
              <Text
                ml={2}
                fontSize={fontSizeTitle[size]}
                color={"white"}
                zIndex={10}
                width="95%"
                fontWeight={600}
                isTruncated
              >
                {trip.client_name}
                <br />
                {prettyPrintDatePlusDuration(convertToUTCTimezone(trip.start_date), trip.nrOfDays || 0, "days")}
              </Text>
            ) : (
              <Flex height={"3.4em"} gap={4} mx={2} alignItems={"center"} justifyContent={"flex-start"}>
                {trip.country?.flagImage && (
                  <Image mt={2} height={`16px`} width={"22px"} borderRadius={2} src={trip.country.flagImage} />
                )}
                <Box>
                  <Text my={1} textAlign={"start"} fontWeight={700} fontSize={"lg"} nrOfLines={1} isTruncated>
                    {tripTitle}
                  </Text>

                  <Text ml={0.5} fontSize="sm">
                    {userTrip.user.company.name}
                  </Text>
                </Box>
              </Flex>
            )}
          </Box>
        </Box>
      </Section>
    </Box>
  );
};
