import {
  Box,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tooltip,
  useDisclosure,
  useModalContext,
} from "@chakra-ui/react";
import React from "react";
interface ConfirmPopoverProps {
  action: (e: any) => any;
  disclosure: any;
  children?: React.ReactNode;
  message?: string;
  confirmButtonText?: string;
  confirmButtonColorScheme?: string;
  titleText?: string;
  popoverBodyClassname?: string;
  popoverContentClassname?: string;
}

const ConfirmPopover: React.FC<ConfirmPopoverProps> = ({
  action,
  disclosure,
  children,
  titleText,
  message = "Are you sure you want to delete this record?",
  confirmButtonText = "Delete",
  confirmButtonColorScheme = "red",
  popoverBodyClassname,
  popoverContentClassname,
}) => {
  const { isOpen, onOpen, onClose } = disclosure;

  if (titleText === undefined) {
    titleText = "Confirmation";
  }

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <ConfirmPopoverContent action={action} disclosure={disclosure} message={message}>
        <PopoverContent onClick={(e) => e.stopPropagation()} zIndex={10000} className={popoverContentClassname}>
          <PopoverArrow />
          {titleText && (
            <PopoverHeader fontWeight="semibold" fontSize="md">
              {titleText}
            </PopoverHeader>
          )}
          <PopoverBody fontSize="md" className={popoverBodyClassname}>
            {message}
          </PopoverBody>
          <PopoverFooter border="0" textAlign="right" pt={-2} pb={4}>
            <ButtonGroup size="sm">
              <Button
                variant="link"
                colorScheme="realGray"
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              >
                Close
              </Button>
              <Button
                colorScheme={confirmButtonColorScheme}
                onClick={(e) => {
                  e.stopPropagation();
                  action(e);
                  onClose();
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
              >
                {confirmButtonText}
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </ConfirmPopoverContent>
    </Popover>
  );
};
export default ConfirmPopover;

const ConfirmPopoverContent: React.FC<ConfirmPopoverProps> = ({ children }) => {
  try {
    const modalContext = useModalContext();
    return <Portal containerRef={modalContext.dialogRef}>{children}</Portal>;
  } catch (error) {
    return <Portal>{children}</Portal>;
  }
};
