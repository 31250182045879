import React from "react";
import ReactDOMServer from "react-dom/server";
import CustomLeafletMarker, { CustomLeafletMarkerProps } from "./CustomLeafletMarker";

const markerHeight = 27;
const markerWidth = 23;
const nestedMarkedIconSize = 0.65;

//@ts-ignore
const CustomLeafletPinMarkerIcon = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconSize: [markerWidth, markerHeight],
    iconAnchor: [markerWidth / 2, markerHeight],
    popupAnchor: [0, -markerHeight + 5],
    className: "leaflet-div-icon",
    pinColor: "currentColor",
    nestedIcon: null,
    nestedIconColor: null,
  },
  initialize: function (options: Record<string, unknown>) {
    // Makes sure the options object is available in the other functions.
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars
    options = L.Util.setOptions(this, options);
  },
  createIcon: function () {
    const div = document.createElement("div"),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unused-vars
      options = this.options;

    // if (options.poi) {
    div.innerHTML = this._createInner();
    // }
    this._setIconStyles(div, "icon");
    div.classList.add("leaflet-marker-prominent");

    return div;
  },
  _createInner: function () {
    // @ts-ignore
    // Convert the functional component to a HTML string.
    return ReactDOMServer.renderToString(
      <CustomPinMarker
        pinColor={this.options.pinColor}
        nestedIcon={this.options.nestedIcon}
        disableHover={this.options.disableHover}
        nestedIconColor={this.options.nestedIconColor}
      />,
    );
    // return Icon();
  },
});

const CustomPinIcon = ({ ...props }) => {
  return (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        stroke="currentColor"
        strokeWidth="1"
        fill="currentColor"
        fillOpacity={0.9}
        d="m8.49998,0.56247c-2.12173,0 -4.15656,0.84286 -5.65685,2.34315c-1.50029,1.50029 -2.34315,3.53512 -2.34315,5.65685c0,5.4 7.05,11.5 7.35,11.76c0.18113,0.1549 0.41165,0.2401 0.65,0.2401c0.23835,0 0.46887,-0.0852 0.65,-0.2401c0.35,-0.26 7.35,-6.36 7.35,-11.76c0,-2.12173 -0.8429,-4.15656 -2.3431,-5.65685c-1.5003,-1.50029 -3.5352,-2.34315 -5.6569,-2.34315z"
      />
    </svg>
  );
};

interface CustomPinMarkerProps {
  pinColor?: string;
  nestedIcon?: React.ReactNode;
  nestedIconColor?: string;
  disableHover?: boolean;
}

const CustomPinMarker: React.FC<CustomPinMarkerProps> = ({
  pinColor,
  nestedIcon,
  disableHover = false,
  nestedIconColor = "white",
}) => {
  return (
    <div className={!disableHover ? "leaflet-marker-grow" : ""}>
      <div style={{ position: "relative" }}>
        <CustomPinIcon
          width={`${markerWidth}px`}
          // height={`${markerHeight}px`}
          height="100%"
          color={pinColor}
        />
        <div
          style={{
            // To align the item vertically
            display: "flex",
            color: nestedIconColor,
            width: `${nestedMarkedIconSize * markerWidth}px`,
            height: `${nestedMarkedIconSize * markerWidth}px`,
            // height: ""
            position: "absolute",
            left: `calc(50% - ${(nestedMarkedIconSize * markerWidth) / 2}px)`,
            top: `calc(35% - ${(nestedMarkedIconSize * markerWidth) / 2}px)`,
            justifyContent: "center",
          }}
        >
          {nestedIcon}
        </div>
      </div>
    </div>
  );
};

interface CustomLeafletPinMarkerProps extends Omit<CustomLeafletMarkerProps, "icon"> {
  coordinates: number[] | null;
  pinColor?: string;
  nestedIcon?: React.ReactNode;
  nestedIconColor?: string;
  disableHover?: boolean;
  onClick?: () => void;
  openPopup?: boolean;
  children?: React.ReactNode;
}
export const CustomLeafletPinMarker: React.FC<CustomLeafletPinMarkerProps> = ({
  pinColor,
  nestedIcon,
  nestedIconColor,
  disableHover,
  onClick,
  openPopup,
  children,
  ...props
}) => {
  return (
    <CustomLeafletMarker
      {...props}
      onClick={onClick}
      openPopup={openPopup}
      icon={
        // @ts-ignore
        new CustomLeafletPinMarkerIcon({
          pinColor,
          nestedIcon,
          nestedIconColor,
          disableHover,
        })
      }
    >
      {children}
    </CustomLeafletMarker>
  );
};
