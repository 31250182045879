import { Box, Flex, Heading, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import GlassBoxElement from "./layout/GlassBoxElement";

interface TimeCountDownProps {
  initialSecondsLeft: number;
}

const TimeCountDown: React.FC<TimeCountDownProps> = ({ initialSecondsLeft }) => {
  const [countDown, setCountdown] = React.useState(initialSecondsLeft);
  const [timerDone, setTimerDone] = React.useState(false);
  React.useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    if (initialSecondsLeft > 0) {
      setTimeout(() => {
        if (isMounted) {
          countDown - 1 < 0 ? setTimerDone(true) : setCountdown(countDown - 1);
        }
      }, 1000);
    }
    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, [countDown, timerDone]);

  if (timerDone || initialSecondsLeft < 1) return null;

  const dhms = secondsToDhms(countDown);
  return (
    <Flex w="60%">
      <Box textAlign="center" bg="white" p={2} boxShadow="lg" borderRadius="lg" w="23%">
        <Heading fontWeight={400} size="lg">
          {dhms.days.toString()}
        </Heading>
        <Text fontSize="xs">Days</Text>
      </Box>
      <Spacer />
      <Box textAlign="center" bg="white" p={2} boxShadow="lg" borderRadius="lg" w="23%">
        <Heading fontWeight={400} size="lg">
          {toTwoDigits(dhms.hours)}
        </Heading>
        <Text fontSize="xs">Hours</Text>
      </Box>
      <Spacer />
      <Box textAlign="center" bg="white" p={2} boxShadow="lg" borderRadius="lg" w="23%">
        <Heading fontWeight={400} size="lg">
          {toTwoDigits(dhms.minutes)}
        </Heading>
        <Text fontSize="xs">Mins</Text>
      </Box>
      <Spacer />
      <Box textAlign="center" bg="white" p={2} boxShadow="lg" borderRadius="lg" w="23%">
        <Heading fontWeight={400} size="lg">
          {toTwoDigits(dhms.seconds)}
        </Heading>
        <Text fontSize="xs">Secs</Text>
      </Box>
    </Flex>
  );
};
export default React.memo(TimeCountDown);

export function secondsToDhms(seconds: number): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} {
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  return {
    days: d,
    hours: h,
    minutes: m,
    seconds: s,
  };
}
export const toTwoDigits = (n: number) => (n < 10 ? "0" : "") + n;
