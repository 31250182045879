import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  Portal,
  Tooltip,
} from "@chakra-ui/react";
import { Trash2 as DeleteIcon, MoreHorizontal } from "lucide-react";
import React from "react";

import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { ImMoveUp as MoveUp } from "react-icons/im";
import { IoDuplicateOutline as Duplicate } from "react-icons/io5";

interface OptionsDropdownProps {
  isDisabled?: boolean;
  menuButton?: React.ReactNode;
  menuIcon?: React.ReactElement;
  gutter?: number;
  children: any;
  onClick?: (e: any) => void;
  label?: string;
  menuListInPortal?: boolean;
}

const OptionsDropdown: React.FC<OptionsDropdownProps> = ({
  menuButton = undefined,
  menuIcon,
  gutter = 4,
  children,
  onClick,
  label = "Options",
  isDisabled = false,
  menuListInPortal = true,
  ...props
}) => {
  const menuList = () => {
    const menu = (
      <MenuList
        zIndex="10000"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </MenuList>
    );
    if (menuListInPortal) return <Portal>{menu}</Portal>;
    return menu;
  };

  return (
    <Menu gutter={gutter} closeOnBlur={true} {...props}>
      {menuButton ? (
        menuButton
      ) : (
        <Tooltip label={label} aria-label="table-options-tooltip" closeOnMouseDown>
          <MenuButton
            isDisabled={isDisabled}
            as={IconButton}
            aria-label="table-options"
            icon={menuIcon ?? <Icon as={MoreHorizontal} boxSize={4} />}
            onMouseDown={(e) => {
              e.stopPropagation();
              onClick && onClick(e);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            variant="unstyled"
            height={"unset"}
            zIndex={601}
          />
        </Tooltip>
      )}
      {menuList()}
    </Menu>
  );
};
export default React.memo(OptionsDropdown);

export type OptionsDropdownItemProps = MenuItemProps & {
  name?: string;
  customItem?: any;
};

const OptionsDropdownItemm: React.FC<OptionsDropdownItemProps> = ({ name, customItem, ...props }) => {
  return (
    <MenuItem
      // fontSize="sm"
      // fontWeight="500"
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        if (props.onClick) props.onClick(e);
      }}
    >
      {name}
      {customItem}
    </MenuItem>
  );
};
export const OptionsDropdownItem = React.memo(OptionsDropdownItemm);

export const DeleteOptionsDropdownItem: React.FC<
  OptionsDropdownItemProps & {
    resourceName: string;
  }
> = ({ resourceName, ...props }) => {
  return (
    <OptionsDropdownItem
      color="red.500"
      ml={"-0.1em"}
      icon={<DeleteIcon size={"15px"} />}
      name={`Delete ${resourceName}`}
      {...props}
    />
  );
};

export const DuplicateOptionsDropdownItem: React.FC<
  OptionsDropdownItemProps & {
    resourceName: string;
  }
> = ({ resourceName, ...props }) => {
  return (
    <OptionsDropdownItem
      icon={
        <Box marginLeft={"-0.1em"}>
          <Duplicate size={"14px"} />
        </Box>
      }
      name={`Duplicate ${resourceName}`}
      {...props}
    />
  );
};

export const MoveLeftOptionsDropdownItem: React.FC<
  OptionsDropdownItemProps & {
    resourceName: string;
  }
> = ({ resourceName, ...props }) => {
  return (
    <OptionsDropdownItem
      icon={
        <Box marginLeft={"-0.1em"}>
          <BsArrowBarLeft size={"14px"} />
        </Box>
      }
      name={`Move ${resourceName} to the left`}
      {...props}
    />
  );
};

export const MoveLeftRightDropdownItem: React.FC<
  OptionsDropdownItemProps & {
    resourceName: string;
  }
> = ({ resourceName, ...props }) => {
  return (
    <OptionsDropdownItem
      icon={
        <Box marginLeft={"-0.1em"}>
          <BsArrowBarRight size={"14px"} />
        </Box>
      }
      name={`Move ${resourceName} to the right`}
      {...props}
    />
  );
};

export const MoveTripdayOptionsDropdownItem: React.FC<
  OptionsDropdownItemProps & {
    resourceName: string;
    currentTripdayIndex: number;
  }
> = ({ resourceName, currentTripdayIndex, ...props }) => {
  return (
    <OptionsDropdownItem
      icon={
        <Box marginLeft={"-0.1em"}>
          <MoveUp size={"16px"} style={{ transform: "rotate(90deg)" }} />
        </Box>
      }
      name={`Move ${resourceName}`}
      {...props}
    />
  );
};
