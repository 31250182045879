import React from "react";
import accommodations from "../components/elements/accommodations";
import activities from "../components/elements/activities";
import libraryImages from "../components/elements/libraryImages";
import poi from "../components/elements/poi";
import textfields from "../components/elements/textfields";
import leadForm from "../features/leads-form/LeadForm";
import Checkout from "../components/settings/billing/checkout/Checkout";
import OverallTripSettings from "../components/trips/edit/daybyday/settings/OverallTripSettings";
import SamplesList from "../components/trips/samples/SamplesList";
import WikiVoyage from "../features/wikivoyage/WikiVoyage";
import AcceptInvitation from "../pages/accept-invitation/[token]";
import BillingSettings from "../pages/billingSettings";
import IntegrationsSettings from "../pages/settings/IntegrationsSettings";
import ChangePassword from "../pages/change-password/[token]";
import Chats from "../pages/chats";
import Contacts from "../pages/contacts";
import Dashboard from "../pages/dashboard";
import StatisticOverview from "../pages/dashboard/StatisticOverview";
import FeaturesOverview from "../pages/dashboard/features";
import EmailConfirmation from "../pages/email-confirmation/[token]";
import ForgotPassword from "../pages/forgot-password";
import Login from "../pages/login";
import Register from "../pages/register";
import MapSettings from "../pages/settings/MapSettings";
import SecuritySettings from "../pages/settings/SecuritySettings";
import AccountSettings from "../pages/settings/accountSettings";
import DeeplSettings from "../pages/settings/deeplSettings";
import Referral from "../pages/settings/referral";
import { TripListContainerRoute } from "../pages/trip-list-container";
import TripCollaborateRoute from "../pages/trip/edit/TripCollaborateRoute";

import { Role } from "@lato/common";
import {
  BarChart2,
  Bed,
  BookOpenCheck,
  CalendarIcon,
  Cog,
  Contact as ContactIcon,
  CreditCard,
  Flag,
  Globe2,
  HeartHandshake,
  Home,
  Image,
  Languages,
  Lock,
  Map,
  MessageSquare,
  Pin,
  Puzzle,
  Rocket,
  Settings,
  Store,
  TextSelect,
  User,
  type LucideIcon,
} from "lucide-react";
import { BookDetachedWrapper, default as tripBookingsWrapper } from "../features/bookings/BookDetached";
import BookingConfiguration from "../features/bookings/BookingConfiguration";
import BookingOverview from "../features/bookings/BookingOverview";
import Contact from "../features/contacts/Contact";
import Accommodation from "../components/elements/accommodations/Accommodation";
import POIs from "../pages/POIs";
import Calendar from "../pages/calendar";
import StatisticOverviewProduction from "../pages/dashboard/StatisticOverviewProduction";
import DayByDaySampleRoute from "../pages/sample/daybyday/DayByDaySampleRoute";
import GeneralSampleRoute from "../pages/sample/general/GeneralSampleRoute";
import OverviewSampleRoute from "../pages/sample/overview/OverviewSampleRoute";
import PriceSampleRoute from "../pages/sample/price/PriceSampleRoute";
import ShareSampleRoute from "../pages/sample/share/ShareSampleRoute";
import BrandSettings from "../pages/settings/BrandSettings";
import { TasksRoute } from "../pages/tasks/TasksRoute";
import { default as AcceptCollaborateRoute } from "../pages/trip/edit/AcceptCollaborateRoute";
import DayByDayTripRoute from "../pages/trip/edit/daybyday/DayByDayTripRoute";
import GeneralTripRoute from "../pages/trip/edit/general/GeneralTripRoute";
import OverviewTripRoute from "../pages/trip/edit/overview/OverviewTripRoute";
import PriceTripRoute from "../pages/trip/edit/price/PriceTripRoute";
import SettingsTripRoute from "../pages/trip/edit/settings/SettingsTripRoute";
import ShareTripRoute from "../pages/trip/edit/share/ShareTripRoute";
import ENV from "./env";
import destinations from "../components/elements/destinations";
import TasksTripRoute from "../pages/trip/edit/tasks/TasksTripRoute";
import NotesTripRoute from "../pages/trip/edit/notes/NotesTripRoute";
import TasksSampleRoute from "../pages/sample/tasks/TasksSampleRoute";
import SettingsSampleRoute from "../pages/sample/settings/SettingsSampleRoute";

type TNav = {
  name: string;
  icon: LucideIcon;
};

type TRouteInternal = {
  path: string[];
  Component: React.FC<any>;
  subroutes?: TRouteInternal[];
  nav: TNav | null;
} & ({ type: "private"; roles: Role[] } | { type: "normal" | "public" });

export type TRoute = Omit<TRouteInternal, "path" | "subroutes"> & {
  path: string;
  subroutes?: TRoute[];
};

const baseSettingsRoute: TRouteInternal = {
  path: ["settings", "account"],
  Component: AccountSettings,
  type: "private",
  roles: [Role.ADMIN, Role.USER],
  nav: {
    name: "Settings",
    icon: Settings,
  },
};

const baseBookingsRoute: TRouteInternal = {
  path: ["booking", "overview"],
  Component: BookingOverview,
  type: "private",
  roles: [Role.ADMIN, Role.USER],
  nav: {
    name: "Booking",
    icon: BookOpenCheck,
  },
};

const baseElementsRoute: TRouteInternal = {
  path: ["library", "accommodations"],
  Component: accommodations,
  type: "private",
  roles: [Role.ADMIN, Role.USER],
  nav: {
    name: "Elements",
    icon: Puzzle,
  },
};

const baseTripRoute: TRouteInternal = {
  path: ["trips"],
  Component: TripListContainerRoute,
  type: "private",
  roles: [Role.ADMIN, Role.USER],
  nav: null,
};

const baseSampleRoute: TRouteInternal = {
  path: ["samples"],
  Component: SamplesList,
  type: "private",
  roles: [Role.ADMIN, Role.USER],
  nav: null,
};

const routes: TRouteInternal[] = [
  {
    path: ["accommodations", ":id"],
    Component: Accommodation,
    type: "private",
    nav: null,
    roles: [Role.ADMIN, Role.USER],
  },
  {
    path: ["trips", "collaborate", ":id"],
    Component: TripCollaborateRoute,
    type: "normal",
    nav: null,
  },
  {
    path: ["trips", "collaborate", ":id", ":accept"],
    Component: AcceptCollaborateRoute,
    type: "normal",
    nav: null,
  },
  {
    path: ["accept-invitation", ":token"],
    Component: AcceptInvitation,
    type: "normal",
    nav: null,
  },
  {
    path: ["login"],
    Component: Login,
    type: "public",
    nav: null,
  },
  {
    path: ["register", ":token?"],
    Component: Register,
    type: "public",
    nav: null,
  },
  {
    path: ["forgot-password"],
    Component: ForgotPassword,
    type: "normal",
    nav: null,
  },
  {
    path: ["change-password", ":token"],
    Component: ChangePassword,
    type: "normal",
    nav: null,
  },
  {
    path: ["email-confirmation", ":token"],
    Component: EmailConfirmation,
    type: "normal",
    nav: null,
  },
  {
    path: [],
    Component: Dashboard,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: {
      name: "Home",
      icon: Home,
    },
  },
  {
    path: ["statistics"],
    Component: ENV === "production" ? StatisticOverviewProduction : StatisticOverview,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: {
      name: "Statistics",
      icon: BarChart2,
    },
  },
  {
    path: ["features"],
    Component: FeaturesOverview,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: {
      name: "Features",
      icon: Rocket,
    },
  },
  {
    ...baseTripRoute,
    subroutes: [
      {
        ...baseTripRoute,
        path: ["trips", ":id"],
        Component: GeneralTripRoute,
      },
      {
        ...baseTripRoute,
        path: ["trips", ":id", "general"],
        Component: GeneralTripRoute,
      },
      {
        ...baseTripRoute,
        path: ["trips", ":id", "overview"],
        Component: OverviewTripRoute,
      },
      {
        ...baseTripRoute,
        path: ["trips", ":id", "day-by-day", ":dayIndex?"],
        Component: DayByDayTripRoute,
      },
      {
        ...baseTripRoute,
        path: ["trips", ":id", "price"],
        Component: PriceTripRoute,
      },
      {
        ...baseTripRoute,
        path: ["trips", ":id", "tasks"],
        Component: TasksTripRoute,
      },
      {
        ...baseTripRoute,
        path: ["trips", ":id", "notes"],
        Component: NotesTripRoute,
      },
      {
        ...baseTripRoute,
        path: ["trips", ":id", "share"],
        Component: ShareTripRoute,
      },
      {
        ...baseTripRoute,
        path: ["trips", ":id", "settings"],
        Component: SettingsTripRoute,
      },
    ],
  },
  {
    ...baseSampleRoute,
    subroutes: [
      {
        ...baseSampleRoute,
        path: ["samples", ":id"],
        Component: GeneralSampleRoute,
      },
      {
        ...baseSampleRoute,
        path: ["samples", ":id", "general"],
        Component: GeneralSampleRoute,
      },
      {
        ...baseSampleRoute,
        path: ["samples", ":id", "overview"],
        Component: OverviewSampleRoute,
      },
      {
        ...baseSampleRoute,
        path: ["samples", ":id", "day-by-day", ":dayIndex?"],
        Component: DayByDaySampleRoute,
      },
      {
        ...baseSampleRoute,
        path: ["samples", ":id", "price"],
        Component: PriceSampleRoute,
      },
      { ...baseSampleRoute, path: ["samples", ":id", "tasks"], Component: TasksSampleRoute },
      {
        ...baseSampleRoute,
        path: ["samples", ":id", "share"],
        Component: ShareSampleRoute,
      },
      { ...baseSampleRoute, path: ["samples", ":id", "settings"], Component: SettingsSampleRoute },
    ],
  },
  {
    path: ["chats", ":chatId"],
    Component: Chats,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: null,
  },
  {
    path: ["chats"],
    Component: Chats,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: {
      name: "Chats",
      icon: MessageSquare,
    },
  },
  {
    path: ["contacts"],
    Component: Contacts,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: {
      name: "Contacts",
      icon: ContactIcon,
    },
  },
  {
    path: ["contacts", ":id"],
    Component: Contact,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: null,
  },
  {
    path: ["points-of-interest"],
    Component: POIs,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: {
      name: "Points of Interest (POIs)",
      icon: Pin,
    },
  },
  {
    path: ["tripbooking", ":accommodationId"],
    Component: tripBookingsWrapper,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: null,
  },
  {
    path: ["calendar"],
    Component: Calendar,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: {
      name: "Calendar",
      icon: CalendarIcon,
    },
  },
  {
    path: ["tasks"],
    Component: TasksRoute,
    type: "private",
    roles: [Role.ADMIN, Role.USER],
    nav: {
      name: "Tasks",
      icon: CalendarIcon,
    },
  },
  {
    ...baseBookingsRoute,
    subroutes: [
      {
        ...baseBookingsRoute,
        path: ["booking", "configuration"],
        Component: BookingConfiguration,
        nav: {
          name: "Configuration",
          icon: BookOpenCheck,
        },
      },
      {
        ...baseBookingsRoute,
        path: ["booking", "book"],
        Component: BookDetachedWrapper,
        nav: {
          name: "External Booking",
          icon: BookOpenCheck,
        },
      },
    ],
  },
  {
    ...baseElementsRoute,
    subroutes: [
      {
        ...baseElementsRoute,
        Component: accommodations,
        nav: {
          name: "Accommodations",
          icon: Bed,
        },
      },
      {
        ...baseElementsRoute,
        path: ["library", "activities"],
        Component: activities,
        nav: {
          name: "Activities",
          icon: Flag,
        },
      },
      {
        ...baseElementsRoute,
        path: ["library", "text-fields"],
        Component: textfields,
        nav: {
          name: "Text fields",
          icon: TextSelect,
        },
      },
      {
        ...baseElementsRoute,
        path: ["library", "destinations"],
        Component: destinations,
        nav: {
          name: "Text fields",
          icon: TextSelect,
        },
      },
      {
        ...baseElementsRoute,
        path: ["library", "images"],
        Component: libraryImages,
        nav: {
          name: "Images",
          icon: Image,
        },
      },
      {
        ...baseElementsRoute,
        path: ["library", "pois"],
        Component: poi,
        nav: {
          name: "POIs",
          icon: Pin,
        },
      },
    ],
  },
  {
    ...baseSettingsRoute,
    subroutes: [
      {
        ...baseSettingsRoute,
        nav: {
          name: "Account",
          icon: User,
        },
      },
      {
        ...baseSettingsRoute,
        path: ["settings", "security"],
        Component: SecuritySettings,
        nav: {
          name: "Security",
          icon: Lock,
        },
      },
      {
        ...baseSettingsRoute,
        path: ["settings", "billing"],
        Component: BillingSettings,
        nav: {
          name: "Billing",
          icon: CreditCard,
        },
      },
      {
        ...baseSettingsRoute,
        path: ["settings", "checkout"],
        Component: Checkout,
        nav: null,
      },
      // {
      //   ...baseSettingsRoute,
      //   path: ["settings", "followers"],
      //   Component: FollowerSettings,
      //   nav: {
      //     name: "Followers",
      //     icon: Users,
      //   },
      // },
      {
        ...baseSettingsRoute,
        path: ["settings", "trip"],
        Component: OverallTripSettings,
        nav: {
          name: "Trips",
          icon: Globe2,
        },
      },
      {
        ...baseSettingsRoute,
        path: ["settings", "map"],
        Component: MapSettings,
        nav: {
          name: "Maps",
          icon: Map,
        },
      },
      {
        ...baseSettingsRoute,
        path: ["settings", "branding"],
        Component: BrandSettings,
        nav: {
          name: "Branding",
          icon: Store,
        },
      },
      {
        ...baseSettingsRoute,
        path: ["settings", "referral"],
        Component: Referral,
        nav: {
          name: "Referral",
          icon: HeartHandshake,
        },
      },
      {
        ...baseSettingsRoute,
        path: ["settings", "deepl"],
        Component: DeeplSettings,
        nav: {
          name: "Deepl",
          icon: Languages,
        },
      },
      {
        ...baseSettingsRoute,
        path: ["settings", "integrations"],
        Component: IntegrationsSettings,
        nav: {
          name: "Integrations",
          icon: Cog,
        },
      },
    ],
  },
];

export const getRoutes = ({ flatten, onlyNavs }: { flatten: boolean; onlyNavs: boolean }) => {
  let result: TRoute[];

  if (ENV !== "production") {
    routes.push({
      path: ["lead"],
      Component: leadForm,
      type: "private",
      roles: [Role.ADMIN, Role.USER],
      nav: null,
    });
  }

  // Build the paths as strings
  result = routes.map((route) => ({
    ...route,
    path: "/" + route.path.join("/"),
    subroutes: route.subroutes?.map((subroute) => ({
      ...subroute,
      path: "/" + subroute.path.join("/"),
    })),
  })) as TRoute[];
  // Filter out the navs
  if (onlyNavs) {
    result = result.filter((route) => route.nav);
    result = result.map((route) => ({
      ...route,
      subroutes: route.subroutes?.filter((route) => route.nav),
    }));
  }
  // Flatten if necessary
  if (!flatten) return result;
  return result.flatMap((route) => (route.subroutes ? [route, ...route.subroutes] : [route]));
};
