import { TranslationField, User } from "@lato/common";

export const resolveIncorrectTranslationFields = (
  fields: TranslationField[],
  first: boolean,
  firstSelectedLanguageIndex: number,
  secondSelectedLanguageIndex: number,
  tripTranslations: any,
  me: User,
) => {
  return fields.map((d) => {
    if (d && Object.keys(d).length === 1 && d.hasOwnProperty("content")) {
      const languageIndex = first ? firstSelectedLanguageIndex : secondSelectedLanguageIndex;
      return {
        content: d.content,
        language_code: tripTranslations && tripTranslations.priceDescriptions[languageIndex].language_code,
        creator: me,
      };
    }
    return d;
  });
};
