import SettingsStep from "../../../components/trips/edit/daybyday/settings/SettingsStep";
import SampleStepWrapper from "../SampleStepWrapper";

export const SettingsSampleRoute: React.FC = () => {
  const stepNumber = 6;

  return (
    <SampleStepWrapper>
      <SettingsStep stepNumber={stepNumber} />
    </SampleStepWrapper>
  );
};

export default SettingsSampleRoute;
