import axios from "axios";
import config from "../../config";
import ENV from "../../utils/env";
import rawApiProvider from "./rawProvider";

// Define your api url from any source.
const api_version = "/api/v1";
const BASE_URL = config[ENV].api_url + api_version;
axios.defaults.baseURL = BASE_URL; // the prefix of the URL
axios.defaults.headers.get["Accept"] = "application/json"; // default header for all get request
axios.defaults.headers.post["Accept"] = "application/json"; // default header for all POST request
axios.defaults.headers.common["Content-Type"] = "application/json"; // default header for all requests
axios.defaults.withCredentials = true; // Include the cookie credentials by default

// Destructure the raw api provider
const { get, post, put, patch, remove } = rawApiProvider;

/** @param {string} resource */
const getAll = async (resource: string) => {
  return get(resource);
};

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource: string, id: string) => {
  return get(`${resource}/${id}`);
};

const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
};
export default apiProvider;
