import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import TabButton from "../../_app/layout/TabButton";
import CustomModal from "./CustomModal";

interface TabModalProps extends Partial<ModalProps> {
  title: string;
  tabs: string[];
  disclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
  tabHeaderWidth?: string;
  showHeader?: boolean;
  defaultIndex?: number;
  inModal?: boolean;
}

const TabModal: React.FC<TabModalProps> = ({
  title,
  tabs,
  disclosure,
  children,
  tabHeaderWidth = "300px",
  defaultIndex,
  inModal = true,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = disclosure;

  const tabsElement = () => {
    return (
      <Tabs variant="soft-rounded" colorScheme="brand" onClick={(e) => e.stopPropagation()} defaultIndex={defaultIndex}>
        <TabList mx="auto" bg="white" zIndex={3} pos="sticky" top={"-0.5em"}>
          <Flex w={tabHeaderWidth} mx="auto" justifyContent={"space-between"}>
            {tabs.map((tabTitle, i) => (
              <Tab key={`tabbutton-${i}`}>{tabTitle}</Tab>
            ))}
          </Flex>
        </TabList>
        <TabPanels>{children && Array.isArray(children) && children}</TabPanels>
      </Tabs>
    );
  };

  if (!inModal) {
    return tabsElement();
  }

  return (
    <CustomModal title={title} isOpen={isOpen} onClose={onClose} size="3xl" {...props}>
      {tabsElement()}
    </CustomModal>
  );
};
export default TabModal;
