import { prettyPrintDate } from "./date";

export function prettyPrintDatePlusDuration(
  start: Date,
  duration: number,
  type: "days" | "nights",
  minDurationToShow = 2,
): string {
  const startDate = new Date(start);
  return (
    prettyPrintDate(startDate, "nl", "full", false, undefined, false) +
    (duration >= minDurationToShow
      ? " • " +
        duration +
        " " +
        (type === "days" ? (duration <= 1 ? "day" : "days") : duration <= 1 ? "night" : "nights")
      : "")
  );
}

export default prettyPrintDatePlusDuration;
