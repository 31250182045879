import { LinkIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Code,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
} from "@chakra-ui/react";
import { PaymentInvitation, Plan, TRIP_STATUS, Trip, User, UserTrip } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { BiSolidShare } from "react-icons/bi";
import { BsPrinter } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { usePaymentInvitations } from "../../../../api/paymentInvitations.api";
import { useMeContext } from "../../../../stores/me-context";
import { useTripLanguage } from "../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import {
  DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY,
  SHARE_STEP_TRIP_QUERY_KEY,
  SHARE_STEP_USER_TRIP_QUERY_KEY,
} from "../../../../utils/constants";
import ENV from "../../../../utils/env";
import { getCollaborateTripLink, getViewTripLink } from "../../../../utils/generateURLs";
import { grantAtLeast } from "../../../../utils/grantPermission";
import {
  useCollaborationInvitationStatus,
  useUserTripNotes,
  useUserTripTasks,
} from "../../../../utils/query-helpers/reactQueryHooks";
import {
  getRelationMap,
  updateRelationMap,
  usePatchSplitTrip,
  usePatchSplitUserTrip,
  useSplitTrip,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { CustomSpinner, ShareSkeleton, WizardSkeleton } from "../../../FullScreenSpinner";
import CustomModal from "../../../layout/CustomModal";
import Section from "../../../layout/Section";
import Wizard from "../../../../features/editTrip/wizard/Wizard";
import { NotesWrapper } from "../../../../features/notes/Notes";
import TripForm from "../../TripForm";
import PrintLanguageModal from "../../print-trip/PrintLanguageModal";
import TripSettings from "../daybyday/settings/TripSettings";
import { TasksWrapper } from "../daybyday/settings/TasksWrapper";
import SendTripMail from "./SendTripMail";
import PaymentInvitationsWrapper from "./paymenyInvitations/PaymentInvitationsWrapper";

interface ShareStepProps {
  stepNumber: number;
}
const ShareStep: React.FC<ShareStepProps> = ({ stepNumber }) => {
  const me = useMeContext();
  const travellerTripMailDisclosure = useDisclosure();
  const profTripMailDisclosure = useDisclosure();
  const { id: userTripId } = useParams<{ id: string }>();
  const { trip: tripFromStore, isFirst, isSample } = useTripFormStore();

  const printModalDisclosure = useDisclosure();

  const { firstSelectedLanguageIndex } = useTripLanguage();

  // Checkdata contains another userTripId if the user has or had access to this collaboration invitation.
  // If the invitation was already accepted and the user who accepted the invite is now again requesting the invitation.: another userTripId (the new one) is returned.
  // Else: the same userTripId is returned. (this means that a valid collaboration request should be handled)
  const { data: checkData, isLoading: loadingCheck, error: errorCheck } = useCollaborationInvitationStatus(userTripId!);
  const allUserTrips = checkData?.userTrips;

  const { data: trip, isLoading: isLoadingTrip } = useSplitTrip(
    tripFromStore?.id ?? "",
    SHARE_STEP_TRIP_QUERY_KEY,
    getRelationMap[SHARE_STEP_TRIP_QUERY_KEY],
  );

  const usertrip = allUserTrips && allUserTrips[isFirst ? 0 : 1];

  if (isLoadingTrip || !trip || !usertrip || !me) {
    return (
      <>
        <WizardSkeleton />
        <ShareSkeleton />
      </>
    );
  }

  const usertripCollab = allUserTrips.length > 1 && allUserTrips[1];
  const viewTripURL = getViewTripLink(usertrip.user.company.name, trip.titles && trip.titles[0].content, usertrip.id!);
  const collaborateTripURL = getCollaborateTripLink(usertrip.id!);
  const canShareProfessionalLink = me ? grantAtLeast(me.company.plan, Plan.PRO) : false;

  // TODO: could be improved: when the first user loads the trip, he only receives 1 usertrip.
  // Thus will still see the professional link eventhough someone might already have accpeted it. Of course the link won't work but it shouldn't be shown.
  // show the professional link if the person is logged in and only 1 usertrip is present and the trip is not yet cancelled.
  const showProfessionalLink =
    !!me &&
    !isSample &&
    canShareProfessionalLink &&
    ENV !== "staging" &&
    //!(usertrips.length > 1) &&
    usertrip.status !== TRIP_STATUS.CANCELED;

  return (
    <>
      {!me.useNewEditTripLayout &&
        (trip ? (
          <Wizard
            trip={trip}
            collaboratorCanEdit={trip.collaboratorCanEdit}
            stepNumber={stepNumber}
            isFirst={isFirst}
            sample={isSample}
            type="Edit"
          />
        ) : (
          <WizardSkeleton />
        ))}

      <Flex justifyContent={"space-between"} alignItems={"center"} w={me.useNewEditTripLayout ? "100%" : "99%"}>
        <Box>
          <Heading size="lg" mt={6}>
            Share this trip
          </Heading>
          <Text fontSize="md" color="realGray.600">
            Share this trip with your collaborating travel agent or directly with your clients.
          </Text>
        </Box>
        <Button
          mt={3}
          colorScheme="brand"
          variant="outline"
          rightIcon={<Icon mt={"0.2em"} as={BsPrinter} />}
          onClick={printModalDisclosure.onOpen}
        >
          Export file
        </Button>
      </Flex>

      <Stack
        position={"relative"}
        mt={3}
        spacing={me.useNewEditTripLayout ? 0 : 3}
        gap={8}
        direction={me.useNewEditTripLayout ? "column" : ["column", "column", "row"]}
      >
        {showProfessionalLink && (
          <Flex gap={4} minW="1px">
            <Section
              w={showProfessionalLink && !me.useNewEditTripLayout ? ["100%", "100%", "100%"] : "100%"}
              m={0}
              heading={
                <Flex justifyContent={"space-between"}>
                  <Flex alignItems="center">
                    <LinkIcon mr={2} />
                    <Heading size="lg">{allUserTrips.length > 1 ? "Collaboration" : "Professional link"}</Heading>
                  </Flex>
                  {usertripCollab && (
                    <Badge colorScheme={"green"} p={"0.6em"} margin={"-1px"}>
                      Accepted
                    </Badge>
                  )}
                </Flex>
              }
            >
              {usertripCollab ? (
                <Flex flexDir={"column"} gap={2}>
                  <Flex justifyContent={"space-between"}>
                    <Flex w="75%" alignItems="center" gap={4}>
                      <Avatar
                        src={usertripCollab.user.avatar ? usertripCollab.user.avatarUrl : ""}
                        size="md"
                        name={usertripCollab.user.name}
                      />
                      <Box>
                        <Heading size="lg">{usertripCollab.user.name}</Heading>
                        <Text>{usertripCollab.user.email}</Text>
                      </Box>
                    </Flex>
                    {usertripCollab.brand.logoUrl && (
                      <Flex w="75%" justifyContent="flex-end" alignItems={"center"}>
                        <Image
                          alt={usertripCollab.brand.name}
                          className="brand-logo"
                          src={usertripCollab.brand.logoUrl}
                          style={{
                            maxHeight: `calc(${usertripCollab.brand.logoScale}*1.4em)`,
                          }}
                        />
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              ) : (
                <>
                  <Text fontSize="sm" fontWeight={500} color="realGray.400">
                    To collaborate, share this link with another travel agency.
                  </Text>
                  <Flex w="75%" alignItems="stretch" minW={"100%"}>
                    <ShareLink
                      tripId={trip.id}
                      url={collaborateTripURL}
                      isLoading={loadingCheck || !checkData}
                      // modalText={`'${usertrip.brand.name}' is inviting you to collaborate on a trip: '${trip.titles[0].content}'.\n\n1. Take a look at the end result:\n${viewTripURL}\n\n2. Customize the trip:\n${collaborateTripURL}`}
                      alreadyShared={!!errorCheck || (!!checkData && checkData.status === "error")}
                    />
                    <SendTripMail
                      userTripId={usertrip.id!}
                      travellerLink={false}
                      disclosure={travellerTripMailDisclosure}
                    />
                  </Flex>
                </>
              )}
            </Section>
          </Flex>
        )}
        <Section
          // w={"100%"}
          minW="1px"
          flex="unset"
          heading={
            <Flex alignItems="center">
              {/* <AtSignIcon mr={2} /> */}
              <Icon as={BiSolidShare} mr={2} />
              <Heading size="lg" display="flex" width={"100%"} justifyContent={"space-between"}>
                Traveler link{" "}
                {
                  <div className="relative">
                    <Tooltip label="Total visits, including your own.">
                      <Text size="sm">
                        {usertrip.travelapp_visits} {usertrip.travelapp_visits === 1 ? "visit" : "visits"}
                      </Text>
                    </Tooltip>
                  </div>
                }
              </Heading>
            </Flex>
          }
        >
          <Text fontSize="sm" fontWeight={500} color="realGray.400">
            Share this mobile-friendly itinerary with your clients or travellers.
          </Text>
          <Flex alignItems="stretch" width="80%" minW={"100%"}>
            <ShareLink
              tripId={trip.id}
              url={viewTripURL}
              dark={true}
              // modalText={`You are invited to view a trip.\n
              //   In order to confirm/book the trip, use your groupsname (${trip.client_name}) as booking password.\n
              //   Link to your trip:
              //     ${viewTripURL}`}
              // modalText={`You are invited to view a trip.\n
              // Link to your trip:
              //   ${viewTripURL}\n\nPowered by Lato`}
            />
            <SendTripMail userTripId={usertrip.id!} travellerLink={true} disclosure={profTripMailDisclosure} />
          </Flex>
        </Section>
      </Stack>
      {!me.useNewEditTripLayout && <ShareStepForm trip={trip} usertrips={allUserTrips} isFirst={isFirst} />}
      {printModalDisclosure.isOpen && (
        <PrintLanguageModal
          userTripid={userTripId!}
          modalDisclosure={printModalDisclosure}
          defaultLanguageIndex={firstSelectedLanguageIndex}
        />
      )}
    </>
  );
};
export default React.memo(ShareStep);
interface ShareStepFormProps {
  usertrips: UserTrip[];
  trip: Trip;
  tripPaymentInvitations?: PaymentInvitation[];
  isFirst: boolean;
  me?: User;
}

const ShareStepForm: React.FC<ShareStepFormProps> = ({ usertrips, trip, isFirst }) => {
  const formId = `4-${SHARE_STEP_USER_TRIP_QUERY_KEY}`;

  const queryClient = useQueryClient();
  const { id: userTripId } = useParams<{ id: string }>();
  const { trip: tripFromStore, isSample } = useTripFormStore();

  const { data: notes, isLoading: isLoadingNotes } = useUserTripNotes(userTripId!);
  const {
    data: tasks,
    isFetching: isLoadingTasks,
    isFetching: isFetchingTasks,
    refetch: refetchTasks,
  } = useUserTripTasks(userTripId!);

  // const {
  //   data: paymentInvitations,
  //   isLoading: isLoadingPaymentInvitations,
  //   isFetching: isFetchingPaymentInvitations,
  //   refetch: refetchPaymentInvitations,
  // } = usePaymentInvitations(userTripId!);

  const defaultValues = { id: tripFromStore.id, userTrips: usertrips, collaboratorCanEdit: trip.collaboratorCanEdit };
  const formMethods = useForm<{ id: string; userTrips: UserTrip[]; collaboratorCanEdit: boolean }>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  const { mutateAsync: updateTrip, isPending: isLoadingUpdate } = usePatchSplitTrip(
    tripFromStore.id ?? "",
    updateRelationMap[SHARE_STEP_TRIP_QUERY_KEY],
    queryClient,
    SHARE_STEP_TRIP_QUERY_KEY,
  );

  const { mutateAsync: updateUserTrip, isPending: isLoadingUserTripUpdate } = usePatchSplitUserTrip(
    tripFromStore.userTrips[isFirst ? 0 : 1].id ?? "",
    updateRelationMap[SHARE_STEP_USER_TRIP_QUERY_KEY],
    queryClient,
    SHARE_STEP_USER_TRIP_QUERY_KEY,
  );

  const handleSubmit = async () => {
    const tripValues = formMethods.getValues();

    const userTripValues = tripValues.userTrips[isFirst ? 0 : 1];

    console.log("pp updating trip", userTripValues.daysAfterTripCancelled);

    await new Promise<void>(async (resolve) => {
      updateTrip({ id: tripValues.id, collaboratorCanEdit: tripValues.collaboratorCanEdit });

      const newData = {
        user: userTripValues.user,
        brand: userTripValues.brand,
        max_travellers: userTripValues.max_travellers,
        booked_travellers: userTripValues.booked_travellers,
        group_booking: userTripValues.group_booking,
        show_directions: userTripValues.show_directions,
        show_pois: userTripValues.show_pois,
        show_pdf: userTripValues.show_pdf,
        show_documents: userTripValues.show_documents,
        show_price: userTripValues.show_price,
        poi_range: userTripValues.poi_range,
        status: userTripValues.status,
        book_link: userTripValues.book_link,
        chat_enabled: userTripValues.chat_enabled,
        daysAfterTripCancelled: userTripValues.daysAfterTripCancelled,
      };
      updateUserTrip(newData);

      if (userTripValues.show_directions != usertrips[isFirst ? 0 : 1].show_directions) {
        queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_USERTRIP_QUERY_KEY] });
      }
      resolve();

      formMethods.reset({ ...formMethods.getValues(), userTrips: tripValues.userTrips });
    });
  };

  if (isLoadingUpdate || isLoadingUserTripUpdate) {
    return <ShareSkeleton />;
  }

  return (
    <>
      <TasksWrapper
        tasks={tasks}
        isLoadingTasks={isLoadingTasks}
        isFetchingTasks={isFetchingTasks}
        start_date={trip.start_date!}
        end_date={trip.end_date!}
        userTrip={usertrips[isFirst ? 0 : 1]}
        refetch={refetchTasks}
      />
      {/* <PaymentInvitationsWrapper
        paymentInvitations={paymentInvitations}
        isLoadingPaymentInvitations={isLoadingPaymentInvitations}
        isFetchingPaymentInvitations={isFetchingPaymentInvitations}
        trip={trip}
        isFirst={isFirst}
        usertrip={usertrips[isFirst ? 0 : 1]}
        refetch={refetchPaymentInvitations}
      /> */}
      <Flex
        flexDir={["column", "column", "column", "column", "row"]}
        width={["100%", "100%", "98.5%", "98.5%", "98.5%"]}
        gap={3}
        w="100%"
      >
        <TripForm
          triggerResetData={trip}
          defaultValues={defaultValues}
          formId={formId}
          formMethods={formMethods}
          onSubmit={handleSubmit}
          flexGrow={0.15}
        >
          <Button
            type="submit"
            hidden
            className="form-submit-button"
            isLoading={isLoadingUpdate || isLoadingUserTripUpdate}
          >
            Save
          </Button>
          <TripSettings isFirst={isFirst} sample={trip.sample} />
        </TripForm>
        <Box mt={3} flexGrow={1}>
          <Heading size="lg">Internal notes</Heading>
          <Text
            fontSize="md"
            color="realGray.600"
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            width={"70%"}
            mb={"0.9em"}
          >
            Some private notes not shared with your client or another professional.
          </Text>
          {/*<Section
              m={0}
              w="100%"
              noDivider
              mt={3}
              pt={0.5}
              h={"calc(100% - 3.4em)"}
            >
              <RHFRichTextEditor
                name={`userTrips.${first ? 0 : 1}.notes`}
                placeholder="..."
                inputHeight={"95%"}
                classNameFormControl={"full-height-input"}
                // defaultValue={getValues()?.userTrips[first ? 0 : 1].notes}
              />
            </Section>*/}
          {isLoadingNotes ? (
            <CustomSpinner m="auto" />
          ) : (
            <NotesWrapper
              parentObject={usertrips[isFirst ? 0 : 1]}
              maxHeight={"none"}
              height={"92%"}
              hideSmallAdd
              notes={notes}
            />
          )}
        </Box>
      </Flex>
    </>
  );
};

interface ShareLinkProps {
  tripId: string | undefined;
  url: string;
  dark?: boolean;
  modalText?: string;
  isLoading?: boolean;
  alreadyShared?: boolean;
}
const ShareLink: React.FC<ShareLinkProps> = ({
  tripId,
  url,
  modalText,
  dark = false,
  isLoading = false,
  alreadyShared = false,
}) => {
  const { hasCopied, onCopy } = useClipboard(modalText ?? url);
  const bg = hasCopied ? "green.400" : dark ? "#0e1e25" : "lightGray.200";
  const { onClose, isOpen, onOpen } = useDisclosure();

  const renderContent = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (alreadyShared) {
      return <div>Already shared</div>;
    }

    if (tripId) {
      return (
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="sm" fontWeight="500" isTruncated>
            {hasCopied ? `${modalText ? "Text" : "Link"} copied to your clipboard` : url}
          </Text>
          {!hasCopied && (
            <IconButton
              icon={<Icon as={FaRegCopy} boxSize={4} />}
              aria-label="Copy url to clipboard"
              variant="basic"
              colorScheme={dark ? "white" : "gray"}
              size="xs"
            />
          )}
        </Flex>
      );
    }

    return <Text fontSize="sm">First save the changes before sharing!</Text>;
  };

  return (
    <>
      <Flex
        p={3}
        h="45px"
        bg={bg}
        borderRadius="md"
        color={dark || hasCopied ? "white" : "gray.500"}
        mt="auto"
        flexDir="column"
        justifyContent="center"
        onClick={modalText ? onOpen : onCopy}
        _hover={{ cursor: "pointer" }}
        minW={"1px"}
      >
        {renderContent()}
      </Flex>
      <CustomModal
        title="Copy trip invitation"
        onClose={onClose}
        isOpen={isOpen}
        size="lg"
        footer={
          <>
            <Button
              onClick={() => {
                onCopy();
                onClose();
              }}
              colorScheme="brand"
              mr={2}
            >
              Copy invitation
            </Button>
            <Button onClick={onClose}>Close</Button>
          </>
        }
      >
        <Code whiteSpace="pre-line" p={3}>
          {modalText}
        </Code>
      </CustomModal>
    </>
  );
};
