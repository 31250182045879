import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "tasks";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

// const getTasksFromCompany = (contactId: string) => {
//     return rawApiProvider.get(`${url}/contact/${contactId}`);
//   };

const getTasksFromUserTrip = (userTripId: string) => {
  return rawApiProvider.get(`${url}/trip/${userTripId}`);
};

const getTasksFromContact = (contactId: string) => {
  return rawApiProvider.get(`${url}/contact/${contactId}`);
};

const patchTask = (model: object[]) => {
  return rawApiProvider.patch(`${url}`, model);
};

const addTask = (model: object) => {
  return rawApiProvider.post(`${url}`, model);
};

const addTaskToTrip = (model: object, tripId?: string) => {
  return rawApiProvider.post(`${url}/trip/${tripId}`, model);
};

type TasksAPIType = ReturnEndpoints & {
  getTasks: (userId: string) => Promise<any>;
  getTasksFromUserTrip: (userTripId: string) => Promise<any>;
  getTasksFromContact: (contactId: string) => Promise<any>;
  patchTask(model: object): Promise<any>;
  addTaskToTrip(model: object, tripId: string): Promise<any>;
  addTask(model: object): Promise<any>;
  delete(id: string): Promise<any>;
};
const TasksAPI: TasksAPIType = {
  ...apiEndpoints,
  getTasks: apiEndpoints.getAll,
  getTasksFromUserTrip,
  getTasksFromContact,
  patchTask,
  addTask,
  addTaskToTrip,
};
export default TasksAPI;
