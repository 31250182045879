import { Box } from "@chakra-ui/react";
import React from "react";
import { maxFileSizeMB } from "./ImageUploader";

interface ImageUploaderButtonProps {
  handleChosenImage(image: File): void;
  children: React.ReactNode;
}

const ImageUploaderButton: React.FC<ImageUploaderButtonProps> = ({ handleChosenImage, children }) => {
  const hiddenInput = React.useRef<any>(null);

  return (
    <>
      <Box onClick={() => hiddenInput.current.click()} _hover={{ cursor: "pointer" }}>
        {children}
      </Box>
      <input
        hidden
        type="file"
        accept="image/*"
        ref={hiddenInput}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (file.size > 1000000 * maxFileSizeMB) {
              // File larger than 10MB
              alert(`Image exceeds file size limit of ${maxFileSizeMB}MB`);
            } else {
              // File size smaller than 10MB
              // Set the input value to null such that subsequently choosing the same file will trigger the onChange
              e.target.value = "";
              // Handle what should happen when the image is chosen
              handleChosenImage(file);
            }
          }
        }}
      />
    </>
  );
};
export default ImageUploaderButton;
