const fonts = {
  // heading: "-apple-system, BlinkMacSystemFont, brand",
  // body: "-apple-system, BlinkMacSystemFont, brand",
  heading:
    "system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Helvetica,Fira Sans,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
  body: "system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Helvetica,Fira Sans,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
};
export default fonts;

// in another .tsx file:
// and import it in index.tsx
// import { Global } from "@emotion/react";

// const Fonts = () => (
//   <Global
//     styles={`
//       /* latin */
//       @font-face {
//         font-family: 'brand';
//         font-style: normal;
//         font-weight: 400;
//         font-display: swap;
//         src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
//         unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//       }
//   `}
//   />
// );
// export default Fonts;
