import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Select,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { DefaultDocument, Document, EDocument, Trip, isLink } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useFieldArray, useForm, useFormContext, useWatch } from "react-hook-form";
import { BsFileEarmark as FileEarmark } from "react-icons/bs";
import { IoChevronDownSharp as ChevronDown, IoChevronForwardSharp as ChevronForward } from "react-icons/io5";
import { CellProps, Column, useAsyncDebounce } from "react-table";
import { GENERAL_STEP_DOCUMENTS_QUERY_KEY } from "../../utils/constants";
import { DevTool } from "../../utils/hookform-devtools";
import {
  updateRelationMap,
  usePatchSplitTrip,
  useTripDocuments,
} from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { submitDocumentsArray } from "../../utils/submitDocumentsArray";
import { useReorderFieldarray } from "../../utils/useReorderFieldarray";
import { tripValidationSchemas } from "../../validation/validationSchemas";
import { CARDSIZES } from "../../components/CRUD/CardView";
import CRUDResource, { BadgeCount, ResourceTitle, SmallAddResourceButton } from "../../components/CRUD/Resource";
import { CustomSpinner } from "../../components/FullScreenSpinner";
import { CardProps } from "../../components/TripsList";
import RHFInput from "../../components/input/RHFInput";
import Section from "../../components/layout/Section";
import TripForm from "../../components/trips/TripForm";
import DocumentUploadModal from "./DocumentUploadModal";
import { ExternalLink, FileDown } from "lucide-react";

interface DocumentsProps {
  // push: (obj: any) => void;
  // remove: <T>(index: number) => T | undefined;
  trip?: Trip;
  tripId?: string;
  tripdocs?: any[];
  tripDefaultdocs?: DefaultDocument[];
  fieldArrayName: string;
  defaultFieldArrayName?: string;
  inForm?: boolean;
  showHeading?: boolean;
  handleEdit?: (index: number, newRow: any) => void;
  handleRemove?: (index: number) => void;
  disableLinks?: boolean;
  useBigHeading?: boolean;
  showDeleteConfirm?: boolean;
  disabled?: boolean;
  showDocumentType?: boolean;
}

export const documentDisplay: { [key in EDocument | string]: any } = {
  [EDocument.EBOOK]: "e-book",
  [EDocument.VIDEO]: "video",
  [EDocument.PODCAST]: "podcast",
  [EDocument.OTHERDOC]: "other",
  [EDocument.OTHERLINK]: "other",
  [EDocument.MUSIC]: "music",
  [EDocument.TRAVELINFODOC]: "travel information document",
  [EDocument.TRAVELINFOLINK]: "travel information link",
  [EDocument.ESIM]: "e-sim",
  [EDocument.TRAVELREQDOC]: "travel requirements",
  [EDocument.TRAVELREQLINK]: "travel requirements",
  [EDocument.VOUCHERDOC]: "voucher",
  [EDocument.VOUCHERLINK]: "voucher",
};

export const view_document_cell = (doc: Document) => {
  return (
    <Tooltip label={isLink(doc.type) ? "Open" : "Download"} aria-label="view-document-tooltip">
      <Link href={doc.s3url} isExternal>
        <IconButton
          // colorScheme="blue"
          aria-label="view-document"
          icon={<Icon as={isLink(doc.type) ? ExternalLink : FileDown} boxSize={4} />}
          onClick={(e) => {
            e.stopPropagation();
          }}
          w="fit-content"
          variant="ghost"
        />
      </Link>
    </Tooltip>
  );
};

export const DocumentsFormWrapper: React.FC<DocumentsProps> = ({ ...props }) => {
  const { data: tripDocumentsData, isFetching: isLoadingDocuments } = useTripDocuments(props.tripId ?? "");

  if (isLoadingDocuments || !tripDocumentsData || !props.tripId) return <CustomSpinner card skeleton />;
  return (
    <DocumentsForm
      {...props}
      tripId={props.tripId}
      tripdocs={tripDocumentsData.tripdocs}
      tripDefaultdocs={tripDocumentsData.tripdefaultdocs}
    />
  );
};

export const DocumentsForm: React.FC<DocumentsProps> = ({ trip, tripId, ...props }) => {
  const formId = `0-${GENERAL_STEP_DOCUMENTS_QUERY_KEY}`;
  const queryClient = useQueryClient();

  const formMethods = useForm<Trip>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      id: trip?.id ?? "",
      tripdocs: props?.tripdocs ?? [],
      tripdefaultdocs: props?.tripDefaultdocs ?? [],
    },
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(tripValidationSchemas[0], { abortEarly: false }),
  });

  const { mutateAsync: updateTrip, isPending: isLoadingUpdate } = usePatchSplitTrip(
    tripId ?? "",
    updateRelationMap[GENERAL_STEP_DOCUMENTS_QUERY_KEY],
    queryClient,
    GENERAL_STEP_DOCUMENTS_QUERY_KEY,
  );

  const handleSubmit = async () => {
    const tripValues = formMethods.getValues();
    await new Promise<void>(async (resolve) => {
      await submitDocumentsArray(tripValues.tripdocs, "tripdocs/");
      updateTrip({ id: tripValues.id, tripdocs: tripValues.tripdocs, tripdefaultdocs: tripValues.tripdefaultdocs });
      resolve();
    });
  };

  useEffect(() => {
    let docs = formMethods.getValues("tripdocs");
    let change = false;

    docs = (docs ?? []).map((doc: any, index: number) => {
      if ((props.tripdocs ?? [])[index]?.id && !doc?.id && (props.tripdocs ?? [])[index].s3url !== doc.s3url) {
        change = true;
        return (props.tripdocs ?? [])[index];
      }
      return doc;
    });

    if (change) {
      formMethods.setValue("tripdocs", docs);
    }
  }, [props.tripdocs]);

  return (
    <TripForm formId={formId} formMethods={formMethods} onSubmit={handleSubmit}>
      <Button type="submit" hidden className="form-submit-button">
        Save
      </Button>
      <Documents {...props} trip={trip} tripId={tripId} />
    </TripForm>
  );
};

const Documents: React.FC<DocumentsProps> = ({
  // push,
  // remove,
  fieldArrayName,
  defaultFieldArrayName,
  inForm = false,
  showHeading = true,
  handleEdit,
  handleRemove,
  disableLinks = false,
  useBigHeading = false,
  showDeleteConfirm = true,
  disabled = false,
  showDocumentType = true,
}) => {
  const { control, register, getValues, setValue } = useFormContext<any>();

  const defaultFieldArrayMethods = useFieldArray({
    name: defaultFieldArrayName as any,
    control: control,
    // @ts-ignore
    keyName: "rhfId",
  });

  const documentsWatch = useWatch({
    name: fieldArrayName as any,
  }) as Document[];
  const defaultDocumentsWatch = useWatch({
    name: defaultFieldArrayName as any,
  }) as Document[];

  const fieldArrayMethods = useFieldArray({
    // control,
    name: fieldArrayName as any,
    control: control,
    // @ts-ignore
    keyName: "rhfId", // default to "id", you can change the key name
  });

  const { fields: documents, append, remove, replace } = fieldArrayMethods;
  const { fields: defaultDocuments, replace: replaceDefault } = defaultFieldArrayMethods;
  const [collapsed, setCollapsed] = React.useState<boolean>(!inForm);

  useEffect(() => {
    (defaultDocumentsWatch?.length ? defaultDocumentsWatch : []).forEach((defDoc: any) => {
      if (defaultDocuments.filter((doc: any) => doc.url === defDoc.url).length === 0) {
        defaultDocuments.push(defDoc);
      }
    });

    (documentsWatch?.length ? documentsWatch : []).forEach((doc: any) => {
      if (documents.filter((dc: any) => dc.s3url === doc.s3url).length === 0) {
        documents.push(doc);
      }
    });
  }, [defaultDocumentsWatch, documentsWatch]);

  const disclosure = useDisclosure();
  const columns: Column<Document>[] = React.useMemo(
    () => [
      {
        accessor: "displayName",
        Header: "Name*",
        Cell: ({ value, row, column }) => {
          if ((row.original as any).isNotOriginal) {
            return (
              <Tooltip label="Default documents can not be edited">
                <Text key={`doc-displayname-${(row.original as any).rhfId}`} ml={3}>
                  {value}
                </Text>
              </Tooltip>
            );
          }
          return (
            <RHFInput
              name={`${fieldArrayName}.${(row.original as any).index}.${column.id}` as any}
              onClick={(e) => e.stopPropagation()}
              onChange={useAsyncDebounce((e) => {
                handleEdit &&
                  handleEdit(row.index, {
                    ...row.original,
                    displayName: e.target.value,
                  });

                setValue(`${fieldArrayName}.${(row.original as any).index}.${column.id}`, e.target.value, {
                  shouldDirty: true,
                });
              }, 500)}
              defaultValue={value}
              isDisabled={column.isDisabled || disabled}
              placeholder={column.placeholder}
              key={`doc-displayname-${(row.original as any).rhfId}`}
            />
          );
        },
      },
      {
        accessor: "type",
        Header: "Type*",
        hide: !showDocumentType,
        Cell: ({ value, row, column }) => {
          if ((row.original as any).isNotOriginal) {
            return (
              <Tooltip label="Default documents can not be edited">
                <Text key={`doc-type-${(row.original as any).rhfId}`} ml={3}>
                  {documentDisplay[value]}
                </Text>
              </Tooltip>
            );
          }
          return (
            <>
              <Select
                // @ts-ignore
                {...register(`${fieldArrayName}.${(row.original as any).index}.${column.id}` as any)}
                key={`doc-type-${(row.original as any).rhfId}`}
                // isDisabled={value === EDocument.LINK}
                defaultValue={value}
                disabled={disabled}
                onChange={(e) => {
                  handleEdit &&
                    handleEdit(row.index, {
                      ...row.original,
                      type: e.target.value,
                    });
                  setValue(`doc-type-${(row.original as any).rhfId}`, e.target.value, { shouldDirty: true });
                }}
              >
                {/* Never iterate over these! For some reason this will introduce some bugs */}
                {isLink(value)
                  ? Object.values(EDocument)
                      .filter((doc) => isLink(doc))
                      .map((doc, i) => (
                        <option key={`row-${row.index}-option${i}`} value={doc}>
                          {documentDisplay[doc]}
                        </option>
                      ))
                  : Object.values(EDocument)
                      .filter((doc) => !isLink(doc))
                      .map((doc, i) => (
                        <option key={`row-${row.index}-option${i}`} value={doc}>
                          {documentDisplay[doc]}
                        </option>
                      ))}
              </Select>
            </>
          );
        },
      },
      {
        id: "view-document",
        isNumeric: true,
        chakraWidth: "60px",
        Cell: ({ row }: CellProps<Document>) => view_document_cell(row.original),
      },
    ],
    [fieldArrayName, register, handleEdit],
  );

  async function handleRemoveDocument(index: number, doc: any) {
    if (!doc.rhfId) {
      replace(documents.filter((d: any) => d.s3url !== doc.s3url));
    }
    const docIndex = defaultDocuments.findIndex((d: any) => d.defaultDocument.id === doc.id);
    if (doc.isNotOriginal) {
      setValue(`${defaultFieldArrayName}.${docIndex}.inUse`, false);
      defaultDocuments[docIndex] = {
        ...defaultDocuments[docIndex],
        inUse: false,
      } as any;
      replaceDefault(defaultDocuments);
    } else {
      // If the document was just uploaded (hence a file is present) then revoke the temporary URL
      if ("file" in documents[doc.index] && (documents[doc.index] as any).file != undefined) {
        URL.revokeObjectURL((documents[doc.index] as any).s3url!);
      }
      // Removes the document from the rhf values at the given index
      remove(doc.index);
      // The actual s3 file is removed on the server such as removed passengers ans tripdays.
    }
    handleRemove && handleRemove(index);
  }

  const docs = getValues(fieldArrayName);

  const addDocument = (document: any) => {
    addDocumentField(document);
    handleEdit && handleEdit(documents.length, document);
  };

  const defaultDocs = getValues(defaultFieldArrayName ?? "");

  const { addField: addDocumentField, moveField: handleMove } = useReorderFieldarray(
    // fieldArrayName,
    [fieldArrayMethods, defaultFieldArrayMethods],
    [...(docs ?? []), ...(defaultDocs ?? []).filter((d: any) => d.inUse)].sort((a: any, b: any) => a.ord - b.ord),
    // getValues
  );

  const docHeading = (
    <DocumentsHeading
      setCollapsed={setCollapsed}
      collapsed={collapsed}
      inForm={inForm}
      count={documents.length + (defaultDocuments.length ?? 0)}
      openModal={disclosure.onOpen}
      showHeading={showHeading}
      useBigHeading={useBigHeading}
      disabled={disabled}
    />
  );

  return (
    <>
      {/* {fieldArrayName === "tripdocs" ? ( */}
      {!inForm || collapsed ? (
        <CRUDResource
          id="documents"
          formName={fieldArrayName}
          displayName="documents"
          padding={0}
          columns={columns as any}
          data={[
            ...documents.map((doc, index) => ({ ...doc, index })),
            ...(defaultDocuments.length
              ? defaultDocuments
                  .filter((doc) => (doc as any).inUse)
                  .map((doc, index) => ({
                    ...(doc as any).defaultDocument,
                    isNotOriginal: true,
                    ord: (doc as any).ord,
                    index,
                  }))
              : []),
          ].sort((a, b) => a.ord - b.ord)}
          handleAdd={disclosure.onOpen}
          showAddButtons={!disabled}
          handleDelete={disabled ? undefined : handleRemoveDocument}
          disableAddButton={disabled}
          handleEdit={handleEdit}
          inlineEdit={true}
          moveRow={(from, to) => handleMove(from, to, true)}
          inForm={inForm}
          heading={docHeading}
          enableGridView={true}
          useSpaciousLayout={false}
          showHeading={showHeading}
          cardViewComponent={DocumentCardView}
          noCardBorder={true}
          hideHeadersWhenEmpty={true}
          showDeleteConfirm={showDeleteConfirm}
          disableFilterSave={true}
          // heading2={
          //   <Dropzone
          //     fileLimitMB={fileLimitMB}
          //     fileTypeDisplayName="*.pdf documents"
          //     resourceName="travel documents"
          //     dropzoneState={dropzoneState}
          //   />
          // }
        />
      ) : (
        docHeading
      )}
      <DocumentUploadModal disclosure={disclosure} addDocument={addDocument} disableLinks={disableLinks} />
    </>
  );
};
export default React.memo(Documents);

export const DocumentsHeading = ({
  setCollapsed,
  collapsed,
  inForm,
  count,
  openModal,
  showHeading,
  useBigHeading,
  disabled = false,
}: any) => {
  return (
    <Flex alignItems="center" width={"100%"} justifyContent={"flex-start"}>
      <Flex
        alignItems="center"
        justifyContent={"flex-start"}
        cursor={inForm ? "pointer" : "default"}
        onClick={() => setCollapsed(!collapsed)}
        width={"100%"}
      >
        {useBigHeading ? (
          <>
            <Icon
              aria-label={collapsed ? "expand" : "collapse"}
              as={collapsed ? ChevronDown : ChevronForward}
              boxSize={6}
              color={"grey"}
            ></Icon>
            <Heading size={"lg"} my={"0.3em"} ml={"1.2em"}>
              {/*<Icon as={icon} mb={"0.3em"} boxSize={7} mr={"0.6em"} />*/}
              {"Documents"}
              {<BadgeCount totalCount={count} height={"1.5em"} />}
            </Heading>
          </>
        ) : (
          <>
            {inForm && (
              <Icon
                aria-label={collapsed ? "expand" : "collapse"}
                as={!collapsed ? ChevronForward : ChevronDown}
                marginRight={1}
                color={showHeading ? "black" : "gray"}
                boxSize={showHeading ? 4 : 6}
              ></Icon>
            )}
            {showHeading && <ResourceTitle displayName={"Documents"} totalCount={count} inForm={inForm} />}
          </>
        )}
      </Flex>
      {!disabled && (
        <SmallAddResourceButton
          onClick={(e) => {
            e.stopPropagation();
          }}
          singularDisplayName={"Document"}
          onAdd={() => {
            setCollapsed(true);
            openModal();
          }}
          ml={showHeading ? 0 : "auto"}
        />
      )}
    </Flex>
  );
};

export const DocumentCardView: React.FC<CardProps> = ({ key, row, size, handleEdit }) => {
  const [title, setTitle] = React.useState<string>(row.original.displayName);

  const fileType = (fileName: string) => {
    if (fileName.endsWith("pdf")) {
      return "pdf";
    } else if (fileName.endsWith("docx") || fileName.endsWith("doc")) {
      return "docx";
    } else if (fileName.endsWith("xls") || fileName.endsWith("csv")) {
      return "xlsx";
    } else if (
      fileName.endsWith("png") ||
      fileName.endsWith("img") ||
      fileName.endsWith("jpg") ||
      fileName.endsWith("jpeg")
    ) {
      return "img";
    }
    return fileName.split(".").slice(-1)[0];
  };

  const fileTypeColor: { [key in string]: string } = {
    ["pdf"]: "#ab0e0e",
    ["docx"]: "#3366cc",
    ["xlsx"]: "#147d37",
    ["img"]: "#5cbdba",
  };

  const fileEarmarkSize: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "172px",
    [CARDSIZES.L]: "90px",
    [CARDSIZES.MD]: "72px",
    [CARDSIZES.S]: "64px",
  };

  const fileEarmarkBottom: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "8em",
    [CARDSIZES.L]: "8.5em",
    [CARDSIZES.MD]: "6.5em",
    [CARDSIZES.S]: "7.5em",
  };

  const fileEarmarkFontSize: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "32px",
    [CARDSIZES.L]: "14px",
    [CARDSIZES.MD]: "12px",
    [CARDSIZES.S]: "10px",
  };

  const fileEarmarkTop: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "32px",
    [CARDSIZES.L]: "8em",
    [CARDSIZES.MD]: "6em",
    [CARDSIZES.S]: "3em",
  };

  const fileIcon = (fileType: string) => {
    const color = fileTypeColor[fileType];

    return (
      <Box px={1} py={"2px"} marginTop={fileEarmarkTop[size]} fontSize={"14px"} fontWeight={"700"} position="relative">
        <Icon as={FileEarmark} boxSize={fileEarmarkSize[size]} />
        {fileType && (
          <Box
            border={`2px solid ${color}`}
            borderRadius={"10px"}
            backgroundColor={color ?? "gray"}
            position="absolute"
            textAlign={"center"}
            color={"white"}
            bottom={fileEarmarkBottom[size]}
            left={2}
            right={2}
            fontSize={fileEarmarkFontSize[size]}
            isTruncated
          >
            {fileType.toUpperCase()}
          </Box>
        )}
      </Box>
    );
  };

  const optionsCell = row.cells.filter((cell: any) => cell.column.id === "view-document")[0];
  const deleteCell = row.cells.filter((cell: any) => cell.column.id === "delete-record")[0];
  const filetype = fileType(row.original.file ? row.original.file.path : row.original.s3url);
  return (
    <Section
      h={"100%"}
      w={"100%"}
      noDivider
      noHorizontalPadding
      noVerticalPadding
      noShadow
      paddingTop={0}
      paddingBottom={0}
      borderRadius={"10px"}
      key={`tripcard-${key}`}
      className={"document-card"}
      _hover={{
        boxShadow: "lg",
      }}
    >
      <Box position="relative" height={"100%"} width={"100%"} borderRadius={"10px"}>
        <Flex
          position="absolute"
          right={0}
          left={0}
          bottom={"5.5em"}
          top={0}
          zIndex={10}
          className={"trip-card-view-options document-card-preview"}
          justifyContent={"space-around"}
          display={"none"}
        >
          {optionsCell.render("Cell")}
        </Flex>

        <Box
          className={"document-card-overlap"}
          position="absolute"
          borderRadius={"10px"}
          top="0"
          bottom="0"
          left="0"
          right="0"
        />
        <Flex overflow="hidden" height={"100%"} width={"100%"} justifyContent={"center"}>
          {filetype === "img" ? (
            row.original.s3url ? (
              <Image
                background={"linear-gradient(rgba(255, 0, 0, 0.45), rgba(255, 0, 0, 0.45))"}
                borderRadius={"10px"}
                src={row.original.s3url}
                height={"80%"}
                width={"100%"}
                objectFit={"cover"}
              ></Image>
            ) : (
              <></>
            )
          ) : (
            fileIcon(filetype)
          )}
        </Flex>
        <Box
          position={"absolute"}
          bottom={2}
          color={"black"}
          fontWeight={200}
          width={"100%"}
          zIndex={10}
          borderBottomRadius={"10px"}
          className={"document-card-box"}
          pt={"0.4em"}
          pb={0}
        >
          <Flex justifyContent={"center"} width={"90%"} margin="auto">
            <Editable
              defaultValue={title}
              onBlur={() => {
                if (handleEdit && title !== row.original.displayName) {
                  handleEdit(row.index, {
                    ...row.original,
                    displayName: title,
                  });
                  row.original.displayName = title;
                }
              }}
              w={"100%"}
            >
              <EditablePreview isTruncated width={"100%"} textAlign={"center"} p={0} />
              <Input
                onChange={(e) => setTitle(e.target.value)}
                as={EditableInput}
                _focus={{ border: "none", boxShadow: "none" }}
                background={"white"}
                fontSize={"16px"}
                textAlign={"center"}
                p={0}
              />
            </Editable>
          </Flex>
          <Flex
            gap={2}
            alignItems={"center"}
            justifyContent={"center"}
            p={"0.2em"}
            display={"none"}
            className={"document-card-buttons"}
          >
            {deleteCell.render("Cell")}
          </Flex>
        </Box>
      </Box>
    </Section>
  );
};
