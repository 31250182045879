import { useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthAPI from "../../api/auth.api";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import { addToast } from "../../utils/addToast";

interface EmailConfirmationProps {}

const EmailConfirmation: React.FC<EmailConfirmationProps> = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  React.useEffect(() => {
    async function verifyMail() {
      try {
        await AuthAPI.confirmEmail(token!);
        addToast(toast, {
          title: "Successfully verified email address.",
          status: "success",
        });
      } catch (e) {
        console.log(e);
        addToast(toast, {
          title: "Failed verifying email.",
          description: "Either the account was deleted, the token is invalid or the token has expired.",
          status: "error",
        });
      }
      navigate("/", { replace: true });
    }
    verifyMail();
  }, [navigate, toast, token]);

  return <FullScreenSpinner />;
};
export default React.memo(EmailConfirmation);
