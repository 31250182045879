import { Spinner } from "@chakra-ui/react";
import { APICALL, TLocation } from "@lato/common";
import React, { useEffect, useState } from "react";
import { useAsyncDebounce } from "react-table";
import { trpc } from "../../../trpc";
import { useTrpcInfiniteQuery } from "../../utils/query-helpers/reactQueryHooks";
import { transformInfiniteData, useCustomInfiniteQuery } from "../../utils/query-helpers/useCustomInfiniteQuery";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import ErrorCardView from "../layout/ErrorCardView";
import CustomComboBox, { DropdownFilter, FILTER_TYPE } from "./CustomComboBox";
import CustomMultipleSelection, { CustomMultipleSelectionProps } from "./CustomMultipleSelection";

type apiCall = {
  call: any;
  type: string;
  name: string;
};

type CustomDownShiftWithPaginationProps<T extends object> = {
  apiCall?: () => Promise<any>;
  apiCalls?: apiCall[]; // Pass Promise to CustomInfiniteQuery Hook
  queryName: string; // Pass queryname to CustomInfiniteQuery Hook
  additionalQueryState?: object; // Pass additional query params to CustomInfiniteQuery Hook
  height?: string;
  excludeIds?: string[];
  multipleSelection?: boolean;
  location?: TLocation | null;
  filters?: DropdownFilter[];
  apiIcon?: any;
  initialInputValue?: string;
  isDropdown?: boolean;
} & Omit<CustomMultipleSelectionProps<T>, "bottomRef" | "dropDownItems">;

// TODO Try to merge UsePaginationQueryProps & CustomComboBoxProps and assign it to CustomDownShiftWithPaginationProps
// type CustomDownShiftWithPaginationProps = UsePaginationQueryProps;

const CustomDownShiftWithPagination = <T extends object>({
  apiCall,
  apiCalls = [{ call: apiCall, type: APICALL.REST, name: "apiCall" }],
  queryName,
  additionalQueryState = {},
  disableItemSelection = false,
  disableInput = false,
  height,
  excludeIds,
  multipleSelection = false,
  location,
  filters,
  apiIcon,
  initialInputValue,
  isDropdown = true,
  ...props
}: CustomDownShiftWithPaginationProps<T>) => {
  const bottomOfListRef = React.useRef<HTMLDivElement>(null);

  const [apiChoice, setApiChoice] = React.useState<number>(0);

  const mappedFilters = {
    ...(filters ?? [])
      .filter((filt) => filt.name && !filt.prio && !filt.hide)
      .reduce(
        (result, filtValue) => {
          const { name, currentValue } = filtValue;
          result[name ?? "key"] = currentValue?.length === 0 ? undefined : currentValue;
          return result;
        },
        {} as { [key: string]: string },
      ),
  };

  // Disabling the API call at first instance
  const {
    queryResult: RestQueryResult,
    triggerFetch,
    paginationOptions,
  } = useCustomInfiniteQuery<any>(bottomOfListRef, {
    queryName,
    additionalQueryState: {
      ...additionalQueryState,
      exclude: excludeIds && excludeIds.join(","),
      ...mappedFilters,
    },
    apiCall: apiCalls.length > 0 && apiCalls[apiChoice].call,
    options: {
      enabled: apiCalls.length > 0 && apiCalls[apiChoice].type === APICALL.REST,
    },
  });

  const [inputValue, setInputValue] = React.useState(initialInputValue ?? "");

  //disabling the trpc call at first instance
  //given a basic getHotels call when it is a REST call to avoid errors, this wouldn't affect the workflow since it is disabled
  const TrcpQueryResult = useTrpcInfiniteQuery(
    apiCalls.length > 0 && apiCalls[apiChoice].type === APICALL.TRPC
      ? apiCalls[apiChoice].call
      : trpc.getHotelbedHotels,
    {
      ...additionalQueryState,
      q: inputValue,
      ...mappedFilters,
    },
    {
      getNextPageParam: (lastPage: any) => lastPage.nextCursor,
      enabled: apiCalls.length > 0 && apiCalls[apiChoice].type === APICALL.TRPC,
    },
  );

  //Based on the chosen apicalltype the Result is selected and the returnvalues are read
  const { data, error, hasNextPage, isLoading, isFetching, fetchNextPage } =
    apiCalls.length > 0 && apiCalls[apiChoice].type === APICALL.TRPC ? TrcpQueryResult : RestQueryResult;

  useIntersectionObserver({
    target: bottomOfListRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const refetchEl = hasNextPage ? (
    <div
      style={{
        textAlign: "center",
        height: "15px",
      }}
      ref={bottomOfListRef}
    >
      {isFetching && <Spinner color="brand.500" boxSize="13px" />}
    </div>
  ) : undefined;

  // TODO check if any can be replaced
  // Tranform the set the dropdown items
  const [dropDownItems, setDropDownItems] = useState<any>(transformInfiniteData(data));

  /**
   * Tranform the response and get data from the custom infinte query response and set the dropdown data
   */
  useEffect(() => {
    setDropDownItems(transformInfiniteData(data));
  }, [data]);

  // Trigger the triggerFetch function on change the on Input value in textbox
  const searchInputValue = useAsyncDebounce((inputValue: string) => {
    triggerFetch({
      q: inputValue,
    });
    setInputValue(inputValue);
  }, 400);

  const onInputValueChange = React.useCallback((inputValue: string, shouldDirty = false) => {
    // On change callback
    if (props.onInputValueChangeCallBack) {
      props.onInputValueChangeCallBack(inputValue, shouldDirty);
    }
    // Trigger a new server fetch
    searchInputValue(inputValue);
  }, []);

  const filtersWrap = (filters ?? []).map((f) => ({
    ...f,
    onChange: (e: any) => {
      f.onChange(e);
      triggerFetch({
        q: inputValue,
        ...mappedFilters,
        [`${f.name}`]: e,
      });
    },
  }));
  const preFilters = filtersWrap?.filter((filter) => filter.prio);
  const postFilters = filtersWrap?.filter((filter) => !filter.prio);
  const apiFilters =
    apiCalls.length > 1
      ? [
          {
            options: apiCalls.map((a, i) => ({
              text: a.name,
              value: i,
            })),
            onChange: setApiChoice,
            icon: apiIcon,
            currentValue: apiChoice,
            type: FILTER_TYPE.SELECT,
          },
        ]
      : [];

  if (error && apiCalls.length > 0) return <div>Error...</div>;

  return (
    <>
      {multipleSelection ? (
        <CustomMultipleSelection
          {...props}
          dropDownItems={dropDownItems ?? []}
          isLoading={props.isLoading || isLoading || isFetching}
          onInputValueChangeCallBack={onInputValueChange}
          refetchEl={refetchEl}
          disableInput={disableInput}
          height={height}
          isDropdown={isDropdown}
        />
      ) : (
        <CustomComboBox
          {...props}
          initialInputValue={initialInputValue}
          dropDownItems={dropDownItems ?? []}
          isLoading={props.isLoading || isLoading || isFetching}
          onInputValueChangeCallBack={onInputValueChange}
          refetchEl={refetchEl}
          disableInput={disableInput}
          height={height}
          filters={filters && filters.length > 0 ? [...preFilters, ...apiFilters, ...postFilters] : undefined}
          isDropdown={isDropdown}
        />
      )}
    </>
  );
};

export default CustomDownShiftWithPagination;
