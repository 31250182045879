import { Box, Flex, Image, Skeleton } from "@chakra-ui/react";
import { CompanyBrand, Country } from "@lato/common";
import React from "react";
import { FilterProps } from "react-table";
import { useTripsListStore } from "../../stores/trip/tripslistStore";
import { brandValidationSchema } from "../../validation/validationSchemas";
import MultiSelectColumnFilter from "./MultiSelectColumnFilter";

interface BrandFilterProps extends React.PropsWithChildren<FilterProps<any>> {
  brands: CompanyBrand[];
}

const BrandFilter: React.FC<BrandFilterProps> = ({ brands, ...props }) => {
  const createBrandOptions = React.useCallback(
    (preFilteredRows: any, columnName: string) => {
      // If the members didn't load yet, return an empty set.
      if (!brands) return new Set<string>();

      return new Set(brands.map((c) => c.id));
    },
    [brands],
  );

  const createSearchValueOption = React.useCallback(
    (brandId: string) => {
      if (!brands) return "";
      return brands?.find((b) => b.id === brandId)?.name ?? "";
    },
    [brands],
  );

  const displayBrandOption = React.useCallback(
    (brandId: string): React.ReactElement => {
      // Find a single countrytrip of this country to get the country object
      const brand = brands?.find((b) => b.id === brandId);
      // If the members didn't load yet or somehow no country was found, return an empty fragment.
      if (!brand) return <></>;

      return (
        <Flex alignItems="center">
          <Box display="flex" gap={2} alignItems={"center"}>
            {brand.logo && brand.logoUrl ? (
              <img
                alt={brand.name}
                className="brand-logo"
                src={brand.logoUrl}
                style={{
                  maxHeight: `calc(${brand.logoScale}*1em)`,
                }}
              />
            ) : (
              <>
                <h2 className="brand-name max-w-[270px] truncate">{brand.name}</h2>
              </>
            )}
          </Box>
        </Flex>
      );
    },
    [brands],
  );

  return (
    <>
      {brands && brands.length > 0 && (
        <MultiSelectColumnFilter
          {...props}
          displayName={"brand"}
          displayOption={displayBrandOption}
          searchValueOption={createSearchValueOption}
          createOptions={createBrandOptions}
          useSelectedItems={false}
          onChange={(e) => {
            if (e.length === brands.length) {
              props.columns.find((column) => column.id === "brands" || column.id === "brand")?.setFilter([]);
            } else {
              props.columns.find((column) => column.id === "brands" || column.id === "brand")?.setFilter(e);
            }
          }}
        />
      )}
    </>
  );
};
export default React.memo(BrandFilter);
