import { Button, HStack, Input, useNumberInput } from "@chakra-ui/react";

interface CounterInputProps {
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  precision?: number;
  onChange: (e: any) => void;
}

const CounterInput: React.FC<CounterInputProps> = ({
  step = 1,
  defaultValue = 0,
  min = 0,
  max = 10,
  precision = 0,
  onChange,
}) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step,
    defaultValue,
    min,
    max,
    precision,
    onChange,
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack maxW="320px">
      <Button {...dec}>-</Button>
      <Input {...input} />
      <Button {...inc}>+</Button>
    </HStack>
  );
};

export default CounterInput;
