import React from "react";
import { useFieldArray } from "react-hook-form";
import { Column, Row } from "react-table";
import CRUDResource from "./Resource";

interface FieldArrayResourceProps<ObjectType extends object> {
  formName: string;
  displayName?: string;
  newEmptyRecord: ObjectType;
  columns: Column<any>[];
  showAddButton?: boolean;
  headerButton?: any;
  fieldArray?: any;
  inlineEdit?: any;
  onDelete?: (e: any, value?: any) => void;
  getRowProps?: ({
    row,
    toggleAllRowsExpanded,
  }: {
    row: Row;
    toggleAllRowsExpanded: (value?: boolean | undefined) => void;
  }) => any;
  disableFilterSave?: boolean;
}

const FieldArrayResource = <T extends object>({
  formName,
  showAddButton = true,
  fieldArray,
  inlineEdit = true,
  onDelete = () => {},
  ...props
}: FieldArrayResourceProps<T>) => {
  const backupFieldArray = useFieldArray({
    name: formName,
    keyName: "rhfId", // default to "id", you can change the key name
  });
  if (!fieldArray) {
    fieldArray = backupFieldArray;
  }
  const { fields: records, append, remove, insert } = fieldArray;

  function add() {
    // Append an empty row to the end of the list
    // The shouldFocus will only work if the input is registered using RHF and the name prop is set correctly!
    // Doesn't work with the editable version we have now, because the input is not registered using RHF.
    append(props.newEmptyRecord);
  }

  // TODO: make sure to use the provided react-hook-form ID in order to prevent unnecesary re-renders as stated in the docs.
  return (
    <CRUDResource
      formName={formName}
      {...props}
      data={records ? records : []}
      handleAdd={add}
      handleDelete={(e, obj) => {
        onDelete(e, obj);
        const index = (records.filter((rec: any) => !rec.isNotOriginal) as any[]).indexOf(obj);
        if (index >= 0) {
          remove(index);
        }
      }}
      inlineEdit={inlineEdit}
      showAddButtons={showAddButton}
    />
  );
};
export default React.memo(FieldArrayResource);
