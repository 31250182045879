import {
  Event,
  HOTEL_BOOKING_STATUS,
  HOTEL_ORIGIN,
  Hotel,
  ImageSite,
  POIType,
  TLibraryImage,
  TLocation,
  TRANSPORTATION_TYPE,
  Transportation,
  User,
  Destination,
  LibraryImage,
  Task,
  TASK_STATUS,
  DUE_DAYS_TYPE,
} from "@lato/common";
import React from "react";
import EventsAPI from "../../../api/events.api";
import { POI } from "../../../api/pois.api";
import { useMeContext } from "../../../stores/me-context";
import { getEmptyTranslationField } from "../../../pages/create-trip";
import { ACTIVITIES_QUERY_KEY } from "../../../utils/constants";
import { LibraryItemType } from "../../trips/edit/daybyday/library-items/LibraryItemModal";
import LibraryItems from "../../trips/edit/daybyday/library-items/LibraryItems";
import LibraryItemsList from "../../trips/edit/daybyday/library-items/LibraryItemsList";

interface ActivitiesProps {}

const resourceName: LibraryItemType = "activity";

export const getEmptyActivity = (me: User): Event => ({
  ord: 0,
  name: null,
  time: null,
  endTime: null,
  titles: [getEmptyTranslationField(me)],
  descriptions: [getEmptyTranslationField(me)],
  dayIndex: null,
  images: [],
  libraryImages: [],
  location: null,
  user: null,
  documents: [],
});

export const getEmptyLocation = (): TLocation => ({
  name: "",
  address: {
    country_code: null,
    state: null,
    city: null,
    postal_code: null,
    street: null,
  },
  coordinates: null,
});

export const getEmptyPoi = (me: User): POI => ({
  names: [getEmptyTranslationField(me)],
  descriptions: [getEmptyTranslationField(me)],
  coordinatesDepricated: null,
  location: getEmptyLocation(),
  type: POIType.OTHER,
  images: [],
  libraryImages: [],
});

export const getEmptyDestination = (me: User): Destination => ({
  descriptions: [getEmptyTranslationField(me)],
  titles: [getEmptyTranslationField(me)],
  documents: [],
  images: [],
  libraryImages: [],
  location: null,
  user: me,
});

export const getEmptyLibraryImage = (me: User): LibraryImage => ({
  name: "",
  site: ImageSite.CUSTOMPICTURE,
  url: "",
  user: me,
  ord: 0,
});

export const getEmptyTask = (me: User): Task => ({
  title: "",
  description: "",
  status: TASK_STATUS.TO_DO,
  user: me,
  due_date: new Date(),
  due_days: 7,
  due_days_type: DUE_DAYS_TYPE.BEFORE_START,
  priority: null,
  docs: [],
});

export const getEmptyTransportation = (me: User): Transportation => ({
  type: TRANSPORTATION_TYPE.CAR,
  descriptions: [getEmptyTranslationField(me)],
  arrivalAirportIata: null,
  departureAirportIata: null,
  realtime: false,
  carrier: "",
  transportNumber: "",
  arrivalLocation: null,
  departureLocation: null,
  ord: 0,
  arrivalDayIndex: null,
  arrivalTime: null,
  departureDayIndex: null,
  departureTime: null,
  documents: [],
});

export const getEmptyHotel = (me: User): Hotel => ({
  name: "",
  descriptions: [getEmptyTranslationField(me)],
  images: [],
  libraryImages: [],
  ord: 0,
  user: null,
  location: null,
  documents: [],
  board: "",
  rooms: [],
  origin: HOTEL_ORIGIN.LATO,
  originCode: "",
  primaryEmail: "",
  phoneNumber: "",
  website: "",
  bookingStatus: HOTEL_BOOKING_STATUS.QUOTE,
  bookingRef: "",
  rating: 0,
  accommodationType: "",
  comments: "",
});

const Activities: React.FC<ActivitiesProps> = () => {
  const user = useMeContext();
  return (
    <LibraryItems>
      <LibraryItemsList
        api={EventsAPI}
        eventType={resourceName}
        emptyResource={getEmptyActivity(user)}
        query_key={ACTIVITIES_QUERY_KEY}
      />
    </LibraryItems>
  );
};
export default React.memo(Activities);
