import { TRIP_STATUS, UserTrip } from "@lato/common";
import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "usertrips";

// Get the standard CRUD operations
const basicTripEndpoints = getApiEndpoints({
  url,
});

// Get a trip with the temperatures
const getAppTrip = (id: string) => {
  return rawApiProvider.get(`${url}/${id}/app`);
};

// Get trips connected to a contact
const getContactTrips = (contactId: string) => {
  return rawApiProvider.get(`${url}/contact/${contactId}`);
};

// Accept a collaboration invite
const acceptTripCollaboration = (id: string) => {
  return rawApiProvider.post(`${url}/${id}/acceptCollaboration`, {});
};

// Add language to trip
const addLanguageToTrip = (
  id: string,
  language_code: string,
  translateFields?: {
    targetLanguage: string;
    sourceLanguage?: string;
    translateFromUserCompanyId?: string;
  },
) => {
  return rawApiProvider.post(`${url}/${id}/addLanguageToTrip`, {
    language_code,
    translateFields,
  });
};

// Remove language from trip
const removeLanguageFromTrip = (id: string, language_code: string) => {
  return rawApiProvider.post(`${url}/${id}/removeLanguageFromTrip`, {
    language_code,
  });
};

// Accept a collaboration invite
const downloadPDF = (id: string, language_code: string) => {
  return rawApiProvider.get(`${url}/${id}/pdf/${language_code}`, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
      "Content-Type": "application/pdf",
    },
  });
};

const getCounts = (id: string) => {
  return rawApiProvider.get(`${url}/${id}/counts`);
};

const getMultipleTrips = (ids: string[]) => {
  return rawApiProvider.get(`${url}/multiple/${ids.join(",")}`);
};

// Check if the collaboration invite was already accepted.
const collaborationInvitationStatus = (id: string) => {
  return rawApiProvider.get(`${url}/${id}/collaborationInvitationStatus`);
};

// // Check if the user has access to edit this usertrip.
// const checkEditTripAccess = (id: string) => {
//   return rawApiProvider.get(`${url}/${id}/checkEditTripAccess`);
// };

// Copy usertrip
const copyUserTrip = (userTripId: string, model: object): Promise<UserTrip> => {
  return rawApiProvider.put(`${url}/${userTripId}/copy`, model);
};

// Get POIs of this usertrip and those before this usertrip
const getPOIs = (userTripId: string) => {
  return rawApiProvider.get(`${url}/${userTripId}/pois`);
};

// Get chat messages of this usertrip and those before this usertrip
const getPaginatedChatMessages = (userTripId: string, queryParams?: string) => {
  return rawApiProvider.get(`${url}/${userTripId}/chatmessages?${queryParams}`);
};

// Get number of latest created trips created of this company
const getNumberOfLatestTrips = () => {
  return rawApiProvider.get(`${url}/createdTrips`);
};

// Get statistics of this company
const bookTrip = (userTripId: string, model: object) => {
  return rawApiProvider.post(`${url}/${userTripId}/book`, model);
};

// Get statistics of this company
const sendTripMail = (userTripId: string, model: object) => {
  return rawApiProvider.post(`${url}/${userTripId}/sendTripMail`, model);
};

// Get preview of trip mail
const getPreviewTripMail = (userTripId: string, model: object) => {
  return rawApiProvider.post(`${url}/${userTripId}/sendTripMail/preview`, model);
};

// Change the status of the trip
const changeStatus = (userTripId: string, newStatus: TRIP_STATUS) => {
  return rawApiProvider.post(`${url}/${userTripId}/changeStatus`, {
    status: newStatus,
  });
};

type TripsAPIType = ReturnEndpoints & {
  getPaginatedTrips(queryParams?: string): Promise<any>;
  getAppTrip(id: string): Promise<any>;
  getTrip(id: string): Promise<any>;
  acceptTripCollaboration(id: string): Promise<any>;
  addLanguageToTrip(
    id: string,
    language_code: string,
    translateFields?: {
      targetLanguage: string;
      sourceLanguage?: string;
      translateFromUserCompanyId?: string;
    },
  ): Promise<any>;
  removeLanguageFromTrip(id: string, language_code: string): Promise<any>;
  collaborationInvitationStatus(id: string): Promise<any>;
  deleteUserTrip(id: string): Promise<any>;
  copyUserTrip(id: string, model: object): Promise<UserTrip>;
  getPOIs(userTripId: string): Promise<any>;
  getPaginatedChatMessages(userTripId: string, queryParams?: string): Promise<any>;
  // checkEditTripAccess(id: string): Promise<any>;
  getNumberOfLatestTrips(): Promise<number>;
  bookTrip(userTripId: string, model: object): Promise<any>;
  sendTripMail(userTripId: string, model: object): Promise<any>;
  getPreviewTripMail(userTripId: string, model: object): Promise<any>;
  changeStatus(userTripId: string, newStatus: TRIP_STATUS): Promise<any>;
  downloadPDF(userTripId: string, language_code: string): Promise<any>;
  getContactTrips(contactId: string): Promise<any>;
  getMultipleTrips(ids: string[]): Promise<any>;
  getCounts(id: string): Promise<any>;
};
const UserTripsAPI: TripsAPIType = {
  ...basicTripEndpoints,
  getAppTrip: getAppTrip,
  getPaginatedTrips: basicTripEndpoints.getAll,
  getTrip: basicTripEndpoints.getSingle,
  acceptTripCollaboration: acceptTripCollaboration,
  addLanguageToTrip: addLanguageToTrip,
  removeLanguageFromTrip: removeLanguageFromTrip,
  collaborationInvitationStatus,
  deleteUserTrip: basicTripEndpoints.delete,
  copyUserTrip: copyUserTrip,
  getPOIs,
  getPaginatedChatMessages,
  downloadPDF,
  getNumberOfLatestTrips,
  bookTrip,
  sendTripMail,
  getPreviewTripMail,
  changeStatus,
  getContactTrips,
  getMultipleTrips,
  getCounts,
};
export default UserTripsAPI;
