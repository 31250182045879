import { CalendarIcon, LinkIcon } from "@chakra-ui/icons";
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Circle,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  ListItem,
  MenuDivider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  chakra,
  useClipboard,
  useDisclosure,
  useToast,
  List,
  ListIcon,
} from "@chakra-ui/react";
import {
  Country,
  ImageSite,
  Plan,
  QueryType,
  TASK_STATUS,
  TRIP_STATUS,
  Task,
  TourRadarTour,
  TranslationField,
  Trip,
  User,
  UserTrip,
} from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { Clock } from "lucide-react";
import React, { useEffect, useMemo } from "react";
import { BiSolidShare } from "react-icons/bi";
import { BsPersonFillGear, BsPrinter, BsShare as Share2 } from "react-icons/bs";
import { FaCheck, FaCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CellProps, Column, Filters, Row } from "react-table";
import CountryAPI from "../../api/countries.api";
import TripsAPI from "../../api/trips.api";
import UserTripsAPI from "../../api/usertrips.api";
import { EditIcon } from "../../assets/icons/MiniDrawerIcons";
import DrawKitVacationIllustration from "../../assets/icons/drawkit-illustration.png";
import { useMeContext } from "../../stores/me-context";
import { getInitialTrip } from "../../pages/create-trip";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import { useTripsListStore } from "../../stores/trip/tripslistStore";
import { addToast } from "../../utils/addToast";
import { SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../utils/constants";
import ENV from "../../utils/env";
import { getCollaborateTripLink, getViewTripLink } from "../../utils/generateURLs";
import { grantAtLeast, grantPayingPlansPermission } from "../../utils/grantPermission";
import { useDeleteUsertrip } from "../../utils/query-helpers/react-query-mutations";
import { useLanguages, useParamsQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import { PaginationOptions } from "../../utils/query-helpers/usePaginatedQuery";
import AIPDFReaderButton from "./../../../src/pages/ai-pdf-reader-button";
import BrandsFilter from "../../components/CRUD/BrandsFilter";
import { CARDSIZES, CustomCard } from "../../components/CRUD/CardView";
import CompanyFilter from "../../components/CRUD/CompanyFilter";
import ContinentFilter from "../../components/CRUD/ContinentFilter";
import CountryFilter from "../../components/CRUD/CountryFilter";
import LanguageFilter from "../../components/CRUD/LanguageFilter";
import MultiSelectColumnFilter from "../../components/CRUD/MultiSelectColumnFilter";
import OptionsDropdown, { DeleteOptionsDropdownItem, OptionsDropdownItem } from "../../components/CRUD/OptionsDropdown";
import CRUDResource from "../../components//CRUD/Resource";
import StartDateFilter from "../../components//CRUD/StartDateFilter";
import TravelingFilter from "../../components//CRUD/TravelingFilter";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import { MdCheckCircle, MdOutlineCircle } from "react-icons/md";
import { FILTER_TYPE } from "../../components/CustomDownShift/CustomComboBox";
import { FilterSelect } from "../../components/CustomDownShift/FilterSelect";
import ErrorCardView from "../../components/layout/ErrorCardView";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { stars } from "../../components/trips/edit/daybyday/library-items/hotel/StarRating";
import PrintLanguageModal from "../../components/trips/print-trip/PrintLanguageModal";
import CreateTripFromSample from "../../components/trips/samples/options/CreateTripFromSample";
import { getTasksProgressionColor } from "../../utils/getProgressCircleColor";
import useCreateNewTrip from "../../utils/useCreateNewTrip";
import { TripPopover } from "../calenderOverview/TripPopover";
import AssignTripUserModal from "./AssignTripUserModal";
import CopyTripModal from "./CopyTripModal";

interface TripsListProps {
  samples: boolean;
  userTrips: UserTrip[];
  fetchingTrips: boolean;
  loadingTrips: boolean;
  totalCount: number;
  initialTableState: PaginationOptions;
  permissionToCreateTrips: boolean;
  triggerFetch: (paginationOptions: {
    page: number;
    step: number;
    q: string;
    order: "DESC" | "ASC" | undefined;
    orderBy: string | undefined;
    filters: Filters<UserTrip>;
  }) => void;
  members?: User[];
  filters: PaginationOptions;
  resetFilters: () => void;
  listName: string;
  smallTable?: boolean;
  isRecentFilter?: boolean;
  setIsRecentFilter?: (b: boolean) => void;
}

export const tripStatusColorMap: { [key in TRIP_STATUS]: string } = {
  [TRIP_STATUS.QUOTE]: "gray",
  [TRIP_STATUS.APPROVED]: "blue",
  [TRIP_STATUS.BOOKED]: "green",
  [TRIP_STATUS.CANCELED]: "red",
};

const createBookedOptions = (preFilteredRows: any, columnName: string) => {
  return new Set(Object.values(TRIP_STATUS));
};

export const displayBookedOption = (value: string) => {
  return (
    <Badge colorScheme={tripStatusColorMap[value as TRIP_STATUS]} title={value}>
      {value}
    </Badge>
  );
};

// const transformBookedOptions = (
//   chosenOptions: ("Offer" | "Booked")[]
// ): "false" | "true" | undefined => {
//   if (chosenOptions.length === 1) {
//     // If only 1 chosen option and the option is 'Offer', the booked filter should be false.
//     return chosenOptions[0] === "Offer" ? "false" : "true";
//   }
//   // If no option is selected or both options are selected return null.
//   return undefined;
// };

const createDayOptions = (preFilteredRows: any, columnName: string) => {
  // Make sure to create the options as strings. Format: [0-9]*-[0-9]* ->
  // First get the
  // Three possibilities:
  // - Show standard intervals ([0-5], [6-10], [10+])
  // - Show all intervals till the interval of the max value in the tabel ([0-5], [6-10], [11 - 15] if 12 is the max value e.g.)
  // - Show only the intervals containing a value in that interval ([0-5], [11-15] if no value in [6-10])
  return new Set(["0 - 5 days", "6 - 10 days", "11 - 20 days", "More than 20 days"]);
};

export const start_date_column: Column<UserTrip> = {
  accessor: (row) => row.trip!.start_date,
  Header: "Start Date",
  id: "start_date",
  chakraWidth: "60px",
  Filter: (props) => <StartDateFilter {...props} />,
  disableFilters: false,
};

export const nrofdays_column: Column<UserTrip | TourRadarTour> = {
  // Currently does not work because the tripdays is not joined in the usertrips.service getTrips()
  accessor: (row) => row.nrOfDays ?? row.trip?.nrOfDays!,
  Header: "days",
  // isNumeric: true,
  minWidth: 60,
  chakraMaxWidth: "60px",
  width: 60,
  chakraWidth: "60px",
  Filter: (props) => {
    const filterValue = props.columns.find((column: any) => column.id === "days")?.filterValue;
    return (
      <FilterSelect
        options={100}
        icon={Clock}
        onChange={(e) => {
          props.columns.find((column: any) => column.id === "days")?.setFilter(e);
        }}
        defaultSelected={filterValue && filterValue !== "" ? filterValue : [5, 100]}
        name="days"
        type={FILTER_TYPE.RANGE}
        dropdown={true}
        height={"2em"}
      ></FilterSelect>
    );
  },
  // https://github.com/tannerlinsley/react-table/pull/2329
  // filter: filterDayOptions as any,
  // disableFilters: false,
  disableFilters: true,
  // Disabled because of the issues in the backend due to TypeORM
  disableSortBy: true,
};

export const ref_column: Column<UserTrip> = {
  accessor: "external_ref",
  Header: "Ref #",
  minWidth: 100,
  chakraMaxWidth: "100px",
  Cell: ({ row, value }: CellProps<UserTrip | TourRadarTour>) => {
    value = row.original.operator ?? value;

    return (
      <Box display="flex" justifyContent="flex-start" marginRight={2} title={value?.name}>
        {value && value}
      </Box>
    );
  },
  width: 100,
};

export const client_name_column: Column<UserTrip> = {
  accessor: (row) => row.trip!.client_name,
  Header: "Client name",
  chakraMaxWidth: "130px",
  id: "client_name",
};

export const title_column: Column<UserTrip | TourRadarTour> = {
  accessor: (row) => row.title ?? (row.trip!.titles.length !== 0 ? row.trip!.titles[0]?.content : ""),
  Header: "Title",
  chakraMaxWidth: "220px",
  id: "title",
  disableSortBy: true,
  // Cell: (props) => <EditableCell {...props} noInputBorder={false} />,
};

export const trip_user_cell = (row: any) => {
  // @ts-ignore
  if (row.original.endCity && row.original.endCity !== row.original.startCity) {
    return "";
    // return <Text>{row.original.endCity}</Text>;
  }
  const uts: UserTrip[] = row.original.trip?.userTrips;
  const previous_user = uts && uts.length > 1 ? uts[uts.length - 2].user : undefined;
  const next_user = uts && uts.length > 1 ? uts[uts.length - 1].user : undefined;
  const first = uts && row.original.id === uts[0].id!;
  const avatars = [];
  if (!first && previous_user) {
    avatars.push({
      name: previous_user?.name,
      src: previous_user.avatar?.key ? previous_user.avatarUrl : undefined,
      title: previous_user.name,
    });
  }
  if (row.original.user) {
    avatars.push({
      name: row.original.user?.name,
      src: row.original.user?.avatar?.key ? row.original.user.avatarUrl : undefined,
      title: row.original.user?.name,
    });
  }
  if (first && next_user) {
    avatars.push({
      name: next_user.name,
      src: next_user.avatar?.key ? next_user.avatarUrl : undefined,
      title: next_user?.name,
    });
  }

  const Avatarr = (props: any) => (
    <Avatar {...props} className="group" overflow={"hidden"}>
      <Flex
        className="absolute text-white/0 group-data-[loaded]:text-white group-data-[loaded]:bg-black/20"
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
        height={"100%"}
      >
        {props.name
          .split(" ")
          .map((word: string) => word.toUpperCase().slice(0, 1))
          .join("")
          .slice(0, 2)}
      </Flex>
    </Avatar>
  );

  return (
    <AvatarGroup size="sm" max={2}>
      {avatars.map((a, i) => (
        <Avatarr key={`avatar-${i}-${a.name}`} {...a} />
      ))}
    </AvatarGroup>
  );
};

export const country_column: Column<UserTrip> = {
  accessor: "trip.country" as any,
  id: "country",
  Header: "Country",
  chakraWidth: "70px",
  isNumeric: false,
  Cell: ({ row, value }: CellProps<UserTrip | TourRadarTour>) => {
    value = row.original.country ?? value;
    return (
      <Box display="flex" justifyContent="center" marginRight={2} title={value?.name}>
        {value?.iso && value.iso}
      </Box>
    );
  },
  disableFilters: false,
};

export const continent_column: Column<UserTrip> = {
  accessor: "trip.country" as any,
  id: "continent",
  Header: "Continent",
  chakraWidth: "70px",
  Cell: ({ row, value }: CellProps<UserTrip>) => <Box textAlign={"start"}>{value?.continent}</Box>,
  disableFilters: false,
  hide: true,
};

const TripsList: React.FC<TripsListProps> = ({
  samples,
  userTrips,
  totalCount,
  fetchingTrips,
  loadingTrips,
  initialTableState,
  permissionToCreateTrips,
  triggerFetch,
  members,
  filters,
  resetFilters,
  listName,
  smallTable = false,
  isRecentFilter = false,
  setIsRecentFilter,
}) => {
  console.log(filters);
  const me = useMeContext();
  const { continentFilter, setContinentFilter } = useTripsListStore();

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { resetStore } = useTripFormStore();
  const selectedContinents = continentFilter !== "" ? continentFilter?.split(",") : [];
  const disableFilters = isRecentFilter;
  const { handleCreateTrip } = useCreateNewTrip(samples);

  useEffect(() => {
    resetStore();
  }, []);

  const { data: allLanguages, isLoading: isLoadingLanguages, error: errorLanguages } = useLanguages();
  const companiesFilter = filters.filters!.find((f) => f.id === "companies")?.value;
  const usersFilter = filters.filters!.find((f) => f.id === "users")?.value;

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: isErrorCountries,
  } = useParamsQueryHelper<Country[]>({
    queryKey: "countries-trips",
    apiCall: CountryAPI.getAll,
    queryParams: {
      users: usersFilter,
      companies: companiesFilter,
      type: samples
        ? (Array.isArray(companiesFilter) ? companiesFilter : [companiesFilter]).some((c: any) => !isNaN(+c))
          ? undefined
          : QueryType.SAMPLE
        : QueryType.TRIP,
    } as any,
    options: { enabled: !!usersFilter?.length || !!companiesFilter?.length },
  });
  const resourceName = samples ? "sample" : "trip";
  const route = resourceName + "s";

  const { mutateAsync: deleteUserTripAsync, isPending: isDeleting } = useDeleteUsertrip();

  const { mutateAsync: changeTripStatus } = useMutation({
    mutationFn: ({ userTripId, newStatus }: { userTripId: string; newStatus: TRIP_STATUS }) =>
      UserTripsAPI.changeStatus(userTripId, newStatus),
    onSuccess: async () => {
      // Better handle this, can't you just add the current message to the cache?
      await queryClient.invalidateQueries({
        queryKey: [samples ? SAMPLES_USERTRIPS_KEY : TRIPS_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: ["usertrip", userTripId],
      });
    },
  });

  /**
   * Call the API to update Trip Share Status
   */
  const { mutateAsync: updateTripShareStatus } = useMutation({
    mutationFn: ({ tripId, share }: { tripId: string; share: boolean }) =>
      TripsAPI.updateTripShareStatus(tripId, share),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [samples ? SAMPLES_USERTRIPS_KEY : TRIPS_USERTRIPS_KEY],
      });
    },
  });

  const createUserOptions = React.useCallback(
    (preFilteredRows: any, columnName: string) => {
      // If the members didn't load yet, return an empty set.
      if (!members) return new Set<string>();
      return new Set(members.map((m) => m.id!));
    },
    [members],
  );

  const displayUserOption = React.useCallback(
    (userId: string): React.ReactElement => {
      // Find a single usertrip of this user to get the user object
      const user = members?.find((u) => u.id! === userId);
      // If the members didn't load yet or somehow no user was found, return an empty fragment.
      if (!user) return <></>;
      return (
        <Flex alignItems="center">
          <Avatar size="xs" name={user.name} src={user.avatar?.key ? user.avatarUrl : ""} mr={2} />
          <span>{user.name}</span>
        </Flex>
      );
    },
    [members],
  );

  const handleChangeTripStatus = React.useCallback(
    async (e: any, index: number, newStatus: TRIP_STATUS) => {
      e.stopPropagation();
      e.preventDefault();
      // const newStatusMap: { [key in TRIP_STATUS]: TRIP_STATUS } = {
      //   [TRIP_STATUS.QUOTE]: TRIP_STATUS.APPROVED,
      //   [TRIP_STATUS.APPROVED]: TRIP_STATUS.BOOKED,
      //   [TRIP_STATUS.BOOKED]: TRIP_STATUS.CANCELED,
      //   [TRIP_STATUS.CANCELED]: TRIP_STATUS.QUOTE,
      // };
      await changeTripStatus({
        userTripId: userTrips[index].id!,
        newStatus: newStatus,
      });
    },
    [changeTripStatus, userTrips],
  );

  /**
   * Method is called from Sample Trips Share Column on click method
   * Trigger API call to toggle Trips Share Status
   */
  const handleShareUpdate = React.useCallback(
    async (e: any, tripId: string, share: boolean) => {
      e.stopPropagation();
      e.preventDefault();
      await updateTripShareStatus({
        tripId,
        share: !share,
      });
    },
    [updateTripShareStatus],
  );

  const brands_column: Column<UserTrip> = useMemo(
    () => ({
      id: "brands",
      accessor: "brand",
      hide: true,
      Filter: (props) => {
        if (disableFilters) return <></>;

        return <BrandsFilter {...props} brands={me.company.brands} />;
      },
      disableFilters: false,
    }),
    [disableFilters, me.company.brands],
  );

  const user_column: Column<UserTrip> = React.useMemo(
    () => ({
      id: "users",
      // Use the user id as accessor such that users with exactly the same name are seen as different in the filter.
      accessor: (row) => row.user?.id!,
      chakraWidth: "80px",
      Cell: ({ row }: CellProps<UserTrip>) => {
        return trip_user_cell(row);
      },
      Filter: (props) => {
        if (disableFilters) return <></>;

        // The user filter should be disabled whenever a company is selected different than the company of the logged in user.
        const companyFilterValue = props.state.filters.find((filter) => filter.id === "companies")?.value;
        const isUserFilterDisabled = companyFilterValue.length !== 1 || companyFilterValue[0] !== me.company.id;
        return (
          <MultiSelectColumnFilter
            {...props}
            displayOption={displayUserOption}
            // TODO: remove later, but currently BUG: the preFilteredRows are sometimes undefined
            createOptions={createUserOptions}
            isDisabled={isUserFilterDisabled}
            useSelectedItems={false}
          />
        );
      },
      disableFilters: false,
      disableSortBy: true,
    }),
    [displayUserOption, me, createUserOptions, disableFilters],
  );

  const start_date_column: Column<UserTrip> = useMemo(
    () => ({
      accessor: (row) => row.trip!.start_date,
      Header: "Start Date",
      id: "start_date",
      chakraWidth: "60px",
      Filter: (props) => {
        if (disableFilters) return <></>;

        return <StartDateFilter {...props} />;
      },
      disableFilters: false,
    }),
    [disableFilters],
  );

  const nrofdays_column: Column<UserTrip> = useMemo(
    () => ({
      // Currently does not work because the tripdays is not joined in the usertrips.service getTrips()
      accessor: (row) => (row as any).nrOfDays ?? row.trip?.nrOfDays!,
      Header: "days",
      // isNumeric: true,
      minWidth: 60,
      chakraMaxWidth: "60px",
      width: 60,
      chakraWidth: "60px",
      Filter: (props) => {
        if (disableFilters) return <></>;

        return <MultiSelectColumnFilter {...props} createOptions={createDayOptions} />;
      },
      // https://github.com/tannerlinsley/react-table/pull/2329
      // filter: filterDayOptions as any,
      // disableFilters: false,
      disableFilters: true,
      // Disabled because of the issues in the backend due to TypeORM
      disableSortBy: false,
    }),
    [createDayOptions, disableFilters, filters],
  );

  const company_column: Column<UserTrip | TourRadarTour> = useMemo(
    () => ({
      id: "companies",
      accessor: (row) => row.user?.company?.id,
      chakraWidth: "100px",
      Cell: ({ row }: CellProps<UserTrip | TourRadarTour>) => {
        const brand = row.original.brand;
        if (row.original.startCity) {
          return "";
          // return (
          //   <Flex justifyContent={"space-between"}>
          //     <Box>{row.original.startCity}</Box>
          //     {row.original.startCity !== row.original.endCity && <Box>{"-"}</Box>}
          //   </Flex>
          // );
        }

        if (brand.logo && brand.logoUrl) {
          return (
            <Image
              src={brand.logoUrl}
              alt={brand.name}
              title={brand.name}
              // width={"50px"}
              height={"1.8em"}
            />
          );
        } else {
          return (
            <Heading size="md" title={brand.name}>
              {brand.name}
            </Heading>
          );
        }
      },
      Filter: (props) => {
        if (disableFilters) return <></>;
        return (
          <CompanyFilter
            {...props}
            filters={[...filters.filters, { id: "sample", value: "true" }]}
            singleSelect
            disableDeselect
          />
        );
      },
      disableFilters: false,
    }),
    [filters.filters, disableFilters],
  );

  const isPaying = grantPayingPlansPermission(me.company.plan);

  const language_column: Column<UserTrip | TourRadarTour> = React.useMemo(
    () => ({
      Header: "Languages",
      accessor: (row) => row.title ?? row.trip!.titles,
      chakraWidth: "60px",
      id: "languages",
      Cell: ({ value, row, column }: CellProps<UserTrip | TourRadarTour>) => {
        const languages = row.original.language
          ? [row.original.language]
          : ((value as TranslationField[]) ?? [])
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.language.code === value.language.code) &&
                  members?.map((m) => m.id).includes(value.creator?.id),
              )
              .map((l) => l.language)
              .sort((a, b) => (a.name > b.name ? 1 : -1));
        return (
          <Text isTruncated maxW="6em" ml={1} title={languages.map((l) => l.name).join(", ")}>
            {languages.map((language) => language.flag).join(" ")}
          </Text>
        );
      },
      Filter: (props: any) => {
        if (disableFilters) return <></>;

        return (
          <LanguageFilter
            {...props}
            isLoadingLanguages={isLoadingLanguages}
            languages={allLanguages}
            column={{
              ...props.column,
            }}
          />
        );
      },
      disableSortBy: true,
      disableFilters: false,
    }),
    [allLanguages, isLoadingLanguages, members, disableFilters, filters],
  );

  const status_column: Column<UserTrip> = React.useMemo(
    () => ({
      accessor: "status",
      id: "status",
      Header: "status",
      chakraWidth: "70px",
      isNumeric: true,
      Cell: ({ row, value }: CellProps<UserTrip>) => (
        <OptionsDropdown
          menuIcon={
            <Badge
              as={"button"}
              colorScheme={tripStatusColorMap[value as TRIP_STATUS]}
              disabled={!isPaying}
              _hover={{
                opacity: 0.8,
              }}
            >
              {value}
            </Badge>
          }
          label="Change status"
        >
          {Object.values(TRIP_STATUS).map((trip_status) => (
            <OptionsDropdownItem
              key={`${row.original.id}-${trip_status}`}
              icon={displayBookedOption(trip_status)}
              onClick={(e) => handleChangeTripStatus(e, row.index, trip_status)}
              _hover={{ cursor: "pointer" }}
            />
          ))}
        </OptionsDropdown>
      ),
      Filter: (props) => {
        if (disableFilters) return <></>;

        return (
          <MultiSelectColumnFilter {...props} createOptions={createBookedOptions} displayOption={displayBookedOption} />
        );
      },
      disableFilters: false,
    }),
    [handleChangeTripStatus, isPaying, disableFilters],
  );

  // Help function to check whether the trip originated from the company of the logged-in user.
  const createdByOwnCompany = React.useCallback(
    (row: Row<UserTrip>) => row.original.user?.companyId === me.companyId,
    [me],
  );

  const canEdit = React.useCallback(
    (row: Row<UserTrip>) => createdByOwnCompany(row), // && isPaying,
    [createdByOwnCompany],
  );

  // Share Column display current share status of the trip and allow toggling of status
  const share_column: Column<UserTrip> = React.useMemo(
    () => ({
      id: "share",
      // Header: "Public",
      chakraWidth: "10px",
      Cell: ({ row }: CellProps<UserTrip>) => {
        if (canEdit(row)) {
          const { id, share } = row.original.trip!;
          return (
            <div className="text-right">
              <Tooltip label={share ? "Shared with followers" : "Private"} aria-label="toggle-trip-status-tooltip">
                {/* <Badge
                  colorScheme={share ? "red" : "green"}
                  onClick={(e) => handleShareUpdate(e, id!, share)}
                  _hover={{
                    opacity: 0.8,
                  }}
                >
                  {share ? "Don't Share" : "Share"}
                </Badge> */}
                <Circle
                  bg={share ? "green.500" : "orange.500"}
                  size="7px"
                  // onClick={(e) => handleShareUpdate(e, id!, share)}
                  // _hover={{
                  //   opacity: 0.8,
                  // }}
                />
              </Tooltip>
            </div>
          );
        } else {
          return <></>;
        }
      },
    }),
    [canEdit, disableFilters],
  );

  const onEdit = React.useCallback(
    (e: any, index: number) => {
      e.stopPropagation();

      // Edit the trip
      navigate(`/${route}/${userTrips[index].id!}`);
    },
    [userTrips, navigate, route],
  );

  const getViewTripLinkShort = React.useCallback(
    (row: Row<UserTrip | TourRadarTour>) =>
      getViewTripLink(
        me ? me.company.name : "",
        row.original.title ?? (row.original.trip!.titles.length !== 0 ? row.original.trip!.titles[0].content : ""),
        row.original.id!,
      ),
    [me],
  );

  const canShareProfessionalLink = grantAtLeast(me.company.plan, Plan.PRO);
  // console.log("my plan", me.company.plan);
  // console.log(Object.values(Plan).filter((el) => el !== Plan.FREE));
  // console.log("isisPaying", isPaying);
  // console.log(grantPlanPermission(me.company.plan, [Plan.PRO, Plan.LITE]));

  const createTripMutation = useMutation({
    mutationFn: async (newTrip: Trip) => await TripsAPI.createTrip(newTrip),
    onSuccess: () => {
      // Invalidate the list of trips
      queryClient.invalidateQueries({
        queryKey: ["usertrips"],
      });
    },
  });

  const emptyFormInitialValues = getInitialTrip(me);
  const toast = useToast();
  const deleteUserTrip = React.useCallback(
    async (e: any, index: number) => {
      e.stopPropagation();
      try {
        await deleteUserTripAsync(userTrips[index].id!);
        // TODO should not reload using cache
        // Rerender page
        // navigate(0);
        addToast(toast, {
          title: `Successfully deleted ${resourceName}`,
          status: "success",
        });
      } catch (error) {
        console.log(`Could not delete this ${resourceName}.`);
        console.error(error);
        addToast(toast, {
          title: `Couldn't delete ${resourceName}`,

          status: "error",
        });
      }
    },
    [deleteUserTripAsync, userTrips, resourceName],
  );

  const onClickRow = (e: any, row: Row<UserTrip>) => {
    e.stopPropagation();
    const usertrip = userTrips[row.index] as UserTrip | TourRadarTour;
    if (usertrip && usertrip.id === "tourradar") {
      window && window.open(usertrip.tourLink, "_blank").focus();
      return;
    }
    if (canEdit(row)) {
      // If the trip originated from the company of the logged-in user, edit the trip
      onEdit(e, row.index);
    } else {
      // Else, show the travel app of the trip
      openTravelApp(row);
    }
  };

  const openTravelApp = (row: Row<UserTrip>) => {
    const viewTripLink = getViewTripLinkShort(row);
    // Open the travel app in a new tab
    window.open(viewTripLink, "_blank");
  };

  const rowProps = ({ row }: { row: Row<any> }) => ({
    onClick: (e: any) => onClickRow(e, row),
    _hover: {
      textDecoration: "underline",
      cursor: "pointer",
    },
  });

  const country_column_dupe = useMemo(
    () => ({
      ...country_column,
      Filter: (props: any) => {
        if (disableFilters) return <></>;
        return (
          <CountryFilter
            {...props}
            isLoadingCountries={isLoadingCountries}
            isErrorCountries={isErrorCountries}
            singleSelect={
              companiesFilter &&
              !(Array.isArray(companiesFilter) ? companiesFilter : [companiesFilter]).some((c: any) => isNaN(+c))
            }
            countries={
              countries &&
              countries?.filter((country) =>
                selectedContinents?.length > 0 ? selectedContinents.includes(country.continent) : [],
              )
            }
          />
        );
      },
    }),
    [countries, selectedContinents, companiesFilter],
  );

  const continent_column_dupe = useMemo(
    () => ({
      ...continent_column,
      Filter: (props: any) => {
        if (disableFilters) return <></>;

        return (
          <ContinentFilter
            {...props}
            singleSelect={
              companiesFilter &&
              (!Array.isArray(companiesFilter) ? companiesFilter : [companiesFilter].some((c: any) => !isNaN(+c)))
            }
            continents={countries
              ?.flatMap((country) => country.continent)
              .filter((value, index, self) => self.indexOf(value) === index)}
          />
        );
      },
    }),
    [countries, disableFilters],
  );

  // Column for the filter traveling status of the trip (upcoming, past or traveling)
  const traveling_column: Column<UserTrip> = useMemo(
    () => ({
      accessor: "traveling_status" as any,
      Header: "traveling",
      id: "traveling_status",
      chakraWidth: "60px",
      isNumeric: true,
      Filter: (props: any) => {
        if (disableFilters) return <></>;

        return <TravelingFilter {...props} />;
      },
      disableFilters: false,
      disableSortBy: true,
      hide: true,
    }),
    [disableFilters, filters],
  );

  const tasks_column = useMemo(
    () => ({
      id: "tasks",
      accessor: "tasks",
      chakraWidth: "30px",
      Cell: ({ row }: CellProps<UserTrip>) => {
        const tasks = row?.original.tasks || [];
        const amountTasksUnfinished = tasks.filter((task: Task) => task.status !== TASK_STATUS.DONE)?.length;
        const percentageTasksDone = ((tasks?.length - amountTasksUnfinished) / tasks?.length) * 100 + 5;

        if (tasks.length === 0) return <></>;

        return (
          <div className="flex justify-center w-full">
            <Popover placement="top" closeOnBlur={true} trigger="hover">
              <PopoverTrigger>
                <CircularProgress
                  size="20px"
                  value={percentageTasksDone}
                  color={`${getTasksProgressionColor(percentageTasksDone)}.400`}
                  trackColor="gray.100"
                  capIsRound={true}
                  thickness={"14px"}
                >
                  <CircularProgressLabel fontSize="12px" fontWeight={600}>
                    <Text color={"black"}>
                      {amountTasksUnfinished === 0 ? (
                        <FaCheck color="green.400" className="ml-[5px]" />
                      ) : (
                        amountTasksUnfinished
                      )}
                    </Text>
                  </CircularProgressLabel>
                </CircularProgress>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverHeader fontWeight={600} fontSize="md">
                    {amountTasksUnfinished === 0
                      ? tasks.length === 0
                        ? "No tasks added"
                        : "All done!"
                      : `${amountTasksUnfinished} unfinished task${amountTasksUnfinished > 1 ? "s" : ""}`}
                  </PopoverHeader>
                  {tasks.length !== 0 && (
                    <PopoverBody m={2}>
                      <List>
                        {tasks
                          .sort(
                            (a, b) => (a.status === TASK_STATUS.DONE ? 1 : 0) - (b.status === TASK_STATUS.DONE ? 1 : 0),
                          )
                          .map((task) => (
                            <ListItem key={task.id}>
                              <ListIcon
                                mb={0.5}
                                as={task.status === TASK_STATUS.DONE ? MdCheckCircle : MdOutlineCircle}
                                color={task.status === TASK_STATUS.DONE ? "green.500" : "black"}
                              />
                              {task.title}
                            </ListItem>
                          ))}
                      </List>
                    </PopoverBody>
                  )}
                </PopoverContent>
              </Portal>
            </Popover>
          </div>
        );
      },
    }),
    [countries, selectedContinents, companiesFilter],
  );

  const small_table_columns = React.useMemo(
    () => [title_column, client_name_column],
    [title_column, client_name_column],
  );

  const trips_columns: Column<UserTrip>[] = React.useMemo(
    () => [
      user_column,
      continent_column_dupe,
      country_column_dupe,
      traveling_column,
      start_date_column,
      nrofdays_column,
      ref_column,
      title_column,
      client_name_column,
      language_column,
      status_column,
      tasks_column,
      brands_column,
    ],
    [
      user_column,
      status_column,
      continent_column_dupe,
      traveling_column,
      country_column_dupe,
      brands_column,
      country_column_dupe,
    ],
  );

  const samples_columns: Column<UserTrip>[] = React.useMemo(
    () => [
      share_column,
      continent_column_dupe,
      country_column_dupe,
      company_column,
      user_column,
      {
        ...nrofdays_column,
        // disableFilters:
        //   companiesFilter && !Array.isArray(companiesFilter)
        //     ? companiesFilter
        //     : [companiesFilter].some((c: any) => !isNaN(+c)),
      },
      ref_column,
      title_column,
      language_column,
      brands_column,
    ],
    [user_column, continent_column_dupe, country_column_dupe, company_column, share_column, companiesFilter],
  );

  const getColumns = React.useCallback(
    () => (samples ? samples_columns : trips_columns),
    [samples_columns, trips_columns, samples],
  );

  const allColumns = React.useMemo(
    () => [
      ...getColumns(),
      {
        id: "trip-options",
        isNumeric: true,
        chakraWidth: "60px",
        Cell: ({ row }: CellProps<UserTrip>) => {
          const viewTripURL = getViewTripLinkShort(row);
          const collaborateTripLink = getCollaborateTripLink(row.original.id!);
          const { hasCopied: hasCopiedEdit, onCopy: onCopyEditUrl } = useClipboard(collaborateTripLink);
          const { hasCopied: hasCopiedView, onCopy: onCopyViewUrl } = useClipboard(viewTripURL);
          const toast = useToast();
          const modalDisclosure = useDisclosure();
          const printModalDisclosure = useDisclosure();
          const deleteModalDisclosure = useDisclosure();
          const assignTripModalDisclosure = useDisclosure();
          const ownCompany = createdByOwnCompany(row);
          const alreadyUsedCollaboration = !(userTrips.length > 1);
          if (row.original.id === "tourradar") {
            return <></>;
          }

          return (
            <OptionsDropdown>
              <OptionsDropdownItem
                icon={<EditIcon boxSize={3} />}
                onClick={(e) => onEdit(e, row.index)}
                name={`Edit ${resourceName}`}
                isDisabled={!canEdit(row)}
              />
              <OptionsDropdownItem
                icon={<Icon as={BiSolidShare} />}
                onClick={(e) => {
                  onCopyViewUrl();
                  addToast(toast, {
                    title: "Copied link to your clipboard.",
                    status: "success",
                    description: `You can now share this ${resourceName} with your clients/travelers.`,
                  });
                }}
                name="Get traveler link"
              />
              {canShareProfessionalLink &&
                !samples &&
                ENV !== "staging" &&
                !alreadyUsedCollaboration &&
                userTrips[row.index].status !== TRIP_STATUS.CANCELED && (
                  <OptionsDropdownItem
                    icon={<LinkIcon />}
                    onClick={(e) => {
                      onCopyEditUrl();
                      addToast(toast, {
                        title: "Copied link to your clipboard.",
                        status: "success",
                        description: `You can now share this ${resourceName} with another travel professional.`,
                      });
                    }}
                    name="Get professional link"
                  />
                )}
              {/* <OptionsDropdownItem
                icon={
                  <Checkbox
                    colorScheme="green"
                    defaultChecked={true}
                    title="Booked"
                    size="sm"
                    isReadOnly
                  />
                }
                onClick={(e) => handleChangeTripStatus(e, row.index)}
                name={
                  row.original.status === TRIP_STATUS.BOOKED
                    ? "Unbook trip"
                    : "Book trip"
                }
              /> */}
              {samples ? (
                <CreateTripFromSample
                  userTrip={userTrips[row.index]}
                  sample={samples ? true : false}
                  convertType="sampleToTrip"
                  isDisabled={!isPaying}
                />
              ) : (
                <CreateTripFromSample
                  userTrip={userTrips[row.index]}
                  sample={samples ? true : false}
                  convertType="tripToSample"
                  isDisabled={!isPaying}
                />
              )}
              <OptionsDropdownItem
                icon={<Icon as={FaCopy} />}
                onClick={(e) => modalDisclosure.onOpen()}
                name={`Duplicate ${resourceName}...`}
                isDisabled={!isPaying}
              />
              <OptionsDropdownItem
                icon={<Icon boxSize={3} as={BsPersonFillGear} />}
                onClick={(e) => assignTripModalDisclosure.onOpen()}
                name={`Assign ${resourceName} to...`}
              />

              {canEdit(row) && samples && (
                <OptionsDropdownItem
                  icon={<Icon as={Share2} />}
                  onClick={(e) => handleShareUpdate(e, row.original.trip!.id!, row.original.trip!.share)}
                  name={row.original.trip!.share ? "Make trip private" : "Make trip public"}
                  // name={row.original.trip!.share ? "Stop sharing with followers" : "Share with followers"}
                  isDisabled={!canShareProfessionalLink}
                />
              )}
              <OptionsDropdownItem
                icon={<Icon as={BsPrinter} />}
                onClick={(e) => printModalDisclosure.onOpen()}
                name="Export to PDF or DOCX..."
                isDisabled={!isPaying}
              />
              <MenuDivider />
              <DeleteOptionsDropdownItem
                onClick={(e) => deleteModalDisclosure.onOpen()}
                resourceName={resourceName}
                isDisabled={!ownCompany}
              />
              <CopyTripModal
                sample={samples ? true : false}
                userTrip={userTrips[row.index]}
                modalDisclosure={modalDisclosure}
              />
              <AssignTripUserModal
                modalDisclosure={assignTripModalDisclosure}
                userTripId={row.original.id!}
                currentUserId={row.original.user.id ?? ""}
              />
              <PrintLanguageModal userTripid={row.original.id!} modalDisclosure={printModalDisclosure} />
              {deleteModalDisclosure.isOpen && (
                <ConfirmModal
                  title={`Delete ${resourceName} '${
                    userTrips[row.index].trip?.titles?.length !== 0 ? userTrips[row.index].trip?.titles[0].content : ""
                  }'`}
                  description={`Are you sure you want to delete this ${resourceName}?`}
                  action={(e: any) => deleteUserTrip(e, row.index)}
                  disclosure={deleteModalDisclosure}
                  isLoading={isDeleting}
                />
              )}
            </OptionsDropdown>
          );
        },
      },
    ],
    [onEdit, getColumns, members, deleteUserTrip, getViewTripLinkShort, createdByOwnCompany],
  );

  const calendarItems = useMemo(() => {
    return (
      userTrips &&
      userTrips.map((userTrip: UserTrip) => {
        return {
          title: userTrip.trip?.titles.at(-1)?.content,
          start: userTrip.trip?.start_date,
          //end: userTrip.trip?.end_date,
          id: userTrip.id,
          extendedProps: userTrip,
          editable: false,
        };
      })
    );
  }, [userTrips]);

  const tripCalendarPopover = (eventInfo: any) => {
    const event = eventInfo.event;
    const trip = event.extendedProps;
    console.log("tt trip", trip);
    return (
      <Popover placement="top" closeOnBlur={true} isLazy>
        <PopoverTrigger>
          <div
            className={clsx(
              `border-${tripStatusColorMap[trip.status as TRIP_STATUS]}-400`,
              `bg-${tripStatusColorMap[trip.status as TRIP_STATUS]}-50`,
              "mx-1 h-4.5 w-3/4 z-10 border-l-8 rounded-sm shadow-sm border-y-0 border-r-0 text-black",
            )}
          >
            <span className="flex justify-between ml-2 pt-0.5 text-black font-medium my-auto">
              <span className="truncate w-4/5 text-xs">{event.title}</span>
            </span>
          </div>
        </PopoverTrigger>
        <TripPopover trip={event.extendedProps} listView={false} />
      </Popover>
    );
  };

  if (isLoadingCountries) return <FullScreenSpinner />;
  if (isErrorCountries || !countries) return <ErrorCardView title="Could not load countries" />;

  return (
    <>
      <CRUDResource
        formName={listName}
        data={userTrips}
        fetchingData={fetchingTrips}
        loadingData={loadingTrips}
        columns={smallTable ? small_table_columns : (allColumns as Column<any>[])}
        disableSorting={isRecentFilter}
        handleAdd={handleCreateTrip}
        // handleDelete={deleteTrip}
        getRowProps={rowProps}
        initialTableState={initialTableState}
        globalSearch={true}
        triggerFetch={triggerFetch}
        totalCount={totalCount}
        tbodyBackgroundImage={totalCount < 4 ? DrawKitVacationIllustration : undefined}
        disableAddButton={!permissionToCreateTrips}
        enableGridView={true}
        enableCalendarView={true}
        showCalendarTypeButtons={false}
        calendarItems={calendarItems}
        popoverMonthGrid={tripCalendarPopover}
        cardViewComponent={TripCard}
        useSpaciousLayout={!smallTable}
        minTableHeight={"7em"}
        showHeaders={!smallTable}
        heading={
          smallTable ? (
            <Stat color="gray.500" width={"100%"}>
              <StatLabel>
                <span>Departures today</span>
              </StatLabel>
              <StatNumber alignItems="center" lineHeight="1.2">
                <chakra.span fontSize={"2xl"} fontWeight={600} color={"gray.900"}>
                  {totalCount}
                </chakra.span>
              </StatNumber>
              <Link
                position="absolute"
                right={2}
                top={"25%"}
                _hover={{ textDecoration: "none" }}
                href="/trips"
                fontSize="md"
                color="realGray.600"
              >
                {" "}
                All trips →
              </Link>
            </Stat>
          ) : undefined
        }
        showHeading={!smallTable}
        disableFilterSave={smallTable}
        showPagination={!smallTable && !isRecentFilter}
        showViewSwitch={!smallTable}
        filtersOnNextLine
        filterWidth={"70%"}
        resetFilters={resetFilters}
        headerButton={!permissionToCreateTrips ? undefined : <AIPDFReaderButton user={me} />}
        extraFilters={
          !samples
            ? [
                <div className={isRecentFilter ? "-ml-8" : ""} key="recent-filter">
                  <Tooltip label="10 last opened trips">
                    <Button
                      bgColor={isRecentFilter ? "brand.100" : "gray.100"}
                      _hover={{ bgColor: isRecentFilter ? "brand.100" : "gray.100" }}
                      onClick={() => {
                        setIsRecentFilter &&
                          setIsRecentFilter((prevIsRecentFilter: boolean) => {
                            return !prevIsRecentFilter;
                          });
                      }}
                    >
                      Recently opened
                    </Button>
                  </Tooltip>
                </div>,
              ]
            : []
        }
      />
    </>
  );
};
export default React.memo(TripsList);

export interface CardProps {
  row: any;
  key: number;
  size: CARDSIZES;
  handleEdit?: (index: number, newRow: any) => void;
}

const TripCard: React.FC<CardProps> = ({ row, key, size }) => {
  const { trip } = row.original as { trip: Trip };
  const image =
    row.original.images?.length > 0
      ? { url: row.original.images[0], site: ImageSite.CUSTOMPICTURE }
      : (trip?.tripdays[trip.userTrips[0].homeBackgroundNumber]?.image ??
        trip?.tripdays[trip.userTrips[0].homeBackgroundNumber]?.libraryImage ??
        trip?.tripdays.find((tripday) => tripday.image !== undefined)?.image ?? {
          url: "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png",
          site: ImageSite.CUSTOMPICTURE,
        });
  const optionsCell = row.cells.filter((cell: any) => cell.column.id === "trip-options")[0];
  const statusCell = row.cells.filter((cell: any) => cell.column.id === "status")[0];
  const isSample = trip?.sample || row.original.id === "tourradar";

  const quoteClass = "trip-card-view-status";

  const quoteClassName: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: quoteClass + "-xl",
    [CARDSIZES.L]: quoteClass + "-l",
    [CARDSIZES.MD]: quoteClass + "-md",
    [CARDSIZES.S]: quoteClass + "-s",
  };

  const flagWidth: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "26px",
    [CARDSIZES.L]: "22px",
    [CARDSIZES.MD]: "18px",
    [CARDSIZES.S]: "16px",
  };

  return (
    <CustomCard
      image={image}
      key={key}
      size={size}
      optionsCell={optionsCell}
      title={row.original.title ?? (trip.titles.length !== 0 ? trip.titles[0]?.content : "")}
    >
      {(row.original.country?.flagImage || trip.country?.flagImage) && (
        <Flex justifyContent={"center"}>
          <Image
            height={`calc(${flagWidth[size]} - 6px)`}
            width={flagWidth[size]}
            borderRadius={2}
            shadow={"0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)"}
            src={row.original.country?.flagImage ?? trip.country?.flagImage}
          />
        </Flex>
      )}
      <Text textAlign={"center"} fontWeight={600}>
        {row.original.operator ?? trip.client_name}
      </Text>

      <Text textAlign={"center"} fontWeight={600}>
        <CalendarIcon mr={1} mb={size === CARDSIZES.MD || size === CARDSIZES.S ? "0.2em" : 1} />
        {isSample ? `${row.original.nrOfDays ?? trip.nrOfDays} days` : `${trip.start_date} - ${trip.end_date}`}
      </Text>
      {row.original.rating && (
        <Box position="absolute" left={{ base: "43%", md: "31%" }} right={"50%"} mt={"1em"}>
          {[...Array(row.original.rating)].map((_, i) =>
            stars({ value: row.original.rating }, row.original.rating, i, 1),
          )}
        </Box>
      )}
      {!isSample && (
        <Flex
          position="absolute"
          mt={"0.3em"}
          left={0}
          right={0}
          mx={"auto"}
          className={`trip-card-view-status ${quoteClassName[size]}`}
          justifyContent={"center"}
        >
          {statusCell.render("Cell")}
        </Flex>
      )}
    </CustomCard>
  );
};
