import apiProvider from "./provider";

type ApiCoreOptions = {
  url: string;
  plural?: string;
  single?: string;
};

export type ReturnEndpoints = {
  getAll(queryParams?: string): Promise<any>;
  getSingle(id: string): Promise<any>;
  post(model: object): Promise<any>;
  put(id: string, model: object): Promise<any>;
  patch(id: string, model: object): Promise<any>;
  delete(id: string): Promise<any>;
};

const getApiEndpoints = (options: ApiCoreOptions): ReturnEndpoints => {
  const getAll = (queryParams?: string) => {
    return apiProvider.getAll(`${options.url}${queryParams ? `?${queryParams}` : ""}`);
  };

  const getSingle = (id: string) => {
    return apiProvider.getSingle(options.url, id);
  };

  const post = (model: object) => {
    return apiProvider.post(options.url, model);
  };

  const put = (id: string, model: object) => {
    return apiProvider.put(options.url + "/" + id, model);
  };

  const patch = (id: string, model: object) => {
    return apiProvider.patch(options.url + "/" + id, model);
  };

  const deleteEndpoint = (id: string) => {
    return apiProvider.remove(options.url + "/" + id);
  };
  return {
    getAll,
    getSingle,
    post,
    put,
    patch,
    delete: deleteEndpoint,
  };
};

export default getApiEndpoints;
