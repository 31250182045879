import { TableBodyProps as ChakraTableBodyProps, Tbody } from "@chakra-ui/react";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DraggableProvided,
  type DraggableStateSnapshot,
  type DropResult,
  type DroppableProvided,
} from "react-beautiful-dnd";
import { CustomRow, CustomRowProps } from "./Table";

type DroppableTbodyType = ChakraTableBodyProps & {
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  lastDisabled: boolean;
};

export const DroppableTbody: React.FC<DroppableTbodyType> = ({ children, moveRow, lastDisabled, ...props }) => {
  const onDragEnd = (result: DropResult) => {
    console.log(result);
    // dropped outside the list
    if (!result.destination) {
      console.log("dndd: Dropped outside the table");
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      console.log("dndd: Not moved");
      return;
    }
    console.log("dndd: Moved");
    // const newData = [...tableRows];
    // const [movedRow] = newData.splice(result.source.index, 1);
    // newData.splice(result.destination.index, 0, movedRow);
    // setTableRows(newData);
    moveRow(result.source.index, result.destination.index);
  };

  const childrenList = children && Array.isArray(children) ? children : [];

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="table-body">
          {(droppableProvided: DroppableProvided) => (
            <Tbody {...props} ref={droppableProvided.innerRef}>
              {/* If the last record should not be moved, don't include it in the droppable. */}
              {childrenList.map((child, i) => (lastDisabled && i === childrenList.length - 1 ? null : child))}
              {droppableProvided.placeholder}
            </Tbody>
          )}
        </Droppable>
      </DragDropContext>
      {/* If the last record should not be moved, display it outside of the droppable in a new table body */}
      {lastDisabled && <Tbody {...props}>{childrenList[childrenList.length - 1]}</Tbody>}
    </>
  );
};

export const DraggableCustomRoww: React.FC<CustomRowProps> = ({ ...props }) => {
  return (
    <Draggable draggableId={(props.row as any).original.rhfId ?? props.row.id} index={props.row.index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <CustomRow {...props} provided={provided} snapshot={snapshot} />
      )}
    </Draggable>
  );
};
export const DraggableCustomRow = React.memo(DraggableCustomRoww);
