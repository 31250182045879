import { EditIcon } from "@chakra-ui/icons";
import { Avatar, Box, Flex, useDisclosure } from "@chakra-ui/react";
import { Role, User } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { CellProps, Column } from "react-table";
import { useMeContext } from "../../../stores/me-context";
import { grantPermission } from "../../../utils/grantPermission";
import { useMyTeam } from "../../../utils/query-helpers/reactQueryHooks";
import OptionsDropdown, { OptionsDropdownItem } from "../../CRUD/OptionsDropdown";
import CRUDResource from "../../CRUD/Resource";
import FullScreenSpinner from "../../FullScreenSpinner";
import ErrorCardView from "../../layout/ErrorCardView";
import EditRoleModal from "./EditRoleModal";
import NewMemberModal from "./NewMemberModal";

interface MemberListProps {
  id: any;
}

// type TableColumn = typeof Column<User> & {
//   isNumeric: boolean;
// }

/**
 * The column names.
 * The react-table-config file adds the isNumeric type
 * *** see https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
 * *** to configure react-table typings
 */
const columns: Column<User>[] = [
  {
    accessor: (row) => (
      <Flex alignItems="center">
        <Avatar size="sm" name={row.name} src={row.avatarUrl} mr={2} />
        {row.name}
      </Flex>
    ),
    Header: "Name",
  },
  {
    accessor: "email",
    Header: "Email",
    isNumeric: true,
  },
  {
    accessor: "role",
    Header: "Role",
  },
];

const MemberList: React.FC<MemberListProps> = ({ id }) => {
  const [editRoleUser, setEditRoleUser] = React.useState<User>();
  const roleDisclosure = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useMeContext();
  // Infinity
  const { data: users, isLoading, error } = useMyTeam();
  const queryClient = useQueryClient();
  // const { mutateAsync, isLoading: isMutating } = useMutation(
  //   CompanyAPI.removeMember(),
  //   {
  //     onSuccess: () => {
  //       // TODO: better handle this: can't you just delete one out of cache as well?
  //       queryClient.invalidateQueries("my-team");
  //     },
  //   }
  // );

  const optionsColumn = React.useMemo(
    () => ({
      id: "optionsColumn",
      isNumeric: true,
      minWidth: 60,
      chakraMaxWidth: "60px",
      width: 60,
      Cell: ({ row }: CellProps<User>) => (
        <OptionsDropdown>
          <OptionsDropdownItem
            icon={<EditIcon boxSize={3} />}
            onClick={() => {
              setEditRoleUser(row.original);
              roleDisclosure.onOpen();
            }}
            name="Edit role..."
          />
          {/* <OptionsDropdownItem
          icon={<Icon as={IoLogOutOutline} boxSize={4} />}
          onClick={(e) => alert("Remove member clicked")}
          name="Remove member"
        /> */}
        </OptionsDropdown>
      ),
    }),
    [roleDisclosure],
  );

  if (isLoading) return <FullScreenSpinner />;
  if (!user) return <ErrorCardView title="Could not load your information" />;
  if (error || !users) return <ErrorCardView title="Could not load company members" />;

  const permissionToRemoveMembers = grantPermission(user.role, [Role.ADMIN]);
  const memberLimitReached = users.length >= user.company.maxUsers;

  return (
    <Box id={id}>
      <NewMemberModal isOpen={isOpen} onClose={onClose} />
      {editRoleUser && <EditRoleModal disclosure={roleDisclosure} user={editRoleUser} />}
      {/* TODO: switch to react-hook-form, but this doesn't need the useFormContext in the Table component */}
      <CRUDResource
        formName="Company members"
        data={users}
        // Only add the remove icons if the user has permission
        columns={
          permissionToRemoveMembers ? ([...columns, optionsColumn] as Column<any>[]) : (columns as Column<any>[])
        }
        handleAdd={onOpen}
        disableAddButton={memberLimitReached}
        buttonDisplayName="member"
      />
    </Box>
  );
};
export default MemberList;
