import { Stack } from "@chakra-ui/react";
import React from "react";

import ContactList from "../../features/contacts/ContactList";
import LearningSection from "../../components/learn/LearningSection";
import articleData from "../../assets/data/learnsection/learnArticles.json";

const Contacts: React.FC = () => {
  return (
    <Stack w="100%" maxW="1000px" mx="auto" my={10} gap={0}>
      <ContactList />
      <LearningSection learnData={articleData.contactArticles} />
      {/*<ContactGroupsList />*/}
    </Stack>
  );
};
export default Contacts;
