import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "events";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

// Add language to event
const addLanguage = (
  id: string,
  language_code: string,
  translateFields?: {
    targetLanguage: string;
    sourceLanguage?: string;
    translateFromUserCompanyId?: string;
  },
) => {
  return rawApiProvider.post(`${url}/${id}/addLanguage`, {
    language_code,
    translateFields,
  });
};

const duplicate = (id: string, tripdayId: string) => {
  return rawApiProvider.post(url + "/duplicate", {
    id,
    tripdayId,
  });
};

//remove language from event
const removeLanguage = (id: string, language_code: string) => {
  return rawApiProvider.post(`${url}/${id}/removeLanguage`, {
    language_code,
  });
};

export type EventsAPIType = ReturnEndpoints & {
  addLanguage: (
    id: string,
    language_code: string,
    translateFields?: {
      targetLanguage: string;
      sourceLanguage?: string;
      translateFromUserCompanyId?: string;
    },
  ) => Promise<any>;
  removeLanguage: (id: string, language_code: string) => Promise<any>;
  duplicate: (id: string, tripdayId: string) => Promise<any>;
};
const EventsAPI: EventsAPIType = {
  ...apiEndpoints,
  addLanguage,
  removeLanguage,
  duplicate,
};
export default EventsAPI;
