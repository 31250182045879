import React from "react";
import TextfieldsAPI from "../../../api/textfields.api";
import { useMeContext } from "../../../stores/me-context";
import { getEmptyTranslationField } from "../../../pages/create-trip";
import { User } from "@lato/common";
import { TextFieldElement } from "@lato/common";
import { TEXTFIELDS_QUERY_KEY } from "../../../utils/constants";
import ENV from "../../../utils/env";
import LibraryItems from "../../trips/edit/daybyday/library-items/LibraryItems";
import LibraryItemsList from "../../trips/edit/daybyday/library-items/LibraryItemsList";

interface TextFieldsProps {}

const resourceName = "textfield";

export const getEmptyTextField = (me: User): TextFieldElement => ({
  descriptions: [getEmptyTranslationField(me)],
  name: "",
  ord: 0,
  user: null,
});

const TextFields: React.FC<TextFieldsProps> = () => {
  const user = useMeContext();

  const localLanguageFilter = localStorage.getItem(`filter-Text Fields-${user.id}-${ENV}`);

  const initialLanguageFilter =
    localLanguageFilter && JSON.parse(localLanguageFilter).filters?.find((f: any) => f.id === "languages")?.value;

  return (
    <LibraryItems>
      <LibraryItemsList
        api={TextfieldsAPI}
        eventType={resourceName}
        emptyResource={getEmptyTextField(user)}
        query_key={TEXTFIELDS_QUERY_KEY}
        initialFilters={[
          {
            id: "languages",
            value: initialLanguageFilter && initialLanguageFilter?.length !== 0 ? initialLanguageFilter : undefined,
          },
        ]}
      />
    </LibraryItems>
  );
};
export default React.memo(TextFields);
