import { newEmptyBrand } from "../components/settings/company/Branding";
import { Trip, User, TranslationField } from "@lato/common";

// export const getDefaultLanguage = (me: User): Language => {
//   code: "nl",
//   flag: "🇳🇱",
//   name: "Nederlands",
// };

export const getEmptyTranslationField = (me: User): TranslationField => ({
  content: "",
  language: me.defaultTFLang,
  language_code: me.defaultTFLang.code,
  creator: me,
});

export const getInitialTrip = (me: User): Trip => ({
  sample: false,
  titles: [getEmptyTranslationField(me)],
  descriptions: [getEmptyTranslationField(me)],
  client_name: "",
  start_date: new Date().toISOString().substring(0, 10),
  end_date: new Date().toISOString().substring(0, 10),
  collaboratorCanEdit: me.defaultCollaboratorCanEdit,
  userTrips: [
    // When creating a new trip, also create a new usertrip template
    {
      brand: newEmptyBrand,
      // This user is overwritten in the backend.
      // @ts-ignore
      user: null,
      notes: [],
      booked: false,
      external_ref: "",
      homeBackgroundNumber: 0,
      show_pois: me.defaultshow_pois,
      show_pdf: me.defaultshow_pdf,
      show_documents: me.defaultshow_documents,
      chat_enabled: me.defaultchat_enabled,
      show_price: me.defaultshow_price,
      status: me.defaultTripStatus,
      poi_range: me.defaultpoi_range,
      show_confirm_option: me.defaultshow_confirm_option,
      group_booking: me.defaultgroup_booking,
      show_directions: me.defaultshow_directions,
      daysAfterTripCancelled: me.daysAfterTripCancelled ? Number(me.daysAfterTripCancelled) : null,
    },
  ],
  country: undefined,
  tripdocs: [],
  tripdefaultdocs: [],
  chatmessages: [],
  contacts: [],
  priceDescriptions: [getEmptyTranslationField(me)],
  includeds: [getEmptyTranslationField(me)],
  notIncludeds: [getEmptyTranslationField(me)],
  tripdays: [
    {
      nrOfNights: 0,
      documents: [],
      dayNumber: 1,
      location: null,
      hotels: [],
      events: [],
      transportations: [],
      titles: [getEmptyTranslationField(me)],
    },
  ],
  flights: [
    // {
    //   airline: "Emirates",
    //   number: "EK650",
    //   // departureAirport: {
    //   departureAirportCode: "DXB",
    //   departureAirportName: "Dubai Airport",
    //   // },
    //   // arrivalAirport: {
    //   arrivalAirportCode: "BRU",
    //   arrivalAirportName: "Brussels Airport",
    //   // },
    //   departure_date_time: new Date(),
    //   arrival_date_time: new Date(),
    //   departureTerminal: "3",
    //   departureGate: "2",
    //   arrivalTerminal: "4",
    //   arrivalGate: "5",
    // },
    // {
    //   airline: "Brussels Airlines",
    //   number: "EK650",
    //   // departureAirport: {
    //   departureAirportCode: "DXB",
    //   departureAirportName: "Dubai Airport",
    //   // },
    //   // arrivalAirport: {
    //   arrivalAirportCode: "BRU",
    //   arrivalAirportName: "Brussels Airport",
    //   // },
    //   departure_date_time: new Date(),
    //   arrival_date_time: new Date(),
    //   departureTerminal: "3",
    //   departureGate: "2",
    //   arrivalTerminal: "4",
    //   arrivalGate: "5",
    // },
    // {
    //   airline: "Ryanair",
    //   number: "EK650",
    //   // departureAirport: {
    //   departureAirportCode: "DXB",
    //   departureAirportName: "Dubai Airport",
    //   // },
    //   // arrivalAirport: {
    //   arrivalAirportCode: "BRU",
    //   arrivalAirportName: "Brussels Airport",
    //   // },
    //   departure_date_time: new Date(),
    //   arrival_date_time: new Date(),
    //   departureTerminal: "3",
    //   departureGate: "2",
    //   arrivalTerminal: "4",
    //   arrivalGate: "5",
    // },
    // {
    //   airline: "British Airways",
    //   number: "EK650",
    //   // departureAirport: {
    //   departureAirportCode: "DXB",
    //   departureAirportName: "Dubai Airport",
    //   // },
    //   // arrivalAirport: {
    //   arrivalAirportCode: "BRU",
    //   arrivalAirportName: "Brussels Airport",
    //   // },
    //   departure_date_time: new Date(),
    //   arrival_date_time: new Date(),
    //   departureTerminal: "3",
    //   departureGate: "2",
    //   arrivalTerminal: "4",
    //   arrivalGate: "5",
    // },
  ],
});

// const CreateTrip: React.FC = () => {
//   const queryClient = useQueryClient();

//   const mutation = useMutation(
//     async (newTrip: Trip) => await TripsAPI.createTrip(newTrip),
//     {
//       onSuccess: () => {
//         // Invalidate the list of trips
//         queryClient.invalidateQueries(["usertrips"]);
//       },
//     }
//   );

//   const createTrip = async (trip: Trip) => {
//     return await mutation.mutateAsync(trip);
//   };

//   return (
//       <TripForm
//         type="New"
//         initialValues={emptyFormInitialValues}
//         requestHandler={createTrip}
//         first={true}
//         me={undefined}
//       />
//   );
// };
// export default CreateTrip;
