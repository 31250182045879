import { Flex, Spinner } from "@chakra-ui/react";
import { Trip, UserTrip } from "@lato/common";
import React, { useEffect, useState } from "react";
import { useSocketSubscribe } from "../../app/providers/SocketProvider";
import { Message } from "../../pages/chats";
import { useChatStore } from "../../stores/trip/chat-store";
import { SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../utils/constants";
import ChatBottomLayout from "./ChatBottomLayout";
import ChatBoxLayout from "./ChatBoxLayout";
import ChatTitleLayout from "./ChatTitleLayout";
import TripListDropDown from "../tripList/TripListDropDown";

interface ChatWrapperProps {
  setUserTripId: React.Dispatch<React.SetStateAction<string | undefined>>;
  trip: Trip | undefined;
  messageList: Message[];
  isLoading: boolean;
  isFetching: boolean;
  refetchEl: JSX.Element | undefined;
  selectedTripId: string | undefined;
  markMessageRead: (userTripId: string) => void;
}

const ChatWrapper: React.FC<ChatWrapperProps> = ({
  setUserTripId,
  trip,
  messageList,
  isLoading,
  isFetching,
  refetchEl,
  selectedTripId,
  markMessageRead,
}) => {
  const [allMessages, setAllMessages] = React.useState(messageList);
  const { newLocalMessagesPerTrip } = useChatStore();

  /**
   * UPdate the message list once a new message arrive and mark them as read
   * @param newMessage
   */
  const messageRoomListener = (newMessage: Message) => {
    // Mark the message as read
    markMessageRead(newMessage.usertrip.id!);
    // setAllMessages((prevState) => [...prevState, body]);
  };

  useEffect(() => {
    setAllMessages(messageList);
  }, [messageList]);

  // Binded Socket Event Listners and call back
  useSocketSubscribe("chatToClient", messageRoomListener);

  const reversedMessageList = [...allMessages].reverse();
  const newLocalMessages =
    selectedTripId && selectedTripId in newLocalMessagesPerTrip ? newLocalMessagesPerTrip[selectedTripId] : [];
  const allmessages = [...reversedMessageList, ...newLocalMessages];

  let usertrip: UserTrip | undefined = undefined;
  if (trip) {
    usertrip = trip.userTrips[trip.userTrips.length - 1];
    const { userTrips, ...tripWithoutUsertrips } = trip;
    usertrip["trip"] = tripWithoutUsertrips;
  }

  const chat_enabled = !!usertrip?.chat_enabled;

  isLoading = isLoading || (!usertrip && !!selectedTripId);
  return (
    <Flex
      w={{ base: "100%", xl: "50%" }}
      flexDir="column"
      borderRight={{ base: "none", xl: "1px" }}
      h={{ base: !trip ? "auto" : "64vh", sm: !trip ? "auto" : "73vh", xl: "100%" }}
      order={{ base: "2", xl: "0" }}
    >
      {isLoading ? (
        <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
          <Spinner color="brand.500" />
        </Flex>
      ) : (
        <>
          <ChatTitleLayout trip={trip} />
          {/* {!trip && <ChatSelectTrip setUserTripId={setUserTripId} />} */}
          {/* {!trip && <ChatDropDown setUserTripId={setUserTripId} />} */}
          {!trip && (
            <TripListDropDown
              setUserTripId={setUserTripId}
              queryKey={(trip as any)?.sample ? SAMPLES_USERTRIPS_KEY : TRIPS_USERTRIPS_KEY}
            />
          )}
          {/* {!trip && <TestDropDown />} */}
          {/* <ChatBoxLayout messages={trip ? trip.chatmessages : []} /> */}
          {selectedTripId && (
            <ChatBoxLayout
              messages={allmessages}
              refetchEl={refetchEl}
              isFetching={isFetching}
              chat_enabled={chat_enabled}
              selectedTripId={selectedTripId}
            />
          )}
          {selectedTripId && (
            <ChatBottomLayout
              tripId={trip?.id}
              usertrip={usertrip!}
              chat_enabled={chat_enabled}
              userTripId={selectedTripId}
            />
          )}
        </>
      )}
    </Flex>
  );
};
export default React.memo(ChatWrapper);
