import React from "react";

type UseIntersectionObserverProps = {
  root?: any;
  target: React.MutableRefObject<any>;
  onIntersect(): any;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
};
export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = 0.9,
  rootMargin = "0px",
  enabled = true,
}: UseIntersectionObserverProps) {
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        // The viewport for where the intersection is searched. Default to the complete viewport if not defined.
        root: root && root.current,
        // The margin around the viewport.
        rootMargin,
        // Runs the callback if the percentage of pixels of the target in the viewport (root) is greater than this threshold.
        // (1 means that the target should be completely in the view).
        threshold,
      },
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target.current, enabled]);
}
