import { QueryRelation } from "@lato/common";
import rawApiProvider from "./utilities/rawProvider";

const url = "tripdays";

// Get the standard CRUD operations

const getTripTripdays = (tripId: string, relations: string) => {
  return rawApiProvider.get(`${url}/trip/${tripId}?relation=${relations}`);
};

const getTripdaySplit = (tripdayId: string, relations: string) => {
  return rawApiProvider.get(`${url}/${tripdayId}?relation=${relations}`);
};

const patch = (tripdayId: string, tripday: object, relation: QueryRelation) => {
  return rawApiProvider.patch(`${url}/${tripdayId}`, { updateTripdayDTO: tripday, relation: relation });
};

const addTripdays = (tripId: string, number: number) => {
  return rawApiProvider.post(`${url}/${tripId}`, { number });
};

const removeTripday = (tripdayId: string) => {
  return rawApiProvider.remove(`${url}/${tripdayId}`);
};

type DefaultTripdaysAPIType = {
  getTripTripdays(tripId: string, relation: string): Promise<any>;
  getTripdaySplit(tripdayId: string, relation: string): Promise<any>;
  patch(tripdayId: string, tripday: object, relation: QueryRelation): Promise<any>;
  addTripdays(tripId: string, number: number): Promise<any>;
  removeTripday(tripdayId: string): Promise<any>;
};
const TripdaysAPI: DefaultTripdaysAPIType = {
  getTripTripdays,
  getTripdaySplit,
  patch,
  addTripdays,
  removeTripday,
};
export default TripdaysAPI;
