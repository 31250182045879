import { SettingsIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  MenuButton,
  MenuDivider,
  MenuGroup,
  Text,
  Tooltip,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { Trip, User } from "@lato/common";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { PlusCircleIcon } from "lucide-react";
import React, { useState } from "react";
import { IoMdHelpBuoy } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { SiBrevo } from "react-icons/si";
import { useLocation, useNavigate, type NavigateFunction } from "react-router-dom";
import AuthAPI from "../../../api/auth.api";

import { FaAngleLeft } from "react-icons/fa";
import { useMeContext } from "../../../stores/me-context";
import { getInitialTrip } from "../../../pages/create-trip";
import ENV from "../../../utils/env";
import { useCreateTrip } from "../../../utils/query-helpers/react-query-mutations";
import OptionsDropdown, { OptionsDropdownItem } from "../../../components/CRUD/OptionsDropdown";
import { UpgradeNowButton } from "../../../components/alerts/FreeTrialAlert";
import { CommandMenu } from "../../commandMenu/CommandMenu";
import { default as ResourceCenter } from "../../resource-center/ResourceCenter";
import TaskModal from "../../tasks/TaskModal";
import NotificationModal from "../../../components/modals/NotificationModal";
import NewModal, { allTabs } from "../NewModal";

interface HorizontalTopMenuBarProps {
  user: User;
}

export const logout = async (navigate: NavigateFunction, queryClient: QueryClient) => {
  // Set the lastLoggedIn in localstorage
  localStorage.setItem("lastLoggedIn", JSON.stringify(new Date()));
  await AuthAPI.logout();
  // Remove the react-query cache
  queryClient.clear();
  navigate("/", { replace: true });
  //Refresh page so that the login form is interactive again
  navigate(0);
};

const HorizontalTopMenuBar: React.FC<HorizontalTopMenuBarProps> = ({ user }) => {
  const navigate = useNavigate();
  const disclosureNotification = useDisclosure();
  const editDisclosure = useDisclosure();
  const disclosureNew = useDisclosure();
  const location = useLocation();
  const settingsRoute = "/settings/account";
  const leadRoute = "/lead";
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const me = useMeContext();
  const ref = React.useRef();

  const [selectedTab, setSelectedTab] = useState<string>();
  const { mutateAsync: createTripAsync, isPending: isCreating } = useCreateTrip();

  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  const handleOptionClick = () => {
    setPopoverOpen(false);
  };
  const closeAllPopovers = () => {
    setPopoverOpen(false);
    console.log("closing all");
  };

  // Hook to handle outside clicks for help popover
  useOutsideClick({
    ref: ref,
    handler: () => setPopoverOpen(false),
  });

  const handleCreateTrip = async (isSample: boolean) => {
    const emptyFormInitialValues = getInitialTrip(me);
    const userTripId = await createTrip(emptyFormInitialValues, isSample);
    // Go to the edit trip or sample page
    navigate(`/${isSample ? "samples" : "trips"}/${userTripId}`);
  };

  const createTrip = async (initialTrip: Trip, sample: boolean): Promise<string> => {
    const sampleOverwrites = sample
      ? {
          sample,
          start_date: null,
          end_date: null,
        }
      : {};
    // Create a new trip
    const trip: Trip = await createTripAsync({
      ...initialTrip,
      ...sampleOverwrites,
    });
    return trip.userTrips[trip.userTrips.length - 1].id!;
  };

  return (
    <>
      <Box pos="sticky" w="inherit" top={0} zIndex={"sticky"} bg="lato.bg">
        <Flex p={2} justifyContent={"space-between"} alignItems="center" mx={{ base: "0", lg: "8" }}>
          {/* <VerticalMenuButton
            onClick={() => disclosure.onOpen()}
            name="Notifications"
          >
            <BellIcon w={6} h={6} />
            <NotificationBadge count={1} transform="translate(5px, -5px)" />
          </VerticalMenuButton> */}
          <div></div>
          <Box>
            <CommandMenu />
          </Box>

          <div className="flex align-center gap-4">
            <UpgradeNowButton user={user} />
            {ENV !== "production" && (
              <div className="border-r border-gray-300 px-4 mt-0.5">
                <OptionsDropdown
                  menuIcon={
                    <>
                      <Icon as={PlusCircleIcon} boxSize={4} className="mr-1.5 mb-0.5" />
                      <span className="text-md">New</span>
                    </>
                  }
                  offset={[-80, 16]}
                  label="Create items"
                >
                  <MenuGroup title="General">
                    <OptionsDropdownItem
                      key="trip"
                      name="Trip"
                      // icon={displayBookedOption(trip_status)}
                      onClick={() => handleCreateTrip(false)}
                      _hover={{ cursor: "pointer" }}
                    />
                    <OptionsDropdownItem
                      key="sample"
                      name="Sample"
                      // icon={displayBookedOption(trip_status)}
                      onClick={() => handleCreateTrip(true)}
                      _hover={{ cursor: "pointer" }}
                    />
                    <OptionsDropdownItem
                      key="task"
                      name="Task"
                      // icon={displayBookedOption(trip_status)}
                      onClick={() => {
                        editDisclosure.onOpen();
                      }}
                      _hover={{ cursor: "pointer" }}
                    />
                  </MenuGroup>
                  <MenuDivider />
                  <MenuGroup title="Library items">
                    {allTabs.map((tab: string) => (
                      <OptionsDropdownItem
                        key={tab}
                        name={tab}
                        // icon={displayBookedOption(trip_status)}
                        onClick={() => {
                          setSelectedTab(tab);
                          disclosureNew.onOpen();
                        }}
                        _hover={{ cursor: "pointer" }}
                      />
                    ))}
                    {/* <OptionsDropdownItem
                    key="poi"
                    name="POI"
                    // icon={displayBookedOption(trip_status)}
                    // onClick={(e) => handleChangeTripStatus(e, row.index, trip_status)}
                    _hover={{ cursor: "pointer" }}
                  /> */}
                  </MenuGroup>
                </OptionsDropdown>
              </div>
            )}

            <div ref={ref}>
              <VerticalMenuButton
                alignItems={"center"}
                onClick={togglePopover}
                name="Help"
                active={isPopoverOpen}
                pt={"4px"}
                id="produktlyHelpIcon"
              >
                <Icon as={IoMdHelpBuoy} boxSize={6} />

                {/* <FollowRequestBadge /> */}
              </VerticalMenuButton>
              <ResourceCenter
                isOpen={isPopoverOpen}
                onClose={() => setPopoverOpen(false)}
                handleOptionClick={handleOptionClick}
                isProductTour={false}
                closeAllPopovers={closeAllPopovers}
                user={user}
              />
            </div>

            {ENV === "staging" && (
              <VerticalMenuButton
                alignItems={"center"}
                mr={1}
                onClick={() => navigate(leadRoute)}
                name="Leads"
                active={location.pathname.split("/")[1] === leadRoute.split("/")[1]}
              >
                <Icon as={SiBrevo} boxSize={5} />
                {/* <FollowRequestBadge /> */}
              </VerticalMenuButton>
            )}
            <VerticalMenuButton
              alignItems={"center"}
              onClick={() => navigate(settingsRoute)}
              name="Settings"
              active={location.pathname.split("/")[1] === settingsRoute.split("/")[1]}
              id="produktlySettingsIcon"
            >
              <Icon as={SettingsIcon} boxSize={5} />
              {/* <FollowRequestBadge /> */}
            </VerticalMenuButton>
            {/* <Tooltip
            label="Notifications"
            aria-label="avatar-menu-icon"
            closeOnMouseDown
          ></Tooltip> */}
            <Flex alignItems={"center"}>
              <AvatarMenu user={user} />
            </Flex>
          </div>
        </Flex>
        <Divider />
      </Box>
      {disclosureNotification.isOpen && <NotificationModal disclosure={disclosureNotification} />}
      {disclosureNew.isOpen && <NewModal disclosure={disclosureNew} selectedTab={selectedTab} />}
      {editDisclosure.isOpen && (
        <TaskModal onSubmitTask={() => editDisclosure.onClose()} modalDisclosure={editDisclosure} />
      )}
    </>
  );
};

export default React.memo(HorizontalTopMenuBar);

interface AvatarMenuProps {
  user: User;
}

const AvatarMenu: React.FC<AvatarMenuProps> = ({ user }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <OptionsDropdown
      gutter={5}
      menuButton={
        <Tooltip label="Profile" aria-label="avatar-menu-icon">
          <MenuButton
            as={Avatar}
            size="sm"
            flexDir="column"
            className="cursor-pointer avatar-button"
            name={user.name}
            src={user.avatar && user.avatarUrl ? user.avatarUrl : ""}
          />
        </Tooltip>
      }
    >
      <Box px={3} pt={1}>
        <Heading size="md">{user.name}</Heading>
        <Text fontSize="xs">{user.email}</Text>
        <Text fontSize="xs">{user.company.name}</Text>
      </Box>
      <MenuDivider />
      <OptionsDropdownItem
        icon={<Icon as={IoLogOutOutline} boxSize={4} />}
        name="Logout"
        onClick={async () => {
          await logout(navigate, queryClient);
        }}
      />
    </OptionsDropdown>
  );
};

const VerticalMenuButton: React.FC<any> = ({ name, active, children, ...props }) => {
  const color = active ? "currentColor" : "gray.600";
  return (
    <Tooltip label={name}>
      <Box
        {...props}
        className="relative cursor-pointer flex align-center"
        color={color}
        textColor={color}
        _hover={{ textColor: "currentColor" }}
        transition="all 0.2s ease-out"
      >
        {children}
      </Box>
    </Tooltip>
  );
};
