import { yupResolver } from "@hookform/resolvers/yup";
import { Contact, HOTEL_ORIGIN, Hotel, Trip, calculateTripdayDateByDayIndex } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { type DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { MdHotel } from "react-icons/md";
import HotelsAPI from "../../../../../../api/hotels.api";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, TRIPDAY_ACCOMMODATION_QUERY_KEY } from "../../../../../../utils/constants";
import {
  useAccommodation,
  usePatchAccommodation,
  useTripContacts,
} from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import useMutation from "../../../../../../utils/query-helpers/useMutation";
import { hotelSchemaInTrip } from "../../../../../../validation/validationSchemas";
import { ElementSkeleton } from "../../../../../FullScreenSpinner";
import { calculateAge } from "../../../../../../features/contacts/Contact";
import GeneralElementForm from "../../GeneralElementForm";
import { copyDocuments, copyImages } from "../../import-from-library/AddFromLibraryCard";
import { copyLocation } from "../activities/EventFormInTrip";
import HotelForm from "./HotelForm";
import { submitForms } from "../../../../../../utils/EventHelper";

interface HotelFormInTripProps {
  i: number;
  tripdayIndex: number;
  remove: (index: string) => void;
  tripday: any;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  fieldArrayMethods: any;
  uniqueId: any;
  collaboratorCanEdit: boolean;
  hotelAmount: number;
  hotel: Hotel;
  firstInSwiper?: boolean;
  lastInSwiper?: boolean;
  orderIndex: number;
  tripdayId: string;
  move: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    fromIndex: number,
    toIndex: number,
    moveRelated?: boolean,
  ) => void;
  updateAccommodation?: (data: any) => Promise<void>;
  isUpdatingAccommodation?: boolean;
}

const HotelFormInTripWrapper: React.FC<HotelFormInTripProps> = ({ ...props }) => {
  const { isLoadingTranslations: isLoadingTranslationsFromStore, trip, isMovingElements } = useTripFormStore();

  const {
    data: hotel,
    isLoading: isLoadingHotel,
    isFetching: isFetchingHotel,
    isFetchedAfterMount: isFetchedAfterMountHotel,
  } = useAccommodation(props.hotel.id ?? "", { enabled: props.hotel.id != null });

  const queryClient = useQueryClient();

  const { mutateAsync: updateAccommodation, isPending: isUpdatingAccommodation } = usePatchAccommodation(
    props.hotel.id ?? "",
    queryClient,
    trip?.id,
  );

  const isLoadingTrans = (!isFetchedAfterMountHotel && isFetchingHotel) || isLoadingTranslationsFromStore;

  if (isLoadingHotel || isFetchingHotel || !hotel || isUpdatingAccommodation || isLoadingTrans || isMovingElements)
    return <ElementSkeleton />;

  return (
    <HotelFormInTrip
      {...props}
      hotel={hotel}
      updateAccommodation={updateAccommodation}
      isUpdatingAccommodation={isUpdatingAccommodation}
    />
  );
};

const HotelFormInTrip: React.FC<HotelFormInTripProps> = ({
  i,
  tripdayIndex,
  remove,
  tripday,
  dragHandleProps,
  fieldArrayMethods,
  uniqueId,
  collaboratorCanEdit,
  hotelAmount,
  hotel,
  firstInSwiper = false,
  lastInSwiper = false,
  orderIndex,
  move,
  updateAccommodation = () => {},
  isUpdatingAccommodation = false,
  tripdayId,
}) => {
  const formId = `2-${tripdayIndex}-form-${i}-accommodation`;
  const {
    isFirst,
    trip,
    setIsErrorForms,
    setIsInvalidForms,
    setUnCollapsedElement,
    unCollapsedElement,
    isSubmittingForms,
    isInvalidForms,
  } = useTripFormStore();

  const queryClient = useQueryClient();

  //When booking in a different tab an item in localstorage gets added to refetch this accommodation
  //react-query doesnt invalidate across different tabs
  window.addEventListener("storage", () => {
    const refetchId = localStorage.getItem(`${TRIPDAY_ACCOMMODATION_QUERY_KEY}-${hotel.id}`);
    if (refetchId === hotel.id) {
      queryClient.invalidateQueries({ queryKey: [TRIPDAY_ACCOMMODATION_QUERY_KEY, hotel.id] });
      localStorage.removeItem(`${TRIPDAY_ACCOMMODATION_QUERY_KEY}-${hotel.id}`);
    }
  });

  const { data: contacts, isLoading: isLoadingContacts } = useTripContacts(trip.id) as {
    data: Contact[];
    isLoading: boolean;
  };

  const formMethods = useForm<Hotel>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: hotel,
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(hotelSchemaInTrip, { abortEarly: false }),
  });

  const { mutate: copyHotel } = useMutation({
    apiCall: (id: string) => HotelsAPI.duplicate(id, tripdayId),
    successMessage: `duplicated accommodation`,
    failMessage: `duplicating accommodation`,
  });

  const handleDuplicateAccommodation = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const hotelFormValues = formMethods.getValues();
      await updateAccommodation(hotelFormValues);
      const copiedHotel = await copyHotel(hotelFormValues.id);
      //await fieldArrayMethods.append(e, { ...copiedHotel, ord: copiedHotel.ord + hotelAmount });
      await queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripdayId] });
    },
    [fieldArrayMethods.append, hotel, hotelAmount],
  );

  const handleMoveTripdayAccommodation = React.useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number, tripday: any) => {
      await fieldArrayMethods.remove(i, false);
      await updateAccommodation({
        ...formMethods.getValues(),
        ord:
          tripday.hotels.length > 0
            ? tripday.hotels[tripday.hotels.length - 1].ord + 1
            : tripday.events.length + tripday.hotels.length + tripday.transportations.length,
        tripdayId: tripday?.id,
      });

      await queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripday?.id] });
      await queryClient.invalidateQueries({ queryKey: [DAY_BY_DAY_STEP_TRIPDAY_QUERY_KEY, tripdayId] });
    },
    [fieldArrayMethods.moveToTripday, formMethods.getValues],
  );

  if (isLoadingContacts || !hotel?.id) return <ElementSkeleton />;

  const tripdayStartDateString = calculateTripdayDateByDayIndex(tripdayIndex, trip.start_date, trip);
  const tripdayStartDate = tripdayStartDateString ? new Date(tripdayStartDateString) : new Date(trip.start_date);

  return (
    <GeneralElementForm
      handleDelete={(e) => {
        remove(hotel.id!);
        setIsErrorForms(formId, false);
        setIsInvalidForms(formId, false);
      }}
      handleDuplicate={handleDuplicateAccommodation}
      handleTripdayMove={async (e, tripday) => {
        await handleMoveTripdayAccommodation(e, +i, tripday);
      }}
      handleMoveLeft={(e) => move(e, i, i - 1)}
      handleMoveRight={(e) => move(e, i, i + 1)}
      canDuplicate={hotelAmount < 3}
      type="accommodation"
      getEvent={formMethods.getValues}
      first={isFirst}
      //code for collapsable elements
      //title={hotel?.name}
      icon={MdHotel}
      currentTripdayIndex={tripdayIndex}
      canMove={false}
      showLeftMove={!firstInSwiper}
      showRightMove={!lastInSwiper}
      hideLastTripday
      onMouseDown={async (e) => {
        if (hotel?.id && ![...isInvalidForms.values()].some((value) => value === true)) {
          e.stopPropagation();
          unCollapsedElement && unCollapsedElement !== hotel?.id && (await submitForms(isSubmittingForms));
          setUnCollapsedElement(hotel?.id);
        }
      }}
    >
      <HotelForm
        tripProps={{
          location: tripday.location,
          tripdayIndex: tripdayIndex,
          tripdayId: tripday.id,
          libraryItemIndex: i,
          first: isFirst,
          uniqueId: hotel.updated_at,
          collaboratorCanEdit: collaboratorCanEdit,
          holder: contacts.length > 0 ? contacts[0] : undefined,
          adults: contacts.filter(
            (contact) => !contact.date_of_birth || (contact.date_of_birth && calculateAge(contact.date_of_birth) >= 18),
          ).length,
          children: contacts
            .filter((contact) => contact.date_of_birth && calculateAge(contact.date_of_birth) < 18)
            .map((contact) => ({ age: calculateAge(contact.date_of_birth!, tripdayStartDate) })),
          formId: formId,
        }}
        formMethods={formMethods}
        hotelAmount={hotelAmount}
        hotel={hotel}
        formId={formId}
        updateAccommodation={updateAccommodation}
        isUpdatingAccommodation={isUpdatingAccommodation}
      />
    </GeneralElementForm>
  );
};

export default React.memo(HotelFormInTripWrapper);
