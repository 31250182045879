import NotesStep from "../../../../components/trips/edit/notes/NotesStep";
import { default as TripStepWrapper } from "../TripStepWrapper";

export const NotesTripRoute: React.FC = () => {
  const stepNumber = 5;

  return (
    <TripStepWrapper>
      <NotesStep stepNumber={stepNumber} />
    </TripStepWrapper>
  );
};

export default NotesTripRoute;
