import {
  Box,
  Flex,
  FormLabel,
  IconButton,
  Portal,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import {
  APICALL,
  BOARD_TYPES,
  HOTEL_BOOKING_STATUS,
  HOTEL_ORIGIN,
  Hotel,
  Room,
  TLocation,
  encodeToLettersAndNumbersOnly,
} from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { Trash2 as DeleteIcon } from "lucide-react";
import React from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import PdfAPI from "../../../../../../api/pdf.api";
import { useMeContext } from "../../../../../../stores/me-context";
import { useTripLanguage } from "../../../../../../stores/trip-language-context";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";
import { DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY } from "../../../../../../utils/constants";
import { useLatoRooms } from "../../../../../../utils/query-helpers/reactQueryHooks";
import {
  updateRelationMap,
  usePatchSplitTripday,
} from "../../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { ELEMENT_VIEW } from "../../../../../CRUD/Resource";
import { DSListItemConfig } from "../../../../../CustomDownShift/CustomComboBox";
import CustomDownShiftWithPagination from "../../../../../CustomDownShift/CustomDownShiftWithPagination";
import Documents from "../../../../../../features/documents/Documents";
import RHFInput from "../../../../../input/RHFInput";
import RHFTranslationInput from "../../../../../input/RHFTranslationInput";
import { default as AutocompleteStaticMap } from "../../../../../../features/map/AutocompleteStaticMap";
import ConfirmModal from "../../../../../modals/ConfirmModal";
import ElementPreview from "../../ElementPreview";
import { default as PicturesUploader } from "../../PicturesUploader";
import LibraryItemDownshift, { LIBRARYITEM_TYPE } from "../LibraryItemDownshift";
import { afterChooseElementPictures } from "../activities/afterChooseElementPictures";
import BookModal from "./BookModal";
import { default as StarRating } from "./StarRating";
import StaticMap from "../../../../../map/staticMap";
import { SupplierInformation } from "./SupplierInformation";
import { ContactInformation } from "./ContactInformation";
import { AmountLabel } from "./AmountLabel";
import { SimpleItemDownshift } from "./SimpleItemDownshift";
import HotelFormOutsideTrip from "./HotelFormOutsideTrip";
import { SingleItem } from "./SingleItem";
import { HotelFormProps, roomMap, boardMap } from "./HotelForm";

export const ActualHotelForm: React.FC<HotelFormProps> = ({
  tripProps,
  updateAccommodation,
  hotel,
  hotelAmount,
  onDelete,
  isCreate,
  view,
  forceNew = false,
}) => {
  const { getValues } = useFormContext();
  const bookDisclosure = useDisclosure();
  const { trip, setInTripStore, unCollapsedElement } = useTripFormStore();
  const user = useMeContext();
  const [generatingPDF, setGeneratingPDF] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(unCollapsedElement !== hotel?.id && !!tripProps);
  const queryClient = useQueryClient();
  const { realLanguageIndex } = useTripLanguage();

  React.useEffect(() => {
    setCollapsed(unCollapsedElement !== hotel?.id && !!tripProps);
  }, [unCollapsedElement]);

  const ref = React.useRef(null);

  React.useEffect(() => {
    const now = new Date();
    const eventCreatedData = hotel?.created_at && new Date(hotel.created_at);
    if (
      eventCreatedData &&
      eventCreatedData.getDate() === now.getDate() &&
      eventCreatedData.getHours() === now.getHours() &&
      eventCreatedData.getMinutes() === now.getMinutes() &&
      (hotelAmount ?? 2) < 2
    )
      ref && ref.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, []);

  const { mutateAsync: updateTripday } = usePatchSplitTripday(
    tripProps?.tripdayId ?? "",
    updateRelationMap[DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY],
    queryClient,
    DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  );

  const tripdayLibraryImage =
    trip?.tripdays && tripProps ? trip.tripdays[tripProps?.tripdayIndex ?? 0]?.libraryImage : true;
  const tripdayImage = trip?.tripdays && tripProps ? trip.tripdays[tripProps?.tripdayIndex ?? 0]?.image : true;

  const afterChooseAccommodationPictures = React.useCallback(
    async (images: any[], library = false) => {
      if (!tripdayImage && !tripdayLibraryImage)
        await afterChooseElementPictures(
          images,
          tripProps?.tripdayId,
          updateTripday,
          library,
          tripProps?.tripdayIndex ?? 0,
          setInTripStore,
          trip?.tripdays && tripProps ? trip.tripdays[tripProps?.tripdayIndex ?? 0] : undefined,
        );
    },
    [trip, tripProps],
  );

  const { data: latoRooms, isLoading: isLoadingLatoRooms } = useLatoRooms();

  const first = tripProps ? tripProps.first : true;
  const collaboratorCanEdit = tripProps ? tripProps.collaboratorCanEdit : true;
  const { field: location } = useController({
    name: `location`,
  });

  const { field: bookingStatus } = useController({
    name: "bookingStatus",
  });

  const origin = useWatch({
    name: "origin",
  });
  const originCode = useWatch({
    name: "originCode",
  });

  const images = useWatch({
    name: "images",
  });

  const libraryImages = useWatch({
    name: "libraryImages",
  });

  const allImages = [...images, ...libraryImages];

  const documents = useWatch({
    name: "documents",
  });

  const { field: board } = useController({
    name: "board",
  });
  const { field: rooms } = useController({
    name: "rooms",
  });

  const downloadVoucher = async (e: any) => {
    e.stopPropagation();

    const formValues = getValues();
    setGeneratingPDF(true);

    const hotel = await updateAccommodation(formValues);

    const res = await PdfAPI.downloadTripAccommodationVoucherPDF(hotel.id, trip.userTrips[tripProps?.first ? 0 : 1].id);

    // Create blob link to download
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${encodeToLettersAndNumbersOnly(hotel?.name ?? "voucher")}.pdf`;

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
    setGeneratingPDF(false);
  };

  const [selectedRooms, setSelectedRooms] = React.useState<Room[]>(rooms.value ?? []);
  const hasDelete = onDelete !== undefined;
  const confirmDisclosure = useDisclosure();

  React.useEffect(() => {
    !!roomMap[origin as HOTEL_ORIGIN] &&
      rooms.onChange(
        selectedRooms.map((room: any) => ({
          ...room,
          maxAdults: +(room.maxAdults ?? 0),
          maxChildren: +(room.maxChildren ?? 0),
        })),
      );
  }, [selectedRooms]);

  const customRoomItem: DSListItemConfig<any>[] = React.useMemo(
    () => [
      {
        ListItem: ({ inputValue }: any) => <SingleItem item={{ description: `Add room '${inputValue}'` }} />,
        showSelected: ({ inputValue }: any) => inputValue,
        isHidden: ({ inputValue }: any) => inputValue?.length <= 1,
        customObject: (inputValue: any) => ({
          description: inputValue,
          value: inputValue,
        }),
      },
    ],
    [],
  );

  const customBoardItem: DSListItemConfig<any>[] = React.useMemo(
    () => [
      {
        ListItem: ({ inputValue }: any) => <SingleItem item={{ description: `Add board '${inputValue}'` }} />,
        showSelected: ({ inputValue }: any) => inputValue,
        isHidden: ({ inputValue }: any) => !inputValue || inputValue?.length <= 1,
        onSelected: ({ inputValue }: any) => board.onChange(inputValue),
      },
    ],
    [],
  );

  const preview = React.useCallback(() => {
    const element = getValues();
    return (
      <ElementPreview
        title={element.name}
        description={element.descriptions[realLanguageIndex]?.content}
        location={element.location?.name}
        firstCoords={element.location?.coordinates}
        overlay={!!unCollapsedElement}
        externalImages={element.images}
        libraryImages={element.libraryImages}
        type="hotel"
      />
    );
  }, [getValues, realLanguageIndex]);

  const [fromLocation, setFromLocation] = React.useState<TLocation | null>(location.value);

  if (collapsed) {
    return (
      <div ref={ref} id={hotel?.id}>
        {preview()}
      </div>
    );
  }

  return (
    <>
      <div className="space-y-4" ref={ref} id={hotel?.id ?? getValues("id")}>
        {!isCreate && hasDelete && view === ELEMENT_VIEW.MAP && (
          <Stack direction="row" alignItems="center" h={10}>
            {/* Only show the delete icon when the form for updating and there is a delete method (handeld in LibraryItemModal.tsx) */}

            <Tooltip label="Delete Hotel" aria-label="Delete Hotel" hasArrow>
              <IconButton
                aria-label="Delete Hotel"
                size="sm"
                onClick={() => confirmDisclosure.onOpen()}
                icon={<DeleteIcon />}
                colorScheme="red"
                variant="outline"
                border="none"
                marginLeft="auto"
              />
            </Tooltip>
          </Stack>
        )}

        <ConfirmModal
          title="Delete Hotel"
          description="Are you sure you want to delete this Hotel?"
          disclosure={confirmDisclosure}
          action={onDelete}
        />

        <div className="ml-2">
          {tripProps && (
            <Box
              mt={"1.3em !important"}
              ml={{ base: "-8em", sm: "-8em", md: "-1em", lg: "-8em", xl: "-1em", "2xl": "-1em" }}
            >
              <StarRating name={"rating"} scale={1} />
            </Box>
          )}
          {tripProps &&
            (bookingStatus.value === HOTEL_BOOKING_STATUS.QUOTE ? (
              <LibraryItemDownshift
                {...tripProps}
                inTrip
                getValues={getValues}
                libraryItemName={LIBRARYITEM_TYPE.HOTELS}
                libraryItem={hotel}
                reset={updateAccommodation}
                placeholder="Hotel name"
              />
            ) : (
              <RHFInput name={`name`} size="md" h="unset" isDisabled={!first} fontSize="25px" layout="inline" />
            ))}
        </div>
        {tripProps ? (
          <div className="pr-8">
            <RHFTranslationInput
              first={first}
              collaboratorCanEdit={collaboratorCanEdit}
              name={`descriptions.0.content`}
              label={"Description"}
              size="md"
              textarea
              isInline
            />
          </div>
        ) : (
          <HotelFormOutsideTrip updateAccommodation={updateAccommodation} forceNew={forceNew} />
        )}

        <Tabs variant="soft-rounded" colorScheme="brand">
          <TabList flexFlow={"wrap"}>
            <Tab>General</Tab>
            <Tab>Supplier</Tab>
            <Tab>Contact</Tab>
            <Tab>
              Images
              <AmountLabel amount={allImages?.length} />
            </Tab>
            <Tab>
              Documents
              <AmountLabel amount={documents?.length} />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex width={"100%"} justify={"space-between"} gap={4}>
                {/*When the hotel is custommade we supply standard boards and rooms*/}
                <Stack width="50%" gap={4} justifyContent={"space-between"}>
                  <AutocompleteStaticMap
                    fillFieldName={`name`}
                    fromFieldName={`location`}
                    setGeometryFromValue={(coordinates: any, name: string, address: any) => {
                      if (coordinates === null) {
                        location.onChange(null);
                      } else {
                        location.onChange({
                          name: name,
                          coordinates,
                          address: address,
                        });
                      }
                    }}
                    mapMargin={"0.3em"}
                    first={first}
                    uniqueId={`${tripProps?.uniqueId}`}
                    inTrip={tripProps !== null}
                    fromLocationValue={location.value}
                    setFromLocation={setFromLocation}
                  ></AutocompleteStaticMap>
                  {!boardMap[origin as HOTEL_ORIGIN] ? (
                    <SimpleItemDownshift
                      name={"board"}
                      placeholder="Pick or type a board"
                      key={`board-downshift-${tripProps?.uniqueId}`}
                      items={Object.values(BOARD_TYPES).map((item) => ({ description: item, value: item }))}
                      defaultSelected={[board.value]}
                      onChange={(value: any) => {
                        if (Object.values(BOARD_TYPES).includes(value?.value)) {
                          board.onChange(value?.value);
                        } else if (Object.values(BOARD_TYPES).includes(value)) {
                          board.onChange(value);
                        }
                      }}
                      customListItemConfigs={customBoardItem}
                      isDisabled={
                        bookingStatus.value === HOTEL_BOOKING_STATUS.BOOKED || (tripProps && !tripProps?.first)
                      }
                    />
                  ) : (
                    <>
                      {/*In case of a hotel supplied by a external supplier we retrieve the rooms and boards from the external supplier since they are custom per hotel*/}
                      <Box width={"100%"}>
                        <FormLabel h={"2em"}>
                          <Flex>
                            <Text>Board</Text>
                          </Flex>
                        </FormLabel>
                        <CustomDownShiftWithPagination
                          apiCalls={[
                            {
                              call: boardMap[origin as HOTEL_ORIGIN],
                              type: APICALL.TRPC,
                              name: "boards",
                            },
                          ]}
                          key={`board-libdownshift-${tripProps?.uniqueId}`}
                          disableInput={bookingStatus.value === HOTEL_BOOKING_STATUS.BOOKED || !tripProps?.first}
                          queryName={"hotelboards"}
                          height={"200px"}
                          listItemConfig={{
                            ListItem: SingleItem,
                            showSelected: ({ item }) =>
                              item?.description
                                ? item.description.charAt(0) + item.description.slice(1).toLowerCase()
                                : "",
                            onSelected: ({ item }) => {
                              board.onChange(
                                item?.description
                                  ? item.description.charAt(0) + item.description.slice(1).toLowerCase()
                                  : null,
                              );
                            },
                          }}
                          // Change the value of the controller field.
                          additionalQueryState={{
                            code: originCode,
                          }}
                          initialInputValue={board.value}
                        />
                      </Box>
                    </>
                  )}
                  {!roomMap[origin as HOTEL_ORIGIN] ? (
                    <>
                      {isLoadingLatoRooms ? (
                        <Spinner />
                      ) : (
                        <SimpleItemDownshift
                          name={"room"}
                          key={`room-downshift-${tripProps?.uniqueId}`}
                          placeholder="Pick or type a room"
                          items={[
                            ...(latoRooms ?? []),
                            ...rooms.value?.filter(
                              (item: any) => latoRooms?.findIndex((room: any) => room.id === item.id) < 0,
                            ),
                          ].map((item: Room) => ({
                            description: item.description,
                            value: item.id,
                          }))}
                          defaultSelected={rooms.value && rooms.value.map((item: Room) => item.id)}
                          onChange={(items: any[]) => {
                            rooms.onChange(
                              items.map((item, i) => {
                                const roomIndex = rooms.value.findIndex((room: any) => room.id === item?.value);
                                return {
                                  id: roomIndex >= 0 && roomIndex !== i ? uuidv4() : item?.value,
                                  description: item?.description,
                                  maxAdults: 1,
                                  maxChildren: 0,
                                };
                              }),
                            );
                          }}
                          customListItemConfigs={customRoomItem}
                          multipleSelection
                          isDisabled={bookingStatus.value === HOTEL_BOOKING_STATUS.BOOKED || !tripProps?.first}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {/*In case of a hotel supplied by a external supplier we retrieve the rooms and boards from the external supplier since they are custom per hotel*/}
                      <Box width={"100%"}>
                        <FormLabel h={"2em"}>
                          <Flex>
                            <Text>Rooms</Text>
                          </Flex>
                        </FormLabel>
                        <CustomDownShiftWithPagination
                          apiCalls={[
                            {
                              call: roomMap[origin as HOTEL_ORIGIN],
                              type: APICALL.TRPC,
                              name: "boards",
                            },
                          ]}
                          key={`room-libdownshift-${tripProps?.uniqueId}`}
                          disableInput={bookingStatus.value === HOTEL_BOOKING_STATUS.BOOKED || !tripProps?.first}
                          queryName={"hotelrooms"}
                          listItemConfig={{
                            ListItem: SingleItem,
                            showSelected: ({ item }) =>
                              item?.description
                                ? item.description.charAt(0) + item.description.slice(1).toLowerCase()
                                : "",
                          }}
                          inputItemConfig={{
                            inputItem: SingleItem,
                          }}
                          defaultSelected={selectedRooms}
                          // Change the value of the controller field.
                          additionalQueryState={{
                            code: originCode,
                            limit: 10,
                            cursor: 1,
                          }}
                          placeholder="Add a room"
                          height={"200px"}
                          multipleSelection
                          selectedItems={selectedRooms}
                          setSelectedItems={(rooms) => setSelectedRooms(rooms)}
                          useDivider={false}
                        />
                      </Box>
                    </>
                  )}
                </Stack>
                {fromLocation?.coordinates && (
                  <Box mt={4} mr={4} width="50%">
                    <StaticMap
                      containerHeight="11.2rem"
                      containerWidth="100%"
                      height={640}
                      width={1000}
                      center={
                        fromLocation?.coordinates
                          ? {
                              lng: fromLocation.coordinates[0],
                              lat: fromLocation.coordinates[1],
                            }
                          : undefined
                      }
                    />
                  </Box>
                )}
              </Flex>
            </TabPanel>

            <TabPanel className="space-y-4">
              <SupplierInformation isFirst={tripProps?.first} placeholderRef="AZE123" placeholderChain="Expedia" />
            </TabPanel>
            <TabPanel>
              <ContactInformation first={tripProps?.first} />
            </TabPanel>
            <TabPanel>
              <PicturesUploader
                name={`images`}
                defaultSearchValue={tripProps?.location?.name || ""}
                defaultSearchValueName={getValues(`location.name`) ? "location.name" : "name"}
                awsFilename={`accommodation`}
                first={first}
                location={getValues(`location`) ?? (tripProps ? tripProps.location : undefined)}
                afterChoosePictures={(images) => afterChooseAccommodationPictures(images)}
                afterChooseLibraryPictures={(images) => afterChooseAccommodationPictures(images, true)}
              />
            </TabPanel>
            <TabPanel p={0}>
              <Documents
                fieldArrayName={`documents`}
                inForm={false}
                trip={trip}
                disabled={(tripProps && !tripProps?.first) ?? undefined}
                showHeading={false}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      {bookDisclosure.isOpen && (
        <Portal>
          <BookModal
            onClose={bookDisclosure.onClose}
            isOpen={bookDisclosure.isOpen}
            hotel={getValues() as Hotel}
            startDate={tripProps?.tripdayStartDate ?? new Date()}
            endDate={tripProps?.tripdayEndDate ?? new Date()}
            roomCall={roomMap[origin as HOTEL_ORIGIN]}
            holder={tripProps?.holder}
            adults={tripProps?.adults ?? 0}
            children={tripProps?.children ?? []}
            setRooms={setSelectedRooms}
            clientReference={user.company.hotelbeds_client_id}
          />
        </Portal>
      )}
    </>
  );
};
