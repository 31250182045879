import { Flex, Heading, Text, useToast } from "@chakra-ui/react";
import { Plan, User } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import CompanyAPI from "../../../../../api/companies.api";
import UsersAPI from "../../../../../api/users.api";
import { useMeContext } from "../../../../../stores/me-context";
import { handleSubmission } from "../../../../../utils/toErrorMap";
import useUnsavedChangesPrompt from "../../../../../utils/useUnsavedChangesPrompt";
import Form from "../../../../form/Form";
import SubmitButton from "../../../../form/SubmitButton";
import Settings from "../../../../settings/Settings";
import DefaultDocuments from "../../../../settings/documents/DefaultDocuments";
import { TripSettingsFields } from "./TripSettings";
import ENV from "../../../../../utils/env";
import { ME_QUERY_KEY } from "../../../../../utils/constants";

const OverallTripSettings: React.FC<any> = () => {
  const [error, setError] = React.useState<string | undefined>();

  const user = useMeContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  const formMethods = useForm<User>({
    mode: "onChange",
    // resolver: yupResolver(validationSchema),
    defaultValues: user,
  });

  useUnsavedChangesPrompt(formMethods.formState.isDirty);

  const { mutateAsync: mutateUser } = useMutation({
    mutationFn: UsersAPI.patchUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ME_QUERY_KEY] });
    },
  });

  const updateUserInformation = async (user: User) => {
    await handleSubmission({
      successMessage: "updated user information",
      failMessage: "updating user information",
      apiCall: mutateUser(user),
      toast,
      setError: formMethods.setError,
    });

    // await handleSubmitCompany(user.company);
  };

  const { mutateAsync: mutateCompany, isPending } = useMutation({
    mutationFn: CompanyAPI.updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ME_QUERY_KEY] });
    },
  });

  // const handleSubmitCompany = async (company: Company) => {
  //   const formValues = formMethodsCompany.getValues();
  //   const lowestDaysBefore = Math.min(...formValues.defaultPaymentInvitations.map((dpi) => dpi.days_before));
  //   console.log("tt", lowestDaysBefore);
  //   const formValuesChanged = {
  //     ...formValues,
  //     defaultPaymentInvitations: formValues.defaultPaymentInvitations?.map((dpi) => ({
  //       ...dpi,
  //       type: "Percentage" as PAYMENT_INVITATIONTYPE,
  //       status: "Inactive" as PAYMENT_INVITATIONSTATUS,
  //       is_last: dpi.days_before == lowestDaysBefore,
  //     })),
  //   };

  //   console.log("ttt", formValues);
  //   await mutateCompany(formValues);
  // };

  const showSettings = {
    showBrands: false,
    showchat_enabled: true,
    show_pois: true,
    show_pdf: true,
    show_documents: true,
    showTripStatus: true,
    showpoi_range: true,
    showCollaboratorCanEdit: user.company.plan === Plan.PRO && ENV !== "staging",
    show_confirm_option: true,
    showgroup_booking: true,
    showChatMail: true,
    showbook_link: false,
    showmax_travellers: false,
    showbooked_travellers: false,
    showdefaultlang: true,
    showshowprice: true,
    show_defaults_show_price: true,
    show_default_show_directions: true,
    show_new_edittrip_layout: true,
    showPaymentSettings: true,
    showDaysAfterCancelled: true,
  };

  const generalRef = "general-settings";
  const chatRef = "chat-settings";
  const mapRef = "map-settings";
  const documentsRef = "default-document-settings";
  const PDFRef = "pdf-settings";
  const showDocumentsRef = "document-settings";
  const paymentsRef = "payment-settings";

  const subNavItems = [
    {
      name: "General",
      reference: generalRef,
    },
    {
      name: "Chat",
      reference: chatRef,
    },
    {
      name: "Map",
      reference: mapRef,
    },
    {
      name: "PDF",
      reference: PDFRef,
    },
    {
      name: "Documents",
      reference: showDocumentsRef,
    },
    {
      name: "Default documents",
      reference: documentsRef,
    },
    // { name: "Trip reference", reference: refRef },
  ];

  return (
    <Settings subNavItems={subNavItems} tabName={"Trip"}>
      <Form formMethods={formMethods} onSubmit={updateUserInformation}>
        <Flex alignItems="top" mt={2} mb={4} w={"100%"} justifyContent={"space-between"}>
          <div>
            <Heading>Default Trip Settings</Heading>
            <Text fontSize="md" color="realGray.600">
              These settings can be changed per user.
            </Text>
          </div>
          <SubmitButton>Save</SubmitButton>
        </Flex>
        <Flex flexDirection={"column"} gap={1}>
          <TripSettingsFields
            {...showSettings}
            chatEnableFormName={"defaultchat_enabled"}
            show_poisFormName={"defaultshow_pois"}
            show_pdfFormName={"defaultshow_pdf"}
            show_documentsFormName={"defaultshow_documents"}
            tripStatusFormName={"defaultTripStatus"}
            poi_rangeFormName={"defaultpoi_range"}
            collaboratorCanEditName={"defaultCollaboratorCanEdit"}
            show_confirm_optionFormName={"defaultshow_confirm_option"}
            daysAfterCancelledFieldName={"daysAfterTripCancelled"}
            show_directionsName={"defaultshow_directions"}
            default_show_price_name={"defaultshow_price"}
            group_bookingName={"defaultgroup_booking"}
            chatMailName={"receiveChatMail"}
            defaultlangName={"defaultTFLang"}
            new_edittripName={"useNewEditTripLayout"}
            defaultInputWidth={"20em"}
            inTrip={false}
            generalId={generalRef}
            chatId={chatRef}
            poiId={mapRef}
            pdfId={PDFRef}
            documentsId={showDocumentsRef}
            paymentsId={paymentsRef}
          ></TripSettingsFields>
        </Flex>
      </Form>
      <DefaultDocuments id={documentsRef}></DefaultDocuments>
      {/* 
      <Form formMethods={formMethodsCompany} onSubmit={handleSubmitCompany}>
        <Flex alignItems="center" mt={2} mb={4} w={"100%"} justifyContent={"space-between"}>
          <Heading>Default Company Settings Configuration</Heading>
          <SubmitButton isDisabled={!!error} isLoading={isPending}>
            Save changes
          </SubmitButton>
        </Flex>
        <Flex flexDirection={"column"} gap={3}>
          <TripPaymentSettings
            id={paymentsRef}
            company={user.company}
            error={error}
            setError={setError}
            showAccountNumber={true}
            showDaysAfterCancelled={true}
            paymentInvitationsFieldName="defaultPaymentInvitations"
            daysAfterCancelledFieldName="daysAfterTripCancelled"
          />
          <TripRefSettings user={user} id={refRef}></TripRefSettings>
          <TripTerms id={termsRef} company={user.company} />
        </Flex>
      </Form> */}
      {/* <DefaultDocuments id={documentsRef}></DefaultDocuments> */}
      {/* <TripRefSettings user={user} id={refRef}></TripRefSettings> */}
    </Settings>
  );
};

export default OverallTripSettings;
