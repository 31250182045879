import React, { useState, ReactNode } from "react";
import { Flex, Icon, Heading, IconButton, Tooltip, Box } from "@chakra-ui/react";
import { IoChevronForward, IoChevronDown } from "react-icons/io5";
import { AddIcon } from "@chakra-ui/icons";
import { BadgeCount } from "./CRUD/Resource";

interface CollapsibleSectionProps {
  title: string;
  badgeCount?: number;
  onAdd?: (e: React.MouseEvent) => void;
  children: ReactNode;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, badgeCount, onAdd, children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <Box>
      <Flex width="100%" gap={5} alignItems="center" onClick={() => setIsCollapsed((prev) => !prev)} cursor="pointer">
        <Icon
          aria-label={isCollapsed ? "expand" : "collapse"}
          as={isCollapsed ? IoChevronForward : IoChevronDown}
          boxSize={6}
          color="grey"
        />
        <Heading size="lg" my="0.3em">
          {title}
          {badgeCount !== undefined && <BadgeCount totalCount={badgeCount} height="1.5em" />}
        </Heading>
        {onAdd && (
          <Tooltip label={`Add new ${title}`}>
            <IconButton
              ml="auto"
              aria-label="add"
              icon={<AddIcon />}
              colorScheme="brand"
              variant="ghost"
              onClick={(e) => {
                e.stopPropagation();
                onAdd(e);
              }}
            />
          </Tooltip>
        )}
      </Flex>
      {!isCollapsed && <Box mt={4}>{children}</Box>}
    </Box>
  );
};

export default CollapsibleSection;
