import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  List,
  ListItem,
  Spinner,
} from "@chakra-ui/react";
import {
  GetPropsCommonOptions,
  UseComboboxGetItemPropsOptions,
  UseComboboxGetMenuPropsOptions,
  useCombobox,
} from "downshift";
import { Fragment, default as React, RefObject, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import colors from "../../theme/foundations/colors";
import { FilterSelect } from "./FilterSelect";

export enum FILTER_TYPE {
  RANGE = "range",
  SELECT = "select",
  LOCATION = "location",
  MULTISELECT = "multiSelect",
  DATERANGE = "dateRange",
  SLIDER = "slider",
}

export type DropdownFilter = {
  options?: { text: string; value: any }[] | number;
  onChange?: (value: any) => void;
  step?: number;
  icon: any;
  currentValue: any;
  name?: string;
  type: FILTER_TYPE;
  hide?: boolean;
  displayValue?: string;
  prio?: boolean;
  marks?: number[];
  min?: number;
  label?: string;
};

export type DSAccessorProps<T extends object> = {
  item: any;
  inputValue: string;
};
export type DSListItemConfig<T extends object> = {
  //accessor: string | ((dsAccessorProps: DSAccessorProps<T>) => string); // Deprecated. If we want a global accessor back. Please search and find all 'accessor' usages in this file. Either a key of the object or a function returning a string. The result is shown in the list item. Very important as this is used in listitem itself but also shown in the input box when the corresponding listitem was selected. But could also be used as for example a title tooltip
  ListItem: string | ((listItemProps: DSAccessorProps<T>) => React.ReactNode); // See accessor type as well as this has now been changed. This prop receive React component which gives us single dropdown item and can design custom Single list item Element. The value represents the value indicated by the result of the accessor while the item represents the complete item before the accessor was applied.
  onSelected?: (dsAccessorProps: DSAccessorProps<T>) => void | Promise<void>; // This prop is a callback function which return us  selected value /  item from the dropdown
  showSelected?: string | ((dsAccessorProps: DSAccessorProps<T>) => string); // This prop is used to display value of selected item in the input box.
  isHidden?: boolean | ((dsAccessorProps: DSAccessorProps<T>) => boolean);
  customObject?: (dsAccessorProps: DSAccessorProps<T>) => T;
  customValidation?: (dsAccessorProps: DSAccessorProps<T>) => true | string;
};

export type DSInputItemConfig<T extends object> = {
  inputItem: string | ((listItemProps: DSAccessorProps<T>) => React.ReactNode); // See accessor type as well as this has now been changed. This prop receive React component which gives us single dropdown item and can design custom Single list item Element. The value represents the value indicated by the result of the accessor while the item represents the complete item before the accessor was applied.
  onSelected?: (dsAccessorProps: DSAccessorProps<T>) => void | Promise<void>; // This prop is a callback function which return us  selected value /  item from the dropdown
  showSelected?: string | ((dsAccessorProps: DSAccessorProps<T>) => string); // This prop is used to display value of selected item in the input box.
  isHidden?: boolean | ((dsAccessorProps: DSAccessorProps<T>) => boolean);
};

// TODO Try later to make it more generic type, try to replace any with Generic T
export type CustomComboBoxProps<T extends object> = {
  isLoading?: boolean; // It’s a optional prop used to display Spinner when data is loading,  It has default value false
  placeholder?: string; // It’s a optional prop used used to display placeholder value in Input, It has default value `Search…`
  dropDownItems: T[]; //Prop is used to pass Array to downshift to load content in dropdown
  disableItemSelection?: boolean; // Prop is used to disable item selection from the dropdown
  disableInput?: boolean; //Prop used to disable inputField of dropdown
  onSelectedItemChangeCallBack?: (selectedItem: any) => void; // This prop is a callback function which return us  selected value /  item from the dropdown
  showSelectedItem?: (item: any) => string; // This prop is used to display value of selected item in the input box
  listItemConfig: DSListItemConfig<T>;
  inputItemConfig?: DSInputItemConfig<T>;
  customListItemConfigs?: DSListItemConfig<T>[];
  onInputValueChangeCallBack?: (inputValue: string, shouldDirty: boolean) => void; //This props is a callback which return us user entered input value in the dropdown
  bottomRef?: RefObject<HTMLDivElement>; // This props is used to add ref to bottom of dropdown for scrolling
  refetchEl?: JSX.Element | undefined; // This props is used to add ref to bottom of dropdown for automatic scrolling
  defaultSelected?: any; // Prop for setting default Selected Item
  InputEl?: JSX.Element;
  initialInputValue?: string;
  height?: string;
  priorityItems?: T[];
  filters?: DropdownFilter[];
  useDivider?: boolean;
  isDropdown?: boolean;
  inputRef?: React.RefObject<HTMLDivElement>;
};

function CustomComboBox<T extends object>({
  isLoading = false,
  placeholder = "Search...",
  dropDownItems = [],
  listItemConfig,
  customListItemConfigs = [],
  disableItemSelection = false,
  disableInput = false,
  onSelectedItemChangeCallBack,
  showSelectedItem,
  onInputValueChangeCallBack,
  bottomRef,
  refetchEl,
  defaultSelected,
  InputEl,
  initialInputValue = "",
  height = "400px",
  filters,
  useDivider,
  isDropdown = true,
}: CustomComboBoxProps<T>) {
  const [userInput, setUserInput] = React.useState(initialInputValue);

  useEffect(() => {
    setUserInput(initialInputValue);
  }, [initialInputValue]);

  // Filter out the custom list item configs which need to be hidden
  const filteredCustomListItemConfigs = customListItemConfigs.filter(
    ({ isHidden }, i) =>
      !(
        isHidden === true ||
        (typeof isHidden === "function" &&
          isHidden({
            // Just set the item to empty, doesn't really matter
            item: {},
            inputValue: userInput,
          }))
      ),
  );
  // Map the custom configs to real list items and add an index value. This way we can retrieve the corresponding list config from the item.
  const additionalCustomItems: any[] = filteredCustomListItemConfigs.map((_customListItemConf, i) => ({
    // ...customListItemConf.item,
    custom: true,
    index: i,
  }));

  const items = [...additionalCustomItems, ...dropDownItems];

  const getItemConfig = (item: any): DSListItemConfig<T> => {
    // Check if the index is smaller than the number of custom list items. Because then we should get the config of one of the custom list items.
    if (
      item &&
      item.hasOwnProperty("custom") &&
      item["custom"] === true &&
      item.hasOwnProperty("index") &&
      item["index"] < customListItemConfigs.length
    ) {
      return customListItemConfigs[item["index"]!];
    }
    return listItemConfig;
  };

  const {
    isOpen,
    openMenu,
    closeMenu,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getInputProps,
    setHighlightedIndex,
  } = useCombobox({
    initialInputValue: initialInputValue,
    // defaultHighlightedIndex: 0,
    items,
    defaultSelectedItem: defaultSelected,
    onIsOpenChange: () => {},
    onSelectedItemChange: async ({ selectedItem }) => {
      const { onSelected } = getItemConfig(selectedItem);
      if (onSelected && selectedItem) {
        await onSelected({ item: selectedItem, inputValue: userInput });
      }
      // // Close the menu once all onClick handlers are done. We made sure to not close the menu when selecting a list item in the state reducer.
      // closeMenu();
    },
    itemToString: (item) => {
      // If for some reason the item is null or undefined return an empty string.
      if (!item) return "";
      const itemConfig = getItemConfig(item);
      let { showSelected } = itemConfig;
      const { ListItem } = itemConfig;
      if (!showSelected && typeof ListItem === "string") {
        // If the showSelected is not defined and the ListItem is just string accessor, assign the listitem accessor to the showSelected
        showSelected = ListItem;
        // const { accessor, showSelected = accessor } = getItemConfig(item);
      }
      // If the showSelected is a string, the string is the key of the object.
      if (typeof showSelected === "string") return item[showSelected as keyof typeof item] as string;
      // If the showSelected is defined it is a function which maps to the correct object property to display.
      else if (showSelected) return showSelected({ item: item, inputValue: userInput });
      // If the showSelected is not defined, clear the input value.
      return "";
    },
    //inputValue: userInput,
    onInputValueChange: ({ inputValue }) => {
      setUserInput(inputValue || "");
      if (onInputValueChangeCallBack) {
        onInputValueChangeCallBack(inputValue || "", false);
      }
    },
    // stateReducer: (state, { changes, type }) => {
    //   switch (type) {
    //     case useCombobox.stateChangeTypes.InputKeyDownEnter:
    //     case useCombobox.stateChangeTypes.ItemClick:
    //       if (!changes.selectedItem) return changes;
    //       return {
    //         ...changes,
    //         // Don't close the menu as soon as you click a list item.
    //         isOpen: true,
    //       };
    //     default:
    //       return changes; // otherwise business as usual.
    //   }
    // },
  });

  const commonInputProps: InputProps = {
    autoComplete: "nope",
    ...getInputProps(),
    onBlur: (e) => {
      if (onInputValueChangeCallBack) {
        onInputValueChangeCallBack(e.target.value || "", true);
      }
    },
  };

  return (
    <Box w="100%" position={"relative"}>
      {/* <!-- The text and password here are to prevent FF from auto filling my login credentials because it ignores autocomplete="off"--> */}
      {InputEl ? (
        React.cloneElement(InputEl, commonInputProps)
      ) : (
        <InputGroup size="md" m={0}>
          <InputLeftElement pointerEvents="none">
            {isLoading ? <Spinner color="gray.300" /> : <SearchIcon color="gray.300" />}
          </InputLeftElement>
          <Input
            bg="white"
            onClick={openMenu}
            placeholder={placeholder && placeholder != "" ? placeholder : undefined}
            size="md"
            disabled={disableInput}
            {...commonInputProps}
          />
        </InputGroup>
      )}
      <CustomDownShiftList
        getMenuProps={getMenuProps}
        getItemConfig={getItemConfig}
        getItemProps={getItemProps}
        isOpen={isOpen}
        items={items}
        height={height}
        userInput={userInput}
        refetchEl={refetchEl}
        highlightedIndex={highlightedIndex}
        disableItemSelection={disableItemSelection}
        isLoading={isLoading}
        filters={filters}
        useDivider={useDivider}
        isDropdown={isDropdown}
      />
    </Box>
  );
}
export default React.memo(CustomComboBox);

interface CustomDownShiftListProps<T extends object> {
  getMenuProps: (
    options?: UseComboboxGetMenuPropsOptions | undefined,
    otherOptions?: GetPropsCommonOptions | undefined,
  ) => any;
  getItemConfig: (item: any) => DSListItemConfig<T>;
  getItemProps: (options: UseComboboxGetItemPropsOptions<any>) => any;
  isOpen: boolean;
  items: any[];
  height: string;
  userInput: any;
  disableItemSelection?: boolean;
  highlightedIndex: number;
  refetchEl?: JSX.Element | undefined;
  isLoading?: boolean;
  filters?: DropdownFilter[];
  useDivider?: boolean;
  isDropdown?: boolean;
}

export function CustomDownShiftList<T extends Object>({ ...props }: CustomDownShiftListProps<T>) {
  const { getMenuProps, isOpen, items, height, refetchEl, isLoading, filters, useDivider, isDropdown } = props;
  let firstNotOriginal = false;

  //stupid fix for stupid rangepicker
  const [isOpenWrap, setIsOpenWrap] = React.useState(isOpen);
  const [portalClicked, setPortalClicked] = React.useState(false);
  const [filterChanging, setFilterChanging] = React.useState(false);

  useEffect(() => {
    if (!filterChanging && !portalClicked) {
      setIsOpenWrap(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (portalClicked) {
      setIsOpenWrap(true);
    }
  }, [portalClicked]);

  useEffect(() => {
    setFilterChanging(false);
    setPortalClicked(false);
  }, [items]);

  const showList = !!filters || items?.length > 0;

  return (
    <>
      {showList && (
        <Box>
          {filters && (
            <Flex
              display={isOpenWrap ? "flex" : "none"}
              position="absolute"
              right={"1em"}
              left={0}
              top={"2.2em"}
              background={"white"}
              px={"1em"}
              pt={"1em"}
              pb={"0.5em"}
              zIndex={1001}
              overflowX={"auto"}
              overflowY={"hidden"}
              gap={2}
              w={"98%"}
              h={"3.5rem"}
              onClick={() => {
                setPortalClicked(true);
              }}
            >
              {filters.map(
                (f, i) =>
                  !f.hide && (
                    <FilterSelect
                      onChange={(e) => {
                        f.onChange && setFilterChanging(true);
                        f.onChange && f.onChange(e);
                      }}
                      options={f.options}
                      icon={f.icon}
                      defaultSelected={f.displayValue ?? f.currentValue}
                      type={f.type}
                      onClick={() => {
                        setPortalClicked(true);
                      }}
                      step={f.step}
                      marks={f.marks}
                      min={f.min}
                      label={f.label}
                    />
                  ),
              )}

              {/* <Divider
                w={"95%"}
                zIndex={-1}
                position="absolute"
                top={"3.5em"}
                opacity={1}
                colorScheme={"brand"}
                borderColor={colors.lato.primaryColor}
              /> */}
            </Flex>
          )}
          <List
            {...getMenuProps()}
            display={isOpenWrap ? undefined : "none"}
            position={isDropdown ? "absolute" : "relative"}
            zIndex={"dropdown"}
            maxH={height}
            overflow="auto"
            boxShadow={isDropdown ? "0px 7px 25px -5px rgba(0,0,0,0.3)" : ""}
            rounded="lg"
            bg="white"
            p={2}
            minW={isDropdown ? "25em" : "18em"}
            w="100%"
            onBlur={(e) => {
              if (
                !e.relatedTarget?.id.includes("menu") &&
                !e.currentTarget.contains(e.relatedTarget) &&
                !filterChanging &&
                !portalClicked &&
                !isOpen
              ) {
                setIsOpenWrap(false);
              }
            }}
          >
            {filters && <>{filters.length > 0 && <Box h={"3em"} w={"100%"}></Box>}</>}
            {isLoading && (
              <Flex justifyContent={"center"} m={1}>
                <Spinner></Spinner>
              </Flex>
            )}
            {items &&
              items.map((item, index) => {
                if (item) {
                  const listItem = (
                    <CustomDownShiftListItem {...props} item={item} index={index} key={`complete-list-item-${index}`} />
                  );
                  if (!firstNotOriginal && !item.original && index !== 0 && useDivider) {
                    firstNotOriginal = true;
                    return (
                      <>
                        <hr />
                        {listItem}
                      </>
                    );
                  }
                  return listItem;
                }
              })}
            {refetchEl}
          </List>
        </Box>
      )}
    </>
  );
}

interface CustomDownShiftListItemProps<T extends object> extends CustomDownShiftListProps<T> {
  item: T;
  index: number;
}

export function KFormatter(num: number) {
  return Math.abs(num) > 999
    ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1) as any) + "K"
    : Math.sign(num) * Math.abs(num);
}

function CustomDownShiftListItem<T extends object>({
  item,
  userInput,
  getItemConfig,
  getItemProps,
  index,
  disableItemSelection = false,
  highlightedIndex,
}: CustomDownShiftListItemProps<T>) {
  const accessorProps: DSAccessorProps<T> = {
    item: item,
    inputValue: userInput,
  };
  const { ListItem: DSListItem } = getItemConfig(item);
  const rest = { ...getItemProps({ item, index }) };
  if (disableItemSelection) {
    delete rest.onClick;
  }

  return (
    <Fragment>
      <ListItem
        {...rest}
        bg={highlightedIndex === index ? "gray.200" : undefined}
        p={2}
        cursor={disableItemSelection ? undefined : "pointer"}
        borderRadius="lg"
      >
        {typeof DSListItem === "string"
          ? // @ts-ignore
            item[DSListItem]
          : DSListItem(accessorProps)}
      </ListItem>
      {/* Add a divider after each list item except after the last one */}
      {/* {index !== items.length - 1 && <Divider />} */}
    </Fragment>
  );
}
