import React from "react";
// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/a11y/a11y.scss";
// import "swiper/components/controller/controller.scss";
// Custom swiper styles
import { AspectRatio } from "@chakra-ui/react";
import { Draggable } from "react-beautiful-dnd";
import DragAndDrop from "../../components/layout/drag-and-drop/DragAndDrop";
import { extraPictureWidth } from "../../components/trips/edit/daybyday/PicturesUploader";
import { useWindowWidth } from "../../utils/useWindowWidth";
import ExtraPicture from "./ExtraPicture";

interface PictureSwiperProps {
  removePicture?: (index: number) => void;
  movePicture?: (fromIndex: number, toIndex: number) => void;
  images: any[];
  name: string;
  first?: boolean;
  addOffset?: boolean;
}

export const extraPictureAspectRatio = 6 / 5;
const PictureSwiper: React.FC<PictureSwiperProps> = ({
  name,
  removePicture,
  movePicture,
  images,
  first,
  addOffset = false,
}) => {
  const handleDragAndDropMove = React.useCallback(
    (fromIndex: number, toIndex: number) => {
      if (first) {
        movePicture!(fromIndex, toIndex);
      }
    },
    [movePicture],
  );

  const windowSize = useWindowWidth();

  const dragEnabled = !!movePicture;
  console.log(images);

  return (
    <div className="flex overflow-auto swiper-no-swiping" style={{ overflow: "overlay" }}>
      <DragAndDrop moveCallback={handleDragAndDropMove} direction="horizontal" className="flex justify-start w-full">
        {/* <Swiper
        // effect="coverflow"
        // mousewheel
        // Makes sure the swiper slides further when released to scroll faster
        freeMode={true}
        // onSlideChange={handleSlideChange}
        style={{
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
        slidesPerView={2.3}
        spaceBetween={5}
      > */}
        {/* {editable && (
          <SwiperSlide>
            <AspectRatio ratio={aspectRatio}>
              <TripdayExtraPictureUploader
                i={i}
                trip={trip}
                aspectRatio={aspectRatio}
                tripday={tripday}
              />
            </AspectRatio>
          </SwiperSlide>
        )} */}

        {images.map((picture, index) => (
          <Draggable
            disableInteractiveElementBlocking
            key={picture.rhfId! || picture.id}
            draggableId={picture.rhfId! || picture.id}
            index={index}
            isDragDisabled={!dragEnabled || !first}
          >
            {(provided, snapshot) => {
              if (snapshot.isDragging) {
                if (addOffset) {
                  (provided.draggableProps as any).style.left =
                    (provided.draggableProps as any).style.left - windowSize + 510;
                }
              }
              return (
                <AspectRatio
                  cursor={first ? "default" : "not-allowed"}
                  opacity={first ? "1" : "0.7"}
                  key={`${name}.${index}`}
                  w={extraPictureWidth}
                  minW={extraPictureWidth}
                  maxW={extraPictureWidth}
                  className="ms-2"
                  ratio={extraPictureAspectRatio}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  //style={provided.draggableProps.style}*/
                >
                  <ExtraPicture image={picture} imageSize="thumb" removeImage={removePicture} i={index} first={first} />
                </AspectRatio>
              );
            }}
          </Draggable>
        ))}
        {/* </Swiper> */}
      </DragAndDrop>
    </div>
  );
};
export default React.memo(PictureSwiper);
