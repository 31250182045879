import { CheckIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, List, ListIcon, ListItem, Stack, Text, Spacer } from "@chakra-ui/react";
import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import config from "../../../config";
import Section from "../../layout/Section";
import { Subscription } from "./checkout/Checkout";
import ENV from "../../../utils/env";
import { Plan } from "@lato/common";
interface PlanCardProps {
  plan: Plan;
  selectedSubscription: Subscription;
  changeSelectedPlan: (plan: Plan) => void;
  chooseSubscription: (subscription: Subscription) => Promise<void>;
}

export const planOrder: { [key in Plan]: number } = {
  [Plan.FREE]: 0,
  [Plan.LITE]: 1,
  [Plan.PRO]: 2,
};

export const allowedTrips: { [key in Plan]: number | "unlimited" } = {
  [Plan.FREE]: 8,
  [Plan.LITE]: "unlimited",
  [Plan.PRO]: "unlimited",
};

type PlanPeriodType = {
  stripeId: string;
  price: number;
  priceDiscount: number;
};

type PlanType = {
  description: string;
  features: string[];
  quarterly: PlanPeriodType;
  yearly: PlanPeriodType;
};

const configEnv = config[ENV];

export const plans: { [key in Plan]: PlanType } = {
  [Plan.FREE]: {
    description: "Try it out, get 8 trips for free per year.",
    features: [
      "Always free",
      `${allowedTrips[Plan.FREE]} trips monthly`,
      "Create quotes",
      "Quote approval",
      "Branded pocket guide",
      "B2B collaboration",
      "Human support",
    ],
    quarterly: {
      stripeId: "",
      price: 0,
      priceDiscount: 0,
    },
    yearly: {
      stripeId: "",
      price: 0,
      priceDiscount: 0,
    },
  },
  [Plan.LITE]: {
    description: "B2C: for travel professionals selling directly to travellers",
    features: [
      `${allowedTrips[Plan.LITE]} trips`,
      "Create quotes",
      "Quote approval",
      "Branded pocket guide",
      "B2B collaboration",
      "Human support",
    ],
    quarterly: {
      stripeId: configEnv.STRIPE_LITE_QUARTERLY,
      price: 40,
      priceDiscount: 30,
    },
    yearly: {
      stripeId: configEnv.STRIPE_LITE_YEARLY,
      price: 35,
      priceDiscount: 25,
    },
  },
  [Plan.PRO]: {
    description: "B2B: for travel professionals that are also selling to other travel professionals",
    features: ["All features in the Essential plan +", "B2B - selling", "B2B - advertising"],
    quarterly: {
      stripeId: configEnv.STRIPE_PRO_QUARTERLY,
      price: 50,
      priceDiscount: 40,
    },
    yearly: {
      stripeId: configEnv.STRIPE_PRO_YEARLY,
      price: 45,
      priceDiscount: 35,
    },
  },
};
const userDiscountThreshold = 5;

export const calculateMonthlyPrice = (subscription: Subscription) => {
  const planPeriod = subscription.yearly ? plans[subscription.plan].yearly : plans[subscription.plan].quarterly;
  return (
    Math.min(subscription.users, userDiscountThreshold) * planPeriod.price +
    Math.max(subscription.users - userDiscountThreshold, 0) * planPeriod.priceDiscount
  );
};

const PlanCard: React.FC<PlanCardProps> = ({ plan, selectedSubscription, changeSelectedPlan, chooseSubscription }) => {
  const { plan: selectedPlan, users, yearly } = selectedSubscription;
  const queryClient = useQueryClient();
  const active = plan === selectedPlan;
  const planName = plan[0].toUpperCase() + plan.substring(1);

  const price = calculateMonthlyPrice({
    ...selectedSubscription,
    plan,
  });
  return (
    <Section
      noDivider
      borderColor={active ? "brand.400" : undefined}
      borderWidth="3px"
      _hover={{ cursor: "pointer" }}
      onClick={() => changeSelectedPlan(plan)}
    >
      <Stack spacing={4} h="100%">
        <Box>
          <Heading size="xl">{planName}</Heading>
          <Text>{plans[plan].description}</Text>
        </Box>
        <Box>
          <Flex alignItems="center">
            <Heading size="2xl">€{yearly ? plans[plan].yearly.price : plans[plan].quarterly.price}</Heading>
            <Flex color="gray.500" alignItems={"baseline"}>
              <Text fontWeight="semibold" ml={1}>
                / user / month,
              </Text>
              <Text fontSize="xs">&nbsp;excl. TAX</Text>
            </Flex>
          </Flex>
          <Text fontSize="md" color="gray.500">
            From 6th user: €{yearly ? plans[plan].yearly.priceDiscount : plans[plan].quarterly.priceDiscount} per user
            per month
          </Text>
          {plan !== Plan.FREE && (
            <Heading size="md" color="gray.500" mt={2}>
              Total €{price} / month
            </Heading>
          )}
        </Box>

        <List spacing={2}>
          {plans[plan].features.map((feature, i) => (
            <ListItem key={`plan-${i}`}>
              <ListIcon as={CheckIcon} color="green.500" />
              {feature}
            </ListItem>
          ))}
        </List>
        <Flex flexDir="column" h="100%">
          <Spacer />
          {/* <Button
            colorScheme="brand"
            size="md"
            // isDisabled={active}
            isDisabled={selectedPlan !== Plan.FREE}
            isLoading={isLoadingCheckout}
            // onClick={() =>
            //   chooseSubscription({ plan: plan, users: users, yearly })
            // }
            onClick={handleSwitchTo}
          >
            Choose {planName} Plan
          </Button> */}
        </Flex>
      </Stack>
    </Section>
  );
};
export default React.memo(PlanCard);
