import ShareStep from "../../../components/trips/edit/share/ShareStep";
import SampleStepWrapper from "../SampleStepWrapper";

export const ShareSampleRoute: React.FC = () => {
  const stepNumber = 5;

  return (
    <SampleStepWrapper>
      <ShareStep stepNumber={stepNumber} />
    </SampleStepWrapper>
  );
};

export default ShareSampleRoute;
