import { Event, Hotel, Transportation, Trip } from "@lato/common";
import _, { cloneDeep } from "lodash";
import { create } from "zustand";
import FieldArrayResource from "../../components/CRUD/FieldArrayResource";

//The interface for the store, add properties and methods here before adding them to the store
interface TripFormState {
  trip: any;
  //Errors from server
  errors: any;
  isDirtyForms: Map<string, boolean>;
  isErrorForms: Map<string, boolean>;
  isInvalidForms: Map<string, boolean>;
  isSubmittingForms: Map<string, boolean>;
  isLoadingTranslations: boolean;
  isFirst: boolean;
  isSample: boolean;
  collaboratorCanEdit: boolean;
  isDirty: boolean;
  selectedFirstLanguage?: number;
  selectedSecondLanguage?: number;
  //Property that is set when the trip should be send to the server to update in db
  shouldUpdate: boolean;
  //Property that will be set after shouldUpdate is set to trigger an update to the server
  triggerUpdate: boolean;
  //Property to set the button in the form to isLoading:true
  isSubmitting: boolean;
  //Property to indicate if a step is in Error, this will disable the save button and other steps
  stepError?: number;
  //Proprty to indicate if elements are being moved by D&D
  isMovingElements: boolean;
  unCollapsedElement?: string;
  addTransportation: (transport: Transportation, tripdayIndex: number) => void;
  addEvent: (event: Event, tripdayIndex: number) => void;
  addHotel: (hotel: Hotel, tripdayIndex: number) => void;
  setTrip: (by: any) => void;
  setErrors: (by: any) => void;
  setIsFirst: (by: boolean) => void;
  setIsSample: (by: boolean) => void;
  setCollaboratorCanEdit: (by: boolean) => void;
  setIsDirty: (by: boolean) => void;
  setSelectedFirstLanguage: (by: number) => void;
  setSelectedSecondLanguage: (by?: number) => void;
  resetStore: () => void;
  setMultipleInTripStore: (fieldNames: object) => any;
  setInTripStore: (fieldName: string, value: any) => any;
  setShouldUpdate: (by: boolean) => void;
  setTriggerUpdate: (by: boolean) => void;
  setIsSubmitting: (by: boolean) => void;
  encounteredFormError: (step?: number) => void;
  setIsDirtyForms: (id: string, isDirty: boolean) => void;
  setIsErrorForms: (id: string, isError: boolean) => void;
  setIsInvalidForms: (id: string, isError: boolean) => void;
  setIsSubmittingForms: (id: string, isSubmitting: boolean) => void;
  setIsLoadingTranslations: (isLoadingTranslations: boolean) => void;
  setIsMovingElements: (by: boolean) => void;
  setUnCollapsedElement: (by: string | undefined) => void;
}

//The initialState for the store with defaultValues
const initialState = {
  trip: undefined as unknown as Trip,
  errors: [],
  isDirtyForms: new Map<string, boolean>(),
  isErrorForms: new Map<string, boolean>(),
  isInvalidForms: new Map<string, boolean>(),
  isSubmittingForms: new Map<string, boolean>(),
  isLoadingTranslations: false,
  isFirst: true,
  isSample: false,
  collaboratorCanEdit: true,
  isDirty: false,
  shouldUpdate: false,
  triggerUpdate: false,
  isSubmitting: false,
  stepError: undefined,
  isMovingElements: false,
  unCollapsedElement: undefined,
};

//The store itself with the initialState set and method declaration
export const useTripFormStore = create<TripFormState>((set, get) => ({
  ...initialState,
  //setting the entire trip property
  setIsDirtyForms: (id: string, isDirty: boolean) => {
    const map = get().isDirtyForms;
    map.set(id, isDirty);
    set({ isDirtyForms: map });
  },
  setIsErrorForms: (id: string, isError: boolean) => {
    const map = get().isErrorForms;
    map.set(id, isError);
    set({ isErrorForms: map });
  },
  setIsInvalidForms: (id: string, isInvalid: boolean) => {
    const map = get().isInvalidForms;
    map.set(id, isInvalid);
    set({ isInvalidForms: map });
  },
  setIsSubmittingForms: (id: string, isSubmitting: boolean) => {
    const map = get().isSubmittingForms;
    map.set(id, isSubmitting);
    set({ isSubmittingForms: map });
  },
  setIsLoadingTranslations(isLoadingTranslations: boolean) {
    set({ isLoadingTranslations });
  },
  setTrip: (tripValues: Trip) =>
    //using Immer to alter the store
    set(() => ({
      trip: cloneDeep(tripValues),
    })),
  setSelectedFirstLanguage: (selectedFirstLanguage: number) =>
    set(() => ({ selectedFirstLanguage: selectedFirstLanguage })),
  setSelectedSecondLanguage: (selectedSecondLanguage?: number) =>
    set(() => ({ selectedSecondLanguage: selectedSecondLanguage })),
  addTransportation: (transport: Transportation, tripdayIndex: number) => {
    let trip = get().trip as Trip;
    const tripday = trip.tripdays[tripdayIndex];
    trip = {
      ...trip,
      tripdays: [
        ...trip.tripdays
          .map((tripday, ind) => ({ ...tripday, dayNumber: tripday.dayNumber === 0 ? ind + 1 : tripday.dayNumber }))
          .filter((_, index) => index !== tripdayIndex),
        {
          ...tripday,
          transportations: [...tripday.transportations, transport],
          dayNumber: tripday.dayNumber === 0 ? tripdayIndex + 1 : tripday.dayNumber,
        },
      ].sort((a, b) => (a.dayNumber < b.dayNumber ? -1 : a.dayNumber === b.dayNumber ? 0 : 1)),
    };
    set(() => ({
      trip,
    }));
  },
  addEvent: (event: Event, tripdayIndex: number) => {
    let trip = get().trip as Trip;
    const tripday = trip.tripdays[tripdayIndex];
    trip = {
      ...trip,
      tripdays: [
        ...trip.tripdays
          .map((tripday, ind) => ({ ...tripday, dayNumber: tripday.dayNumber === 0 ? ind + 1 : tripday.dayNumber }))
          .filter((_, index) => index !== tripdayIndex),
        {
          ...tripday,
          events: [...tripday.events, event],
          dayNumber: tripday.dayNumber === 0 ? tripdayIndex + 1 : tripday.dayNumber,
        },
      ].sort((a, b) => (a.dayNumber < b.dayNumber ? -1 : a.dayNumber === b.dayNumber ? 0 : 1)),
    };

    set(() => ({
      trip: trip,
    }));
  },
  addHotel: (hotel: Hotel, tripdayIndex: number) => {
    let trip = get().trip as Trip;
    const tripday = trip.tripdays[tripdayIndex];
    trip = {
      ...trip,
      tripdays: [
        ...trip.tripdays
          .map((tripday, ind) => ({ ...tripday, dayNumber: tripday.dayNumber === 0 ? ind + 1 : tripday.dayNumber }))
          .filter((_, index) => index !== tripdayIndex),
        {
          ...tripday,
          hotels: [...tripday.hotels, hotel],
          dayNumber: tripday.dayNumber === 0 ? tripdayIndex + 1 : tripday.dayNumber,
        },
      ].sort((a, b) => (a.dayNumber < b.dayNumber ? -1 : a.dayNumber === b.dayNumber ? 0 : 1)),
    };
    set(() => ({
      trip: trip,
    }));
  },
  setErrors: (errorValues: any) => set(() => ({ errors: errorValues })),
  setIsFirst: (firstValue: boolean) => set(() => ({ isFirst: firstValue })),
  setIsSample: (sampleValue: boolean) => set(() => ({ isSample: sampleValue })),
  setCollaboratorCanEdit: (collaboratorCanEditValue: boolean) =>
    set(() => ({ collaboratorCanEdit: collaboratorCanEditValue })),
  setIsDirty: (isDirtyValue: boolean) => set(() => ({ isDirty: isDirtyValue })),
  resetStore: () => {
    set(initialState);
  },
  //Given the path to a property that needs to be set
  //fieldName format : {propName}.{propPosition}.{elementName}.{elementPosition} ( 'tripdays.0.events.2' or 'descriptions' )
  //when the fieldName is empty the trip will be set
  setInTripStore: (fieldName: string, value: any) =>
    //using Immer to alter the store
    set(() => ({
      trip: setInTripStore(fieldName, value, get().trip, get().shouldUpdate, set),
    })),
  //Same functionality as SetInTripStore but an object is passed with multiple fieldNames and values
  setMultipleInTripStore: (fieldNames: object) =>
    //using Immer to alter the store
    set(() => ({
      trip: setMultipleInTripStore(fieldNames, get().trip, get().shouldUpdate, set),
    })),

  setShouldUpdate: (shouldUpdateValue: boolean) => set(() => ({ shouldUpdate: shouldUpdateValue })),
  setTriggerUpdate: (triggerUpdateValue: boolean) => set(() => ({ triggerUpdate: triggerUpdateValue })),
  setIsSubmitting: (isSubmittingValue: boolean) => set(() => ({ isSubmitting: isSubmittingValue })),
  //Setting the form in error mode, when no parameters are passed the form will be return to 'non-error mode'
  //The step parameter is the step of the tripform [0-4]
  setIsMovingElements: (movingValue: boolean) => set(() => ({ isMovingElements: movingValue })),
  encounteredFormError: (step?: number) =>
    set((state) => ({
      isSubmitting: step ? state.isSubmitting : false,
      shouldUpdate: step ? state.shouldUpdate : false,
      stepError: step,
    })),
  setUnCollapsedElement: (elementId: string) => set(() => ({ unCollapsedElement: elementId })),
}));

export const get = <T, K extends keyof T>(obj: T, key: K) => obj[key];

//function that can be used anywhere to retrieve values from the current trip in the store
export const getFromTripStore = (fieldName: string) => {
  const { trip } = useTripFormStore() as any;
  return retrieveObject(trip, fieldName);
};

//ref line76
function setInTripStore(fieldName: string, value: any, trip: Trip, shouldUpdate: boolean, set: any) {
  const tripCopy = setObject(trip, fieldName, value, true);
  if (shouldUpdate) {
    updateTriggerUpdate(set);
  }
  return tripCopy;
}

//ref line85
function setMultipleInTripStore(fieldNames: object, trip: Trip, shouldUpdate: boolean, set: any) {
  let tripCopy = trip;
  Object.keys(fieldNames).forEach((key) => {
    if (fieldNames.hasOwnProperty(key)) {
      tripCopy = setObject(trip, key, (fieldNames as any)[key]);
    }
  });
  if (shouldUpdate) {
    updateTriggerUpdate(set);
  }
  return tripCopy;
}

//function that is called from setFunctions to trigger an update to the server
//debouncing it so the last update is passed with
const updateTriggerUpdate = _.debounce((set) => {
  set({ triggerUpdate: true });
}, 1000);

//exported function to convert any object to the desired format to call setMultipleInstore afterwards
//Mostly used in forms to only alter dirtyFields
export const convertObjectToFormat = (
  obj: Record<string, any>,
  formValues: object,
  parentKey = "",
  returnIfContain?: string,
): Record<string, any> => {
  const result: Record<string, string> = {};
  //looping over the passed keys
  for (const key in obj) {
    if (obj && obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Construct the new key in the desired format
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      //when the value is an object we have to go deeper
      if (typeof value === "object" && value !== null) {
        //A check on returnIfContain that will alter objects when it has a property with the given name
        //Used to alter translationFields ( returnIfContain will be 'creator' )
        if (returnIfContain && returnIfContain in value) {
          result[newKey] = retrieveObject(formValues, newKey);
        }
        // If the value is an object, recursively process it
        const subResult = convertObjectToFormat(value, formValues, newKey, returnIfContain);
        // Merge the sub-result into the result
        Object.assign(result, subResult);
      } else {
        if (!returnIfContain) {
          // If the value is not an object, store it with the new key
          result[newKey] = retrieveObject(formValues, newKey);
        }
      }
    }
  }

  return result;
};

//Function that alters objects based on the given fieldName
const setObject = (trip: Trip, fieldName: string, newValue: any, ignoreEmptyCheck = false): Trip => {
  if (!fieldName) return trip;
  //splitting the fieldName
  const fields = fieldName.split(".");

  let currentField = trip;

  //looping over the splitted fieldName
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (field === undefined) continue;
    if (
      (!ignoreEmptyCheck && !currentField) ||
      (!ignoreEmptyCheck && !Array.isArray(currentField) && !currentField.hasOwnProperty(field))
    ) {
      // Key not found, return the original object unchanged
      return trip;
    }
    //Go deeper until we reach the last element of the splitted fieldName
    if (i === fields.length - 1) {
      currentField &&
        (isNaN(+field) ? ((currentField as any)[field] = newValue) : ((currentField as any)[+field] = newValue));
    } else {
      currentField = isNaN(+field) ? get(currentField, field as any) : (currentField as any)[+field];
    }
  }
  return trip;
};

//function to retrieve a value from an object based on the given fieldName
const retrieveObject = (trip: object, fieldName: string): any => {
  if (!fieldName || !trip) return trip;
  const fields = fieldName.split(".");

  for (const field of fields) {
    if (!trip) return trip;
    if (!field) continue;
    trip = isNaN(+field) ? get(trip, field as any) : (trip as any)[+field];
  }
  return trip;
};
