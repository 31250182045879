export const IMPERSONALIZATION_COOKIE_KEY = "ick";
export const FREE_AI_TRIP_CONVERSIONS_LIMIT = 5;
export const AI_TRIP_CONVERSIONS_PRICE_PER_UNIT = 0.85;
export const ENABLE_DESTINATIONS_IN_PROD = false;
export const ENABLE_DESTINATIONS_IN_OVERVIEW = false;
export const UNSPLASH_PHOTO_BASE_URL = "https://images.unsplash.com/";
export const UNSPLASH_BASE_URL = "https://unsplash.com/";
export const PEXELS_PHOTO_BASE_URL = "https://images.pexels.com/photos/";
export const PEXELS_VIDEO_BASE_URL = "https://video.pexels.com/";
export const PIXABAY_PHOTO_BASE_URL = "https://pixabay.com/get/";
export const PIXABAY_VIDEO_BASE_URL = "https://cdn.pixabay.com/vimeo/";
export const PIXABAY_BASE_URL = "https://pixabay.com";
export const NAME_MARKETTING = "Isabeau";
export const DEFAULT_MAX_KM_DISTANCE = 50;
/*** LATO COLORS ***/
export const PRIMARY_COLOR = "#43a8a0";
export const PRIMARY_COLOR_LIGHT = "#e5fbf9";
export const PRIMARY_COLOR_DARK = "#296d68";
export const SECONDARY_COLOR = "#ff817d";
export const TERNARY_COLOR = "#004354";
export const DARK_BLUE_COLOR = "#243543";
/*** LATO domains ***/
export const LATO_DOMAIN = "latotravelapp.com";
/*** LATO SALES WEBSITE LINKS ***/
export const LATO_SALES_URL = "https://www." + LATO_DOMAIN;
export const PRICING_URL = LATO_SALES_URL + "/pricing";
const RESOURCES_URL = LATO_SALES_URL + "/resources";
export const WHY_LATO_URL = RESOURCES_URL + "/why-lato";
export const WEBINAR_URL = RESOURCES_URL + "/webinars";
export const VIDEOS_URL = RESOURCES_URL + "/videos";
export const DEMO_BOOKING_LINK = "https://book." + LATO_DOMAIN;
export const HELP_CENTER_LINK = "https://help." + LATO_DOMAIN + "/en";
/*** LATO PORTAL LINKS ***/
// const PORTAL_URL = "https://portal." + LATO_DOMAIN; // Should probably never be used, because this depends on the environment
export const TASKS_PATH = "/tasks";
export const CHATS_SUFFIX = "/chats";
/*** LATO MAIL ADDRESSES ***/
export const HELLO_MAIL = "hello@" + LATO_DOMAIN;
export const WOUT_MAIL = "wout@" + LATO_DOMAIN;
export const KRIS_MAIL = "kris@" + LATO_DOMAIN;
export const SUPPORT_MAIL = "support@" + LATO_DOMAIN;
export const ADMIN_MAIL = "admin@" + LATO_DOMAIN;
export const NOREPLY_EMAIL = "noreply@" + LATO_DOMAIN;
/*** LATO SOCIAL LINKS ***/
export const LATO_FACEBOOK_URL = "https://www.facebook.com";
export const LATO_INSTAGRAM_URL = "https://www.instagram.com/latotravelapp";
export const LATO_LINKEDIN_URL = "https://www.linkedin.com/company/lato-travel-app/";
/*** CANNY URLS ***/
export const CANNY_URL = "https://latotravelapp.canny.io/";
export const CANNY_CHANGELOG_URL = CANNY_URL + "changelog";
export const CANNY_FEEDBACK_URL = CANNY_URL + "feature-requests";
/*** STRAPI URLS ***/
export const STRAPI_API_HOST = "https://cms." + LATO_DOMAIN + "/api";
