import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { DeepMap, FieldError, useFormContext } from "react-hook-form";

type SubmitButtonProps = ButtonProps & {
  // isDisabled?: boolean;
  // errors?: DeepMap<any, FieldError>;
  // error?: FieldError;
};

const SubmitButton: React.FC<SubmitButtonProps> = ({ children, colorScheme = "brand", ...props }) => {
  const {
    getValues,

    formState: { isValid, isSubmitting, isDirty, errors },
  } = useFormContext();
  return (
    // The check if the errors object is not empty is used for the server side validation
    <Button
      type="submit"
      colorScheme={colorScheme}
      isLoading={isSubmitting}
      isDisabled={(Object.keys(errors).length > 0 || !isValid) && isDirty}
      {...props}
    >
      {children}
    </Button>
  );
};
export default SubmitButton;
