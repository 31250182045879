export var EDocument;
(function (EDocument) {
    EDocument["OTHERDOC"] = "otherdoc";
    EDocument["TRAVELINFODOC"] = "travelinfodoc";
    EDocument["TRAVELINFOLINK"] = "travelinfolink";
    EDocument["VOUCHERDOC"] = "voucherdoc";
    EDocument["VOUCHERLINK"] = "voucherlink";
    EDocument["OTHERLINK"] = "otherlink";
    EDocument["PODCAST"] = "podcast";
    EDocument["VIDEO"] = "video";
    EDocument["EBOOK"] = "ebook";
    EDocument["MUSIC"] = "music";
    EDocument["TRAVELREQDOC"] = "travelreqdoc";
    EDocument["TRAVELREQLINK"] = "travelreqlink";
    EDocument["ESIM"] = "esim";
})(EDocument || (EDocument = {}));
export var APICALL;
(function (APICALL) {
    APICALL["TRPC"] = "trpc";
    APICALL["REST"] = "rest";
})(APICALL || (APICALL = {}));
export var GENDER;
(function (GENDER) {
    GENDER["M"] = "Male (M)";
    GENDER["F"] = "Female (F)";
    GENDER["X"] = "Unspecified (X)";
})(GENDER || (GENDER = {}));
export var CONTACTTYPE;
(function (CONTACTTYPE) {
    CONTACTTYPE["PERSON"] = "Person";
    CONTACTTYPE["COMPANY"] = "Company";
})(CONTACTTYPE || (CONTACTTYPE = {}));
export var QueryType;
(function (QueryType) {
    QueryType["ACCOMMODATION"] = "accommodation";
    QueryType["ACTIVITY"] = "activity";
    QueryType["TRIP"] = "trip";
    QueryType["SAMPLE"] = "sample";
    QueryType["DEFAULTDOCUMENT"] = "defaultDocument";
    QueryType["CONTACT"] = "contact";
})(QueryType || (QueryType = {}));
export var MAP_TYPE;
(function (MAP_TYPE) {
    MAP_TYPE["GOOGLE"] = "Google";
    MAP_TYPE["MAPBOX"] = "MapBox";
    MAP_TYPE["OPENSTREETMAP"] = "OpenStreetmap";
    MAP_TYPE["TOMTOM"] = "Tomtom";
})(MAP_TYPE || (MAP_TYPE = {}));
export var TRANSPORTATION_TYPE;
(function (TRANSPORTATION_TYPE) {
    TRANSPORTATION_TYPE["FLIGHT"] = "flight";
    TRANSPORTATION_TYPE["TRAIN"] = "train";
    TRANSPORTATION_TYPE["BOAT"] = "boat";
    TRANSPORTATION_TYPE["BUS"] = "bus";
    TRANSPORTATION_TYPE["CAR"] = "car";
    TRANSPORTATION_TYPE["BIKE"] = "bike";
    TRANSPORTATION_TYPE["FOOT"] = "foot";
    TRANSPORTATION_TYPE["RENTAL"] = "rental";
    TRANSPORTATION_TYPE["CUSTOM"] = "custom";
})(TRANSPORTATION_TYPE || (TRANSPORTATION_TYPE = {}));
export const transportIconSvg = {
    [TRANSPORTATION_TYPE.BIKE]: "https://i.ibb.co/7b4dryJ/bike.png",
    [TRANSPORTATION_TYPE.CAR]: "https://i.ibb.co/hCvLbGq/car.png",
    [TRANSPORTATION_TYPE.FLIGHT]: "https://i.ibb.co/1XcHZ3w/airplane.png",
    [TRANSPORTATION_TYPE.BOAT]: "https://i.ibb.co/Mfv4bXx/boat.png",
    [TRANSPORTATION_TYPE.BUS]: "https://i.ibb.co/jbqTTkB/bus.png",
    [TRANSPORTATION_TYPE.FOOT]: "https://i.ibb.co/F8wKk3t/walk.png",
    [TRANSPORTATION_TYPE.TRAIN]: "https://i.ibb.co/JRDG5xr/train.png",
    [TRANSPORTATION_TYPE.CUSTOM]: "https://i.ibb.co/hCvLbGq/car.png",
};
export var HOTEL_ORIGIN;
(function (HOTEL_ORIGIN) {
    HOTEL_ORIGIN["LATO"] = "Lato";
    HOTEL_ORIGIN["HOTELBEDS"] = "HotelBeds";
    HOTEL_ORIGIN["RATEHAWK"] = "Ratehawk";
    HOTEL_ORIGIN["YALAGO"] = "Yalago";
    HOTEL_ORIGIN["MAPPED"] = "Mapped";
})(HOTEL_ORIGIN || (HOTEL_ORIGIN = {}));
export var HOTEL_BOOKING_STATUS;
(function (HOTEL_BOOKING_STATUS) {
    HOTEL_BOOKING_STATUS["BOOKED"] = "Booked";
    HOTEL_BOOKING_STATUS["QUOTE"] = "Quote";
    HOTEL_BOOKING_STATUS["ONREQUEST"] = "On Request";
    HOTEL_BOOKING_STATUS["CANCELLED"] = "Cancelled";
})(HOTEL_BOOKING_STATUS || (HOTEL_BOOKING_STATUS = {}));
export var BOARD_TYPES;
(function (BOARD_TYPES) {
    BOARD_TYPES["FULL"] = "Full Board (Breakfast, Lunch, and Dinner)";
    BOARD_TYPES["HALF"] = "Half Board (Breakfast and Dinner)";
    BOARD_TYPES["ROOM"] = "Room only";
    BOARD_TYPES["BNB"] = "Bed and Breakfast (B&B)";
    BOARD_TYPES["ALL"] = "All-Inclusive";
})(BOARD_TYPES || (BOARD_TYPES = {}));
export var DUE_DAYS_TYPE;
(function (DUE_DAYS_TYPE) {
    DUE_DAYS_TYPE["BEFORE_START"] = "Before start";
    DUE_DAYS_TYPE["AFTER_START"] = "After start";
    DUE_DAYS_TYPE["BEFORE_END"] = "Before end";
    DUE_DAYS_TYPE["AFTER_END"] = "After end";
})(DUE_DAYS_TYPE || (DUE_DAYS_TYPE = {}));
export const isLink = (type) => {
    if (type === EDocument.OTHERLINK ||
        type === EDocument.EBOOK ||
        type === EDocument.MUSIC ||
        type === EDocument.VIDEO ||
        type === EDocument.PODCAST ||
        type === EDocument.TRAVELREQLINK ||
        type === EDocument.TRAVELINFOLINK ||
        type === EDocument.ESIM ||
        type === EDocument.VOUCHERLINK) {
        return true;
    }
    return false;
};
export var POIType;
(function (POIType) {
    POIType["ACTIVITY"] = "Activity";
    POIType["AIRPORT"] = "Airport";
    POIType["BAR"] = "Bar";
    POIType["BEACH"] = "Beach";
    POIType["BRIDGE"] = "Bridge";
    POIType["BUILDING"] = "Building";
    POIType["CAMPGROUND"] = "Campground";
    POIType["CAR_RENTAL"] = "Car rental";
    POIType["CITY"] = "City";
    POIType["COFFEE_HOUSE"] = "Coffeehouse";
    POIType["CULTURAL_SITE"] = "Cultural site";
    POIType["CYCLING"] = "Cycling";
    POIType["ENTERTAINMENT"] = "Entertainment";
    POIType["FERRY"] = "Ferry";
    POIType["FLAG"] = "Flag";
    POIType["GOLF_RESORT"] = "Golf resort";
    POIType["HOTEL"] = "Hotel";
    POIType["ISLAND"] = "Island";
    POIType["MARKET"] = "Market";
    POIType["MUSEUM"] = "Museum";
    POIType["NATURE"] = "Nature";
    POIType["NEIGHBORHOOD"] = "Neighborhood";
    POIType["OTHER"] = "Other";
    POIType["PARK"] = "Park";
    POIType["PHARMACY"] = "Pharmacy";
    POIType["RELIGION"] = "Religion";
    POIType["RESTAURANT"] = "Restaurant";
    POIType["SCENIC_ROUTE"] = "Scenic route";
    POIType["SHOP"] = "Shop";
    POIType["SKI_AREA"] = "Ski area";
    POIType["SPA"] = "Spa";
    POIType["STREET"] = "Street";
    POIType["SQUARE"] = "Square";
    POIType["TOURIST_ATTRACTION"] = "Tourist attraction";
    POIType["TRAIL"] = "Trail";
    POIType["TRAIN_STATION"] = "Train station";
    POIType["VIEWPOINT"] = "Viewpoint";
    POIType["WATERFALL"] = "Waterfall";
    POIType["ZOO"] = "Zoo";
})(POIType || (POIType = {}));
export var SHOW_OPTIONS;
(function (SHOW_OPTIONS) {
    SHOW_OPTIONS["ALWAYS"] = "Always";
    SHOW_OPTIONS["IFQUOTE"] = "IfQuote";
    SHOW_OPTIONS["IFBOOKED"] = "IfBooked";
    SHOW_OPTIONS["NEVER"] = "Never";
})(SHOW_OPTIONS || (SHOW_OPTIONS = {}));
export var CalendarItemType;
(function (CalendarItemType) {
    CalendarItemType["TaskDone"] = "taskDone";
    CalendarItemType["TaskNotDone"] = "taskNotDone";
    CalendarItemType["UserTrip"] = "userTrip";
})(CalendarItemType || (CalendarItemType = {}));
export var PAYMENT_INVITATIONTYPE;
(function (PAYMENT_INVITATIONTYPE) {
    PAYMENT_INVITATIONTYPE["PERCENTAGE"] = "Percentage";
    PAYMENT_INVITATIONTYPE["FIXED"] = "Fixed";
})(PAYMENT_INVITATIONTYPE || (PAYMENT_INVITATIONTYPE = {}));
export var PAYMENT_INVITATIONSTATUS;
(function (PAYMENT_INVITATIONSTATUS) {
    PAYMENT_INVITATIONSTATUS["INACTIVE"] = "Inactive";
    PAYMENT_INVITATIONSTATUS["WAITING"] = "Waiting";
    PAYMENT_INVITATIONSTATUS["SENT"] = "Sent";
    PAYMENT_INVITATIONSTATUS["FAILED"] = "Failed";
    PAYMENT_INVITATIONSTATUS["PAID"] = "Paid";
})(PAYMENT_INVITATIONSTATUS || (PAYMENT_INVITATIONSTATUS = {}));
export var TASK_FUNCTION_NAME;
(function (TASK_FUNCTION_NAME) {
    TASK_FUNCTION_NAME["PAYMENT_INVITATION_TASK"] = "Payment invitation task";
    TASK_FUNCTION_NAME["USERTRIP_SET_STATUS"] = "Usertrip set status";
})(TASK_FUNCTION_NAME || (TASK_FUNCTION_NAME = {}));
export var JOB_STATUS;
(function (JOB_STATUS) {
    JOB_STATUS["WAITING"] = "Waiting";
    JOB_STATUS["SUCCESFULL"] = "Succesfull";
    JOB_STATUS["FAILED"] = "Failed";
})(JOB_STATUS || (JOB_STATUS = {}));
export var ImageSite;
(function (ImageSite) {
    ImageSite["S3"] = "s3";
    ImageSite["UNSPLASH"] = "unsplash";
    ImageSite["PEXELS"] = "pexels";
    ImageSite["PEXELSVIDEO"] = "pexelsvideo";
    ImageSite["PIXABAY"] = "pixabay";
    ImageSite["PIXABAYVIDEO"] = "pixabayvideo";
    ImageSite["CUSTOMPICTURE"] = "custompicture";
    ImageSite["CUSTOMVIDEO"] = "customvideo";
})(ImageSite || (ImageSite = {}));
export var Role;
(function (Role) {
    Role["ADMIN"] = "admin";
    Role["USER"] = "user";
})(Role || (Role = {}));
export var TRIP_STATUS;
(function (TRIP_STATUS) {
    TRIP_STATUS["QUOTE"] = "Quote";
    TRIP_STATUS["APPROVED"] = "Approved";
    TRIP_STATUS["BOOKED"] = "Booked";
    TRIP_STATUS["CANCELED"] = "Canceled";
})(TRIP_STATUS || (TRIP_STATUS = {}));
export var TRIP_TRAVELING_STATUS;
(function (TRIP_TRAVELING_STATUS) {
    TRIP_TRAVELING_STATUS["TRAVELING"] = "Traveling";
    TRIP_TRAVELING_STATUS["UPCOMING"] = "Upcoming";
    TRIP_TRAVELING_STATUS["PAST"] = "Past";
})(TRIP_TRAVELING_STATUS || (TRIP_TRAVELING_STATUS = {}));
export const SHOW_OPTIONS_TextMap = {
    [SHOW_OPTIONS.ALWAYS]: "Always",
    [SHOW_OPTIONS.NEVER]: "Never",
    [SHOW_OPTIONS.IFBOOKED]: "If booked",
    [SHOW_OPTIONS.IFQUOTE]: "If quote",
};
export var TravelCompanyType;
(function (TravelCompanyType) {
    TravelCompanyType["LA"] = "Local Agent (LA), Destination Management Company (DMC)";
    TravelCompanyType["TO"] = "Tour Operator (TO), Travel Organizer (TO), Travel Designer";
    TravelCompanyType["TA"] = "Travel Agent, Travel Reseller";
    TravelCompanyType["OTHER"] = "Other";
})(TravelCompanyType || (TravelCompanyType = {}));
export var AI_STYLE_PREFERENCES;
(function (AI_STYLE_PREFERENCES) {
    AI_STYLE_PREFERENCES["PERSUASIVE"] = "Persuasive";
    AI_STYLE_PREFERENCES["STORYTELLING"] = "Story telling";
    AI_STYLE_PREFERENCES["SIMPLE"] = "Simple";
    AI_STYLE_PREFERENCES["CREATEIVE"] = "Creative";
    AI_STYLE_PREFERENCES["DESCRIPTIVE"] = "Descriptive";
    AI_STYLE_PREFERENCES["ATTENTIONGRAB"] = "Attention grabbing";
    AI_STYLE_PREFERENCES["PROFESSIONAL"] = "Professional";
    AI_STYLE_PREFERENCES["INFORMATIVE"] = "Informative";
    AI_STYLE_PREFERENCES["CONVERSATIONAL"] = "Conversational";
    AI_STYLE_PREFERENCES["RELATABLE"] = "Relatable";
    AI_STYLE_PREFERENCES["SHAKESPEARE"] = "ShakeSpeare";
})(AI_STYLE_PREFERENCES || (AI_STYLE_PREFERENCES = {}));
export var Plan;
(function (Plan) {
    Plan["FREE"] = "free";
    Plan["LITE"] = "essential";
    Plan["PRO"] = "pro";
})(Plan || (Plan = {}));
export var LOYALTY_PROGRAM_TYPE;
(function (LOYALTY_PROGRAM_TYPE) {
    LOYALTY_PROGRAM_TYPE["AIRLINES"] = "airlines";
    LOYALTY_PROGRAM_TYPE["HOTELS"] = "hotels";
    LOYALTY_PROGRAM_TYPE["RAILWAYS"] = "railways";
    LOYALTY_PROGRAM_TYPE["RETAIL"] = "retail";
    LOYALTY_PROGRAM_TYPE["TRANSPORTATION"] = "transportation";
    LOYALTY_PROGRAM_TYPE["FERRIES"] = "ferries";
    LOYALTY_PROGRAM_TYPE["CRUISES"] = "cruises";
    LOYALTY_PROGRAM_TYPE["CREDIT_CARDS"] = "credit cards";
    LOYALTY_PROGRAM_TYPE["CAR_RENTAL"] = "car rental";
    LOYALTY_PROGRAM_TYPE["BUSES"] = "buses";
    LOYALTY_PROGRAM_TYPE["BOOKINGS"] = "bookings";
    LOYALTY_PROGRAM_TYPE["AIRPORTS"] = "airports";
})(LOYALTY_PROGRAM_TYPE || (LOYALTY_PROGRAM_TYPE = {}));
export const monthByNum = {
    [1]: "Jan",
    [2]: "Feb",
    [3]: "Mar",
    [4]: "Apr",
    [5]: "May",
    [6]: "Jun",
    [7]: "Jul",
    [8]: "Aug",
    [9]: "Sep",
    [10]: "Oct",
    [11]: "Nov",
    [12]: "Dec",
};
export var TimeInterval;
(function (TimeInterval) {
    TimeInterval["DAILY"] = "daily";
    TimeInterval["WEEKLY"] = "weekly";
    TimeInterval["MONTHLY"] = "monthly";
    TimeInterval["QUARTERLY"] = "quarterly";
    TimeInterval["YEARLY"] = "yearly";
})(TimeInterval || (TimeInterval = {}));
export var StatCategory;
(function (StatCategory) {
    StatCategory["REVENUE"] = "revenue";
    StatCategory["TRIPS"] = "trips";
    StatCategory["TRAVELLERS"] = "travellers";
})(StatCategory || (StatCategory = {}));
export const HOTEL = "Hotel";
export const TRIPDAY = "Tripday";
export const EVENT = "Event";
export var PRIORITY;
(function (PRIORITY) {
    PRIORITY["LOW"] = "Low";
    PRIORITY["NORMAL"] = "Normal";
    PRIORITY["HIGH"] = "High";
    PRIORITY["URGENT"] = "Urgent";
})(PRIORITY || (PRIORITY = {}));
export var TASK_STATUS;
(function (TASK_STATUS) {
    TASK_STATUS["DONE"] = "Done";
    TASK_STATUS["TO_DO"] = "To do";
})(TASK_STATUS || (TASK_STATUS = {}));
