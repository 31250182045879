export function timeDifferenceInSeconds(startTime, endTime, arrivalDayIndex = 0) {
    if (!startTime || !endTime)
        return 0;
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);
    const minutesInHour = 60;
    let totalMinutes = (endHour - (arrivalDayIndex === 0 ? startHour : startHour % minutesInHour)) * minutesInHour +
        endMinute -
        startMinute;
    if (arrivalDayIndex && arrivalDayIndex !== 0)
        totalMinutes += arrivalDayIndex * minutesInHour * 24;
    return totalMinutes * 60;
}
