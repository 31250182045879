import { Switch, SwitchProps } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";

interface RHFSwitchProps extends SwitchProps {
  name: string;
  onChange?: (e: any) => void;
  defaultValue?: any;
}

const RHFSwitch: React.FC<RHFSwitchProps> = ({ name, defaultValue, onChange, ...props }) => {
  // const { field } = useController({
  //   name,
  //   // On first render, the default value of the column should be used,
  //   // subsequent renders should use the more up to date getValues defaultValue.
  //   // defaultValue: defaultV || defaultValue,
  // });

  const { register } = useFormContext();
  const field = register(name);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    } else {
      await field.onChange(event);
    }
  };

  return <Switch {...props} {...register(name)} onChange={handleChange} defaultChecked={defaultValue} />;
};
export default React.memo(RHFSwitch);
