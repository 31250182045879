import { ImageSite } from "../types/index.js";
const sizeToWidth = {
    thumb: 200,
    small: 400,
    regular: 800,
};
var ImageProcessingSite;
(function (ImageProcessingSite) {
    ImageProcessingSite["IMGIX"] = "imgix";
    ImageProcessingSite["GUMLET"] = "gumlet";
    // IMAGEKIT = "imagekit",
    ImageProcessingSite["WSRV"] = "wsrv";
    ImageProcessingSite["NONE"] = "none";
})(ImageProcessingSite || (ImageProcessingSite = {}));
// Object to get the image processing site from the image site.
const imageSiteToImageProcessingSite = {
    [ImageSite.S3]: ImageProcessingSite.GUMLET,
    [ImageSite.UNSPLASH]: ImageProcessingSite.IMGIX,
    [ImageSite.PEXELS]: ImageProcessingSite.IMGIX,
    [ImageSite.PIXABAY]: ImageProcessingSite.WSRV,
    [ImageSite.CUSTOMPICTURE]: ImageProcessingSite.WSRV,
    [ImageSite.PEXELSVIDEO]: ImageProcessingSite.NONE,
    [ImageSite.PIXABAYVIDEO]: ImageProcessingSite.NONE,
    [ImageSite.CUSTOMVIDEO]: ImageProcessingSite.NONE,
};
const gumletParamsMapping = ({ smartCropping, w, h, q }) => {
    // Enables progressive loading of the image (first a bad quality version loads and then a higher quality once it loaded)
    // But isn't it better to just use fm=auto because then .webp images will be used which will come in faster.
    let params = ["compress=true", "fm=jpg"];
    // let params: string[] = [];
    params = smartCropping ? params.concat("crop=entropy", "fit=crop") : params; // entropy vs smart?
    params = params.concat([`q=${q}`]);
    params = params.concat(w !== undefined && h !== undefined
        ? [`w=${w}&h=${h}`]
        : w !== undefined
            ? [`w=${w}`]
            : h !== undefined
                ? [`h=${h}`]
                : []);
    return params;
};
// const imageKitParamsMapping = ({
//   smartCropping,
//   w,
//   h,
//   q,
// }: TImageRealParams): string[] => {
//   const transformation_params =
//     `tr=q-${q},w-${w}` +
//     (h !== undefined ? `,h-${h}` : "") +
//     (smartCropping ? `,fo-auto` : "");
//   return [transformation_params];
// };
const imgixParamsMapping = ({ smartCropping, w, h, q }) => {
    // Pjpg -> progressive jpg
    let params = ["cs=tinysrgb", "fm=pjpg"];
    params = smartCropping ? params.concat("crop=edges", "fit=crop") : params;
    params = params.concat([`q=${q}`]);
    params = params.concat(w !== undefined && h !== undefined
        ? [`w=${w}&h=${h}`]
        : w !== undefined
            ? [`w=${w}`]
            : h !== undefined
                ? [`h=${h}`]
                : []);
    return params;
};
const wsrvParamsMapping = ({ smartCropping, w, h, q }) => {
    let params = [];
    params = smartCropping ? params.concat("fit=cover", "a=entropy") : params;
    params = params.concat([`q=${q}`]);
    params = params.concat(w !== undefined && h !== undefined
        ? [`w=${w}&h=${h}`]
        : w !== undefined
            ? [`w=${w}`]
            : h !== undefined
                ? [`h=${h}`]
                : []);
    return params;
};
const imageProcessingSiteConfigMap = {
    // [ImageProcessingSite.IMAGEKIT]: {
    //   generateQueryParams: imageKitParamsMapping,
    // },
    [ImageProcessingSite.GUMLET]: {
        generateQueryParams: gumletParamsMapping,
    },
    [ImageProcessingSite.IMGIX]: {
        generateQueryParams: imgixParamsMapping,
    },
    [ImageProcessingSite.WSRV]: {
        generateQueryParams: wsrvParamsMapping,
    },
    [ImageProcessingSite.NONE]: {
        generateQueryParams: () => [],
    },
};
function getParamsString(imageProcessingSiteConfig, params) {
    // If a size param is provided convert it to a width property.
    if ("size" in params) {
        const { size, ...paramsWithoutSize } = params;
        const w = sizeToWidth[size];
        params = { ...paramsWithoutSize, w };
    }
    const { w, h, smartCropping = true, q = 80 } = params;
    // Gather the params as a list
    const paramslist = imageProcessingSiteConfig.generateQueryParams({
        w: w ?? 0,
        h,
        smartCropping,
        q,
    });
    // Convert the params object to a params string.
    return paramslist.join("&");
}
export function transformImage(image, params) {
    if (!image)
        return "";
    const url = image.url;
    if (!IsImage(image.site))
        return url;
    if (image.url && image.url.includes("blob"))
        return url;
    // Convert the params to a paramsString using the correct params config.
    const paramsString = getParamsString(imageProcessingSiteConfigMap[imageSiteToImageProcessingSite[image.site]], params);
    switch (image.site) {
        case ImageSite.UNSPLASH: // Unsplash already has query params
        case ImageSite.CUSTOMPICTURE: // Wsrv uses '&' instead of '?'
        case ImageSite.PIXABAY: // Wsrv uses '&' instead of '?'
            return url + `&${paramsString}`;
        default:
            return url + `?${paramsString}`;
    }
}
export const IsImage = (imageSite) => {
    if (imageSite === ImageSite.PIXABAYVIDEO ||
        imageSite === ImageSite.PEXELSVIDEO ||
        imageSite === ImageSite.CUSTOMVIDEO) {
        return false;
    }
    return true;
};
// TODO: search for transformImage(
