import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { Box, Code, Grid, Link, VStack, Text, Icon, Button } from "@chakra-ui/react";
import NotFoundIllustration from "../assets/icons/404.illustration";
import SplitScreenLayout from "../components/layout/SplitScreenLayout";
import { CardWrapper } from "../components/CardWrapper";

// Or Redirect instead of Link: <Redirect to={"/"} />
const NotFound: React.FC = () => {
  return (
    <SplitScreenLayout>
      <CardWrapper
        title="Looks like you are lost"
        description="The page you are looking for doesn't exist."
        topIllustration={NotFoundIllustration}
      >
        <Button w="100%" size="md" mt={4} as={ReactLink} to={"/"} colorScheme="brand" fontSize="2xl">
          Go back home
        </Button>
      </CardWrapper>
    </SplitScreenLayout>
  );
};
export default NotFound;
