import { EDocument, POIType, BOARD_TYPES } from "../types/index.js";
export var LangCodes;
(function (LangCodes) {
    LangCodes["nl"] = "nl";
    LangCodes["en_gb"] = "en-gb";
    LangCodes["en_us"] = "en-us";
    LangCodes["fr"] = "fr";
    LangCodes["it"] = "it";
    LangCodes["es"] = "es";
    LangCodes["pt"] = "pt";
    LangCodes["de"] = "de";
    LangCodes["zh"] = "zh";
    LangCodes["tr"] = "tr";
    LangCodes["ru"] = "ru";
    LangCodes["id"] = "id";
    LangCodes["ja"] = "ja";
    LangCodes["ko"] = "ko";
    LangCodes["ar"] = "ar";
    LangCodes["sl"] = "sl";
})(LangCodes || (LangCodes = {}));
export const i18n_translations = {
    nl: {
        generalErrorMessage: "Er ging iets mis",
        navbar: {
            home: "Home",
            map: "Kaart",
            details: "Details",
        },
        home: {
            exploreTheTrip: "Ontdek de reis",
        },
        mail: {
            preview: "Bekijk je persoonlijke reisvoorstel van {0} van {1}",
            contact: "Neem contact op met uw reisagent",
            preview_agent_confirm: "{0} heeft zojuist hun reis bevestigd met {1}",
            no_images: "Geen afbeeldingen",
            click_here: "Klik hier",
            confirmation_thanks: "Bedankt voor het bevestigen van deze reis, uw reisprofessional zal de boeking verder afhandelen.",
            booking_request: "Boekingsaanvraag",
            booking_confirmation: "Boekingsbevestiging",
            by: "door",
            with: "met",
            edit_trip: "of bewerk reis",
            booking_detail: "Boekingsdetails",
            client_name: "Naam klant",
            client_details: "Contactgegevens",
            client_feedback: "Klantfeedback",
            trip: "Reis",
            number_travelers: "Aantal reizigers",
            ref: "Ref",
            total_price: "Totale reissom",
        },
        details: {
            book: {
                title: "Bevestig reis",
                book: "Bevestig",
                description: "Gelieve onderstaande gegevens in te vullen om uw reisprofessional te laten weten dat je geïnteresseerd bent om de reis te boeken.",
                description_book_link: "Helemaal tevreden? Klik op onderstaande knop om deze reis te boeken. Alvast bedankt voor het vertrouwen!",
                email: "E-mailadres",
                comment: "Opmerking",
                bookedMessage: "De boeking is voltooid, geniet van je reis.",
                errorMessage: "Gelieve een geldig e-mailadres in te geven.",
                retry: "Probeer opnieuw",
                terms: "algemene voorwaarden",
                termsAgree: "Ik ga akkoord met de {0}",
                termsAgreed: "Bij het bevestigen van de reis ben je akkoord gegaan met de {0}.",
                availableSpots: "Beschikbare plaatsen",
            },
            downloadPDF: "Download PDF",
            playAudio: "Audio afspelen",
            general: {
                others: "Andere",
                title: "Algemeen",
                day: "dag",
                days: "dagen",
                hours: "uur",
                mins: "min",
                secs: "sec",
                night: "nacht",
                nights: "nachten",
                traveller: "reiziger",
                travellers: "reizigers",
                period: "periode",
                duration: "duur",
                group: "groep",
                departure: "vertrek",
                arrival: "aankomst",
                pickup: "ophaling",
                dropoff: "inlevering",
                option: "optie",
                travelAgent: "jouw reisagent",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "Stad van aankomst",
                departureCity: "Stad van vertrek",
                arrivalCountry: "Land van aankomst",
                departureCountry: "Land van vertrek",
                flight: "Vlucht",
                flightDuration: "Duur van de vlucht",
            },
            included: "Inbegrepen",
            notIncluded: "Niet inbegrepen",
            price: "Prijs",
            documents: "Documenten",
            downloadAll: "Download alles",
            documentTypes: {
                [EDocument.OTHERDOC]: "andere",
                [EDocument.TRAVELINFODOC]: "reisinformatie",
                [EDocument.TRAVELINFOLINK]: "reisinformatie",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "andere",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "muziek",
                [EDocument.VIDEO]: "video",
                [EDocument.TRAVELREQDOC]: "reisvereisten",
                [EDocument.TRAVELREQLINK]: "reisvereisten",
                [EDocument.ESIM]: "elektronische simkaart",
            },
            flights: "Vluchten",
            flightsAndTransports: "Vluchten & Transporten",
            transports: "Transporten",
            accommodations: "Accommodaties",
            chat: "Chat",
            more: "Meer",
            viewAllTrips: "Alle reizen",
            overview: "Reisoverzicht",
            stay: "verblijf",
        },
        map: {
            viewMap: "Bekijk op de kaart",
            viewOverview: "Bekijk het reisoverzicht",
            hidePOIs: "Verberg POIs",
            show_pois: "Toon POIs",
            selectAll: "alles selecteren",
            deselectAll: "alles deselecteren",
        },
        chat: {
            typeYourMessage: "Typ uw bericht",
        },
        root: {
            myTrips: "Mijn reizen",
            myTripsDescription: "een overzicht van al jouw reizen geopend in Lato",
        },
        poi_types: {
            [POIType.ACTIVITY]: "activiteit",
            [POIType.AIRPORT]: "luchthaven",
            [POIType.BAR]: "bar",
            [POIType.BRIDGE]: "brug",
            [POIType.BEACH]: "strand",
            [POIType.BUILDING]: "gebouw",
            [POIType.CAMPGROUND]: "camping",
            [POIType.CAR_RENTAL]: "autoverhuur",
            [POIType.CITY]: "stad",
            [POIType.COFFEE_HOUSE]: "koffiehuis",
            [POIType.CULTURAL_SITE]: "culturele locatie",
            [POIType.CYCLING]: "fietsen",
            [POIType.ENTERTAINMENT]: "entertainment",
            [POIType.FERRY]: "ferry",
            [POIType.FLAG]: "vlag",
            [POIType.GOLF_RESORT]: "golfresort",
            [POIType.HOTEL]: "hotel",
            [POIType.MARKET]: "markt",
            [POIType.MUSEUM]: "museum",
            [POIType.NATURE]: "natuur",
            [POIType.NEIGHBORHOOD]: "buurt",
            [POIType.OTHER]: "andere",
            [POIType.PARK]: "park",
            [POIType.RESTAURANT]: "restaurant",
            [POIType.SCENIC_ROUTE]: "prachtige route",
            [POIType.SHOP]: "winkel",
            [POIType.SKI_AREA]: "skigebied",
            [POIType.SPA]: "spa",
            [POIType.TOURIST_ATTRACTION]: "toeristische attractie",
            [POIType.TRAIN_STATION]: "treinstation",
            [POIType.TRAIL]: "pad",
            [POIType.VIEWPOINT]: "uitkijkpunt",
            [POIType.WATERFALL]: "waterval",
            [POIType.PHARMACY]: "apotheek",
            [POIType.STREET]: "straat",
            [POIType.SQUARE]: "plein",
            [POIType.RELIGION]: "religie",
            [POIType.ISLAND]: "eiland",
            [POIType.ZOO]: "dierentuin",
        },
        boards: {
            [BOARD_TYPES.ALL]: "All-Inclusive",
            [BOARD_TYPES.BNB]: "Bed & Breakfast (B&B)",
            [BOARD_TYPES.FULL]: "Volpension (ontbijt, lunch en diner)",
            [BOARD_TYPES.HALF]: "Halfpension (ontbijt en diner)",
            [BOARD_TYPES.ROOM]: "Room only (enkel verblijf)",
        },
        rooms: {
            double: "Tweepersoonskamer",
            single: "Eenpersoonskamer",
            suite: "Suite",
            triple: "Driepersoonskamer",
        },
    },
    "en-gb": {
        generalErrorMessage: "Something went wrong",
        navbar: {
            home: "Home",
            map: "Map",
            details: "Details",
        },
        home: {
            exploreTheTrip: "Discover the journey",
        },
        mail: {
            preview: "See your personal travel proposal from {0} of {1}",
            contact: "Contact your travel agent",
            preview_agent_confirm: "{0} just confirmed their trip with {1}",
            no_images: "No images",
            click_here: "Click here",
            confirmation_thanks: "Thank you for confirming this trip, your travel professional will handle the booking further.",
            booking_request: "Booking request",
            booking_confirmation: "Booking confirmation",
            by: "by",
            with: "with",
            edit_trip: "or edit trip",
            booking_detail: "Booking details",
            client_name: "Client name",
            client_details: "Contact details",
            client_feedback: "Client feedback",
            trip: "Trip",
            number_travelers: "Number of travellers",
            ref: "Ref",
            total_price: "Total trip price",
        },
        details: {
            book: {
                title: "Confirm trip",
                book: "Confirm",
                description: "Please fill in the details below to let your travel professional know that you are interested in booking the trip.",
                description_book_link: "Please press the button below to book this trip, it will redirect you to an external page of your travel professional.",
                email: "Email address",
                comment: "Comment",
                bookedMessage: "The booking is finalised, enjoy your trip.",
                errorMessage: "Please make sure a valid email address was provided.",
                retry: "Try again",
                terms: "terms and conditions",
                termsAgree: "I agree to the {0}",
                termsAgreed: "When confirming the trip, you have agreed to the {0}.",
                availableSpots: "Available spots",
            },
            downloadPDF: "Download PDF",
            playAudio: "Play audio",
            general: {
                others: "others",
                title: "General",
                day: "day",
                days: "days",
                hours: "hours",
                mins: "mins",
                secs: "secs",
                night: "night",
                nights: "nights",
                traveller: "traveller",
                travellers: "travellers",
                period: "period",
                duration: "duration",
                group: "group",
                departure: "departure",
                arrival: "arrival",
                pickup: "pickup",
                dropoff: "dropoff",
                option: "option",
                travelAgent: "your travel agent",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "City of arrival",
                departureCity: "City of departure",
                arrivalCountry: "Country of arrival",
                departureCountry: "Country of departure",
                flight: "Flight",
                flightDuration: "Flight Duration",
            },
            included: "Included",
            notIncluded: "Not included",
            price: "Price",
            documents: "Documents",
            downloadAll: "Download all",
            documentTypes: {
                [EDocument.OTHERDOC]: "other",
                [EDocument.TRAVELINFODOC]: "travel information",
                [EDocument.TRAVELINFOLINK]: "travel information",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "other",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "music",
                [EDocument.VIDEO]: "video",
                [EDocument.TRAVELREQDOC]: "travel requirements",
                [EDocument.TRAVELREQLINK]: "travel requirements",
                [EDocument.ESIM]: "electronic SIM card",
            },
            flights: "Flights",
            flightsAndTransports: "Flights & Transports",
            transports: "Transports",
            accommodations: "Accommodations",
            chat: "Chat",
            more: "More",
            viewAllTrips: "All trips",
            overview: "Overview",
            stay: "stay",
        },
        map: {
            viewMap: "View map",
            viewOverview: "View overview",
            hidePOIs: "Hide POIs",
            show_pois: "Show POIs",
            selectAll: "select all",
            deselectAll: "deselect all",
        },
        chat: {
            typeYourMessage: "Type your message",
        },
        root: {
            myTrips: "My trips",
            myTripsDescription: "an overview of all your trips opened in Lato",
        },
        poi_types: {
            [POIType.ACTIVITY]: "activity",
            [POIType.AIRPORT]: "airport",
            [POIType.BAR]: "bar",
            [POIType.BEACH]: "beach",
            [POIType.BRIDGE]: "bridge",
            [POIType.BUILDING]: "building",
            [POIType.CAMPGROUND]: "campground",
            [POIType.CAR_RENTAL]: "car rental",
            [POIType.CITY]: "city",
            [POIType.COFFEE_HOUSE]: "coffee house",
            [POIType.CULTURAL_SITE]: "cultural site",
            [POIType.CYCLING]: "cycling",
            [POIType.ENTERTAINMENT]: "entertainment",
            [POIType.FERRY]: "ferry",
            [POIType.FLAG]: "flag",
            [POIType.GOLF_RESORT]: "golf resort",
            [POIType.HOTEL]: "hotel",
            [POIType.MARKET]: "market",
            [POIType.MUSEUM]: "museum",
            [POIType.NATURE]: "nature",
            [POIType.NEIGHBORHOOD]: "neighbourhood",
            [POIType.OTHER]: "other",
            [POIType.PARK]: "park",
            [POIType.RESTAURANT]: "restaurant",
            [POIType.SCENIC_ROUTE]: "scenic route",
            [POIType.SHOP]: "shop",
            [POIType.SKI_AREA]: "ski area",
            [POIType.SPA]: "spa",
            [POIType.TOURIST_ATTRACTION]: "tourist attraction",
            [POIType.TRAIN_STATION]: "train station",
            [POIType.TRAIL]: "trail",
            [POIType.VIEWPOINT]: "viewpoint",
            [POIType.WATERFALL]: "waterfall",
            [POIType.PHARMACY]: "pharmacy",
            [POIType.STREET]: "street",
            [POIType.SQUARE]: "square",
            [POIType.RELIGION]: "religion",
            [POIType.ISLAND]: "island",
            [POIType.ZOO]: "zoo",
        },
        boards: {
            [BOARD_TYPES.ALL]: BOARD_TYPES.ALL,
            [BOARD_TYPES.BNB]: BOARD_TYPES.BNB,
            [BOARD_TYPES.FULL]: BOARD_TYPES.FULL,
            [BOARD_TYPES.HALF]: BOARD_TYPES.HALF,
            [BOARD_TYPES.ROOM]: BOARD_TYPES.ROOM,
        },
        rooms: {
            double: "Double",
            single: "Single",
            suite: "Suite",
            triple: "Triple",
        },
    },
    "en-us": {
        generalErrorMessage: "Something went wrong",
        navbar: {
            home: "Home",
            map: "Map",
            details: "Details",
        },
        home: {
            exploreTheTrip: "Discover the journey",
        },
        mail: {
            preview: "See your personal travel proposal from {0} of {1}",
            contact: "Contact your travel agent",
            preview_agent_confirm: "{0} just confirmed their trip with {1}",
            no_images: "No images",
            click_here: "Click here",
            confirmation_thanks: "Thank you for confirming this trip, your travel professional will continue to handle the booking.",
            booking_request: "Booking request",
            booking_confirmation: "Booking confirmation",
            by: "by",
            with: "with",
            edit_trip: "or edit trip",
            booking_detail: "Booking details",
            client_name: "Client name",
            client_details: "Contact details",
            client_feedback: "Client feedback",
            trip: "Trip",
            number_travelers: "Number of travelers",
            ref: "Ref",
            total_price: "Total trip price",
        },
        details: {
            book: {
                title: "Confirm trip",
                book: "Confirm",
                description: "Please fill in the information below to let your travel professional know you are interested in booking the trip.",
                description_book_link: "Please press the button below to book this trip, it will redirect you to an external page of your travel professional.",
                email: "Email address",
                comment: "Comment",
                bookedMessage: "The booking is finalized, enjoy your trip.",
                errorMessage: "Please make sure a valid email address was provided.",
                retry: "Try again",
                terms: "terms and conditions",
                termsAgree: "I agree to the {0}",
                termsAgreed: "When confirming the trip, you have agreed to the {0}.",
                availableSpots: "Available spots",
            },
            downloadPDF: "Download PDF",
            playAudio: "Play audio",
            general: {
                others: "others",
                title: "General",
                day: "day",
                days: "days",
                hours: "hours",
                mins: "min",
                secs: "sec",
                night: "night",
                nights: "nights",
                traveller: "traveler",
                travellers: "travelers",
                period: "period",
                duration: "duration",
                group: "group",
                departure: "departure",
                arrival: "arrival",
                pickup: "pickup",
                dropoff: "dropoff",
                option: "option",
                travelAgent: "your travel agent",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "City of arrival",
                departureCity: "City of departure",
                arrivalCountry: "Country of arrival",
                departureCountry: "Country of departure",
                flight: "Flight",
                flightDuration: "Flight Duration",
            },
            included: "Included",
            notIncluded: "Not included",
            price: "Price",
            documents: "Documents",
            downloadAll: "Download all",
            documentTypes: {
                [EDocument.OTHERDOC]: "other",
                [EDocument.TRAVELINFODOC]: "travel information",
                [EDocument.TRAVELINFOLINK]: "travel information",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "other",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "music",
                [EDocument.VIDEO]: "video",
                [EDocument.TRAVELREQDOC]: "travel requirements",
                [EDocument.TRAVELREQLINK]: "travel requirements",
                [EDocument.ESIM]: "electronic SIM card",
            },
            flights: "Flights",
            flightsAndTransports: "Flights & Transportations",
            transports: "Transportations",
            accommodations: "Accommodations",
            chat: "Chat",
            more: "More",
            viewAllTrips: "All trips",
            overview: "Travel overview",
            stay: "stay",
        },
        map: {
            viewMap: "View on map",
            viewOverview: "See the travel overview",
            hidePOIs: "Hide POIs",
            show_pois: "Show POIs",
            selectAll: "select all",
            deselectAll: "deselect all",
        },
        chat: {
            typeYourMessage: "Type your message",
        },
        root: {
            myTrips: "My travels",
            myTripsDescription: "an overview of all your trips opened in Lato",
        },
        poi_types: {
            [POIType.ACTIVITY]: "activity",
            [POIType.AIRPORT]: "airport",
            [POIType.BAR]: "bar",
            [POIType.BEACH]: "beach",
            [POIType.BRIDGE]: "bridge",
            [POIType.BUILDING]: "building",
            [POIType.CAMPGROUND]: "campsite",
            [POIType.CAR_RENTAL]: "car rental",
            [POIType.CITY]: "city",
            [POIType.COFFEE_HOUSE]: "coffee house",
            [POIType.CULTURAL_SITE]: "cultural place",
            [POIType.CYCLING]: "bicycles",
            [POIType.ENTERTAINMENT]: "entertainment",
            [POIType.FERRY]: "ferry",
            [POIType.FLAG]: "flag",
            [POIType.GOLF_RESORT]: "golf resort",
            [POIType.HOTEL]: "hotel",
            [POIType.MARKET]: "market",
            [POIType.MUSEUM]: "museum",
            [POIType.NATURE]: "nature",
            [POIType.NEIGHBORHOOD]: "neighborhood",
            [POIType.OTHER]: "other",
            [POIType.PARK]: "park",
            [POIType.RESTAURANT]: "restaurant",
            [POIType.SCENIC_ROUTE]: "touristic route",
            [POIType.SHOP]: "store",
            [POIType.SKI_AREA]: "ski area",
            [POIType.SPA]: "spa",
            [POIType.TOURIST_ATTRACTION]: "touristic attraction",
            [POIType.TRAIN_STATION]: "train station",
            [POIType.TRAIL]: "trail",
            [POIType.VIEWPOINT]: "viewpoint",
            [POIType.WATERFALL]: "waterfall",
            [POIType.PHARMACY]: "pharmacy",
            [POIType.STREET]: "street",
            [POIType.SQUARE]: "square",
            [POIType.RELIGION]: "religion",
            [POIType.ISLAND]: "island",
            [POIType.ZOO]: "zoo",
        },
        boards: {
            [BOARD_TYPES.ALL]: BOARD_TYPES.ALL,
            [BOARD_TYPES.BNB]: BOARD_TYPES.BNB,
            [BOARD_TYPES.FULL]: BOARD_TYPES.FULL,
            [BOARD_TYPES.HALF]: BOARD_TYPES.HALF,
            [BOARD_TYPES.ROOM]: BOARD_TYPES.ROOM,
        },
        rooms: {
            double: "Double",
            single: "Single",
            suite: "Suite",
            triple: "Triple",
        },
    },
    fr: {
        generalErrorMessage: "Une erreur s'est produite",
        navbar: {
            home: "Accueil",
            map: "Carte",
            details: "Détails",
        },
        home: {
            exploreTheTrip: "Découvrir le voyage",
        },
        mail: {
            preview: "Consultez votre proposition de voyage personnalisée de {0} à {1}",
            contact: "Contactez votre professionnel du voyage",
            preview_agent_confirm: "{0} vient de confirmer leur voyage avec {1}",
            no_images: "Pas d'images",
            click_here: "Cliquez ici",
            confirmation_thanks: "Merci d'avoir confirmé ce voyage, votre professionnel du voyage s'occupera de la suite de la réservation.",
            booking_request: "Demande de réservation",
            booking_confirmation: "Confirmation de réservation",
            by: "par",
            with: "avec",
            edit_trip: "ou modifier le voyage",
            booking_detail: "Détails de la réservation",
            client_name: "Nom du client",
            client_details: "Coordonnées",
            client_feedback: "Retour client",
            trip: "Voyage",
            number_travelers: "Nombre de voyageurs",
            ref: "Réf",
            total_price: "Prix total du voyage",
        },
        details: {
            book: {
                title: "Confirmer ce voyage",
                book: "Confirmer",
                description: "Veuillez compléter vos données pour faire savoir à votre professionnel du voyage que vous êtes intéressé par ce voyage.",
                description_book_link: "Veuillez cliquer sur le bouton ci-dessous pour réserver ce voyage, cela vous redirigera vers une page externe de votre professionnel du voyage.",
                email: "Adresse e-mail",
                comment: "Commentaire",
                bookedMessage: "La réservation est finalisée, profitez de votre voyage.",
                errorMessage: "Veuillez entrer une adresse e-mail valide.",
                retry: "Réessayer",
                terms: "termes et conditions",
                termsAgree: "J'accepte les {0}",
                termsAgreed: "En confirmant le voyage, vous acceptez les {0}.",
                availableSpots: "Places disponibles",
            },
            downloadPDF: "Télécharger PDF",
            playAudio: "Écouter l'audio",
            general: {
                others: "autres",
                title: "Général",
                day: "jour",
                days: "jours",
                hours: "heures",
                mins: "mins",
                secs: "secs",
                night: "nuit",
                nights: "nuits",
                traveller: "voyageur",
                travellers: "voyageurs",
                period: "période",
                duration: "durée",
                group: "groupe",
                departure: "départ",
                arrival: "arrivée",
                pickup: "ramassage",
                dropoff: "dépose-minute",
                option: "option",
                travelAgent: "your travel agent",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "Ville d'arrivée",
                departureCity: "Ville de départ",
                arrivalCountry: "Pays d'arrivée",
                departureCountry: "Pays de départ",
                flight: "Vol",
                flightDuration: "Durée du vol",
            },
            included: "Inclus",
            notIncluded: "Non inclus",
            price: "Prix",
            documents: "Documents",
            downloadAll: "Télécharger tout",
            documentTypes: {
                [EDocument.OTHERDOC]: "autre",
                [EDocument.TRAVELINFODOC]: "informations sur le voyage",
                [EDocument.TRAVELINFOLINK]: "informations sur le voyage",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "autre",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "musique",
                [EDocument.VIDEO]: "vidéo",
                [EDocument.TRAVELREQDOC]: "exigences de voyage",
                [EDocument.TRAVELREQLINK]: "exigences de voyage",
                [EDocument.ESIM]: "carte SIM électronique",
            },
            flights: "Vols",
            flightsAndTransports: "Vols & Transports",
            transports: "Transports",
            accommodations: "hébergements",
            chat: "Chat",
            more: "Plus",
            viewAllTrips: "Tous les voyages",
            overview: "Aperçu",
            stay: "résidence",
        },
        map: {
            viewMap: "Voir sur la carte",
            viewOverview: "Voir l'aperçu du voyage",
            hidePOIs: "Voir points d'intérêt",
            show_pois: "Cacher points d'intérêts",
            selectAll: "sélectionner tout",
            deselectAll: "désélectionner tout",
        },
        chat: {
            typeYourMessage: "Écrivez votre message",
        },
        root: {
            myTrips: "Mes voyages",
            myTripsDescription: "un aperçu de tous vos voyages ouverts dans Lato",
        },
        poi_types: {
            [POIType.ACTIVITY]: "activité",
            [POIType.AIRPORT]: "aéroport",
            [POIType.BAR]: "bar",
            [POIType.BRIDGE]: "pont",
            [POIType.BEACH]: "plage",
            [POIType.BUILDING]: "bâtiment",
            [POIType.CAMPGROUND]: "camping",
            [POIType.CAR_RENTAL]: "location de voiture",
            [POIType.COFFEE_HOUSE]: "café",
            [POIType.CITY]: "ville",
            [POIType.CULTURAL_SITE]: "site culturel",
            [POIType.CYCLING]: "cyclisme",
            [POIType.ENTERTAINMENT]: "divertissement",
            [POIType.FERRY]: "ferry",
            [POIType.FLAG]: "drapeau",
            [POIType.GOLF_RESORT]: "centre de golf",
            [POIType.HOTEL]: "hôtel",
            [POIType.MARKET]: "marché",
            [POIType.MUSEUM]: "musée",
            [POIType.NATURE]: "nature",
            [POIType.NEIGHBORHOOD]: "le voisinage",
            [POIType.OTHER]: "autre",
            [POIType.PARK]: "parc",
            [POIType.RESTAURANT]: "restaurant",
            [POIType.SCENIC_ROUTE]: "route panoramique",
            [POIType.SHOP]: "magasin",
            [POIType.SKI_AREA]: "domaine skiable",
            [POIType.SPA]: "station thermale",
            [POIType.TOURIST_ATTRACTION]: "attraction touristique",
            [POIType.TRAIN_STATION]: "gare",
            [POIType.TRAIL]: "sentier",
            [POIType.VIEWPOINT]: "belvédère",
            [POIType.WATERFALL]: "cascade",
            [POIType.PHARMACY]: "pharmacie",
            [POIType.STREET]: "rue",
            [POIType.SQUARE]: "place",
            [POIType.RELIGION]: "religion",
            [POIType.ISLAND]: "île",
            [POIType.ZOO]: "zoo",
        },
        boards: {
            [BOARD_TYPES.ALL]: "Tout inclus",
            [BOARD_TYPES.BNB]: "Chambres d'hôtes (B&B)",
            [BOARD_TYPES.FULL]: "Pension complète (petit-déjeuner, déjeuner et dîner)",
            [BOARD_TYPES.HALF]: "Demi-pension (petit-déjeuner et dîner)",
            [BOARD_TYPES.ROOM]: "Chambre seulement",
        },
        rooms: {
            double: "Double",
            single: "Individuelle",
            suite: "Suite",
            triple: "Triple",
        },
    },
    es: {
        generalErrorMessage: "Se ha producido un error",
        navbar: {
            home: "Inicio",
            map: "Mapa",
            details: "Detalles",
        },
        home: {
            exploreTheTrip: "Descubre el viaje",
        },
        mail: {
            preview: "Vea su propuesta de viaje personalizada de {0} de {1}",
            contact: "Póngase en contacto con su agente de viajes",
            preview_agent_confirm: "{0} acaba de confirmar su viaje con {1}",
            no_images: "Sin imágenes",
            click_here: "Haga clic aquí",
            confirmation_thanks: "Gracias por confirmar este viaje. Su agente de viajes se encargará de tramitar la reserva.",
            booking_request: "Solicitud de reserva",
            booking_confirmation: "Confirmación de reserva",
            by: "por",
            with: "con",
            edit_trip: "o editar viaje",
            booking_detail: "Detalles de la reserva",
            client_name: "Nombre del cliente",
            client_details: "Detalles de contacto",
            client_feedback: "Comentarios del cliente",
            trip: "Viaje",
            number_travelers: "Número de viajeros",
            ref: "Ref",
            total_price: "Precio total del viaje",
        },
        details: {
            book: {
                title: "Confirmar el viaje",
                book: "Confirmar",
                description: "Por favor, introduzca su dirección de correo electrónico para hacer saber a su profesional de viajes que está interesado en reservar este viaje.",
                description_book_link: "Por favor, pulse el botón de abajo para reservar este viaje, le redirigirá a una página externa de su profesional de viajes.",
                email: "Dirección de correo electrónico",
                comment: "Comentario",
                bookedMessage: "La reserva ha finalizado, disfrute de su viaje.",
                errorMessage: "Por favor, introduzca una dirección de correo electrónico válida.",
                retry: "Reintentar",
                terms: "condiciones generales",
                termsAgree: "Acepto los {0}",
                termsAgreed: "Al confirmar el viaje, ha aceptado las {0}.",
                availableSpots: "Plazas disponibles",
            },
            flight: {
                arrivalCity: "Ciudad de llegada",
                departureCity: "Ciudad de salida",
                arrivalCountry: "País de llegada",
                departureCountry: "País de salida",
                flight: "Vuelo",
                flightDuration: "Duración del vuelo",
            },
            downloadPDF: "Descargar PDF",
            playAudio: "Reproducir audio",
            general: {
                others: "otros",
                title: "General",
                day: "día",
                days: "días",
                hours: "horas",
                mins: "mins",
                secs: "secs",
                night: "noche",
                nights: "noches",
                traveller: "viajero",
                travellers: "viajeros",
                period: "período",
                duration: "duración",
                group: "grupo",
                departure: "salida",
                arrival: "llegada",
                pickup: "colección",
                dropoff: "rendirse",
                option: "opción",
                travelAgent: "su agencia de viajes",
                voucher: "bono",
            },
            included: "Incluido",
            notIncluded: "No incluido",
            price: "Precio",
            documents: "Documentos",
            downloadAll: "Descargar todos",
            documentTypes: {
                [EDocument.OTHERDOC]: "otro",
                [EDocument.TRAVELINFODOC]: "información de viaje",
                [EDocument.TRAVELINFOLINK]: "información de viaje",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "otro",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "música",
                [EDocument.VIDEO]: "video",
                [EDocument.TRAVELREQDOC]: "requisitos de viaje",
                [EDocument.TRAVELREQLINK]: "requisitos de viaje",
                [EDocument.ESIM]: "tarjeta SIM electrónica",
            },
            flights: "Vuelos",
            flightsAndTransports: "Vuelos & Transportes",
            transports: "Transportes",
            accommodations: "alojamientos",
            chat: "Chat",
            more: "Más",
            viewAllTrips: "Todos los viajes",
            overview: "Resumen",
            stay: "estancia",
        },
        map: {
            viewMap: "Ver el mapa",
            viewOverview: "Ver el resumen del viaje",
            hidePOIs: "Ocultar PDIs",
            show_pois: "Mostrar PDIs",
            selectAll: "seleccionar todo",
            deselectAll: "deseleccionar todo",
        },
        chat: {
            typeYourMessage: "Escriba su mensaje",
        },
        root: {
            myTrips: "Mis viajes",
            myTripsDescription: "un resumen de todos tus viajes abiertos en Lato",
        },
        poi_types: {
            [POIType.ACTIVITY]: "actividad",
            [POIType.AIRPORT]: "aeropuerto",
            [POIType.BAR]: "bar",
            [POIType.BEACH]: "playa",
            [POIType.BRIDGE]: "puente",
            [POIType.BUILDING]: "edificio",
            [POIType.CAMPGROUND]: "camping",
            [POIType.CAR_RENTAL]: "alquiler de coches",
            [POIType.CITY]: "ciudad",
            [POIType.COFFEE_HOUSE]: "cafetería",
            [POIType.CULTURAL_SITE]: "sitio cultural",
            [POIType.CYCLING]: "ciclismo",
            [POIType.ENTERTAINMENT]: "entretenimiento",
            [POIType.FERRY]: "ferry",
            [POIType.FLAG]: "bandera",
            [POIType.GOLF_RESORT]: "complejo de golf",
            [POIType.HOTEL]: "hotel",
            [POIType.MARKET]: "mercado",
            [POIType.MUSEUM]: "museo",
            [POIType.NATURE]: "naturaleza",
            [POIType.NEIGHBORHOOD]: "barrio",
            [POIType.OTHER]: "otro",
            [POIType.PARK]: "parque",
            [POIType.RESTAURANT]: "restaurante",
            [POIType.SCENIC_ROUTE]: "ruta paisajística",
            [POIType.SHOP]: "tienda",
            [POIType.SKI_AREA]: "zona de esquí",
            [POIType.SPA]: "balneario",
            [POIType.TOURIST_ATTRACTION]: "atracción turística",
            [POIType.TRAIN_STATION]: "estación de tren",
            [POIType.TRAIL]: "sendero",
            [POIType.VIEWPOINT]: "mirador",
            [POIType.WATERFALL]: "cascada",
            [POIType.PHARMACY]: "farmacia",
            [POIType.STREET]: "calle",
            [POIType.SQUARE]: "plaza",
            [POIType.RELIGION]: "religión",
            [POIType.ISLAND]: "isla",
            [POIType.ZOO]: "zoológico",
        },
        boards: {
            [BOARD_TYPES.ALL]: "Todo incluido",
            [BOARD_TYPES.BNB]: "Casa rural (B&B)",
            [BOARD_TYPES.FULL]: "Pensión completa (desayuno, almuerzo y cena)",
            [BOARD_TYPES.HALF]: "Media pensión (desayuno y cena)",
            [BOARD_TYPES.ROOM]: "Sólo habitación",
        },
        rooms: {
            double: "Doble",
            single: "Individual",
            suite: "Suite",
            triple: "Triple",
        },
    },
    it: {
        generalErrorMessage: "Si è verificato un errore",
        navbar: {
            home: "Home",
            map: "Mappa",
            details: "Dettagli",
        },
        home: {
            exploreTheTrip: "Scoprire il viaggio",
        },
        mail: {
            preview: "Visualizza la tua proposta di viaggio personalizzata da {0} di {1}",
            contact: "Contatta il tuo professionista di viaggio",
            preview_agent_confirm: "{0} ha appena confermato il suo viaggio con {1}",
            no_images: "Nessuna immagine",
            click_here: "Clicca qui",
            confirmation_thanks: "Grazie per aver confermato questo viaggio, il vostro professionista di viaggio si occuperà dell'ulteriore prenotazione.",
            booking_request: "Richiesta di prenotazione",
            booking_confirmation: "Conferma della prenotazione",
            by: "da",
            with: "con",
            edit_trip: "o modifica viaggio",
            booking_detail: "Dettagli della prenotazione",
            client_name: "Nome del cliente",
            client_details: "Dettagli di contatto",
            client_feedback: "Feedback del cliente",
            trip: "Viaggio",
            number_travelers: "Numero di viaggiatori",
            ref: "Rif",
            total_price: "Prezzo totale del viaggio",
        },
        details: {
            book: {
                title: "Conferma il viaggio",
                book: "Confermare",
                description: "Inserisci il tuo indirizzo e-mail per comunicare al tuo professionista di viaggio che sei interessato/a a prenotare questo viaggio",
                description_book_link: "Premi il pulsante sottostante per prenotare questo viaggio, che ti reindirizzerà a una pagina esterna del tuo professionista di viaggio.",
                email: "Indirizzo e-mail",
                comment: "Commento",
                bookedMessage: "La prenotazione è conclusa, godetevi il viaggio.",
                errorMessage: "Inserisci un indirizzo e-mail valido.",
                retry: "Riprova",
                terms: "termini e condizioni",
                termsAgree: "Accetto il {0}",
                termsAgreed: "Confermando il viaggio, avete accettato i {0}.",
                availableSpots: "Posti disponibili",
            },
            downloadPDF: "Scaricare PDF",
            playAudio: "Riproduzione audio",
            general: {
                others: "altri",
                title: "Generale",
                day: "giorno",
                days: "giorni",
                hours: "ore",
                mins: "min",
                secs: "sec",
                night: "notte",
                nights: "notti",
                traveller: "viaggiatore",
                travellers: "viaggiatori",
                period: "periodo",
                duration: "durata",
                group: "gruppo",
                departure: "partenza",
                arrival: "arrivo",
                pickup: "pick-up",
                dropoff: "drop-off",
                option: "opzione",
                travelAgent: "il vostro agente di viaggio",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "Città di arrivo",
                departureCity: "Città di partenza",
                arrivalCountry: "Paese di arrivo",
                departureCountry: "Paese di partenza",
                flight: "Volo",
                flightDuration: "Durata del volo",
            },
            included: "Incluso",
            notIncluded: "Non incluso",
            price: "Prezzo",
            documents: "Documenti",
            downloadAll: "Scarica tutto",
            documentTypes: {
                [EDocument.OTHERDOC]: "altro",
                [EDocument.TRAVELINFODOC]: "informazioni sul viaggio",
                [EDocument.TRAVELINFOLINK]: "informazioni sul viaggio",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "altro",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "musica",
                [EDocument.VIDEO]: "video",
                [EDocument.TRAVELREQDOC]: "requisiti di viaggio",
                [EDocument.TRAVELREQLINK]: "requisiti di viaggio",
                [EDocument.ESIM]: "carta SIM elettronica",
            },
            flights: "Voli",
            flightsAndTransports: "Voli & Trasporti",
            transports: "Transporti",
            accommodations: "sistemazioni",
            chat: "Chat",
            more: "Altro",
            viewAllTrips: "Tutti i viaggi",
            overview: "Panoramica",
            stay: "soggiorno",
        },
        map: {
            viewMap: "Visualizza sulla mappa",
            viewOverview: "Visualizza la panoramica",
            hidePOIs: "Nascondere i PDI",
            show_pois: "Mostrare i PDI",
            selectAll: "selezionare tutto",
            deselectAll: "deselezionare tutto",
        },
        chat: {
            typeYourMessage: "Digitare il messaggio",
        },
        root: {
            myTrips: "I miei viaggi",
            myTripsDescription: "una panoramica di tutti i vostri viaggi aperti in Lato",
        },
        poi_types: {
            [POIType.ACTIVITY]: "attività",
            [POIType.AIRPORT]: "aeroporto",
            [POIType.BAR]: "bar",
            [POIType.BEACH]: "spiaggia",
            [POIType.BRIDGE]: "ponte",
            [POIType.BUILDING]: "edificio",
            [POIType.CAMPGROUND]: "campeggio",
            [POIType.CAR_RENTAL]: "noleggio macchina",
            [POIType.CITY]: "città",
            [POIType.COFFEE_HOUSE]: "caffetteria",
            [POIType.CULTURAL_SITE]: "sito culturale",
            [POIType.CYCLING]: "ciclismo",
            [POIType.ENTERTAINMENT]: "intrattenimento",
            [POIType.FERRY]: "traghetto",
            [POIType.FLAG]: "bandiera",
            [POIType.GOLF_RESORT]: "resort di golf",
            [POIType.HOTEL]: "albergo",
            [POIType.MARKET]: "mercato",
            [POIType.MUSEUM]: "museo",
            [POIType.NATURE]: "natura",
            [POIType.NEIGHBORHOOD]: "quartiere",
            [POIType.OTHER]: "altro",
            [POIType.PARK]: "parco",
            [POIType.RESTAURANT]: "ristorante",
            [POIType.SCENIC_ROUTE]: "percorso panoramico",
            [POIType.SHOP]: "negozio",
            [POIType.SKI_AREA]: "area sciistica",
            [POIType.SPA]: "stazione termale",
            [POIType.TOURIST_ATTRACTION]: "attrazione turistica",
            [POIType.TRAIN_STATION]: "stazione ferroviaria",
            [POIType.TRAIL]: "sentiero",
            [POIType.VIEWPOINT]: "punto panoramico",
            [POIType.WATERFALL]: "cascata",
            [POIType.PHARMACY]: "farmacia",
            [POIType.STREET]: "strada",
            [POIType.SQUARE]: "piazza",
            [POIType.RELIGION]: "religione",
            [POIType.ISLAND]: "isola",
            [POIType.ZOO]: "zoo",
        },
        boards: {
            [BOARD_TYPES.ALL]: "Tutto compreso",
            [BOARD_TYPES.BNB]: "Agriturismo (B&B)",
            [BOARD_TYPES.FULL]: "Pensione completa (colazione, pranzo e cena)",
            [BOARD_TYPES.HALF]: "Mezza pensione (colazione e cena)",
            [BOARD_TYPES.ROOM]: "Solo camera",
        },
        rooms: {
            double: "Doble",
            single: "Individual",
            suite: "Suite",
            triple: "Triple",
        },
    },
    pt: {
        generalErrorMessage: "Ocorreu um erro",
        navbar: {
            home: "Início",
            map: "Mapa",
            details: "Detalhes",
        },
        home: {
            exploreTheTrip: "Descobrir a viagem",
        },
        mail: {
            preview: "Veja a sua proposta de viagem personalizada a partir de {0} de {1}",
            contact: "Contactar o seu profissional de viagens",
            preview_agent_confirm: "{0} acabou de confirmar sua viagem com {1}",
            no_images: "Sem imagens",
            click_here: "Clique aqui",
            confirmation_thanks: "Obrigado por confirmar esta viagem, o seu profissional de viagens tratará da reserva.",
            booking_request: "Pedido de reserva",
            booking_confirmation: "Confirmação da reserva",
            by: "por",
            with: "com",
            edit_trip: "ou editar viagem",
            booking_detail: "Detalhes da reserva",
            client_name: "Nome do cliente",
            client_details: "Detalhes de contato",
            client_feedback: "Feedback do cliente",
            trip: "Viagem",
            number_travelers: "Número de viajantes",
            ref: "Ref",
            total_price: "Preço total da viagem",
        },
        details: {
            book: {
                title: "Confirmar a viagem",
                book: "Confirme",
                description: "Por favor, introduza o seu endereço electrónico para que o seu profissional de viagens saiba que está interessado em reservar esta viagem.",
                description_book_link: "Prima o botão abaixo para reservar esta viagem, que o redireccionará para uma página externa do seu profissional de viagens.",
                email: "Endereço de e-mail",
                comment: "Comentário",
                bookedMessage: "A reserva está concluída, aproveite a sua viagem.",
                errorMessage: "Por favor introduza um endereço de correio electrónico válido.",
                retry: "Tente novamente",
                terms: "termos e condições",
                termsAgree: "Aceito o {0}",
                termsAgreed: "Ao confirmar a viagem, está a concordar com os {0}.",
                availableSpots: "Lugares disponíveis",
            },
            downloadPDF: "Descarregar PDF",
            playAudio: "Reproduzir áudio",
            general: {
                others: "outros",
                title: "Geral",
                day: "dia",
                days: "dias",
                hours: "horas",
                mins: "mins",
                secs: "secs",
                night: "noite",
                nights: "noites",
                traveller: "viajante",
                travellers: "viajantes",
                period: "período",
                duration: "duração",
                group: "grupo",
                departure: "partida",
                arrival: "chegada",
                pickup: "colecção",
                dropoff: "rendição",
                option: "opção",
                travelAgent: "o seu agente de viagens",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "Cidade de chegada",
                departureCity: "Cidade de Partida",
                arrivalCountry: "País de chegada",
                departureCountry: "País de partida",
                flight: "Voo",
                flightDuration: "Duração do voo",
            },
            included: "Incluído",
            notIncluded: "Não incluído",
            price: "Preço",
            documents: "Documentos",
            downloadAll: "Descarregar todos",
            documentTypes: {
                [EDocument.OTHERDOC]: "outros",
                [EDocument.TRAVELINFODOC]: "informações de viagem",
                [EDocument.TRAVELINFOLINK]: "informações de viagem",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "outro",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "música",
                [EDocument.VIDEO]: "vídeo",
                [EDocument.TRAVELREQDOC]: "requisitos de viagem",
                [EDocument.TRAVELREQLINK]: "requisitos de viagem",
                [EDocument.ESIM]: "cartão SIM eletrónico",
            },
            flights: "Voos",
            flightsAndTransports: "Voos & Transportes",
            transports: "Transportes",
            accommodations: "alojamentos",
            chat: "Chat",
            more: "Mais",
            viewAllTrips: "Todas as viagens",
            overview: "Síntese",
            stay: "estadia",
        },
        map: {
            viewMap: "Ver no mapa",
            viewOverview: "Veja a visão geral da viagem",
            hidePOIs: "Ocultar PIs",
            show_pois: "Mostrar PIs",
            selectAll: "selecionar tudo",
            deselectAll: "anular a seleção de todos",
        },
        chat: {
            typeYourMessage: "Escreva a sua mensagem",
        },
        root: {
            myTrips: "Minhas viagens",
            myTripsDescription: "uma visão geral de todas as suas viagens abertas no Lato",
        },
        poi_types: {
            [POIType.ACTIVITY]: "atividade",
            [POIType.AIRPORT]: "aeroporto",
            [POIType.BAR]: "bar",
            [POIType.BEACH]: "praia",
            [POIType.BUILDING]: "edifício",
            [POIType.BRIDGE]: "ponte",
            [POIType.CAMPGROUND]: "parque de campismo",
            [POIType.CAR_RENTAL]: "aluguer de automóveis",
            [POIType.COFFEE_HOUSE]: "café",
            [POIType.CITY]: "cidade",
            [POIType.CULTURAL_SITE]: "sítio cultural",
            [POIType.CYCLING]: "ciclismo",
            [POIType.ENTERTAINMENT]: "entretenimento",
            [POIType.FERRY]: "ferry",
            [POIType.FLAG]: "bandeira",
            [POIType.GOLF_RESORT]: "estância de golfe",
            [POIType.HOTEL]: "hotel",
            [POIType.MARKET]: "mercado",
            [POIType.MUSEUM]: "museu",
            [POIType.NATURE]: "natureza",
            [POIType.NEIGHBORHOOD]: "bairro",
            [POIType.OTHER]: "outro",
            [POIType.PARK]: "parque",
            [POIType.RESTAURANT]: "restaurante",
            [POIType.SCENIC_ROUTE]: "rota cénica",
            [POIType.SHOP]: "loja",
            [POIType.SKI_AREA]: "área de esqui",
            [POIType.SPA]: "spa",
            [POIType.TOURIST_ATTRACTION]: "atração turística",
            [POIType.TRAIN_STATION]: "estação de comboios",
            [POIType.TRAIL]: "trilho",
            [POIType.VIEWPOINT]: "miradouro",
            [POIType.WATERFALL]: "cascata",
            [POIType.PHARMACY]: "farmácia",
            [POIType.STREET]: "rua",
            [POIType.SQUARE]: "praça",
            [POIType.RELIGION]: "religião",
            [POIType.ISLAND]: "ilha",
            [POIType.ZOO]: "zoológico",
        },
        boards: {
            [BOARD_TYPES.ALL]: "Tudo incluído",
            [BOARD_TYPES.BNB]: "Pousada (B&B)",
            [BOARD_TYPES.FULL]: "Pensão completa (pequeno-almoço, almoço e jantar)",
            [BOARD_TYPES.HALF]: "Meia pensão (pequeno-almoço e jantar)",
            [BOARD_TYPES.ROOM]: "Apenas quarto",
        },
        rooms: {
            double: "Duplo",
            single: "Individual",
            suite: "Suite",
            triple: "Triplo",
        },
    },
    de: {
        generalErrorMessage: "Es ist ein Fehler aufgetreten",
        navbar: {
            home: "Home",
            map: "Karte",
            details: "Details",
        },
        home: {
            exploreTheTrip: "Entdecken Sie die Reise",
        },
        mail: {
            preview: "Sehen Sie Ihr persönliches Reiseangebot von {0} von {1}",
            contact: "Kontaktieren Sie Ihren Reiseprofi",
            preview_agent_confirm: "{0} hat gerade seine Reise mit {1} bestätigt",
            no_images: "Keine Bilder",
            click_here: "Klicken Sie hier",
            confirmation_thanks: "Vielen Dank für die Bestätigung dieser Reise, Ihr Reiseberater wird die Buchung weiter bearbeiten.",
            booking_request: "Buchungsanfrage",
            booking_confirmation: "Buchungsbestätigung",
            by: "von",
            with: "mit",
            edit_trip: "oder Reise bearbeiten",
            booking_detail: "Buchungsdetails",
            client_name: "Kundenname",
            client_details: "Kontaktdaten",
            client_feedback: "Kundenfeedback",
            trip: "Reise",
            number_travelers: "Anzahl der Reisenden",
            ref: "Ref",
            total_price: "Gesamtpreis der Reise",
        },
        details: {
            book: {
                title: "Bestätigen Sie die Reise",
                book: "Bestätigen Sie",
                description: "Bitte vervollständigen Sie Ihre Angaben, um Ihrem Reiseexperten mitzuteilen, dass Sie an der Buchung dieser Reise interessiert sind.",
                description_book_link: "Bitte klicken Sie auf die Schaltfläche unten, um diese Reise zu buchen, sie leitet Sie auf eine externe Seite Ihres Reiseanbieters weiter.",
                email: "E-Mail Adresse",
                comment: "Kommentar",
                bookedMessage: "Die Buchung ist abgeschlossen, genießen Sie Ihre Reise.",
                errorMessage: "Bitte geben Sie eine gültige E-Mail Adresse ein.",
                retry: "Erneut versuchen",
                terms: "Bedingungen und Konditionen",
                termsAgree: "Ich stimme den {0}",
                termsAgreed: "Mit der Bestätigung der Reise erklären Sie sich mit den {0} einverstanden.",
                availableSpots: "Freie Plätze",
            },
            downloadPDF: "PDF herunterladen",
            playAudio: "Audio abspielen",
            general: {
                others: "andere",
                title: "Allgemein",
                day: "Tag",
                days: "Tage",
                hours: "Stunden",
                mins: "Min.",
                secs: "Sek.",
                night: "Nacht",
                nights: "Nächte",
                traveller: "Reisender",
                travellers: "Reisende",
                period: "Zeitspanne",
                duration: "Dauer",
                group: "Gruppe",
                departure: "Abreise",
                arrival: "Ankunft",
                pickup: "Kollektion",
                dropoff: "Übergabe",
                option: "option",
                travelAgent: "Ihr Reiseveranstalter",
                voucher: "gutschein",
            },
            flight: {
                arrivalCity: "Ankunftsstadt",
                departureCity: "Abflugstadt",
                arrivalCountry: "Ankunftsland",
                departureCountry: "Abreiseland",
                flight: "Flug",
                flightDuration: "Flugdauer",
            },
            included: "Eingeschlossen",
            notIncluded: "Nicht eingeschlossen",
            price: "Preis",
            documents: "Dokumente",
            downloadAll: "Alles herunterladen",
            documentTypes: {
                [EDocument.OTHERDOC]: "sonstiges",
                [EDocument.TRAVELINFODOC]: "reiseinformationen",
                [EDocument.TRAVELINFOLINK]: "reiseinformationen",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "sonstiges",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "musik",
                [EDocument.VIDEO]: "video",
                [EDocument.TRAVELREQDOC]: "reiseanforderungen",
                [EDocument.TRAVELREQLINK]: "reiseanforderungen",
                [EDocument.ESIM]: "elektronische SIM-Karte",
            },
            flights: "Flüge",
            flightsAndTransports: "Flüge & Transporte",
            transports: "Transporte",
            accommodations: "Unterkünfte",
            chat: "Chat",
            more: "Mehr",
            viewAllTrips: "Alle Reisen",
            overview: "Übersicht",
            stay: "Aufenthalt",
        },
        map: {
            viewMap: "Auf der Karte ansehen",
            viewOverview: "Reisevorschau ansehen",
            hidePOIs: "POIs ausblenden",
            show_pois: "POIs anzeigen",
            selectAll: "salles auswählen",
            deselectAll: "alle abwählen",
        },
        chat: {
            typeYourMessage: "Geben Sie Ihre Nachricht ein",
        },
        root: {
            myTrips: "Meine Reisen",
            myTripsDescription: "eine Übersicht über alle Ihre in Lato geöffneten Reisen",
        },
        poi_types: {
            [POIType.ACTIVITY]: "Tätigkeit",
            [POIType.AIRPORT]: "Flughafen",
            [POIType.BAR]: "Bar",
            [POIType.BEACH]: "Strand",
            [POIType.BRIDGE]: "Brücke",
            [POIType.BUILDING]: "Gebäude",
            [POIType.CAMPGROUND]: "Zeltplatz",
            [POIType.CAR_RENTAL]: "Autovermietung",
            [POIType.COFFEE_HOUSE]: "Kaffeehaus",
            [POIType.CITY]: "Stadt",
            [POIType.CULTURAL_SITE]: "Kulturstätte",
            [POIType.CYCLING]: "Radfahren",
            [POIType.ENTERTAINMENT]: "Unterhaltung",
            [POIType.FERRY]: "Fähre",
            [POIType.FLAG]: "Flagge",
            [POIType.GOLF_RESORT]: "Golfresort",
            [POIType.HOTEL]: "Hotel",
            [POIType.MARKET]: "Markt",
            [POIType.MUSEUM]: "Museum",
            [POIType.NATURE]: "Natur",
            [POIType.NEIGHBORHOOD]: "Nachbarschaft",
            [POIType.OTHER]: "Sonstiges",
            [POIType.PARK]: "Park",
            [POIType.RESTAURANT]: "Restaurant",
            [POIType.SCENIC_ROUTE]: "Panoramastraße",
            [POIType.SHOP]: "Geschäft",
            [POIType.SKI_AREA]: "Skigebiet",
            [POIType.SPA]: "Heilbad",
            [POIType.TOURIST_ATTRACTION]: "Touristenattraktion",
            [POIType.TRAIN_STATION]: "Bahnhof",
            [POIType.TRAIL]: "Pfad",
            [POIType.VIEWPOINT]: "Aussichtspunkt",
            [POIType.WATERFALL]: "Wasserfall",
            [POIType.PHARMACY]: "Apotheke",
            [POIType.STREET]: "straße",
            [POIType.SQUARE]: "platz",
            [POIType.RELIGION]: "religion",
            [POIType.ISLAND]: "insel",
            [POIType.ZOO]: "zoo",
        },
        boards: {
            [BOARD_TYPES.ALL]: "All-inclusive",
            [BOARD_TYPES.BNB]: "Bed & Breakfast (B&B)",
            [BOARD_TYPES.FULL]: "Vollpension (Frühstück, Mittagessen und Abendessen)",
            [BOARD_TYPES.HALF]: "Halbpension (Frühstück und Abendessen)",
            [BOARD_TYPES.ROOM]: "Nur Zimmer",
        },
        rooms: {
            double: "Zweibett",
            single: "Einzel",
            suite: "Suite",
            triple: "Dreibett",
        },
    },
    zh: {
        generalErrorMessage: "出了问题",
        navbar: {
            home: "首页",
            map: "地图",
            details: "详细信息",
        },
        home: {
            exploreTheTrip: "探索旅程",
        },
        mail: {
            preview: "查看来自 {0} of {1} 的个人旅行建议",
            contact: "联系您的旅行社",
            preview_agent_confirm: "{0} 刚刚确认了他们与 {1} 的旅行",
            no_images: "没有图片",
            click_here: "点击这里",
            confirmation_thanks: "感谢您确认此次旅行，您的旅行专业人员将进一步处理预订事宜。",
            booking_request: "预订请求",
            booking_confirmation: "预订确认",
            by: "由",
            with: "与",
            edit_trip: "或编辑旅行",
            booking_detail: "预订详情",
            client_name: "客户姓名",
            client_details: "联系方式",
            client_feedback: "客户反馈",
            trip: "旅行",
            number_travelers: "旅行人数",
            ref: "参考",
            total_price: "旅行总价",
        },
        details: {
            book: {
                title: "确认行程",
                book: "缚上",
                description: "请填写以下详细信息，以便让您的旅游专业人员知道您有兴趣预订该行程。",
                description_book_link: "请按下面的按钮预订此行程，它将把您重定向到您的旅游专业人员的外部页面。",
                email: "电子邮件地址",
                comment: "评论",
                bookedMessage: "预订已完成，祝您旅途愉快。",
                errorMessage: "请输入有效的电子邮件地址。",
                retry: "再试一次",
                terms: "条款和条件",
                termsAgree: "我同意 {0}",
                termsAgreed: "确认行程时，您同意{0}。",
                availableSpots: "可用名额",
            },
            downloadPDF: "下载 PDF",
            playAudio: "播放音频",
            general: {
                others: "其他人",
                title: "一般情况",
                day: "天",
                days: "天数",
                hours: "小时",
                mins: "分钟",
                secs: "秒钟",
                night: "夜",
                nights: "夜里",
                traveller: "旅行者",
                travellers: "旅客",
                period: "时间",
                duration: "会期",
                group: "组",
                departure: "离职",
                arrival: "新来的人",
                pickup: "系列",
                dropoff: "投降",
                option: "选择权",
                travelAgent: "您的旅行社",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "抵达城市",
                departureCity: "出发城市",
                arrivalCountry: "出发国家",
                departureCountry: "抵达国家",
                flight: "飞行",
                flightDuration: "飞行时间",
            },
            included: "包括",
            notIncluded: "不包括",
            price: "价格",
            documents: "文件",
            downloadAll: "全部下载",
            documentTypes: {
                [EDocument.OTHERDOC]: "其他",
                [EDocument.TRAVELINFODOC]: "旅游信息",
                [EDocument.TRAVELINFOLINK]: "旅游信息",
                [EDocument.VOUCHERDOC]: "传票",
                [EDocument.VOUCHERLINK]: "传票",
                [EDocument.OTHERLINK]: "其他",
                [EDocument.PODCAST]: "播客",
                [EDocument.EBOOK]: "电子书",
                [EDocument.MUSIC]: "音乐",
                [EDocument.VIDEO]: "视频",
                [EDocument.TRAVELREQDOC]: "差旅要求",
                [EDocument.TRAVELREQLINK]: "差旅要求",
                [EDocument.ESIM]: "电子卡",
            },
            flights: "航班",
            flightsAndTransports: "航班和运输",
            transports: "运输",
            accommodations: "住宿",
            chat: "聊天",
            more: "更多信息",
            viewAllTrips: "所有行程",
            overview: "旅游概况",
            stay: "逗留",
        },
        map: {
            viewMap: "在地图上查看",
            viewOverview: "查看旅游概况",
            hidePOIs: "隐藏 POI",
            show_pois: "显示 POI",
            selectAll: "全部选择",
            deselectAll: "全部取消选择",
        },
        chat: {
            typeYourMessage: "输入您的信息",
        },
        root: {
            myTrips: "我的旅行",
            myTripsDescription: "查看所有可用行程",
        },
        poi_types: {
            [POIType.ACTIVITY]: "活动",
            [POIType.AIRPORT]: "机场",
            [POIType.BAR]: "酒吧",
            [POIType.BEACH]: "海滩",
            [POIType.BRIDGE]: "桥牌",
            [POIType.BUILDING]: "建筑物",
            [POIType.CAMPGROUND]: "露营地",
            [POIType.CAR_RENTAL]: "汽车租赁",
            [POIType.CITY]: "城市",
            [POIType.COFFEE_HOUSE]: "咖啡馆",
            [POIType.CULTURAL_SITE]: "文化场所",
            [POIType.CYCLING]: "骑自行车",
            [POIType.ENTERTAINMENT]: "娱乐",
            [POIType.FERRY]: "轮渡",
            [POIType.GOLF_RESORT]: "高尔夫度假村",
            [POIType.FLAG]: "旗帜",
            [POIType.HOTEL]: "酒店",
            [POIType.MARKET]: "市场",
            [POIType.MUSEUM]: "博物馆",
            [POIType.NATURE]: "自然",
            [POIType.NEIGHBORHOOD]: "街区",
            [POIType.OTHER]: "其他",
            [POIType.PARK]: "公园",
            [POIType.RESTAURANT]: "餐厅",
            [POIType.SCENIC_ROUTE]: "观光路线",
            [POIType.SHOP]: "商店",
            [POIType.SKI_AREA]: "滑雪场",
            [POIType.SPA]: "温泉",
            [POIType.TOURIST_ATTRACTION]: "旅游景点",
            [POIType.TRAIN_STATION]: "火车站",
            [POIType.TRAIL]: "小径",
            [POIType.VIEWPOINT]: "观景点",
            [POIType.WATERFALL]: "瀑布",
            [POIType.PHARMACY]: "药店",
            [POIType.STREET]: "街道",
            [POIType.SQUARE]: "广场",
            [POIType.RELIGION]: "宗教",
            [POIType.ISLAND]: "岛屿",
            [POIType.ZOO]: "动物园",
        },
        boards: {
            [BOARD_TYPES.ALL]: "全包",
            [BOARD_TYPES.BNB]: "住宿加早餐（B&B)",
            [BOARD_TYPES.FULL]: "全餐（早餐、午餐和晚餐)",
            [BOARD_TYPES.HALF]: "半膳（早餐和晚餐)",
            [BOARD_TYPES.ROOM]: "仅限房间",
        },
        rooms: {
            double: "双人",
            single: "单人",
            suite: "套房",
            triple: "三人",
        },
    },
    tr: {
        generalErrorMessage: "Bir şeyler ters gitti",
        navbar: {
            home: "Ev",
            map: "Harita",
            details: "Detaylar",
        },
        home: {
            exploreTheTrip: "Yolculuğu keşfedin",
        },
        mail: {
            preview: "Kişisel seyahat teklifinizi {0}'dan {1}'e kadar görün",
            contact: "Seyahat acentenizle iletişime geçin",
            preview_agent_confirm: "{0}, {1} ile seyahatini onayladı",
            no_images: "Resim yok",
            click_here: "Buraya tıklayın",
            confirmation_thanks: "Bu seyahati onayladığınız için teşekkür ederiz, seyahat uzmanınız rezervasyon işlemlerini gerçekleştirecektir.",
            booking_request: "Rezervasyon talebi",
            booking_confirmation: "Rezervasyon onayi",
            by: "tarafından",
            with: "ile",
            edit_trip: "veya seyahati düzenle",
            booking_detail: "Rezervasyon detayları",
            client_name: "Müşteri adı",
            client_details: "İletişim detayları",
            client_feedback: "Müşteri geri bildirimi",
            trip: "Seyahat",
            number_travelers: "Seyahat eden kişi sayısı",
            ref: "Ref",
            total_price: "Toplam seyahat fiyatı",
        },
        details: {
            book: {
                title: "Seyahati onaylayın",
                book: "Onayla",
                description: "Seyahat uzmanınıza seyahat rezervasyonu yaptırmak istediğinizi bildirmek için lütfen aşağıdaki bilgileri doldurun.",
                description_book_link: "Bu seyahati rezerve etmek için lütfen aşağıdaki düğmeye basın, sizi seyahat uzmanınızın bir sayfasına yönlendirecektir.",
                email: "E-posta adresiniz",
                comment: "Yorum",
                bookedMessage: "Rezervasyon tamamlandı, seyahatinizin tadını çıkarın.",
                errorMessage: "Lütfen geçerli bir e-posta adresi girin.",
                retry: "Tekrar deneyin",
                terms: "şartlar ve koşullar",
                termsAgree: "Kabul ediyorum {0}",
                termsAgreed: "Seyahati onaylarken, {0} kabul etmiş olursunuz.",
                availableSpots: "Mevcut yerler",
            },
            downloadPDF: "PDF indir",
            playAudio: "Ses oynat",
            general: {
                others: "Diğerleri",
                title: "Genel",
                day: "gün",
                days: "gün",
                hours: "saat",
                mins: "dakika",
                secs: "saniye",
                night: "gece",
                nights: "gece",
                traveller: "yolcu",
                travellers: "yolcu",
                period: "dönem",
                duration: "süre",
                group: "grup",
                departure: "gidiş",
                arrival: "geliş",
                pickup: "alma",
                dropoff: "bırakma",
                option: "opsiyon",
                travelAgent: "seyahat acenteniz",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "Varış şehri",
                departureCity: "Kalkış şehri",
                arrivalCountry: "Kalkış ülkesi",
                departureCountry: "Varış ülkesi",
                flight: "Uçuş",
                flightDuration: "Uçuş süresi",
            },
            included: "Dahil",
            notIncluded: "Dahil değildir",
            price: "Fiyat",
            documents: "Belgeler",
            downloadAll: "Tümünü indir",
            documentTypes: {
                [EDocument.OTHERDOC]: "diğer",
                [EDocument.TRAVELINFODOC]: "seyahat bi̇lgi̇leri̇",
                [EDocument.TRAVELINFOLINK]: "seyahat bi̇lgi̇leri̇",
                [EDocument.VOUCHERDOC]: "kupon",
                [EDocument.VOUCHERLINK]: "kupon",
                [EDocument.OTHERLINK]: "diğer",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-kitap",
                [EDocument.MUSIC]: "müzik",
                [EDocument.VIDEO]: "video",
                [EDocument.TRAVELREQDOC]: "seyahat gereklilikleri",
                [EDocument.TRAVELREQLINK]: "seyahat gereklilikleri",
                [EDocument.ESIM]: "elektronik SIM kart",
            },
            flights: "Uçuşlar",
            flightsAndTransports: "Uçuşlar & Taşımalar",
            transports: "Taşımalar",
            accommodations: "konaklama yerleri",
            chat: "Sohbet",
            more: "Daha fazla",
            viewAllTrips: "Tüm seyahatler",
            overview: "seyahate genel bakiş",
            stay: "kalmak",
        },
        map: {
            viewMap: "Haritada görüntüle",
            viewOverview: "Seyahate genel bakışı görün",
            hidePOIs: "POI'leri Gizle",
            show_pois: "POI'leri göster",
            selectAll: "tümünü seç",
            deselectAll: "tümünün seçimini kaldır",
        },
        chat: {
            typeYourMessage: "Mesajınızı yazın",
        },
        root: {
            myTrips: "Seyahatlerim",
            myTripsDescription: "Lato'da açılan tüm seyahatlerinize genel bir bakış",
        },
        poi_types: {
            [POIType.ACTIVITY]: "aktivite",
            [POIType.AIRPORT]: "havaalanı",
            [POIType.BAR]: "bar",
            [POIType.BEACH]: "plaj",
            [POIType.BRIDGE]: "KÖPRÜ",
            [POIType.BUILDING]: "bina",
            [POIType.CAMPGROUND]: "kamp alanı",
            [POIType.CAR_RENTAL]: "araç kiralama",
            [POIType.CITY]: "şehir",
            [POIType.COFFEE_HOUSE]: "KAHVE EVİ",
            [POIType.CULTURAL_SITE]: "kültürel alan",
            [POIType.CYCLING]: "bisiklet",
            [POIType.ENTERTAINMENT]: "eğlence",
            [POIType.FERRY]: "feribot",
            [POIType.FLAG]: "bayrak",
            [POIType.GOLF_RESORT]: "golf tesisi",
            [POIType.HOTEL]: "Otel",
            [POIType.MARKET]: "Pazar",
            [POIType.MUSEUM]: "müze",
            [POIType.NATURE]: "doğa",
            [POIType.NEIGHBORHOOD]: "mahalle",
            [POIType.OTHER]: "diğer",
            [POIType.PARK]: "park",
            [POIType.RESTAURANT]: "restoran",
            [POIType.SCENIC_ROUTE]: "doğal rota",
            [POIType.SHOP]: "dükkan",
            [POIType.SKI_AREA]: "kayak alanı",
            [POIType.SPA]: "spa",
            [POIType.TOURIST_ATTRACTION]: "turistik cazibe merkezi",
            [POIType.TRAIN_STATION]: "tren istasyonu",
            [POIType.TRAIL]: "iz",
            [POIType.VIEWPOINT]: "bakış açısı",
            [POIType.WATERFALL]: "Şelale",
            [POIType.PHARMACY]: "Eczane",
            [POIType.STREET]: "sokak",
            [POIType.SQUARE]: "meydan",
            [POIType.RELIGION]: "din",
            [POIType.ISLAND]: "ada",
            [POIType.ZOO]: "hayvanat bahçesi",
        },
        boards: {
            [BOARD_TYPES.ALL]: "Herşey Dahil",
            [BOARD_TYPES.BNB]: "Oda ve Kahvaltı (B&B)",
            [BOARD_TYPES.FULL]: "Tam Pansiyon (Kahvaltı, Öğle Yemeği ve Akşam Yemeği)",
            [BOARD_TYPES.HALF]: "Yarım Pansiyon (Kahvaltı ve Akşam Yemeği)",
            [BOARD_TYPES.ROOM]: "Sadece oda",
        },
        rooms: {
            double: "Çift kişilik",
            single: "Tek kişilik",
            suite: "Süit",
            triple: "Üç kişilik",
        },
    },
    ru: {
        generalErrorMessage: "Что-то пошло не так",
        navbar: {
            home: "Главная",
            map: "Карта",
            details: "Подробности",
        },
        home: {
            exploreTheTrip: "Откройте для себя путешествие",
        },
        mail: {
            preview: "Посмотрите ваше персональное туристическое предложение от {0} из {1}",
            contact: "Обратитесь в туристическое агентство",
            preview_agent_confirm: "{0} только что подтвердил свою поездку с {1}",
            no_images: "Нет изображений",
            click_here: "Нажмите здесь",
            confirmation_thanks: "Спасибо за подтверждение этой поездки, ваш специалист по туризму займется дальнейшим бронированием.",
            booking_request: "Запрос на бронирование",
            booking_confirmation: "подтверждение бронирования",
            by: "от",
            with: "с",
            edit_trip: "или изменить поездку",
            booking_detail: "Детали бронирования",
            client_name: "Имя клиента",
            client_details: "Контактная информация",
            client_feedback: "Отзыв клиента",
            trip: "Поездка",
            number_travelers: "Количество путешественников",
            ref: "Ссылка",
            total_price: "Общая стоимость поездки",
        },
        details: {
            book: {
                title: "Подтверждение поездки",
                book: "Подтвердить",
                description: "Заполните приведенную ниже информацию, чтобы сообщить специалисту по туризму о своей заинтересованности в бронировании поездки.",
                description_book_link: "Пожалуйста, нажмите кнопку ниже, чтобы забронировать эту поездку, она перенаправит вас на внешнюю страницу вашего специалиста по туризму.",
                email: "Адрес электронной почты",
                comment: "Комментарий",
                bookedMessage: "Бронирование завершено, наслаждайтесь поездкой.",
                errorMessage: "Пожалуйста, введите действительный адрес электронной почты.",
                retry: "Попробуйте еще раз",
                terms: "правила и условия",
                termsAgree: "Я согласен с {0}",
                termsAgreed: "Подтверждая поездку, вы соглашаетесь с {0}.",
                availableSpots: "Свободные места",
            },
            downloadPDF: "Скачать PDF",
            playAudio: "Воспроизведение аудио",
            general: {
                others: "другие",
                title: "Общие сведения",
                day: "день",
                days: "дней",
                hours: "час",
                mins: "минут",
                secs: "сек",
                night: "ночь",
                nights: "ночи",
                traveller: "путешественник",
                travellers: "путешественники",
                period: "период",
                duration: "продолжительность",
                group: "группа",
                departure: "отправление",
                arrival: "прибытие",
                pickup: "коллекция",
                dropoff: "капитуляция",
                option: "опция",
                travelAgent: "ваше туристическое агентство",
                voucher: "ваучер",
            },
            flight: {
                arrivalCity: "Город прибытия",
                departureCity: "Город отправления",
                arrivalCountry: "Страна отправления",
                departureCountry: "Страна прибытия",
                flight: "Полет",
                flightDuration: "Продолжительность полета",
            },
            included: "В комплекте",
            notIncluded: "Не входит в комплект поставки",
            price: "Цена",
            documents: "Документы",
            downloadAll: "Скачать все",
            documentTypes: {
                [EDocument.OTHERDOC]: "другие",
                [EDocument.TRAVELINFODOC]: "туристическая информация",
                [EDocument.TRAVELINFOLINK]: "туристическая информация",
                [EDocument.VOUCHERDOC]: "ваучер",
                [EDocument.VOUCHERLINK]: "ваучер",
                [EDocument.OTHERLINK]: "другие",
                [EDocument.PODCAST]: "подкаст",
                [EDocument.EBOOK]: "электронная книга",
                [EDocument.MUSIC]: "музыка",
                [EDocument.VIDEO]: "видео",
                [EDocument.TRAVELREQDOC]: "требования к командировкам",
                [EDocument.TRAVELREQLINK]: "требования к командировкам",
                [EDocument.ESIM]: "электронная SIM-карта",
            },
            flights: "Рейсы",
            flightsAndTransports: "Полеты и перевозки",
            transports: "Перевозки",
            accommodations: "размещение",
            chat: "Чат",
            more: "Подробнее",
            viewAllTrips: "Все поездки",
            overview: "Обзор путешествий",
            stay: "оставайтесь",
        },
        map: {
            viewMap: "Посмотреть на карте",
            viewOverview: "Посмотреть обзор путешествия",
            hidePOIs: "Скрыть точки интереса",
            show_pois: "Показать точки интереса",
            selectAll: "выбрать все",
            deselectAll: "отменить выбор",
        },
        chat: {
            typeYourMessage: "Напечатайте свое сообщение",
        },
        root: {
            myTrips: "Мои путешествия",
            myTripsDescription: "обзор всех ваших поездок, открытых в Лато",
        },
        poi_types: {
            [POIType.ACTIVITY]: "Деятельность",
            [POIType.AIRPORT]: "Аэропорт",
            [POIType.BAR]: "Бар",
            [POIType.BEACH]: "Пляж",
            [POIType.BRIDGE]: "мост",
            [POIType.BUILDING]: "здание",
            [POIType.CAMPGROUND]: "Кемпинг",
            [POIType.CAR_RENTAL]: "Прокат автомобилей",
            [POIType.CITY]: "Город",
            [POIType.COFFEE_HOUSE]: "Кофейня",
            [POIType.CULTURAL_SITE]: "Культурное место",
            [POIType.CYCLING]: "Велосипеды",
            [POIType.ENTERTAINMENT]: "Развлечения",
            [POIType.FERRY]: "Паром",
            [POIType.FLAG]: "Флаг",
            [POIType.GOLF_RESORT]: "гольф-курорт",
            [POIType.HOTEL]: "Отель",
            [POIType.MARKET]: "Рынок",
            [POIType.MUSEUM]: "Музей",
            [POIType.NATURE]: "Природа",
            [POIType.NEIGHBORHOOD]: "район",
            [POIType.OTHER]: "Другое",
            [POIType.PARK]: "Парк",
            [POIType.RESTAURANT]: "Ресторан",
            [POIType.SCENIC_ROUTE]: "Туристический маршрут",
            [POIType.SHOP]: "Магазин",
            [POIType.SKI_AREA]: "Горнолыжный курорт",
            [POIType.SPA]: "Спа",
            [POIType.TOURIST_ATTRACTION]: "Туристическая достопримечательность",
            [POIType.TRAIN_STATION]: "Железнодорожная станция",
            [POIType.TRAIL]: "тропа",
            [POIType.VIEWPOINT]: "Смотровая площадка",
            [POIType.WATERFALL]: "Водопад",
            [POIType.PHARMACY]: "Aптека",
            [POIType.STREET]: "улица",
            [POIType.SQUARE]: "площадь",
            [POIType.RELIGION]: "религия",
            [POIType.ISLAND]: "остров",
            [POIType.ZOO]: "зоопарк",
        },
        boards: {
            [BOARD_TYPES.ALL]: "Все включено",
            [BOARD_TYPES.BNB]: "Кровать и завтрак (B&B)",
            [BOARD_TYPES.FULL]: "Полный пансион (завтрак, обед и ужин)",
            [BOARD_TYPES.HALF]: "Полупансион (завтрак и ужин)",
            [BOARD_TYPES.ROOM]: "Только в номере",
        },
        rooms: {
            double: "Двухместный",
            single: "Одноместный ",
            suite: "Люкс",
            triple: "Трехместный",
        },
    },
    id: {
        generalErrorMessage: "Ada yang tidak beres",
        navbar: {
            home: "Beranda",
            map: "Peta",
            details: "Detail",
        },
        home: {
            exploreTheTrip: "Temukan perjalanannya",
        },
        mail: {
            preview: "Lihat proposal perjalanan pribadi Anda dari {0} dari {1}",
            contact: "Hubungi agen perjalanan Anda",
            preview_agent_confirm: "{0} baru saja mengonfirmasi perjalanan mereka dengan {1}",
            no_images: "Tidak ada gambar",
            click_here: "Klik di sini",
            confirmation_thanks: "Terima kasih telah mengonfirmasi perjalanan ini, profesional perjalanan Anda akan menangani pemesanan lebih lanjut.",
            booking_request: "Permintaan pemesanan",
            booking_confirmation: "Konfirmasi pemesanan",
            by: "oleh",
            with: "dengan",
            edit_trip: "atau edit perjalanan",
            booking_detail: "Detail pemesanan",
            client_name: "Nama klien",
            client_details: "Detail kontak",
            client_feedback: "Umpan balik klien",
            trip: "Perjalanan",
            number_travelers: "Jumlah wisatawan",
            ref: "Ref",
            total_price: "Total harga perjalanan",
        },
        details: {
            book: {
                title: "Konfirmasi perjalanan",
                book: "Konfirmasi",
                description: "Silakan isi detail di bawah ini untuk memberi tahu profesional perjalanan Anda bahwa Anda tertarik untuk memesan perjalanan tersebut.",
                description_book_link: "Silakan tekan tombol di bawah ini untuk memesan perjalanan ini, ini akan mengarahkan Anda ke halaman eksternal profesional perjalanan Anda.",
                email: "Alamat email",
                comment: "Komentar",
                bookedMessage: "Pemesanan sudah selesai, selamat menikmati perjalanan Anda.",
                errorMessage: "Masukkan alamat email yang valid.",
                retry: "Coba lagi",
                terms: "syarat dan ketentuan",
                termsAgree: "Saya setuju dengan {0}",
                termsAgreed: "Saat mengonfirmasi perjalanan, Anda telah menyetujui {0}.",
                availableSpots: "Tempat yang tersedia",
            },
            downloadPDF: "Unduh PDF",
            playAudio: "Memutar audio",
            general: {
                others: "lainnya",
                title: "Umum",
                day: "hari",
                days: "hari",
                hours: "jam",
                mins: "menit",
                secs: "detik",
                night: "malam",
                nights: "malam",
                traveller: "pelancong",
                travellers: "wisatawan",
                period: "periode",
                duration: "durasi",
                group: "kelompok",
                departure: "keberangkatan",
                arrival: "kedatangan",
                pickup: "koleksi",
                dropoff: "menyerah",
                option: "opsi",
                travelAgent: "agen perjalanan Anda",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "Kota kedatangan",
                departureCity: "Kota keberangkatan",
                arrivalCountry: "Negara keberangkatan",
                departureCountry: "Negara kedatangan",
                flight: "Penerbangan",
                flightDuration: "Durasi penerbangan",
            },
            included: "Termasuk",
            notIncluded: "Tidak termasuk",
            price: "Harga",
            documents: "Dokumen",
            downloadAll: "Unduh semua",
            documentTypes: {
                [EDocument.OTHERDOC]: "lainnya",
                [EDocument.TRAVELINFODOC]: "informasi perjalanan",
                [EDocument.TRAVELINFOLINK]: "informasi perjalanan",
                [EDocument.VOUCHERDOC]: "voucher",
                [EDocument.VOUCHERLINK]: "voucher",
                [EDocument.OTHERLINK]: "lainnya",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-book",
                [EDocument.MUSIC]: "musik",
                [EDocument.VIDEO]: "video",
                [EDocument.TRAVELREQDOC]: "persyaratan perjalanan",
                [EDocument.TRAVELREQLINK]: "persyaratan perjalanan",
                [EDocument.ESIM]: "kartu SIM elektronik",
            },
            flights: "Penerbangan",
            flightsAndTransports: "Penerbangan & Transportasi",
            transports: "Transportasi",
            accommodations: "akomodasi",
            chat: "Mengobrol",
            more: "Lebih lanjut",
            viewAllTrips: "Semua perjalanan",
            overview: "Ikhtisar perjalanan",
            stay: "tinggal",
        },
        map: {
            viewMap: "Lihat di peta",
            viewOverview: "Lihat ikhtisar perjalanan",
            hidePOIs: "Sembunyikan POI",
            show_pois: "Tampilkan POI",
            selectAll: "pilih semua",
            deselectAll: "batalkan pilihan semua",
        },
        chat: {
            typeYourMessage: "Ketik pesan Anda",
        },
        root: {
            myTrips: "Perjalanan saya",
            myTripsDescription: "ikhtisar dari semua perjalanan Anda yang dibuka di Lato",
        },
        poi_types: {
            [POIType.ACTIVITY]: "Aktivitas",
            [POIType.AIRPORT]: "Bandara",
            [POIType.BAR]: "Bar",
            [POIType.BEACH]: "Pantai",
            [POIType.BRIDGE]: "jembatan",
            [POIType.BUILDING]: "bangunan",
            [POIType.CAMPGROUND]: "Perkemahan",
            [POIType.CAR_RENTAL]: "Penyewaan mobil",
            [POIType.CITY]: "Kota",
            [POIType.COFFEE_HOUSE]: "Kedai kopi",
            [POIType.CULTURAL_SITE]: "Tempat Budaya",
            [POIType.CYCLING]: "Sepeda",
            [POIType.ENTERTAINMENT]: "Hiburan",
            [POIType.FERRY]: "Feri",
            [POIType.FLAG]: "Bendera",
            [POIType.GOLF_RESORT]: "Resor golf",
            [POIType.HOTEL]: "Hotel",
            [POIType.MARKET]: "Pasar",
            [POIType.MUSEUM]: "Museum",
            [POIType.NATURE]: "Alam",
            [POIType.NEIGHBORHOOD]: "lingkungan",
            [POIType.OTHER]: "Lainnya",
            [POIType.PARK]: "Taman",
            [POIType.RESTAURANT]: "Restoran",
            [POIType.SCENIC_ROUTE]: "Rute wisata",
            [POIType.SHOP]: "Toko",
            [POIType.SKI_AREA]: "Resor ski",
            [POIType.SPA]: "Spa",
            [POIType.TOURIST_ATTRACTION]: "Objek wisata",
            [POIType.TRAIN_STATION]: "Stasiun kereta api",
            [POIType.TRAIL]: "jalan setapak",
            [POIType.VIEWPOINT]: "Sudut pandang",
            [POIType.WATERFALL]: "Air terjun",
            [POIType.PHARMACY]: "Apotek",
            [POIType.STREET]: "ulica",
            [POIType.SQUARE]: "plac",
            [POIType.RELIGION]: "religia",
            [POIType.ISLAND]: "wyspa",
            [POIType.ZOO]: "ogród zoologiczny",
        },
        boards: {
            [BOARD_TYPES.ALL]: "Semua termasuk",
            [BOARD_TYPES.BNB]: "Bed & Breakfast (B&B)",
            [BOARD_TYPES.FULL]: "Full Board (Sarapan, Makan Siang, dan Makan Malam)",
            [BOARD_TYPES.HALF]: "Half Board (Sarapan dan Makan Malam)",
            [BOARD_TYPES.ROOM]: "Hanya untuk kamar",
        },
        rooms: {
            double: "Double",
            single: "Single ",
            suite: "Suite",
            triple: "Triple",
        },
    },
    ja: {
        generalErrorMessage: "何かが間違っていた",
        navbar: {
            home: "ホーム",
            map: "地図",
            details: "詳細",
        },
        home: {
            exploreTheTrip: "旅の発見",
        },
        mail: {
            preview: "1}の{0}からあなただけの旅行プランをご覧ください。",
            contact: "旅行代理店に問い合わせる",
            preview_agent_confirm: "{0} が {1} との旅行を確認しました",
            no_images: "画像なし",
            click_here: "こちらをクリック",
            confirmation_thanks: "ご旅行のご予約をありがとうございました。",
            booking_request: "予約リクエスト",
            booking_confirmation: "予約確認",
            by: "by",
            with: "with",
            edit_trip: "または旅行を編集",
            booking_detail: "予約の詳細",
            client_name: "クライアント名",
            client_details: "連絡先詳細",
            client_feedback: "クライアントフィードバック",
            trip: "旅行",
            number_travelers: "旅行者数",
            ref: "Ref",
            total_price: "総旅行費用",
        },
        details: {
            book: {
                title: "旅行の確認",
                book: "確認",
                description: "下記の詳細をご記入の上、ご旅行のご予約を希望される旨を担当者にお知らせください。",
                description_book_link: "この旅行を予約するには、下のボタンを押してください、それはあなたの旅行専門家の外部ページにリダイレクトされます。",
                email: "メールアドレス",
                comment: "コメント",
                bookedMessage: "ご予約が完了しました。ご旅行をお楽しみください。",
                errorMessage: "有効なEメールアドレスを入力してください。",
                retry: "再試行",
                terms: "諸条件",
                termsAgree: "{0}に同意します。",
                termsAgreed: "旅行を確認する際、あなたは{0}に同意しました。",
                availableSpots: "利用可能なスポット",
            },
            downloadPDF: "PDFダウンロード",
            playAudio: "オーディオ再生",
            general: {
                others: "すべて",
                title: "一般",
                day: "日",
                days: "日",
                hours: "時",
                mins: "議事録",
                secs: "秒",
                night: "夜",
                nights: "夜",
                traveller: "旅行者",
                travellers: "旅行者",
                period: "ピリオド",
                duration: "存続期間",
                group: "グループ",
                departure: "出発",
                arrival: "到着",
                pickup: "コレクション",
                dropoff: "降伏",
                option: "オプション",
                travelAgent: "旅行代理店",
                voucher: "クーポン",
            },
            flight: {
                arrivalCity: "到着地",
                departureCity: "出発地",
                arrivalCountry: "出発国",
                departureCountry: "到着国",
                flight: "フライト",
                flightDuration: "飛行時間",
            },
            included: "含まれる",
            notIncluded: "含まれない",
            price: "価格",
            documents: "書類",
            downloadAll: "すべてダウンロード",
            documentTypes: {
                [EDocument.OTHERDOC]: "その他",
                [EDocument.TRAVELINFODOC]: "旅行情報",
                [EDocument.TRAVELINFOLINK]: "旅行情報",
                [EDocument.VOUCHERDOC]: "バウチャー",
                [EDocument.VOUCHERLINK]: "バウチャー",
                [EDocument.OTHERLINK]: "その他",
                [EDocument.PODCAST]: "ポッドキャスト",
                [EDocument.EBOOK]: "電子書籍",
                [EDocument.MUSIC]: "音楽",
                [EDocument.VIDEO]: "ビデオ",
                [EDocument.TRAVELREQDOC]: "旅行条件",
                [EDocument.TRAVELREQLINK]: "旅行条件",
                [EDocument.ESIM]: "電子SIMカード",
            },
            flights: "フライト",
            flightsAndTransports: "航空券と輸送",
            transports: "輸送",
            accommodations: "宿泊施設",
            chat: "チャット",
            more: "もっと見る",
            viewAllTrips: "すべての旅行",
            overview: "旅行概要",
            stay: "滞在",
        },
        map: {
            viewMap: "地図で見る",
            viewOverview: "旅行概要を見る",
            hidePOIs: "POIを隠す",
            show_pois: "POIを表示する",
            selectAll: "pすべて選択",
            deselectAll: "すべて選択解除",
        },
        chat: {
            typeYourMessage: "メッセージを入力する",
        },
        root: {
            myTrips: "私の旅",
            myTripsDescription: "ラトで開かれるすべての旅行の概要",
        },
        poi_types: {
            [POIType.ACTIVITY]: "アクティビティ",
            [POIType.AIRPORT]: "空港",
            [POIType.BAR]: "バー",
            [POIType.BEACH]: "ビーチ",
            [POIType.BRIDGE]: "ブリッジ",
            [POIType.BUILDING]: "建物",
            [POIType.CAMPGROUND]: "キャンプ場",
            [POIType.CAR_RENTAL]: "レンタカー",
            [POIType.CITY]: "市",
            [POIType.COFFEE_HOUSE]: "コーヒーハウス",
            [POIType.CULTURAL_SITE]: "文化的な場所",
            [POIType.CYCLING]: "自転車",
            [POIType.ENTERTAINMENT]: "エンターテイメント",
            [POIType.FERRY]: "フェリー",
            [POIType.FLAG]: "国旗",
            [POIType.GOLF_RESORT]: "ゴルフリゾート",
            [POIType.HOTEL]: "ホテル",
            [POIType.MARKET]: "マーケット",
            [POIType.MUSEUM]: "博物館",
            [POIType.NATURE]: "自然",
            [POIType.NEIGHBORHOOD]: "近隣",
            [POIType.OTHER]: "その他",
            [POIType.PARK]: "公園",
            [POIType.RESTAURANT]: "レストラン",
            [POIType.SCENIC_ROUTE]: "観光ルート",
            [POIType.SHOP]: "ショップ",
            [POIType.SKI_AREA]: "スキー場",
            [POIType.SPA]: "スパ",
            [POIType.TOURIST_ATTRACTION]: "観光スポット",
            [POIType.TRAIN_STATION]: "鉄道駅",
            [POIType.TRAIL]: "トレイル",
            [POIType.VIEWPOINT]: "ビューポイント",
            [POIType.WATERFALL]: "滝",
            [POIType.PHARMACY]: "薬局",
            [POIType.STREET]: "通り",
            [POIType.SQUARE]: "広場",
            [POIType.RELIGION]: "宗教",
            [POIType.ISLAND]: "島",
            [POIType.ZOO]: "動物園",
        },
        boards: {
            [BOARD_TYPES.ALL]: "すべて込み",
            [BOARD_TYPES.BNB]: "ベッド＆ブレックファースト (B&B)",
            [BOARD_TYPES.FULL]: "フルボード（朝食、昼食、夕食）",
            [BOARD_TYPES.HALF]: "ハーフボード（朝食と夕食）",
            [BOARD_TYPES.ROOM]: "ルームオンリー",
        },
        rooms: {
            double: "ダブル",
            single: "シングル",
            suite: "スイートルーム",
            triple: "トリプル",
        },
    },
    ko: {
        generalErrorMessage: "문제가 발생했습니다.",
        navbar: {
            home: "홈",
            map: "지도",
            details: "세부 정보",
        },
        home: {
            exploreTheTrip: "여정 알아보기",
        },
        mail: {
            preview: "1} 중 {0}에서 개인 여행 제안서 보기",
            contact: "여행사에 문의",
            preview_agent_confirm: "{0}님이 {1}과(와)의 여행을 방금 확인했습니다",
            no_images: "이미지 없음",
            click_here: "여기를 클릭하세요",
            confirmation_thanks: "여행을 확정해 주셔서 감사드리며, 여행 전문가가 예약을 추가로 처리해 드립니다.",
            booking_request: "예약 요청",
            booking_confirmation: "예약 확인",
            by: "으로",
            with: "와 함께",
            edit_trip: "또는 여행 편집",
            booking_detail: "예약 세부 정보",
            client_name: "고객명",
            client_details: "연락처 세부 정보",
            client_feedback: "고객 피드백",
            trip: "여행",
            number_travelers: "여행자 수",
            ref: "참조",
            total_price: "총 여행 비용",
        },
        details: {
            book: {
                title: "여행 확인",
                book: "확인",
                description: "아래 세부 정보를 입력하여 여행 전문가에게 여행 예약에 관심이 있음을 알려 주세요.",
                description_book_link: "이 여행을 예약하려면 아래 버튼을 누르시면 여행 전문가의 외부 페이지로 리디렉션됩니다.",
                email: "이메일 주소",
                comment: "댓글",
                bookedMessage: "예약이 완료되었으니 즐거운 여행 되세요.",
                errorMessage: "유효한 이메일 주소를 입력하세요.",
                retry: "다시 시도",
                terms: "이용 약관",
                termsAgree: "본인은 {0}에 동의합니다.",
                termsAgreed: "여행을 확정할 때 {0}에 동의하셨습니다.",
                availableSpots: "사용 가능한 자리",
            },
            downloadPDF: "PDF 다운로드",
            playAudio: "오디오 재생",
            general: {
                others: "기타",
                title: "일반",
                day: "일",
                days: "일",
                hours: "시간",
                mins: "분",
                secs: "초",
                night: "밤",
                nights: "밤",
                traveller: "여행자",
                travellers: "여행자",
                period: "기간",
                duration: "기간",
                group: "그룹",
                departure: "출발",
                arrival: "도착",
                pickup: "컬렉션",
                dropoff: "항복",
                option: "옵션",
                travelAgent: "여행사",
                voucher: "바우처",
            },
            flight: {
                arrivalCity: "도착 도시",
                departureCity: "출발 도시",
                arrivalCountry: "출발 국가",
                departureCountry: "도착 국가",
                flight: "항공편",
                flightDuration: "비행 시간",
            },
            included: "포함",
            notIncluded: "포함되지 않음",
            price: "가격",
            documents: "문서",
            downloadAll: "모두 다운로드",
            documentTypes: {
                [EDocument.OTHERDOC]: "기타",
                [EDocument.TRAVELINFODOC]: "여행 정보",
                [EDocument.TRAVELINFOLINK]: "여행 정보",
                [EDocument.VOUCHERDOC]: "바우처",
                [EDocument.VOUCHERLINK]: "바우처",
                [EDocument.OTHERLINK]: "기타",
                [EDocument.PODCAST]: "팟캐스트",
                [EDocument.EBOOK]: "전자책",
                [EDocument.MUSIC]: "음악",
                [EDocument.VIDEO]: "비디오",
                [EDocument.TRAVELREQDOC]: "여행 요건",
                [EDocument.TRAVELREQLINK]: "여행 요건",
                [EDocument.ESIM]: "전자 SIM 카드",
            },
            flights: "항공편",
            flightsAndTransports: "항공편 및 교통편",
            transports: "전송",
            accommodations: "숙박 시설",
            chat: "채팅",
            more: "자세히 보기",
            viewAllTrips: "모든 여행",
            overview: "여행 개요",
            stay: "숙박 시설",
        },
        map: {
            viewMap: "지도에서 보기",
            viewOverview: "여행 개요 보기",
            hidePOIs: "POI 숨기기",
            show_pois: "POI 표시",
            selectAll: "모두 선택",
            deselectAll: "모두 선택 해제",
        },
        chat: {
            typeYourMessage: "메시지 입력",
        },
        root: {
            myTrips: "내 여행",
            myTripsDescription: "라토에서 개설된 모든 여행에 대한 개요",
        },
        poi_types: {
            [POIType.ACTIVITY]: "활동",
            [POIType.AIRPORT]: "공항",
            [POIType.BAR]: "바",
            [POIType.BEACH]: "해변",
            [POIType.BRIDGE]: "브릿지",
            [POIType.BUILDING]: "건물",
            [POIType.CAMPGROUND]: "캠프장",
            [POIType.CAR_RENTAL]: "렌터카",
            [POIType.CITY]: "도시",
            [POIType.COFFEE_HOUSE]: "커피 하우스",
            [POIType.CULTURAL_SITE]: "문화 장소",
            [POIType.CYCLING]: "자전거",
            [POIType.ENTERTAINMENT]: "엔터테인먼트",
            [POIType.FERRY]: "페리",
            [POIType.FLAG]: "깃발",
            [POIType.GOLF_RESORT]: "골프 리조트",
            [POIType.HOTEL]: "호텔",
            [POIType.MARKET]: "시장",
            [POIType.MUSEUM]: "박물관",
            [POIType.NATURE]: "자연",
            [POIType.NEIGHBORHOOD]: "이웃",
            [POIType.OTHER]: "기타",
            [POIType.PARK]: "공원",
            [POIType.RESTAURANT]: "레스토랑",
            [POIType.SCENIC_ROUTE]: "관광 루트",
            [POIType.SHOP]: "상점",
            [POIType.SKI_AREA]: "스키 리조트",
            [POIType.SPA]: "스파",
            [POIType.TOURIST_ATTRACTION]: "관광 명소",
            [POIType.TRAIN_STATION]: "기차역",
            [POIType.TRAIL]: "트레일",
            [POIType.VIEWPOINT]: "뷰포인트",
            [POIType.WATERFALL]: "폭포",
            [POIType.PHARMACY]: "약국",
            [POIType.STREET]: "거리",
            [POIType.SQUARE]: "광장",
            [POIType.RELIGION]: "종교",
            [POIType.ISLAND]: "섬",
            [POIType.ZOO]: "동물원",
        },
        boards: {
            [BOARD_TYPES.ALL]: "모든 기능 포함",
            [BOARD_TYPES.BNB]: "베드 & 브렉퍼스트 (B&B)",
            [BOARD_TYPES.FULL]: "풀보드(조식, 중식, 석식)",
            [BOARD_TYPES.HALF]: "하프 보드(조식 및 석식)",
            [BOARD_TYPES.ROOM]: "객실 전용",
        },
        rooms: {
            double: "더블",
            single: "싱글",
            suite: "스위트",
            triple: "트리플",
        },
    },
    ar: {
        generalErrorMessage: "حدث خطأ ما",
        navbar: { home: "الصفحة الرئيسية", map: "الخريطة", details: "التفاصيل" },
        home: { exploreTheTrip: "اكتشف الرحلة" },
        mail: {
            preview: "اطلع على عرض سفرك الشخصي من {0} من {1}",
            contact: "اتصل بوكيل سفرك",
            preview_agent_confirm: "لقد قام {0} بتأكيد رحلته مع {1} للتو",
            no_images: "لا توجد صور",
            click_here: "انقر هنا",
            confirmation_thanks: "شكراً لك على تأكيدك لهذه الرحلة، وسيتولى أخصائي السفر الخاص بك الحجز بشكل أكبر.",
            booking_request: "طلب حجز",
            booking_confirmation: "تأكيد الحجز",
            by: "من قبل",
            with: "مع",
            edit_trip: "أو تعديل الرحلة",
            booking_detail: "تفاصيل الحجز",
            client_name: "اسم العميل",
            client_details: "تفاصيل الاتصال",
            client_feedback: "تعليقات العميل",
            trip: "رحلة",
            number_travelers: "عدد المسافرين",
            ref: "المرجع",
            total_price: "السعر الإجمالي للرحلة",
        },
        details: {
            book: {
                title: "تأكيد الرحلة",
                book: "تأكيد",
                description: "يُرجى ملء التفاصيل أدناه لإعلام خبير السفر بأنك مهتم بحجز الرحلة.",
                description_book_link: "يُرجى الضغط على الزر أدناه لحجز هذه الرحلة، حيث سيُعيد توجيهك إلى صفحة خارجية خاصة بمتخصص السفر.",
                email: "عنوان البريد الإلكتروني",
                comment: "كيفية",
                bookedMessage: "تم الانتهاء من الحجز، استمتع برحلتك.",
                errorMessage: "يرجى التأكد من تقديم عنوان بريد إلكتروني صحيح.",
                retry: "حاول مرة أخرى",
                terms: "الشروط والأحكام",
                termsAgree: "أوافق على {0}",
                termsAgreed: "عند تأكيد الرحلة، وافقت على {0}.",
                availableSpots: "الأماكن المتاحة",
            },
            downloadPDF: "تنزيل ملف PDF",
            playAudio: "تشغيل الصوت",
            general: {
                others: "آخرون",
                title: "جنرال لواء",
                day: "اليوم",
                days: "أيام",
                hours: "ساعات",
                mins: "دقيقة",
                secs: "ثوانٍ",
                night: "ليلاً",
                nights: "الليالي",
                traveller: "مسافر",
                travellers: "المسافرون",
                period: "الفترة",
                duration: "المدة",
                group: "المجموعة",
                departure: "المغادرة",
                arrival: "الوصول",
                pickup: "الالتقاط",
                dropoff: "الإنزال",
                option: "الخيار",
                travelAgent: "وكيل سفرك",
                voucher: "قسيمة",
            },
            flight: {
                arrivalCity: "مدينة الوصول",
                departureCity: "مدينة المغادرة",
                arrivalCountry: "بلد المغادرة",
                departureCountry: "بلد الوصول",
                flight: "الطيران",
                flightDuration: "مدة الرحلة",
            },
            included: "متضمنة",
            notIncluded: "غير مشمول",
            price: "السعر",
            documents: "المستندات",
            downloadAll: "تحميل الكل",
            documentTypes: {
                [EDocument.OTHERDOC]: "أخرى",
                [EDocument.TRAVELINFODOC]: "معلومات السفر",
                [EDocument.TRAVELINFOLINK]: "معلومات السفر",
                [EDocument.VOUCHERDOC]: "قسيمة",
                [EDocument.VOUCHERLINK]: "قسيمة",
                [EDocument.OTHERLINK]: "أخرى",
                [EDocument.PODCAST]: "بودكاست",
                [EDocument.EBOOK]: "الكتاب الإلكتروني",
                [EDocument.MUSIC]: "الموسيقى",
                [EDocument.VIDEO]: "فيديو",
                [EDocument.TRAVELREQDOC]: "متطلبات السفر",
                [EDocument.TRAVELREQLINK]: "متطلبات السفر",
                [EDocument.ESIM]: "بطاقة SIM الإلكترونية",
            },
            flights: "الرحلات الجوية",
            flightsAndTransports: "رحلات الطيران والنقل",
            transports: "وسائل النقل",
            accommodations: "أماكن الإقامة",
            chat: "الدردشة",
            more: "المزيد",
            viewAllTrips: "جميع الرحلات",
            overview: "لمحة عامة",
            stay: "الإقامة",
        },
        map: {
            viewMap: "عرض الخريطة",
            viewOverview: "عرض نظرة عامة",
            hidePOIs: "إخفاء النقاط المهمة",
            show_pois: "إظهار النقاط المهمة",
            selectAll: "اختر الكل",
            deselectAll: "إلغاء تحديد الكل",
        },
        chat: { typeYourMessage: "اكتب رسالتك" },
        root: {
            myTrips: "رحلاتي",
            myTripsDescription: "نظرة عامة على جميع رحلاتك المفتوحة في لاتو",
        },
        poi_types: {
            [POIType.ACTIVITY]: "النشاط",
            [POIType.AIRPORT]: "المطار",
            [POIType.BAR]: "شريط",
            [POIType.BEACH]: "الشاطئ",
            [POIType.BRIDGE]: "الجسر",
            [POIType.BUILDING]: "المبنى",
            [POIType.CAMPGROUND]: "أرض التخييم",
            [POIType.CAR_RENTAL]: "تأجير السيارات",
            [POIType.CITY]: "المدينة",
            [POIType.COFFEE_HOUSE]: "بيت القهوة",
            [POIType.CULTURAL_SITE]: "موقع ثقافي",
            [POIType.CYCLING]: "ركوب الدراجات",
            [POIType.ENTERTAINMENT]: "الترفيه",
            [POIType.FERRY]: "عبّارة",
            [POIType.FLAG]: "العلم",
            [POIType.GOLF_RESORT]: "منتجع جولف",
            [POIType.HOTEL]: "الفندق",
            [POIType.MARKET]: "السوق",
            [POIType.MUSEUM]: "المتحف",
            [POIType.NATURE]: "الطبيعة",
            [POIType.NEIGHBORHOOD]: "الحي",
            [POIType.OTHER]: "أخرى",
            [POIType.PARK]: "الحديقة",
            [POIType.RESTAURANT]: "المطعم",
            [POIType.SCENIC_ROUTE]: "طريق ذات مناظر خلابة",
            [POIType.SHOP]: "متجر",
            [POIType.SKI_AREA]: "منطقة التزلج",
            [POIType.SPA]: "المنتجع الصحي",
            [POIType.TOURIST_ATTRACTION]: "مناطق الجذب السياحي",
            [POIType.TRAIN_STATION]: "محطة القطار",
            [POIType.TRAIL]: "درب",
            [POIType.VIEWPOINT]: "وجهة نظر",
            [POIType.WATERFALL]: "الشلال",
            [POIType.PHARMACY]: "الصيدلة",
            [POIType.STREET]: "شارع",
            [POIType.SQUARE]: "ساحة",
            [POIType.RELIGION]: "دين",
            [POIType.ISLAND]: "جزيرة",
            [POIType.ZOO]: "حديقة حيوان",
        },
        boards: {
            [BOARD_TYPES.ALL]: "شامل كل شيء",
            [BOARD_TYPES.BNB]: "مبيت وإفطار (مبيت وإفطار)",
            [BOARD_TYPES.FULL]: "إقامة كاملة (إفطار، وغداء، وعشاء)",
            [BOARD_TYPES.HALF]: "نصف إقامة (إفطار وعشاء)",
            [BOARD_TYPES.ROOM]: "الغرفة فقط",
        },
        rooms: {
            double: "مضاعفة",
            single: "مفردة",
            suite: "جناح",
            triple: "ثلاثي",
        },
    },
    sl: {
        generalErrorMessage: "Nekaj je šlo narobe",
        navbar: { home: "Domov", map: "Zemljevid", details: "Podrobnosti" },
        home: { exploreTheTrip: "Odkrijte potovanje" },
        mail: {
            preview: "Oglejte si osebni predlog potovanja iz {0} od {1}",
            contact: "Obrnite se na svojega potovalnega agenta",
            preview_agent_confirm: "{0} je pravkar potrdil/a svoje potovanje s {1}",
            no_images: "Ni slik",
            click_here: "Kliknite tukaj",
            confirmation_thanks: "Zahvaljujemo se vam za potrditev tega potovanja.Vaš potovalni strokovnjak bo poskrbel za nadaljnjo rezervacijo.",
            booking_request: "Zahteva za rezervacijo",
            booking_confirmation: "Potrditev rezervacije",
            by: "od",
            with: "s",
            edit_trip: "ali uredi potovanje",
            booking_detail: "Podrobnosti rezervacije",
            client_name: "Ime stranke",
            client_details: "Podrobnosti stika",
            client_feedback: "Povratne informacije stranke",
            trip: "Potovanje",
            number_travelers: "Število popotnikov",
            ref: "Sklic",
            total_price: "Skupna cena potovanja",
        },
        details: {
            book: {
                title: "Potrdite potovanje",
                book: "Potrdite",
                description: "Izpolnite spodnje podatke in sporočite potovalnemu strokovnjaku, da vas zanima rezervacija potovanja.",
                description_book_link: "Če želite rezervirati to potovanje, pritisnite spodnji gumb, ki vas bo preusmeril na zunanjo stran vašega potovalnega strokovnjaka.",
                email: "E-poštni naslov",
                comment: "Kako",
                bookedMessage: "Rezervacija je zaključena, uživajte v potovanju.",
                errorMessage: "Prepričajte se, da je bil posredovan veljaven e-poštni naslov.",
                retry: "Poskusite znova",
                terms: "pogoji in določila",
                termsAgree: "Strinjam se s {0}",
                termsAgreed: "S potrditvijo potovanja se strinjate s {0}.",
                availableSpots: "Razpoložljiva mesta",
            },
            downloadPDF: "Prenesi PDF",
            playAudio: "Predvajanje zvoka",
            general: {
                others: "drugi",
                title: "Splošno",
                day: "dan",
                days: "dni",
                hours: "ure",
                mins: "minut",
                secs: "sekunde",
                night: "noč",
                nights: "noči",
                traveller: "popotnik",
                travellers: "potniki",
                period: "obdobje",
                duration: "trajanje",
                group: "skupina",
                departure: "odhod",
                arrival: "prihod",
                pickup: "Prevzemnik",
                dropoff: "dropoff",
                option: "možnost",
                travelAgent: "vaš potovalni agent",
                voucher: "voucher",
            },
            flight: {
                arrivalCity: "Mesto prihoda",
                departureCity: "Mesto odhoda",
                arrivalCountry: "Država odhoda",
                departureCountry: "Država prihoda",
                flight: "Let",
                flightDuration: "Trajanje leta",
            },
            included: "Vključeno",
            notIncluded: "Ni vključeno",
            price: "Cena",
            documents: "Dokumenti",
            downloadAll: "Prenesi vse",
            documentTypes: {
                [EDocument.OTHERDOC]: "drugi",
                [EDocument.TRAVELINFODOC]: "potovalne informacije",
                [EDocument.TRAVELINFOLINK]: "potovalne informacije",
                [EDocument.VOUCHERDOC]: "kupon",
                [EDocument.VOUCHERLINK]: "kupon",
                [EDocument.OTHERLINK]: "drugi",
                [EDocument.PODCAST]: "podcast",
                [EDocument.EBOOK]: "e-knjiga",
                [EDocument.MUSIC]: "glasba",
                [EDocument.VIDEO]: "videoposnetek",
                [EDocument.TRAVELREQDOC]: "potovalne zahteve",
                [EDocument.TRAVELREQLINK]: "potovalne zahteve",
                [EDocument.ESIM]: "elektronska kartica SIM",
            },
            flights: "Poleti",
            flightsAndTransports: "Leti in prevozi",
            transports: "Prevozi",
            accommodations: "nastanitve",
            chat: "Klepet",
            more: "Več",
            viewAllTrips: "Vsa potovanja",
            overview: "Pregled",
            stay: "ostani",
        },
        map: {
            viewMap: "Oglejte si zemljevid",
            viewOverview: "Oglejte si pregled",
            hidePOIs: "Skrivanje točk interesnih točk",
            show_pois: "Prikaži točke zanimanja",
            selectAll: "izberite vse",
            deselectAll: "izberite vse",
        },
        chat: { typeYourMessage: "Vnesite svoje sporočilo" },
        root: {
            myTrips: "Moja potovanja",
            myTripsDescription: "pregled vseh vaših potovanj, odprtih v Latu",
        },
        poi_types: {
            [POIType.ACTIVITY]: "dejavnost",
            [POIType.AIRPORT]: "letališče",
            [POIType.BAR]: "bar",
            [POIType.BEACH]: "plaža",
            [POIType.BRIDGE]: "most",
            [POIType.BUILDING]: "stavba",
            [POIType.CAMPGROUND]: "kamp",
            [POIType.CAR_RENTAL]: "najem avtomobila",
            [POIType.CITY]: "mesto",
            [POIType.COFFEE_HOUSE]: "kavarna",
            [POIType.CULTURAL_SITE]: "kulturni spomenik",
            [POIType.CYCLING]: "kolesarjenje",
            [POIType.ENTERTAINMENT]: "zabava",
            [POIType.FERRY]: "trajekt",
            [POIType.FLAG]: "zastava",
            [POIType.GOLF_RESORT]: "letovišče za golf",
            [POIType.HOTEL]: "hotel",
            [POIType.MARKET]: "trg",
            [POIType.MUSEUM]: "muzej",
            [POIType.NATURE]: "narava",
            [POIType.NEIGHBORHOOD]: "soseska",
            [POIType.OTHER]: "drugi",
            [POIType.PARK]: "park",
            [POIType.RESTAURANT]: "restavracija",
            [POIType.SCENIC_ROUTE]: "razgledna pot",
            [POIType.SHOP]: "trgovina",
            [POIType.SKI_AREA]: "smučišče",
            [POIType.SPA]: "spa",
            [POIType.TOURIST_ATTRACTION]: "turistična atrakcija",
            [POIType.TRAIN_STATION]: "železniška postaja",
            [POIType.TRAIL]: "pot",
            [POIType.VIEWPOINT]: "stališče",
            [POIType.WATERFALL]: "slap",
            [POIType.PHARMACY]: "lekarna",
            [POIType.STREET]: "ulica",
            [POIType.SQUARE]: "trg",
            [POIType.RELIGION]: "religija",
            [POIType.ISLAND]: "otok",
            [POIType.ZOO]: "živalski vrt",
        },
        boards: {
            [BOARD_TYPES.ALL]: "Vse vključeno",
            [BOARD_TYPES.BNB]: "Nočitev z zajtrkom (B&B)",
            [BOARD_TYPES.FULL]: "Polni penzion (zajtrk, kosilo in večerja)",
            [BOARD_TYPES.HALF]: "Polpenzion (zajtrk in večerja)",
            [BOARD_TYPES.ROOM]: "Samo soba",
        },
        rooms: {
            double: "Dvojni",
            single: "Enotni",
            suite: "Suite",
            triple: "Trojni",
        },
    },
};
export const getBoardTranslation = (board, language) => {
    if (board == null || !Object.values(BOARD_TYPES).includes(board.trim()))
        return board;
    return i18n_translations[language === "en" ? "en-gb" : language].boards[board];
};
export const getRoomTranslation = (room, language) => {
    if (room == null || !["double", "single", "suite", "triple"].includes(room))
        return room.charAt(0).toUpperCase() + room.slice(1);
    return i18n_translations[language === "en" ? "en-gb" : language].rooms[room];
};
export const getTravelAppI18N = (langCode) => {
    if (!langCode || !(langCode in LangCodes)) {
        // Return the default language
        return i18n_translations["en-gb"];
    }
    return i18n_translations[langCode];
};
/************************************************************************/
/************** Functions used to export the i18n to CSV ****************/
/************************************************************************/
function pivot(obj) {
    var mp = new Map();
    // Array.from(mp.keys());
    function setValue(a, path, val) {
        if (Object(val) !== val) {
            // primitive value
            var pathStr = path.join(".");
            var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
            a[i] = val;
        }
        else {
            for (var key in val) {
                setValue(a, key == "0" ? path : path.concat(key), val[key]);
            }
        }
        return a;
    }
    var result = Object.entries(obj).map(([k, obj]) => [k, ...setValue([], [], obj)]);
    return [["lang", ...Array.from(mp.keys())], ...result];
}
export function convertToCSV() {
    let obj = i18n_translations;
    let array = pivot(obj);
    // Transpose the 2D array
    array = array[0].map((_, colIndex) => array.map((row) => row[colIndex]));
    console.log();
    return array.map((row) => row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(",")).join("\n");
    // return array.map(it => {
    //   return Object.values(it).toString()
    // }).join('\n')
}
