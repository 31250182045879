import { Badge } from "@chakra-ui/react";
import { TASK_STATUS } from "@lato/common";
import React from "react";
import OptionsDropdown, { OptionsDropdownItem } from "../../components/CRUD/OptionsDropdown";
import { taskStatusColorMap } from "./TasksTable";

interface StatusSelectProps {
  smallTable?: boolean;
  selectedStatus?: TASK_STATUS;
  handleChangeStatus: (status: TASK_STATUS) => void;
}

export const displayTaskStatusOption = (value: string) => {
  return (
    <Badge colorScheme={taskStatusColorMap[value as TASK_STATUS]} title={value}>
      {value}
    </Badge>
  );
};

export const StatusSelect: React.FC<StatusSelectProps> = ({
  smallTable = false,
  selectedStatus,
  handleChangeStatus,
}) => {
  return (
    <OptionsDropdown
      isDisabled={smallTable}
      menuIcon={
        <Badge colorScheme={taskStatusColorMap[selectedStatus || TASK_STATUS.TO_DO]} _hover={{ opacity: 0.8 }}>
          {selectedStatus}
        </Badge>
      }
      label={!smallTable ? "Change status" : ""}
    >
      {Object.values(TASK_STATUS)
        .reverse()
        .map((status) => (
          <OptionsDropdownItem
            key={status}
            icon={displayTaskStatusOption(status)}
            onClick={() => handleChangeStatus(status)}
            _hover={{ cursor: "pointer" }}
          />
        ))}
    </OptionsDropdown>
  );
};
