import { Icon } from "@chakra-ui/icons";
import { Flex, Box } from "@chakra-ui/react";
import { ChevronDown, ChevronRight } from "lucide-react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { ResourceTitle } from "../../../../../CRUD/Resource";
import { RHFEmailInput } from "../../../../../../features/auth/GeneralLoginRegister";
import { RHFWebInput } from "../../../../../../features/contacts/ContactForm";
import PhoneInput from "../../../../../input/PhoneInput";

interface ContactInformationProps {
  first?: boolean;
}

export const CollapsableContactInformation: React.FC<ContactInformationProps> = ({ first = true }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const { setValue } = useFormContext();

  return (
    <>
      <Flex
        width={"100%"}
        gap={2}
        alignItems={"center"}
        onClick={() => setCollapsed(!collapsed)}
        cursor={"pointer"}
        py={2}
      >
        <Icon
          aria-label={collapsed ? "expand" : "collapse"}
          as={collapsed ? ChevronRight : ChevronDown}
          color={"black"}
          boxSize={4}
        ></Icon>
        <ResourceTitle displayName={"Contact information"} inForm>
          {/*<Icon as={icon} mb={"0.3em"} boxSize={7} mr={"0.6em"} />*/}
        </ResourceTitle>
      </Flex>
      {!collapsed && (
        <Box pb={4}>
          <Flex gap={3} flexDirection={"column"}>
            <Flex gap={3} flexWrap={"wrap"}>
              <RHFEmailInput label={"Email"} name={"primaryEmail"} w={"32%"} isDisabled={!first ?? undefined} />
              <PhoneInput
                hidePrefixSelector
                name={"phoneNumber"}
                label={"Phone number"}
                width={"32%"}
                isDisabled={!first ?? undefined}
                onChange={(e) => setValue("phoneNumber", e.target.value, { shouldDirty: true })}
              />

              <RHFWebInput label={"Website"} name={"website"} width={"32%"} isDisabled={!first ?? undefined} />
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};

export const ContactInformation: React.FC<ContactInformationProps> = ({ first = true }) => {
  const { setValue } = useFormContext();

  return (
    <Flex gap={3} flexWrap={"wrap"}>
      <RHFEmailInput label={"Email"} name={"primaryEmail"} w={"32%"} isDisabled={!first ?? undefined} />
      <PhoneInput
        hidePrefixSelector
        name={"phoneNumber"}
        label={"Phone number"}
        width={"32%"}
        isDisabled={!first ?? undefined}
        onChange={(e) => setValue("phoneNumber", e.target.value, { shouldDirty: true })}
      />

      <RHFWebInput label={"Website"} name={"website"} width={"32%"} isDisabled={!first ?? undefined} />
    </Flex>
  );
};
