import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "defaultdocuments";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

const getTripDefaultDocuments = (tripId: string) => {
  return rawApiProvider.get(`${url}/trip/${tripId}`);
};

type DefaultDocumentsAPIType = ReturnEndpoints & {
  getTripDefaultDocuments(tripId: string): Promise<any>;
};
const DefaultDocumentsAPI: DefaultDocumentsAPIType = {
  ...apiEndpoints,
  getTripDefaultDocuments,
};
export default DefaultDocumentsAPI;
