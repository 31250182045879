import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CONTACTTYPE, Contact, TranslationField } from "@lato/common";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BiSolidSend } from "react-icons/bi";
import ContactAPI from "../../../../api/contacts.api";
import { useTripLanguage } from "../../../../stores/trip-language-context";
import { getFromTripStore, useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { addToast } from "../../../../utils/addToast";
import { getAvailableLanguages } from "../../../../utils/getLanguages";
import { useSendTripMail } from "../../../../utils/query-helpers/react-query-mutations";
import { useTripContacts } from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { handleSubmission } from "../../../../utils/toErrorMap";
import { sendTripMailValidationSchema } from "../../../../validation/validationSchemas";
import { DSListItemConfig } from "../../../CustomDownShift/CustomComboBox";
import CustomDownShiftWithPagination from "../../../CustomDownShift/CustomDownShiftWithPagination";
import { SingleContactMail, SingleMail } from "../../../../features/contactGroups/AddContactRelations";
import Form from "../../../form/Form";
import RHFSelect from "../../../input/RHFSelect";
import RHFTranslationInput from "../../../input/RHFTranslationInput";
import CustomModal from "../../../layout/CustomModal";
import SendTripMailPreview from "./SendTripMailPreview";

export function showErrorToast(toast: any, message = "Please make sure all fields are filled in correctly.") {
  addToast(toast, {
    title: `Loading mail preview`,
    description: message,
    status: "error",
  });
}

const SendTripMail: React.FC<SendTripMailModalProps> = (props) => {
  return (
    <>
      {/* <Box pos="relative">
        <Divider borderBottomWidth={2} borderStyle="dashed" my={5} />
        <Text
          color="gray.300"
          textAlign="center"
          pos="absolute"
          left="50%"
          top={0}
          transform="translateY(-50%)"
          bg="white"
          px={2}
        >
          or
        </Text>
      </Box> */}
      <>
        <Button
          colorScheme="brand"
          rightIcon={<Icon as={BiSolidSend} />}
          type={"submit"}
          onClick={props.disclosure.onOpen}
          minW="25%"
          h="inherit"
          ml={3}
          flexShrink={0}
        >
          {window.innerWidth > 600 ? "Send Mail" : "Send"}
        </Button>
        {props.disclosure.isOpen && <SendTripMailModal {...props} />}
      </>
    </>
  );
};

interface SendTripMailModalProps {
  disclosure: any;
  userTripId: string;
  travellerLink: boolean;
}
const SendTripMailModal: React.FC<SendTripMailModalProps> = ({ userTripId, travellerLink, disclosure }) => {
  const { isOpen, onClose } = disclosure;
  const [showInvalidEmailError, setShowInvalidEmailError] = React.useState(false);
  const [showNoEmailError, setShowNoEmailError] = React.useState(false);
  const [previewMode, setPreviewMode] = React.useState(false);
  const [showDescriptionError, setShowDescriptionError] = React.useState(false);
  const [selectedMails, setSelectedMails] = React.useState<{ primaryEmail: string }[]>([]);
  const { firstSelectedLanguageIndex } = useTripLanguage();
  const { isFirst, trip } = useTripFormStore();
  const toast = useToast();

  const { data: tripContacts, isLoading: isLoadingContacts } = useTripContacts(trip?.id ?? "");

  const { mutateAsync: sendTripMail, isPending } = useSendTripMail(userTripId);

  const handleError = (fieldname: string) => {
    if (fieldname === "mailDescriptions.0.content") {
      setShowDescriptionError(true);
    }
  };

  const translations = getFromTripStore("priceDescriptions")?.map((translation: TranslationField) => ({
    content: "",
    language: translation.language,
    creator: translation.creator,
    language_code: translation.language_code,
  }));

  const languages = trip ? getAvailableLanguages(trip) : [];
  const nrOfLanguages = languages.length;
  const defaultLanguage = firstSelectedLanguageIndex
    ? languages[firstSelectedLanguageIndex].code
    : nrOfLanguages > 0
      ? languages[0].code
      : null;

  const formMethods = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      mailDescriptions: translations,
      language_code: defaultLanguage,
    },
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(sendTripMailValidationSchema),
  });

  useEffect(() => {
    const descriptions = formMethods.getValues("mailDescriptions");

    if (translations && descriptions && translations.length !== descriptions.length) {
      formMethods.setValue("mailDescriptions", translations);
    }
    if (translations && formMethods.getValues("mailSubject") === "") {
      formMethods.setValue("mailSubject", trip?.titles?.[firstSelectedLanguageIndex]?.content);
    }
  }, [translations, translations?.length]);

  useEffect(() => {
    setShowNoEmailError(formMethods.formState.isDirty && selectedMails.length === 0);
  }, [selectedMails]);

  const getMailData = () => {
    const mailDescriptions = formMethods.getValues("mailDescriptions");
    let description = mailDescriptions && mailDescriptions[firstSelectedLanguageIndex];
    if (!description || description === "") {
      description = mailDescriptions.find((d: TranslationField) => d.content !== "");
    }
    const subject = formMethods.getValues("mailSubject");
    const email = selectedMails.map((contact: { primaryEmail: string }) => contact.primaryEmail).join(";");
    const language_code = formMethods.getValues("language_code");
    return {
      email,
      subject: subject && subject !== "" ? subject : "Trip",
      language_code,
      description: description.content,
      travellerLink,
    };
  };

  const sendMail = async () => {
    const mailData = getMailData();

    await handleSubmission({
      successMessage: "sent mail",
      failMessage: "sending mail",
      apiCall: sendTripMail(mailData),
      toast,
      setError: handleError,
      successCallback: () => {
        setSelectedMails([]);
        setPreviewMode(false);
        disclosure.onClose();
      },
    });
  };

  const previewButton = () => {
    const { isValid } = formMethods.formState;
    const isDisabled = showInvalidEmailError || showNoEmailError || showDescriptionError || selectedMails.length === 0;

    const handleClick = () => {
      setShowNoEmailError(selectedMails.length === 0);

      if (previewMode) {
        setPreviewMode(false);
      } else if (isValid && !isDisabled) {
        setPreviewMode(true);
      } else {
        showErrorToast(toast);
      }
    };

    return (
      <Button colorScheme="brand" maxW={"6em"} alignSelf={"flex-end"} variant={"outline"} onClick={handleClick}>
        {previewMode ? "Edit" : "Preview"}
      </Button>
    );
  };
  const sendButton = () => (
    <Button
      isLoading={isPending}
      isDisabled={showNoEmailError || showInvalidEmailError || showDescriptionError}
      colorScheme="brand"
      rightIcon={<Icon as={BiSolidSend} />}
      maxW={"6em"}
      alignSelf={"flex-end"}
      type={"submit"}
    >
      Send
    </Button>
  );

  const customListItems: DSListItemConfig<any>[] = React.useMemo(
    () => [
      // {
      //   ListItem: ({ inputValue }) => `Use '${inputValue}'`,
      //   showSelected: ({ inputValue }) => inputValue,
      //   isHidden: ({ inputValue }) => inputValue.length === 0,
      //   onSelected: handleOnClickManual,
      // },
      {
        ListItem: ({ inputValue }) => `Add '${inputValue}'`,
        showSelected: ({ inputValue }) => inputValue,
        isHidden: ({ inputValue }) => inputValue.length <= 1,
        customObject: (inputValue) => ({
          primaryEmail: inputValue,
        }),
        customValidation: (inputValue) => {
          const mailRegex = new RegExp(/^[\w\-.+]+@([\w-]+\.)+[\w-]{2,4}$/gm);
          const isValid = mailRegex.test(inputValue as any);
          // If the input value is not a valid email, return an error message
          return isValid || "Invalid email";
        },
      },
    ],
    [],
  );

  const emailInput = (inModal: boolean) => (
    <div>
      <FormControl isInvalid={showInvalidEmailError || showNoEmailError}>
        <FormLabel>{inModal ? "Email" : "Send the mobile-friendly itinerary via email."}</FormLabel>
        <Flex gap={2}>
          <CustomDownShiftWithPagination
            apiCall={(queryParams?: string) => ContactAPI.getAll(queryParams)}
            priorityItems={
              (tripContacts ?? []).filter(
                (item: Contact) =>
                  item.primaryEmail !== "" && selectedMails.findIndex((m) => m.primaryEmail === item.primaryEmail),
              ) === -1
            }
            queryName="SEARCH_CONTACTS"
            customListItemConfigs={customListItems}
            listItemConfig={{
              ListItem: SingleContactMail,
              onSelected: ({ item }) => {},
            }}
            inputItemConfig={{
              inputItem: SingleMail,
            }}
            additionalQueryState={{
              step: 5,
              page: 1,
              contact_type: CONTACTTYPE.PERSON,
              hasMail: true,
            }}
            placeholder="Add contact..."
            height={"200px"}
            multipleSelection
            selectedItems={selectedMails}
            setSelectedItems={setSelectedMails}
            onBlur={(e) => {
              const value = e.target.value;
              const mailRegex = new RegExp(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,4}$/gm);
              const isValid = mailRegex.test(value);
              if (value && value !== "" && isValid) {
                setSelectedMails([...selectedMails, { primaryEmail: value }]);
                e.target.value = "";
              }
            }}
            useDivider={true}
          />
        </Flex>
        {showInvalidEmailError && <FormErrorMessage mt="-0.09rem">Enter a valid email address</FormErrorMessage>}
        {showNoEmailError && <FormErrorMessage mt="-0.09rem">Enter at least one email address</FormErrorMessage>}
      </FormControl>
    </div>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title="Send trip mail"
      isCentered
      blockScrollOnMount={false}
      size={"4xl"}
      modalContentProps={{ maxH: "90vh" }}
    >
      <Form formMethods={formMethods} onSubmit={sendMail} autoComplete={false}>
        <Flex gap={2} flexDir={"column"} justifyContent={"center"}>
          {previewMode ? (
            <SendTripMailPreview mailData={getMailData()} />
          ) : (
            <>
              {emailInput(false)}
              <Box>
                <Text fontSize="sm" color="realGray.400" fontWeight={"500"}>
                  Email language
                </Text>
                <RHFSelect
                  name="language_code"
                  options={languages.map((l: Language) => ({
                    value: l.code,
                    text: `${l.flag} ${l.name}`,
                  }))}
                />
              </Box>
              <RHFTranslationInput
                first={isFirst}
                collaboratorCanEdit={true}
                showSecondTranslationInput={false}
                label={"Subject"}
                placeholder={"E-mail subject"}
                name={`mailSubject`}
              />
              <RHFTranslationInput
                first={isFirst}
                collaboratorCanEdit={true}
                showSecondTranslationInput={false}
                label={"Description"}
                textarea
                placeholder={"You can optionally include some additional information in the mail."}
                name={"mailDescriptions.0.content"}
              />
            </>
          )}
          <Flex justifyContent={"end"} gap={2}>
            {previewButton()}
            {sendButton()}
          </Flex>

          <FormErrorMessage mt="-0.09rem">Max. 1000 char. allowed</FormErrorMessage>
        </Flex>
      </Form>
    </CustomModal>
  );
};
export default React.memo(SendTripMail);
