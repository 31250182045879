import { HOTEL_BOOKING_STATUS, HOTEL_ORIGIN, User, Hotel } from "@lato/common";
import React from "react";
import HotelsAPI from "../../../api/hotels.api";
import { useMeContext } from "../../../stores/me-context";
import { getEmptyTranslationField } from "../../../pages/create-trip";
import { ACCOMMODATIONS_QUERY_KEY } from "../../../utils/constants";
import ENV from "../../../utils/env";
import LibraryItems from "../../trips/edit/daybyday/library-items/LibraryItems";
import LibraryItemsList from "../../trips/edit/daybyday/library-items/LibraryItemsList";

interface AccommodationsProps {}

const resourceName = "accommodation";

export const getEmptyAccommodation = (me: User): Hotel => ({
  ord: 0,
  name: "",
  location: null,
  descriptions: [getEmptyTranslationField(me)],
  images: [],
  libraryImages: [],
  user: null,
  documents: [],
  rating: 0,
  origin: HOTEL_ORIGIN.LATO,
  primaryEmail: "",
  phoneNumber: "",
  rooms: [],
  board: "",
  originCode: "",
  website: "",
  bookingStatus: HOTEL_BOOKING_STATUS.QUOTE,
  bookingRef: "",
  accommodationType: "Hotel",
  comments: "",
});

const Accommodations: React.FC<AccommodationsProps> = () => {
  const user = useMeContext();

  const localRatingFilter = localStorage.getItem(`filter-Accommodations-${user.id}-${ENV}`);
  const initialRatingFilter =
    localRatingFilter && JSON.parse(localRatingFilter).filters?.find((f: any) => f.id === "rating")?.value;

  return (
    <LibraryItems>
      <LibraryItemsList
        api={HotelsAPI}
        eventType={resourceName}
        emptyResource={getEmptyAccommodation(user)}
        query_key={ACCOMMODATIONS_QUERY_KEY}
        initialFilters={[
          {
            id: "rating",
            value: initialRatingFilter && initialRatingFilter?.length !== 0 ? initialRatingFilter : undefined,
          },
        ]}
      />
    </LibraryItems>
  );
};
export default React.memo(Accommodations);
