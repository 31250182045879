import { Icon, Spinner, useDisclosure } from "@chakra-ui/react";
import { ImageSite, Event } from "@lato/common";
import React from "react";
import { UseFormGetValues, useFormContext } from "react-hook-form";
import { BsSave2 } from "react-icons/bs";
import EventsAPI from "../../../../../../api/events.api";
import HotelsAPI from "../../../../../../api/hotels.api";
import TextfieldsAPI from "../../../../../../api/textfields.api";
import DestinationsAPI from "../../../../../../api/destinations.api";
import { ReturnEndpoints } from "../../../../../../api/utilities/core";
import useMutation from "../../../../../../utils/query-helpers/useMutation";
import { OptionsDropdownItem } from "../../../../../CRUD/OptionsDropdown";
import { copyDocument } from "../../import-from-library/AddFromLibraryCard";
import LibraryItemModal, { LibraryItem, LibraryItemType } from "../LibraryItemModal";
import { queryKeyMap } from "../LibraryItemsTable";

interface SaveToLibraryOptionProps {
  type: LibraryItemType;
  getValues: UseFormGetValues<any>;
}

export const apiMap: { [key in string]: ReturnEndpoints } = {
  ["accommodation"]: HotelsAPI,
  ["activity"]: EventsAPI,
  ["textfield"]: TextfieldsAPI,
  ["destination"]: DestinationsAPI,
};

const SaveToLibraryOption: React.FC<SaveToLibraryOptionProps> = ({ type, getValues }) => {
  const disclosure = useDisclosure();
  const [isFetching, setIsFetching] = React.useState(false);
  let libraryItem = getValues();
  const [storedLibraryItem, setStoredLibraryItem] = React.useState<LibraryItem>(libraryItem);

  const api = apiMap[type];
  const mut = useMutation(
    {
      apiCall: (libraryItem: LibraryItem) => api.post(libraryItem),
      successMessage: `saved ${type}`,
      failMessage: `saving ${type}`,
    },
    queryKeyMap[type],
  );

  const handleOnOpenSaveHotelModal = React.useCallback(async () => {
    if (type !== "textfield") {
      const { id, ...libraryItemWithoutId } = getValues();
      libraryItem = libraryItemWithoutId;
    } else {
      libraryItem = {
        descriptions: getValues(),
      };
    }
    setIsFetching(true);
    // Since we are copying the content of the accommodation in the trip to another accommodation in the library, remove the IDs and copy the images.
    const libraryItemDescriptions = libraryItem.descriptions.map(({ id, ...hd }: any) => hd);
    let libraryItemImages = [];
    if (libraryItem.hasOwnProperty("images")) {
      libraryItemImages = libraryItem.images.map(({ id, ...hd }: any) => hd);
    }
    // Remove the location id
    if (libraryItem.hasOwnProperty("location")) {
      delete libraryItem.location?.id;
    }
    // Copy the images
    const copiedImages = await Promise.all(
      libraryItemImages.map(async (im: any) => {
        // If the image is an S3 image and it is already saved then copy it from S3. If it was not saved yet make sure to create a new temporary URL.
        if (im.site === ImageSite.S3) {
          if (!im.file) {
            return await copyDocument(im, type);
          } else {
            const newFile = new File([im.file], im.file.name, {
              type: im.file.type,
            });
            return { ...im, file: newFile, url: URL.createObjectURL(newFile) };
          }
        }
        return im;
      }),
    );

    const basicFields = {
      ...libraryItem,
      descriptions: libraryItemDescriptions,
    };
    const hotelSpecificFields = {
      images: copiedImages.filter((im) => im !== null),
      rooms: [],
      board: null,
      ord: 0,
    };
    const eventSpecificFields = {
      // Only available for the events and not for the hotels.
      titles: "titles" in libraryItem ? (libraryItem as Event).titles.map(({ id, ...hd }) => hd) : [],
      time: null,
      endTime: null,
      dayIndex: null,
      images: copiedImages,
      ord: 0,
    };

    const destinationSpecificFields = {
      titles: "titles" in libraryItem ? (libraryItem as Event).titles.map(({ id, ...hd }) => hd) : [],
      images: copiedImages.filter((im) => im !== null),
    };

    const fieldMap: { [key in string]: any } = {
      ["activity"]: eventSpecificFields,
      ["accommodation"]: hotelSpecificFields,
      ["textField"]: {},
      ["destination"]: destinationSpecificFields,
    };

    setStoredLibraryItem({ ...basicFields, ...fieldMap[type] });
    disclosure.onOpen();
    setIsFetching(false);
  }, [libraryItem]);

  const handleOnCloseModal = () => {
    // Close the modal
    disclosure.onClose();
    // Revoke all temporary object URLs.
    if (storedLibraryItem.hasOwnProperty("images")) {
      (storedLibraryItem as any).images.forEach((im: any) => {
        if (im.file && im.url) URL.revokeObjectURL(im.url);
      });
    }
  };

  return (
    <>
      <OptionsDropdownItem
        icon={isFetching ? <Spinner boxSize={4} /> : <Icon as={BsSave2} />}
        onClick={handleOnOpenSaveHotelModal}
        name="Save to library"
      />
      <LibraryItemModal
        disclosure={{ ...disclosure, onClose: handleOnCloseModal }}
        mut={mut}
        libraryItem={storedLibraryItem}
        eventType={type}
        type={"save-to-library"}
        forceNew={true}
      />
    </>
  );
};
export default React.memo(SaveToLibraryOption);
