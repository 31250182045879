import { Alert, AlertIcon, Link } from "@chakra-ui/react";
import React from "react";
import Settings from "../../components/settings/Settings";
import PersonalInformation from "../../components/settings/account/PersonalInformation";
import { CompanySettings } from "../../components/settings/company/CompanySettings";
import MemberList from "../../components/settings/company/MemberList";
import { useMeContext } from "../../stores/me-context";

interface accountSettingsProps {}

// const user: User = {
//   first_name: "Wout",
//   last_name: "Klingele",
//   phoneNumber: "",
//   jobDescription: "",
//   id: "ldsmfjqmdslj-qkflsj-sdmlfj-dsqmlfj",
//   avatar:
//     "https://lato-assets.s3-eu-west-1.amazonaws.com/avatars/2021-02-02_cf281883-a653-4030-93b4-f9429e79729e_ldsmfjqmdslj-qkflsj-sdmlfj-dsqmlfj",
// };

// const formatFilename = (filename: string) => {
//   // YYYYMMDD format
//   const date = new Date().toISOString().slice(0, 10);
//   const randomString = Math.random().toString(36).substring(2, 7);
//   const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, "-");
//   const newFilename = `avatars/${date}_${randomString}_${cleanFileName}`;
//   return newFilename.substring(0, 128);
// };

export const AccountSettings: React.FC<accountSettingsProps> = () => {
  const user = useMeContext();

  const personalInfoRef = "personal-information";
  const CompanySettingsRef = "company-settings";
  const BrandingRef = "branding";
  const MemberListRef = "member-list";

  const subNavItems = [
    {
      name: "Personal Information",
      reference: personalInfoRef,
    },
    {
      name: "Company Settings",
      reference: CompanySettingsRef,
    },
    {
      name: "Members",
      reference: MemberListRef,
    },
  ];

  return (
    <Settings subNavItems={subNavItems} tabName={"Account"}>
      <PersonalInformation user={user} id={personalInfoRef} />
      <CompanySettings company={user.company} id={CompanySettingsRef} />
      <MemberList id={MemberListRef} />
    </Settings>
  );
};
export default AccountSettings;
