import * as React from "react";

import { format as _format, differenceInCalendarDays, parse } from "date-fns";
import { enUS } from "date-fns/locale";
import {
  type DateRange,
  type DayClickEventHandler,
  type DayPickerProps,
  type InputHTMLAttributes,
  type MonthChangeEventHandler,
  type SelectRangeModifiers,
} from "react-day-picker";
import { customAddToRange as addToRange } from "../../../utils/addToRange";
import { parseFromToProps } from "../../../utils/parseFromToProps";
import { UseRangeInput } from "../../../types/UseRangeInput";
import { UseRangeInputOptions } from "../../../types/UseRangeInputOptions";

function isValidDate(day: Date): boolean {
  // const time = day.getTime();
  // if (time.)
  return !isNaN(day.getTime());
}

const DefaultFormat = "PP";

export function dateRangeToInputValue(dateRange: DateRange | undefined, format: string, locale: Locale): string {
  const start_date = dateRange?.from;
  const end_date = dateRange?.to;
  if (!dateRange || !start_date) {
    // Neither 'from' nor 'to' is defined
    return "";
  } else {
    if (!end_date) {
      return _format(start_date, format, { locale });
    }
    // Bother 'from' and 'to' should be defined
    return _format(start_date, format, { locale }) + " - " + _format(end_date, format, { locale });
  }
}

function isValidDateRange(dateRange: DateRange) {
  return dateRange.from && dateRange.to && isValidDate(dateRange.from) && isValidDate(dateRange.to);
}

function parseInputValueToRange(
  inputValue: string,
  format: string,
  today: Date,
  locale: Locale,
): DateRange | undefined {
  const [start, end] = inputValue.split(" - ");
  const start_date = parse(start, format, today, { locale });
  const end_date = parse(end, format, today, { locale });
  return start_date && end_date
    ? {
        from: start_date,
        to: end_date,
      }
    : undefined;
}

/** Return props for binding an input field to DayPicker. */
function useRangeInput(options: UseRangeInputOptions): UseRangeInput {
  const {
    locale = enUS,
    format = DefaultFormat,
    defaultSelected,
    today = new Date(),
    min,
    max,
    ...props
    // updateRHFValues = (_: DateRange | undefined) => null,
  } = options;
  const { fromDate, toDate } = parseFromToProps(options);

  // Shortcut to the DateFns functions
  const parseInputToRange = (inputValue: string) => parseInputValueToRange(inputValue, format, today, locale);

  // Initialize states
  const [selectedRange, setSelectedRange] = React.useState<DateRange | undefined>(defaultSelected);
  const { from: selectedFrom, to: selectedTo } = selectedRange || {};
  const [month, setMonth] = React.useState(selectedFrom ?? today);
  const defaultInputValue = dateRangeToInputValue(defaultSelected, format, locale);
  const [inputValue, setInputValue] = React.useState(defaultInputValue);

  React.useEffect(() => {
    // Used for the react-query caching, such that the daterange is updated once the new invalidated trip values were fetched.
    // setselected(defaultSelected);
    // setInputValue(dateRangeToInputValue(defaultSelected, format, locale));
    setSelectedRange(defaultSelected);
    setMonth(defaultSelected?.from ?? today);
    setInputValue(dateRangeToInputValue(defaultSelected, format, locale));
  }, [defaultSelected]);

  const reset = () => {
    setSelectedRange(defaultSelected);
    setMonth(selectedFrom ?? today);
    setInputValue(defaultInputValue ?? "");
    // updateRHFValues(defaultSelected);
  };

  const setSelected = (dateRange: DateRange | undefined) => {
    setSelectedRange(dateRange);
    setMonth(dateRange?.from ?? today);
    setInputValue(dateRangeToInputValue(dateRange, format, locale));
  };

  const handleDayClick: DayClickEventHandler = (day, activeModifiers, e) => {
    const selected = selectedRange;
    const range = addToRange(day, selected);
    // The following if statement will make sure the range does not exceed its bounds.
    if ((min || max) && selected && range?.to && range.from && range.from !== range.to) {
      const diff = Math.abs(differenceInCalendarDays(range?.to, range?.from));
      if ((min && diff < min) || (max && diff >= max)) {
        return;
      }
    }
    console.log(range);
    props.onSelect?.(range, day, activeModifiers, e);
    setSelectedRange(range);
    setInputValue(dateRangeToInputValue(range, format, locale));
    // updateRHFValues(range);
  };

  const handleMonthChange: MonthChangeEventHandler = (month) => {
    setMonth(month);
  };

  // When changing the input field, save its value in state and check if the
  // string is a valid date. If it is a valid day, set it as selected and update
  // the calendar’s month.
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
    const dateRange = parseInputToRange(e.target.value);
    const fromIsBefore = dateRange?.from && fromDate && differenceInCalendarDays(fromDate, dateRange.from) > 0;
    const fromIsAfter = dateRange?.from && toDate && differenceInCalendarDays(dateRange.from, toDate) > 0;
    const toIsBefore = dateRange?.to && fromDate && differenceInCalendarDays(fromDate, dateRange.to) > 0;
    const toIsAfter = dateRange?.to && toDate && differenceInCalendarDays(dateRange.to, toDate) > 0;
    if (
      !dateRange ||
      !dateRange.from ||
      !dateRange.to ||
      !isValidDateRange(dateRange) ||
      fromIsBefore ||
      fromIsAfter ||
      toIsBefore ||
      toIsAfter
    ) {
      // reset();
      setSelectedRange(undefined);
      return;
    }
    if (dateRange.to < dateRange.from) {
      const temp = dateRange.to;
      dateRange.to = dateRange.from;
      dateRange.from = temp;
      setInputValue(dateRangeToInputValue(dateRange, format, locale));
    }
    setSelectedRange(dateRange);
    setMonth(dateRange.from);
    // updateRHFValues(dateRange);
  };

  // Special case for _required_ fields: on blur, if the value of the input is not
  // a valid date, reset the calendar and the input value.
  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    // const dateRange = parseInputToRange(e.target.value);
    if (!selectedRange || !isValidDateRange(selectedRange)) {
      reset();
    }
  };

  // When focusing, make sure DayPicker visualizes the month of the date in the
  // input field.
  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.value) {
      reset();
      return;
    }
    const dateRange = parseInputToRange(e.target.value);
    if (dateRange && dateRange.from && isValidDateRange(dateRange)) {
      setMonth(dateRange.from);
    }
  };

  const modifiers: Omit<SelectRangeModifiers, "disabled"> = {
    range_start: [],
    range_end: [],
    range_middle: [],
  };

  if (selectedFrom) {
    modifiers.range_start = [selectedFrom];
    if (!selectedTo) {
      modifiers.range_end = [selectedFrom];
    } else {
      modifiers.range_end = [selectedTo];
      modifiers.range_middle = [
        {
          after: selectedFrom,
          before: selectedTo,
        },
      ];
    }
  }

  // Doesn't work: when min = 1 -> and we are currently the 15th of the month. Then 15 will be disabled when today is set as default start date.
  // if (min && selectedFrom && selectedTo) {
  //   modifiers.disabled.push((date: Date) => {
  //     if (date.getDate() === 15) {
  //       console.log(date);
  //       console.log(selectedFrom);
  //       console.log(isBefore(date, selectedFrom));
  //       console.log(differenceInCalendarDays(selectedFrom, date) < min);
  //       console.log(selectedTo);
  //       console.log(isAfter(date, selectedTo));
  //       console.log(differenceInCalendarDays(date, selectedFrom) < min);
  //     }
  //     return (
  //       (isBefore(date, selectedFrom) &&
  //         differenceInCalendarDays(selectedFrom, date) < min) ||
  //       (isAfter(date, selectedTo) &&
  //         differenceInCalendarDays(date, selectedFrom) < min)
  //     );
  //   });
  // }

  // if (max && selectedFrom && selectedTo) {
  //   modifiers.disabled.push((date: Date) => {
  //     return (
  //       (isBefore(date, selectedFrom) &&
  //         differenceInCalendarDays(selectedTo, date) >= max) ||
  //       (isAfter(date, selectedTo) &&
  //         differenceInCalendarDays(date, selectedFrom) >= max)
  //     );
  //   });
  // }

  // let footer = <p>Please pick the first day.</p>;
  // if (selectedRange?.from) {
  //   if (!selectedRange.to) {
  //     footer = <p>{_format(selectedRange.from, "PPP")}</p>;
  //   } else if (selectedRange.to) {
  //     footer = (
  //       <p>
  //         {_format(selectedRange.from, "PPP")}–
  //         {_format(selectedRange.to, "PPP")}
  //       </p>
  //     );
  //   }
  // }

  const dayPickerProps: DayPickerProps = {
    ...props,
    mode: "default",
    month: month,
    onDayClick: handleDayClick,
    onMonthChange: handleMonthChange,
    selected: selectedRange,
    locale,
    fromDate: options?.fromDate,
    toDate: options?.toDate,
    today,
    modifiers: modifiers,
    // footer: footer,
  };

  const fieldProps: InputHTMLAttributes = {
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    value: inputValue,
  };

  return { dayPickerProps, fieldProps };
}
export default useRangeInput;
