import { Box, Flex, Heading, Skeleton, Text } from "@chakra-ui/react";
import { TRIP_STATUS, omitLocalTimezone } from "@lato/common";
import React from "react";
import { useTripLanguage } from "../../../stores/trip-language-context";
import { prettyPrintDate } from "../../../utils/date";
import { DaysAndNights } from "../../../components/input/date/DatePicker";
import { LanguageSwitcherAbstract } from "../../language/LanguageSwitcherAbstract";
import { displayBookedOption } from "../../tripList/TripsList";

interface WizardTitleProps {
  title: string;
  client_name: string;
  refvalue?: string;
  status?: TRIP_STATUS;
  hidden?: boolean;
  first: boolean;
  nrOfNights: number;
  start_date?: string;
  end_date?: string;
  isLoadingTranslations?: boolean;
}
export const WizardTitle: React.FC<WizardTitleProps> = ({
  title,
  client_name,
  status,
  first,
  refvalue,
  start_date,
  nrOfNights,
  hidden = false,
  isLoadingTranslations,
}) => {
  return (
    <Box pos="relative" w="100%" maxWidth={"20vw"} textColor={"black"}>
      <Heading
        size="md"
        fontWeight="semibold"
        className="text-wrap"
        isTruncated
        visibility={hidden ? "hidden" : undefined}
      >
        {isLoadingTranslations ? <Skeleton height={"20px"} /> : title}
        {client_name && (
          <Text isTruncated fontSize="sm" fontWeight={400}>
            {client_name}
          </Text>
        )}
        <p className="text-xs">
          {start_date && nrOfNights != null ? (
            <>{prettyPrintDate(omitLocalTimezone(new Date(start_date)), "en", "full")}: </>
          ) : (
            ""
          )}

          <DaysAndNights numberOfNights={nrOfNights} />
        </p>
        {(refvalue || status) && (
          <div className="flex items-center gap-2 text-xs font-normal">
            {/* {refvalue && <p>#{refvalue}</p>} */}
            {/* {refvalue && status && <p>/</p>} */}
            {status && displayBookedOption(status)}
          </div>
        )}
      </Heading>
    </Box>
  );
};

export const LanguageSwitcherAbstractBundle: React.FC<any> = ({ first, name, reset }) => {
  const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();
  const doubleFields = secondSelectedLanguageIndex !== null;
  return (
    <Box>
      <Flex justifyContent={"end"}>
        <Text fontSize="md" mr={1}>
          {!first && "Other's language"}
        </Text>
        <LanguageSwitcherAbstract
          selectedLanguageIndex={firstSelectedLanguageIndex}
          firstInput={true}
          doubleFields={doubleFields}
          isFirst={first}
          name={name}
          reset={reset}
        />
      </Flex>
      {doubleFields && (
        <Flex justifyContent={"end"}>
          <Text fontSize="md" mr={1}>
            Your language
          </Text>
          <LanguageSwitcherAbstract
            selectedLanguageIndex={secondSelectedLanguageIndex}
            firstInput={false}
            doubleFields={doubleFields}
            isFirst={first}
            name={name}
            reset={reset}
          />
        </Flex>
      )}
    </Box>
  );
};
