import { Box, Button, Flex, Image, Text, useRadioGroup, useToast } from "@chakra-ui/react";
import { MAP_TYPE, Company } from "@lato/common";
import React from "react";
import { useController, useForm } from "react-hook-form";
import GoogleImage from "../../assets/images/Google.png";
import MapboxImage from "../../assets/images/MapBox.png";
import OpenStreetMapImage from "../../assets/images/OpenStreetMap.png";
import TomtomImage from "../../assets/images/Tomtom.png";
import Form from "../../components/form/Form";
import Section from "../../components/layout/Section";
import Settings from "../../components/settings/Settings";
import { RadioCard } from "../../components/trips/edit/daybyday/library-items/transportations/RadioCard";
import { useMeContext } from "../../stores/me-context";
import { useMapSettingsMutation } from "../../utils/query-helpers/react-query-mutations";
import { handleSubmission } from "../../utils/toErrorMap";
import useUnsavedChangesPrompt from "../../utils/useUnsavedChangesPrompt";
interface MapSettingsProps {
  id: any;
}

export const mapType_Image: { [key in MAP_TYPE]: string } = {
  [MAP_TYPE.GOOGLE]: GoogleImage,
  [MAP_TYPE.TOMTOM]: TomtomImage,
  [MAP_TYPE.OPENSTREETMAP]: OpenStreetMapImage,
  [MAP_TYPE.MAPBOX]: MapboxImage,
};

const MapSettings: React.FC<MapSettingsProps> = ({ id }) => {
  const user = useMeContext();

  const toast = useToast();
  const company = user?.company;
  const { mutateAsync: saveMapProvider, isPending: isLoadingSave } = useMapSettingsMutation({});

  const formMethods = useForm<Company>({
    mode: "onChange",
    defaultValues: company,
  });

  useUnsavedChangesPrompt(formMethods.formState.isDirty);

  const updateMapProvider = async ({ map_provider }: { map_provider: MAP_TYPE }) => {
    await handleSubmission({
      successMessage: "updated Map provider",
      failMessage: "updating Map provider",
      apiCall: saveMapProvider({ ...company, map_provider }),
      toast,
      setError: formMethods.setError,
    });
    formMethods.reset({ ...company, map_provider });
  };

  return (
    <Settings>
      <Section title="Map Settings">
        <Form formMethods={formMethods} onSubmit={updateMapProvider}>
          <Flex justifyContent={"space-between"}>
            <Text mb={4}>Changing this will change the layout of your maps</Text>
          </Flex>
          <MapSettingsForm />
          <Button
            size={"sm"}
            float={"left"}
            type="submit"
            fontSize={"md"}
            colorScheme="brand"
            isLoading={isLoadingSave}
            mr={2}
          >
            Save
          </Button>
        </Form>
      </Section>
    </Settings>
  );
};

interface MapSettingsFormProps {}

const MapSettingsForm: React.FC<MapSettingsFormProps> = () => {
  const { field: provider } = useController({
    name: "map_provider",
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "map_providerType",
    defaultValue: provider.value,
    onChange: (value) => {
      provider.onChange(value);
    },
  });

  return (
    <Box>
      <Flex flexDirection={"row"} gap={3} alignItems={"center"} justifyContent={"flex-start"} mb={4} flexWrap={"wrap"}>
        {Object.values(MAP_TYPE).map((type) => {
          const radio = getRadioProps({
            value: type,
          });
          return (
            <RadioCard key={type} {...radio} width={{ base: "47%", sm: "48%", md: "49%", lg: "49%" }}>
              <Text fontWeight={"bold"} fontSize={12}>
                {type}
              </Text>
              <Image
                src={mapType_Image[type]}
                borderRadius="5px"
                width="100%"
                objectPosition="30% 80%"
                objectFit="cover"
                position="relative"
                minH={0}
                p={0.5}
              ></Image>
            </RadioCard>
          );
        })}
      </Flex>
    </Box>
  );
};

export default MapSettings;
