import { Box, Button, ButtonGroup, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Country, User } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CountryAPI from "../../api/countries.api";
import UsersAPI from "../../api/users.api";
import { DevTool } from "../../utils/hookform-devtools";
import { useAllLanguages, useLanguages, useParamsQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import { handleSubmission } from "../../utils/toErrorMap";
import { ZohoSalesIQ } from "../../utils/zoho/salesIqName";
import { accountSetupValidationSchema } from "../../validation/validationSchemas";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import Form from "../../components/form/Form";
import InputWrapper from "../../components/input/InputWrapper";
import RHFInput from "../../components/input/RHFInput";
import RHFSelect from "../../components/input/RHFSelect";
import CustomModal from "../../components/layout/CustomModal";
import ErrorCardView from "../../components/layout/ErrorCardView";
import { ME_QUERY_KEY, MY_COMPANY_QUERY_KEY } from "../../utils/constants";

interface AccountSetupModalProps {
  user: User;
}

const AccountSetupModal: React.FC<AccountSetupModalProps> = ({ user }) => {
  const [currentStep, setCurrentStep] = useState(1);

  // get prefered language from browser

  let userLanguage = navigator.language.toLowerCase() || navigator.userLanguage.toLowerCase();

  if (userLanguage !== "en-us" && userLanguage !== "en-gb") {
    userLanguage = userLanguage.split("-")[0];
  }

  const [loadingCountry, setLoadingCountry] = useState(true);

  const {
    data: countries,
    isLoading,
    error,
  } = useParamsQueryHelper<Country[]>({
    queryKey: "countries-setup",
    apiCall: CountryAPI.getAll,
    queryParams: {} as any,
    options: {},
  });

  const settingsStepDone = !!user.name && !!user.company.name;
  const data = user;

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: !settingsStepDone,
  });
  // all translatable languages in Lato
  const { data: allDefaultLanguages, isLoading: isLoadingLanguages, error: errorDefaultLanguages } = useLanguages();
  // all languages
  const { data: allLanguages, isLoading: isloadingAllLanguages, error: errorLanguages } = useAllLanguages();
  const [userCountry, setUserCountry] = useState(null);

  // free api that fetches country out of ip info
  useEffect(() => {
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        const country = data.country;
        setUserCountry(country);
        setLoadingCountry(false);
        formMethods.setValue("iso", country || "US");
      })
      .catch((error) => {
        console.error("Error fetching IP information:", error);
        setLoadingCountry(false);
      });
  }, []);

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: setupAccount } = useMutation({
    mutationFn: UsersAPI.accountSetup,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [MY_COMPANY_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
    },
  });
  const formMethods = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: user.name || "",
      companyName: user.company.name || "",
      languageCode: user.defaultTFLang.code || "en",
      languageCodeUser: userLanguage ? userLanguage : "en",
      iso: userCountry ? userCountry : "US",
    },
    resolver: yupResolver(accountSetupValidationSchema),
  });

  const closeModal = React.useCallback(() => onClose(), [onClose]);

  const handleSubmit = async (values: any) => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      await handleSubmission({
        successMessage: "initialized personal workspace",
        failMessage: "initializing personal workspace",
        apiCall: setupAccount(values),
        toast,
        successCallback: closeModal,
        setError: formMethods.setError,
      });
      //uncomment when produktly is set to release on prod
      // setTimeout(() => {
      //   window.Produktly.startTour({ tourId: 3309 });
      // }, 2000);
    }
  };
  // all languages
  if (isLoadingLanguages || loadingCountry || isloadingAllLanguages) return <FullScreenSpinner />;
  if (!allDefaultLanguages || !allLanguages || errorLanguages || errorDefaultLanguages)
    return <ErrorCardView title="Could not load languages" />;
  if (error || !countries) return <ErrorCardView title="Could not load countries" />;
  // all translatable languages in Lato
  const defaultlanguages = allDefaultLanguages.map((lang) => ({ value: lang.code, text: lang.name }));
  // all languages
  const languages = allLanguages.map((lang) => ({ value: lang.code, text: lang.name }));

  const isExistingLang = languages.some((lang) => lang.value === userLanguage);

  //set language code to "en" if the userLanguage value is not recognisable
  formMethods.setValue("languageCodeUser", isExistingLang ? (userLanguage ? userLanguage : "en") : "en");

  return (
    <CustomModal
      title={`You are almost there`}
      isOpen={isOpen}
      onClose={onClose}
      closable={false}
      size="sm"
      modalContentProps={{ maxH: "100vh" }}
    >
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <DevTool control={formMethods.control} />
        <Stack spacing={3}>
          <Text fontSize="md">This information will help us serve you better.</Text>
          {currentStep === 1 && (
            <>
              <RHFInput
                name="companyName"
                size="md"
                layout="normal"
                label="Company name"
                placeholder="Lato"
                isRequired
                isReadOnly={!!user.company.name}
              />
              <RHFInput
                name="name"
                size="md"
                layout="normal"
                label="Name"
                placeholder="Jane Doe"
                helperText={<>This name will be shown to your clients.</>}
                isRequired
              />
              <ButtonGroup colorScheme="brand" mt={5} justifyContent="flex-end">
                <Button size="md" onClick={() => setCurrentStep(2)} isDisabled={!formMethods.formState.isValid}>
                  Next
                </Button>
              </ButtonGroup>
            </>
          )}
          {currentStep === 2 && (
            <>
              <InputWrapper
                name="address.country"
                label="Country"
                helperText="The country you are working from"
                isRequired
              >
                <RHFSelect
                  colorScheme="brand"
                  name={`iso`}
                  options={
                    countries
                      ? countries.map((country) => ({
                          value: country.iso,
                          text: country.name,
                        }))
                      : [
                          {
                            value: "",
                            text: "",
                          },
                        ]
                  }
                  placeholder="Select country"
                ></RHFSelect>
              </InputWrapper>
              <InputWrapper
                name={"languageCodeUser"}
                label="Primary language"
                helperText="Your primary spoken language"
                isRequired
              >
                <RHFSelect colorScheme="brand" name={`languageCodeUser`} options={languages} isRequired />
              </InputWrapper>
              <InputWrapper
                name={"languageCode"}
                label="Default trip language"
                helperText="The language you primarily want to create trips in"
                isRequired
              >
                <RHFSelect colorScheme="brand" name={`languageCode`} options={defaultlanguages} isRequired />
              </InputWrapper>
              <ButtonGroup colorScheme="brand" mt={5} justifyContent="flex-end">
                <Button
                  size="md"
                  type="submit"
                  isLoading={formMethods.formState.isSubmitting}
                  isDisabled={!formMethods.formState.isValid}
                >
                  Get started
                </Button>
              </ButtonGroup>
            </>
          )}
        </Stack>
      </Form>
    </CustomModal>
  );
};
export default React.memo(AccountSetupModal);
