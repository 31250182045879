import { Link, Text } from "@chakra-ui/react";
import React from "react";
import { Link as ReactLink, useParams } from "react-router-dom";
import AuthAPI from "../api/auth.api";
import { CardWrapper } from "../components/CardWrapper";
import GeneralLoginRegister from "../features/auth/GeneralLoginRegister";
import SplitScreenLayout from "../components/layout/SplitScreenLayout";
import { registerValidationSchema } from "../validation/validationSchemas";
import { LATO_SALES_URL } from "@lato/common";

export const getButtonOrLinkProps = (
  otherRoute: "/login" | "/register",
  toggleRoute?: React.Dispatch<React.SetStateAction<boolean>>,
) =>
  toggleRoute
    ? ({
        onClick: () => toggleRoute((prev) => !prev),
        as: "button",
      } as any)
    : ({
        as: ReactLink,
        to: otherRoute,
      } as any);

type RegisterProps = {
  sameRouteToggle?: React.Dispatch<React.SetStateAction<boolean>>;
  successCallback?: () => void;
};
export const Register: React.FC<RegisterProps> = ({ sameRouteToggle, successCallback }) => {
  const buttonOrLinkProps = getButtonOrLinkProps("/login", sameRouteToggle);

  return (
    <SplitScreenLayout>
      <CardWrapper title="Create a new account" description="Sign up for free. No credit card required.">
        <RegisterForm successCallback={successCallback} />
      </CardWrapper>
      <Text mt={4} color="white" fontSize="md">
        Already have an account?{" "}
        <Link color="brand.400" {...buttonOrLinkProps}>
          Log in
        </Link>
      </Text>
      <Text color="gray.500" fontSize="md" pos="absolute" bottom={4} width="100%" textAlign={"center"}>
        By clicking {`"Create account", you agree to Lato's `}
        <Link color="brand.400" href={LATO_SALES_URL + "/legal/terms"} isExternal>
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link color="brand.400" href={LATO_SALES_URL + "/legal/privacy"} isExternal>
          Privacy Policy
        </Link>
        .
      </Text>
    </SplitScreenLayout>
  );
};
export default Register;

type RegisterFormProps = {
  successCallback?: () => void;
  initializeWithDefaultTrips?: boolean;
};
const handleAdditionalErr = (statusCode: number, setError: any, errors?: any) => {
  setError("url", {
    type: "manual",
    message: "registering account",
  });
  return errors.hasOwnProperty("referredByToken");
};

export const RegisterFormm: React.FC<RegisterFormProps> = ({ successCallback, initializeWithDefaultTrips = true }) => {
  const { token } = useParams<{ token: string }>();

  const isCollaborationInvite = window.location.href.indexOf("collaborate") > -1;

  return (
    <GeneralLoginRegister
      type="register"
      validationSchema={registerValidationSchema}
      successMessage="registered account"
      failMessage="registering account"
      apiCall={AuthAPI.register}
      successCallback={successCallback}
      initializeWithDefaultTrips={initializeWithDefaultTrips}
      referredByToken={token}
      handleAdditionalError={handleAdditionalErr}
      customDescription={"The refertoken in the url is invalid"}
      registerWithoutTrial={isCollaborationInvite}
    />
  );
};

export const RegisterForm = React.memo(RegisterFormm);
