import { Button, Flex, Stack, Text, useToast } from "@chakra-ui/react";
import { Language, TranslationField, Trip, getTripFileName } from "@lato/common";
import React from "react";
import { useForm } from "react-hook-form";
import { BsFileEarmarkPdf, BsFileEarmarkWord } from "react-icons/bs";
import PdfAPI from "../../../api/pdf.api";
import { getAvailableLanguages } from "../../../utils/getLanguages";
import { DevTool } from "../../../utils/hookform-devtools";
import { useTrip } from "../../../utils/query-helpers/reactQueryHooks";
import { handleSubmission } from "../../../utils/toErrorMap";
import { CopyTripType } from "../../CopyTripModal";
import Form from "../../form/Form";
import RHFInput from "../../input/RHFInput";
import RHFSelect from "../../input/RHFSelect";
import CustomModal from "../../layout/CustomModal";

interface CopyTripModalProps {
  userTripid: string;
  modalDisclosure: any;
  defaultLanguageIndex?: number;
}

export type PrintLanguageType = CopyTripType & {
  language_code: string | null;
  userTripId: string;
  transportations: boolean;
  accommodations: boolean;
  activities: boolean;
  images: boolean;
  travelAgent: boolean;
  qrCode: boolean;
};

const CopyTripModal: React.FC<CopyTripModalProps> = ({ ...props }) => {
  const { isOpen, onClose } = props.modalDisclosure;

  return (
    <CustomModal title={`Export to .PDF or .DOCX`} onClose={onClose} isOpen={isOpen} size="sm" colorScheme="brand">
      {isOpen && <PrintLanguageModalContent {...props} />}
    </CustomModal>
  );
};
export default React.memo(CopyTripModal);

const PrintLanguageModalContent: React.FC<CopyTripModalProps> = ({
  userTripid,
  modalDisclosure,
  defaultLanguageIndex,
}) => {
  // Start fetching data
  const {
    data: trip,
    isLoading,
    error,
  } = useTrip(userTripid, {
    staleTime: Infinity,
  });

  if (isLoading) return <div>Loading...</div>;

  const languages = trip ? getAvailableLanguages(trip) : [];
  const nrOfLanguages = languages.length;
  const defaultLanguage = defaultLanguageIndex
    ? languages[defaultLanguageIndex].code
    : nrOfLanguages > 0
      ? languages[0].code
      : null;

  if (error || !defaultLanguage || !trip) {
    modalDisclosure.onClose();
    return null;
  }

  // if (nrOfLanguages === 1) {
  //   // downloadPdf(defaultLanguage);
  //   return null;
  // }

  const { isOpen, onClose } = modalDisclosure;

  return (
    <PrintLanguageForm
      languages={languages}
      defaultLanguage={defaultLanguage}
      modalDisclosure={modalDisclosure}
      userTripId={userTripid}
      trip={trip}
    />
  );
};

type PrintLanguageFormType = {
  trip: Trip;
  languages: Language[];
  defaultLanguage: string;
  userTripId: string;
  modalDisclosure: any;
};

const PrintLanguageForm: React.FC<PrintLanguageFormType> = ({
  defaultLanguage,
  languages,
  userTripId,
  modalDisclosure,
  trip,
}) => {
  const toast = useToast();
  const [isGenerating, setIsGenerating] = React.useState<null | "docx" | "pdf">();

  const formMethods = useForm<PrintLanguageType>({
    shouldUnregister: false,
    defaultValues: {
      language_code: defaultLanguage,
      passengers: true,
      tripdocs: true,
      flights: true,
      tripdays: true,
      priceDetails: true,
      transportations: true,
      accommodations: true,
      activities: true,
      images: true,
      travelAgent: true,
      qrCode: true,
    },
  });

  // const { mutateAsync: downloadPDF, isLoading: isGenerating } = useMutation(
  //   (language_code: string) =>
  //     UserTripsAPI.downloadPDF(userTripid, language_code),
  //   {
  //     onSuccess: () => {
  //       // Better handle this, can't you just add the current message to the cache?
  //       // queryClient.invalidateQueries("userapptrip");
  //       modalDisclosure.onClose();
  //       // window.open(editTripUrl + `/print/${langCode}`, "_blank")?.focus();
  //     },
  //   }
  // );

  // const savePDF = async (language_code: string) => {
  //   const res = await downloadPDF(language_code);
  //   const blob = new Blob([res], { type: "application/pdf" });
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = window.URL.createObjectURL(blob);
  //   downloadLink.download = `${getTripFileName(trip.titles[0].content)}.pdf`;
  //   downloadLink.click();
  //   return res;
  // };

  const downloadFileShort = async (type: "docx" | "pdf") => {
    setIsGenerating(type);
    const language_code = formMethods.getValues("language_code");
    const tripTitle = trip.titles.find((tf: TranslationField) => tf.language.code === language_code)?.content || "trip";
    const clientName = trip.client_name;
    if (language_code) {
      const printLanguageType = {
        language_code: language_code,
        userTripId: userTripId,
        flights: formMethods.getValues("flights"),
        passengers: formMethods.getValues("passengers"),
        priceDetails: formMethods.getValues("priceDetails"),
        tripdays: formMethods.getValues("tripdays"),
        tripdocs: formMethods.getValues("tripdocs"),
        transportations: formMethods.getValues("transportations"),
        accommodations: formMethods.getValues("accommodations"),
        activities: formMethods.getValues("activities"),
        images: formMethods.getValues("images"),
        travelAgent: formMethods.getValues("travelAgent"),
        qrCode: formMethods.getValues("qrCode"),
      } as PrintLanguageType;
      await handleSubmission({
        successMessage: `generated ${type}`,
        failMessage: `generating ${type}`,
        apiCall: downloadFile(type, printLanguageType, tripTitle, clientName),
        toast,
      });
    }
    setIsGenerating(null);
    // Close popup
    modalDisclosure.onClose();
  };

  const printTrip = React.useCallback(
    async (formValues: any) => {
      // downloadFile("pdf", userTripId, formValues.language_code);
      // Download the pdf
      // await handleSubmission({
      //   successMessage: "generated PDF",
      //   failMessage: "generating PDF",
      //   apiCall: savePDF(formValues.language_code),
      //   toast,
      //   // successCallback,
      //   setError: formMethods.setError,
      // });
    },
    [userTripId],
  );

  const { onClose } = modalDisclosure;

  return (
    <Form formMethods={formMethods} onSubmit={printTrip}>
      <DevTool control={formMethods.control} />
      <Text fontSize="sm" color="realGray.600" mt={2}>
        Choose the language to use in the PDF.
      </Text>
      <Stack spacing={1}>
        <RHFSelect
          name="language_code"
          options={languages.map((l: Language) => ({
            value: l.code,
            text: `${l.flag} ${l.name}`,
          }))}
        />
        <Text fontSize="sm" color="realGray.600">
          {`Deselect any items you don't want in the document`}
        </Text>
        <RHFInput type="checkbox" name="tripdays">
          All tripdays
        </RHFInput>
        <RHFInput type="checkbox" name="priceDetails">
          All price details
        </RHFInput>
        <RHFInput type="checkbox" name="passengers">
          All passengers
        </RHFInput>
        <RHFInput type="checkbox" name="flights">
          All flights
        </RHFInput>
        <RHFInput type="checkbox" name="transportations">
          All transportations
        </RHFInput>
        <RHFInput type="checkbox" name="accommodations">
          All accommodations
        </RHFInput>
        <RHFInput type="checkbox" name="activities">
          All activities
        </RHFInput>
        <RHFInput type="checkbox" name="images">
          All images
        </RHFInput>
        <RHFInput type="checkbox" name="tripdocs">
          All documents
        </RHFInput>
        <RHFInput type="checkbox" name="travelAgent">
          Travelagent details
        </RHFInput>
        <RHFInput type="checkbox" name="qrCode">
          QR-code
        </RHFInput>
      </Stack>
      <Flex alignItems="center" justifyContent="center" gap={3} my={3}>
        {/* <Button onClick={onClose} colorScheme="red" variant="outline" mr={2}>
          Cancel
        </Button> */}
        <Button
          type="button"
          colorScheme="blue"
          variant="outline"
          onClick={() => downloadFileShort("docx")}
          isLoading={isGenerating === "docx"}
          leftIcon={<BsFileEarmarkWord />}
        >
          Export to .DOCX
        </Button>
        <Button
          type="button"
          colorScheme="red"
          variant="outline"
          onClick={() => downloadFileShort("pdf")}
          isLoading={isGenerating === "pdf"}
          leftIcon={<BsFileEarmarkPdf />}
        >
          Export to .PDF
        </Button>
      </Flex>
    </Form>
  );
};

export const downloadFile = async (
  type: "docx" | "pdf",
  printLanguageType: PrintLanguageType,
  tripTitle: string,
  clientName: string,
) => {
  // const { data: documentData } = await PdfAPI.getDocumentData(
  //   printLanguageType
  // );
  // const shouldRunOnStaging = ENV !== "development";
  // const res =
  //   type === "pdf"
  //     ? shouldRunOnStaging
  //       ? await PdfAPI.downloadUsertripPDFStaging(documentData)
  //       : await PdfAPI.downloadUsertripPDF(documentData)
  //     : shouldRunOnStaging
  //     ? await PdfAPI.downloadUsertripDocxStaging(documentData)
  //     : await PdfAPI.downloadUsertripDocx(documentData);

  const res =
    type === "pdf"
      ? await PdfAPI.downloadUsertripPDF(printLanguageType)
      : await PdfAPI.downloadUsertripDocx(printLanguageType);

  console.log(res.data);
  // Create blob downloadLink to download
  const url = window.URL.createObjectURL(res.data);
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = getTripFileName(tripTitle, clientName, type);

  // Append to html downloadLink element page
  document.body.appendChild(downloadLink);

  // Start download
  downloadLink.click();

  // Clean up and remove the downloadLink
  // downloadLink.parentNode.removeChild(downloadLink);
};
