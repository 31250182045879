import React, { useEffect, useState } from "react";
import {
  HiOutlineClipboardList,
  HiOutlineCog,
  HiOutlineCurrencyDollar,
  HiOutlineInformationCircle,
  HiOutlineMap,
  HiOutlineShare,
} from "react-icons/hi";
import { IoIosCheckbox, IoIosCheckboxOutline } from "react-icons/io";
import { getRoute, stepNamesNew, stepNamesSamplesNew } from "../../../features/editTrip/wizard/WizardTopBar";
import { useLocation, useParams } from "react-router-dom";
import { useUsertripsCounts } from "../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { useMeContext } from "../../../stores/me-context";
import InfoPanel from "../../../features/editTrip/wizard/InfoPanel";

interface TripNavProps {
  children: React.ReactNode;
}

export const TripNav: React.FC<TripNavProps> = ({ children }) => {
  const location = useLocation();
  const me = useMeContext();

  const { id: userTripId, dayIndex } = useParams();
  const isSample = location.pathname.split("/")[1] === "samples";
  const [activeStep, setActiveStep] = useState(
    (isSample ? stepNamesSamplesNew : stepNamesNew).indexOf(location.pathname.split("/")[3]),
  );

  console.log("activeStep", isSample, activeStep);

  const { data: counts } = useUsertripsCounts(userTripId!);

  useEffect(() => {
    setActiveStep((isSample ? stepNamesSamplesNew : stepNamesNew).indexOf(location.pathname.split("/")[3]));
  }, [location]);

  const primaryTripSteps = [
    {
      text: "General",
      icon: <HiOutlineInformationCircle size="18px" />,
      selectedIcon: <HiOutlineInformationCircle size="18px" />,
      route: getRoute(userTripId!, 0, me, Number(dayIndex), isSample),
    },
    {
      text: "Overview",
      icon: <HiOutlineMap size="18px" />,
      selectedIcon: <HiOutlineMap size="18px" />,
      route: getRoute(userTripId!, 1, me, Number(dayIndex), isSample),
    },
    {
      text: "Days",
      icon: <HiOutlineClipboardList size="18px" />,
      selectedIcon: <HiOutlineClipboardList size="18px" />,
      route: getRoute(userTripId!, 2, me, Number(dayIndex), isSample),
    },
    {
      text: "Price",
      icon: <HiOutlineCurrencyDollar size="18px" />,
      selectedIcon: <HiOutlineCurrencyDollar size="18px" />,
      route: getRoute(userTripId!, 3, me, Number(dayIndex), isSample),
    },
  ];

  const secondaryTripSteps = [
    {
      text: "Tasks",
      icon: <IoIosCheckboxOutline size="18px" />,
      selectedIcon: <IoIosCheckbox size="18px" />,
      route: getRoute(userTripId!, 4, me, Number(dayIndex), isSample),
      count: counts?.tasks,
    },
    ...(isSample
      ? []
      : [
          {
            text: "Notes",
            icon: <HiOutlineClipboardList size="18px" />,
            selectedIcon: <HiOutlineClipboardList size="18px" />,
            route: getRoute(userTripId!, 5, me, Number(dayIndex), isSample),
            count: counts?.notes,
          },
        ]),
    {
      text: "Share",
      icon: <HiOutlineShare size="18px" />,
      selectedIcon: <HiOutlineShare size="18px" />,
      route: getRoute(userTripId!, isSample ? 5 : 6, me, Number(dayIndex), isSample),
    },
    {
      text: "Settings",
      icon: <HiOutlineCog size="18px" />,
      selectedIcon: <HiOutlineCog size="18px" />,
      route: getRoute(userTripId!, isSample ? 6 : 7, me, Number(dayIndex), isSample),
    },
  ];

  return (
    <div className="flex">
      <InfoPanel primaryTripSteps={primaryTripSteps} secondaryTripSteps={secondaryTripSteps} activeStep={activeStep} />
      <div className="ml-48 w-[calc(100%-12rem)]">{children}</div>
    </div>
  );
};

export default TripNav;
