import { CalendarIcon } from "@chakra-ui/icons";
import { Flex, Icon, Spinner, useToast } from "@chakra-ui/react";
import {
  APICALL,
  Contact,
  DEFAULT_MAX_KM_DISTANCE,
  Destination,
  Event,
  HOTEL_ORIGIN,
  Hotel,
  TLocation,
  TranslationField,
} from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { default as React } from "react";
import { UseFormReturn, useController, useFormContext } from "react-hook-form";
import { BiMoney } from "react-icons/bi";
import { LiaBedSolid, LiaFontAwesomeFlag, LiaStar } from "react-icons/lia";
import { RiPinDistanceLine } from "react-icons/ri";
import { TbWorld } from "react-icons/tb";
import { useAsyncDebounce } from "react-table";
import { trpc } from "../../../../../../trpc";
import DestinationsAPI from "../../../../../api/destinations.api";
import EventsAPI from "../../../../../api/events.api";
import HotelsAPI from "../../../../../api/hotels.api";
import { POI } from "../../../../../api/pois.api";
import MagicIcon from "../../../../../assets/icons/MagicIcon";
import { useMeContext } from "../../../../../stores/me-context";
import { useTripLanguage } from "../../../../../stores/trip-language-context";
import { getEmptyTranslationField } from "../../../../../pages/create-trip";
import { useTripFormStore } from "../../../../../stores/trip/tripFormStore";
import { addToast } from "../../../../../utils/addToast";
import {
  ACCOMMODATIONS_DOWNSHIFT_QUERY_KEY,
  ACTIVITIES_DOWNSHIFT_QUERY_KEY,
  DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  DESTINATIONS_QUERY_KEY,
} from "../../../../../utils/constants";
import { useChatGPTPrompt } from "../../../../../utils/query-helpers/react-query-mutations";
import { useTrpcMutation } from "../../../../../utils/query-helpers/reactQueryHooks";
import {
  updateRelationMap,
  usePatchSplitTripday,
  useTripTranslations,
} from "../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import useMutation from "../../../../../utils/query-helpers/useMutation";
import { handleSubmission } from "../../../../../utils/toErrorMap";
import { DSAccessorProps, DSListItemConfig, FILTER_TYPE } from "../../../../CustomDownShift/CustomComboBox";
import CustomDownShiftWithPagination from "../../../../CustomDownShift/CustomDownShiftWithPagination";
import RHFInput from "../../../../input/RHFInput";
import RHFTranslationInput from "../../../../input/RHFTranslationInput";
import { writeMoreAboutAIPrompt } from "../../../../input/wysiwyg/AIDropdown";
import { getWikiContentByLocation } from "../../../../../api/wikivoyage.api";
import { addLibraryItem } from "../import-from-library/AddFromLibraryCard";
import { SingleAccommodation } from "./SingleAccommodation";
import { SingleActivity } from "./SingleActivity";
import { afterChooseElementPictures } from "./activities/afterChooseElementPictures";
import { disableBedBanks } from "./hotel/HotelForm";

export enum LIBRARYITEM_TYPE {
  EVENTS = "events",
  HOTELS = "hotels",
  POIS = "pois",
  DESTINATION = "destination",
}
export interface LibraryItemInTripProps {
  location: TLocation | null;
  tripdayIndex?: number;
  tripdayId?: string;
  libraryItemIndex?: number;
  first: boolean;
  inTrip?: boolean;
  uniqueId?: any;
  collaboratorCanEdit: boolean;
  tripdayStartDate?: Date;
  tripdayEndDate?: Date;
  holder?: Contact;
  outsideTripLibraryitem?: any;
  isPoi?: boolean;
  libraryItem?: any;
  libraryItemName?: LIBRARYITEM_TYPE;
  POILocationName?: string;
  adults?: number;
  children?: any;
  placeholder?: string;
  formId?: string;
  initialInputValue?: string;
  width?: string;
}

interface LibraryItemDownshiftProps extends LibraryItemInTripProps {
  getValues: UseFormReturn<any>["getValues"];
  reset?: any;
}

interface LibraryItemConfig {
  api: any;
  trpc?: any;
  queryKey: string;
  tfFieldName?: any;
  listItemConfig: DSListItemConfig<any>;
  InputEl: { Component: any; props: any };
}

const LibraryItemDownshift: React.FC<LibraryItemDownshiftProps> = React.memo(({ ...props }) => {
  const { realLanguageIndex } = useTripLanguage();

  // // If the secondSelectedLanguageIndex is null, the first selected language index should be used, otherwise the second should be used.
  // const languageIndex =
  //   secondSelectedLanguageIndex !== null
  //     ? secondSelectedLanguageIndex
  //     : firstSelectedLanguageIndex;
  // const showType = getShowType(
  //   secondSelectedLanguageIndex !== null,
  //   props.first
  // );

  const libraryItem: Hotel | Event | POI | Destination = props.libraryItem ?? props.outsideTripLibraryitem;

  // const libraryItem: Event = {
  //   id: "",
  //   ord: 0,
  //   descriptions: [],
  //   images: [],
  //   location: null,
  //   user: null,
  //   name: "",
  //   titles: [],
  //   dayIndex: null,
  //   time: null,
  // };

  // // Calculate the real selected language index
  // const realLanguageIndex = calculateRealLanguageIndex(
  //   libraryItem.descriptions,
  //   showType,
  //   languageIndex,
  //   props.me
  // );
  const titlesTranslationField = `titles.${realLanguageIndex}.content` as any;
  const nameFields = `name` as any;
  const namesTranslationField = `names.${realLanguageIndex}.content` as any;

  const defaultNameMap: { [key in LIBRARYITEM_TYPE]: any } = {
    [LIBRARYITEM_TYPE.POIS]: namesTranslationField,
    [LIBRARYITEM_TYPE.HOTELS]: nameFields,
    [LIBRARYITEM_TYPE.EVENTS]: titlesTranslationField,
    [LIBRARYITEM_TYPE.DESTINATION]: titlesTranslationField,
  };

  const fieldName = defaultNameMap[props?.libraryItemName ?? LIBRARYITEM_TYPE.EVENTS];

  const [initialInput, setInitialInput] = React.useState(props.getValues(fieldName));

  return (
    <ActualLibraryItemDownshift
      {...props}
      initialInputValue={initialInput ?? ""}
      fieldName={fieldName}
      realLanguageIndex={realLanguageIndex}
      libraryItem={libraryItem}
      libraryItemName={props.libraryItemName ?? LIBRARYITEM_TYPE.EVENTS}
    />
  );
});

interface ActualLibraryItemDownshiftProps extends LibraryItemDownshiftProps {
  initialInputValue: string;
  fieldName: string;
  realLanguageIndex: number;
  libraryItem: Hotel | Event | POI | Destination;
  libraryItemName: LIBRARYITEM_TYPE;
}
const ActualLibraryItemDownshift: React.FC<ActualLibraryItemDownshiftProps> = ({
  tripdayIndex = 0,
  tripdayId,
  inTrip,
  isPoi,
  libraryItemIndex,
  location,
  first,
  initialInputValue,
  fieldName,
  realLanguageIndex,
  libraryItem,
  libraryItemName,
  collaboratorCanEdit,
  POILocationName,
  getValues,
  reset,
  placeholder,
  tripdayStartDate,
  tripdayEndDate,
  adults,
  children,
  formId,
  width = "100%",
}) => {
  const me = useMeContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { reset: resetForm, setValue } = useFormContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [coordinates, setCoordinates] = React.useState(
    location && location.coordinates ? [location.coordinates[0] ?? 0, location.coordinates[1] ?? 0] : undefined,
  );
  const [rating, setRating] = React.useState([0, 5]);
  const [priceRange, setPriceRange] = React.useState([0, 2000]);
  const [maxDistance, setMaxDistance] = React.useState(DEFAULT_MAX_KM_DISTANCE);
  const [origin, setOrigin] = React.useState([HOTEL_ORIGIN.LATO]);
  const [availabilityDateRange, setAvailabilityDateRange] = React.useState<{
    from: Date | undefined;
    to: Date | undefined;
  }>({
    from: tripdayStartDate,
    to: tripdayEndDate,
  });

  console.log("maxDistance", maxDistance);

  const { mutateAsync: askChatGPTQuestion } = useChatGPTPrompt();

  const { trip, setIsSubmittingForms, setInTripStore } = useTripFormStore();

  const { data: tripTranslations } = useTripTranslations(trip?.id ?? "");

  const { mutateAsync: updateTripday, isPending: isUpdatingTripday } = usePatchSplitTripday(
    tripdayId ?? "",
    updateRelationMap[DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY],
    queryClient,
    DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  );

  const tripdayLibraryImage = inTrip && trip.tripdays ? trip.tripdays[tripdayIndex]?.libraryImage : true;
  const tripdayImage = inTrip && trip.tripdays ? trip.tripdays[tripdayIndex]?.image : true;

  const selectedLanguageCode = trip?.priceDescriptions && trip?.priceDescriptions?.[realLanguageIndex]?.language_code;

  const resetLibraryItem = async (values: any) => {
    //const eventType = "titles" in values ? "events" : "hotels";

    const id = getValues("id");
    await reset({ id, ...values, ord: (libraryItem as any)?.ord ?? 0 });
    resetForm(
      { ...values, updated_at: new Date(), ord: (libraryItem as any).ord ?? 0 },
      { keepDirty: true, keepValues: true },
    );

    /*if (inTrip) {
      setInTripStore(`tripdays.${tripdayIndex}.${eventType}.${libraryItemIndex}`, values);
    }*/

    /*inTrip
      ? 
      : setValue(`descriptions.${realLanguageIndex}.content`, values.descriptions[0].content);*/
  };

  const { mutateAsync: getHotelbedHotel } = useTrpcMutation(trpc.hotelbeds.getHotelbedHotel);
  const { mutateAsync: getRatehawkHotel } = useTrpcMutation(trpc.ratehawk.getRatehawkHotel);
  const { mutateAsync: getYalagoHotel } = useTrpcMutation(trpc.yalago.getYalagoHotel);

  const hotelMutations: { [key in HOTEL_ORIGIN]: any } = {
    [HOTEL_ORIGIN.LATO]: undefined,
    [HOTEL_ORIGIN.HOTELBEDS]: getHotelbedHotel,
    [HOTEL_ORIGIN.RATEHAWK]: getRatehawkHotel,
    [HOTEL_ORIGIN.YALAGO]: getYalagoHotel,
    [HOTEL_ORIGIN.MAPPED]: undefined,
  };

  const addNewLibraryItem = async (newLibraryItem: any) => {
    const globalLibraryItemOrd = (libraryItem as any)?.ord ?? 0;
    await addLibraryItem(
      newLibraryItem,
      me,
      tripTranslations!,
      resetLibraryItem,
      tripdayIndex,
      libraryItemIndex ?? 0,
      globalLibraryItemOrd,
    );
  };

  const handleChooseLibraryItem = async ({ item }: DSAccessorProps<any>) => {
    setIsLoading(true);
    try {
      let newLibraryItem: any = undefined;
      if (item.origin && item.origin !== HOTEL_ORIGIN.LATO) {
        const { originCode, origin } = item;
        const getHotel = hotelMutations[origin as HOTEL_ORIGIN];
        newLibraryItem = await getHotel({ code: originCode });
      } else {
        const { id: selectedItemId } = item;
        newLibraryItem = await getLibraryItem(selectedItemId);
      }
      addNewLibraryItem(newLibraryItem);

      if (!tripdayImage && !tripdayLibraryImage) {
        const allImages = [
          ...(newLibraryItem?.images || []).map((im: any) => ({ ...im, library: false })),
          ...(newLibraryItem?.libraryImages || []).map((im: any) => ({ ...im, library: true })),
        ].sort((a, b) => a.ord - b.ord);
        const firstImageLibrary = allImages.length > 0 && allImages[0].hasOwnProperty("name");
        // If no main tripday image is present yet, add one if the library item contains an image.
        afterChooseElementPictures(
          allImages,
          tripdayId,
          updateTripday,
          firstImageLibrary,
          tripdayIndex,
          setInTripStore,
          inTrip && trip.tripdays ? trip.tripdays[tripdayIndex]?.image : undefined,
        );
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const accomodationConfig = {
    api: HotelsAPI,
    queryKey: ACCOMMODATIONS_DOWNSHIFT_QUERY_KEY,
    listItemConfig: {
      ListItem: ({ item }: any) => SingleAccommodation({ item, width: "100%", showLanguage: true }),
      onSelected: handleChooseLibraryItem,
    },
    // Currently disabled for collaborators because using this input field they are able to choose another element which seems wrong.
    InputEl: {
      Component: RHFInput,
      props: { isDisabled: !first, name: "name" },
    },
  };
  const POIconfig = {
    api: EventsAPI,
    queryKey: ACTIVITIES_DOWNSHIFT_QUERY_KEY,
    listItemConfig: {
      ListItem: ({ item }: any) => item.names && item.names[0].content,
      // TODO: update 0 to the actual selected language?
      // showSelected: ({ item }) => item.titles[realLanguageIndex].content,
      onSelected: handleChooseLibraryItem,
    },
    InputEl: {
      Component: RHFTranslationInput,
      props: {
        first: first,
        collaboratorCanEdit: collaboratorCanEdit,
        name: `names.0.content` as any,
      },
    },
  };

  const DestinationConfig = {
    api: DestinationsAPI,
    queryKey: DESTINATIONS_QUERY_KEY,
    listItemConfig: {
      ListItem: ({ item }: any) => item.titles && item.titles[0].content,
      onSelected: handleChooseLibraryItem,
    },
    InputEl: {
      Component: RHFTranslationInput,
      props: {
        first: first,
        collaboratorCanEdit: collaboratorCanEdit,
        name: `titles.0.content` as any,
      },
    },
  };

  const activityConfig = {
    api: EventsAPI,
    queryKey: ACTIVITIES_DOWNSHIFT_QUERY_KEY,
    listItemConfig: {
      // This should be 0, because the dropdown items should always show the first title.
      // And which title to show in the dropdown has nothing to do with the selected index.
      ListItem: ({ item }: any) => SingleActivity({ item, width: "100%" }),
      // TODO: update 0 to the actual selected language?
      // showSelected: ({ item }) => item.titles[realLanguageIndex].content,
      onSelected: handleChooseLibraryItem,
    },
    InputEl: {
      Component: RHFTranslationInput,
      props: {
        first: first,
        collaboratorCanEdit: collaboratorCanEdit,
        name: `titles.0.content` as any,
        firstInputProps: { variant: "inline", label: undefined },
        isInline: true,
      },
    },
  };

  const configMap: { [key in LIBRARYITEM_TYPE]: any } = {
    [LIBRARYITEM_TYPE.POIS]: POIconfig,
    [LIBRARYITEM_TYPE.HOTELS]: accomodationConfig,
    [LIBRARYITEM_TYPE.EVENTS]: activityConfig,
    [LIBRARYITEM_TYPE.DESTINATION]: DestinationConfig,
  };

  const config: LibraryItemConfig = configMap[libraryItemName];

  const { mutate: getLibraryItem, rqMutation } = useMutation({
    apiCall: (id: string) => config.api.getSingle(id),
    failMessage: `loading libraryItem information`,
  });

  // We could also use the register function instead of the useController: https://codesandbox.io/s/competent-einstein-vf7wrr
  const { field: downshiftField } = useController<any>({
    name: fieldName,
  });
  // const { ...rhfProps } = register(config.fieldName);

  // const initialFieldValue = getValues(fieldName as any) || "";
  const locationName = location?.name || "";

  const handleOnClickManual = ({ inputValue }: DSAccessorProps<any>) => {
    // Change the value of the controller field.
    downshiftField.onChange(inputValue);
  };

  const handleOnClickWikiListItem = async ({ inputValue }: DSAccessorProps<any>) => {
    // Change the value of the controller field.
    downshiftField.onChange(inputValue);

    const emptyTranslationField = getEmptyTranslationField(me);

    const selectedLanguageCode = libraryItem.descriptions[realLanguageIndex].language_code;

    setIsLoading(true);
    formId && setIsSubmittingForms(formId, true);
    const locationContent = await getWikiContentByLocation(
      inputValue,
      true,
      trip.userTrips[trip.userTrips.length - 1].brand.content_ai_preference_sentence,
      selectedLanguageCode,
    );

    if (locationContent) {
      const destination = getValues();

      const newDescriptions = (destination.descriptions ?? []).map((description: TranslationField, i: number) => ({
        ...emptyTranslationField,
        ...description,
        content: i === realLanguageIndex ? locationContent.description : description.content,
      }));

      const newDestination = {
        ...destination,
        descriptions: newDescriptions,
        titles: (destination.titles ?? []).map((title: TranslationField) => ({
          ...emptyTranslationField,
          ...title,
        })),
        updated_at: new Date(),
        images: locationContent.images,
      };

      resetLibraryItem(newDestination);
    } else {
      addToast(toast, {
        status: "error",
        title: `No content found for ${inputValue}`,
      });
    }

    setIsLoading(false);
    formId && setIsSubmittingForms(formId, false);
  };

  // TODO: update this function
  const handleOnClickChatGPTListitem = async ({ inputValue }: DSAccessorProps<any>) => {
    // Change the value of the controller field.
    downshiftField.onChange(inputValue);

    const brandAiVoicePrompt = trip?.userTrips
      ? trip.userTrips[trip.userTrips.length - 1].brand.content_ai_preference_sentence
      : "";

    libraryItem = getValues();

    setIsLoading(true);
    formId && setIsSubmittingForms(formId, true);

    const selectedLanguageCode = libraryItem.descriptions[realLanguageIndex].language.code;
    const locationSubQuery =
      inTrip && locationName
        ? " in " + locationName
        : POILocationName
          ? " in " + POILocationName
          : getValues().location?.name
            ? " in " + getValues().location.name
            : "";

    // const prompt = `Describe the given text in a couple of sentences in ${selectedLanguageCode}.`;
    const prompt = writeMoreAboutAIPrompt;
    // `Translate the text to ${selectedLanguageCode}.` +
    // (brandAiVoicePrompt && brandAiVoicePrompt != "" ? ` ${brandAiVoicePrompt}` : "") +
    // "Only use full sentences.";
    // const prompt = `Geef mij een hotel in het centrum van gent met een zwembad en een goed restaurant. Beschrijf mij in een aantal zinnen.`;
    const res = await handleSubmission({
      successMessage: `received response from ChatGPT`,
      failMessage: `getting data from ChatGPT`,
      apiCall: askChatGPTQuestion({
        prompt,
        text: `${inputValue}${locationSubQuery}`,
        languageCode: selectedLanguageCode,
        brandVoice: brandAiVoicePrompt,
      }),
      toast,
    });

    if (res?.response) {
      // await sleep(3);
      // const response = `<p>qsdfjmdsj-${new Date().toDateString()}</p>`;
      const newDescriptions = [...libraryItem.descriptions];
      newDescriptions[realLanguageIndex] = {
        ...newDescriptions[realLanguageIndex],
        content: res.response,
      };
      const basicFields = {
        ...libraryItem,
        descriptions: newDescriptions,
        updated_at: new Date(),
        libraryImages: libraryItem.libraryImages,
      };

      const createNewEvent = () => {
        const newTitles = [...(libraryItem as Event)?.titles];
        newTitles[realLanguageIndex] = {
          ...newTitles[realLanguageIndex],
          content: inputValue,
        };
        const newEvent = {
          ...basicFields,
          titles: newTitles,
        };
        return newEvent;
      };

      const createNewHotel = () => {
        const newHotel = {
          ...basicFields,
          name: inputValue,
        };

        return newHotel;
      };

      const createNewPoi = () => {
        const newNames = [...(libraryItem as POI)?.names];
        newNames[realLanguageIndex] = {
          ...newNames[realLanguageIndex],
          content: inputValue,
        };

        const newPoi = {
          ...basicFields,
          names: newNames,
        };

        return newPoi;
      };

      const createNewDestination = () => {
        const newTitles = [...(libraryItem as Event)?.titles];
        newTitles[realLanguageIndex] = {
          ...newTitles[realLanguageIndex],
          content: inputValue,
        };
        const newDestination = {
          ...basicFields,
          titles: newTitles,
        };
        return newDestination;
      };

      const newItemMap: { [key in LIBRARYITEM_TYPE]: any } = {
        [LIBRARYITEM_TYPE.POIS]: createNewPoi,
        [LIBRARYITEM_TYPE.HOTELS]: createNewHotel,
        [LIBRARYITEM_TYPE.EVENTS]: createNewEvent,
        [LIBRARYITEM_TYPE.DESTINATION]: createNewDestination,
      };
      const newItem = newItemMap[libraryItemName];

      resetLibraryItem(newItem());
      formId && setIsSubmittingForms(formId, false);

      setIsLoading(false);
    }
  };

  const chatGptOption = {
    ListItem: ({ inputValue }: any) => (
      <Flex alignItems={"center"} gap="2">
        <Icon as={MagicIcon} boxSize={5} color="brand.500" />
        Ask AI to generate content for &apos;{inputValue}&apos;
      </Flex>
    ),
    showSelected: ({ inputValue }: any) => inputValue,
    isHidden: ({ inputValue }: any) => inputValue.length <= 3,
    onSelected: handleOnClickChatGPTListitem,
  };

  const wikivoyageOption = {
    ListItem: ({ inputValue }: any) => (
      <Flex alignItems={"center"} gap="2">
        <Icon as={TbWorld} boxSize={5} color="brand.500" />
        Get content for &apos;{inputValue}&apos;
      </Flex>
    ),
    showSelected: ({ inputValue }: any) => inputValue,
    isHidden: ({ inputValue }: any) => inputValue.length <= 3,
    onSelected: handleOnClickWikiListItem,
  };

  const customListItems: DSListItemConfig<any>[] = React.useMemo(
    () => (libraryItemName === LIBRARYITEM_TYPE.DESTINATION ? [wikivoyageOption, chatGptOption] : [chatGptOption]),
    [handleOnClickManual, handleOnClickChatGPTListitem],
  );

  const generalInputProps = {
    key: fieldName,
    leftIcon: isLoading ? <Spinner color="gray.300" pointerEvents={"none"} /> : undefined,
    // label: "Name",
    placeholder,
    size: "md",
    // variant="noBorders"
    h: "unset",
    fontSize: "25px",
    ...config.InputEl.props,
  };

  // No downshift for the collaborators in activities
  const inputsOnly = libraryItemName !== LIBRARYITEM_TYPE.HOTELS && !first;

  const bedbankApiMap: { [key in HOTEL_ORIGIN]: any } = {
    [HOTEL_ORIGIN.HOTELBEDS]:
      getValues(fieldName) === "" ? trpc.hotelbeds.hotelbedsListAvailability : trpc.hotelbeds.getHotelbedHotels,
    [HOTEL_ORIGIN.RATEHAWK]: trpc.ratehawk.getRatehawkHotels,
    [HOTEL_ORIGIN.YALAGO]: trpc.yalago.getYalagoHotels,
    [HOTEL_ORIGIN.LATO]: undefined,
    [HOTEL_ORIGIN.MAPPED]: undefined,
  };

  const hotelApi = [
    {
      call: origin.length > 1 ? trpc.externalHotels.getExternalHotels : bedbankApiMap[origin[0]],
      type: APICALL.TRPC,
      name: "Mapped",
    },
  ];

  const eventApi = [
    {
      call: config.api.getAll,
      type: APICALL.REST,
      name: "Excursions",
    },
  ];

  const destinationApi = [
    {
      call: config.api.getAll,
      type: APICALL.REST,
      name: "Destinations",
    },
  ];

  const ApiMap: { [key in LIBRARYITEM_TYPE]: any } = {
    [LIBRARYITEM_TYPE.HOTELS]: hotelApi,
    [LIBRARYITEM_TYPE.EVENTS]: eventApi,
    [LIBRARYITEM_TYPE.POIS]: [],
    [LIBRARYITEM_TYPE.DESTINATION]: destinationApi,
  };

  const originFilter = {
    onChange: (e: any) => setOrigin([e]),
    icon: LiaFontAwesomeFlag,
    currentValue: origin,
    name: "origin",
    type: FILTER_TYPE.SELECT,
    options: [
      { text: "Lato Library", value: HOTEL_ORIGIN.LATO },
      { text: "Hotelbeds", value: HOTEL_ORIGIN.HOTELBEDS },
      { text: "Ratehawk", value: HOTEL_ORIGIN.RATEHAWK },
      { text: "Yalago", value: HOTEL_ORIGIN.YALAGO },
    ],
  };

  const hotelFilter = [
    {
      onChange: (coords: any) => {
        setCoordinates(coords);
      },
      icon: LiaFontAwesomeFlag,
      currentValue: coordinates,
      name: "location",
      type: FILTER_TYPE.LOCATION,
      displayValue: location?.name,
    },
    {
      options: 100,
      step: 5,
      onChange: (e: any) => {
        setMaxDistance(e);
      },
      icon: RiPinDistanceLine,
      currentValue: maxDistance,
      name: "maxDistance",
      label: "km",
      type: FILTER_TYPE.SLIDER,
      min: DEFAULT_MAX_KM_DISTANCE,
      hide: !coordinates,
    },
    {
      options: 5,
      onChange: setRating,
      icon: LiaStar,
      currentValue: rating,
      name: "rating",
      type: FILTER_TYPE.RANGE,
    },
  ];

  const eventFilter = [
    {
      onChange: (coords: any) => {
        setCoordinates(coords);
      },
      icon: LiaFontAwesomeFlag,
      currentValue: coordinates,
      name: "location",
      type: FILTER_TYPE.LOCATION,
      displayValue: location?.name,
    },
    {
      options: 100,
      step: 5,
      onChange: (e: any) => setMaxDistance(e),
      icon: RiPinDistanceLine,
      currentValue: maxDistance,
      name: "maxDistance",
      label: "km",
      type: FILTER_TYPE.SLIDER,
      min: DEFAULT_MAX_KM_DISTANCE,
      hide: !coordinates,
    },
  ];

  const availabilityFilter = [
    {
      onChange: setAvailabilityDateRange,
      icon: CalendarIcon,
      currentValue: availabilityDateRange,
      name: "availabilityDateRange",
      type: FILTER_TYPE.DATERANGE,
    },
    {
      options: 2000,
      onChange: useAsyncDebounce((e: any) => {
        setPriceRange(e);
      }, 200),
      icon: BiMoney,
      currentValue: priceRange,
      name: "price",
      type: FILTER_TYPE.RANGE,
    },
  ];

  const FilterMap: { [key in LIBRARYITEM_TYPE]: any } = {
    [LIBRARYITEM_TYPE.POIS]: [],
    [LIBRARYITEM_TYPE.HOTELS]: disableBedBanks
      ? hotelFilter
      : [
          originFilter,
          ...(origin[0] === HOTEL_ORIGIN.LATO || getValues(fieldName) !== ""
            ? hotelFilter
            : [...hotelFilter, ...availabilityFilter]),
        ],
    [LIBRARYITEM_TYPE.EVENTS]: eventFilter,
    [LIBRARYITEM_TYPE.DESTINATION]: [],
  };

  const inputElement = (
    <config.InputEl.Component
      {...downshiftField}
      controlled
      className="swiper-no-swiping w-fit font-bold border-0 hover:border-none ring-0 focus:border-none bg-white focus:outline-none focus:ring-0 focus:shadow-none"
      {...generalInputProps}
      key={inTrip ? `${fieldName}.${tripdayIndex}.${libraryItemIndex}` : fieldName}
      label={undefined}
      layout="inline"
      fontSizeInline="1.5rem"
    />
  );

  return !inputsOnly ? (
    <CustomDownShiftWithPagination
      key={fieldName + initialInputValue}
      apiCalls={
        inTrip
          ? origin[0] === HOTEL_ORIGIN.LATO
            ? [
                {
                  call: config.api.getAll,
                  type: APICALL.REST,
                  name: "Saved",
                },
              ]
            : ApiMap[libraryItemName]
          : []
      }
      filters={inTrip ? FilterMap[libraryItemName] : []}
      apiIcon={LiaBedSolid}
      queryName={config.queryKey}
      listItemConfig={config.listItemConfig}
      initialInputValue={initialInputValue}
      // Change the value of the controller field.
      onInputValueChangeCallBack={(e, shouldDirty) => {
        setValue(fieldName, e, { shouldDirty, shouldValidate: shouldDirty });
      }}
      customListItemConfigs={customListItems}
      additionalQueryState={{
        q: getValues(fieldName),
        location: coordinates,
        order: "ASC",
        limit: 10,
        userId: me.id,
        from: availabilityDateRange.from,
        to: availabilityDateRange.to,
        minRate: priceRange[0],
        maxRate: priceRange[1],
        rooms: [
          {
            adults: +(adults ?? 0),
            children: +(children ?? []).length,
            ages: (children ?? []).filter((child: any) => child.age).map((child: any) => child.age),
          },
        ],
        maxDistance: maxDistance,
      }}
      location={location}
      InputEl={inputElement}
    />
  ) : (
    <config.InputEl.Component {...generalInputProps} />
  );
};

LibraryItemDownshift.displayName = "LibraryItemDownshift";
export default React.memo(LibraryItemDownshift);
