import { Box } from "@chakra-ui/react";
import React from "react";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FeaturedTripsSkeleton } from "../../components/FullScreenSpinner";
import { SeperatedHeading } from "../../components/SeperateHeading";
import EmblaSwiper from "../../components/embla-swiper/EmblaSwiper";
import ErrorCardView from "../../components/layout/ErrorCardView";
import { useMeContext } from "../../stores/me-context";
import { getViewTripLink } from "../../utils/generateURLs";
import { usePaginatedTrips } from "../../utils/query-helpers/reactQueryHooks";
import { FollowerSampleBlock } from "./FollowerSampleBlock";
import { TripCard } from "../../features/dashboard/TripCard";
import { INSPIRED_TRIPS } from "../../utils/constants";

interface FollowerSamplesWallProps {}

const FollowerSamplesWall: React.FC<FollowerSamplesWallProps> = () => {
  const me = useMeContext();

  const {
    isLoading: isLoadingTrips,
    data,
    error,
  } = usePaginatedTrips(
    {
      page: 1,
      step: 10,
      //companies: me?.company.followers?.map((follower) => follower.id).join(","),
      sample: true,
      share: true,
    },
    { queryKey: [INSPIRED_TRIPS] },
  );

  if (isLoadingTrips) return <FeaturedTripsSkeleton />;
  if (error || !data) return <ErrorCardView title="Could not load trips" />;

  const samples = data.data;
  if (!samples || samples.length === 0) return <></>;

  return (
    <Box position="relative" mt={16}>
      <SeperatedHeading
        title={"Get inspired"}
        icon={HiOutlineLightBulb}
        tooltipText={"Share a sample to promote your trips here!"}
      />
      <Box className="absolute bg-gradient-to-r from-transparent from-90% via-[99%] via-[#f7fafc] to-[#f7fafc] to-100% w-full h-[20em] z-50 pointer-events-none right-0 top-0" />

      <EmblaSwiper
        mouseWheel
        renderDotNavigation={undefined}
        options={{ align: "start" }}
        slides={samples.map((userTrip, i) => {
          const tripTitle = userTrip.trip?.titles?.find((title) => title.content !== "")?.content ?? "";

          return (
            <TripCard
              userTrip={userTrip}
              key={userTrip.id}
              cardWidth="14em"
              cardHeight="14em"
              padding={3}
              cardSize={"md"}
              onClick={() => window.open(getViewTripLink(userTrip.user.company.name, tripTitle, userTrip.id ?? ""))}
              sample
            />
          );
        })}
      />
    </Box>
  );
};

export default FollowerSamplesWall;
