const baseStyleList = {
  p: 1.5,
  color: "gray.700",
};

const baseStyleItem = {
  // py: "0.4rem",
  // px: "0.8rem",
  fontSize: "0.8rem",
  borderRadius: "md",
  // transitionProperty: "background",
  transitionDuration: "fast",
  // transitionTimingFunction: "ease-in",
  borderWidth: 0,
  // transition: "background .2s cubic-bezier(.785,.135,.15,.86) 0s",
  _focus: {
    bg: "gray.50",
  },
  // _active: {
  //   bg: "gray.200",
  // },
  // _expanded: {
  //   bg: "gray.100",
  // },
  // _disabled: {
  //   opacity: 0.4,
  //   cursor: "not-allowed",
  // },
};

const baseStyle = {
  list: baseStyleList,
  item: baseStyleItem,
};

export default {
  baseStyle,
};
