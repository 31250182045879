import { ImageSite } from "../types/index.js";
export function getKeyFromImageUrl(url, site) {
    // This will remove the url where the image is stored, because this does not need to be saved on the server.
    // Also
    switch (site) {
        case ImageSite.PEXELS:
        case ImageSite.PIXABAYVIDEO:
            return url.split("/").slice(-2).join("/");
        case ImageSite.PEXELSVIDEO:
            return url.split("/").slice(3).join("/");
        default:
            // Remove the domain part of the image url. Unsplash example URLs:
            // https://images.unsplash.com/flagged/photo-1575555201693-7cd442b8023f
            // https://images.unsplash.com/photo-1554836398-55e836ac3c47
            // So first remove the https:// part and then keep everything after the first /
            return url.replace("https://", "").split("/").slice(1).join("/");
    }
}
