import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  useModalContext,
} from "@chakra-ui/react";
import { TLibraryImage } from "@lato/common";
import { Trash2 as DeleteIcon } from "lucide-react";
import React from "react";
import { LiaFontAwesomeFlag } from "react-icons/lia";
import { useAsyncDebounce } from "react-table";
import LibraryImagesAPI from "../../api/library-images.api";
import { LIBRARY_IMAGES_QUERY_KEY, MAX_PICTURES } from "../../utils/constants";
import { useParamsInfiniteQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import { FILTER_TYPE } from "../../components/CustomDownShift/CustomComboBox";
import { FilterSelect } from "../../components/CustomDownShift/FilterSelect";
import LibraryImageGrid from "./LibraryImageGrid";

interface LibraryImagesPickerProps {
  defaultSearchValue?: string | null;
  handleChosenPictures: (photo: TLibraryImage | TLibraryImage[]) => void;
  multiple: boolean;
  nrOfImages: number;
}

const LibraryImagesPicker: React.FC<LibraryImagesPickerProps> = ({
  defaultSearchValue,
  handleChosenPictures,
  multiple,
  nrOfImages,
}) => {
  const [selectedImages, setSelectedImages] = React.useState<TLibraryImage[]>([]);
  const [imagesCount, setImagesCount] = React.useState<number>(nrOfImages);
  const [locationFilter, setLocationFilter] = React.useState();
  const [locationFilterCoordinates, setLocationFilterCoordinates] = React.useState();
  const modalContext = useModalContext();

  const [search, setSearch] = React.useState(defaultSearchValue || "");

  const infiniteQueryResultLibrary = useParamsInfiniteQueryHelper<TLibraryImage>({
    queryKey: LIBRARY_IMAGES_QUERY_KEY,
    queryParams: {
      q: search,
      location: locationFilterCoordinates,
    } as any,
    apiCall: LibraryImagesAPI.getAll,
    options: {
      getNextPageParam: (lastPage, pages) => (pages.length >= lastPage.total_pages ? false : pages.length + 1),
    },
  });

  const { isFetchingNextPage, fetchNextPage, hasNextPage } = infiniteQueryResultLibrary;

  const isMoreDataAvailable =
    hasNextPage &&
    infiniteQueryResultLibrary.data.pages.flatMap((page) => (page as any).data).length <
      infiniteQueryResultLibrary.data.pages[0]?.count;

  const searchLibrary = useAsyncDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
    // Debounce search
    // Trigger new unsplash api call by changing the search state
    setSearch(e.target.value);
  }, 700);
  const pageEnd = React.useRef<any>();
  useIntersectionObserver({
    target: pageEnd,
    onIntersect: fetchNextPage,
    enabled: isMoreDataAvailable,
  });

  const handleChosenPicture = (img: TLibraryImage) => {
    if (multiple) {
      if (selectedImages.some((selectedImg) => selectedImg.id === img.id)) {
        // If the image which the user selects is already inside the array, remove it again.
        const removedImg = selectedImages.filter((selectedImg) => selectedImg.id !== img.id);
        setSelectedImages((prevImgs) => [...removedImg]);
        setImagesCount(imagesCount - 1);
      } else {
        setSelectedImages((prevImgs) => [...prevImgs, img]);
        setImagesCount(imagesCount + 1);
      }
    } else {
      handleChosenPictures(img);
    }
  };

  return (
    <>
      <Flex mx="auto" w="100%" gap={3} alignItems={"center"} mb={7}>
        <InputGroup size="md" w={"75%"}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            defaultValue={search}
            type="search"
            placeholder="Search photos..."
            autoComplete="off"
            borderRadius="full"
            focusBorderColor="brand.500"
            _hover={{ borderColor: "brand.200" }}
            onChange={searchLibrary}
          />
        </InputGroup>
        <FilterSelect
          type={FILTER_TYPE.LOCATION}
          onChange={setLocationFilterCoordinates}
          defaultSelected={locationFilter}
          icon={LiaFontAwesomeFlag}
          name="location"
          width={"10em"}
          setFilterValue={setLocationFilter}
          borderRadius={"sm"}
          dropdown
          modalContext={modalContext}
        ></FilterSelect>
      </Flex>
      <LibraryImageGrid
        infiniteQueryResult={infiniteQueryResultLibrary}
        handleChosenPicture={handleChosenPicture}
        selectedPictures={selectedImages}
      />
      {multiple && selectedImages.length > 0 && (
        <Flex
          pos="sticky"
          bottom={0}
          bg="gray.100"
          borderRadius="full"
          overflow="hidden"
          boxShadow={"lg"}
          mx="auto"
          w="80%"
        >
          <Button
            fontSize="sm"
            leftIcon={<Icon as={DeleteIcon} boxSize={4} />}
            borderRadius="full"
            flexShrink={0}
            px={5}
            color="red.400"
            onClick={() => setSelectedImages([])}
          >
            Clear selection
          </Button>
          <Tooltip
            hasArrow
            label="You can only have 20 images per element"
            bg="red.600"
            isDisabled={imagesCount <= MAX_PICTURES}
            placement="top"
          >
            <Box w="100%">
              <Button
                fontWeight={600}
                fontSize="md"
                color="white"
                colorScheme="brand"
                w="100%"
                borderRadius="full"
                disabled={imagesCount > MAX_PICTURES}
                onClick={() => handleChosenPictures(selectedImages)}
              >
                Add {selectedImages.length} {selectedImages.length === 1 ? "image" : "images"}
              </Button>
            </Box>
          </Tooltip>
        </Flex>
      )}
      <Flex justify="center">
        <Button
          onClick={() => fetchNextPage()}
          disabled={!isMoreDataAvailable || isFetchingNextPage}
          colorScheme="brand"
          ref={pageEnd}
        >
          {isFetchingNextPage ? "Loading more..." : isMoreDataAvailable ? "Load More" : "Nothing more to load"}
        </Button>
      </Flex>
    </>
  );
};
export default React.memo(LibraryImagesPicker);
