import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  ModalCloseButton,
  Text,
  Image,
} from "@chakra-ui/react";
import React from "react";
import modalImage from "../../assets/images/sadpdf.png";
import colors from "../../theme/foundations/colors";

interface NotificationModalProps {
  disclosure: any;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ disclosure }) => {
  return (
    <Modal motionPreset="slideInBottom" isOpen={disclosure.isOpen} onClose={disclosure.onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody mx={4} mb={2}>
          <Flex alignItems="center" justifyContent="space-between" margin={1} mb={4}>
            <Heading size="2xl" color={colors.lato.primaryColorDark}>
              Important notice
            </Heading>
            <ModalCloseButton marginTop={2} pos="unset" size={"2xl"} />
          </Flex>
          <Text fontSize={"18px"} margin={1} color={colors.lato.primaryColorDark} fontWeight={400} width={"90%"}>
            We are currently improving the pdf generation tool. For the time being you and your clients will not be able
            to generate a pdf from a trip.
          </Text>
          <Text mt={6} fontSize={"18px"} margin={1} color={colors.lato.primaryColorDark} fontWeight={400} width={"90%"}>
            {" "}
            Thank you for your patience.
          </Text>
          {<Image width="16rem" objectFit="cover" marginTop={4} src={modalImage} margin={"auto"}></Image>}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(NotificationModal);
