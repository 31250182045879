import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { BrandContent, BrandImage } from "../../../../../pages/settings/BrandSettings";
import { CompanyBrand } from "@lato/common";
import RHFRichTextEditor2 from "../../../../input/wysiwyg/RHFRichTextEditor2";
import Section from "../../../../layout/Section";
import { TripLanguageProvider } from "../../../../../stores/trip-language-context";

interface TripTermsProps {
  id: string | undefined;
  brand: CompanyBrand;
  index: number;
  setAsDefault: (i: number, fieldname: BrandContent) => void;
}

const TripTerms: React.FC<TripTermsProps> = ({ id, brand, index, setAsDefault }) => {
  return (
    <Section
      title={"Terms & Conditions"}
      id={id}
      headingExtra={<BrandImage name={brand ? brand.name : ""} logoUrl={brand?.logoUrl} />}
    >
      <Flex flexDir={"column"} gap={3}>
        <TripLanguageProvider first={true} doubleFields={false} defaultPartialFields={[]} enabled={false}>
          <RHFRichTextEditor2
            key={`brand-${index}-terms`}
            name={`brands.${index}.terms`}
            label=" "
            minHeight={"20em"}
            transformToTF
          />
        </TripLanguageProvider>
        {/* Button to make setting default for all brands, disabled for now
        <Button
          alignSelf={"flex-start"}
          size={"sm"}
          float={"left"}
          fontSize={"md"}
          colorScheme="brand"
          outline={"ghost"}
          onClick={() => setAsDefault(index, "terms")}
        >
          Make Default For All Brands
        </Button> */}
      </Flex>
    </Section>
  );
};

export default TripTerms;
