import React from "react";
import WarningIllustration from "../../assets/icons/Warning.Illustration";
import { CardWrapper } from "../CardWrapper";
import SplitScreenLayout from "./SplitScreenLayout";

interface ErrorCardViewProps {
  title: string;
  description?: string;
}

const ErrorCardView: React.FC<ErrorCardViewProps> = ({ title, description }) => {
  return (
    <SplitScreenLayout>
      <CardWrapper title={title} description={description} topIllustration={WarningIllustration}></CardWrapper>
    </SplitScreenLayout>
  );
};
export default React.memo(ErrorCardView);
