import { mode } from "@chakra-ui/theme-tools";

const sizes = {
  lg: {
    h: 12,
    minW: 12,
    fontSize: "lg",
    px: 6,
  },
  md: {
    h: 10,
    minW: 10,
    fontSize: "md",
    px: 4,
  },
  sm: {
    h: 8,
    minW: 8,
    fontSize: "sm",
    px: 3,
  },
  xs: {
    h: 6,
    minW: 6,
    fontSize: "xs",
    px: 2,
  },
};
function variantBasic(props: Record<string, any>) {
  const { colorScheme: c } = props;
  return {
    color: mode(`${c}.500`, `${c}.200`)(props),
    bg: "transparent",
    _hover: {
      color: `${c}.700`,
    },
    _active: {
      color: `${c}.700`,
    },
  };
}
const variants = {
  basic: variantBasic,
};
const defaultProps = {
  variant: "solid",
  size: "sm",
  colorScheme: "gray",
};

export default {
  defaultProps,
  sizes,
  variants,
};
