import { Contact } from "@lato/common";
import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "contacts";

const apiEndpoints = getApiEndpoints({ url });

const uploadCsvFile = (formData: any) => {
  return rawApiProvider.post(`${url}/uploadCsvFile/`, formData);
};

const changeAvatar = (s3key: string, contactId: string) => {
  return rawApiProvider.patch(`${url}/change-avatar/${contactId}`, {
    avatar: s3key,
  });
};

const patchTripPassengers = (tripId: string, passengers: Contact[]) => {
  return rawApiProvider.patch(`${url}/multiple/${tripId}`, { passengers: passengers });
};

const addPassengerToTrip = (tripId: string, contactId: string) => {
  return rawApiProvider.patch(`${url}/${tripId}/add/${contactId}`, {});
};

const removePassengerToTrip = (tripId: string, contactId: string) => {
  return rawApiProvider.remove(`${url}/${tripId}/remove/${contactId}`);
};

const getTripContacts = (tripId: string) => {
  return rawApiProvider.get(`${url}/trip/${tripId}`);
};

type ContactAPIType = ReturnEndpoints & {
  uploadCsvFile(formData: any): Promise<Contact>;
  changeAvatar(s3key: string, contactId: string): Promise<any>;
  getTripContacts(tripId: string): Promise<{ id: string; contacts: Contact[] }>;
  //patchTripPassengers(tripId: string, passengers: Contact[]): Promise<any>;
  addPassengerToTrip(tripId: string, contactId: string): Promise<Contact>;
  removePassengerToTrip(tripId: string, contactId: string): Promise<Contact>;
};
const ContactAPI: ContactAPIType = {
  ...apiEndpoints,
  uploadCsvFile,
  changeAvatar,
  getTripContacts,
  addPassengerToTrip,
  removePassengerToTrip,
};

export default ContactAPI;
