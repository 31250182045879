import { Box, Flex, Input, InputGroup, InputProps, useToast } from "@chakra-ui/react";
import { useCombobox, useMultipleSelection } from "downshift";
import React from "react";
import { addToast } from "../../utils/addToast";
import { CustomComboBoxProps, CustomDownShiftList, DSAccessorProps, DSListItemConfig } from "./CustomComboBox";

export interface CustomMultipleSelectionProps<T extends object> extends CustomComboBoxProps<T> {
  selectedItems?: T[];
  setSelectedItems?: (items: any[]) => void;
  onBlur?: (e: any) => void;
  useDivider?: boolean;
  isDropdown?: boolean;
}

function CustomMultipleSelection<T extends object>({
  isLoading = false,
  placeholder = "Search...",
  dropDownItems = [],
  listItemConfig,
  inputItemConfig,
  customListItemConfigs = [],
  disableItemSelection = false,
  disableInput = false,
  onSelectedItemChangeCallBack,
  showSelectedItem,
  onInputValueChangeCallBack,
  bottomRef,
  refetchEl,
  defaultSelected,
  InputEl,
  initialInputValue = "",
  height = "400px",
  priorityItems,
  selectedItems = [],
  setSelectedItems = (items) => {},
  onBlur,
  useDivider = false,
  isDropdown = true,
}: CustomMultipleSelectionProps<T>) {
  const [userInput, setUserInput] = React.useState(initialInputValue);
  // Filter out the custom list item configs which need to be hidden
  const filteredCustomListItemConfigs = customListItemConfigs.filter(
    ({ isHidden }, i) =>
      !(
        isHidden === true ||
        (typeof isHidden === "function" &&
          isHidden({
            // Just set the item to empty, doesn't really matter
            item: {},
            inputValue: userInput,
          }))
      ),
  );
  // Map the custom configs to real list items and add an index value. This way we can retrieve the corresponding list config from the item.
  const additionalCustomItems: any[] = filteredCustomListItemConfigs.map((customListItemConf, i) => ({
    // ...customListItemConf.item,
    custom: true,
    index: i,
    customObject: customListItemConf.customObject,
    customValidation: customListItemConf.customValidation,
  }));

  const filteredDropItems = dropDownItems
    .filter(
      (item) =>
        selectedItems.filter((selected) => ((selected as any)?.id ?? selected) === ((item as any)?.id ?? item))
          .length === 0 &&
        (priorityItems?.length && userInput === "" ? priorityItems : []).filter(
          (prio) => ((prio as any)?.id ?? prio) === ((item as any)?.id ?? item),
        ).length === 0,
    )
    .map((item) => ({
      ...item,
      original: false,
    }));

  const items = [
    ...additionalCustomItems,
    ...(priorityItems?.length && userInput === ""
      ? priorityItems.map((item) => ({ ...item, original: true }))
      : []
    ).filter((item) => !selectedItems.includes(item)),
    ...(userInput === "" && priorityItems?.length ? [] : filteredDropItems),
  ];

  const getItemConfig = (item: any): DSListItemConfig<T> => {
    // Check if the index is smaller than the number of custom list items. Because then we should get the config of one of the custom list items.
    if (
      item &&
      item.hasOwnProperty("custom") &&
      item["custom"] === true &&
      item.hasOwnProperty("index") &&
      item["index"] < customListItemConfigs.length
    ) {
      return customListItemConfigs[item["index"]!];
    }
    return listItemConfig;
  };

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          setSelectedItems(newSelectedItems ?? []);
          break;
        default:
          break;
      }
    },
  });

  const toast = useToast();

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.name : "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    //inputValue: userInput,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: state.highlightedIndex, // keep highlight on last selected.
          };
        default:
          return changes;
      }
    },
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            if (newSelectedItem.custom && newSelectedItem.customObject) {
              const response = newSelectedItem.customValidation ? newSelectedItem.customValidation(userInput) : true;
              if (response === true) {
                newSelectedItem = newSelectedItem.customObject(userInput);
                setSelectedItems([...(selectedItems as any[]), newSelectedItem]);
                break;
              } else {
                addToast(toast, {
                  title: response,
                  status: "error",
                });
                break;
              }
            } else {
              setSelectedItems([...(selectedItems as any[]), newSelectedItem]);
            }
            setUserInput("");
            if (onInputValueChangeCallBack) {
              onInputValueChangeCallBack("", false);
            }
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setUserInput(newInputValue ?? "");

          break;
        default:
          break;
      }
    },
    onInputValueChange: ({ inputValue }) => {
      setUserInput(inputValue || "");
      if (onInputValueChangeCallBack) {
        onInputValueChangeCallBack(inputValue || "", false);
      }
    },
  });

  const inputProps = getInputProps();

  const commonInputProps: InputProps = {
    autoComplete: "nope",
    ...getInputProps(),
    onBlur: (e) => {
      inputProps.onBlur(e);
      onBlur && onBlur(e);
      setUserInput("");
    },
  };

  return (
    <Box w="100%" position={"relative"}>
      {/* <!-- The text and password here are to prevent FF from auto filling my login credentials because it ignores autocomplete="off"--> */}
      {InputEl ? (
        React.cloneElement(InputEl, commonInputProps)
      ) : (
        <Flex border={"solid 1px #E2E8F0"} borderRadius={"5px"} mb={0} alignItems={"center"} flexWrap={"wrap"}>
          {selectedItems.map((item: T, index: number) => {
            const accessorProps: DSAccessorProps<T> = {
              item: item,
              inputValue: userInput,
            };
            const { inputItem: DSListItem } = inputItemConfig!;

            return (
              <Flex
                key={`selected-item-${index}`}
                {...getSelectedItemProps({
                  selectedItem: item,
                  index,
                })}
                padding={"2px"}
                margin={1}
                alignItems={"center"}
                borderRadius={"8px"}
                background={"gray.100"}
                px={"8px"}
              >
                {typeof DSListItem === "string"
                  ? // @ts-ignore
                    item[DSListItem]
                  : DSListItem(accessorProps)}
                {!disableInput && (
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSelectedItem(item);
                    }}
                    cursor={"pointer"}
                    margin="2px"
                    alignSelf={"flex-start"}
                    fontSize={"12px"}
                  >
                    &#10005;
                  </Box>
                )}
              </Flex>
            );
          })}
          <InputGroup
            size="md"
            border={"none"}
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            width={"auto"}
            min-width={"30%"}
            flexGrow={1}
          >
            <Input
              bg="white"
              placeholder={placeholder ? placeholder : ""}
              size="md"
              disabled={disableInput}
              border="none"
              borderRadius={"5px"}
              {...commonInputProps}
            />
          </InputGroup>
        </Flex>
      )}
      <CustomDownShiftList
        getMenuProps={getMenuProps}
        getItemConfig={getItemConfig}
        getItemProps={getItemProps}
        isOpen={isOpen}
        items={items}
        height={height}
        userInput={userInput}
        refetchEl={refetchEl}
        highlightedIndex={highlightedIndex}
        disableItemSelection={disableItemSelection}
        useDivider={useDivider}
        isDropdown={isDropdown}
      />
    </Box>
  );
}
export default React.memo(CustomMultipleSelection);
