import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { FieldName, useFormContext } from "react-hook-form";
import { useTripLanguage } from "../../stores/trip-language-context";

import LanguagesInput from "../trips/edit/daybyday/LanguagesInput";
import { RHFInputProps } from "./RHFInput";

type RHFTranslationInputProps = RHFInputProps & {
  first: boolean;
  name: FieldName<any>;
  showSecondTranslationInput?: boolean;
  firstInputProps?: object;
  secondInputProps?: object;
  collaboratorCanEdit: boolean;
  characterLimit?: number;
  isInline?: boolean;
};

const RHFTranslationInput = React.forwardRef<HTMLInputElement, RHFTranslationInputProps>(
  (
    { showSecondTranslationInput = true, firstInputProps, secondInputProps, collaboratorCanEdit, isInline, ...props },
    ref,
  ) => {
    const { firstSelectedLanguageIndex, secondSelectedLanguageIndex } = useTripLanguage();
    // Only use border colors if 2 fields are shown.
    const { getValues } = useFormContext<any>();
    const firstFieldValue: any = getValues(`${props.name}` as any);
    const secondFieldValue: any = getValues(`${props.name}` as any);
    // If the first value is not undefined or empty string '', the first is active.
    const activeFirst = !!firstFieldValue;
    const activeSecond = !!secondFieldValue;
    // const minTextareaH = "120px";
    // const Input = props.textarea ? RichTextEditor : RHFInput;

    return (
      <Flex h="100%">
        {/* Switch to minH for the other textarea to scale as well? Gives wonky behaviour where one textarea layers under the next one */}
        <Box h="100%" w="100%">
          <LanguagesInput
            ref={ref}
            {...props}
            firstInput={true}
            isDisabled={!props.first && showSecondTranslationInput}
            selectedLanguageIndex={firstSelectedLanguageIndex}
            doubleFields={secondSelectedLanguageIndex !== null}
            layout={isInline ? "inline" : "floating"}
            {...firstInputProps}
          />
        </Box>
        {secondSelectedLanguageIndex !== null && showSecondTranslationInput && (
          <Box h="100%" w="100%" pl={3}>
            <LanguagesInput
              ref={ref}
              {...props}
              firstInput={false}
              conditionalUP={true}
              isDisabled={!collaboratorCanEdit}
              selectedLanguageIndex={secondSelectedLanguageIndex}
              doubleFields={secondSelectedLanguageIndex !== null}
              {...secondInputProps}
              layout={isInline ? "inline" : "floating"}
              // Only color the second field border if translation fields are present,
              // the second field is filled in
              // borderColor={!props.first && activeSecond ? "brand.100" : "inherit"}
            />
          </Box>
        )}
      </Flex>
    );
  },
);
RHFTranslationInput.displayName = "RHFTranslationInput";

const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.first === nextProps.first && prevProps.name === nextProps.name;
};

// are equal is left out!
export default React.memo(RHFTranslationInput);
