import getApiEndpoints from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "amadeus";

// Get the standard CRUD operations
// const basicAmadeusEndpoints = getApiEndpoints({
//   url,
// });

// Get all the trips of my company in a paginated manner
const getFlightStatus = (queryParams: string) => {
  return rawApiProvider.get(`${url}?${queryParams}`);
};

type AmadeusAPIType = {
  getFlightStatus(queryString: string): Promise<any>;
};
const AmadeusAPI: AmadeusAPIType = {
  getFlightStatus,
};
export default AmadeusAPI;
