import rawApiProvider from "./utilities/rawProvider";

const url = "transportations";

// Get the standard CRUD operations
// const basicAmadeusEndpoints = getApiEndpoints({
//   url,
// });

// Get all the trips of my company in a paginated manner
const parsePnr = (pnr: string) => {
  return rawApiProvider.post(`${url}`, { pnr: pnr });
};

const getFlightsByTrip = (tripId: string) => {
  return rawApiProvider.get(`${url}/flights/trip/${tripId}`);
};

const getSingle = (id: string) => {
  return rawApiProvider.get(`${url}/${id}`);
};

const patch = (id: string, model: object, tripId?: string) => {
  return rawApiProvider.patch(`${url}/${id}` + (tripId ? `?tripId=${tripId}` : ""), model);
};

const patchMultiple = (model: object[], tripId?: string) => {
  return rawApiProvider.patch(`${url}/multiple/${tripId}`, { transportations: model });
};

const remove = (id: string) => {
  return rawApiProvider.remove(`${url}/${id}`);
};

const duplicate = (id: string) => {
  return rawApiProvider.post(url + "/duplicate", {
    id,
  });
};

type TransportationsApiType = {
  parsePnr(pnr: string): Promise<any>;
  getFlightsByTrip(tripId: string): Promise<any>;
  getSingle(id: string): Promise<any>;
  patch(id: string, model: object, tripId?: string): Promise<any>;
  patchMultiple(model: object[], tripId?: string): Promise<any>;
  remove(id: string): Promise<any>;
  duplicate: (transportId: string) => Promise<any>;
};
const TransportationsApi: TransportationsApiType = {
  parsePnr,
  getFlightsByTrip,
  getSingle,
  patch,
  patchMultiple,
  remove,
  duplicate,
};
export default TransportationsApi;
