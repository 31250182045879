import { Box, Button, Center, Circle, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import colors from "../../../theme/foundations/colors";
import { CustomSpinner } from "../../../components/FullScreenSpinner";

interface StepperElementProps {
  index: number;
  isActive: boolean;
  isError: boolean;
  totalSteps: number;
  step: any;
  isDisabled: boolean;
  handleStep: (step: number) => () => void;
  first: boolean;
  collaboratorCanEdit: boolean;
}

const StepperElement: React.FC<StepperElementProps> = ({
  index,
  isActive,
  isError,
  isDisabled,
  totalSteps,
  step,
  handleStep,
  first,
  collaboratorCanEdit,
}) => {
  return (
    <Center
      as={Button}
      variant="unstyled"
      isDisabled={isDisabled}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      w="100%"
      h="100%"
      type="button"
      onClick={handleStep(index)}
      role="group"
    >
      <Flex
        /* Center the content */
        alignItems="center"
        justifyContent="center"
      >
        {/* <Box
          flex={1}
          h="1px"
          // Set the connector transparent for the first step
          bg={index === 0 ? "transparent" : "rgba(0, 0, 0, .3)"}
        /> */}
        <Divider w="100%" borderColor={index === 0 ? "transparent" : "rgba(0, 0, 0, .3)"} />
        <Circle
          mx={3}
          bg={
            isError
              ? "red.400"
              : (!first && (index == 0 || index == 1)) || (!collaboratorCanEdit && !first && index == 2)
                ? "gray.400"
                : isActive
                  ? "brand.500"
                  : "brand.200"
          }
          opacity={
            (!first && !isActive && (index == 0 || index == 1)) ||
            (!first && !collaboratorCanEdit && !isActive && index == 2)
              ? "0.5 "
              : 1
          }
          transition="all 0.2s"
          _groupHover={{
            bg:
              (!first && (index == 0 || index == 1)) || (!first && !collaboratorCanEdit && index == 2)
                ? "gray.500"
                : "brand.500",
            opacity: "1",
          }}
          color="white"
          size="25px"
        >
          <Text fontSize="md">{index + 1}</Text>
        </Circle>

        {/* <Box
          flex={1}
          h="1px"
          // Set the connector transparent for the last step
          bg={index === totalSteps - 1 ? "transparent" : "rgba(0, 0, 0, .3)"}
        /> */}
        <Divider w="100%" borderColor={index === totalSteps - 1 ? "transparent" : "rgba(0, 0, 0, .3)"} />
      </Flex>
      <Text
        fontSize="md"
        fontWeight={isActive ? 600 : 50}
        transition="all 0.2s"
        color={isActive ? "currentColor" : "realGray.600"}
        _groupHover={{ color: "currentColor" }}
      >
        {step.props.name}
      </Text>
    </Center>
  );
};
export default React.memo(StepperElement);
