import { TripsOverViewStep } from "../../../../components/trips/edit/overviewStep/TripsOverViewStep";
import TripStepWrapper from "../TripStepWrapper";

export const OverviewTripRoute: React.FC = () => {
  const stepNumber = 1;

  return (
    <TripStepWrapper>
      <TripsOverViewStep stepNumber={stepNumber} />
    </TripStepWrapper>
  );
};

export default OverviewTripRoute;
