import { Avatar, Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { FilterProps } from "react-table";
import { User } from "@lato/common";
import { useTripsListStore } from "../../stores/trip/tripslistStore";
import MultiSelectColumnFilter from "./MultiSelectColumnFilter";

interface MemberFilterProps extends React.PropsWithChildren<FilterProps<any>> {
  members: User[];
}

const MemberFilter: React.FC<MemberFilterProps> = ({ members, ...props }) => {
  const displayMemberOption = React.useCallback(
    (userId: string): React.ReactElement => {
      // Find a single usertrip of this user to get the user object
      const user = members?.find((u) => u.id! === userId);
      // If the members didn't load yet or somehow no user was found, return an empty fragment.
      if (!user) return <></>;
      return (
        <Flex alignItems="center">
          <Avatar size="xs" name={user.name} src={user.avatar ? user.avatarUrl : ""} mr={2} />
          <span>{user.name}</span>
        </Flex>
      );
    },
    [members],
  );

  const createMemberOptions = React.useCallback(
    (preFilteredRows: any, columnName: string) => {
      // If the members didn't load yet, return an empty set.
      if (!members) return new Set<string>();
      return new Set(members.map((m) => m.id!));
    },
    [members],
  );

  // const createSearchValueOption = React.useCallback(
  //   (countryIso: string) => {
  //     if (!members) return "";
  //     return members?.find((c) => c.name === countryIso)?.name ?? "";
  //   },
  //   [members],
  // );

  return (
    <>
      {members && members.length > 0 && (
        <MultiSelectColumnFilter
          {...props}
          displayOption={displayMemberOption}
          //searchValueOption={createSearchValueOption}
          createOptions={createMemberOptions}
          useSelectedItems={false}
        />
      )}
    </>
  );
};
export default React.memo(MemberFilter);
