import React from "react";

import Cropper from "react-cropper";
// Import cropperjs styles
import "cropperjs/dist/cropper.css";
import { Box, Circle } from "@chakra-ui/react";
// Import the custom css as well
// import "./imagePicture.css";

interface ImageCropperProps {
  type: "profile" | "image";
  setCropper: React.Dispatch<React.SetStateAction<Cropper | undefined>>;
  url: string;
  aspectRatio?: number;
  setInitialZoomRatio: React.Dispatch<React.SetStateAction<number>>;
  maxZoomLevel: number;
  zoomable?: boolean;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  setCropper,
  url,
  aspectRatio,
  setInitialZoomRatio,
  maxZoomLevel,
  type,
  zoomable = true,
}) => {
  const cropperRef = React.useRef<HTMLImageElement>(null);
  const isProfile = type === "profile";
  const Wrapper = isProfile ? Circle : Box;
  const aspectR = isProfile ? 1 : aspectRatio;
  return (
    // TODO check IOS size issue on react-cropper readme
    <Wrapper
      display={isProfile ? undefined : "block"}
      w={isProfile ? "200px" : undefined}
      h={isProfile ? "200px" : undefined}
      overflow={isProfile ? "hidden" : undefined}
    >
      <Cropper
        initialAspectRatio={aspectR}
        aspectRatio={aspectR}
        guides={false}
        center={false}
        toggleDragModeOnDblclick={false}
        style={{
          maxHeight: isProfile ? undefined : "70vh",
          height: isProfile ? "100%" : undefined,
          width: "100%",
        }}
        // TODO: check if we can use this instead of the temporary URLS
        //preview=".img-preview"
        // Don't change this: when set to 3, it will not show the full portrait mode picture because it will fill 100% width and 70vh height.
        // Maybe for a profile, 2 can be used as well.
        // Viewmode 0 allows zooming out of bounds
        viewMode={isProfile ? 3 : 2}
        cropBoxMovable={isProfile ? false : true}
        cropBoxResizable={isProfile ? false : true}
        dragMode={isProfile ? "move" : "crop"}
        //highlight={false}
        minCropBoxHeight={30}
        minCropBoxWidth={30}
        background={false}
        responsive={true}
        zoomable={zoomable}
        autoCropArea={1} // Default: 0.8 -> makes the crop area a little bit smaller than the complete image
        src={url}
        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        onInitialized={(instance) => {
          // setCropper(instance);
        }}
        ready={(e) => {
          console.log("ready");
          const imageElement: any = cropperRef?.current;
          const cropper: Cropper = imageElement?.cropper;
          setCropper(cropper);
          const currentZoomXRatio = cropper.getImageData().width / cropper.getImageData().naturalWidth;
          const currentZoomYRatio = cropper.getImageData().height / cropper.getImageData().naturalHeight;
          const initialZoomR = Math.min(currentZoomXRatio, currentZoomYRatio);
          setInitialZoomRatio(initialZoomR);
          // cropper.
          console.log(initialZoomR);
          if (zoomable) {
            e.currentTarget?.addEventListener("zoom", (event: any) => {
              console.log(event.detail.ratio);
              // Restrict the zoom to the maximum zoom level.
              if (event.detail.ratio > initialZoomR + maxZoomLevel) {
                // The zoomRatio is greater than the maximum zoom level plus the initial ratio.
                // Hence prevent further zooming.
                event.preventDefault();
                cropper.zoomTo(initialZoomR + maxZoomLevel);
              }
              // else if (event.detail.ratio < initialZoomR - maxZoomLevel) {
              //   event.preventDefault();
              //   cropper.zoomTo(initialZoomR + maxZoomLevel);
              // }
            });
          }
        }}
        // crop={onCrop}
        ref={cropperRef}
        // zoom={handleZoom}
      />
    </Wrapper>
  );
};
export default React.memo(ImageCropper);
