import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import clsx from "clsx";
import React, { ReactElement, useRef } from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { HiOutlineChevronLeft } from "react-icons/hi2";
import { useWindowDimensions } from "../../utils/useWindowDimensions";
import LoadingIcon from "../../assets/icons/loadingIcon";

interface CalendarProps {
  isFetchingCalendarItems: boolean;
  calendarItems: any[];
  moveCalendarItem: (item: any) => Promise<void>;
  triggerFetch: (start: string, end: string) => void;
  isLoadingUpdate: boolean;
  showCalendarTypeButtons?: boolean;
  popoverMonthGrid: ReactElement;
  popoverWeekGrid: ReactElement;
  popoverList: ReactElement;
}
const Calendar: React.FC<CalendarProps> = ({
  isFetchingCalendarItems,
  isLoadingUpdate,
  calendarItems,
  moveCalendarItem,
  triggerFetch,
  showCalendarTypeButtons = true,
  popoverMonthGrid,
  popoverWeekGrid,
  popoverList,
}) => {
  const calendar = useRef(null);
  const { height } = useWindowDimensions();

  return (
    <div className="-mr-6 -mb-10 flex flex-col">
      <div className="flex w-full items-start gap-x-4 py-4 ">
        <main className="flex-1 lg:mr-6 mr-12">
          <header className="px-8 flex items-center justify-between pb-4 lg:flex-none">
            <div className="w-44 flex gap-4">
              <h1 className="leading-6 space-x-2 text-gray-900">
                <time className="font-bold text-2xl" dateTime="2022-01">
                  {calendar.current?.getApi().view.title.split(" ")[0]}
                </time>
                <span className="text-lg font-medium">{calendar.current?.getApi().view.title.split(" ")[1]}</span>
              </h1>
              <p className="mt-2">
                {(isFetchingCalendarItems || isLoadingUpdate) && (
                  <div className="flex gap-2">
                    <LoadingIcon />
                    Loading
                  </div>
                )}
              </p>
            </div>
            {showCalendarTypeButtons && (
              <div className="hidden md:ml-4 md:flex md:items-center">
                {popoverMonthGrid && (
                  <button
                    onClick={() => calendar.current?.getApi().changeView("dayGridMonth")}
                    className={clsx(
                      calendar.current?.getApi().view.type == "dayGridMonth"
                        ? "text-gray-500 font-bold bg-gray-100"
                        : "bg-white",
                      "p-1 pointer text-gray-500 hover:bg-teal-50 rounded-l-lg w-20 px-2 border border-gray-200",
                    )}
                  >
                    Month
                  </button>
                )}
                {popoverWeekGrid && (
                  <button
                    onClick={() => calendar.current?.getApi().changeView("dayGridWeek")}
                    className={clsx(
                      calendar.current?.getApi().view.type == "dayGridWeek"
                        ? "text-gray-500 font-bold bg-gray-100"
                        : "bg-white",
                      "p-1 pointer text-gray-500 hover:bg-teal-50 w-20 px-2 border-y border-gray-200",
                    )}
                  >
                    Week
                  </button>
                )}

                {popoverList && (
                  <button
                    onClick={() => calendar.current?.getApi().changeView("listWeek")}
                    className={clsx(
                      calendar.current?.getApi().view.type == "listWeek"
                        ? "text-gray-500 font-bold bg-gray-100"
                        : "bg-white",
                      "p-1 pointer text-gray-500 hover:bg-teal-50 rounded-r-lg w-20 px-2 border border-gray-200",
                    )}
                  >
                    List
                  </button>
                )}
              </div>
            )}
            <div className="flex items-center bg-white relative rounded-md shadow-sm md:items-stretch">
              <button
                onClick={() => calendar.current?.getApi().prev()}
                type="button"
                className="flex py-1.5 w-12 items-center justify-center rounded-l-md border border-gray-200 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-teal-50"
              >
                <span className="sr-only">Previous month</span>
                <HiOutlineChevronLeft className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => calendar.current?.getApi().gotoDate(new Date())}
                type="button"
                className="hidden px-3.5 text-sm border-y border-gray-200 font-semibold text-gray-500 md:hover:bg-teal-50 focus:relative md:block "
              >
                Today
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                onClick={() => calendar.current?.getApi().next()}
                type="button"
                className="flex w-12 items-center justify-center rounded-r-md  border border-gray-200 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-teal-50"
              >
                <span className="sr-only">Next month</span>
                <HiOutlineChevronRight className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </header>
          <div className="bg-white rounded-lg shadow-lg relative">
            <FullCalendar
              ref={calendar}
              height={height - 138}
              datesSet={async (event) => {
                triggerFetch(event.start.toDateString(), event.end.toDateString());
                calendar.current?.getApi().render();
              }}
              headerToolbar={false}
              editable={true}
              eventDragMinDistance={1}
              dragScroll={true}
              selectable={true}
              dayMaxEvents={true}
              showNonCurrentDates={true}
              allDaySlot={false}
              plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
              eventBackgroundColor="transparant"
              views={{
                dayGridMonth: { eventContent: popoverMonthGrid },
                dayGridWeek: { eventContent: popoverWeekGrid },
                listWeek: { eventContent: popoverList },
              }}
              dayCellContent={(info: any) => {
                return <p className="h-4 text-end">{info.dayNumberText}</p>;
              }}
              eventDrop={(info: any) => {
                void (moveCalendarItem && moveCalendarItem(info.event));
              }}
              events={calendarItems ?? []}
            />
          </div>
        </main>
      </div>
    </div>
  );
};
export default Calendar;
