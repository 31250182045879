import getApiEndpoints from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "currencies";

const { getAll } = getApiEndpoints({ url });

const getAllByCountryISO = () => {
  return rawApiProvider.get(url + "/country");
};

const getAllCountriesWithMostCommon = () => {
  return rawApiProvider.get(url + "/common");
};

type CurrencyAPIType = {
  getAll(): Promise<any>;
  getAllByCountryISO(countryIso: string): Promise<any>;
  getAllCountriesWithMostCommon(): Promise<any>;
};

const CurrencyAPI: CurrencyAPIType = {
  getAll,
  getAllByCountryISO,
  getAllCountriesWithMostCommon,
};

export default CurrencyAPI;
