import { Box } from "@chakra-ui/react";
import { TASK_STATUS, Task } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Filters } from "react-table";
import { useMeContext } from "../../stores/me-context";
import { STORAGE_KEY, validateLocalStorage } from "../../utils/checkOldStorage";
import ENV from "../../utils/env";
import { useMyTeam, usePaginatedTasks } from "../../utils/query-helpers/reactQueryHooks";
import { useAddTask, usePatchTask } from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { PaginationOptions, defaultPaginationOptions } from "../../utils/query-helpers/usePaginatedQuery";
import { mapFiltersToQueryParams } from "../trip-list-container";
import { TasksTable } from "../../features/tasks/TasksTable";
import { ELEMENT_VIEW } from "../../components/CRUD/Resource";
import LearningSection from "../../components/learn/LearningSection";
import articleData from "../../assets/data/learnsection/learnArticles.json";

interface TasksRouteProps {}

export const TasksRoute: React.FC<TasksRouteProps> = () => {
  const listName = "tasks";
  const me = useMeContext();
  const queryClient = useQueryClient();

  const { data: members } = useMyTeam();

  const LS_LAYOUT_KEY = `layout-${listName}`;
  const LS_FILTER_KEY = STORAGE_KEY(listName, me.id, ENV);

  validateLocalStorage(LS_FILTER_KEY);
  const localFilters = localStorage.getItem(LS_FILTER_KEY) && JSON.parse(localStorage.getItem(LS_FILTER_KEY)!);
  const userLayoutPref = localStorage.getItem(LS_LAYOUT_KEY) as ELEMENT_VIEW;

  const defaultFilters = [
    { id: "search", value: "" },
    { id: "users", value: [me?.id] },
    { id: "companies", value: [me?.company.id] },
    { id: "start_date", value: "" },
    { id: "end_date", value: "" },
    { id: "status", value: [TASK_STATUS.TO_DO] },
    { id: "priority", value: "" },
    { id: "due_date", value: new Date() },
  ];

  const defaultTableState: PaginationOptions = {
    ...defaultPaginationOptions,
    filters: defaultFilters,
    step: userLayoutPref == ELEMENT_VIEW.CALENDAR ? 100 : 10,
  };

  const [tableState, setTableState] = React.useState<PaginationOptions>(
    localFilters
      ? {
          ...localFilters,
          page: localFilters.page + 1,
          updated: undefined,
        }
      : defaultTableState,
  );
  const { data, isFetching, isLoading, refetch } = usePaginatedTasks({
    ...tableState,
    ...mapFiltersToQueryParams(tableState.filters!),
  });
  const { mutateAsync: updateTask } = usePatchTask(queryClient);

  const triggerFetch = React.useCallback(
    ({
      order,
      orderBy,
      page,
      step,
      q,
      filters,
    }: {
      page: number;
      step: number;
      q: string;
      order: "DESC" | "ASC" | undefined;
      orderBy: string | undefined;
      filters: Filters<Task>;
    }) => {
      // This will get called when the table needs new data
      // set the state to the new query params, such that the useQuery will automatically trigger an update
      // If no sorting is enabled, then sort on descending order because of the creation date descending order.
      const users = filters?.find((filter) => filter.id === "users")?.value?.join(",") || me?.id;
      const companies = filters?.find((filter) => filter.id === "companies")?.value?.join(",") || me?.company.id;
      const startDateRange = filters?.find((filter) => filter.id === "start_date")?.value?.from;
      const endDateRange = filters?.find((filter) => filter.id === "start_date")?.value?.to;
      const statuses = filters?.find((filter) => filter.id === "status")?.value;
      const priorities = filters?.find((filter) => filter.id === "priority")?.value;

      const filterInstance: PaginationOptions = {
        page,
        step: userLayoutPref == ELEMENT_VIEW.CALENDAR ? 100 : step,
        orderBy,
        order,
        q,
        filters: [
          { id: "search", value: q },
          { id: "users", value: users },
          { id: "companies", value: companies },
          { id: "startDateRange", value: startDateRange },
          { id: "endDateRange", value: endDateRange },
          { id: "status", value: statuses },
          { id: "priority", value: priorities },
        ],
      };

      setTableState(filterInstance);
    },
    [me?.id, me?.company.id, userLayoutPref],
  );

  if (!data || !members) return null;

  const tasks = data.data;
  const count = data.count;

  return (
    <Box w="100%" maxW="1000px" mx="auto" my={10}>
      <TasksTable
        tasks={tasks}
        fetchingTasks={isFetching}
        loadingTasks={isLoading}
        initialTableState={defaultTableState}
        triggerFetch={triggerFetch}
        refetch={refetch}
        resetFilters={() => setTableState(defaultTableState)}
        totalCount={count}
        members={members}
        listName={listName}
        updateTask={updateTask}
        isInTrip={false}
      />
      <LearningSection learnData={articleData.taskArticles} />
    </Box>
  );
};
