import {
  Hotel,
  TRANSPORTATION_TYPE,
  TTransportation,
  Transportation,
  TripDay,
  getDistanceFromLatLonInKm,
} from "@lato/common";
import { FaPlaneArrival } from "react-icons/fa";
import { MdHotel, MdLocationOn } from "react-icons/md";

export const MINIMUM_IMPLIED_DISTANCE = 1;
export const MAXIMUM_IMPLIED_DISTANCE = 500;

export function getTransportations(isDirectionsDisabled: boolean, tripdays: TripDay[]): Partial<TTransportation>[] {
  const iconClassName = "mt-[3px] me-1 w-[14px] h-[14px]";

  const isTrain = (transport: any) => transport.type === TRANSPORTATION_TYPE.TRAIN;
  const isFlight = (transport: any) => transport.type === TRANSPORTATION_TYPE.FLIGHT;
  const isRental = (transport: any) => transport.type === TRANSPORTATION_TYPE.RENTAL;
  // const isStraightTransportation = (transport: any) =>
  //   [TRANSPORTATION_TYPE.FLIGHT, TRANSPORTATION_TYPE.TRAIN].includes(transport.type);

  const isImpliedTransportLongEnough = (departureCoords: number[], arrivalCoords: number[]) => {
    const distance = getDistanceFromLatLonInKm(
      { lng: departureCoords[0], lat: departureCoords[1] },
      { lng: arrivalCoords[0], lat: arrivalCoords[1] },
    );

    return distance > MINIMUM_IMPLIED_DISTANCE && distance < MAXIMUM_IMPLIED_DISTANCE;
  };

  // const tripdayHasStraightTransport = (tripday: TripDay) =>
  //   tripday.transportations.some((transport) => isStraightTransportation(transport));

  const isCloserThan = (firstCoords: number[], secondCoords: number[], distance: number) => {
    const distanceBetween = getDistanceFromLatLonInKm(
      { lng: firstCoords[0], lat: firstCoords[1] },
      { lng: secondCoords[0], lat: secondCoords[1] },
    );

    return distanceBetween < distance;
  };

  // const getArrivalLocation = (transport: Transportation) => {
  //   if (isFlight(transport)) {
  //     return [transport.arrivalAirport!.lat, transport.arrivalAirport!.lon];
  //   }

  //   return transport.arrivalLocation?.coordinates;
  // };

  function getToNameTransport(transport: Transportation): string {
    if (isFlight(transport)) {
      return transport.arrivalAirport!.iata;
    }

    return (
      transport.arrivalLocation?.address!.street ??
      transport.arrivalLocation?.address!.city ??
      transport.arrivalLocation?.address!.state ??
      transport.arrivalLocation?.address!.country_code ??
      transport.arrivalAirport?.name ??
      ""
    );
  }

  function getFromNameTransport(transport: Transportation): string {
    if (isFlight(transport)) {
      return transport.departureAirport!.iata;
    }

    return (
      transport.departureLocation?.address!.street ??
      transport.departureLocation?.address!.city ??
      transport.departureLocation?.address!.state ??
      transport.departureLocation?.address!.country_code ??
      transport.departureLocation?.name ??
      ""
    );
  }

  function getNameTripday(tripday: TripDay): string {
    return (
      tripday.location?.name ??
      tripday.location?.address.street ??
      tripday.location?.address.city ??
      tripday.location?.address.state ??
      tripday.location?.address.country_code ??
      ""
    );
  }

  function getNameHotel(hotel: Hotel): string {
    return (
      hotel.name ??
      hotel.location?.name ??
      hotel.location?.address.street ??
      hotel.location?.address.city ??
      hotel.location?.address.state ??
      hotel.location?.address.country_code ??
      ""
    );
  }

  const basicProperties = {
    name: "",
    address: {
      country_code: null,
      state: null,
      city: null,
      postal_code: null,
      street: null,
    },
  };

  const handleImpliedFlightTransportations = (
    tripday: TripDay,
    lastTransport: any,
    i: number,
    arrivalOfDay: number[],
    toHotel: boolean,
  ) => {
    // Defining a flight can be done in 3 different ways:
    // 1. DAY 0 -> DAY 1 with arrivaldayindex of 0 on DAY 0
    // 2. DAY 0 -> DAY 1 with arrivaldayindex of 1 on DAY 1
    // 3. DAY 1 -> DAY 1 with arrivaldayindex of 0 on DAY 1
    // Thus, next tripdayindex is always current tripdayindex + arrivaldayindex
    // And case 1 needs +1 -> calculate distance of departureLocation of flight and current tripday location
    // If these are not close, then this is case 1

    const isCaseOne =
      !tripdays[i].location ||
      ((!lastTransport.arrivalDayIndex || lastTransport.arrivalDayIndex == 0) &&
        !isCloserThan(
          tripdays[i].location!.coordinates!,
          [lastTransport.arrivalAirport.lon, lastTransport.arrivalAirport.lat],
          300,
        ));

    const index = (lastTransport.arrivalDayIndex ?? 0) + i + (isCaseOne ? 1 : 0);

    if (!tripdays[index] || !tripdays[index].location) {
      return;
    }

    // const fromHotel = tripdays[i].hotels && tripdays[i].hotels.length > 0 && tripdays[i].hotels[0].location;

    // //Transport to airport
    // if (tripday.location) {
    //   transportations.push({
    //     type: TRANSPORTATION_TYPE.CAR,
    //     departureLocation: {
    //       coordinates: [tripday.location.coordinates![0], tripday.location.coordinates![1]],
    //       ...basicProperties,
    //     },
    //     arrivalLocation: {
    //       coordinates: [lastTransport.departureAirport.lon, lastTransport.departureAirport.lat],
    //       ...basicProperties,
    //     },
    //     fromName: fromHotel ? getNameHotel(tripdays[i].hotels[0]) : getNameTripday(tripdays[i]),
    //     fromIcon: <MdLocationOn className={iconClassName} />,
    //     toName: getToNameTransport(lastTransport as TTransportation),
    //     toIcon: <FaPlaneDeparture className={iconClassName} />,
    //     tripdayIndex: i,
    //   });
    // }

    // const toHotel = tripdays[index].hotels && tripdays[index].hotels.length > 0 && tripdays[index].hotels[0].location;
    const departureCoords = [lastTransport.arrivalAirport.lon, lastTransport.arrivalAirport.lat];
    // // Hotel or next day location
    // const arrivalCoords = arrivalOfDay;

    if (arrivalOfDay && isImpliedTransportLongEnough(departureCoords, arrivalOfDay)) {
      transportations.push({
        type: TRANSPORTATION_TYPE.CAR,
        departureLocation: {
          coordinates: [lastTransport.arrivalAirport.lon, lastTransport.arrivalAirport.lat],
          ...basicProperties,
        },
        arrivalLocation: {
          coordinates: arrivalOfDay,
          ...basicProperties,
        },
        fromName: getToNameTransport(lastTransport as TTransportation),
        fromIcon: <FaPlaneArrival className={iconClassName} />,
        toName:
          toHotel && tripdays[index].hotels[0] && tripdays[index].hotels[0].name
            ? getNameHotel(tripdays[index].hotels[0])
            : getNameTripday(tripdays[index]),
        toIcon: toHotel ? <MdHotel className={iconClassName} /> : <MdLocationOn className={iconClassName} />,
        tripdayIndex: i,
      });

      // If case 3, we have to add another transportation to the next tripday
      // if ((lastTransport.arrivalDayIndex === 0 || !lastTransport.arrivalDayIndex) && tripday.location && !isCaseOne) {
      //   const toHotelNextTripday = tripdays[index + 1].hotels.length > 0 && tripdays[index + 1].hotels[0].location;
      //   if (!tripdays[index + 1] || !tripdays[index + 1].location) {
      //     return;
      //   }
      //   if (
      //     isCloserThan(
      //       toHotel ? tripdays[index].hotels[0].location!.coordinates! : tripdays[index].location!.coordinates!,
      //       toHotelNextTripday
      //         ? tripdays[index + 1].hotels[0].location!.coordinates!
      //         : tripdays[index + 1].location!.coordinates!,
      //       1,
      //     )
      //   ) {
      //     return;
      //   }
      //   transportations.push({
      //     type: TRANSPORTATION_TYPE.CAR,
      //     departureLocation: {
      //       coordinates: toHotel
      //         ? tripdays[index].hotels[0].location!.coordinates
      //         : tripdays[index].location!.coordinates,
      //       ...basicProperties,
      //     },
      //     arrivalLocation: {
      //       coordinates: toHotelNextTripday
      //         ? tripdays[index + 1].hotels[0].location!.coordinates
      //         : tripdays[index + 1].location!.coordinates,
      //       ...basicProperties,
      //     },
      //     fromName: toHotel ? getNameHotel(tripdays[index].hotels[0]) : getNameTripday(tripdays[index]),
      //     fromIcon: toHotel ? <MdHotel className={iconClassName} /> : <MdLocationOn className={iconClassName} />,
      //     toName: toHotelNextTripday
      //       ? getNameHotel(tripdays[index + 1].hotels[0])
      //       : getNameTripday(tripdays[index + 1]),
      //     toIcon: toHotelNextTripday ? (
      //       <MdHotel className={iconClassName} />
      //     ) : (
      //       <MdLocationOn className={iconClassName} />
      //     ),
      //     tripdayIndex: i + 1,
      //   });
      // }
    }
  };

  const handleImpliedTrainTransportations = (
    tripday: TripDay,
    lastTransport: any,
    i: number,
    arrivalOfDay: number[],
    toHotel: boolean,
  ) => {
    // Defining a flight can be done in 3 different ways:
    // 1. DAY 0 -> DAY 1 with arrivaldayindex of 0 on DAY 0
    // 2. DAY 0 -> DAY 1 with arrivaldayindex of 1 on DAY 1
    // 3. DAY 1 -> DAY 1 with arrivaldayindex of 0 on DAY 1
    // Thus, next tripdayindex is always current tripdayindex + arrivaldayindex
    // And case 1 needs +1 -> calculate distance of departureLocation of flight and current tripday location
    // If these are not close, then this is case 1

    const isCaseOne =
      !tripdays[i].location ||
      ((!lastTransport.arrivalDayIndex || lastTransport.arrivalDayIndex == 0) &&
        !isCloserThan(
          tripdays[i].location!.coordinates!,
          [lastTransport.arrivalLocation.coordinates[0], lastTransport.arrivalLocation.coordinates[1]],
          300,
        ));

    const index = (lastTransport.arrivalDayIndex ?? 0) + i + (isCaseOne ? 1 : 0);

    if (!tripdays[index] || !tripdays[index].location) {
      return;
    }

    // const fromHotel = tripdays[i].hotels && tripdays[i].hotels.length > 0 && tripdays[i].hotels[0].location;

    // Transport to airport
    // if (tripday.location) {
    //   transportations.push({
    //     type: TRANSPORTATION_TYPE.CAR,
    //     departureLocation: {
    //       coordinates: [tripday.location.coordinates![0], tripday.location.coordinates![1]],
    //       ...basicProperties,
    //     },
    //     arrivalLocation: {
    //       coordinates: [lastTransport.departureLocation.coordinates[0], lastTransport.departureLocation.coordinates[1]],
    //       ...basicProperties,
    //     },
    //     fromName: true ? getNameHotel(tripdays[i].hotels[0]) : getNameTripday(tripdays[i]),
    //     fromIcon: <MdLocationOn className={iconClassName} />,
    //     toName: getToNameTransport(lastTransport as TTransportation),
    //     toIcon: <TbBuildingTunnel className={iconClassName} />,
    //     tripdayIndex: i,
    //   });
    // }

    // const toHotel = tripdays[index].hotels && tripdays[index].hotels.length > 0 && tripdays[index].hotels[0].location;
    const departureCoords = [
      lastTransport.arrivalLocation.coordinates[0],
      lastTransport.arrivalLocation.coordinates[1],
    ];
    // Hotel or next day location
    // const arrivalCoords = toHotel
    //   ? tripdays[index].hotels[0].location!.coordinates
    //   : tripdays[index].location?.coordinates;

    if (arrivalOfDay && isImpliedTransportLongEnough(departureCoords, arrivalOfDay)) {
      // transportations.push({
      //   type: TRANSPORTATION_TYPE.CAR,
      //   departureLocation: {
      //     coordinates: [lastTransport.departureLocation.coordinates[0], lastTransport.departureLocation.coordinates[1]],
      //     ...basicProperties,
      //   },
      //   arrivalLocation: {
      //     coordinates: arrivalOfDay,
      //     ...basicProperties,
      //   },
      //   fromName: getToNameTransport(lastTransport as TTransportation),
      //   fromIcon: <TbBuildingTunnel className={iconClassName} />,
      //   toName:
      //     toHotel && tripdays[index].hotels.length > 0 && tripdays[index].hotels[0].name
      //       ? getNameHotel(tripdays[index].hotels[0])
      //       : getNameTripday(tripdays[index]),
      //   toIcon: toHotel ? <MdHotel className={iconClassName} /> : <MdLocationOn className={iconClassName} />,
      //   tripdayIndex: i,
      // });

      // If case 3, we have to add another transportation to the next tripday
      if ((lastTransport.arrivalDayIndex === 0 || !lastTransport.arrivalDayIndex) && tripday.location && !isCaseOne) {
        // const toHotelNextTripday = tripdays[index + 1].hotels.length > 0 && tripdays[index + 1].hotels[0].location;
        // if (!tripdays[index + 1] || !tripdays[index + 1].location) {
        //   return;
        // }
        // if (
        //   isCloserThan(
        //     toHotel ? tripdays[index].hotels[0].location!.coordinates! : tripdays[index].location!.coordinates!,
        //     toHotelNextTripday
        //       ? tripdays[index + 1].hotels[0].location!.coordinates!
        //       : tripdays[index + 1].location!.coordinates!,
        //     1,
        //   )
        // ) {
        //   return;
        // }
        // transportations.push({
        //   type: TRANSPORTATION_TYPE.CAR,
        //   departureLocation: {
        //     coordinates: toHotel
        //       ? tripdays[index].hotels[0].location!.coordinates
        //       : tripdays[index].location!.coordinates,
        //     ...basicProperties,
        //   },
        //   arrivalLocation: {
        //     coordinates: toHotelNextTripday
        //       ? tripdays[index + 1].hotels[0].location!.coordinates
        //       : tripdays[index + 1].location!.coordinates,
        //     ...basicProperties,
        //   },
        //   fromName: toHotel ? getNameHotel(tripdays[index].hotels[0]) : getNameTripday(tripdays[index]),
        //   fromIcon: toHotel ? <MdHotel className={iconClassName} /> : <MdLocationOn className={iconClassName} />,
        //   toName: toHotelNextTripday
        //     ? getNameHotel(tripdays[index + 1].hotels[0])
        //     : getNameTripday(tripdays[index + 1]),
        //   toIcon: toHotelNextTripday ? (
        //     <MdHotel className={iconClassName} />
        //   ) : (
        //     <MdLocationOn className={iconClassName} />
        //   ),
        //   tripdayIndex: i,
        // });
      }
    }
  };

  // Array to add all defined transportations and all implied transportations
  // An implied transportation is transportation that's not added in backoffice but should be shown
  const transportations: Partial<TTransportation>[] = [];

  if (isDirectionsDisabled) {
    tripdays
      .filter((tripday) => tripday.location)
      .forEach((tripday, i) => {
        if (!tripday.location || !tripdays[i + 1] || !tripdays[i + 1].location) return;
        const commonProperties = {
          type: TRANSPORTATION_TYPE.CAR,
          departureLocation: { coordinates: tripday.location.coordinates, ...basicProperties },
          arrivalLocation: { coordinates: tripdays[i + 1].location!.coordinates, ...basicProperties },
          fromName: getNameTripday(tripday),
          fromIcon: <MdLocationOn className={iconClassName} />,
          toName: getNameTripday(tripdays[i + 1]),
          toIcon: <MdLocationOn className={iconClassName} />,
          tripdayIndex: i,
        };

        transportations.push({ ...commonProperties });
      });

    return transportations;
  }

  tripdays &&
    tripdays.forEach((tripday, i) => {
      // Looping over tripdays to add explicit transportations and find implicit transportations

      const departureOfDayFromHotel =
        i > 0 && tripdays[i - 1].hotels && tripdays[i - 1].hotels.length > 0 && tripdays[i - 1].hotels[0].location;
      const departureOfDay =
        i > 0
          ? departureOfDayFromHotel
            ? tripdays[i - 1].hotels[0].location!.coordinates
            : tripdays[i - 1].location?.coordinates
          : null;

      const arrivalOfDayToHotel = tripday.hotels && tripday.hotels.length > 0 && tripday.hotels[0].location;
      const arrivalOfDay = arrivalOfDayToHotel
        ? tripday.hotels[0].location!.coordinates
        : tripday.location?.coordinates;

      // Add all defined transportations and find toName and fromName
      tripday.transportations &&
        tripday.transportations
          .filter((transport) => !isRental(transport))
          .sort((t1, t2) => t2.ord - t1.ord)
          .forEach((transport, transportIndex) => {
            const fromTransport = transportIndex > 0;
            const from = fromTransport
              ? tripday.transportations.filter((transport) => !isRental(transport))[transportIndex - 1]?.arrivalLocation
                  ?.coordinates
              : departureOfDay;
            const toTransport =
              transportIndex < tripday.transportations.filter((transport) => !isRental(transport)).length - 1;
            const to = toTransport
              ? tripday.transportations.filter((transport) => !isRental(transport))[transportIndex + 1]
                  .departureLocation?.coordinates
              : arrivalOfDay;

            // Flights are not added! These are shown seperatly
            if (
              transport &&
              isFlight(transport) &&
              transport.arrivalAirport &&
              transport.departureAirport &&
              tripdays[i].location
            ) {
              const commonProperties: TTransportation = {
                ...transport,
                tripdayIndex: i,
                toName: getToNameTransport(transport),
                toIcon: <MdLocationOn className={iconClassName} />,
                fromName: getFromNameTransport(transport),
                fromIcon: <MdLocationOn className={iconClassName} />,
              };

              transportations.push({
                ...commonProperties,
              });

              if (i + 1 < tripdays.length && arrivalOfDay) {
                // Transports to the next day are not added because they are implied
                handleImpliedFlightTransportations(tripdays[i], transport, i + 1, arrivalOfDay, !!arrivalOfDayToHotel);
              }

              if (departureOfDay) {
                if (
                  !isCloserThan(departureOfDay, [transport.departureAirport.lon, transport.departureAirport.lat], 100)
                ) {
                  return;
                }

                // if (
                //   arrivalOfDay &&
                //   isCloserThan(departureOfDay, [transport.departureAirport.lon, transport.departureAirport.lat], 100)
                // ) {
                //   // Transport to airport
                //   transportations.push({
                //     type: TRANSPORTATION_TYPE.CAR,
                //     departureLocation: {
                //       coordinates: departureOfDay,
                //       ...basicProperties,
                //     },
                //     arrivalLocation: {
                //       coordinates: [transport.departureAirport.lon, transport.departureAirport.lat],
                //       ...basicProperties,
                //     },
                //     fromName: !!departureOfDayFromHotel
                //       ? getNameHotel(tripdays[i - 1].hotels[0])
                //       : getNameTripday(tripdays[i - 1]),
                //     fromIcon: !!departureOfDayFromHotel ? (
                //       <MdHotel className={iconClassName} />
                //     ) : (
                //       <MdLocationOn className={iconClassName} />
                //     ),
                //     toName: getFromNameTransport(transport),
                //     toIcon: <FaPlaneDeparture className={iconClassName} />,
                //     tripdayIndex: i,
                //   });
                // }
              }

              return;
            } else if (
              transport &&
              isTrain(transport) &&
              transport.arrivalLocation &&
              transport.departureLocation &&
              tripdays[i].location
            ) {
              const commonProperties: TTransportation = {
                ...transport,
                tripdayIndex: i,
                toName: getToNameTransport(transport),
                toIcon: <MdLocationOn className={iconClassName} />,
                fromName: getFromNameTransport(transport),
                fromIcon: <MdLocationOn className={iconClassName} />,
              };

              transportations.push({
                ...commonProperties,
              });

              if (i + 1 < tripdays.length && arrivalOfDay) {
                handleImpliedTrainTransportations(tripdays[i], transport, i + 1, arrivalOfDay, !!arrivalOfDayToHotel);
              }

              return;
            }
            if (isFlight(transport) && (!transport.arrivalAirport || !transport.departureAirport)) return;
            if (!isFlight(transport) && (!transport.arrivalLocation || !transport.departureLocation)) return;

            // const filteredTransportations = transportations.filter((transport) => !isRental(transport));
            // const lastTransport = filteredTransportations?.at(-1);

            // if (i > 0 && arrivalOfDay) {
            //   if (
            //     tripday &&
            //     tripday.location &&
            //     transport &&
            //     ((isTrain(transport) && isCloserThan(transport.arrivalLocation!.coordinates!, arrivalOfDay, 1)) ||
            //       (isFlight(transport) &&
            //         isCloserThan([transport.arrivalAirport!.lat, transport.arrivalAirport!.lon], arrivalOfDay, 1)))
            //   )
            //     return;
            // }

            // if (isTrain(transport) && (!transport.departureLocation || !transport.arrivalLocation)) return;
            // if (isTrain(transport)) {
            //   const commonProperties: TTransportation = {
            //     ...transport,
            //     tripdayIndex: i,
            //     toName: getToNameTransport(transport),
            //     toIcon: <MdLocationOn className={iconClassName} />,
            //     fromName: getFromNameTransport(transport),
            //     fromIcon: <MdLocationOn className={iconClassName} />,
            //   };

            //   transportations.push({
            //     ...commonProperties,
            //   });
            //   return;
            // }

            // console.log("transport train", transport);

            if (
              from &&
              transport.departureLocation &&
              !isCloserThan(from, transport.departureLocation.coordinates!, 5)
            ) {
              transportations.push({
                type: TRANSPORTATION_TYPE.CAR,
                departureLocation: { coordinates: from, ...basicProperties },
                arrivalLocation: { coordinates: transport.departureLocation.coordinates!, ...basicProperties },
                fromName: fromTransport
                  ? getToNameTransport(tripday.transportations[transportIndex - 1])
                  : departureOfDayFromHotel
                    ? getNameHotel(tripdays[i - 1].hotels[0])
                    : getNameTripday(tripdays[i - 1]),
                fromIcon: departureOfDayFromHotel ? (
                  <MdHotel className={iconClassName} />
                ) : (
                  <MdLocationOn className={iconClassName} />
                ),
                toName: getFromNameTransport(transport),
                toIcon: <MdLocationOn className={iconClassName} />,
                tripdayIndex: i,
              });
            }

            const commonProperties: TTransportation = {
              ...transport,
              tripdayIndex: i,
              toName: getToNameTransport(transport),
              toIcon: <MdLocationOn className={iconClassName} />,
              fromName: getFromNameTransport(transport),
              fromIcon: <MdLocationOn className={iconClassName} />,
            };

            transportations.push({
              ...commonProperties,
            });

            if (
              transportIndex === tripday.transportations.length - 1 &&
              to &&
              transport.arrivalLocation &&
              !isCloserThan(to, transport.arrivalLocation.coordinates!, 5)
            ) {
              transportations.push({
                type: TRANSPORTATION_TYPE.CAR,
                departureLocation: { coordinates: transport.arrivalLocation.coordinates!, ...basicProperties },
                arrivalLocation: { coordinates: to, ...basicProperties },
                fromName: getToNameTransport(transport),
                fromIcon: <MdLocationOn className={iconClassName} />,
                toName: toTransport
                  ? getToNameTransport(tripday.transportations[transportIndex + 1])
                  : arrivalOfDayToHotel
                    ? getNameHotel(tripdays[i].hotels[0])
                    : getNameTripday(tripdays[i]),
                toIcon: arrivalOfDayToHotel ? (
                  <MdHotel className={iconClassName} />
                ) : (
                  <MdLocationOn className={iconClassName} />
                ),
                tripdayIndex: i,
              });
            }
          });

      // Search for implied transportations
      // if (
      //   tripday.transportations &&
      //   tripday.transportations.filter((transport) => !isRental(transport)).length > 0 &&
      //   i !== tripdays.length &&
      //   i > 0
      // ) {
      //   // All previous transportations should be added in order
      //   // The last transport is used to add implied transportations
      //   const filteredTransportations = transportations.filter((transport) => !isRental(transport));
      //   const lastTransport = filteredTransportations?.at(-1);

      //   const lastStraightTransport = tripdays[i - 1].transportations
      //     .filter((transport: any) => isTrain(transport) || isFlight(transport))
      //     .at(-1);

      //   if (
      //     tripdays[i - 1].transportations &&
      //     tripdays[i - 1].transportations.length > 0 &&
      //     tripday &&
      //     tripday.location &&
      //     lastStraightTransport &&
      //     ((isTrain(lastStraightTransport) && lastStraightTransport.arrivalLocation) ||
      //       (isFlight(lastStraightTransport) && lastStraightTransport.arrivalAirport)) &&
      //     ((isTrain(lastStraightTransport) &&
      //       lastStraightTransport?.arrivalLocation &&
      //       tripday.location &&
      //       isCloserThan(lastStraightTransport.arrivalLocation.coordinates!, tripday.location!.coordinates!, 10)) ||
      //       (isFlight(lastStraightTransport) &&
      //         lastStraightTransport?.arrivalAirport &&
      //         tripday.location &&
      //         isCloserThan(
      //           [lastStraightTransport?.arrivalAirport.lat, lastStraightTransport?.arrivalAirport.lon],
      //           tripday.location!.coordinates!,
      //           10,
      //         )))
      //   )
      //     // There is a straight transportation on the previous day
      //     return;

      //   if (
      //     lastTransport &&
      //     //isStraightTransportation(lastTransport) &&
      //     tripday.transportations &&
      //     tripday.transportations.length > 0 &&
      //     tripdayHasStraightTransport(tripday)
      //   )
      //     // Last transport was a flight/train and next day has another flight/train, so no implied transportations should be added
      //     return;

      //   // Add a transportation from the destination of the last transportation to the accomodation/location of the next tripday
      //   // Flight and other transportations are divided because of different properties
      //   if (
      //     lastTransport &&
      //     isFlight(lastTransport) &&
      //     lastTransport.arrivalAirport &&
      //     lastTransport.departureAirport &&
      //     tripdays[i].location
      //   ) {
      //     handleImpliedFlightTransportations(tripday, lastTransport, i);
      //   } else if (
      //     lastTransport &&
      //     isTrain(lastTransport) &&
      //     lastTransport.arrivalLocation &&
      //     lastTransport.departureLocation &&
      //     tripdays[i].location
      //   ) {
      //     handleImpliedTrainTransportations(tripday, lastTransport, i);
      //   }

      //   departureOfDay &&
      //     transportations.push({
      //       type: TRANSPORTATION_TYPE.CAR,
      //       departureLocation: { coordinates: departureOfDay, ...basicProperties },
      //       arrivalLocation: { coordinates: arrivalOfDay!, ...basicProperties },
      //       fromName:
      //         departureOfDayFromHotel && tripdays[i - 1].hotels[0].name
      //           ? getNameHotel(tripdays[i - 1].hotels[0])
      //           : getNameTripday(tripdays[i - 1]),
      //       fromIcon: departureOfDayFromHotel ? (
      //         <MdHotel className={iconClassName} />
      //       ) : (
      //         <MdLocationOn className={iconClassName} />
      //       ),
      //       toName:
      //         arrivalOfDayToHotel && tripdays[i].hotels[0].name
      //           ? getNameHotel(tripdays[i].hotels[0])
      //           : getNameTripday(tripdays[i]),
      //       toIcon: arrivalOfDayToHotel ? (
      //         <MdHotel className={iconClassName} />
      //       ) : (
      //         <MdLocationOn className={iconClassName} />
      //       ),
      //       tripdayIndex: i + 1,
      //     });
      // } else if (
      //   tripday.location &&
      //   i !== tripdays.length - 1 &&
      //   tripdays[i].location &&
      //   tripday.location.coordinates &&
      //   tripdays[i].location!.coordinates &&
      //   !isCloserThan(tripday.location.coordinates, tripdays[i].location!.coordinates!, 1)
      // ) {
      //   const straightTransports = (tripdays[i]?.transportations ?? []).filter(
      //     (transport) => isTrain(transport) || isFlight(transport),
      //   );

      //   // If no transportations are present on the tripday, add an implied transportation to the next accomodation/tripday location
      //   // First check if the transportation to the next tripday location is present on the next tripday
      //   if (
      //     straightTransports.length > 0 &&
      //     // isStraightTransportation(temp) &&
      //     tripdays[i].location &&
      //     (straightTransports[0].arrivalAirport ||
      //       (straightTransports[0].arrivalLocation &&
      //         isCloserThan(
      //           straightTransports[0].arrivalLocation.coordinates!,
      //           tripdays[i].location!.coordinates!,
      //           200,
      //         )))
      //   ) {
      //     const fromHotel = tripday.hotels && tripday.hotels.length > 0 && tripday.hotels[0].location;
      //     const departureCoords = fromHotel ? tripday.hotels[0].location!.coordinates : tripday.location.coordinates;
      //     if (
      //       straightTransports?.length > 0 &&
      //       tripdayHasStraightTransport(tripdays[i]) &&
      //       straightTransports[0].arrivalAirport &&
      //       straightTransports[0].departureAirport &&
      //       departureCoords
      //     ) {
      //       const t = straightTransports.filter((t) => isStraightTransportation(t))[0];
      //       // Transportation to the airport or trainstation, typically last day of trip
      //       if (t && isFlight(t) && t.departureAirport && t.arrivalAirport) {
      //         transportations.push({
      //           type: TRANSPORTATION_TYPE.CAR,
      //           departureLocation: {
      //             coordinates: departureCoords,
      //             ...basicProperties,
      //           },
      //           arrivalLocation: {
      //             coordinates: [t.departureAirport.lon, t.departureAirport.lat],
      //             ...basicProperties,
      //           },
      //           fromName: fromHotel ? getNameHotel(tripday.hotels[0]) : getNameTripday(tripday),
      //           fromIcon: fromHotel ? (
      //             <MdHotel className={iconClassName} />
      //           ) : (
      //             <MdLocationOn className={iconClassName} />
      //           ),
      //           toName: getFromNameTransport(tripdays[i + 1].transportations[0]),
      //           toIcon: <FaPlaneDeparture className={iconClassName} />,
      //           tripdayIndex: i,
      //         });
      //       } else if (t && isTrain(t) && t.departureLocation && t.arrivalLocation) {
      //         transportations.push({
      //           type: TRANSPORTATION_TYPE.CAR,
      //           departureLocation: {
      //             coordinates: departureCoords,
      //             ...basicProperties,
      //           },
      //           arrivalLocation: {
      //             coordinates: [t.departureLocation.coordinates![0], t.departureLocation.coordinates![1]],
      //             ...basicProperties,
      //           },
      //           fromName: fromHotel ? getNameHotel(tripday.hotels[0]) : getNameTripday(tripday),
      //           fromIcon: fromHotel ? (
      //             <MdHotel className={iconClassName} />
      //           ) : (
      //             <MdLocationOn className={iconClassName} />
      //           ),
      //           toName: getToNameTransport(tripdays[i + 1].transportations[0]),
      //           toIcon: <TbBuildingTunnel className={iconClassName} />,
      //           tripdayIndex: i,
      //         });
      //       }
      //       return;
      //     }
      //     return;
      //   }

      if (
        departureOfDay &&
        arrivalOfDay &&
        !isCloserThan(departureOfDay, arrivalOfDay, 1) &&
        tripday.transportations.filter((transport) => !isRental(transport))?.length === 0
      ) {
        if (
          i > 0 &&
          tripdays[i - 1].transportations.length > 0 &&
          tripdays[i - 1].transportations[0].arrivalLocation &&
          tripdays[i - 1].transportations[0].departureLocation &&
          isTrain(tripdays[i - 1].transportations.at(-1)) &&
          tripday.location &&
          isCloserThan(
            tripday.location.coordinates!,
            tripdays[i - 1].transportations.at(-1)!.arrivalLocation!.coordinates!,
            200,
          )
        ) {
          return;
        }

        // if (
        //   i > 0 &&
        //   tripdays[i + 1].transportations.length > 0 &&
        //   isFlight(tripdays[i + 1].transportations.at(-1)) &&
        //   tripdays[i + 1].transportations.at(-1)!.arrivalAirport &&
        //   tripday.location &&
        //   isCloserThan(
        //     tripday.location.coordinates!,
        //     [
        //       tripdays[i + 1].transportations.at(-1)!.arrivalAirport!.lon,
        //       tripdays[i + 1].transportations.at(-1)!.arrivalAirport!.lat,
        //     ],
        //     200,
        //   )
        // ) {
        //   return;
        // }

        if (!isCloserThan(departureOfDay, arrivalOfDay, 700)) {
          return;
        }

        transportations.push({
          type: TRANSPORTATION_TYPE.CAR,
          departureLocation: { coordinates: departureOfDay, ...basicProperties },
          arrivalLocation: { coordinates: arrivalOfDay, ...basicProperties },
          fromName:
            departureOfDayFromHotel && tripdays[i - 1].hotels[0].name
              ? getNameHotel(tripdays[i - 1].hotels[0])
              : getNameTripday(tripdays[i - 1]),
          fromIcon: departureOfDayFromHotel ? (
            <MdHotel className={iconClassName} />
          ) : (
            <MdLocationOn className={iconClassName} />
          ),
          toName:
            arrivalOfDayToHotel && tripdays[i].hotels[0].name
              ? getNameHotel(tripdays[i].hotels[0])
              : getNameTripday(tripdays[i]),
          toIcon: arrivalOfDayToHotel ? (
            <MdHotel className={iconClassName} />
          ) : (
            <MdLocationOn className={iconClassName} />
          ),
          tripdayIndex: i,
        });
      }
      // }
    });

  return transportations;
}
