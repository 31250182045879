import { ENABLE_DESTINATIONS_IN_PROD } from "@lato/common";
import React from "react";
import { BsFileTextFill, BsFillFlagFill, BsImages, BsPinAngleFill } from "react-icons/bs";
import { MdHotel as HotelIcon } from "react-icons/md";
import { TbAirBalloon } from "react-icons/tb";
import ENV from "../../../../../utils/env";
import SideMenu, { SideMenuMultiItem } from "../../../../settings/SideMenu";
import { SettingsNavItemProps } from "../../../../settings/SideMenuNavItem";
import { NewBadge } from "../../../../layout/FeatureBadges";

export interface libraryItemsProps {
  children: React.ReactNode;
}

const mainPath = "library";
const libraryItems: SettingsNavItemProps[] = [
  {
    tabname: "Accommodations",
    icon: <HotelIcon size="18px" />,
    path: "accommodations",
    marginLeft: "-1px",
  },
  { tabname: "Activities", icon: <BsFillFlagFill size="16px" />, path: "activities" },
  { tabname: "Text Fields", icon: <BsFileTextFill size="20px" />, path: "text-fields" },
  { tabname: "Images", icon: <BsImages size="18px" />, path: "images" },
  { tabname: "POIs", icon: <BsPinAngleFill size="18px" />, path: "pois", marginLeft: "-1px" },
];

if (ENV !== "production" && !ENABLE_DESTINATIONS_IN_PROD) {
  libraryItems.push({
    tabname: "Destinations",
    icon: <TbAirBalloon size="18px" />,
    path: "destinations",
    badge: <NewBadge />,
  });
}

const LibraryItems: React.FC<libraryItemsProps> = ({ children }) => {
  return (
    <>
      <SideMenu mainPath={mainPath} heading="Library Items" navItems={libraryItems}>
        {children}
      </SideMenu>
    </>
  );
};
export default LibraryItems;
