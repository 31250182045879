import { useState } from "react";
import { Center, Text } from "@chakra-ui/react";
import { useSocketSubscribe } from "../../app/providers/SocketProvider";

const Typing = () => {
  let timer: any;
  const [typingText, setTypingText] = useState("");
  /**
   * This callback will be trigerred once Travel Agent will start typing
   */
  const typingResponseFn = () => {
    clearTimeout(timer);
    setTypingText("Traveler is typing...");
    timer = setTimeout(() => {
      setTypingText("");
    }, 3000);
  };

  // Subscribe to travelAgentIsTyping event
  useSocketSubscribe("travelerIsTyping", typingResponseFn);
  return (
    <div>
      <Center my={2}>
        <Text fontSize="xs" color="gray.500">
          {typingText}
        </Text>
      </Center>
    </div>
  );
};

export default Typing;
