import { Box, Flex, Heading, Stack, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import SideMenuNavItem, { SettingsNavItemProps, SubSideMenuNavItemProps } from "./SideMenuNavItem";

interface SideMenuProps {
  children?: React.ReactNode;
  mainPath: string;
  heading: string;
  navItems?: SettingsNavItemProps[];
  subNavItems?: SubSideMenuNavItemProps[];
  tabName?: string;
}
const SideMenu: React.FC<SideMenuProps> = ({ children, mainPath, heading, navItems, subNavItems, tabName }) => {
  const location = useLocation();
  // Scroll to first child of SideMenuNavItem if:
  //  1. the first child is not null or undefined;
  //  2. The width of the page is smaller than 992px;
  //  3. The state given to the SideMenuNavItem buttons is not null (Otherwise clicking on settings button also scrolls to first child)
  const firstChildRef = useRef<HTMLDivElement | null>(null);
  const scrollToFirstChild = () => {
    if (firstChildRef.current && window.innerWidth < 992 && location.state != null) {
      // if all these commits are defined, onclick SideMenuNavItem button it scrolls to first child
      firstChildRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    scrollToFirstChild();
  }, []);

  return (
    <Flex flexDir={{ base: "column", lg: "row" }} alignItems={"flex-start"} pos={{ base: "relative", lg: "sticky" }}>
      <MenuPanel
        mainPath={mainPath}
        heading={heading}
        navItems={navItems}
        subNavItems={subNavItems}
        tabName={tabName}
        scrollToFirstChild={scrollToFirstChild}
      />
      <Stack ref={firstChildRef} flexGrow={0} p={10} pr={{ base: "0", xl: "12" }} pl={0} spacing={5} width="100%">
        {children}
      </Stack>
    </Flex>
  );
};
export default SideMenu;

interface MenuPanelProps {
  heading: string;
  mainPath: string;
  navItems?: SettingsNavItemProps[];
  subNavItems?: SubSideMenuNavItemProps[];
  tabName?: string;
  isMulti?: boolean;
  scrollToFirstChild: () => void;
}

const MenuPanel: React.FC<MenuPanelProps> = ({
  heading,
  navItems,
  mainPath,
  subNavItems,
  tabName,
  scrollToFirstChild,
  isMulti = false,
}) => {
  const minWidth = isMulti ? "100%" : "23%";
  const width = isMulti ? "100%" : { base: "100%", lg: "23%" };
  const padding = isMulti ? 0 : { base: 0, lg: 2 };
  const paddingTop = isMulti ? 0 : { base: 5, lg: 10 };
  return (
    <Box w={width} minW={minWidth} top={5} p={padding} pl={{ base: 0, lg: 12 }} pr={{ base: 0, lg: 6 }} pt={paddingTop}>
      <VStack
        borderRadius="md"
        w="100%"
        h="100wh"
        minW={"10em"}
        spacing={0}
        backgroundColor={"white"}
        border={"1px solid"}
        borderColor={"lightGray.400"}
      >
        <Heading size="md" p={4} isTruncated>
          {heading}
        </Heading>
        {navItems?.map((navItem) => (
          <SideMenuNavItem
            key={navItem.tabname}
            tabname={navItem.tabname}
            icon={navItem.icon}
            badge={navItem.badge}
            path={"/" + mainPath + "/" + navItem.path}
            subNavItems={window.innerWidth > 992 && tabName === navItem.tabname ? subNavItems : []}
            onClick={scrollToFirstChild}
            marginLeft={navItem.marginLeft}
            locationIndex={2}
          />
        ))}
      </VStack>
    </Box>
  );
};

interface SideMenuMultiItemProps {
  children: React.ReactNode;
  mainPath: string;
  category: {
    heading: string;
    navItems?: SettingsNavItemProps[];
  }[];
}

const SideMenuMultiItem: React.FC<SideMenuMultiItemProps> = ({ children, mainPath, category }) => {
  const location = useLocation();
  // Scroll to first child of SideMenuNavItem if:
  //  1. the first child is not null or undefined;
  //  2. The width of the page is smaller than 992px;
  //  3. The state given to the SideMenuNavItem buttons is not null (Otherwise clicking on settings button also scrolls to first child)
  const firstChildRef = useRef<HTMLDivElement | null>(null);
  const scrollToFirstChild = () => {
    if (firstChildRef.current && window.innerWidth < 992 && location.state != null) {
      // if all these commits are defined, onclick SideMenuNavItem button it scrolls to first child
      firstChildRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    scrollToFirstChild();
  }, []);

  return (
    <Flex flexDir={{ base: "column", lg: "row" }} alignItems={"flex-start"}>
      <Flex
        flexDir={"column"}
        alignItems={"flex-start"}
        width={"100%"}
        maxW={{ base: "100%", lg: "25%" }}
        pos={{ base: "relative", lg: "sticky" }}
        minW={"300px"}
        pt={{ base: 0, lg: 10 }}
        gap={5}
      >
        {category.map((cat, index) => (
          <MenuPanel
            key={index}
            mainPath={mainPath}
            heading={cat.heading}
            navItems={cat.navItems}
            scrollToFirstChild={scrollToFirstChild}
            isMulti
          />
        ))}
      </Flex>
      <Stack ref={firstChildRef} flexGrow={0} pt={10} pr={{ base: "0", xl: "12" }} pl={0} spacing={5} width="100%">
        {children}
      </Stack>
    </Flex>
  );
};
export { SideMenuMultiItem };
