import { AspectRatio, Box, Button, Checkbox, Skeleton } from "@chakra-ui/react";
import {
  ImageSite,
  Image as ImageType,
  IsImage,
  TLibraryImage,
  Trip,
  TripDay,
  UserTrip,
  transformImage,
} from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import GeneralTripdayBackgroundUploader from "../../../../_app/TripDaysSwiper/GeneralTripdayBackgroundUploader";
import TripForm from "../../../../components/trips/TripForm";
import { useMeContext } from "../../../../stores/me-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { useTripDayIndex } from "../../../../components/trips/edit/daybyday/hooks/useTripDayIndex";
import { DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY, GENERAL_STEP_USERTRIP_QUERY_KEY } from "../../../../utils/constants";
import {
  getRelationMap,
  updateRelationMap,
  usePatchSplitTripday,
  usePatchSplitUserTrip,
  useSplitTripday,
  useSplitUserTrip,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { PreviewSkeleton } from "../../../../components/FullScreenSpinner";

interface TripdayPreviewProps {
  changeTripday: (i: number) => void;
  tripdayId: string;
  trip: Trip;
  disabledDaySwitch: boolean;
  i: number;
}

const TripdayPreview: React.FC<TripdayPreviewProps> = ({ changeTripday, tripdayId, trip, disabledDaySwitch, i }) => {
  const { id: userTripId } = useParams<{ id: string }>();

  tripdayId = trip?.tripdays[i]?.id ?? "";

  const {
    data: tripday,
    isLoading: isLoadingTripday,
    isFetching: isFetchingTripday,
  } = useSplitTripday(
    tripdayId,
    getRelationMap[DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY],
    DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  );

  const {
    data: usertrips,
    isFetching: isLoadingUserTrip,
    error: userTripError,
  } = useSplitUserTrip(
    userTripId ?? "",
    getRelationMap[GENERAL_STEP_USERTRIP_QUERY_KEY],
    GENERAL_STEP_USERTRIP_QUERY_KEY,
  );

  if (isLoadingUserTrip || isFetchingTripday || !tripday || !usertrips) return <PreviewSkeleton />;

  const usertrip = usertrips[usertrips.length - 1];

  return (
    <TripdayPreviewForm
      changeTripday={changeTripday}
      tripday={tripday}
      usertrip={usertrip}
      tripdayId={tripdayId}
      trip={trip}
      disabledDaySwitch={disabledDaySwitch}
    />
  );
};
export default React.memo(TripdayPreview);

interface TripdayPreviewFormProps {
  changeTripday: (i: number) => void;
  tripday: TripDay;
  trip: Trip;
  usertrip: UserTrip;
  disabledDaySwitch: boolean;
  tripdayId: string;
}

const TripdayPreviewForm: React.FC<TripdayPreviewFormProps> = ({
  changeTripday,
  tripday,
  trip,
  usertrip,
  tripdayId,
  disabledDaySwitch,
}) => {
  const formId = `2-${DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY}`;
  const queryClient = useQueryClient();

  const { trip: tripFormStore, setTrip, collaboratorCanEdit, isFirst } = useTripFormStore();

  const formMethods = useForm<TripDay>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: tripday,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  const { mutateAsync: updateTripday, isPending: isUpdatingBackground } = usePatchSplitTripday(
    tripdayId ?? tripday.id,
    updateRelationMap[DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY],
    queryClient,
    DAY_BY_DAY_STEP_TRIP_IMAGES_QUERY_KEY,
  );

  const handleSubmit = async (e: any) => {
    if (e) return;

    const tripValues = formMethods.getValues();

    // Set the images in the store, otherwise setting event/hotel images will overwrite this image
    // because the trip in the store doesn't have images
    setTrip({
      ...tripFormStore,
      tripdays: tripFormStore.tripdays.map((td: TripDay) => ({
        ...td,
        titles: tripValues.titles,
        image: tripValues.image,
        libraryImage: tripValues.libraryImage,
      })),
    });
    await updateTripday({
      id: tripday.id,
      image: tripValues.image,
      libraryImage: tripValues.libraryImage,
      titles: tripValues.titles,
    });
  };

  return (
    <TripForm defaultValues={tripday} formId={formId} formMethods={formMethods} onSubmit={handleSubmit}>
      <Button type="submit" hidden className="form-submit-button">
        Save
      </Button>

      <ActualTripdayPreview
        tripday={tripday}
        changeTripday={changeTripday}
        handleSubmit={handleSubmit}
        trip={trip}
        usertrip={usertrip}
        disabledDaySwitch={disabledDaySwitch}
        isUpdatingBackground={isUpdatingBackground}
      />
    </TripForm>
  );
};

interface ActualTripdayPreviewProps {
  changeTripday: (i: number) => void;
  tripday: TripDay;
  handleSubmit: (e: any) => Promise<void>;
  usertrip: UserTrip;
  trip: Trip;
  disabledDaySwitch: boolean;
  isUpdatingBackground: boolean;
}

const ActualTripdayPreview: React.FC<ActualTripdayPreviewProps> = ({
  tripday,
  changeTripday,
  handleSubmit,
  usertrip,
  trip,
  disabledDaySwitch,
  isUpdatingBackground,
}) => {
  const me = useMeContext();
  const queryClient = useQueryClient();
  const { id: userTripId } = useParams<{ id: string }>();
  const tripdayIndex = useTripDayIndex();

  const { isFirst } = useTripFormStore();

  const tripStartDate = trip?.start_date;
  const libraryImage = tripday.libraryImage;
  const externalImage = tripday.image;
  const tripdayImage: ImageType | TLibraryImage | undefined = externalImage ?? libraryImage;

  const homeBackgroundNumber = usertrip?.homeBackgroundNumber;

  const { mutateAsync: updateUserTrip } = usePatchSplitUserTrip(
    userTripId!,
    updateRelationMap[GENERAL_STEP_USERTRIP_QUERY_KEY],
    queryClient,
    GENERAL_STEP_USERTRIP_QUERY_KEY,
  );

  const imagePlaceholder =
    "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png";
  const tripdayBackgroundUrl =
    (tripdayImage?.site === ImageSite.CUSTOMVIDEO
      ? tripdayImage?.originUrl
      : transformImage(tripdayImage, { size: "small" })) || imagePlaceholder;

  const updateHomeBackgroundNumber = async (number: number) => {
    await updateUserTrip({
      homeBackgroundNumber: number,
    });
  };

  return (
    <Box
      className={clsx("hidden md:block shrink-0 text-white text-center", me.useNewEditTripLayout ? "w-full" : "w-64")}
    >
      <div className={me.useNewEditTripLayout ? "sticky space-y-4" : "sticky top-20"}>
        {isUpdatingBackground ? (
          <Skeleton
            className="w-full rounded-lg overflow-hidden"
            aspectRatio={me.useNewEditTripLayout ? 2.35 / 0.7 : 3 / 4}
          />
        ) : (
          <>
            <AspectRatio
              className={clsx(
                `w-full rounded-lg overflow-hidden`,
                me.useNewEditTripLayout ? "h-[12rem]" : "aspect-[3/4]",
              )}
              bgImage={
                IsImage(tripdayImage?.site ?? ImageSite.CUSTOMPICTURE)
                  ? `linear-gradient(to top, rgba(0, 67, 84, 0.5), rgba(0, 67, 84, ${tripdayBackgroundUrl ? 0.2 : 0.8})), url('${tripdayBackgroundUrl}')`
                  : ""
              }
              bgSize="cover"
              bgRepeat={"no-repeat"}
              bgPos={"center center"}
            >
              <>
                {tripdayImage?.site &&
                  !IsImage(tripdayImage?.site) &&
                  (tripdayImage?.site === ImageSite.CUSTOMVIDEO ? (
                    <iframe
                      title={`TripdayPreview-image`}
                      style={{ height: "100%", width: "240%", left: "-70%" }}
                      src={tripdayBackgroundUrl}
                    ></iframe>
                  ) : (
                    <video key={tripdayBackgroundUrl} autoPlay muted loop>
                      <source src={tripdayBackgroundUrl} type="video/mp4" />
                    </video>
                  ))}
                <GeneralTripdayBackgroundUploader
                  tripdayIndex={tripdayIndex}
                  tripdays={trip?.tripdays}
                  className="w-full h-full relative align-start"
                  first={isFirst}
                  changeTripday={changeTripday}
                  tripStartDate={tripStartDate}
                  updateTripday={handleSubmit}
                  disabledDaySwitch={disabledDaySwitch}
                  tripdayImage={tripdayImage}
                  homeBackgroundNumber={homeBackgroundNumber}
                  updateHomeBackgroundNumber={updateHomeBackgroundNumber}
                  isUpdatingBackground={isUpdatingBackground}
                  trip={trip}
                />
              </>
            </AspectRatio>

            {tripdayImage?.url && !me.useNewEditTripLayout && (
              <Checkbox
                mx="auto"
                color="black"
                mt={2}
                colorScheme="brand"
                isChecked={homeBackgroundNumber === tripdayIndex}
                isDisabled={homeBackgroundNumber === tripdayIndex || !isFirst}
                onChange={(e) => updateHomeBackgroundNumber(e.target.checked ? tripdayIndex : 0)}
              >
                Use picture on home page.
              </Checkbox>
            )}
          </>
        )}
      </div>
    </Box>
  );
};
