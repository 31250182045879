import { type UseInfiniteQueryResult, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import React from "react";
import { Filters } from "react-table";
import { mapFiltersToQueryParams } from "../../pages/trip-list-container";
import {
  useParamsInfiniteQueryHelper,
  useParamsQueryHelper,
  useTrpcInfiniteQuery,
  useTrpcQuery,
} from "./reactQueryHooks";
export type UsePaginationQueryProps<T> = {
  queryName: string;
  apiCall: () => Promise<any>;
  additionalQueryState?: object;
  options?: Omit<UseQueryOptions<T, Error, T>, "queryKey"> | undefined;
};

type ReactQueryReturn<T> =
  | UseQueryResult<PaginationReturn<T>, Error>
  | UseInfiniteQueryResult<PaginationReturn<T>, Error>;
export type UsePaginationQueryReturn<T, RQR extends ReactQueryReturn<T>> = {
  triggerFetch: ({
    page,
    step,
    q,
    order,
    orderBy,
    filters,
  }: Partial<PaginationOptions & { [key: string]: any }>) => void;
  paginationOptions: PaginationOptions;
  queryResult: RQR;
};
export type PaginationReturn<T> = {
  data: T[];
  count: number;
};

export type PaginationOptions = {
  page: number;
  step: number;
  cursor?: number;
  limit?: number;
  q: string;
  orderBy: string | undefined;
  order: "ASC" | "DESC" | undefined;
  filters?: Filters<any>;
};

export const defaultPaginationOptions: PaginationOptions = {
  page: 1,
  step: 10,
  // TODO: Vital for pagination
  // cursor: 1,
  // limit: 25,
  q: "",
  orderBy: undefined,
  order: undefined,
};

const useGeneralPaginatedQuery = <T, RQR extends ReactQueryReturn<T>>(
  { queryName, apiCall, additionalQueryState = {}, options }: UsePaginationQueryProps<T>,
  type: "regular" | "infinite",
  trpc: boolean,
): UsePaginationQueryReturn<T, RQR> => {
  const [paginationOptions, setPaginationOptions] = React.useState<PaginationOptions>({
    ...defaultPaginationOptions,
    ...additionalQueryState,
  });

  const triggerFetch: UsePaginationQueryReturn<T, RQR>["triggerFetch"] = React.useCallback(
    ({ filters, ...newPaginationOptions }) => {
      if (filters) {
        newPaginationOptions = {
          ...newPaginationOptions,
          ...mapFiltersToQueryParams(filters),
        };
      }

      // Delete all undefined values from the object.
      /*newPaginationOptions = Object.fromEntries(
        Object.entries(newPaginationOptions).filter(([, v]) => v !== undefined),
      );*/

      setPaginationOptions({
        ...paginationOptions,
        ...newPaginationOptions,
        // page: page + 1,
        // step,
        // q,
        // // If no sorting is enabled, then sort on descending order because of the creation date descending order.
        // // order: !sort || sort.desc ? "DESC" : "ASC",
        // // If no sort is enabled than return undefined.
        // // order: sort ? (sort.desc ? "DESC" : "ASC") : undefined,
        // // orderBy: sort?.id,
        // order,
        // orderBy,
      });
    },
    [setPaginationOptions],
  );

  if (!trpc) {
    const method = type === "regular" ? useParamsQueryHelper : useParamsInfiniteQueryHelper;
    const queryResult = method<PaginationReturn<T>>({
      queryKey: queryName,
      apiCall,
      // queryParams: { ...paginationOptions, ...additionalQueryState },
      queryParams: paginationOptions,
      options,
    });

    return {
      triggerFetch,
      paginationOptions,
      queryResult: queryResult as any,
    };
  } else {
    const method =
      type === "regular"
        ? useTrpcQuery(apiCall, { ...paginationOptions, ...additionalQueryState, ...options })
        : useTrpcInfiniteQuery(apiCall, { ...paginationOptions, ...additionalQueryState }, options);

    return {
      triggerFetch,
      paginationOptions,
      queryResult: method,
    };
  }
};

export const usePaginatedQuery = <T>(paginationQueryProps: UsePaginationQueryProps<T>, trpc = false) =>
  useGeneralPaginatedQuery<T, UseQueryResult<PaginationReturn<T>, Error>>(paginationQueryProps, "regular", trpc);

export const useHelperCustomInfiniteQuery = <T>(paginationQueryProps: UsePaginationQueryProps<T>, trpc = false) =>
  useGeneralPaginatedQuery<T, UseInfiniteQueryResult<PaginationReturn<T>, Error>>(
    paginationQueryProps,
    "infinite",
    trpc,
  );
