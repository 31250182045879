import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Trip, UserTrip } from "@lato/common";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMeContext } from "../../../../stores/me-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { GENERAL_STEP_TRIP_QUERY_KEY, GENERAL_STEP_USERTRIP_QUERY_KEY } from "../../../../utils/constants";
import {
  getRelationMap,
  updateRelationMap,
  usePatchSplitTrip,
  usePatchSplitUserTrip,
  useSplitTrip,
  useSplitUserTrip,
  useTripTranslations,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { tripValidationSchemas } from "../../../../validation/validationSchemas";
import { Flights } from "../../../../features/flights/Flights";
import { CustomSpinner, GeneralSkeleton, WizardSkeleton } from "../../../FullScreenSpinner";
import Overlay, { DisabledMessage } from "../../../accessiblity/overlay";
import { DocumentsFormWrapper } from "../../../../features/documents/Documents";
import RHFInput from "../../../input/RHFInput";
import RHFTranslationInput from "../../../input/RHFTranslationInput";
import Section from "../../../layout/Section";
import { Passengers } from "../../../../features/passengers/Passengers";
import TripForm from "../../TripForm";
import Wizard from "../../../../features/editTrip/wizard/Wizard";

interface CreateGeneralTripProps {
  stepNumber: number;
}

const CreateGeneralTrip: React.FC<CreateGeneralTripProps> = ({ stepNumber }) => {
  const { id: userTripId } = useParams<{ id: string }>();
  const me = useMeContext();

  const {
    trip: tripFromStore,
    isFirst,
    isSample,
    isLoadingTranslations: isLoadingTranslationsFromStore,
  } = useTripFormStore();

  const { data: tripTranslations, isLoading: isLoadingTranslations } = useTripTranslations(tripFromStore?.id ?? "");

  const { data: usertrips, isLoading: isLoadingUserTrips } = useSplitUserTrip(
    userTripId ?? "",
    getRelationMap[GENERAL_STEP_USERTRIP_QUERY_KEY],
    GENERAL_STEP_USERTRIP_QUERY_KEY,
  );

  const usertrip = usertrips && usertrips[usertrips.length - 1];

  const {
    data: trip,
    isLoading: isLoadingTrip,
    isFetching: isFetchingTrip,
    isFetchedAfterMount: isFetchedAfterMountTrip,
  } = useSplitTrip(tripFromStore?.id ?? "", GENERAL_STEP_TRIP_QUERY_KEY, getRelationMap[GENERAL_STEP_TRIP_QUERY_KEY]);

  const isLoadingTrans = (!isFetchedAfterMountTrip && isFetchingTrip) || isLoadingTranslationsFromStore;

  const renderDisabledMessage = !isFirst && trip && (
    <DisabledMessage margin={2} collaboratorCanEdit={trip.collaboratorCanEdit} />
  );

  const renderGeneralInfo =
    !isLoadingTrip && !isLoadingUserTrips && trip && usertrip && !isLoadingTrans ? (
      <GeneralInfo
        isSample={isSample}
        first={isFirst}
        usertrip={usertrip}
        trip={trip}
        tripId={tripFromStore?.id}
        userTripId={userTripId!}
      />
    ) : (
      <GeneralSkeleton />
    );

  const renderPassengers =
    trip && !isLoadingTrip && !isLoadingUserTrips ? (
      <Passengers trip_start_date={trip.start_date} trip={trip} tripId={tripFromStore?.id} />
    ) : (
      <CustomSpinner card skeleton />
    );

  const renderFlights = trip ? <Flights trip={trip} tripId={tripFromStore?.id} /> : <CustomSpinner card skeleton />;

  const renderDocumentsFormWrapper = !isLoadingTrip ? (
    <DocumentsFormWrapper
      trip={trip}
      tripId={tripFromStore?.id}
      fieldArrayName="tripdocs"
      defaultFieldArrayName="tripdefaultdocs"
    />
  ) : (
    <CustomSpinner card skeleton />
  );

  const getValues = React.useCallback(
    (value: string) => {
      return (tripTranslations as any)?.[value];
    },
    [tripTranslations],
  );

  return (
    <>
      {!me.useNewEditTripLayout &&
        (trip && usertrips ? (
          <Wizard
            trip={trip}
            collaboratorCanEdit={trip.collaboratorCanEdit}
            stepNumber={stepNumber}
            isFirst={isFirst}
            sample={isSample}
            type="Edit"
          />
        ) : (
          <WizardSkeleton />
        ))}
      {renderDisabledMessage}
      <Stack position={"relative"} spacing={5} mb={8}>
        <Overlay display={!isFirst}>
          {renderGeneralInfo}
          {!isSample && renderPassengers}
          {!isSample && renderFlights}
          {renderDocumentsFormWrapper}
        </Overlay>
      </Stack>
    </>
  );
};
export default CreateGeneralTrip;

interface GeneralInfoProps {
  isSample: boolean;
  first: boolean;
  trip: Trip;
  usertrip: UserTrip;
  tripId: string;
  userTripId: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({ isSample, first, trip, usertrip, tripId, userTripId }) => {
  const formId = `0-${GENERAL_STEP_TRIP_QUERY_KEY}`;
  const queryClient = useQueryClient();

  const { mutateAsync: updateTrip, isPending: isLoadingUpdate } = usePatchSplitTrip(
    tripId ?? "",
    updateRelationMap[GENERAL_STEP_TRIP_QUERY_KEY],
    queryClient,
    GENERAL_STEP_TRIP_QUERY_KEY,
  );

  const { mutateAsync: updateUserTrip, isPending: isLoadingUserTripUpdate } = usePatchSplitUserTrip(
    userTripId ?? "",
    updateRelationMap[GENERAL_STEP_USERTRIP_QUERY_KEY],
    queryClient,
    GENERAL_STEP_USERTRIP_QUERY_KEY,
  );

  const defaultValues = {
    id: trip?.id,
    titles: trip?.titles,
    client_name: trip?.client_name,
    userTrips: [{ id: usertrip.id, external_ref: usertrip.external_ref }],
    descriptions: trip?.descriptions,
  };

  const formMethods = useForm<Trip | UserTrip>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: defaultValues,
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(tripValidationSchemas[0], { abortEarly: false }),
  });

  const handleSubmit = async () => {
    const tripValues = formMethods.getValues() as Trip;
    const userTrip = tripValues.userTrips[0];

    await new Promise<void>(async (resolve) => {
      const tripResponse = await updateTrip({
        id: tripValues.id,
        titles: tripValues.titles,
        client_name: tripValues.client_name,
        descriptions: tripValues.descriptions,
      });
      const userTripResponse = await updateUserTrip({ external_ref: userTrip.external_ref });
      resolve();
      formMethods.reset({
        id: tripResponse.id,
        titles: tripResponse.titles,
        client_name: tripResponse.client_name,
        userTrips: [{ id: userTripResponse.id, external_ref: userTripResponse.external_ref }],
        descriptions: tripResponse.descriptions,
      });
    });
  };

  return (
    <TripForm
      formId={formId}
      triggerResetData={trip}
      defaultValues={defaultValues}
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <Button type="submit" hidden className={"form-submit-button"}>
        Save
      </Button>

      <Section title="Details" id={"details"}>
        <Flex flexDir={{ base: "column", lg: "row" }} align="stretch">
          <Box minW="50%" mr={{ base: "0", lg: "3" }} mb={{ base: "2", lg: "0" }}>
            <Stack align="stretch">
              <RHFTranslationInput
                first={first}
                collaboratorCanEdit={trip.collaboratorCanEdit}
                placeholder="Namibia and Botswana"
                name={`titles.0.content`}
                label={"Title"}
                size="md"
                showSecondTranslationInput={false}
              />
              {!isSample && <RHFInput name="client_name" label="Client name" placeholder="Family Smith" />}
              <RHFInput name={`userTrips.${first ? 0 : 1}.external_ref`} label="Ref." placeholder="#76GF89" />
            </Stack>
          </Box>
          <Flex minW="50%" gap={"0.4em"} flexDir={"column"}>
            <RHFTranslationInput
              first={first}
              collaboratorCanEdit={trip.collaboratorCanEdit}
              placeholder={
                "E.g.: discover the Amalfi Coast: drive along stunning cliffs, explore Positano, enjoy seafood, visit the Blue Grotto, relax on beaches, and savor sunset views with Limoncello. A perfect blend of beauty and relaxation..."
              }
              name={`descriptions.0.content`}
              label={"Description"}
              size="md"
              textarea
              minHeight={"10em"}
              showSecondTranslationInput={false}
              characterLimit={500}
            />
          </Flex>
        </Flex>
      </Section>
    </TripForm>
  );
};
