import { ExternalImage, transformImage } from "@lato/common";

const transformExternalImage = (externalImage: ExternalImage): string => {
  return transformImage(
    {
      url: externalImage.urls.raw,
      // Just use Unsplash such that the imgix params are added.
      // But this will of course also add imgix params to images from pixabay which does not support that.
      site: externalImage.imageSite,
    },
    { size: "thumb" },
  );
};
export default transformExternalImage;
