import { Box, Heading } from "@chakra-ui/react";
import { GoogleAddress } from "@lato/common";
import React, { useEffect } from "react";
import { Column } from "react-table";
import EventsAPI from "../../../../../api/events.api";
import HotelsAPI from "../../../../../api/hotels.api";
import POIsAPI from "../../../../../api/pois.api";
import { ELEMENT_VIEW } from "../../../../CRUD/Resource";
import {
  ACCOMMODATIONS_ALL_QUERY_KEY,
  ACCOMMODATIONS_QUERY_KEY,
  ACTIVITIES_ALL_QUERY_KEY,
  ACTIVITIES_QUERY_KEY,
  POI_ALL_QUERY_KEY,
  POI_QUERY_KEY,
  TEXTFIELDS_QUERY_KEY,
  DESTINATIONS_QUERY_KEY,
} from "../../../../../utils/constants";
import { PaginationOptions, usePaginatedQuery } from "../../../../../utils/query-helpers/usePaginatedQuery";
import { CARDSIZES } from "../../../../CRUD/CardView";
import CRUDResource, { TableWithDrawerProps } from "../../../../CRUD/Resource";
import { LibraryItem, LibraryItemType, MarkerMapItem, MarkerMapItemType, MarkerMapsAPI } from "./LibraryItemModal";

export interface LibraryItemsTableProps<T extends LibraryItem> extends Partial<TableWithDrawerProps<any>> {
  additionalColumns?: Column<any>[];
  additionalQueryState?: object;
  eventType: LibraryItemType;
  defaultColumns: Column<any>[];
  api: any;
  resetFilters: () => void;
  enableGridView?: boolean;
  enableMapView?: boolean;
  defaultView?: ELEMENT_VIEW;
  initialFilters?: Array<any>;
  users?: string[] | undefined;
  cardViewComponent?: React.ComponentType<{ row: any; key: number; size: CARDSIZES }> | undefined;
  markerComponent?: (item: MarkerMapItem, index: number, onClick: any) => React.ReactNode;
  getEmptyItem?: (coordinates?: number[], address?: GoogleAddress, locationName?: string) => MarkerMapItem;
  focusCoordinates?: number[];
  setLocationFilter?: any;
}

export const queryKeyMap: { [key in LibraryItemType]: string } = {
  ["accommodation"]: ACCOMMODATIONS_QUERY_KEY,
  ["activity"]: ACTIVITIES_QUERY_KEY,
  ["textfield"]: TEXTFIELDS_QUERY_KEY,
  ["destination"]: DESTINATIONS_QUERY_KEY,
  ["poi"]: POI_QUERY_KEY,
};

export const titleMap: { [key in LibraryItemType]: string } = {
  ["accommodation"]: "Accommodations",
  ["activity"]: "Activities",
  ["textfield"]: "Text Fields",
  ["destination"]: "Trip Day Contents",
  ["poi"]: "Points of Interest",
};

export const apiMap: { [key in MarkerMapItemType]: MarkerMapsAPI } = {
  ["accommodation"]: HotelsAPI,
  ["activity"]: EventsAPI,
  ["poi"]: POIsAPI,
};

export const queryKeyMarkerMap: { [key in MarkerMapItemType]: string } = {
  ["accommodation"]: ACCOMMODATIONS_ALL_QUERY_KEY,
  ["activity"]: ACTIVITIES_ALL_QUERY_KEY,
  ["poi"]: POI_ALL_QUERY_KEY,
};

function LibraryItemsTable<T extends LibraryItem>({
  additionalColumns = [],
  additionalQueryState = {},
  defaultColumns,
  eventType,
  api,
  resetFilters,
  enableGridView = false,
  enableMapView = false,
  useSpaciousLayout = enableGridView || enableMapView,
  cardViewComponent,
  markerComponent,
  getEmptyItem,
  initialFilters,
  defaultView,
  focusCoordinates,
  setLocationFilter,
  ...props
}: LibraryItemsTableProps<T>) {
  // React.useEffect(() => {
  //   triggerFetch(additionalQueryState);
  // }, [additionalQueryState]);

  const formName = titleMap[eventType];

  const LS_LAYOUT_KEY = `layout-${formName}`;
  const userLayoutPref = localStorage.getItem(LS_LAYOUT_KEY) as ELEMENT_VIEW;

  const {
    paginationOptions,
    queryResult: { data, isFetching, isLoading },
    triggerFetch,
  } = usePaginatedQuery<T>({
    queryName: queryKeyMap[eventType],
    apiCall: api.getAll,
    additionalQueryState,
    options: {
      enabled: userLayoutPref !== ELEMENT_VIEW.MAP,
    },
  });
  const result = React.useMemo(() => data?.data ?? [], [data]);

  const columns = React.useMemo(() => [...defaultColumns, ...additionalColumns], [defaultColumns, additionalColumns]);

  const handleTriggerFetch = (options: PaginationOptions) => {
    if (setLocationFilter) {
      if (options.orderBy) {
        options = {
          ...options,
          filters:
            options.filters &&
            (options.filters.find((f) => f.id === "location")
              ? options.filters
              : [...options.filters, { id: "location", value: undefined }]),
        };
        setLocationFilter("");
      }
    }
    triggerFetch(options);
  };
  return (
    <CRUDResource
      {...props}
      formName={formName}
      eventType={eventType}
      data={result}
      fetchingData={isFetching}
      loadingData={isLoading}
      totalCount={data?.count ?? 0}
      //pageSize={paginationOptions.step}
      resetFilters={resetFilters}
      columns={columns}
      globalSearch={true}
      initialTableState={{ ...paginationOptions, filters: initialFilters }}
      // handleAdd={createTrip}
      // handleDelete={deleteTrip}
      // initialSortBy={initialSortBy}
      // getRowProps={rowProps}
      triggerFetch={handleTriggerFetch}
      // Renamed to enableGridView from enableCardView
      enableGridView={enableGridView}
      enableGridPadding={true}
      enableMapView={enableMapView}
      focusCoordinates={focusCoordinates}
      useSpaciousLayout={useSpaciousLayout}
      cardViewComponent={cardViewComponent}
      markerComponent={markerComponent}
      getEmptyItem={getEmptyItem}
      defaultView={defaultView}
      // enableGridView={false}
      // cardViewComponent={SingleCard}
    />
  );
}
export default React.memo(LibraryItemsTable);
