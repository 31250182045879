import { Box } from "@chakra-ui/react";
import { Color } from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorContent, EditorEvents, Extensions, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { CSSProperties, useRef, useState } from "react";
import EditorBubbleMenu, { LINK_MODAL_ID } from "./EditorBubbleMenu";
import "./Popover.scss";
import SlashCommand, { SLASH_COMMAND_ID } from "./slash-command";
import { PLUS_COMMAND_ID, PlusCommand } from "./plus-command";
import "./tiptap.scss";

export const disable_rich_text_color_feature = true;

export const getPrevText = (
  editor: Editor,
  {
    chars,
    offset = 0,
  }: {
    chars: number;
    offset?: number;
  },
) => {
  // for now, we're using textBetween for now until we can figure out a way to stream markdown text
  // with proper formatting: https://github.com/steven-tey/novel/discussions/7
  return editor.state.doc.textBetween(
    Math.max(0, editor.state.selection.from - chars),
    editor.state.selection.from - offset,
    "\n",
  );
  // complete(editor.storage.markdown.getMarkdown());
};

export interface BasicRichTextEditorProps extends CSSProperties {
  isDisabled?: boolean;
  characterLimit?: number;
}
interface RichTextEditorProps extends BasicRichTextEditorProps {
  onChange: (value: string) => void;
  defaultValue: string;
  onClick?: () => void;
  selectedLanguage?: string;
  isInline?: boolean;
  placeholder?: string;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  onChange,
  defaultValue,
  isDisabled,
  characterLimit = 5000,
  placeholder,
  onClick,
  selectedLanguage,
  isInline = false,
  ...cssprops
}) => {
  const minTextareaHeight = cssprops.minHeight ? cssprops.minHeight : "75px";
  const ref = useRef<HTMLDivElement | null>(null);

  // const [content, setContent] = useState(defaultValue);
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const onUpdate = React.useCallback(
    ({ editor }: EditorEvents["update"]) => {
      // const selection = editor.state.selection;
      // const lastChars = getPrevText(editor, {
      //   chars: 2,
      // });
      // if (lastChars === "++") {
      //   editor.commands.deleteRange({
      //     from: selection.from - 2,
      //     to: selection.from,
      //   });
      //   complete(
      //     getPrevText(e.editor, {
      //       chars: 5000,
      //     }),
      //   );
      //   // complete(e.editor.storage.markdown.getMarkdown());
      //   va.track("Autocomplete Shortcut Used");
      // } else {
      //   onUpdate(e.editor);
      //   debouncedUpdates(e);
      // }

      // dsfqds
      let content = editor.getHTML();
      const json = editor.getJSON().content;
      // Check if the editor content has an empty tag (<p></p>, <h1></h1>, ...) and remove it.
      if (Array.isArray(json) && json.length === 1 && !json[0].hasOwnProperty("content")) {
        content = ""; // or any other default value
      }
      onChange(content);
    },
    [onChange],
  );

  const extensions: Extensions = [
    StarterKit,
    Underline,
    TextStyle,
    // CharacterCount.configure({
    //   limit: characterLimit,
    // }),
    Placeholder.configure({
      placeholder: ({ node, editor }) => {
        if (placeholder && editor.getText() === "") return placeholder;

        if (node.type.name === "heading") {
          return `Heading ${node.attrs.level}`;
        }
        return "Press '+' for AI, '/' for commands...";
      },
    }),
    Link.configure({
      linkOnPaste: false,
      openOnClick: false,
    }),
    // Based on:
    // - https://github.com/steven-tey/novel
    // - https://github.com/sereneinserenade/notitap
    SlashCommand(selectedLanguage ?? ""),
    PlusCommand(selectedLanguage ?? ""),
  ];

  if (!disable_rich_text_color_feature) {
    extensions.push(
      Color.configure({
        types: ["textStyle"],
      }),
    );
  }

  const editor = useEditor({
    extensions,
    content: defaultValue,
    editable: isDisabled !== true,
    editorProps: {
      attributes: {
        class: `prose prose-p:my-2 prose-h1:my-2 prose-h2:my-2 prose-h3:my-2 prose-ul:my-2 prose-ol:my-2 max-w-none focus:outline-none w-full`,
        // class: `prose-lg prose-stone dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full`,
        suppressContentEditableWarning: "true",
      },

      transformPastedHTML: (html) => {
        let newHtml = html;

        if (html.includes("color")) {
          newHtml = newHtml.replace(/color\s*:\s*(.*?)(?=\s*;)/, ``);
        }
        return newHtml;
      },
    },
    onUpdate,
    onFocus: () => setIsEditorOpen(true),
    onBlur: (e) => {
      const clickedTarget = e.event.relatedTarget as Node;
      // The IDs of the allowed external elements. If clicked inside one of these, don't collapse the editor.
      const allowedExternalIds = [PLUS_COMMAND_ID, SLASH_COMMAND_ID, LINK_MODAL_ID];
      // Get the actual HTML elements using the IDs
      const allowedExternalElements = allowedExternalIds.map((allowedExternalId) =>
        document.querySelector("#" + allowedExternalId),
      );

      // Append the parent of the rich text editor with the allowed external elements
      const allowedElements = [ref.current, ...allowedExternalElements];
      // Check if the click occured within the editor + children of the editor (bubblemenu), if not collapse the editor
      if (ref.current && !allowedElements.some((element) => element?.contains(clickedTarget))) {
        // Only gets executed if:
        // 1. The click was outside the editor AND
        // 2. The click was not on an allowed element AND
        // 3. The blur did not happend due to a browser onBlur event (e.g. switching tabs)

        // Add a small timeout because otherwise when you immediately click a button while still in the editor, the button click will not work
        setTimeout(() => {
          // Collapse the text field after a brief delay to allow button click
          setIsEditorOpen(false);
        }, 100);
      }
    },
    // autofocus: "end",
  });

  return (
    <div className="relative" ref={ref}>
      <Box
        borderWidth="1px"
        spellCheck="true"
        borderRadius="lg"
        overflow="auto"
        bg={isInline ? "white" : "rgb(250,251,252)"}
        border={"1px solid inherit"}
        opacity={isDisabled ? 0.4 : undefined}
        _hover={{
          borderColor: "brand.200",
          cursor: isDisabled ? "not-allowed" : "text",
        }}
        minH={minTextareaHeight}
        height={isEditorOpen ? "auto" : minTextareaHeight}
        onClick={() => {
          onClick && onClick();
          editor?.chain().focus().run();
        }}
        {...cssprops}
      >
        <EditorBubbleMenu editor={editor} selectedLanguage={selectedLanguage} />
        <EditorContent editor={editor} spellCheck="true" />
        {/* {isEditorOpen && (
          <div className="absolute bottom-2 right-3 italic text-gray-500 text-xs">
            {editor.storage.characterCount.characters()}/{characterLimit}
          </div>
        )} */}
      </Box>
    </div>
  );
};
export default React.memo(RichTextEditor);
