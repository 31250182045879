import rawApiProvider from "./utilities/rawProvider";

const url = "notifications";

/**
 * GET API to fetch unread messages of all trips if any
 * @returns
 */
const getAllUnreadChatCount = () => {
  return rawApiProvider.get(`${url}/chatMessagesCountForTravelAgent`);
};

/**
 * POST API to mark message read once read by agent, this will mark message read of traveler
 * @param userTripId
 * @returns
 */
const markMessageRead = (userTripId: string) => {
  return rawApiProvider.post(`${url}/markMessageReadOfTripTraveler`, {
    userTripId,
  });
};

/**
 * TYPE of Notification API's
 */
type NotificationsAPIType = {
  getAllUnreadChatCount(): Promise<any>;
  markMessageRead(userTripId: string): Promise<any>;
};

/**
 * Named Export of Notifications API
 */
const NotificationsAPI: NotificationsAPIType = {
  getAllUnreadChatCount,
  markMessageRead,
};

export default NotificationsAPI;
