import { FormControl, FormLabel, Switch, SwitchProps } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import TimeMaskInput, { TimeMaskInputProps } from "./TimeMaskInput";

interface RHFTimeMaskInput extends TimeMaskInputProps {
  label?: string;
  inputWidth?: string;
}

const RHFTimeMaskInput: React.FC<RHFTimeMaskInput> = ({ name, label, w, width, inputWidth, ...props }) => {
  // const { field } = useController({
  //   name,
  //   // On first render, the default value of the column should be used,
  //   // subsequent renders should use the more up to date getValues defaultValue.
  //   // defaultValue: defaultV || defaultValue,
  // });

  const { register } = useFormContext();

  return (
    <FormControl
      // h="100%"
      pos="relative"
      isRequired={props.isRequired}
      bg={props.bg}
      position="relative"
      display={props.display}
      w={w ?? width}
      minW={w ?? width}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <TimeMaskInput width={inputWidth} name={name} {...props} />
    </FormControl>
  );
};
export default React.memo(RHFTimeMaskInput);
