import { Box, Button, ButtonGroup, Flex, Heading, Icon, Image, Input, Text, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ExternalImage, ImageSite, Image as ImageType, IsImage, TLibraryImage, getKeyFromImageUrl } from "@lato/common";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { IoCameraOutline as Camera } from "react-icons/io5";
import LibraryImagesAPI from "../../../api/library-images.api";
import { useMeContext } from "../../../stores/me-context";
import { LIBRARY_IMAGES_QUERY_KEY } from "../../../utils/constants";
import { prettyPrintDate } from "../../../utils/date";
import useMutation from "../../../utils/query-helpers/useMutation";
import { submitDocumentsArray } from "../../../utils/submitDocumentsArray";
import transformExternalImage from "../../../utils/transformExternalImage";
import { libraryImageSchema } from "../../../validation/validationSchemas";
import Form from "../../form/Form";
import InputWrapper from "../../input/InputWrapper";
import CustomModal from "../../layout/CustomModal";
import AutocompleteStaticMap from "../../../features/map/AutocompleteStaticMap";
import ImageUploadModal from "../../../features/external-images/ImageUploadModal";
import { getEmptyLibraryImage } from "../activities";

interface LibraryImageModalProps {
  disclosure: any;
  edit: boolean;
  image?: TLibraryImage;
  locationDisclosure: any;
  inModal?: boolean;
  onClose?: () => void;
}

const LibraryImageModal: React.FC<LibraryImageModalProps> = ({
  disclosure,
  edit,
  image,
  locationDisclosure,
  inModal = false,
  onClose,
}) => {
  const user = useMeContext();
  const emptyImageItem = getEmptyLibraryImage(user);
  const [selectedImages, setSelectedImages] = React.useState<TLibraryImage[] | undefined>(image && [image]);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [showImageError, setShowImageError] = React.useState(false);
  let imageDisclosure = useDisclosure();
  if (!edit) {
    imageDisclosure = disclosure;
  }

  const formMethods = useForm<TLibraryImage>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: image ?? emptyImageItem,
    shouldUnregister: false,
    resolver: yupResolver(libraryImageSchema),
  });

  const editImage = useMutation(
    {
      apiCall: (image: TLibraryImage) => LibraryImagesAPI.patch(image.id!, image),
      successMessage: `updated image`,
      failMessage: `updating image`,
    },
    [LIBRARY_IMAGES_QUERY_KEY],
  );

  const createImage = useMutation(
    {
      apiCall: (image: TLibraryImage[]) => LibraryImagesAPI.post({ libraryImages: image }),
      successMessage: `created images`,
      failMessage: `creating images`,
    },
    [LIBRARY_IMAGES_QUERY_KEY],
  );

  const handlePicture = (images: TLibraryImage[]) => {
    setSelectedImages(images);
    if (!edit) {
      locationDisclosure.onOpen();
      //await handleSave(images);
    }
  };

  const handleSave = async (libraryIt: TLibraryImage[] | TLibraryImage) => {
    if (!selectedImages || !selectedImages[0]) {
      setShowImageError(true);
      return;
    }
    setShowImageError(false);
    setSubmitting(true);
    const S3imageLocation = `libraryImages/${user.company.name}-${user.companyId}/`;

    if (edit) {
      libraryIt = libraryIt as TLibraryImage;
      await submitDocumentsArray(selectedImages, S3imageLocation);
      await editImage.mutate({
        ...selectedImages[0],
        location: libraryIt.location,
        name: libraryIt.name,
        id: libraryIt.id,
      });
    } else {
      libraryIt = libraryIt as TLibraryImage;
      await submitDocumentsArray(selectedImages, S3imageLocation);
      const imagesToUpload = selectedImages.map((img) => ({ ...img, location: (libraryIt as TLibraryImage).location }));
      await createImage.mutate(imagesToUpload);
    }
    setSubmitting(false);
    disclosure.onClose();
    locationDisclosure.onClose();
    onClose && onClose();
  };

  const handleChosenLinkPictures = (images: ImageType[]) => {
    const imagesToHandle = images.map((image) => ({
      ...image,
      s3: { key: image.originUrl },
      name: image.originUrl!.split("/").pop()!.split("?")[0],
      user: user,
    }));
    handlePicture(imagesToHandle);
  };

  const handleChosenExternalPicture = async (photos: ExternalImage | ExternalImage[], searchInput?: string) => {
    let name = searchInput;
    if (!searchInput || searchInput === "") {
      name = "image";
    }
    if (edit) {
      photos = [photos as ExternalImage];
    }
    handlePicture(
      (photos as ExternalImage[]).map((photo, i) => ({
        site: photo.imageSite,
        s3: { key: getKeyFromImageUrl(photo.urls.raw, photo.imageSite) },
        url: transformExternalImage(photo),
        user: user,
        ord: 0,
        name: i === 0 ? name! : `${name}-${i}`,
      })),
    );
  };

  const handleChosenLocalPicture = (blob: Blob | Blob[]) => {
    if (edit) {
      if (Array.isArray(blob)) {
        return;
      }
      blob = [blob];
    }

    handlePicture(
      (blob as Blob[]).map((item) => {
        const f = new File([item], "libraryImage", {
          type: "image/jpeg",
        });
        // create a new file with as filename the location name of the day.
        const url = URL.createObjectURL(f);
        return {
          site: ImageSite.S3,
          name: f.name,
          user: user,
          file: f,
          url: url,
          ord: 0,
        };
      }),
    );
  };

  return (
    <>
      {edit && (
        <CustomModal isOpen={disclosure.isOpen} onClose={disclosure.onClose} title={"image"}>
          <Form formMethods={formMethods} onSubmit={handleSave}>
            {/*<Box className="flex flex-col space-y-2 items-stretch" marginBottom={4}>
              <RHFInput
                // first={first}
                name={`name`}
                label="Name"
                // placeholder="Marriott Hotel"
                size="md"
                // variant="noBorders"
                h="unset"
                fontSize="25px"
              />
      </Box>*/}
            <Flex justifyContent={"space-between"} position="relative">
              <LibraryImageLocationInput />
              {image && (
                <InputWrapper right={2} w={"30%"} position="absolute" name="created_at" label={"Created"}>
                  <Input disabled value={prettyPrintDate(image.created_at)}></Input>
                </InputWrapper>
              )}
            </Flex>
            {selectedImages && selectedImages[0] ? (
              <Flex
                role="group"
                pos="relative"
                borderRadius={"md"}
                overflow="hidden"
                as="button"
                type="button"
                width={"100%"}
                maxH={"16em"}
                onClick={imageDisclosure.onOpen}
                alignItems={"center"}
                mt={4}
              >
                <Text
                  pos="absolute"
                  textAlign="center"
                  top={0}
                  w="100%"
                  bg="blackAlpha.500"
                  color="white"
                  transition=".3s ease"
                  opacity={0}
                  whiteSpace="nowrap"
                  isTruncated
                  _groupHover={{
                    opacity: 1,
                  }}
                  py={0.5}
                  px={3}
                >
                  Change Image
                </Text>
                {!IsImage(selectedImages[0].site) ? (
                  <>
                    {selectedImages[0].site === ImageSite.CUSTOMVIDEO ? (
                      <iframe
                        title="image"
                        src={selectedImages[0].url}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          pointerEvents: "none",
                        }}
                      ></iframe>
                    ) : (
                      <video
                        key={selectedImages[0].url}
                        autoPlay
                        muted
                        width="100%"
                        loop
                        disablePictureInPicture
                        controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
                      >
                        <source src={selectedImages[0].url} />
                      </video>
                    )}
                  </>
                ) : (
                  <Image key={selectedImages[0].url} width={"100%"} src={selectedImages[0].url} />
                )}
              </Flex>
            ) : (
              <Flex mt={4} width={"100%"} justifyContent={"space-around"} position="relative">
                {showImageError && (
                  <Text color="red" position="absolute" fontSize={"14px"} top={"-14px"}>
                    An image is required
                  </Text>
                )}
                <Button
                  onClick={imageDisclosure.onOpen}
                  h="auto"
                  color="brand.500"
                  background="gray.100"
                  mt={2}
                  width={"12em"}
                  height={"12em"}
                  // leftIcon={<Icon as={Camera} boxSize={4} />}
                >
                  <Flex flexDir="column" justifyContent="center" alignItems="center" my={1}>
                    <Icon as={Camera} boxSize={6} />
                    <Text fontSize="sm" fontWeight="300">
                      Image
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            )}

            <Flex mt={6}>
              <ButtonGroup ml="auto">
                <Button onClick={disclosure.onClose} colorScheme="red" variant="outline">
                  Cancel
                </Button>
                <Button onClick={formMethods.handleSubmit(handleSave)} isLoading={isSubmitting} colorScheme="brand">
                  {`Save`}
                </Button>
              </ButtonGroup>
            </Flex>
          </Form>
        </CustomModal>
      )}
      <ImageUploadModal
        aspectRatio={9 / 16}
        disclosure={imageDisclosure}
        defaultSearchValue={""}
        handleChosenLocalPictures={handleChosenLocalPicture}
        handleChosenExternalPictures={handleChosenExternalPicture}
        handleChosenLinkPictures={handleChosenLinkPictures}
        handleChosenLibraryPictures={() => {}}
        inLibrary={true}
        multiple={!edit}
        inModal={inModal}
      />
      {locationDisclosure.isOpen && (
        <LibraryImageLocationModal
          locationDisclosure={locationDisclosure}
          handleSave={handleSave}
          formMethods={formMethods}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default LibraryImageModal;

interface LibraryImageLocationModalProps {
  locationDisclosure: any;
  formMethods: any;
  handleSave: (libraryIt: TLibraryImage | TLibraryImage[]) => void;
  isSubmitting: boolean;
}

export const LibraryImageLocationModal: React.FC<LibraryImageLocationModalProps> = ({
  locationDisclosure,
  formMethods,
  handleSave,
  isSubmitting,
}) => {
  return (
    <CustomModal isOpen={locationDisclosure.isOpen} onClose={locationDisclosure.onClose} title={"location"}>
      <Box m={2} mb={4}>
        <Form formMethods={formMethods} onSubmit={handleSave}>
          <Heading size={"lg"} textAlign={"center"} mb={4}>
            Provide a location for the images
          </Heading>
          <LibraryImageLocationInput hideLabel />
          <Flex mt={6}>
            <ButtonGroup ml="auto">
              <Button onClick={locationDisclosure.onClose} colorScheme="red" variant="outline">
                Cancel
              </Button>
              <Button onClick={formMethods.handleSubmit(handleSave)} isLoading={isSubmitting} colorScheme="brand">
                {`Save`}
              </Button>
            </ButtonGroup>
          </Flex>
        </Form>
      </Box>
    </CustomModal>
  );
};

interface LibraryImageLocationInputProps {
  hideLabel?: boolean;
}

const LibraryImageLocationInput: React.FC<LibraryImageLocationInputProps> = ({ hideLabel = false }) => {
  const { field: location } = useController({
    name: `location`,
  });

  return (
    <AutocompleteStaticMap
      fromFieldName={`location`}
      setGeometryFromValue={(coordinates: any, name: string, address: any) => {
        if (coordinates === null) {
          location.onChange(null);
        } else {
          location.onChange({
            name: name,
            coordinates,
            address: address,
          });
        }
      }}
      mapMargin={"0.3em"}
      fromLocationValue={location.value}
      hideLabel={hideLabel}
      inputWidth={"50%"}
    ></AutocompleteStaticMap>
  );
};
