import {
  Box,
  BoxProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
} from "@chakra-ui/react";
import React, { FocusEvent, ReactElement } from "react";
import { DeepMap, FieldError, RegisterOptions, useFormContext } from "react-hook-form";
import CoreInput from "./CoreInput";

export type RHFInputProps = InputProps & {
  name: string;
  label?: string | React.ReactElement;
  textarea?: boolean | string;
  layout?: "horizontal" | "floating" | "normal" | "inline";
  // selectOptions?: string[];
  conditionalUP?: boolean;
  helperText?: React.ReactElement;
  registerOptions?: RegisterOptions;
  controlled?: boolean;
  mask?: string | Array<string | RegExp>;
  maskPlaceholder?: string | null;
  alwaysShowMask?: boolean;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  isRequired?: boolean;
  inputWidth?: BoxProps["width"];
  inputHeight?: string;
  headerButton?: any;
  labelWidth?: BoxProps["width"];
  classNameFormControl?: string;
  bottomMarginLabel?: string;
  showError?: boolean;
  isInline?: boolean;
  className?: string;
  fontSizeInline?: string;
};

export const moveLabelUp = "translate(-8px, -20px) scale(0.85)";

export function getFieldErrors(fieldName: string, errors: DeepMap<Record<string, any>, FieldError>) {
  const parts = fieldName.split(/\.\[|\[|\]\.|\./);
  let result = errors;
  for (let i = 0; i < parts.length; i++) {
    result = result?.[parts[i]];
  }
  return result;
}

const RHFInput = React.forwardRef<HTMLInputElement, RHFInputProps>(
  (
    {
      name,
      label,
      placeholder,
      layout = "normal",
      conditionalUP,
      helperText,
      controlled = false,
      w,
      width,
      inputWidth,
      inputHeight,
      labelWidth = "100%",
      headerButton,
      classNameFormControl,
      bottomMarginLabel = "0.2em",
      showError = true,
      fontSizeInline,
      ...props
    },
    ref,
  ) => {
    const {
      register,
      control,
      formState: { errors },
      getValues,
    } = useFormContext();

    const initialFieldValue = getValues(name);
    const initialValue = conditionalUP || props.defaultValue || initialFieldValue;
    const [transition, setTransition] = React.useState(initialValue ? moveLabelUp : "");

    React.useEffect(() => {
      if (conditionalUP !== undefined) setTransition(conditionalUP ? moveLabelUp : "");
    }, [conditionalUP]);

    const handleBlur = (e: FocusEvent<any>) => {
      register(name).onBlur(e);

      if (layout === "floating") {
        e.target.placeholder = "";
        setTransition(e.target.value ? moveLabelUp : "");
      }

      if (props.onBlur) {
        props.onBlur(e);
      }
    };

    const renderFormControl = () => {
      return (
        <FormControl
          isInvalid={!!errors[name]}
          bg={props.bg}
          pos="relative"
          pt={layout === "floating" && label ? 3 : 0}
          display={props.display}
          isRequired={props.isRequired}
          w={w ?? width}
          p={0}
          className={classNameFormControl}
        >
          {renderInput()}
        </FormControl>
      );
    };

    const renderInput = () => {
      switch (layout) {
        case "floating":
          return renderFloatingLayout();
        case "horizontal":
          return renderHorizontalLayout();
        case "normal":
          return renderNormalLayout();
        case "inline":
          return renderInlineLayout();
        default:
          return renderFloatingLayout();
      }
    };

    const renderInlineLayout = () => {
      return (
        <>
          <CoreInput
            {...props}
            ref={ref}
            border={0}
            bgColor="transparent"
            p={0}
            width={width}
            m={0}
            minH={inputHeight}
            onBlur={handleBlur}
            name={name}
            placeholder={placeholder}
            register={!controlled ? register : undefined}
            fontSize={fontSizeInline}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
        </>
      );
    };

    const renderFloatingLayout = () => {
      return (
        <>
          {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
          <CoreInput
            {...props}
            ref={ref}
            width={inputWidth}
            minH={inputHeight}
            onBlur={handleBlur}
            name={name}
            placeholder={placeholder}
            register={!controlled ? register : undefined}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
          {errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
        </>
      );
    };

    const renderHorizontalLayout = () => {
      return (
        <Flex
          alignItems={{ base: "left", sm: "center" }}
          display={{ base: "flex", sm: "static" }}
          flexDir={{ base: "column", sm: "row" }}
          mt={{ base: "2", sm: "0" }}
          mb={{ base: "2", sm: "0" }}
        >
          {label && (
            <Box w={labelWidth}>
              <FormLabel m={0} htmlFor={name} whiteSpace="nowrap" variant="horizontal" w={labelWidth}>
                {label}
              </FormLabel>
              {helperText && (
                <FormHelperText color="realGray.400" mt="-0.1rem" w={labelWidth === "100%" ? "auto" : "70%"}>
                  {helperText}
                </FormHelperText>
              )}
            </Box>
          )}
          <Box ml={labelWidth === "100%" ? "auto" : "0"} minW="60%" mt={{ base: "2", sm: "0" }}>
            <CoreInput
              {...props}
              ref={ref}
              width={inputWidth}
              minH={inputHeight}
              onBlur={handleBlur}
              name={name}
              placeholder={placeholder}
              register={!controlled ? register : undefined}
            />
            {errors[name] && showError && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
          </Box>
        </Flex>
      );
    };

    const renderNormalLayout = () => {
      return (
        <>
          {label && (
            <FormLabel htmlFor={name} whiteSpace="nowrap" overflow="hidden" isTruncated mb={"3px"} w={labelWidth}>
              {label}
            </FormLabel>
          )}
          <CoreInput
            {...props}
            ref={ref}
            width={inputWidth}
            minH={inputHeight}
            onBlur={handleBlur}
            name={name}
            placeholder={placeholder}
            register={!controlled ? register : undefined}
          />
          {helperText && (
            <FormHelperText mt="-0.5" mx="1px" color="realGray.400">
              {helperText}
            </FormHelperText>
          )}
          {errors[name] && showError && (
            <FormErrorMessage mt="-0.5" mx="1px">
              {errors[name].message}
            </FormErrorMessage>
          )}
        </>
      );
    };

    return renderFormControl();
  },
);

RHFInput.displayName = "RHFInput";
export default React.memo(RHFInput);
