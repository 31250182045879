// The polyfills to support older browser versions (should always be the first import statement)
import "react-app-polyfill/stable";

// import "./wdyr"; // <--- first import

import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import theme from "../theme/index";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "../reportWebVitals";

// Makes sure that the ugly blue outlines only appear when keyboard navigating.
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { httpBatchLink } from "@trpc/client";
import "focus-visible/dist/focus-visible";
import { RouterProvider, createBrowserRouter, type RouteObject } from "react-router-dom";
import { trpc } from "../../trpc";
import NotificationManager from "../features/Notifications/NotificationManager";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import config from "../config";
import NotificationProvider from "./providers/NotificationProvider";
import SocketProvider from "./providers/SocketProvider";
import "../styles/index.css";
import NotFound from "../pages/NotFound";
import ENV from "../utils/env";
import { getRoutes } from "../utils/routes";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Create new Query Client for React Query
const queryClient = new QueryClient();
const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: `${config[ENV].api_url}/api/v1/trpc`,
    }) as any,
  ],
});
// Initialize sentry logging
Sentry.init({
  dsn: "https://29c04ec4d1fe4ba98bcf03d935004a62@o1009541.ingest.sentry.io/5973702",
  integrations: [new BrowserTracing()],
  enabled: ENV === "staging" || ENV === "production",
  environment: ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
});

const routes: RouteObject[] = [
  ...getRoutes({ flatten: true, onlyNavs: false }).map(({ path, type, Component, ...rest }) => {
    const Wrapper = type === "normal" ? React.Fragment : type === "private" ? PrivateRoute : PublicRoute;
    const props = type === "private" ? rest : {};
    return {
      path,
      element: (
        // @ts-ignore
        <Wrapper {...props}>
          <Component />
        </Wrapper>
      ),
    };
  }),
  {
    path: "*",
    element: <NotFound />,
  },
];

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <GoogleOAuthProvider clientId={config[ENV].GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <SocketProvider>
              <NotificationProvider>
                <RouterProvider
                  router={createBrowserRouter([
                    {
                      path: "/",
                      element: <App />,
                      children: routes,
                    },
                  ])}
                />
                <NotificationManager />
              </NotificationProvider>
            </SocketProvider>
          </ChakraProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </trpc.Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.unregister();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
