import { Box, Flex, Stack, useDisclosure } from "@chakra-ui/react";
import { TASK_STATUS, Task } from "@lato/common";
import React, { useState } from "react";
import { IoFlag, IoFlagOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useMeContext } from "../../stores/me-context";
import { mapFiltersToQueryParams } from "../../pages/trip-list-container";
import { usePaginatedTasks } from "../../utils/query-helpers/reactQueryHooks";
import { PaginationOptions, defaultPaginationOptions } from "../../utils/query-helpers/usePaginatedQuery";
import TaskPopover from "../calenderOverview/TaskPopover";
import TaskModal from "../tasks/TaskModal";
import { SeperatedHeading } from "../../components/SeperateHeading";
import Quotes from "../../components/Quotes";

export const UpcomingTasks: React.FC = () => {
  const navigate = useNavigate();
  const me = useMeContext();
  const [selectedTask, setSelectedTask] = useState<Task | undefined>();
  const editDisclosure = useDisclosure();

  const defaultFilters = [
    { id: "users", value: [me?.id] },
    { id: "status", value: [TASK_STATUS.TO_DO] },
  ];

  const defaultTableState: PaginationOptions = {
    ...defaultPaginationOptions,
    filters: defaultFilters,
  };

  const { data, isFetching, isLoading, refetch } = usePaginatedTasks({
    ...defaultTableState,
    ...mapFiltersToQueryParams(defaultTableState.filters!),
  });

  const tasks = data?.data;

  const onTaskClick = (task: Task) => {
    setSelectedTask(task);
    editDisclosure.onOpen();
  };

  return (
    <>
      <Box>
        <SeperatedHeading
          title={
            <>
              <span>Tasks</span>
            </>
          }
          tooltipText="View all tasks"
          icon={IoFlagOutline}
          hoverIcon={IoFlag}
          onClick={() => {
            navigate("/tasks");
          }}
        />

        <Stack w="26em" h="18em" overflow={"scroll"} spacing={2} p={2}>
          {(isLoading || isFetching) && (
            <Box mt={1} fontSize="small">
              Loading...
            </Box>
          )}
          {tasks && tasks.length === 0 && (
            <Box mt={1} fontSize="small">
              No tasks due today.
            </Box>
          )}
          {tasks &&
            tasks.map((task) => (
              <TaskPopover key={task.id} task={task} listView={true} onTaskTitleClick={onTaskClick} dashboardView />
            ))}
        </Stack>
      </Box>
      {selectedTask && editDisclosure.isOpen && (
        <TaskModal
          modalDisclosure={editDisclosure}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          refetch={refetch}
        />
      )}
    </>
  );
};
