import { Link, Text } from "@chakra-ui/react";
import { Event, Hotel, ImageSite, Trip } from "@lato/common";
import React from "react";
import { KeepStateOptions } from "react-hook-form";
import ImageDropzone from "./dropzone/ImageDropzone";

interface TripdayImageDropzoneProps {
  aspectRatio: number;
  multiple: boolean;
  inLibrary?: boolean;
  setDropError: any;
  addImages: (images: any[]) => void;
}

export async function resetRHFValues(reset: any, newTrip: Trip | Hotel | Event, options?: KeepStateOptions) {
  await reset(newTrip, {
    keepErrors: true, // errors will not be reset
    keepDirty: false, // dirtyFields will be recalculated depending on the default values. Hence when you update a field with this reset, it will become dirty.
    keepIsSubmitted: true,
    keepTouched: true,
    keepIsValid: true,
    keepSubmitCount: true,
    keepDefaultValues: true,
    ...options,
    // Maybe add keepDefaultValues
  });
}

const TripdayImageDropzone: React.FC<TripdayImageDropzoneProps> = ({
  aspectRatio,
  multiple,
  inLibrary = false,
  setDropError,
  addImages,
}) => {
  const handlePicture = (blob: Blob[] | Blob) => {
    if (!multiple) {
      addImages([
        {
          url: URL.createObjectURL(
            new File([blob as Blob], "file", {
              type: "image/jpeg",
            }),
          ),
          type: ImageSite.CUSTOMPICTURE,
          file: blob,
        },
      ]);
    }
    if (!Array.isArray(blob)) {
      blob = [blob];
    }
    if (blob && blob.length > 100) {
      setDropError("You can only add up to 100 images to the library at a time.");
      return;
    }
    addImages(
      blob.map((blb) => ({
        url: URL.createObjectURL(
          new File([blb], "file", {
            type: "image/jpeg",
          }),
        ),
        file: blb,
        type: ImageSite.CUSTOMPICTURE,
      })),
    );
    setDropError(undefined);
  };

  return (
    <>
      <ImageDropzone handleDroppedImages={handlePicture} multiple={multiple} />
      <Text margin="auto" w="fit-content" mb={4}>
        Maximum 10MB. Please use{" "}
        <Link href="https://compressjpeg.com/" color="brand.500" isExternal>
          this site
        </Link>{" "}
        to compress your image if it is too large.
      </Text>

      {/* <ImageUploader
        submitPicture={submitPicture}
        type="image"
        aspectRatio={aspectRatio}
        dropzone={true}
        cropper={false}
      > */}
      {/* <Flex
          w="120px"
          h="120px"
          p={2}
          borderRadius="md"
          // overflow="hidden"
          bg="gray.200"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        > */}
      {/* {tripday.imageUrl ? (
          <Box pos="relative">
            <IconButton
              right={-2}
              top={-2}
              size="sm"
              pos="absolute"
              borderRadius="full"
              aria-label="Remove tripday image"
              icon={<CloseIcon />}
              onClick={(e) => {
                e.stopPropagation();
                console.log("Remove image");
                // To not upload the file and s3key to the server
                if (file != undefined) {
                  // If the image was just uploaded, revoke the temporary URL
                  URL.revokeObjectURL(imageUrl!);
                }
                setS3key("");
                setFile(null);
                // To not show the file
                setImageUrl("");
              }}
            />
            <Image
              borderRadius="md"
              boxSize="100%"
              src={tripday.imageUrl}
              alt={`tripdays-${i}-image`}
              fallbackSrc="https://via.placeholder.com/100"
            ></Image>
          </Box>
        ) : (
          <Icon boxSize="50%" as={ImageAdd} />
        )} */}
      {/* <Icon boxSize="50%" as={ImageAdd} />
          <Text fontSize="sm" textAlign="center">
            Click here to add an image
          </Text>
        </Flex> */}
      {/* </ImageUploader> */}
    </>
  );
};
export default React.memo(TripdayImageDropzone);
