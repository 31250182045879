import { Box, Tooltip } from "@chakra-ui/react";
import { TRIP_STATUS, TRIP_TRAVELING_STATUS } from "@lato/common";
import React from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { IoEarthOutline, IoEarthSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useMeContext } from "../../stores/me-context";
import { mapFiltersToQueryParams } from "../../pages/trip-list-container";
import { STORAGE_KEY } from "../../utils/checkOldStorage";
import { PAST_TRIPS, TRAVELING_TRIPS, UPCOMING_TRIPS } from "../../utils/constants";
import ENV from "../../utils/env";
import { usePaginatedTrips } from "../../utils/query-helpers/reactQueryHooks";
import { PaginationOptions, defaultPaginationOptions } from "../../utils/query-helpers/usePaginatedQuery";
import { useHistoryStack } from "../../utils/useListenRouter";
import EmblaSwiper from "../../components/embla-swiper/EmblaSwiper";
import { SeperatedHeading } from "../../components/SeperateHeading";
import { TripCard } from "./TripCard";

interface UpcomingTripsProps {}

export const UpcomingTrips: React.FC<UpcomingTripsProps> = () => {
  const me = useMeContext();
  const push = useHistoryStack((state: any) => state.push);

  const navigate = useNavigate();
  const listName = "trips";
  const LS_FILTER_KEY = STORAGE_KEY(listName, me.id, ENV);

  const defaukltFiltersPast = [
    { id: "statuses", value: [TRIP_STATUS.BOOKED] },
    { id: "users", value: [me.id] },
    { id: "companies", value: [me?.company.id] },
    { id: "travelingStatuses", value: ["Past"] },
    { id: "orderBy", value: ["start_date"] },
    { id: "order", value: "DESC" },
  ];

  const defaultFiltersTravelling = [
    { id: "statuses", value: [TRIP_STATUS.BOOKED] },
    { id: "users", value: [me.id] },
    { id: "companies", value: [me?.company.id] },
    { id: "travelingStatuses", value: ["Traveling"] },
    { id: "orderBy", value: ["start_date"] },
    { id: "order", value: "ASC" },
  ];

  const defaultFiltersUpcoming = [
    { id: "statuses", value: [TRIP_STATUS.BOOKED] },
    { id: "users", value: [me.id] },
    { id: "companies", value: [me?.company.id] },
    { id: "travelingStatuses", value: ["Upcoming"] },
    { id: "orderBy", value: ["start_date"] },
    { id: "order", value: "ASC" },
  ];

  const defaultTableStatePast: PaginationOptions = {
    ...defaultPaginationOptions,
    filters: defaukltFiltersPast,
    limit: 5,
  };

  const defaultTableStateTravelling: PaginationOptions = {
    ...defaultPaginationOptions,
    filters: defaultFiltersTravelling,
  };

  const defaultTableStateUpcoming: PaginationOptions = {
    ...defaultPaginationOptions,
    filters: defaultFiltersUpcoming,
    limit: 10,
  };

  const {
    data: tripsPast,
    isLoading: isLoadingTripsPast,
    error: isErrorTripsPast,
  } = usePaginatedTrips(
    {
      ...defaultTableStatePast,
      ...mapFiltersToQueryParams(defaultTableStatePast.filters!),
      sample: false,
      tasks: true,
    },
    { queryKey: [PAST_TRIPS] },
  );

  const {
    data: tripsTravelling,
    isLoading: isLoadingTripsTravelling,
    error: isErrorTripsTravelling,
  } = usePaginatedTrips(
    {
      ...defaultTableStateTravelling,
      ...mapFiltersToQueryParams(defaultTableStateTravelling.filters!),
      sample: false,
      tasks: true,
    },
    { queryKey: [TRAVELING_TRIPS] },
  );

  const {
    data: tripsUpcoming,
    isLoading: isLoadingTripsUpcoming,
    error: isErrorTripsUpcoming,
  } = usePaginatedTrips(
    {
      ...defaultTableStateUpcoming,
      ...mapFiltersToQueryParams(defaultTableStateUpcoming.filters!),
      sample: false,
      tasks: true,
    },
    { queryKey: [UPCOMING_TRIPS] },
  );

  const defaultTripListFilters = [
    { id: "search", value: "" },
    { id: "days", value: "" },
    { id: "status", value: [TRIP_STATUS.BOOKED] },
    { id: "users", value: [me?.id] },
    { id: "companies", value: [me?.company.id] },
    { id: "start_date", value: "" },
    { id: "end_date", value: "" },
    { id: "country", value: [] },
    { id: "continent", value: [] },
    { id: "languages", value: [] },
    { id: "brands", value: [] },
  ];

  const defaultTripListOptions = {
    page: 0,
    step: 20,
    q: "",
    order: undefined,
    orderBy: undefined,
    updated: new Date(),
  };

  const setLocaleUpcomingStorage = () => {
    const filters = [
      ...defaultTripListFilters,
      { id: "traveling_status", value: [TRIP_TRAVELING_STATUS.TRAVELING, TRIP_TRAVELING_STATUS.UPCOMING] },
    ];

    localStorage.setItem(LS_FILTER_KEY, JSON.stringify({ ...defaultTripListOptions, filters: filters }));
  };

  const setLocalePastStorage = () => {
    const filters = [...defaultTripListFilters, { id: "traveling_status", value: [TRIP_TRAVELING_STATUS.PAST] }];
    localStorage.setItem(LS_FILTER_KEY, JSON.stringify({ ...defaultTripListOptions, filters: filters }));
  };

  const onHeaderClick = () => {
    setLocaleUpcomingStorage();
    // Push /trips to prevent from redirecting to a certain edit trip
    push("/trips");
    navigate("/trips");
  };

  const onViewMorePastClick = () => {
    setLocalePastStorage();
    // Push /trips to prevent from redirecting to a certain edit trip
    push("/trips");
    navigate("/trips");
  };

  if (!tripsUpcoming || !tripsTravelling || !tripsPast) return null;

  const trips = [
    ...tripsPast.data.map((trip) => ({ ...trip, travelingStatus: TRIP_TRAVELING_STATUS.PAST })),
    ...tripsTravelling.data.map((trip) => ({ ...trip, travelingStatus: TRIP_TRAVELING_STATUS.TRAVELING })),
    ...tripsUpcoming.data.map((trip) => ({ ...trip, travelingStatus: TRIP_TRAVELING_STATUS.UPCOMING })),
  ];
  if (trips?.length === 0) return null;

  return (
    <Box w="100%" mt={16} position={"relative"}>
      <SeperatedHeading
        title="Departures"
        tooltipText="View more departing trips"
        icon={IoEarthOutline}
        hoverIcon={IoEarthSharp}
        onClick={onHeaderClick}
      />

      <Box className="absolute bg-gradient-to-r from-transparent from-90% via-[99%] via-[#f7fafc] to-[#f7fafc] to-100% w-full h-[20em] z-50 pointer-events-none right-0 top-0" />
      <EmblaSwiper
        mouseWheel
        renderDotNavigation={undefined}
        selectedAccommodationIndex={tripsTravelling.data?.length + tripsPast.data?.length}
        options={{ align: "start" }}
        slides={[
          <div className="w-full h-full flex justify-center items-center">
            <Tooltip label="View more past trips" aria-label="view-more-past-trips-tooltip">
              <FaChevronCircleLeft onClick={onViewMorePastClick} className="w-5 h-5 cursor-pointer text-gray-400" />
            </Tooltip>
          </div>,
          ...trips.map((userTrip: any) => (
            <TripCard
              userTrip={userTrip}
              key={userTrip.id}
              cardWidth="14em"
              cardHeight="14em"
              padding={3}
              cardSize={"md"}
              onClick={() => {
                window.open(`/trips/${userTrip.id}`);
              }}
              travelingStatus={userTrip.travelingStatus}
            />
          )),
          <div className="w-full h-full flex justify-center items-center pr-8">
            <Tooltip label="View more past trips" aria-label="view-more-past-trips-tooltip">
              <FaChevronCircleRight onClick={onHeaderClick} className="w-5 h-5 cursor-pointer text-gray-400" />
            </Tooltip>
          </div>,
        ]}
      />
    </Box>
  );
};

export default UpcomingTrips;
