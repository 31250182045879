import { encodeToLettersAndNumbersOnly } from "@lato/common";
import axios from "axios";

export const uploadToS3 = async (file: File, fileType: string, signedRequest: string) => {
  const options = {
    headers: {
      // Makes sure the file is downloaded instead of displayed when receiving it.
      "Content-Disposition": `attachment; filename="${encodeToLettersAndNumbersOnly(file.name)}"`,
      "Content-Type": fileType,
      "x-amz-acl": "public-read",
    },
  };
  await axios.put(signedRequest, file, options);
};

// Function to make sure that unrecognized file extensions by a browser get picked up correctly upon download.
export const getMimeType = (fileName: string): string | null => {
  const n = fileName.lastIndexOf(".");
  if (n === -1) return null;
  const extension = fileName.substring(n + 1);
  return mimeTypeMapping[extension] ?? null;
};
const mimeTypeMapping: { [key: string]: string } = {
  // Safari on IOS downloads .gpx files as .txt or .xml files.
  gpx: "application/gpx",
};
