import {
  Stack,
  Flex,
  VStack,
  Stat,
  Skeleton,
  StatLabel,
  StatNumber,
  chakra,
  StatArrow,
  StatHelpText,
  Box,
  Text,
  Link,
} from "@chakra-ui/react";
import { DonutChart, SparkAreaChart } from "@tremor/react";
import { useTripsStatistics, useDailyStats } from "../../utils/query-helpers/reactQueryHooks";
import { StatsSkeleton } from "../../components/FullScreenSpinner";
import ErrorCardView from "../../components/layout/ErrorCardView";
import ENV from "../../utils/env";
import { SeperatedHeadingProps } from "../../components/SeperateHeading";

type CustomStatProps = StatType & {
  isLoading: boolean;
};

export type StatisticsType = {
  total: StatisticType;
  ratioDiff: StatisticType;
  thisMonth: StatisticType;
};

type StatisticType = {
  revenue: number;
  count: number;
  booked: number;
  bookingRate: number;
};

export type StatType = {
  label: string;
  value: number | string | undefined;
  value2?: number | string | undefined;
  limit?: number | string;
  helpText?: string;
  helpText2?: string;
  ratioDiff?: string | number | undefined;
  ratioDiff2?: string | number | undefined;
  diffIncreased?: boolean | undefined;
  diffIncreased2?: boolean | undefined;
  chartArr?: any | undefined;
  showGraph?: boolean;
  onStatisticOverview?: boolean;
};

const Stats: React.FC = () => {
  const { data: tripStats, isLoading: isLoadingStats, error: statsError } = useTripsStatistics({});
  const today = new Date();
  const threeMonthsAgo = new Date(new Date().setMonth(today.getMonth() - 2));
  const todayText = today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate();
  const threeMonthsAgoText =
    threeMonthsAgo.getFullYear() + "/" + (threeMonthsAgo.getMonth() + 1) + "/" + threeMonthsAgo.getDate();
  const {
    data: dailyStats,
    isLoading: isLoadingStatsYearly,
    error: statsErrorYearly,
  } = useDailyStats({
    first_date: new Date(threeMonthsAgoText),
    second_date: new Date(new Date().getFullYear(), 11, 31),
  });
  if (isLoadingStatsYearly || isLoadingStats)
    return (
      <Stack>
        <StatsSkeleton />{" "}
        <Flex justifyContent="end" width="100%" mb={"1.7rem"}>
          <Link _hover={{ textDecoration: "none" }} href="/calendar" fontSize="md" color="realGray.600">
            Go to statistics →
          </Link>
        </Flex>
      </Stack>
    );
  const chartArrStats = tripStats?.bookedRatio ? tripStats.bookedRatio : [];
  const statData = tripStats?.result ? tripStats?.result : undefined;
  const bookingRate = [];

  for (let i = 0; i < chartArrStats.length; i++) {
    bookingRate.push({
      monthly: chartArrStats[i].past,
      rate:
        ((chartArrStats[i].bookedCount ? chartArrStats[i].bookedCount : 0) /
          (chartArrStats[i].totalCount ? chartArrStats[i].totalCount : 1)) *
        100,
    });
  }

  const currentDate = new Date();
  const formatMonthYear = (date) => {
    const month = date.getMonth() + 1; // JavaScript months are 0-indexed
    const year = date.getFullYear();
    return `${month < 10 ? "0" + month : month}-${year}`;
  };

  const revenueStats = dailyStats?.tripData ? dailyStats.tripData : [];
  // Get current month and year
  const currentMonth = formatMonthYear(currentDate);

  // Get previous month and year
  const lastMonth = formatMonthYear(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));

  // Get the month and year from two months ago
  const twoMonthsAgo = formatMonthYear(new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1));

  // Filter data based on the current month, the past month, and the month before that
  const filteredData = revenueStats
    .filter((item) => {
      const itemMonthYear = item.period.substring(3);
      return itemMonthYear === currentMonth || itemMonthYear === lastMonth || itemMonthYear === twoMonthsAgo;
    })
    .map((item) => ({ ...item, period: item.period.substring(3) }));

  const aggregationObject = filteredData.reduce((acc, item) => {
    const { period, ...otherProps } = item;
    if (!acc[period]) {
      acc[period] = { period, ...otherProps };
    } else {
      Object.keys(otherProps).forEach((key) => {
        acc[period][key] = (acc[period][key] || 0) + otherProps[key];
      });
    }
    return acc;
  }, {});

  // Convert the aggregation object into an array
  const aggregatedData = Object.values(aggregationObject);
  const valueFormatter = (number: any) => `€ ${new Intl.NumberFormat("us").format(number)}`;

  const reversedChartData = [...chartArrStats].reverse();

  const reversedBookingRate = [...bookingRate].reverse();

  //logic wether to show graph if not enough data is present
  const noBookedTrips = reversedChartData.every((obj) => !obj.hasOwnProperty("bookedCount"));
  const noRates = reversedBookingRate.every((obj) => obj.rate === 0 || obj.rate === undefined || obj.rate === null);

  const totalStats: StatType[] = [
    {
      label: "Total revenue",
      value: `€ ${Math.round(statData?.total?.revenue ?? 0).toLocaleString()}`,
      chartArr: (
        <DonutChart
          data={aggregatedData}
          category="revenue"
          index={"period"}
          valueFormatter={valueFormatter}
          colors={["blue", "cyan", "indigo"]}
          className="h-auto w-28 z-30 text-xs"
        />
      ),
      helpText: "Chart represents revenue for the  past 3 months",
      showGraph: filteredData.length >= 1,
    },
    {
      label: "Booked / Total trips",
      value: statData ? statData?.total.booked : undefined,
      value2: statData ? statData?.total.count : undefined,
      chartArr: (
        <SparkAreaChart
          stack={true}
          data={reversedChartData}
          categories={["bookedCount"]}
          index={"past"}
          colors={["zinc", "green"]}
          className="h-auto w-20 z-10"
        />
      ),
      helpText: "Graphic compared to past 3 months",
      showGraph: reversedChartData.length >= 2 && !noBookedTrips,
    },
    {
      label: "Booking rate",
      value: statData ? Math.round(statData?.total.bookingRate) + "%" : undefined,
      chartArr: (
        <SparkAreaChart
          data={reversedBookingRate}
          categories={["rate"]}
          index={"monthly"}
          colors={
            bookingRate && bookingRate.length > 0
              ? [
                  reversedBookingRate[reversedBookingRate.length - 1].rate - reversedBookingRate[0].rate >= 0
                    ? "green"
                    : "red",
                ]
              : []
          }
          className="h-30 w-32"
        />
      ),
      helpText: "Graphic compared to past 3 months",
      showGraph: reversedBookingRate.length >= 2 && !noRates,
    },
  ];

  return (
    <StatisticsSection
      isLoading={isLoadingStats || !statData || isLoadingStatsYearly}
      statData={totalStats}
      title="Overview statistics"
      morelink="/statistics"
      morelinkText="more statistics →"
      onstatisticOverview={false}
    />
  );
};

const StatsProduction: React.FC = () => {
  const { data: statData, isLoading: isLoadingStats, error: statsError } = useTripsStatistics({});

  if (isLoadingStats) return <StatsSkeleton />;
  if (statsError || !statData) return <ErrorCardView title="Could not load trips" />;

  const monthlyStats: StatType[] = statData.thisMonth &&
    statData.ratioDiff && [
      {
        label: "Trips",
        value: statData?.thisMonth.count,
        ratioDiff: statData ? Math.abs(statData.ratioDiff.count) : undefined,
        helpText:
          statData && statData.ratioDiff.count >= 0
            ? "More trips were created this month"
            : "Less trips were created this month",
        diffIncreased: statData ? statData.ratioDiff.count >= 0 : undefined,
      },
      {
        label: "Booked trips",
        value: statData?.thisMonth.booked,
        ratioDiff: statData ? Math.abs(statData.ratioDiff.booked) : undefined,
        helpText:
          statData && statData.ratioDiff.booked >= 0
            ? "More trips were sold this month"
            : "Less trips were sold this month",
        diffIncreased: statData ? statData.ratioDiff.booked >= 0 : undefined,
      },
      {
        label: "Booking rate",
        value: statData?.thisMonth.bookingRate + "%",
        ratioDiff: statData ? Math.abs(statData.ratioDiff.bookingRate) + "%" : undefined,
        helpText:
          statData && statData.ratioDiff.bookingRate >= 0
            ? "More trips were converted this month"
            : "Less trips were converted this month",
        diffIncreased: statData ? statData.ratioDiff.bookingRate >= 0 : undefined,
      },
    ];

  return (
    <StatisticsSection
      isLoading={isLoadingStats || !statData}
      statData={monthlyStats}
      title="Past 30 days statistics"
      morelink="/statistics"
      morelinkText="more statistics →"
      onstatisticOverview={false}
    />
  );
};

type StatisticsSectionProps = SeperatedHeadingProps & {
  statData: StatType[];
  isLoading: boolean;
  morelink?: string;
  morelinkText?: string;
  onstatisticOverview: boolean;
};

export const StatisticsSection: React.FC<StatisticsSectionProps> = ({
  statData,
  isLoading,
  morelink,
  morelinkText,
  onstatisticOverview = false,
  ...headingProps
}) => {
  return (
    <>
      <VStack spacing={onstatisticOverview ? 2 : 6} maxH={"100%"}>
        {statData.map((statProps, i) => (
          <CustomStat key={`totalStats-${statProps.label}-${i}`} isLoading={isLoading} {...statProps} />
        ))}
      </VStack>
      <Flex justifyContent="end" width="100%" mt={4} mb={"1.7rem"}>
        <Link _hover={{ textDecoration: "none" }} href="/calendar" fontSize="md" color="realGray.600">
          Go to statistics →
        </Link>
      </Flex>
    </>
  );
};

export const CustomStat: React.FC<CustomStatProps> = ({
  label,
  value,
  value2,
  ratioDiff,
  ratioDiff2,
  limit,
  helpText,
  isLoading,
  diffIncreased,
  diffIncreased2,
  chartArr,
  showGraph = true,
  onStatisticOverview = false,
}) => {
  return (
    <Stat px={{ base: 4, sm: 6 }} py="4" bg={"white"} shadow="base" rounded="lg" color="gray.500" w="100%">
      <Flex flexDir={"row"} gap={2} justifyContent={"space-between"}>
        <Box
          className={`max-w-lg h-full flex flex-col w-${showGraph ? "50%" : "80%"} ${showGraph ? "items-left" : "items-center"} justify-between ${disableNewStatsOnProd ? "" : "mx-auto"}`}
        >
          <Skeleton isLoaded={!isLoading}>
            <StatLabel
              textAlign={showGraph ? "left" : "center"}
              fontSize={showGraph ? "sm" : onStatisticOverview ? "lg" : "2xl"}
              fontWeight="medium"
              isTruncated
            >
              {label}
            </StatLabel>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} my={1} display={"flex"} mt={2}>
            <StatNumber display="flex" alignItems="center" lineHeight="1.2">
              <chakra.span
                fontSize={ENV === "production" ? "4xl" : showGraph ? "md" : onStatisticOverview ? "lg" : "2xl"}
                fontWeight={600}
                color={"gray.900"}
                mr={1}
                textAlign={showGraph ? "left" : "center"}
              >
                {value + "" || "\u200B"}
              </chakra.span>
              {limit && (
                <Text fontWeight="semibold" fontSize="lg">
                  {" "}
                  / {limit}
                </Text>
              )}
              {ratioDiff !== undefined && (
                <Flex
                  alignItems="center"
                  className={classNames(
                    diffIncreased == true ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800",
                    "inline-flex items-baseline rounded-full px-1.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0",
                  )}
                >
                  <StatArrow type={diffIncreased ? "increase" : "decrease"} />
                  <Text fontSize="sm" fontWeight="normal" color="gray.900">
                    {ratioDiff + "" || "\u200B"}
                  </Text>
                </Flex>
              )}
            </StatNumber>
            {value2 !== undefined && (
              <StatNumber display="flex" alignItems="center" lineHeight="1.2">
                <chakra.span fontSize={showGraph ? "sm" : "xl"} fontWeight={600} color={"gray.900"} mr={1}>
                  &ensp;/&ensp;
                </chakra.span>
                <chakra.span
                  fontSize={showGraph ? "md" : onStatisticOverview ? "lg" : "2xl"}
                  fontWeight={600}
                  color={"gray.900"}
                  mr={1}
                >
                  {value2 + "" || "\u200B"}
                </chakra.span>
                {limit && (
                  <Text alignItems="center" fontWeight="semibold" fontSize="lg">
                    {" "}
                    / {limit}
                  </Text>
                )}
                {ratioDiff2 !== undefined && (
                  <Flex
                    alignItems="center"
                    className={classNames(
                      diffIncreased2 == true ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800",
                      "inline-flex items-baseline rounded-full px-1.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0",
                    )}
                  >
                    <StatArrow type={diffIncreased2 ? "increase" : "decrease"} />
                    <Text fontSize="sm" fontWeight="normal" color="gray.900">
                      {ratioDiff2 + "" || "\u200B"}
                    </Text>
                  </Flex>
                )}
              </StatNumber>
            )}
          </Skeleton>
          {showGraph && (
            <Skeleton isLoaded={!isLoading}>
              {helpText && (
                <Skeleton isLoaded={!isLoading}>
                  <StatHelpText mt={1} fontSize="sm">
                    {helpText}
                  </StatHelpText>
                </Skeleton>
              )}
            </Skeleton>
          )}
        </Box>
        {chartArr && showGraph && (
          <Skeleton isLoaded={!isLoading} className="flex flex-1 justify-end">
            {chartArr}
          </Skeleton>
        )}
      </Flex>
    </Stat>
  );
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
