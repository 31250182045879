import TripPreviewStep from "../../../../components/trips/edit/daybyday/TripPreviewStep";
import TripStepWrapper from "../TripStepWrapper";

export const DayByDayTripRoute: React.FC = () => {
  const stepNumber = 2;

  return (
    <TripStepWrapper>
      <TripPreviewStep stepNumber={stepNumber} />
    </TripStepWrapper>
  );
};

export default DayByDayTripRoute;
