import { Box, Link, Text, useClipboard } from "@chakra-ui/react";
import React from "react";
import { BiCopy } from "react-icons/bi";
import { MdOutlineEmail } from "react-icons/md";
import { RiNumber1, RiNumber2, RiNumber3 } from "react-icons/ri";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Section, { horizontalSectionPadding } from "../../components/layout/Section";
import Settings from "../../components/settings/Settings";
import config from "../../config";
import { useMeContext } from "../../stores/me-context";
import ENV from "../../utils/env";

interface referralProps {}

export const Referral: React.FC<referralProps> = () => {
  const user = useMeContext();

  const referralLink = `${config[ENV].domain}/register/${user?.companyId}`;
  const { hasCopied, onCopy } = useClipboard(referralLink);

  return (
    <Settings>
      <Section title="Convince another Travel Agency and get free usermonths" h="auto" noHorizontalPadding>
        <Box px={horizontalSectionPadding} display="flex" flexDirection={"column"} gap={5}>
          <Text>
            <RiNumber1 size={28} style={{ marginRight: 10 }} />
            {`Another travel agency signs up using your company's unique referral link.`}
          </Text>
          <Text>
            <RiNumber2 size={28} style={{ marginRight: 10 }} />
            The other travel agency receives on their first purchase <b>3 free usermonths</b>.
          </Text>
          <Text>
            <RiNumber3 size={28} style={{ marginRight: 10 }} />
            <b>Your travel agency</b> receives on their next invoice <b>3 free usermonths</b> for each user the other
            travel agency signed up with.
          </Text>
        </Box>
      </Section>
      {ENV === "staging" ? (
        <Text color="red.500">Referral links are not supported in the staging environment.</Text>
      ) : (
        <>
          <Section title={"Your referralLink"} h="auto">
            <Box
              px={{ base: 4, sm: 6 }}
              py={3}
              bg={"white"}
              shadow="base"
              rounded="lg"
              color={hasCopied ? "white" : "gray.500"}
              backgroundColor={hasCopied ? "green.400" : "white"}
              onClick={onCopy}
              _hover={{ cursor: "pointer" }}
            >
              <Link _hover={{ textDecoration: "none" }} display="block">
                {hasCopied ? "Link copied to your clipboard" : referralLink}
                <BiCopy size="24px" style={{ float: "right" }} />
              </Link>
            </Box>
          </Section>
          <Section title="Some sharebuttons to help you get started" h="auto">
            <Box
              px={{ base: 0, md: 6 }}
              py={3}
              w="65%"
              display="flex"
              justifyContent={"flex-start"}
              gap={{ base: 2, md: 4 }}
            >
              <LinkedinShareButton url={referralLink} title={referralLink}>
                <LinkedinIcon size={48} borderRadius={5} />
              </LinkedinShareButton>
              <TwitterShareButton url={"#"} title={referralLink} className="Demo__some-network__share-button">
                <TwitterIcon size={48} borderRadius={5} />
              </TwitterShareButton>
              <FacebookShareButton url={referralLink}>
                <FacebookIcon size={48} borderRadius={5} />
              </FacebookShareButton>
              <WhatsappShareButton url={referralLink}>
                <WhatsappIcon size={48} borderRadius={5} />
              </WhatsappShareButton>
              <Link
                href={`mailto:?&subject=I would like you to know about Lato!&body=Use this link to register ${referralLink}`}
              >
                <MdOutlineEmail
                  size={48}
                  style={{
                    border: "solid 2px black",
                    borderRadius: "5px",
                    padding: "3px",
                    backgroundColor: "darkblue",
                  }}
                  color="white"
                />
              </Link>
            </Box>
          </Section>
        </>
      )}
    </Settings>
  );
};

export default Referral;
