import React, { useState } from "react";
import AuthAPI from "../api/auth.api";
import { ForgotPasswordIllustration } from "../assets/icons/MiniDrawerIcons";
import { CardWrapper } from "../components/CardWrapper";
import EmailSentView from "../components/EmailSentView";
import GeneralEmailForm from "../components/form/GeneralEmailForm";
import SplitScreenLayout from "../components/layout/SplitScreenLayout";

const ForgotPassword: React.FC = () => {
  const [complete, setComplete] = useState(false);

  const successCallback = React.useCallback(() => {
    setComplete(true);
  }, [setComplete]);

  const handleUnknownEmailAddress = React.useCallback((statusCode: number, setError: any) => {
    if (statusCode === 404) {
      // No user found exception
      // Hence, no user exists with this email address
      setError("email", {
        type: "manual",
        message: "No user exists with the given email address.",
      });
    }
  }, []);

  return (
    <SplitScreenLayout>
      {complete ? (
        <EmailSentView />
      ) : (
        <CardWrapper
          title="Forgot password?"
          description="Please enter your registered email address. We'll send instructions to help reset your password."
          topIllustration={ForgotPasswordIllustration}
        >
          <GeneralEmailForm
            successCallback={successCallback}
            buttonText="Forgot password"
            apiCall={AuthAPI.forgotPassword}
            successMessage="sent email"
            failMessage="sending email"
            handleAdditionalError={handleUnknownEmailAddress}
          />
        </CardWrapper>
      )}
    </SplitScreenLayout>
  );
};
export default ForgotPassword;
