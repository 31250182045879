import { Role } from "@lato/common";
import React from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { MeProvider } from "../../stores/me-context";
import ENV from "../../utils/env";
import { grantPermission } from "../../utils/grantPermission";
import { useMe } from "../../utils/query-helpers/reactQueryHooks";
import { ZohoSalesIQ } from "../../utils/zoho/salesIqName";
import FullScreenSpinner from "../../components/FullScreenSpinner";
import LatoPortal from "../../components/layout/LatoPortal";
import SimultaneousSessionGuard from "../../components/simultaneous-session-guard/SimultaneousSessionGuard";

type PrivateRouteProps = {
  roles: Role[];
  children: React.ReactNode;
};

// TODO: remove code duplication
const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles, children }) => {
  // const [user, setUser] = useState<any>(null);
  // const [loading, setLoading] = useState(true);
  // should be refetched in the background every 1 hour
  // TODO: will this be fetched for every private route each hour?
  const { data, isLoading, error } = useMe();
  const location = useLocation();
  const params = useParams();

  React.useEffect(() => {
    if (data) {
      if (ENV !== "development") {
        ZohoSalesIQ({ data });
      }
      // if (data.name) {
      //   if (ENV !== "production") {
      //     TawkStart({ data });
      //     Produktly({ data });
      //   }
      // }
    }
  }, [data]);
  // const { data, isLoading, error } = useMe(1000 * 60 * 60 * 1);

  // useEffect(() => {
  //   let isMounted = true;
  //   async function authenticate() {
  //     try {
  //       const res = await AuthAPI.me();
  //       const loggedInUser = res.data;
  //       if (loggedInUser && isMounted) setUser(loggedInUser);
  //       setLoading(false);
  //     } catch (error) {
  //       // Failed logging in
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   }
  //   authenticate();
  //   return () => {
  //     isMounted = false;
  //   }; // use effect cleanup to set flag false, if unmounted
  // }, []);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (isLoading) return <FullScreenSpinner />;

  if (error || !data) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={{ pathname: "/login", search: location.search }} />;
  }
  // check if route is restricted by role
  if (!grantPermission(data.role, roles)) {
    // role not authorised so redirect to home page
    return <Navigate to={{ pathname: "/test" }} />;
  }

  // Logged in
  // authorised so return component
  return (
    <MeProvider>
      {/* The beneath session guard restricts users from having multiple sessions open at the same time */}
      <SimultaneousSessionGuard />
      <LatoPortal>{children}</LatoPortal>
    </MeProvider>
  );
};
export default PrivateRoute;
