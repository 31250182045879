import { Divider, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { TripInfoWrapper } from "./TripInfo";
import SideMenuNavItem from "../../../components/settings/SideMenuNavItem";
import { useTripDayIndex } from "../../../components/trips/edit/daybyday/hooks/useTripDayIndex";
import { useMeContext } from "../../../stores/me-context";
import { useTripFormStore } from "../../../stores/trip/tripFormStore";
import { canSwitch, submitForms } from "../../../utils/EventHelper";
import { getRoute } from "./WizardTopBar";

export const InfoPanel = ({ primaryTripSteps, secondaryTripSteps, activeStep }: any) => {
  const { id: userTripId } = useParams();
  const me = useMeContext();
  const navigate = useNavigate();
  const { isSubmittingForms, isErrorForms, isInvalidForms, isSample } = useTripFormStore();
  const tripdayIndex = useTripDayIndex();

  const handleStep = (step: number) => async () => {
    if (step !== activeStep && canSwitch(isErrorForms, isInvalidForms)) {
      await submitForms(isSubmittingForms);
      if (canSwitch(isErrorForms, isInvalidForms)) {
        navigate(getRoute(userTripId!, step, me, step === 2 ? tripdayIndex : undefined, isSample));
      }
    }
  };

  return (
    <Stack className="fixed px-4 pt-4 h-full marker:space-y-1 w-48 bg-[#f7fcfc] border-r border-[#E2E8F0] text-black">
      <TripInfoWrapper />
      <Divider py={2} />
      <div className="space-y-2">
        {primaryTripSteps.map((navItem: any, index: number) => (
          <SideMenuNavItem
            key={navItem.text}
            tabname={navItem.text}
            icon={navItem.icon}
            iconSelected={navItem.selectedIcon}
            badge={navItem.badge}
            path={navItem.route}
            onClick={() => {
              handleStep(index)();
            }}
            isInTrip={true}
            count={navItem.count}
            isDisabled={!canSwitch(isErrorForms, isInvalidForms)}
          />
        ))}
        <Divider py={2} />
        {secondaryTripSteps.map((navItem: any, index: number) => (
          <SideMenuNavItem
            key={navItem.text}
            tabname={navItem.text}
            icon={navItem.icon}
            iconSelected={navItem.selectedIcon}
            badge={navItem.badge}
            path={navItem.route}
            onClick={() => {
              handleStep(primaryTripSteps.length + index)();
            }}
            isInTrip={true}
            count={navItem.count}
            isDisabled={!canSwitch(isErrorForms, isInvalidForms)}
          />
        ))}
      </div>
    </Stack>
  );
};

export default InfoPanel;
