import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { create } from "zustand";

export const useHistoryStack = create((set) => ({
  historyStack: [],
  push: (newRoute: string) =>
    set((state: any) => ({
      historyStack: [newRoute, ...state.historyStack],
    })),
}));

// this would have to go in `_app.tsx` to be a true previous route tracker (across all pages)
// Listens to all route changes and redirects to the latest subroute if there is any in the history stack.
const useRedirectToPreviousSubroute = () => {
  const historyStack = useHistoryStack((state: any) => state.historyStack);
  const push = useHistoryStack((state: any) => state.push);

  const location = useLocation();
  const navigate = useNavigate();

  console.log("historyStack", historyStack);
  useEffect(() => {
    const newPath = location.pathname;
    // Get the previous path from the history stack, if it is empty, the previous path is null.
    const previousPath = historyStack.length ? historyStack[0] : null;
    if (newPath !== previousPath) {
      // Get a subroute from the history stack.
      const previousSubroute = getPreviousSubroute(historyStack, newPath);
      if (previousSubroute) {
        // Not null so redirect to that subroute.
        navigate(previousSubroute);
        // Should not be added to the history stack because we are immediately redirected
      } else {
        // Null so no redirect is necessary
        push(newPath);
      }
    }
  }, [location]);
};
export default useRedirectToPreviousSubroute;

const getPreviousSubroute = (historyStack: string[], newPath: string): string | null => {
  // If the newly requested page is the home page then no redirect should happen.
  if (newPath === "/") return null;

  // Get the latest route from the history stack (first one is the latest) which is a subroute of the requested route.
  // For example when /trips gets requested, get the first in the stack which is of type /trips/xyz
  const previousSubrouteIndex = historyStack.findIndex(
    (entry) =>
      // Redirect back to the given previous route if it is a subroute
      entry.startsWith(newPath.endsWith("/") ? newPath : newPath + "/") &&
      // Never redirect back to the collab page
      !entry.includes("collaborate"),
  );
  // If no entry was found then -1 is returned so then the previousSubroute should be undefined.
  // However the previousSubroute should also be undefined when the first item in the list matches because otherwise you can't navigate from /trips/xyz to /trips
  const previousSubroute = previousSubrouteIndex < 1 ? undefined : historyStack[previousSubrouteIndex];
  if (previousSubroute && previousSubroute !== newPath) {
    // If such a subroute was found and it is different from the current requested path, redirect to that subroute.
    return previousSubroute;
  }
  return null;
};
