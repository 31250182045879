import { PRIORITY } from "@lato/common";
import clsx from "clsx";
import { GrFlagFill } from "react-icons/gr";
import { IoFlag } from "react-icons/io5";

interface PriorityLabelProps {
  priority: PRIORITY;
  onClick?: () => void;
  isSelected?: boolean;
  showLabel?: boolean;
  marginTop?: string;
}

const priorityToColorMap: { [key in PRIORITY]: string } = {
  [PRIORITY.URGENT]: "text-red-400",
  [PRIORITY.HIGH]: "text-orange-400",
  [PRIORITY.NORMAL]: "text-blue-400",
  [PRIORITY.LOW]: "text-gray-400",
};

export const PriorityLabel = ({
  priority,
  onClick,
  isSelected = false,
  showLabel = false,
  marginTop,
}: PriorityLabelProps) => {
  if (!priority) return null;

  const iconColor = priorityToColorMap[priority];
  const labelClassName = showLabel ? "w-full" : undefined;

  return (
    <button
      type="button"
      className={clsx(
        "flex gap-4 px-2 text-left rounded-full cursor-pointer",
        isSelected && "bg-teal-100",
        !isSelected && onClick && "opacity-40 hover:bg-gray-100",
      )}
      onClick={onClick}
    >
      <GrFlagFill className={clsx("w-full", iconColor, `mb-2 ${marginTop}`)} />
      {showLabel && <p className={clsx(labelClassName, "ml-2")}>{priority}</p>}
    </button>
  );
};

export default PriorityLabel;
