function formatNumber(n: string) {
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

type props = {
  text: string;
  addDecimals: boolean;
};

const formatPrice = ({ text, addDecimals }: props): string => {
  // check for decimal
  if (text.indexOf(".") >= 0) {
    const decimal_pos = text.indexOf(".");

    let left = text.substring(0, decimal_pos);
    let right = text.substring(decimal_pos);

    // format the left side
    left = formatNumber(left).replace(/^[0 ]*(?=\d)/, "");

    // validate right side
    right = formatNumber(right);

    // add decimals when blur
    if (addDecimals) {
      right += "00";
    }

    // limit to 2 decimals
    right = right.substring(0, 2);

    if (left.length === 0) {
      left = "0";
    }
    text = left + "." + right;
  } else {
    // no decimal found, format and add decimals
    text = formatNumber(text);

    if (addDecimals) {
      text += ".00";
    }
  }
  return text;
};

export default formatPrice;
