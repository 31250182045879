import { Flex } from "@chakra-ui/react";
import React from "react";
import { FilterProps } from "react-table";
import { useTripsListStore } from "../../stores/trip/tripslistStore";
import MultiSelectColumnFilter from "./MultiSelectColumnFilter";

interface ContinentFilterProps extends React.PropsWithChildren<FilterProps<any>> {
  continents: string[];
  singleSelect?: boolean;
}

const ContinentFilter: React.FC<ContinentFilterProps> = ({ continents, singleSelect = false, ...props }) => {
  const { setContinentFilter } = useTripsListStore();

  const createContinentOptions = React.useCallback(
    (preFilteredRows: any, columnName: string) => {
      // If the members didn't load yet, return an empty set.
      if (!continents) return new Set<string>();
      return new Set(continents);
    },
    [continents],
  );

  const displayContinentOption = React.useCallback(
    (continentValue: string): React.ReactElement => {
      // Find a single continenttrip of this continent to get the continent object
      const continent = continents?.find((c) => c === continentValue);
      // If the members didn't load yet or somehow no continent was found, return an empty fragment.
      if (!continent) return <></>;
      return (
        <Flex alignItems="center">
          {/* <Avatar
            size="xs"
            name={continent.name}
            src={continent.avatar ? continent.avatarUrl : ""}
            alt={"continent-" + (continent.name || continent.id!)}
            mr={2}
          /> */}
          <span>{continent}</span>
        </Flex>
      );
    },
    [continents],
  );

  return (
    <>
      {continents && continents.length > 0 && (
        <MultiSelectColumnFilter
          {...props}
          displayName={"continent"}
          displayOption={displayContinentOption}
          // TODO: remove later, but currently BUG: the preFilteredRows are sometimes undefined
          createOptions={createContinentOptions}
          singleSelect={singleSelect}
          onChange={(e) => {
            if (e.length === continents.length) {
              setContinentFilter("");
              props.columns.find((column) => column.id === "continent" || column.id === "continents")?.setFilter([]);
            } else {
              setContinentFilter(e.join(","));
              props.columns.find((column) => column.id === "continent" || column.id === "continents")?.setFilter(e);
            }
            props.columns.find((column) => column.id === "country" || column.id === "countries")?.setFilter([]);
          }}
        />
      )}
    </>
  );
};
export default React.memo(ContinentFilter);
