import { Slider, SliderFilledTrack, SliderMark, SliderProps, SliderThumb, SliderTrack } from "@chakra-ui/react";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
import colors from "../../theme/foundations/colors";

interface RHFSliderProps extends SliderProps {
  name: string;
  min: any;
  max: any;
  unit: string;
  sliderValues: any[];
}

const RHFSlider: React.FC<RHFSliderProps> = ({ name, unit, sliderValues, ...props }) => {
  // const { field } = useController({
  //   name,
  //   // On first render, the default value of the column should be used,
  //   // subsequent renders should use the more up to date getValues defaultValue.
  //   // defaultValue: defaultV || defaultValue,
  // });
  const { field } = useController({
    name,
  });

  const { register } = useFormContext();

  const markers = sliderValues.map((pos, i) => (
    <SliderMark key={`marker-${i}`} value={i} mt="1" ml={i === 0 ? "-0.5em" : "-1.5em"} fontSize="sm">
      {pos}
      {unit}
    </SliderMark>
  ));
  /*props.step &&
    Array(props.max / props.step + 1)
      .fill(0)
      .map((_, i) => (
        <SliderMark
          value={i === 0 ? i : i * props.step! - props.max / (props.step! * 2)}
          mt="1"
          ml="-2.5"
          fontSize="sm"
        >
          {i * props.step!}
          {unit}
        </SliderMark>
      ));*/
  return (
    <Slider
      {...props}
      defaultValue={field.value ? sliderValues.indexOf(+field.value) : 1}
      {...register(name)}
      onChange={(v) => field.onChange(sliderValues[v])}
      mb={2}
    >
      {markers}
      <SliderTrack>
        <SliderFilledTrack bg={colors.lato.primaryColor} />
      </SliderTrack>
      {/*<Tooltip
        hasArrow
        bg="teal.500"
        color="white"
        placement="bottom"
        isOpen={true}
        label={`${field.value}${unit}`}
  >*/}
      <SliderThumb />
      {/*</Tooltip>*/}
    </Slider>
  );
};
export default React.memo(RHFSlider);
