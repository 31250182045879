import getApiEndpoints from "./utilities/core";

const url = "airports";

// Get the standard CRUD operations
const basicAirportsEndpoints = getApiEndpoints({
  url,
});

type AirportsAPIType = {
  getAirports(queryParams?: string): Promise<any>;
};
const AirportsAPI: AirportsAPIType = {
  getAirports: basicAirportsEndpoints.getAll,
};
export default AirportsAPI;
