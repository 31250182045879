import { Box, FormLabel } from "@chakra-ui/react";
import React from "react";
import CustomComboBox, { DSListItemConfig } from "../../../../../CustomDownShift/CustomComboBox";
import CustomMultipleSelection from "../../../../../CustomDownShift/CustomMultipleSelection";
import { SingleItem } from "./SingleItem";

interface SimpleItemDownshiftProps {
  items: { description: string; value: string }[];
  name: string;
  onChange: any;
  multipleSelection?: boolean;
  defaultSelected: string[];
  isDisabled?: boolean;
  placeholder?: string;
  customListItemConfigs?: DSListItemConfig<any>[];
}

export const SimpleItemDownshift: React.FC<SimpleItemDownshiftProps> = ({
  items,
  name,
  onChange,
  placeholder,
  multipleSelection = false,
  defaultSelected,
  isDisabled = false,
  customListItemConfigs,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedItems, setSelectedItems] = React.useState<any[]>(
    defaultSelected != undefined ? defaultSelected.map((item: any) => items.find((i: any) => i.value === item)) : [],
  );

  React.useEffect(() => {
    selectedItems.length !== defaultSelected.length && onChange(selectedItems.map((item: any) => item));
  }, [selectedItems]);

  return (
    <Box width={"100%"}>
      <FormLabel mb={"0.2em"}>{name.charAt(0).toUpperCase() + name.slice(1)}</FormLabel>
      {multipleSelection ? (
        <CustomMultipleSelection
          dropDownItems={items
            .filter((value, index, self) => index === self.findIndex((t) => t.description === value.description))
            .filter(
              (item: any) =>
                item.value?.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.description?.toLowerCase().includes(searchValue.toLowerCase()),
            )}
          disableInput={isDisabled}
          listItemConfig={{
            ListItem: SingleItem,
            showSelected: ({ item }: any) => (item ? item.description : ""),
          }}
          inputItemConfig={{
            inputItem: SingleItem,
          }}
          customListItemConfigs={customListItemConfigs}
          selectedItems={selectedItems}
          setSelectedItems={(items: any[]) => setSelectedItems(items)}
          placeholder={placeholder ?? `Add a ${name}`}
          height={"200px"}
          onInputValueChangeCallBack={(inputValue: string) => {
            setSearchValue(inputValue);
          }}
          useDivider={false}
        />
      ) : (
        <CustomComboBox
          dropDownItems={items.filter((item: any) => item.value.toLowerCase().includes(searchValue.toLowerCase()))}
          disableInput={isDisabled}
          defaultSelected={items.find((item: any) => item.value === defaultSelected[0])}
          initialInputValue={defaultSelected[0]}
          listItemConfig={{
            ListItem: SingleItem,
            showSelected: ({ item }: any) => (item ? item.description : ""),
            onSelected: ({ item }: any) => {
              onChange(item.value);
            },
          }}
          customListItemConfigs={customListItemConfigs}
          placeholder={placeholder ?? `Pick a ${name}`}
          height={"200px"}
          onInputValueChangeCallBack={(inputValue: string) => {
            if (inputValue === "") onChange(undefined);
            setSearchValue(inputValue);
          }}
        />
      )}
    </Box>
  );
};
