import { useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import { addToast } from "../../utils/addToast";
import Form from "../form/Form";

export function showLoadingToast(toast: any, name: string) {
  addToast(toast, {
    title: `Saving ${name}`,
    status: "info",
    duration: 3000,
    isClosable: true,
  });
}

export function showErrorToast(
  toast: any,
  name: string,
  message = "Please make sure all fields are filled in correctly.",
) {
  addToast(toast, {
    title: `Failed saving ${name}`,
    description: message,
    status: "error",
  });
}

export function showUnauthorizedToast(toast: any) {
  addToast(toast, {
    title: "Unauthorized",
    description: "You are not authorized to edit this trip.",
    status: "error",
  });
}

export function showSuccessToast(toast: any, name: string) {
  addToast(toast, {
    title: `Successfully saved ${name}`,
    status: "success",
    duration: 3000,
    isClosable: true,
  });
}

type TripFormProps = {
  onSubmit: (e?: any) => Promise<void>;
  formId: string;
  children: any;
  formMethods: any;
  flexGrow?: any;
  triggerResetData?: any;
  defaultValues?: any;
  disableDirty?: any;
  id?: string;
};
const TripForm: React.FC<TripFormProps> = ({
  onSubmit,
  formId,
  children,
  formMethods,
  triggerResetData,
  defaultValues,
  disableDirty,
  ...props
}) => {
  const toast_loading = useToast({
    id: "toast_loading",
    position: "bottom-right",
  });
  const toast = useToast({
    id: "toast_success",
    position: "bottom-right",
  });

  const {
    setIsSubmittingForms,
    setIsDirtyForms,
    setIsErrorForms,
    setIsInvalidForms,
    isErrorForms,
    isInvalidForms,
    isSubmittingForms,
    isDirtyForms,
  } = useTripFormStore();

  useEffect(() => {
    if (isErrorForms.get(formId)) {
      return;
    }

    formMethods.reset(defaultValues, { keepValues: true, keepErrors: true });

    return () => {
      setIsDirtyForms(formId, false);
      setIsErrorForms(formId, false);
      setIsInvalidForms(formId, false);
      setIsSubmittingForms(formId, false);
    };
  }, [triggerResetData]);

  useEffect(() => {
    setIsSubmittingForms(formId, formMethods.formState.isSubmitting);
  }, [formId, formMethods.formState.isSubmitting]);

  useEffect(() => {
    if (!disableDirty && !isErrorForms.get(formId)) {
      setIsDirtyForms(formId, formMethods.formState.isDirty);
    }
  }, [formMethods.formState.isDirty, formId, setIsDirtyForms]);

  useEffect(() => {
    setIsInvalidForms(formId, formMethods.formState.isDirty && !formMethods.formState.isValid);
  }, [formMethods.formState.isDirty, formMethods.formState.isValid, formId, setIsDirtyForms, setIsErrorForms]);

  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  const submit = async () => {
    // Don't submit if not dirty
    if (!isDirtyForms.get(formId)) return;

    try {
      await onSubmit();

      formMethods.reset({}, { keepErrors: true, keepDirty: true, keepIsSubmitted: true, keepTouched: true });
      setIsDirtyForms(formId, false);
      setIsErrorForms(formId, false);
      setIsInvalidForms(formId, false);
    } catch (error: any) {
      if (error.response?.status === 401) {
        // Unauthorized exception: hence a user tried to save a trip which does not originate from his company.
        showUnauthorizedToast(toast);
        return;
      }

      setIsErrorForms(formId, true);
      setIsDirtyForms(formId, true);
    }
  };

  return (
    <Form onSubmit={submit} formMethods={formMethods} onKeyDown={(e) => checkKeyDown(e)} {...props}>
      {children}
    </Form>
  );
};

export default React.memo(TripForm);
