import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from "@chakra-ui/react";
import React from "react";
import PaymentAlert from "./PaymentAlert";

interface PaymentAlertModalProps {
  success: boolean;
  disclosure: any;
}

const PaymentAlertModal: React.FC<PaymentAlertModalProps> = ({ success, disclosure }) => {
  const { isOpen } = disclosure;

  const onClose = () => {
    window.location.href = "/settings/billing";
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <PaymentAlert success={success} />
      </ModalContent>
    </Modal>
  );
};
export default React.memo(PaymentAlertModal);
