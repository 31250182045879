import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { TLibraryImage, prettyLocation } from "@lato/common";
import React from "react";
import Card from "./Card";

interface LibraryCardProps {
  Photo: TLibraryImage;
  handleChosenPicture: (photo: TLibraryImage) => void;
  selected: boolean;
}

const LibraryCard: React.FC<LibraryCardProps> = ({ Photo, handleChosenPicture, selected }) => {
  const choosePicture = () => {
    handleChosenPicture(Photo);
  };

  const { mainAddress } = Photo.location ? prettyLocation(Photo.location) : { mainAddress: "" };

  return (
    <Card
      choosePicture={choosePicture}
      imageAlt={mainAddress && mainAddress !== "" ? mainAddress : Photo.name}
      imageSite={Photo.site}
      imageUrl={Photo.url}
      selected={selected}
      nameElement={
        <Flex>
          {Photo.country?.flagImage && (
            <Box my={"auto"} mr={1}>
              <Image height={`10px`} width={"12px"} src={Photo.country.flagImage} />
            </Box>
          )}

          <Text>{mainAddress ? mainAddress : Photo.name}</Text>
        </Flex>
      }
    />
  );
};
export default React.memo(LibraryCard);
