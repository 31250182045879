import { useContext } from "react";
import { Box } from "@chakra-ui/react";
import { NotificationContext } from "../../stores/NotificationContext";
import NotificationBadge from "./NotificationBadge";

/**
 * Display Notification Count In Badge
 * @returns React Component
 */
const NotificationDisplay = () => {
  const { notificationCount } = useContext(NotificationContext);
  if (notificationCount === 0) return <></>;
  return <NotificationBadge count={notificationCount} />;
};
export default NotificationDisplay;
