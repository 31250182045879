import { Box, Link, Text } from "@chakra-ui/react";
import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link as ReactLink } from "react-router-dom";

interface BackToOverviewButtonProps {
  overviewName: string;
  route: string;
}

const BackToOverviewButton: React.FC<BackToOverviewButtonProps> = ({ overviewName, route }) => {
  return (
    <Link
      as={ReactLink}
      to={route}
      alignItems={"center"}
      p={3}
      gap={2}
      position={"absolute"}
      display={"flex"}
      left={{ base: "0", md: "10em" }}
      top={"4em"}
    >
      <BsArrowLeft size={"24px"} />
      <Text>{`Back to ${overviewName}`}</Text>
    </Link>
  );
};

export default React.memo(BackToOverviewButton);
