import { TabPanel, useDisclosure } from "@chakra-ui/react";
import React from "react";
import EventsAPI from "../../api/events.api";
import HotelsAPI from "../../api/hotels.api";
import POIsAPI from "../../api/pois.api";
import TextfieldsAPI from "../../api/textfields.api";
import DestinationsAPI from "../../api/destinations.api";
import { useMeContext } from "../../stores/me-context";
import {
  ACCOMMODATIONS_QUERY_KEY,
  ACTIVITIES_QUERY_KEY,
  POI_QUERY_KEY,
  TEXTFIELDS_QUERY_KEY,
  DESTINATIONS_QUERY_KEY,
} from "../../utils/constants";
import useMutation from "../../utils/query-helpers/useMutation";
import { getEmptyAccommodation } from "../../components/elements/accommodations";
import { getEmptyActivity, getEmptyPoi, getEmptyDestination, getEmptyTask } from "../../components/elements/activities";
import LibraryImageModal from "../../components/elements/libraryImages/libraryimage-modal";
import { getEmptyTextField } from "../../components/elements/textfields";
import TabModal from "../../components/layout/TabModal";
import {
  LibraryItem,
  LibraryItemFormInModal,
  LibraryItemType,
} from "../../components/trips/edit/daybyday/library-items/LibraryItemModal";

interface NewModalProps {
  disclosure: any;
  selectedTab?: string;
}

export const allTabs = ["Accommodation", "Activity", "Text Field", "Destination", "Image", "POI"];

const NewModal: React.FC<NewModalProps> = ({ disclosure, selectedTab }) => {
  const user = useMeContext();
  const locationDisclosure = useDisclosure();
  const imagesDisclosure = useDisclosure();

  return (
    <TabModal
      title="Add new library item"
      tabs={allTabs}
      disclosure={disclosure}
      scrollBehavior="inside"
      tabHeaderWidth={"90%"}
      showHeader={true}
      defaultIndex={allTabs.indexOf(selectedTab || "Accommodation")}
      modalContentProps={{ maxH: "80vh" }}
    >
      <TabPanel>
        <NewTabModal
          api={HotelsAPI}
          eventType={"accommodation"}
          libraryItem={getEmptyAccommodation(user)}
          query_key={ACCOMMODATIONS_QUERY_KEY}
          onClose={disclosure.onClose}
        />
      </TabPanel>
      <TabPanel>
        <NewTabModal
          api={EventsAPI}
          eventType={"activity"}
          libraryItem={getEmptyActivity(user)}
          query_key={ACTIVITIES_QUERY_KEY}
          onClose={disclosure.onClose}
        />
      </TabPanel>
      <TabPanel>
        <NewTabModal
          api={TextfieldsAPI}
          eventType={"textfield"}
          libraryItem={getEmptyTextField(user)}
          query_key={TEXTFIELDS_QUERY_KEY}
          onClose={disclosure.onClose}
        />
      </TabPanel>
      <TabPanel>
        <NewTabModal
          api={DestinationsAPI}
          eventType={"destination"}
          libraryItem={getEmptyDestination(user)}
          query_key={DESTINATIONS_QUERY_KEY}
          onClose={disclosure.onClose}
        />
      </TabPanel>
      <TabPanel>
        <LibraryImageModal
          inModal={false}
          disclosure={imagesDisclosure}
          edit={false}
          locationDisclosure={locationDisclosure}
          onClose={disclosure.onClose}
        />
      </TabPanel>
      <TabPanel>
        <NewTabModal
          api={POIsAPI}
          eventType={"poi"}
          libraryItem={getEmptyPoi(user)}
          query_key={POI_QUERY_KEY}
          onClose={disclosure.onClose}
        />
      </TabPanel>
      {/* <TabPanel>
        <NewTabModal
          api={TasksAPI}
          eventType={"task"}
          libraryItem={getEmptyTask(user)}
          query_key={POI_QUERY_KEY}
          onClose={disclosure.onClose}
        />
      </TabPanel> */}
    </TabModal>
  );
};

interface NewTabModalProps {
  libraryItem: LibraryItem;
  eventType: LibraryItemType;
  forceNew?: boolean;
  api: any;
  query_key: string;
  onClose: () => void;
}

const NewTabModal: React.FC<NewTabModalProps> = ({ eventType, api, query_key, ...props }) => {
  const createMut = useMutation(
    {
      apiCall: (libraryItem: any) => api.post(libraryItem),
      successMessage: `created ${eventType}`,
      failMessage: `creating ${eventType}`,
    },
    [query_key],
  );

  return <LibraryItemFormInModal {...props} eventType={eventType} type="Create" forceNew={true} mut={createMut} />;
};
export default React.memo(NewModal);
