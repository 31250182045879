import { Destination, TranslationField } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { BsSignpost2Fill } from "react-icons/bs";
import { useMeContext } from "../../../../stores/me-context";
import {
  useCreateDestination,
  useTripdayDestination,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { createNewTranslationFields } from "../../../../utils/createNewTranslationFields";
import { getEmptyDestination } from "../../../elements/activities";
import EventTypeCard from "./EventTypeCard";

interface AddDestinationCardProps {
  first: boolean;
  control: any;
  setUnCollapsedElement: (by: string | undefined) => void;
  translations: TranslationField[];
  tripdayId: string;
}

const AddDestinationCard: React.FC<AddDestinationCardProps> = ({
  first,
  setUnCollapsedElement,
  control,
  translations,
  tripdayId,
}) => {
  const me = useMeContext();

  const { data: destinationData, isFetching: isLoadingDestination } = useTripdayDestination(tripdayId);
  const destinationServer = destinationData?.destination;

  const queryClient = useQueryClient();

  const { mutateAsync: createDestination } = useCreateDestination(queryClient, tripdayId);

  const addDestinationHandler = React.useCallback(async () => {
    setUnCollapsedElement(undefined);

    const newTranslationFields = createNewTranslationFields(translations);

    const emptyDestination: Destination = {
      ...getEmptyDestination(me),
      descriptions: [...newTranslationFields],
      titles: [...newTranslationFields],
      tripdayId,
    };
    // Create the destination in the database
    const newDest = await createDestination(emptyDestination);
    // Refetch the destination
    // queryClient.invalidateQueries({ queryKey: ["get-destinations"] });
    setUnCollapsedElement(newDest.id);
  }, [createDestination, queryClient]);

  const showAddButton = !destinationServer && !isLoadingDestination && first;

  return (
    <>
      {showAddButton && <EventTypeCard name={"Destination"} icon={BsSignpost2Fill} onClick={addDestinationHandler} />}
    </>
  );
};

export default React.memo(AddDestinationCard);
