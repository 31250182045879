import React from "react";
import CoreInput from "./CoreInput";

function auto_grow(e: any) {
  const element: HTMLElement = e.target;
  element.style.height = "5px";
  element.style.height = element.scrollHeight + "px";
}

const TextareaAutoHeight: React.FC<any> = React.forwardRef((props, ref) => {
  return (
    <CoreInput
      textarea
      resize="none"
      overflow="auto"
      minHeight={"1.1em"}
      maxHeight="100px"
      ref={ref}
      onInput={auto_grow}
      {...props}
    />
  );
});
TextareaAutoHeight.displayName = "TextareaAutoHeight";

export default React.memo(TextareaAutoHeight);
