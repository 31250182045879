import { Box, Flex, FormLabel, Icon, Select } from "@chakra-ui/react";
import { Country, CountryGroups } from "@lato/common";
import React from "react";
import { useFormContext } from "react-hook-form";
import { HiPhone as Phone } from "react-icons/hi";
import CountryAPI from "../../api/countries.api";
import { useParamsQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import FullScreenSpinner, { CustomSpinner } from "../FullScreenSpinner";
import ErrorCardView from "../layout/ErrorCardView";
import RHFInput, { RHFInputProps } from "./RHFInput";

interface PhoneInputProps extends RHFInputProps {
  hidePrefixSelector?: boolean;
  selectedCountry?: Country;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ hidePrefixSelector = false, selectedCountry, ...props }) => {
  const {
    data: countries,
    isLoading,
    error,
  } = useParamsQueryHelper<CountryGroups>({
    queryKey: "countries-phone",
    apiCall: CountryAPI.getAll,
    queryParams: {} as any,
    options: {},
  });
  const definePrefixByPhoneNumber = (phoneNumber: string) => {
    if (countries && countries.all) {
      const country = (countries.all ?? []).filter(
        (country) => phoneNumber && phoneNumber.startsWith(country.phonePrefix),
      );
      if (country.length === 1) {
        return `${country[0].flagEmoticon} ${country[0].phonePrefix}`;
      }
    }
    return "";
  };

  const [selectedPrefix, setSelectedPrefix] = React.useState<string>(
    definePrefixByPhoneNumber(props.defaultValue as string),
  );
  const { getValues, setValue } = useFormContext<any>();

  const handleSelectCountry = (e: any) => {
    const countryPrefix = e.target.value;
    setSelectedPrefix(countryPrefix);
    const value = getValues(props.name);
    if (value.length <= 8) {
      setValue(props.name, countryPrefix.split(" ")[1]);
    }
  };

  if (isLoading) return <CustomSpinner m="auto" />;
  if (error || !countries) return <ErrorCardView title="Could not load countries" />;

  const checkIfPrefix = (e: any) => {
    const val = e.target.value;
    const existingPref = (countries.all ?? []).filter((country) => country.phonePrefix === val);
    if (existingPref.length === 1) {
      setSelectedPrefix(`${existingPref[0].flagEmoticon} ${existingPref[0].phonePrefix}`);
    }
    if (props.onChange) props.onChange(e);
    setValue(props.name, val.replace(/\s+/g, ""), { shouldDirty: true });
  };

  const { label, ...propsWithoutLabel } = props;

  const handleFocus = () => {
    const phoneNumberValue = getValues("phoneNumber");
    if (phoneNumberValue.length <= 7 && !hidePrefixSelector) {
      setValue("phoneNumber", selectedCountry?.phonePrefix);
    }
  };

  const handleBlur = () => {
    const phoneNumberValue = getValues("phoneNumber");
    if (phoneNumberValue === selectedCountry?.phonePrefix) {
      setValue("phoneNumber", "");
    }
  };

  return (
    <Box w={props.width}>
      <FormLabel mb={"0.2em"}>{label}</FormLabel>
      <Flex alignItems={"center"} gap={1}>
        {!hidePrefixSelector && (
          <Select value={selectedPrefix} onChange={handleSelectCountry} w={"30%"}>
            {countries.all.map((country) => (
              <option key={`country-option-${country.phonePrefix}`}>
                {country.flagEmoticon} {country.phonePrefix}
              </option>
            ))}
          </Select>
        )}
        <RHFInput
          {...propsWithoutLabel}
          leftIcon={<Icon as={Phone} color="gray.400" />}
          onChange={checkIfPrefix}
          w={hidePrefixSelector ? "100%" : "60%"}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder="+49 123 456 789"
        ></RHFInput>
      </Flex>
    </Box>
  );
};

export default PhoneInput;
