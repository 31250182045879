import { useEffect, useState } from "react";
import { IconButton, Flex, Icon, Tooltip, Select } from "@chakra-ui/react";

import { TableInstance } from "react-table";
import { FcGenericSortingDesc, FcGenericSortingAsc } from "react-icons/fc";

interface SortingDropDownProps {
  tableMethods: TableInstance<any>;
}

const SortingDropDown = ({ tableMethods }: SortingDropDownProps) => {
  const { headerGroups, state, toggleSortBy }: TableInstance = tableMethods;
  const [sortKey, setsortKey] = useState<string | undefined>();
  const [sortOrderDesc, setSortOrderDesc] = useState(false);

  /**
   * Trigger Sort By on changing of sort key or sort order
   */
  useEffect(() => {
    if (sortKey) toggleSortBy(sortKey, sortOrderDesc);
  }, [sortKey, sortOrderDesc]);

  /**
   * Set Sorting key and sorting order
   */
  useEffect(() => {
    if (state && state.sortBy.length) {
      setsortKey(state.sortBy[0].id);
      if (state.sortBy[0].desc) {
        setSortOrderDesc(state.sortBy[0].desc);
      }
    }
  }, [state]);

  return (
    <Flex>
      <Tooltip label={sortOrderDesc ? "Sort Asc" : "Sort Desc"} aria-label="sort-asc-tooltip">
        <IconButton
          disabled={sortKey ? false : true}
          colorScheme="brand"
          aria-label={sortOrderDesc ? "sort-asc" : "sort-desc"}
          icon={
            <Icon
              as={sortOrderDesc ? FcGenericSortingAsc : FcGenericSortingDesc}
              boxSize={4}
              transform={"rotateY(180deg)"}
            />
          }
          onClick={() => setSortOrderDesc(!sortOrderDesc)}
          w="fit-content"
          variant="ghost"
        />
      </Tooltip>
      {headerGroups.map((headerGroup) => {
        return (
          // eslint-disable-next-line react/jsx-key
          <Select
            {...headerGroup.getHeaderGroupProps()}
            onChange={(event) => {
              setsortKey(event.target.value);
            }}
            value={sortKey}
            placeholder="Select Sort"
            borderRadius={"10em"}
          >
            {headerGroup.headers.map((column, i) => {
              return column.canSort ? (
                <option {...column.getHeaderProps(column.getSortByToggleProps())} value={column.id} key={i}>
                  {typeof column.render("Header") === "string" ? column.render("Header") : column.id}
                </option>
              ) : (
                ""
              );
            })}
          </Select>
        );
      })}
    </Flex>
  );
};

export default SortingDropDown;
