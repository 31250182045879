import { EmailIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Link, Spacer, Stack, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { LoginType } from "../../api/auth.api";
import { DevTool } from "../../utils/hookform-devtools";
import { handleSubmission } from "../../utils/toErrorMap";
import Form from "../../components/form/Form";
import RHFInput, { RHFInputProps } from "../../components/input/RHFInput";
import RHFInputPassword from "../../components/input/RHFInputPassword";

interface LoginFormProps {
  type: "login" | "register";
  validationSchema: any;
  successMessage: string;
  failMessage: string;
  apiCall: any;
  handleAdditionalError?: (statusCode: number, setError: any, errors?: any[]) => any;
  defaultEmail?: string;
  successCallback?: () => void;
  initializeWithDefaultTrips?: boolean;
  referredByToken?: string;
  customDescription?: string;
  registerWithoutTrial?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  validationSchema,
  successMessage,
  failMessage,
  apiCall,
  handleAdditionalError,
  type,
  defaultEmail,
  successCallback,
  initializeWithDefaultTrips = true,
  referredByToken,
  customDescription,
  registerWithoutTrial = false,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const formMethods = useForm<LoginType>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: defaultEmail ?? "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const nextRoute = "/";
  const redirectToDashboard = React.useCallback(
    // If the navigate replace is used directly inside the handleSubmission function, it is always called.
    () => navigate(nextRoute, { replace: true }),
    [navigate],
  );

  const handleAdditionalErr = (statusCode: number, setError?: any, errors?: any[]) => {
    if (handleAdditionalError) return handleAdditionalError(statusCode, formMethods.setError, errors);
  };

  const handleSubmit = async (values: LoginType) => {
    await handleSubmission({
      successMessage,
      failMessage,
      apiCall: apiCall({
        ...values,
        initializeWithDefaultTrips,
        referredByToken,
        registerWithoutTrial,
      }),
      toast,
      successCallback: successCallback ?? redirectToDashboard,
      setError: formMethods.setError,
      handleAdditionalError: handleAdditionalErr,
      customDescription: customDescription,
    });
  };

  return (
    <Form formMethods={formMethods} onSubmit={handleSubmit}>
      <DevTool control={formMethods.control} />
      <Stack spacing={3}>
        <RHFEmailInput name="email" label={"Email"} isReadOnly={!!defaultEmail} size="lg" layout="normal" />
        {type === "login" ? (
          <Box>
            <RHFInputPassword name="password" label="Password" />
            <Flex>
              <Spacer />
              <Link as={ReactLink} fontSize="sm" color="teal.500" to="/forgot-password">
                Forgot password?
              </Link>
            </Flex>
          </Box>
        ) : (
          <RHFInputPassword name="password" label="Password" />
        )}
        <Button
          size="md"
          mt={4}
          type="submit"
          isLoading={formMethods.formState.isSubmitting}
          isDisabled={!formMethods.formState.isValid}
          colorScheme="brand"
        >
          {type === "login" ? "Login" : "Create account"}
        </Button>
      </Stack>
    </Form>
  );
};
export default React.memo(LoginForm);

interface RHFEmailInputProps extends RHFInputProps {
  name: string;
  label: string;
  isReadOnly?: boolean;
  width?: string;
}

export const RHFEmailInput: React.FC<RHFEmailInputProps> = ({ name, label, isReadOnly = false, ...props }) => {
  return (
    <RHFInput
      name={name}
      label={label}
      type="email"
      placeholder="bob@mail.com"
      leftIcon={<EmailIcon color="gray.400" pointerEvents={"none"} />}
      isReadOnly={isReadOnly}
      {...props}
    />
  );
};
