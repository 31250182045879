import { GoogleAddress } from "@lato/common";

const formatAddress = (address: GoogleAddress | undefined, short = false) => {
  const street = !short && address?.street ? address.street + ", " : "";
  const city = address?.city ? address.city + ", " : "";
  const state = address?.state ? address.state + ", " : "";
  const country = address?.country_code || "";
  return `${street}${city}${state}${country}`;
};
export default formatAddress;
