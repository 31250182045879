import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "chatmessages";

// Get the standard CRUD operations
const { getAll } = getApiEndpoints({
  url,
});

// Send a message to all trip users.
const sendMessage = (id: string, message: string) => {
  return rawApiProvider.post(`trips/${id}/${url}`, { message });
};

const deleteMessage = (id: string) => {
  return rawApiProvider.remove(`${url}/${id}`);
};

type MessageAPIType = {
  getMyMessages(): Promise<any>;
  sendMessage(id: string, message: string): Promise<any>;
  deleteMessage(id: string): Promise<any>;
};
const MessageAPI: MessageAPIType = {
  getMyMessages: getAll,
  sendMessage: sendMessage,
  deleteMessage: deleteMessage,
};
export default MessageAPI;
