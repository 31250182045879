import { Box, Skeleton, Text } from "@chakra-ui/react";
import { MdAccessTime, MdAccessTimeFilled } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useMeContext } from "../../stores/me-context";
import {
  LAST_EDITED_USERTRIP_QUERY_KEY,
  RECENTLY_EDITED_LIST_QUERY_KEY,
  RECENTLY_EDITED_TRIP_QUERY_KEY,
  RECENTLY_EDITED_USERTRIP_QUERY_KEY,
} from "../../utils/constants";
import {
  getRelationMap,
  useSplitTripByUserTripId,
  useSplitUserTrip,
} from "../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { SeperatedHeading } from "../../components/SeperateHeading";
import { TripCard } from "./TripCard";
import ENV from "../../utils/env";
import { useHistoryStack } from "../../utils/useListenRouter";
import { PaginationOptions, defaultPaginationOptions } from "../../utils/query-helpers/usePaginatedQuery";
import { usePaginatedTrips } from "../../utils/query-helpers/reactQueryHooks";
import { mapFiltersToQueryParams } from "../../pages/trip-list-container";
import React from "react";
import { Queue } from "../../utils/queue";
import { CreateFirstTripCard } from "./CreateFirstTripCard";

interface RecentlyEditedTripProps {}

export const RecentlyEditedTrip: React.FC<RecentlyEditedTripProps> = () => {
  const navigate = useNavigate();
  const me = useMeContext();
  const push = useHistoryStack((state: any) => state.push);

  const recentlyEditedListKey = `${RECENTLY_EDITED_LIST_QUERY_KEY}-${me.id}-${ENV}`;
  const recentlyOpenedTrips = localStorage.getItem(recentlyEditedListKey) ?? "";
  const stack = new Queue<string>(recentlyOpenedTrips.split(","), 20);
  const lastRecentTrip = stack.peek();

  const {
    data: userTrips,
    isLoading: isLoadingUserTrip,
    error: userTripError,
  } = useSplitUserTrip(
    lastRecentTrip ?? "",
    getRelationMap[RECENTLY_EDITED_USERTRIP_QUERY_KEY],
    RECENTLY_EDITED_USERTRIP_QUERY_KEY,
    { enabled: !!lastRecentTrip },
  );

  const {
    data: trip,
    isLoading: isLoadingTrip,
    error: tripError,
  } = useSplitTripByUserTripId(
    lastRecentTrip ?? "",
    RECENTLY_EDITED_TRIP_QUERY_KEY,
    getRelationMap[RECENTLY_EDITED_TRIP_QUERY_KEY],
    { enabled: !!lastRecentTrip },
  );

  const defaultFiltersLastUpdated = [
    { id: "users", value: [me.id] },
    { id: "companies", value: [me?.company.id] },
    { id: "orderBy", value: ["updated_at"] },
    { id: "order", value: "DESC" },
  ];

  const defaultTableStateLastUpdated: PaginationOptions = {
    ...defaultPaginationOptions,
    filters: defaultFiltersLastUpdated,
    limit: 1,
    step: 1,
  };

  const {
    data: tripsLastUpdated,
    isLoading: isLoadingTripLastUpdated,
    error: isErrorTripLastUpdated,
  } = usePaginatedTrips(
    {
      ...defaultTableStateLastUpdated,
      ...mapFiltersToQueryParams(defaultTableStateLastUpdated.filters!),
      sample: false,
      tasks: true,
    },
    { queryKey: [LAST_EDITED_USERTRIP_QUERY_KEY], enabled: !lastRecentTrip || !tripError },
  );

  const redirectToTrips = () => {
    // Push /trips to prevent from redirecting to a certain edit trip
    push("/trips");
    navigate("/trips?recentlyOpened=true");
  };

  const heading = (
    <SeperatedHeading
      title="Recently opened"
      tooltipText="View more recently opened trips"
      icon={MdAccessTime}
      hoverIcon={MdAccessTimeFilled}
      onClick={redirectToTrips}
    />
  );

  if (isLoadingUserTrip || isLoadingTrip || isLoadingTripLastUpdated) {
    return (
      <Box maxH="22em">
        {heading}
        <Skeleton h="16em" w="29.2em" rounded={"lg"} />
      </Box>
    );
  }

  const isFirst = userTrips
    ? userTrips[0].user.id === me.id
    : tripsLastUpdated && tripsLastUpdated.data && tripsLastUpdated.data[0]
      ? tripsLastUpdated.data[0].user.id === me.id
      : false;
  let userTrip;
  if (userTrips) userTrip = { ...userTrips[isFirst ? 0 : 1], trip: trip };
  if (!userTrip && tripsLastUpdated) userTrip = tripsLastUpdated.data[0];

  const meCreated = new Date(me.created_at);
  const meCreatedWithExtraTime = new Date(meCreated.getTime() + 3 * 60000);

  if (!userTrip || new Date(userTrip.created_at) < meCreatedWithExtraTime) {
    // This way, trips that are automatically created by the system are not taken into account.
    return (
      <Box maxH="22em" width="29.2em">
        {heading}
        <CreateFirstTripCard />
      </Box>
    );
  }

  return (
    <Box maxH="22em">
      {heading}
      <TripCard
        key={userTrip.id!}
        userTrip={userTrip}
        cardWidth="29.2em"
        cardHeight="16em"
        padding={6}
        cardSize={"lg"}
        travelling={false}
        onClick={() => {
          window.open(`/trips/${userTrip.id}`);
        }}
      />
    </Box>
  );
};

export default RecentlyEditedTrip;
