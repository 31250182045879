import { Trip, omitLocalTimezone, calculateTripdayDate, getDayAndMonthFromSelectToDate } from "@lato/common";
import { calculateDayNumber } from "../features/editTrip/overviewStep/TripsOverviewTable";
import { useTripFormStore } from "../stores/trip/tripFormStore";

//defining daySelectOptions for a tripday
//used in dropdowns of Transportations & Events
export const defineDaySelectOptions = (trip: Trip, tripdayIndex: number, additionalDays: number = 0) => {
  const start_date = trip?.start_date;
  const tripdays = trip?.tripdays ?? [];
  const tripday = tripdays[tripdayIndex];

  const nrOfNights =
    (!tripday?.nrOfNights || tripday.nrOfNights === 0 ? 1 : Math.max(0, tripday.nrOfNights)) + additionalDays;

  const realDayNumber = calculateDayNumber(tripdayIndex, tripdays);
  return [...Array(Math.max(1, nrOfNights)).keys()].map((i) => {
    const baseText = `Day ${realDayNumber + i + 1}`;
    const tripdayDate = start_date && calculateTripdayDate(realDayNumber + i, start_date);
    const extraText = tripdayDate ? `: ${tripdayDate.substring(0, 11)}` : "";
    return {
      value: i,
      text: baseText + extraText,
    };
  });
};

export const canSwitch = (isInvalidForms: Map<string, boolean>, isErrorForms: Map<string, boolean>) => {
  return !(
    [...isInvalidForms.values()].some((value) => value === true) ||
    [...isErrorForms.values()].some((value) => value === true)
  );
};

export const closeElement = (
  setUncollapsedElement: any,
  isInvalidForms: Map<string, boolean>,
  isErrorForms: Map<string, boolean>,
) => {
  if (canSwitch(isInvalidForms, isErrorForms)) {
    setUncollapsedElement(undefined);
  }
};

export const submitForms = async (isSubmittingForms: Map<string, boolean>): Promise<void> => {
  const allFormButtons = document.querySelectorAll(".form-submit-button");
  allFormButtons.forEach((formButton: any) => {
    formButton.click();
  });
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (![...isSubmittingForms.values()].some((value) => value === true)) {
        clearInterval(intervalId);
        resolve();
      } else {
      }
    }, 10);
  });
};

export const getRemainingTripDays = (trip: Trip, tripdayIndex: number) => {
  if (!trip.tripdays) return 0;

  let count = 1;
  tripdayIndex = Math.max(0, tripdayIndex);
  tripdayIndex = Math.min(tripdayIndex, trip.tripdays.length - 1);
  for (let i = tripdayIndex + 1; i < trip.tripdays.length; i++) {
    const tripday = trip.tripdays[i];
    count += tripday.nrOfNights || 0;
  }
  return count;
};

//Retrieving surrounding Locations new or moved elements
export const getLinkedLocations = (objects: any[], newObject: boolean, fromIndex?: number, toIndex?: number) => {
  if (objects.length === 0) {
    return null;
  }
  //when a newObject element is added it will be added at the end of the list so only the previous last element's location is needed
  if (newObject) {
    const lastObject = objects[objects.length - 1];
    const location = lastObject?.arrivalLocation ?? lastObject?.location;
    if (location === "" || !location) {
      return null;
    }
    const { id, ...locationWithoutId } = location;
    return locationWithoutId;
  }
  //when a object is moved it will look at the surrounding elements and retrieve it's locations
  const previous = objects[fromIndex! > toIndex! ? toIndex! - 1 : toIndex!];
  const next = objects[fromIndex! < toIndex! ? toIndex! + 1 : toIndex!];

  const result = [];

  if (previous) {
    const previousLocation = previous.arrivalLocation ?? previous.location;
    if (previousLocation) {
      const { id, ...previousLocationWithoutId } = previousLocation;
      result.push(previousLocationWithoutId);
    }
  }
  if (next) {
    const nextLocation = next.departureLocation ?? next.location;
    if (nextLocation) {
      const { id, ...nextLocationWithoutId } = nextLocation;
      result.push(nextLocationWithoutId);
    }
  }

  return result;
};

export const getNewEventIndex = (
  dayIndex: number | null,
  time: string | null,
  initialEventIndex: number,
  events: any[],
  type: "event" | "transportation",
): number => {
  if (dayIndex === null && !time) {
    // Both the new dayindex and the new time is not defined
    return initialEventIndex;
  }
  // Check if the new dayIndex is already in the correct spot (hence, check if the array is sorted)
  const arr = [...events];

  //determening type of the element
  if (type === "event") {
    arr[initialEventIndex].dayIndex = dayIndex;
    arr[initialEventIndex].time = time;
  } else {
    arr[initialEventIndex].departureDayIndex = dayIndex;
    arr[initialEventIndex].departureTime = time;
  }
  // If the array is still sorted when the new day index is used then nothing should change.
  if (checkEventsSorted(arr, type)) return initialEventIndex;
  // Not sorted yet hence get the index where the new event should be placed.
  let c = 0;
  // While not at the end of the array and while the new day index is larger than the day index at position c,
  // continue (meaning that the new day index should be placed further)
  let currentEvent = events[c];

  let eventType = currentEvent.hasOwnProperty("dayIndex") ? "event" : "transportation";
  let currentEventDayIndex = eventType === "event" ? currentEvent.dayIndex : currentEvent.departureDayIndex;
  let currentEventTime = eventType === "event" ? currentEvent.time : currentEvent.departureTime;
  if (dayIndex === null) {
    // New day is NOT defined

    while (
      c < events.length &&
      (currentEventDayIndex !== null || !time || !currentEventTime || time >= currentEventTime)
    ) {
      c++;
      currentEvent = events[c];
      if (currentEvent === undefined) {
        break;
      }
      eventType = currentEvent.hasOwnProperty("dayIndex") ? "event" : "transportation";
      currentEventDayIndex = eventType === "event" ? currentEvent.dayIndex : currentEvent.departureDayIndex;
      currentEventTime = eventType === "event" ? currentEvent.time : currentEvent.departureTime;
    }
  } else {
    // New day is defined
    while (
      c < events.length &&
      (currentEventDayIndex === null ||
        dayIndex > currentEventDayIndex ||
        (dayIndex === currentEventDayIndex && (!currentEventTime || !time || time >= currentEventTime)))
    ) {
      c++;
      currentEvent = events[c];
      if (currentEvent === undefined) {
        break;
      }
      eventType = currentEvent.hasOwnProperty("dayIndex") ? "event" : "transportation";
      currentEventDayIndex = eventType === "event" ? currentEvent.dayIndex : currentEvent.departureDayIndex;
      currentEventTime = eventType === "event" ? currentEvent.time : currentEvent.departureTime;
    }
  }
  // If the original index is lower than the new index, substract 1
  if (initialEventIndex < c) c = c - 1;
  console.log(c);
  return c;
};

export const retrieveValueByName = (name: string, object: any) => {
  const key = Object.keys(object).find((key) => key.startsWith(name));
  return object[key ?? ""];
};

const getStartTime = (event: any) => {
  // Create a new Date object for the current day
  const date = new Date();

  let time;
  if (event.hasOwnProperty("departureTime")) {
    time = event.departureTime;
  } else {
    time = event.time;
  }

  if (!time) {
    return null;
  }

  // Split the time string into hours and minutes
  const [hours, minutes] = time.split(":").map(Number);

  // Set the hours and minutes on the Date object
  date.setHours(hours, minutes, 0, 0);

  return date;
};

export const checkEventsSorted = (arr: any[]): boolean => {
  const events = arr.filter((e) => e);
  let previousStart: Date | null = events.length > 0 ? getStartTime(events[0]) : null;
  for (let k = 1; k < events.length; k++) {
    const currentStart = getStartTime(events[k]);
    if (!!previousStart && !!currentStart && previousStart > currentStart) {
      return false;
    }
    previousStart = currentStart;
  }
  return true;
};

//method to dayIndex and time based on a given date
export const getDayIndexFromDate = (trip: any, tripdayIndex: number, date: Date) => {
  date = omitLocalTimezone(date);
  //Retrieving daySelectOptions for given tripdayIndex
  const daySelectOptions = defineDaySelectOptions(trip, tripdayIndex, getRemainingTripDays(trip, tripdayIndex));
  for (let i = 0; i < daySelectOptions.length; i++) {
    const daySelectDate = getDayAndMonthFromSelectToDate(daySelectOptions[i].text);
    //when the date and month are the same we have found the dayIndex
    if (date && daySelectDate[0] === date.getMonth() && daySelectDate[1] === date.getDate()) {
      return i;
    }
  }
  return null;
};

export const monthByShort: { [key: string]: number } = {
  ["JAN"]: 0,
  ["FEB"]: 1,
  ["MAR"]: 2,
  ["APR"]: 3,
  ["MAY"]: 4,
  ["JUN"]: 5,
  ["JUL"]: 6,
  ["AUG"]: 7,
  ["SEP"]: 8,
  ["OCT"]: 9,
  ["NOV"]: 10,
  ["DEC"]: 11,
};
