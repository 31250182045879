import { Company, Contact, ContactGroup, Follow, Language, Room, Task, User, UserTrip } from "@lato/common";
import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import AuthAPI from "../../api/auth.api";
import CalendarAPI from "../../api/calendar.api";
import CompanyAPI from "../../api/companies.api";
import ContactGroupAPI from "../../api/contact-group.api";
import ContactAPI from "../../api/contacts.api";
import CountryAPI from "../../api/countries.api";
import FollowingStatusAPI from "../../api/followingstatus.api";
import HotelsAPI from "../../api/hotels.api";
import LanguagesAPI from "../../api/languages.api";
import NotesAPI from "../../api/notes.api";
import NotificationsAPI from "../../api/notifications.api";
import POIsAPI, { POI } from "../../api/pois.api";
import StatisticsAPI from "../../api/statistics.api";
import StrapiAPI from "../../api/strapi.api";
import StripeAPI from "../../api/stripe.api";
import TasksAPI from "../../api/tasks.api";
import TripsAPI from "../../api/trips.api";
import UserAPI from "../../api/users.api";
import UserTripsAPI from "../../api/usertrips.api";
import { TAnnouncement } from "../../components/alerts/StrapiAnnouncementAlert";
import {
  CALENDAR_ITEMS_QUERY_KEY,
  CONTACT_NOTES_QUERY_KEY,
  ME_QUERY_KEY,
  MY_COMPANY_QUERY_KEY,
  TASKS_ITEMS_CONTACT_KEY,
  TASKS_ITEMS_QUERY_KEY,
  TRIPS_USERTRIPS_KEY,
  USERTRIP_NOTES_QUERY_KEY,
  USERTRIP_TASKS_QUERY_KEY,
} from "../constants";
import { PaginationOptions, PaginationReturn } from "./usePaginatedQuery";

const defaultRQOptions = {
  retry: 0,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
};
export const useCustomQuery = <T>(
  queryKey: QueryKey,
  apiCall: () => Promise<any>,
  options?: Omit<UseQueryOptions<T, Error, T>, "queryKey"> | undefined,
) => {
  return useQuery<T, Error>({
    queryKey,
    queryFn: apiCall,
    ...defaultRQOptions,
    ...options,
  });
};

export const useTrpcQuery = <T>(
  trpcCall: any,
  options?: Omit<UseQueryOptions<T, Error, T>, "queryKey"> | undefined,
) => {
  return trpcCall.useQuery({
    ...defaultRQOptions,
    ...options,
  });
};

export const useTrpcInfiniteQuery = <T>(
  trpcCall: any,
  queryParams?: any,
  options?: Omit<UseQueryOptions<T, Error, T>, "queryKey"> | undefined,
) => {
  return trpcCall.useInfiniteQuery(
    {
      ...queryParams,
    },
    {
      ...options,
      ...defaultRQOptions,
    },
  );
};

export const useTrpcMutation = <T>(
  trpcCall: any,
  options?: Omit<UseMutationOptions<any, any, any, any>, "mutationFn"> | undefined,
) => {
  return trpcCall.useMutation({
    ...defaultRQOptions,
    ...options,
  });
};

export const useMe = () => {
  return useQuery({
    queryKey: [ME_QUERY_KEY],
    queryFn: async () => {
      return await AuthAPI.me();
    },
    // Data gets stale after staleTime is exceeded. When the data is stale, it will still get the data from the fetch.
    // It will not do a fetch yet, only in certain circumstances: for example on window focus, on mounting a new component that uses the query, ...
    // If you navigate to another page such that a new component that uses the query is mounted, it will fetch fresh data but
    // the loading will not be visible in the frontend because the stale data is initially used until the fresh data is available.
    retry: 0,
    staleTime: Infinity,
  });
};

export const useContactNotes = (contactId: string) => {
  return useQuery({
    queryKey: [CONTACT_NOTES_QUERY_KEY, contactId],
    queryFn: async () => {
      return await NotesAPI.getNotesFromContact(contactId);
    },
    ...defaultRQOptions,
  });
};

export const useUserTripNotes = (usertripId: string) => {
  return useQuery({
    queryKey: [USERTRIP_NOTES_QUERY_KEY, usertripId],
    queryFn: async () => {
      return await NotesAPI.getNotesFromUserTrip(usertripId);
    },
    ...defaultRQOptions,
  });
};

export const useUserTripTasks = (usertripId: string) => {
  return useQuery({
    queryKey: [USERTRIP_TASKS_QUERY_KEY, usertripId],
    queryFn: async () => {
      return await TasksAPI.getTasksFromUserTrip(usertripId);
    },
    ...defaultRQOptions,
  });
};

export const useContactTasks = (contactId: string) => {
  return useQuery({
    queryKey: [TASKS_ITEMS_CONTACT_KEY, contactId],
    queryFn: async () => {
      return await TasksAPI.getTasksFromContact(contactId);
    },
    ...defaultRQOptions,
  });
};

export const useMyCompany = () => {
  return useQuery({
    queryKey: [MY_COMPANY_QUERY_KEY],
    queryFn: async () => {
      return await CompanyAPI.getMyCompany();
    },
    retry: 0,
  });
};

export const useMyTeam = () =>
  useQuery({
    queryKey: ["my-team"],
    queryFn: async () => {
      return await CompanyAPI.getMyCompanyTeam();
    },
    ...defaultRQOptions,
  });

export const useMyCountries = (options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useQuery({
    queryKey: ["my-countries"],

    queryFn: async () => {
      return await CountryAPI.getAll();
    },
    ...options,
  });

export const useGetAllCompanies = () =>
  useQuery({
    queryKey: ["all-companies"],

    queryFn: async () => {
      return await CompanyAPI.getAllCompanies();
    },
  });

// export const useTrip = (tripId: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
//   useQuery<Trip, Error>(
//     ["trips", tripId],
//     async () => {
//       return await TripsAPI.getTrip(tripId);
//     },
//     { retry: 0, ...options }
//   );

export const useTripWithTemperature = (tripId: string) =>
  useQuery({
    queryKey: ["tripsWithTemperature", tripId],

    queryFn: async () => {
      return await TripsAPI.getTripWithTemperature(tripId);
    },
    ...defaultRQOptions,
  });

export const useContact = (contactId: string) =>
  useQuery({
    queryKey: ["get-contact", contactId],

    queryFn: async () => {
      return await ContactAPI.getSingle(contactId);
    },
    ...defaultRQOptions,
  });

export const usePoi = (poiId: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useQuery({
    queryKey: ["get-poi", poiId],
    queryFn: async () => {
      return await POIsAPI.getSingle(poiId);
    },
    ...defaultRQOptions,
    ...options,
  });

export const useContactTrips = (contactId: string) =>
  useQuery({
    queryKey: ["get-contact-trips", contactId],

    queryFn: async () => {
      return await UserTripsAPI.getContactTrips(contactId);
    },
    ...defaultRQOptions,
  });

export const useAppTrip = (usertripId: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useQuery({
    queryKey: ["userapptrip", usertripId],
    queryFn: async () => {
      return await UserTripsAPI.getAppTrip(usertripId);
    },
    ...defaultRQOptions,
    ...options,
  });

export const useTripMailPreview = (
  usertripId: string,
  model: any,
  options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {},
) =>
  useQuery({
    queryKey: ["tripmailtemplate", usertripId, model],
    queryFn: async () => {
      return await UserTripsAPI.getPreviewTripMail(usertripId, model);
    },
    ...defaultRQOptions,
    ...options,
  });

export const useTrip = (usertripId: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useQuery({
    queryKey: ["usertrip", usertripId],
    queryFn: async () => {
      return await UserTripsAPI.getTrip(usertripId);
    },
    ...defaultRQOptions,
    ...options,
  });

export const useCollaborationInvitationStatus = (userTripId: string) =>
  useCustomQuery<{ status: string; userTrips: UserTrip[] }>(
    ["collaborationInvitationStatus", userTripId],
    async () => await UserTripsAPI.collaborationInvitationStatus(userTripId),
  );

export const useHotel = (hotelId: string, options: Omit<UseQueryOptions<any, any, any>, "queryKey"> = {}) =>
  useCustomQuery<{ status: string }>(["hotel", hotelId], async () => await HotelsAPI.getSingle(hotelId), options);

// export const useCheckEditTripAccess = (userTripId: string) =>
//   useCustomQuery<{ success: boolean }>(
//     ["checkEditTripAccess", userTripId],
//     async () => {
//       return await UserTripsAPI.checkEditTripAccess(userTripId);
//     }
//   );

export const buildQueryString = (queryParams: any) => {
  const queryParamsClone: any = { ...queryParams };
  const entries = Object.entries(queryParamsClone);
  // Remove the undefined/null and empty string values from the object
  entries.forEach(([k, v]): any => {
    if (v === undefined || v === null || v === "") {
      delete queryParamsClone[k];
    }
  });
  // Convert the object into a query param string
  // Some of these queryParams are undefined, maybe filter them out if they are undefined before assigning as key.
  // Not necessary: undefined query keys inside objects will be ommited: https://react-query.tanstack.com/guides/query-keys
  return {
    queryParamsString: new URLSearchParams(queryParamsClone).toString(),
    sanitizedQueryParams: queryParamsClone,
  };
};

type QueryParamHelperType = {
  queryKey: string;
  apiCall: (queryParamString: string) => Promise<any>;
  queryParams: { [x: string]: unknown } & PaginationOptions;
  options?: Omit<UseQueryOptions<any, any, any>, "queryKey">;
};
export const useParamsInfiniteQueryHelper = <T>({ queryKey, apiCall, queryParams, options }: QueryParamHelperType) => {
  const { sanitizedQueryParams } = buildQueryString(queryParams);
  return useInfiniteQuery<T, Error>({
    queryKey: [queryKey, sanitizedQueryParams],
    queryFn: async ({ pageParam = 1 }) => {
      const { queryParamsString } = buildQueryString({
        ...queryParams,
        page: pageParam,
      });
      return await apiCall(queryParamsString);
    },
    ...defaultRQOptions,
    getNextPageParam: !queryParams.step
      ? () => undefined
      : (lastPage, pages) =>
          // @ts-ignore
          pages.length * queryParams.step < lastPage.count ? pages.length + 1 : undefined,
    // pages.length >= lastPage.count ? false : pages.length + 1,
    ...options,
  });
};

export const useParamsQueryHelper = <T>({ queryKey, apiCall, queryParams, options }: QueryParamHelperType) => {
  console.log(queryKey);
  console.log(queryParams);
  const { queryParamsString, sanitizedQueryParams } = buildQueryString(queryParams);
  return useCustomQuery<T>([queryKey, sanitizedQueryParams], async () => await apiCall(queryParamsString), {
    placeholderData: keepPreviousData,
    ...options,
  });
};

export const usePlanUpdatePreview = (queryParams: any, options?: Omit<UseQueryOptions<any, any, any>, "queryKey">) => {
  return useParamsQueryHelper<any>({
    queryKey: "update-subscription-preview",
    apiCall: StripeAPI.changeSubscriptionPreview,
    queryParams,
    options,
  });
};

export const usePaginatedTrips = (queryParams: any, options?: UseQueryOptions<any, any, any>) => {
  return useParamsQueryHelper<PaginationReturn<UserTrip>>({
    queryKey: TRIPS_USERTRIPS_KEY,
    apiCall: UserTripsAPI.getPaginatedTrips,
    queryParams,
    options,
  });
  // Staletime should always be set to infinity to make sure the pagination does not lag!
};

export const useMultipleTrips = (ids: string[], options?: UseQueryOptions<any, any, any>) => {
  return useCustomQuery<{ count: number; data: UserTrip[] }>(
    ["recentlyOpenedTrips", ids],
    async () => {
      if (ids.length === 0) return { count: 0, data: [] };
      return await UserTripsAPI.getMultipleTrips(ids);
    },
    options,
  );
};

export const usePaginatedTasks = (queryParams: any, options?: UseQueryOptions<any, any, any>) => {
  return useParamsQueryHelper<PaginationReturn<Task>>({
    queryKey: TASKS_ITEMS_QUERY_KEY,
    apiCall: TasksAPI.getTasks,
    queryParams,
    options,
  });
  // Staletime should always be set to infinity to make sure the pagination does not lag!
};

export const useCalendar = (queryParams: any, options?: UseQueryOptions<any, any, any>) => {
  return useParamsQueryHelper<Calendar>({
    queryKey: CALENDAR_ITEMS_QUERY_KEY,
    apiCall: CalendarAPI.getCalendar,
    queryParams,
    options,
  });
  // Staletime should always be set to infinity to make sure the pagination does not lag!
};

export const useOurPOIs = (queryParams: any, options?: UseQueryOptions<any, any, any>) => {
  return useParamsQueryHelper<PaginationReturn<POI>>({
    queryKey: "ourPOIs",
    apiCall: POIsAPI.getAll,
    queryParams,
    options,
  });
  // Staletime should always be set to infinity to make sure the pagination does not lag!
};

export const useTripPOIs = (userTripId: string) => {
  return useCustomQuery<POI[][]>(["tripPOIs", userTripId], async () => await UserTripsAPI.getPOIs(userTripId));
};

export const useCompanyInviteInfo = (token: string) => {
  return useCustomQuery<{
    invitingUser: User;
    newUserEmail: string;
    newUser: User | undefined;
  }>(
    ["compInvInfo", token],
    async () => await CompanyAPI.getInviteInformation(token),
    // Disable caching
    { gcTime: 0 },
  );
};

export const useChangePasswordTokenStatus = (token: string) => {
  return useCustomQuery<{
    invitingUser: User;
    newUserEmail: string;
    newUser: User | undefined;
  }>(
    ["changePwdToken", token],
    async () => await AuthAPI.getChangePasswordTokenStatus(token),
    // Disable caching
    { gcTime: 0 },
  );
};

export const useTripsStatistics = (queryParams: any) => {
  return useParamsQueryHelper<any>({
    queryKey: "myStatistics",
    apiCall: StatisticsAPI.getStatistics,
    queryParams,
  });
};

export const useDailyStats = (queryParams: any) => {
  return useParamsQueryHelper<any>({
    queryKey: "myDailyStatistics",
    apiCall: StatisticsAPI.getDailyStats,
    queryParams,
  });
};

export const useUpcomingTrips = (page?: number) => {
  return useParamsQueryHelper<any>({
    queryKey: "myUpcomingTrips",
    apiCall: StatisticsAPI.getUpcomingTrips,
    queryParams: {
      page: page,
    } as any,
  });
};

export const useMostBookedCountries = (queryParams: any) => {
  return useParamsQueryHelper<any>({
    queryKey: "myMostBookedCountries",
    apiCall: StatisticsAPI.getMostBookedCountries,
    queryParams,
  });
};

export const useBookedPerContinent = (queryParams: any) => {
  return useParamsQueryHelper<any>({
    queryKey: "myBookedPerContinent",
    apiCall: StatisticsAPI.getBookedPerContinent,
    queryParams,
  });
};

export const useNumberOfLatestTrips = () =>
  useCustomQuery<any>(["nrOfLatestTrips"], async () => await UserTripsAPI.getNumberOfLatestTrips());

export const useLanguages = () =>
  useCustomQuery<Language[]>(["languages"], async () => await LanguagesAPI.getAllTranslatable());

export const useAllLanguages = () =>
  useCustomQuery<Language[]>(["all-languages"], async () => await LanguagesAPI.getAll());

export const useLatoRooms = () => useCustomQuery<Room[]>(["latoRooms"], async () => await HotelsAPI.getLatoRooms());

export const useExtraSubscriptionInformation = () =>
  useCustomQuery<{
    [key: string]: any;
  } | null>(["extraSubscriptionInformation"], async () => await StripeAPI.getExtraSubscriptionInformation());

/**
 * React Query API Hook to fetch Trips Unread ChatMessage
 * @returns
 */
export const useUnreadMessageCount = () =>
  useCustomQuery<any>(["unReadMessageCount"], async () => await NotificationsAPI.getAllUnreadChatCount());

/**
 * React Query API Hook to Mark Messages Read
 * @param userTripId
 * @returns
 */
export const useMarkMessageRead = (userTripId: string) =>
  useCustomQuery<any>(["markMessageRead"], async () => await NotificationsAPI.markMessageRead(userTripId));

/**
 * Fetch the follower list of passed company id
 * @param id Company Id
 * @returns
 */
export const useFollowerListCompany = (id: string) =>
  useCustomQuery<Company>(["getFollowers", id], async () => await FollowingStatusAPI.getFollowers(id));

/**
 * Fetch the following list
 * @param id Logged In Company Id
 * @returns
 */
export const useFollowingListCompany = (id: string) =>
  useCustomQuery<Company>(["getFollowing", id], async () => await FollowingStatusAPI.getFollowing(id));

/**
 * Fetch the following list
 * @param id Logged In Company Id
 * @returns
 */
export const useMyFollowRequests = () =>
  useCustomQuery<PaginationReturn<Follow>>(
    ["myFollowRequests"],
    async () => await FollowingStatusAPI.getMyFollowRequests(),
  );

/**
 * Fetch all the blogs from the Strapi CMS
 * @returns
 */
export const useLatestStrapiBlogArticle = () =>
  useCustomQuery<any>(["latestStrapiBlogArticle"], async () => await StrapiAPI.getMostRecentBlog());

/**
 * Fetch all the blogs from the Strapi CMS
 * @returns
 */
export const useLatestStrapiAnnouncement = () =>
  useCustomQuery<TAnnouncement>(["latestStrapiAnnouncement"], async () => await StrapiAPI.getMostRecentAnnouncement());

export const usePaginatedContacts = (queryParams: any, options?: UseQueryOptions<any, any, any>) => {
  return useParamsQueryHelper<PaginationReturn<Contact>>({
    queryKey: "get-contacts",
    apiCall: ContactAPI.getAll,
    queryParams,
    options,
  });
};

export const useContactGroup = () =>
  useCustomQuery<ContactGroup[]>(["contact-groups"], async () => {
    return await ContactGroupAPI.getAll();
  });

export const useFindCompaniesWhere = (queryParams: any, options?: Omit<UseQueryOptions<any, any, any>, "queryKey">) => {
  return useParamsInfiniteQueryHelper<Company[]>({
    queryKey: "find-companies-where",
    apiCall: CompanyAPI.findCompaniesWhere,
    queryParams,
    options,
  });
};
export const useLatestCannyChangelogs = (userId: string) =>
  useCustomQuery<any>(["latestCannyChangelogs"], async () => await UserAPI.getLatestChangelog(userId));
