import { useContext, useEffect, useRef, useState } from "react";

import { SocketContext } from "../../stores/SocketContext";
import io, { Socket } from "socket.io-client";
import config from "../../config";
import { useMe } from "../../utils/query-helpers/reactQueryHooks";
import ENV from "../../utils/env";

interface SocketProviderProps {
  children: React.ReactNode;
}

const SOCKET_URL = config[ENV].socket_url;
/**
 * Socket Provider to create and destroy Socket Connection give access to Socket Instance to children components
 * @param param0
 * @returns
 */
const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  // DONOT REMOVE FOLLOWING GRAY CODE
  // we use a ref to store the socket as it won't be updated frequently
  // import socketIOClient from "socket.io-client";
  // const socket = useRef(socketIOClient("https://server-domain.com"));
  // const socket = useRef(socketIOClient("http://localhost:8001"));

  // When the Provider mounts, initialize it 👆
  // and register a few listeners 👇

  // useEffect(() => {
  //   socket.current.on("connect", () => {
  //     console.log("SocketIO: Connected and authenticated");
  //   });

  //   socket.current.on("error", (msg: string) => {
  //     console.error("SocketIO: Error", msg);
  //   });

  //   // Remove all the listeners and
  //   // close the socket when it unmounts
  //   return () => {
  //     if (socket && socket.current) {
  //       socket.current.removeAllListeners();
  //       socket.current.close();
  //     }
  //   };
  // }, []);

  const [socket, setSocket] = useState<Socket | null>(null);
  const { data: user } = useMe();
  useEffect(() => {
    // Create Socket Connection
    const newSocket = io(SOCKET_URL);
    setSocket(newSocket);

    // Close Socket connection and all listners if exists
    return () => {
      // console.log("Closing Socket Connection");
      socket?.removeAllListeners();
      socket?.close();
    };
  }, []);

  useEffect(() => {
    // Once User Id fetched send request to join room
    if (user?.id) {
      socket?.emit("joinRoom", [user?.id]);
    }
  }, [user]);

  return <SocketContext.Provider value={{ socket: socket }}>{children}</SocketContext.Provider>;
};

/**
 * Custom Hook to add eventlistner and callback to recive data
 * @param eventName EventListner
 * @param eventHandler Callback
 */
export const useSocketSubscribe = (eventName: any, eventHandler: any) => {
  // Get the socket instance
  const { socket } = useContext(SocketContext);

  // when the component, *which uses this hook* mounts,
  // add a listener.
  useEffect(() => {
    socket?.on(eventName, eventHandler);

    // Remove when it unmounts
    return () => {
      socket?.off(eventName, eventHandler);
    };

    // Sometimes the handler function gets redefined
    // when the component using this hook updates (or rerenders)
    // So adding a dependency makes sure the handler is
    // up to date!
  }, [eventHandler]);
};

/**
 * Custom Hook to return current socket instance
 * @returns Current Socket Instance
 */
export const useSocketInstance = () => {
  // Get the socket instance
  const { socket } = useContext(SocketContext);
  return socket;
};

export default SocketProvider;
