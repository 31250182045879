import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import { Box, ButtonGroup, GridItem, Heading, IconButton, Link, Tooltip } from "@chakra-ui/react";
import { Trip } from "@lato/common";
import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { prettyPrintDate, prettyPrintDuration } from "../../utils/date";
import { getViewTripLink } from "../../utils/generateURLs";

interface ChatInfoProps {
  trip: Trip | undefined;
  userTripId: string | undefined;
}

const ChatInfo: React.FC<ChatInfoProps> = ({ trip, userTripId }) => {
  const userTrip = trip?.userTrips[trip.userTrips.length - 1];
  const companyName = userTrip?.brand.name;
  const tripTitle = trip?.titles[0].content;
  const viewLink = getViewTripLink(companyName || "trip", tripTitle || "app", userTripId || "");
  return (
    <GridItem colSpan={3} h={{ base: "9vh", xl: "100%" }} display={!trip ? "none" : "inline"}>
      {trip && (
        <Box p={{ base: "0", xl: "7" }}>
          {trip.titles[0].content && (
            <Heading size="lg" width={{ base: "100%", xl: "auto" }}>
              {trip.titles[0].content}
            </Heading>
          )}
          {trip.client_name && (
            <Heading size={{ base: "sm", xl: "md" }} mt={{ base: "1", xl: "5" }}>
              {trip.client_name}
            </Heading>
          )}
          {/* {trip.country?.name && (
            <Heading>Country: {trip.country?.name}</Heading>
          )} */}
          {userTrip?.external_ref && (
            <Heading size="sm" fontWeight={400} color="gray.500" display={{ base: "none", xl: "inline" }}>
              Ref.: {userTrip.external_ref}
            </Heading>
          )}
          {trip.start_date && trip.end_date && (
            <Heading
              size="sm"
              fontWeight={400}
              color="gray.500"
              display={{ base: "none", xl: "inline" }}
              mr={{ base: "0", xl: "3" }}
            >
              {prettyPrintDuration(new Date(trip.start_date), new Date(trip.end_date), "en")}
            </Heading>
          )}
          <ButtonGroup
            colorScheme="brand"
            variant="outline"
            mt={{ base: "-8", xl: "3" }}
            position={{ base: "absolute", xl: "relative" }}
            right={{ base: "0", xl: "auto" }}
          >
            <Tooltip label="View trip">
              <IconButton
                width="100%"
                as={Link}
                icon={<ViewIcon />}
                aria-label="View trip"
                href={viewLink}
                isExternal
              />
            </Tooltip>
            <Tooltip label="Edit trip">
              <IconButton
                width="100%"
                as={ReactLink}
                icon={<EditIcon />}
                aria-label="Edit trip"
                to={`/trips/${userTripId}`}
                target="_blank"
              />
            </Tooltip>
          </ButtonGroup>
        </Box>
      )}
    </GridItem>
  );
};
export default React.memo(ChatInfo);
