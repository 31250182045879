import { ErrorOption } from "react-hook-form";
import { StringDecoder } from "string_decoder";
import { addToast, ToastType } from "./addToast";
import { ADMIN_MAIL } from "@lato/common";

export const toErrorMap = (serverErrors: any) => {
  function parseChildren(children: any, errors: Record<string, string[]>, baseProp: string) {
    children.forEach((child: any) => {
      if (child.children)
        child.children.forEach((childchild: any) => {
          const newBaseProp = baseProp + "[" + child.property + "]." + childchild.property;
          parseChildren(childchild.children, errors, newBaseProp);
          if (childchild.constraints) errors[newBaseProp] = Object.values(childchild.constraints);
        });
    });
  }

  const errorMap: Record<string, string[]> = {};
  serverErrors.forEach((serverError: any) => {
    const baseProp = serverError.property;
    const baseConstraints: string[] = serverError.constraints;
    parseChildren(serverError.children, errorMap, baseProp);
    if (baseConstraints) errorMap[baseProp] = Object.values(baseConstraints);
  });
  return errorMap;
};

export function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void,
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: "server",
      message: errors[key as keyof T]!.join(". "),
      // message: errors[key as keyof T]!,
    });
  });
}

export type HandleSubmissionType<T> = {
  successMessage?: string;
  failMessage: string;
  apiCall: any;
  toast?: any;
  successCallback?: any;
  setError?: (name: any, error: ErrorOption) => void;
  handleAdditionalError?: (statusCode: number, setError?: any, errors?: any[]) => any;
  customDescription?: string;
};
export async function handleSubmission<T>({
  successMessage,
  failMessage,
  apiCall,
  toast,
  successCallback,
  setError,
  handleAdditionalError,
  customDescription,
}: HandleSubmissionType<T>): Promise<any | null> {
  let toastOptions: ToastType | undefined;
  let resp: any = null;
  try {
    resp = await apiCall;
    console.log(resp);
    if (resp?.errors) {
      if (setError) {
        // Show the errors
        addServerErrors(resp.errors, setError);
      }
      if (handleAdditionalError) {
        customDescription = handleAdditionalError(0, setError, resp.errors);
        toastOptions = {
          title: `Failed ${failMessage}`,
          description: customDescription ?? "Please make sure all fields are filled in correctly.",
          status: "error",
        };
      } else {
        // Show toast
        toastOptions = {
          title: `Failed ${failMessage}`,
          description: "Please make sure all fields are filled in correctly.",
          status: "error",
        };
      }
    } else {
      if (successMessage) {
        toastOptions = {
          title: `Successfully ${successMessage}`,
          status: "success",
        };
      }
      if (successCallback) {
        successCallback(resp);
      }
    }
  } catch (error: any) {
    toastOptions = {
      title: `Failed ${failMessage}`,
      description: "Something went wrong, try again later or mail " + ADMIN_MAIL,
      status: "error",
    };
    console.log(error);

    if (error.response) {
      console.log(error.response.data.message);

      if (handleAdditionalError) {
        customDescription = handleAdditionalError(error.response.data.statusCode, setError, [
          error.response.data.message,
        ]);
        toastOptions = {
          title: `Failed ${failMessage}`,
          description: customDescription ?? "Please make sure all fields are filled in correctly.",
          status: "error",
        };
      }
    }
  }
  if (toast && toastOptions) addToast(toast, toastOptions);
  return resp;
}
