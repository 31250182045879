import { Language, recursivelyRetrieveObjectValues, Trip, User, TranslationField } from "@lato/common";
import { PartialTranslationField } from "../stores/trip-language-context";

/**
 * Checks if the logged in user already has company members who created some translations.
 * @param trip
 * @param me
 * @returns
 */
export const checkUserTranslated = (object: object | PartialTranslationField[], me: User | undefined): boolean => {
  // If for some reason the user is not defined, return false such that the double fields are not shown.
  // E.g. when loading the user, the user can be undefined. Or when a not logged in user can access the edit route.
  if (!me) return false;
  let tfs;
  if (!Array.isArray(object)) {
    tfs = getAllTranslations(object);
  } else {
    tfs = object;
  }
  const ids: (string | null)[] = tfs.map((tf) => (tf.creator ? tf.creator.companyId! : null));
  const checkAlreadySaved = ids.includes(me.companyId!);
  const checkNew = ids.includes(null);
  return checkAlreadySaved || checkNew;
};

const getAvailableLanguages = (trip: Trip | Omit<Trip, "userTrips">): Language[] => {
  const languagesSet = getAllTranslations(trip).map((tf) => tf.language);
  return languagesSet.filter((value, index, self) => index === self.findIndex((t) => t.code === value.code));
};

const getAllTranslations = (object: object): TranslationField[] => {
  // Return a list with all translation-fields
  return recursivelyRetrieveObjectValues(object, (k, v) => v[k] && typeof v[k] === "object" && v[k]["creator"]);
};
export { getAvailableLanguages };
