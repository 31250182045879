import { Role, Plan } from "@lato/common";
import { planOrder } from "../components/settings/billing/PlanCard";

export const grantPermission = (userRole: Role, permittedRoles: Role[]) => {
  // in case of multiple roles, if one of the permittedRoles is present in requestedRoles, return true;
  return itemInList(userRole, permittedRoles);
};

export const grantPlanPermission = (companyPlan: Plan, plans: Plan[]) => itemInList(companyPlan, plans);
export const grantPayingPlansPermission = (companyPlan: Plan) =>
  grantPlanPermission(
    companyPlan,
    Object.values(Plan).filter((el) => el !== Plan.FREE),
  );

export const grantAtLeast = (companyPlan: Plan, lowestAllowedPlan: Plan) =>
  planOrder[companyPlan] >= planOrder[lowestAllowedPlan];

const itemInList = (item: any, list: any[]) => list.indexOf(item) !== -1;
