import { Box } from "@chakra-ui/react";
import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import { TbSquareRoundedArrowRightFilled } from "react-icons/tb";
import { BentoGrid, BentoGridItem } from "../BentoGrid";
import { SeperatedHeading } from "../SeperateHeading";
import { HELP_CENTER_LINK } from "@lato/common";

interface Article {
  title: string;
  description?: string;
  img: string;
  help_center_path: string;
}
interface LearningSectionProps {
  randomiseArticles?: boolean;
  learnData: Article[];
}

export const LearningSection: React.FC<LearningSectionProps> = ({ randomiseArticles = false, learnData }) => {
  if (!learnData || learnData.length === 0) {
    return null;
  }
  const getNRandomArticles = (n: number) => {
    const shuffled = learnData.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, n);
  };

  const articles = randomiseArticles ? getNRandomArticles(7) : learnData;

  return (
    <Box className="my-16">
      <SeperatedHeading
        title="Learn"
        tooltipText="Learn more about Lato"
        icon={FaGraduationCap}
        hoverIcon={FaGraduationCap}
        onClick={() => {
          window.open(HELP_CENTER_LINK, "_blank");
        }}
      />
      <BentoGrid className="">
        {articles.map((item, i) => (
          <BentoGridItem
            key={i}
            img={item.img}
            title={item.title}
            description={item.description}
            // className={i === 2 || i === 4 ? "md:col-span-2" : ""}
            href={HELP_CENTER_LINK + item.help_center_path}
            logo={false}
          />
        ))}
        <div className="row-span-1 rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-white border border-transparent flex justify-center align-middle space-y-4">
          <a href={HELP_CENTER_LINK} className="self-center" target="_blank" rel="noreferrer">
            <TbSquareRoundedArrowRightFilled className="h-10 w-10 cursor-pointer text-[#43a8a0]" />
          </a>
        </div>
      </BentoGrid>
    </Box>
  );
};

export default LearningSection;
