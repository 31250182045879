import { Flex, Spinner } from "@chakra-ui/react";
import { Currency, CurrencyGroups } from "@lato/common";
import React, { useEffect } from "react";
import { useController, useWatch } from "react-hook-form";
import { default as CurrencyAPI } from "../../api/currencies.api";
//import { Currency } from "../../types/types";
import { useParamsQueryHelper } from "../../utils/query-helpers/reactQueryHooks";
import { default as InputWrapper } from "./InputWrapper";
import { OptionsGrouped, default as RHFSelect, SelectOption } from "./RHFSelect";

interface CurrencyPickerProps {
  name: string;
  countryIso?: string;
  currencySelectionHandler: (currency: Currency) => void;
  isFirst?: boolean;
}

const CurrencyPicker: React.FC<CurrencyPickerProps> = ({
  countryIso,
  name,
  currencySelectionHandler,
  isFirst = true,
}: CurrencyPickerProps) => {
  const currencyValue = useWatch({ name });

  const {
    data: currencies,
    isLoading: isLoadingCurrencies,
    error: currencyError,
  } = useParamsQueryHelper<CurrencyGroups>({
    queryKey: "get-currencies",
    apiCall: CurrencyAPI.getAllCountriesWithMostCommon,
    queryParams: { countryIso } as any,
    options: {},
  });
  const setSelectedCurrency = (e: any, currencyIso: string) => {
    const currency = currencies?.all.find((currency) => currency.iso === currencyIso);

    if (currency) {
      currencySelectionHandler(currency);
    }
  };

  useEffect(() => {
    const currency = currencyValue;
    if (currencies && !currency) {
      const defaultC = getDefaultCurrency(currencies);
      if (defaultC) {
        setSelectedCurrency(null, defaultC.iso);
      }
    }
  }, [currencies]);

  const currencyFormatter = (currency: Currency) => {
    return `${currency.iso} ${currency.symbol}`;
  };

  if (isLoadingCurrencies) {
    return (
      <Flex w="100%" h="100%" align="center" justifyContent="center" mt={5}>
        <Spinner color="brand.500" />
      </Flex>
    );
  }

  if (currencies?.all.length === 0 || !currencies || currencyError)
    return (
      <InputWrapper name={name.replace("Iso", "")} label={<label htmlFor="currency">Currency</label>}>
        <RHFSelect name={name} id="currency" options={[]} placeholder="Error: No currencies found"></RHFSelect>
      </InputWrapper>
    );

  const currencies_grouped: OptionsGrouped = {
    Recent: (currencies.most_used.map((currency: Currency) => ({
      value: currency.iso,
      text: currencyFormatter(currency),
    })) ?? []) as SelectOption[],
    "Most popular": (currencies.most_popular.map((currency: Currency) => ({
      value: currency.iso,
      text: currencyFormatter(currency),
    })) ?? []) as SelectOption[],
    All: (currencies.all.map((currency: Currency) => ({
      value: currency.iso,
      text: currencyFormatter(currency),
    })) ?? []) as SelectOption[],
  };

  const defaultCurrency = getDefaultCurrency(currencies);

  return (
    <InputWrapper name={name} label={<label htmlFor="currency">Currency</label>}>
      <RHFSelect
        name={name}
        id="currency"
        isDisabled={!isFirst}
        optionsGrouped={currencies_grouped}
        onChange={setSelectedCurrency}
        defaultValue={defaultCurrency?.iso}
      ></RHFSelect>
    </InputWrapper>
  );
};

export default CurrencyPicker;

const getDefaultCurrency = (currencies: CurrencyGroups | undefined): Currency | undefined =>
  currencies
    ? ((currencies.most_used?.length ? currencies.most_used[0] : undefined) ??
      (currencies.most_popular?.length ? currencies.most_popular[0] : undefined) ??
      (currencies.all?.length ? currencies.all[0] : undefined))
    : undefined;
