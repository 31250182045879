import clsx from "clsx";

export const BentoGrid = ({ className, children }: { className?: string; children?: React.ReactNode }) => {
  return (
    <div
      className={clsx(
        "grid md:auto-rows-[14rem] md:auto-cols-[14rem] grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-7xl",
        className,
      )}
    >
      {children}
    </div>
  );
};

export const BentoGridItem = ({
  className,
  img,
  title,
  description,
  href,
  icon,
  logo,
}: {
  className?: string;
  img?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  href: string;
  icon?: React.ReactNode;
  logo?: boolean;
}) => {
  return (
    <a
      href={href}
      target="_blank"
      className={clsx(
        "row-span-1 rounded-xl group/bento shadown-md hover:shadow-xl transition duration-200 shadow-input dark:shadow-none dark:bg-black dark:border-white/[0.2] bg-white border border-gray-200 justify-between flex flex-col",
        className,
      )}
      rel="noreferrer"
    >
      <img
        src={img}
        alt="fallback"
        className={clsx("h-32 w-full object-contain rounded-t-xl", logo && "object-cover")}
      />
      <div className="group-hover/bento:translate-x-2 transition duration-200 pb-2 px-4">
        {/* {icon} */}
        <div className="text-[15px] font-sans font-bold text-neutral-600 dark:text-neutral-200 mb-4 h-12">{title}</div>

        {/* <div className="font-sans font-normal text-neutral-600 text-xs dark:text-neutral-300 line-clamp-3">
          {description}
        </div> */}
      </div>
    </a>
  );
};
