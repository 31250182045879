import { AnyPtrRecord } from "dns";
import React from "react";
import { Column, Row } from "react-table";
import FilterButton from "./FilterButton";

export interface FilterButtonProps {
  column: { filterValue: any; setFilter: any; preFilteredRows: any } & Column<any>;
  // onSelectionChanged: (value: string | string[], options: string[]) => any;
  createOptions?: (preFilteredRows: any, columnName: string) => Set<string>;
  transformOptions?: (chosenOptions: any) => any;
  displayOption?: (option: string) => React.ReactNode;
  searchValueOption?: (option: string) => string;
  onChange?: (e: any) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  useSelectedItems?: boolean;
  displayName?: string;
  singleSelect?: boolean;
  disableDeselect?: boolean;
  refetchEl?: JSX.Element | undefined; // This props is used to add ref to bottom of dropdown for automatic scrolling
}

const defaultCreateOptions = (preFilteredRows: any, columnName: string): Set<string> => {
  const options = new Set<string>();
  preFilteredRows.forEach((row: Row) => {
    options.add(row.values[columnName]);
  });
  return options;
};

const defaultDisplayOption = (option: string): string => option;
const defaultSearchValueOption = (option: string): string => option;

const MultiSelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, Header },
  // onSelectionChanged,
  createOptions = defaultCreateOptions,
  displayOption = defaultDisplayOption,
  searchValueOption = defaultSearchValueOption,
  isDisabled = false,
  isLoading = false,
  isError = false,
  transformOptions,
  onChange,
  useSelectedItems = true,
  displayName,
  singleSelect = false,
  disableDeselect = false,
  refetchEl,
}: FilterButtonProps) => {
  const [selectedItems, setSelectedItems] = React.useState<any[]>(filterValue || []);

  if (!id) return null;

  if (!displayName) {
    if (Header && typeof Header === "string") {
      displayName = Header;
    } else {
      displayName = id;
    }
  }

  const options = createOptions(preFilteredRows, id);
  const allOptions = [...options.values()];

  const handleSelectionChanged = (value: string | string[], options: string[]) => {
    // This is a multi select, so 'value' (the selected options) will always be an array
    // If no options are selected, make sure all options are shown in the table
    const result = value.length > 0 ? (value as string[]) : options;
    if (transformOptions) {
      setFilter(transformOptions(result));
    } else {
      setFilter(result);
    }
    if (onChange) onChange(result);
    // const result = onSelectionChanged(value, options);
    setSelectedItems(allOptions.length === result.length ? [] : result);
  };
  return (
    <FilterButton
      columnName={displayName}
      type={singleSelect ? "radio" : "checkbox"}
      preFilteredRows={preFilteredRows}
      createOptions={createOptions}
      selectedOptions={
        useSelectedItems ? selectedItems : filterValue && filterValue.length !== allOptions.length ? filterValue : []
      }
      onSelectionChanged={handleSelectionChanged}
      displayOption={displayOption}
      searchValueOption={searchValueOption}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isError={isError}
      singleSelect={singleSelect}
      disableDeselect={disableDeselect}
      refetchEl={refetchEl}
    />
  );
};
export default React.memo(MultiSelectColumnFilter);
