import {
  AddIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DragHandleIcon,
  HamburgerIcon,
  SearchIcon,
} from "@chakra-ui/icons";

import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  IconButtonProps,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Select,
  Spacer,
  Stack,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { GoogleAddress, PRIMARY_COLOR, TRIP_STATUS } from "@lato/common";
import { Trash2 as DeleteIcon } from "lucide-react";
import React, { useEffect, useMemo } from "react";
import { BiGridVertical, BiMapAlt, BiTable } from "react-icons/bi";
import { GiConsoleController } from "react-icons/gi";
import { MdFilterAltOff } from "react-icons/md";
import { NavigationType } from "react-router-dom";
import {
  CellProps,
  Column,
  Filters,
  IdType,
  Row,
  SortingRule,
  TableInstance,
  TableOptions,
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMeContext } from "../../stores/me-context";
import { STORAGE_KEY, validateLocalStorage } from "../../utils/checkOldStorage";
import ENV from "../../utils/env";
import { useMe } from "../../utils/query-helpers/reactQueryHooks";
import { PaginationOptions } from "../../utils/query-helpers/usePaginatedQuery";
import Calendar from "../calendar/Calendar";
import { ConditionalWrapper } from "../../features/contacts/Contact";
import RHFInput from "../input/RHFInput";
import Section from "../layout/Section";
import LibraryItemMaps from "../map/LibraryItemMaps";
import {
  LibraryItemType,
  MarkerMapItem,
  MarkerMapItemType,
} from "../trips/edit/daybyday/library-items/LibraryItemModal";
import { apiMap, queryKeyMarkerMap } from "../trips/edit/daybyday/library-items/LibraryItemsTable";
import CardView, { CARDSIZES } from "./CardView";
import ConfirmPopover from "./ConfirmPopover";
import Pagination from "./Pagination";
import CRUDTable from "./Table";

export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholder = "Search by title, client name or ref.",
  borderRadius = "md",
}: {
  preGlobalFilteredRows?: Row<any>[];
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
  placeholder?: string;
  borderRadius?: string;
}) => {
  const count = preGlobalFilteredRows?.length ?? 0;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  useEffect(() => {
    setValue(globalFilter);
  }, [globalFilter]);

  return (
    <InputGroup size="sm">
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        size="sm"
        borderRadius={borderRadius}
        type="search"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        minW={"14em"}
        // style={{
        //   fontSize: "1.1rem",
        // }}
      />
    </InputGroup>
  );
};

export enum ELEMENT_VIEW {
  TABLE = "Table",
  GRID = "Grid",
  MAP = "Map",
  CALENDAR = "Calendar",
}

export interface TableWithDrawerProps<ObjectType extends object> {
  id?: string;
  formName: string;
  eventType?: LibraryItemType;
  displayName?: string;
  subText?: string;
  data: ObjectType[];
  fetchingData?: boolean;
  loadingData?: boolean;
  columns: Column<ObjectType>[];
  getRowProps?: ({
    row,
    toggleAllRowsExpanded,
  }: {
    row: Row;
    toggleAllRowsExpanded: (value?: boolean | undefined) => void;
  }) => any;
  handleAdd?: any;
  handleDelete?: (index: number, value?: any) => void;
  handleEdit?: (index: number, newRow: ObjectType) => void;
  initialSortBy?: Array<SortingRule<ObjectType>>;
  resetFilters?: () => void;
  initialTableState?: PaginationOptions;
  error?: string;
  warning?: string;
  globalSearch?: boolean;
  moveRow?: (fromIndex: number, toIndex: number) => void;
  totalCount?: number;
  initialRowExpansionState?: Record<string, boolean>;
  triggerFetch?: (paginationOptions: {
    page: number;
    step: number;
    q: string;
    order: "DESC" | "ASC" | undefined;
    orderBy: string | undefined;
    filters: Filters<ObjectType>;
  }) => any;
  renderRowExpansion?: (row: Row) => any;
  inlineEdit?: boolean;
  heading2?: React.ReactElement;
  heading?: React.ReactElement;
  underTable?: React.ReactElement;
  disableAddButton?: boolean;
  tableCaption?: any;
  tbodyBackgroundImage?: any;
  minTableHeight?: string;
  maxTableHeight?: string;
  disableSorting?: boolean;
  disableIf?: (cell: CellProps<any>) => boolean;
  lastDisabled?: boolean;
  headerButton?: React.ReactElement;
  inForm?: boolean;
  focusCoordinates?: number[];
  enableGridView?: boolean;
  enableMapView?: boolean;
  enableCalendarView?: boolean;
  popoverMonthGrid?: any;
  popoverWeekGrid?: any;
  calendarItems?: any;
  moveCalendarItem?: () => void;
  showCalendarTypeButtons?: boolean;
  cardViewComponent?: React.ComponentType<{ row: any; key: number; size: CARDSIZES }> | undefined;
  markerComponent?: (item: MarkerMapItem, index: number, onClick: any) => React.ReactNode;
  getEmptyItem?: (coordinates?: number[], address?: GoogleAddress, locationName?: string) => MarkerMapItem;
  filterWidth?: string;
  useSpaciousLayout?: boolean;
  filtersOnNextLine?: boolean;
  showHeading?: boolean;
  showAddButtons?: boolean;
  showIconAddButtons?: boolean;
  noCardBorder?: boolean;
  hideHeadersWhenEmpty?: boolean;
  hiddenColumns?: string[];
  showDeleteConfirm?: boolean;
  buttonDisplayName?: string;
  showTable?: boolean;
  disableAddLabel?: string;
  addButtonLabel?: string;
  disableFilterSave?: boolean;
  defaultView?: ELEMENT_VIEW;
  searchBarPlaceholder?: string;
  showPagination?: boolean;
  showViewSwitch?: boolean;
  showHeaders?: boolean;
  extraFilters?: React.ReactElement[];
  disableFilters?: boolean;
}

function caseInsensitiveAlphaNumericSort(row1: any, row2: any, columnName: string) {
  const rowOneColumn = row1.values[columnName];
  const rowTwoColumn = row2.values[columnName];
  if (isNaN(rowOneColumn)) {
    return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1;
  }
  return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
}

const ExpandedArrowColumn: any = {
  id: "expanded-arrow-indicator",
  minWidth: 0,
  Cell: ({ row }: CellProps<any>) => (
    <chakra.span>
      {row.isExpanded ? (
        <ChevronUpIcon aria-label="close-row-expansion" boxSize={4} />
      ) : (
        <ChevronDownIcon aria-label="open-row-expansion" boxSize={4} />
      )}
    </chakra.span>
  ),
};

const CRUDResource = <T extends object>({
  id,
  formName,
  eventType,
  data,
  fetchingData = false,
  loadingData = false,
  columns,
  handleAdd,
  handleDelete,
  initialRowExpansionState = {},
  displayName = formName,
  subText,
  handleEdit,
  initialSortBy = [],
  initialTableState,
  resetFilters,
  // Initialize the getRowProps with an empty prop object.
  getRowProps = () => ({}),
  error,
  warning,
  globalSearch = false,
  moveRow,
  triggerFetch,
  renderRowExpansion,
  inlineEdit = false,
  heading2,
  heading,
  underTable,
  disableAddButton = false,
  tableCaption,
  tbodyBackgroundImage,
  minTableHeight,
  maxTableHeight,
  disableSorting = false,
  disableIf = () => false,
  lastDisabled = false,
  headerButton,
  inForm = false,
  enableGridView = false,
  enableMapView = false,
  focusCoordinates,
  enableCalendarView = false,
  popoverMonthGrid,
  popoverWeekGrid,
  calendarItems,
  moveCalendarItem,
  showCalendarTypeButtons,
  cardViewComponent,
  markerComponent,
  getEmptyItem = () => ({}),
  filterWidth = "50%",
  useSpaciousLayout = false,
  filtersOnNextLine = false,
  showHeading = true,
  showAddButtons = true,
  showIconAddButtons = true,
  noCardBorder,
  hideHeadersWhenEmpty = false,
  hiddenColumns = [],
  showDeleteConfirm = true,
  buttonDisplayName,
  showTable = true,
  disableAddLabel,
  addButtonLabel = "",
  disableFilterSave = false,
  defaultView = ELEMENT_VIEW.TABLE,
  searchBarPlaceholder,
  showPagination = true,
  showViewSwitch = true,
  showHeaders = true,
  extraFilters,
  disableFilters,
  ...props
}: TableWithDrawerProps<T>) => {
  const [totalCount, setTotalCount] = React.useState(props.totalCount ?? data.length);

  React.useEffect(() => {
    setTotalCount(props.totalCount ?? data.length);
  }, [props.totalCount, data]);

  const initialFilters = initialTableState?.filters || [];
  const singularDisplayName = buttonDisplayName ?? displayName.substring(0, displayName.length - 1);
  const user = useMeContext();
  const [isEditing, setIsEditing] = React.useState<Row<T> | "new">();
  //const [cardView, setCardView] = React.useState<boolean>(false);
  const LS_FILTER_KEY = STORAGE_KEY(formName, user.id, ENV);
  validateLocalStorage(LS_FILTER_KEY);
  const localFilters = localStorage.getItem(LS_FILTER_KEY) && JSON.parse(localStorage.getItem(LS_FILTER_KEY)!);
  const LS_LAYOUT_KEY = `layout-${formName}`;
  const userLayoutPref = localStorage.getItem(LS_LAYOUT_KEY) as ELEMENT_VIEW;
  const [viewState, setViewState] = React.useState<ELEMENT_VIEW>(userLayoutPref ?? defaultView);
  const handleViewState = (state: ELEMENT_VIEW) => {
    localStorage.setItem(LS_LAYOUT_KEY, state);
    setViewState(state);
  };

  // useEffect(() => {
  //   if (userLayoutPref === ELEMENT_VIEW.CARD) {
  //     setCardView(true);
  //   }
  // }, [userLayoutPref]);

  // const [editingSet, setEditingSet] = React.useState<Set<number>>(new Set());

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = React.useMemo(
    () => ({
      disableFilters: true,
      isTruncated: true,
      inlineEdit: true,
      Cell: ({ value, row, column }: any) => {
        // TODO use these to set the fieldarray errors.
        // console.log(errors);
        // console.log(errors?.[fieldArrayName]?.[row.index]?.[column.id]);
        // if (
        //   row &&
        //   isEditing &&
        //   ((isEditing === "new" &&
        //     row.index === tableMethods.rows.length - 1) ||
        //     (isEditing !== "new" && row.id === isEditing.id))
        //   // editingSet.has(row.index)
        // ) {
        //   return (
        //     <Input
        //       autoFocus={column.id === (columns[0].id || columns[0].accessor)}
        //       onBlur={(event) => {
        //         // Assign the new value to the row object
        //         if (!row.new) row.new = {};
        //         row.new[column.id] = event.target.value;
        //       }}
        //       defaultValue={value}
        //       size="sm"
        //       borderRadius="base"
        //       borderWidth="1px"
        //       focusBorderColor="brand.500"
        //       borderColor="gray.300"
        //       _hover={{ borderColor: "brand.200" }}
        //     />
        //   );
        // } else {
        // return value === undefined ? null : value;
        // }
        if (inlineEdit && column.inlineEdit && (!column.skipLast || data.length - 1 !== row.index)) {
          return (
            // <Controller
            //   render={(field) => (
            //     <RHFInput controlled {...field} value={field.value || ""} />
            //   )}
            //   name={`${fieldArrayName}.[${row.index}].${column.id}`}
            //   defaultValue={value}
            // />
            <RHFInput
              name={`${formName}.${row.index}.${column.id}`}
              onClick={(e) => e.stopPropagation()}
              defaultValue={value}
              isDisabled={column.isDisabled}
              isReadOnly={row.original.realtime}
              placeholder={column.placeholder}
            />
          );
        } else {
          return value === undefined ? null : value;
        }
      },
    }),
    [formName, inlineEdit, data.length],
  );

  // const EditColumn: any = React.useMemo(
  //   () => ({
  //     id: "edit-record",
  //     isNumeric: true,
  //     minWidth: 60,
  //     chakraMaxWidth: "60px",
  //     width: 60,
  //     Cell: ({ row }: any) => {
  //       // If a new row is added or if an old row is edited
  //       if (
  //         row &&
  //         isEditing &&
  //         ((isEditing === "new" &&
  //           row.index === tableMethods.rows.length - 1) ||
  //           (isEditing !== "new" && row.id === isEditing.id))
  //         // editingSet.has(row.index)
  //       ) {
  //         return (
  //           <Tooltip label="Save" aria-label="save-record-tooltip">
  //             <IconButton
  //               aria-label="save-record"
  //               colorScheme="green"
  //               icon={<Icon as={CheckIcon} boxSize={4} />}
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 // Change the table data such that the isEditing is set to true for the row which is clicked on
  //                 //   // ATTENTION: as mentioned in the RHF docs: no subsequent actions are allowed. (maybe this is the cause of the weird edit behaviour?)
  //                 //   // Set the value of the new row
  //                 //   // setValue(`${fieldArrayName}[${row.index}].isEditing`, true);
  //                 setIsEditing(undefined);
  //                 // editingSet.delete(row.index);
  //                 // setEditingSet(editingSet);
  //                 // Save the row
  //                 const r = row;
  //                 // Construct the new row
  //                 const newR = { ...r.original, ...r.new };
  //                 // Update the row (update method is defined because otherwise the edit column is not present)
  //                 handleEdit!(row.index, newR);
  //                 // TODO: yup validation here
  //               }}
  //               w="fit-content"
  //               variant="ghost"
  //             />
  //           </Tooltip>
  //         );
  //       } else {
  //         return (
  //           <Tooltip label="Edit" aria-label="edit-record-tooltip">
  //             <IconButton
  //               aria-label="edit-record"
  //               icon={<Icon as={EditIcon} boxSize={4} />}
  //               onClick={(e) => {
  //                 e.stopPropagation();
  //                 setIsEditing(row);
  //                 // setEditingSet(editingSet.add(row.index));
  //               }}
  //               w="fit-content"
  //               variant="ghost"
  //             />
  //           </Tooltip>
  //         );
  //       }
  //     },
  //   }),
  //   [isEditing, handleEdit]
  // );

  const onDelete = React.useCallback(
    (e: any, row: Row) => {
      e.stopPropagation();
      e.preventDefault();
      // Only when in editing mode (update is defined)
      // set isEditing to undefined when deleting the currently editing row
      if (handleEdit) setIsEditing(undefined);
      // If this row is expanded, close the row.
      if (row.isExpanded) row.toggleRowExpanded(false);
      // editingSet.delete(index);
      // if (handleEdit) setEditingSet(editingSet);
      // handleDelete is always defined as the handleDelete check is executed when adding the column to the columns list
      handleDelete!(row.index, data[row.index]);
    },
    [handleDelete, handleEdit],
  );

  const DeleteColumn: any = React.useMemo(
    () => ({
      id: "delete-record",
      isNumeric: true,
      isTruncated: false,
      // minWidth: 60,
      // chakraMaxWidth: "60px",
      // width: 60,
      chakraWidth: "60px",
      Cell: (cell: CellProps<any>) => {
        const confirmDiscl = useDisclosure();

        return disableIf(cell) ? (
          ""
        ) : (
          <ConditionalWrapper
            condition={showDeleteConfirm}
            wrapper={(children: any) => (
              <ConfirmPopover
                disclosure={confirmDiscl}
                message={"Are you sure you want to remove this record from the trip?"}
                action={(e) => onDelete(e, cell.row)}
              >
                {children}
              </ConfirmPopover>
            )}
          >
            <Tooltip label="Remove" aria-label="remove-record-tooltip">
              <IconButton
                colorScheme="red"
                aria-label="remove-record"
                icon={<Icon as={DeleteIcon} boxSize={"18px"} />}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  showDeleteConfirm ? confirmDiscl.onOpen() : onDelete(e, cell.row);
                }}
                w="fit-content"
                variant="ghost"
              />
            </Tooltip>
          </ConditionalWrapper>
        );
      },
    }),
    [onDelete],
  );

  const DragMoveColumn = React.useMemo(
    () => ({
      id: "move-row",
      chakraWidth: "10px",
      Cell: (props: any) =>
        disableIf(props) ? (
          <></>
        ) : (
          <Tooltip label="Drag to reorder" aria-label="drag-row-tooltip">
            <span {...props.dragHandleProps}>
              <Icon as={DragHandleIcon} />
            </span>
          </Tooltip>
        ),
    }),
    [],
  );

  const newColumns = React.useMemo(() => {
    let c = columns;
    // Add the defaultColumn to each column.
    c = c.map((column) =>
      column.accessor
        ? // Only use the defaultColumn values if there is an accessor.
          {
            ...defaultColumn,
            ...column,
          }
        : column,
    );
    // if (handleEdit) {
    //   // Add the edit button only if the update method is defined.
    //   c.push(EditColumn);
    // }
    if (renderRowExpansion) {
      // Render an expansion arrow indicator column
      c.unshift(ExpandedArrowColumn);
    }
    if (moveRow) {
      c.unshift(DragMoveColumn);
    }
    if (handleDelete) {
      // Add the delete button only if the handleDelete is defined.
      c.push(DeleteColumn);
    }
    return c;
  }, [
    columns,
    handleDelete,
    defaultColumn,
    DeleteColumn,
    renderRowExpansion,
    moveRow,
    DragMoveColumn,
    // ExpandedArrowColumn,
  ]);

  const pageSize = localFilters && localFilters.step ? localFilters.step : initialTableState?.step;
  const rtInitialTableState = useMemo(
    () => ({
      // The initial column on which to sort
      // sort: localFilters && localFilters.order ? localFilters.order : "DESC",
      sortBy:
        localFilters && localFilters.orderBy && !disableSorting
          ? [{ id: localFilters.orderBy, desc: localFilters.order === "DESC" }]
          : initialSortBy,
      filters: localFilters && localFilters.filters ? localFilters.filters : initialFilters,
      pageIndex: localFilters && localFilters.page ? localFilters.page : 0,
      pageSize: pageSize,
      globalFilter: localFilters && localFilters.q ? localFilters.q : "",
      // The initial rows which are expanded
      expanded: initialRowExpansionState,
      hiddenColumns: hiddenColumns,
    }),
    [pageSize, initialFilters, initialRowExpansionState, initialSortBy, localFilters],
  );

  const generalTableOptions: TableOptions<T> = {
    columns: newColumns as any,
    data,
    disableSortBy: disableSorting,
    // Initial sort state
    initialState: rtInitialTableState,
    // To not reset the expanded state when the data changes
    // autoResetExpanded: false,
    // Custom comparator functions
    sortTypes: { alphanumeric: caseInsensitiveAlphaNumericSort },
    // To allow for server side pagination, only when a pageSize prop is set
    manualPagination: !!pageSize,
    // To allow for server side sorting, only when a pageSize is given. (Hence, only when server side functionality do also manual sorting)
    manualSortBy: pageSize ? true : false,
    // To allow for server-side searching
    manualGlobalFilter: true,
    // To allow for server-side filtering
    manualFilters: true,
    autoResetFilters: true,
    // To allow for both pagination and sorting
    autoResetSortBy: false,
    autoResetPage: false,
    // The number of pages needed, only when a pageSize prop is set
    pageCount: pageSize ? Math.ceil(totalCount / pageSize) : undefined,
    // Not from the react-table API but everything added below can be used inside the Cell renderers etc
  };
  // React table hook, when the pageSize is defined, use pagination, filtering and searching.
  const tableMethods = useTable<T>(
    {
      ...generalTableOptions,
    },
    useGlobalFilter,
    // // Explanation about the different settings for the column specific filters (this in combination with disableFilters in defaultColumn will disable the column filters by default)
    // // https://github.com/tannerlinsley/react-table/issues/2506#issuecomment-701197958
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const { state }: TableInstance<T> = tableMethods;

  React.useEffect(() => {
    if (totalCount < (state.pageIndex + 1) * state.pageSize && totalCount !== 0) {
      tableMethods.gotoPage(Math.floor(totalCount / state.pageSize));
    }
  }, [data, tableMethods, state.pageIndex]);

  React.useEffect(() => {
    if (!disableFilterSave) {
      localStorage.setItem(
        LS_FILTER_KEY,
        JSON.stringify({
          page: state.pageIndex,
          step: state.pageSize,
          q: state.globalFilter,
          order: state.sortBy[0] ? (state.sortBy[0].desc ? "DESC" : "ASC") : undefined,
          orderBy: state.sortBy[0] ? state.sortBy[0].id : undefined,
          filters: state.filters,
          updated: new Date(),
        }),
      );
    }

    if (triggerFetch) {
      triggerFetch({
        page: state.pageIndex + 1,
        step: state.pageSize,
        q: state.globalFilter,
        order: state.sortBy[0]
          ? state.sortBy[0].desc
            ? "DESC"
            : "ASC"
          : state.filters.find((f) => f.id === "location")?.value
            ? "ASC"
            : undefined,
        orderBy: state.sortBy[0] ? state.sortBy[0].id : undefined,
        filters: state.filters,
      });
    }
  }, [LS_FILTER_KEY, state.filters, state.globalFilter, state.pageIndex, state.pageSize, state.sortBy]);

  const onReset = () => {
    localStorage.setItem(LS_FILTER_KEY, JSON.stringify({ filters: JSON.stringify(initialFilters) }));
    state.pageIndex = 0;
    state.pageSize = 10;
    state.filters = initialFilters;
    state.globalFilter = "";
    state.sortBy = initialSortBy;
    tableMethods.setGlobalFilter(null);
    resetFilters && resetFilters();
  };

  const onAdd = React.useCallback(() => {
    // HandleAdd method is defined as this is checked in the render method
    handleAdd();
    // Set this row to editing if this resource has an edit button
    if (handleEdit) setIsEditing("new");
    // if (handleEdit) setEditingSet(editingSet.add(data.length));
  }, [handleAdd, handleEdit]);

  const resourceTitle = () => <ResourceTitle displayName={displayName} totalCount={totalCount} inForm={inForm} />;

  const smallAdd = () => {
    if (handleAdd && !inlineEdit)
      return (
        <SmallAddResourceButton
          display={{ base: "none", md: "inline" }}
          singularDisplayName={singularDisplayName}
          onAdd={onAdd}
          isDisabled={disableAddButton}
        />
      );
  };

  const bigAdd = () => {
    if (handleAdd)
      return inlineEdit ? (
        <SmallAddResourceButton
          disableAddLabel={disableAddLabel}
          singularDisplayName={singularDisplayName}
          isDisabled={disableAddButton}
          onAdd={onAdd}
        />
      ) : (
        <Tooltip label={disableAddButton ? disableAddLabel : addButtonLabel}>
          <Button
            leftIcon={window.innerWidth < 480 ? <></> : <AddIcon />}
            colorScheme="brand"
            onClick={onAdd}
            isDisabled={disableAddButton}
          >
            Add {singularDisplayName}
          </Button>
        </Tooltip>
      );
  };

  const searchbar = () => {
    return (
      <Box mb={2} width={"60%"} maxW={"100%"} alignSelf={"flex-start"}>
        <GlobalFilter
          preGlobalFilteredRows={tableMethods.preGlobalFilteredRows}
          globalFilter={tableMethods.state.globalFilter}
          setGlobalFilter={tableMethods.setGlobalFilter}
          placeholder={viewState === ELEMENT_VIEW.MAP ? "Search by location" : searchBarPlaceholder}
        />
      </Box>
    );
  };

  const filters = () => {
    const colums = tableMethods.headerGroups
      .map((headerGroup) => headerGroup.headers)
      .flat()
      .filter((column) => column.canFilter);
    return (
      <>
        {colums.map((column) => {
          if (viewState === ELEMENT_VIEW.MAP && column.hideFiltersOnMapView) return null;
          return <div key={`filter-column-${column.id}`}>{column.render("Filter")}</div>;
        })}
        {extraFilters}
      </>
    );
  };

  const filterPadding = { base: "2", lg: "0" };

  // Handles the menu component to show the different views
  type viewIcon = {
    icon: any;
    onClick: () => void;
    label: string | JSX.Element;
    view: ELEMENT_VIEW;
  };

  const viewIcons: viewIcon[] = [
    {
      icon: <Icon as={BiTable} boxSize={4} color={PRIMARY_COLOR} />,
      onClick: () => handleViewState(ELEMENT_VIEW.TABLE),
      view: ELEMENT_VIEW.TABLE,
      label: `${ELEMENT_VIEW.TABLE} Layout`,
    },
  ];

  if (enableGridView) {
    viewIcons.push({
      icon: <Icon as={BiGridVertical} boxSize={4} color={PRIMARY_COLOR} />,
      onClick: () => handleViewState(ELEMENT_VIEW.GRID),
      view: ELEMENT_VIEW.GRID,
      label: `${ELEMENT_VIEW.GRID} Layout`,
    });
  }

  if (enableMapView) {
    viewIcons.push({
      icon: <Icon as={BiMapAlt} boxSize={4} color={PRIMARY_COLOR} />,
      onClick: () => handleViewState(ELEMENT_VIEW.MAP),
      view: ELEMENT_VIEW.MAP,
      label:
        eventType === "accommodation" || eventType === "activity" ? (
          <Flex>
            {ELEMENT_VIEW.MAP} Layout <Box marginBottom={1}> </Box>
          </Flex>
        ) : (
          `${ELEMENT_VIEW.MAP} Layout`
        ),
    });
  }
  function IconMenu(icons: viewIcon[]) {
    if (icons.length === 1) {
      return <></>;
    }

    return (
      <Menu>
        <MenuButton as={IconButton} colorScheme="brand" aria-label="Views" icon={<HamburgerIcon />} variant="ghost" />
        <MenuList zIndex={1000}>
          {icons.map((icon, index) => {
            const isSelected = viewState === icon.view;

            return (
              <MenuItem
                key={index}
                icon={icon.icon}
                onClick={icon.onClick}
                opacity={isSelected ? 1 : 0.75}
                color={isSelected ? PRIMARY_COLOR : "gray.800"}
                fontWeight={isSelected ? "bold" : "normal"}
              >
                {icon.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  }

  function getFilterColumns() {
    return tableMethods.headerGroups;
  }

  const filterOptions = () => {
    const filterOptions: any = {};
    const columns = getFilterColumns();
    const searchbar = tableMethods.state.globalFilter;
    if (searchbar) {
      filterOptions["q"] = searchbar;
    }
    columns
      .filter((filter) => filter.filterValue !== undefined)
      .filter((filter) => filter.filterValue.length !== 0)
      .forEach((column) => {
        filterOptions[column.id] = column.filterValue;
      });
    return filterOptions;
  };

  return (
    <>
      {useSpaciousLayout && showHeading && (
        <>
          <Flex mb={subText ? 0 : 2} alignItems={"center"}>
            {resourceTitle()} {smallAdd()}
            <Spacer />
            {headerButton} {bigAdd()}
          </Flex>
          {subText && (
            <Text fontSize="small" color="GrayText" mb={4}>
              {subText}
            </Text>
          )}
        </>
      )}
      <Section
        id={id}
        noHorizontalPadding
        noDivider
        noBorderRadius={inForm}
        noShadow={formName === "tripdays" || inForm}
        inForm={inForm}
        border={inForm ? "none" : "1px solid"}
        height={"100%"}
        heading={
          <Box mb={2}>
            <Flex alignItems="flex-start" justifyContent={"space-between"} flex-direction="row">
              {heading ?? (
                <Flex overflow={"visible"} alignItems="center" width={"100%"} flexWrap={{ base: "wrap", lg: "nowrap" }}>
                  {!useSpaciousLayout && showHeading && resourceTitle()}
                  {!useSpaciousLayout && showHeading && showAddButtons && showIconAddButtons && smallAdd()}
                  {globalSearch && (
                    <>
                      {!useSpaciousLayout && showHeading && <Spacer />}
                      <HStack
                        flexWrap={"wrap"}
                        alignItems={"flex-start"}
                        pt={filterPadding}
                        width={viewState == ELEMENT_VIEW.GRID ? "80%" : "100%"}
                        spacing={1}
                      >
                        {searchbar()}
                        {filters()}
                      </HStack>
                      {!useSpaciousLayout && <Spacer />}
                    </>
                  )}
                  {!useSpaciousLayout && showHeading && showAddButtons && bigAdd()}
                  {!useSpaciousLayout && headerButton}
                </Flex>
              )}
              <Flex marginLeft={"auto"} alignSelf={"flex-start"}>
                {state.filters.length > 0 && !disableFilterSave && (
                  <Tooltip label="Remove filters" aria-label="remove-record-tooltip">
                    <IconButton
                      colorScheme="brand"
                      aria-label="remove-record"
                      icon={<Icon as={MdFilterAltOff} boxSize={4} />}
                      onClick={onReset}
                      w="fit-content"
                      variant="ghost"
                      ml={1}
                    />
                  </Tooltip>
                )}
                {/* Turned IconButton into a Menu to show the different views */}
                {showViewSwitch && (enableGridView || enableMapView || enableCalendarView) && (
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      colorScheme="brand"
                      aria-label="Views"
                      icon={<HamburgerIcon />}
                      variant="ghost"
                    />
                    <MenuList zIndex={1000}>
                      <MenuItem
                        icon={<Icon as={BiTable} boxSize={4} color={PRIMARY_COLOR} />}
                        onClick={() => handleViewState(ELEMENT_VIEW.TABLE)}
                      >
                        {`${ELEMENT_VIEW.TABLE} Layout`}
                      </MenuItem>
                      {enableGridView && (
                        <MenuItem
                          icon={<Icon as={BiGridVertical} boxSize={4} color={PRIMARY_COLOR} />}
                          onClick={() => handleViewState(ELEMENT_VIEW.GRID)}
                        >
                          {`${ELEMENT_VIEW.GRID} Layout`}
                        </MenuItem>
                      )}
                      {enableMapView && (
                        <MenuItem
                          icon={<Icon as={BiMapAlt} boxSize={4} color={PRIMARY_COLOR} />}
                          onClick={() => handleViewState(ELEMENT_VIEW.MAP)}
                        >
                          {`${ELEMENT_VIEW.MAP} Layout`}
                        </MenuItem>
                      )}
                      {enableCalendarView && (
                        <MenuItem
                          icon={<Icon as={CalendarIcon} boxSize={4} color={PRIMARY_COLOR} />}
                          onClick={() => handleViewState(ELEMENT_VIEW.CALENDAR)}
                        >
                          {`${ELEMENT_VIEW.CALENDAR} Layout`}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                )}
              </Flex>
              {heading2}
            </Flex>
            {error && (
              <Alert status="error" variant="left-accent">
                <AlertIcon boxSize={4} />
                <Text fontSize="sm" color="gray.700">
                  {error}
                </Text>
              </Alert>
            )}
            {warning && (
              <Alert status="warning" variant="left-accent">
                <AlertIcon boxSize={4} />
                <Text fontSize="sm" color="gray.700">
                  {warning}
                </Text>
              </Alert>
            )}
          </Box>
        }
      >
        {/* Shows view based on the viewState with the default view being the table view. */}
        {viewState == ELEMENT_VIEW.GRID ? (
          <CardView
            tableMethods={tableMethods}
            rows={pageSize ? tableMethods.page : tableMethods.rows}
            fetchingData={fetchingData}
            loadingData={loadingData}
            displayName={displayName}
            tableCaption={tableCaption}
            tbodyBackgroundImage={tbodyBackgroundImage}
            minTableHeight={minTableHeight}
            cardViewComponent={cardViewComponent}
            getRowProps={getRowProps}
            handleEdit={handleEdit}
            noBorder={noCardBorder}
            isFiltering={
              (tableMethods?.state?.filters
                ? (tableMethods.state.filters as any[]).some((filter: any) =>
                    filter.value ? filter.value?.length > 0 : false,
                  )
                : false) ||
              (tableMethods.state.globalFilter && tableMethods.state.globalFilter !== "")
            }
            useSpaciousLayout={useSpaciousLayout}
          />
        ) : viewState == ELEMENT_VIEW.MAP ? (
          <Box h="50vh" marginLeft={"2%"} w="96%" mt={4} pos="relative" borderRadius="xl" overflow="hidden">
            <LibraryItemMaps
              showAllMarkers
              markerComponent={markerComponent}
              getEmptyItem={getEmptyItem}
              query_key={queryKeyMarkerMap[eventType! as MarkerMapItemType]}
              filterOptions={filterOptions()}
              api={apiMap[eventType! as MarkerMapItemType]}
              type={eventType! as MarkerMapItemType}
              handleAdd={handleAdd}
              handleEdit={handleEdit}
              focusCoordinates={focusCoordinates}
              setTotalCount={setTotalCount}
            />
          </Box>
        ) : viewState == ELEMENT_VIEW.CALENDAR ? (
          <Calendar
            isFetchingCalendarItems={fetchingData}
            calendarItems={calendarItems}
            moveCalendarItem={moveCalendarItem}
            triggerFetch={(from: any, to: any) => {
              triggerFetch &&
                triggerFetch({
                  page: state.pageIndex + 1,
                  step: state.pageSize,
                  q: state.globalFilter,
                  order: state.sortBy[0] ? (state.sortBy[0].desc ? "DESC" : "ASC") : undefined,
                  orderBy: state.sortBy[0] ? state.sortBy[0].id : undefined,
                  filters: state.filters.map((f) => {
                    if (f.id === "start_date") {
                      return { id: "start_date", value: { from: from, to: to } };
                    } else {
                      return f;
                    }
                  }),
                });
            }}
            isLoadingUpdate={false}
            showCalendarTypeButtons={showCalendarTypeButtons}
            popoverMonthGrid={popoverMonthGrid}
            popoverWeekGrid={popoverWeekGrid}
          />
        ) : (
          <CRUDTable
            isFiltering={
              (tableMethods?.state?.filters
                ? (tableMethods.state.filters as any[]).some((filter: any) => filter?.value && filter.value.length > 0)
                : false) ||
              (tableMethods.state.globalFilter && tableMethods.state.globalFilter !== "")
            }
            tableMethods={tableMethods}
            rows={pageSize ? tableMethods.page : tableMethods.rows}
            totalCount={totalCount}
            fetchingData={fetchingData}
            loadingData={loadingData}
            getRowProps={getRowProps}
            moveRow={moveRow}
            renderRowExpansion={renderRowExpansion}
            addRow={onAdd}
            displayName={displayName}
            tableCaption={tableCaption}
            tbodyBackgroundImage={tbodyBackgroundImage}
            minTableHeight={minTableHeight}
            maxTableHeight={maxTableHeight}
            lastDisabled={lastDisabled}
            hideHeadersWhenEmpty={hideHeadersWhenEmpty}
            showHeaders={showHeaders}
          />
        )}

        {viewState != ELEMENT_VIEW.MAP && viewState != ELEMENT_VIEW.CALENDAR && showPagination && (
          <Pagination tableMethods={tableMethods} totalCount={totalCount} pageSize={pageSize} />
        )}
      </Section>
    </>
  );
};
export default React.memo(CRUDResource);

type SmallAddResourceButtonProps = Partial<IconButtonProps> & {
  singularDisplayName: string;
  onAdd: any;
  inForm?: boolean;
  disableAddLabel?: string;
  isDisabled?: boolean;
};

export const SmallAddResourceButton: React.FC<SmallAddResourceButtonProps> = ({
  singularDisplayName,
  disableAddLabel,
  onAdd,
  isDisabled,
  ...props
}) => (
  <Tooltip
    label={isDisabled ? disableAddLabel : `Add ${singularDisplayName}`}
    aria-label={`add-${singularDisplayName}-tooltip`}
  >
    <IconButton
      {...props}
      size="sm"
      icon={<AddIcon />}
      colorScheme="brand"
      aria-label={`Add new ${singularDisplayName}`}
      onClick={onAdd}
      variant="ghost"
    />
  </Tooltip>
);

export const ResourceTitle = ({ displayName, totalCount, reversed = false, inForm = false }: any) => {
  return (
    <>
      {reversed && <BadgeCount totalCount={totalCount} />}
      <Heading size={inForm ? "md" : "lg"} color="realGray.800" whiteSpace="nowrap">
        {/* Uppercase the resource name */}
        {`${displayName.charAt(0).toUpperCase()}${displayName.slice(1)}`}{" "}
      </Heading>
      {!reversed && totalCount !== undefined && <BadgeCount totalCount={totalCount} />}
    </>
  );
};

export const BadgeCount = ({ totalCount, height }: any) => (
  <Badge ml="1" mr={3} fontSize="0.7rem" fontWeight="600" borderRadius="full" py="0.07rem" px={2} height={height}>
    {totalCount}
  </Badge>
);
