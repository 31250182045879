import rawApiProvider from "./utilities/rawProvider";

const url = "calendar";

const getCalendar = (queryParams: string) => {
  return rawApiProvider.get(`${url}/items?${queryParams}`);
};

type CalendarAPIType = {
  getCalendar(queryString: string): Promise<any>;
};
const CalendarAPI: CalendarAPIType = {
  getCalendar,
};
export default CalendarAPI;
