import { InputProps } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { Controller, ControllerRenderProps, useWatch } from "react-hook-form";
import RHFInput from "./RHFInput";

export interface TimeMaskInputProps extends InputProps {
  name: string;
  defaultDate?: Date | null;
  customOnChangeHandler?: any;
  showError?: boolean;
}

const toString = (date: Date | null): string => {
  return date ? format(new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000), "HH:mm") : "";
};

const TimeMaskInput: React.FC<TimeMaskInputProps> = ({ name, defaultDate, customOnChangeHandler, ...props }) => {
  const date = useWatch({
    name,
    defaultValue: defaultDate,
  });
  const [inputValue, setInputValue] = React.useState<string>(date);

  function toDate(time: string): Date | null {
    if (!time) {
      setInputValue(date);
      return new Date(date);
    }
    if (/\d\d:\d\d/.test(time)) {
      try {
        // Split the time string into the hours and the minutes.
        const newDate = new Date(date ?? "");
        const parts = time.split(":");
        const hours = +parts[0];
        const minutes = +parts[1];
        newDate.setUTCHours(hours, minutes);
        //const userTimezoneOffset = newDate!.getTimezoneOffset() * 60000;
        ///return new Date(newDate!.getTime() - userTimezoneOffset);
        return newDate;
      } catch {
        // If the time could not be parsed, return null and show an error message.
        // trigger();
        setInputValue(date);
        return new Date(date);
      }
    }
    setInputValue(date);
    return new Date(date);
  }

  React.useEffect(() => {
    setInputValue(date);
  }, [date, setInputValue]);

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<Record<string, any>>) => {
    field.onChange(e.target.value);
    field.onBlur();
  };

  return (
    <Controller
      name={name}
      defaultValue={date}
      render={({ field }) => (
        <RHFInput
          {...props}
          {...field}
          controlled
          isDisabled={props.isDisabled}
          onChange={(e) => {
            setInputValue(e.target.value);
            if (customOnChangeHandler) customOnChangeHandler(e.target.value);
          }}
          onBlur={(e) => onBlur(e, field)}
          value={inputValue}
          mask="99:99"
          // mask={mask}
          alwaysShowMask={false}
          maskPlaceholder="hh:mm"
        />
      )}
    />
  );
};
export default React.memo(TimeMaskInput);
