import { User } from "@lato/common";
import getApiEndpoints from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "users";

// Get the standard CRUD operations
const { delete: deleteUser } = getApiEndpoints({
  url,
});

const getAllTrips = (userId: string) => {
  return rawApiProvider.get(`${url}/${userId}/trips`);
};

const changeAvatar = (s3key: string) => {
  return rawApiProvider.patch(`${url}/change-avatar`, { avatar: s3key });
};

const editRole = (userId: string, model: object) => {
  return rawApiProvider.patch(`${url}/${userId}/role`, model);
};

const updateUser = (user: User) => {
  return rawApiProvider.put(url, user);
};

const updateZapierApiKey = (apiKey?: string) => {
  return rawApiProvider.patch(`${url}/zapierAPIKey`, { apiKey });
};

const patchUser = (user: Partial<User>) => {
  return rawApiProvider.patch(url, user);
};

const accountSetup = (accountSetupDTO: object) => {
  return rawApiProvider.post(url + "/account-setup", accountSetupDTO);
};

const updateAIConversionCounter = (type: "increment" | "decrement") => {
  return rawApiProvider.post(`${url}/ai-conversion-counter`, { type });
};
const getLatestChangelog = (userId: string) => {
  return rawApiProvider.get(`${url}/${userId}/changelog`);
};

type UsersAPIType = {
  getAllTrips(userId: string): Promise<any>;
  changeAvatar(s3key: string): Promise<any>;
  deleteUser(id: string): Promise<any>;
  patchUser(user: Partial<User>): Promise<any>;
  updateUser(user: User): Promise<any>;
  updateZapierApiKey(apiKey?: string): Promise<any>;
  accountSetup(accountSetupDTO: object): Promise<any>;
  editRole(userId: string, model: object): Promise<any>;
  updateAIConversionCounter(model: "increment" | "decrement"): Promise<any>;
  getLatestChangelog(userId: string): Promise<any>;
};
const UsersAPI: UsersAPIType = {
  getAllTrips: getAllTrips,
  changeAvatar: changeAvatar,
  deleteUser: deleteUser,
  updateUser,
  updateZapierApiKey,
  patchUser,
  accountSetup,
  editRole,
  updateAIConversionCounter,
  getLatestChangelog,
};
export default UsersAPI;
