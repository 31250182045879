import { TranslateFields } from "@lato/common";
import getApiEndpoints, { ReturnEndpoints } from "./utilities/core";
import rawApiProvider from "./utilities/rawProvider";

const url = "translation";

// Get the standard CRUD operations
const apiEndpoints = getApiEndpoints({ url });

// Get the specific invite team member REST endpoint
const translate = (queryParams: string) => {
  return rawApiProvider.get(url + "/translate?" + queryParams);
};

const getTargetLanguages = () => {
  return rawApiProvider.get(url + "/gettargetlanguages");
};

const getSourceLanguages = () => {
  return rawApiProvider.get(url + "/getsourcelanguages");
};

const translateFields = (translateFields: TranslateFields) => {
  return rawApiProvider.post(url + "/translatefields", translateFields);
};

const getCharacterUsage = () => {
  return rawApiProvider.get(url + "/characterUsage");
};

type TranslateAPIType = ReturnEndpoints & {
  translate(queryParams: string): Promise<any>;
  translateFields(translateFields: TranslateFields): Promise<any>;
  getTargetLanguages(): Promise<any>;
  getSourceLanguages(): Promise<any>;
  getCharacterUsage(): Promise<any>;
};
const TranslateAPI: TranslateAPIType = {
  ...apiEndpoints,
  translate,
  translateFields,
  getTargetLanguages,
  getSourceLanguages,
  getCharacterUsage,
};
export default TranslateAPI;
