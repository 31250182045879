import { Link } from "@chakra-ui/react";
import { ExternalImage, ImageSite } from "@lato/common";
import React from "react";
import ImagesExternalApi from "../../api/images-external.api";
import { EXTERNAL_IMAGE_APP_NAME } from "../../utils/constants";
import Card from "./Card";

interface ExternalCardProps {
  Photo: ExternalImage;
  handleChosenPicture: (photo: ExternalImage) => void;
  selected: boolean;
}

const ExternalCard: React.FC<ExternalCardProps> = ({ Photo, handleChosenPicture, selected }) => {
  const choosePicture = async () => {
    if (Photo.imageSite === ImageSite.UNSPLASH) {
      try {
        // No await in order not to block as this is not necessary for this api call.
        await ImagesExternalApi.trackDownload(Photo.links.download_location);
      } catch (e) {
        console.error(e);
      }
    }
    handleChosenPicture(Photo);
  };

  return (
    <Card
      choosePicture={choosePicture}
      imageAlt={Photo.user.name}
      imageSite={Photo.imageSite}
      imageUrl={Photo.urls.small}
      selected={selected}
      nameElement={
        <Link
          isExternal
          href={`${Photo.user.links.html}?utm_source=${EXTERNAL_IMAGE_APP_NAME}&utm_medium=referral`}
          onClick={(e) => e.stopPropagation()}
        >
          {Photo.user.name}
        </Link>
      }
    />
  );
};
export default React.memo(ExternalCard);
