import React from "react";
import DestinationsAPI from "../../../api/destinations.api";
import { useMeContext } from "../../../stores/me-context";
import { DESTINATIONS_QUERY_KEY } from "../../../utils/constants";
import ENV from "../../../utils/env";
import LibraryItems from "../../trips/edit/daybyday/library-items/LibraryItems";
import LibraryItemsList from "../../trips/edit/daybyday/library-items/LibraryItemsList";
import { getEmptyDestination } from "../activities";

interface TextFieldsProps {}

const resourceName = "destination";

const Destinations: React.FC<TextFieldsProps> = () => {
  const user = useMeContext();

  const localLanguageFilter = localStorage.getItem(`filter-destinations-${user.id}-${ENV}`);

  const initialLanguageFilter =
    localLanguageFilter && JSON.parse(localLanguageFilter).find((f: any) => f.id === "languages")?.value;

  return (
    <LibraryItems>
      <LibraryItemsList
        api={DestinationsAPI}
        eventType={resourceName}
        emptyResource={getEmptyDestination(user)}
        query_key={DESTINATIONS_QUERY_KEY}
        initialFilters={[
          {
            id: "languages",
            value: initialLanguageFilter && initialLanguageFilter?.length !== 0 ? initialLanguageFilter : undefined,
          },
        ]}
      />
    </LibraryItems>
  );
};
export default React.memo(Destinations);
