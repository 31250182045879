import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useMe } from "../../utils/query-helpers/reactQueryHooks";

type PublicRouteProps = {
  children: React.ReactNode;
};

// TODO: remove code duplication
const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const location = useLocation();
  // should be refetched in the background every 1 hour
  const { data, error } = useMe();
  // const { data, isLoading, error } = useMe(1000 * 60 * 60 * 1);

  // const [user, setUser] = React.useState<any>(null);
  // const [loading, setLoading] = React.useState(true);

  // React.useEffect(() => {
  //   let isMounted = true;
  //   async function authenticate() {
  //     try {
  //       const res = await AuthAPI.me();
  //       const loggedInUser = res.data;
  //       if (loggedInUser && isMounted) setUser(loggedInUser);
  //       setLoading(false);
  //     } catch (error) {
  //       // Failed logging in
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   }
  //   authenticate();
  //   return () => {
  //     isMounted = false;
  //   }; // use effect cleanup to set flag false, if unmounted
  // }, []);

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (isLoading) return <FullScreenSpinner />;

  if (error || !data) {
    // not logged in so let the user go to the login page
    return <>{children}</>;
  }

  // TODO: role check here
  // check if route is restricted by role
  // if (roles && roles.indexOf(currentUser.role) === -1) {
  //   // role not authorised so redirect to home page
  //   return <Redirect to={{ pathname: '/'}} />
  //  }

  // Logged in
  // authorised so return component
  return <Navigate to={{ pathname: "/", search: location.search }} />;
};
export default PublicRoute;
