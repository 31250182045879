import { Box } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import useInternetConnection from "../../utils/checkInternetConnection";
import { useMe } from "../../utils/query-helpers/reactQueryHooks";
import AccountSetupModal from "../../features/accountSetup/AccountSetupModal";
import FullScreenSpinner from "../FullScreenSpinner";
import HorizontalTopMenuBar from "../../features/menu/horizontal/HorizontalTopMenuBar";
import EmailConfirmationAlert from "../alerts/EmailConfirmationAlert";
import StrapiAnnouncementAlert from "../alerts/StrapiAnnouncementAlert";
import ErrorCardView from "./ErrorCardView";

interface MainFrameProps {
  navBarWidth?: string;
  fullHeight: boolean;
  children: React.ReactNode;
}

const MainFrame: React.FC<MainFrameProps> = ({ children, fullHeight, navBarWidth = "0px" }) => {
  const { data: user, isLoading: isLoadingMe, error: errorMe } = useMe();
  const location = useLocation();
  // Hide the alerts if on the trip edit page.
  const hideAlerts = location.pathname.split("/")[1] === "trips";
  useInternetConnection();

  if (isLoadingMe) return <FullScreenSpinner />;
  if (errorMe || !user) return <ErrorCardView title="Could not load user" />;

  return (
    <Box
      flexDir="column"
      display={fullHeight ? "flex" : undefined}
      overflow="auto"
      flexGrow={1}
      // bg="rgb(240,242,245)"
      bg="lato.bg"
      w={["100%", `calc(100vw - ${navBarWidth})`]}
      minH={[`calc(100vh - ${navBarWidth})`, "100%"]}
      overflowX={"hidden"}
    >
      {!hideAlerts && (
        <div style={{ flexShrink: 0 }}>
          <StrapiAnnouncementAlert />
          <EmailConfirmationAlert user={user} />
        </div>
      )}
      <div className="relative">
        <HorizontalTopMenuBar user={user} />
        <Box pl={{ base: 5, xl: 0 }} pr={{ base: 5, xl: 0 }}>
          {children}
        </Box>
        {!user.name && <AccountSetupModal user={user} />}
      </div>
    </Box>
  );
};
export default MainFrame;
