import { Spinner } from "@chakra-ui/react";
import { RefObject } from "react";
import { type InfiniteData, type UseInfiniteQueryResult } from "@tanstack/react-query";
import useIntersectionObserver from "../useIntersectionObserver";
import {
  PaginationReturn,
  useHelperCustomInfiniteQuery,
  UsePaginationQueryProps,
  UsePaginationQueryReturn,
} from "./usePaginatedQuery";

type InfiniteQueryProps<T> = UsePaginationQueryReturn<T, UseInfiniteQueryResult<PaginationReturn<T>, Error>> & {
  refetchEl: JSX.Element | undefined;
};

export function useCustomInfiniteQuery<T>(
  pageEndRef: RefObject<any>,
  paginationQueryOptions: UsePaginationQueryProps<T>,
): InfiniteQueryProps<T> {
  const infinitePaginationQueryResult = useHelperCustomInfiniteQuery(paginationQueryOptions);

  useIntersectionObserver({
    target: pageEndRef,
    onIntersect: infinitePaginationQueryResult.queryResult.fetchNextPage,
    enabled: infinitePaginationQueryResult.queryResult.hasNextPage,
  });

  const refetchEl = infinitePaginationQueryResult.queryResult.hasNextPage ? (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        height: "15px",
      }}
      ref={pageEndRef}
    >
      {infinitePaginationQueryResult.queryResult.isFetching && <Spinner color="brand.500" boxSize="13px" />}
    </div>
  ) : undefined;

  return { ...infinitePaginationQueryResult, refetchEl };
}

/**
 * This helper function takes in the data returned by a react-query infinite query and returns a flat array.
 * @param infiniteData
 */
export function transformInfiniteData<T>(infiniteData: InfiniteData<PaginationReturn<T>> | undefined): T[] {
  if (!infiniteData) return [];
  return infiniteData.pages.flatMap((obj) => obj.data);
}
