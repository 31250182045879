import { EditIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Country, User } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import CountryAPI from "../../../api/countries.api";
import { FilesAPI } from "../../../api/files.api";
import UsersAPI from "../../../api/users.api";
import { ME_QUERY_KEY, SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../../utils/constants";
import { useAllLanguages, useParamsQueryHelper } from "../../../utils/query-helpers/reactQueryHooks";
import { uploadToS3 } from "../../../utils/s3Upload";
import { handleSubmission } from "../../../utils/toErrorMap";
import useUnsavedChangesPrompt from "../../../utils/useUnsavedChangesPrompt";
import { personalInfoValidationSchema } from "../../../validation/validationSchemas";
import { SettingsSkeleton } from "../../FullScreenSpinner";
import ImageUploader from "../../fileUpload/ImageUploader";
import Form from "../../form/Form";
import SubmitButton from "../../form/SubmitButton";
import InputWrapper from "../../input/InputWrapper";
import RHFInput from "../../input/RHFInput";
import RHFSelect from "../../input/RHFSelect";
import ErrorCardView from "../../layout/ErrorCardView";
import Section, { horizontalSectionPadding } from "../../layout/Section";
import { SettingsInputWrapper } from "./PasswordSettings";

interface PersonalInformationProps {
  user: User;
  id: string;
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({ user, id }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const formMethods = useForm<User>({
    mode: "onChange",
    resolver: yupResolver(personalInfoValidationSchema),
    defaultValues: user,
  });

  useUnsavedChangesPrompt(formMethods.formState.isDirty);

  const {
    data: countries,
    isLoading,
    error,
  } = useParamsQueryHelper<Country[]>({
    queryKey: "countries-personal",
    apiCall: CountryAPI.getAll,
    queryParams: {} as any,
    options: {},
  });

  const { data: allLanguages, isLoading: isloadingAllLanguages, error: errorLanguages } = useAllLanguages();

  const { mutateAsync: mutatePersonalInformation } = useMutation({
    mutationFn: UsersAPI.patchUser,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: ["my-team"],
      });
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: UsersAPI.changeAvatar,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ME_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: ["my-team"],
      });
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });

  const submitAvatar = async (cropper: Cropper) => {
    //TODO: instead of isMutating, create a loading variable wrapping the following lines such that the loading displays from the beginning
    // Get a presigned S3 url
    cropper.getCroppedCanvas().toBlob(async function (blob) {
      if (blob) {
        const fileType = "image/jpeg";
        const {
          data: { signedRequest, s3key },
        } = await FilesAPI.s3Sign({
          filename: user.id!,
          filetype: fileType,
          folder: "avatars/",
        });
        const file = new File([blob], user.id!, { type: fileType });
        await uploadToS3(file, fileType, signedRequest);
        await mutateAsync(s3key);
      }
    }, "image/jpeg");
  };

  const updateAccountInfo = async (values: User) => {
    const { avatarUrl, avatar, ...user } = values;
    await handleSubmission({
      successMessage: "updated personal information",
      failMessage: "updating personal information",
      apiCall: mutatePersonalInformation(user),
      toast,
      setError: formMethods.setError,
    });
    formMethods.reset(values);
  };

  if (isLoading || isloadingAllLanguages)
    return (
      <Section title="Personal information" h="auto" noHorizontalPadding position="relative" id={id}>
        <Text px={horizontalSectionPadding}>This is public information and can be seen by others.</Text>
        <SettingsSkeleton />
      </Section>
    );
  if (error || !countries) return <ErrorCardView title="Could not load countries" />;
  if (!allLanguages || errorLanguages) return <ErrorCardView title="Could not load languages" />;

  const languages = allLanguages.map((lang) => ({ value: lang.code, text: lang.name }));

  return (
    <Section title="Personal information" h="auto" noHorizontalPadding position="relative" id={id}>
      <Text px={horizontalSectionPadding}>This is public information and can be seen by others.</Text>
      <Flex
        w="auto"
        justifyContent={{ base: "left", md: "end" }}
        position={{ base: "static", md: "absolute" }}
        right={{ base: "7", md: "5", xl: "12" }}
        top={{ base: "2", md: "28", xl: "20" }}
        px={{ base: "7", md: "0" }}
      >
        <Box w={{ base: "100px", md: "120px", xl: "180px" }} zIndex={10}>
          <ImageUploader submitPicture={submitAvatar} type="profile" dropzone={false}>
            {/* {isMutating ? (
                    <div>Updating profile picture ...</div>
                  ) : ( */}
            {/* TODO: this loading is working but it triggers an additional load of the previous profile picture possibly resulting in 403 forbidden */}
            <Avatar size="" src={user.avatarUrl ? user.avatarUrl : ""}>
              <AvatarBadge borderColor="transparent" right={4} bottom={4} borderRadius={0}>
                {/* <IconButton
                        icon={<EditIcon />}
                        // boxSize="30px"
                        fontSize="20px"
                        w="20px"
                        h="30px"
                        // boxSize="25px"
                        aria-label="edit-profile-picture"
                        bg="white"
                        border="1px"
                        borderColor="gray.300"
                      /> */}
                <Button
                  size={{ base: "xs", md: "sm" }}
                  leftIcon={<EditIcon boxSize="16px" />}
                  bg="white"
                  border="1px"
                  fontWeight="600"
                  borderColor="gray.300"
                  p={2}
                  zIndex={10}
                >
                  Edit
                </Button>
              </AvatarBadge>
            </Avatar>
            {/* )} */}
          </ImageUploader>
        </Box>
      </Flex>
      <Box minW="65%">
        <Form formMethods={formMethods} onSubmit={updateAccountInfo}>
          <SettingsInputWrapper>
            <RHFInput
              name="name"
              label="Name"
              helperText={<>This name will be shown to your clients.</>}
              placeholder="Jane Doe"
              layout="horizontal"
              isRequired
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper hasBg>
            <RHFInput
              name="email"
              label="Email"
              // helperText="Make sure to verify your new email address after changing"
              isDisabled
              layout="horizontal"
              isRequired
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper>
            <InputWrapper
              name="country"
              label="Country"
              horizontal
              isRequired
              labelWidth={{ base: "40%", md: "250px" }}
              inputMarginLeft={"0"}
            >
              <RHFSelect
                name={`country.iso`}
                options={countries.map((country) => ({
                  value: country.iso,
                  text: country.name,
                }))}
                inputWidth={{ base: "100%", md: "63%" }}
                placeholder="Select country"
              ></RHFSelect>
            </InputWrapper>
          </SettingsInputWrapper>
          <SettingsInputWrapper hasBg>
            <InputWrapper
              name={"primaryLanguage"}
              label="Primary language"
              helperText="Your primary spoken language"
              horizontal
              isRequired
              labelWidth={{ base: "40%", md: "250px" }}
              inputMarginLeft={"0"}
            >
              <RHFSelect
                name={`primaryLanguage.code`}
                options={languages}
                isRequired
                inputWidth={{ base: "100%", md: "63%" }}
                placeholder="Select language"
              />
            </InputWrapper>
          </SettingsInputWrapper>
          <SettingsInputWrapper>
            <RHFInput
              name="phoneNumber"
              label="Phone number"
              placeholder="+141 51 23 12 34"
              layout="horizontal"
              type="tel"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper hasBg>
            <RHFInput
              name="jobTitle"
              label="Job title"
              placeholder="USA expert"
              layout="horizontal"
              inputWidth={{ base: "100%", md: "63%" }}
              labelWidth={{ base: "100%", md: "250px" }}
            />
          </SettingsInputWrapper>

          <SubmitButton mt={3} mx={horizontalSectionPadding}>
            Save
          </SubmitButton>
        </Form>
      </Box>
    </Section>
  );
};
export default React.memo(PersonalInformation);
