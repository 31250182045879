import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Select,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import { default as React } from "react";
import DateRangePicker from "../input/date/DateRangePicker";
import { ConditionalWrapper } from "../../features/contacts/Contact";
import { default as MapsAutocomplete } from "../../features/map/MapsAutocomplete";
import { FILTER_TYPE, KFormatter } from "./CustomComboBox";
import colors from "../../theme/foundations/colors";
import { UseRangeInputOptions } from "../../types/UseRangeInputOptions";

export const rangePickerOptions: UseRangeInputOptions = {
  min: 1,
  format: "PP",
  max: 300,
  numberOfMonths: 2,
};

export interface FilterSelectProps {
  onChange: (value: any) => void;
  options?: { value: any; text: string }[] | number;
  icon: any;
  defaultSelected?: any;
  type: FILTER_TYPE;
  onClick?: () => void;
  name?: string;
  width?: string;
  height?: string;
  hidden?: boolean;
  dropdown?: boolean;
  setFilterValue?: any;
  borderRadius?: string;
  modalContext?: any;
  step?: number;
  marks?: number[];
  min?: number;
  label?: string;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
  onChange,
  options,
  icon,
  defaultSelected,
  type,
  onClick,
  name = "origin",
  height = "auto",
  width,
  hidden = false,
  dropdown = false,
  setFilterValue,
  borderRadius = "10em",
  modalContext,
  step,
  marks,
  min,
  label,
}) => {
  const [currentValue, setCurrentValue] = React.useState(defaultSelected);
  if (type === FILTER_TYPE.SELECT) {
    return (
      <Box position="relative" maxW={"10em"} height={height} hidden={hidden}>
        <Icon position="absolute" as={icon} left={2} top={2.5} zIndex={10} boxSize={"0.8em"} />
        <Select
          defaultValue={defaultSelected}
          className="filter-select"
          onChange={(event) => {
            onChange(event.target.value);
          }}
          borderRadius={borderRadius}
          width={"fit-content"}
        >
          {(options as any[]).map((option) => (
            <option value={option.value}>{option.text}</option>
          ))}
        </Select>
      </Box>
    );
  }
  if (type === FILTER_TYPE.MULTISELECT) {
    const filterActivated = defaultSelected.length !== (options as any[]).length && defaultSelected.length !== 0;

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          borderRadius={borderRadius}
          as={Button}
          variant={dropdown ? undefined : "outline"}
          rightIcon={
            <Flex alignItems="center">
              <Text color="gray.800" isTruncated size="sm">
                {filterActivated ? defaultSelected.length : "All"}
              </Text>
              <Icon as={ChevronDownIcon} boxSize={"16px"} m={"0.5em"} />
            </Flex>
          }
        >
          {name}
        </MenuButton>
        <Portal containerRef={modalContext && modalContext.dialogRef}>
          <MenuList minWidth="200px" zIndex={21} maxH={"25em"} overflowY={"scroll"} onClick={onClick}>
            <MenuOptionGroup type={"checkbox"} onChange={onChange} defaultValue={defaultSelected}>
              {((options as any[]) ?? []).map((option: any) => {
                // Convert the option to a nicer looking option
                return (
                  <MenuItemOption key={`filter-option-${option.value}`} value={option.value}>
                    {option.text}
                  </MenuItemOption>
                );
              })}
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    );
  }
  if (type === FILTER_TYPE.RANGE) {
    return (
      <ConditionalWrapper
        condition={dropdown}
        wrapper={(children: any) => (
          <Menu closeOnSelect={false}>
            <MenuButton
              color="gray.500"
              as={Button}
              rightIcon={
                <Flex alignItems="center">
                  <Text color="gray.800" isTruncated size="sm">
                    {currentValue && currentValue.length > 0 ? currentValue[0] : 0} to{" "}
                    {currentValue && currentValue.length > 1 ? currentValue[1] : 5}
                  </Text>
                  <ChevronDownIcon boxSize={5} />
                </Flex>
              }
            >
              {name}
            </MenuButton>
            <Portal containerRef={modalContext && modalContext.dialogRef}>
              <MenuList minWidth="200px" zIndex={21} maxH={"25em"} overflowY={"scroll"} onClick={onClick}>
                {children}
              </MenuList>
            </Portal>
          </Menu>
        )}
      >
        <Flex
          w={"11em"}
          alignItems={"center"}
          border={"1px solid"}
          borderColor={"inherit"}
          borderRadius={borderRadius}
          pr={"1em"}
          height={height}
          hidden={hidden}
        >
          <Icon as={icon} zIndex={10} boxSize={"0.8em"} ml={"1em"} />
          <RangeSlider
            defaultValue={defaultSelected}
            min={0}
            max={options as number}
            step={step ?? 1}
            w={"80%"}
            ml={"1em"}
            onChange={(e) => {
              setCurrentValue(e);
              onChange(e);
            }}
            onClick={onClick}
          >
            <RangeSliderTrack bg="yellow.100">
              <RangeSliderFilledTrack bg={colors.lato.primaryColorDark} />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0}>
              {KFormatter(currentValue && currentValue.length > 0 ? currentValue[0] : 0)}
            </RangeSliderThumb>
            <RangeSliderThumb boxSize={6} index={1}>
              {KFormatter(currentValue && currentValue.length > 1 ? currentValue[1] : 5)}
            </RangeSliderThumb>
          </RangeSlider>
        </Flex>
      </ConditionalWrapper>
    );
  }
  if (type === FILTER_TYPE.SLIDER) {
    return (
      <ConditionalWrapper
        condition={dropdown}
        wrapper={(children: any) => (
          <Menu closeOnSelect={false}>
            <MenuButton
              color="gray.500"
              as={Button}
              rightIcon={
                <Flex alignItems="center">
                  <Text color="gray.800" isTruncated size="sm">
                    {currentValue && currentValue.length > 0 ? currentValue[0] : 0} to{" "}
                    {currentValue && currentValue.length > 1 ? currentValue[1] : 5}
                  </Text>
                  <ChevronDownIcon boxSize={5} />
                </Flex>
              }
            >
              {name}
            </MenuButton>
            <Portal containerRef={modalContext && modalContext.dialogRef}>
              <MenuList minWidth="200px" zIndex={21} maxH={"25em"} overflowY={"scroll"} onClick={onClick}>
                {children}
              </MenuList>
            </Portal>
          </Menu>
        )}
      >
        <Flex
          w={"11em"}
          alignItems={"center"}
          border={"1px solid"}
          borderColor={"inherit"}
          borderRadius={borderRadius}
          pr={"1em"}
          height={height}
          hidden={hidden}
        >
          <Icon as={icon} zIndex={10} boxSize={"0.8em"} ml={"1em"} />
          <Slider
            value={defaultSelected}
            max={options as number}
            step={step ?? 1}
            w={"80%"}
            ml={"1em"}
            onChange={(e) => {
              setCurrentValue(e);
              onChange && onChange(e);
            }}
            onClick={onClick}
            min={min}
          >
            <SliderMark
              value={defaultSelected}
              textAlign="center"
              bg="brand.500"
              color="white"
              mt="-6"
              ml="-5"
              w="10"
              rounded="md"
              fontSize="10px"
            >
              {defaultSelected}
              {label ?? ""}
            </SliderMark>
            {marks?.map((mark: number, i) => (
              <SliderMark value={mark} mt="1" ml="-2.5" fontSize="sm">
                {mark}
                {i === marks.length - 1 ? (label ?? "") : ""}
              </SliderMark>
            ))}
            <SliderTrack>
              <SliderFilledTrack bg={colors.lato.primaryColorDark} />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>
      </ConditionalWrapper>
    );
  }

  if (type === FILTER_TYPE.LOCATION) {
    return (
      <ConditionalWrapper
        condition={dropdown}
        wrapper={(children: any) => (
          <Menu closeOnSelect={false}>
            <MenuButton
              color="gray.500"
              as={Button}
              width={width}
              rightIcon={
                <Flex alignItems="center">
                  <Text color="gray.800" isTruncated size="sm" maxW={"6em"}>
                    {currentValue && currentValue !== "" ? currentValue : "All"}
                  </Text>
                  <ChevronDownIcon boxSize={5} />
                </Flex>
              }
            >
              {name}
            </MenuButton>
            <Portal containerRef={modalContext && modalContext.dialogRef}>
              <MenuList minWidth="200px" zIndex={21} maxH={"25em"} overflowY={"scroll"} onClick={onClick}>
                {children}
              </MenuList>
            </Portal>
          </Menu>
        )}
      >
        <Box width={width ?? "25%"} borderRadius={borderRadius} position={"relative"} height={height} hidden={hidden}>
          <MapsAutocomplete
            handlePlaceChanged={(e) => {
              setFilterValue && setFilterValue(e.name);
              setCurrentValue(e.name);
              onChange([e.geometry.location.lng(), e.geometry.location.lat()]);
            }}
          >
            <InputGroup>
              {icon && (
                <InputLeftElement>
                  <Icon as={icon} zIndex={10} />
                </InputLeftElement>
              )}
              <Input
                defaultValue={defaultSelected}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick && onClick();
                }}
                onChange={(e) => {
                  if (e.target?.value === "") {
                    setFilterValue && setFilterValue(e.target.value);
                    setCurrentValue(e.target.value);
                    onChange(null);
                  }
                }}
                borderRadius={borderRadius}
              />
            </InputGroup>
          </MapsAutocomplete>
        </Box>
      </ConditionalWrapper>
    );
  }
  if (type === FILTER_TYPE.DATERANGE) {
    return (
      <Box w={"23%"} hidden={hidden}>
        <DateRangePicker
          {...rangePickerOptions}
          defaultSelected={defaultSelected}
          onSelect={onChange}
          borderRadius={borderRadius}
        />
      </Box>
    );
  }
};
