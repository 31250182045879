import { useToast } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { NotificationContext } from "../../stores/NotificationContext";
import { useSocketSubscribe } from "../../app/providers/SocketProvider";
import { Message } from "../../pages/chats";
import { useChatStore } from "../../stores/trip/chat-store";
import { useUnreadMessageCount } from "../../utils/query-helpers/reactQueryHooks";
import {
  getLocalStorageNotificationCount,
  getLocalStorageNotificationValue,
  LOCAL_STORAGE_NOTIFICATION_KEY,
} from "./NotificationFunctions";

/**
 * Notification Manager is used to track Notifications behind the scenes
 * @returns
 */
const NotificationManager = () => {
  const toast = useToast();
  // Fetch Unread Messages For All Usertrips from API
  const { data } = useUnreadMessageCount();
  // Import setNotification Context method
  const { setNotificationCount } = useContext(NotificationContext);
  const { addNewLocalTripMessage, newLocalMessagesPerTrip } = useChatStore();

  // Method to Set data in local storage and fetch all unread notifications from localstorage and set in context
  const updateNotificationCount = (data: any) => {
    // Set Messages In localstorage
    localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY, JSON.stringify(data));
    const count = getLocalStorageNotificationCount();
    // Sent Count In Context
    setNotificationCount(count);
  };

  // Hook will trigger once data recieved from API
  useEffect(() => {
    // If any unread messages recieved from API
    if (data?.length) {
      updateNotificationCount(data);
    }
  }, [data]);

  /**
   * Travel Agent Notification Listner ,
   * In case of any new notification it will be listned here
   * @param res
   */
  const notificationListner = (newMessage: Message) => {
    addNewLocalTripMessage(newMessage);
    // Create an alert.
    // toast({
    //   title: `1 new chat`,
    //   description: "click to read",
    //   variant: "solid",
    //   isClosable: true,
    //   colorScheme: "brand",
    // })
    // Set the notification badge.
    let result = getLocalStorageNotificationValue();
    if (result?.length) {
      const index = result.findIndex((x: any) => x.usertripId == newMessage.usertrip.id);

      index === -1 ? result.push({ usertripId: newMessage.usertrip.id, count: 1 }) : result[index].count++;
    } else {
      result = [
        {
          usertripId: newMessage.usertrip.id,
          count: 1,
        },
      ];
    }
    updateNotificationCount(result);
  };
  // Subscribe to sendNotificationToTravelAgent event listner
  useSocketSubscribe("sendNotificationToTravelAgent", notificationListner);

  return <></>;
};

export default React.memo(NotificationManager);
