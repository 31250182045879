/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, Button, HStack, Heading, Progress, Skeleton, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Currency, Trip, UserTrip } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMeContext } from "../../../../stores/me-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { PRICE_STEP_TRIP_QUERY_KEY, PRICE_STEP_USERTRIP_QUERY_KEY } from "../../../../utils/constants";
import formatPrice from "../../../../utils/formatPrice";
import {
  getRelationMap,
  updateRelationMap,
  usePatchSplitTrip,
  usePatchSplitUserTrip,
  useSplitTrip,
  useSplitUserTrip,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { priceTrip, priceUserTrip } from "../../../../validation/validationSchemas";
import CurrencyPicker from "../../../input/CurrencyPicker";
import { WizardSkeleton } from "../../../FullScreenSpinner";
import { CollaboratorEnabledPriceMessage } from "../../../accessiblity/overlay";
import RHFDecimalInput from "../../../input/RHFDecimalInput";
import RHFTranslationInput from "../../../input/RHFTranslationInput";
import Section from "../../../layout/Section";
import Wizard, { WizardNewLayout } from "../../../../features/editTrip/wizard/Wizard";
import TripForm from "../../TripForm";

interface TripPriceStepProps {
  stepNumber: number;
}

const TripPriceStep: React.FC<TripPriceStepProps> = ({ stepNumber }) => {
  const { id: userTripId } = useParams<{ id: string }>();
  const me = useMeContext();

  const {
    trip: tripFromStore,
    isFirst,
    isSample,
    isLoadingTranslations: isLoadingTranslationsFromStore,
  } = useTripFormStore();

  const { data: usertrips, isLoading: isLoadingUserTrip } = useSplitUserTrip(
    userTripId ?? "",
    getRelationMap[PRICE_STEP_USERTRIP_QUERY_KEY],
    PRICE_STEP_USERTRIP_QUERY_KEY,
  );

  const usertrip = usertrips && usertrips[isFirst ? 0 : 1];

  const {
    data: trip,
    isLoading: isLoadingTrip,
    isFetching: isFetchingTrip,
    isFetchedAfterMount: isFetchedAfterMountTrip,
  } = useSplitTrip(tripFromStore?.id ?? "", PRICE_STEP_TRIP_QUERY_KEY, getRelationMap[PRICE_STEP_TRIP_QUERY_KEY]);

  const isLoadingTrans = (!isFetchedAfterMountTrip && isFetchingTrip) || isLoadingTranslationsFromStore;

  return (
    <>
      {!me.useNewEditTripLayout &&
        (trip ? (
          <Wizard
            trip={trip}
            collaboratorCanEdit={trip.collaboratorCanEdit}
            stepNumber={stepNumber}
            isFirst={isFirst}
            sample={isSample}
            type="Edit"
          />
        ) : (
          <WizardSkeleton />
        ))}
      {usertrips && !isFirst && trip && !tripFromStore.collaboratorCanEdit && <CollaboratorEnabledPriceMessage />}
      <Section
        heading={
          <HStack>
            <Heading w="100%" size="lg" color="realGray.800">
              Price information
            </Heading>
          </HStack>
        }
        mb={4}
      >
        {isLoadingUserTrip || !usertrip ? (
          <Skeleton h={"50px"} />
        ) : (
          <TripPriceStepForm userTripId={userTripId!} usertrips={usertrips} isFirst={isFirst} />
        )}
        {isLoadingTrip || isFetchingTrip || !trip || isLoadingTrans ? (
          <>
            <Skeleton h={"200px"} />
            <Skeleton h={"200px"} />
            <Skeleton h={"200px"} />
          </>
        ) : (
          <TripPriceInformationStepForm trip={trip} isFirst={isFirst} stepNumber={stepNumber} />
        )}
      </Section>
    </>
  );
};
export default TripPriceStep;

interface TripPriceStepFormProps {
  userTripId: string;
  usertrips: UserTrip[];
  isFirst: boolean;
}

const TripPriceStepForm: React.FC<TripPriceStepFormProps> = ({ userTripId, usertrips, isFirst }) => {
  const formId = `3-${PRICE_STEP_USERTRIP_QUERY_KEY}`;
  const queryClient = useQueryClient();

  const defaultValues = usertrips.map((usertrip) => ({
    currency: usertrip.currency,
    currencyIso: usertrip.currency?.iso || null,
    price: usertrip.price ? formatPrice({ text: usertrip.price.toString(), addDecimals: true }) : null,
  }));

  const formMethods = useForm<{ userTrips: UserTrip[] }>({
    mode: "onChange",
    reValidateMode: "onBlur",
    defaultValues: { userTrips: defaultValues },
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(priceUserTrip, { abortEarly: false }),
  });

  const { mutateAsync: updateUserTrip, isPending: isLoadingUserTripUpdate } = usePatchSplitUserTrip(
    userTripId,
    updateRelationMap[PRICE_STEP_USERTRIP_QUERY_KEY],
    queryClient,
    PRICE_STEP_USERTRIP_QUERY_KEY,
  );

  const handleSubmit = async () => {
    const tripValues = formMethods.getValues().userTrips[isFirst ? 0 : 1];
    await updateUserTrip({
      currencyIso: tripValues.currency?.iso as string,
      currency: tripValues.currency,
      price: tripValues.price
        ? tripValues.price
            .toString()
            .replace(/[., ](?=\d{3}(\D|$))/g, "")
            .replace(",", ".")
        : null,
    });
  };

  function handleCurrencySelection(currency: Currency, isFirst: boolean): void {
    formMethods.setValue(`userTrips.${isFirst ? 0 : 1}.currency`, currency);
    formMethods.setValue(`userTrips.${isFirst ? 0 : 1}.currencyIso`, currency.iso);
  }

  return (
    <TripForm
      formId={formId}
      defaultValues={{ userTrips: defaultValues }}
      triggerResetData={usertrips[isFirst ? 0 : 1]}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      className="mb-4"
    >
      {isLoadingUserTripUpdate && <Progress mt={"-13px"} mb={3} height={"2px"} colorScheme="brand" isIndeterminate />}
      <Button type="submit" hidden className="form-submit-button">
        Save
      </Button>
      <HStack>
        <HStack width={isFirst ? "100%" : "50%"}>
          <Box width={"20%"} minWidth={"140px"}>
            <CurrencyPicker
              isFirst={isFirst}
              name={`userTrips.0.currencyIso`}
              currencySelectionHandler={(currency) => handleCurrencySelection(currency, true)}
            />
          </Box>
          <Box width={"100%"} minWidth={"100px"}>
            <RHFDecimalInput
              isDisabled={!isFirst}
              name={`userTrips.0.price`}
              formatCode="en-US"
              placeholder="0.00"
              label={"Price"}
              onChange={(e: string) => {
                formMethods.setValue(`userTrips.0.price`, e, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
          </Box>
        </HStack>
        {!isFirst && (
          <HStack width={"50%"}>
            <Box width={"20%"} minWidth={"140px"}>
              <CurrencyPicker
                name={`userTrips.1.currencyIso`}
                currencySelectionHandler={(currency) => handleCurrencySelection(currency, false)}
              />
            </Box>
            <Box width={"100%"} minWidth={"100px"}>
              <RHFDecimalInput
                name={`userTrips.1.price`}
                formatCode="en-US"
                placeholder="0.00"
                label={"Price"}
                onChange={(e: string) => {
                  formMethods.setValue(`userTrips.1.price`, e, { shouldDirty: true, shouldValidate: true });
                }}
              />
            </Box>
          </HStack>
        )}
      </HStack>
    </TripForm>
  );
};

interface TripPriceStepDscriptionFormProps {
  trip: Trip;
  isFirst: boolean;
  stepNumber: number;
}

const TripPriceInformationStepForm: React.FC<TripPriceStepDscriptionFormProps> = ({ trip, isFirst, stepNumber }) => {
  const formId = `3-${PRICE_STEP_TRIP_QUERY_KEY}`;
  const queryClient = useQueryClient();

  const defualtValues = {
    id: trip.id,
    priceDescriptions: trip.priceDescriptions,
    includeds: trip.includeds,
    notIncludeds: trip.notIncludeds,
  };

  const formMethods = useForm<Trip>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: defualtValues,
    shouldUnregister: false,
    criteriaMode: "all",
    resolver: yupResolver(priceTrip, { abortEarly: false }),
  });

  const { mutateAsync: updateTrip } = usePatchSplitTrip(
    trip.id ?? "",
    updateRelationMap[PRICE_STEP_TRIP_QUERY_KEY],
    queryClient,
    PRICE_STEP_TRIP_QUERY_KEY,
  );

  const handleSubmit = async () => {
    const tripValues = formMethods.getValues();
    await void updateTrip({
      id: tripValues.id,
      priceDescriptions: tripValues.priceDescriptions,
      includeds: tripValues.includeds,
      notIncludeds: tripValues.notIncludeds,
    });
  };

  return (
    <TripForm
      formId={formId}
      triggerResetData={trip}
      defaultValues={defualtValues}
      formMethods={formMethods}
      onSubmit={handleSubmit}
      className="space-y-4"
    >
      <Button type="submit" hidden className="form-submit-button">
        Save
      </Button>

      <RHFTranslationInput
        first={isFirst}
        collaboratorCanEdit={trip.collaboratorCanEdit}
        name={`priceDescriptions.0.content`}
        label="Additional information"
        placeholder="Flights: 500$ - Activities: 300$"
        textarea
      />

      <RHFTranslationInput
        first={isFirst}
        collaboratorCanEdit={trip.collaboratorCanEdit}
        name={`includeds.0.content`}
        label="Included"
        placeholder="Guides - Hotels/lodges"
        textarea
      />

      <RHFTranslationInput
        first={isFirst}
        collaboratorCanEdit={trip.collaboratorCanEdit}
        name={`notIncludeds.0.content`}
        label="Not included"
        placeholder="Insurance - Drinks"
        textarea
      />
    </TripForm>
  );
};
