import { Box, Button, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { Role, capitalizeFirstLetter, Plan, Company } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate } from "react-router-dom";
import StripeAPI from "../api/stripe.api";
import { SettingsSkeleton } from "../components/FullScreenSpinner";
import ErrorCardView from "../components/layout/ErrorCardView";
import Section from "../components/layout/Section";
import Settings from "../components/settings/Settings";
import AdditionalUsage from "../components/settings/billing/AdditionalUsage";
import CancelSubscription from "../components/settings/billing/CancelSubscription";
import PaymentAlertModal from "../components/settings/billing/PaymentAlertModal";
import Plans, { alertNotAdmin } from "../components/settings/billing/Plans";
import { useMeContext } from "../stores/me-context";
import { prettyPrintDate } from "../utils/date";
import { grantPermission } from "../utils/grantPermission";
import { useExtraSubscriptionInformation } from "../utils/query-helpers/reactQueryHooks";
import { handleSubmission } from "../utils/toErrorMap";

interface billingSettingsProps {}

export const hasPayingPlan = (company: Company) => company.plan !== Plan.FREE && !company.trial_end_date;

const SubInfoRef = "subscription-information";
const PlansRef = "plans";
const CancelRef = "cancel-information";
const AdditionalUsageRef = "additional-usage";

const subNavItems = [
  {
    name: "Subscription Information",
    reference: SubInfoRef,
  },
  {
    name: "Plans",
    reference: PlansRef,
  },
  {
    name: "Additional usage",
    reference: AdditionalUsageRef,
  },
  {
    name: "Cancel Subscription",
    reference: CancelRef,
  },
];

export const BillingSettings: React.FC = () => {
  return (
    <Settings subNavItems={subNavItems} tabName={"Billing"}>
      <Billing />
    </Settings>
  );
};

export default BillingSettings;

const Billing: React.FC<billingSettingsProps> = () => {
  const queryParams: string[] = window.location.search.substring(1).split("&");
  const status: string | undefined = queryParams[0].split("=")[1];
  const paymentAlertDisclosure = useDisclosure({ defaultIsOpen: !!status });
  const me = useMeContext();

  const {
    data: extraSubscriptionInformation,
    isLoading: isLoadingExtraSubscriptionData,
    error: errorExtraSubscriptionData,
  } = useExtraSubscriptionInformation();

  if (isLoadingExtraSubscriptionData)
    return (
      <Section title="Subscription information" h="auto" id={SubInfoRef}>
        <SettingsSkeleton amount={4} />
      </Section>
    );
  if (errorExtraSubscriptionData || !extraSubscriptionInformation) return <ErrorCardView title="Could not load data" />;

  const currentPlan = me.company.plan;
  const trial_end = me.company.trial_end_date ? new Date(me.company.trial_end_date) : null;
  const now_date = new Date();
  const isAdmin = grantPermission(me.role, [Role.ADMIN]);
  const hasSubscription = currentPlan !== Plan.FREE;
  const payingPlan = hasPayingPlan(me.company);

  const current_period_end =
    extraSubscriptionInformation?.current_period_end &&
    prettyPrintDate(new Date(extraSubscriptionInformation.current_period_end), undefined, "long");

  return (
    <>
      {hasSubscription && (
        <>
          <Section title="Subscription information" h="auto" id={SubInfoRef}>
            <Text>
              <b>Plan: </b>
              {capitalizeFirstLetter(currentPlan)}
              {trial_end && trial_end > now_date ? ` - free trial` : ""}
            </Text>
            <Text>
              <b>Users: </b>
              {me.company.maxUsers}
            </Text>
            <Text>
              <b>Current billing cycle: </b>
              {extraSubscriptionInformation?.current_period_start &&
                prettyPrintDate(new Date(extraSubscriptionInformation.current_period_start), undefined, "long") +
                  " - " +
                  current_period_end}
            </Text>
          </Section>
        </>
      )}
      {me.company.freeUserMonths > 0 && (
        <Section title="Free user months" h="auto">
          <Box>
            <Text>Thanks to your {`company's`} contribution you are eligible for some free user months.</Text>
            <Text>These free user months will be automatically used on your first upcoming payment.</Text>
          </Box>
          <Box display="flex" flexDirection="row" gap={3} alignItems="center" justifyContent={"center"} marginTop={3}>
            <Box fontSize={34} fontWeight="bold">
              {me.company.freeUserMonths}
            </Box>
            <Text fontWeight={"bold"}>Remaining free user months</Text>
          </Box>
        </Section>
      )}
      {!extraSubscriptionInformation?.cancel_at_period_end &&
        (!extraSubscriptionInformation?.scheduledUpdate ? (
          <Plans
            currentSubscription={{
              plan: currentPlan,
              users: me.company.maxUsers,
              yearly: extraSubscriptionInformation?.yearly ?? true,
            }}
            setSubscription={async () => console.log("")}
            payingPlan={payingPlan}
          />
        ) : (
          <UpdateScheduledAlready
            current_period_end={current_period_end}
            isAdmin={isAdmin}
            scheduledUpdate={extraSubscriptionInformation.scheduledUpdate}
          />
        ))}
      {payingPlan && <AdditionalUsage id={AdditionalUsageRef} />}
      {payingPlan && isAdmin && (
        <CancelSubscription
          id={CancelRef}
          scheduledToCancel={!!extraSubscriptionInformation?.cancel_at_period_end}
          current_period_end={current_period_end}
        />
      )}
      {/* <Link as={ReactLink} to="/settings/checkout">
        Checkout
      </Link> */}
      {!!status && <PaymentAlertModal success={status === "success"} disclosure={paymentAlertDisclosure} />}
    </>
  );
};

const UpdateScheduledAlready = ({ current_period_end, isAdmin, scheduledUpdate }: any) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const { mutateAsync: removeSubscriptionSchedule, isPending: isRemovingSchedule } = useMutation({
    mutationFn: () => StripeAPI.removeSubscriptionSchedule(),
    onSuccess: () => {
      // Better handle this, can't you just add the current message to the cache?
      queryClient.invalidateQueries({
        queryKey: ["extraSubscriptionInformation"],
      });
      navigate(0);
    },
  });

  const handleRemoveSubscriptionSchedule = async () => {
    if (!isAdmin) {
      alertNotAdmin();
      return;
    }
    await handleSubmission({
      successMessage: `canceled subscription update`,
      failMessage: `canceling subscription update`,
      apiCall: removeSubscriptionSchedule(),
      toast,
    });
  };

  return (
    <>
      <Section title="Upcoming subscription change" h="auto">
        <Text>
          You currently have the following subscription update scheduled at the end of your current billing cycle (
          {current_period_end}):
        </Text>
        <Text>
          <b>Plan:</b> {capitalizeFirstLetter(scheduledUpdate.newPlan)}
        </Text>
        <Text>
          <b>Users:</b> {scheduledUpdate.newQuantity}
        </Text>
        <Text>
          <b>Next billing cycle:</b>{" "}
          {prettyPrintDate(new Date(scheduledUpdate.start_date), undefined, "long") +
            " - " +
            prettyPrintDate(new Date(scheduledUpdate.end_date), undefined, "long")}
        </Text>
        <Text mt={2}>If you want to change this update please first click on the undo button beneath.</Text>
        {/* Show your new subscription plan */}
        <Button onClick={handleRemoveSubscriptionSchedule} isLoading={isRemovingSchedule} mt={2} colorScheme="brand">
          Undo subscription update
        </Button>
      </Section>
    </>
  );
};
