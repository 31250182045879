import { LockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Flex, Wrap } from "@chakra-ui/react";
import React, { useState } from "react";
import RHFInput, { RHFInputProps } from "./RHFInput";

type RHFPasswordInput = RHFInputProps;
const RHFInputPassword: React.FC<RHFPasswordInput> = ({ placeholder, ...props }) => {
  const [showPassword, toggleShowPassword] = useState(false);

  return (
    <RHFInput
      size="lg"
      layout="normal"
      type={showPassword ? "text" : "password"}
      placeholder={placeholder ?? "8+ characters"}
      leftIcon={<LockIcon mt={"-0.5em"} color="gray.400" pointerEvents={"none"} />}
      rightIcon={
        <Flex height="full" align="center">
          <Wrap
            mt={"-0.5em"}
            onClick={() => {
              toggleShowPassword(!showPassword);
            }}
            _hover={{
              cursor: "pointer",
            }}
          >
            {showPassword ? <ViewIcon color="gray.500" /> : <ViewOffIcon color="gray.500" />}
          </Wrap>
        </Flex>
      }
      {...props}
    />
  );
};
export default React.memo(RHFInputPassword);
