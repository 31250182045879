import { Box, Flex, Heading, Icon, Image, Text } from "@chakra-ui/react";
import { IsImage, Language, prettyLocation, TranslationField } from "@lato/common";
import { default as React } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

export const SingleActivity: React.FC<any> = ({ item, width = "60%" }) => {
  const { mainAddress, subAddress } = item.location
    ? prettyLocation(item.location)
    : { mainAddress: "", subAddress: "" };

  const languages = item.titles?.map((title: TranslationField) => title.language);

  return (
    <Box w={width}>
      <Flex alignItems={"center"} gap={3}>
        <Image
          src={
            item.images.filter((image: any) => IsImage(image.site))[0]?.url ??
            "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png"
          }
          w={"5em"}
          maxH={"3.5em"}
          borderRadius={"1em"}
        />
        <Flex flexDir={"column"} justifyContent={"space-between"} gap={1}>
          <Heading fontSize={"16px"} isTruncated>
            {item.titles[0].content}
          </Heading>
          {mainAddress && mainAddress !== "" && (
            <Flex gap={1} alignItems={"center"}>
              <Icon as={FaMapMarkerAlt} color={"gray.500"} boxSize={3} />
              <Text fontSize={"12px"}>{mainAddress}</Text>
            </Flex>
          )}
        </Flex>
        {languages && <Text ml={"auto"}>{languages.map((language: Language) => language?.flag).join(" ")}</Text>}
      </Flex>
    </Box>
  );
};
