import { Box, Flex, Image, Text } from "@chakra-ui/react";
import {
  Country,
  Event,
  GoogleAddress,
  ImageSite,
  POIType,
  QueryType,
  TranslationField,
  User,
  prettyLocation,
} from "@lato/common";
import React from "react";
import { LiaFontAwesomeFlag } from "react-icons/lia";
import { CellProps, Column } from "react-table";
import CountryAPI from "../../../../../../api/countries.api";
import EventsAPI from "../../../../../../api/events.api";
import { useMeContext } from "../../../../../../stores/me-context";
import { useTripsListStore } from "../../../../../../stores/trip/tripslistStore";
import formatAddress from "../../../../../../utils/formatAddress";
import { useLanguages, useParamsQueryHelper } from "../../../../../../utils/query-helpers/reactQueryHooks";
import { CARDSIZES, CustomCard } from "../../../../../CRUD/CardView";
import ContinentFilter from "../../../../../CRUD/ContinentFilter";
import CountryFilter from "../../../../../CRUD/CountryFilter";
import LanguageFilter from "../../../../../CRUD/LanguageFilter";
import MemberFilter from "../../../../../CRUD/MemberFilter";
import { TableWithDrawerProps } from "../../../../../CRUD/Resource";
import { FILTER_TYPE } from "../../../../../CustomDownShift/CustomComboBox";
import { FilterSelect } from "../../../../../CustomDownShift/FilterSelect";
import { ListSkeleton } from "../../../../../FullScreenSpinner";
import { getEmptyActivity, getEmptyLocation } from "../../../../../elements/activities";
import ErrorCardView from "../../../../../layout/ErrorCardView";
import { IconMarker } from "../../../../../map/IconMarker";
import { POIsToIcons } from "../../../../../map/POIMarker";
import { LibraryItemType, MarkerMapItem } from "../LibraryItemModal";
import LibraryItemsTable from "../LibraryItemsTable";

interface ActivitiesTableProps extends Partial<TableWithDrawerProps<any>> {
  additionalColumns?: Column<Event>[];
  additionalQueryState?: object;
  users: User[];
}

const eventType: LibraryItemType = "activity";
// Subtext for under the title
const subText = "These activities can be seamlessly imported into your travel itinerary with just one click.";

const defaultColumns: Column<Event>[] = [
  {
    Header: "Title",
    accessor: (row) => (row.titles.length ? row.titles[0].content : ""),
    disableSortBy: false,
    chakraMaxWidth: "200px",
    // chakraWidth: "30%",
  },

  // {
  //   accessor: "type",
  //   Header: "Type",
  //   chakraWidth: "20%",
  // },
];

const ActivitiesTable: React.FC<ActivitiesTableProps> = ({ users, ...props }) => {
  const { data: allLanguages, isLoading: isLoadingLanguages, error: errorLanguages } = useLanguages();

  const [memberFilter, setMemberFilter] = React.useState<string[]>(users.map((member) => member.id!));
  const { continentFilter, setContinentFilter } = useTripsListStore();
  const [locationFilter, setLocationFilter] = React.useState();
  const selectedContinents = continentFilter !== "" ? continentFilter?.split(",") : [];

  // Used to get a new event object with the correct language and user, also used to set default values in EventForm in map
  const me = useMeContext();
  const getNewEvent = (coordinates?: number[], address?: GoogleAddress, locationName?: string) => {
    const newEvent = getEmptyActivity(me);
    newEvent.name = locationName ?? "New Activity";
    newEvent.location = {
      coordinates: coordinates ?? [0, 0],
      address: address ?? getEmptyLocation().address,
      name: locationName ?? "",
    };
    return newEvent;
  };

  const resetFilters = () => {
    setMemberFilter(users.map((member) => member.id!));
    setContinentFilter("");
  };

  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: errorCountries,
  } = useParamsQueryHelper<Country[]>({
    queryKey: "countries-activities",
    apiCall: CountryAPI.getAll,
    queryParams: {
      users: memberFilter,
      type: QueryType.ACTIVITY,
    } as any,
    options: {
      enabled: !!memberFilter,
    },
  });

  const columns = React.useMemo(
    () => [
      ...defaultColumns,
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ value }) => {
          const address = formatAddress(value?.address, true);
          return <Text title={address}>{address}</Text>;
        },
        // Due to issues in the backend.
        disableSortBy: true,
        // chakraWidth: "30%",
        Filter: (props: any) => {
          React.useEffect(() => {
            locationFilter === "" &&
              props.columns
                .find((column: any) => column.id === "location" || column.id === "coordinates")
                ?.setFilter(undefined);
          }, [locationFilter]);
          return (
            <FilterSelect
              type={FILTER_TYPE.LOCATION}
              onChange={(e) => {
                props.columns
                  .find((column: any) => column.id === "location" || column.id === "coordinates")
                  ?.setFilter(e);
              }}
              defaultSelected={locationFilter}
              icon={LiaFontAwesomeFlag}
              name="location"
              width={"100%"}
              borderRadius="sm"
              dropdown
              setFilterValue={setLocationFilter}
            ></FilterSelect>
          );
        },
        disableFilters: false,
        hideFiltersOnMapView: true,
      },
      {
        accessor: "users" as any,
        chakraWidth: "0px",
        Filter: (props: any) => <MemberFilter {...props} members={users} setMemberFilter={setMemberFilter} />,
        disableFilters: false,
        hide: true,
      },
      {
        accessor: "continent" as any,
        id: "continents",
        Header: "Continent",
        chakraWidth: "70px",
        Cell: ({ row, value }: CellProps<Event>) => <Box textAlign={"start"}>{value?.continent}</Box>,
        Filter: (props: any) => (
          <ContinentFilter
            {...props}
            continents={countries
              ?.flatMap((country) => country.continent)
              .filter((value, index, self) => self.indexOf(value) === index)}
          />
        ),
        disableFilters: false,
        hide: true,
        hideFiltersOnMapView: true,
      },
      {
        accessor: "country" as any,
        id: "countries",
        Header: "Country",
        chakraWidth: "70px",
        isNumeric: false,
        Cell: ({ row, value }: CellProps<Event>) => (
          <Box display="flex" justifyContent="center" marginRight={2} title={value?.name}>
            {" "}
            {value?.iso && value.iso}
          </Box>
        ),
        Filter: (props: any) => (
          <CountryFilter
            {...props}
            type={QueryType.ACTIVITY}
            countries={countries?.filter((country) =>
              selectedContinents?.length > 0 ? selectedContinents.includes(country.continent) : [],
            )}
          />
        ),
        disableFilters: false,
        disableSortBy: true,
        hideFiltersOnMapView: true,
      },
      {
        Header: "Languages",
        accessor: "titles",
        chakraWidth: "60px",
        id: "languages",
        Cell: ({ value, row, column }: CellProps<Event>) => {
          const languages = ((value as TranslationField[]) ?? [])
            .map((l) => l.language)
            .sort((a, b) => (a.name > b.name ? 1 : -1));
          return (
            <Text isTruncated maxW="6em" ml={1} title={languages.map((l) => l?.name).join(", ")}>
              {languages.map((language) => language?.flag).join(" ")}
            </Text>
          );
        },
        Filter: (props: any) => {
          return <LanguageFilter {...props} isLoadingLanguages={isLoadingLanguages} languages={allLanguages} />;
        },
        disableFilters: false,
        disableSortBy: true,
        hideFiltersOnMapView: true,
      },
    ],
    [allLanguages, countries, selectedContinents, users, isLoadingLanguages],
  );

  if (isLoadingCountries || isLoadingLanguages) return <ListSkeleton />;
  if (errorCountries || !countries) return <ErrorCardView title="Could not load countries" />;
  if (errorLanguages || !allLanguages) return <ErrorCardView title="Could not load languages" />;

  return (
    <>
      <LibraryItemsTable
        buttonDisplayName="activity"
        defaultColumns={columns}
        api={EventsAPI}
        subText={subText}
        eventType={eventType}
        resetFilters={resetFilters}
        enableGridView={true}
        enableMapView={true}
        cardViewComponent={ActivityCard}
        markerComponent={ActivityMarker}
        searchBarPlaceholder="Search by title, location, description..."
        getEmptyItem={getNewEvent}
        initialFilters={[{ id: "users", value: memberFilter }]}
        additionalQueryState={{
          users: memberFilter,
        }}
        setLocationFilter={setLocationFilter}
        {...props}
      />
    </>
  );
};
export default React.memo(ActivitiesTable);

const ActivityCard: React.FC<CardProps> = ({ row, key, size }) => {
  const activity = row.original as Event;
  const image =
    activity?.images && activity.images[0]
      ? activity.images[0]
      : {
          url: "https://www.romacfuels.com/wp-content/uploads/2020/12/orionthemes-placeholder-image-1-1.png",
          site: ImageSite.CUSTOMPICTURE,
        };
  const optionsCell = row.cells.filter((cell: any) => cell.column.id === "libraryItem-options")[0];
  const { mainAddress, subAddress } = activity.location
    ? prettyLocation(activity.location)
    : { mainAddress: "", subAddress: "" };

  const flagWidth: { [key in CARDSIZES | string]: string } = {
    [CARDSIZES.XL]: "22px",
    [CARDSIZES.L]: "18px",
    [CARDSIZES.MD]: "16px",
    [CARDSIZES.S]: "14px",
  };

  return (
    <CustomCard
      image={image}
      key={key}
      size={size}
      optionsCell={optionsCell}
      title={activity?.titles.length !== 0 ? activity?.titles[0]?.content : ""}
    >
      <Flex
        flexDir={"column"}
        gap={"0.2em"}
        position={"absolute"}
        left={size === CARDSIZES.S ? "1.2em" : "1.8em"}
        bottom={size === CARDSIZES.S ? "-2em" : "-3em"}
      >
        {activity.country?.flagImage && (
          <Box mb={1}>
            <Image
              height={`calc(${flagWidth[size]} - 6px)`}
              width={flagWidth[size]}
              borderRadius={2}
              shadow={"0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)"}
              src={activity.country.flagImage}
            />
          </Box>
        )}
        <Box fontSize={"1em"}>{mainAddress}</Box>
        {subAddress && subAddress !== "" && (
          <Box fontSize={"12px"} fontWeight={400}>
            {subAddress}
          </Box>
        )}
      </Flex>
    </CustomCard>
  );
};

// Handels making Markers for Activities used in the map view, icon layout is the same as events in POI
const ActivityMarker = (item: MarkerMapItem, index: number, onClick: any) => {
  const activity = item as Event;
  return (
    <IconMarker
      key={`event-marker-${index}`}
      coordinates={activity.location?.coordinates ?? [0, 0]}
      layout={POIsToIcons[POIType.ACTIVITY]}
      onClick={() => onClick(activity)}
      name={activity.location?.name}
    />
  );
};
