import { useDisclosure } from "@chakra-ui/react";
import { Language, TranslationField } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useMeContext } from "../../stores/me-context";
import { useTripLanguage } from "../../stores/trip-language-context";
import { useTripFormStore } from "../../stores/trip/tripFormStore";
import { submitForms } from "../../utils/EventHelper";
import { checkUserTranslated } from "../../utils/getLanguages";
import { getAlreadyTranslatedLanguages, getShowType } from "../../utils/user-translation-utils";
import LanguageSwitcher from "../../components/trips/edit/daybyday/LanguageSwitcher";
import { calculateRealLanguageIndex, checkOwnTranslation } from "../../components/trips/edit/daybyday/LanguagesInput";
import DeeplModal from "./DeeplModal";
import { NewLanguage } from "../editTrip/wizard/WizardTopBar";
import { invalidateAllTripQueries } from "../../utils/invalidateAllTripQueries";

export const LanguageSwitcherAbstract: React.FC<any> = ({
  selectedLanguageIndex,
  firstInput,
  doubleFields,
  isFirst,
  getValues,
  reset,
  name,
  trip,
  allowDeepl = false,
  elementId,
  addLanguageCall,
  removeLanguageCall,
  invalidateQueries,
  forceNew = false,
}) => {
  const user = useMeContext();
  const { changeTripLanguageContext, calculateRealIndex } = useTripLanguage();
  const fields: TranslationField[] = getValues(name);
  const queryClient = useQueryClient();
  const { setIsLoadingTranslations, isSubmittingForms } = useTripFormStore();

  const modalDisclosure = useDisclosure();
  const translations = fields.filter((t: TranslationField) => t.content !== undefined);

  const [newLanguage, setNewLanguage] = React.useState<NewLanguage>();

  const handleNewLanguage = React.useCallback(
    async (index: number, id: string, language: Language, copy: boolean, reset: any) => {
      setIsLoadingTranslations(true);
      setNewLanguage({ id, language, copy, reset, index });
      if (
        translations.findIndex(
          (translation) =>
            translation.language.code === language.code &&
            (!isFirst || (user && checkOwnTranslation(translation.creator, user))),
        ) === -1
      ) {
        modalDisclosure.onOpen();
      } else {
        await addLanguageCall(id, language.code);
        // await Promise.all(invalidateQueries.map((key: string) => queryClient.invalidateQueries({ queryKey: [key] })));
        invalidateAllTripQueries(queryClient);
        changeTripLanguageContext((c) => ({
          ...c, // Add the new language to the context store
          partialFields: [...c.partialFields, { creator: null, language }],
        }));
        handleChangeLanguage(index);
      }
      setIsLoadingTranslations(false);
    },
    [changeTripLanguageContext, isFirst, doubleFields, translations, user],
  );

  const handleChangeLanguage = React.useCallback(
    async (i: number) => {
      //Saving previous translations
      await submitForms(isSubmittingForms);
      changeTripLanguageContext((c) => ({
        ...c,
        // Change the first selected language
        firstSelectedLanguageIndex: firstInput ? i : c.firstSelectedLanguageIndex,
        // Change the second selected language
        secondSelectedLanguageIndex: !firstInput ? i : c.secondSelectedLanguageIndex,
      }));
    },
    [changeTripLanguageContext, firstInput],
  );

  const processNewLanguage = async () => {
    changeTripLanguageContext((c) => ({
      ...c,
      firstSelectedLanguageIndex: isFirst ? newLanguage!.index : c.firstSelectedLanguageIndex,
      secondSelectedLanguageIndex: !isFirst
        ? doubleFields
          ? newLanguage
            ? newLanguage.index
            : 0
          : 0
        : c.secondSelectedLanguageIndex,
      // Add the new language to the context store
      partialFields: [...c.partialFields, { creator: null, language: newLanguage!.language }],
    }));
  };

  const handleRemoveLanguage = React.useCallback(
    async (i: number, code: string) => {
      await submitForms(isSubmittingForms);
      const realLanguageIndex = calculateRealIndex(i);
      !forceNew && setIsLoadingTranslations(true);

      await removeLanguageCall(elementId ?? "", code);
      if (!forceNew) {
        // await Promise.all(invalidateQueries.map((key: string) => queryClient.invalidateQueries({ queryKey: [key] })));
        invalidateAllTripQueries(queryClient);
      }

      changeTripLanguageContext((c) => {
        // Remove the language at 'i' from the context store
        const newPartialFields = c.partialFields.filter((_, j) => j !== realLanguageIndex);
        const hasThisUserTranslated = checkUserTranslated(newPartialFields, user);
        return {
          ...c,
          firstSelectedLanguageIndex: firstInput ? 0 : c.firstSelectedLanguageIndex,
          secondSelectedLanguageIndex: !firstInput
            ? !hasThisUserTranslated
              ? null
              : 0
            : c.secondSelectedLanguageIndex,
          partialFields: newPartialFields,
        };
      });
      !forceNew && setIsLoadingTranslations(false);
    },
    [changeTripLanguageContext, firstInput, calculateRealIndex],
  );

  const showType = getShowType(doubleFields, firstInput);
  const shortCalculateRealLanguageIndex = (i: number) => calculateRealLanguageIndex(translations, showType, i, user);
  const translatedLanguages = getAlreadyTranslatedLanguages(showType, user, translations);

  return (
    <>
      <LanguageSwitcher
        currentlySelectedIndex={selectedLanguageIndex}
        handleChangeLanguage={handleChangeLanguage}
        handleNewLanguage={handleNewLanguage}
        handleRemoveLanguage={handleRemoveLanguage}
        translatedLanguages={translatedLanguages}
        isFirst={isFirst}
        firstInput={firstInput}
        doubleFields={doubleFields}
        showType={showType}
        calculateRealIndex={shortCalculateRealLanguageIndex}
        getValues={getValues}
        reset={reset}
        elementId={elementId}
      />
      {modalDisclosure.isOpen && (
        <DeeplModal
          modalDisclosure={modalDisclosure}
          processNewLanguage={processNewLanguage}
          currentlySelectedIndex={selectedLanguageIndex}
          translatedLanguages={translatedLanguages}
          newLanguage={newLanguage}
          addLanguageCall={addLanguageCall}
          invalidateQueryKeys={invalidateQueries}
          forceNew={forceNew}
        />
      )}
    </>
  );
};
