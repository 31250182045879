import { Box, Flex, Icon, IconButton, Spinner, Textarea } from "@chakra-ui/react";
import { UserTrip } from "@lato/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BiSolidSend as Send } from "react-icons/bi";
import { v4 as uuid } from "uuid";
import UserTripsAPI from "../../api/usertrips.api";
import { useSocketInstance } from "../../app/providers/SocketProvider";
import { useMeContext } from "../../stores/me-context";
import { useChatStore } from "../../stores/trip/chat-store";
import { SAMPLES_USERTRIPS_KEY, TRIPS_USERTRIPS_KEY } from "../../utils/constants";
import Form from "../../components/form/Form";

interface ChatBottomLayoutProps {
  tripId: string | undefined;
  usertrip: UserTrip;
  chat_enabled: boolean;
  userTripId: string;
}

type SendMessageForm = {
  message: string;
};

const ChatBottomLayout: React.FC<ChatBottomLayoutProps> = ({ tripId, usertrip, chat_enabled, userTripId }) => {
  const queryClient = useQueryClient();
  const user = useMeContext();
  const { addNewLocalTripMessage } = useChatStore();

  const socket = useSocketInstance();
  const formMethods = useForm<SendMessageForm>({
    defaultValues: {
      message: "",
    },
  });

  // const { mutateAsync: sendMessage, isLoading: isSending } = useMutation(
  //   (message: string) => MessageAPI.sendMessage(tripId!, message),
  //   {
  //     onSuccess: () => {
  //       // Better handle this, can't you just add the current message to the cache?
  //       // queryClient.invalidateQueries(["userapptrip", userTripId]);
  //       // queryClient.invalidateQueries(MESSAGES_QUERY_KEY);
  //     },
  //   }
  // );

  useEffect(() => {
    // Invalidate Query and Leave Room On Unmount
    return () => {
      // queryClient.invalidateQueries(["userapptrip", userTripId]);
      socket?.emit("leaveRoom", userTripId);
    };
  }, []);

  const { mutateAsync: enableChat } = useMutation({
    mutationFn: async () => await UserTripsAPI.patch(userTripId, { chat_enabled: true }),
    onSuccess: (data) => {
      // Better handle this, can't you just add 1 country to the cache?
      queryClient.invalidateQueries({
        queryKey: ["usertrip", userTripId],
      });
      // queryClient.setQueryData(["usertrip", usertripId], data);
      // Improve this, that not both need to be invalidated
      queryClient.invalidateQueries({
        queryKey: [SAMPLES_USERTRIPS_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [TRIPS_USERTRIPS_KEY],
      });
    },
  });

  async function handleSubmit({ message }: SendMessageForm) {
    if (!chat_enabled) {
      // If the chat is disabled, but an agent sends a message, the chat should automatically become enabled.
      await enableChat();
    }
    const messageId = uuid();
    // Emit Socket Event to Server
    socket?.emit("chatToServer", {
      userTripId: userTripId,
      message: message,
      userId: user?.id,
      id: messageId,
    });
    // Add the new local message to the local state
    addNewLocalTripMessage({
      created_at: new Date(),
      message,
      read: true,
      sender: user,
      usertrip: usertrip,
      id: messageId,
    });
    // Reset the form such that the values are cleared
    formMethods.reset();
  }

  // When this component re-renders, reset the form state
  React.useEffect(() => formMethods.reset(), [tripId]);

  /**
   * Emit travelAgentIsTyping when user is typing
   * Added setTimeout to set  timer = null to avoid server to get bomarbared with
   * On every key press
   */
  let timer: any = null;
  const handleTyping = () => {
    if (timer === null) {
      socket?.emit("travelAgentIsTyping", {
        userTripId,
      });
      timer = setTimeout(() => {
        timer = null;
      }, 2000);
    }
  };

  return (
    <Box mb={{ base: "5", xl: "0" }} p={{ base: "0", xl: "6" }} pt={2} position="relative">
      <Form formMethods={formMethods} onSubmit={handleSubmit}>
        <Flex alignItems="center">
          <Textarea
            maxLength={1024}
            autoComplete="off"
            {...formMethods.register("message")}
            borderRadius="xl"
            focusBorderColor="brand.500"
            size="md"
            placeholder="Type a message..."
            mr={3}
            resize="none"
            onKeyDown={handleTyping}
            rows={3}
          />
          {formMethods.formState.isSubmitting ? (
            <Spinner color="brand.500" />
          ) : (
            <IconButton
              icon={<Icon as={Send} boxSize={7} />}
              variant="basic"
              colorScheme="brand"
              aria-label="Send chat message"
              type="submit"
              isDisabled={!formMethods.formState.isDirty || !tripId}
            />
          )}
        </Flex>
      </Form>
    </Box>
  );
};
export default React.memo(ChatBottomLayout);
