import { AddIcon, CloseIcon, DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Image, TabPanel, Text, useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ExternalImage, ImageSite, Image as ImageType, TLibraryImage, TLocation } from "@lato/common";
import React from "react";
import { useForm } from "react-hook-form";
import LibraryImagesAPI from "../../api/library-images.api";
import { useMeContext } from "../../stores/me-context";
import colors from "../../theme/foundations/colors";
import { MAX_PICTURES } from "../../utils/constants";
import useMutation from "../../utils/query-helpers/useMutation";
import { submitDocumentsArray } from "../../utils/submitDocumentsArray";
import { libraryImageSchema } from "../../validation/validationSchemas";
import { getEmptyLibraryImage } from "../../components/elements/activities";
import { LibraryImageLocationModal } from "../../components/elements/libraryImages/libraryimage-modal";
import ExternalImages from "./ExternalImages";
import ImageByLinkUploader from "./ImageByLinkUploader";
import LibraryImagesPicker from "./LibraryImagesPicker";
import TripdayImageDropzone from "../../components/fileUpload/TripdayImageDropzone";
import TabModal from "../../components/layout/TabModal";

export const imageNoPreviewError = "Image loaded, but preview not supported!";

interface ImageUploadModalProps {
  aspectRatio: number;
  orientation?: "portrait" | "landscape" | "squarish";
  handleChosenLinkPictures: (image: ImageType[]) => any;
  handleChosenLocalPictures: (blobs: Blob | Blob[]) => void;
  handleChosenExternalPictures: (photo: ExternalImage | ExternalImage[], searchInput?: string) => void;
  handleChosenLibraryPictures: (photo: TLibraryImage | TLibraryImage[]) => void;
  disclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
  multiple: boolean;
  defaultSearchValue: string;
  nrOfImages?: number;
  inLibrary?: boolean;
  location?: TLocation;
  inModal?: boolean;
}
interface localImage {
  url: string;
  type: ImageSite;
  image?: ImageType;
  file?: Blob;
}

const ImageUploadModal: React.FC<ImageUploadModalProps> = ({
  orientation,
  disclosure,
  aspectRatio,
  handleChosenLocalPictures,
  handleChosenExternalPictures,
  handleChosenLinkPictures,
  handleChosenLibraryPictures,
  multiple,
  defaultSearchValue,
  nrOfImages = 0,
  inLibrary = false,
  location,
  inModal = true,
}) => {
  const user = useMeContext();
  const { isOpen, onOpen, onClose } = disclosure;
  const locationDisclosure = useDisclosure();
  const [dropError, setDropError] = React.useState<string>();
  const [showLinkError, setShowLinkError] = React.useState<boolean>(false);
  const [localImages, setLocalImages] = React.useState<localImage[]>([]);
  const [isAddingToLibrary, setIsAddingToLibrary] = React.useState<boolean>();

  const createImage = useMutation({
    apiCall: (images: TLibraryImage[]) => LibraryImagesAPI.post({ libraryImages: images }),
    successMessage: `Added images to library`,
    failMessage: `Adding images to library`,
  });

  const emptyImageItem = getEmptyLibraryImage(user);

  const formMethods = useForm<TLibraryImage>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: emptyImageItem,
    shouldUnregister: false,
    resolver: yupResolver(libraryImageSchema),
  });

  const S3imageLocation = `libraryImages/${user.company.name}-${user.companyId}/`;

  const addImages = (images: localImage[]) => {
    setLocalImages([...localImages, ...images]);
  };

  const handleChosenExternalPicturesAndClose = (photo: ExternalImage | ExternalImage[], searchInput?: string) => {
    handleChosenExternalPictures(photo, searchInput);
    // Close the image modal once a picture is chosen.
    onClose();
  };

  const handleChosenLibraryPicturesAndClose = (photo: TLibraryImage | TLibraryImage[]) => {
    handleChosenLibraryPictures(photo);
    // Close the image modal once a picture is chosen.
    onClose();
  };

  const splitFilesAndLinks = () => {
    return {
      blobs: localImages.filter((local) => local.file && local.file !== null).map((local) => local.file),
      imageLinks: localImages.filter((local) => local.image && local.image !== null).map((local) => local.image),
    };
  };

  const handleAddToLibrary = async () => {
    if (location) {
      await addToLibrary(location);
    } else {
      locationDisclosure.onOpen();
    }
  };

  const addToLibrary = async (location: TLocation) => {
    const { blobs, imageLinks } = splitFilesAndLinks();
    if (blobs && blobs[0]) {
      setIsAddingToLibrary(true);
      const libraryImages = blobs.map((image, i) => {
        const f = new File([image!], "file", {
          type: "image/jpeg",
        });
        return {
          site: ImageSite.S3,
          name: i === 0 ? "fileImage" : "fileImage-" + i,
          user: user,
          file: f,
          url: URL.createObjectURL(f),
          ord: 0,
          location,
        };
      });
      await submitDocumentsArray(libraryImages, S3imageLocation);

      await createImage.mutate(libraryImages);
      setIsAddingToLibrary(false);
    }
    if (imageLinks && imageLinks[0]) {
      setIsAddingToLibrary(true);
      await createImage.mutate(
        imageLinks.map((imgL) => ({
          ...imgL,
          s3: { key: imgL!.url },
          name: imgL!.originUrl!.split("/").pop()!.split("?")[0],
          location,
        })),
      );
      setIsAddingToLibrary(false);
    }
    locationDisclosure.onClose();
  };

  const handleChosenPicturesAndClose = () => {
    const newPictureAmount = nrOfImages + (Array.isArray(localImages) ? (localImages as any[]).length : 1);
    const { blobs, imageLinks } = splitFilesAndLinks();

    if (newPictureAmount <= MAX_PICTURES) {
      if (blobs && blobs[0]) {
        handleChosenLocalPictures(blobs as Blob[]);
      }
      if (imageLinks && imageLinks[0]) {
        handleChosenLinkPictures(imageLinks as ImageType[]);
      }
      // Close the image modal once a picture is chosen.
      onClose();
    } else {
      setDropError("You can only have 20 images per element");
    }
  };

  const handleChosenLinkImages = (images: ImageType[]) => {
    if (nrOfImages + images.length <= MAX_PICTURES) {
      handleChosenLinkPictures(images);
      onClose();
    } else {
      setShowLinkError(true);
    }
  };

  const handlePaste = (e: any) => {
    if (e.clipboardData.files.length) {
      const fileObjects = e.clipboardData.files;
      const images = [];
      for (let i = 0; i < fileObjects.length; i++) {
        images.push({
          url: URL.createObjectURL(fileObjects[i]),
          type: ImageSite.CUSTOMPICTURE,
          file: fileObjects[i],
        });
      }
      addImages(images);
    }
  };

  const handleRemoveImage = (index: number) => {
    setLocalImages(localImages.filter((_, i) => i !== index));
  };

  const libraryTabs = ["Suggested Images", "Upload Image"];
  const allTabs = ["Suggested Images", "Saved Images", "Upload Image"];

  return (
    // <CustomModal isOpen={isOpen} onClose={onClose} size="3xl" title="Choose a background picture">
    //       <Tabs variant="unstyled" onClick={(e) => e.stopPropagation()}>
    //         <TabList mx="auto" bg="white" zIndex={3} pos="sticky" top={0}>
    //           <Flex w="300px" mx="auto">
    //             <TabButton>Suggested Pictures</TabButton>
    //             <TabButton>Own Pictures</TabButton>
    //           </Flex>
    //         </TabList>
    //         <TabPanels>
    //           <TabPanel>
    //             <Unsplash
    //               i={i}
    //               orientation={orientation}
    //               handleChosenUnsplashPicture={
    //                 handleChosenUnsplashPictureAndClose
    //               }
    //             />
    //           </TabPanel>
    //           <TabPanel h="35vh">
    //             <TripdayImageDropzone
    //               i={i}
    //               aspectRatio={aspectRatio}
    //               handleChosenLocalPicture={handleChosenLocalPictureAndClose}
    //             />
    //           </TabPanel>
    //         </TabPanels>
    //       </Tabs>
    // </CustomModal>
    <>
      <TabModal
        title="Choose a background image"
        tabs={inLibrary ? libraryTabs : allTabs}
        disclosure={disclosure}
        scrollBehavior="inside"
        tabHeaderWidth={inLibrary ? "50%" : "75%"}
        inModal={inModal}
      >
        <TabPanel>
          <ExternalImages
            defaultSearchValue={defaultSearchValue}
            orientation={orientation}
            handleChosenPictures={handleChosenExternalPicturesAndClose}
            multiple={multiple}
            nrOfImages={nrOfImages}
            inLibrary={inLibrary}
            location={location}
          />
        </TabPanel>
        {!inLibrary && (
          <TabPanel>
            <LibraryImagesPicker
              defaultSearchValue={defaultSearchValue}
              handleChosenPictures={handleChosenLibraryPicturesAndClose}
              multiple={multiple}
              nrOfImages={nrOfImages}
            />
          </TabPanel>
        )}
        <TabPanel padding={2} position={"relative"}>
          <Box onPaste={handlePaste}>
            <ImageByLinkUploader
              nrOfImages={nrOfImages}
              showError={setShowLinkError}
              inLibrary={inLibrary}
              addImagetoPreview={addImages}
            ></ImageByLinkUploader>

            <Text my={3} textAlign={"center"}>
              --- OR ---
            </Text>
            <TripdayImageDropzone
              aspectRatio={aspectRatio}
              multiple={multiple}
              inLibrary={inLibrary}
              setDropError={setDropError}
              addImages={addImages}
            />
            {localImages.length > 0 && (
              <>
                <Flex my={3} height={"9em"} overflow={"scroll"} width={"100%"} gap={1} mx={"auto"}>
                  {localImages.map((preview, index) => (
                    <Box
                      key={`image-${index}`}
                      minWidth={"max-content"}
                      position={"relative"}
                      ml={index === 0 ? "auto" : 0}
                      mr={index === localImages.length - 1 ? "auto" : 0}
                    >
                      <Flex
                        pos="absolute"
                        right={1}
                        top={1}
                        as="button"
                        type="button"
                        borderRadius="full"
                        w="20px"
                        h="20px"
                        bg="gray.200"
                        alignItems="center"
                        justifyContent="center"
                        transition="ease 0.3s"
                        _hover={{
                          bg: "gray.300",
                        }}
                        onClick={(e) => handleRemoveImage(index)}
                      >
                        <CloseIcon fontSize="9px" />
                      </Flex>
                      {preview.type === ImageSite.CUSTOMPICTURE ? (
                        <div className="object-contain h-32 bg-gray-200 rounded-lg py-2 px-4 text-md">
                          <Image
                            h={"100%"}
                            src={preview.url}
                            onError={(e) => {
                              e.currentTarget.parentElement!.innerHTML = imageNoPreviewError;
                            }}
                          ></Image>
                        </div>
                      ) : (
                        <iframe
                          title={`image-${index}`}
                          key={preview.url}
                          id="iframeUpload"
                          height="100%"
                          width="100%"
                          src={preview.url}
                        ></iframe>
                      )}
                    </Box>
                  ))}
                </Flex>
                <Flex gap={3} margin={"auto"} mb={"1em"} justifyContent={"center"}>
                  {!inLibrary && (
                    <Button
                      leftIcon={<DownloadIcon />}
                      color={colors.lato.primaryColor}
                      borderColor={colors.lato.primaryColor}
                      borderWidth={1}
                      isLoading={isAddingToLibrary}
                      onClick={handleAddToLibrary}
                    >
                      Add Images to Library
                    </Button>
                  )}
                  <Button leftIcon={<AddIcon />} colorScheme="brand" onClick={() => handleChosenPicturesAndClose()}>
                    Add Images
                  </Button>
                </Flex>
              </>
            )}
            {dropError && (
              <Text
                position={"absolute"}
                bottom={0}
                textAlign={"center"}
                width={"100%"}
                color="red.500"
                marginLeft={"-0.5em"}
              >
                {dropError}
              </Text>
            )}
            {showLinkError && (
              <Text
                position="absolute"
                top={"18.2em"}
                textAlign={"center"}
                width={"100%"}
                color="red.500"
                marginLeft={"-0.5em"}
              >
                You can only have 20 images per element
              </Text>
            )}
          </Box>
        </TabPanel>
      </TabModal>
      <LibraryImageLocationModal
        locationDisclosure={locationDisclosure}
        formMethods={formMethods}
        handleSave={async (lib) => {
          await addToLibrary((lib as TLibraryImage).location!);
        }}
        isSubmitting={isAddingToLibrary ?? false}
      />
    </>
  );
};
export default React.memo(ImageUploadModal);
