import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import UsersAPI from "../../api/users.api";
import { useMeContext } from "../../stores/me-context";

interface GetTimeZoneProps {}

export const GetTimeZone: React.FC<GetTimeZoneProps> = () => {
  const me = useMeContext();

  const { mutateAsync: mutateUser } = useMutation({
    mutationFn: UsersAPI.patchUser,
  });

  // Free api that fetches timezone of ip info
  useEffect(() => {
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        if (me.timezone === undefined) {
          // Timezone has not been set yet
          const country = data.country;
          mutateUser({ ...me, timezone: country });
        } else if (me.timezone !== data.timezone) {
          // Timezone of user is changed
          mutateUser({ ...me, timezone: data.timezone });
        }
      });
  }, []);

  return null;
};

export default GetTimeZone;
