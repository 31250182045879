import rawApiProvider from "./utilities/rawProvider";

const url = "documents";

// Get the standard CRUD operations

const getTripDocuments = (tripId: string) => {
  return rawApiProvider.get(`${url}/trip/${tripId}`);
};

type DefaultDocumentsAPIType = {
  getTripDocuments(tripId: string): Promise<any>;
};
const DocumentsAPI: DefaultDocumentsAPIType = {
  getTripDocuments,
};
export default DocumentsAPI;
