const baseStyle = {
  fontSize: "sm",
};

const variantFloating = {
  mb: 0,
  // marginStart: 1,
  // fontSize: "xs",
  color: "realGray.400",
};

// const variantHorizontal = {
//   bg: "papayawhip",
//   // Makes sure halve of the form control container is reserverd for the form label
//   // w: "100%",
//   // Undo the marginEnd from the baseStyle
//   marginEnd: 0,
//   // flexGrow: 0.5,
// };

const variants = {
  floating: variantFloating,
};

const defaultProps = {
  variant: "floating",
};

export default {
  baseStyle,
  variants,
  defaultProps,
};
