import { Tabs, TabList, TabPanels, Tab, TabPanel, ButtonGroup, Box, IconButton } from "@chakra-ui/react";
import { AddIcon, ChevronDownIcon, ChevronUpIcon, DragHandleIcon } from "@chakra-ui/icons";
import { MdHotel } from "react-icons/md";
import { Hotel, Trip, TripDay, calculateTripdayDate, omitLocalTimezone } from "@lato/common";
import HotelFormInTripWrapper from "../hotel/HotelFormInTrip";
import { alphabet } from "../../../../../../utils/constants";
import * as React from "react";
import { useTripFormStore } from "../../../../../../stores/trip/tripFormStore";

interface HotelTabsProps {
  hotels: Hotel[];
  selectedAccommodationIndex: number;
  setSelectedAccommodationIndex: (index: number) => void;
  hotelFieldArrayMethods: any;
  provided: any;
  handleMoveLeftRight: (e: any, from: number, to: number) => Promise<void>;
  trip: Trip;
  tripdayIndex: number;
  tripday: TripDay;
  realDayNumber: number;
  first: boolean;
  handleDragAndDropMove: (fromIndex: number, toIndex: number) => Promise<void>;
  orderIndex: number;
  isMovingDisabled: boolean;
  objectsLength: number;
}

export const HotelTabs: React.FC<HotelTabsProps> = ({
  hotels,
  selectedAccommodationIndex,
  setSelectedAccommodationIndex,
  hotelFieldArrayMethods,
  provided,
  handleMoveLeftRight,
  trip,
  tripdayIndex,
  tripday,
  realDayNumber,
  first,
  handleDragAndDropMove,
  orderIndex,
  isMovingDisabled,
  objectsLength,
}) => {
  const { isInvalidForms, setUnCollapsedElement } = useTripFormStore();

  if (!hotels) return null;

  return (
    <Tabs
      className="group"
      variant="enclosed"
      index={selectedAccommodationIndex}
      onChange={(i: number) => {
        setUnCollapsedElement(undefined);
        setSelectedAccommodationIndex(i);
      }}
    >
      <TabList px={1} justifyContent={"space-between"}>
        <div className="flex">
          {hotels
            .filter((hotel) => hotel.id)
            .sort((a, b) => a.ord - b.ord)
            .map((hotel, hotelIndex) => (
              <Tab
                zIndex={20}
                key={`hotel-tab-${hotel.id}`}
                _selected={{
                  color: "brand.500",
                  bg: "white",
                  border: "1px solid",
                  borderColor: "lightGray.400",
                  borderBottom: "none",
                  fontWeight: "bold",
                }}
                className="max-w-[150px] truncate"
                display={"block"}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  setSelectedAccommodationIndex(hotel.id);
                }}
                borderBottom={"none"}
                mb={"-2px"}
                isDisabled={[...isInvalidForms.values()].some((value) => value === true)}
              >
                {hotel.name || `Hotel ${alphabet[hotelIndex]}`}
              </Tab>
            ))}
          {(hotels.length ?? 0) < 5 && (
            <button onClick={() => hotelFieldArrayMethods.append()} className="mx-8">
              <Box position="relative" className="hover:bg-gray-100 p-2 rounded-full">
                <MdHotel size={"15px"} />
                <AddIcon position={"absolute"} boxSize={"8px"} right={"5px"} top={"3px"} />
              </Box>
            </button>
          )}
        </div>
        {!isMovingDisabled && (
          <ButtonGroup
            spacing={0}
            display={first ? "flex" : "none"}
            gap={1}
            bgColor={"transparent"}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {orderIndex !== 0 && (
              // Dont show if the hotels are first in the list
              <IconButton
                className="opacity-0 transition-all group-hover:opacity-100 z-50"
                bgColor="transparent"
                aria-label="Move element up"
                icon={<ChevronUpIcon boxSize={4} />}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  handleDragAndDropMove(
                    orderIndex + selectedAccommodationIndex,
                    orderIndex + selectedAccommodationIndex - 1,
                  );
                }}
                disabled={isMovingDisabled}
              />
            )}
            <IconButton
              className="opacity-0 transition-all group-hover:opacity-100 z-50"
              aria-label="move accommodations"
              icon={<DragHandleIcon boxSize={3} />}
              {...provided.dragHandleProps}
              zIndex={10}
              disabled={isMovingDisabled}
            />
            {orderIndex !== objectsLength - hotels.length && (
              // Dont show if the hotels are last in the list
              <IconButton
                className="opacity-0 transition-all group-hover:opacity-100 z-50"
                bgColor="transparent"
                aria-label="Move element down"
                icon={<ChevronDownIcon boxSize={4} />}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) =>
                  handleDragAndDropMove(
                    orderIndex + selectedAccommodationIndex,
                    orderIndex + selectedAccommodationIndex + 1,
                  )
                }
                disabled={isMovingDisabled}
              />
            )}
          </ButtonGroup>
        )}
      </TabList>
      <TabPanels>
        {hotels
          .sort((a, b) => a.ord - b.ord)
          .map((hotel, hotelIndex) => (
            <TabPanel key={`hotel-swiper-${hotel.id}`} p={0}>
              <HotelFormInTripWrapper
                dragHandleProps={provided.dragHandleProps}
                i={hotelIndex}
                remove={hotelFieldArrayMethods.remove}
                tripdayIndex={tripdayIndex}
                tripday={tripday}
                fieldArrayMethods={hotelFieldArrayMethods}
                orderIndex={hotelIndex}
                trip={trip}
                tripdayId={tripday.id!}
                tripdayStartDate={
                  new Date(
                    omitLocalTimezone(
                      calculateTripdayDate(realDayNumber, trip.start_date) ?? trip.start_date ?? new Date(),
                    ),
                  )
                }
                tripdayEndDate={
                  new Date(
                    omitLocalTimezone(
                      calculateTripdayDate(realDayNumber + tripday.nrOfNights, trip.start_date) ??
                        trip.start_date ??
                        new Date(),
                    ),
                  )
                }
                move={handleMoveLeftRight}
                hotel={hotel}
                hotelAmount={hotels.length}
                selectedAccommodationIndex={selectedAccommodationIndex}
                setSelectedAccommodationIndex={setSelectedAccommodationIndex}
              />
            </TabPanel>
          ))}
      </TabPanels>
    </Tabs>
  );
};

export default React.memo(HotelTabs);
