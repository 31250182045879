import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Text,
} from "@chakra-ui/react";
import { User } from "@lato/common";
import clsx from "clsx";
import React, { useMemo } from "react";
import { GoPersonAdd } from "react-icons/go";
import { ConditionalWrapper } from "../contacts/Contact";
import { FaCaretDown } from "react-icons/fa";

interface UserSelectProps {
  selectedUser?: User;
  users: User[];
  isDisabled: boolean;
  handleUserSelect: (user?: User) => void;
  isWideLabel?: boolean;
  isInModal?: boolean;
}

export const UserSelect: React.FC<UserSelectProps> = ({
  selectedUser,
  users,
  isDisabled,
  handleUserSelect,
  isWideLabel = true,
  isInModal = true,
}) => {
  const renderUserOption = (user: User) => (
    <Flex alignItems="center">
      <Avatar size="xs" name={user.name} src={user.avatarUrl} mr={2} />
      <span>{user.name}</span>
    </Flex>
  );

  const onSelect = (value?: string | string[]) => {
    const user = users.find((user) => user.id === value);
    handleUserSelect(user);
  };

  const options = (
    <MenuOptionGroup type="radio" value={selectedUser ? selectedUser.id : undefined}>
      {users?.map((user) => (
        <MenuItemOption
          key={`user-option-${user.id}`}
          value={user.id}
          onClick={() => {
            user.id === selectedUser?.id ? onSelect(undefined) : onSelect(user.id);
          }}
        >
          {renderUserOption(user)}
        </MenuItemOption>
      ))}
    </MenuOptionGroup>
  );

  return (
    <Flex className={clsx("relative rounded-md h-8 justify-between", isWideLabel ? "w-44" : "w-8")}>
      <Menu closeOnSelect={true}>
        <MenuButton
          as={Button}
          backgroundColor={"transparent"}
          isDisabled={isDisabled}
          mt={0.5}
          px={0}
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          className={clsx("text-black -mt-1 h-8 bg-white cursor-pointer z-10", isWideLabel ? "w-36" : "w-8")}
        >
          <Flex>
            {selectedUser ? (
              <Avatar
                justifySelf="center"
                size="xs"
                name={selectedUser.name}
                src={selectedUser.avatarUrl}
                title={selectedUser.name}
                zIndex={10}
              />
            ) : (
              <GoPersonAdd className="w-6 h-6 ml-2 right-0 rounded-full fill-gray text-gray-600 bg-gray-300 p-1.5 z-0" />
            )}
            {isWideLabel && <Text className="text-black pl-4 pt-1 text-center z-10">{selectedUser?.name}</Text>}
          </Flex>
        </MenuButton>
        <ConditionalWrapper condition={!isInModal} wrapper={(children) => <Portal>{children}</Portal>}>
          <MenuList ml={-12} zIndex={50} position="absolute" minWidth="200px" maxH="25em" overflowY="scroll">
            {options}
          </MenuList>
        </ConditionalWrapper>
      </Menu>
    </Flex>
  );
};

export default UserSelect;
