import { Box, Flex, Image, Skeleton } from "@chakra-ui/react";
import { Language } from "@lato/common";
import React from "react";
import { FilterProps } from "react-table";
import { CustomSpinner } from "../FullScreenSpinner";
import MultiSelectColumnFilter from "./MultiSelectColumnFilter";

interface LanguageFilterProps extends React.PropsWithChildren<FilterProps<any>> {
  languages: Language[];
  isLoadingLanguages: boolean;
}

const LanguageFilter: React.FC<LanguageFilterProps> = ({ languages, isLoadingLanguages, ...props }) => {
  const createLanguageOptions = React.useCallback(
    (preFilteredRows: any, columnName: string) => {
      // If the members didn't load yet, return an empty set.
      if (!languages) return new Set<string>();

      return new Set(languages.map((c) => c.code));
    },
    [languages],
  );

  const createSearchValueOption = React.useCallback(
    (language: string) => {
      if (!languages) return "";
      return languages?.find((c) => c.code === language)?.code ?? "";
    },
    [languages],
  );

  const displayCountryOption = React.useCallback(
    (languageCode: string): React.ReactElement => {
      // Find a single countrytrip of this country to get the country object
      const language = languages?.find((c) => c.code === languageCode);
      // If the members didn't load yet or somehow no country was found, return an empty fragment.
      if (!language) return <></>;

      return (
        <Flex alignItems="center">
          <Flex display="flex" gap={2} alignItems={"center"} justifyContent={"center"}>
            <Box>{language.flag}</Box>
            <Box>{language.name}</Box>
          </Flex>
        </Flex>
      );
    },
    [languages],
  );

  if (isLoadingLanguages) return <Skeleton height="30px" w="120px" />;

  return (
    <>
      <MultiSelectColumnFilter
        {...props}
        displayName={"language"}
        displayOption={displayCountryOption}
        searchValueOption={createSearchValueOption}
        // TODO: remove later, but currently BUG: the preFilteredRows are sometimes undefined
        createOptions={createLanguageOptions}
        useSelectedItems={true}
        onChange={(e) => {
          if (e.length === languages.length || e.length === 0) {
            props.columns.find((column) => column.id === "language" || column.id === "languages")?.setFilter([]);
          } else {
            props.columns.find((column) => column.id === "language" || column.id === "languages")?.setFilter(e);
          }
        }}
      />
    </>
  );
};
export default React.memo(LanguageFilter);
