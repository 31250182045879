import React from "react";
import RHFInput from "../RHFInput";
import { Controller, useFormContext } from "react-hook-form";

interface MaskedDateInputProps {
  name: string;
  defaultValue?: any;
  label?: string;
}

// const mask = [
//   /[0-3]/,
//   /\d/,
//   ".",
//   /(0|1)/,
//   /\d/,
//   ".",
//   /(1|2)/,
//   /(9|0)/,
//   /\d/,
//   /\d/,
// ];

const MaskedDateInput: React.FC<MaskedDateInputProps> = ({ name, defaultValue, label }) => {
  const { trigger, getValues } = useFormContext();

  defaultValue = defaultValue || getValues(name);
  const [inputValue, setInputValue] = React.useState(toEur(defaultValue) || "");

  function toEur(date: Date | string | undefined): string | undefined {
    // Change the way it is saved to the backend (if you change this, also change the frontend validationschema)
    if (date instanceof Date) {
      date = date.toISOString().substring(0, 10);
    }
    return date ? date.split("-").reverse().join(".") : undefined;
  }
  function toISO(date: string): string | null {
    setInputValue(date);
    if (!date) return null;
    if (/\d\d\.\d\d\.\d\d\d\d/.test(date)) {
      const isoFormat = date.split(".").reverse().join("-");
      try {
        return new Date(isoFormat).toISOString().substring(0, 10);
      } catch {
        // If the date is invalid, return null and show an error message.
        // Return a date that is far in the future so that the validation gets triggered.
        return "9999-01-01";
      }
    }
    return null;
  }

  console.log(inputValue);

  return (
    // <RHFInput
    //   name={name}
    //   mask={mask}
    //   alwaysShowMask={false}
    //   maskPlaceholder="dd.mm.yyyy"
    //   // The valueAsDate prop expects a Date in US format: mm.dd.yyyy,
    //   // Hence the setValueAs prop is used
    //   registerOptions={{setValueAs: v => {
    //     if (/[0-3]\d\.(0|1)\d\.(1|2)(9|0)\d\d/.test(v)) {
    //       const isoFormat = v.split(".").reverse().join("-");
    //       return new Date(isoFormat).toISOString().substring(0,10);
    //     }
    //     return null;
    //   }}}
    // />
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RHFInput
          {...field}
          controlled
          onChange={(e) => field.onChange(toISO(e.target.value))}
          value={inputValue}
          mask="99.99.9999"
          // mask={mask}
          alwaysShowMask={false}
          maskPlaceholder="dd.mm.yyyy"
          label={label}
        />
      )}
    />
  );
};
export default React.memo(MaskedDateInput);
