import { useCallback, useEffect } from "react";
import { calculateValue } from "./calculateValue";
export function useReorderFieldarray(
  // fieldArrayName: string,
  fieldArrayMethodsArray: any[],
  // getValues: any
  fields2: any[] = [],
) {
  let allFields: any[] = [];
  const fieldsArray: any[] = [];
  const replaces: any[] = [];
  const appends: any[] = [];

  //Retrieving all needed methods from passed fieldArrayMethods
  fieldArrayMethodsArray.forEach((fieldArrayMethods) => {
    const { replace, append, fields } = fieldArrayMethods;
    replaces.push(replace);
    appends.push(append);
    fieldsArray.push(fields);
    allFields = allFields.concat(fields);
  });
  allFields.sort((a, b) => a.ord - b.ord);

  const handleMove = useCallback(
    (fromIndex: number, toIndex: number, noMoveRelated: boolean = false) => {
      // Move the item to another place
      // const fieldsClone = getValues(fieldArrayName);
      const fieldsClone = [...fields2].sort((a, b) => a.ord - b.ord);
      fieldsClone.splice(toIndex, 0, fieldsClone.splice(fromIndex, 1)[0]);
      if (!noMoveRelated) {
        if (
          fieldsClone[toIndex] &&
          !fieldsClone[toIndex].hasOwnProperty("endTime") &&
          !fieldsClone[toIndex].hasOwnProperty("transportNumber") &&
          !fieldsClone[toIndex].hasOwnProperty("displayName")
        ) {
          for (let j = 1; j <= 2; j++) {
            const newIndex = toIndex < fromIndex ? fromIndex + j : fromIndex;
            if (
              fieldsClone[newIndex] &&
              !fieldsClone[newIndex].hasOwnProperty("endTime") &&
              !fieldsClone[newIndex].hasOwnProperty("transportNumber") &&
              !fieldsClone[newIndex].hasOwnProperty("displayName")
            ) {
              fieldsClone.splice(toIndex + j, 0, fieldsClone.splice(newIndex, 1)[0]);
            }
          }
        }
      }
      const renamedOrdProps = fieldsClone.map((field: any, i: number) => ({
        ...field,
        ord: calculateValue(i, allFields.length),
      }));

      replace(renamedOrdProps);
    },
    [fields2],
  );

  const addField = useCallback(
    (field: any) => {
      append({ ...field, ord: allFields.length });
    },
    [allFields],
  );

  //custom replace to decide which replace of all fieldArrayMethods to use
  const replace = (objects: any[]) => {
    //when only one fieldArrayMethod is passed we can use it directly
    if (fieldsArray.length === 1) {
      const replaceMethod = replaces[0];
      replaceMethod(objects);
    } else {
      //when mutltiple fieldArrayMethods are passed we have to decide which one to use
      //This is hardcoded since there is only one case where multiple fieldArrayMethods are passed : EventsList
      const events: any[] = [];
      const transports: any[] = [];
      const hotels: any[] = [];
      objects.forEach((object) => {
        if (object.hasOwnProperty("transportNumber") || object.hasOwnProperty("inUse")) {
          transports.push(object);
        } else if (object.hasOwnProperty("endTime") || object.hasOwnProperty("s3url")) {
          events.push(object);
        } else {
          hotels.push(object);
        }
      });
      //collecting all replace methods and calling them
      const eventsReplace = replaces[0];
      const transportReplace = replaces[1];
      const hotelReplace = replaces[2];
      eventsReplace([...events]);
      transportReplace([...transports]);
      hotelReplace && hotelReplace([...hotels]);
    }
  };

  //custom append to decide which append of all fieldArrayMethods to use
  const append = (object: any) => {
    //when only one fieldArrayMethod is passed we can use it directly
    if (fieldsArray.length === 1) {
      const appendMethod = appends[0];
      appendMethod({ ...object, ord: allFields.length });
    } else {
      //when mutltiple fieldArrayMethods are passed we have to decide which one to use
      //This is hardcoded since there is only one case where multiple fieldArrayMethods are passed : EventsList
      let appendMethod: any = undefined;

      if (object.hasOwnProperty("transportNumber") || object.hasOwnProperty("inUse")) {
        appendMethod = appends[1];
      } else if (object.hasOwnProperty("endTime") || object.hasOwnProperty("s3url")) {
        appendMethod = appends[0];
      } else {
        appendMethod = appends[2];
      }
      appendMethod({ ...object, ord: allFields.length });
    }
  };

  /*useEffect(() => {
    console.log("fields length changed");
    // If the length of the events changes, also update the 'ord' properties.
    const fieldsClone = [...fields2];
    const renamedOrdProps = fieldsClone.map((field, i) => ({
      ...field,
      ord: i,
    }));
    replace(renamedOrdProps);
  }, [allFields.length]);*/

  return { moveField: handleMove, addField };
}
