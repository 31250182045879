import DOMPurify from "dompurify";

// Add a hook to make all links open a new window
DOMPurify.addHook("afterSanitizeAttributes", function (node: any) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
    node.setAttribute("rel", "noreferrer");
  }
});

// This function sanitizes an html string. This makes sure that XSS attacks are prevented
// e.g. when someone sends <p><script>while(1){}</script><p> to the backend, the browser could hang.
const sanitizeXSS = (htmlString: string): string => {
  return DOMPurify.sanitize(htmlString, { FORBID_TAGS: ["pre"] });
};

export const removeAllHTMLTags = (htmlString: string): string => {
  return DOMPurify.sanitize(htmlString, {
    ALLOWED_TAGS: [],
  });
};

export default sanitizeXSS;
