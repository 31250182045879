import React from "react";

import { Button, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from "@chakra-ui/react";
import CustomModal from "../layout/CustomModal";
import ImageCropper from "./ImageCropper";
interface ProfilePictureProps {
  img: File | undefined;
  modalDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
  setImg: React.Dispatch<React.SetStateAction<File | undefined>>;
  submitPicture: (cropper: Cropper) => Promise<void>;
  type: "profile" | "image";
  aspectRatio?: number;
  modalExplanation?: string;
  zoomable?: boolean;
}

const maxZoomLevel = 1;

const ImageCropperModal: React.FC<ProfilePictureProps> = ({
  img,
  modalDisclosure: { isOpen, onClose },
  setImg,
  submitPicture,
  type,
  aspectRatio,
  modalExplanation,
  zoomable = true,
}) => {
  const [cropper, setCropper] = React.useState<Cropper>();
  const [initialZoomRatio, setInitialZoomRatio] = React.useState(0);
  const [url, setUrl] = React.useState("");
  const submit = async () => {
    if (cropper != undefined) {
      // Get the cropped data and transform it to a file object
      submitPicture(cropper);
    }
  };

  React.useEffect(() => {
    // There is no onOpen callback in the chakra modal
    // Since the img is updated right before opening, this will be triggered when the modal opens
    if (img) {
      console.log("new url");
      setUrl(URL.createObjectURL(img));
    }
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [img]);
  const onCloseRevokeURL = () => {
    console.log("revoke url");
    URL.revokeObjectURL(url);
    setImg(undefined);
    onClose();
  };

  return (
    <CustomModal
      title={"Crop your picture"}
      isOpen={isOpen}
      onClose={onCloseRevokeURL}
      blockScrollOnMount
      footer={
        <>
          <Button variant="ghost" mr={3} onClick={onCloseRevokeURL} colorScheme="brand">
            Cancel
          </Button>
          <Button
            colorScheme="brand"
            mr={3}
            onClick={() => {
              submit();
              onCloseRevokeURL();
            }}
          >
            Submit
          </Button>
        </>
      }
    >
      <Flex flexDirection="column" alignItems="center">
        <Text mb={3} fontSize="md">
          {modalExplanation}
        </Text>
        <ImageCropper
          type={type}
          setCropper={setCropper}
          url={url}
          aspectRatio={aspectRatio}
          setInitialZoomRatio={setInitialZoomRatio}
          maxZoomLevel={maxZoomLevel}
          zoomable={zoomable}
        />
        {zoomable && (
          <Slider
            min={0}
            max={maxZoomLevel}
            step={0.01}
            mt={2}
            colorScheme="brand"
            aria-label="slider-profile-picture-zoom"
            w="60%"
            defaultValue={0}
            onChange={(val) => {
              if (cropper) {
                cropper.zoomTo(initialZoomRatio + val);
              }
            }}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb
              _focus={{
                borderColor: "gray.100",
                borderWidth: "1px",
              }}
            />
          </Slider>
        )}
      </Flex>
    </CustomModal>
  );
};
export default React.memo(ImageCropperModal);
