import { PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, Portal, Text } from "@chakra-ui/react";
import clsx from "clsx";
import React from "react";
import { basicColor } from "../../features/calenderOverview/CalendarOverview";
import { removeAllHTMLTags } from "../../utils/sanitizeXSS";

interface BasicEventPopoverProps {
  event: any;
  listView?: boolean;
  calendarView?: boolean;
}

export const BasicEventPopover: React.FC<BasicEventPopoverProps> = ({ event, listView, calendarView = false }) => {
  const header = (
    <div className="flex text-black">
      <div className={`bg-${basicColor}-600 w-1 rounded-md`} />
      <div className="flex justify-between">
        <div>
          <p className={clsx("ml-5 z-0 w-[70%] flex font-bold w-full truncate")}>{event.title}</p>
          <span className="text-gray-500 text-sm ml-5">{new Date(event.start).toLocaleString()}</span>
        </div>
      </div>
    </div>
  );

  const body = (
    <div className="flex justify-between gap-2">
      <div className="space-y-4 text-sm text-gray-700 mt-0.5">
        <Text className="w-[60%]" noOfLines={2}>
          {event.extendedProps.description === "" ? "Empty" : removeAllHTMLTags(event.extendedProps.description)}
        </Text>
      </div>
    </div>
  );

  if (listView) {
    return header;
  }

  return (
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>{header}</PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
      </PopoverContent>
    </Portal>
  );
};

export default BasicEventPopover;
