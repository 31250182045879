import { Box, Button, Flex, Heading, Input, Text, useDisclosure } from "@chakra-ui/react";
import { Transportation, omitLocalTimezone } from "@lato/common";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { HiArrowRight as ArrowRight } from "react-icons/hi";
import AmadeusAPI from "../../api/amadeus.api";
import DayPicker from "../../components/input/date/DayPicker";
import CustomModal from "../../components/layout/CustomModal";
import { prettyDateTime } from "./PnrParser";

interface AmadeusFlightSearchProps {
  //modalDisclosure: any;
  startDate: Date;
  setStartDate: (date: Date) => void;
  addFlight: (flight: Transportation) => void;
}

export type FlightDesignator = {
  flightNo: string;
  scheduledDepartureDate: string;
};

const AmadeusFlightSearch: React.FC<AmadeusFlightSearchProps> = ({
  //modalDisclosure,
  addFlight,
  startDate = new Date(),
  setStartDate,
}) => {
  const flightNoRef = React.useRef<any>();
  const [error, setError] = React.useState(false);
  const [flights, setFlights] = React.useState<Transportation[]>([]);
  const disclosure = useDisclosure();
  const { mutateAsync: getFlightStatus, isPending } = useMutation({
    mutationFn: async (flightDesignator: FlightDesignator) => {
      // Convert the object into a query param string
      const queryParamsString = new URLSearchParams(flightDesignator).toString();
      return await AmadeusAPI.getFlightStatus(queryParamsString);
    },
  });

  const removeFlight = (number: string, flightArrivalIata: string, flightDepartureIata: string) => {
    setFlights(
      flights.filter(
        (flight) =>
          flight.transportNumber !== number ||
          (flight.arrivalAirportIata ?? flight.arrivalTrainstationIata) !== flightArrivalIata ||
          (flight.departureAirportIata ?? flight.departureTrainstationIata) !== flightDepartureIata,
      ),
    );
  };

  const handleFlightAdds = (flightResponse?: any) => {
    (flightResponse ?? flights).forEach((flight: any) => addFlight(flight));
    //modalDisclosure.onClose();
  };

  const handleFlightSearch = async () => {
    try {
      //remove conversion when replacing datepicker to nice one
      const convertedDate = new Date(startDate);
      const convertedDateString = new Date(convertedDate.getTime() - convertedDate.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 10);
      const flightNo = flightNoRef.current.value.toString().toUpperCase();
      const response = await getFlightStatus({
        flightNo,
        scheduledDepartureDate: convertedDateString,
      });
      if (response.length === 0) {
        setError(true);
      } else {
        setError(false);
        // The first value in the array is the resulted flight:
        /* const flight = response[0];
        const departureFlightPoint = flight.flightPoints.find(
          (flightPoint: any) => flightPoint.hasOwnProperty("departure")
        );
        const arrivalFlightPoint = flight.flightPoints.find(
          (flightPoint: any) => flightPoint.hasOwnProperty("arrival")
        );
        const departureTiming = departureFlightPoint.departure.timings.find(
          (timing: any) => timing.qualifier === "STD"
        );
        const arrivalTiming = arrivalFlightPoint.arrival.timings.find(
          (timing: any) => timing.qualifier === "STA"
        );
        console.log(removeTimezoneValue(departureTiming.value));
        console.log(removeTimezoneValue(arrivalTiming.value));
        // add the flight to the form
        const flightObject = {
          realtime: true,
          transportNumber: flightNo,
          departure_date_time: removeTimezoneValue(departureTiming.value),
          arrival_date_time: removeTimezoneValue(arrivalTiming.value),
          departureAirportCode: departureFlightPoint.iataCode,
          arrivalAirportCode: arrivalFlightPoint.iataCode,
          ord: 0,
          type: TRANSPORTATION_TYPE.FLIGHT,
          names: [
            {
              content: `${flightNo}`,
              language: me.defaultTFLang,
              language_code: me.defaultTFLang.code,
              creator: null,
            },
          ],
          departureLocation: null,
          arrivalLocation: null,
          carrier: null,
          departureDayIndex: null,
          arrivalDayIndex: null,
          arrivalTime: null,
          departureTime: null,
        };*/
        if (response.length > 1) {
          //when there are multiple legs on an AmadeusFlight a modal is opened to select the correct flights
          setFlights(response);
          disclosure.onOpen();
        } else {
          //with only one leg the flight is added as normal
          handleFlightAdds(response);
        }
      }
    } catch (e) {
      setError(true);
    }
    setStartDate(startDate);
  };

  const today = new Date();

  return (
    <>
      <Flex columnGap={"2"} align={"center"} my={3} flexDir={"row"} flexWrap={{ base: "wrap", md: "nowrap" }}>
        {error && (
          <Text color="red.500" fontSize="md" w="300px">
            Could not retrieve flight, make sure the flight number and departure date are correct.
          </Text>
        )}
        <Text flex={{ base: "0 0 100%", md: "auto" }} align="left" order="1" display={"absolute"} whiteSpace="nowrap">
          Search flight:{" "}
        </Text>
        <Input
          flex={{ base: "0 0 30%", md: "auto" }}
          order="2"
          w="11ch"
          mx={{ base: "0", md: "3" }}
          placeholder="IB532"
          textTransform={"uppercase"}
          ref={flightNoRef}
          minW={"9ch"}
        />
        <Box flex={{ base: "0 0 30%", md: "auto" }} order="3" width={"30%"} minW={"10ch"}>
          <DayPicker
            customOnClickHandler={(e: any) => setStartDate(omitLocalTimezone(e, true))}
            fromDate={startDate.getTime() < today.getTime() ? startDate : today}
            defaultDate={startDate}
            required
          />
        </Box>
        <Button
          flex={{ base: "0 0 22%", md: "auto" }}
          order={{ base: "1", md: "4" }}
          type="button"
          colorScheme="brand"
          variant="solid"
          mx={{ base: "0", md: "3" }}
          isLoading={isPending}
          onClick={() => handleFlightSearch()}
        >
          Search
        </Button>
      </Flex>
      {/*<>
      <Flex mx={8} my={2} flexDirection={"column"}>
        <Flex flexDirection={"row"} my={1} justifyContent={"space-between"}>
          <Text whiteSpace="nowrap">number: </Text>
          <Input
            width={"15.5em"}
            mx={1}
            placeholder="IB532"
            ref={flightNoRef}
          />
        </Flex>
        <Flex flexDirection={"row"} my={1} justifyContent={"space-between"}>
          <Text whiteSpace="nowrap">Estimated departuredate:</Text>
          <DayPicker
            customOnClickHandler={setDate}
            fromDate={new Date()}
            required
            marginLeft={3}
            marginRight={1}
            inputWidth={""}
          />
        </Flex>

        <Flex mt={3} justifyContent={"space-around"}>
          <Button
            type="button"
            colorScheme="brand"
            variant="solid"
            width={"30%"}
            mx={3}
            isPending={isPending}
            onClick={() => handleFlightSearch()}
          >
            Search Flights
          </Button>
        </Flex>

    </Flex>
      {error && (
        <Text color="red.500" fontSize="sm" textAlign={"center"}>
          Could not retrieve flight, make sure the flight number and departure
          date are correct.
        </Text>
      )}*/}
      <CustomModal size="xl" title="PNR" isOpen={disclosure.isOpen} onClose={disclosure.onClose} trapFocus={false}>
        <Heading marginTop={1} textAlign={"center"} fontWeight={600} size="lg">
          {`Remove the flights that you don't need`}
        </Heading>
        <Box my={4} px={2} maxHeight={"10em"} overflow={"scroll"}>
          {flights.map((flight, i) => (
            <Box
              key={`amadeusFlight-${i}`}
              py={1}
              px={3}
              my={2}
              borderRadius={"5px"}
              border={"rgba(0, 0, 0, 0.16) solid 1px"}
              backgroundColor={"lightGray.100"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Box fontWeight={"bold"} width={"4.5em"}>
                {flight.transportNumber}
              </Box>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1} width={"5em"}>
                {flight.departureAirportIata ?? flight.departureTrainstationIata} <ArrowRight size="15px" />
                {flight.arrivalAirportIata ?? flight.arrivalTrainstationIata}
              </Box>
              {flight.departureDate && <Box width={"8em"}>{prettyDateTime(flight.departureDate.toString())}</Box>}
              <Box
                color={"red.600"}
                cursor={"pointer"}
                position={"relative"}
                bottom={"2px"}
                onClick={() =>
                  removeFlight(
                    flight.transportNumber,
                    flight.arrivalAirportIata ?? flight.arrivalTrainstationIata ?? "",
                    flight.departureAirportIata ?? flight.departureTrainstationIata ?? "",
                  )
                }
              >
                x
              </Box>
            </Box>
          ))}
        </Box>
        <Flex mt={3} justifyContent={"space-around"} overflow={"auto"}>
          <Button
            w="30%"
            mt={4}
            onClick={() => {
              handleFlightAdds();
              disclosure.onClose();
            }}
            colorScheme="brand"
          >
            Add Flights to Trip
          </Button>
        </Flex>
      </CustomModal>
    </>
  );
};
export default React.memo(AmadeusFlightSearch);
