import { Flex, Heading } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";
import { ModalBodyProps, ModalContentProps, HeadingProps } from "@chakra-ui/react";
import React from "react";

interface CustomModalProps extends ModalProps {
  title: string;
  footer?: React.ReactElement;
  closable?: boolean;
  modalContentProps?: ModalContentProps;
  modalBodyProps?: ModalBodyProps;
  showHeader?: boolean;
  className?: string;
  headingProps?: HeadingProps;
  modalId?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  title,
  footer,
  closable = true,
  children,
  isOpen,
  modalContentProps,
  modalBodyProps,
  showHeader = true,
  headingProps,
  modalId,
  ...props
}) => {
  if (isOpen) {
    return (
      <Modal
        // This breaks the outside overflow behaviour
        // isCentered
        motionPreset="slideInBottom"
        closeOnOverlayClick={closable}
        closeOnEsc={closable}
        isOpen={isOpen}
        {...props}
      >
        <div id={modalId}>
          <ModalOverlay />
          <ModalContent w={{ base: "90%", md: "100%" }} maxH={"70vh"} {...modalContentProps}>
            {showHeader && (
              <ModalHeader>
                <Flex alignItems="start" justifyContent="space-between">
                  <Heading size="lg" {...headingProps}>
                    {title}
                  </Heading>
                  {closable ? <ModalCloseButton pos="unset" /> : <></>}
                </Flex>
              </ModalHeader>
            )}
            <ModalBody overflow={"scroll"} {...modalBodyProps}>
              {children}
            </ModalBody>
            {footer && <ModalFooter>{footer}</ModalFooter>}
          </ModalContent>
        </div>
      </Modal>
    );
  } else {
    return <></>;
  }
};
export default CustomModal;
