import { TripDay } from "@lato/common";

export function differenceInNumberOfDays(start_date: Date, end_date: Date) {
  // If both days are equal -> trip contains 1 day -> hence the +1
  return Math.floor(Math.abs(end_date.getTime() - start_date.getTime()) / (24 * 60 * 60 * 1000)) + 1;
}
export function totalNumberOfDays(tripdays: TripDay[]) {
  return tripdays?.reduce((partialSum, td) => partialSum + td.nrOfNights, 1);
}

export function correctNumberOfDays(
  tripdays: TripDay[],
  start_date: any,
  end_date: any,
  sample: boolean,
): string | null {
  if (sample) return null;
  const diffInNights = differenceInNumberOfDays(new Date(start_date), new Date(end_date)) - 1;
  const totalNights = tripdays ? totalNumberOfDays(tripdays) - 1 : 0;
  console.log("RUNNING TEST: ", diffInNights === totalNights ? "no error" : "error");
  return diffInNights === totalNights
    ? null
    : `${totalNights} night${totalNights !== 1 ? "s" : ""} does not match with ${diffInNights} night${
        diffInNights !== 1 ? "s" : ""
      }.`;
}
