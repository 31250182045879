import React from "react";
import POIsAPI from "../../../api/pois.api";
import { useMeContext } from "../../../stores/me-context";
import { POI_QUERY_KEY } from "../../../utils/constants";
import LibraryItems from "../../trips/edit/daybyday/library-items/LibraryItems";
import LibraryItemsList from "../../trips/edit/daybyday/library-items/LibraryItemsList";
import { getEmptyPoi } from "../activities";

interface PoiProps {}

const resourceName = "poi";

const Pois: React.FC<PoiProps> = () => {
  const user = useMeContext();
  const emptyPoi = getEmptyPoi(user);
  emptyPoi.names[0].content = "New POI";
  //emptyPoi.location.coordinates = [0, 0]

  return (
    <LibraryItems>
      <LibraryItemsList api={POIsAPI} eventType={resourceName} emptyResource={emptyPoi} query_key={POI_QUERY_KEY} />
    </LibraryItems>
  );
};
export default React.memo(Pois);
