import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Row } from "react-table";

interface FilterButtonProps {
  type: "radio" | "checkbox";
  columnName: string;
  selectedOptions: string[];
  onSelectionChanged: (value: string | string[], options: string[]) => void;
  preFilteredRows: any;
  createOptions: (preFilteredRows: any, columnName: string) => Set<string>;
  displayOption: (option: string) => React.ReactNode;
  searchValueOption: (option: string) => string;
  isDisabled: boolean;
  isLoading: boolean;
  isError: boolean;
  singleSelect?: boolean;
  disableDeselect?: boolean;
  refetchEl?: JSX.Element | undefined;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  type,
  columnName,
  selectedOptions,
  onSelectionChanged,
  preFilteredRows,
  createOptions,
  displayOption,
  searchValueOption,
  isDisabled,
  isLoading,
  isError,
  singleSelect = false,
  disableDeselect = false,
  refetchEl,
}) => {
  // Calculate the options for filtering using the preFilteredRows
  const initialOptions = React.useMemo(() => {
    const options = createOptions(preFilteredRows, columnName);
    const initialOpts = [...options.values()];
    return initialOpts;
  }, [columnName, preFilteredRows, createOptions]);
  const filterActivated = selectedOptions.length !== initialOptions.length && selectedOptions.length !== 0;

  let menu = null;
  if (isLoading) menu = <MenuItem>Loading {columnName}...</MenuItem>;
  else if (isError) menu = <MenuItem>Error loading {columnName}</MenuItem>;
  else
    menu = (
      <MenuOptionGroup
        type={type}
        onChange={(value) => {
          onSelectionChanged(Array.isArray(value) ? value : [value], initialOptions);
        }}
        defaultValue={type === "checkbox" ? selectedOptions : selectedOptions[0]}
        value={type === "checkbox" ? selectedOptions : selectedOptions[0]}
      >
        {initialOptions.map((option) => {
          // Convert the option to a nicer looking option
          const o = displayOption(option);
          const v = searchValueOption(option);
          return (
            <MenuItemOption
              key={`filter-option-${columnName}-${option}`}
              value={option}
              onClick={(e) => {
                if (singleSelect && selectedOptions.length === 1 && option === selectedOptions[0] && !disableDeselect) {
                  onSelectionChanged([], initialOptions);
                } else if (singleSelect && selectedOptions[0] !== option) {
                  onSelectionChanged([option], initialOptions);
                }
              }}
            >
              {v !== option && <Text display="none">{v}</Text>}
              {React.isValidElement(o) ? o : <Text isTruncated>{o}</Text>}
            </MenuItemOption>
          );
        })}
      </MenuOptionGroup>
    );
  return (
    <Menu
      closeOnSelect={false}
      // Bug: does not remember the chosen options after closing
      // isLazy
    >
      <MenuButton
        color="gray.500"
        as={Button}
        isDisabled={isDisabled}
        rightIcon={
          <Flex alignItems="center">
            <Text color="gray.800" isTruncated size="sm">
              {filterActivated ? selectedOptions.length : "All"}
            </Text>
            <ChevronDownIcon boxSize={5} />
          </Flex>
        }
      >
        {columnName}
      </MenuButton>
      <MenuList minWidth="200px" zIndex={1000} maxH={"25em"} overflowY={"scroll"}>
        {menu}
        {refetchEl}
      </MenuList>
    </Menu>
  );
};
export default React.memo(FilterButton);
