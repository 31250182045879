import { median } from "../median.js";
import { getDistanceFromLatLonInKm } from "./geoUtils.js";
export function removeFarMarkers(markerPositions) {
    if (!markerPositions || markerPositions.length < 3)
        return markerPositions;
    var distances = [];
    for (let i = 0; i < markerPositions.length - 1; i++) {
        distances.push(getDistanceFromLatLonInKm(markerPositions[i], markerPositions[i + 1]));
    }
    const med = median(distances);
    const calculateHowManyToRemove = (arr) => arr.reduce(({ count, stopCounting }, d) => stopCounting || d < med * 2 ? { count, stopCounting: true } : { count: count + 1, stopCounting }, { stopCounting: false, count: 0 });
    const firstToRemove = calculateHowManyToRemove(distances).count;
    const reversedDistances = distances.reverse();
    const lastToRemove = calculateHowManyToRemove(reversedDistances).count;
    return markerPositions.slice(firstToRemove, markerPositions.length - lastToRemove);
}
