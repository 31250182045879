import React from "react";
import { useParams } from "react-router-dom";
import { useMeContext } from "../../../../stores/me-context";
import { useTripFormStore } from "../../../../stores/trip/tripFormStore";
import { SHARE_STEP_TRIP_QUERY_KEY, SHARE_STEP_USER_TRIP_QUERY_KEY } from "../../../../utils/constants";
import { useUserTripTasks } from "../../../../utils/query-helpers/reactQueryHooks";
import {
  getRelationMap,
  useSplitTrip,
  useSplitUserTrip,
} from "../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import { CustomSpinner } from "../../../FullScreenSpinner";
import { TasksWrapper } from "../daybyday/settings/TasksWrapper";

interface TasksStepProps {
  stepNumber: number;
}

export const TasksStep: React.FC<TasksStepProps> = ({ stepNumber }) => {
  const me = useMeContext();
  const { id: userTripId } = useParams<{ id: string }>();
  const { trip: tripFromStore, isFirst, isSample } = useTripFormStore();

  const { data: usertrips, isLoading: isLoadingUsertrip } = useSplitUserTrip(
    userTripId ?? "",
    getRelationMap[SHARE_STEP_USER_TRIP_QUERY_KEY],
    SHARE_STEP_USER_TRIP_QUERY_KEY,
  );

  const { data: trip, isLoading: isLoadingTrip } = useSplitTrip(
    tripFromStore?.id ?? "",
    SHARE_STEP_TRIP_QUERY_KEY,
    getRelationMap[SHARE_STEP_TRIP_QUERY_KEY],
  );

  const {
    data: tasks,
    isFetching: isLoadingTasks,
    isFetching: isFetchingTasks,
    refetch: refetchTasks,
  } = useUserTripTasks(userTripId!);

  const usertrip = usertrips && usertrips[isFirst ? 0 : 1];

  if (isLoadingTrip || isLoadingUsertrip || !trip || !usertrip || !me) return <CustomSpinner m="auto" />;

  return (
    <TasksWrapper
      isLoadingTasks={isLoadingTasks}
      isFetchingTasks={isFetchingTasks}
      start_date={trip.start_date!}
      end_date={trip.end_date!}
      userTrip={usertrip}
      tasks={tasks}
      refetch={refetchTasks}
      isSample={isSample}
    />
  );
};
export default React.memo(TasksStep);
