import { AddIcon } from "@chakra-ui/icons";
import {
  Icon,
  IconButton,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { ImageSite, Trip, User, TranslationField, Image, Event, Hotel, Document } from "@lato/common";
import axios from "axios";
import React from "react";
import { useFormContext } from "react-hook-form";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { CellProps, Column } from "react-table";
import EventsAPI from "../../../../../api/events.api";
import HotelsAPI from "../../../../../api/hotels.api";
import { useMeContext } from "../../../../../stores/me-context";
import { getEmptyTranslationField } from "../../../../../pages/create-trip";
import { useTripFormStore } from "../../../../../stores/trip/tripFormStore";
import { S3_BASE_URL } from "../../../../../utils/constants";
import { useTripTranslations } from "../../../../../utils/query-helpers/triphooks/trip-split-QueryHooks";
import useMutation from "../../../../../utils/query-helpers/useMutation";
import CustomModal from "../../../../layout/CustomModal";
import EventTypeCard from "../EventTypeCard";
import { checkOwnTranslation } from "../LanguagesInput";
import { LibraryItem, LibraryItemType } from "../library-items/LibraryItemModal";
import ActivitiesTable from "../library-items/activities/ActivitiesTable";

interface AddFromLibraryCardProps {
  fieldArrayMethods: any;
  tripdayIndex: number;
  first: boolean;
}

const shouldReplaceTF = (libraryItemLanguageCodes: string[], tf: TranslationField, user: User) =>
  libraryItemLanguageCodes.includes(tf.language.code) && checkOwnTranslation(tf.creator, user);

export const copyDocument = async (doc: any, type: LibraryItemType | string): Promise<Image | Document | null> => {
  let isImage = false;
  if (["accommodation", "activity", "textfield"].includes(type)) {
    isImage = true;
  }
  if ((isImage && !doc.originUrl) || (!isImage && !doc.s3.key)) {
    return doc;
  }

  try {
    const response = await axios({
      // Added the random not-from-cache param because AWS serves the image without headers when loading them on the page and caches that.
      url: `${isImage ? doc.originUrl! : doc.s3url}?not-from-cache-please`,
      method: "GET",
      responseType: "blob",
      // headers: {
      //   "Access-Control-Allow-Credentials": true,
      // },
    });
    // create a new file
    const f = new File([new Blob([response.data])], type, {
      type: isImage ? "image/jpeg" : "application/pdf",
    });
    const newDoc = {
      file: f,
      ord: doc.ord,
    } as any;
    const url = URL.createObjectURL(f);
    if (isImage) {
      newDoc.site = doc.site;
      newDoc.url = url;
    } else {
      newDoc.type = doc.type;
      newDoc.displayName = doc.displayName;
      newDoc.s3 = { key: url.replace(S3_BASE_URL, "") };
      newDoc.s3url = url;
    }
    return newDoc;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export function mergeTFsIntoExistingTFs(
  existingTripTranslations: TranslationField[],
  libraryItemDescriptions: TranslationField[],
  user: User | undefined,
) {
  const libraryItemDescriptionsPrevious = existingTripTranslations.map((tf) => {
    // Replace the empty translation-field with a translation-field from the libraryItem you want to add.
    if (
      shouldReplaceTF(
        libraryItemDescriptions.map((tf) => tf.language.code),
        tf,
        user!,
      )
    ) {
      const hDescr = libraryItemDescriptions.find((tr) => tr.language.code === tf.language.code);
      if (hDescr) {
        // Delete the language from the libraryItemDescriptions such that we can later concat the remaining libraryItemDescriptions.
        libraryItemDescriptions = libraryItemDescriptions.filter((hd) => hd.language.code !== hDescr.language.code);
        return hDescr;
      }
      return tf;
    }
    return tf;
  });
  return {
    previous: libraryItemDescriptionsPrevious,
    new: libraryItemDescriptions,
  };
}

export const copyImages = async (libraryItem: LibraryItem, eventType: LibraryItemType): Promise<Image[]> => {
  let libraryItemImages: any[] = [];
  if (libraryItem.hasOwnProperty("images")) {
    libraryItemImages = (libraryItem as any).images.map(({ id, ...hd }: any) => hd);
  }
  // Copy the images
  const copiedImages = await Promise.all(
    libraryItemImages.map(async (im) => (im.site === ImageSite.S3 ? await copyDocument(im, eventType) : im)),
  );
  return copiedImages.filter((im) => im !== null);
};

export const copyDocuments = async (libraryItem: LibraryItem): Promise<Document[]> => {
  let libraryItemDocuments: any[] = [];
  if (libraryItem.hasOwnProperty("documents")) {
    libraryItemDocuments = (libraryItem as any).documents.map(({ id, ...hd }: any) => hd);
  }
  // Copy the documents
  const copiedDocuments = await Promise.all(
    libraryItemDocuments.map(async (doc) => (await copyDocument(doc, "document")) as Document),
  );
  return copiedDocuments.filter((doc) => doc !== null);
};

export const addLibraryItem = async (
  { id, ...libraryItem }: LibraryItem,
  me: User,
  trip: Trip,
  reset: any,
  tripdayIndex: number,
  libraryItemIndexToReplace: number,
  globalLibraryItemOrd: number,
) => {
  const emptyTranslationField = getEmptyTranslationField(me);
  const globalLanguages = trip.priceDescriptions.map((td) => td);
  const sortedLanguages = globalLanguages.map((l) => l.language.code);
  const eventType: LibraryItemType = "titles" in libraryItem ? "activity" : "accommodation";
  const libraryItemDescriptions = libraryItem.descriptions.map(({ id, ...hd }) => hd);
  // Create new empty translation-fields for the libraryItem.
  const existingTripDescriptonTranslations = ([...libraryItem.descriptions] as TranslationField[]).filter(
    (hd) => sortedLanguages.findIndex((hs) => hs.includes(hd.language.code)) !== -1,
  );
  // This 'previous' variable will contain all the existing translation fields and also insert the new translation fields with the same language.
  const previousLanguageCodes = existingTripDescriptonTranslations.map((ht) => ht.language.code);
  // 'libraryItemDescriptions' now contain all libraryItem languages which were not yet translated by this user in the trip.
  // Now add the missing translation fields for all other fields such as for the pricing.
  libraryItemDescriptions.forEach((hd) => {
    const copy = previousLanguageCodes.indexOf(hd.language.code);
    const remove = globalLanguages.findIndex(
      (hs) => hs.language.code.includes(hd.language.code) && hs.creator?.companyId === me.companyId,
    );
    if (copy !== -1) {
      existingTripDescriptonTranslations[copy] = {
        ...hd,
        language: remove !== -1 ? globalLanguages[remove].language : hd.language,
      };
    }
    if (remove !== -1) {
      globalLanguages.splice(remove, 1);
    }
  });
  // Copy the non library images
  const copiedDocuments = await copyDocuments(libraryItem);
  const copiedImages = await copyImages(libraryItem, eventType);

  const newDescriptions = [
    ...existingTripDescriptonTranslations.map((hd) => ({
      ...emptyTranslationField,
      content: hd.content,
      language: hd.language,
      language_code: hd.language.code,
    })),
    ...globalLanguages.map((hd) => ({
      ...emptyTranslationField,
      creator: hd.creator,
      content:
        existingTripDescriptonTranslations.findIndex((ht) => ht.language.code === hd.language.code) === -1
          ? ""
          : existingTripDescriptonTranslations.find((ht) => ht.language.code === hd.language.code)!.content,
      language: hd.language,
      language_code: hd.language.code,
    })),
  ].sort((a, b) => {
    const asort = sortedLanguages.findIndex((lang: any) => lang === a.language_code);
    const bsort = sortedLanguages.findIndex((lang: any) => lang === b.language_code);

    return (asort === -1 ? 100 : asort) - (bsort === -1 ? 100 : bsort);
  });

  let locationWithoutId: any = undefined;
  if (libraryItem.hasOwnProperty("location") && (libraryItem as any).location) {
    const { id, ...location } = (libraryItem as any).location;
    locationWithoutId = location;
  }

  const basicFields: LibraryItem = {
    ...libraryItem,
    descriptions: newDescriptions,
    user: null,
    ord: globalLibraryItemOrd,
    updated_at: new Date(),
    images: copiedImages,
    documents: copiedDocuments,
  };
  if (eventType === "accommodation") {
    const newHotel: Hotel = {
      ...(basicFields as Hotel),
      location: locationWithoutId,
      phoneNumber: (libraryItem as Hotel).phoneNumber.split(" / ")[0],
    };
    // Replace the hotel
    //reset(`tripdays.${tripdayIndex}.hotels.${libraryItemIndexToReplace}`, newHotel, trip);
    reset(newHotel);
    //fieldArrayMethods.replace(hotels);
  } else if (eventType === "activity") {
    const ev = basicFields as Event;
    const evTitles = ev.titles.map(({ id, ...hd }) => hd);
    const existingTripNameTranslations = ([...(libraryItem as Event).titles] as TranslationField[]).filter(
      (hd) => sortedLanguages.findIndex((hs) => hs.includes(hd.language.code)) !== -1,
    );

    evTitles.forEach((hd) => {
      const copy = previousLanguageCodes.indexOf(hd.language.code);
      if (copy !== -1) {
        existingTripNameTranslations[copy] = hd;
      }
    });
    // 'libraryItemDescriptions' now contain all libraryItem languages which were not yet translated by this user in the trip.
    const newTitles = [
      ...existingTripNameTranslations.map((hd) => ({
        ...emptyTranslationField,
        content: hd.content,
        language: hd.language,
        language_code: hd.language.code,
      })),
      ...globalLanguages.map((hd) => ({
        ...emptyTranslationField,
        creator: hd.creator,
        content:
          existingTripNameTranslations.findIndex((ht) => ht.language.code === hd.language.code) === -1
            ? ""
            : existingTripNameTranslations.find((ht) => ht.language.code === hd.language.code)!.content,
        language: hd.language,
        language_code: hd.language.code,
      })),
    ].sort((a, b) => {
      const asort = sortedLanguages.findIndex((lang: any) => lang === a.language_code);
      const bsort = sortedLanguages.findIndex((lang: any) => lang === b.language_code);

      return (asort === -1 ? 100 : asort) - (bsort === -1 ? 100 : bsort);
    });
    const newEvent: Event = {
      ...ev,
      titles: newTitles,
      location: locationWithoutId,
    };

    // Replace the event
    //reset(`tripdays.${tripdayIndex}.events.${libraryItemIndexToReplace}`, newEvent, trip);

    reset(newEvent);
  }
};

const AddFromLibraryCard: React.FC<AddFromLibraryCardProps> = ({ fieldArrayMethods, tripdayIndex, first }) => {
  const me = useMeContext();
  const { getValues, reset } = useFormContext<Trip>();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const { fields: libraryItems, append } = fieldArrayMethods;
  const { trip } = useTripFormStore();

  const { data: tripTranslations } = useTripTranslations(trip?.id ?? "");

  const shortAddLibraryItem = async (libraryItem: LibraryItem) => {
    await addLibraryItem(
      libraryItem,
      me,
      tripTranslations!,
      reset,
      tripdayIndex,
      // libraryItems,
      // This will give bugs because we don't want to replace any library item. A new one should be created.
      libraryItems.length - 1,
      0,
    );
    onClose();
  };

  const extraColumns: Column<LibraryItem>[] = React.useMemo(
    () => [
      {
        id: "insert-libraryItem",
        isNumeric: true,
        isTruncated: false,
        // minWidth: 60,
        // chakraMaxWidth: "60px",
        // width: 60,
        chakraWidth: "60px",
        Cell: (cell: CellProps<LibraryItem>) => {
          const isAccommodation = !("time" in cell.row.original);
          const api = isAccommodation ? HotelsAPI : EventsAPI;
          const { mutate: getLibraryItem, rqMutation } = useMutation({
            apiCall: (id: string) => api.getSingle(id),
            failMessage: `loading libraryItem information`,
          });
          return (
            <Tooltip label="Add accommodation" aria-label="insert-accommodation-tooltip">
              <IconButton
                colorScheme="brand"
                aria-label="insert-accommodation"
                icon={rqMutation.isPending ? <Spinner /> : <Icon as={AddIcon} boxSize={3} />}
                onClick={async (e) => {
                  e.stopPropagation();
                  const libraryItem = await getLibraryItem(cell.row.original.id!);
                  if (libraryItem) {
                    await shortAddLibraryItem(libraryItem);
                  }
                }}
                w="fit-content"
                variant="ghost"
              />
            </Tooltip>
          );
        },
      },
    ],
    [addLibraryItem],
  );

  console.log("Rerendering Library Add");

  const tripdayLocation = getValues().tripdays[tripdayIndex].location;

  const tableProps = {
    additionalColumns: extraColumns as any,
    additionalQueryState: tripdayLocation
      ? {
          location: [
            (tripdayLocation.coordinates && tripdayLocation.coordinates[0]) ?? 0,
            (tripdayLocation.coordinates && tripdayLocation.coordinates[1]) ?? 0,
          ],
          order: "ASC",
        }
      : undefined,
  };
  return (
    <>
      <EventTypeCard
        name={"Library element"}
        icon={IoExtensionPuzzleOutline}
        // isDisabled={eventsFieldArrayMethods.fields.length >= 10}
        onClick={onOpen}
        display={first ? "flex" : "none"}
      />
      <CustomModal title="Import element from library" onClose={onClose} isOpen={isOpen} size="3xl">
        <Tabs colorScheme="brand" mt={5} width="100%" maxWidth={"1000px"} mx="auto">
          <TabList mb={0}>
            {/* <Tab>Accommodations</Tab> */}
            <Tab>Activities</Tab>
          </TabList>

          <TabPanels>
            {/* <TabPanel p={0}>
              <AccommodationsTable {...tableProps} />
            </TabPanel> */}
            <TabPanel p={0}>
              <ActivitiesTable {...tableProps} users={[]} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CustomModal>
    </>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.tripDayIndex === nextProps.tripDayIndex && prevProps.first === nextProps.first;
};

export default React.memo(AddFromLibraryCard, areEqual);
