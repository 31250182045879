import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

import FreeTrialAlert from "../components/alerts/FreeTrialAlert";
import { Greeting } from "../features/dashboard/Greeting";
import { UpcomingTasks } from "../features/dashboard/UpcomingTasks";
import { UpcomingTrips } from "../features/dashboard/UpcomingTrips";
import { RecentlyEditedTrip } from "../features/dashboard/RecentlyEditedTrip";
import { useNavigate } from "react-router-dom";
import FollowerSamplesWall from "./dashboard/FollowerSamplesWall";
import articleData from "../assets/data/learnsection/learnArticles.json";
import LearningSection from "../components/learn/LearningSection";
import GetTimeZone from "../features/dashboard/GetTimeZone";
import { ChangelogModal } from "../features/changelog/ShowNewestUpdateModal";
interface dashboardProps {}

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const Dashboard: React.FC<dashboardProps> = () => {
  const date = new Date();
  const navigate = useNavigate();

  return (
    <Box w="100%" maxW="1000px" mx="auto" py={4}>
      <GetTimeZone />
      <FreeTrialAlert />
      <ChangelogModal />
      <Greeting />
      <Tooltip label={"Go to your personal calendar"}>
        <Flex cursor={"pointer"} position={"absolute"} right={44} mr={4} mt={-6} onClick={() => navigate("/calendar")}>
          <Box>
            <Text className="font-medium align-bottom self-center text-[#ff817d] text-lg">
              {days[date.getDay()].toUpperCase()}
            </Text>
            <Flex justifyContent={"end"}>
              <Text className="text-[#004354] font-bold text-4xl -mt-2">{date.getDate()}</Text>
            </Flex>
          </Box>
        </Flex>
      </Tooltip>
      <Flex w="100%" height={"40vh"} gap={8}>
        <RecentlyEditedTrip />
        <UpcomingTasks />
      </Flex>
      <UpcomingTrips />
      <FollowerSamplesWall />
      <LearningSection learnData={articleData.dashboardArticles} randomiseArticles={true} />
    </Box>
  );
};

export default Dashboard;
