import { Box, Text, Link } from "@chakra-ui/react";
import React from "react";
import { ForgotPasswordIllustration } from "../assets/icons/MiniDrawerIcons";
import { CardWrapper } from "./CardWrapper";

interface EmailSentViewProps {}

const EmailSentView: React.FC<EmailSentViewProps> = () => {
  return (
    <CardWrapper
      title="Email is on its way"
      description="Please follow the instructions we sent to your inbox."
      topIllustration={ForgotPasswordIllustration}
    >
      <Box>
        If you {`didn't`} receive an email, please check your <b>spam</b> folders.
      </Box>
      {/* <Text>Not in your spam? Click here to receive a new email.</Text> */}
    </CardWrapper>
  );
};
export default React.memo(EmailSentView);
