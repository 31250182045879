import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Portal as ChakraPortal,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { encodeToLettersAndNumbersOnly } from "@lato/common";
import { useQueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { BiDownload } from "react-icons/bi";
import { BsFillJournalBookmarkFill, BsPerson } from "react-icons/bs";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { HiExternalLink, HiPhone as Phone } from "react-icons/hi";
import { IoRestaurantOutline } from "react-icons/io5";
import { LiaBedSolid, LiaStar } from "react-icons/lia";
import { LuBed, LuBookPlus, LuHash } from "react-icons/lu";
import { PiBookmarkSimpleBold } from "react-icons/pi";
import { TbMoodKid } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useAsyncDebounce } from "react-table";
import { trpc } from "../../../trpc";
import PdfAPI from "../../api/pdf.api";
import config from "../../config";
import { useMeContext } from "../../stores/me-context";
import { prettyPrintDate } from "../../utils/date";
import ENV from "../../utils/env";
import { usePaginatedQuery } from "../../utils/query-helpers/usePaginatedQuery";
import Pagination from "../../components/CRUD/Pagination";
import { GlobalFilter } from "../../components/CRUD/Resource";
import { FILTER_TYPE } from "../../components/CustomDownShift/CustomComboBox";
import { FilterSelect } from "../../components/CustomDownShift/FilterSelect";
import DateRangePicker from "../../components/input/date/DateRangePicker";
import { UseRangeInputOptions } from "../../types/UseRangeInputOptions";
import { CustomSpinner } from "../../components/FullScreenSpinner";
import Overlay from "../../components/accessiblity/overlay";
import Form from "../../components/form/Form";
import Section from "../../components/layout/Section";
import { SingleAccommodation } from "../../components/trips/edit/daybyday/library-items/SingleAccommodation";
import BookModal from "../../components/trips/edit/daybyday/library-items/hotel/BookModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import Bookings from "./Bookings";

// overview of made bookings
// currently only for hotelbeds
const BookingOverview: React.FC = () => {
  const user = useMeContext();

  // statusFilter
  const [status, setStatus] = React.useState(["CONFIRMED"]);
  // searchInput
  const [globalFilter, setGlobalFilter] = React.useState("");
  // ratingFilter
  const [rating, setRating] = React.useState([0, 5]);

  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  // setting defaultRange for DateRangeFilter
  const defaultDateRange = { from: new Date(), to: oneYearFromNow };
  const [dateRangeCurrent, setDateRangeCurrent] = React.useState<DateRange>(defaultDateRange);
  const [dateRange, setDateRange] = React.useState<DateRange>(defaultDateRange);

  // pagination page
  const [cursor, setCursor] = React.useState(1);
  // pagination page
  const [limit, setLimit] = React.useState(10);

  // query for bookings
  const {
    paginationOptions,
    queryResult: { data, isFetching, isLoading },
    triggerFetch,
  } = usePaginatedQuery<any>(
    {
      queryName: "bookings",
      apiCall: trpc.hotelbeds.getHotelbedsBookings,
      additionalQueryState: {
        clientReference: user.company.hotelbeds_client_id,
        userId: user.id,
        companyId: user.companyId,
        status: status.length === 2 || status.length === 0 ? "ALL" : status[0],
        rating,
        dateRange,
        q: globalFilter ?? "",
        cursor,
        limit,
      },
    },
    true,
  );

  const bookings = React.useMemo(() => data?.data ?? [], [data]);

  // dateRangefilter is set on close of datePicker
  const onBeforeClose = () => {
    setDateRange(
      dateRangeCurrent
        ? { from: dateRangeCurrent.from ?? new Date(), to: dateRangeCurrent.to ?? oneYearFromNow }
        : defaultDateRange,
    );
  };

  // pagination tablemethods
  const tableMethods = {
    state: {
      pageIndex: cursor - 1,
      pageSize: limit,
    },
    page: bookings,
    pageOptions: paginationOptions,
    nextPage: () => {
      setCursor(cursor + 1);
    },
    previousPage: () => {
      setCursor(cursor - 1);
    },
    gotoPage: (page: number) => {
      setCursor(page + 1);
    },
    setPageSize: (size: number) => {
      setLimit(size);
    },
    canNextPage: !!(data as any)?.nextCursor,
    canPreviousPage: cursor > 1,
    pages: Math.ceil((data?.count ?? 0) / limit),
  };

  // options for dateRangepicker
  const rangePickerOptions: UseRangeInputOptions = {
    min: 1,
    // // date-fns specific date format type
    format: "PP",
    max: 300,
    numberOfMonths: 2,
    onSelect: (e) => setDateRangeCurrent(e ?? dateRangeCurrent),
    // showOutsideDays,
    // updateRHFValues,
  };

  return (
    <Bookings>
      <ReactQueryDevtools />
      <Stack w="100%" maxW="1000px" mx="auto" mb={10} gap={4}>
        <Flex gap={4}>
          <Heading mb={5}>Booking</Heading>
          <Box w="30%">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={useAsyncDebounce((e) => {
                if (e && e !== "") {
                  // not possible to filter on Cancelled bookings, so setting status to CONFIRMED when there is a value
                  setStatus(["CONFIRMED"]);
                  setCursor(1);
                }
                setGlobalFilter(e);
              }, 100)}
              placeholder={"Search by hotel, booking reference, ..."}
              borderRadius="xl"
            />
          </Box>

          {(globalFilter && globalFilter !== "") || (rating && (rating[0] !== 0 || rating[1] !== 5)) ? (
            // Whenever a filter is applied, setting status to CONFIRMED and disabling change to CANCELLED
            <Tooltip
              aria-label={"filter-tooltip"}
              label={"You can only filter on confirmed bookings"}
              placement={"top"}
            >
              <Button fontWeight={"bold"} isDisabled borderRadius={"10px"} w={"8.2em"}>
                {"Confirmed"}
              </Button>
            </Tooltip>
          ) : (
            // when no filters, we can switch between CANCELLED or CONFIRMED
            <FilterSelect
              icon={BsFillJournalBookmarkFill}
              onChange={(e) => {
                setCursor(1);
                setStatus(e);
              }}
              options={[
                { value: "CANCELLED", text: "Cancelled" },
                { value: "CONFIRMED", text: "Confirmed" },
              ]}
              type={FILTER_TYPE.MULTISELECT}
              defaultSelected={status}
              name="status"
            />
          )}

          <FilterSelect
            options={5}
            onChange={(e) => {
              if (e && (e[0] !== 0 || e[1] !== 5)) {
                // not possible to filter on Cancelled bookings, so setting status to CONFIRMED when there is a value different than default
                setStatus(["CONFIRMED"]);
                setCursor(0);
              }
              setRating(e);
            }}
            icon={LiaStar}
            defaultSelected={rating}
            name="rating"
            type={FILTER_TYPE.RANGE}
            height={"2em"}
          />
          <Box w={"25%"}>
            <DateRangePicker
              {...rangePickerOptions}
              defaultSelected={dateRangeCurrent}
              beforeClose={onBeforeClose}
              borderRadius="10px"
            />
          </Box>
        </Flex>
        {isLoading || isFetching ? (
          <CustomSpinner />
        ) : (
          <>
            {data && bookings.length > 0 && (
              // showing paginationButtons
              <Pagination onlyButtons tableMethods={tableMethods} pageSize={limit} totalCount={data.count}></Pagination>
            )}

            {(bookings ?? []).map((booking: any) => (
              // bookings
              <Booking booking={booking} refetchBookings={triggerFetch}></Booking>
            ))}
            {data && bookings.length > 0 && (
              // showing paginationButtons at bottom of page
              <Pagination tableMethods={tableMethods} pageSize={limit} totalCount={data.count}></Pagination>
            )}
          </>
        )}
      </Stack>
    </Bookings>
  );
};

interface BookingProps {
  booking: any;
  voucher?: boolean;
  refetchBookings: (params?: any) => void;
}

const Booking: React.FC<BookingProps> = ({ booking, voucher = false, refetchBookings }) => {
  const { mutateAsync: cancelBooking, isPending: isCancelling } = trpc.hotelbeds.cancelBooking.useMutation();
  // boolean to show spinner when downloading pdf
  const [generatingPDF, setGeneratingPDF] = React.useState(false);
  const confirmDisclosure = useDisclosure();
  const bookingDisclosure = useDisclosure();
  const queryClient = useQueryClient();

  const user = useMeContext();

  const accommodationsheader = SingleAccommodation({
    item: booking.hotelDetail,
    scale: 3,
    width: "65%",
    name: "hotelDetail.rating",
  });

  const formMethods = useForm<any>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: booking,
    shouldUnregister: false,
    criteriaMode: "all",
  });

  // downloading pdf for voucher of booking
  const downloadVoucher = async (e: any) => {
    e.stopPropagation();
    setGeneratingPDF(true);
    const res = await PdfAPI.downloadVoucherPDF(booking.reference);

    // Create blob link to download
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${encodeToLettersAndNumbersOnly(booking.reference)}.pdf`;

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
    setGeneratingPDF(false);
  };

  return (
    <>
      <Section
        noDivider
        key={`booking-${booking.reference}`}
        position="relative"
        cursor="pointer"
        _hover={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)" }}
        onClick={() => window.open(`/accommodations/${booking.hotelDetail.id}`, "_blank")}
      >
        {/*when booking is cancelled we show an overlay*/}
        <Overlay
          display={booking.status === "CANCELLED"}
          style={{
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            borderRadius: "12px",
          }}
          zIndex={11}
        />
        <Form formMethods={formMethods} onSubmit={() => {}}>
          <Flex width={"100%"} justifyContent={"space-between"} height={"8.5em"} mb={{ lg: 4, md: 0 }}>
            {accommodationsheader}
            <Flex flexDirection={"column"} gap={1} mt={"0.1em"}>
              <Tooltip label={"Check In - Check Out"} aria-label={"check-in-check-out"} placement={"top-start"}>
                <Flex alignItems={"center"} gap={2}>
                  <CalendarIcon />
                  <Text>
                    {prettyPrintDate(booking.hotel.checkIn, undefined, "full")} -{" "}
                    {prettyPrintDate(booking.hotel.checkOut, undefined, "full")}
                  </Text>
                </Flex>
              </Tooltip>
              <Tooltip label={"Booking status"} aria-label={"booking-status"} placement={"top-start"}>
                <Flex alignItems={"center"} gap={2} ml={"-0.15em"}>
                  <Icon as={PiBookmarkSimpleBold} boxSize={5} />
                  <Text color={booking.status === "CANCELLED" ? "red" : undefined}>
                    {booking.status.charAt(0) + booking.status.slice(1).toLowerCase()}
                  </Text>
                </Flex>
              </Tooltip>
              <Tooltip label="Booking creation date" aria-label="booking-creation-date" placement="top-start">
                <Flex alignItems={"center"} gap={2} ml={"-0.1em"}>
                  <Icon as={LuBookPlus} boxSize={5} />
                  <Text>{prettyPrintDate(booking.creationDate, undefined, "full")}</Text>
                </Flex>
              </Tooltip>
              <Tooltip label="Booking reference" aria-label="booking-reference" placement="top-start">
                <Flex alignItems={"center"} gap={2} ml={"-0.1em"}>
                  <Icon as={LuHash} boxSize={5} />
                  <Text>{booking.reference}</Text>
                </Flex>
              </Tooltip>
              <Tooltip label="phone number" aria-label="phone-number" placement="top-start">
                <Flex alignItems={"center"} gap={2} ml={"-0.1em"}>
                  <Icon as={Phone} boxSize={5} />
                  <Text>{booking.hotelDetail.phoneNumber}</Text>
                </Flex>
              </Tooltip>
            </Flex>
          </Flex>
          <Flex px={1} mt={1} zIndex={10} gap={2} flexDir={"column"}>
            <Flex flexDir={"row"} gap={4}>
              <Flex ml={"0.1em"} minWidth={"20%"}>
                {booking.hotelDetail.origin && (
                  <Tooltip label={"Origin"} aria-label="origin" placement="top-start">
                    <Flex gap={"0.6em"} alignItems={"center"}>
                      <Icon as={LuBed} boxSize={5} />
                      <Text>{booking.hotelDetail.origin}</Text>
                    </Flex>
                  </Tooltip>
                )}
              </Flex>
              {!voucher && (
                <Flex flexDir={"row"} gap={4}>
                  {booking.totalNet && (
                    <Tooltip label={"Total Price"} aria-label="total-price" placement="top-start">
                      <Flex gap={"0.6em"} minWidth={"20%"} alignItems={"center"} ml={"-0.1em"}>
                        <Icon as={GiReceiveMoney} boxSize={6} alignSelf={"start"} />
                        <Text>
                          {booking.totalSellingRate} {booking.currency}
                        </Text>
                      </Flex>
                    </Tooltip>
                  )}

                  {booking.pendingAmount && (
                    <Tooltip label={"Pending Price"} aria-label="pending-price" placement="top-start">
                      <Flex ml={"-0.1em"} gap={"0.9em"} alignItems={"center"}>
                        <Icon as={GiPayMoney} boxSize={5} />
                        <Text>
                          {booking.pendingAmount} {booking.currency}
                        </Text>
                      </Flex>
                    </Tooltip>
                  )}
                </Flex>
              )}
            </Flex>
            {booking.hotel.supplier && voucher && (
              <Text>{`Payable through ${booking.hotel.supplier.name}, acting as agent for the service operating company, details of which can be provided upon request. VAT: ${booking.hotel.supplier.vatNumber} Reference: ${booking.reference}" (mandatory).`}</Text>
            )}
            <Heading size="lg" textDecoration={"underline"}>
              Rooms
            </Heading>
            {booking.hotel.rooms.map((room: any, index: number) => {
              const roomDetail =
                booking.hotelDetail.rooms.find(
                  (roomH: any) => roomH.code === room.code || roomH.description === room.name,
                ) ?? booking.hotelDetail.rooms[index];
              return (
                <Box>
                  <Flex flexDirection={"row"} gap={4} alignItems="start">
                    <Flex gap={"0.6em"} minWidth={"20%"}>
                      <Icon as={LiaBedSolid} boxSize={6} alignSelf={"start"} />
                      <Text>{roomDetail.description.charAt(0) + roomDetail.description.slice(1).toLowerCase()}</Text>
                    </Flex>
                    <Tooltip label="Board" aria-label="board" placement="top-start">
                      <Flex gap={3} alignItems={"center"}>
                        <Icon as={IoRestaurantOutline} boxSize={5} />
                        <Text>
                          {booking.hotelDetail.board.charAt(0) + booking.hotelDetail.board.slice(1).toLowerCase()}
                        </Text>
                      </Flex>
                    </Tooltip>
                    {room.paxes.map((roomPax: any, index: number) =>
                      index === 0 || roomPax.type === "CH" ? (
                        <Tooltip
                          label={roomPax.type === "CH" ? "child" : "Holder"}
                          aria-label="holder"
                          placement="top-start"
                        >
                          <Flex gap={"0.6em"} alignItems={"center"} ml={"-0.1em"}>
                            <Icon as={roomPax.type === "CH" ? TbMoodKid : BsPerson} boxSize={6} alignSelf={"start"} />
                            <Flex gap={2}>
                              {roomPax.type !== "CH" && (
                                <Text>
                                  {(room.paxes[0].surname ?? booking.holder.surname).charAt(0) +
                                    (room.paxes[0].surname ?? booking.holder.surname).slice(1).toLowerCase()}{" "}
                                  {(room.paxes[0].name ?? booking.holder.name).charAt(0) +
                                    (room.paxes[0].name ?? booking.holder.name).slice(1).toLowerCase()}
                                </Text>
                              )}
                              {roomPax.type === "CH" && <Text>age : {roomPax.age}</Text>}
                            </Flex>
                          </Flex>
                        </Tooltip>
                      ) : (
                        <></>
                      ),
                    )}
                  </Flex>
                  {voucher && roomDetail.comments && (
                    <Box>
                      <Text fontSize={"18px"} textDecoration={"underline"} my={2}>
                        Comments
                      </Text>
                      {roomDetail.comments.split(/(?=[^0-9])\.(?=[^0-9])/g).map((comment: string) => (
                        <Text>{comment.trim()}</Text>
                      ))}
                    </Box>
                  )}
                </Box>
              );
            })}

            {booking.status === "CONFIRMED" && (
              <Flex ml={"auto"} gap={2} alignItems={"end"} mr={4}>
                {booking.userTripId && (
                  <Tooltip label="Go to trip" aria-label={"go-to-trip"}>
                    <IconButton
                      as={Link}
                      variant={"outline"}
                      aria-label={"go-to-trip-button"}
                      icon={<Icon as={HiExternalLink} boxSize={4} />}
                      href={`${config[ENV].domain}/trips/${booking.userTripId}`}
                      target={"_blank"}
                    ></IconButton>
                  </Tooltip>
                )}
                <Button
                  colorScheme="red"
                  variant="outline"
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDisclosure.onOpen();
                  }}
                >
                  Cancel
                </Button>
                {!voucher && (
                  <IconButton
                    isLoading={generatingPDF}
                    aria-label="Download voucher"
                    icon={<Icon as={BiDownload} />}
                    title="Download Voucher"
                    colorScheme="brand"
                    variant="outline"
                    onClick={downloadVoucher}
                  ></IconButton>
                )}
              </Flex>
            )}
          </Flex>
          {booking.status === "CONFIRMED" && bookingDisclosure.isOpen && (
            // used to edit bookings but currently not supported
            <ChakraPortal>
              <BookModal
                hotel={booking.hotelDetail}
                edit
                isOpen={bookingDisclosure.isOpen}
                onClose={bookingDisclosure.onClose}
                adults={
                  booking.hotel.rooms
                    ? booking.hotel.rooms.reduce(
                        (a: any, b: any) => (a.paxes ? a.paxes.filter((pax: any) => pax.type === "AD").length : 0),
                        0,
                      )
                    : 0
                }
                children={
                  booking.hotel.rooms
                    ? booking.hotel.rooms
                        .map((a: any, b: any) =>
                          a.paxes
                            ? a.paxes.filter((pax: any) => pax.type === "CH").map((pax: any) => ({ age: pax.age }))
                            : {},
                        )
                        .flat()
                    : 0
                }
                endDate={booking.hotel.checkOut}
                setRooms={() => {}}
                startDate={booking.hotel.checkIn}
                roomCall={trpc.hotelbeds.getHotelbedRooms}
                bookingReference={booking.reference}
                clientReference={booking.clientReference}
                holder={{
                  first_name:
                    booking.holder.name && booking.holder.name.charAt(0) + booking.holder.name.slice(1).toLowerCase(),
                  last_name:
                    booking.holder.surname &&
                    booking.holder.surname.charAt(0) + booking.holder.surname.slice(1).toLowerCase(),
                }}
                userTripId={booking.userTripId}
              />
            </ChakraPortal>
          )}
        </Form>
      </Section>
      {confirmDisclosure.isOpen && (
        <ConfirmModal
          title={`Cancel Booking for ${booking.hotelDetail.name}`}
          description="Are you sure you want to cancel this booking?"
          disclosure={confirmDisclosure}
          action={async () => {
            await cancelBooking({
              bookingReference: booking.reference,
              detached: !booking.userTripId,
              userId: user.id,
            });
            refetchBookings({ filters: [], q: "", page: 0 });
            await queryClient.invalidateQueries({
              queryKey: ["usertrip", booking.userTripId],
            });
          }}
          isLoading={isCancelling}
          confirmButtonName="Confirm"
        />
      )}
    </>
  );
};

export default BookingOverview;
